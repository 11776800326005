import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Typography from 'ravenjs/lib/Typography';
import Col from 'ravenjs/lib/Col';
import { get } from 'ravenjs/utils/lodash';
import Row from 'ravenjs/lib/Row';

import { LogoutIcon, NavigationArrowLeft } from 'components/Icons';
import SupportEmailAddress from 'components/SupportEmailAddress';
import SupportPhoneNumber from 'components/SupportPhoneNumber';
import { DEFAULT_COLORS } from 'constants/colors';
import { DEFAULT_USER_NAV_ITEMS, USER_NAV_SUPPORT_INFO } from 'constants/navigation';
import { USER_NAV_ITEMS } from 'constants/proptypes';
import { selectors as userSelectors } from 'modules/user';
import { isOasisEnabled } from 'utils/company';
import { sanitizeMarkup } from 'utils/sanitize';

import NavItems from './NavItems';
import NeedHelpSectionPaychex from '../NeedHelpSectionPaychex';

const BackButton = styled.div`
    align-items: center;
    display: flex;
    margin-top: 2.85rem;
`;

const NavItemsContainer = styled.div`
    margin-top: 3rem;
`;

const MenuContainer = styled.div`
    background: ${DEFAULT_COLORS.WHITE};
    height: 100%;
    padding: 1.5rem 1.5rem 1.5rem 1.97rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1000;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const UserActions = styled(Col)`
    display: flex;
    justify-content: flex-end;
    padding: 0;
`;

const LinkItem = styled.li`
    align-items: center;
    display: flex;
    position: relative;
    ${({ theme }) => theme.media.down('md')`
        margin: 0.5rem 0 2.263rem 0;
        font-size: 1.188rem;
        font-weight: normal;
    `};
    ${({ theme }) => theme.media.up('md')`
        font-size: 1rem;
    `};
`;

const LinkItemButton = styled(NavLink)`
    color: ${DEFAULT_COLORS.BLACK};

    :hover {
        color: ${DEFAULT_COLORS.LINK};
        text-decoration: none;
    }
`;

const MobileUserMenu = props => {
    const { userNavItems, onClose, partnerSettings } = props;
    const [selected, setSelected] = useState(null);

    const onChangeSelection = selection => {
        setSelected(selection);
    };

    const onClickBack = () => {
        onChangeSelection(null);
    };

    const supportEmailAddress = () => {
        return get(partnerSettings, 'support.supportEmail', '');
    };

    const supportPhoneNumber = () => {
        return get(partnerSettings, 'support.hotlinePhone', '');
    };

    const supportInfoSubTitle = () => {
        return get(partnerSettings, 'support.supportInfoSubTitle', '');
    };

    const showSupportInformation = () => {
        const displaySupportInfoInAvatar = _get(
            partnerSettings,
            'support.displaySupportInfoInAvatar'
        );
        return (
            (isOasisEnabled() || displaySupportInfoInAvatar) &&
            Boolean(
                typeof displaySupportInfoInAvatar === 'boolean' &&
                    Boolean(supportInfoSubTitle() || supportPhoneNumber() || supportEmailAddress())
            )
        );
    };

    const renderSupportSubtitle = () => {
        const supportInfoSubTitle = get(partnerSettings, 'support.supportInfoSubTitle', null);
        const displaySupportInfoInAvatar = get(
            partnerSettings,
            'support.displaySupportInfoInAvatar',
            false
        );
        const partnerId = get(partnerSettings, 'partnerId', '');
        const paychexSupportEmail = get(partnerSettings, 'support.supportEmail', '');

        const isPaychexPartner =
            partnerId === MAESTER.companies.paychexinc_id ||
            partnerId === MAESTER.companies.paychexsales_id ||
            partnerId === MAESTER.companies.paychexFlex_id;

        if (isPaychexPartner) {
            return <NeedHelpSectionPaychex paychexSupportEmail={paychexSupportEmail} />;
        } else if (displaySupportInfoInAvatar) {
            return (
                <>
                    <SupportPhoneNumber
                        fontSize="1.118rem"
                        fontWeight="normal"
                        margin="0 0 0.375rem 0.0625rem"
                        phoneNumber={supportPhoneNumber()}
                    />
                    <SupportEmailAddress
                        emailAddress={supportEmailAddress()}
                        fontSize="1.118rem"
                        fontWeight="normal"
                        margin="0 0 2rem 0.0625rem"
                    />
                </>
            );
        }
        return (
            <Typography color={DEFAULT_COLORS.BLACK} fontFamily="Roboto" lineHeight="normal">
                {supportInfoSubTitle}
            </Typography>
        );
    };

    return (
        <div>
            <MenuContainer>
                <Row direction="column">
                    {selected && (
                        <BackButton>
                            <NavigationArrowLeft
                                onClick={onClickBack}
                                style={{ marginRight: '0.8rem' }}
                            />
                            <Typography
                                as="span"
                                fontFamily="Roboto"
                                fontSize="0.875rem"
                                fontWeight="500"
                                gutterBottom="0"
                                gutterTop="0"
                                onClick={onClickBack}
                                style={{
                                    letterSpacing: '0.93px',
                                }}
                            >
                                ALL
                            </Typography>
                        </BackButton>
                    )}
                    <NavItemsContainer>
                        <NavItems
                            items={userNavItems}
                            selected={selected}
                            onChangeSelection={onChangeSelection}
                            onRedirect={state => {
                                onClose({ state });
                            }}
                        />
                    </NavItemsContainer>
                </Row>
                {showSupportInformation() && !selected && (
                    <Row>
                        <Col margin="0" padding="0">
                            <Typography
                                color={DEFAULT_COLORS.BLACK}
                                fontSize="1.5rem"
                                fontWeight="bold"
                                fontFamily="Favorit"
                                gutterBottom="0.625rem"
                                gutterTop="0"
                                lineHeight="normal"
                            >
                                {USER_NAV_SUPPORT_INFO.TITLE}
                            </Typography>
                            {renderSupportSubtitle()}
                        </Col>
                    </Row>
                )}
                <Row>
                    <UserActions size={12}>
                        <LinkItem key={DEFAULT_USER_NAV_ITEMS.id}>
                            {DEFAULT_USER_NAV_ITEMS.id === 'logout' ? (
                                <LogoutIcon
                                    width="14px"
                                    height="15px"
                                    style={{ marginRight: '0.9375rem' }}
                                />
                            ) : null}
                            <LinkItemButton
                                {...DEFAULT_USER_NAV_ITEMS}
                                onClick={() => onClose({ state: 'logout' })}
                                dangerouslySetInnerHTML={sanitizeMarkup(
                                    DEFAULT_USER_NAV_ITEMS.title
                                )}
                            />
                        </LinkItem>
                    </UserActions>
                </Row>
            </MenuContainer>
        </div>
    );
};

MobileUserMenu.propTypes = {
    userNavItems: USER_NAV_ITEMS.isRequired,
    onClose: func.isRequired,
    partnerSettings: shape({
        support: shape({
            supportEmail: string,
            supportPhone: string,
        }),
    }).isRequired,
};

const mapStateToProps = createStructuredSelector({
    partnerSettings: userSelectors.getPartnerSettings,
});

export { MobileUserMenu as MobileUserMenuUnwrapped };

export default connect(mapStateToProps, null)(MobileUserMenu);
