import { bool, func, object, number } from 'prop-types';
import React from 'react';
import _debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { get } from 'ravenjs/utils/lodash';
import Responsive from 'ravenjs/lib/Responsive';
import { renderCheck } from 'ravenjs/utils/viewport';
import { formatDate } from 'ravenjs/utils/date';

import { EVENTS } from 'constants/events';
import MESSAGES from 'constants/messages';
import { MODALS } from 'constants/modals';
import { MNL_INTELLIGENCE } from 'constants/intelligence';
import MobileNotificationsMenu from 'components/MobileNotificationsMenu';
import { NOTIFICATION_TYPES } from 'constants/notification';
import NotificationsMenu from 'components/NotificationsMenu';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { actions as notificationsActions } from 'modules/notifications';
import { actions as pendoActions } from 'modules/pendo';
import { actions as adminActions } from 'modules/admin';
import { selectors as userSelectors } from 'modules/user';
import * as userActions from 'modules/user/actions';
import { actions as uiActions } from 'modules/ui';
import { STATUS } from 'utils/notifications';

class NotificationWidget extends React.Component {
    notificationIconRef = React.createRef();

    notificationMenuRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            hasError: false,
            isMenuOpen: false,
            loading: false,
            notifications: [],
            total: null,
            totalUnread: null,
            totalUnseen: null,
            welcomeInsightStatusFromNotification: false,
        };
    }

    componentDidMount() {
        const { isSoftLogin } = this.props;

        if (!isSoftLogin) {
            this.getNotifications();
        }
    }

    getNotifications = async () => {
        const { fetchMenuNotifications, isMobile } = this.props;
        this.setState({
            loading: true,
        });

        const payload = {
            limit: isMobile ? 5 : 10,
        };
        this.fetchCompanyDetails();
        try {
            const response = await fetchMenuNotifications(payload);
            if (response) {
                const {
                    totalRecords: total,
                    totalUnread,
                    totalUnseen,
                    events: notifications,
                } = response;
                this.setState({
                    loading: false,
                    notifications,
                    total,
                    totalUnread,
                    totalUnseen,
                });
            } else {
                throw response;
            }
        } catch (error) {
            this.setState({
                errorMessage: MESSAGES.NOTIFICATIONS.ERROR_MESSAGE,
                hasError: true,
                loading: false,
                notifications: [],
                total: null,
                totalUnread: null,
                totalUnseen: null,
            });
        }
    };

    fetchCompanyDetails = async () => {
        const { fetchCompany, currentCompanyIdByLoggedUser } = this.props;
        const response = await fetchCompany(currentCompanyIdByLoggedUser);
        const welcomeInsightDelivered = get(response, 'welcomeInsightDelivered', '');
        this.setState({
            welcomeInsightStatusFromNotification: welcomeInsightDelivered,
        });
    };

    onClickNotificationIcon = () => {
        const { isSoftLogin, openLoginPrompt } = this.props;
        const { isMenuOpen } = this.state;

        if (isSoftLogin) {
            openLoginPrompt();
        } else {
            this.setState({
                isMenuOpen: !isMenuOpen,
            });
            this.onNotificationMenuOpen(!isMenuOpen);
        }
    };

    onClickNotification = notification => {
        const {
            updateNotifications,
            currentUser,
            updateWelcomeInsightsStatus,
            welcomeInsightStatus,
        } = this.props;
        const { welcomeInsightStatusFromNotification } = this.state;
        const payload = {
            notificationId: notification.notificationId,
            userId: currentUser.userId,
            status: STATUS.READ,
        };
        let url;
        const { additionalInfo, entityType, notificationType, timestamp } = notification;
        const redirectUrl = get(additionalInfo, 'redirectUrl', '/dashboard');
        const isWelcomeInsights = get(additionalInfo, 'isWelcomeInsights', false);
        if (entityType === NOTIFICATION_TYPES.COMMUNICATION) {
            url = '/admin/communication/upcoming-communications';
        } else if (redirectUrl.includes('my_handbooks')) {
            url = '/company-policies/handbooks-express';
        } else if (notificationType === NOTIFICATION_TYPES.INSIGHTS) {
            const updateUrl = redirectUrl.replace(
                'mineral-intelligence',
                'mineral-intelligence/insights'
            );
            if (isWelcomeInsights) {
                const userWelcomeInsightStatus = get(welcomeInsightStatus, 'welcomeInsightStatus');
                if (userWelcomeInsightStatus !== MNL_INTELLIGENCE.WELCOME_INSIGHTS_VIEWED_STATUS) {
                    updateWelcomeInsightsStatus({
                        welcomeInsightStatus: MNL_INTELLIGENCE.WELCOME_INSIGHTS_DELIVERED_STATUS,
                    });
                }
            }
            url = updateUrl.replace('?version=v3', '/details?version=v3');
        } else {
            url = redirectUrl;
        }

        const { sendPendoEvent } = this.props;
        const pendoEvent = EVENTS.NOTIFICATION.BELL_ICON_VIEW_ENTITY_SELECT;
        pendoEvent.eventProperties.entityId = notification.notificationId;
        pendoEvent.eventProperties.entityType = notification.entityType;
        pendoEvent.eventProperties.generatedTimestamp = formatDate(timestamp, 'MMMM DD, YYYY');
        sendPendoEvent(pendoEvent);

        this.setState({ isMenuOpen: false });
        updateNotifications(payload);
        const { history } = this.props;
        if (redirectUrl.includes('my_handbooks')) {
            history.push(`${url}`, {
                pathName: `${redirectUrl}`,
            });
        } else if (isWelcomeInsights) {
            history.push(`${url}`, { welcomeInsightStatusFromNotification });
        } else {
            history.push(`${url}`);
        }
    };

    onClickViewMore = e => {
        const { history, sendPendoEvent } = this.props;
        this.setState({ isMenuOpen: false });
        const isMobile = renderCheck('md', 'less');
        if (!isMobile) {
            e.stopPropagation();
        }

        const pendoEvent = EVENTS.NOTIFICATION.BELL_ICON_VIEW_ALL_SELECT;
        sendPendoEvent(pendoEvent);

        history.push(`/notifications`);
    };

    hideNotificationMenu = () => {
        this.setState({ isMenuOpen: false });
    };

    showNotificationMenu = () => {
        const { isMenuOpen } = this.state;
        const { isSoftLogin } = this.props;
        if (!isSoftLogin) {
            this.setState({ isMenuOpen: true });
            this.onNotificationMenuOpen(!isMenuOpen);
        }
    };

    onClickDeleteAll = e => {
        const { openModal, closeModal, sendPendoEvent } = this.props;
        const { total } = this.state;
        const isMobile = renderCheck('md', 'less');
        if (!isMobile) {
            e.stopPropagation();
        }

        let pendoEvent = EVENTS.NOTIFICATION.BELL_ICON_DELETE_ALL_SELECT;
        sendPendoEvent(pendoEvent);

        openModal(MODALS.CONFIRM, {
            description: `${MESSAGES.NOTIFICATIONS.DELETE_CONFIRMATION_MESSAGE} ${total} notifications?`,
            hideIcon: true,
            actions: [
                {
                    text: 'Cancel',
                    border: '1px',
                    color: 'cancelButton',
                    onClick: () => {
                        pendoEvent = EVENTS.NOTIFICATION.BELL_ICON_DELETE_ALL_CANCEL;
                        sendPendoEvent(pendoEvent);

                        closeModal(MODALS.CONFIRM);
                    },
                },
                {
                    text: 'Delete All',
                    color: 'primary',
                    onClick: () => {
                        pendoEvent = EVENTS.NOTIFICATION.BELL_ICON_DELETE_ALL_CONFIRM;
                        sendPendoEvent(pendoEvent);

                        this.handleDeleteAll();
                        closeModal(MODALS.CONFIRM);
                    },
                },
            ],
        });
    };

    getNotificationId = row => {
        return get(row, 'notificationId');
    };

    handleDeleteAll = async () => {
        const { deleteNotifications, currentUser, isLoading } = this.props;
        isLoading(true, MESSAGES.LOADING.DEFAULT);
        const payload = {
            userId: currentUser.userId,
        };

        try {
            await deleteNotifications(payload);
            await this.getNotifications();
        } catch (e) {
            this.setState({
                errorMessage: MESSAGES.NOTIFICATIONS.ERROR_MESSAGE,
                hasError: true,
                loading: false,
                notifications: [],
                total: null,
                totalUnread: null,
                totalUnseen: null,
            });
        } finally {
            isLoading(false);
        }
    };

    onDeleteNotification = async notification => {
        const { updateNotifications, currentUser, isLoading } = this.props;
        const payload = {
            notificationId: notification.notificationId,
            userId: currentUser.userId,
            status: STATUS.DELETE,
        };
        isLoading(true, MESSAGES.LOADING.DEFAULT);
        try {
            await updateNotifications(payload);
            await this.getNotifications();
        } catch (e) {
            this.setState({
                errorMessage: MESSAGES.NOTIFICATIONS.ERROR_MESSAGE,
                hasError: true,
                loading: false,
                notifications: [],
                total: null,
                totalUnread: null,
                totalUnseen: null,
            });
        } finally {
            isLoading(false);
        }
    };

    onNotificationMenuOpen = isMenuOpen => {
        const { totalUnseen } = this.state;
        const { updateNotifications, currentUser, sendPendoEvent } = this.props;
        const payload = {
            userId: currentUser.userId,
        };
        const pendoEvent = EVENTS.NAVIGATION.SELECT;
        pendoEvent.eventProperties.selection = `Notification bell icon`;
        sendPendoEvent(pendoEvent);
        if (totalUnseen) {
            updateNotifications(payload);
            this.setState({
                totalUnseen: null,
            });
        } else if (isMenuOpen) {
            this.getNotifications();
        }
    };

    render() {
        const {
            errorMessage,
            hasError,
            isMenuOpen,
            loading,
            notifications,
            total,
            totalUnread,
            totalUnseen,
        } = this.state;

        const { history } = this.props;

        return (
            <>
                <Responsive size="md" value="greater">
                    <NotificationsMenu
                        iconRef={this.notificationIconRef}
                        menuRef={this.notificationMenuRef}
                        errorMessage={errorMessage}
                        hasError={hasError}
                        open={isMenuOpen}
                        loading={loading}
                        hideNotificationMenu={this.hideNotificationMenu}
                        showNotificationMenu={this.showNotificationMenu}
                        onClickNotification={this.onClickNotification}
                        onClickDeleteAll={this.onClickDeleteAll}
                        onClickNotificationIcon={_debounce(this.onClickNotificationIcon, 500)}
                        onDeleteNotification={this.onDeleteNotification}
                        onViewMore={this.onClickViewMore}
                        noNotificationsMessage="No new Notifications"
                        notifications={notifications}
                        total={total}
                        totalUnread={totalUnread}
                        totalUnseen={totalUnseen}
                        history={history}
                    />
                </Responsive>
                <Responsive size="md" value="less">
                    <MobileNotificationsMenu
                        iconRef={this.notificationIconRef}
                        menuRef={this.notificationMenuRef}
                        errorMessage={errorMessage}
                        hasError={hasError}
                        open={isMenuOpen}
                        loading={loading}
                        hideNotificationMenu={this.hideNotificationMenu}
                        onClickNotification={this.onClickNotification}
                        onClickDeleteAll={this.onClickDeleteAll}
                        onClickNotificationIcon={_debounce(this.onClickNotificationIcon, 500)}
                        onDeleteNotification={this.onDeleteNotification}
                        onViewMore={this.onClickViewMore}
                        noNotificationsMessage="No new Notifications"
                        notifications={notifications}
                        total={total}
                        totalUnread={totalUnread}
                        totalUnseen={totalUnseen}
                        history={history}
                    />
                </Responsive>
            </>
        );
    }
}

NotificationWidget.propTypes = {
    fetchMenuNotifications: func.isRequired,
    updateNotifications: func.isRequired,
    isLoading: func.isRequired,
    deleteNotifications: func.isRequired,
    history: object.isRequired,
    openModal: func.isRequired,
    currentUser: object,
    closeModal: func.isRequired,
    isMobile: bool,
    isSoftLogin: bool,
    openLoginPrompt: func,
    sendPendoEvent: func.isRequired,
    updateWelcomeInsightsStatus: func.isRequired,
    welcomeInsightStatus: object.isRequired,
    fetchCompany: func.isRequired,
    currentCompanyIdByLoggedUser: number.isRequired,
};

NotificationWidget.defaultProps = {
    currentUser: {},
    isMobile: false,
    isSoftLogin: false,
    openLoginPrompt: () => {},
};

const mapStateToProps = createStructuredSelector({
    currentUser: userSelectors.getUserInfo,
    welcomeInsightStatus: userSelectors.getWelcomeInsightsStatus,
    currentCompanyIdByLoggedUser: userSelectors.getCompanyIdFromUserInfo,
});

const mapDispatchToProps = {
    fetchMenuNotifications: notificationsActions.fetchMenuNotifications,
    updateNotifications: notificationsActions.updateNotifications,
    deleteNotifications: notificationsActions.deleteNotifications,
    closeModal: uiActions.closeModal,
    openModal: uiActions.openModal,
    isLoading: uiActions.isLoading,
    sendPendoEvent: pendoActions.sendPendoEvent,
    updateWelcomeInsightsStatus: userActions.updateWelcomeInsightsStatus,
    fetchCompany: adminActions.fetchCompany,
};

export { NotificationWidget as NotificationWidgetWrapped };
export default withPublicContent(connect(mapStateToProps, mapDispatchToProps)(NotificationWidget));
