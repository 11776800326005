import { any, bool, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { MODALS } from 'constants/modals';
import { LOCATION } from 'constants/proptypes';
import { selectors as authSelectors } from 'modules/auth';
import { actions as uiActions } from 'modules/ui';
import { actions as brandingActions } from 'modules/branding';
import MESSAGES from 'constants/messages';

const PublicContentContext = React.createContext([{}, () => {}]);

const PublicContent = props => {
    const {
        children,
        fetchBranding,
        location: { pathname, search },
        openModal,
        loggedIn,
    } = props;
    const SOFT_LOGIN_ROUTES = ['/public/content/details'];

    const queryParams = queryString.parse(search) || {};
    const domain = MAESTER.companies.root_company_id;
    const { token: softLoginToken, partnerId = domain, partnerLoginUrl } = queryParams;
    const isSoftLoginRoute = Boolean(SOFT_LOGIN_ROUTES.find(route => pathname.includes(route)));

    const [isSoftLogin, setSoftLoginStatus] = useState(false);

    useEffect(() => {
        if (isSoftLoginRoute && !loggedIn) {
            fetchBranding(partnerId);
        }
    }, [fetchBranding, isSoftLoginRoute, loggedIn, partnerId]);

    useEffect(() => {
        if (loggedIn) {
            setSoftLoginStatus(false);
        } else {
            setSoftLoginStatus(isSoftLoginRoute);
        }
    }, [SOFT_LOGIN_ROUTES, isSoftLoginRoute, loggedIn, pathname]);

    const contextValue = {
        isSoftLogin,
        openLoginPrompt: (data = {}) => {
            const { redirectUrl, redirectState } = data;

            // If a partnerLoginUrl is available in query-params,
            // then user need to be redirected to the partner login page
            // instead of opening the login modal.
            if (partnerLoginUrl) {
                openModal(MODALS.CONFIRM, {
                    title: MESSAGES.MODAL.REDIRECT_TO_PARTNER_LOGIN.TITLE,
                    description: MESSAGES.MODAL.REDIRECT_TO_PARTNER_LOGIN.DESCRIPTION,
                    onSuccess: () => {
                        window.location.href = partnerLoginUrl;
                    },
                });
            } else {
                openModal(MODALS.LOGIN, { redirectUrl, redirectState });
            }
        },
        softLoginToken,
    };

    return (
        <PublicContentContext.Provider value={contextValue}>
            {children}
        </PublicContentContext.Provider>
    );
};

PublicContent.propTypes = {
    children: any.isRequired,
    location: LOCATION.isRequired,
    openModal: func.isRequired,
    fetchBranding: func.isRequired,
    loggedIn: bool.isRequired,
};

const mapDispatchToProps = {
    openModal: uiActions.openModal,
    fetchBranding: brandingActions.fetchBranding,
};

const mapStateToProps = createStructuredSelector({
    loggedIn: authSelectors.isUserLoggedIn,
});

const PublicContentProvider = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PublicContent)
);

export { PublicContentContext, PublicContentProvider };
