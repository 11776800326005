import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const PaginationNextIcon = ({
    setRef,
    height,
    viewBox,
    width,
    fill,
    fillInner,
    disabled,
    ...rest
}) => (
    <SvgIcon
        {...rest}
        ref={setRef}
        width={width}
        height={height}
        viewBox={viewBox}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m12.643 7.72 3.636 3.637.006.006c.16.164.26.387.26.634v.006a.906.906 0 0 1-.26.634l-.006.006-3.636 3.636a.91.91 0 1 1-1.286-1.286l2.085-2.084H8.364a.91.91 0 1 1 0-1.818h5.078l-2.085-2.085a.91.91 0 0 1 1.286-1.285z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm10-8.182a8.182 8.182 0 1 0 0 16.364 8.182 8.182 0 0 0 0-16.364z"
            fill={fill}
        />
    </SvgIcon>
);

PaginationNextIcon.propTypes = {
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fill: PropTypes.string,
    fillInner: PropTypes.string,
    disabled: PropTypes.bool,
};

PaginationNextIcon.defaultProps = {
    setRef: null,
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    fill: 'none',
    fillInner: 'none',
    disabled: false,
};

export default PaginationNextIcon;
