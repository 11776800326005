import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    fetchThreadDocuments,
    fetchInsightsDocuments,
    fetchPreviewDocument,
    fetchInsightsList,
    fetchInsightsPreviewDashboard,
    fetchInsightsGraphDashboard,
    updateInsightsNewTag,
    fetchAllRecommendationsAPI,
    fetchRecommendationByIdAPI,
    fetchRecommendationsByStateAPI,
    fetchRecommendationDetailsAPI,
    fetchRecommendation,
} from 'modules/apis';

import * as threadActions from '../actions';

/**
 * Saga to fetch thread documents by threadId
 *
 * @method fetchThreadDocumentsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchThreadDocumentsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchThreadDocuments, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchThreadDocuments.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchThreadDocuments.error(error));
        return error;
    }
}

/**
 * Saga to fetch insights documents by threadId
 *
 * @method fetchInsightsDocumentsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchInsightsDocumentsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchInsightsDocuments, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchInsightsDocuments.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchInsightsDocuments.error(error));
        return error;
    }
}

/**
 * Saga to update the new tag information for the Insights
 *
 * @method updateInsightsNewTagSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* updateInsightsNewTagSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateInsightsNewTag, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.updateInsightsNewTag.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.updateInsightsNewTag.error(error));
        return error;
    }
}

/**
 * Saga to fetch documents by entryId for preview
 *
 * @method fetchPreviewDocumentSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchPreviewDocumentSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchPreviewDocument, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchPreviewDocument.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchPreviewDocument.error(error));
        return error;
    }
}

/**
 * Saga to fetch insights list data for a company
 *
 * @method fetchInsightsListSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchInsightsListSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchInsightsList, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchInsightsList.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchInsightsList.error(error));
        return error;
    }
}

/**
 * Saga to fetch insights preview data
 *
 * @method fetchInsightsPreviewDashboardSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchInsightsPreviewDashboardSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchInsightsPreviewDashboard, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchInsightsPreviewDashboard.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchInsightsPreviewDashboard.error(error));
        return error;
    }
}

/**
 * Saga to fetch insights graph data
 *
 * @method fetchInsightsGraphDashboardSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchInsightsGraphDashboardSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchInsightsGraphDashboard, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchInsightsGraphDashboard.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchInsightsGraphDashboard.error(error));
        return error;
    }
}

/**
 * Saga to fetch All Recommendations
 *
 * @method fetchAllRecommendationsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchAllRecommendationsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchAllRecommendationsAPI, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchAllRecommendations.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchAllRecommendations.error(error));
        return error;
    }
}

/**
 * Saga to fetch Recommendation by Id
 *
 * @method fetchRecommendationByIdSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchRecommendationByIdSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchRecommendationByIdAPI, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchRecommendationById.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchRecommendationById.error(error));
        return error;
    }
}

/**
 * Saga to fetch Recommendations by State
 *
 * @method fetchRecommendationsByStateSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchRecommendationsByStateSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchRecommendationsByStateAPI, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchRecommendationsByState.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchRecommendationsByState.error(error));
        return error;
    }
}

/**
 * Saga to fetch Recommendations Details
 *  @method fetchRecommendationDetailsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 * */

export function* fetchRecommendationDetailsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchRecommendationDetailsAPI, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchRecommendationDetails.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchRecommendationDetails.error(error));
        return error;
    }
}

/**
 * Saga to fetch Recommendation for compliance dashboard
 *  @method fetchRecommendationSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 * */

export function* fetchRecommendationSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchRecommendation, payload);
        const data = get(response, 'data', {});
        yield put(threadActions.fetchRecommendation.success(data));
        return data;
    } catch (error) {
        yield put(threadActions.fetchRecommendation.error(error));
        return error;
    }
}
