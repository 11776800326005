import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import styled from 'styled-components';

import { genID } from 'ravenjs/utils/generate';
import { getThemeProps } from 'ravenjs/utils/theme';

import AppSubNavigation from 'components/AppSubNavigation';
import { DEFAULT_COLORS } from 'constants/colors';
import MESSAGES from 'constants/messages';
import { IMPERSONATION_RESTRICTED_NAV_ITEMS } from 'constants/navigation';
import Acl, { accessControl } from 'modules/acl';

import { navItemsNotBeClickable } from 'utils/navigation';

/**
 * Generate a activeClassName for the NavLink
 *
 * @type {string}
 */
const navItemActiveClassName = genID();

const NavItemChild = styled.div`
    &::before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        height: 0;
        border-bottom: solid 0.188rem;
        border-color: ${({ selected, theme }) =>
            selected
                ? getThemeProps('NavItem.active.backgroundColor', DEFAULT_COLORS.ORANGE, { theme })
                : 'transparent'};
        transition: border-bottom 0.15s ease-in-out;
    }
`;

const NavItemStyled = styled.div.attrs(() => ({
    activeClassName: navItemActiveClassName,
}))`
    ${getThemeProps('NavItem.styles')};
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    cursor: ${({ disabled, id, itemsNotBeClickeable }) => {
        if (disabled) {
            return 'not-allowed';
        } else if (itemsNotBeClickeable.indexOf(id.replace(/ /g, '').toLowerCase()) !== -1) {
            return 'default';
        }
        return 'pointer';
    }};
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 1.1875rem;
    height: 100%;
    font-size: 0.938rem;
    text-decoration: none !important;
    white-space: nowrap;

    &:hover,
    &.active {
        &::before {
            border-color: ${({ theme }) =>
                getThemeProps('NavItem.active.backgroundColor', DEFAULT_COLORS.ORANGE, { theme })};
        }
    }
    &:hover {
        text-shadow: 0 0 1px ${DEFAULT_COLORS.WHITE};
    }
    &:first-of-type {
        padding-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
`;

const NavItem = props => {
    const {
        isImpersonatingUser,
        isSubNavVisibleOnDOM,
        onMouseEnter,
        onMouseLeave,
        onClick,
        title,
        selected,
        showSubNav,
        state,
        subnav,
        subNavScrollTop,
    } = props;

    const disabled = isImpersonatingUser && IMPERSONATION_RESTRICTED_NAV_ITEMS.includes(state);
    const itemsNotBeClickeable = navItemsNotBeClickable(Acl);

    const onClickNavItem = () => {
        onClick(title);
    };

    const navItemProps = disabled
        ? {
              title: MESSAGES.RESTRICTED_NAV_ITEM,
          }
        : {
              onMouseEnter,
              onMouseLeave,
              onClick: onClickNavItem,
          };

    return (
        <NavItemStyled
            id={title}
            disabled={disabled}
            itemsNotBeClickeable={itemsNotBeClickeable}
            {...navItemProps}
        >
            <div style={{ position: 'relative' }}>
                {title}
                <AppSubNavigation
                    isImpersonatingUser={isImpersonatingUser}
                    state={state}
                    show={showSubNav}
                    nav={props}
                    subnav={subnav}
                    isVisibleOnDOM={isSubNavVisibleOnDOM}
                    scrollTop={subNavScrollTop}
                />
                <NavItemChild selected={selected} />
            </div>
        </NavItemStyled>
    );
};

NavItem.propTypes = {
    isImpersonatingUser: bool,
    isSubNavVisibleOnDOM: bool,
    onMouseEnter: func,
    onMouseLeave: func,
    onClick: func,
    selected: bool,
    showSubNav: bool,
    state: string,
    subnav: object,
    subNavScrollTop: number,
    title: string,
};

NavItem.defaultProps = {
    isImpersonatingUser: false,
    isSubNavVisibleOnDOM: false,
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onClick: () => {},
    selected: false,
    showSubNav: false,
    state: null,
    subnav: {},
    subNavScrollTop: 0,
    title: '',
};

export default accessControl(NavItem);
