import TableCellActionsMenu from './TableCellActionsMenu';
import TableCellButton from './TableCellButton';
import TableCellButtonGroup from './TableCellButtonGroup';
import TableCellCheckbox from './TableCellCheckbox';
import TableCellDate from './TableCellDate';
import TableCellLinksList from './TableCellLinksList';
import TableCellSkus from './TableCellSkus';
import TableCellStatus from './TableCellStatus';
import TableCellSvg from './TableCellSvg';
import EditableCell from './EditableCell';
import TableCellSelectMenu from './TableCellSelectMenu';
import TableCellToggle from './TableCellToggle';

const types = {
    actionsMenu: TableCellActionsMenu,
    button: TableCellButton,
    buttonGroup: TableCellButtonGroup,
    checkbox: TableCellCheckbox,
    date: TableCellDate,
    link: TableCellLinksList,
    skus: TableCellSkus,
    status: TableCellStatus,
    svg: TableCellSvg,
    editable: EditableCell,
    select: TableCellSelectMenu,
    toggle: TableCellToggle,
};

export default types;

export {
    TableCellActionsMenu,
    TableCellButton,
    TableCellCheckbox,
    TableCellDate,
    TableCellLinksList,
    TableCellSkus,
    TableCellStatus,
    TableCellSvg,
    TableCellSelectMenu,
    TableCellToggle,
};
