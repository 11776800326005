import axios from 'axios';

/**
 * API to fetch establishments
 *
 * @method fetchEstablishmentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchEstablishmentsAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/establishment',
    });
};

/**
 * API to fetch establishment type
 *
 * @method fetchEstablishmentTypeAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchEstablishmentTypeAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/establishment-type',
    });
};

/**
 * API to fetch cities
 *
 * @method fetchCitiesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchCitiesAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/city',
    });
};

/**
 * API to fetch naics
 *
 * @method fetchNaicsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchNaicsAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/naics',
    });
};

/**
 * API to fetch cases
 *
 * @method fetchCasesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchCasesAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/case',
    });
};

/**
 * API to fetch employee names
 *
 * @method fetchEmployeeNamesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchEmployeeNamesAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/employee',
    });
};

/**
 * API to fetch illness types
 *
 * @method fetchIllnessTypesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchIllnessTypesAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/illness-type',
    });
};

/**
 * API to fetch where occurred
 *
 * @method fetchWhereOccurredAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchWhereOccurredAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/osha/event',
    });
};

/**
 * API to create establishment.
 *
 * @method createEstablishmentAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const createEstablishmentAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        url: '/tools/v1/osha/establishment',
        data: params,
    });
};

/**
 * API call to fetch info about the given establishment, via `id`.
 *
 * @method fetchEstablishmentByIdAPI
 * @param  {Object}  id The id to fetch
 * @return {Promise}
 */
export const fetchEstablishmentByIdAPI = id => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `/tools/v1/osha/establishment/${id}`,
    });
};

/**
 * API to update a establishment.
 *
 * @method updateEstablishmentAPI
 * @param  {Object}  params The establishment info
 * @return {Promise}
 */
export const updateEstablishmentAPI = params => {
    const { id } = params;
    delete params[id];
    return axios({
        type: 'apiTools',
        method: 'put',
        url: `/tools/v1/osha/establishment/${id}`,
        noBearer: true,
        data: params,
    });
};

/**
 * API call to delete establishment.
 *
 * @method deleteEstablishmentByIdAPI
 * @param  {Object}  id The id to fetch
 * @return {Promise}
 */
export const deleteEstablishmentByIdAPI = id => {
    return axios({
        type: 'apiTools',
        method: 'delete',
        noBearer: true,
        url: `/tools/v1/osha/establishment/${id}`,
    });
};

/**
 * API call to fetch body parts.
 *
 * @method fetchBodyPartsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchBodyPartsAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: `/tools/v1/osha/body-parts`,
    });
};

/**
 * API call to get case number.
 *
 * @method getCaseNumberAPI
 * @return {Promise}
 */
export const getCaseNumberAPI = () => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `/tools/v1/osha/case-number`,
    });
};

/**
 * API call to fetch case types.
 *
 * @method fetchCaseTypesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchCaseTypesAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: `/tools/v1/osha/case-type`,
    });
};

/**
 * API to create case.
 *
 * @method createCaseAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const createCaseAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        url: '/tools/v1/osha/case',
        data: params,
    });
};

/**
 * API call to fetch info about the given case, via `id`.
 *
 * @method fetchCaseByIdAPI
 * @param  {Object}  id The id to fetch
 * @return {Promise}
 */
export const fetchCaseByIdAPI = id => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `/tools/v1/osha/case/${id}`,
    });
};

/**
 * API to update a case.
 *
 * @method updateCaseAPI
 * @param  {Object}  params The establishment info
 * @return {Promise}
 */
export const updateCaseAPI = params => {
    const { id } = params;
    delete params[id];
    return axios({
        type: 'apiTools',
        method: 'put',
        url: `/tools/v1/osha/case/${id}`,
        noBearer: true,
        data: params,
    });
};

/**
 * API call to delete case.
 *
 * @method deleteCaseByIdAPI
 * @param  {Object}  id The id to fetch
 * @return {Promise}
 */
export const deleteCaseByIdAPI = id => {
    return axios({
        type: 'apiTools',
        method: 'delete',
        noBearer: true,
        url: `/tools/v1/osha/case/${id}`,
    });
};

/**
 * API call to fetch form establishments.
 *
 * @method fetchFormEstablishmentsAPI
 * @return {Promise}
 */
export const fetchFormEstablishmentsAPI = () => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `/tools/v1/osha/form-300/establishments`,
    });
};

/**
 * API call to download form 300.
 *
 * @method downloadForm300API
 * @param  {Object} params
 * @return {Promise}
 */
export const downloadForm300API = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: `/tools/v1/osha/download/form-300`,
    });
};

/**
 * API call to download form 300A.
 *
 * @method downloadForm300AAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const downloadForm300AAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: `/tools/v1/osha/download`,
    });
};

/**
 * API call to download form report.
 *
 * @method downloadFormReportAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const downloadFormReportAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: `/tools/v1/osha/report`,
    });
};
