import PropTypes from 'prop-types';
import React from 'react';
import { renderCheck } from 'ravenjs/utils/viewport';

function Responsive(props) {
    const { size, children, value } = props;
    return <>{renderCheck(size, value) && React.cloneElement(children)}</>;
}

Responsive.propTypes = {
    size: PropTypes.string,
    children: PropTypes.node,
    value: PropTypes.string,
};

Responsive.defaultProps = {
    size: 'md',
    children: null,
    value: null,
};

export default Responsive;
