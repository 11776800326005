import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ClipboardIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns="http://www.w3.org/2000/SvgIcon"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 1.16675C5.26362 1.16675 4.66667 1.7637 4.66667 2.50008L4 2.50008C3.46957 2.50008 2.96086 2.71079 2.58579 3.08587C2.21071 3.46094 2 3.96965 2 4.50008V13.8334C2 14.3638 2.21071 14.8726 2.58579 15.2476C2.96086 15.6227 3.46957 15.8334 4 15.8334H12C12.5304 15.8334 13.0391 15.6227 13.4142 15.2476C13.7893 14.8726 14 14.3638 14 13.8334V4.50008C14 3.96965 13.7893 3.46094 13.4142 3.08587C13.0391 2.71079 12.5304 2.50008 12 2.50008L11.3333 2.50008C11.3333 1.7637 10.7364 1.16675 10 1.16675H6ZM11.3333 3.83341C11.3333 4.56979 10.7364 5.16675 10 5.16675H6C5.26362 5.16675 4.66667 4.56979 4.66667 3.83341H4C3.82319 3.83341 3.65362 3.90365 3.5286 4.02868C3.40357 4.1537 3.33333 4.32327 3.33333 4.50008V13.8334C3.33333 14.0102 3.40357 14.1798 3.5286 14.3048C3.65362 14.4298 3.82319 14.5001 4 14.5001H12C12.1768 14.5001 12.3464 14.4298 12.4714 14.3048C12.5964 14.1798 12.6667 14.0102 12.6667 13.8334V4.50008C12.6667 4.32327 12.5964 4.1537 12.4714 4.02868C12.3464 3.90365 12.1768 3.83341 12 3.83341H11.3333ZM6 3.16732V3.83341H10L10 3.16675L10 2.50008H6V3.16618C6 3.16637 6 3.16656 6 3.16675C6 3.16694 6 3.16713 6 3.16732Z"
                fill="#282828"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.6665 7.16667C4.6665 6.79848 4.96498 6.5 5.33317 6.5H10.6665C11.0347 6.5 11.3332 6.79848 11.3332 7.16667C11.3332 7.53486 11.0347 7.83333 10.6665 7.83333H5.33317C4.96498 7.83333 4.6665 7.53486 4.6665 7.16667Z"
                fill="#282828"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.6665 9.83341C4.6665 9.46522 4.96498 9.16675 5.33317 9.16675H10.6665C11.0347 9.16675 11.3332 9.46522 11.3332 9.83341C11.3332 10.2016 11.0347 10.5001 10.6665 10.5001H5.33317C4.96498 10.5001 4.6665 10.2016 4.6665 9.83341Z"
                fill="#282828"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.6665 12.4999C4.6665 12.1317 4.96498 11.8333 5.33317 11.8333H9.33317C9.70136 11.8333 9.99984 12.1317 9.99984 12.4999C9.99984 12.8681 9.70136 13.1666 9.33317 13.1666H5.33317C4.96498 13.1666 4.6665 12.8681 4.6665 12.4999Z"
                fill="#282828"
            />
        </SvgIcon>
    );
};

ClipboardIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ClipboardIcon.defaultProps = {
    fill: 'none',
    height: '17',
    viewBox: '0 0 16 17',
    width: '16',
};

export default ClipboardIcon;
