export const WIZARD_BREADCRUMBS_STATUS = {
    COMPLETED: 'completed',
    CURRENT: 'current',
    ERROR: 'error',
    UPCOMING: 'upcoming',
};

export const BULK_IMPORT_PROSPECT_STEPS = ['Upload File', 'Preview Prospects', 'Add Prospects'];

export const HOME_ICON_LABEL = 'Home';
