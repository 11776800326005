/**
 * Define the redux persist storage Id
 *
 * @type {String}
 */
export const APP_PERSIST_ID = 'app_persist';

/**
 * Define the redux persist storage Id for 'auth' module
 *
 * @type {String}
 */
export const AUTH_PERSIST_ID = 'auth_persist';

/**
 * Define the redux persist storage Id for 'ui' module
 *
 * @type {String}
 */
export const UI_PERSIST_ID = 'ui_persist';

export const TICKETS_PERSIST_ID = 'tickets_persist';
