import PropTypes from 'prop-types';
import React from 'react';

import SkusTooltip from 'ravenjs/components/tooltips/SkusTooltip';
import Typography from 'ravenjs/lib/Typography';
import { get } from 'ravenjs/utils/lodash';

function TableCellSkus(props) {
    const { row } = props;
    const configName = get(row, 'configurationName', '');

    return (
        <SkusTooltip configuration={row} placement="top">
            <Typography as="span" gutterBottom="0" gutterLeft="10px">
                {configName}
            </Typography>
        </SkusTooltip>
    );
}

TableCellSkus.propTypes = {
    row: PropTypes.object,
};

TableCellSkus.defaultProps = {
    row: {},
};

export default TableCellSkus;
