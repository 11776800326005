import { HANDBOOK_TYPE } from 'constants/handbooks';

export const CLICK_TO_BUY = {
    SEHP: {
        TITLE: 'Select a Handbook Type',
        SUB_TITLE: 'What type of handbook do you want to build for ${companyName}?',
        EMPLOYEE_COUNT: {
            MIN_LIMIT: 0,
            MAX_LIMIT: 1000,
        },
        SEH_PLUS_SKU_KEYS: [
            'handbooks.spanish',
            'handbookslocalpolicies',
            'handbooksmultistate',
            'esignature',
        ],
        SEH_SKU_LIST: {
            [HANDBOOK_TYPE.ONE_STATE]: [
                {
                    id: 1,
                    name: 'Federal Compliance',
                },
                {
                    id: 2,
                    name: 'Compliance for One State',
                },
                {
                    id: 3,
                    name: 'Manage Employee E-Signatures',
                },
                {
                    id: 4,
                    name: 'Spanish Translation',
                },
                {
                    id: 5,
                    name: 'Select Local Policies',
                },
            ],
            [HANDBOOK_TYPE.MULTI_STATE]: [
                {
                    id: 1,
                    name: 'Federal Compliance',
                },
                {
                    id: 2,
                    name: 'Multi-State Compliance',
                },
                {
                    id: 3,
                    name: 'Manage Employee E-Signatures',
                },
                {
                    id: 4,
                    name: 'Spanish Translation',
                },
                {
                    id: 5,
                    name: 'Select Local Policies',
                },
            ],
        },
        BASIC_HANDBOOK: {
            TITLE: 'Single-State Handbook',
            DISCLAIMER_TEXT: 'Build a handbook for your company and one state.',
            NOTE: 'No Additional Fee',
            PENDO_ID: 'IPFM-Proceed_with_Single-State',
        },
        SMART_HANDBOOK: {
            TITLE: 'Smart Employee Handbook Plus',
            DISCLAIMER_TEXT:
                'Build a handbook for your company and all states with at least one company location.',
            NOTE: '<b>Pricing is based on company size.</b>',
            PENDO_ID: 'IPFM-Proceed_with_Multi-State',
            QUOTE_KEY: 'MIN - SMAR - EHP - Click to Buy',
            TERMS_IN_MONTHS: 12,
        },
        VIDEO_PANEL: {
            TITLE: 'Making the Case for Smart Employee Handbook Plus',
            SUB_TITLE:
                '<a href="https://lp.trustmineral.com/mineral-smart-employee-handbook-plus-resources-page/"> View our resource page </a> to help convince your organization with talking points, testimonials, FAQs, and more.',
            LINK: 'https://play.goconsensus.com/uf0f599f1?autoplay',
            SCROLL_TO_TOP_TEXT: 'Compare Employee Handbook Options',
        },
        BUTTONS: {
            LEARN_MORE: 'Upgrade Now',
            SELECT_BASIC: 'Proceed with Single-State',
            CLOSE: 'Close',
            PROCEED: 'Proceed',
        },
        PAYMENT_CONFIRMATION: {
            TITLE: "We're Setting Up Your Upgrade...",
            SUCCESS_TITLE: 'Upgrade Complete!',
            WAITING_MESSAGE: 'Smart Employee Handbook Plus',
            DESCRIPTION_1:
                "We're working in the background to set up your system. This may take a few <br> moments.",
            DESCRIPTION_2: "We'll notify you when your Smart Employee Handbook Plus is ready.",
            DESCRIPTION_3:
                'In the meantime, here are some useful resources to help you get started.',
            DESCRIPTION_4:
                'Click the Create Multi-State Handbook button below to use the new <br> Smart Employee Handbook Plus feature.',
            SUB_TITLE: 'Quick Reference Guide',
            SUCCESS: {
                TITLE: 'Thank you for your purchase!',
                SUB_TITLE_1: 'Your confirmation number is #${invoiceID}.',
                SUB_TITLE_2: 'Your are now ready to create a Smart Employee Handbook.',
            },
            FAILURE: {
                TITLE: 'Something went wrong.',
                SUB_TITLE_1:
                    'Your payment has been processed, but we\'re experiencing a minor error. Our team has been notified and you\'ll receive confirmation when your access to Smart Employee Handbook Plus is ready. <b>To avoid duplicate charges, please do not submit any additional payments</b>.<br><br>Got questions or not seeing a confirmation email? Please contact Mineral Technical Support at <a href="mailto:support@trustmineral.com"> support@trustmineral.com.',
            },
            RETRY: {
                MAX_ATTEMPT: 5,
                MAX_TIMEOUT: 4000,
            },
            BUTTONS: {
                CONTINUE: 'Continue',
                SYSTEM_UPDATE: 'System Updating...',
                CREATE_MULTI_STATE: 'Create Multi-State Handbook',
                DOWNLOAD_LINK: 'Getting Started with Smart Employee Handbook',
            },
            DOWNLOAD_URL:
                'https://assets.ctfassets.net/548jxf0ptn0a/5Lxn0yhSdmRh9h1hDS1Qj0/1c15bab25fb1210fe622f5db9b2e1506/Handbooks_PLUS_Getting_Started_Client_220521.pdf',
        },
        SALESFORCE_C2B_PRODUCT_IDS: {
            ALPHA: '01t5a00000IFrEWAA1',
            DEV: '01t5a00000IFrEWAA1',
            PREPROD: '01t5a00000IFrEWAA1',
            PROD: '01t5a00000IFrEWAA1',
            QA: '01t5a00000IFrEWAA1',
        },
        LEADS_INFO: {
            AD_TYPE: 'ClickToBuy',
            OPTY_NAME: 'Smart Employee Handbook Plus',
            PENDO_ID: '',
            SOURCE: 'CreateHandbook',
        },
    },
    WHP: {
        SECTION_1: {
            TITLE: 'Upgrade to Workplace Harassment Prevention',
            DESCRIPTION:
                'Expand your Learn course catalog with interactive, harassment prevention training for your workforce. Workplace Harassment Prevention includes <b>19 courses</b> with interactive, state-mandated topics to ensure compliance. Plus, all courses are added to Learn, making it easy to assign and manage employee training from a single place.',
        },
        SECTION_2: {
            TITLE: 'Why Workplace Harassment Prevention?',
            DESCRIPTION:
                "Even organizations with the strongest workplace culture can experience incidents of harassment. And the average harassment claim settled outside of court can run an organization anywhere from $75,000 to $125,000. That's why it's so important to take proactive steps against workplace harassment with employee training.",
            BENEFIT_LIST: [
                'Ensure compliance with state-mandated training courses - in CA, CT, DE, IL, ME, NY',
                'Offer training courses for managers and employees',
                'Build an inclusive workplace culture with Diversity, Equity, and Inclusion courses',
                'Easily assign courses and track completions',
                'Train employees in Spanish and English',
            ],
        },
        SECTION_3: {
            TITLE: 'Start Using Workplace Harassment Prevention',
            DESCRIPTION:
                'Protect your people - and your organization - with harassment prevention training.',
        },
        WHP_SKU_KEYS: ['learnpackagenysharassment'],
        COMMON: {
            BUTTONS: {
                BACK: 'back',
                CLOSE: 'Close',
                GET_PRICING: 'Get Pricing',
                VIEW_COURSE_CATALOG: 'View Course Catalog',
            },
            NOTE: 'Pricing is based on company size. ',
            IMAGE_TAG: 'Mineral™',
        },
        PAYMENT_CONFIRMATION: {
            TITLE: "We're Setting Up Your Upgrade...",
            SUCCESS_TITLE: 'View Courses in Learn',
            WAITING_MESSAGE: 'Workplace Harassment Prevention',
            DESCRIPTION_1:
                "We're working in the background to set up your system. This may take a few <br> moments.",
            DESCRIPTION_2:
                "We'll notify you when your Workplace Harassment Prevention training courses are ready.",
            DESCRIPTION_3:
                'In the meantime, here are some useful resources to help you get started.',
            DESCRIPTION_4:
                'Click the button below to use the new <br> Workplace Harassment Prevention feature.',
            SUB_TITLE: '',
            SUCCESS: {
                TITLE: 'Thank you for your purchase!',
                SUB_TITLE_1: 'Your confirmation number is #${invoiceID}.',
                SUB_TITLE_2: 'Your are now ready to create a Workplace Harassment Prevention.',
            },
            FAILURE: {
                TITLE: 'Something went wrong.',
                SUB_TITLE_1:
                    'Your payment has been processed, but we\'re experiencing a minor error. Our team has been notified and you\'ll receive confirmation when your access to Workplace Harassment Prevention training courses are ready. <b>To avoid duplicate charges, please do not submit any additional payments</b>.<br><br>Got questions or not seeing a confirmation email? Please contact Mineral Technical Support at <a href="mailto:support@trustmineral.com"> support@trustmineral.com.',
            },
            RETRY: {
                MAX_ATTEMPT: 5,
                MAX_TIMEOUT: 4000,
            },
            BUTTONS: {
                CONTINUE: 'Continue',
                SYSTEM_UPDATE: 'System Updating...',
                CREATE_MULTI_STATE: 'View Workplace Harassment Prevention Courses',
                DOWNLOAD_LINK: 'Getting Started with Workplace Harassment Prevention',
            },
            DOWNLOAD_URL: 'https://apps.trustmineral.com/help-center/training',
        },
        VIDEO_PANEL: {
            LINK: 'https://play.goconsensus.com/s5c963df1',
        },
        WHP_UPGRADE: {
            QUOTE_KEY: 'MIN - WKPH - USA',
            TERMS_IN_MONTHS: 12,
        },
        SALESFORCE_WHP_PRODUCT_IDS: {
            ALPHA: '01t5a00000Hz2hAAAR',
            DEV: '01t5a00000Hz2hAAAR',
            PREPROD: '01t5a00000Hz2hAAAR',
            PROD: '01t5a00000Hz2hAAAR',
            QA: '01t5a00000Hz2hAAAR',
        },
        LEADS_INFO: {
            AD_TYPE: 'h-banner',
            OPTY_NAME: 'LearnBundle-1_MNL',
            PENDO_ID: '#submitGuide',
            SOURCE: 'Learn-Course-Catalog-Top',
        },
        BREADCRUMBS: {
            TRAINING_LABEL: 'Training',
            PAGE_LABEL: 'Upgrade to Workplace Harassment Prevention',
        },
        WHP_PAGES: {
            COMPARISON_PAGE: 'COMPARISON_PAGE',
            EMPLOYEE_COUNT_PAGE: 'EMPLOYEE_COUNT_PAGE',
            NONE: 'NONE',
            PRICING_PAGE: 'PRICING_PAGE',
            TESTIMONIAL_PAGE: 'TESTIMONIAL_PAGE',
        },
        MODAL: {
            PURCHASE_CONFIRMATION_DETAILS_MODAL: {
                TITLE: 'Newly Added: Workplace Harassment Prevention courses',
                DESCRIPTION_1: 'Click the ',
                DESCRIPTION_2: '"Harassment"',
                DESCRIPTION_3: 'icon',
                DESCRIPTION_4:
                    'to get started with your new Workplace Harassment Prevention catalog.',
                DESCRIPTION_5:
                    'Meet your harassment compliance requirements with 45 training courses, now available and ready to assign to employees in your Learn dashboard.',
                DESCRIPTION_6:
                    'Need a quick overview on what\'s included with Workplace Harassment Prevention? <a href="https://trustmineral.com/solutions/learn-course-catalogs/#whp">View the catalog here.</a>',
                GOT_IT: 'Got it',
                GOT_IT_1: 'Got it!',
            },
        },
    },
    COMMON: {
        TYPES: {
            SEH_PLUS: 'SEH_PLUS',
            WHP: 'WHP',
        },
        EMPLOYEE_COUNT_VERIFICATION: {
            TITLE: 'Please Verify Your Total Employee Count',
            SUB_NAV_TITLE: 'Employee Count Verification',
            INSTRUCTION_1: {
                SEH_PLUS:
                    'An accurate employee count is critical when generating a valid employee handbook. Our records indicate that you have ${totalEmpCountText}. Is this information correct?',
                WHP:
                    'Please provide an accurate total employee count to move forward with the Workplace Harassment Prevention upgrade. Our records indicate that you have ${totalEmpCountText}. Is this information correct?',
            },
            INSTRUCTION_2: 'Please enter the total number of employees at ${companyName}.',
            TEXT_FIELD: 'Total Employee Count',
            SUFFIX: {
                TOTAL_EMPLOYEE: 'total employee',
                EMPLOYEE: 'employee',
                STATE: 'state',
            },
            BUTTONS: {
                CANCEL: 'Cancel',
                VERIFY: 'Verify',
            },
            ERROR: '*Employee count must be between 1 - 500000',
            ZERO_EMPLOYEE_ERROR: '*Number of employees cannot be 0',
            ZERO_EMPLOYEE: 0,
            MAX_EMPLOYEE_COUNT: 500000,
        },
        CONFIRMATION_MODAL: {
            WARING_MESSAGE: 'Something Went Wrong',
            DESCRIPTION_1:
                "We're sorry, a system error has occurred while trying to verify your employee count. We've notified our team of the issue. A representative will contact you within 48 hours for any questions and to complete your upgrade.",
            DESCRIPTION_1_1:
                "We're sorry, a system error has occurred. We've notified our team of the issue. A representative will contact you within 48 hours for any questions and to complete your upgrade.",
            BUTTONS: {
                CLOSE: 'Close',
            },
        },
        PRICING_CONFIRMATION: {
            TITLE: {
                SEH_PLUS: 'Smart Employee Handbook Plus Pricing',
                WHP: 'Workplace Harassment Prevention Pricing',
            },
            UNKNOWN_PRICING: 'Pricing is based on company size.',
            INSTRUCTION_1: '$${pricing} for a ${termInMonths}-month license.',
            INSTRUCTION_2: 'Upgrade pricing is based on a company size of ${totalEmpCountText}.',
            SUFFIX: {
                EMPLOYEE: 'employee',
            },
            SUB_TITLE: {
                SEH_PLUS: 'Upgrade Includes:',
                WHP: 'With Workplace Harassment Prevention training, you can:',
            },
            BENEFIT_LIST: {
                SEH_PLUS: [
                    'Federal Compliance',
                    'Multi-State Compliance',
                    'Manage Employee E-Signatures',
                    'Spanish Translation',
                    'Select Local Policies',
                ],
                WHP: [
                    'Ensure compliance with state-mandated training courses - in CA, CT, DE, IL, ME, NY',
                    'Offer training courses for managers and employees',
                    'Build an inclusive workplace culture with Diversity, Equity, and Inclusion courses',
                    'Easily assign courses and track completions',
                    'Train employees in Spanish and English',
                ],
            },

            BUTTONS: {
                PROCEED_TO_CHECKOUT: 'Proceed to Checkout',
                CANCEL: 'Cancel',
            },
        },
        TESTIMONIAL_PAGE: {
            TITLE:
                'Please wait while we process your upgrade. This process may take up to a minute.',
            CONTENTS: {
                SEH_PLUS: [
                    {
                        ID: '1',
                        CONTENT:
                            '<p>"<b>Smart Handbook Employee Plus</b> is user-friendly and allows me to readily include my own customized policies as well as making modifications as needed."</p>',
                        NAME: 'Lynne M. Kelly, PHR',
                        DESIGNATION: 'Human Resources Manager, Mainely Tubs',
                    },
                    {
                        ID: '2',
                        CONTENT:
                            '<p>"The <b>Smart Employee Handbook</b> tool is best in class. Having Mineral feels like we have an HR expert in-house to answer our questions and keep our policies and handbooks up-to-date and in compliance."</p>',
                        NAME: 'Kitty Richardson',
                        DESIGNATION: 'Operations Manager, Texoma Health Foundation',
                    },
                    {
                        ID: '3',
                        CONTENT: `<p>"The <b>Smart Employee Handbook</b> builder is by far the best I\'ve seen for a Handbook tool, with easy to find answers, templates, forms, policies, and more."</p>`,
                        NAME: 'Marlo Saunders',
                        DESIGNATION: 'VP of Human Resources, Mayroad',
                    },
                    {
                        ID: '4',
                        CONTENT: `<P>"The <b>Smart Employee Handbook</b> builder in the platform is easy to use and manage. The regulations are constantly updated, so I have peace of mind knowing my handbook is accurate."</p>`,
                        NAME: 'Geraldine Flatt',
                        DESIGNATION: 'Director, Sonoma Creamery LLC',
                    },
                ],
                WHP: [
                    {
                        ID: '1',
                        CONTENT:
                            "<p>\"Whenever there's a requirement update in California, the <b>Workplace Harassment Prevention</b> courses are ready to address the updates, even before I'm aware there's a change to regulations.\"</p>",
                        NAME: 'Shannon Kirkman',
                        DESIGNATION: 'Director of Human Resources, ProSciento',
                    },
                    {
                        ID: '2',
                        CONTENT:
                            '<p>"By using <b>Workplace Harassment Prevention</b>, we\'ve been able to provide effective harassment and diversity training for employees, meet state training mandates, and reduce training costs. Mineral is so useful, I use it every day!"</p>',
                        NAME: 'Alice Barnhart',
                        DESIGNATION: 'HR Manager, WBE Traffic Control',
                    },
                    {
                        ID: '3',
                        CONTENT:
                            '<p>"Mineral is a huge resource. I really appreciate the state regulatory updates and compliance training tracking, reminders, and vast options of HR and workplace safety training available."</p>',
                        NAME: 'Heather Wollenburg',
                        DESIGNATION: 'Consultant, ERRG Inc.',
                    },
                ],
            },
            TIMEOUT_MS: 5000,
        },
    },
};
