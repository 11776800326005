export const MOVE_TOOL = {
    PATH: '/admin/bulk-change-tool/',
    TITLE: 'Dashboard',
    DESCRIPTION:
        'Summary of all the transactions added to this tool. This list is shared by everyone.',
    SUCCESS_SPRINT_BATCH: 'Process was executed successfully.',
    TITLES: {
        MERGE: 'Merge',
        MOVE: 'Move',
        MASS_DATA_UPDATE: 'Mass Data Update',
    },
    MERGE_MOVE_SINGLE_COMPANY: {
        label: 'Single Company',
        value: 'Single Company',
        key: 'single-company',
        TEXT_1:
            'Here you can ${type} two companies. Please select the companies that you want to ${type}.',
        TEXT_2: 'You need to select the source and target companies first.',
        DESCRIPTION_LIST: 'Please select the options below',
        STEPS_LABEL: 'Step ${step} of 3',
    },
    MERGE_PARTNERS: {
        label: 'Partners',
        value: 'Partners',
        key: 'partners',
        DESCRIPTION:
            'Here you can merge two partners. Please select the partners that you want to merge.',
        DESCRIPTION_LIST: 'Please select the options below',
        STEPS_LABEL: 'Step ${step} of 4',
        TABLE_LABEL: {
            COMPANY: 'Total companies to move',
            MOVE: 'Total user(s) to move',
            MERGE: 'Total user(s) to merge',
        },
    },
    MERGE_MULTIPLE_COMPANIES: {
        label: 'Multiple Companies',
        value: 'Multiple Companies',
        key: 'multiple-companies',
        TITLE: 'Multiple Companies',
        UPLOAD_LABEL: 'Upload the companies you want to ${type}',
        DESCRIPTION:
            'Here you can ${type} two or more companies. Please upload the CSV file with all the companies that you want to ${type}.',
        DESCRIPTION_2: 'You need to select the source and target partners first.',
        TABLE_LABEL: 'Total companies to ${type}',
        USER_TABLE_LABEL: 'Total user(s) to ${type}',
        STEPS_LABEL: 'Step ${step} of 2',
        CSV_TOOLTIP_MESSAGE:
            "This is the default configuration for all the companies that don't have any configuration assigned in the csv file.",
        UPLOAD_INPUT_LABEL: 'Company Data',
        PARTNER_TOOLTIP: 'Select the source partner',
        ALERT_DESCRIPTION_1:
            'If you want to set the same Configuration to all Companies, please fill out the Target Configuration Name in the Form in that case you should remove the header TARGET_CONFIGURATION_NAME in the CSV file.',
        ALERT_DESCRIPTION_2:
            'Otherwise, leave the field of the form empty and use the CSV to assign different configurations to the companies.',
    },
    MERGE_SINGLE_USER: {
        label: 'Single User',
        value: 'Single User',
        key: 'single-user',
        TEXT_1: 'Here you can merge two users. Please select the users that you want to merge.',
        TEXT_2: 'You need to select the source and target companies first.',
        UPLOAD_LABEL: 'Upload the users you want to merge',
        DESCRIPTION_LIST: 'Please select the options below',
        STEPS_LABEL: 'Step ${step} of 3',
        SELECT_USER: 'Select the source User to merge',
    },
    MOVE_SINGLE_USER: {
        label: 'Single User',
        value: 'Single User',
        key: 'single-user',
        TEXT_1: 'Here you can move a user. Please select the user that you want to move.',
        TEXT_2: 'You need to select the source and target partner first.',
        DESCRIPTION_LIST: 'Please select the options below',
        STEPS_LABEL: 'Step ${step} of 3',
        SELECT_USER: 'Select the source User to move',
    },
    MERGE_MOVE_MULTIPLE_USERS: {
        label: 'Multiple Users',
        value: 'Multiple Users',
        key: 'multiple-users',
        TEXT_1: 'Here you can ${type} users. Please upload the users that you want to ${type}.',
        TEXT_2: 'You need to select the source and target companies first.',
        UPLOAD_LABEL: 'Upload the users you want to ${type}',
        DESCRIPTION_LIST: 'Please select the options below',
        LIST_LABEL: 'total user(s) to ${type}',
        STEPS_LABEL: 'Step ${step} of 3',
    },
    MASS_UPDATE_USERS: {
        TITLE: 'Mass Data Update',
        SUBTITLE: 'Users',
        UPLOAD_LABEL: 'Upload the users you want to update',
        DESCRIPTION:
            'Here you can update information for some users. Please upload the CSV file with user data that you want to update.',
        TABLE_LABEL: 'Total user(s) to update',
        STEPS_LABEL: 'Step ${step} of 2',
        label: 'Users Update',
        value: 'Update Users',
        key: 'update-users',
        PARTNER_TOOLTIP: 'Select the source partner',
        COMPANY_TOOLTIP: 'Select the source company',
    },
    MASS_REACTIVATE_USERS: {
        TITLE: 'Mass Reactivate',
        SUBTITLE: 'Reactivate Users',
        UPLOAD_LABEL: 'Upload the users you want to reactivate',
        DESCRIPTION:
            'Here you can reactivate some users. Please upload the CSV file with the users you want to reactivate.',
        TABLE_LABEL: 'Total user(s) to reactivate',
        STEPS_LABEL: 'Step ${step} of 2',
        label: 'Users Reactivation',
        value: 'Reactivate Users',
        key: 'reactivate-users',
        PARTNER_TOOLTIP: 'Select the source partner',
    },
    MASS_DEACTIVATE_USERS: {
        TITLE: 'Mass Deactivate',
        SUBTITLE: 'Deactivate Users',
        UPLOAD_LABEL: 'Upload the users you want to deactivate',
        DESCRIPTION:
            'Here you can deactivate some users. Please upload the CSV file with the users you want to deactivate.',
        TABLE_LABEL: 'Total user(s) to deactivate',
        STEPS_LABEL: 'Step ${step} of 2',
        label: 'Users Deactivation',
        value: 'Deactivate Users',
        key: 'deactivate-users',
        PARTNER_TOOLTIP: 'Select the source partner',
    },
    MASS_UPDATE_COMPANY: {
        UPLOAD_INPUT_LABEL: 'Company Data',
        TITLE: 'Mass Data Update',
        SUBTITLE: 'Companies',
        UPLOAD_LABEL: 'Upload the clients you want to update',
        DESCRIPTION:
            'Here you can Activate, Inactivate, Change Configuration or Subscribe/Unsubscribe BDC document creation. Please upload the CSV file with clients data that you want to update.',
        DESCRIPTION_2: 'You need to select the source and target companies first.',
        TABLE_LABEL: 'Total clients to update',
        STEPS_LABEL: 'Step ${step} of 2',
        label: 'Company',
        value: 'Company',
        key: 'company',
        PARTNER_TOOLTIP: 'Select partner',
    },
    MASS_WELCOME_EMAIL_SEND: {
        TITLE: 'Send Mass Welcome Email',
        UPLOAD_LABEL: 'Upload the users that will be sent the welcome email',
        DESCRIPTION:
            'Here you can mass send welcome emails. Upload the CSV file with the usernames.',
        DESCRIPTION_2: 'You need to select the source and target companies first.',
        TABLE_LABEL: 'Users to receive welcome email',
        STEPS_LABEL: 'Step ${step} of 2',
    },
    COMMON_LABELS: {
        BUTTON_BACK: 'Back',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_CONTINUE: 'Continue',
        BUTTON_CONFIRM: 'Confirm',
        BUTTON_DOWNLOAD: 'Download Report',
        BUTTON_PREVIEW_WELCOME_EMAIL: 'Preview Welcome Email',
        BUTTON_RUN: 'Run',
        LIST_LABEL: 'total user(s) to',
        LIST_COMPANY_LABEL: 'total companies to',
    },
    ACTION_LABELS: {
        DETAILS: 'Details',
        CANCEL: 'Cancel',
        DOWNLOAD: 'Download',
        RETRY: 'Retry',
        DOWNLOAD_RESTRICTED: "This request doesn't have a document to download.",
    },
    FIELD_NAMES_LABELS: {
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        USERNAME: 'Username',
        EMAIL: 'Email',
        DEPARTMENT: 'Department',
        JOB_TITLE: 'Job Title',
        ROLE: 'Role',
        USER_IDENTIFIER: 'User Identifier',
    },
    FIELD_USERS_LABELS: [
        { KEY: 'firstName', OLD_KEY: 'oldFirstName', LABEL: 'First Name' },
        { KEY: 'lastName', OLD_KEY: 'oldLastName', LABEL: 'Last Name' },
        { KEY: 'userName', OLD_KEY: 'oldUserName', LABEL: 'Username' },
        { KEY: 'email', OLD_KEY: 'oldEmail', LABEL: 'Email' },
        { KEY: 'department', OLD_KEY: 'oldDepartment', LABEL: 'Department' },
        { KEY: 'jobTitle', OLD_KEY: 'oldJobTitle', LABEL: 'Job Title' },
        { KEY: 'roleName', OLD_KEY: 'oldRoleName', LABEL: 'Role' },
        { KEY: 'isActive', OLD_KEY: 'oldIsActive', LABEL: 'Status' },
    ],
    FIELD_COMPANY_LABELS: [
        { KEY: 'companyName', OLD_KEY: 'oldCompanyName', LABEL: 'Company Name' },
        { KEY: 'displayName', OLD_KEY: 'oldDisplayName', LABEL: 'Display Name' },
        { KEY: 'configurationName', OLD_KEY: 'oldConfigurationName', LABEL: 'Configuration Name' },
        { KEY: 'createBdcDocuments', OLD_KEY: 'oldCreateBdcDocuments', LABEL: 'BDC Documents' },
        { KEY: 'isActive', OLD_KEY: 'oldIsActive', LABEL: 'Status' },
    ],
    FIELD_USER_TITLE_LABEL: { KEY: 'userId', LABEL: 'User id' },
    FIELD_COMPANY_TITLE_LABEL: { KEY: 'companyId', LABEL: 'Company id' },
    LANDING_LABELS: {
        BULK_CHANGE_LABEL: 'Bulk Change Users',
        MASS_COMPANIES_UPDATE_LABEL: 'Mass Update Companies',
        MASS_USERS_UPDATE_LABEL: 'Mass Update Users',
        MASS_WELCOME_EMAIL_LABEL: 'Send Mass Welcome Email',
        MOVE_SINGLE_USER_LABEL: 'Move Single User',
        MOVE_MULTIPLE_USERS_LABEL: 'Move Users',
        MERGE_SINGLE_USER_LABEL: 'Merge Single User',
        MERGE_MULTIPLE_USERS_LABEL: 'Merge Users',
        MERGE_MULTIPLE_COMPANIES_LABEL: 'Merge Companies',
        MASS_EMAIL_USERS: 'Email Users',
        MASS_COMPANY_UPDATE_LABEL: 'Mass Update Companies',
        MERGE_COMPANY_LABEL: 'Merge Single Company',
        MOVE_COMPANY_LABEL: 'Move Single Company',
        MOVE_MULTIPLE_COMPANIES_LABEL: 'Move Companies',
        MERGE_PARTNERS_LABEL: 'Merge Partner',
        MERGE_SINGLE_COMPANY_LABEL: 'Merge Single Company',
        MASS_UPDATE_ACTIVATE_USERS: 'Mass Update Activate Users',
        MASS_UPDATE_DEACTIVATE_USERS: 'Mass Update Deactivate Users',
    },
    LANDING_STATUS: {
        CANCELLED: 'Cancelled',
        COMPLETE: 'Complete',
        COMPLETED: 'Completed',
        ERROR: 'Error',
        IN_PROGRESS: 'In Progress',
        PENDING: 'Pending',
        STARTED: 'Started',
        REGISTER: 'Register',
        REGISTERED: 'Registered',
    },
    DETAILS_LABELS: {
        PARTNER_ID: 'Partner Id',
        COMPANY_ID: 'Company Id',
        CONFIGURATION_NAME: 'Configuration Name',
        ADDON_CONFIGURATION_NAME: 'Add-On Configuration Name',
        USERS_TO_MERGE: 'Users to ${type}',
        COMPANIES_TO_MERGE: 'Companies to ${type}',
        TOTAL: 'Total',
        TOTAL_USERS: 'Total Users',
        COMPANIES_TO_MOVE: 'Companies to Move',
        REFERENCE_ID: 'Company Reference Id',
    },
    DISABLED_LABEL: 'Disable ${userToDisabled} after merge.',
    DISABLED_USERS_LABEL: 'Disable source users after merge.',
    DISABLED_USER_LABEL: 'Disable source user after merge.',
    DISABLED_COMPANY_LABEL: 'Disable source company after ${type}.',
    DISABLED_PARTNER_LABEL: 'Disable source partner after merge.',
    ALLOW_STATUS_TO_RUN: ['Register', 'Registered', 'Started'],
    ALLOW_STATUS_COMPLETED: ['Complete', 'Completed'],
    NOT_ALLOW_DOWNLOAD_FILE_REQUEST: ['Merge Single User', 'Move Single User'],
    DISABLED_COMPANIES_LABEL: 'Disable source companies after merge.',
    COMMON_KEYS: {
        MASS_DATA_UPDATE: 'mass-data-update',
        MERGE: 'merge',
        MOVE: 'move',
        COMPANIES: 'companies',
        COMPANY: 'company',
        USERS: 'users',
        PARTNERS: 'partners',
        SINGLE_COMPANY: 'single-company',
        SINGLE_USER: 'single-user',
        MULTIPLE_USERS: 'multiple-users',
        MULTIPLE_COMPANIES: 'multiple-companies',
        UPDATE_USERS: 'update-users',
        UPDATE_COMPANY: 'update',
        MASS_DEACTIVATE_USERS: 'deactivate-users',
        MASS_REACTIVATE_USERS: 'reactivate-users',
        MASS_WELCOME: 'mass-welcome',
        LIST_MERGE_USERS: 'list-merge-users',
        LIST_MOVE_USERS: 'list-move-users',
    },
    DASHBOARD_REFRESH_INTERVAL: 10000,
    DASHBOARD_ERROR_MESSAGE:
        'There is an error in the bulk change tool service, please contact the engineering area.',
    MAIN_MENU: ['Companies', 'Partners', 'Users'],
    CSV_TEMPLATES: {
        MOVE_MULTIPLE_COMPANIES: {
            name: 'move_multiples_companies.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/move_multiple_companies.csv',
        },
        MERGE_MULTIPLE_COMPANIES: {
            name: 'merge_multiple_companies.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/merge_multiple_companies.csv',
        },
        MERGE_MULTIPLE_USERS: {
            name: 'merge_multiple_users.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/merge_multiple_users.csv',
        },
        MOVE_MULTIPLE_USERS: {
            name: 'move_multiple_users.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/move_multiple_users.csv',
        },
        MASS_COMPANIES_UPDATE: {
            name: 'mass_companies_update.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/mass_data_update_companies.csv',
        },
        MASS_USERS_UPDATE: {
            name: 'mass_users_update.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/mass_data_update_users.csv',
        },
        MASS_REACTIVATE_USERS: {
            name: 'mass_reactivate_users.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/mass_reactivate_users.csv',
        },
        MASS_DEACTIVATE_USERS: {
            name: 'mass_deactivate_users.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/mass_deactivate_users.csv',
        },
        SEND_WELCOME_EMAIl: {
            name: 'send_welcome_email.csv',
            url:
                '/v2/bulk-change-tool/download-document?urlFile=https://s3.us-west-2.amazonaws.com/secure.trustmineral-staging.com/bulkchangetool/BCT_TEMPLATES/mass_welcome_email_send.csv',
        },
    },
};
