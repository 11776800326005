import Acl from 'modules/acl';
import { APP_PERMISSIONS } from 'constants/permissions';
import { ROOT_PRIVATE_ROUTE_PLATFORM_LITE } from 'constants/routes';

/**
 * Get title from key to Accordion component.
 *
 * @method getAccordionTitle
 * @param  {string} key
 * @return {string}
 */
export const getAccordionTitle = key => {
    let title = '';

    switch (key) {
        case '2mhr':
            title = 'Videos';
            break;
        case 'document':
            title = 'Documents';
            break;
        case 'jobdescription':
            title = 'Job Description';
            break;
        case 'law':
            title = 'Law';
            break;
        case 'lawAlert':
            title = 'Law Alert';
            break;
        case 'qa':
            title = 'Q&A';
            break;
        case 'toolkit':
            title = 'Toolkit';
            break;
        default:
            break;
    }

    return title;
};

/**
 * Stripping all HTML tags from a string.
 *
 * @method stripHTML
 * @param  {string} string
 * @param  {string} replacementString
 * @return {string}
 */
export const stripHTML = (string, replacementString = ' ') => {
    return string.toString().replace(/(<([^>]+)>)/gi, replacementString);
};

/**
 * Validate “HR Compliance” breadcrumb link.
 *
 * @method getHRComplianceLink
 * @return {boolean}
 */
export const getHRComplianceLink = () => {
    return Acl.check(APP_PERMISSIONS.platformliteCompanyDashboardView) ||
        Acl.check(APP_PERMISSIONS.intelligenceCompanyDashboard1View)
        ? ROOT_PRIVATE_ROUTE_PLATFORM_LITE
        : '/hr-compliance';
};
