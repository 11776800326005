import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const ArrowRightIconLarge = ({ height, viewBox, width, fillColor }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7322 4.03062C11.7085 3.1008 13.2915 3.1008 14.2678 4.03062L29.2678 18.3163C30.2441 19.2461 30.2441 20.7537 29.2678 21.6835L14.2678 35.9692C13.2915 36.899 11.7085 36.899 10.7322 35.9692C9.75592 35.0394 9.75592 33.5319 10.7322 32.602L23.9645 19.9999L10.7322 7.39779C9.75592 6.46797 9.75592 4.96044 10.7322 4.03062Z"
                fill={fillColor}
            />
        </SvgIcon>
    );
};

ArrowRightIconLarge.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
};

ArrowRightIconLarge.defaultProps = {
    width: '40',
    height: '40',
    viewBox: '0 0 40 40',
    fillColor: DEFAULT_COLORS.BLACK,
};

export default ArrowRightIconLarge;
