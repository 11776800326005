import PropTypes from 'prop-types';
import React from 'react';
import _set from 'lodash/set';
import styled from 'styled-components';
import _isEmpty from 'lodash/isEmpty';

import Col from 'ravenjs/lib/Col';
import Input from 'ravenjs/lib/Input';
import Row from 'ravenjs/lib/Row';
import TextArea from 'ravenjs/lib/TextArea';
import Tooltip from 'ravenjs/lib/Tooltip';
import TooltipContent from 'ravenjs/lib/TooltipContent';
import { get } from 'ravenjs/utils';
import DisplayRequiredWithIconField from './DisplayRequiredWithIconField';

const TooltipContentWrapper = styled.div`
    display: block;
`;

class InputWithTooltipField extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        formContext: PropTypes.object.isRequired,
        formData: PropTypes.string,
        idSchema: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        registry: PropTypes.object.isRequired,
        required: PropTypes.bool,
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object.isRequired,
        onBlur: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
        required: null,
        formData: '',
        onBlur: () => {},
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.onInputChange = this.onInputChange.bind(this);
    }

    _getUiOptions = schema => get(schema, 'ui:options', {});

    onInputChange({ target: { value } }) {
        const {
            formContext,
            idSchema: { $id },
            onChange,
        } = this.props;
        const formData = { ...formContext.getFormData() };
        const path = $id.split('_').splice(1);

        onChange(value);
        _set(formData, path.join('.'), value);
        formContext.updateFormData({ formData });
    }

    getTooltipContent = text => {
        return text && <TooltipContent text={text} />;
    };

    render() {
        const {
            disabled,
            idSchema,
            formData,
            name,
            registry,
            required,
            schema,
            uiSchema,
            onBlur,
        } = this.props;
        const options = this._getUiOptions(uiSchema);
        const title = get(schema, 'title') || name;
        const helpText = get(schema, 'helpText', {});
        const TitleField = get(registry, 'fields.FormGroupTitleField', () => <></>);
        const { $id: id } = idSchema;
        const { tooltipContent = '', type = 'input' } = options;
        const modifiedValue = this.props.formData !== null ? formData.toString() : formData;

        const showRequired =
            this.props.formContext &&
            (this.props.formContext.taintedFields.includes(id) ||
                !this.props.formContext.pristine) &&
            this.props.required &&
            _isEmpty(modifiedValue);

        return (
            <>
                <Row width="auto">
                    <Col size={12}>
                        <Tooltip
                            content={this.getTooltipContent(tooltipContent)}
                            padding="0"
                            arrowSize={0}
                            color="white"
                            placement="top"
                            maxWidth="initial"
                        >
                            <TooltipContentWrapper>
                                <TitleField
                                    id={id}
                                    helpText={helpText}
                                    title={title}
                                    required={showRequired}
                                    showRequired={showRequired}
                                    showOptionalMark={!required}
                                />
                            </TooltipContentWrapper>
                        </Tooltip>
                    </Col>
                    <Col style={{ position: 'relative' }}>
                        {type === 'input' && (
                            <Input
                                disabled={disabled}
                                id={id}
                                required={required}
                                type="text"
                                ref={this.inputRef}
                                onChange={this.onInputChange}
                                showRequired={showRequired}
                                defaultValue={formData}
                                onBlur={
                                    typeof onBlur === 'function' &&
                                    (event => onBlur(id, event.target.value))
                                }
                            />
                        )}
                        {type === 'textarea' && (
                            <TextArea
                                disabled={disabled}
                                defaultValue={formData}
                                onChange={this.onInputChange}
                                required={required}
                                ref={this.inputRef}
                                showRequired={showRequired}
                                onBlur={
                                    typeof onBlur === 'function' &&
                                    (event => onBlur(id, event.target.value))
                                }
                            />
                        )}
                        {showRequired && <DisplayRequiredWithIconField />}
                    </Col>
                </Row>
            </>
        );
    }
}

export default InputWithTooltipField;
