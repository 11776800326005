import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';

import Button from 'ravenjs/lib/Button';
import Divider from 'ravenjs/lib/Divider';
import FaIcon from 'ravenjs/lib/FaIcon';
import List from 'ravenjs/lib/List';
import ListItem from 'ravenjs/lib/ListItem';
import Menu from 'ravenjs/lib/Menu';
import PopoverContent from 'ravenjs/lib/PopoverContent';
import Tooltip from 'ravenjs/lib/Tooltip';
import { checkObjectKeys } from 'ravenjs/utils/object';
import { genID } from 'ravenjs/utils/generate';

const BtnContainer = styled.div`
    display: inline-block;
`;

class ActionsMenu extends React.Component {
    btnContainer = React.createRef();

    static propTypes = {
        /**
         * Custom properties for the rendered `Divider` component.
         */
        DividerProps: PropTypes.object,
        /**
         * Custom properties for the rendered `List` component.
         */
        ListProps: PropTypes.object,
        /**
         * Pass a set of `data` to the `ActionsMenu`.
         */
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
        /**
         * A map of action handlers for the menu items.
         */
        handlers: PropTypes.object,
        /**
         * The list of menu items to build.
         * Array of Arrays.
         */
        menuItems: PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    /**
                     * Should we disable the list item?
                     */
                    disabled: PropTypes.oneOfType([
                        PropTypes.bool,
                        PropTypes.string,
                        PropTypes.func,
                    ]),
                    /**
                     * Add in dynamic `style` for the list item.
                     */
                    style: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
                    /**
                     * Display some helpText (tooltip) for the list item.
                     */
                    helpText: PropTypes.object,
                    /**
                     * The icon to render next to the list item.
                     * This is the same as passing in props to the `<FaIcon />` component.
                     */
                    icon: PropTypes.object,
                    /**
                     * The label (main text) for the list item.
                     */
                    label: PropTypes.string,
                    /**
                     * The `onClick` handler for the list item.
                     */
                    onClick: PropTypes.func,
                })
            )
        ),
        /**
         * If set to `true`, the actions menu will be rendered alongside the Button component.
         * This way the ActionsMenu won't be appended to the `document.body`.
         */
        snapToButton: PropTypes.bool,
    };

    static defaultProps = {
        DividerProps: null,
        ListProps: null,
        data: null,
        handlers: null,
        menuItems: [],
        snapToButton: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
        };
    }

    handleOnMenuClose = () => this.setState(() => ({ menuOpen: false }));

    handleOnMenuOpen = () => this.setState(() => ({ menuOpen: true }));

    renderMenuItems = (menuItem, menuItemIdx) => {
        const { data, handlers } = this.props;
        // Return the list of menu items
        return menuItem.map(item => {
            const {
                disabled: disabledProp,
                helpText,
                icon = {},
                label,
                onClick,
                show = true,
                style: styleProp,
                ...action
            } = item;
            // Dynamically disable or enable the list item (if needed).
            const disabled = checkObjectKeys(data, disabledProp, handlers);
            // Dynamically add in styles for the list item (if needed).
            const style = checkObjectKeys(data, styleProp, handlers) || {};
            // Dynamically check if we should show the helpText tooltip.
            const showHelpText =
                helpText && helpText.disabled
                    ? checkObjectKeys(data, helpText.disabled, handlers)
                    : true;
            // If we don't want to show the menu item,
            // move on and don't render it.
            if (!show) {
                return null;
            }

            return typeof helpText === 'object' && !_isEmpty(helpText) && showHelpText ? (
                <Tooltip {...helpText} key={genID('ActionsMenu_MenuList_HelpText_Item_')}>
                    <ListItem
                        {...action}
                        style={style}
                        button
                        dense
                        disabled={disabled}
                        onClick={disabled ? null : onClick}
                    >
                        {!_isEmpty(icon) && <FaIcon margin="0 7.5px 0 0" {...icon} />}
                        {label}
                        <FaIcon margin="0 0 0 7.5px" icon={faInfoCircle} color="error" />
                    </ListItem>
                </Tooltip>
            ) : (
                <ListItem
                    {...action}
                    style={style}
                    button
                    dense
                    disabled={disabled}
                    key={genID('ActionsMenu_MenuList_Item_')}
                    onClick={disabled ? null : onClick}
                >
                    {!_isEmpty(icon) && <FaIcon margin="0 7.5px 0 0" {...icon} />}
                    {label}
                </ListItem>
            );
        });
    };

    renderMenuItemsList = () => {
        const { menuItems, DividerProps } = this.props;
        // Render the parent list of menu items.
        return menuItems.map((menuItem, menuItemIdx) => (
            <React.Fragment key={genID('ActionsMenu_MenuList')}>
                {this.renderMenuItems(menuItem)}
                {menuItemIdx !== menuItems.length - 1 && (
                    <Divider height="2px" margin="0" {...DividerProps} />
                )}
            </React.Fragment>
        ));
    };

    render() {
        const { menuOpen } = this.state;
        const { DividerProps, ListProps, menuItems, snapToButton, ...rest } = this.props;
        // Build the default props for the Menu.
        const defaultMenuProps = {
            ButtonComponent: snapToButton ? (
                <BtnContainer ref={this.btnContainer}>
                    <Button active={menuOpen} border color="actions" noMinWidth width="115px">
                        Actions
                    </Button>
                </BtnContainer>
            ) : (
                <Button active={menuOpen} border color="actions" noMinWidth width="115px">
                    Actions
                </Button>
            ),
            PopperProps: snapToButton
                ? {
                      container: this.btnContainer,
                      style: {
                          zIndex: 1,
                      },
                  }
                : {},
            ContentComponent: PopoverContent,
            ContentProps: {
                borderRadius: '4px',
                borderWidth: '1px',
                boundToAnchor: true,
                elevation: 8,
                elevationDirection: 'bottom',
                padding: '0',
            },
            IconComponent: null,
            arrowSize: 7.5,
            color: 'white',
            distanceFromContainer: 5,
            onClose: this.handleOnMenuClose,
            onOpen: this.handleOnMenuOpen,
            placement: 'right',
        };
        // Render the menu with the list of items
        return (
            <Menu {...defaultMenuProps} {...rest}>
                <List borderRadius="4px" backgroundColor="white" {...ListProps}>
                    {this.renderMenuItemsList()}
                </List>
            </Menu>
        );
    }
}

export default ActionsMenu;
