import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { DEFAULT_COLORS } from 'constants/colors';

const NotificationsCounterStyled = styled.span`
    align-items: center;
    background: ${DEFAULT_COLORS.RED_3};
    border-radius: 9.5px;
    color: ${DEFAULT_COLORS.WHITE};
    display: flex;
    font-size: 0.6rem;
    font-weight: bold;
    justify-content: center;
    right: 5px;
    top: 0;
    position: absolute;
    min-width: 0.8rem;
    height: 0.8rem;
    padding: 5px 5px;
    object-fit: contain;
    ${({ value }) =>
        value > 9
            ? {
                  right: value > 99 ? '-5px' : 0,
              }
            : null}
`;

function NotificationsCounter({ value }) {
    return (
        <NotificationsCounterStyled value={value}>
            {value > 99 ? '99+' : value}
        </NotificationsCounterStyled>
    );
}

NotificationsCounter.propTypes = {
    value: string,
};

NotificationsCounter.defaultProps = {
    value: null,
};

export default NotificationsCounter;
