import { func } from 'prop-types';
import React from 'react';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import styled, { withTheme } from 'styled-components';

import { callFunc } from 'ravenjs/utils/actions';
import FaIcon from 'ravenjs/lib/FaIcon';
import Button from 'ravenjs/lib/Button';
import { getThemeProps } from 'ravenjs/utils/theme';

import { DEFAULT_COLORS } from 'constants/colors';

const BackButton = styled(Button)`
    background-color: ${({ theme }) =>
        getThemeProps('palette.secondary.gradientColor', DEFAULT_COLORS.BLUE, { theme })};
    &:hover {
        background-color: transparent;
    }
    border-right: 1px solid #e6eaee;
    min-height: ${getThemeProps('AppNavigation.height')}px;
    ${({ theme }) => theme.media.down('md')`
        min-height: 100%;
        padding: 0 0.9375rem;
    `};
`;

const BackButtonIcon = styled(FaIcon)`
    color: ${({ theme }) =>
        getThemeProps('palette.secondary.text', DEFAULT_COLORS.BLUE, { theme })};
`;

const SubNavigationBackButton = props => {
    const { onBackButtonClick } = props;

    const handleOnclick = () => {
        callFunc(onBackButtonClick);
    };

    return (
        <BackButton maxWidth="55px" margin="0 0 0 -15px" noMinWidth onClick={handleOnclick}>
            <BackButtonIcon icon={faAngleLeft} height="19px" width="16px" />
        </BackButton>
    );
};

SubNavigationBackButton.propTypes = {
    onBackButtonClick: func,
};

SubNavigationBackButton.defaultProps = {
    onBackButtonClick: null,
};

export { SubNavigationBackButton as SubNavigationBackButtonUnwrapped };
export default withTheme(SubNavigationBackButton);
