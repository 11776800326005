import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const OshaLogIcon = ({
    height,
    viewBox,
    width,
    fillColor,
    fillColorComplementary,
    strokeColor,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fill={fillColorComplementary}
                d="M32,63C16.8,59.3,9.6,45,9.6,29.4V14.2c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.3-0.8,2.1-0.8h3.2
                c6.8,0,12.1-2.8,16.4-7.1c5.4,5,12.5,5.6,19.9,5.6h3.6c0.8,0,1.5,0.3,2.1,0.8c0.5,0.5,0.8,1.3,0.8,2.1v16.7
                C58.3,45,47.2,59.3,32,63"
            />
            <path
                fill={fillColor}
                d="M32,15.6c8.8,0,16,7.2,16,16s-7.2,16-16,16c-8.8,0-16-7.2-16-16S23.2,15.6,32,15.6"
            />
            <path
                fill={strokeColor}
                d="M14,50.1c4.5,5.7,10.8,10.1,18,11.9c7.3-1.8,13.6-6.1,18-11.9c4.5-5.8,7.2-13.1,7.2-20.7V11.7
                c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.1-0.8-0.1h-3.6c-3.8,0-7.5-0.7-11-2.1
                c-3.2-1.3-6.2-3.1-8.8-5.4c-2.6,2.3-5.6,4.1-8.8,5.4c-3.5,1.4-7.2,2.1-11,2.1H8.5c-0.3,0-0.5,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.5,0.3
                l0,0c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.5-0.1,0.8v17.6C6.7,37,9.4,44.3,14,50.1 M31.8,64c-7.8-1.9-14.6-6.5-19.4-12.7
                c-4.8-6.2-7.7-13.9-7.7-22V11.7c0-0.5,0.1-1,0.3-1.5C5.1,9.8,5.4,9.3,5.8,9l0.1-0.1C6.2,8.6,6.6,8.3,7,8.1C7.5,8,8,7.9,8.5,7.9h3.6
                c3.6,0,7-0.7,10.3-2c3.3-1.3,6.3-3.2,8.9-5.6c0.4-0.4,1-0.4,1.4,0c2.6,2.4,5.6,4.3,8.9,5.6c3.2,1.3,6.7,2,10.3,2h3.6
                c0.5,0,1,0.1,1.5,0.3c0.5,0.2,0.9,0.4,1.3,0.8c0.4,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.3,1,0.3,1.5v17.6c0,8.1-2.9,15.8-7.7,22
                C46.8,57.5,40.1,62.1,32.2,64C32.1,64,31.9,64,31.8,64z"
            />
            <path
                fill={strokeColor}
                d="M42.6,21c-2.7-2.7-6.5-4.4-10.6-4.4s-7.9,1.7-10.6,4.4c-2.7,2.7-4.4,6.5-4.4,10.6c0,4.1,1.7,7.9,4.4,10.6
                s6.5,4.4,10.6,4.4c4.1,0,7.8-1.7,10.5-4.3l0,0c2.7-2.7,4.4-6.5,4.4-10.6C47,27.4,45.3,23.7,42.6,21 M32,14.6c4.7,0,8.9,1.9,12,5
                c3.1,3.1,5,7.3,5,12c0,4.7-1.9,8.9-5,12L44,43.6c-3.1,3-7.3,4.9-12,4.9c-4.7,0-8.9-1.9-12-5c-3.1-3.1-5-7.3-5-12
                c0-4.7,1.9-8.9,5-12C23.1,16.5,27.3,14.6,32,14.6z"
            />
            <path
                fill={strokeColor}
                d="M24.8,33.5c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l3,3l8.6-8.6c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4
                l-9.3,9.3c-0.4,0.4-1,0.4-1.4,0L24.8,33.5z"
            />
        </SvgIcon>
    );
};

OshaLogIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
    fillColorComplementary: string,
    strokeColor: string,
};

OshaLogIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
    fillColor: `${DEFAULT_COLORS.WHITE}`,
    fillColorComplementary: `${DEFAULT_COLORS.GREEN}`,
    strokeColor: `${DEFAULT_COLORS.BLACK}`,
};

export default OshaLogIcon;
