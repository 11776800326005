import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import styled from 'styled-components';

import Button from 'ravenjs/lib/Button';
import Col from 'ravenjs/lib/Col';
import Row from 'ravenjs/lib/Row';
import { ChevronIcon, TrashIcon } from 'ravenjs/lib/SvgIcon';
import { getThemeProps } from 'ravenjs/utils/theme';

import { DEFAULT_COLORS } from 'constants/colors';
import { ACTIONS } from 'ravenjs/constants/messages';

import FormGroupTitle from './FormGroupTitle';
import FormGroupContent from './FormGroupContent';

const ArrayFieldTemplateStyled = styled.div`
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('ArrayFieldTemplate.styles')};
`;

const ArrayFieldButtons = styled.div`
    padding: 10px 0;
`;

function ArrayFieldTemplate(props) {
    const { canAdd, disabled, formContext, idSchema, items, onAddClick, required, schema } = props;
    const schemaId = _get(idSchema, '$id');
    const uiSchemaTitle = _get(props, ['uiSchema', 'ui:title']);
    const uiOptions = _get(props, ['uiSchema', 'ui:options'], {});
    const labelButtonAdd = _get(uiOptions, 'labelButtonAdd', ACTIONS.ADD_ITEM);
    const propsTitle = _get(props, 'title');
    const titleId = `${schemaId}__title`;
    const formGroup = _get(schema, 'formGroup', false);

    const renderArrayFields = () => (
        <>
            {items.map(item => (
                <div className="arrayFieldStyled" key={item.index}>
                    <div className="arrayFieldChildStyled">{item.children}</div>
                    <ArrayFieldButtons>
                        <Row gutter={false}>
                            <Col>
                                {item.hasMoveDown && (
                                    <Button
                                        noMinWidth
                                        disabled={disabled}
                                        onClick={item.onReorderClick(item.index, item.index + 1)}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <ChevronIcon
                                            direction="down"
                                            style={{ width: 25, height: 25 }}
                                        />
                                    </Button>
                                )}
                                {item.hasMoveUp && (
                                    <Button
                                        noMinWidth
                                        disabled={disabled}
                                        onClick={item.onReorderClick(item.index, item.index - 1)}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <ChevronIcon
                                            direction="up"
                                            style={{ width: 25, height: 25 }}
                                        />
                                    </Button>
                                )}
                                <Button
                                    noMinWidth
                                    padding="0 10px"
                                    disabled={disabled}
                                    onClick={item.onDropIndexClick(item.index)}
                                    color="error"
                                >
                                    <TrashIcon
                                        fill={DEFAULT_COLORS.WHITE}
                                        style={{ width: 25, height: 25 }}
                                    />
                                </Button>
                            </Col>
                        </Row>
                    </ArrayFieldButtons>
                </div>
            ))}

            {canAdd && (
                <Row gutter={false}>
                    <Col>
                        <Button disabled={disabled} onClick={onAddClick}>
                            {labelButtonAdd}
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );

    const renderContent = () =>
        formGroup ? (
            <FormGroupContent schema={schema}>{renderArrayFields()}</FormGroupContent>
        ) : (
            renderArrayFields()
        );

    const renderTitle = () => (
        <FormGroupTitle
            formContext={formContext}
            id={titleId}
            required={required}
            root={schemaId === 'root'}
            title={propsTitle || uiSchemaTitle}
        />
    );

    return (
        <ArrayFieldTemplateStyled>
            {renderTitle()}
            {renderContent()}
        </ArrayFieldTemplateStyled>
    );
}

ArrayFieldTemplate.propTypes = {
    canAdd: PropTypes.bool,
    disabled: PropTypes.bool,
    formContext: PropTypes.object,
    idSchema: PropTypes.object,
    items: PropTypes.array,
    onAddClick: PropTypes.func,
    required: PropTypes.bool,
    schema: PropTypes.object.isRequired,
};

ArrayFieldTemplate.defaultProps = {
    canAdd: false,
    disabled: false,
    formContext: null,
    idSchema: {},
    items: [],
    onAddClick: () => {},
    required: false,
};

export default ArrayFieldTemplate;
