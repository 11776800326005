import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    downloadActiveClientsUsersReportsAPI,
    fetchEcAggregateReportAPI,
    fetchPvrPartnersAPI,
    fetchCalculationValuesAPI,
    updateCalculationValuesAPI,
    fetchClientUsageAPI,
    fetchPartnerValueReportAPI,
    fetchTotalClientsByPartnerAPI,
    fetchCvrClientsAPI,
    fetchClientValueReportAPI,
    fetchClientValueReportTemplateAPI,
    fetchReportingSnapshotByPartnerAPI,
    fetchReportingSnapshotRequestAPI,
    fetchReportingSnapshotRefreshAPI,
    runEmailEngagementAPI,
    fetchClientSolutionReportAPI,
    runClientSolutionReportAPI,
    runAdministrationReportsAPI,
    fetchAdministrationReportsAPI,
} from 'modules/apis';
import * as reportingActions from '../actions';

/**
 * Fetch Administration Reports
 *
 * @method fetchAdministrationReportsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchAdministrationReportsSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchAdministrationReportsAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchAdministrationReports.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchAdministrationReports.error(error));
        return error;
    }
}

/**
 * Download active clients and users report
 *
 * @method downloadActiveClientsUsersReportsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* downloadActiveClientsUsersReportsSaga(action) {
    try {
        const { payload: params } = action;
        let response = '';

        if (!params) {
            response = yield call(downloadActiveClientsUsersReportsAPI);
        } else {
            response = yield call(downloadActiveClientsUsersReportsAPI, params);
        }

        const data = get(response, 'data.results', {});
        yield put(reportingActions.downloadActiveClientsUsersReports.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.downloadActiveClientsUsersReports.error(error));
        return error;
    }
}

/**
 * Fetch client usage
 *
 * @method fetchClientUsageSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchClientUsageSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchClientUsageAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchClientUsage.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchClientUsage.error(error));
        return error;
    }
}

/**
 * Fetch partners
 *
 * @method fetchPvrPartnersSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */

export function* fetchPvrPartnersSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchPvrPartnersAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchPvrPartners.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchPvrPartners.error(error));
        return error;
    }
}

/**
 * Fetch calculation values to partner value report
 *
 * @method fetchCalculationValuesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchCalculationValuesSaga(action) {
    try {
        const response = yield call(fetchCalculationValuesAPI);
        const data = get(response, 'data._embedded.calculation_values.0', []);
        yield put(reportingActions.fetchCalculationValues.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchCalculationValues.error(error));
        return error;
    }
}

/**
 * Update calculation values to partner value report
 *
 * @method updateCalculationValuesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* updateCalculationValuesSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(updateCalculationValuesAPI, params);
        const data = get(response, 'data._embedded.calculation_values.0', []);
        yield put(reportingActions.updateCalculationValues.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.updateCalculationValues.error(error));
        return error;
    }
}

/**
 * Fetch partner value report
 *
 * @method fetchPartnerValueReportSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchPartnerValueReportSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchPartnerValueReportAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchPartnerValueReport.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchPartnerValueReport.error(error));
        return error;
    }
}

/**
 * Fetch total clients
 *
 * @method fetchTotalClientsByPartnerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchTotalClientsByPartnerSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchTotalClientsByPartnerAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchTotalClientsByPartner.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchTotalClientsByPartner.error(error));
        return error;
    }
}

/**
 * Fetch clients to client value report
 *
 * @method fetchCvrClientsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */

export function* fetchCvrClientsSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchCvrClientsAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchCvrClients.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchCvrClients.error(error));
        return error;
    }
}

/**
 * Fetch client value report
 *
 * @method fetchClientValueReportSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchClientValueReportSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchClientValueReportAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchClientValueReport.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchClientValueReport.error(error));
        return error;
    }
}

/**
 * Fetch client solution report
 *
 * @method fetchClientSolutionReportSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchClientSolutionReportSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchClientSolutionReportAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchClientSolutionReport.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchClientSolutionReport.error(error));
        return error;
    }
}

/**
 * Fetch client value report
 *
 * @method fetchClientValueReportTemplateSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchClientValueReportTemplateSaga(action) {
    try {
        const response = yield call(fetchClientValueReportTemplateAPI);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchClientValueReportTemplate.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchClientValueReportTemplate.error(error));
        return error;
    }
}

/**
 * Fetch EC Aggregate report data or CSV
 *
 * @method fetchEcAggregateReportSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEcAggregateReportSaga(action) {
    try {
        const { payload: params } = action;
        const { downloadReport = false } = params;
        const response = yield call(fetchEcAggregateReportAPI, params);
        let data;
        if (downloadReport) {
            data = get(response, 'data', '');
        } else {
            data = get(response, 'data.reportData', []);
        }
        yield put(reportingActions.fetchEcAggregateReport.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchEcAggregateReport.error(error));
        return error;
    }
}

/**
 * Fetch reporting snapshot by partner
 *
 * @method fetchReportingSnapshotByPartnerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchReportingSnapshotByPartnerSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchReportingSnapshotByPartnerAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchReportingSnapshotByPartner.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchReportingSnapshotByPartner.error(error));
        return error;
    }
}

/**
 * Fetch reporting snapshot by partner
 *
 * @method fetchReportingSnapshotRequestSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchReportingSnapshotRequestSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchReportingSnapshotRequestAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchReportingSnapshotRequest.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchReportingSnapshotRequest.error(error));
        return error;
    }
}

/**
 * Fetch reporting snapshot refresh
 *
 * @method fetchReportingSnapshotRefreshSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchReportingSnapshotRefreshSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchReportingSnapshotRefreshAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchReportingSnapshotRefresh.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchReportingSnapshotRefresh.error(error));
        return error;
    }
}

/**
 * Run email engagement report
 *
 * @method runEmailEngagementSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* runEmailEngagementSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(runEmailEngagementAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.runEmailEngagement.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.runEmailEngagement.error(error));
        return error;
    }
}

export function* runClientSolutionReportSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(runClientSolutionReportAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.runClientSolutionReport.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.runClientSolutionReport.error(error));
        return error;
    }
}

export function* runAdministrationReportsSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(runAdministrationReportsAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.runAdministrationReports.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.runAdministrationReports.error(error));
        return error;
    }
}
