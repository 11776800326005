import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Col from 'ravenjs/lib/Col';
import PaginationGoTo from 'ravenjs/lib/PaginationGoTo';
import Row from 'ravenjs/lib/Row';
import { calculateLastPage } from 'ravenjs/utils/pagination';
import { getThemeProps } from 'ravenjs/utils/theme';
import { get } from 'ravenjs/utils/lodash';
import { renderCheck } from 'ravenjs/utils/viewport';
import { DEFAULT_COLORS } from 'constants/colors';
import PaginationActions from './PaginationActions';
import PaginationTotalRows from './PaginationTotalRows';
import PaginationTotalSelected from './PaginationTotalSelected';
import PaginationRowsPerPage from './PaginationRowsPerPage';

const PaginationControlsStyled = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const PaginationStyled = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0;
    @media (min-width: 641px) {
        align-items: center;
        flex-direction: row;
    }
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('Pagination.styles')};
    ${({ theme }) =>
        theme.media.down('md')`
        background-color: ${DEFAULT_COLORS.COMPONENTS_TABLE_TITLE_ROW}
    `};
`;

class Pagination extends React.Component {
    static propTypes = {
        compress: PropTypes.string,
        /**
         * Index of the current page
         */
        currentPage: PropTypes.number,
        // Prop to hide pagination
        hidePagination: PropTypes.bool,
        /**
         * Boolean flag to hide the 'selected row' section from pagination
         */
        hideSelectedRow: PropTypes.bool,
        /**
         * The number of rows per page
         */
        rowsPerPage: PropTypes.number,
        /**
         * The display label for the rows per page
         */
        rowsPerPageLabel: PropTypes.node,
        /**
         * The options of the rows per page selection field
         */
        rowsPerPageOptions: PropTypes.array,
        /**
         * Define and overwrite the CSS styles.
         */
        style: PropTypes.object,
        /**
         * The total number of rows
         */
        totalRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /**
         * The display label for the total rows
         */
        totalRowsLabel: PropTypes.oneOfType([
            PropTypes.shape({
                prefix: PropTypes.node,
                suffix: PropTypes.node,
            }),
            PropTypes.string,
        ]),
        /**
         * Event fired upon a successful page change
         */
        onChangePage: PropTypes.func,
        /**
         * Event fired upon a successful rows per page change
         */
        onChangeRowsPerPage: PropTypes.func,
        /**
         * Props for the selected items count.
         */
        selected: PropTypes.object,
        showcaseCount: PropTypes.number,
        goToPage: PropTypes.bool,
        callChange: PropTypes.bool,
        paginationButtonSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        buttonColor: PropTypes.string,
        innerButtonColor: PropTypes.string,
        enableRowsPerPageOptions: PropTypes.bool,
        menuPlacement: PropTypes.string,
    };

    static defaultProps = {
        paginationButtonSize: '20',
        buttonColor: '#9CDEFF',
        innerButtonColor: '#282828',
        compress: '',
        currentPage: 0,
        hidePagination: false,
        hideSelectedRow: false,
        onChangePage: () => {},
        onChangeRowsPerPage: () => {},
        rowsPerPage: 10,
        rowsPerPageLabel: 'Rows per page:',
        rowsPerPageOptions: [5, 10, 15, 20],
        enableRowsPerPageOptions: false,
        selected: {},
        showcaseCount: 4,
        style: null,
        totalRows: 0,
        totalRowsLabel: null,
        goToPage: true,
        callChange: true,
        menuPlacement: 'auto',
    };

    constructor(props) {
        super(props);
        this.state = {
            pageInputValue: '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { totalRows, onChangePage, currentPage, rowsPerPage, callChange } = this.props;
        // Calculate the last page of the pagination.
        const lastPage = calculateLastPage(totalRows, rowsPerPage);
        // If the current page is greater than the last page,
        // then we will throw the onChangePage event.
        if (currentPage > lastPage) {
            if (callChange) {
                onChangePage(null, lastPage);
            }
        }
    }

    onRowsChange = ({ value }) => {
        const { onChangeRowsPerPage } = this.props;
        onChangeRowsPerPage({ value: parseInt(value, 10) });
    };

    handleInputChange = event => {
        const { rowsPerPage, totalRows } = this.props;
        const lastPage = calculateLastPage(totalRows, rowsPerPage);
        const { pageInputValue } = this.state;
        const { value } = event.target;
        let page = 1;

        if (value > 0 && value <= lastPage) {
            page = value;
        } else {
            page = '';
        }

        pageInputValue !== value && this.setState(() => ({ pageInputValue: page }));
    };

    handleGoToPageClick = () => {
        const { onChangePage } = this.props;
        const { pageInputValue } = this.state;

        if (pageInputValue > 0) {
            this.setState({
                pageInputValue: '',
            });
            onChangePage(null, Number(pageInputValue));
        }
    };

    isValidPage = page => {
        const { rowsPerPage, totalRows } = this.props;
        const lastPage = calculateLastPage(totalRows, rowsPerPage);

        return page > 0 && page <= lastPage;
    };

    onGoToPageSubmit = page => {
        const { onChangePage } = this.props;
        if (this.isValidPage(page)) {
            onChangePage(null, Number(page));
        }
    };

    renderGotoPage = () => {
        const { goToPage, rowsPerPage, totalRows } = this.props;
        const lastPage = calculateLastPage(totalRows, rowsPerPage);

        if (renderCheck('md', 'greater') && goToPage && lastPage > 1) {
            return (
                <PaginationGoTo
                    lastPage={lastPage}
                    onSubmit={this.onGoToPageSubmit}
                    pageValidationCallback={this.isValidPage}
                />
            );
        }
        return null;
    };

    render() {
        const {
            paginationButtonSize,
            buttonColor,
            innerButtonColor,
            compress,
            currentPage,
            hideSelectedRow,
            onChangePage,
            rowsPerPage,
            style,
            selected,
            showcaseCount,
            totalRows,
            totalRowsLabel,
            rowsPerPageLabel,
            rowsPerPageOptions,
            enableRowsPerPageOptions,
            menuPlacement,
        } = this.props;

        const selectedCount = !_isEmpty(selected) ? get(selected, 'itemCount', 0) : null;
        const isSelectedCountPresent = typeof selectedCount === 'number';
        const hasPages = calculateLastPage(totalRows, rowsPerPage) > 1;
        return (
            <PaginationStyled style={style}>
                <Row alignItems="center" gutter={false} width="100%">
                    {hasPages && (
                        <Col gutter={false} margin="0.5rem 0" size={12}>
                            <PaginationControlsStyled>
                                <PaginationActions
                                    paginationButtonSize={paginationButtonSize}
                                    buttonColor={buttonColor}
                                    innerButtonColor={innerButtonColor}
                                    currentPage={currentPage}
                                    onChangePage={onChangePage}
                                    rowsPerPage={rowsPerPage}
                                    showcaseCount={showcaseCount}
                                    totalRows={totalRows}
                                    compress={compress}
                                />
                                {enableRowsPerPageOptions && !_isEmpty(rowsPerPageOptions) && (
                                    <Col
                                        display={{ xs: 'none', md: 'block' }}
                                        size={6}
                                        gutter={false}
                                    >
                                        <PaginationRowsPerPage
                                            label={rowsPerPageLabel}
                                            onChange={this.onRowsChange}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={rowsPerPageOptions}
                                            menuPlacement={menuPlacement}
                                        />
                                    </Col>
                                )}
                                {this.renderGotoPage()}
                            </PaginationControlsStyled>
                        </Col>
                    )}
                    {!hideSelectedRow && (
                        <Col display={{ xs: 'none', md: 'block' }} size={6} gutter={false}>
                            <Row direction="row" gutter={false} height="100%" justify="flex-start">
                                {!_isEmpty(totalRowsLabel) && (
                                    <PaginationTotalRows
                                        label={totalRowsLabel}
                                        totalRows={totalRows}
                                    />
                                )}
                                {isSelectedCountPresent && (
                                    <PaginationTotalSelected count={selectedCount} {...selected} />
                                )}
                            </Row>
                        </Col>
                    )}
                </Row>
            </PaginationStyled>
        );
    }
}

export default Pagination;
