import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';

import Button from 'ravenjs/lib/Button';
import Col from 'ravenjs/lib/Col';
import FaIcon from 'ravenjs/lib/FaIcon';
import Input from 'ravenjs/lib/Input';
import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { callFunc } from 'ravenjs/utils/actions';
import { sanitizeMarkup } from 'ravenjs/utils/sanitize';

import _isEmpty from 'lodash/isEmpty';
import FormGroupTitle from './FormGroupTitle';
import DisplayRequiredWithIconField from './DisplayRequiredWithIconField';

class FileUploadField extends React.Component {
    static propTypes = {
        /**
         * If `true`, the field is disabled.
         */
        disabled: PropTypes.bool,
        /**
         * The schema object for identifying the field.
         */
        idSchema: PropTypes.object.isRequired,
        /**
         * The name for the field.
         */
        name: PropTypes.string.isRequired,
        /**
         * Callback fired on the change event of the input element.
         */
        onChange: PropTypes.func.isRequired,
        /**
         * If `true`, the field will be readonly.
         */
        readonly: PropTypes.bool,
        /**
         * The form's registry object, containing the registered custom fields and widgets.
         */
        registry: PropTypes.object.isRequired,
        /**
         * Is this a required field?
         */
        required: PropTypes.bool,
        /**
         * The JSON Schema for this field.
         */
        schema: PropTypes.object.isRequired,
        /**
         * The JSON uiSchema for this field.
         */
        uiSchema: PropTypes.object.isRequired,
        /**
         * The value for this field.
         */
        value: PropTypes.object,
        /**
         * Callback fired on the blur event of the input element.
         */
        onBlur: PropTypes.func,
        /**
         * formContext of the input element.
         */
        formContext: PropTypes.object,
        /**
         * formData of the input element.
         */
        formData: PropTypes.object,
    };

    static defaultProps = {
        disabled: null,
        readonly: null,
        required: null,
        value: null,
        onBlur: null,
        formContext: null,
        formData: null,
    };

    // Create a reference to the hidden `input` DOM element.
    hiddenFileInputRef = React.createRef();

    constructor(props) {
        super(props);
        // TODO: Switch to use `useSate` hook later on...
        this.state = {
            // Create an empty files list.
            filesList: [],
        };
    }

    /**
     * Get the `ui:options` for the current field.
     *
     * @method _getUiOptions
     * @private
     * @param  {Object}      schema The schema to extract the options from
     * @return {Object}             The extracted options
     */
    _getUiOptions = schema => _get(schema, 'ui:options', {});

    /**
     * The label for the rendered `Input` component.
     *
     * @method getInputLabel
     * @return {string}
     */
    getInputLabel = () => {
        const { uiSchema, value } = this.props;
        const { filesList } = this.state;
        // Determine the value based on current formData or internal filesList.
        const files = value || filesList;
        // Get the uiOptions
        const options = this._getUiOptions(uiSchema);
        const { chooseFileLabel = 'Select a file...', notHandleList } = options;
        const count = (files && files.length) || 0;
        // Based on the `count` value, update the label.
        if (notHandleList) {
            return _get(this.props, 'formData.[0].name', chooseFileLabel);
        }

        if (!count) {
            return chooseFileLabel;
        } else if (count > 1) {
            return `${count} files selected.`;
        }
        return _get(files, '[0].name', '1 file selected.');
    };

    /**
     * Styles for the hidden `input` element
     *
     * @method getHiddenInputStyle
     * @return {Object}
     */
    getHiddenInputStyle = () => ({
        height: 38,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: -1,
    });

    handleOnUploadClick = event => {
        event.preventDefault();
        const { current } = this.hiddenFileInputRef;
        // Invoke the input click() event.
        current && typeof current.click === 'function' && current.click();
    };

    handleOnInputChange = event => {
        // Extract the `onChange` handler from props.
        const { onChange } = this.props;
        const setParentState = _get(this.props, 'formContext.handlers.setParentState');
        // Extract the files out of the file input.
        const { current } = this.hiddenFileInputRef;
        const files = (current && current.files) || {};
        // Update the current files list.
        this.setState(
            () => ({
                filesList: files,
            }),
            () => {
                // Invoke the `onChange` handler.
                typeof onChange === 'function' && onChange(files);
            }
        );
        // The use case for this is that if you need to know from the parent if a file has
        // been uploaded to the input field. That way from your parent you can do other checks.
        // For example, if you need a button disabled in your form, you can set the state
        // in order to know when the file has a value. See `CompaniesDeactivate.js` for an example.
        callFunc(setParentState, { selectedFile: current && current.files });
    };

    handleOnInputClick = event => {
        event.target.value = null;
    };

    render() {
        const {
            disabled: disabledProps,
            idSchema,
            name,
            readonly,
            required,
            schema,
            uiSchema,
            onBlur,
            formContext,
            formData,
        } = this.props;
        // Determine if the field is disabled
        const disabled = readonly || disabledProps;
        // Extract couple of fields from the props.
        const options = this._getUiOptions(uiSchema);
        const title = _get(schema, 'title') || name;
        const helpText = _get(schema, 'helpText');
        const disclaimer = _get(schema, 'disclaimer');
        const customDisclaimer = _get(schema, 'customDisclaimer');
        const pristine = _get(formContext, 'pristine', true);
        const taintedFields = _get(formContext, 'taintedFields', []);
        const { $id: id } = idSchema;

        let showRequired = false;
        if (!_isEmpty(taintedFields)) {
            showRequired =
                (!pristine || taintedFields.includes(id)) && required && _isEmpty(formData);
        }

        const {
            accept = '*',
            multiple = false,
            btnProps = {},
            btnLabel = 'Upload',
            showIcon = true,
        } = options;
        // Render the `FileField` component.
        return (
            <>
                <Row width="auto">
                    <Col size={12}>
                        <FormGroupTitle
                            helpText={helpText}
                            id={id}
                            required={showRequired}
                            title={title}
                        />
                    </Col>
                    <Col style={{ position: 'relative' }}>
                        <Input
                            onChange={() => {}}
                            onClick={this.handleOnUploadClick}
                            required={required}
                            type="text"
                            value={this.getInputLabel()}
                            showRequired={showRequired}
                            onBlur={
                                typeof onBlur === 'function' &&
                                (event => onBlur(id, event.target.value))
                            }
                        />
                        <input
                            accept={accept}
                            defaultValue=""
                            disabled={disabled}
                            multiple={multiple}
                            onChange={this.handleOnInputChange}
                            onClick={this.handleOnInputClick}
                            ref={this.hiddenFileInputRef}
                            required={required}
                            style={this.getHiddenInputStyle()}
                            tabIndex={-1}
                            type="file"
                            onBlur={
                                typeof onBlur === 'function' &&
                                (event => onBlur(id, event.target.value))
                            }
                        />
                    </Col>
                    <Col size="auto">
                        <Button
                            disabled={disabled}
                            onClick={this.handleOnUploadClick}
                            type="button"
                            {...btnProps}
                        >
                            {showIcon && <FaIcon icon={faUpload} margin="0 5px 0 0" />}
                            {btnLabel}
                        </Button>
                    </Col>
                    {disclaimer && !customDisclaimer && (
                        <Col size={12}>
                            <Typography
                                fontFamily="Roboto"
                                color="#899AA8"
                                dangerouslySetInnerHTML={sanitizeMarkup(disclaimer)}
                            />
                        </Col>
                    )}
                    {customDisclaimer}
                </Row>
                {showRequired && <DisplayRequiredWithIconField />}
            </>
        );
    }
}

export default FileUploadField;
