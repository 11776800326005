import _trimEnd from 'lodash/trimEnd';

/**
 * Trim out empty extensions from phone numbers.
 *
 * @param {string}    phone
 * @return {string}
 */
export const trimPhoneNumber = phone => {
    if (typeof phone === 'string') {
        const phrase = phone.replace(/_/g, '');
        const extPhrase = /ext(.*)/;
        const ext = extPhrase.exec(phrase);
        if (ext && /\d/.test(ext[1])) {
            return phrase;
        }
        return _trimEnd(phrase.replace(/ext.*$/, ''));
    }
    return '';
};
