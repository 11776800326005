import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CrossIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <path
                fill={fill}
                fillRule="evenodd"
                d="M24.9428 7.0572C25.4635 7.5779 25.4635 8.42212 24.9428 8.94281L8.94277 24.9428C8.42207 25.4635 7.57785 25.4635 7.05715 24.9428C6.53645 24.4221 6.53645 23.5779 7.05715 23.0572L23.0572 7.0572C23.5779 6.5365 24.4221 6.5365 24.9428 7.0572Z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                d="M7.05715 7.0572C7.57785 6.5365 8.42207 6.5365 8.94277 7.0572L24.9428 23.0572C25.4635 23.5779 25.4635 24.4221 24.9428 24.9428C24.4221 25.4635 23.5779 25.4635 23.0572 24.9428L7.05715 8.94281C6.53645 8.42212 6.53645 7.5779 7.05715 7.0572Z"
            />
        </SvgIcon>
    );
};

CrossIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CrossIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '20',
    viewBox: '0 0 32 32',
    width: '20',
};

export default CrossIcon;
