import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const MoreVerticalIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        {...rest}
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.0007 13C12.553 13 13.0007 12.5523 13.0007 12C13.0007 11.4477 12.553 11 12.0007 11C11.4485 11 11.0007 11.4477 11.0007 12C11.0007 12.5523 11.4485 13 12.0007 13Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.0007 6C12.553 6 13.0007 5.55228 13.0007 5C13.0007 4.44772 12.553 4 12.0007 4C11.4485 4 11.0007 4.44772 11.0007 5C11.0007 5.55228 11.4485 6 12.0007 6Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.0007 20C12.553 20 13.0007 19.5523 13.0007 19C13.0007 18.4477 12.553 18 12.0007 18C11.4485 18 11.0007 18.4477 11.0007 19C11.0007 19.5523 11.4485 20 12.0007 20Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);

MoreVerticalIcon.propTypes = {
    viewBox: string,
    width: string,
    height: string,
    color: string,
};

MoreVerticalIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default MoreVerticalIcon;
