import _get from 'lodash/get';

import INITIAL_STATE from './initialState';

/**
 * Reducer helper for handling the loading state (success).
 *
 * @method isLoadingSuccess
 * @param  {Object}         state  The current state
 * @param  {Object}         action The dispatched action
 * @return {Object}                The new state
 */
export const isLoadingSuccess = (state, action) => ({
    ...state,
    loading: _get(action, 'payload.loading'),
    text: _get(action, 'payload.text') || state.text,
});

/**
 * Reducer helper for catching the auth logout action.
 *
 * @method logoutSuccess
 * @return {Object}      The updated state
 */
export const logoutSuccess = () => INITIAL_STATE;
