import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';

import * as brandingActions from './actions';
import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'branding' redux handlers.
 *
 * @type {Object}
 */
const branding = {
    [brandingActions.fetchBranding.SUCCESS]: helpers.fetchBrandingSuccess,
    [brandingActions.updatePartnerBranding.SUCCESS]: helpers.fetchBrandingSuccess,
    [brandingActions.fetchBrandingByDomain.SUCCESS]: helpers.fetchBrandingSuccess,
    [brandingActions.fetchCustomLoginWidget.SUCCESS]: helpers.fetchCustomLoginWidgetSuccess,
    [brandingActions.updateCustomLoginWidget.SUCCESS]: helpers.fetchCustomLoginWidgetSuccess,
    [brandingActions.fetchDefaultCustomLoginWidget.SUCCESS]:
        helpers.fetchDefaultCustomLoginWidgetSuccess,
    [brandingActions.setIsUsingTemporaryTheme.SUCCESS]: helpers.setIsUsingTemporaryThemeSuccess,
};

// Create and export the 'branding' reducer.
export default createReducer(INITIAL_STATE, branding);

/**
 * Get the company branding info
 *
 * @method getBranding
 * @param  {Object}     state The current state
 * @return {Array}
 */
export const getBranding = state => _get(state, 'branding', {});

/**
 * Get the branding config for the application
 *
 * @method getConfig
 * @param  {Object}   state The current state
 * @return {Array}
 */
export const getConfig = createSelector([getBranding], branding => _get(branding, 'config', {}));

/**
 * Get the theme for the application
 *
 * @method getTheme
 * @param  {Object}   state The current state
 * @return {Array}
 */
export const getTheme = createSelector([getBranding], branding => _get(branding, 'theme', {}));

/**
 * Get the logo url
 *
 * @method getLogoUrl
 * @param  {Object}     state
 * @return {String}
 */
export const getLogoUrl = createSelector([getBranding], branding =>
    _get(branding, 'config.logoUrl', '')
);

/**
 * Get the login header text
 *
 * @method getLoginHeader
 * @param  {Object}     state
 * @return {String}
 */
export const getLoginHeader = createSelector([getBranding], branding =>
    _get(branding, 'config.loginHeader', '')
);

/**
 * Get the signoutForwarding
 *
 * @method getSignoutForwarding
 * @param  {Object}     state
 * @return {boolean}
 */
export const getSignoutForwarding = createSelector([getBranding], branding =>
    _get(branding, 'config.signoutForwarding', false)
);

/**
 * Get the getLogoutUrl
 *
 * @method getLogoutUrl
 * @param  {Object}     state
 * @return {String}
 */
export const getLogoutUrl = createSelector([getBranding], branding =>
    _get(branding, 'config.logoutUrl', '')
);

/**
 * Get the login logo url
 *
 * @method getLoginLogoUrl
 * @param  {Object}     state
 * @return {String}
 */
export const getLoginLogoUrl = createSelector([getBranding], branding =>
    _get(branding, 'config.loginLogoUrl', '')
);

/**
 * Get the company's branding name
 *
 * @method getCompanyBrandingName
 * @param  {Object}     state
 * @return {String}
 */
export const getCompanyBrandingName = createSelector([getBranding], branding =>
    _get(branding, 'config.companyName', '')
);

/**
 * Get the platform name
 *
 * @method getPlatformName
 * @param  {Object}     state
 * @return {String}
 */
export const getPlatformName = createSelector([getBranding], branding =>
    _get(branding, 'platformName', '')
);

/**
 * Get styled for the given typography
 *
 * @param   {string}    typography
 * @return  {Object}
 */
export const getTypographyStyles = typography =>
    createSelector([getTheme], theme => _get(theme, `typography.${typography}`, {}));

/**
 * Get the main partner name
 *
 * @method getMainPartnerName
 * @param  {Object}     state
 * @return {String}
 */
export const getMainPartnerName = createSelector([getBranding], branding =>
    _get(branding, 'mainPartnerName', '')
);

/**
 * Get root company logo url
 *
 * @method getRootCompanyLogoUrl
 * @param  {Object}     state
 * @return {String}
 */
export const getRootCompanyLogoUrl = createSelector([getBranding], branding =>
    _get(branding, 'config.mineralLogoUrl', '')
);

/**
 * Is logged in partner has custom logo
 *
 * @method hasCustomLogo
 * @param  {Object}     state
 * @return {String}
 */
export const hasCustomLogo = createSelector([getBranding], branding =>
    _get(branding, 'config.isCustomLogo', false)
);

/**
 * Get value for White Logo background flag
 *
 * @method hasWhiteLogoBackground
 * @param  {Object}     state
 * @return {Boolean}
 */
export const hasWhiteLogoBackground = createSelector([getBranding], branding =>
    _get(branding, 'config.whiteLogoBackground', true)
);

/**
 * Get the custom login widget configuration settings
 *
 * @method getCustomLoginWidgetConfig
 * @param  {Object}   state
 * @return {Array}
 */
export const getCustomLoginWidgetConfig = createSelector([getBranding], branding =>
    _get(branding, 'customLoginWidget.partner', INITIAL_STATE.customLoginWidget.partner)
);

/**
 * Get the custom login widget configuration settings
 *
 * @method getCustomLoginWidgetConfig
 * @param  {Object}   state
 * @return {Array}
 */
export const getDefaultCustomLoginWidgetConfig = createSelector([getBranding], branding =>
    _get(branding, 'customLoginWidget.default', INITIAL_STATE.customLoginWidget.default)
);

/**
 * Get the main partner name
 *
 * @method getMainPartnerName
 * @param  {Object}     state
 * @return {Boolean}
 */
export const getIsUsingTemporaryTheme = createSelector([getBranding], branding =>
    _get(branding, 'isUsingTemporaryTheme', false)
);
