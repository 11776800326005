import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'ravenjs/constants/colors';
import SvgIcon from '../SvgIcon';

const TrashIcon = ({ fill, setRef, ...rest }) => (
    <SvgIcon {...rest} ref={setRef} width="14" height="19">
        <path
            fill={fill}
            fillRule="evenodd"
            d="M12.45 6.05v11.303c0 .913-.618 1.647-1.415 1.647H2.767c-.798 0-1.416-.734-1.416-1.647V6.05h11.1zM9.48 7.432c-.194 0-.35.164-.35.366v8.649c0 .202.156.366.35.366.192 0 .349-.164.349-.366V7.798c0-.202-.157-.366-.35-.366zm-2.58 0c-.192 0-.349.164-.349.366v8.649c0 .202.157.366.35.366.193 0 .35-.164.35-.366V7.798c0-.202-.157-.366-.35-.366zm-2.578 0c-.193 0-.35.164-.35.366v8.649c0 .202.157.366.35.366.194 0 .35-.164.35-.366V7.798c0-.202-.156-.366-.35-.366zm5.094-5.22c0-.562-.427-.972-.974-.972H4.435c-.547 0-.975.41-.975.971v1.064h-.722V2.211C2.738 1.242 3.49.5 4.435.5h4.007c.945 0 1.696.742 1.696 1.711v1.064h2.806c.494 0 .894.414.894.925 0 .51-.4.925-.894.925H.857c-.493 0-.894-.414-.894-.925 0-.51.4-.925.894-.925h8.56z"
        />
    </SvgIcon>
);

TrashIcon.propTypes = {
    fill: PropTypes.string,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
};

TrashIcon.defaultProps = {
    fill: COLORS.GREY_2,
    setRef: null,
    viewBox: '0 0 14 19',
};

export default TrashIcon;
