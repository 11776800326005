import { arrayOf, bool, func, object, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { isEmpty } from 'ravenjs/utils/lodash';
import { getThemeProps } from 'ravenjs/utils/theme';
import Responsive from 'ravenjs/lib/Responsive';

import SubNavActions from 'components/SubNavActions';
import SubNavItems from 'components/SubNavItems';
import SubNavTitle from 'components/SubNavTitle';
import SubNavigationBackButton from 'components/SubNavigationBackButton';
import { hasSubnav } from 'utils/navigation';

import SubNavigationStyled from './SubNavigationStyled';

const SubNavigationItemsStyled = styled.div`
    flex-grow: 1;
    height: ${getThemeProps('AppNavigation.height')}px;
    margin-right: 10px;
    overflow: auto hidden;
`;

const SubNavigation = ({
    ActionsComponent,
    history,
    isImpersonatingUser,
    items,
    show,
    caption,
    subtitle,
    title,
    showBackButton,
    onBackButtonClick,
    padding,
    iPadView,
    titleStyle,
    onTitleClick,
}) => {
    return (
        hasSubnav(history) && (
            <>
                {!iPadView && (
                    <Responsive size="md" value="greater">
                        <SubNavigationStyled
                            className="impersonatingBar"
                            isImpersonatingUser={isImpersonatingUser}
                            show={show}
                            padding={padding}
                        >
                            {showBackButton && (
                                <SubNavigationBackButton onBackButtonClick={onBackButtonClick} />
                            )}
                            <SubNavTitle
                                caption={caption}
                                subtitle={subtitle}
                                title={title}
                                titleStyle={titleStyle}
                                onTitleClick={onTitleClick}
                            />
                            <SubNavigationItemsStyled>
                                <SubNavItems items={items} />
                            </SubNavigationItemsStyled>
                            {ActionsComponent && (
                                <SubNavActions>
                                    <ActionsComponent />
                                </SubNavActions>
                            )}
                        </SubNavigationStyled>
                    </Responsive>
                )}
                <Responsive size="md" value="less">
                    <>
                        <SubNavigationStyled
                            className="impersonatingBar"
                            isImpersonatingUser={isImpersonatingUser}
                            overflowX="visible"
                            show={show}
                            padding={padding}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    height: '100%',
                                }}
                            >
                                {showBackButton && (
                                    <SubNavigationBackButton
                                        onBackButtonClick={onBackButtonClick}
                                    />
                                )}
                                <SubNavTitle caption={caption} subtitle={subtitle} title={title} />
                            </div>
                            {ActionsComponent && (
                                <SubNavActions>
                                    <ActionsComponent />
                                </SubNavActions>
                            )}
                        </SubNavigationStyled>
                        {!isEmpty(items) && (
                            <SubNavigationStyled
                                className="impersonatingBar"
                                isImpersonatingUser={isImpersonatingUser}
                                show={show}
                                padding={padding}
                            >
                                <SubNavItems items={items} justifyContent="center" />
                            </SubNavigationStyled>
                        )}
                    </>
                </Responsive>
            </>
        )
    );
};

SubNavigation.propTypes = {
    ActionsComponent: oneOfType([string, func, object]),
    caption: string,
    history: object.isRequired,
    isImpersonatingUser: bool,
    items: arrayOf(
        shape({
            title: string,
            to: string,
        })
    ),
    show: bool,
    subtitle: string,
    title: string,
    showBackButton: bool,
    onBackButtonClick: func,
    padding: string,
    iPadView: bool,
    titleStyle: object,
    onTitleClick: func,
};

SubNavigation.defaultProps = {
    ActionsComponent: null,
    caption: '',
    isImpersonatingUser: false,
    items: [],
    show: false,
    subtitle: '',
    title: '',
    showBackButton: false,
    onBackButtonClick: null,
    padding: '0 1rem',
    iPadView: false,
    titleStyle: {},
    onTitleClick: null,
};

export default SubNavigation;
