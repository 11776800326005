import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { callFunc } from 'ravenjs/utils/actions';
import { genID } from 'ravenjs/utils/generate';
import Typography from 'ravenjs/lib/Typography';
import TextArea from 'ravenjs/lib/TextArea/TextArea';
import Button from 'ravenjs/lib/Button';

import { DEFAULT_COLORS } from 'constants/colors';
import {
    THUMBS_DOWN_OPTIONS,
    THUMBS_FEEDBACK_TITLE,
    FEEDBACK_MAX_CHARACTERS,
} from 'constants/compliance';

const CheckboxOptions = styled.div`
    position: relative;
    margin: 1rem 0;
`;

const CheckboxOptionsLabel = styled(Typography)`
    display: block;
    margin-bottom: 0.25rem;
    font-weight: 400 !important;
`;

const TextAreaWrapper = styled.div`
    position: relative;
    margin: 0 0 1.5rem 0;
`;

const SubmitFeedbackActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
`;

const ThumbsFeedback = ({
    actions,
    options,
    handleExternalCheckboxChange,
    handleExternalTextChange,
    textBoxLabel,
}) => {
    const [checkboxes, setCheckboxes] = useState(
        options.map(option => ({ ...option, checked: false }))
    );
    const [comments, setComments] = useState('');
    const [colorMaxChar, setColorMaxChar] = useState('');
    const [currentSelectedCheckboxes, setCurrentSelectedCheckboxes] = useState([]);

    const handleCheckboxChange = id => {
        const updatedCheckboxes = checkboxes.map(checkbox =>
            checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
        );
        setCheckboxes(updatedCheckboxes);

        const selectedCheckboxes = updatedCheckboxes.filter(checkbox => checkbox.checked);

        setCurrentSelectedCheckboxes(selectedCheckboxes);

        handleExternalCheckboxChange(selectedCheckboxes);
    };

    const handleCommentsChange = event => {
        const { value } = event.target;

        if (!value || (value && value.length <= FEEDBACK_MAX_CHARACTERS)) {
            setComments(value);
            setColorMaxChar(DEFAULT_COLORS.GREY_20);
        } else {
            setColorMaxChar(DEFAULT_COLORS.RED_2);
        }

        handleExternalTextChange(event.target.value);
    };

    return (
        <>
            <Typography type="modalTitle">{THUMBS_FEEDBACK_TITLE}</Typography>
            <CheckboxOptions>
                {checkboxes.map(checkbox => (
                    <div key={checkbox.id}>
                        <CheckboxOptionsLabel type="defaultRoboto">
                            <input
                                data-label={checkbox.label}
                                type="checkbox"
                                checked={checkbox.checked}
                                onChange={() => handleCheckboxChange(checkbox.id)}
                                style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                            />
                            {checkbox.label}
                        </CheckboxOptionsLabel>
                    </div>
                ))}
            </CheckboxOptions>
            <Typography type="pBody">{textBoxLabel}</Typography>
            <TextAreaWrapper>
                <TextArea
                    id="additional-comments"
                    name="additional-comments"
                    value={comments}
                    onChange={handleCommentsChange}
                    height="150px"
                />
                <Typography type="pSmallBody" color={colorMaxChar} style={{ marginTop: '0.25rem' }}>
                    {comments.length}/{FEEDBACK_MAX_CHARACTERS}
                </Typography>
            </TextAreaWrapper>
            <SubmitFeedbackActions>
                {actions.map(action => {
                    const {
                        actionHelpText,
                        disabled,
                        hideActionOnClick,
                        label,
                        showButtonLabel,
                        text,
                        type,
                        Component,
                        hide,
                        ...rest
                    } = action;

                    const handleOnClick = () => {
                        const { onClick } = action;
                        callFunc(onClick);
                    };

                    return (
                        <Button
                            disabled={
                                THUMBS_DOWN_OPTIONS.BUTTON_FEEDBACK === text &&
                                currentSelectedCheckboxes.length <= 0
                            }
                            key={genID()}
                            onClick={handleOnClick}
                            {...rest}
                        >
                            {text}
                        </Button>
                    );
                })}
            </SubmitFeedbackActions>
        </>
    );
};

ThumbsFeedback.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            disabled: PropTypes.bool,
            hideActionOnClick: PropTypes.bool,
            actionHelpText: PropTypes.string,
            label: PropTypes.string,
            showButtonLabel: PropTypes.bool,
            type: PropTypes.string,
            Component: PropTypes.node,
            hide: PropTypes.bool,
        })
    ),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.string.isRequired,
            checked: PropTypes.bool,
        })
    ).isRequired,
    handleExternalCheckboxChange: PropTypes.func.isRequired,
    handleExternalTextChange: PropTypes.func.isRequired,
    textBoxLabel: PropTypes.string.isRequired,
};

ThumbsFeedback.defaultProps = {
    actions: [],
};

export default ThumbsFeedback;
