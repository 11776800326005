const CONTENT_LIST = {
    EVENT: {
        VIEWED: 'ListViewed',
        FILTER: 'ListViewedFilter',
        PAGINATION: 'ListViewedPagination',
        HELP_VIEWED: 'HelpCenterSectionListViewed',
        HELP_FAQ_VIEWED: 'HelpCenterFaqListViewed',
        HELP_SEARCH_VIEWED: 'HelpCenterSearchListViewed',
    },
    EVENT_PROPERTIES: {
        DESCRIPTION: 'Compliance Section: ',
        TEMPLATE_DESCRIPTION: 'Template Section: ',
        HELP_DESCRIPTION: 'Help Section: ',
        HELP_FAQ_DESCRIPTION: 'Help FAQ Section',
        HELP_SEARCH_DESCRIPTION: 'Help Search Section',
    },
};

export default CONTENT_LIST;
