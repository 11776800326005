import { string } from 'prop-types';
import { withTheme } from 'styled-components';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ImageIcon = ({ height, viewBox, width }) => (
    <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="24/image-photo">
            <path
                id="Vector (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.50073 8C8.22459 8 8.00073 8.22386 8.00073 8.5C8.00073 8.77614 8.22459 9 8.50073 9C8.77687 9 9.00073 8.77614 9.00073 8.5C9.00073 8.22386 8.77687 8 8.50073 8ZM6.00073 8.5C6.00073 7.11929 7.12002 6 8.50073 6C9.88144 6 11.0007 7.11929 11.0007 8.5C11.0007 9.88071 9.88144 11 8.50073 11C7.12002 11 6.00073 9.88071 6.00073 8.5Z"
                fill="#282828"
            />
            <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00073 5C4.00073 4.44772 4.44845 4 5.00073 4H19.0007C19.553 4 20.0007 4.44772 20.0007 5V12.5858L16.7078 9.29289C16.3173 8.90237 15.6841 8.90237 15.2936 9.29289L4.64982 19.9367C4.27063 19.7946 4.00073 19.4288 4.00073 19V5ZM5.00171 22H5.00073H5.00171ZM5.00171 22H19.0007C20.6576 22 22.0007 20.6569 22.0007 19V15.001C22.0007 15.0003 22.0007 14.9997 22.0007 14.999V5C22.0007 3.34315 20.6576 2 19.0007 2H5.00073C3.34388 2 2.00073 3.34315 2.00073 5V19C2.00073 20.6565 3.34323 21.9994 4.99954 22M20.0007 15.4142V19C20.0007 19.5523 19.553 20 19.0007 20H7.41495L16.0007 11.4142L20.0007 15.4142Z"
                fill="#282828"
            />
        </g>
    </SvgIcon>
);

ImageIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

ImageIcon.defaultProps = {
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
};

export { ImageIcon as ImageIconUnwrapped };
export default withTheme(ImageIcon);
