import axios from 'axios';

import { DOWNTIME_WARNING_JSON_URL } from 'constants/api';

/**
 * Boostrap API endpoint
 *
 * @return {Promise}
 */
export const getBootstrapData = () =>
    axios({
        method: 'get',
        url: '/v2/bootstrap',
    });

/**
 * Fetch application menus
 *
 * @method fetchMenus
 * @return {Promise}
 */
export const fetchMenus = () =>
    axios({
        method: 'get',
        url: '/v2/menus',
    });

/**
 * Fetch list of states
 *
 * @method fetchStates
 * @return {Promise}
 */
export const fetchStates = () =>
    axios({
        method: 'get',
        url: '/v2/states',
    });

/**
 * Fetch downtime info
 *
 * @method fetchDowntimeInfoAPI
 * @return {Promise}
 */
export const fetchDowntimeInfoAPI = () =>
    axios({
        method: 'get',
        url: `${DOWNTIME_WARNING_JSON_URL}?timestamp=${Date.now()}`,
        ignoreAuthorization: true,
    });
