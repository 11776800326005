import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const FavoriteIcon = ({ color, strokeColor, height, viewBox, width, ...rest }) => (
    <SvgIcon cursor="pointer" width={width} height={height} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero">
                <path
                    d="M12,2c0.3,0,0.7,0.2,0.8,0.5L15.4,8l5.8,0.9C21.6,9,21.9,9.2,22,9.6c0.1,0.3,0,0.7-0.2,1l-4.2,4.3l1,6.1
                    c0.1,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.2-1,0.1L12,19l-5.2,2.9c-0.3,0.2-0.7,0.1-1-0.1c-0.3-0.2-0.4-0.6-0.4-0.9l1-6.1l-4.2-4.3
                    C2,10.3,1.9,9.9,2,9.6C2.1,9.2,2.4,9,2.8,8.9L8.6,8l2.6-5.5C11.3,2.2,11.6,2,12,2z"
                    stroke={strokeColor}
                />
            </g>
        </g>
    </SvgIcon>
);

FavoriteIcon.propTypes = {
    color: string,
    strokeColor: string,
    height: string,
    viewBox: string,
    width: string,
};

FavoriteIcon.defaultProps = {
    color: 'none',
    strokeColor: 'none',
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default FavoriteIcon;
