import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ContentTypeIcon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path
                fill="#080808"
                d="M4.246 10.19V1.653h7.63c.18 0 .326.126.326.282v7.966c0 .155-.146.281-.326.281h-.005c-.024.005-.049.008-.075.008h-7.55zm7.63 2.157c.18 0 .326-.126.326-.282v-1.06c0-.156-.146-.283-.326-.283-.18 0-.326.127-.326.282v.78H4.246v.563h7.63zm-8.504-.564c-.495 0-.9-.34-.919-.764l.002-.022-.001-.058c.028-.417.429-.75.918-.75h.223V1.654h-.222c-.868 0-1.573.61-1.573 1.36l.003.03c0 .692-.01 7.058 0 7.895-.002.016-.004.032-.004.049 0 .72.653 1.311 1.474 1.356.017.002.033.004.05.004h.272v-.564h-.223z"
                transform="translate(-388 -338) translate(388.666 253) translate(0 85.056)"
            />
        </SvgIcon>
    );
};

ContentTypeIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeIcon.defaultProps = {
    height: '15',
    viewBox: '0 0 15 15',
    width: '15',
};

export default ContentTypeIcon;
