import PropTypes from 'prop-types';
import React from 'react';
import _differenceBy from 'lodash/differenceBy';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _intersectionBy from 'lodash/intersectionBy';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';

import Box from 'ravenjs/lib/Box';
import Button from 'ravenjs/lib/Button';
import ButtonGroup from 'ravenjs/lib/ButtonGroup';
import Responsive from 'ravenjs/lib/Responsive';
import Col from 'ravenjs/lib/Col';
import Row from 'ravenjs/lib/Row';
import SearchInput from 'ravenjs/lib/SearchInput';
import Tooltip from 'ravenjs/lib/Tooltip';
import { calculateSort } from 'ravenjs/utils/sorting';
import { extractAPIConfig, fetchAPIData } from 'ravenjs/utils/apis';
import { getHandler } from 'ravenjs/utils/actions';
import { mergeObjects } from 'ravenjs/utils/merge';
import { warning } from 'ravenjs/utils/warning';

import TransferColumnList from './TransferColumnList';
import TransferColumnTitle from './TransferColumnTitle';

class Transfer extends React.Component {
    static propTypes = {
        /**
         * A map of action handlers used by rendered components.
         */
        actions: PropTypes.object,
        /**
         * A base URL for making the API calls.
         */
        baseUrl: PropTypes.string,
        /**
         * Properties passed down to the top-level `Box` component.
         */
        boxProps: PropTypes.object,
        /**
         * The main data for the body of the component.
         * Usually the data is fetched via the `schema.apis` property.
         */
        data: PropTypes.array,
        /**
         * An `oAuth` token to make API calls.
         */
        oAuthToken: PropTypes.string,
        /**
         * Callback fired upon `adding` and `removing` items
         * from the source and target columns.
         */
        onChange: PropTypes.func,
        /**
         * Display a 'Required' label for the component.
         */
        required: PropTypes.bool,
        /**
         * The JSON schema for the `Transfer` and its inner components.
         */
        schema: PropTypes.object,
        /**
         * Default values for pre-loading a sub-set of selected items.
         */
        selectedItems: PropTypes.array,
    };

    static defaultProps = {
        actions: null,
        baseUrl: null,
        boxProps: null,
        data: null,
        oAuthToken: null,
        onChange: null,
        required: null,
        schema: null,
        selectedItems: null,
    };

    // Create a ref for the search input.
    searchInputRef = React.createRef();

    constructor(props) {
        super(props);

        const { data, schema, selectedItems } = this.props;

        this.state = {
            initialTotalRecords: 0,
            itemKey: _get(schema, 'items.uniqueKey', 'id'),
            limit: _get(schema, 'pagination.limit', 5),
            maxLimit: _get(schema, 'pagination.maxLimit', 100),
            order: _get(schema, 'sorting.order', ''),
            orderBy: _get(schema, 'sorting.orderBy', ''),
            page: _get(schema, 'pagination.startPage', 0),
            queue: [],
            searchSpec: null,
            sendToAll: false,
            source: data || [],
            target: selectedItems || [],
            totalRecords: 0,
        };
    }

    componentDidMount() {
        // Fetch the initial data on mount.
        this.fetchData(this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        const { sendToAll, searchSpec } = this.state;
        const { baseUrl, schema } = this.props;
        const schemaDifferent = !_isEqual(schema, prevProps.schema);
        const baseUrlDifferent = !_isEqual(baseUrl, prevProps.baseUrl);
        const sendToAllDifferent = !sendToAll && sendToAll !== prevState.sendToAll;
        const searchSpecDifferent = searchSpec !== prevState.searchSpec;
        const schemaBaseUrlDifferent = schemaDifferent || baseUrlDifferent;

        if (schemaBaseUrlDifferent || sendToAllDifferent || searchSpecDifferent) {
            if (schemaBaseUrlDifferent) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState(
                    () => ({
                        initialTotalRecords: 0,
                        queue: [],
                        searchSpec: null,
                        sendToAll: false,
                        source: [],
                        target: [],
                        totalRecords: 0,
                    }),
                    () => {
                        // Fire the onChange handler.
                        this.handleTransferOnChange();
                        // Clear the search field.
                        this.searchInputRef.current &&
                            this.searchInputRef.current.clearSearchValue();
                        // Re-fetch the data
                        this.fetchData({
                            ...this.props,
                            page: 0,
                            schemaDifferent: schemaBaseUrlDifferent,
                            searchSpecDifferent,
                            sendToAllDifferent,
                        });
                    }
                );
            } else {
                this.fetchData({
                    ...this.props,
                    page: 0,
                    schemaDifferent: schemaBaseUrlDifferent,
                    searchSpecDifferent,
                    sendToAllDifferent,
                });
            }
        }
    }

    getAllLoaded = () => {
        const { searchSpec, searchSpecTotal, source, totalRecords } = this.state;

        if (searchSpec) {
            return source.length === searchSpecTotal;
        }

        return source.length >= totalRecords;
    };

    /**
     * Build the search spec based on the search input.
     * NOTE: We're assuming that the user enters in a `firstName{space}lastName`
     * into the search field. Thus building out the search spec as needed.
     *
     * Note taken from ticket THR-7649:
     * If the search text has no spaces, do as we are currently doing.
     * If we have a space in between, we assume its a name that the user
     * is searching on and chop it up. The last component in the string
     * is a search on last name and the preceding part is a search on first name.
     * For example, if the user enters "King Robert", it translates to a
     * search on firstName = King, lastName = Robert.
     *
     * @method getSearchSpec
     * @param  {string}      searchSpec    The search string entered by user
     * @param  {string}      searchSpecKey The search spec key to pass to API
     * @return {Object}                    A query params object
     */
    getSearchSpec = (searchSpec, searchSpecKey) => {
        // Split the search into an Array of values,
        // upon each space
        const splitSearch = String(searchSpec)
            .split(/[ ]+/)
            .filter(Boolean);
        // If there are no spaces in the search string,
        // then return as usual with the provided `searchSpecKey`.
        if (splitSearch.length === 1) {
            return {
                [searchSpecKey]: searchSpec,
            };
        }
        // If there are spaces in the search string,
        // determine the `firstName` and `lastName`.
        // NOTE: We are assuming the user is inputting a firstName{space}lastName.
        // `lastName` will be the very last element in the array,
        // after it has been split on ' ' (spaces).
        const lastName = splitSearch.pop();
        // `firstName` will be all the elements left in the array,
        // after popping the last element.
        const firstName = splitSearch.join(' ');
        // Return the
        return {
            firstName,
            lastName,
        };
    };

    handleClearAllItems = e => {
        e.stopPropagation();
        e.preventDefault();

        this.setState(prevState => {
            // Dedupe the target items when adding them back into the `source` list.
            const newSource = _uniqBy(
                [...prevState.source, ...prevState.target],
                prevState.itemKey
            );

            return {
                source: newSource || [],
                target: [],
                sendToAll: false,
                totalRecords: prevState.initialTotalRecords,
            };
        }, this.handleTransferOnChange);
    };

    handleSearchOnCancel = e => {
        e.preventDefault();
        // Nullify the search spec.
        return this.updateSearchSpec({ target: { value: '' } });
    };

    handleSearchOnClick = value => {
        // If value is present, let's update the search spec.
        return value && this.updateSearchSpec({ target: { value } });
    };

    handleSearchOnChange = value => {
        // If the value is empty, we want to clear the search spec.
        return value === '' && this.updateSearchSpec({ target: { value } });
    };

    handleSearchKeyPress = e => {
        // If we are pressing the 'Enter' key,
        // let's update the search spec.
        // (e.key === 'Enter') === (charCode === 13)
        if (e.charCode === 13) {
            e.preventDefault();
            e.target.blur();
            this.updateSearchSpec(e);
        }
    };

    handleTransferAllItemsToTarget = e => {
        e.preventDefault();
        e.stopPropagation();
        const { actions, schema } = this.props;
        // Extract the onAddAll ref from the `schema`.
        const onAddAllRef = _get(schema, 'onAddAllRef');
        const onAddAll = getHandler(actions, onAddAllRef);
        // We'll check if the onAddAll is a typeof function, and then invoke it.
        // This function should return an instanceof a Promise object.
        // This way the user can run some function before transfering
        // all of the items from source to target column.
        if (onAddAll && typeof onAddAll === 'function') {
            try {
                // Try to invoke the function and get the returned value from the promise.
                onAddAll().then(
                    update =>
                        update &&
                        this.setState(
                            prevState => ({
                                queue: [],
                                source: [],
                                target: [],
                                sendToAll: true,
                            }),
                            this.handleTransferOnChange
                        )
                );
            } catch (e) {
                // Throw a warning if the provided `onAddAll` function does not return a promise.
                warning(!e, [
                    `The passed in 'onAddAll' function to the <Transfer /> component does not return a 'Promise'.`,
                    `Please double-check the function, and either return a valid 'Promise' object,`,
                    `or remove the function reference from 'schema.onAddAllRef'.`,
                ]);
            }
        } else {
            this.setState(
                prevState => ({
                    queue: [],
                    source: [],
                    target: [],
                    sendToAll: true,
                }),
                this.handleTransferOnChange
            );
        }
    };

    handleTransferItemsInQueue = e => {
        e.preventDefault();
        e.stopPropagation();
        const { itemKey, queue, source, totalRecords } = this.state;

        this.setState(
            prevState => ({
                queue: [],
                source: _differenceBy(source, queue, itemKey) || [],
                target: [...prevState.target, ...prevState.queue],
                totalRecords: prevState.totalRecords && prevState.totalRecords - queue.length,
                searchSpecTotal: prevState.searchSpec
                    ? prevState.searchSpecTotal - queue.length
                    : prevState.searchSpecTotal,
            }),
            this.handleTransferOnChange
        );

        if (queue.length === source.length && totalRecords > 20) {
            this.loadMoreUsers();
        }
    };

    loadMoreUsers = () => {
        const { maxLimit, page, sendToAll, source } = this.state;

        const loadMore = source.length < maxLimit;

        if (!sendToAll && loadMore) {
            this.fetchData({ ...this.props, page });
        }
    };

    handleSourceItemSelection = item => () => {
        const { itemKey, queue, source } = this.state;
        const itemInSourceIdx = _findIndex(source, [itemKey, item[itemKey]]);
        const itemInQueueIdx = _findIndex(queue, [itemKey, item[itemKey]]);

        if (itemInQueueIdx === -1) {
            this.setState(prevState => ({
                queue: [...prevState.queue, source[itemInSourceIdx]],
            }));
        } else {
            this.setState(prevState => ({
                queue: [
                    ...prevState.queue.slice(0, itemInQueueIdx),
                    ...prevState.queue.slice(itemInQueueIdx + 1),
                ],
            }));
        }
    };

    handleTargetItemSelection = item => () => {
        const { itemKey, target } = this.state;
        // const itemInTargetIdx = target.findIndex(i => i[itemKey] === item[itemKey]);
        const itemInTargetIdx = _findIndex(target, [itemKey, item[itemKey]]);

        this.setState(prevState => {
            // Dedupe the target items when adding them back into the `source` list.
            const newSource = _uniqBy(
                [...prevState.source, target[itemInTargetIdx]],
                prevState.itemKey
            );
            // Update the `totalRecords` when adding items back into the `source` list.
            const totalRecords =
                prevState.source.length === newSource.length
                    ? prevState.totalRecords
                    : prevState.totalRecords + 1;

            return {
                source: newSource || [],
                target: [
                    ...prevState.target.slice(0, itemInTargetIdx),
                    ...prevState.target.slice(itemInTargetIdx + 1),
                ],
                totalRecords:
                    totalRecords >= prevState.initialTotalRecords
                        ? prevState.initialTotalRecords
                        : totalRecords,
            };
        }, this.handleTransferOnChange);
    };

    handleTransferOnChange = () => {
        const { onChange } = this.props;
        const { sendToAll, target, totalRecords } = this.state;

        return (
            typeof onChange === 'function' &&
            onChange({
                selected: target || [],
                sendToAll: typeof sendToAll === 'boolean' ? sendToAll : false,
                totalRecords,
            })
        );
    };

    handleLoadMore = e => {
        e.preventDefault();

        const { maxLimit, page, sendToAll, source } = this.state;

        const loadMore = source.length < maxLimit;

        if (!sendToAll && loadMore) {
            this.fetchData({ ...this.props, page });
        }
    };

    isItemChecked = item => {
        const { itemKey, queue } = this.state;

        return Boolean(_findIndex(queue, [itemKey, item[itemKey]]) !== -1);
    };

    isItemDisabled = item => {
        const { actions, schema } = this.props;
        const { itemKey, target } = this.state;
        // Extract the disabled ref from the `schema`.
        const disabledRef = _get(schema, 'items.disabled');
        const isDisabled = getHandler(actions, disabledRef);
        // Check to see if the item has been found in the target array.
        const foundInTarget = Boolean(_findIndex(target, [itemKey, item[itemKey]]) !== -1);
        if (foundInTarget) {
            return foundInTarget;
        }
        // Otherwise invoke the `isDisabled` callback.
        if (typeof isDisabled === 'function') {
            return isDisabled(item);
        }
        // Otherwise return `false`.
        return false;
    };

    fetchData = props => {
        const {
            baseUrl,
            data: dataProps,
            oAuthToken,
            page,
            schema,
            schemaDifferent,
            searchSpecDifferent,
        } = props;
        const { limit, order, orderBy, searchSpec: searchSpecState, sendToAll } = this.state;
        const apis = _get(schema, 'apis');
        const offset = page > 0 ? page * limit : 0;
        const sort = calculateSort(order, orderBy);
        const searchSpec = searchSpecState && searchSpecState !== '' ? searchSpecState : null;

        if (!sendToAll && _isEmpty(dataProps) && !_isEmpty(apis)) {
            // Throw a warning if there is no `oAuthToken` found.
            warning(!_isEmpty(oAuthToken), [
                `A <Transfer /> component requires an oAuthToken to fetch the requested data.`,
            ]);
            // Extract some info from the `schema`.
            const api = extractAPIConfig(apis, 'data');
            const paramsMap = _get(api, 'paramsMap', {});
            const request = _get(api, 'request', {});
            const {
                dataKey = 'data',
                limitKey = 'limit',
                offsetKey = 'offset',
                searchSpecKey = 'searchSpec',
                sortKey = 'sort',
                totalKey = 'totalRecords',
            } = paramsMap;
            const Authorization = _get(request, 'headers.Authorization');
            const BaseURL = _get(request, 'baseURL');
            // Build the `axios` config.
            const config = mergeObjects(request, {
                baseURL: baseUrl || BaseURL,
                headers: {
                    Authorization: Authorization || `Bearer ${oAuthToken}`,
                },
                params: {
                    [limitKey]: limit,
                    [offsetKey]: offset,
                    [sortKey]: sort,
                    ...this.getSearchSpec(searchSpec, searchSpecKey),
                },
            });
            // Catch case for the `oAuthToken`.
            if (!_isEmpty(oAuthToken) && !_isEmpty(config.baseURL)) {
                // Fetch the data and update the state.
                fetchAPIData(config, {
                    data: dataKey,
                    offset: offsetKey,
                    totalRecords: totalKey,
                })
                    .then(
                        ({
                            data: respData,
                            offset: respOffset,
                            totalRecords: respTotalRecords,
                        }) => {
                            const errorMessage = [
                                `<Transfer /> requires a list of objects as its internal data.`,
                                `The API call returned 'data' of type '${typeof respData}'.`,
                                `Please check your 'schema.apis.dataKey' value.`,
                            ];
                            // Throw a warning if the returned `data` is not an Array.
                            warning(Array.isArray(respData), errorMessage);
                            // If the returned `data` is not an Array, catch and return.
                            if (!Array.isArray(respData)) {
                                this.setState(prevState => ({
                                    page: 0,
                                    queue: [],
                                    source: [],
                                }));
                                // We don't want to continue if the
                                // returned `data` was not an Array.
                                return;
                            }
                            // At times the `totalRecords` gets returned as a String.
                            const totalRecords =
                                (respTotalRecords && parseInt(respTotalRecords, 10)) || 0;
                            // Update the state with new data.
                            this.setState(
                                prevState => {
                                    // This is the inital total records that we display.
                                    const initialTotalRecords = schemaDifferent
                                        ? totalRecords
                                        : prevState.initialTotalRecords || totalRecords;
                                    // Empty out the queue if the schema or search spec is different.
                                    const queue =
                                        schemaDifferent || searchSpecDifferent
                                            ? []
                                            : prevState.queue;
                                    // Determine the new target array.
                                    const target = schemaDifferent ? [] : prevState.target;
                                    // Build the new source based on the `sendToAll` func.
                                    const source = prevState.sendToAll
                                        ? []
                                        : offset === 0 && (searchSpec || searchSpec === null)
                                        ? [...respData]
                                        : [...prevState.source, ...respData];
                                    // Dedupe the source with the already selected target items.
                                    const dedupedSource = _differenceBy(
                                        source,
                                        target,
                                        prevState.itemKey
                                    );
                                    // Determine the searchSpecTotal.
                                    const searchSpecTotal = searchSpec
                                        ? totalRecords -
                                          _intersectionBy(source, target, prevState.itemKey).length
                                        : 0;

                                    return {
                                        initialTotalRecords,
                                        page: respOffset / limit + 1,
                                        queue,
                                        searchSpecTotal,
                                        source: dedupedSource,
                                        target,
                                        totalRecords: schemaDifferent
                                            ? totalRecords
                                            : prevState.totalRecords || totalRecords,
                                    };
                                },
                                () => {
                                    // Scroll to the top of the parent DOM element,
                                    // if the offset is set to `0`.
                                    if (offset === 0 && searchSpec) {
                                        this.scrollParentRef.scrollTop = 0;
                                    }
                                }
                            );
                        }
                    )
                    .catch(error => {
                        // Update the state.
                        // this.setState(() => ({ error }));
                    });
            }
        } else {
            // Throw a warning if the passed in `data` is not an Array.
            warning(!Array.isArray(dataProps), [
                `<Transfer /> requires a list of objects as its internal data.`,
                `The API call returned 'data' of type '${typeof dataProps}'.`,
                `Please check your 'schema.apis.dataKey' value.`,
            ]);
            // Update the state.
            this.setState(() => ({
                queue: [],
                source: Array.isArray(dataProps) ? dataProps : [],
                target: [],
                totalRecords: Array.isArray(dataProps) ? dataProps.length : 0,
            }));
        }
    };

    assignScrollParentRef = node => (this.scrollParentRef = node);

    updateSearchSpec = event => {
        // Extract the value from the current event
        const value = _get(event, 'target.value');
        // Update the state with the new search spec.
        return (value === '' || value) && this.setState(() => ({ searchSpec: value }));
    };

    renderSearch = () => {
        const { schema } = this.props;
        const { source, searchSpec, sendToAll } = this.state;
        // Extract the search spec from the schema.
        const searchSchema = _get(schema, 'search');
        // If the `searchSchema` is empty,
        // don't render anything.
        if (_isEmpty(searchSchema)) {
            return null;
        }
        // Extract the placeholder from the `searchSchema`.
        const placeholder = _get(searchSchema, 'placeholder', 'Search');
        // Build the search input props.
        const searchInputProps = {
            disabled: (!searchSpec && source.length === 0) || sendToAll,
            onCancel: this.handleSearchOnCancel,
            onChange: this.handleSearchOnChange,
            onClick: this.handleSearchOnClick,
            onKeyPress: this.handleSearchKeyPress,
            placeholder,
            ref: this.searchInputRef,
        };
        // Render the search field.
        return <SearchInput {...searchInputProps} />;
    };

    renderSourceButtons = () => {
        const { queue, searchSpec, sendToAll, source } = this.state;
        const { schema } = this.props;
        const addAllTooltip = _get(schema, 'tooltips.addAll');
        const addAllTooltipText = _get(addAllTooltip, 'text', '');
        const addAllTooltipProps = _get(addAllTooltip, 'props', {});
        const addAllDisabled = Boolean(source.length === 0 || searchSpec || sendToAll);
        // Build the buttons for the source column.
        const buttons = [
            {
                disabled: Boolean(queue.length === 0),
                label: 'Add',
                noMinWidth: true,
                onClick: this.handleTransferItemsInQueue,
            },
            {
                disabled: addAllDisabled,
                label: 'Add All',
                noMinWidth: true,
                onClick: this.handleTransferAllItemsToTarget,
            },
        ];
        // Render the target column's button group.
        return (
            <div>
                <Button {...buttons[0]} style={{ marginRight: 10 }}>
                    Add
                </Button>
                {!addAllDisabled && !_isEmpty(addAllTooltip) && addAllTooltipText ? (
                    <Tooltip content={addAllTooltipText} {...addAllTooltipProps}>
                        <Button {...buttons[1]}>Add All</Button>
                    </Tooltip>
                ) : (
                    <Button {...buttons[1]}>Add All</Button>
                )}
            </div>
        );
    };

    renderTargetButtons = () => {
        const { sendToAll, target } = this.state;
        const clearAllDisabled = sendToAll ? Boolean(!sendToAll) : Boolean(target.length === 0);

        const buttons = [
            {
                disabled: clearAllDisabled,
                label: 'Clear All',
                noMinWidth: true,
                onClick: this.handleClearAllItems,
                xsDisplayLabel: true,
            },
        ];
        // Render the target column's button group.
        return <ButtonGroup buttons={buttons} />;
    };

    render() {
        const {
            initialTotalRecords,
            itemKey,
            maxLimit,
            searchSpec,
            sendToAll,
            source,
            target,
            totalRecords,
        } = this.state;
        const { boxProps, required, schema } = this.props;
        const allLoaded = this.getAllLoaded();

        return (
            <Box {...boxProps}>
                <Row gutter={false}>
                    <Col size={{ sm: 12, md: 6, xs: 12 }}>
                        <TransferColumnTitle
                            count={source.length || 0}
                            initialTotalRecords={initialTotalRecords}
                            schema={schema}
                            sendToAll={sendToAll}
                            totalRecords={totalRecords}
                            type="source"
                        />
                        <Responsive size="md" value="less">
                            <Row gutter={false}>
                                <Col size={{ sm: 12, md: 6, xs: 12 }} gutter={false}>
                                    {this.renderSearch()}
                                </Col>
                                <Col padding="0" size={{ sm: 12, md: 6, xs: 12 }}>
                                    <Row margin="5px 0 0 0" gutter={false} justify="center">
                                        {this.renderSourceButtons()}
                                    </Row>
                                </Col>
                            </Row>
                        </Responsive>
                        <Responsive size="md" value="greater">
                            <Row gutter={false}>
                                <Col gutter={false}>{this.renderSearch()}</Col>
                                <Col gutter={false}>
                                    <Row gutter={false} justify="flex-end">
                                        {this.renderSourceButtons()}
                                    </Row>
                                </Col>
                            </Row>
                        </Responsive>
                        <Box
                            borderRadius="0"
                            height="200px"
                            ref={this.assignScrollParentRef}
                            margin="10px 0"
                            overflow="auto"
                            padding="0"
                        >
                            <TransferColumnList
                                allLoaded={allLoaded}
                                initialTotalRecords={initialTotalRecords}
                                isItemChecked={this.isItemChecked}
                                isItemDisabled={this.isItemDisabled}
                                itemKey={itemKey}
                                items={source}
                                loadMore={this.handleLoadMore}
                                maxLimit={maxLimit}
                                schema={schema}
                                searchSpec={searchSpec}
                                selectItem={this.handleSourceItemSelection}
                                sendToAll={sendToAll}
                                totalRecords={totalRecords}
                                type="source"
                            />
                        </Box>
                    </Col>
                    <Col size={{ sm: 12, md: 6, xs: 12 }}>
                        <TransferColumnTitle
                            count={target.length || 0}
                            initialTotalRecords={initialTotalRecords}
                            required={required}
                            schema={schema}
                            sendToAll={sendToAll}
                            totalRecords={totalRecords}
                            type="target"
                        />
                        <Responsive size="md" value="less">
                            <Row gutter={false}>{this.renderTargetButtons()}</Row>
                        </Responsive>
                        <Responsive size="md" value="greater">
                            <Row gutter={false} justify="flex-end">
                                {this.renderTargetButtons()}
                            </Row>
                        </Responsive>
                        <Box
                            borderRadius="0"
                            height="200px"
                            margin="10px 0"
                            overflow="auto"
                            padding="0"
                        >
                            <TransferColumnList
                                clearAllItems={this.handleClearAllItems}
                                initialTotalRecords={initialTotalRecords}
                                itemKey={itemKey}
                                items={target}
                                schema={schema}
                                selectItem={this.handleTargetItemSelection}
                                sendToAll={sendToAll}
                                totalRecords={totalRecords}
                                type="target"
                            />
                        </Box>
                    </Col>
                </Row>
            </Box>
        );
    }
}

export default Transfer;
