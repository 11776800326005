import axios from 'axios';
import Qs from 'qs';
import { isEmpty } from 'ravenjs/utils/lodash';

const MOCK_INSIGHT_CATEGORIES = [
    {
        id: '4df3h7fTIojatEcksj5kOf',
        name: 'Sexual Harrassment Prevention',
        topics: [
            {
                name: 'New York',
                id: '33yfWWdQVioSi16Pf3aADy',
            },
            {
                name: 'California',
                id: '2beb25TPXLFpOtFOzsY63k',
            },
        ],
    },
    {
        id: '5h7vnJntgBgba22QtzBQmq',
        name: 'Workplace Violence Prevention',
        topics: [
            {
                name: 'Alaska',
                id: '2DLBoqWkJexoQCCK5NCCds',
            },
            {
                name: 'California',
                id: '5BLgqGuCvI9PoCmEs39Yt8',
            },
        ],
    },
];

/**
 * API to search Contentful content
 *
 * @method fetchContentfulContentAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchContentfulContentAPI = params => {
    return axios({
        method: 'get',
        url: '/v2/contentful',
        params,
    });
};

/**
 * API to fetch Contentful content types
 *
 * @method fetchContentfulContentTypesAPI
 * @return {Promise}
 */
export const fetchContentfulContentTypesAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/contentful/content-types/`,
    });
};

/**
 * API to fetch Contentful categories
 *
 * @method fetchContentfulCategoriesAPI
 * @param  {Object} types
 * @return {Promise}
 */
export const fetchContentfulCategoriesAPI = types => {
    const contentType =
        types && types.contentType && types.contentType !== 'all'
            ? `&contentType=${types.contentType}`
            : '';
    const showEHS = types && types.showEHS ? `&showEHS=${types.showEHS}` : '';
    const onlyEHS = types && types.onlyEHS ? `&onlyEHS=${types.onlyEHS}` : '';
    const limit = types && types.limit ? `&limit=${types.limit}` : '';
    const offset = types && types.offset ? `&offset=${types.offset}` : '';
    const documentType = types && types.documentType ? `&documentType=${types.documentType}` : '';
    return axios({
        method: 'get',
        url: `/v2/contentful/category/?showAll=true&setOrder=true${contentType}${documentType}${showEHS}${onlyEHS}${limit}${offset}`,
    });
};

/**
 * API to fetch Contentful employee counts
 *
 * @method fetchContentfulEmployeeCountsAPI
 * @return {Promise}
 */
export const fetchContentfulEmployeeCountsAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/contentful/employee-count/`,
    });
};

/**
 * API to fetch Contentful content types
 *
 * @method fetchContentfulJurisdictionsAPI
 * @return {Promise}
 */
export const fetchContentfulJurisdictionsAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/contentful/jurisdiction/`,
    });
};

/**
 * TODO: Replace with the real API
 * API call to fetch the notices.
 *
 * @method fetchInsightsCategoriesList
 * @return {Promise}
 */
export const fetchInsightsCategoriesList = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(MOCK_INSIGHT_CATEGORIES);
        }, 100);
    });
};

/**
 * API to fetch a Contentful document
 *
 * @method fetchContentfulDocumentAPI
 * @param  {string} documentId
 * @return {Promise}
 */
export const fetchContentfulDocumentAPI = documentId =>
    axios({
        method: 'get',
        url: `/v2/contentful/documents/${documentId}`,
    });

/**
 * API to fetch a Contentful documents List
 *
 * @method fetchContentfulDocumentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentfulDocumentsAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/entries/categories/all`,
        params,
    });
};

/**
 * API to fetch Contentful documents for a topic
 *
 * @method fetchContentfulTopicDocumentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentfulTopicDocumentsAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/entries/categories`,
        params,
    });
};

/**
 * API to fetch Contentful documents for all topics
 *
 * @method fetchContentfulTopicsDocumentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentfulTopicsDocumentsAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/entries/documents/all`,
        params,
    });
};

/**
 * API to add selected item to Favorite list
 *
 * @method addUserFavItemAPI
 * @param  {Object} payload
 * @return {Promise}
 */
export const addUserFavItemAPI = payload => {
    const { resourceId, resourceType } = payload;
    const url = isEmpty(payload.url)
        ? `${window.location.pathname}${window.location.search}`
        : payload.url;

    return axios({
        method: 'post',
        url: `/v2/contentful/user-favorite-items`,
        data: {
            resourceId,
            resourceType,
            url,
        },
    });
};

/**
 * API to remove selected item to Favorite list
 *
 * @method removeUserFavItemAPI
 * @param  {Object} payload
 * @return {Promise}
 */

export const removeUserFavItemAPI = payload => {
    const { resourceId, resourceType } = payload;
    return axios({
        method: 'patch',
        url: `/v2/contentful/user-favorite-items?resourceId=${resourceId}&resourceType=${resourceType}`,
    });
};

/**
 * API to add selected item to Like or Dislike option
 *
 * @method addLikeDislikeItemAPI
 * @param  {Object} payload
 * @return {Promise}
 */
export const addLikeDislikeItemAPI = payload => {
    const { resourceId, resourceType, likeDislike, comments, options } = payload;
    return axios({
        method: 'post',
        url: `/v2/contentful/likes`,
        data: {
            comments,
            options,
            resourceId,
            resourceType,
            likeDislike,
        },
    });
};

/**
 * API to remove selected item to Like or Dislike option
 *
 * @method removeLikeDislikeItemAPI
 * @param  {Object} payload
 * @return {Promise}
 */
export const removeLikeDislikeItemAPI = payload => {
    const { resourceId } = payload;
    return axios({
        method: 'patch',
        url: `/v2/contentful/likes`,
        params: {
            resourceId,
            resourceType: 'entry',
        },
    });
};

/**
 * API to fetch a Contentful documents List
 *
 * @method fetchContentfulDocumentListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentfulDocumentListAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/documents/type`,
        params,
    });
};

/**
 * API to fetch contentTypes of topics
 *
 * @method fetchContentTypesAPI
 * @param  {string} payload
 * @return {Promise}
 */
export const fetchContentTypesAPI = payload => {
    const { contentType } = payload;

    return axios({
        method: 'get',
        url: `/v2/contentful/content-types/${contentType}`,
    });
};

/**
 * API to fetch Contentful documents by category
 *
 * @method fetchDocumentsByCategory
 * @param  {string} params
 * @return {Promise}
 */
export const fetchDocumentsByCategoryAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/entries/categories`,
        params,
    });
};

/**
 * API to fetch law-alerts content documents
 *
 * @method fetchLawAlertsContentAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchLawAlertsContentAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/law-alerts`,
        params,
    });
};

/**
 * API call to fetch all user favorite items.
 *
 * @param {Object}      params
 * @method fetchUserFavoriteItemsAPI
 * @return {Promise}
 */
export const fetchUserFavoriteItemsAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/user-favorite-items`,
        params,
    });
};

/**
 * API call to fetch all user favorite items by Resource Type and Id.
 *
 * @param {Object}    params
 * @method fetchUserFavoriteItemsByTypeAndIdAPI
 * @return {Promise}
 */
export const fetchUserFavoriteItemsByTypeAndIdAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/user-favorite-items/resources`,
        params,
    });
};

/**
 * API to delete user favorite item.
 *
 * @param {Object}      params
 * @method deleteUserFavoriteItemAPI
 * @return {Promise}
 */
export const deleteUserFavoriteItemAPI = params => {
    return axios({
        method: 'patch',
        url: `/v2/contentful/user-favorite-items`,
        params,
    });
};

/**
 * API call to fetch the notices.
 *
 * @method fetchInsightsCategoriesList
 * @return {Promise}
 */
export const fetchContentfulNewsletter = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                data: {
                    requestReferenceId: '350e18d4-a281-4b78-8af0-708645187782',
                    status: 'OK',
                    code: '200',
                    limit: '50',
                    offset: '0',
                    sort: '+id',
                    totalRecords: '2',
                    contentModels: [
                        {
                            id: 'hrLens',
                            type: 'HR Lens',
                        },
                        {
                            id: 'thinkHrCrunch',
                            type: 'Think HR Crunch',
                        },
                    ],
                },
            });
        }, 100);
    });
};

/**
 * API to fetch Contentful documents
 *
 * @method fetchDocuments
 * @param  {Object} params
 * @param  {string} state
 * @param  {string} id
 * @return {Promise}
 */
export const fetchDocumentsAPI = (params, state, id) => {
    let paramId = '';

    if (id) {
        paramId = `/${id}`;
    }

    return axios({
        method: 'get',
        url: `/v2/contentful/${state}${paramId}`,
        params,
    });
};

/**
 * API to fetch communication types
 *
 * @method fetchCommunicationTypesAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchCommunicationTypesAPI = params => {
    return axios({
        method: 'get',
        url: '/v2/contentful',
        params,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

/**
 * API to download document by id
 *
 * @method downloadDocumentAPI
 * @param  {string} params
 * @return {Promise}
 */
export const downloadDocumentAPI = params => {
    const url = params.isNewHelpCenter
        ? '/tools/v2/help-center/download-document'
        : '/tools/v1/generate-compliance-document';

    delete params.isNewHelpCenter;

    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url,
    });
};

/**
 * API to fetch Contentful Law Alerts
 *
 * @method fetchLawAlertsAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchLawAlertsAPI = params => {
    return axios({
        method: 'get',
        url: '/v2/contentful/entries/widget',
        params,
    });
};

/**
 * API to download HR Assessment report.
 *
 * @method downloadHrFitnnesTestReportAPI
 * @param  {string} url
 * @return {Promise}
 */
export const downloadHrFitnnesTestReportAPI = url => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `${url}&type=base64`,
    });
};

/**
 * API to fetch featured content
 *
 * @method fetchFeaturedContentAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchFeaturedContentAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/featured-content',
    });
};

export const fetchPublicFeaturedContentAPI = ({ entryId, token }) =>
    axios({
        method: 'get',
        params: {
            token,
        },
        url: `/v2/public/contentful/featured-content/${entryId}`,
    });

/**
 * API to fetch Contentful carousel content
 *
 * @method fetchContentfulDocumentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentfulCarouselContentAPI = params => {
    return axios({
        method: 'get',
        params,
        url: '/v2/contentful/carousel',
    });
};

/**
 * API to add related term
 *
 * @method fetchContentfulDocumentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const addRelatedTermAPI = params => {
    return axios({
        method: 'post',
        data: params,
        url: '/v2/contentful/searched-terms/related',
    });
};

/**
 * API to add visit item
 *
 * @method fetchContentfulDocumentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const addVisitItemAPI = params => {
    return axios({
        method: 'post',
        data: params,
        url: '/v2/contentful/visit-logs',
    });
};

/**
 * API to fetch upcoming webinar
 *
 * @method fetchUpcomingWebinarAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchUpcomingWebinarAPI = params => {
    return params.showClassicWebinars
        ? axios({
              type: 'staticJson',
              ignoreAuthorization: true,
              method: 'get',
              noBearer: true,
              url: '/json/webinars.json',
          })
        : axios({
              method: 'get',
              params,
              type: 'apiTools',
              noBearer: true,
              url: '/tools/v1/webinar/upcoming',
          });
};

/**
 * API to download contentful document.
 *
 * @method downloadContentfulDocumentAPI
 * @param  {string} url
 * @return {Promise}
 */
export const downloadContentfulDocumentAPI = url => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: false,
        params: {
            filters: {
                url,
            },
        },
        url: `/tools/v1/contentful/download-document`,
    });
};

export const getDocumentWithLogoAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: false,
        url: `/tools/v1/contentful/document/generate-logo-preview`,
        params,
    });
};

/**
 * API to fetch Resources Contentful documents
 *
 * @method fetchResourcesDocuments
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchResourcesDocumentsAPI = params => {
    return axios({
        method: 'get',
        url: '/v2/contentful/entries/section/all',
        params,
    });
};

/**
 * API to fetch safety resources
 *
 * @method fetchSafetyResourcesAPI
 * @return {Promise}
 */
export const fetchSafetyResourcesAPI = () => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: '/tools/v1/safety-resources',
    });
};

/**
 * API to fetch safety courses
 *
 * @method fetchSafetyCoursesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchSafetyCoursesAPI = params => {
    return axios({
        type: 'staticJson',
        ignoreAuthorization: true,
        method: 'get',
        noBearer: true,
        url: '/json/safety_courses.json',
        params,
    });
};

/**
 * API to fetch training categories
 *
 * @method fetchTrainingCategoriesAPI
 * @return {Promise}
 */
export const fetchTrainingCategoriesAPI = () => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: '/tools/v1/training/categories',
    });
};

/**
 * API to fetch help center sections
 *
 * @method fetchHelpCenterSectionsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterSectionsAPI = (params = {}) => {
    const { isNewHelpCenter = false } = params;
    const system = isNewHelpCenter ? 'v2' : 'v1';

    return axios({
        type: 'apiTools',
        method: 'get',
        url: `/tools/${system}/help-center/sections`,
    });
};

/**
 * API to fetch help center faq by section
 *
 * @method fetchHelpCenterFaqsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterFaqsAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        params,
        url: `/tools/v2/help-center/faqs`,
    });
};

/**
 * API to fetch help center categories by section
 *
 * @method fetchHelpCenterFaqsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterCategoriesBySectionAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        params,
        url: `tools/v2/help-center/category`,
    });
};

/**
 * API to fetch help center category
 *
 * @method fetchHelpCenterCategoryAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterCategoryAPI = params => {
    const { isNewHelpCenter = false } = params;
    const system = isNewHelpCenter ? '/authorit/' : '/';
    return axios({
        type: 'apiTools',
        method: 'get',
        url: `/tools/v1${system}help-center/category`,
        params,
    });
};

/**
 * API to fetch help center detail
 *
 * @method fetchHelpCenterDetailAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterDetailAPI = params => {
    const { isNewHelpCenter = false } = params;
    const system = isNewHelpCenter ? 'v2' : 'v1';

    return axios({
        type: 'apiTools',
        method: 'get',
        url: `/tools/${system}/help-center/detail`,
        params,
    });
};

/**
 * API call to download a docx with laws comparison between states.
 *
 * @method downloadCompareLawsByStatesAPI
 * @param  {Object} data
 * @return {Promise}
 */
export const fetchCompareLawsByStatesAPI = data => {
    return axios({
        data,
        method: 'post',
        url: '/tools/v1/contentful/law/comparison-tool',
        type: 'apiTools',
    });
};

/**
 * API to fetch information banner details from contentful
 *
 * @param   {string}     entryId
 * @param   {string}     companyId
 * @return  {Promise}
 */
export const fetchInformationBannerAPI = ({ entryId, companyId }) =>
    axios({
        method: 'get',
        params: {
            companyId,
            entryId,
        },
        url: `/v2/informationBanner/${companyId}/preview`,
    });

/**
 * API to fetch a Contentful EHS content
 *
 * @method fetchContentfulEHSContentAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentfulEHSContentAPI = params => {
    const { ehsContentType, id } = params;
    delete params.ehsContentType;
    delete params.id;
    return axios({
        method: 'get',
        params,
        url: `/v2/contentful/ehs/${ehsContentType}/${id}`,
    });
};

/**
 * API to fetch safety products
 *
 * @method fetchSafetyProductsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchSafetyProductsAPI = params => {
    return axios({
        type: 'staticJson',
        ignoreAuthorization: true,
        method: 'get',
        noBearer: true,
        url: '/json/safety_products.json',
        params,
    });
};

/**
 * API to fetch ehs topics by parent category
 *
 * @method fetchEHSTopicsByCategoryAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchEHSTopicsByCategoryAPI = params => {
    const { categoryId } = params;
    delete params.categoryId;
    return axios({
        method: 'get',
        params,
        url: `/v2/contentful/ehs/categories/${categoryId}/topics`,
    });
};

/**
 * API to fetch industries for ehs
 *
 * @method fetchEHSIndustriesAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchEHSIndustriesAPI = params => {
    return axios({
        method: 'get',
        url: '/v2/contentful/ehs/industries',
        params,
    });
};

/**
 * API to fetch content for ehs
 *
 * @method fetchEHSContentAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchEHSContentAPI = params => {
    return axios({
        method: 'get',
        url: '/v2/contentful/ehs',
        params,
    });
};

/**
 * API to fetch ehs content by topic
 *
 * @method fetchEHSContentAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchEHSContentByTopicAPI = params => {
    const { topicId } = params;
    delete params.topicId;
    return axios({
        method: 'get',
        url: `/v2/contentful/ehs/topic/${topicId}`,
        params,
    });
};

/**
 * API to fetch ehs banner by topic
 *
 * @method fetchEHSBannerByTopicAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchEHSBannerByTopicAPI = params => {
    const { topicId } = params;
    delete params.topicId;
    return axios({
        method: 'get',
        url: `/v2/contentful/ehs/topics/${topicId}/bannerAd`,
    });
};

/**
 * API to fetch safety manual template
 *
 * @method fetchSafetyManualTemplateAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchSafetyManualTemplateAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/contentful/ehs/safety-manual-template`,
    });
};

/**
 * API to fetch ehs content type route
 *
 * @method fetchEHSContentTypeRouteAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchEHSContentTypeRouteAPI = params => {
    const { entryId } = params;
    return axios({
        method: 'get',
        url: `/v2/contentful/ehs/content-type-route/${entryId}`,
    });
};

/**
 * API call to fetch the categories to help center.
 *
 * @method fetchHelpCenterCategoriesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterCategoriesAPI = params => {
    return axios({
        method: 'get',
        params,
        url: `/tools/v2/help-center/search/categories`,
        type: 'apiTools',
    });
};

/**
 * API call to fetch the search to help center.
 *
 * @method fetchHelpCenterSearchAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterSearchAPI = params => {
    return axios({
        params,
        method: 'get',
        url: `/tools/v2/help-center/search`,
        type: 'apiTools',
    });
};

/**
 * API call to fetch widget to dashboard to help center.
 *
 * @method fetchHelpCenterHomeWidgetAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterHomeWidgetAPI = params => {
    return axios({
        params,
        method: 'get',
        url: '/tools/v2/help-center/dashboard-widget',
        type: 'apiTools',
    });
};

/**
 * API to fetch on demand webinar
 *
 * @method fetchTrainingWebinarAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchTrainingWebinarAPI = params => {
    const { filters, type } = params;
    const label = type === 'topics' ? 'hr-topics' : 'how-to-training';

    return axios({
        method: 'get',
        params: { filters },
        type: 'apiTools',
        noBearer: true,
        url: `/tools/v1/webinar/on-demand/${label}`,
    });
};

/**
 * API to fetch last webinars
 *
 * @method fetchLastWebinarsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchLastWebinarsAPI = params => {
    return axios({
        method: 'get',
        params,
        type: 'apiTools',
        noBearer: true,
        url: '/tools/v1/webinar/on-demand/most-recent',
    });
};

/**
 * API to fetch webinars categories
 *
 * @method fetchWebinarsCategoriesAPI
 * @param  {string} type
 * @return {Promise}
 */
export const fetchWebinarsCategoriesAPI = type => {
    return axios({
        method: 'get',
        type: 'apiTools',
        noBearer: true,
        url: `/tools/v1/webinar/category/${type}`,
    });
};

/**
 * API to search Contentful additional ehs results
 *
 * @method fetchContentfulAdditionalEhsResultsAPI
 * @param  {string} params
 * @return {Promise}
 */
export const fetchContentfulAdditionalEhsResultsAPI = params => {
    return axios({
        method: 'get',
        url: '/v2/contentful/additionalEhsResultsInUpgradePackages',
        params,
    });
};

/**
 * API to fetch a Contentful law alerts for Preview Component
 *
 * @method fetchContentfulLawAlertsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentfulLawAlertsAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/entries/law-alerts/preview`,
        params,
    });
};

/**
 * API to download wistia wideo from Paligo
 *
 * @method downloadHelpCenterVideoAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const downloadHelpCenterVideoAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        data: params,
        url: `/tools/v2/help-center/download-video`,
        responseType: 'blob',
    });
};
/**
 * API call to fetch all user favorite items insights.
 *
 * @param {Object}    params
 * @method fetchUserFavoriteItemsInsightsAPI
 * @return {Promise}
 */
export const fetchUserFavoriteItemsInsightsAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/user-favorite-items/insights`,
    });
};

/**
 * API call to get the most recents documents to help center.
 *
 * @method fetchHelpCenterRecentDocumentsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterRecentDocumentsAPI = params => {
    return axios({
        params,
        method: 'get',
        url: '/tools/v2/help-center/section/hr-tools/recent-entries',
        type: 'apiTools',
    });
};

/**
 * API call to get release notes.
 *
 * @method fetchHelpCenterReleaseNotesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchHelpCenterReleaseNotesAPI = params => {
    return axios({
        params,
        method: 'get',
        url: '/tools/v2/help-center/release-note',
        type: 'apiTools',
    });
};

/**
 * API call to fetch feedback options.
 *
 * @method fetchFeedbackOptionsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchFeedbackOptionsAPI = params => {
    return axios({
        params,
        method: 'get',
        url: '/v2/contentful/likes/feedbacks',
    });
};
