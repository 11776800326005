import PropTypes from 'prop-types';
import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import DraftEditor from 'draft-js-plugins-editor';
import { setBlockData, getSelectionText, getSelectionEntity } from 'draftjs-utils';
import 'draft-js/dist/Draft.css';
import _findIndex from 'lodash/findIndex';
import {
    SelectionState,
    EditorState,
    ContentState,
    RichUtils,
    Modifier,
    AtomicBlockUtils,
    DefaultDraftBlockRenderMap,
} from 'draft-js';
import { Map } from 'immutable';
import htmlToDraft from 'html-to-draftjs';

import { LINE_BREAK_TOOLTIP_MESSAGE } from 'ravenjs/constants';
import Box from 'ravenjs/lib/Box';
import Divider from 'ravenjs/lib/Divider';
import Button from 'ravenjs/lib/Button';
import FaIcon from 'ravenjs/lib/FaIcon';
import Tooltip from 'ravenjs/lib/Tooltip';
import Popover from 'ravenjs/lib/Popover';
import PopoverContent from 'ravenjs/lib/PopoverContent';
import { callFunc, get, isEmpty, genID } from 'ravenjs/utils';

import { TEXT_ALIGNMENTS_MAP } from 'constants/style';
import REGEX_VALIDATE from 'constants/regex';

import InsertLinkModal from './InsertLinkModal';
import {
    fontStyles,
    ButtonStyle,
    ToolbarMenuIconListStyled,
    IconMenuDropdownStyled,
    TypographyStyled,
    ToolBarWrapper,
    ActionsWrapper,
    ToolGroupStyled,
    EditorWrapper,
} from './editorStyles';
import { DefaultEditorSchema } from './editorSchema';
import { InsertHorizontalLine } from './InsertHorizontalLine';
import EditorContext from './EditorContext/EditorContext';
import './Editor.scss';

const blockStyles = [
    'header-one',
    'header-two',
    'header-three',
    'header-four',
    'header-five',
    'header-six',
    'blockquote',
    'atomic',
];

const FormatWrapper = props => {
    const { children, type, currentContent } = props;

    const enhancedChildren = React.Children.map(children, child => {
        const { key: blockKey } = child;
        const currentContentBlock = currentContent.getBlockForKey(blockKey);
        const currentTextAlignStyle = !isEmpty(currentContentBlock.getData().get('text-align'))
            ? currentContentBlock.getData().get('text-align')
            : '';

        return React.cloneElement(child, { className: currentTextAlignStyle });
    });

    return (
        <div className="content">
            {(type === blockStyles[0] && (
                <h1 className="title is-1 marginHeading">{enhancedChildren}</h1>
            )) ||
                (type === blockStyles[1] && (
                    <h2 className="title is-2 marginHeading">{enhancedChildren}</h2>
                )) ||
                (type === blockStyles[2] && (
                    <h3 className="title is-3 marginHeading">{enhancedChildren}</h3>
                )) ||
                (type === blockStyles[3] && (
                    <h4 className="title is-4 marginHeading">{enhancedChildren}</h4>
                )) ||
                (type === blockStyles[4] && (
                    <h5 className="title is-5 marginHeading">{enhancedChildren}</h5>
                )) ||
                (type === blockStyles[5] && (
                    <h6 className="title is-6 marginHeading">{enhancedChildren}</h6>
                )) ||
                (type === blockStyles[6] && (
                    <blockquote className="editorBlockquote">{enhancedChildren}</blockquote>
                )) ||
                (type === blockStyles[7] && <div>{enhancedChildren}</div>) ||
                (type === blockStyles[7] && enhancedChildren.length === 1 && <hr />) ||
                (type === blockStyles[8] && (
                    <ul>
                        {enhancedChildren.map(child => {
                            return <li key={genID()}>{child}</li>;
                        })}
                    </ul>
                )) ||
                (type === blockStyles[9] && (
                    <ol>
                        {enhancedChildren.map(child => {
                            return <li key={genID()}>{child}</li>;
                        })}
                    </ol>
                ))}
        </div>
    );
};

const InlineStyleButton = ({ onToggle, tool, editorState }) => {
    const { customStyle, inlineStyle } = tool;
    const currentFocus = editorState.getSelection().getFocusKey();
    const currentStyle = editorState.getCurrentInlineStyle(currentFocus);
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockStartKey = selection.getStartKey();
    const blockState = contentState.getBlockMap().get(blockStartKey);
    const alignment = blockState.getData().get('text-align');
    const onMouseDown = e => {
        e.preventDefault();
        callFunc(onToggle, inlineStyle);
    };

    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    const hasStyle =
        currentStyle.has(tool.inlineStyle) ||
        blockType === tool.inlineStyle ||
        alignment === inlineStyle;

    return (
        <ButtonStyle
            border
            borderRadius="0px"
            customStyle={customStyle}
            hasStyle={hasStyle}
            noMinHeight
            noMinWidth
            onMouseDown={onMouseDown}
            type="button"
        >
            <FaIcon margin="0 7.5px 0 0" {...tool.icon} />
        </ButtonStyle>
    );
};

class Editor extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        width: PropTypes.string,
        editorState: PropTypes.object,
        restoreDefaultContent: PropTypes.bool,
        setRestoreDefaultContent: PropTypes.func,
        handlekeyEvents: PropTypes.array,
        handleBeforeInput: PropTypes.func,
        toolSchema: PropTypes.array,
        actions: PropTypes.object,
        disableLineBreaks: PropTypes.bool,
        retainPasteStyling: PropTypes.bool,
        editorValidation: PropTypes.bool,
        editorWrapperProps: PropTypes.object,
        hideHyperlinkCheckbox: PropTypes.bool,
        readOnly: PropTypes.bool,
        defaultBlockStyle: PropTypes.string,
    };

    static defaultProps = {
        onChange: null,
        width: 'auto',
        editorState: null,
        restoreDefaultContent: false,
        setRestoreDefaultContent: null,
        handlekeyEvents: null,
        handleBeforeInput: null,
        toolSchema: null,
        actions: null,
        disableLineBreaks: false,
        retainPasteStyling: false,
        editorValidation: false,
        editorWrapperProps: {},
        hideHyperlinkCheckbox: false,
        readOnly: false,
        defaultBlockStyle: null,
    };

    constructor(props) {
        super(props);
        const { editorState } = props;
        this.domEditorRef = React.createRef();
        this.linkRef = React.createRef();
        this.editorWrapperRef = React.createRef();
        this.state = {
            editorState,
            updatedSelection: null,
            showLimitedStylingTooltip: false,
            toolbarSchema: this.getToolbarSchema(),
            modalLink: {
                open: false,
                LinkValueForText: '',
                selectedText: '',
            },
        };

        this.dropdownContentRef = React.createRef();
    }

    componentDidMount = () => {
        const { editorState } = this.props;
        this.editorWrapperRef.current &&
            this.editorWrapperRef.current.addEventListener('click', this.handleClick);
        this.setState({ editorState });
    };

    componentDidUpdate() {
        const { restoreDefaultContent, setRestoreDefaultContent } = this.props;
        const orderListElements = document.querySelectorAll('#lists');
        if (orderListElements.length > 0) {
            orderListElements.forEach(ele => {
                ele.querySelectorAll('#highLightedText');
                if (ele.length > 0) {
                    ele.classList.add('highlight-text');
                } else {
                    ele.classList.remove('highlight-text');
                }
            });
        }
        if (restoreDefaultContent) {
            this.componentDidMount();
            callFunc(setRestoreDefaultContent);
        }
    }

    componentWillUnmount = () => {
        this.editorWrapperRef.current.removeEventListener('click', this.handleClick);
    };

    handleClick = e => {
        const { toolbarSchema } = this.state;
        const isDropdownOpen = _findIndex(toolbarSchema, { dropDownOpen: true });
        if (isDropdownOpen > 0) {
            const updateToolbarSchema = toolbarSchema.map(tool => ({
                ...tool,
                dropDownOpen: false,
            }));
            this.setState({
                toolbarSchema: updateToolbarSchema,
            });
        }
    };

    getToolbarSchema = () => {
        const { toolSchema } = this.props;
        if (toolSchema) {
            return toolSchema;
        }
        return DefaultEditorSchema;
    };

    onChange = updateEditorState => {
        const { onChange } = this.props;
        const { editorState, toolbarSchema } = this.state;
        const updateToolbarSchema = toolbarSchema.map(tool => ({
            ...tool,
            dropDownOpen: false,
        }));
        this.setState({
            editorState: updateEditorState,
            toolbarSchema: updateToolbarSchema,
        });

        if (editorState.getCurrentContent() !== updateEditorState.getCurrentContent()) {
            if (updateEditorState.getCurrentContent().getPlainText().length === 0) {
                callFunc(onChange, '<p> </p>');
            } else {
                const htmlContent = stateToHTML(updateEditorState.getCurrentContent(), {
                    blockStyleFn: block => {
                        const blockAlignment = block.getData() && block.getData().get('text-align');
                        const blockType = block.getType();

                        if (blockStyles.includes(blockType) && !isEmpty(blockAlignment)) {
                            return {
                                style: {
                                    'text-align': TEXT_ALIGNMENTS_MAP[blockAlignment],
                                },
                                attributes: {
                                    class: blockAlignment,
                                },
                            };
                        }
                        return null;
                    },
                });
                callFunc(onChange, htmlContent);
            }
        }
    };

    handlePastedText = (text, html, editorState) => {
        const { disableLineBreaks, retainPasteStyling } = this.props;
        if (disableLineBreaks) {
            const extractWhiteSpacesFromText = text.match(/[^\s*].*[^\s*]/g);
            if (!isEmpty(extractWhiteSpacesFromText) && extractWhiteSpacesFromText.length > 1) {
                this.setState({
                    showLimitedStylingTooltip: true,
                });
                const formattedText = extractWhiteSpacesFromText.join(' ');
                const pastedBlocks = ContentState.createFromText(formattedText).blockMap;
                const newState = Modifier.replaceWithFragment(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    pastedBlocks
                );
                const newEditorState = EditorState.push(editorState, newState, 'insert-fragment');
                this.onChange(newEditorState);
                return 'handled';
            }
        } else if (retainPasteStyling) {
            let url = text;
            if (
                url.length > 0 &&
                !REGEX_VALIDATE.NO_SPACES.test(url) &&
                REGEX_VALIDATE.URL.test(url)
            ) {
                const openNewTab = true;
                const selectedText = getSelectionText(editorState);
                const selectionState = editorState.getSelection();
                const start = selectionState.getEndOffset();
                const end = selectionState.getStartOffset();
                const selection = editorState.getSelection();
                const textUrl = selectedText || url;

                if (!url?.match(/^(http|https)/g)) {
                    url = `https://${url}`;
                }

                let textWithEntity;
                if (!url) {
                    this.onChange(RichUtils.toggleLink(editorState, selection, null));
                    return 'handled';
                }
                const content = editorState.getCurrentContent();
                const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
                    url,
                    openNewTab,
                    toolTipContents: this.getLinkToolTipContent(url),
                });
                const entityKey = contentWithEntity.getLastCreatedEntityKey();

                if (start === end) {
                    textWithEntity = Modifier.insertText(
                        contentWithEntity,
                        selection,
                        textUrl,
                        null,
                        entityKey
                    );
                } else if (selectedText) {
                    textWithEntity = Modifier.replaceText(
                        contentWithEntity,
                        selection,
                        textUrl,
                        null,
                        entityKey
                    );
                }
                this.onChange(EditorState.push(editorState, textWithEntity, 'insert-link'));
            } else {
                const { contentBlocks, entityMap } = htmlToDraft(text || html);
                const pastedBlocks = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                ).getBlockMap();
                const newState = Modifier.replaceWithFragment(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    pastedBlocks
                );
                const newEditorState = EditorState.push(editorState, newState, 'insert-fragment');
                this.onChange(newEditorState);
            }
            return 'handled';
        }
        return undefined;
    };

    getEditorContent = () => {
        const { editorState } = this.state;
        const stateHtmlResponse = stateToHTML(editorState.getCurrentContent(), {
            entityStyleFn: entity => {
                if (entity.getType() === 'TABLE') {
                    return {
                        element: 'table',
                        attributes: {
                            data: entity.data.innerHTML,
                        },
                    };
                } else if (entity.getType() === 'HORIZONTAL_LINE') {
                    return {
                        element: 'hr',
                    };
                }
                if (entity.getType() === 'LINK') {
                    const data = entity.getData();
                    if (data.openNewTab) {
                        return {
                            element: 'a',
                            attributes: {
                                href: data.url,
                                target: '_blank',
                            },
                        };
                    } else {
                        return {
                            element: 'a',
                            attributes: {
                                href: data.url,
                            },
                        };
                    }
                }
                return {};
            },
            blockStyleFn: block => {
                const blockType = block.getType();
                const blockAlignment = block.getData() && block.getData().get('text-align');
                if (Object.keys(TEXT_ALIGNMENTS_MAP).indexOf(blockType) !== -1) {
                    return {
                        style: {
                            textAlign: TEXT_ALIGNMENTS_MAP[blockType],
                        },
                        attributes: {
                            class: blockType,
                        },
                    };
                } else if (blockStyles.includes(blockType) && !isEmpty(blockAlignment)) {
                    return {
                        style: {
                            textAlign: TEXT_ALIGNMENTS_MAP[blockAlignment],
                        },
                    };
                }
                return null;
            },
        });
        return stateHtmlResponse;
    };

    getEditorState = () => {
        const { editorState } = this.state;
        return editorState;
    };

    setEditorContent = editorState => {
        this.setState({
            editorState,
        });
    };

    focus = () => {
        this.domEditorRef.current.focus();
    };

    handleInlineStyle = inlineStyle => {
        const { editorState, toolbarSchema } = this.state;
        const updateToolbarSchema = toolbarSchema.map(tool => ({
            ...tool,
            dropDownOpen: false,
        }));
        if (inlineStyle === 'Unstyled') {
            const { editorState } = this.state;
            const selection = editorState.getSelection();

            const textStyles = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'CODE', 'LIST'];
            const contentState = editorState.getCurrentContent();
            const contentWithoutStyles = textStyles.reduce(
                (newContentState, style) =>
                    Modifier.removeInlineStyle(newContentState, selection, style),
                contentState
            );
            const removeBlock = Modifier.setBlockType(contentWithoutStyles, selection, 'unstyled');

            const newEditorState = EditorState.push(
                editorState,
                removeBlock,
                'change-inline-style'
            );

            this.setState({
                editorState: newEditorState,
            });
        } else if (inlineStyle === 'Line') {
            this.onAddHrLine(updateToolbarSchema);
        } else if (Object.keys(TEXT_ALIGNMENTS_MAP).indexOf(inlineStyle) !== -1) {
            const currentContentBlock = this.getSelectedBlock(editorState);
            const blockType = currentContentBlock.getType();
            if (blockStyles.includes(blockType)) {
                this.onChange(setBlockData(editorState, { 'text-align': inlineStyle }));
            } else {
                this.onChange(RichUtils.toggleBlockType(editorState, inlineStyle));
            }
            this.setState({
                toolbarSchema: updateToolbarSchema,
            });
        } else {
            this.onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
            this.setState({
                toolbarSchema: updateToolbarSchema,
            });
        }
    };

    onAddHrLine = updateToolbarSchema => {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'HORIZONTAL_LINE',
            'IMMUTABLE',
            {}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            { currentContent: contentStateWithEntity },
            'create-entity'
        );
        this.setState(
            {
                toolbarSchema: updateToolbarSchema,
                editorState: AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '),
            },
            () => {
                setTimeout(() => this.focus(), 0);
            }
        );
    };

    updateMenuItems = (menu, inlineStyle) => {
        return menu.map(list => {
            return {
                ...list,
                disableLink: !!(
                    list.disable &&
                    !(inlineStyle === 'unordered-list-item' || inlineStyle === 'ordered-list-item')
                ),
            };
        });
    };

    handleModalClose = () => {
        const updateModal = {
            open: false,
            LinkValueForText: '',
            selectedText: '',
        };
        this.setState({ modalLink: updateModal });
    };

    handleBlockStyle = (inlineStyle, label, disable, e) => {
        const { editorState } = this.state;
        const { toolbarSchema } = this.state;
        const updateToolbarSchema = toolbarSchema.map(tool => ({
            ...tool,
            dropDownOpen: false,
            disable,
            menuItems: tool.menuItems ? this.updateMenuItems(tool.menuItems, inlineStyle) : [],
        }));
        if (label === 'Insert Link') {
            const updateModal = {
                open: true,
                onSubmit: this.handleInsertLink,
                formData: {
                    url: '',
                    text: getSelectionText(editorState),
                },
            };
            this.setState({ modalLink: updateModal, toolbarSchema: updateToolbarSchema });
        } else if (label === 'Unlink') {
            const selectionState = editorState.getSelection();
            const blockKey = selectionState.getAnchorKey();
            const currentContent = editorState.getCurrentContent();
            const currentContentBlock = currentContent.getBlockForKey(blockKey);
            const anchorOffset = selectionState.getStartOffset();
            const end = selectionState.getEndOffset();
            const selectedText = currentContentBlock.getText().slice(anchorOffset, end);
            const params = { selectedText, anchorOffset, blockKey };
            this.handleUnlink(params);
            this.setState({
                toolbarSchema: updateToolbarSchema,
            });
        } else if (label === '< Outdent') {
            const block = this.getSelectedBlock(editorState);
            this.handleOutdentForListItem(block);
        } else if (label === '> Indent') {
            this._onTab(e, 4);
        } else {
            this.onChange(RichUtils.toggleBlockType(editorState, inlineStyle));
            this.setState({
                toolbarSchema: updateToolbarSchema,
            });
        }
    };

    changeBlockType = editorState =>
        EditorState.push(
            editorState,
            RichUtils.tryToRemoveBlockStyle(editorState),
            'change-block-type'
        );

    decreaseBlockDepth = (block, editorState) => {
        const blockKey = block.getKey();
        const depth = block.getDepth();
        const newBlock = block.set('depth', depth - 1);
        const contentState = editorState.getCurrentContent();
        const blockMap = contentState.getBlockMap();
        const newBlockMap = blockMap.set(blockKey, newBlock);
        return EditorState.push(
            editorState,
            contentState.merge({ blockMap: newBlockMap }),
            'adjust-depth'
        );
    };

    handleOutdentForListItem = block => {
        const { editorState } = this.state;
        const depth = block.getDepth();
        if (depth > 0) {
            this.onChange(this.decreaseBlockDepth(block, editorState));
        } else if (depth === 0) {
            this.onChange(this.changeBlockType(editorState));
        }
    };

    getSelectedBlock = editorState => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const blockStartKey = selection.getStartKey();
        return contentState.getBlockMap().get(blockStartKey);
    };

    isEmptyListItem = block => {
        const text = block.getText();
        const hasEmptyText = text.length === 0;
        const blockType = block.getType();
        const isListItemBlock =
            blockType === 'unordered-list-item' || blockType === 'ordered-list-item';

        return isListItemBlock && hasEmptyText;
    };

    getActions = () => {
        return {
            onClickInline: this.handleInlineStyle,
            onClickBlock: this.handleBlockStyle,
        };
    };

    getLinkToolTipContent = (url, isNewTab) => [
        {
            name: url,
            href: true,
            isNewTab,
            onClick: ({ url }) => {
                window.open(url, '_blank');
            },
        },
        {
            name: '|',
        },
        {
            name: 'Edit',
            onClick: this.handleEditlink,
        },
        {
            name: '|',
        },
        {
            name: 'Unlink',
            onClick: this.handleUnlink,
        },
    ];

    updateLinkFormat = (formData, linkInfo) => {
        const { entityKey } = linkInfo;
        const { url, text, openNewTab } = formData;
        const { editorState, updatedSelection } = this.state;
        const contentState = editorState.getCurrentContent();
        const updateModal = {
            open: false,
            LinkValueForText: '',
            selectedText: '',
        };

        contentState.mergeEntityData(entityKey, { url, openNewTab });
        const textWithEntity = Modifier.replaceText(
            contentState,
            updatedSelection,
            text,
            null,
            entityKey
        );

        this.setState(
            {
                editorState: EditorState.push(editorState, textWithEntity, 'replace-text'),
                modalLink: updateModal,
            },
            () => this.focus()
        );
        return 'handled';
    };

    handleEditlink = params => {
        const { url, selectedText, entityKey, anchorOffset, blockKey, openNewTab } = params;
        const updateModal = {
            open: true,
            editLink: true,
            entityKey,
            onSubmit: this.updateLinkFormat,
            formData: {
                url,
                text: selectedText,
                openNewTab,
            },
        };

        const updatedSelection = this.getNewSelectionState(anchorOffset, blockKey, selectedText);
        this.setState({ modalLink: updateModal, updatedSelection });
    };

    getNewSelectionState = (anchorOffset, blockKey, text) => {
        const updatedSelection = new SelectionState({
            anchorKey: blockKey,
            focusKey: blockKey,
            isBackward: false,
            anchorOffset,
            hasFocus: true,
            focusOffset: anchorOffset + text.length,
        });
        return updatedSelection;
    };

    handleUnlink = params => {
        const { selectedText, anchorOffset, blockKey } = params;
        const { editorState } = this.state;
        const updatedSelection = this.getNewSelectionState(anchorOffset, blockKey, selectedText);
        this.onChange(RichUtils.toggleLink(editorState, updatedSelection, null));
    };

    handleInsertLink = formData => {
        const updateModal = {
            open: false,
            LinkValueForText: '',
            selectedText: '',
        };
        const { editorState } = this.state;
        const { url, text, openNewTab } = formData;
        const initialText = getSelectionText(editorState);
        const selectionState = editorState.getSelection();
        const start = selectionState.getEndOffset();
        const end = selectionState.getStartOffset();
        const selection = editorState.getSelection();
        let textWithEntity;
        if (!url) {
            this.onChange(RichUtils.toggleLink(editorState, selection, null));
            return 'handled';
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
            url,
            openNewTab,
            toolTipContents: this.getLinkToolTipContent(url),
        });
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        if (start === end) {
            textWithEntity = Modifier.insertText(
                contentWithEntity,
                selection,
                text,
                null,
                entityKey
            );
            this.onChange(EditorState.push(editorState, textWithEntity, 'insert-link'));
            this.setState({
                modalLink: updateModal,
            });
        } else if (initialText !== text) {
            textWithEntity = Modifier.replaceText(
                contentWithEntity,
                selection,
                text,
                null,
                entityKey
            );
            this.onChange(EditorState.push(editorState, textWithEntity, 'insert-link'));

            this.setState({
                modalLink: updateModal,
            });
        } else {
            const formatedContent = RichUtils.toggleLink(newEditorState, selection, entityKey);
            const selectionState = formatedContent.getSelection();
            const updatedSelection = selectionState.merge({
                focusOffset: selectionState.getAnchorOffset(),
            });
            updatedSelection.set('hasFocus', true);
            this.onChange(formatedContent);
            this.setState({ modalLink: updateModal }, () => this.focus());
        }
        return 'handled';
    };

    handleKeyCommand = command => {
        const { handlekeyEvents } = this.props;
        const { editorState } = this.state;
        if (isEmpty(handlekeyEvents)) {
            const newState = RichUtils.handleKeyCommand(editorState, command);
            if (newState) {
                this.onChange(newState);
                return 'handled';
            }
        } else if (!isEmpty(handlekeyEvents)) {
            if (handlekeyEvents.includes(command)) {
                if (command === 'split-block') {
                    this.setState({ showLimitedStylingTooltip: true });
                    return 'handled';
                }
            } else {
                const newState = RichUtils.handleKeyCommand(editorState, command);
                if (newState) {
                    this.onChange(newState);
                    return 'handled';
                }
            }
        }
        return 'not-handled';
    };

    onClick = (tool, e) => {
        this.handleMenuClick(tool, e);
    };

    handleMenuClick = currenttool => {
        const { toolbarSchema } = this.state;
        const updateToolbarSchema = toolbarSchema.map(tool => ({
            ...tool,
            dropDownOpen: tool.label === currenttool.label ? !currenttool.dropDownOpen : false,
        }));
        this.setState({
            toolbarSchema: updateToolbarSchema,
        });
    };

    handleItemClick = item => {
        return () => {
            const { label, inlineStyle, disable } = item;
            this.handleBlockStyle(inlineStyle, label, disable);
        };
    };

    handleActionMenuClick = clickFunc => {
        return () => {
            const { showLimitedStylingTooltip } = this.state;
            if (showLimitedStylingTooltip) {
                this.setState({
                    showLimitedStylingTooltip: false,
                });
            }
            callFunc(clickFunc);
        };
    };

    blockStyleFn = contentBlock => {
        const { defaultBlockStyle } = this.props;
        const type = contentBlock.getType();
        if (Object.keys(TEXT_ALIGNMENTS_MAP).indexOf(type) !== -1) {
            return type;
        }
        if (defaultBlockStyle && type === 'unstyled') {
            return defaultBlockStyle;
        }
        return type === '' || type === 'unstyled' ? 'editorParagraphMargin' : null;
    };

    renderBlockStyleControls = tool => {
        const { editorState } = this.state;

        return (
            <>
                <BlockStyleControls
                    tool={tool}
                    onClick={this.onClick}
                    editorState={editorState}
                    onToggle={this.handleBlockStyle}
                />
            </>
        );
    };

    renderToolBar = () => {
        const { editorState, toolbarSchema } = this.state;

        const groupedToolBar = {
            basic: [],
            list: [],
            style: [],
            custom: [],
        };
        toolbarSchema.forEach(tool => {
            const groupId = tool.groupId || 'basic';
            groupedToolBar[groupId].push(tool);
        });
        const groups = Object.keys(groupedToolBar);
        return groups.map(group => {
            return (
                <ToolGroupStyled key={group}>
                    {groupedToolBar[group].map(tool => {
                        const isMenuItems = get(tool, 'isMenuItems', false);
                        return isMenuItems ? (
                            <Tooltip
                                key={tool.id}
                                arrowSize={0}
                                margin={0}
                                padding="5px 10px"
                                content={tool.toolTip}
                                placement="bottom"
                            >
                                {this.renderBlockStyleControls(tool)}
                            </Tooltip>
                        ) : (
                            <Tooltip
                                key={tool.id}
                                arrowSize={0}
                                margin={0}
                                padding="5px 10px"
                                content={tool.toolTip}
                                placement="bottom"
                            >
                                <InlineStyleButton
                                    tool={tool}
                                    key={tool.label}
                                    onToggle={this.handleInlineStyle}
                                    editorState={editorState}
                                />
                            </Tooltip>
                        );
                    })}
                </ToolGroupStyled>
            );
        });
    };

    onTab = e => this._onTab(e, 4);

    _onTab(e, maxdepth) {
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const blockStartKey = selection.getStartKey();
        const currentBlockKey = contentState.getBlockForKey(blockStartKey);
        const text = currentBlockKey.getText();
        const hasEmptyText = text.length === 0;
        const previousBlock = contentState.getBlockBefore(blockStartKey);
        if (previousBlock.depth === currentBlockKey.depth - 1 && hasEmptyText) {
            this.focus();
        } else {
            this.onChange(RichUtils.onTab(e, this.state.editorState, maxdepth));
        }
    }

    handleReturn = event => {
        const { editorState } = this.state;

        if (event.shiftKey) {
            this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
            return 'handled';
        }

        return 'not-handled';
    };

    getBlockRenderMap = currentState => {
        const blockMap = {};
        blockStyles.forEach(style => {
            blockMap[style] = {
                wrapper: (
                    <FormatWrapper type={style} currentContent={currentState.getCurrentContent()} />
                ),
            };
        });

        return DefaultDraftBlockRenderMap.merge(Map(blockMap));
    };

    render() {
        const {
            actions,
            editorValidation,
            editorWrapperProps,
            handleBeforeInput,
            hideHyperlinkCheckbox,
            readOnly,
        } = this.props;
        const actionMenu = get(actions, 'actionMenu');
        const { editorState, modalLink, showLimitedStylingTooltip } = this.state;
        const contextValue = {
            getLinkToolTipContent: this.getLinkToolTipContent,
        };
        const contentAvailable = editorState.getCurrentContent().getPlainText();
        const borderColor = editorValidation && !contentAvailable.trim() ? 'error' : 'muted';

        return (
            <EditorContext.Provider value={contextValue}>
                <Tooltip
                    arrowSize={0}
                    color="white"
                    margin={0}
                    padding="5px 10px"
                    content={LINE_BREAK_TOOLTIP_MESSAGE}
                    placement="top-start"
                    open={showLimitedStylingTooltip}
                    tooltip={{
                        style: {
                            fontWeight: 800,
                            marginLeft: '20px',
                            maxWidth: '100%',
                            height: '40px',
                        },
                    }}
                >
                    <div className="Editor-root">
                        <Box
                            borderRadius="none"
                            borderColor={borderColor}
                            padding="0"
                            width="auto"
                            margin="0 3px 13px 3px"
                        >
                            <ToolBarWrapper>
                                {readOnly ? null : this.renderToolBar()}
                                <ActionsWrapper>
                                    {actionMenu &&
                                        actionMenu.map(actionButton => {
                                            return (
                                                <Button
                                                    disabled={actionButton.disabled}
                                                    key={actionButton.label}
                                                    margin="5px"
                                                    border
                                                    color={actionButton.color}
                                                    {...actionButton}
                                                    onClick={this.handleActionMenuClick(
                                                        actionButton.onClick
                                                    )}
                                                >
                                                    {actionButton.label}
                                                </Button>
                                            );
                                        })}
                                </ActionsWrapper>
                            </ToolBarWrapper>
                            {readOnly ? null : <Divider margin="0 0 10px 0" />}
                            <EditorWrapper
                                borderStyle="none"
                                style={fontStyles}
                                ref={this.editorWrapperRef}
                                {...editorWrapperProps}
                            >
                                <DraftEditor
                                    readOnly={readOnly}
                                    editorState={editorState}
                                    onChange={this.onChange}
                                    ref={this.domEditorRef}
                                    blockRenderMap={this.getBlockRenderMap(editorState)}
                                    blockRendererFn={InsertHorizontalLine}
                                    handleBeforeInput={handleBeforeInput}
                                    handleKeyCommand={this.handleKeyCommand}
                                    handlePastedText={this.handlePastedText}
                                    handleReturn={this.handleReturn}
                                    blockStyleFn={this.blockStyleFn}
                                    onTab={this.onTab}
                                />
                            </EditorWrapper>
                            <InsertLinkModal
                                hideCheckbox={hideHyperlinkCheckbox}
                                handleModalClose={this.handleModalClose}
                                {...modalLink}
                            />
                        </Box>
                    </div>
                </Tooltip>
            </EditorContext.Provider>
        );
    }
}

const BlockStyleControls = ({ editorState, tool, onClick, onToggle }) => {
    const { customStyle, label } = tool;
    const onMouseDown = e => {
        e.preventDefault();
        onClick(tool, e);
    };
    return (
        <Popover
            anchor={
                <ButtonStyle
                    border
                    borderRadius="0px"
                    customStyle={customStyle}
                    noMinHeight
                    noMinWidth
                    onMouseDown={onMouseDown}
                    type="button"
                >
                    {isEmpty(tool.icon) ? label : <FaIcon margin="0 7.5px 0 0" {...tool.icon} />}
                </ButtonStyle>
            }
            transition
            arrowSize={0}
            open={tool.dropDownOpen}
            placement="bottom-end"
            PopperProps={{
                style: { zIndex: 1000 },
            }}
        >
            {renderProps => (
                <PopoverContent {...renderProps}>
                    <ToolbarMenuIconListStyled>
                        {tool.menuItems.map(item => {
                            const { id: menuId } = tool;
                            const selection = editorState.getSelection();
                            const blockType = editorState
                                .getCurrentContent()
                                .getBlockForKey(selection.getStartKey())
                                .getType();

                            if (item.checkDisable && menuId === 'lists') {
                                item.disable = !(
                                    blockType === 'unordered-list-item' ||
                                    blockType === 'ordered-list-item'
                                );
                            }

                            if (item.checkDisable && menuId === 'link') {
                                item.disable = !getSelectionEntity(editorState);
                            }

                            return (
                                !item.hide && (
                                    <RenderPopoverContent
                                        key={genID()}
                                        onToggle={onToggle}
                                        editorState={editorState}
                                        item={item}
                                        blockType={blockType}
                                    />
                                )
                            );
                        })}
                    </ToolbarMenuIconListStyled>
                </PopoverContent>
            )}
        </Popover>
    );
};

const RenderPopoverContent = ({ editorState, item, onToggle, blockType }) => {
    const { inlineStyle, label, disable } = item;
    const onMouseDown = e => {
        e.preventDefault();
        onToggle(inlineStyle, label, disable, e);
    };
    return (
        <IconMenuDropdownStyled
            customStyles={item.customStyles}
            onMouseDown={onMouseDown}
            key={genID()}
            active={blockType === inlineStyle}
        >
            <TypographyStyled
                as="a"
                customStyles={item.customStyles}
                disableLink={item.disable}
                disable={item.disable}
            >
                {item.label}
            </TypographyStyled>
        </IconMenuDropdownStyled>
    );
};

Editor.contextType = EditorContext;

export default Editor;

FormatWrapper.propTypes = {
    children: PropTypes.array,
    type: PropTypes.string,
    currentContent: PropTypes.object,
};

FormatWrapper.defaultProps = {
    children: [],
    type: '',
    currentContent: {},
};

InlineStyleButton.propTypes = {
    onToggle: PropTypes.func,
    editorState: PropTypes.object,
    tool: PropTypes.object,
};

InlineStyleButton.defaultProps = {
    onToggle: null,
    editorState: null,
    tool: null,
};

BlockStyleControls.propTypes = {
    onToggle: PropTypes.func,
    editorState: PropTypes.object,
    tool: PropTypes.object,
    onClick: PropTypes.func,
};

BlockStyleControls.defaultProps = {
    onToggle: null,
    editorState: null,
    tool: null,
    onClick: null,
};

RenderPopoverContent.propTypes = {
    onToggle: PropTypes.func,
    editorState: PropTypes.object,
    item: PropTypes.object,
    blockType: PropTypes.string,
};

RenderPopoverContent.defaultProps = {
    onToggle: null,
    editorState: null,
    item: null,
    blockType: '',
};
