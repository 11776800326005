import axios from 'axios';
/**
 * API call to fetch info about the given category, via `categoryId`.
 *
 * @method fetchCalendarEventsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchCalendarEventsAPI = params => {
    return axios({
        method: 'get',
        noBearer: true,
        params,
        type: 'apiTools',
        url: 'tools/v1/calendar/event',
    });
};

/**
 * API call to fetch event detail.
 *
 * @method fetchCalendarEventDetailsAPI
 * @param  {number}  id
 * @return {Promise}
 */
export const fetchCalendarEventDetailsAPI = id => {
    return axios({
        method: 'get',
        noBearer: true,
        type: 'apiTools',
        url: `/tools/v1/calendar/event/${id}`,
    });
};

/**
 * API call to create calendar event.
 *
 * @method createCalendarEventAPI
 * @param  {obj}  params
 * @return {Promise}
 */
export const createCalendarEventAPI = params => {
    return axios({
        method: 'post',
        noBearer: true,
        data: params,
        type: 'apiTools',
        url: '/tools/v1/calendar/event',
    });
};

/**
 * API call to update calendar event.
 *
 * @method updateCalendarEventAPI
 * @param  {obj}  params
 * @return {Promise}
 */
export const updateCalendarEventAPI = params => {
    return axios({
        method: 'put',
        noBearer: true,
        data: params,
        type: 'apiTools',
        url: `/tools/v1/calendar/event/${params.id}`,
    });
};

/**
 * API call to download calendar events.
 *
 * @method downloadCalendarEventsAPI
 * @param  {obj}  params
 * @return {Promise}
 */
export const downloadCalendarEventsAPI = params => {
    return axios({
        method: 'get',
        noBearer: true,
        params,
        type: 'apiTools',
        url: '/tools/v1/calendar/download',
    });
};

/**
 * API call to delete calendar event.
 *
 * @method deleteCalendarEventAPI
 * @param  {number}  id
 * @return {Promise}
 */
export const deleteCalendarEventAPI = id => {
    return axios({
        method: 'delete',
        noBearer: true,
        type: 'apiTools',
        url: `/tools/v1/calendar/event/${id}`,
    });
};
