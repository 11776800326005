import axios from 'axios';
import Qs from 'qs';

/**
 * Fetch Affiliates API call
 *
 * @param   {Object}    params
 * @return  {Promise}
 */
export const fetchAffiliatesAPI = (params = {}) =>
    axios({
        method: 'get',
        params,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        url: '/v2/affiliates',
    });

/**
 * Fetch Affiliate API call
 *
 * @param   {string|number}    affiliateId
 * @return  {Promise}
 */
export const fetchAffiliateAPI = affiliateId =>
    axios({
        method: 'get',
        url: `/v2/affiliates/${affiliateId}`,
    });

/**
 * Update Affiliate API call
 *
 * @param   {string|number}    affiliateId
 * @param   {Object}           data
 * @return  {Promise}
 */
export const updateAffiliateAPI = (affiliateId, data) =>
    axios({
        data,
        method: 'put',
        url: `/v2/affiliates/${affiliateId}`,
    });

/**
 * Activate Affiliate API call
 *
 * @param   {string|number}    affiliateId
 * @return  {Promise}
 */
export const activateAffiliateAPI = affiliateId =>
    axios({
        method: 'patch',
        url: `/v2/affiliates/${affiliateId}/activate`,
    });

/**
 * Activate Affiliate API call
 *
 * @param   {string|number}    affiliateId
 * @return  {Promise}
 */
export const deactivateAffiliateAPI = affiliateId =>
    axios({
        method: 'patch',
        url: `/v2/affiliates/${affiliateId}/deactivate`,
    });
