import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const SafetyManualTemplateIcon = ({
    height,
    viewBox,
    width,
    fillColor,
    fillColorComplementary,
    strokeColor,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <rect fill="none" width="64" height="64" />
            <path
                fill={fillColor}
                d="M4.4,1H47c1.8,0,3.3,1.5,3.3,3.3v55.4c0,1.8-1.5,3.3-3.3,3.3H4.4C4.4,63,4.4,1,4.4,1z"
            />
            <path
                fill={fillColorComplementary}
                d="M8.4,62.8h38.1c2.1,0,3.8-1.7,3.8-3.8V3c0,0,0,0,0,0H8.4c0,0,0,0,0,0L8.4,62.8C8.4,62.8,8.4,62.8,8.4,62.8z"
            />
            <path
                fill={strokeColor}
                d="M4.4,0H47c1.2,0,2.3,0.5,3,1.3c0.8,0.8,1.3,1.9,1.3,3v55.4c0,1.2-0.5,2.3-1.3,3c-0.8,0.8-1.8,1.3-3,1.3H4.4
                c-0.5,0-1-0.5-1-1V1C3.4,0.5,3.8,0,4.4,0z M47,2H5.4v60H47c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6V4.3
                c0-0.6-0.3-1.2-0.7-1.6C48.2,2.3,47.7,2,47,2z"
            />
            <path
                fill={strokeColor}
                d="M7.5,7.3c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1H2.2c-0.5,0-1-0.5-1-1c0-0.6,0.5-1,1-1C2.2,7.3,7.5,7.3,7.5,7.3z"
            />
            <path
                fill={strokeColor}
                d="M7.5,6.6c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2C8.4,9.8,8,10,7.5,10
                C7,10,6.6,9.9,6.3,9.5C6,9.2,5.8,8.8,5.8,8.3c0-0.5,0.2-0.9,0.5-1.2C6.6,6.8,7,6.6,7.5,6.6z M7.3,8.5l0.2-0.2L7.3,8.5z"
            />
            <path
                fill={strokeColor}
                d="M7.5,15.2c0.6,0,1,0.5,1,1c0,0.6-0.4,1-1,1H2.2c-0.5,0-1-0.5-1-1c0-0.6,0.5-1,1-1C2.2,15.2,7.5,15.2,7.5,15.2z"
            />
            <path
                fill={strokeColor}
                d="M7.5,14.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2S8,17.9,7.5,17.9
                c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2C6.6,14.7,7,14.5,7.5,14.5z M7.3,16.4l0.2-0.2L7.3,16.4z"
            />
            <path
                fill={strokeColor}
                d="M7.5,23.1c0.6,0,1,0.5,1,1c0,0.5-0.4,1-1,1H2.2c-0.5,0-1-0.5-1-1c0-0.6,0.5-1,1-1C2.2,23.1,7.5,23.1,7.5,23.1z"
            />
            <path
                fill={strokeColor}
                d="M7.5,22.4c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2S9.1,25,8.7,25.3c-0.3,0.3-0.7,0.5-1.2,0.5
                c-0.5,0-0.9-0.2-1.2-0.5s-0.5-0.7-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2C6.6,22.6,7,22.4,7.5,22.4z M7.3,24.3l0.2-0.2L7.3,24.3z"
            />
            <path
                fill={strokeColor}
                d="M7.5,31c0.6,0,1,0.5,1,1c0,0.6-0.4,1-1,1H2.2c-0.5,0-1-0.5-1-1s0.5-1,1-1C2.2,31,7.5,31,7.5,31z"
            />
            <path
                fill={strokeColor}
                d="M7.5,30.3c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2S8,33.7,7.5,33.7
                c-0.5,0-0.9-0.2-1.2-0.5C6,32.9,5.8,32.5,5.8,32c0-0.5,0.2-0.9,0.5-1.2C6.6,30.5,7,30.3,7.5,30.3z M7.3,32.2L7.5,32L7.3,32.2z"
            />
            <path
                fill={strokeColor}
                d="M7.5,38.9c0.6,0,1,0.5,1,1c0,0.6-0.4,1-1,1H2.2c-0.5,0-1-0.5-1-1c0-0.5,0.5-1,1-1C2.2,38.9,7.5,38.9,7.5,38.9z"
            />
            <path
                fill={strokeColor}
                d="M7.5,38.2c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2S8,41.6,7.5,41.6
                c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2S6,39,6.3,38.7C6.6,38.4,7,38.2,7.5,38.2z M7.3,40.1l0.2-0.2L7.3,40.1z"
            />
            <path
                fill={strokeColor}
                d="M7.5,46.8c0.6,0,1,0.5,1,1c0,0.5-0.4,1-1,1H2.2c-0.5,0-1-0.5-1-1c0-0.6,0.5-1,1-1C2.2,46.8,7.5,46.8,7.5,46.8z"
            />
            <path
                fill={strokeColor}
                d="M7.5,46.1c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2S9.1,48.7,8.7,49c-0.3,0.3-0.7,0.5-1.2,0.5
                c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2C6.6,46.3,7,46.1,7.5,46.1z M7.3,48l0.2-0.2L7.3,48z"
            />
            <path
                fill={strokeColor}
                d="M7.5,54.7c0.6,0,1,0.5,1,1c0,0.6-0.4,1-1,1H2.2c-0.5,0-1-0.5-1-1c0-0.5,0.5-1,1-1C2.2,54.7,7.5,54.7,7.5,54.7z"
            />
            <path
                fill={strokeColor}
                d="M7.5,54c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2S8,57.4,7.5,57.4
                c-0.5
                0-0.9-0.2-1
                2-0.5c-0.3
                0.3-0.5-0.8
                -0.5-1.2s0.2-0.9,0.5-1.2C6.6,54.1,7,54,7.5,54z M7.3,55.9l0.2-0.2L7.3,55.9z"
            />
            <rect x="19.6" y="11.6" fill={fillColor} width="20.8" height="20" />
            <path
                fill={strokeColor}
                d="M21.9,19.4c-0.5,0-1-0.5-1-1s0.5-1,1-1h12.9c0.6,0,1,0.5,1,1s-0.5,1-1,1H21.9z"
            />
            <path
                fill={strokeColor}
                d="M21.9,25.7c-0.5,0-1-0.5-1-1s0.5-1,1-1h6.4c0.6,0,1,0.5,1,1s-0.5,1-1,1H21.9z"
            />
            <path
                fill={strokeColor}
                d="M16.2,10.5h24.2c0.5,0,1,0.5,1,1v20c0,0.6-0.5,1-1,1H16.2c-0.6,0-1-0.5-1-1v-20C15.2,11,15.7,10.5,16.2,10.5z
                M39.4,12.6H17.2v18h22.2V12.6z"
            />
            <g>
                <path
                    fill={fillColor}
                    d="M39.8,39.2h20.1c1,0,1.8,0.8,1.8,1.8v20.1c0,1-0.8,1.8-1.8,1.8H39.8c-1,0-1.8-0.8-1.8-1.8V41
                    C38,40,38.8,39.2,39.8,39.2z"
                />
                <path
                    fill={strokeColor}
                    d="M59.9,64H39.8c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-2V41c0-0.8,0.3-1.5,0.8-2c0.5-0.5,1.2-0.8,2-0.8
                    h20.1c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.8,1.2,0.8,2v20.1c0,0.8-0.3,1.5-0.8,2l-0.1,0.1C61.3,63.7,60.6,64,59.9,64z M39.8,61.9h20.1
                    c0.2,0,0.4-0.1,0.5-0.2l0,0c0.1-0.1,0.2-0.3,0.2-0.5V41c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2H39.8
                    c-0.2,0-0.4,0.1-0.5,0.2C39.1,40.6,39,40.8,39,41v20.1c0,0.2,0.1,0.4,0.2,0.5C39.4,61.8,39.6,61.9,39.8,61.9z"
                />
                <path
                    fill={strokeColor}
                    d="M53.9,46.4c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1v-0.2h-3l0,9.6c0.5,0.1,0.9,0.5,0.9,1c0,0.6-0.5,1-1,1h-0.9
                    c-0.6,0-1-0.5-1-1l0-11.7c0-0.6,0.5-1,1-1h5.1c0.6,0,1,0.5,1,1C53.9,45.2,53.9,46.4,53.9,46.4z"
                />
                <path
                    fill={strokeColor}
                    d="M43.6,46.4c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1v-1.2c0-0.6,0.5-1,1-1h5.3c0.6,0,1,0.5,1,1l-0.1,11.7
                    c0,0.6-0.5,1-1,1h-0.9c-0.6,0-1-0.5-1-1c0-0.5,0.4-1,0.9-1l0.1-9.6h-3.2V46.4z"
                />
                <path
                    fill={strokeColor}
                    d="M58.2,51.9c0,0.6-0.5,1-1,1c-0.5,0-0.8-0.3-1-0.7h-0.8l0,3.7c0.3,0.2,0.5,0.5,0.5,0.9c0,0.6-0.5,1-1,1h-0.5
                    c-0.6,0-1-0.5-1-1l0-5.6c0-0.6,0.5-1,1-1h2.8c0.6,0,1,0.5,1,1C58.2,51.2,58.2,51.9,58.2,51.9z"
                />
                <path
                    fill={strokeColor}
                    d="M52.5,52.3c-0.1,0.4-0.5,0.7-1,0.7c-0.6,0-1-0.5-1-1v-0.7c0-0.6,0.5-1,1-1h2.9c0.6,0,1,0.5,1,1l-0.1,5.7
                    c0,0.6-0.5,1-1,1h-0.5c-0.6,0-1-0.5-1-1c0-0.4,0.2-0.7,0.5-0.9l0-3.7C53.4,52.3,52.5,52.3,52.5,52.3z"
                />
            </g>
        </SvgIcon>
    );
};

SafetyManualTemplateIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
    fillColorComplementary: string,
    strokeColor: string,
};

SafetyManualTemplateIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
    fillColor: `${DEFAULT_COLORS.WHITE}`,
    fillColorComplementary: `${DEFAULT_COLORS.YELLOW}`,
    strokeColor: `${DEFAULT_COLORS.BLACK}`,
};

export default SafetyManualTemplateIcon;
