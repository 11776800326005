import { string } from 'prop-types';
import React from 'react';

import { DEFAULT_COLORS } from 'constants/colors';

const AlertIconTriangle = ({ fill, height, width, viewBox }) => {
    return (
        <svg width={width} height={height} fill="none" viewBox={viewBox}>
            <path
                d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0v0zM12 9v4M12 17h.01"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

AlertIconTriangle.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

AlertIconTriangle.defaultProps = {
    fill: DEFAULT_COLORS.RED,
    height: '24',
    width: '24',
    viewBox: '0 0 24 24',
};

export default AlertIconTriangle;
