import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { forgotUsernameAPI } from 'modules/apis';

import * as adminActions from '../actions';

/**
 * Find username by email ID.
 *
 * @method forgotUsernameSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} email
 */
export function* forgotUsernameSaga(action) {
    try {
        const { payload: email } = action;
        const resp = yield call(forgotUsernameAPI, email);
        const message = get(resp, 'message', '');
        yield put(adminActions.forgotUsername.success(resp));
        return message;
    } catch (error) {
        yield put(adminActions.forgotUsername.error(error));
        return error;
    }
}
