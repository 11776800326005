import styled from 'styled-components';

import { themeGet } from 'ravenjs/utils/theme';

import { DEFAULT_COLORS } from 'constants/colors';

const TableStyled = styled.table`
    border-collapse: separate;
    overflow: hidden;
    overflow-x: auto;
    min-width: 100%;
    width: 100%;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
    /**
* Add all of the remaining styles from theme
*/
    ${themeGet('Table.styles')};

    th {
        background-color: ${({ tableHeader: { backgroundColor } = {} }) =>
            backgroundColor || DEFAULT_COLORS.COMPONENTS_TABLE_TITLE_ROW};
        color: ${({ tableHeader: { color } = {} }) => color || DEFAULT_COLORS.BLACK};
        padding: ${({ tableHeader: { padding } = {} }) =>
            padding || `0.563rem 0 0.719rem 0.938rem`};
    }

    td {
        white-space: ${({ tableRow: { whiteSpace } = {} }) => whiteSpace || ``};
        padding: ${({ tableRow: { padding } = {} }) => padding || `0.563rem 0 0.719rem 0.938rem`};
    }
`;

export default TableStyled;
