import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const BenefitsDocumentCreatorIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M44.459,1.009H17.19L6.186,12.014
			v39.421c0,1.564,1.278,2.845,2.842,2.845h35.431c1.566,0,2.844-1.28,2.844-2.845V3.854C47.303,2.289,46.025,1.009,44.459,1.009"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#F1E3D8"
                d="M47.303,3.538H17.188l-7.121,8.476
			V54.28c11.464,0,22.929,0,34.393,0c1.542,0,2.842-1.28,2.842-2.845V3.538z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M17.188,0h27.272c1.056,0,2.021,0.435,2.718,1.131
			c0.7,0.7,1.134,1.665,1.134,2.723v47.582c0,1.058-0.434,2.024-1.129,2.723c-0.698,0.695-1.665,1.131-2.723,1.131H9.028
			c-1.06,0-2.024-0.436-2.722-1.131c-0.698-0.698-1.131-1.665-1.131-2.723V12.014c0.002-0.256,0.102-0.515,0.297-0.712L16.446,0.33
			C16.631,0.126,16.894,0,17.188,0 M44.461,2.018H17.605L7.193,12.431v39.004c0,0.505,0.208,0.967,0.54,1.298
			c0.332,0.33,0.791,0.537,1.296,0.537h35.433c0.505,0,0.965-0.207,1.296-0.537c0.332-0.331,0.537-0.793,0.537-1.298V3.854
			c0-0.507-0.203-0.969-0.537-1.298C45.428,2.224,44.966,2.018,44.461,2.018z"
            />
            <polygon
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                points="17.188,1.009 6.186,12.014
			17.188,12.014 		"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M5.472,11.302L16.446,0.33C16.631,0.126,16.894,0,17.188,0
			c0.558,0,1.009,0.454,1.009,1.009v11.005c0,0.557-0.452,1.009-1.009,1.009H6.186c-0.26,0-0.517-0.1-0.714-0.297
			C5.077,12.333,5.077,11.697,5.472,11.302 M16.182,3.442l-7.563,7.564h7.563V3.442z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M47.303,41.957
			c-5.808,0-10.516,4.71-10.516,10.519s4.708,10.517,10.516,10.517c5.809,0,10.517-4.708,10.517-10.517S53.111,41.957,47.303,41.957
			"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M39.155,44.325c2.083-2.086,4.968-3.378,8.147-3.378
			c3.183,0,6.065,1.292,8.15,3.378c2.086,2.085,3.378,4.968,3.378,8.15c0,3.18-1.292,6.064-3.378,8.148
			c-2.085,2.087-4.968,3.377-8.15,3.377c-3.18,0-6.064-1.29-8.147-3.377c-2.088-2.084-3.378-4.969-3.378-8.148
			C35.777,49.293,37.067,46.41,39.155,44.325 M47.303,42.966c-2.624,0-5.004,1.064-6.724,2.785c-1.719,1.72-2.785,4.096-2.785,6.725
			c0,2.624,1.066,5.005,2.785,6.722c1.72,1.722,4.1,2.785,6.724,2.785c2.627,0,5.005-1.063,6.725-2.785
			c1.721-1.717,2.785-4.098,2.785-6.722c0-2.627-1.064-5.005-2.785-6.725C52.308,44.03,49.93,42.966,47.303,42.966z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M38.594,12.997c0.558,0,1.009,0.45,1.009,1.007
			c0,0.557-0.451,1.009-1.009,1.009h-9.89c-0.557,0-1.009-0.452-1.009-1.009c0-0.558,0.452-1.007,1.009-1.007H38.594z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M38.594,20.6c0.558,0,1.009,0.452,1.009,1.007
			c0,0.558-0.451,1.011-1.009,1.011h-23.7c-0.557,0-1.009-0.454-1.009-1.011c0-0.556,0.452-1.007,1.009-1.007H38.594z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M38.594,28.205c0.558,0,1.009,0.45,1.009,1.007
			c0,0.558-0.451,1.009-1.009,1.009h-23.7c-0.557,0-1.009-0.452-1.009-1.009c0-0.557,0.452-1.007,1.009-1.007H38.594z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M38.594,35.809c0.558,0,1.009,0.451,1.009,1.007
			c0,0.558-0.451,1.009-1.009,1.009h-23.7c-0.557,0-1.009-0.451-1.009-1.009c0-0.556,0.452-1.007,1.009-1.007H38.594z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M46.294,48.093c0-0.558,0.454-1.009,1.009-1.009
			c0.558,0,1.01,0.451,1.01,1.009v8.763c0,0.558-0.452,1.01-1.01,1.01c-0.555,0-1.009-0.452-1.009-1.01V48.093z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M42.923,53.482c-0.558,0-1.009-0.451-1.009-1.007
			c0-0.558,0.451-1.01,1.009-1.01h8.763c0.558,0,1.009,0.452,1.009,1.01c0,0.556-0.451,1.007-1.009,1.007H42.923z"
            />
        </SvgIcon>
    );
};

BenefitsDocumentCreatorIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

BenefitsDocumentCreatorIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default BenefitsDocumentCreatorIcon;
