import { func, object } from 'prop-types';
import React, { useState } from 'react';
import AutoSuggest from 'react-autosuggest';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import _debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

import { get } from 'ravenjs/utils/lodash';
import { renderCheck } from 'ravenjs/utils/viewport';
import { Typography } from 'ravenjs/lib/index';

import { SearchIcon } from 'components/Icons';
import TypeAheadContainer from 'components/ContentSearch/TypeAheadContainer';

import { actions as contentfulActions } from 'modules/contentful';

import { getIcon, goToSearchDetails } from 'utils/helpCenter';

import { DEFAULT_COLORS } from 'constants/colors';
import { AUTO_SUGGEST } from 'constants/search';
import { BUTTONS, DESCRIPTIONS, TITLES } from 'constants/helpCenter';

import {
    TypeAheadSearch,
    TypeAheadSearchContainer,
    TypeAheadSearchContainerInput,
    TypeAheadSearchInputIcon,
    SearchHeader,
    ButtonStyled,
} from './HelpCenterSearchTypeAheadStyled';

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

const HelpCenterSearchTypeAhead = ({ fetchHelpCenterSearch, history }) => {
    const location = useLocation();
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isLoadingTypeAhead, setIsLoadingTypeAhead] = React.useState(false);
    const [showTypeAheadContainer, setShowTypeAheadContainer] = React.useState(false);
    const queryParams = get(history, 'location.search', '');
    const queryParamsParse = queryString.parse(queryParams);

    React.useEffect(() => {
        setValue('');
    }, [location.pathname]);

    React.useEffect(() => {
        const truncatedSearchTerm =
            queryParamsParse?.query?.substring(0, AUTO_SUGGEST.MAX_LENGTH) || '';
        queryParamsParse.query = truncatedSearchTerm;
        setValue(truncatedSearchTerm);
    }, [queryParamsParse.query]);

    const renderSectionTitle = () => {
        return (
            <SearchHeader>
                <SearchIcon
                    width="20"
                    height="20"
                    margin="0 16px 0 0"
                    fill={DEFAULT_COLORS.GREY_10}
                />
                <Typography type="paragraph" gutterBottom="0 !important">
                    {TITLES.SEARCH_FOR} &quot;{value}&quot;
                </Typography>
            </SearchHeader>
        );
    };

    const getSectionSuggestions = section => {
        return section.languages;
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const renderSuggestion = suggestion => {
        const IconToShow = getIcon(suggestion.type);

        return (
            <>
                <IconToShow width="20" height="20" margin="0 16px 0 0" />
                <span>{suggestion.name}</span>
            </>
        );
    };

    const renderSuggestionsContainer = suggestionsProps => {
        return (
            <TypeAheadContainer
                isLoading={isLoadingTypeAhead}
                showTypeAheadContainer={showTypeAheadContainer}
                {...suggestionsProps}
            />
        );
    };

    const goToResults = () => {
        history.push(`/help-center/search?query=${value}&filters={"category":["categoryAll"]}`);
    };

    const onInputChange = (event, { newValue }) => {
        setValue(newValue);
        setShowTypeAheadContainer(true);

        if (newValue.length >= 3) {
            debouncedFetchSuggestions(newValue);
        } else {
            setSuggestions([]);
        }
    };

    const fetchSuggestions = React.useCallback(
        async value => {
            setIsLoadingTypeAhead(true);
            setShowTypeAheadContainer(true);

            const suggestions = await fetchHelpCenterSearch({
                filters: {
                    categoryNameList: [],
                    limit: 5,
                    search: value,
                    page: 1,
                },
            });
            const formattedSuggestions = [
                {
                    title: 'search',
                    languages: suggestions.list.map(item => {
                        return {
                            category: get(item, 'categories.0.slug'),
                            contentType: item.contentType,
                            id: item.guid,
                            name: item.name,
                            section: get(item, 'sections.0.slug'),
                            src: item.link,
                            type: get(item, 'categories.0.iconName'),
                        };
                    }),
                },
            ];

            setSuggestions(formattedSuggestions);
            setIsLoadingTypeAhead(false);
        },
        [fetchHelpCenterSearch]
    );

    const onSuggestionSelected = (event, { suggestion }) => {
        const { category, contentType, id, section, src } = suggestion;
        setValue('');
        setSuggestions([]);
        setValue('');
        setShowTypeAheadContainer(false);

        goToSearchDetails({
            contentType,
            history,
            section,
            category,
            id,
            src,
        });
    };

    const onKeyDown = event => {
        if (event.keyCode === 13) {
            setShowTypeAheadContainer(false);
            goToResults();
            debouncedFetchSuggestions.cancel();
        }
    };

    const debouncedFetchSuggestions = React.useCallback(
        _debounce(typeAheadSearchTerm => fetchSuggestions(typeAheadSearchTerm), 300),
        []
    );

    return (
        <TypeAheadSearch className="innerSearch">
            <TypeAheadSearchContainer>
                <TypeAheadSearchContainerInput>
                    <TypeAheadSearchInputIcon>
                        <SearchIcon
                            width="20"
                            height="20"
                            onClick={renderCheck('md', 'less') ? goToResults : null}
                        />
                    </TypeAheadSearchInputIcon>
                    <AutoSuggest
                        getSectionSuggestions={getSectionSuggestions}
                        getSuggestionValue={getSuggestionValue}
                        inputProps={{
                            onChange: onInputChange,
                            onKeyDown,
                            maxLength: DESCRIPTIONS.MAX_LENGTH,
                            placeholder: DESCRIPTIONS.TYPEAHEAD,
                            value,
                        }}
                        multiSection
                        onSuggestionSelected={onSuggestionSelected}
                        onSuggestionsFetchRequested={() => {}}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        renderSectionTitle={renderSectionTitle}
                        renderSuggestion={renderSuggestion}
                        renderSuggestionsContainer={renderSuggestionsContainer}
                        suggestions={suggestions}
                    />
                </TypeAheadSearchContainerInput>
                <ButtonStyled onClick={goToResults}>{BUTTONS.SEARCH}</ButtonStyled>
            </TypeAheadSearchContainer>
        </TypeAheadSearch>
    );
};
HelpCenterSearchTypeAhead.propTypes = {
    fetchHelpCenterSearch: func.isRequired,
    history: object.isRequired,
};

const mapDispatchToProps = {
    fetchHelpCenterSearch: contentfulActions.fetchHelpCenterSearch,
};

export { HelpCenterSearchTypeAhead as HelpCenterSearchTypeAheadUnwrapped };
export default withTheme(connect(null, mapDispatchToProps)(HelpCenterSearchTypeAhead));
