import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const SafetyCoursesIcon = ({
    height,
    viewBox,
    width,
    fillColor,
    fillColorComplementary,
    strokeColor,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                d="M4.6,1h54.8c2,0,3.6,1.9,3.6,4.2v39.4c0,2.3-1.6,4.2-3.6,4.2H4.6c-2,0-3.6-1.9-3.6-4.2V5.2
                C1,2.9,2.6,1,4.6,1z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fillColor}
            />
            <path
                d="M6.6,3.5h13.8h41.5C62.5,3.5,63,4,63,4.7v39c0,3.2-2.4,5.2-5,5.2H5.2V15.7V5C5.2,4.2,5.8,3.5,6.6,3.5z"
                fill={fillColorComplementary}
            />
            <path
                d="M5.2,0h53.6c1.4,0,2.7,0.6,3.7,1.5l0.1,0.1C63.4,2.5,64,3.8,64,5.2v39.5c0,1.4-0.6,2.7-1.5,3.7
                c-0.9,1-2.2,1.5-3.7,1.5H5.2c-1.4,0-2.7-0.6-3.7-1.5C0.6,47.4,0,46.1,0,44.7V5.2c0-1.4,0.6-2.7,1.5-3.7S3.8,0,5.2,0z M58.8,2H5.2
                C4.3,2,3.5,2.4,3,3C2.4,3.5,2,4.3,2,5.2v39.5c0,0.9,0.4,1.7,0.9,2.2c0.6,0.6,1.4,0.9,2.2,0.9h53.6c0.9,0,1.7-0.4,2.2-0.9
                c0.6-0.6,0.9-1.4,0.9-2.2V5.2c0-0.8-0.3-1.6-0.9-2.2l0,0C60.5,2.4,59.7,2,58.8,2z"
                fill={strokeColor}
            />
            <path
                d="M1,12c-0.6,0-1-0.4-1-1s0.4-1,1-1h62c0.6,0,1,0.4,1,1s-0.5,1-1,1C63,12,1,12,1,12z"
                fill={strokeColor}
            />
            <path
                d="M7.8,7.5C7,7.5,6.3,6.8,6.3,6c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C9.4,6.8,8.7,7.5,7.8,7.5z"
                fill={strokeColor}
            />
            <path
                d="M12.9,7.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C14.4,6.8,13.8,7.5,12.9,7.5z"
                fill={strokeColor}
            />
            <path
                d="M18,7.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C19.5,6.8,18.8,7.5,18,7.5z"
                fill={strokeColor}
            />
            <rect
                x="4.1"
                y="14.5"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fillColor}
                width="52.8"
                height="5.5"
            />
            <path
                d="M7.8,25.2c-0.6,0-1-0.5-1-1c0-0.5,0.4-1,1-1h24.4c0.5,0,1,0.5,1,1c0,0.6-0.5,1-1,1
                C32.2,25.2,7.8,25.2,7.8,25.2z"
                fill={strokeColor}
            />
            <path
                d="M7.8,32.1c-0.6,0-1-0.5-1-1c0-0.6,0.4-1,1-1H20c0.6,0,1,0.5,1,1c0,0.5-0.5,1-1,1H7.8z"
                fill={strokeColor}
            />
            <circle
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fillColor}
                cx="46"
                cy="46.1"
                r="16.9"
            />
            <path
                d="M46,30.3c-4.4,0-8.3,1.8-11.2,4.6c-2.9,2.9-4.6,6.8-4.6,11.2c0,4.4,1.8,8.4,4.6,11.2
                C37.7,60.2,41.6,62,46,62s8.3-1.8,11.2-4.6c2.9-2.9,4.6-6.8,4.6-11.2c0-4.4-1.8-8.3-4.6-11.2C54.4,32.1,50.4,30.3,46,30.3z
                 M33.4,33.5c3.2-3.2,7.7-5.2,12.6-5.2s9.4,2,12.6,5.2c3.2,3.2,5.2,7.7,5.2,12.6c0,4.9-2,9.4-5.2,12.6C55.4,62,51,64,46,64
                s-9.4-2-12.6-5.2c-3.2-3.2-5.2-7.7-5.2-12.6C28.1,41.2,30.1,36.7,33.4,33.5z"
                fill={strokeColor}
            />
            <path
                d="M41.8,54.7h8.5v-4.3h4.3v-8.5h-4.3v-4.3h-8.5v4.3h-4.3v8.5h4.3V54.7z"
                fill={fillColor}
            />
            <polygon
                points="44,54.7 50.5,54.7 50.5,50.4 54.6,50.4 54.6,41.9 50.5,41.9 50.5,39.7 44,39.7 44,41.9 40.4,41.9 
                40.4,50.4 44,50.4"
                fill={fillColorComplementary}
            />
            <path
                d="M42.8,53.7h6.5v-3.3c0-0.6,0.5-1,1-1h3.3v-6.5h-3.3c-0.6,0-1-0.5-1-1v-3.3h-6.5v3.3c0,0.6-0.5,1-1,1h-3.3
                v6.5h3.3c0.5,0,1,0.5,1,1V53.7z M50.3,55.7h-8.5c-0.6,0-1-0.5-1-1v-3.3h-3.3c-0.5,0-1-0.5-1-1v-8.5c0-0.6,0.5-1,1-1h3.3v-3.3
                c0-0.6,0.5-1,1-1h8.5c0.6,0,1,0.5,1,1v3.3h3.3c0.6,0,1,0.5,1,1v8.5c0,0.5-0.5,1-1,1h-3.3v3.3C51.3,55.2,50.8,55.7,50.3,55.7z"
                fill={strokeColor}
            />
        </SvgIcon>
    );
};

SafetyCoursesIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
    fillColorComplementary: string,
    strokeColor: string,
};

SafetyCoursesIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
    fillColor: `${DEFAULT_COLORS.WHITE}`,
    fillColorComplementary: `${DEFAULT_COLORS.ORANGE}`,
    strokeColor: `${DEFAULT_COLORS.BLACK}`,
};

export default SafetyCoursesIcon;
