import { isEmpty } from 'ravenjs/utils/lodash';
import { pdfjs } from 'react-pdf';
import csv from 'csvtojson';

import { configContainsSku } from 'utils/configurations';
import { DEFAULT_COLORS } from 'constants/colors';
import { BYTES_PER_MEGABYTE } from 'constants/common';
import MESSAGES from 'constants/messages';
import { FILE_REGEX } from 'constants/regex';
import { sanitizeMarkup } from 'utils/sanitize';

/**
 * Check if is a valid phone number.
 *
 * @method isValidPhoneNumber
 * @param  {string} number Phone number
 * @return {string}
 */
export const isValidPhoneNumber = (number = '') => {
    number = formatPhoneNumber(number);
    let status = false;

    if (!number || number === '') {
        status = true;
    } else if (
        /^\(?([0-9]{3})\)([\s])([0-9]{3})([-])([0-9]{4})([\s])(ext.)([\s])([0-9]{1,5}$)/.test(
            number
        )
    ) {
        status = true;
    } else if (/^\(?([0-9]{3})\)([\s])([0-9]{3})([-])([0-9]{4})$/.test(number)) {
        status = true;
    }

    return status;
};

/**
 * Check if is a valid address.
 *
 * @method isValidAddress
 * @param  {string} address string
 * @return {string}
 */
export const isValidAddress = (address = '') => {
    let status = false;

    if (!address || address === '') {
        status = true;
    } else if (/^[0-9A-Za-z\s\.,#-@'% &/()]{1,150}$/.test(address)) {
        status = true;
    }
    return status;
};

/**
 * Check if is a valid name.
 *
 * @method isValidName
 * @param  {string} name string
 * @return {string}
 */
export const isValidName = (name = '') => {
    let status = false;

    if (!name || name === '') {
        status = true;
    } else if (/^[^%\\;]*$/.test(name)) {
        status = true;
    }
    return status;
};

/**
 * Check if is a valid Federal Employement Idenetity Number.
 *
 * @method isValidFederalEINumber
 * @param  {string} number  Federal EIN
 * @return {string}
 */
export const isValidFederalEINumber = (number = '') => {
    let status = false;

    if (!number || number === '') {
        status = true;
    } else if (/^(0*[1-9])\d?-\d{7}$/.test(number)) {
        status = true;
    }
    return status;
};

/**
 * Format a phone number.
 *
 * @method formatPhoneNumber
 * @param  {string} number Phone number
 * @return {string}
 */
export const formatPhoneNumber = (number = '') => {
    if (number) {
        number = number.replace(/\D/g, '');

        if (number.length <= 10) {
            number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else {
            number = number.replace(/(\d{3})(\d{3})(\d{4})(\d{1,5})/, '($1) $2-$3 ext. $4');
        }
    }

    return number;
};

/**
 * Get SSO Information from client.
 *
 * @method getDataSso
 * @param  {Object}     ssoClient SSO information from client
 * @param  {Object}     ssoPartner SSO information from partner
 * @return {Object}
 */
export const getDataSso = (ssoClient = {}, ssoPartner = {}) => {
    let data = {
        ssoCompanyName: '',
        mappedField: '',
    };

    if (ssoClient.ssoCompanyName && ssoClient.mappedField) {
        data = ssoClient;
    } else if (ssoPartner.ssoCompanyName && ssoPartner.mappedField) {
        data = ssoPartner;
    }

    return data;
};

/**
 * Check if a file have the correct size and type. Only to Raven Form.
 *
 * @method formValidateFile
 * @param  {file} file File to check
 * @param  {number} size Max Size in MB
 * @param  {string} fileType File type
 * @param  {Object} errors Error's object
 * @return {Object}
 */
export const formValidateFile = (file, size, fileType, errors) => {
    if (
        window.Blob &&
        window.FileReader &&
        window.FileList &&
        window.File &&
        !isEmpty(file) &&
        !isEmpty(file[0])
    ) {
        const fileExtension = file[0].name.replace(FILE_REGEX.EXTENSION, '');
        // The file size should not be bigger than size property in MB.
        file[0].size > convertMegabyteToByte(size) &&
            errors.addError(
                `Max allowed file size is ${size}MB. Chosen file's size is '${convertByteToMegabyte(
                    file[0].size
                ).toFixed(2)}MB'. Please reselect a smaller file.`
            );
        // The file should be of the type including in fileTypes property.
        fileType !== fileExtension &&
            errors.uploadInfo.file.addError(getFileTypeErrorMessage(fileType));
    }
    return errors;
};

/**
 * Check if a file have the correct size.
 *
 * @method formValidateFile
 * @param  {file} file File to check
 * @param  {number} size Max Size in MB
 * @param  {string} fileType File type
 * @param  {Object} errors Error's object
 * @return {Object}
 */
export const isValidFileSize = (file, size) => {
    if (!isEmpty(file) && !isEmpty(file[0])) {
        return convertMegabyteToByte(size) >= file[0].size;
    }
    return false;
};

/**
 * Download a file.
 *
 * @method downloadFile
 * @param  {file|string} file File to download
 * @param  {string} fileName Name of file
 */
export const downloadFile = (file, fileName) => {
    if (navigator.msSaveBlob) {
        const blob = new Blob([file], {
            type: 'text/csv;charset=utf8;',
        });
        navigator.msSaveBlob(blob, fileName);
    } else {
        const uri = `data:text/csv;charset=utf-8,${file}`;
        const encodedUri = encodeURI(uri);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
};

/**
 * Download a xlsx file.
 *
 * @method downloadXLSXFile
 * @param  {Object} file File data
 * @param  {string} fileName Name of file
 */
export const downloadXLSXFile = (file, fileName) => {
    const blob = new Blob([file.data], {
        type: file.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};

/**
 * Download a csv file with special characters.
 *
 * @method downloadCSVFileWithSpecialChar
 * @param  {Object} file File data
 * @param  {string} fileName Name of file
 */
export const downloadCSVFileWithSpecialChar = (file, fileName) => {
    const blob = new Blob([file], {
        type: 'text/csv;charset=utf8;',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};

export const downloadFileByUrl = (url, fileName, target) => {
    if (isInternetExplorer()) {
        window.open(url, target);
    } else {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.target = target || '';

        if (isIos()) {
            document.location = link.href;
        } else {
            link.dispatchEvent(new MouseEvent('click'));
            (window.URL || window.webkitURL).revokeObjectURL(link.href);
        }
    }
};

/**
 * Extract some relational info about a given configuration and it's skus.
 *
 * @method downloadFile
 * @param  {sring} config Configuration
 * @return {string}
 */
export const extractConfigRelationalInfo = config => {
    // If the config is empty, return an empty obj.
    if (isEmpty(config)) {
        return {};
    }
    // Otherwise return an object with some relational info.
    return {
        hasBcsElite: configContainsSku(config, 'bcselite'),
        hasHbBuilder: configContainsSku(config, 'handbooks'),
        hasPEOBuilder: configContainsSku(config, 'peohandbook'),
        hasHbFederal:
            configContainsSku(config, 'handbooks') && configContainsSku(config, 'handbooksfederal'),
        hasHbMulti:
            configContainsSku(config, 'handbooks') &&
            configContainsSku(config, 'handbooksmultistate'),
        hasHbOne:
            configContainsSku(config, 'handbooks') &&
            configContainsSku(config, 'handbooksonestate'),
        hasPEOFederal:
            configContainsSku(config, 'peohandbook') &&
            configContainsSku(config, 'handbooksfederal'),
        hasPEOMulti:
            configContainsSku(config, 'peohandbook') &&
            configContainsSku(config, 'handbooksmultistate'),
        hasPEOOne:
            configContainsSku(config, 'peohandbook') &&
            configContainsSku(config, 'handbooksonestate'),
    };
};

/**
 * Get warning message on assign bulk configuration process.
 *
 * @method getAssignBulkConfigWarningMessage
 * @param  {Object} config Configuration
 * @param  {Object} diffSkusList Configuration
 * @param  {string} partnerId Configuration
 * @return {string}
 */
export const getAssignBulkConfigWarningMessage = (config, diffSkusList, partnerId) => {
    const data = buildParamsForBulkAssignConfigsToCompanies(config, diffSkusList, partnerId);
    let message = '';
    const hbMessageItems = [];
    if (data.companiesHasBcsElite && !data.configurationHasBcsElite) {
        message += MESSAGES.ALERT.CONFIGURATION.EXISTS_SKU_BCS_ELITE;
    }
    if (data.companiesHasHbBuilder && !data.configurationHasHbBuilder) {
        if (data.companiesHasPEOBuilder && data.configurationHasPEOBuilder) {
            hbMessageItems.push('All PEO Handbooks');
        } else {
            hbMessageItems.push('All Handbooks');
        }
    } else if (data.companiesHasPEOBuilder && !data.configurationHasPEOBuilder) {
        if (data.companiesHasHbBuilder && data.configurationHasHbBuilder) {
            hbMessageItems.push('All PEO Handbooks');
        } else {
            hbMessageItems.push('All Handbooks');
        }
    } else if (
        (data.companiesHasHbBuilder && data.configurationHasHbBuilder) ||
        (data.companiesHasPEOBuilder && data.configurationHasPEOBuilder)
    ) {
        if (data.companiesHasHbFederal && !data.configurationHasHbFederal) {
            hbMessageItems.push('All Federal Handbooks');
        }
        if (data.companiesHasHbOne && !data.configurationHasHbOne) {
            hbMessageItems.push('All One-State Handbooks');
        }
        if (data.companiesHasHbMulti && !data.configurationHasHbMulti) {
            hbMessageItems.push('All Multi-State Handbooks');
        }
    }

    if (hbMessageItems.length > 0) {
        message += `<p class="margin-top-20 margin-bottom-0">
            ${MESSAGES.ALERT.CONFIGURATION.BATCH_HANDBOOK}
            </p>`;
        message += '<ul>';

        for (let i = 0; i < hbMessageItems.length; i += 1) {
            message += `<li> ${hbMessageItems[i]}</li>`;
        }

        message += '</ul>';
    }
    return message;
};

/**
 * Build information to DetailsSection component.
 *
 * @method buildSectionInformation
 * @param  {Array} fields Fields to build
 * @param  {Object} data Data information
 * @return {Array}
 */
export const buildSectionInformation = (fields, data) => {
    const response = fields.map(field => {
        if (field.key.toLowerCase().indexOf('active') !== -1) {
            field.value = data[field.key] ? 'Active' : 'Inactive';
        } else {
            field.value = data[field.key] || '-';
        }
        return field;
    });

    return response;
};

/**
 * Build custom field to advanced search.
 *
 * @method buildCustomFields
 * @param  {Array}  fields      Fields to build
 * @param  {Object} valueMap    map of values
 * @return {Array}
 */
export const buildCustomFields = (fields, valueMap = {}) => {
    return fields.map(field => ({
        id: field.displayLabel.toLowerCase(),
        key: field.displayKey,
        label: field.displayLabel.replace('_', ' '),
        placeholder: '',
        searchKey: `custom${field.displayOrder}`,
        value: valueMap[`custom${field.displayOrder}`] || '',
        displayOrder: field.displayOrder,
    }));
};

export const getFormatedStates = states => {
    const newStates = states.map(state => {
        return {
            label: state.name,
            value: state.abbreviation,
        };
    });

    return newStates;
};

/**
 * Build params to bulk assign configuration.
 *
 * @method buildParamsForBulkAssignConfigsToCompanies
 * @param  {Object} config Configuration
 * @param  {Object} diffSkusList Sku list
 * @param  {string} partnerId
 * @return {string}
 */
const buildParamsForBulkAssignConfigsToCompanies = (config, diffSkusList, partnerId) => {
    const {
        configInfo,
        configurationId,
        excludedClients,
        selectedAllClients,
        selectedClients,
    } = config;
    const params = {};
    // Update params.
    params.allCompanies = selectedAllClients || false;
    params.companiesHasBcsElite = selectedAllClients || false;
    params.companiesHasHbBuilder = selectedAllClients || false;
    params.companiesHasPEOBuilder = selectedAllClients || false;
    params.companiesHasHbOne = selectedAllClients || false;
    params.companiesHasHbMulti = selectedAllClients || false;
    params.companiesHasHbFederal = selectedAllClients || false;
    params.configurationHasBcsElite = false;
    params.configurationHasHbBuilder = false;
    params.configurationHasPEOBuilder = false;
    params.configurationHasHbOne = false;
    params.configurationHasHbMulti = false;
    params.configurationHasHbFederal = false;
    params.partnerId = partnerId;
    params.excludedCompanyIds = excludedClients;
    params.companyIds = [];
    // If the config info is present.
    if (!isEmpty(configInfo)) {
        // Extract some relational info about the selected configuration and it's skus.
        const configSkuInfo = extractConfigRelationalInfo(configInfo);
        // Update the params based on the relational info.
        params.configurationId = configurationId;
        params.configurationHasBcsElite = configSkuInfo.hasBcsElite;
        params.configurationHasHbBuilder = configSkuInfo.hasHbBuilder;
        params.configurationHasPEOBuilder = configSkuInfo.hasPEOBuilder;
        params.configurationHasHbFederal = configSkuInfo.hasHbFederal;
        params.configurationHasHbMulti = configSkuInfo.hasHbMulti;
        params.configurationHasHbOne = configSkuInfo.hasHbOne;
    }
    // If the user has not selected all of the companies,
    // then we need to check the diff-skus list for each of the selected companies,
    // to determine if the above params need to be updated.
    if (!selectedAllClients && !isEmpty(diffSkusList)) {
        // Update the list of comapny ids.
        params.companyIds = selectedClients;
        // Go through the available diff-skus list and update the params if needed.
        const configWithSkus = { skus: diffSkusList };
        params.companiesHasBcsElite = configContainsSku(configWithSkus, 'bcselite');
        params.companiesHasHbBuilder = configContainsSku(configWithSkus, 'handbooks');
        params.companiesHasPEOBuilder = configContainsSku(configWithSkus, 'peohandbook');
        params.companiesHasHbFederal = configContainsSku(configWithSkus, 'handbooksfederal');
        params.companiesHasHbMulti = configContainsSku(configWithSkus, 'handbooksmultistate');
        params.companiesHasHbOne = configContainsSku(configWithSkus, 'handbooksonestate');
    }
    // Return the updated params.
    return params;
};

const getFileTypeErrorMessage = type => {
    let error = '';

    switch (type) {
        case 'csv':
            error = 'You are only allowed to upload a CSV document (.csv).';
            break;

        default:
            break;
    }

    return error;
};

const convertMegabyteToByte = sizeToConvert => {
    return sizeToConvert ? sizeToConvert * BYTES_PER_MEGABYTE : 0;
};

const convertByteToMegabyte = bytes => {
    return bytes ? bytes / BYTES_PER_MEGABYTE : 0;
};

/**
 * Formats CSV Array to the JSON format
 *
 * @param   {Array} csvArray
 * @return  {Array}
 */
export const formatCSVArrayToJSON = csvArray => {
    if (isEmpty(csvArray)) {
        return [];
    }
    const headers = csvArray[0].split(',');
    const records = csvArray.splice(1);
    const result = [];

    records
        .filter(record => record)
        .forEach(record => {
            const recordResultObj = {};
            const recordArray = record.split(',');
            headers.forEach((header, index) => {
                recordResultObj[header] = recordArray[index];
            });
            result.push(recordResultObj);
        });

    return result;
};

/**
 * Formats CSV Array to the JSON with double quotes handling
 *
 * @param   {Array} csvArray
 * @return  {Array}
 */
export const formatCSVArrayToJSONWithQuotes = async csvArray => {
    const csvString = csvArray.join('\n');
    const result = [];
    await csv()
        .fromString(csvString)
        .subscribe(record => {
            result.push(record);
        });

    return result;
};

export const isInternetExplorer = () => {
    const ua = navigator.userAgent.toLowerCase();

    return ua.indexOf('MSIE ') > -1 || ua.indexOf('trident/') > -1 || ua.indexOf('edge/') > -1;
};

/**
 * Check if the given route is un-authenticated
 *
 * @param   {string}    pathname
 * @return  {boolean}
 */
export const isPublicRoute = pathname =>
    pathname.indexOf('/auth/') !== -1 ||
    pathname.indexOf('/public/') !== -1 ||
    pathname.indexOf('/openid') !== -1 ||
    pathname.indexOf('/openid/redirect') !== -1;

/**
 * Check if the given route is soft-login
 *
 * @param   {string}    pathname
 * @return  {boolean}
 */
export const isSoftLoginRoute = pathname =>
    Boolean(pathname.indexOf('/public/content/details') !== -1);

export const isFirefox = () => {
    const ua = navigator.userAgent.toLowerCase();

    return (
        ua.indexOf('mozilla') > -1 &&
        ua.indexOf('chrome') === -1 &&
        ua.indexOf('safari') === -1 &&
        !isInternetExplorer()
    );
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const getRatingColor = score => {
    let backgroundColor = '';
    let color = '';

    if (score < 60) {
        backgroundColor = DEFAULT_COLORS.RED;
        color = DEFAULT_COLORS.WHITE;
    } else if (score >= 60 && score < 90) {
        backgroundColor = DEFAULT_COLORS.YELLOW;
        color = DEFAULT_COLORS.BLACK;
    } else {
        backgroundColor = DEFAULT_COLORS.GREEN;
        color = DEFAULT_COLORS.WHITE;
    }

    return { backgroundColor, color };
};

export const isSafari = () => {
    return (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search('Chrome') < 0
    );
};

export const isIos = () => {
    return navigator.userAgent.toLowerCase().match(/(ipad|iphone)/);
};

const handlePages = (mainPdf, page, currPage, numPages, print, fileName) => {
    if (document.getElementById('containerCanvas')) {
        const viewport = page.getViewport({ scale: 1.05 });
        const canvas = document.createElement('canvas');
        canvas.id = `pdfCanvas${currPage}`;
        canvas.style.display = 'block';
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page.render({ canvasContext: context, viewport });
        document.getElementById('containerCanvas').appendChild(canvas);
        currPage += 1;

        if (mainPdf !== null && currPage <= numPages) {
            mainPdf.getPage(currPage).then(page => {
                handlePages(mainPdf, page, currPage, numPages, print, fileName);
            });
        } else if (mainPdf !== null && currPage > numPages && print) {
            setTimeout(() => {
                printPdf(fileName);
                document.getElementById('containerCanvas').innerHTML = '';
            }, 1000);
        }
    }
};

export const buildCanvasPdf = (url, print, fileName) => {
    if (!isEmpty(url)) {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        const loadingTask = pdfjs.getDocument(url);
        loadingTask.promise.then(
            function(pdf) {
                pdf.getPage(1).then(page => {
                    handlePages(pdf, page, 1, pdf.numPages, print, fileName);
                });
            },
            function(error) {}
        );
    }
};

export const printPdf = fileName => {
    const canvasList = [];
    let width = 0;
    let height = 0;
    const canvas = document.querySelectorAll('canvas');
    let i = 0;

    for (i = 0; i < canvas.length; i += 1) {
        const current = document.getElementById(`pdfCanvas${i + 1}`);

        if (current) {
            canvasList.push({
                cnv: current,
                y: i === 0 ? 0 : current.height,
            });

            height += canvas[i].height;
        }
    }

    const newCanvas = document.createElement('canvas');
    const ctx = newCanvas.getContext('2d');
    width = document.getElementById('pdfCanvas1')
        ? document.getElementById('pdfCanvas1').width
        : '800px';

    newCanvas.width = width;
    newCanvas.height = height;

    canvasList.forEach(function(n) {
        ctx.beginPath();
        ctx.drawImage(n.cnv, 0, n.y, width, n.cnv.height);
    });

    let html = `<html><head><title>${
        sanitizeMarkup(
            fileName
                .split(' ')
                .join('_')
                .substr(0, 100)
        ).__html
    }.pdf</title></head>`;
    html += '<body style="width: 100%; padding: 0; margin: 0;"';
    html += ' onload="window.focus(); window.print(); setTimeout(window.close, 0);">';

    canvasList.forEach(function(value, key) {
        html += `<img src="${document.getElementById(`pdfCanvas${key + 1}`)?.toDataURL()}"/>`;
    });

    const printWindow = window.open('', 'to_print', 'height=600,width=800');

    printWindow.document.open();
    printWindow.document.write(html);
    printWindow.document.close();
};

export const insertNodeIntoArray = (array, newNode, checkForCustomOrder) => {
    const isNodeAlreadyExists = array.some(item => item.displayLabel === newNode.displayLabel);

    if (isNodeAlreadyExists) {
        return array;
    }

    let maxDisplayOrder = 0;
    let maxId = 0;
    let maxDisplayKeyNumber = 0;
    let partnerId = '';

    for (const item of array) {
        partnerId = item.partnerId;
        const displayOrder = parseInt(item.displayOrder, 10);
        if (displayOrder > maxDisplayOrder) {
            maxDisplayOrder = displayOrder;
        }

        const id = parseInt(item.id, 10);
        if (id > maxId) {
            maxId = id;
        }

        const displayKeyNumber = parseInt(item.displayKey.slice(1), 10);
        if (displayKeyNumber > maxDisplayKeyNumber) {
            maxDisplayKeyNumber = displayKeyNumber;
        }
    }

    newNode.displayOrder = (maxDisplayOrder + 1).toString();
    newNode.id = maxId + 1;
    newNode.displayKey = `c${maxDisplayKeyNumber + 1}`;
    newNode.partnerId = partnerId;

    const indexToInsertBefore = array.findIndex(item => item.displayLabel === checkForCustomOrder);

    if (indexToInsertBefore !== -1) {
        return [
            ...array.slice(0, indexToInsertBefore),
            newNode,
            ...array.slice(indexToInsertBefore),
        ];
    } else {
        return [...array, newNode];
    }
};
