import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Typography from 'ravenjs/lib/Typography';
import { themeGet } from 'ravenjs/utils/theme';
import _get from 'lodash/get';

const DescriptionFieldStyled = styled(Typography)`
    /**
     * Add all of the remaining styles from the theme
     */
    ${themeGet('DescriptionField.styles')};
    /**
     * Add in the dynamic styles
     */
    ${({ root, theme }) => {
        const rootStyles = themeGet('DescriptionField.styles.root', null)({ theme });

        return root ? rootStyles : null;
    }};
`;

function DescriptionField(props) {
    const { id, description, formContext, required, value, ...rest } = props;
    const taintedFields = _get(formContext, 'taintedFields', []).map(
        fieldId => `${fieldId}__description`
    );
    const pristine = _get(formContext, 'pristine', false);
    const hasRequiredAttribute = required && (taintedFields.includes(id) || !pristine);
    const customTopMargin = hasRequiredAttribute ? '0' : '10px';

    // If description is not available, don't render.
    if (!description) {
        return null;
    }
    // Otherwise, render the regular description.
    return (
        <DescriptionFieldStyled
            color="#848484"
            gutterBottom="0"
            gutterLeft="5px"
            gutterRight="5px"
            gutterTop={customTopMargin}
            {...rest}
            fontSize="14px"
            htmlFor={id}
            fontFamily="Roboto"
        >
            {description}
        </DescriptionFieldStyled>
    );
}

DescriptionField.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    formContext: PropTypes.object,
    required: PropTypes.bool,
    value: PropTypes.any,
};

DescriptionField.defaultProps = {
    id: null,
    description: null,
    formContext: null,
    required: null,
    value: null,
};

export default DescriptionField;
