const PARTNER_ENABLEMENT = {
    LOAD_MIND_MATRIX: {
        event: 'partnerEnablementMindMatrixLoaded',
        eventProperties: {
            description: 'Loaded Mind Matrix in iframe via Partner Enablement',
        },
    },
    OPEN: {
        event: 'partnerEnablementPageOpened',
        eventProperties: {
            description: 'Opened Partner Enablement page',
        },
    },
};

export default PARTNER_ENABLEMENT;
