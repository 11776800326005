import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const AngleRightIcon = ({ height, viewBox, width, fillColor }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path
                fill={fillColor}
                d="M13.997 3.48c.363-.416.995-.459 1.411-.095.384.335.45.9.172 1.311l-.077.1-6.75 7.724c-.37.424-1.008.454-1.416.091l-.09-.09-6.75-7.725C.134 4.38.177 3.748.592 3.385c.384-.336.952-.325 1.323.005l.088.09L8 10.342l5.997-6.862z"
                transform="matrix(0 1 1 0 0 0)"
            />
        </SvgIcon>
    );
};

AngleRightIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
};

AngleRightIcon.defaultProps = {
    width: '16',
    height: '16',
    viewBox: '0 0 16 16',
    fillColor: '#C7C7C7',
};

export default AngleRightIcon;
