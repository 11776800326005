import { createActions } from 'utils/actions';

/**
 * Redux actions for sending a pendo event.
 *
 * @type {Object}
 */
export const sendPendoEvent = createActions('pendo', 'SEND_PENDO_EVENT', {
    asPromise: false,
});

/**
 * Redux actions for sending a public pendo event.
 *
 * @type {Object}
 */
export const sendPublicPendoEvent = createActions('pendo', 'SEND_PUBLIC_PENDO_EVENT', {
    asPromise: false,
});

/**
 * Redux actions for get chat pendo guide.
 *
 * @type {Object}
 */
export const getChatPendoGuide = createActions('pendo', 'GET_CHAT_PENDO_GUIDE', {
    asPromise: true,
});
