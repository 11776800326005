export const NOT_RECORD_FOUND = 'No records found for this request.';

export const LINE_BREAK_TOOLTIP_MESSAGE =
    'Locked policies are limited to basic styling and link insertion when editing. Line breaks and lists are not supported.';

export const DOCUMENT_PREVIEW = {
    TOOLTIP: {
        COPY_LINK: 'Copy Link',
        DOWNLOAD: 'Download',
        FAVORITE: 'Favorite',
        GENERATING_DOC: 'Generating Document...',
        LINK_COPIED: 'Link Copied',
        PRINT: 'Print',
    },
};

export const ACTIONS = {
    CANCEL: 'Cancel',
    SUBMIT: 'Submit',
    ADD_ITEM: 'Add Item',
};

export const FORM_FIELDS_DESCRIPTION = 'All fields are required unless marked optional.';

export const REQUIRED = 'Required';
