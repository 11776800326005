import { takeLatest } from 'redux-saga/effects';

import * as toolActions from '../actions';

import {
    fetchJobsByFunctionalArea,
    fetchJobsList,
    fetchJobDetails,
    fetchFunctionalAreasList,
    fetchJobsRecommendedContentList,
} from './jobsLibrary';

import {
    fetchBenefitsDocumentCreatorUrlSaga,
    fetchBenefitsDocumentCreatorUrlResourcesSaga,
    fetchBenefitsDocumentCreateDocumentUrlSaga,
    fetchBdcDocumentsSaga,
} from './benefitsDocumentCreator';

import { fetchMinWageMapSaga } from './minimumWageMap';

/**
 * Root saga for `tools`
 * Triggers sagas related to all tool events
 *
 * @method toolsSaga
 * @type   {Generator}
 */
function* toolSaga() {
    yield takeLatest(toolActions.fetchJobsList.TRIGGER, fetchJobsList);
    yield takeLatest(toolActions.fetchJobDetails.TRIGGER, fetchJobDetails);
    yield takeLatest(toolActions.fetchFunctionalAreasList.TRIGGER, fetchFunctionalAreasList);
    yield takeLatest(
        toolActions.fetchJobsRecommendedContent.TRIGGER,
        fetchJobsRecommendedContentList
    );
    yield takeLatest(toolActions.fetchJobsByFunctionalArea.TRIGGER, fetchJobsByFunctionalArea);
    yield takeLatest(
        toolActions.fetchBenefitsDocumentCreatorUrl.TRIGGER,
        fetchBenefitsDocumentCreatorUrlSaga
    );
    yield takeLatest(
        toolActions.fetchBenefitsDocumentCreateDocumentUrl.TRIGGER,
        fetchBenefitsDocumentCreateDocumentUrlSaga
    );
    yield takeLatest(toolActions.fetchBdcDocuments.TRIGGER, fetchBdcDocumentsSaga);
    yield takeLatest(toolActions.fetchMinWageMapData.TRIGGER, fetchMinWageMapSaga);
    yield takeLatest(
        toolActions.fetchBenefitsDocumentCreatorUrlResources.TRIGGER,
        fetchBenefitsDocumentCreatorUrlResourcesSaga
    );
}

export default toolSaga;

export {
    fetchJobsList,
    fetchJobDetails,
    fetchFunctionalAreasList,
    fetchJobsRecommendedContentList,
    fetchBenefitsDocumentCreateDocumentUrlSaga,
    fetchBdcDocumentsSaga,
    fetchMinWageMapSaga,
};
