/**
 * Normalize a given `string` and split it up based on a given `strToSplitWith`.
 *
 * @method normalizeSplitString
 * @param  {string}             [str=null]             The string to normalize
 * @param  {string}             [strToReplace='']      The string to replace inside of the str
 * @param  {string}             [strToSplitWith='___'] The string to split the replaced str
 * @return {Array}                                     The list of normalized split strings
 */
export function normalizeSplitString(str = null, strToReplace = '', strToSplitWith = '___') {
    // Build the regex with the `strToReplace`.
    const regex = new RegExp(`(${strToReplace})`, 'g');
    // Replace and split the `str` based on the given regex,
    // then split it up based on the `strToSplitWith` string.
    return str
        ? String(str)
              .replace(regex, '')
              .split(strToSplitWith)
        : [];
}

export function stringReplaceWithJSX(str, replaceMap) {
    let updatedString = str;
    const finalJSXArray = [];

    const getCustomLinks = (stringToCheck = '') => {
        let customLink = null;

        Object.entries(replaceMap).forEach(([key, value]) => {
            if (stringToCheck.split(key).length > 1 && !customLink) {
                customLink = {
                    key,
                    value,
                };
            }
        });
        return customLink;
    };

    while (getCustomLinks(updatedString)) {
        const { key, value } = getCustomLinks(updatedString);
        const parts = updatedString.split(key);
        finalJSXArray.push(parts[0]);
        finalJSXArray.push(value);
        [, updatedString] = parts;
    }

    finalJSXArray.push(updatedString);

    return finalJSXArray;
}
