import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const WatchIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        {...rest}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.8,12.4c0-2.2-1-4.3-2.5-5.7l-0.3-3.6c-0.1-0.7-0.4-1.3-0.9-1.8c-0.5-0.5-1.1-0.7-1.9-0.7H9.8C9.1,0.7,8.5,1,8,1.4
            C7.5,1.9,7.2,2.5,7.1,3.2L6.8,6.7c-1.5,1.4-2.5,3.4-2.5,5.7c0,2.3,1,4.3,2.5,5.7l0.3,3.6C7.1,22.4,7.5,23,8,23.5
            c0.5,0.5,1.2,0.7,1.8,0.7c0,0,0,0,0,0h4.3c0,0,0,0,0,0c0.7,0,1.3-0.3,1.8-0.7c0.5-0.5,0.8-1.1,0.9-1.8l0.3-3.5
            C18.8,16.7,19.8,14.7,19.8,12.4z M8.6,3.3C8.6,3,8.8,2.7,9,2.5c0.2-0.2,0.5-0.3,0.8-0.3h4.4c0.3,0,0.6,0.1,0.8,0.3
            c0.2,0.2,0.4,0.5,0.4,0.8l0.2,2.3C14.6,5,13.3,4.7,12,4.7c-1.3,0-2.5,0.3-3.6,0.9L8.6,3.3z M5.8,12.4C5.8,9,8.6,6.2,12,6.2
            s6.2,2.8,6.2,6.2c0,1.9-0.9,3.6-2.2,4.8c0,0,0,0,0,0c-1.1,0.9-2.5,1.5-4,1.5C8.6,18.7,5.8,15.9,5.8,12.4z M15.4,21.6
            c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.4-0.9,0.3H9.8c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.4-0.5-0.4-0.8l-0.2-2.3
            c1.1,0.6,2.3,0.9,3.6,0.9c1.3,0,2.5-0.3,3.6-0.9L15.4,21.6z"
            fill={color}
        />
        <path
            d="M13,14.5c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1l-1.3-1.3V9.4c0-0.4-0.3-0.8-0.8-0.8
            S11.2,9,11.2,9.4v3c0,0.2,0.1,0.4,0.2,0.5L13,14.5z"
            fill={color}
        />
    </SvgIcon>
);

WatchIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

WatchIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default WatchIcon;
