/**
 * NOTE: The goal of this file is to load in all of the dependent
 * font-awesome icons and add them to the `library`.
 * This helps us keep track of all the loaded font-awesome icons
 * in a single file, and they get loaded upon bootstrap cycle of the app.
 */

import { library } from '@fortawesome/fontawesome-svg-core';

import { faBug } from '@fortawesome/free-solid-svg-icons/faBug';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons/faShareSquare';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faRetweet } from '@fortawesome/free-solid-svg-icons/faRetweet';

// Build a font-awesome icons library.
library.add(
    faBug,
    faComment,
    faComments,
    faCog,
    faCopy,
    faFax,
    faFilePdf,
    faFileWord,
    faFlag,
    faHistory,
    faList,
    faPhone,
    faShare,
    faTrashAlt,
    faShareSquare,
    faTimesCircle,
    faEye,
    faDownload,
    faEnvelope,
    faRetweet
);
