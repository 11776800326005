import React from 'react';
import { string } from 'prop-types';
import SvgIcon from 'ravenjs/lib/SvgIcon';
import styled from 'styled-components';

const IconStyled = styled(SvgIcon)`
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7 {
        stroke-width: 0px;
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-8,
    .cls-9,
    .cls-5 {
        mix-blend-mode: multiply;
    }
    .cls-1,
    .cls-6 {
        fill: #f9d88f;
    }
    .cls-2 {
        fill: #9fe7f5;
    }
    .cls-3 {
        fill: #282828;
    }
    .cls-4,
    .cls-8,
    .cls-10,
    .cls-11,
    .cls-5,
    .cls-12 {
        fill: none;
    }
    .cls-13 {
        fill: #f7f6f1;
    }
    .cls-8 {
        stroke-width: 1.8px;
    }
    .cls-8,
    .cls-10,
    .cls-11,
    .cls-12 {
        stroke-miterlimit: 10;
    }
    .cls-8,
    .cls-12 {
        stroke: #282828;
    }
    .cls-14 {
        clip-path: url(#clippath-3);
    }
    .cls-15 {
        clip-path: url(#clippath-2);
    }
    .cls-10 {
        stroke: #c1b6a4;
        stroke-width: 3.6px;
    }
    .cls-11 {
        stroke: #f9d88f;
        stroke-width: 1.58704px;
    }
    .cls-16 {
        isolation: isolate;
    }
    .cls-12 {
        stroke-width: 1.98153px;
    }
    .cls-7 {
        fill: #f15a26;
    }
`;
const SessionExpiryIcon = ({ viewBox, height, width }) => {
    return (
        <IconStyled width={width} viewBox={viewBox} height={height} fill="none">
            <defs>
                <clipPath id="clippath-2">
                    <path
                        className="cls-4"
                        d="m97.22715,179.17525c5.40083-.00718,10.07311,4.78247,14.80988,8.05838,17.65578,12.2106,38.28477,21.15271,57.15586,31.7677l.03272.01749h-47.24584c-25.67975.46894-52.67975.46894-79.09965.01552-2.44911-.00559-4.85727-.00898-7.19855-.01552H9.96446c.82597,0,2.56952-1.38046,3.3103-1.77633,16.10438-8.05219,31.63924-15.68859,48.5426-22.03742,10.71413-4.02418,20.67786-9.39752,30.74764-14.79119,1.62087-.86818,3.16933-1.23664,4.66214-1.23862Z"
                    />
                </clipPath>
                <clipPath id="clippath-3">
                    <polygon
                        className="cls-4"
                        points="43.37658 47.92757 135.79978 47.92757 89.58818 111.09763 43.37658 47.92757"
                    />
                </clipPath>
            </defs>
            <g className="cls-16">
                <g id="Layer_2">
                    <g id="Spot_illustration">
                        <rect
                            className="cls-13"
                            x="0"
                            y="62.22376"
                            width="147.48941"
                            height="32.64797"
                            rx="16.32397"
                            ry="16.32397"
                        />
                        <path
                            className="cls-13"
                            d="m208.70761,154.92364h-91.57933c-12.58074-.09639-34.38747-9.78306-41.6408-31.2072h133.22013c9.24482,0,16.80876,6.75128,16.80876,15.00284v1.20151c0,8.25156-7.56394,15.00284-16.80876,15.00284Z"
                        />
                        <polygon
                            className="cls-6"
                            points="89.58818 111.09763 9.95074 2.23531 89.58818 2.23531 169.22562 2.23531 89.58818 111.09763"
                        />
                        <polygon
                            className="cls-6"
                            points="89.58818 110.15649 129.4069 164.58766 169.22562 219.01882 89.58818 219.01882 9.95074 219.01882 49.76946 164.58766 89.58818 110.15649"
                        />
                        <path
                            className="cls-1"
                            d="m97.22715,179.17525c5.40083-.00718,10.07311,4.78247,14.80988,8.05838,17.65578,12.2106,38.28477,21.15271,57.15586,31.7677l.03272.01749h-47.24584c-25.67975.46894-52.67975.46894-79.09965.01552-2.44911-.00559-4.85727-.00898-7.19855-.01552H9.96446c.82597,0,2.56952-1.38046,3.3103-1.77633,16.10438-8.05219,31.63924-15.68859,48.5426-22.03742,10.71413-4.02418,20.67786-9.39752,30.74764-14.79119,1.62087-.86818,3.16933-1.23664,4.66214-1.23862Z"
                        />
                        <path
                            className="cls-5"
                            d="m97.22715,179.17525c5.40083-.00718,10.07311,4.78247,14.80988,8.05838,17.65578,12.2106,38.28477,21.15271,57.15586,31.7677l.03272.01749h-47.24584c-25.67975.46894-52.67975.46894-79.09965.01552-2.44911-.00559-4.85727-.00898-7.19855-.01552H9.96446c.82597,0,2.56952-1.38046,3.3103-1.77633,16.10438-8.05219,31.63924-15.68859,48.5426-22.03742,10.71413-4.02418,20.67786-9.39752,30.74764-14.79119,1.62087-.86818,3.16933-1.23664,4.66214-1.23862Z"
                        />
                        <g className="cls-15">
                            <g className="cls-9">
                                <line
                                    className="cls-11"
                                    x1="-13.2531"
                                    y1="175.7268"
                                    x2="23.19469"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="-7.95873"
                                    y1="175.7268"
                                    x2="28.48905"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="-2.66437"
                                    y1="175.7268"
                                    x2="33.78342"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="2.62999"
                                    y1="175.7268"
                                    x2="39.07778"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="7.92436"
                                    y1="175.7268"
                                    x2="44.37214"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="13.21872"
                                    y1="175.7268"
                                    x2="49.66651"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="18.51308"
                                    y1="175.7268"
                                    x2="54.96087"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="23.80745"
                                    y1="175.7268"
                                    x2="60.25523"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="29.10181"
                                    y1="175.7268"
                                    x2="65.5496"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="34.39617"
                                    y1="175.7268"
                                    x2="70.84396"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="39.69054"
                                    y1="175.7268"
                                    x2="76.13832"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="44.9849"
                                    y1="175.7268"
                                    x2="81.43269"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="50.27926"
                                    y1="175.7268"
                                    x2="86.72705"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="55.57363"
                                    y1="175.7268"
                                    x2="92.02141"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="60.86799"
                                    y1="175.7268"
                                    x2="97.31578"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="66.16235"
                                    y1="175.7268"
                                    x2="102.61014"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="71.45671"
                                    y1="175.7268"
                                    x2="107.9045"
                                    y2="225.86603"
                                />
                            </g>
                            <g className="cls-9">
                                <line
                                    className="cls-11"
                                    x1="76.75108"
                                    y1="175.7268"
                                    x2="113.19887"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="82.04544"
                                    y1="175.7268"
                                    x2="118.49323"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="87.3398"
                                    y1="175.7268"
                                    x2="123.78759"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="92.63417"
                                    y1="175.7268"
                                    x2="129.08196"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="97.92853"
                                    y1="175.7268"
                                    x2="134.37632"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="103.22289"
                                    y1="175.7268"
                                    x2="139.67068"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="108.51726"
                                    y1="175.7268"
                                    x2="144.96505"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="113.81162"
                                    y1="175.7268"
                                    x2="150.25941"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="119.10598"
                                    y1="175.7268"
                                    x2="155.55377"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="124.40035"
                                    y1="175.7268"
                                    x2="160.84814"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="129.69471"
                                    y1="175.7268"
                                    x2="166.1425"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="134.98907"
                                    y1="175.7268"
                                    x2="171.43686"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="140.28344"
                                    y1="175.7268"
                                    x2="176.73123"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="145.5778"
                                    y1="175.7268"
                                    x2="182.02559"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="150.87216"
                                    y1="175.7268"
                                    x2="187.31995"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="156.16653"
                                    y1="175.7268"
                                    x2="192.61432"
                                    y2="225.86603"
                                />
                                <line
                                    className="cls-11"
                                    x1="161.46089"
                                    y1="175.7268"
                                    x2="197.90868"
                                    y2="225.86603"
                                />
                            </g>
                        </g>
                        <line className="cls-10" x1="7.20163" y1="1.8" x2="171.97473" y2="1.8" />
                        <circle className="cls-2" cx="23.20838" cy="65.34753" r="13.20021" />
                        <circle className="cls-8" cx="23.20838" cy="62.58222" r="13.20021" />
                        <circle className="cls-1" cx="203.70447" cy="151.92124" r="13.20021" />
                        <circle className="cls-8" cx="203.70447" cy="154.82724" r="13.20021" />
                        <line
                            className="cls-10"
                            x1="6.91908"
                            y1="218.90999"
                            x2="171.69217"
                            y2="218.90999"
                        />
                        <polygon
                            className="cls-1"
                            points="43.37658 47.92757 135.79978 47.92757 89.58818 111.09763 43.37658 47.92757"
                        />
                        <g className="cls-14">
                            <g className="cls-9">
                                <line
                                    className="cls-11"
                                    x1="8.7645"
                                    y1="45.6228"
                                    x2="45.21229"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="14.05887"
                                    y1="45.6228"
                                    x2="50.50665"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="19.35323"
                                    y1="45.6228"
                                    x2="55.80102"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="24.64759"
                                    y1="45.6228"
                                    x2="61.09538"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="29.94196"
                                    y1="45.6228"
                                    x2="66.38974"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="35.23632"
                                    y1="45.6228"
                                    x2="71.68411"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="40.53068"
                                    y1="45.6228"
                                    x2="76.97847"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="45.82505"
                                    y1="45.6228"
                                    x2="82.27283"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="51.11941"
                                    y1="45.6228"
                                    x2="87.5672"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="56.41377"
                                    y1="45.6228"
                                    x2="92.86156"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="61.70814"
                                    y1="45.6228"
                                    x2="98.15592"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="67.0025"
                                    y1="45.6228"
                                    x2="103.45029"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="72.29686"
                                    y1="45.6228"
                                    x2="108.74465"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="77.59123"
                                    y1="45.6228"
                                    x2="114.03901"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="82.88559"
                                    y1="45.6228"
                                    x2="119.33338"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="88.17995"
                                    y1="45.6228"
                                    x2="124.62774"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="93.47431"
                                    y1="45.6228"
                                    x2="129.9221"
                                    y2="95.76203"
                                />
                            </g>
                            <g className="cls-9">
                                <line
                                    className="cls-11"
                                    x1="98.76868"
                                    y1="45.6228"
                                    x2="135.21647"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="104.06304"
                                    y1="45.6228"
                                    x2="140.51083"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="109.3574"
                                    y1="45.6228"
                                    x2="145.80519"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="114.65177"
                                    y1="45.6228"
                                    x2="151.09956"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="119.94613"
                                    y1="45.6228"
                                    x2="156.39392"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="125.24049"
                                    y1="45.6228"
                                    x2="161.68828"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="130.53486"
                                    y1="45.6228"
                                    x2="166.98265"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="135.82922"
                                    y1="45.6228"
                                    x2="172.27701"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="141.12358"
                                    y1="45.6228"
                                    x2="177.57137"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="146.41795"
                                    y1="45.6228"
                                    x2="182.86574"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="151.71231"
                                    y1="45.6228"
                                    x2="188.1601"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="157.00667"
                                    y1="45.6228"
                                    x2="193.45446"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="162.30104"
                                    y1="45.6228"
                                    x2="198.74883"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="167.5954"
                                    y1="45.6228"
                                    x2="204.04319"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="172.88976"
                                    y1="45.6228"
                                    x2="209.33755"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="178.18413"
                                    y1="45.6228"
                                    x2="214.63192"
                                    y2="95.76203"
                                />
                                <line
                                    className="cls-11"
                                    x1="183.47849"
                                    y1="45.6228"
                                    x2="219.92628"
                                    y2="95.76203"
                                />
                            </g>
                        </g>
                        <circle className="cls-7" cx="119.90131" cy="107.84108" r="47.08256" />
                        <line
                            className="cls-12"
                            x1="119.90131"
                            y1="117.11615"
                            x2="119.90131"
                            y2="77.09474"
                        />
                        <circle className="cls-3" cx="119.90131" cy="132.63958" r="5.94785" />
                    </g>
                </g>
            </g>
        </IconStyled>
    );
};

SessionExpiryIcon.propTypes = {
    viewBox: string,
    height: string,
    width: string,
};

SessionExpiryIcon.defaultProps = {
    viewBox: '0 0 225.51637 220.70999',
    height: '160',
    width: '160',
};
export default SessionExpiryIcon;
