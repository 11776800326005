import { bool, string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ArrowSectionIcon = ({ expanded, height, width, viewBox, margin, color }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            margin={margin}
            color={color}
            style={{ transform: expanded ? 'rotate(180deg)' : null }}
        >
            <path
                fill={color}
                d="M8.8,9.6l6.1-6.1L16,4.5l-8,8l-8-8l1.1-1.1l6.1,6.1C7.8,10.1,8,10.4,8,10.4C8,10.4,8.3,10.1,8.8,9.6z"
            />
        </SvgIcon>
    );
};

ArrowSectionIcon.propTypes = {
    expanded: bool,
    height: string,
    width: string,
    viewBox: string,
    margin: string,
    color: string,
};

ArrowSectionIcon.defaultProps = {
    expanded: false,
    height: '16',
    width: '16',
    viewBox: '0 0 16 16',
    margin: '0',
    color: `${DEFAULT_COLORS.BLACK_2}`,
};

export default ArrowSectionIcon;
