import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CircleCheckIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <g
                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                fill={fill}
                stroke="none"
            >
                <path
                    d="M250 571 c-46 -15 -85 -39 -116 -73 -54 -57 -69 -95 -69 -178 0 -83
                15 -121 69 -178 53 -57 101 -77 186 -77 57 0 84 5 115 22 103 55 161 170 141
                279 -7 33 -9 35 -23 22 -9 -10 -15 -37 -16 -75 -3 -83 -42 -147 -116 -185
                -143 -75 -307 18 -319 180 -12 175 190 294 333 196 29 -19 40 -22 51 -13 50
                41 -149 109 -236 80z"
                />
                <path
                    d="M433 378 l-113 -113 -45 45 c-35 35 -48 41 -57 32 -10 -10 0 -24 45
                -70 l57 -57 125 125 c69 69 124 129 122 134 -8 24 -29 9 -134 -96z"
                />
            </g>
        </SvgIcon>
    );
};

CircleCheckIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CircleCheckIcon.defaultProps = {
    fill: DEFAULT_COLORS.GREEN,
    height: '20',
    viewBox: '0 0 65 65',
    width: '20',
};

export default CircleCheckIcon;
