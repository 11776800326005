import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AlarmFilledIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M33 8.58001L26.1 2.79001L24.165 5.08501L31.065 10.875L33 8.58001ZM11.82 5.08501L9.9 2.79001L3 8.56501L4.935 10.86L11.82 5.08501ZM18.75 12H16.5V21L23.625 25.275L24.75 23.43L18.75 19.875V12ZM18 6.00001C10.545 6.00001 4.5 12.045 4.5 19.5C4.5 26.955 10.53 33 18 33C25.455 33 31.5 26.955 31.5 19.5C31.5 12.045 25.455 6.00001 18 6.00001ZM18 30C12.195 30 7.5 25.305 7.5 19.5C7.5 13.695 12.195 9.00001 18 9.00001C23.805 9.00001 28.5 13.695 28.5 19.5C28.5 25.305 23.805 30 18 30Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

AlarmFilledIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

AlarmFilledIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '36',
    viewBox: '0 0 36 36',
    width: '36',
};

export default AlarmFilledIcon;
