import axios from 'axios';

export const fetchMeetingsAPI = ({ params = {} }) => {
    return axios({
        method: 'get',
        type: 'tickets',
        url: '/api/internal/expert_meetings',
        params,
    });
};

export const updateMeetingAPI = ({ id, data }) => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/expert_meetings/${id}`,
        data,
    });
};

export const fetchMyMeetingsAPI = ({ params = {} }) => {
    return axios({
        method: 'get',
        type: 'tickets',
        url: '/api/v1/expert_meetings',
        params,
    });
};
