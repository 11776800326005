import axios from 'axios';

/**
 * API to fetch broken links
 *
 * @method fetchBrokenLinksAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchBrokenLinksAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/broken-links/list',
    });
};

/**
 * API to fetch broken links
 *
 * @method fetchBrokenLinksAPI
 * @param  {Object} brokenLinkId
 * @return {Promise}
 */
export const deleteBrokenLinkAPI = brokenLinkId => {
    return axios({
        type: 'apiTools',
        method: 'delete',
        noBearer: true,
        url: `/tools/v1/broken-links/${brokenLinkId}`,
    });
};
