/**
 * Initialize the contentful state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    categories: {},
    contentTypes: [],
    documentTypes: [],
    ehsCategories: [],
    ehsDocumentTypes: [],
    employeeCounts: [],
    jurisdictions: [],
    currentFeaturedImageIndex: 0,
};

export default INITIAL_STATE;
