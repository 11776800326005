import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ArrowUpCircleIcon = ({ backgroundColor, fill, height, width, viewBox, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="#D8D8D8" fillOpacity="0">
                    <rect x="0" y="0" width={width} height={height} />
                </g>
                <g transform="translate(20.000000, 20.000000) rotate(-270.000000) translate(-20.000000, -20.000000) translate(0.000000, 0.000000)">
                    <path
                        d="M20,40 C8.9546064,40 0,31.0453936 0,20 C0,8.9546064 8.9546064,0 20,0 C31.0453936,0 40,8.9546064 40,20 C40,31.0453936 31.0453936,40 20,40"
                        fill={backgroundColor}
                    />
                    <polygon
                        fill={fill}
                        points="32.4779245 18.7733019 10.4958491 18.7733019 20.3156604 8.95349057 18.5750943 7.21198113 5.78264151 20.004434 18.5750943 32.7968868 20.3156604 31.0563208 10.4958491 21.235566 32.4779245 21.235566"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

ArrowUpCircleIcon.propTypes = {
    backgroundColor: string,
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

ArrowUpCircleIcon.defaultProps = {
    backgroundColor: DEFAULT_COLORS.ORANGE,
    fill: DEFAULT_COLORS.WHITE,
    height: '25',
    width: '25',
    viewBox: '0 0 50 50',
};

export default ArrowUpCircleIcon;
