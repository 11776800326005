const SAFETY = {
    LANDING_AD_CTA_BUTTON_CLICK: {
        event: 'safetyLandingAdCtaButtonClick',
        eventProperties: {
            description: 'User click on CTA button on landing safety',
        },
    },
    NAVIGATION_SAFETY_CLICK: {
        event: 'safetyTabClick',
        eventProperties: {
            description: 'User click on navigation safety',
        },
    },
    CONTENT_EHS_DETAIL_VIEW: {
        event: 'contentEHSDetailView',
        eventProperties: {},
    },
    CONTENT_LIBRARY_CATEGORY_PAGE: {
        event: 'safetyContentLibraryCategoryPage',
        eventProperties: {},
    },
    CONTENT_LIBRARY_TOPIC_PAGE: {
        event: 'safetyContentLibraryTopicPage',
        eventProperties: {},
    },
    TOOL_CLICK: {
        event: 'safetyToolClick',
        eventProperties: {},
    },
    TYPE_FILTER_SELECT: {
        event: 'safetyContentLibraryTypeFilter',
        eventProperties: {},
    },
    CONTENT_EHS_DETAIL_AD_CTA_BUTTON_CLICK: {
        event: 'contentEHSDetailAdCtaButtonClick',
        eventProperties: {
            source: 'banner',
        },
    },
    DOWNLOAD_SAFETY_MANUAL_TEMPLATE: {
        event: 'downloadSafetyManualTemplate',
        eventProperties: {},
    },
};

export default SAFETY;
