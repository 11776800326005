import { bool, func, object } from 'prop-types';
import React, { Component, createRef } from 'react';
import styled, { withTheme } from 'styled-components';

import { DURATION } from 'ravenjs/constants/transitions';
import Menu from 'ravenjs/lib/Menu';
import PopoverContent from 'ravenjs/lib/PopoverContent';
import { getThemeProps } from 'ravenjs/utils/theme';
import { get } from 'ravenjs/utils/lodash';

import { ProfileIcon } from 'components/Icons';
import UserMenu from 'components/UserMenu';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { DEFAULT_COLORS } from 'constants/colors';
import { APP_PERMISSIONS } from 'constants/permissions';
import Acl from 'modules/acl';

import UserNavStyled from './UserNavStyled';

const PopoverStyles = styled(PopoverContent)`
    bottom: 0;
    margin-left: 8px;
    &:before {
        background: ${DEFAULT_COLORS.WHITE};
        box-shadow: none;
        border-top: 1px solid ${DEFAULT_COLORS.BLACK};
        border-right: 1px solid ${DEFAULT_COLORS.BLACK};
    }
    max-width: 745px;
`;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;
    height: 100%;

    ${({ theme }) => theme.media.up('md')`
        margin: 0 .5625rem;
    `};
    ${({ theme }) => theme.media.down('md')`
        margin: 0;
    `};
`;

class UserNav extends Component {
    userNavRef = createRef();

    static propTypes = {
        history: object,
        items: object,
        user: object,
        theme: object.isRequired,
        isSoftLogin: bool.isRequired,
        onNavClick: func,
        openLoginPrompt: func.isRequired,
    };

    static defaultProps = {
        history: {},
        items: {},
        onNavClick: () => {},
        user: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
    }

    closeMenu = navLink => {
        const { onNavClick } = this.props;
        const { current } = this.userNavRef;

        current && current.closeMenu();
        this.onAvatarLeave();

        onNavClick(navLink);
    };

    renderMenuContent = () => {
        const { history, user } = this.props;
        let { items } = this.props;

        // TODO:  remove once BE change has been made to no longer provide this menu optoin
        const internalTools = get(items, 'internalTools', []).filter(
            item => item.state !== 'ticketingsystem'
        );
        items = { ...items, internalTools };

        return (
            <UserMenu
                history={history}
                user={user}
                items={items}
                onNavClick={navLink => {
                    this.closeMenu(navLink);
                }}
            />
        );
    };

    onAvatarHover = () => {
        const { isSoftLogin } = this.props;
        if (!isSoftLogin) {
            this.setState({
                hover: true,
            });
        }
    };

    onAvatarClick = () => {
        const { isSoftLogin, openLoginPrompt } = this.props;
        if (isSoftLogin) {
            openLoginPrompt();
        } else {
            this.setState({
                hover: true,
            });
        }
    };

    onAvatarLeave = () => {
        const { isSoftLogin } = this.props;
        if (!isSoftLogin) {
            this.setState({
                hover: false,
            });
        }
    };

    renderButtonComponent = () => {
        const { theme } = this.props;
        return (
            <IconWrapper>
                <ProfileIcon
                    fill={getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, { theme })}
                />
            </IconWrapper>
        );
    };

    render() {
        const { hover } = this.state;

        return (
            <UserNavStyled
                className={
                    Acl.check(APP_PERMISSIONS.userAssistanceClientAdmin)
                        ? 'pendo-nav-userprofile'
                        : ''
                }
                onFocus={this.onAvatarHover}
                onMouseEnter={this.onAvatarHover}
                onClick={this.onAvatarClick}
                onMouseLeave={this.onAvatarLeave}
                onBlur={this.onAvatarLeave}
            >
                <Menu
                    ButtonComponent={this.renderButtonComponent()}
                    ContentComponent={PopoverStyles}
                    ContentProps={{
                        borderRadius: '0',
                        elevation: 9,
                    }}
                    IconComponent={null}
                    arrowSize={7}
                    color="transparent"
                    distanceFromContainer={0}
                    placement="bottom-end"
                    ref={this.userNavRef}
                    open={hover}
                    timeout={DURATION.none}
                    borderRadius={4}
                >
                    {this.renderMenuContent()}
                </Menu>
            </UserNavStyled>
        );
    }
}

export { UserNav as UserNavUnwrapped };
export default withPublicContent(withTheme(UserNav));
