import { func, object } from 'prop-types';
import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';

import { addEventListenerOnRoot, removeEventListenerOnRoot } from 'ravenjs/utils/events';
import { getThemeProps } from 'ravenjs/utils/theme';

import { ProfileIcon } from 'components/Icons';
import UserMenu from 'components/UserMenu';
import { DEFAULT_COLORS } from 'constants/colors';

const MobileUserNavStyled = styled.div`
    align-items: center;
    cursor: pointer;
    font-weight: 100;
    ${getThemeProps('MobileUserNav.styles')};
`;

const BackdropStyled = styled.div`
    align-items: center;
    bottom: 0;
    display: ${({ display }) => display || 'none'};
    justify-content: center;
    left: 0;
    position: ${({ position }) => position || 'fixed'};
    right: 0;
    top: 115px;
    user-select: none;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
`;

const PopoverArrowStyled = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: ${DEFAULT_COLORS.WHITE};
    border-left: 1px solid ${DEFAULT_COLORS.BLACK_2};
    border-top: 1px solid ${DEFAULT_COLORS.BLACK_2};
    position: absolute;
    top: 108px;
    z-index: 1300;
    right: 3.9rem;
    transform: rotate(45deg);
`;

const PopoverStyled = styled.div`
    background-color: ${DEFAULT_COLORS.WHITE};
    border-top: solid 1px ${DEFAULT_COLORS.BLACK_2};
    position: absolute;
    z-index: 1100;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    top: 116px;
`;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;
    height: 100%;
    margin: 0;
`;

class MobileUserNav extends Component {
    static propTypes = {
        items: object,
        onNavClick: func,
        theme: object.isRequired,
        user: object,
    };

    static defaultProps = {
        items: {},
        onNavClick: () => {},
        user: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.wrapperRef = React.createRef();
    }

    onClickBlur = event => {
        if (
            this.wrapperRef &&
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.onMenuLeave();
        }
    };

    onAvatarClick = () => {
        const { hover } = this.state;
        document.body.style.overflow = !hover ? 'hidden' : 'unset';
        addEventListenerOnRoot('mousedown', this.onClickBlur);
        this.setState({
            hover: !hover,
        });
    };

    onMenuLeave = navLink => {
        const { onNavClick } = this.props;
        document.body.style.overflow = 'unset';
        removeEventListenerOnRoot('mousedown', this.onClickBlur);
        this.setState({
            hover: false,
        });

        onNavClick(navLink);
    };

    render() {
        const { items, user, theme } = this.props;
        const { hover } = this.state;

        return (
            <>
                <MobileUserNavStyled ref={this.wrapperRef}>
                    <IconWrapper onClick={this.onAvatarClick}>
                        <ProfileIcon
                            fill={getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, {
                                theme,
                            })}
                            height="27"
                            width="27"
                        />
                    </IconWrapper>
                    {hover && (
                        <>
                            <PopoverArrowStyled />
                            <PopoverStyled>
                                <UserMenu
                                    isMobile
                                    user={user}
                                    items={items}
                                    onNavClick={navLink => {
                                        this.onMenuLeave(navLink);
                                    }}
                                />
                            </PopoverStyled>
                        </>
                    )}
                </MobileUserNavStyled>
                <BackdropStyled display={hover ? 'flex' : 'none'} />
            </>
        );
    }
}

export { MobileUserNav as MobileUserNavUnWrapped };
export default withTheme(MobileUserNav);
