import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CloneIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0436 21.5725C20.4312 22.1664 19.6006 22.5 18.7345 22.5H9.08849C8.48733 22.5 8 22.0274 8 21.4444C8 20.8615 8.48733 20.3889 9.08849 20.3889H18.7345C19.0232 20.3889 19.3001 20.2777 19.5042 20.0797C19.7083 19.8818 19.823 19.6133 19.823 19.3333V7.66667C19.823 7.38671 19.7083 7.11823 19.5042 6.92028C19.3001 6.72232 19.0232 6.61111 18.7345 6.61111C18.1334 6.61111 17.646 6.13852 17.646 5.55556C17.646 4.97259 18.1334 4.5 18.7345 4.5C19.6006 4.5 20.4312 4.83363 21.0436 5.42749C21.656 6.02136 22 6.82681 22 7.66667V19.3333C22 20.1732 21.656 20.9786 21.0436 21.5725Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9836 2.15126C15.421 1.58865 14.658 1.27258 13.8623 1.27258H8.8623C8.59709 1.27258 8.34273 1.37794 8.1552 1.56548L1.1552 8.56548C0.967662 8.75301 0.862305 9.00737 0.862305 9.27258V16.2726C0.862305 17.0682 1.17838 17.8313 1.74098 18.3939C2.30359 18.9565 3.06665 19.2726 3.8623 19.2726H13.8623C14.658 19.2726 15.421 18.9565 15.9836 18.3939C16.5462 17.8313 16.8623 17.0682 16.8623 16.2726V4.27258C16.8623 3.47693 16.5462 2.71387 15.9836 2.15126ZM13.8623 3.27258C14.1275 3.27258 14.3819 3.37794 14.5694 3.56548C14.7569 3.75301 14.8623 4.00737 14.8623 4.27258V16.2726C14.8623 16.5378 14.7569 16.7922 14.5694 16.9797C14.3819 17.1672 14.1275 17.2726 13.8623 17.2726H3.8623C3.59709 17.2726 3.34274 17.1672 3.1552 16.9797C2.96766 16.7922 2.8623 16.5378 2.8623 16.2726V9.6868L9.27652 3.27258H13.8623Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.8623 1.27258C8.31002 1.27258 7.8623 1.7203 7.8623 2.27258V8.27258H1.8623C1.31002 8.27258 0.862305 8.7203 0.862305 9.27258C0.862305 9.82487 1.31002 10.2726 1.8623 10.2726H8.8623C9.41459 10.2726 9.8623 9.82487 9.8623 9.27258V2.27258C9.8623 1.7203 9.41459 1.27258 8.8623 1.27258Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CloneIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CloneIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default CloneIcon;
