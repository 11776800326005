import { call, put } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import {
    fetchSurveyAPI,
    fetchSurveysAPI,
    fetchSurveyResponseAPI,
    fetchSurveyResponsesAPI,
} from 'modules/apis';

import * as surveysActions from '../actions';

export function* fetchSurveysSaga() {
    try {
        const response = yield call(fetchSurveysAPI);
        const data = get(response, 'data', []);
        yield put(surveysActions.fetchSurveys.success(data));
        return data;
    } catch (error) {
        yield put(surveysActions.fetchSurveys.error(error));
        return error;
    }
}

export function* fetchSurveySaga(action) {
    try {
        const {
            payload: { surveyId, useExternalEndpoint },
        } = action;
        const response = yield call(fetchSurveyAPI, surveyId, useExternalEndpoint);
        const data = get(response, 'data', []);
        yield put(surveysActions.fetchSurvey.success(data));
        return data;
    } catch (error) {
        yield put(surveysActions.fetchSurvey.error(error));
        return error;
    }
}

export function* fetchSurveyResponseSaga(action) {
    try {
        const {
            payload: { surveyResponseId, useExternalEndpoint },
        } = action;
        const response = yield call(fetchSurveyResponseAPI, surveyResponseId, useExternalEndpoint);
        const data = get(response, 'data', []);
        yield put(surveysActions.fetchSurveyResponse.success(data));
        return data;
    } catch (error) {
        yield put(surveysActions.fetchSurveyResponse.error(error));
        return error;
    }
}

export function* fetchSurveyResponsesSaga(action) {
    try {
        const {
            payload: { params, useExternalEndpoint },
        } = action;
        const response = yield call(fetchSurveyResponsesAPI, params, useExternalEndpoint);
        const data = get(response, 'data', []);
        yield put(surveysActions.fetchSurveyResponses.success(data));
        return data;
    } catch (error) {
        yield put(surveysActions.fetchSurveyResponses.error(error));
        return error;
    }
}
