import { takeLatest } from 'redux-saga/effects';

import * as inProductMarketingActions from '../actions';

import {
    sendEmailSaga,
    fetchUpsellAdsSaga,
    fetchPricingSaga,
    fetchPaymentPageRedirectUrlSaga,
    fetchUpgradeAdsSaga,
    postUpgradeAdsSaga,
    deleteUpgradeAdSaga,
    getUpgradeAdByIdSaga,
    updateUpgradeAdSaga,
} from './inProductMarketing';

/**
 * Root saga for `In Product Marketing`
 * Triggers sagas related to in product marketing
 *
 * @method inProductMarketingSaga
 * @type   {Generator}
 */
function* inProductMarketingSaga() {
    yield takeLatest(inProductMarketingActions.sendEmail.TRIGGER, sendEmailSaga);
    yield takeLatest(inProductMarketingActions.fetchUpsellAds.TRIGGER, fetchUpsellAdsSaga);
    yield takeLatest(inProductMarketingActions.fetchPricing.TRIGGER, fetchPricingSaga);
    yield takeLatest(
        inProductMarketingActions.fetchPaymentPageRedirectUrl.TRIGGER,
        fetchPaymentPageRedirectUrlSaga
    );
    yield takeLatest(inProductMarketingActions.fetchUpgradeAds.TRIGGER, fetchUpgradeAdsSaga);
    yield takeLatest(inProductMarketingActions.postUpgradeAds.TRIGGER, postUpgradeAdsSaga);
    yield takeLatest(inProductMarketingActions.deleteUpgradeAd.TRIGGER, deleteUpgradeAdSaga);
    yield takeLatest(inProductMarketingActions.getUpgradeAdById.TRIGGER, getUpgradeAdByIdSaga);
    yield takeLatest(inProductMarketingActions.updateUpgradeAd.TRIGGER, updateUpgradeAdSaga);
}

export default inProductMarketingSaga;

export {
    sendEmailSaga,
    fetchUpsellAdsSaga,
    fetchPricingSaga,
    deleteUpgradeAdSaga,
    getUpgradeAdByIdSaga,
    updateUpgradeAdSaga,
};
