import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import List from 'ravenjs/lib/List';
import ListItem from 'ravenjs/lib/ListItem';
import Row from 'ravenjs/lib/Row';
import Tooltip from 'ravenjs/lib/Tooltip';
import Typography from 'ravenjs/lib/Typography';
import { callFunc, genID, get } from 'ravenjs/utils';

import { DEFAULT_COLORS } from 'constants/colors';
import InfoIcon from 'components/Icons/InfoIcon';

const StyledLabel = styled(Typography)`
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.3125rem;
`;

function UploadFieldDescription(props) {
    const { description, onClickDownloadLink, onClickDownloadLinkSecond, formSchema } = props;
    const customFieldProps = get(formSchema, 'uiSchema.uploadInfo.file.ui:customFieldProps');

    const templateUrl = get(customFieldProps, 'templateUrl');
    const linkLabel = get(customFieldProps, 'linkLabel');
    const linkLabelType = get(customFieldProps, 'linkLabelType', 'link');
    const tooltip = get(customFieldProps, 'tooltip');

    const secondTemplateUrl = get(customFieldProps, 'secondTemplateUrl');
    const secondLinkLabel = get(customFieldProps, 'secondLinkLabel');
    const secondLinkLabelType = get(customFieldProps, 'secondLinkLabelType', 'link');
    const secondTooltip = get(customFieldProps, 'secondTooltip');

    if (!description) {
        return null;
    }

    function getTooltipContent(tooltipContent) {
        if (Array.isArray(tooltipContent))
            return (
                <List backgroundColor="transparent" listStyle="disc" padding="0 0 0 0.9375rem">
                    {tooltipContent.map(value => (
                        <ListItem key={genID()} display="list-item" padding="0.1875rem">
                            {value}
                        </ListItem>
                    ))}
                </List>
            );

        return tooltip;
    }

    return (
        <Row gutter={false} direction="column" margin="0.625rem 0 0">
            <Typography>{description}</Typography>
            <Row gutter={false} alignItems="flex-start" direction="column">
                {linkLabel && (
                    <StyledLabel
                        type={linkLabelType}
                        onClick={() => callFunc(onClickDownloadLink)}
                        href={templateUrl}
                    >
                        {linkLabel}
                    </StyledLabel>
                )}
                {tooltip && (
                    <Tooltip
                        background={DEFAULT_COLORS.BLACK}
                        content={getTooltipContent(tooltip)}
                        placement="left"
                        color="dark"
                    >
                        <InfoIcon />
                    </Tooltip>
                )}
                {secondLinkLabel && (
                    <StyledLabel
                        type={secondLinkLabelType}
                        onClick={() => callFunc(onClickDownloadLinkSecond)}
                        href={secondTemplateUrl}
                    >
                        {secondLinkLabel}
                    </StyledLabel>
                )}
                {secondTooltip && (
                    <Tooltip
                        background={DEFAULT_COLORS.BLACK}
                        content={getTooltipContent(secondTooltip)}
                        placement="left"
                        color="dark"
                    >
                        <InfoIcon />
                    </Tooltip>
                )}
            </Row>
        </Row>
    );
}

UploadFieldDescription.propTypes = {
    /**
     * Field description
     */
    description: PropTypes.string,
    /**
     * the schema for the form
     */
    formSchema: PropTypes.object.isRequired,
    /**
     * callback function for the download link click event
     */
    onClickDownloadLink: PropTypes.func,
    /**
     * callback function for the download link click event of second link
     */
    onClickDownloadLinkSecond: PropTypes.func,
};

UploadFieldDescription.defaultProps = {
    description: null,
    onClickDownloadLink: null,
    onClickDownloadLinkSecond: null,
};

export default UploadFieldDescription;
