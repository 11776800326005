import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const DemoIcon = ({ height, viewBox, width, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" stroke="#FF1D1D" d="M0 0H28V28H0z" transform="translate(1.75 1)" />
            <path
                fill="red"
                fillRule="nonzero"
                d="M5.272 17.36v-5.603h-.697v5.603h.697zm3.94.1c1.016 0 1.688-.47 2.159-1.108l-.597-.328c-.319.48-.898.815-1.562.815-1.201 0-2.134-.949-2.134-2.276 0-1.336.933-2.277 2.134-2.277.664 0 1.243.345 1.562.815l.588-.327c-.445-.63-1.134-1.11-2.15-1.11-1.588 0-2.856 1.177-2.856 2.899s1.268 2.898 2.856 2.898zm5.603 0c1.655 0 2.78-1.242 2.78-2.897 0-1.655-1.125-2.898-2.78-2.898-1.663 0-2.78 1.243-2.78 2.898s1.117 2.898 2.78 2.898zm0-.62c-1.26 0-2.058-.975-2.058-2.277 0-1.31.798-2.277 2.058-2.277 1.251 0 2.058.966 2.058 2.277 0 1.302-.807 2.276-2.058 2.276zm4.57.52v-4.52l3.267 4.52h.672v-5.603h-.697v4.427L19.4 11.757h-.714v5.603h.697z"
                transform="translate(1.75 1)"
            />
        </g>
    </SvgIcon>
);

DemoIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

DemoIcon.defaultProps = {
    viewBox: '0 0 31 30',
    width: '31',
    height: '30',
};

export default DemoIcon;
