import { createActions } from 'utils/actions';

/*
 * Redux action for deleting a broken link
 *
 * @type {Object}
 */
export const deleteBrokenLink = createActions('reporting', 'DELETE_BROKEN_LINK', {
    asPromise: true,
});

/*
 * Redux action for fetching broken links
 *
 * @type {Object}
 */
export const fetchBrokenLinks = createActions('reporting', 'FETCH_BROKEN_LINKS', {
    asPromise: true,
});

/*
 * Redux action for fetching establishments
 *
 * @type {Object}
 */
export const fetchEstablishments = createActions('reporting', 'FETCH_ESTABLISHMENTS', {
    asPromise: true,
});

/*
 * Redux action for fetching establishment type
 *
 * @type {Object}
 */
export const fetchEstablishmentType = createActions('reporting', 'FETCH_ESTABLISHMENT_TYPE', {
    asPromise: true,
});

/*
 * Redux action for fetching cities
 *
 * @type {Object}
 */
export const fetchCities = createActions('reporting', 'FETCH_CITIES', {
    asPromise: true,
});

/*
 * Redux action for fetching naics
 *
 * @type {Object}
 */
export const fetchNaics = createActions('reporting', 'FETCH_NAICS', {
    asPromise: true,
});

/*
 * Redux action for fetching cases
 *
 * @type {Object}
 */
export const fetchCases = createActions('reporting', 'FETCH_CASES', {
    asPromise: true,
});

/*
 * Redux action for fetching cases
 *
 * @type {Object}
 */
export const fetchEmployeeNames = createActions('reporting', 'FETCH_EMPLOYEE_NAMES', {
    asPromise: true,
});

/*
 * Redux action for fetching illness
 *
 * @type {Object}
 */
export const fetchIllnessTypes = createActions('reporting', 'FETCH_ILLNESS_TYPES', {
    asPromise: true,
});

/*
 * Redux action for fetching where ocurred
 *
 * @type {Object}
 */
export const fetchWhereOccurred = createActions('reporting', 'FETCH_WHERE_OCCURRED', {
    asPromise: true,
});

/*
 * Redux action for create establishment
 *
 * @type {Object}
 */
export const createEstablishment = createActions('reporting', 'CREATE_ESTABLISHMENT', {
    asPromise: true,
});

/*
 * Redux action for fetching active clients and users
 *
 * @type {Object}
 */
export const fetchActiveClientsUsers = createActions('reporting', 'FETCH_ACTIVE_CLIENTS_USERS', {
    asPromise: true,
});

/*
 * Redux action for fetxh establishment by id
 *
 * @type {Object}
 */
export const fetchEstablishmentById = createActions('reporting', 'FETCH_ESTABLISHMENT_BY_ID', {
    asPromise: true,
});

/*
 * Redux action for update establishment
 *
 * @type {Object}
 */
export const updateEstablishment = createActions('reporting', 'UPDATE_ESTABLISHMENT', {
    asPromise: true,
});

/*
 * Redux action for delete establishment
 *
 * @type {Object}
 */
export const deleteEstablishmentById = createActions('reporting', 'DELETE_ESTABLISHMENT_BY_ID', {
    asPromise: true,
});

/*
 * Redux action for download active clients and users reports
 *
 * @type {Object}
 */
export const downloadActiveClientsUsersReports = createActions(
    'reporting',
    'DOWNLOAD_ACTIVE_CLIENTS_USERS_REPORTS',
    {
        asPromise: true,
    }
);

/*
 * Redux action for fetch body parts
 *
 * @type {Object}
 */
export const fetchBodyParts = createActions('reporting', 'FETCH_BODY_PARTS', {
    asPromise: true,
});

/*
 * Redux action for get case number
 *
 * @type {Object}
 */
export const getCaseNumber = createActions('reporting', 'GET_CASE_NUMBER', {
    asPromise: true,
});

/*
 * Redux action for get case number
 *
 * @type {Object}
 */
export const fetchCaseTypes = createActions('reporting', 'FETCH_CASE_TYPES', {
    asPromise: true,
});

/*
 * Redux action for create case
 *
 * @type {Object}
 */
export const createCase = createActions('reporting', 'CREATE_CASE', {
    asPromise: true,
});

/*
 * Redux action for create case
 *
 * @type {Object}
 */
export const fetchCaseById = createActions('reporting', 'FETCH_CASE_BY_ID', {
    asPromise: true,
});

/*
 * Redux action for update case
 *
 * @type {Object}
 */
export const updateCase = createActions('reporting', 'UPDATE_CASE', {
    asPromise: true,
});

/*
 * Redux action for delete case
 *
 * @type {Object}
 */
export const deleteCaseById = createActions('reporting', 'DELETE_CASE_BY_ID', {
    asPromise: true,
});

/*
 * Redux action for fetch partners
 *
 * @type {Object}
 */
export const fetchPvrPartners = createActions('reporting', 'FETCH_PVR_PARTNERS', {
    asPromise: true,
});

/*
 * Redux action for fetch calculation values to partner value report
 *
 * @type {Object}
 */
export const fetchCalculationValues = createActions('reporting', 'FETCH_CALCULATION_VALUES', {
    asPromise: true,
});

/*
 * Redux action for update calculation values to partner value report
 *
 * @type {Object}
 */
export const updateCalculationValues = createActions('reporting', 'UPDATE_CALCULATION_VALUES', {
    asPromise: true,
});

/*
 * Redux action for fetching client usage
 *
 * @type {Object}
 */
export const fetchClientUsage = createActions('reporting', 'FETCH_CLIENT_USAGE', {
    asPromise: true,
});

/*
 * Redux action for fetching partner value report
 *
 * @type {Object}
 */
export const fetchPartnerValueReport = createActions('reporting', 'FETCH_PARTNER_VALUE_REPORT', {
    asPromise: true,
});

/*
 * Redux action for fetching total clients
 *
 * @type {Object}
 */
export const fetchTotalClientsByPartner = createActions(
    'reporting',
    'FETCH_TOTAL_CLIENTS_BY_PARTNER',
    {
        asPromise: true,
    }
);

/*
 * Redux action for fetch clients to client value report
 *
 * @type {Object}
 */
export const fetchCvrClients = createActions('reporting', 'FETCH_CVR_CLIENTS', {
    asPromise: true,
});

/*
 * Redux action for fetching client value report
 *
 * @type {Object}
 */
export const fetchClientValueReport = createActions('reporting', 'FETCH_CLIENT_VALUE_REPORT', {
    asPromise: true,
});

/*
 * Redux action for fetching client value report
 *
 * @type {Object}
 */
export const fetchClientValueReportTemplate = createActions(
    'reporting',
    'FETCH_CLIENT_VALUE_REPORT_TEMPLATE',
    {
        asPromise: true,
    }
);

/*
 * Redux action for fetching form establishments
 *
 * @type {Object}
 */
export const fetchFormEstablishments = createActions('reporting', 'FETCH_FORM_ESTABLISHMENTS', {
    asPromise: true,
});

/*
 * Redux action for download Form 300
 *
 * @type {Object}
 */
export const downloadForm300 = createActions('reporting', 'DOWNLOAD_FORM_300', {
    asPromise: true,
});

/*
 * Redux action for download Form 300A
 *
 * @type {Object}
 */
export const downloadForm300A = createActions('reporting', 'DOWNLOAD_FORM_300A', {
    asPromise: true,
});

/*
 * Redux action for download Form report
 *
 * @type {Object}
 */
export const downloadFormReport = createActions('reporting', 'DOWNLOAD_FORM_REPORT', {
    asPromise: true,
});

/*
 * Redux action for fetching ec aggregate report
 *
 * @type {Object}
 */
export const fetchEcAggregateReport = createActions('reporting', 'FETCH_EC_AGGREGATE_REPORT', {
    asPromise: true,
});

/*
 * Redux action for fetching reporting snapshot by partner
 *
 * @type {Object}
 */
export const fetchReportingSnapshotByPartner = createActions(
    'reporting',
    'FETCH_REPORTING_SNAPSHOT_BY_PARTNER',
    {
        asPromise: true,
    }
);

/*
 * Redux action for fetching reporting snapshot request
 *
 * @type {Object}
 */
export const fetchReportingSnapshotRequest = createActions(
    'reporting',
    'FETCH_REPORTING_SNAPSHOT_REQUEST',
    {
        asPromise: true,
    }
);

/*
 * Redux action for fetching reporting snapshot refresh
 *
 * @type {Object}
 */
export const fetchReportingSnapshotRefresh = createActions(
    'reporting',
    'FETCH_REPORTING_SNAPSHOT_REFRESH',
    {
        asPromise: true,
    }
);

/*
 * Redux action for fetching reporting snapshot refresh
 *
 * @type {Object}
 */
export const runEmailEngagement = createActions('reporting', 'RUN_EMAIL_ENGAGEMENT', {
    asPromise: true,
});

/*
 * Redux action for fetching client solution report
 *
 * @type {Object}
 */
export const fetchClientSolutionReport = createActions(
    'reporting',
    'FETCH_CLIENT_SOLUTION_REPORT',
    {
        asPromise: true,
    }
);

export const runClientSolutionReport = createActions('reporting', 'RUN_CLIENT_SOLUTION_REPORT', {
    asPromise: true,
});

/*
 * Redux action for fetching Administration Reports
 *
 * @type {Object}
 */
export const fetchAdministrationReports = createActions(
    'reporting',
    'FETCH_ADMINISTRATION_REPORTS',
    {
        asPromise: true,
    }
);

/*
 * Redux action for run Administration Reports
 *
 * @type {Object}
 */
export const runAdministrationReports = createActions('reporting', 'RUN_ADMINISTRATION_REPORTS', {
    asPromise: true,
});
