import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    resetPasswordAPI,
    sendResetEmailAPI,
    validateResetPasswordLinkAPI,
    updatePasswordAPI,
} from 'modules/apis';

import * as adminActions from '../actions';

/**
 * Send reset email.
 *
 * @method sendResetEmailSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} email
 */
export function* sendResetEmailSaga(action) {
    try {
        const { payload: data } = action;
        const resp = yield call(sendResetEmailAPI, data);
        const email = get(resp, 'data', {});
        yield put(adminActions.sendResetEmail.success(email));
        return email;
    } catch (error) {
        yield put(adminActions.sendResetEmail.error(error));
        return error;
    }
}

/**
 * Reset password.
 *
 * @method resetPasswordSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} successReset
 */
export function* resetPasswordSaga(action) {
    try {
        const { payload: data } = action;
        const resp = yield call(resetPasswordAPI, data);
        const reset = get(resp, 'data', {});
        yield put(adminActions.resetPassword.success(reset));
        return reset;
    } catch (error) {
        yield put(adminActions.resetPassword.error(error));
        return error;
    }
}

/**
 * Reset password.
 *
 * @method validateResetPasswordLinkSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} successReset
 */
export function* validateResetPasswordLinkSaga(action) {
    try {
        const { payload: data } = action;
        const resp = yield call(validateResetPasswordLinkAPI, data);
        const validate = get(resp, 'data', {});
        yield put(adminActions.validateResetPasswordLink.success(validate));
        return validate;
    } catch (error) {
        yield put(adminActions.validateResetPasswordLink.error(error));
        return error;
    }
}

/**
 * Update password.
 *
 * @method updatePasswordSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} successReset
 */
export function* updatePasswordSaga(action) {
    try {
        const { payload: data } = action;
        const resp = yield call(updatePasswordAPI, data);
        const reset = get(resp, 'data', {});
        yield put(adminActions.updatePassword.success(reset));
        return reset;
    } catch (error) {
        yield put(adminActions.updatePassword.error(error));
        return error;
    }
}
