import { takeLatest } from 'redux-saga/effects';

import * as surveysActions from '../actions';
import {
    fetchSurveySaga,
    fetchSurveysSaga,
    fetchSurveyResponseSaga,
    fetchSurveyResponsesSaga,
} from './surveys';

function* surveysSaga() {
    yield takeLatest(surveysActions.fetchSurvey.TRIGGER, fetchSurveySaga);
    yield takeLatest(surveysActions.fetchSurveys.TRIGGER, fetchSurveysSaga);
    yield takeLatest(surveysActions.fetchSurveyResponse.TRIGGER, fetchSurveyResponseSaga);
    yield takeLatest(surveysActions.fetchSurveyResponses.TRIGGER, fetchSurveyResponsesSaga);
}

export default surveysSaga;

export { fetchSurveySaga, fetchSurveysSaga, fetchSurveyResponseSaga, fetchSurveyResponsesSaga };
