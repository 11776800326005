import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from '../SvgIcon';

/**
 * Render a arrow based on a given direction
 *
 * @method renderDirectionalArrowIcon
 * @param  {string}                   direction The direction of the arrow
 * @return {JSX}
 */
const renderDirectionalArrowIcon = direction => {
    switch (direction) {
        case 'up':
            return (
                <path
                    fill="#5E5E5E"
                    fillRule="evenodd"
                    d="M5.76 9.136L3.773 11.061 3.773 0 2.727 0 2.727 11.061 0.74 9.136 0 9.852 3.25 13 6.5 9.852z"
                />
            );
        case 'right':
            return (
                <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
            );
        case 'down':
            return (
                <path
                    fill="#5E5E5E"
                    fillRule="evenodd"
                    d="M5.76 9.136L3.773 11.061 3.773 0 2.727 0 2.727 11.061 0.74 9.136 0 9.852 3.25 13 6.5 9.852z"
                />
            );
        case 'left':
            return (
                <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
            );
        default:
            return null;
    }
};

const ArrowIcon = ({ direction, setRef, ...rest }) => (
    <SvgIcon {...rest} ref={setRef}>
        {renderDirectionalArrowIcon(direction)}
    </SvgIcon>
);

ArrowIcon.propTypes = {
    direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

ArrowIcon.defaultProps = {
    width: '8',
    height: '14',
    direction: 'up',
    setRef: null,
    viewBox: '0 0 8 14',
};

export default ArrowIcon;
