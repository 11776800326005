import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MoreIcon = ({ fill, height, viewBox, width, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={fill}
            d="M10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM18 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM2 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
        />
    </SvgIcon>
);

MoreIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

MoreIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default MoreIcon;
