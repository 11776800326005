import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const GraphIcon = ({ color, height, axisFill, viewBox, width, ...rest }) => (
    <SvgIcon height={height} width={width} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g>
                <path
                    fill={color}
                    d="M6.6 1.57v16.19H3.25V1.57H6.6zm5.65 4.33v11.86H8.9V5.9h3.35zm5.86 4.31v7.55h-3.35v-7.55h3.35z"
                    transform="translate(0 -1)"
                />
                <path
                    fill={axisFill}
                    d="M1 0v19.5l19.5-.07v1L0 20.5V0h1zm16.7 3.68l.81.59-4.9 6.73-3.1-1.64-5.5 4.42-.62-.78 6.01-4.83 2.91 1.54 4.39-6.03z"
                    transform="translate(0 -1)"
                />
            </g>
        </g>
    </SvgIcon>
);

GraphIcon.propTypes = {
    color: string,
    height: string,
    axisFill: string,
    viewBox: string,
    width: string,
};

GraphIcon.defaultProps = {
    color: DEFAULT_COLORS.YELLOW,
    height: '20',
    axisFill: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 20 20',
    width: '20',
};

export default GraphIcon;
