import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const PaginationNextIcon = ({ color, colorInner, height, width, viewBox, cursor, fillInner }) => {
    return (
        <SvgIcon
            cursor={cursor}
            width={width}
            height={height}
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m12.643 7.72 3.636 3.637.006.006c.16.164.26.387.26.634v.006a.906.906 0 0 1-.26.634l-.006.006-3.636 3.636a.91.91 0 1 1-1.286-1.286l2.085-2.084H8.364a.91.91 0 1 1 0-1.818h5.078l-2.085-2.085a.91.91 0 0 1 1.286-1.285z"
                fill={fillInner}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm10-8.182a8.182 8.182 0 1 0 0 16.364 8.182 8.182 0 0 0 0-16.364z"
                fill={fillInner}
            />
        </SvgIcon>
    );
};

PaginationNextIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
    colorInner: string,
    cursor: string,
    fillInner: string,
};

PaginationNextIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '25',
    color: 'none',
    colorInner: 'none',
    fillInner: '#000',
    cursor: 'pointer',
};

export default PaginationNextIcon;
