import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const HandbookIcon = ({ height, viewBox, width, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <path d="M24 0.667h-17.333c-1.471 0-2.667 1.196-2.667 2.667v1.333h-1.333v1.333h1.333v4h-1.333v1.333h1.333v4h-1.333v1.333h1.333v4h-1.333v1.333h1.333v4h-1.333v1.333h1.333v2.667c0 1.471 1.196 2.667 2.667 2.667h17.333c2.942 0 5.333-2.392 5.333-5.333v-21.333c0-2.942-2.392-5.333-5.333-5.333zM26.667 27.333c0 1.471-1.196 2.667-2.667 2.667h-17.333v-2.667h1.333v-1.333h-1.333v-4h1.333v-1.333h-1.333v-4h1.333v-1.333h-1.333v-4h1.333v-1.333h-1.333v-4h1.333v-1.333h-1.333v-1.333h17.333c1.471 0 2.667 1.196 2.667 2.667v21.333zM19.275 18.533c1.242-0.837 2.058-2.258 2.058-3.867 0-2.575-2.092-4.667-4.667-4.667s-4.667 2.092-4.667 4.667c0 1.608 0.817 3.025 2.054 3.867-1.133 0.45-2.3 1.212-3.246 2.383-0.125 0.154-0.171 0.354-0.133 0.546s0.158 0.358 0.333 0.454c1.725 0.954 3.575 1.417 5.654 1.417 2.087 0 3.938-0.462 5.658-1.417 0.171-0.096 0.292-0.262 0.329-0.454s-0.012-0.392-0.133-0.546c-0.946-1.171-2.113-1.933-3.242-2.383zM13.333 14.667c0-1.838 1.496-3.333 3.333-3.333s3.333 1.496 3.333 3.333c0 1.796-1.425 3.262-3.204 3.329-0.046 0-0.087 0-0.133 0-0.042 0-0.083 0-0.129 0-1.775-0.067-3.2-1.533-3.2-3.329zM16.663 22c-1.571 0-2.938-0.279-4.246-0.867 1.325-1.267 2.963-1.767 4.113-1.8 0.046 0 0.092 0 0.137 0s0.087 0 0.133 0c1.15 0.038 2.788 0.533 4.108 1.8-1.3 0.583-2.696 0.867-4.246 0.867z" />
    </SvgIcon>
);

HandbookIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

HandbookIcon.defaultProps = {
    viewBox: '0 0 33 33',
    height: '55',
    width: '55',
};

export default HandbookIcon;
