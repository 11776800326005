import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { COMPANY_LOCATIONS } from 'constants/company';

import Input from '../Input';
import { callFunc, getHandler } from '../../utils';

const InputStyled = styled(Input)`
    border: none;
    margin-top: ${props =>
        props.value > COMPANY_LOCATIONS.EMPLOYEE_COUNT.MAX_COUNT_LIMIT ? '20px' : ''};
`;

function EditableCell(props) {
    const {
        col,
        handlers,
        row,
        cellProps: { inputProps },
    } = props;
    const onEmployeeCountChange = getHandler(handlers, 'onEmployeeCountChange');
    const isValidEmployeeCount = getHandler(handlers, 'isValidEmployeeCount');

    const [inputValue, setInputValue] = React.useState(row[col.id]);

    return (
        <>
            <InputStyled
                onBlur={() => {
                    inputValue !== row[col.id] &&
                        callFunc(onEmployeeCountChange, { ...row, [col.id]: inputValue });
                }}
                onChange={e => {
                    if (!inputProps.pattern) {
                        setInputValue(e.target.value);
                    } else if (e.target.value.match(inputProps.pattern)) {
                        setInputValue(e.target.value);
                    } else {
                        setInputValue(inputValue);
                    }
                }}
                value={inputValue}
                {...inputProps}
            />
            {callFunc(isValidEmployeeCount, inputValue)}
        </>
    );
}

EditableCell.propTypes = {
    cellProps: PropTypes.shape({
        cellType: PropTypes.string,
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        items: PropTypes.array,
        onClick: PropTypes.string,
        inputProps: PropTypes.shape({
            type: PropTypes.string,
            pattern: PropTypes.string,
            min: PropTypes.string,
            max: PropTypes.string,
            step: PropTypes.string,
        }),
    }),
    col: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    handlers: PropTypes.object,
    row: PropTypes.object,
};

EditableCell.defaultProps = {
    cellProps: {},
    col: null,
    handlers: null,
    row: null,
};

export default EditableCell;
