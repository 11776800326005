import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { getThemeProps } from 'ravenjs/utils/theme';

const NavLogoStyled = styled(NavLink)`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 200px;
    ${getThemeProps('NavLogo.styles')};

    ${({ theme }) => theme.media.up('md')`
        height: 60px;
    `};
    ${({ theme }) => theme.media.down('md')`
        height: 32px;
    `};
`;

export default NavLogoStyled;
