import { call, put } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import { fetchMeetingsAPI, updateMeetingAPI, fetchMyMeetingsAPI } from 'modules/apis';

import * as meetingsActions from '../actions';

export function* fetchMeetingsSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchMeetingsAPI, params);
        const data = get(response, 'data', []);
        yield put(meetingsActions.fetchMeetings.success(data));
        return data;
    } catch (error) {
        yield put(meetingsActions.fetchMeetings.error(error));
        return error;
    }
}

export function* updateMeetingSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateMeetingAPI, payload);
        const data = get(response, 'data', {});
        yield put(meetingsActions.updateMeeting.success(data));
        return data;
    } catch (error) {
        yield put(meetingsActions.updateMeeting.error(error));
        return error;
    }
}

export function* fetchMyMeetingsSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchMyMeetingsAPI, params);
        const data = get(response, 'data', []);
        yield put(meetingsActions.fetchMyMeetings.success(data));
        return data;
    } catch (error) {
        yield put(meetingsActions.fetchMyMeetings.error(error));
        return error;
    }
}
