import axios from 'axios';

/**
 * Update user's newsletter subscription.
 *
 * @param   {Object}    payload
 * @return  {Promise<unknown>}
 */
export const updateUsersNewslettersSubscription = payload => {
    const { hrLens, partnerId, thinkhrCrunch, userIds } = payload;
    return axios({
        method: 'patch',
        url: '/v2/users/bulk',
        data: {
            userIds,
            hrLens,
            thinkhrCrunch,
        },
        params: {
            partnerId,
        },
    });
};
