import { bool, func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import { compose } from 'recompose';
import styled from 'styled-components';

import { get } from 'ravenjs/utils/lodash';
import { addEventListenerOnRoot, removeEventListenerOnRoot } from 'ravenjs/utils/events';

import AppNavigationLogoBar from 'components/AppNavigationLogoBar';
import NavItems from 'components/NavItems';
import NotificationWidget from 'components/NotificationWidget';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import AINav from 'components/AINav';
import UserNav from 'components/UserNav';
import { EVENTS } from 'constants/events';
import { SOFT_LOGIN_APP_NAVIGATION } from 'constants/navigation';
import { APP_PERMISSIONS } from 'constants/permissions';
import { DEFAULT_TITLE } from 'constants/safety';
import Acl from 'modules/acl';
import { actions as pendoActions } from 'modules/pendo';
import { hasSearch } from 'utils/search';
import { isPublicRoute, isSafari } from 'utils/thrservice';
import { isEmployee } from 'utils/user';
import AppNavigationStyled from './AppNavigationStyled';

const NavBar = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const NavBarRight = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;

class AppNavigation extends Component {
    constructor(props) {
        super(props);
        this.appRef = React.createRef();
        this.observer = new IntersectionObserver(this.handleObserver, { threshold: [0] });

        this.state = {
            isVisibleOnDOM: true,
            avoidKeyCodes: { r: false, R: false, Meta: false, Shift: false },
        };

        this.keyDownDetect = this.keyDownDetect.bind(this);
        this.keyUpDetect = this.keyUpDetect.bind(this);
    }

    componentDidMount() {
        addEventListenerOnRoot('keydown', this.keyDownDetect, false);
        addEventListenerOnRoot('keyup', this.keyUpDetect, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.appRef.current && this.observer.observe(this.appRef.current);
    }

    componentWillUnmount() {
        removeEventListenerOnRoot('keydown', this.keyDownDetect, false);
        removeEventListenerOnRoot('keyup', this.keyUpDetect, false);
    }

    isMetaKey = event => {
        return (
            event.metaKey && event.key === 'Meta' && (event.keyCode === 91 || event.keyCode === 93)
        );
    };

    keyDownDetect = event => {
        if (isSafari()) {
            const { avoidKeyCodes } = this.state;
            if (this.isMetaKey(event)) {
                avoidKeyCodes.Meta = true;
            } else {
                avoidKeyCodes[event.key] = true;
            }

            this.setState({ avoidKeyCodes }, () => {
                if (
                    avoidKeyCodes.Meta &&
                    avoidKeyCodes.Shift &&
                    (avoidKeyCodes.r || avoidKeyCodes.R)
                ) {
                    event.preventDefault();
                }
            });
        }
    };

    keyUpDetect = event => {
        if (isSafari()) {
            const { avoidKeyCodes } = this.state;

            if (this.isMetaKey(event)) {
                avoidKeyCodes.Meta = false;
            } else {
                avoidKeyCodes[event.key] = false;
            }

            this.setState({ avoidKeyCodes });
        }
    };

    handleObserver = entries => {
        this.setState({
            isVisibleOnDOM: entries[0].isIntersecting,
        });
    };

    filterAppNavItems = appNavUnfiltered => {
        const { user } = this.props;
        const appNavItemsUnfiltered = _cloneDeep(appNavUnfiltered);
        if (isEmployee(user)) {
            appNavItemsUnfiltered.items = appNavItemsUnfiltered.items.filter(
                item => item.title !== DEFAULT_TITLE
            );
        }
        return appNavItemsUnfiltered;
    };

    onNavLogoClick = () => {
        const { sendPendoEvent } = this.props;
        const pendoEvent = EVENTS.NAVIGATION.SELECT;

        pendoEvent.eventProperties.selection = EVENTS.NAVIGATION.SELECTIONS.NAV_LOGO;
        sendPendoEvent(pendoEvent);
    };

    onUserNavClick = navLink => {
        const { sendPendoEvent } = this.props;
        const pendoEvent = EVENTS.NAVIGATION.SELECT;

        pendoEvent.eventProperties.selection = `usernav:${navLink.state}`;
        sendPendoEvent(pendoEvent);
    };

    render() {
        const {
            appNav: appNavUnfiltered,
            authorized,
            history,
            isImpersonatingUser,
            isSoftLogin,
            user,
            userNav,
        } = this.props;
        const { userId } = user;
        const { isVisibleOnDOM } = this.state;
        const pathname = get(this.props, 'location.pathname', '');
        const appNav = this.filterAppNavItems(
            isSoftLogin ? SOFT_LOGIN_APP_NAVIGATION : appNavUnfiltered
        );
        const showContentAi = Acl.check(APP_PERMISSIONS.aiDigitalAssistantView);

        return (
            ((authorized && !isPublicRoute(pathname)) || isSoftLogin) && (
                <>
                    <AppNavigationLogoBar
                        logoUrl={appNav.logoUrl}
                        onClickLogo={this.onNavLogoClick}
                    />
                    <AppNavigationStyled
                        className={
                            Acl.check(APP_PERMISSIONS.userAssistanceClientAdmin)
                                ? 'pendo-primary-navigation'
                                : ''
                        }
                        ref={this.appRef}
                        hasSearch={hasSearch(pathname)}
                        isImpersonatingUser={isImpersonatingUser}
                    >
                        <NavBar>
                            <NavItems
                                isImpersonatingUser={isImpersonatingUser}
                                items={appNav.items}
                                history={history}
                                isVisibleOnDOM={isVisibleOnDOM}
                            />
                            <NavBarRight>
                                {showContentAi && <AINav />}
                                <NotificationWidget history={history} key={userId} />
                                {userNav.show && (
                                    <UserNav
                                        history={history}
                                        items={isSoftLogin ? {} : userNav.items}
                                        onNavClick={navLink => {
                                            this.onUserNavClick(navLink);
                                        }}
                                        user={user}
                                    />
                                )}
                            </NavBarRight>
                        </NavBar>
                    </AppNavigationStyled>
                </>
            )
        );
    }
}

AppNavigation.propTypes = {
    appNav: object,
    authorized: bool,
    history: object,
    isImpersonatingUser: bool,
    isSoftLogin: bool.isRequired,
    sendPendoEvent: func.isRequired,
    user: object,
    userNav: object,
};

AppNavigation.defaultProps = {
    appNav: {},
    authorized: false,
    history: {},
    isImpersonatingUser: false,
    user: {},
    userNav: {},
};

const mapDispatchToProps = {
    sendPendoEvent: pendoActions.sendPendoEvent,
};

export { AppNavigation as AppNavigationUnwrapped };
export default compose(
    withPublicContent,
    withRouter,
    connect(null, mapDispatchToProps)
)(AppNavigation);
