import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const TrainingValueIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill={color}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)">
                    <circle stroke={color} cx="29" cy="29" r="29" />
                    <g transform="translate(13.000000, 16.000000)" fillRule="nonzero">
                        <path
                            d="M6.46331667,13.363972 C6.46331667,12.7720684 6.46331667,12.1802478 6.46331667,11.5884273 C6.46339873,11.1991985 6.46356285,11.198783 6.81166557,11.3244265 C8.89987156,12.078121 10.9879956,12.8321479 13.0762836,13.5856762 C13.6154244,13.7802906 14.1565345,13.9695868 14.694034,14.1688546 C15.3638979,14.4173162 16.0303972,14.4305287 16.7060875,14.1866375 C19.3526364,13.2311822 22.0010728,12.2808789 24.6486886,11.3284982 C24.9949039,11.2039351 24.9948219,11.2034364 24.9948219,11.5894244 C24.9949039,12.7626784 24.9826769,13.9360985 24.9997455,15.109103 C25.0089364,15.7408935 24.76899,16.2331632 24.3045248,16.6307849 C23.6021648,17.2321616 22.7670301,17.5719474 21.9056359,17.845172 C19.2792739,18.6782246 16.5880837,18.8878797 13.8554527,18.6693332 C12.1539181,18.5333026 10.4889006,18.2205236 8.88789069,17.6051033 C8.2637348,17.3651176 7.66698728,17.0728636 7.15279311,16.6297876 C6.68906645,16.2300886 6.43935486,15.7399795 6.4594598,15.108189 C6.47784147,14.5273373 6.46331667,13.9454053 6.46331667,13.363972"
                            fill={colorInner}
                        />
                        <path
                            d="M29.4749706,9.79166667 L29.4749706,10.1577692 C29.4749706,12.6412017 29.4769628,15.1246343 29.4712352,17.6080668 C29.4707372,17.8067348 29.5110793,17.9363081 29.6831558,18.0701066 C30.6734467,18.84009 30.6580901,20.2814684 29.65485,21.0311541 C29.5068459,21.1417554 29.4719823,21.2500369 29.4724804,21.4150689 C29.4767968,22.843026 29.4779589,24.271066 29.4733104,25.6990231 C29.4715672,26.2203816 29.1347189,26.6008996 28.6453011,26.659804 C28.1850192,26.7153117 27.7636683,26.4295712 27.6422268,25.9666202 C27.6113477,25.8490597 27.6094386,25.7216404 27.6091895,25.5987779 C27.6071143,24.2018884 27.6054541,22.8049162 27.6110157,21.407944 C27.6117628,21.2383555 27.5645309,21.1359561 27.4204283,21.028006 C26.4132036,20.2741778 26.4156109,18.8008204 27.4264879,18.0451695 C27.5730808,17.9355625 27.6108497,17.8309262 27.6106006,17.6650658 C27.6065332,15.3575182 27.6110987,13.0499706 27.6025488,10.7425059 C27.6017188,10.5241202 27.6589946,10.4227979 27.8739035,10.3546973 C28.4034144,10.1869315 28.9230474,9.98809778 29.4749706,9.79166667"
                            fill={DEFAULT_COLORS.YELLOW}
                        />
                        <path
                            d="M31.6569852,6.51725329 C31.4556875,6.90351973 31.1184045,7.09241776 30.7155616,7.23435855 C25.9149279,8.92580596 21.1176754,10.6264638 16.3219071,12.3316447 C15.9834696,12.4519572 15.6756268,12.4503125 15.3360348,12.3295066 C10.4827057,10.6024507 5.62723264,8.88115132 0.768378472,7.16980263 C0.412705729,7.04455592 0.186173612,6.81651316 -3.29861111e-05,6.51725329 L-3.29861111e-05,5.96231908 C0.0692378472,5.63057566 0.289172743,5.43444079 0.587284722,5.30368421 C1.03136024,5.10902961 1.49613455,4.97087171 1.95225,4.81092105 C5.37241493,3.61141447 8.78886892,2.40162829 12.2108481,1.20754934 C13.2315208,0.851381579 14.251039,0.491513158 15.270145,0.130657895 C15.646434,-0.00264802632 16.009941,-0.00190789474 16.3868073,0.129342105 C19.2648455,1.13189145 22.138678,2.14669408 25.0133351,3.1587829 C26.8781224,3.81519737 28.7436519,4.46939145 30.6076971,5.12786184 C30.7533308,5.17934211 30.9000364,5.22712171 31.0416293,5.28986842 C31.3680269,5.43452302 31.6240816,5.63575658 31.6569852,6.02399671 L31.6569852,6.51725329 Z"
                            fill={colorInner}
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

TrainingValueIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

TrainingValueIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default TrainingValueIcon;
