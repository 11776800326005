import * as actions from './actions';
import reducer, * as uiSelectors from './reducer';
import saga from './sagas';

import { selectors as casesSelectors } from './cases';
import { selectors as idleSelectors } from './idle';
import { selectors as loadingSelectors } from './loading';
import { selectors as modalSelectors } from './modals';
import { selectors as navigationSelectors } from './navigation';
import { selectors as featureFlagsSelectors } from './featureFlags';

/**
 * Build the selectors for the ui module
 *
 * @type {Object}
 */
const selectors = {
    ...casesSelectors,
    ...idleSelectors,
    ...loadingSelectors,
    ...modalSelectors,
    ...navigationSelectors,
    ...uiSelectors,
    ...featureFlagsSelectors,
};

export { actions, reducer, saga, selectors };
