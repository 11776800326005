import { call, put } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import { fetchCommunityUrlAPI } from 'modules/apis';

import * as communityActions from '../actions';

export function* fetchCommunityUrlSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCommunityUrlAPI, payload);
        const data = get(response, 'data', []);
        yield put(communityActions.fetchCommunityUrl.success(data));
        return data;
    } catch (error) {
        yield put(communityActions.fetchCommunityUrl.error(error));
        return error;
    }
}
