import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import { DEFAULT_COLORS } from 'constants/colors';

const SupportEmailAddressContainer = styled.div`
    color: ${DEFAULT_COLORS.BLACK};
    margin: ${props => props.margin};
    a {
        color: ${DEFAULT_COLORS.BLACK};
        font-family: ${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        :hover {
            color: ${DEFAULT_COLORS.LINK};
            text-decoration: none;
        }
    }
`;

const SupportEmailAddress = ({ emailAddress, fontFamily, fontSize, fontWeight, margin }) => {
    return emailAddress ? (
        <SupportEmailAddressContainer
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            margin={margin}
        >
            <a className="pendo-support-email-address" href={`mailto:${emailAddress}`}>
                {emailAddress}
            </a>
        </SupportEmailAddressContainer>
    ) : null;
};

SupportEmailAddress.propTypes = {
    emailAddress: string,
    fontFamily: string,
    fontSize: string,
    fontWeight: string,
    margin: string,
};

SupportEmailAddress.defaultProps = {
    emailAddress: '',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    margin: '0',
};

export default SupportEmailAddress;
