import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching jobs list .
 *
 * @type {Object}
 */
export const fetchJobsList = createActions('tools', 'FETCH_JOBS_LIST', {
    asPromise: true,
});

/**
 * Redux actions for fetching job Details.
 *
 * @type {Object}
 */
export const fetchJobDetails = createActions('tools', 'FETCH_JOB_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions for fetching jobs functional Area list.
 *
 * @type {Object}
 */
export const fetchFunctionalAreasList = createActions('tools', 'FETCH_FUNCTIONAL_AREA_LIST', {
    asPromise: true,
});

/**
 * Redux actions for fetching jobs recommended content.
 *
 * @type {Object}
 */
export const fetchJobsRecommendedContent = createActions('tools', 'FETCH_RECOMMENDED_CONTENT', {
    asPromise: true,
});

/**
 * Redux actions to fetch job descriptions by functional area.
 *
 * @type {Object}
 */
export const fetchJobsByFunctionalArea = createActions('tools', 'FETCH_JOBS_BY_FUNCTIONAL_AREA', {
    asPromise: true,
});

/**
 * Redux actions for fetching the url to benefits document creator
 *
 * @type {Object}
 */
export const fetchBenefitsDocumentCreatorUrl = createActions(
    'benefitsDocumentCreator',
    'FETCH_BENEFITS_DOCUMENT_CREATOR_URL',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching the url to benefits document creator
 *
 * @type {Object}
 */
export const fetchBenefitsDocumentCreateDocumentUrl = createActions(
    'benefitsDocumentCreator',
    'FETCH_BENEFITS_DOCUMENT_CREATE_DOCUMENT_URL',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching documents to benefits document creator widget.
 *
 * @type {Object}
 */
export const fetchBdcDocuments = createActions('benefitsDocumentCreator', 'FETCH_BDC_DOCUMENTS', {
    asPromise: true,
});

/**
 * Redux actions for fetching minimum wage map data.
 *
 * @type {Object}
 */
export const fetchMinWageMapData = createActions('tools', 'FETCH_MIN_WAGE_MAP', {
    asPromise: true,
});

/**
 * Redux actions for fetching the url to benefits document creator resources or faq
 * @type {Object}
 */
export const fetchBenefitsDocumentCreatorUrlResources = createActions(
    'benefitsDocumentCreator',
    'FETCH_BENEFITS_DOCUMENT_CREATOR_URL_RESOURCES',
    {
        asPromise: true,
    }
);
