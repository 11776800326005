import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import Typography from 'ravenjs/lib/Typography';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import Link from 'ravenjs/lib/Link';

import { DEFAULT_COLORS } from 'constants/colors';
import MESSAGES from 'constants/messages';
import {
    IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS,
    TOPIC_STATE_ROUTE_MAP,
} from 'constants/navigation';
import { SAFETY_MANUAL_TEMPLATE_ID } from 'constants/safety';
import { NavLink } from 'react-router-dom';
import Acl from 'modules/acl';
import {
    formatSubNavItems,
    getSubNavToProp,
    getTopicNavigationToProp,
    navItemsNotBeClickable,
} from 'utils/navigation';
import { NavigationArrowRight } from 'components/Icons';
import { selectors as authSelectors } from 'modules/auth';
import { actions as contentfulActions } from 'modules/contentful';
import { actions as pendoActions } from 'modules/pendo';
import { actions as uiActions } from 'modules/ui';
import { selectors as userSelectors } from 'modules/user';
import {
    downloadSafetyManualTemplate,
    filterSafetySubnavigation,
    isDisableSafetyItem,
    sendPendoEventToolClick,
} from 'utils/safety';

const LinkStyled = styled(Link)`
    color: ${DEFAULT_COLORS.BLACK} !important;
    :hover {
        color: ${DEFAULT_COLORS.LINK} !important;
        text-decoration: none;
    }
`;

const NavItem = styled.div`
    margin-bottom: 2.9rem;
`;

const NavItemsWrapper = styled.div``;

const SubNavItems = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-left: ${({ hasTopics }) => (hasTopics ? '1rem' : '0')};
`;

const linkStyle = {
    fontWeight: 'normal',
    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
    fontSize: '1.118rem',
    marginBottom: '1.5rem',
    textDecoration: 'none',
};

const NavItems = props => {
    const {
        currentUser,
        closeModal,
        fetchSafetyManualTemplate,
        isImpersonatingUser,
        isLoading,
        items,
        onChangeSelection,
        onRedirect,
        openModal,
        safetyManualTemplateUrl,
        selected,
        sendPendoEvent,
        userCompany,
        userPermissionsOriginal,
    } = props;

    const onNavItemClick = ({ state }) => () => {
        onChangeSelection(state);
    };

    const filterUnauthorized = subNavItems => {
        return subNavItems.filter(item => {
            if (item.permission) {
                return Acl.check(item.permission);
            }
            return filterSafetySubnavigation(item);
        });
    };

    const itemsNotBeClickeable = navItemsNotBeClickable(Acl);

    const sendEventToolClick = toolName => {
        sendPendoEventToolClick({
            currentUser,
            from: 'Navigation',
            sendPendoEvent,
            toolName,
            userCompany,
            userPermissionsOriginal,
        });
    };

    if (selected) {
        const selectedItem = items.find(({ state }) => state === selected);
        let subNavItems = get(selectedItem, 'subnav.main', []);
        subNavItems = filterUnauthorized(subNavItems);
        const topics = get(selectedItem, 'subnav.topics', []);
        const formattedSubNavItems = formatSubNavItems(subNavItems, selected);
        const hasTopics = !isEmpty(topics);
        const navLinkStyle = {
            color: DEFAULT_COLORS.BLACK,
            fontSize: '1.118rem',
            fontWeight: 'normal',
            marginBottom: '1.5rem',
            textDecoration: 'none',
        };
        const topicName = !isEmpty(TOPIC_STATE_ROUTE_MAP[selectedItem.state])
            ? TOPIC_STATE_ROUTE_MAP[selectedItem.state]
            : 'Topics';

        const pendoData = {
            currentUser,
            sendPendoEvent,
            userCompany,
            userPermissionsOriginal,
        };

        return (
            <>
                <NavLink
                    onClick={() =>
                        onRedirect(
                            itemsNotBeClickeable.indexOf(selectedItem.state) === -1
                                ? selectedItem.state
                                : null
                        )
                    }
                    style={{
                        ...navLinkStyle,
                        fontFamily: 'Favorit',
                        fontSize: '1.875rem',
                        fontWeight: 'bold',
                        cursor:
                            itemsNotBeClickeable.indexOf(selectedItem.state) !== -1
                                ? 'default'
                                : 'pointer',
                    }}
                    to={
                        itemsNotBeClickeable.indexOf(selectedItem.state) === -1
                            ? {
                                  pathname: selectedItem.to,
                                  state: selectedItem.state,
                              }
                            : {}
                    }
                >
                    {selectedItem.title}
                </NavLink>
                <SubNavItems hasTopics={hasTopics}>
                    {formattedSubNavItems.map(item => {
                        const { label, state, to } = item;
                        const navLinkDisabled =
                            (isImpersonatingUser &&
                                IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS.includes(to)) ||
                            isDisableSafetyItem(state);

                        if (to === SAFETY_MANUAL_TEMPLATE_ID) {
                            const navLinkStyledPropsManual = navLinkDisabled
                                ? {
                                      disabled: true,
                                      onClick: e => {
                                          e.preventDefault();
                                      },
                                      title: MESSAGES.RESTRICTED_NAV_ITEM,
                                  }
                                : {
                                      onClick: downloadSafetyManualTemplate({
                                          closeModal,
                                          fetchSafetyManualTemplate,
                                          isLoading,
                                          openModal,
                                          safetyManualTemplateUrl,
                                          sendEvent: sendEventToolClick,
                                          pendoData,
                                          product: { title: label },
                                      }),
                                  };

                            const linkStyleManual = navLinkDisabled
                                ? { ...linkStyle, cursor: 'not-allowed' }
                                : linkStyle;

                            return (
                                <LinkStyled
                                    style={linkStyleManual}
                                    key={item.state}
                                    href="#"
                                    {...navLinkStyledPropsManual}
                                >
                                    {item.label}
                                </LinkStyled>
                            );
                        }

                        const navLinkStyledProps = navLinkDisabled
                            ? {
                                  disabled: true,
                                  onClick: e => {
                                      e.preventDefault();
                                  },
                                  title: MESSAGES.RESTRICTED_NAV_ITEM,
                              }
                            : {
                                  onClick: () => onRedirect(selectedItem.state, item),
                              };

                        return (
                            <NavLink
                                key={state}
                                style={navLinkStyle}
                                to={getSubNavToProp(selectedItem.state, item)}
                                {...navLinkStyledProps}
                            >
                                {label}
                            </NavLink>
                        );
                    })}
                </SubNavItems>
                {hasTopics && (
                    <>
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            fontSize="1.25rem"
                            fontWeight="bold"
                        >
                            {topicName}
                        </Typography>
                        <SubNavItems hasTopics={hasTopics}>
                            {topics.map(topic => (
                                <NavLink
                                    key={topic.id}
                                    onClick={() => onRedirect(selectedItem.state, topic.state)}
                                    to={getTopicNavigationToProp(selectedItem.state, topic)}
                                    style={navLinkStyle}
                                    state={topic.state}
                                >
                                    {topic.label}
                                </NavLink>
                            ))}
                        </SubNavItems>
                    </>
                )}
            </>
        );
    }

    return (
        <NavItemsWrapper>
            {items.map(item => (
                <NavItem key={item.state}>
                    <Link onClick={onNavItemClick(item)} style={{ textDecoration: 'none' }}>
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            fontSize="1.5rem"
                            fontWeight="bold"
                        >
                            {item.title}
                            <NavigationArrowRight style={{ marginLeft: '0.8rem' }} />
                        </Typography>
                    </Link>
                </NavItem>
            ))}
        </NavItemsWrapper>
    );
};

NavItems.propTypes = {
    closeModal: func.isRequired,
    currentUser: object.isRequired,
    fetchSafetyManualTemplate: func.isRequired,
    items: arrayOf(
        shape({
            state: string.isRequired,
            subnav: shape({
                main: arrayOf(
                    shape({
                        label: string.isRequired,
                        state: string.isRequired,
                    })
                ).isRequired,
                topics: arrayOf(
                    shape({
                        id: string.isRequired,
                        label: string.isRequired,
                        state: string.isRequired,
                    })
                ),
            }).isRequired,
            title: string.isRequired,
            to: string.isRequired,
        })
    ).isRequired,
    isImpersonatingUser: bool,
    isLoading: func.isRequired,
    onChangeSelection: func.isRequired,
    onRedirect: func.isRequired,
    openModal: func.isRequired,
    safetyManualTemplateUrl: string,
    selected: string,
    sendPendoEvent: func.isRequired,
    userCompany: object.isRequired,
    userPermissionsOriginal: arrayOf(
        shape({
            permissionKey: string,
        })
    ),
};

NavItems.defaultProps = {
    isImpersonatingUser: false,
    safetyManualTemplateUrl: '',
    selected: null,
    userPermissionsOriginal: [],
};

const mapDispatchToProps = {
    closeModal: uiActions.closeModal,
    fetchSafetyManualTemplate: contentfulActions.fetchSafetyManualTemplate,
    isLoading: uiActions.isLoading,
    openModal: uiActions.openModal,
    sendPendoEvent: pendoActions.sendPendoEvent,
};

const mapStateToProps = createStructuredSelector({
    currentUser: userSelectors.getUserInfo,
    isImpersonatingUser: authSelectors.isImpersonatingUser,
    safetyManualTemplateUrl: userSelectors.getSafetyManualTemplateUrl,
    userCompany: userSelectors.getUserCompany,
    userPermissionsOriginal: userSelectors.getUserPermissionsOriginal,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavItems);
