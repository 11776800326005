import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const FullscreenIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3,9c0.5,0,1-0.4,1-1V5.4l3.3,3.3c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L5.4,4H8c0.6,0,1-0.5,1-1S8.6,2,8,2
                H3C2.5,2,2,2.5,2,3v5C2,8.6,2.5,9,3,9z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15,3c0,0.5,0.4,1,1,1h2.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0L20,5.4V8c0,0.6,0.5,1,1,1
                s1-0.4,1-1V3c0-0.5-0.5-1-1-1h-5C15.4,2,15,2.5,15,3z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8,20c0.6,0,1,0.5,1,1s-0.4,1-1,1H3c-0.5,0-1-0.5-1-1v-5c0-0.6,0.5-1,1-1s1,0.4,1,1v2.6l3.3-3.3
                c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L5.4,20H8z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21,15c-0.5,0-1,0.4-1,1v2.6l-3.3-3.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.3,3.3H16c-0.6,0-1,0.5-1,1
                s0.4,1,1,1h5c0.5,0,1-0.5,1-1v-5C22,15.4,21.5,15,21,15z"
            />
        </SvgIcon>
    );
};

FullscreenIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

FullscreenIcon.defaultProps = {
    fill: DEFAULT_COLORS.CATEGORY_LEAVES_ACCOMMODATIONS,
    height: '24px',
    viewBox: '0 0 24 24',
    width: '24px',
};

export default FullscreenIcon;
