import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from '../SvgIcon';

const ArrowDownIcon = ({ height, stroke, viewBox, width }) => (
    <SvgIcon width={width} height={height} viewBox={viewBox}>
        <path
            fill="none"
            fillRule="evenodd"
            stroke={stroke}
            strokeWidth="2"
            d="M1.25 1.021L8 8.745 14.75 1.021"
        />
    </SvgIcon>
);

ArrowDownIcon.propTypes = {
    height: PropTypes.string,
    stroke: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

ArrowDownIcon.defaultProps = {
    height: '10',
    stroke: '#B9B9B9',
    viewBox: '0 0 16 10',
    width: '16',
};

export default ArrowDownIcon;
