import styled from 'styled-components';

const FooterMenu = styled.ul`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    ${({ theme }) => theme.media.down('md')`
        flex-wrap: wrap;
    `};
`;

export default FooterMenu;
