import { createActions } from 'utils/actions';

export const fetchMeetings = createActions('meetings', 'FETCH_MEETINGS', {
    asPromise: true,
});

export const updateMeeting = createActions('meetings', 'UPDATE_MEETING', {
    asPromise: true,
});

export const fetchMyMeetings = createActions('meetings', 'FETCH_MY_MEETINGS', {
    asPromise: true,
});
