import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const DowngradeIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.39052 16.3905C8.91122 15.8698 9.75544 15.8698 10.2761 16.3905L16 22.1144L21.7239 16.3905C22.2446 15.8698 23.0888 15.8698 23.6095 16.3905C24.1302 16.9112 24.1302 17.7554 23.6095 18.2761L16.9428 24.9428C16.4221 25.4635 15.5779 25.4635 15.0572 24.9428L8.39052 18.2761C7.86983 17.7554 7.86983 16.9112 8.39052 16.3905Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.39052 7.05715C8.91122 6.53645 9.75544 6.53645 10.2761 7.05715L16 12.781L21.7239 7.05715C22.2446 6.53645 23.0888 6.53645 23.6095 7.05715C24.1302 7.57785 24.1302 8.42207 23.6095 8.94277L16.9428 15.6094C16.4221 16.1301 15.5779 16.1301 15.0572 15.6094L8.39052 8.94277C7.86983 8.42207 7.86983 7.57785 8.39052 7.05715Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

DowngradeIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

DowngradeIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '32',
    viewBox: '0 0 32 32',
    width: '32',
};

export default DowngradeIcon;
