import React from 'react';
import { number, string } from 'prop-types';
import styled from 'styled-components';

import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';

import { DEFAULT_COLORS } from 'constants/colors';
import MESSAGES from 'constants/messages';

const StyledTypography = styled(Typography)`
    margin: 5px 0 8px !important;
`;

const DisplayRemainingCharacterCount = ({ characterCount, color }) => {
    return (
        <Row justify="flex-end" margin="0">
            <StyledTypography
                color={color}
                fontSize="14px"
                fontFamily="Roboto"
                fontWeight="400"
                lineHeight="19px"
                wordWrap
            >
                {MESSAGES.FORM.CHARACTERS_REMAINING} {characterCount}
            </StyledTypography>
        </Row>
    );
};

DisplayRemainingCharacterCount.propTypes = {
    color: string,
    characterCount: number,
};

DisplayRemainingCharacterCount.defaultProps = {
    color: DEFAULT_COLORS.GREY_27,
    characterCount: 0,
};

export default DisplayRemainingCharacterCount;
