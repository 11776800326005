import styled, { css } from 'styled-components';
import List from 'ravenjs/lib/List';
import ListItem from 'ravenjs/lib/ListItem';
import Typography from 'ravenjs/lib/Typography';
import Input from 'ravenjs/lib/Input';
import Tooltip from 'ravenjs/lib/Tooltip';
import Button from 'ravenjs/lib/Button';
import Row from 'ravenjs/lib/Row';
import Box from 'ravenjs/lib/Box';
import { COLORS } from 'ravenjs/constants/colors';

export const PeoEditorToolTip = styled(Tooltip)`
    font-family: 'roboto_bold', 'Helvetica Neue', Helvetica, Arial !important;
    background-color: #fff !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    max-width: 100%;
`;

export const ModalHeaderStyled = styled.div`
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const ModalBody = styled.div`
    overflow: auto;
    padding: 40px 48px 24px 48px;
`;

export const titleStyle = {
    fontWeight: '400',
    fontSize: '15px',
    display: 'block',
    marginBottom: '4px',
    color: 'rgba(0, 0, 0, .8)',
    fontFamily: 'Trebuchet MS","Helvetica Neue",Helvetica,Tahoma,sans-serif',
};

export const ModalInputStyled = styled(Input)`
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    padding: 8px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: none;
    color: #333;
    width: 100%;
    font-size: 16px;
    font-family: 'Trebuchet MS","Helvetica Neue",Helvetica,Tahoma,sans-serif';
`;

export const modalStyle = {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '600px',
    marginBottom: '16px',
    marginTop: '244.5px',
    position: 'relative',
    margin: 'auto',
    background: '#fff',
    color: '#000',
    borderRadius: '5px',
    boxShadow: '0 4px 40px rgba(0,0,0,.15)',
    fontFamily: 'Trebuchet MS","Helvetica Neue",Helvetica,Tahoma,sans-serif',
    fontSize: '14px',
};

export const ButtonStyle = styled(Button)`
    ${({ customStyle = {}, hasStyle }) => {
        const { color = COLORS.BLACK, hoverColor = COLORS.GREY_4 } = customStyle;
        const activeGradient = hasStyle ? COLORS.GREY_2 : COLORS.GREY_6;
        return css`
            $font-size: 16px;
            color: ${color};
            border-right: 1px solid rgba(0, 0, 0, 0.05);
            border-top: 0;
            border-left: 0;
            border-bottom: 0;
            background: linear-gradient(${COLORS.GREY_9}, ${activeGradient});
            padding: 5px;
            &:hover {
                background-color: ${hoverColor} !important;
                text-decoration: none;
                color: ${color};
            }
        `;
    }};
`;

export const IconMenuDropdownStyled = styled(ListItem)`
    ${({ customStyles = {}, active }) => {
        const {
            hoverColor = COLORS.GREY_4,
            background = COLORS.SEMANTIC_LIGHT_GREY,
        } = customStyles;
        const backgroundColor = active ? background : null;
        return css`
            background-color: ${backgroundColor};
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding: 8px 8px;
            &:hover {
                background-color: ${hoverColor};
                text-decoration: none;
            }
        `;
    }};
`;

export const ToolbarMenuIconListStyled = styled(List)`
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    overflow: auto;
    min-width: 200px;
    max-height: 254px;
`;

export const fontStyles = {
    fontFamily: '"Trebuchet MS", "Helvetica Neue", Helvetica,Tahoma,sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
};

export const TypographyStyled = styled(Typography)`
    /**
    * Add in dynamic styles
    */

    ${({ customStyles, disable, disableLink }) => {
        const renderCustomColor =
            customStyles && customStyles.fontColor ? customStyles.fontColor : COLORS.BLACK;
        const fontColor = disable && disableLink ? '#000' : renderCustomColor;
        return css`
            padding: 12px 10px;
            margin: 0;
            min-width: 220px;
            max-height: 254px;
            overflow: auto;
            text-decoration: none;
            color: ${fontColor};
            font-weight: ${customStyles && customStyles.fontWeight
                ? customStyles.fontWeight
                : 'inherit'};
            font-size: ${customStyles && customStyles.fontSize ? customStyles.fontSize : '14px'};
            font-family: ${customStyles && customStyles.fontFamily
                ? customStyles.fontFamily
                : 'inherit'};
            font-style: ${customStyles && customStyles.fontStyle
                ? customStyles.fontStyle
                : 'inherit'};
            opacity: ${disable && disableLink ? '0.4' : 'unset'};
            cursor: ${disable && disableLink ? 'default' : 'pointer'};
            &:hover {
                text-decoration: none;
            }
        `;
    }};
`;

export const ToolBarWrapper = styled(Row)`
    background: linear-gradient(${COLORS.GREY_4}, ${COLORS.GREY_3});
    margin: 0px;
`;

export const ActionsWrapper = styled(Box)`
    border: none;
    width: auto;
    margin: 0 0 0 auto;
    background: linear-gradient(${COLORS.GREY_4}, ${COLORS.GREY_3});
    padding: 0px;
`;

export const ToolGroupStyled = styled(Box)`
    borderwidth: 0px;
    width: auto;
    margin: 0.5rem;
    padding: 0;
    height: 2rem;
    border: 1px solid ${COLORS.GREY_3};
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15), inset 0 0 6px rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background: linear-gradient(${COLORS.WHITE}, ${COLORS.GREY_6});
`;

export const EditorWrapper = styled(Box)`
    min-height: 250px;
    border-radius: unset;
    padding: 4px 20px 24px 20px;
    max-height: 300px;
    overflow: auto;
`;
