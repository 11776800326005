import _get from 'lodash/get';
import { call, put } from 'redux-saga/effects';

import { fetchContentfulContentTypesAPI } from 'modules/apis';

import * as userActions from '../actions';

/**
 * Saga to fetch content types
 *
 * @method fetchAllContentTypesSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchAllContentTypesSaga(action) {
    try {
        const response = yield call(fetchContentfulContentTypesAPI);
        const contentType = _get(response, 'data.contentTypes', []);
        yield put(userActions.fetchAllContentTypes.success(contentType));
        return contentType;
    } catch (error) {
        yield put(userActions.fetchAllContentTypes.error(error));
        return error;
    }
}
