const COMMON_ERROR = {
    INTUIT_JIT_PROVISIONING_ERROR: {
        event: 'intuitJitProvisioningErrorPage',
        eventProperties: {
            description: '',
        },
    },
};

export default COMMON_ERROR;
