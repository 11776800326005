import React, { useRef } from 'react';
import PropTypes, { arrayOf, node, oneOfType } from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MESSAGES from 'constants/messages';
import { DEFAULT_COLORS } from 'constants/colors';
import { selectors as authSelectors } from 'modules/auth';
import { actions as userActions, selectors as userSelectors } from 'modules/user';

import { webSocketSingleton } from '../WebSocketConnector/WebSocketSingleton';
import CustomToast from './CustomToast';
import { ErrorIcon, SuccessIcon } from '../Icons';

const AsyncBulkImportContext = React.createContext([{}, () => {}]);

const AsyncBulkImportProvider = ({ children, updateImportStatus, importStatus, isLoggedIn }) => {
    const toastIdRef = useRef({});
    const handleOnToastClick = key => {
        toastIdRef.current[key] && toast.dismiss(toastIdRef.current[key]);
    };

    const getToastIcon = toastType => {
        if (toastType === MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.FAILED) {
            return <ErrorIcon color={DEFAULT_COLORS.WHITE} height="24px" width="24px" />;
        } else {
            return <SuccessIcon color={DEFAULT_COLORS.WHITE} height="24px" width="24px" />;
        }
    };

    const renderCustomToast = (toastType, importData) => {
        const { importName } = importData;
        if (toastType === MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.SUCCESS) {
            const toastId = toast.success(
                <CustomToast
                    importData={importData}
                    importDescription={MESSAGES.BULK_IMPORT_CONSTANT.SUCCESS.DESCRIPTION}
                    importLinktext={MESSAGES.BULK_IMPORT_CONSTANT.SUCCESS.LINK_TEXT}
                    toastType={MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.SUCCESS}
                    onClickLink={() => handleOnToastClick(importName)}
                />,
                {
                    autoClose: false,
                    icon: getToastIcon(MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.SUCCESS),
                }
            );
            toastIdRef.current[importData.importName] = toastId;
        } else {
            const toastId = toast.error(
                <CustomToast
                    importData={importData}
                    importDescription={MESSAGES.BULK_IMPORT_CONSTANT.FAILED.DESCRIPTION}
                    importLinktext={MESSAGES.BULK_IMPORT_CONSTANT.FAILED.LINK_TEXT}
                    toastType={MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.FAILED}
                    onClickLink={() => handleOnToastClick(importName)}
                />,
                {
                    autoClose: false,
                    icon: getToastIcon(MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.FAILED),
                }
            );
            toastIdRef.current[importData.importName] = toastId;
        }
    };

    webSocketSingleton.setOnMessage((name, event) => {
        if (
            event.data &&
            typeof event === 'object' &&
            typeof event.data === 'string' &&
            event.data !== MESSAGES.BULK_IMPORT_CONSTANT.KEEP_ALIVE
        ) {
            const message = JSON.parse(event.data);
            if (isLoggedIn && message.type === MESSAGES.IMPORT.RESPONSE_TYPE.API_RESPONSE) {
                updateImportStatus({ name, message });
                const importData = { ...importStatus[name], importName: message.importName };
                if (message.statusCode === MESSAGES.IMPORT.RESPONSE_STATUS_CODE.SUCCESS) {
                    renderCustomToast(MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.SUCCESS, importData);
                } else {
                    renderCustomToast(MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.FAILED, importData);
                }
            }
        }
    });

    return (
        <AsyncBulkImportContext.Provider value={webSocketSingleton}>
            {children}
        </AsyncBulkImportContext.Provider>
    );
};

AsyncBulkImportProvider.propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired,
    updateImportStatus: PropTypes.func.isRequired,
    importStatus: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    updateImportStatus: userActions.updateImportStatus,
};

const mapStateToProps = createStructuredSelector({
    importStatus: userSelectors.getImportStatus,
    isLoggedIn: authSelectors.isUserLoggedIn,
});
export default connect(mapStateToProps, mapDispatchToProps)(AsyncBulkImportProvider);

export { AsyncBulkImportContext };
