import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MobileCaretIcon = ({ height, viewBox, width, color, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} color={color}>
            <g fill="none" fillRule="evenodd">
                <g fill={color}>
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M6 6.755L0 13.163 0 0z"
                                    transform="translate(-264 -396) translate(13.656 380.886) translate(127 15) matrix(-1 0 0 1 129.62 1) matrix(-1 0 0 1 6 0)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

MobileCaretIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

MobileCaretIcon.defaultProps = {
    height: '15',
    viewBox: '0 0 7 15',
    width: '7',
    color: `${DEFAULT_COLORS.BLACK}`,
};

export default MobileCaretIcon;
