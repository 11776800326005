/* istanbul ignore file */

import DOMPurify from 'dompurify';
import { get } from 'ravenjs/utils/lodash';

// Add a hook to make all external links open in a new window/tab
DOMPurify.addHook('afterSanitizeAttributes', node => {
    if (node.hasAttribute('href')) {
        const domainRegex = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
        const regex = domainRegex.exec(node.href);
        if ((!regex || regex.length < 2) && !node.hasAttribute('rel')) return;
        const hrefUrl = get(regex, '1', '');
        const islegacyPlatform = hrefUrl.includes('thinkhr');
        const genesisDomain = window.location.hostname;
        const keepLink = get(node, 'name', '') === 'keepLink';
        if (genesisDomain !== hrefUrl && !islegacyPlatform) {
            node.setAttribute('target', '_blank');
        }
        if (keepLink || get(node, 'rel', '') === 'noopener noreferrer') {
            node.setAttribute('target', '_blank');
        }
        // For any legacy URL, do not redirect to the destination page.
        if (islegacyPlatform && !keepLink) {
            const getText = node.innerHTML;
            const span = document.createElement('span');
            span.innerHTML = getText;
            node.replaceWith(span);
        }
    }
});

/**
 * Sanitize a given markup using DOM-Purify.
 *
 * @method sanitizeMarkup
 * @param  {string} html HTML string to sanitize
 * @param  {Object} [config={}] DOMPurify config
 * @param  {Array} [iframeUrlAllowList=[]] DOMPurify config
 * @param  {boolean} [disableLinks=false] DOMPurify config
 * @return {string}
 */
export const sanitizeMarkup = (
    html,
    config = {},
    iframeUrlAllowList = [],
    disableLinks = false
) => {
    if (iframeUrlAllowList.length && config.ADD_TAGS && config.ADD_TAGS.indexOf('iframe') > -1) {
        DOMPurify.addHook('uponSanitizeElement', function(node, data) {
            if (data.tagName === 'iframe' && node) {
                const iframeSrc = node.getAttribute('src');

                if (iframeSrc) {
                    if (
                        /javascript:/.test(iframeSrc) ||
                        !iframeUrlAllowList.some(regex => regex.test(iframeSrc))
                    ) {
                        // remove iframe
                        node.parentNode && node.parentNode.removeChild(node);
                    }
                } else {
                    // remove iframe
                    node.parentNode && node.parentNode.removeChild(node);
                }
            }
        });
    }

    if (config.ADD_TAGS && config.ADD_TAGS.indexOf('table') > -1) {
        DOMPurify.addHook('uponSanitizeElement', function(node, data) {
            if (data.tagName === 'table') {
                const data1 = node.getAttribute('data');
                node.removeAttribute('data');
                node.innerHTML = data1;
            } else if (data.tagName === 'figure') {
                if (node.innerHTML.includes('<br>')) {
                    node.parentNode && node.parentNode.removeChild(node);
                }
            }
        });
    }

    if (!iframeUrlAllowList.length && config.ADD_TAGS && config.ADD_TAGS.indexOf('iframe') > -1) {
        const iframeIndex = config.ADD_TAGS.indexOf('iframe');
        config.ADD_TAGS.splice(iframeIndex, 1);
    }

    if (disableLinks) {
        DOMPurify.addHook('uponSanitizeElement', function(node, data) {
            if (data.tagName === 'a') {
                const getText = node.innerHTML;
                const span = document.createElement('span');
                span.innerHTML = getText;
                node.replaceWith(span);
            }
        });
    }

    const __html = DOMPurify.sanitize(html, config);

    if (iframeUrlAllowList.length) {
        DOMPurify.removeHook('uponSanitizeElement');
    } else {
        DOMPurify.removeHook('uponSanitizeElement');
    }

    return { __html };
};

export const isAllowedIframeUrl = (iframeUrl, iframeUrlAllowList = []) => {
    const iframeTestMarkup = `<iframe src="${iframeUrl}"></iframe>`;
    const sanitizedIframeMarkup = sanitizeMarkup(
        iframeTestMarkup,
        { ADD_TAGS: ['iframe'] },
        iframeUrlAllowList
    );
    if (sanitizedIframeMarkup.__html.indexOf('&amp;') !== -1) {
        sanitizedIframeMarkup.__html = sanitizedIframeMarkup.__html.replace(/&amp;/g, '&');
    }

    return iframeTestMarkup === sanitizedIframeMarkup.__html;
};
