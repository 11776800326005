import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const BackIcon = ({ height, stroke, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path
                fill="none"
                fillRule="evenodd"
                stroke={stroke}
                strokeWidth="2"
                d="M80 77L86.75 84.725 93.5 77"
                transform="rotate(90 82.75 3.862)"
            />
        </SvgIcon>
    );
};

BackIcon.propTypes = {
    height: string,
    stroke: string,
    viewBox: string,
    width: string,
};

BackIcon.defaultProps = {
    height: '16',
    stroke: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 11 16',
    width: '11',
};

export default BackIcon;
