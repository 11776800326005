import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';

import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { formatNumberWithDelimiter } from 'ravenjs/utils/number';

/**
 * A column title component for the `Transfer` Component.
 *
 * @method      TransferColumnTitle
 * @param       {Object}            props The props for the component
 * @constructor
 */
function TransferColumnTitle(props) {
    const {
        count: countProps,
        initialTotalRecords,
        required,
        schema,
        sendToAll,
        totalRecords,
        type,
    } = props;
    // Define a default label.
    const defaultLabel = type === 'source' ? 'Available Items' : 'Selected Items';
    // Format the passed in `countProps`.
    const formattedLength = formatNumberWithDelimiter(countProps);
    // Format the passed in `totalRecords`.
    const formattedRecords = formatNumberWithDelimiter(totalRecords);
    // Extract the titleSchema.
    const titleSchema = _get(schema, [type, 'title'], {});
    // Extract some props from the title schema.
    const { label = defaultLabel, showCount = true, ...titleProps } = titleSchema;
    // Update some properties based on the `type`.
    switch (type) {
        case 'source': {
            return (
                <Typography {...titleProps}>
                    {showCount && (
                        <span>
                            Showing {formattedLength} of {sendToAll ? 0 : formattedRecords}
                        </span>
                    )}{' '}
                    {label}
                </Typography>
            );
        }
        case 'target': {
            return (
                <Row gutter={false} alignItems="center" justify="space-between" width="100%">
                    <Typography {...titleProps}>
                        {label}{' '}
                        {showCount && (
                            <span>
                                (
                                {sendToAll
                                    ? formatNumberWithDelimiter(initialTotalRecords)
                                    : formattedLength}
                                )
                            </span>
                        )}
                    </Typography>
                    {required && (
                        <Typography color="error" type="required">
                            Required
                        </Typography>
                    )}
                </Row>
            );
        }
        default: {
            return null;
        }
    }
}

TransferColumnTitle.propTypes = {
    initialTotalRecords: PropTypes.number.isRequired,
    /**
     * The count to display in the title.
     */
    count: PropTypes.number,
    /**
     * Display a 'required' label for the column title.
     */
    required: PropTypes.bool,
    /**
     * The schema for the `Transfer` component.
     */
    schema: PropTypes.object,
    /**
     * If `true` the count will be updated with the passed in `totalRecords`.
     */
    sendToAll: PropTypes.bool,
    /**
     * The total number of records available.
     */
    totalRecords: PropTypes.number,
    /**
     * The type of title to render.
     */
    type: PropTypes.oneOf(['source', 'target']),
};

TransferColumnTitle.defaultProps = {
    count: 0,
    required: null,
    schema: {},
    sendToAll: null,
    totalRecords: 0,
    type: 'source',
};

export default TransferColumnTitle;
