import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const IdentifierPatchStyled = styled.div`
    background: ${({ color }) => color};
    border-radius: ${({ borderRadius }) => borderRadius};
    height: ${({ size }) => size};
    flex: ${({ flex }) => flex};
    margin: ${({ margin }) => margin};
    min-width: ${({ minWidth }) => minWidth};
    width: ${({ size }) => size};
`;

function IdentifierPatch({ borderRadius, color, flex, margin, minWidth, size }) {
    return (
        <IdentifierPatchStyled
            borderRadius={borderRadius}
            color={color}
            flex={flex}
            margin={margin}
            minWidth={minWidth}
            size={size}
        />
    );
}

IdentifierPatch.propTypes = {
    borderRadius: string,
    color: string,
    flex: string,
    margin: string,
    minWidth: string,
    size: string,
};

IdentifierPatch.defaultProps = {
    borderRadius: '10rem',
    color: '#888',
    flex: '',
    margin: '',
    minWidth: '',
    size: '1rem',
};

export default IdentifierPatch;
