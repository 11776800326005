import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HRFitnessTestIcon = ({ height, viewBox, width, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <path
                        fill={DEFAULT_COLORS.YELLOW}
                        d="M0.796 0.149H64.796V77.149H0.796z"
                        transform="translate(-154 -493) translate(154 493)"
                    />
                    <path
                        fill={DEFAULT_COLORS.BLACK}
                        d="M14.796 15.149H38.796V20.149H14.796zM12.796 33.149H50.796V38.149H12.796zM12.796 45.149H50.796V50.149H12.796zM12.796 58.149H50.796V63.149H12.796z"
                        transform="translate(-154 -493) translate(154 493)"
                    />
                    <g>
                        <g>
                            <path
                                fill={DEFAULT_COLORS.PEACH}
                                d="M28.216 0L0.021 27.548 0.021 39.81 12.561 39.81 40.756 12.261z"
                                transform="translate(-154 -493) translate(154 493) translate(42.796 47.15) translate(2.137 .77)"
                            />
                            <path
                                fill={DEFAULT_COLORS.BLACK}
                                d="M22.605 5.49l12.538 12.26-1.44 1.407-3.102-3.034-19.73 19.291 3.106 3.038-1.44 1.407L0 27.6l1.44-1.407 3.101 3.032 19.73-19.29-3.106-3.037 1.44-1.408zM.02 35.73l4.193 4.08H.021v-4.08zm25.69-24.388L5.981 30.632l3.451 3.374 19.729-19.29-3.45-3.375z"
                                transform="translate(-154 -493) translate(154 493) translate(42.796 47.15) translate(2.137 .77)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

HRFitnessTestIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

HRFitnessTestIcon.defaultProps = {
    height: '92',
    viewBox: '0 0 88 92',
    width: '88',
};

export default HRFitnessTestIcon;
