import { all } from 'redux-saga/effects';

import { saga as adminSaga } from 'modules/admin';
import { saga as authSaga } from 'modules/auth';
import { saga as bootstrapSaga } from 'modules/bootstrap';
import { saga as brandingSaga } from 'modules/branding';
import { saga as handbooksSaga } from 'modules/handbooks';
import { saga as meetingsSaga } from 'modules/meetings';
import { saga as pendoSaga } from 'modules/pendo';
import { saga as permissionsSaga } from 'modules/permissions';
import { saga as reportingSaga } from 'modules/reporting';
import { saga as contentfulSaga } from 'modules/contentful';
import { saga as uiSaga } from 'modules/ui';
import { saga as userSaga } from 'modules/user';
import { saga as esigDocumentsSaga } from 'modules/esigDocuments';
import { saga as toolSaga } from 'modules/tools';
import { saga as notificationsSaga } from 'modules/notifications';
import { saga as companyPoliciesSaga } from 'modules/companyPolicies';
import { saga as hrFitnessTestSaga } from 'modules/hrFitnessTest';
import { saga as surveysSaga } from 'modules/surveys';
import { saga as ticketsSaga } from 'modules/tickets';
import { saga as threadSaga } from 'modules/thread';
import { saga as toDoListSaga } from 'modules/toDoList';
import { saga as fetchResourceByTypeSaga } from 'modules/legalAndPrivacy';
import { saga as calendarSaga } from 'modules/calendar';
import { saga as prospectSaga } from 'modules/prospect';
import { saga as inProductMarketingSaga } from 'modules/inProductMarketing';
import { saga as aiAssistantSaga } from 'modules/aiAssistant';
import { saga as communitySaga } from 'modules/community';
import { saga as acaReportingSaga } from 'modules/acaReporting';
import { saga as webhookSaga } from 'modules/webhook';

/**
 * Define the root saga
 *
 * @method rootSaga
 * @param  {Object} axiosInstance The axios instance to process AJAX requests
 */
function* rootSaga(axiosInstance) {
    yield all([
        authSaga(),
        bootstrapSaga(),
        brandingSaga(),
        meetingsSaga(),
        pendoSaga(),
        permissionsSaga(),
        reportingSaga(),
        uiSaga(),
        userSaga(),
        adminSaga(),
        contentfulSaga(),
        handbooksSaga(),
        esigDocumentsSaga(),
        toolSaga(),
        notificationsSaga(),
        companyPoliciesSaga(),
        hrFitnessTestSaga(),
        surveysSaga(),
        ticketsSaga(),
        threadSaga(),
        toDoListSaga(),
        fetchResourceByTypeSaga(),
        calendarSaga(),
        prospectSaga(),
        inProductMarketingSaga(),
        aiAssistantSaga(),
        communitySaga(),
        acaReportingSaga(),
        webhookSaga(),
    ]);
}

export default rootSaga;
