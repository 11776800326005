import axios from 'axios';

/**
 * API call to send email
 *
 * @method sendMail
 * @param  {Object}             payload
 * @return {Promise}
 */
export const sendMail = payload =>
    axios({
        method: 'post',
        url: `/v2/send-email`,
        data: payload,
    });

/**
 * API to fetch location
 *
 * @method fetchLocationAPI
 * @param  {Object}  companyId
 * @return {Promise}
 */
export const fetchImportedContactsListAPI = companyId => {
    return axios({
        method: 'get',
        url: `v2/companies/import/contacts/tags/${companyId}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to fetch Welcome email preview
 *
 * @method previewWelcomeEmailAPI
 * @param  {number|string}  partnerId
 * @param  {number|string}  userId
 * @return {Promise}
 */
export const previewWelcomeEmailAPI = (partnerId, userId) => {
    return axios({
        method: 'get',
        params: {
            userId,
        },
        url: `/v2/partners/${partnerId}/email-templates?type=welcome`,
    });
};

/**
 * API to send welcome email to user
 *
 * @method sendWelcomeEmailAPI
 * @param  {Object}  userInfo
 * @return {Promise}
 */
export const sendWelcomeEmailAPI = userInfo => {
    const { additionalInfo, companyId, partnerId, userIds, type } = userInfo;
    return axios({
        method: 'post',
        url: `/v2/users/send-email`,
        params: {
            partnerId,
        },
        data: {
            partnerId,
            additionalInfo,
            companyId,
            userIds,
            type,
        },
    });
};

/**
 * API to get welcome email intro text.
 *
 * @method fetchWelcomeEmailIntroAPI
 * @param {number}        partnerId
 * @param {boolean}       isDefault
 * @param {null}       editor
 * @param {number}        userId
 * @param {number|null}        userRoleId
 * @return {Promise}
 */
export const fetchWelcomeEmailIntroAPI = ({
    partnerId,
    isDefault = false,
    userId,
    userRoleId = null,
    editor = null,
}) => {
    return axios({
        method: 'get',
        url: `v2/partners/${partnerId}/email-templates`,
        params: {
            type: 'welcome',
            isDefault,
            userId,
            editor,
            userRoleId,
        },
    });
};

/**
 * API to update welcome email.
 *
 * @method updateWelcomeEmailAPI
 * @param {number}        partnerId
 * @param {Object}       data
 * @return {Promise}
 */
export const updateWelcomeEmailAPI = ({ partnerId, data }) => {
    return axios({
        method: 'patch',
        url: `v2/partners/${partnerId}/email-templates`,
        params: {
            type: 'welcome',
        },
        data,
    });
};

/**
 * API to send email
 *
 * @method sendWelcomeEmailAPI
 * @param  {number|string}  partnerId
 * @param  {Object}         data
 * @return {Promise}
 */
export const sendEmailAPI = (partnerId, data) => {
    return axios({
        method: 'post',
        url: `/v2/users/send-email`,
        params: {
            partnerId,
        },
        data,
    });
};

/**
 * API to fetch Email Preview
 *
 * @method  fetchEmailPreviewAPI
 * @param {string}    entryId
 * @param {string}    partnerId
 * @param {string}    userId
 * @return {Promise}
 */
export const fetchEmailPreviewAPI = ({ entryId, partnerId, userId }) => {
    return axios({
        method: 'get',
        params: {
            entryId,
            userId,
        },
        url: `/v2/partners/${partnerId}/email-templates`,
    });
};

/**
 * API to fetch unpublished content Preview
 *
 * @method  fetchUnpublishedContentPreviewAPI
 * @param {string}    entryId
 * @param {string}    partnerId
 * @param {string}    userId
 * @return {Promise}
 */
export const fetchUnpublishedContentPreviewAPI = ({ entryId, partnerId, userId }) => {
    return axios({
        method: 'get',
        params: {
            entryId,
            userId,
        },
        url: `/v2/partners/${partnerId}/email-templates/preview`,
    });
};
