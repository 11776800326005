import { createActions } from 'utils/actions';

/**
 * Redux actions for bulk upload prospects.
 *
 * @type {Object}
 */
export const bulkUploadProspects = createActions('prospect', 'BULK_UPLOAD_PROSPECTS', {
    asPromise: true,
});

/**
 * Redux actions for create prospect.
 *
 * @type {Object}
 */
export const createProspect = createActions('prospect', 'CREATE_PROSPECT', {
    asPromise: true,
});

/**
 * Redux actions for fetch prospect by id.
 *
 * @type {Object}
 */
export const fetchProspectById = createActions('prospect', 'FETCH_PROSPECT_BY_ID', {
    asPromise: true,
});

/**
 * Redux actions for fetch prospect by id.
 *
 * @type {Object}
 */
export const fetchProspectsByPartner = createActions('prospect', 'FETCH_PROSPECTS_BY_PARTNER', {
    asPromise: true,
});

/**
 * Redux actions for update prospect.
 *
 * @type {Object}
 */
export const updateProspect = createActions('prospect', 'UPDATE_PROSPECT', {
    asPromise: true,
});

/**
 * Redux actions for delete prospect.
 *
 * @type {Object}
 */
export const deleteProspect = createActions('prospect', 'DELETE_PROSPECT', {
    asPromise: true,
});

/**
 * Redux actions for create Questionnaire for prospect.
 *
 * @type {Object}
 */
export const createQuestionnaire = createActions('prospect', 'CREATE_QUESTIONNAIRE', {
    asPromise: true,
});

/**
 * Redux actions to bulk delete prospect.
 *
 * @type {Object}
 */
export const deleteBulkProspect = createActions('prospect', 'DELETE_BULK_PROSPECT', {
    asPromise: true,
});

/**
 * Redux actions to bulk enroll prospect.
 *
 * @type {Object}
 */
export const enrollBulkProspect = createActions('prospect', 'BULK_ENROLL_PROSPECT', {
    asPromise: true,
});

/**
 * Redux actions to email prospect report
 *
 * @type {Object}
 */
export const emailProspectReport = createActions('prospect', 'EMAIL_PROSPECT_REPORT', {
    asPromise: true,
});

/**
 * Redux actions to activate a prospect
 *
 * @type {Object}
 */
export const activateProspect = createActions('prospect', 'ACTIVATE_PROSPECT', {
    asPromise: true,
});

/**
 * Redux actions to deactivate a prospect
 *
 * @type {Object}
 */
export const deactivateProspect = createActions('prospect', 'DEACTIVATE_PROSPECT', {
    asPromise: true,
});

/**
 * Redux actions to download sample csv for bulk import prospect
 *
 * @type {Object}
 */
export const downloadSampleForBulkProspect = createActions(
    'prospect',
    'DOWNLOAD_SAMPLE_BULK_PROSPECT',
    {
        asPromise: true,
    }
);
