import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ExitFullscreenIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8,2C7.5,2,7,2.5,7,3v2.6L3.7,2.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L5.6,7H3C2.5,7,2,7.5,2,8s0.5,1,1,1h5
                c0.6,0,1-0.4,1-1V3C9,2.5,8.6,2,8,2z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22,8c0-0.5-0.5-1-1-1h-2.6l3.3-3.3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L17,5.6V3c0-0.5-0.5-1-1-1
                c-0.6,0-1,0.5-1,1v5c0,0.6,0.5,1,1,1h5C21.6,9,22,8.6,22,8z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3,17c-0.5,0-1-0.5-1-1c0-0.6,0.5-1,1-1h5c0.6,0,1,0.5,1,1v5c0,0.5-0.4,1-1,1s-1-0.5-1-1v-2.6l-3.3,3.3
                c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4L5.6,17H3z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16,22c0.5,0,1-0.5,1-1v-2.6l3.3,3.3c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L18.4,17H21c0.5,0,1-0.5,1-1
                c0-0.6-0.5-1-1-1h-5c-0.6,0-1,0.5-1,1v5C15,21.6,15.5,22,16,22z"
            />
        </SvgIcon>
    );
};

ExitFullscreenIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ExitFullscreenIcon.defaultProps = {
    fill: DEFAULT_COLORS.CATEGORY_LEAVES_ACCOMMODATIONS,
    height: '24px',
    viewBox: '0 0 24 24',
    width: '24px',
};

export default ExitFullscreenIcon;
