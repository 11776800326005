import axios from 'axios';

/**
 * Fetch community url
 *
 * @method fetchCommunityUrlAPI
 * @param  {string}             hash
 * @return {Promise}
 */
export const fetchCommunityUrlAPI = hash => {
    return axios({
        method: 'post',
        type: 'auth',
        url: `/jwt/sso?hash=${hash}`,
    });
};
