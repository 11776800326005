import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const EnrollIcon = ({ mailFill, height, axisFill, viewBox, width, ...rest }) => (
    <SvgIcon height={height} width={width} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g>
                <path
                    fill={axisFill}
                    fillRule="nonzero"
                    d="M5.424 12.411l.272.584-5.424 2.53L0 14.94l5.424-2.529zM14.806 0c.092.2.155.335.19.408l3.852 8.067.197.416-.392.201-10.566 5.502-.37.198c-.115-.206-.188-.341-.22-.407L3.644 6.318l-.196-.406c.191-.105.322-.175.391-.211L14.406.199c.07-.036.203-.102.4-.199zM4.656 10.764l.272.584-3.91 1.823-.273-.584 3.91-1.823zm-.764-1.65l.273.584-2.397 1.118-.272-.584 2.396-1.118z"
                    transform="translate(0 -1) translate(.349 1.74)"
                />
                <path
                    fill={mailFill}
                    d="M13.483 6.826l3.923 1.884-8.86 4.614.918-4.405 2.693 1.154c.063.027.13.038.196.033.057-.004.113-.02.165-.047.113-.058.196-.165.228-.293l.737-2.94zM4.94 6.98l3.698 1.585-.894 4.285L4.94 6.98zm8.973-5.492l-1.89 7.534-6.901-2.957 8.79-4.577zm.81.4l2.802 5.868-3.815-1.832 1.012-4.037z"
                    transform="translate(0 -1) translate(.349 1.74)"
                />
            </g>
        </g>
    </SvgIcon>
);

EnrollIcon.propTypes = {
    mailFill: string,
    height: string,
    axisFill: string,
    viewBox: string,
    width: string,
};

EnrollIcon.defaultProps = {
    mailFill: DEFAULT_COLORS.YELLOW,
    height: '20',
    axisFill: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 20 20',
    width: '20',
};

export default EnrollIcon;
