import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { formatNumberWithDelimiter } from 'ravenjs/utils/number';
import { themeGet } from 'ravenjs/utils/theme';

const PaginationTotalSelectedStyled = styled(Row)`
    /**
     * Add all of the remaining styles from theme
     */
    ${themeGet('PaginationTotalRows.styles')};
`;

function PaginationTotalSelected(props) {
    const { count } = props;

    return (
        <PaginationTotalSelectedStyled
            margin="0"
            alignItems="center"
            gutter={false}
            justify="flex-start"
        >
            <Typography gutterRight="12px" gutterLeft="12px">
                ...
            </Typography>
            <Typography
                color="text"
                fontSize="0.75rem"
                fontFamily="RobotoMono"
                gutterBottom="0"
                gutterRight="4px"
                gutterTop="0"
                type="paginationTotalSelected"
            >
                {formatNumberWithDelimiter(count)} SELECTED
            </Typography>
        </PaginationTotalSelectedStyled>
    );
}

PaginationTotalSelected.propTypes = {
    /**
     * The number of total selected items.
     */
    count: PropTypes.number,
};

PaginationTotalSelected.defaultProps = {
    count: 0,
};

export default PaginationTotalSelected;
