import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

const SubNavItemsStyled = styled.ul`
    align-items: stretch;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: ${props => props.flexWrap || 'nowrap'};
    ${getThemeProps('SubNavItems.styles')};
`;

export default SubNavItemsStyled;
