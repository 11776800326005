import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ClockIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                fill={fill}
            />
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ClockIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ClockIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default ClockIcon;
