import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SvgIcon, { CloseIcon } from 'ravenjs/lib/SvgIcon';
import { renderCheck } from 'ravenjs/utils/viewport';
import { COLORS } from 'ravenjs/constants/colors';

const CloseIconStyled = styled(CloseIcon)`
    float: right;
    margin-left: 0.5rem;
    mix-blend-mode: difference;
    height: 12px;
    width: 12px;
`;

const ContentBody = styled.div`
    background-color: ${COLORS.BLACK};
    color: ${COLORS.WHITE};
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    line-height: ${({ lineHeight }) => lineHeight || '1rem'};
    min-height: 1.814rem;
    font-size: ${({ fontSize }) => fontSize};
    object-fit: contain;
    padding: 15px;
    text-align: ${({ textAlign }) => textAlign};
    width: fit-content;
    max-width: 320px;
`;

const ContentPointer = styled.div`
    display: flex;
    width: 23px;
    margin: auto;
`;

const ContentStyled = styled.div`
    width: auto;
`;

const TooltipContent = ({ onClickCloseIcon, pointerStyle, showCloseIcon, text, bodyStyle }) => {
    const renderCloseIcon = () => {
        return (
            showCloseIcon &&
            renderCheck('md', 'less') && <CloseIconStyled onClick={onClickCloseIcon} />
        );
    };

    return (
        <ContentStyled>
            <ContentBody
                textAlign={bodyStyle.textAlign}
                lineHeight={bodyStyle.lineHeight}
                fontSize={bodyStyle.fontSize}
            >
                {renderCloseIcon()}
                {text}
            </ContentBody>
            <ContentPointer style={pointerStyle}>
                <SvgIcon width="23" height="12" viewBox="0 0 23 12">
                    <defs>
                        <filter
                            id="prefix__a"
                            width="141.3%"
                            height="200%"
                            x="-20.6%"
                            y="-25%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161631337 0"
                            />
                        </filter>
                        <path id="prefix__b" d="M34.814 29.024L44.353 37.024 54.186 29.024z" />
                    </defs>
                    <g fill="none" fillRule="evenodd" transform="translate(-33 -29)">
                        <use fill={COLORS.BLACK} filter="url(#prefix__a)" xlinkHref="#prefix__b" />
                        <use fill={COLORS.BLACK} xlinkHref="#prefix__b" />
                    </g>
                </SvgIcon>
            </ContentPointer>
        </ContentStyled>
    );
};

TooltipContent.propTypes = {
    bodyStyle: PropTypes.shape({
        textAlign: PropTypes.string,
        lineHeight: PropTypes.string,
        fontSize: PropTypes.string,
    }),
    onClickCloseIcon: PropTypes.func,
    pointerStyle: PropTypes.object,
    showCloseIcon: PropTypes.bool,
    text: PropTypes.string,
};

TooltipContent.defaultProps = {
    bodyStyle: {
        textAlign: 'center',
        lineHeight: '1rem',
        fontSize: '0.75rem',
    },
    onClickCloseIcon: () => {},
    pointerStyle: {},
    showCloseIcon: true,
    text: null,
};

export default TooltipContent;
