import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const EditIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <g clipPath="url(#clip0_2260_39954)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.72622 6.28307C4.40443 5.60486 5.32428 5.22384 6.28341 5.22384H14.7217C15.3875 5.22384 15.9272 5.76355 15.9272 6.42931C15.9272 7.09508 15.3875 7.63479 14.7217 7.63479H6.28341C5.9637 7.63479 5.65708 7.76179 5.43101 7.98786C5.20494 8.21393 5.07794 8.52055 5.07794 8.84026V25.7169C5.07794 26.0366 5.20494 26.3432 5.43101 26.5693C5.65708 26.7953 5.9637 26.9223 6.28341 26.9223H23.16C23.4798 26.9223 23.7864 26.7953 24.0124 26.5693C24.2385 26.3432 24.3655 26.0366 24.3655 25.7169V17.2786C24.3655 16.6128 24.9052 16.0731 25.571 16.0731C26.2368 16.0731 26.7765 16.6128 26.7765 17.2786V25.7169C26.7765 26.676 26.3955 27.5959 25.7172 28.2741C25.039 28.9523 24.1192 29.3333 23.16 29.3333H6.28341C5.32428 29.3333 4.40443 28.9523 3.72622 28.2741C3.04801 27.5959 2.66699 26.676 2.66699 25.7169V8.84026C2.66699 7.88113 3.04801 6.96128 3.72622 6.28307Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.571 5.07757C25.2125 5.07757 24.8687 5.21998 24.6152 5.47348L13.3993 16.6894L12.7621 19.2382L15.3109 18.601L26.5268 7.3851C26.7803 7.13161 26.9227 6.78779 26.9227 6.42929C26.9227 6.07079 26.7803 5.72698 26.5268 5.47348C26.2733 5.21998 25.9295 5.07757 25.571 5.07757ZM22.9104 3.76869C23.616 3.06305 24.5731 2.66663 25.571 2.66663C26.5689 2.66663 27.526 3.06305 28.2316 3.76869C28.9372 4.47432 29.3337 5.43137 29.3337 6.42929C29.3337 7.42721 28.9372 8.38426 28.2316 9.0899L16.7796 20.5419C16.6251 20.6964 16.4315 20.806 16.2196 20.859L11.3977 22.0644C10.9869 22.1671 10.5523 22.0468 10.2529 21.7474C9.95349 21.4479 9.83313 21.0134 9.93582 20.6026L11.1413 15.7807C11.1943 15.5687 11.3039 15.3752 11.4584 15.2207L22.9104 3.76869Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_2260_39954">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

EditIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

EditIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '32',
    viewBox: '0 0 32 32',
    width: '32',
};

export default EditIcon;
