import _get from 'lodash/get';
import { call, put, select } from 'redux-saga/effects';

import * as userSelectors from 'modules/user/reducer';
import { buildPermissions } from 'utils/permissions';
import { fetchUserPermissions } from 'modules/apis';

import * as userActions from '../actions';

/**
 * Fetch the currently logged in user's permissions.
 *
 * @method fetchPermissionsSaga
 * @type   {Generator}
 * @param  {Object}             action The redux action
 * @return {Array|Error}
 */
export function* fetchPermissionsSaga(action) {
    try {
        // Make the call to fetch the user's permissions.
        const resp = yield call(fetchUserPermissions, {
            companySkuFilter: true,
            selfPermissions: true,
        });
        // Extract the permissions from the valid response.
        const permissions = _get(resp, 'data.permissions', []);
        // Dispatch the `fetchPermissions` SUCCESS action.
        yield put(userActions.fetchPermissions.success(permissions));
        // Return the permissions just in case.
        return permissions;
    } catch (error) {
        // Otherwise catch the error and dispatch the `fetchPermissions` ERROR action.
        yield put(userActions.fetchPermissions.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Build the user permissions list.
 *
 * @method buildUserPermissionsSaga
 * @type   {Generator}
 * @return {Array|Error}
 */
export function* buildUserPermissionsSaga() {
    try {
        // select current permissions from the store
        const loggedInUserPermissions = yield select(userSelectors.getUserPermissionsOriginal);
        // build the permissions and sort them for better readability
        const userPermissions = buildPermissions(loggedInUserPermissions).sort();
        // Dispatch the `buildPermissions` SUCCESS action.
        yield put(userActions.buildPermissions.success(userPermissions));
        // Return the permissions just in case.
        return userPermissions;
    } catch (error) {
        // Otherwise catch the error and dispatch the `buildPermissions` ERROR action.
        yield put(userActions.buildPermissions.error(error));
        // Return the error.
        return error;
    }
}
