/**
 * Format number to currency format.
 *
 * @method formatCurrency
 * @param  {string}          number Number to format
 * @return {string}          Number formatted
 */
export function formatCurrency(number) {
    return number
        ? parseFloat(number)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        : '0.00';
}
