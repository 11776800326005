import React from 'react';
import PropTypes from 'prop-types';

let multipleParaHighLightBlockKey = null;
const decoratedblock = [];

export const HighLightStrategy = (contentBlock, callback, contentState) => {
    const text = contentBlock.getText();
    const key = contentBlock.getKey();
    const regexSingleParagraph = /(\[\[)(.*?)(\]\])/g;
    const regexStartConditionParagraph = /(\[\[)(.*)/g;
    const regexMatchAllCharacters = /(.*)/g;
    const regexEndConditionParagraph = /(.*?)(\]\])/g;
    if (text.match(regexSingleParagraph)) {
        multipleParaHighLightBlockKey = null;
        return findWithRegex(
            regexSingleParagraph,
            text,
            contentBlock,
            callback,
            contentState,
            2,
            2
        );
    } else if (
        (multipleParaHighLightBlockKey !== null && !text.match(regexEndConditionParagraph)) ||
        decoratedblock.includes(key)
    ) {
        decoratedblock.push(contentBlock.getKey());
        return findWithRegex(
            regexMatchAllCharacters,
            text,
            contentBlock,
            callback,
            contentState,
            0,
            0
        );
    } else if (text.match(regexStartConditionParagraph)) {
        multipleParaHighLightBlockKey = key;
        return findWithRegex(
            regexStartConditionParagraph,
            text,
            contentBlock,
            callback,
            contentState,
            2,
            0
        );
    } else if (text.match(regexEndConditionParagraph)) {
        multipleParaHighLightBlockKey = null;
        return findWithRegex(
            regexEndConditionParagraph,
            text,
            contentBlock,
            callback,
            contentState,
            0,
            2
        );
    }
    return null;
};

const findWithRegex = (regex, text, contentBlock, callback, contentState, startIndex, endIndex) => {
    let start;
    let matchArr;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        if (matchArr[0].length !== 0) {
            callback(start + startIndex, start + matchArr[0].length - endIndex);
        }
        if (matchArr[0].length === 0) {
            break;
        }
    }
};

export const HighLight = props => {
    const { children } = props;
    return (
        <span id="highLightedText" className="highlight-text">
            {children}
        </span>
    );
};

HighLight.propTypes = {
    children: PropTypes.array,
};

HighLight.defaultProps = {
    children: null,
};

const HighLightPlugin = {
    strategy: HighLightStrategy,
    component: HighLight,
};

export default HighLightPlugin;
