import { array, object, bool } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { get } from 'ravenjs/utils/lodash';

import CategoryLabels from 'components/CategoryLabels';
import { DEFAULT_COLORS } from 'constants/colors';
import { CONTENT_TYPES_MAPPED } from 'constants/content';
import { selectors as userSelectors } from 'modules/user';
import {
    getContentNameByContentType,
    getIconByDocumentType,
    getInfoDocumentType,
} from 'utils/content';
import { formatCategoriesAndSubcategories } from 'utils/documents';
import { sanitizeMarkup } from 'utils/sanitize';

const TypeAheadSuggestionStyled = styled.div`
    background-color: ${DEFAULT_COLORS.WHITE};
    border-bottom: 1px solid ${DEFAULT_COLORS.LIGHT};
    cursor: pointer;
    font-size: 0.95rem;
    overflow: hidden;
    padding: 0.75rem 1rem;
    width: 100%;
    &.active {
        background-color: ${DEFAULT_COLORS.LIGHTEST};
    }
`;

const PreviewTextStyled = styled.div`
    & strong {
        color: ${DEFAULT_COLORS.TAN};
    }
    margin-bottom: 0.4rem;
`;

const ContentTypeContainerStyled = styled.div`
    display: flex;
`;

const ContentTypeStyled = styled.div`
    color: ${DEFAULT_COLORS.BLACK};
    margin-left: 0.5rem;
    font-family: RobotoMono;
    font-weight: bold;
    font-size: 0.8rem;
`;

const TitleStyled = styled.div`
    color: ${DEFAULT_COLORS.DARK};
    font-size: 0.7rem;
    margin-top: 0.15rem;
`;

const TypeAheadSuggestion = ({ contentTypes, suggestion, isHighlighted, isHelpCenter }) => {
    const { description, highlights = [], categories = [] } = suggestion;
    const { previewText } = highlights.length ? highlights[0] : { previewText: '' };
    const content = isHelpCenter ? description : previewText;
    const sanitizedPreviewTextElement = (
        <PreviewTextStyled dangerouslySetInnerHTML={sanitizeMarkup(content)} />
    );

    const getStyledContentType = () => {
        const { contentType, documentType } = suggestion;
        const infoDocumentType = getInfoDocumentType(contentTypes, contentType, documentType);
        const currentContentType =
            suggestion.contentType === CONTENT_TYPES_MAPPED.DOCUMENT ? documentType : contentType;

        return (
            getContentNameByContentType(currentContentType) ||
            get(infoDocumentType, 'documentTypeLabel', '')
        );
    };
    const parentCategories = formatCategoriesAndSubcategories(categories);

    return (
        <TypeAheadSuggestionStyled className={isHighlighted ? 'active' : ''}>
            {sanitizedPreviewTextElement}
            <ContentTypeContainerStyled>
                <div>
                    {getIconByDocumentType(suggestion.documentType || suggestion.contentType, {
                        height: '13',
                        width: '13',
                    })}
                </div>
                <ContentTypeStyled>{getStyledContentType()}</ContentTypeStyled>
            </ContentTypeContainerStyled>
            {parentCategories && <CategoryLabels categories={parentCategories} />}
            <TitleStyled>{isHelpCenter ? suggestion.name : suggestion.title}</TitleStyled>
        </TypeAheadSuggestionStyled>
    );
};

TypeAheadSuggestion.defaultProps = {
    isHelpCenter: false,
};

TypeAheadSuggestion.propTypes = {
    contentTypes: array.isRequired,
    suggestion: object.isRequired,
    isHighlighted: bool.isRequired,
    isHelpCenter: bool,
};

const mapStateToProps = createStructuredSelector({
    contentTypes: userSelectors.getContentTypes,
});

export default connect(mapStateToProps, null)(TypeAheadSuggestion);
