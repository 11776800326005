import { func, string, object } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Typography from 'ravenjs/lib/Typography';
import { getThemeProps } from 'ravenjs/utils/theme';

import { sanitizeMarkup } from 'utils/sanitize';

const SubNavTitleStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: auto;

    ${({ theme }) => theme.media.up('md')`
        max-width: 70%;
        padding: 0.25rem;
    `};
    ${({ theme }) => theme.media.down('md')`
        max-width: auto;
        padding: 0 0 0 0.25rem;
    `};

    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('SubNavTitle.styles')};
`;

const TitleStyled = styled(Typography)`
    && {
        font-family: Favorit;
        font-size: 1.5rem;
        margin-right: 0.6rem;
    }

    ${({ theme }) => theme.media.down('md')`
        overflow: hidden;
        word-break: break-word;
    `};

    ${({ theme }) => theme.media.up('md')`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `};
`;

const TitleWrapperStyled = styled.div`
    align-items: baseline;
    display: flex;
    width: 100%;
`;

const SubNavTitle = ({ caption, subtitle, title, titleStyle, onTitleClick }) => {
    return (
        <SubNavTitleStyled>
            {subtitle && (
                <Typography
                    dangerouslySetInnerHTML={sanitizeMarkup(subtitle)}
                    gutterBottom="0"
                    gutterTop="0"
                />
            )}

            <TitleWrapperStyled>
                {title && (
                    <TitleStyled
                        title={title}
                        dangerouslySetInnerHTML={sanitizeMarkup(title)}
                        gutterBottom="0"
                        gutterTop="0"
                        type="headline"
                        style={titleStyle}
                        onClick={onTitleClick}
                    />
                )}

                {caption && (
                    <Typography
                        type="subNavCaption"
                        dangerouslySetInnerHTML={sanitizeMarkup(caption)}
                        gutterBottom="0"
                        gutterTop="0"
                    />
                )}
            </TitleWrapperStyled>
        </SubNavTitleStyled>
    );
};

SubNavTitle.propTypes = {
    /**
     * The caption rendered next to the title.
     */
    caption: string,
    /**
     * The sub-title rendered above the title.
     */
    subtitle: string,
    /**
     * The main title for the sub-navigation.
     */
    title: string,
    /**
     * The custom title style for the sub-navigation.
     */
    titleStyle: object,
    /**
     * The title click for the sub-navigation.
     */
    onTitleClick: func,
};

SubNavTitle.defaultProps = {
    caption: null,
    subtitle: null,
    title: null,
    titleStyle: null,
    onTitleClick: null,
};

export default SubNavTitle;
