import { takeLatest } from 'redux-saga/effects';

import * as reportingActions from '../actions';
import { deleteBrokenLinkSaga, fetchBrokenLinksSaga } from './brokenLinks';
import {
    createEstablishmentSaga,
    fetchCasesSaga,
    fetchCitiesSaga,
    fetchEstablishmentsSaga,
    fetchEstablishmentTypeSaga,
    fetchEmployeeNamesSaga,
    fetchIllnessTypesSaga,
    fetchNaicsSaga,
    fetchWhereOccurredSaga,
    fetchEstablishmentByIdSaga,
    fetchFormEstablishmentsSaga,
    updateEstablishmentSaga,
    deleteEstablishmentByIdSaga,
    fetchBodyPartsSaga,
    getCaseNumberSaga,
    fetchCaseTypesSaga,
    createCaseSaga,
    fetchCaseByIdSaga,
    updateCaseSaga,
    deleteCaseByIdSaga,
    downloadForm300Saga,
    downloadForm300ASaga,
    downloadFormReportSaga,
} from './osha';
import {
    downloadActiveClientsUsersReportsSaga,
    fetchAdministrationReportsSaga,
    fetchEcAggregateReportSaga,
    fetchPvrPartnersSaga,
    fetchCalculationValuesSaga,
    updateCalculationValuesSaga,
    fetchClientUsageSaga,
    fetchPartnerValueReportSaga,
    fetchTotalClientsByPartnerSaga,
    fetchCvrClientsSaga,
    fetchClientValueReportSaga,
    fetchClientValueReportTemplateSaga,
    fetchReportingSnapshotByPartnerSaga,
    fetchReportingSnapshotRequestSaga,
    fetchReportingSnapshotRefreshSaga,
    runEmailEngagementSaga,
    fetchClientSolutionReportSaga,
    runClientSolutionReportSaga,
    runAdministrationReportsSaga,
} from './reports';

/**
 * Root saga for `reporting`
 * Triggers sagas related to all reporting events
 *
 * @method reportingSaga
 * @type   {Generator}
 */
function* reportingSaga() {
    yield takeLatest(reportingActions.deleteBrokenLink.TRIGGER, deleteBrokenLinkSaga);
    yield takeLatest(reportingActions.fetchBrokenLinks.TRIGGER, fetchBrokenLinksSaga);
    yield takeLatest(reportingActions.fetchEcAggregateReport.TRIGGER, fetchEcAggregateReportSaga);
    yield takeLatest(reportingActions.fetchEstablishments.TRIGGER, fetchEstablishmentsSaga);
    yield takeLatest(reportingActions.fetchEstablishmentType.TRIGGER, fetchEstablishmentTypeSaga);
    yield takeLatest(reportingActions.fetchCases.TRIGGER, fetchCasesSaga);
    yield takeLatest(reportingActions.fetchCities.TRIGGER, fetchCitiesSaga);
    yield takeLatest(reportingActions.fetchEmployeeNames.TRIGGER, fetchEmployeeNamesSaga);
    yield takeLatest(reportingActions.fetchIllnessTypes.TRIGGER, fetchIllnessTypesSaga);
    yield takeLatest(reportingActions.fetchNaics.TRIGGER, fetchNaicsSaga);
    yield takeLatest(reportingActions.fetchWhereOccurred.TRIGGER, fetchWhereOccurredSaga);
    yield takeLatest(reportingActions.createEstablishment.TRIGGER, createEstablishmentSaga);
    yield takeLatest(reportingActions.fetchEstablishmentById.TRIGGER, fetchEstablishmentByIdSaga);
    yield takeLatest(reportingActions.updateEstablishment.TRIGGER, updateEstablishmentSaga);
    yield takeLatest(reportingActions.deleteEstablishmentById.TRIGGER, deleteEstablishmentByIdSaga);
    yield takeLatest(
        reportingActions.fetchAdministrationReports.TRIGGER,
        fetchAdministrationReportsSaga
    );
    yield takeLatest(
        reportingActions.downloadActiveClientsUsersReports.TRIGGER,
        downloadActiveClientsUsersReportsSaga
    );
    yield takeLatest(reportingActions.fetchBodyParts.TRIGGER, fetchBodyPartsSaga);
    yield takeLatest(reportingActions.getCaseNumber.TRIGGER, getCaseNumberSaga);
    yield takeLatest(reportingActions.fetchCaseTypes.TRIGGER, fetchCaseTypesSaga);
    yield takeLatest(reportingActions.createCase.TRIGGER, createCaseSaga);
    yield takeLatest(reportingActions.fetchCaseById.TRIGGER, fetchCaseByIdSaga);
    yield takeLatest(reportingActions.updateCase.TRIGGER, updateCaseSaga);
    yield takeLatest(reportingActions.deleteCaseById.TRIGGER, deleteCaseByIdSaga);
    yield takeLatest(reportingActions.fetchPvrPartners.TRIGGER, fetchPvrPartnersSaga);
    yield takeLatest(reportingActions.fetchCalculationValues.TRIGGER, fetchCalculationValuesSaga);
    yield takeLatest(reportingActions.updateCalculationValues.TRIGGER, updateCalculationValuesSaga);
    yield takeLatest(reportingActions.fetchClientUsage.TRIGGER, fetchClientUsageSaga);
    yield takeLatest(reportingActions.fetchPartnerValueReport.TRIGGER, fetchPartnerValueReportSaga);
    yield takeLatest(
        reportingActions.fetchTotalClientsByPartner.TRIGGER,
        fetchTotalClientsByPartnerSaga
    );
    yield takeLatest(reportingActions.fetchCvrClients.TRIGGER, fetchCvrClientsSaga);
    yield takeLatest(reportingActions.fetchClientValueReport.TRIGGER, fetchClientValueReportSaga);
    yield takeLatest(
        reportingActions.fetchClientSolutionReport.TRIGGER,
        fetchClientSolutionReportSaga
    );
    yield takeLatest(reportingActions.runClientSolutionReport.TRIGGER, runClientSolutionReportSaga);
    yield takeLatest(
        reportingActions.fetchClientValueReportTemplate.TRIGGER,
        fetchClientValueReportTemplateSaga
    );
    yield takeLatest(reportingActions.fetchFormEstablishments.TRIGGER, fetchFormEstablishmentsSaga);
    yield takeLatest(reportingActions.downloadForm300.TRIGGER, downloadForm300Saga);
    yield takeLatest(reportingActions.downloadForm300A.TRIGGER, downloadForm300ASaga);
    yield takeLatest(reportingActions.downloadFormReport.TRIGGER, downloadFormReportSaga);
    yield takeLatest(
        reportingActions.fetchReportingSnapshotByPartner.TRIGGER,
        fetchReportingSnapshotByPartnerSaga
    );
    yield takeLatest(
        reportingActions.fetchReportingSnapshotRequest.TRIGGER,
        fetchReportingSnapshotRequestSaga
    );
    yield takeLatest(
        reportingActions.fetchReportingSnapshotRefresh.TRIGGER,
        fetchReportingSnapshotRefreshSaga
    );
    yield takeLatest(reportingActions.runEmailEngagement.TRIGGER, runEmailEngagementSaga);
    yield takeLatest(
        reportingActions.runAdministrationReports.TRIGGER,
        runAdministrationReportsSaga
    );
}

export default reportingSaga;

export {
    deleteBrokenLinkSaga,
    fetchBrokenLinksSaga,
    fetchCasesSaga,
    fetchCitiesSaga,
    fetchEcAggregateReportSaga,
    fetchEstablishmentsSaga,
    fetchEstablishmentTypeSaga,
    fetchEmployeeNamesSaga,
    fetchIllnessTypesSaga,
    fetchNaicsSaga,
    fetchWhereOccurredSaga,
    createEstablishmentSaga,
    fetchEstablishmentByIdSaga,
    updateEstablishmentSaga,
    deleteEstablishmentByIdSaga,
    fetchAdministrationReportsSaga,
    downloadActiveClientsUsersReportsSaga,
    fetchBodyPartsSaga,
    getCaseNumberSaga,
    fetchCaseTypesSaga,
    createCaseSaga,
    fetchCaseByIdSaga,
    updateCaseSaga,
    deleteCaseByIdSaga,
    fetchPvrPartnersSaga,
    fetchCalculationValuesSaga,
    updateCalculationValuesSaga,
    fetchClientUsageSaga,
    fetchPartnerValueReportSaga,
    fetchTotalClientsByPartnerSaga,
    fetchCvrClientsSaga,
    fetchClientValueReportSaga,
    fetchClientValueReportTemplateSaga,
    fetchFormEstablishmentsSaga,
    downloadForm300Saga,
    downloadForm300ASaga,
    downloadFormReportSaga,
    fetchReportingSnapshotByPartnerSaga,
    fetchReportingSnapshotRequestSaga,
    fetchReportingSnapshotRefreshSaga,
    runEmailEngagementSaga,
    runClientSolutionReportSaga,
    runAdministrationReportsSaga,
};
