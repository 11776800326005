import { createActions } from 'utils/actions';

/**
 * Redux actions for create questionnaire.
 *
 * @type {Object}
 */
export const createQuestionnaire = createActions('hrFitnessTest', 'CREATE_QUESTIONNAIRE', {
    asPromise: true,
});

/**
 * Redux actions for fetching questionnaire.
 *
 * @type {Object}
 */
export const fetchQuestionnaire = createActions('hrFitnessTest', 'FETCH_QUESTIONNAIRE', {
    asPromise: true,
});

/**
 * Redux actions for fetching questionnaires.
 *
 * @type {Object}
 */
export const fetchQuestionnaires = createActions('hrFitnessTest', 'FETCH_QUESTIONNAIRES', {
    asPromise: true,
});

/**
 * Redux actions for sending responses.
 *
 * @type {Object}
 */
export const sendResponses = createActions('hrFitnessTest', 'SEND_RESPONSES', {
    asPromise: true,
});

/**
 * Redux actions for fetching prospect questionnaire.
 *
 * @type {Object}
 */
export const fetchProspectQuestionnaire = createActions(
    'hrFitnessTest',
    'FETCH_PROSPECT_QUESTIONNAIRE',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for sending responses for prospect.
 *
 * @type {Object}
 */
export const prospectSendResponses = createActions('hrFitnessTest', 'PROSPECT_SEND_RESPONSES', {
    asPromise: true,
});
