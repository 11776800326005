import axios from 'axios';

/**
 * API to get documents based on thread ID
 *
 * @method fetchThreadDocuments
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchThreadDocuments = payload => {
    const { id, requestParams } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `/v2/thread/${id}/insights`,
        params,
    });
};

/**
 * API to get insights based on thread ID
 *
 * @method fetchInsightsDocuments
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchInsightsDocuments = payload => {
    const { id, requestParams } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `/v3/thread/${id}/insights`,
        params,
    });
};

/**
 * API to get preview of document based on entry ID
 *
 * @method fetchPreviewDocument
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchPreviewDocument = payload => {
    const { id, requestParams } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `v3/thread/${id}/prescription`,
        params,
    });
};

/**
 * API to get insights list for a company
 *
 * @method fetchInsightsList
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchInsightsList = payload => {
    const { requestParams, type, graph } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `/v3/insights/dashboard?type=${type}&&graph=${graph}`,
        params,
    });
};

/**
 * API to get insights preview data
 *
 * @method fetchInsightsPreviewDashboard
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchInsightsPreviewDashboard = payload => {
    const { requestParams } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `/v3/insights/companyDashboard`,
        params,
    });
};

/**
 * API to get insights graph data
 *
 * @method fetchInsightsGraphDashboard
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchInsightsGraphDashboard = payload => {
    const { graphType } = payload;
    return axios({
        method: 'get',
        url: `/v3/insights/companyDashboard/graph?graphType=${graphType}`,
    });
};

/**
 * API to update the new tag for insights
 *
 * @method updateInsightsNewTag
 * @param  {Object}  payload
 * @return {Promise}
 */
export const updateInsightsNewTag = payload => {
    return axios({
        method: 'post',
        url: `/v3/insights/read/status`,
        params: payload,
    });
};

/**
 * API to fetch All Recommendations
 *
 * @method fetchAllRecommendationsAPI
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchAllRecommendationsAPI = payload => {
    const { requestParams } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `/v2/insight/recommendations/homepage`,
        params,
    });
};

/**
 * API to fetch Recommendation by Id
 *
 * @method fetchRecommendationByIdAPI
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchRecommendationByIdAPI = payload => {
    const { requestParams } = payload;
    const {
        params: { id },
    } = requestParams;

    return axios({
        method: 'get',
        url: `/v2/insight/recommendations/${id}`,
    });
};

/**
 * API to fetch Recommendations by State
 *
 * @method fetchRecommendationsByStateAPI
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchRecommendationsByStateAPI = payload => {
    const { requestParams } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `/v2/insight/recommendations`,
        params,
    });
};

/**
 * API to fetch Recommendations Details
 *
 * @method fetchRecommendationDetailsAPI
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchRecommendationDetailsAPI = payload => {
    const { requestParams } = payload;
    const { params } = requestParams;

    return axios({
        method: 'get',
        url: `/v2/insight/recommendations/${params.id}`,
        params,
    });
};

/**
 * API to fetch Recommendations for compliance dashboard
 *
 * @method fetchRecommendation
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchRecommendation = payload => {
    const { params } = payload;

    return axios({
        method: 'get',
        url: `/v3/insights/compliancedashboard/recommendations/${params.id}`,
        params,
    });
};
