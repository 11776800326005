import PropTypes from 'prop-types';
import React from 'react';

import Select from 'ravenjs/lib/Select';
import _get from 'lodash/get';
import { callFunc, getHandler } from 'ravenjs/utils/actions';

function TableCellSelectMenu(props) {
    const { cellProps, handlers, row, disabled, isClearable } = props;
    const { menuItems } = cellProps;
    const dataKey = _get(cellProps, 'dataKey', 'id');
    const onSelectChange = getHandler(handlers, 'onSelectChange');
    const getDefaultValue = getHandler(handlers, 'getDefaultValue');

    return (
        <Select
            defaultValue={callFunc(getDefaultValue, props)}
            onChange={data => {
                callFunc(onSelectChange, { id: row[dataKey], data });
            }}
            isClearable={isClearable}
            options={menuItems}
            width="100%"
            isDisabled={disabled}
        />
    );
}

TableCellSelectMenu.propTypes = {
    cellProps: PropTypes.shape({
        menuItems: PropTypes.array.isRequired,
        readonly: PropTypes.bool,
    }).isRequired,
    handlers: PropTypes.object,
    row: PropTypes.object,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
};
TableCellSelectMenu.defaultProps = {
    handlers: null,
    disabled: false,
    row: {},
    isClearable: false,
};

export default TableCellSelectMenu;
