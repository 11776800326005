import { arrayOf, bool, func, object, oneOfType, string } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import { DURATION } from 'ravenjs/constants/transitions';
import Menu from 'ravenjs/lib/Menu';
import PopoverContent from 'ravenjs/lib/PopoverContent';
import Typography from 'ravenjs/lib/Typography';
import { callFunc } from 'ravenjs/utils/actions';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import Row from 'ravenjs/lib/Row';
import { getThemeProps } from 'ravenjs/utils/theme';

import { DEFAULT_COLORS } from 'constants/colors';
import MESSAGES from 'constants/messages';
import { APP_PERMISSIONS } from 'constants/permissions';
import Acl from 'modules/acl';
import { AlertIcon, AlertIconCounter, ThrashIcon } from 'components/Icons';
import NotificationItem from './NotificationItem';
import NotificationsCounter from './NotificationsCounter';

const PopoverStyles = styled(PopoverContent)`
    bottom: 0;
    margin-left: 2px;
    &:before {
        background: transparent;
        box-shadow: none;
        border-bottom: 8px solid transparent;
        border-left: 8px solid transparent;
        border-right: 8px solid ${DEFAULT_COLORS.WHITE};
        border-top: 8px solid ${DEFAULT_COLORS.WHITE};
        top: -7px;
    }
`;

const ContentStyled = styled.div`
    border: 1px solid ${DEFAULT_COLORS.DARK};
    background-color: ${DEFAULT_COLORS.WHITE};
    min-width: 16.75rem;
    padding: 0;
`;

const NotificationItems = styled.div`
    max-height: 475.5px;
    overflow-y: scroll;
`;

const DeleteAllStyled = styled.div`
    align-items: center;
    background: ${DEFAULT_COLORS.BANNER_RED};
    display: flex;
    justify-content: center;
    min-height: 2.25rem;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
`;

const MenuWrapper = styled.div`
    height: 100%;
`;

const NoNotificationsRow = styled(Row)`
    margin-top: 2.99rem;
    display: flex;
    justify-content: center;
    height: 5.438rem;
    align-items: center;
`;

const NotificationsStyled = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    user-select: none;
    ${({ theme }) => theme.media.up('md')`
        margin-right: 0.53rem;
        margin-left: 0.53rem;
    `};
    ${({ theme }) => theme.media.down('md')`
        margin-right: 0;
        margin-left: 0;
    `};
`;

const NotificationsWrapper = styled.div`
    height: 100%;
    position: relative;
`;

const ViewMoreStyled = styled(Row)`
    background: ${DEFAULT_COLORS.LIGHTBLUE};
    cursor: ${({ disableClick }) => (disableClick ? 'default' : 'pointer')};
    height: 2.25rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    :hover {
        background: ${DEFAULT_COLORS.BLUE};
    }
`;

function NotificationsMenu({
    errorMessage,
    hasError,
    iconRef,
    menuRef,
    noNotificationsMessage,
    notifications,
    onClickNotification,
    onClickNotificationIcon,
    showNotificationMenu,
    hideNotificationMenu,
    onDeleteNotification,
    onViewMore,
    open: isMenuOpen,
    totalUnseen,
    total,
    onClickDeleteAll,
    history,
    theme,
}) {
    function handleNotificationOnClick(id) {
        callFunc(
            onClickNotification,
            notifications.find(notification => notification.notificationId === id)
        );
    }

    function handleNotificationOnDelete(id) {
        callFunc(
            onDeleteNotification,
            notifications.find(notification => notification.notificationId === id)
        );
    }

    function renderNotifications() {
        if (hasError) {
            return (
                <NoNotificationsRow>
                    <Typography color="error" type="noNewNotifications" gutterBottom="0">
                        {errorMessage}
                    </Typography>
                </NoNotificationsRow>
            );
        } else if (isEmpty(notifications)) {
            return (
                <NoNotificationsRow
                    display="flex"
                    justify="center"
                    height="5.438rem"
                    alignItems="center"
                >
                    <Typography type="noNewNotifications" gutterBottom="0">
                        {noNotificationsMessage}
                    </Typography>
                </NoNotificationsRow>
            );
        }

        return (
            <NotificationItems>
                {notifications.map(
                    ({ entityType, notificationId, summary, status, timestamp }, index) => {
                        return (
                            <NotificationItem
                                entityType={entityType}
                                key={notificationId}
                                id={notificationId}
                                onClick={handleNotificationOnClick}
                                onViewMore={onViewMore}
                                onDelete={handleNotificationOnDelete}
                                summary={summary}
                                status={status}
                                timestamp={timestamp}
                                zebra={index % 2 !== 0}
                            />
                        );
                    }
                )}
            </NotificationItems>
        );
    }

    function renderViewMore() {
        return (
            <>
                {total > 0 ? (
                    <ViewMoreStyled gutter={false} onClick={onViewMore}>
                        <Typography
                            fontSize="0.813rem"
                            lineHeight="1"
                            gutterBottom="0"
                            gutterTop="0"
                            type="link"
                            style={{
                                color: DEFAULT_COLORS.BLACK,
                                letterSpacing: '0.65px',
                                textDecoration: 'none',
                            }}
                        >{`VIEW ALL ${total} NOTIFICATIONS`}</Typography>
                    </ViewMoreStyled>
                ) : (
                    <ViewMoreStyled disableClick gutter={false} />
                )}
            </>
        );
    }

    const renderMenuContent = () => {
        return (
            <ContentStyled ref={menuRef}>
                {renderNotifications()}
                {!isEmpty(notifications) && (
                    <DeleteAllStyled onClick={onClickDeleteAll}>
                        <ThrashIcon />
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            fontSize="0.813rem"
                            gutterLeft="0.729rem"
                            gutterTop="0"
                            gutterBottom="0"
                            lineHeight="1"
                            style={{
                                letterSpacing: '0.65px',
                            }}
                        >
                            {MESSAGES.NOTIFICATIONS.DELETE_ALL}
                        </Typography>
                    </DeleteAllStyled>
                )}
                {renderViewMore()}
            </ContentStyled>
        );
    };

    const renderButtonComponent = () => {
        const pathName = get(history, 'location.pathname', '');
        const isNotificationList = pathName === '/notifications/list';
        const alertIconFillColor = getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, {
            theme,
        });
        return (
            <NotificationsWrapper>
                <NotificationsStyled>
                    <IconWrapper>
                        {totalUnseen > 0 && !isNotificationList ? (
                            <>
                                <AlertIconCounter fill={alertIconFillColor} />
                                <NotificationsCounter value={totalUnseen} />
                            </>
                        ) : (
                            <AlertIcon fill={alertIconFillColor} />
                        )}
                    </IconWrapper>
                </NotificationsStyled>
            </NotificationsWrapper>
        );
    };

    return (
        <MenuWrapper
            className={
                Acl.check(APP_PERMISSIONS.userAssistanceClientAdmin)
                    ? 'pendo-nav-notifications'
                    : ''
            }
            onMouseLeave={hideNotificationMenu}
            onBlur={hideNotificationMenu}
            ref={iconRef}
            onClick={onClickNotificationIcon}
            onFocus={showNotificationMenu}
            onMouseEnter={showNotificationMenu}
        >
            <Menu
                ButtonComponent={renderButtonComponent()}
                ContentComponent={PopoverStyles}
                ContentProps={{
                    borderRadius: '0',
                }}
                IconComponent={null}
                arrowSize={7}
                color="transparent"
                distanceFromContainer={0}
                placement="bottom-end"
                ref={menuRef}
                open={isMenuOpen}
                timeout={DURATION.none}
                borderRadius={4}
            >
                {renderMenuContent()}
            </Menu>
        </MenuWrapper>
    );
}

NotificationsMenu.propTypes = {
    errorMessage: string,
    hasError: bool,
    iconRef: oneOfType([func, object]),
    menuRef: oneOfType([func, object]),
    noNotificationsMessage: string,
    notifications: arrayOf(object),
    onClickNotification: func,
    onDeleteNotification: func,
    onClickNotificationIcon: func.isRequired,
    hideNotificationMenu: func.isRequired,
    showNotificationMenu: func.isRequired,
    onClickDeleteAll: func,
    onViewMore: func,
    open: bool,
    totalUnseen: string,
    total: string,
    history: object.isRequired,
    theme: object.isRequired,
};

NotificationsMenu.defaultProps = {
    errorMessage: null,
    hasError: false,
    iconRef: null,
    menuRef: null,
    noNotificationsMessage: 'No new Notifications',
    notifications: [],
    onClickNotification: null,
    onDeleteNotification: null,
    onClickDeleteAll: null,
    onViewMore: null,
    open: false,
    totalUnseen: null,
    total: null,
};

export { NotificationsMenu as NotificationsMenuUnwrapped };
export default withTheme(NotificationsMenu);
