import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import FormGroupTitle from 'ravenjs/lib/Form/fields/FormGroupTitle';
import Input from 'ravenjs/lib/Input';
import HidePasswordIcon from 'ravenjs/lib/SvgIcon/types/HidePasswordIcon';
import ShowPasswordIcon from 'ravenjs/lib/SvgIcon/types/ShowPasswordIcon';
import { addEventListenerOnRoot, get, removeEventListenerOnRoot } from 'ravenjs/utils';

const BaseInputWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
`;

const SpanStyled = styled.span`
    position: absolute;
    right: 1.375rem;
    padding-top: 0.625rem;
`;

class InputWithPasswordIconField extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        autofocus: PropTypes.bool,
        idSchema: PropTypes.object.isRequired,
        formContext: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        readonly: PropTypes.bool,
        registry: PropTypes.object.isRequired,
        required: PropTypes.bool,
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object.isRequired,
        formData: PropTypes.string,
    };

    static defaultProps = {
        disabled: null,
        readonly: null,
        required: null,
        formData: '',
        autofocus: false,
        onFocus: false,
    };

    inputWrapperRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { showPassword: showPasswordCurrentState } = this.state;
        const { showPassword: showPasswordPrevState } = prevState;

        if (!showPasswordPrevState && showPasswordCurrentState) {
            addEventListenerOnRoot('click', this.outsideClickListener);
        } else if (showPasswordPrevState && !showPasswordCurrentState) {
            removeEventListenerOnRoot('click', this.outsideClickListener);
        }
    }

    /**
     * Get the `ui:options` for the current field.
     *
     * @method _getUiOptions
     * @private
     * @param  {Object}      schema The schema to extract the options from
     * @return {Object}             The extracted options
     */
    _getUiOptions = schema => get(schema, 'ui:options', {});

    handleIconClick = () => {
        const { showPassword } = this.state;
        const {
            idSchema: { $id },
        } = this.props;
        document.getElementById($id).focus();
        this.setState({ showPassword: !showPassword });
    };

    outsideClickListener = e => {
        const isOutsideClick =
            this.inputWrapperRef &&
            this.inputWrapperRef.current &&
            !this.inputWrapperRef.current.contains(e.target);

        if (isOutsideClick) {
            this.setState({
                showPassword: false,
            });
        }
    };

    render() {
        const {
            autofocus,
            disabled,
            idSchema,
            onBlur,
            onChange,
            readonly,
            uiSchema,
            formData,
            schema,
        } = this.props;

        const options = this._getUiOptions(uiSchema);
        const { $id: id } = idSchema;
        const { styles, col, inputType, placeholder } = options;
        const { title } = schema;
        const inputValue = document.getElementById(id)?.value;
        const { showPassword } = this.state;

        const _onChange = ({ target: { value } }) =>
            onChange(value === '' ? options.emptyValue : value);

        const _onBlur = ({ target: { value } }) => {
            if (typeof onBlur === 'function') {
                onBlur(id, value);
            }
            this.setState({ showPassword: false });
        };

        const _onFocus = () => {
            if (inputValue) {
                document.getElementById(id).value = inputValue;
            }
        };

        const getInputType = () => (!showPassword ? inputType : 'text');

        return (
            <>
                <FormGroupTitle title={title} uiOptions={options} />
                <BaseInputWrapper ref={this.inputWrapperRef}>
                    <Input
                        autoFocus={autofocus}
                        disabled={disabled}
                        id={id}
                        readOnly={readonly}
                        value={formData == null ? '' : formData}
                        type={getInputType()}
                        placeholder={placeholder}
                        {...styles}
                        {...col}
                        onChange={_onChange}
                        onBlur={_onBlur}
                        onFocus={_onFocus}
                    />
                    <SpanStyled
                        title={!showPassword ? 'Show Password' : 'Hide Password'}
                        onClick={this.handleIconClick}
                    >
                        {!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                    </SpanStyled>
                </BaseInputWrapper>
            </>
        );
    }
}

export default InputWithPasswordIconField;
