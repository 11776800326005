const IN_PLATFORM_UPGRADE_ADS = {
    PARTNER_DEFAULT_UPGRADE_ADS: {
        event: 'In platform default upgrade Ads',
        eventProperties: {},
    },
    PARTNER_CUSTOM_UPGRADE_ADS: {
        event: 'In platform custom upgrade Ads',
        eventProperties: {},
    },
    UPGRADE_BANNER_ADS_LEAR_MORE_CLICK: {
        event: 'In platform banner ads click',
        eventProperties: {},
    },
};

export default IN_PLATFORM_UPGRADE_ADS;
