import _times from 'lodash/times';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Row from 'ravenjs/lib/Row';
import { PaginationPreviousIcon, PaginationNextIcon } from 'ravenjs/lib/SvgIcon';
import Typography from 'ravenjs/lib/Typography';
import { callFunc } from 'ravenjs/utils/actions';
import { calculateLastPage } from 'ravenjs/utils/pagination';
import { getThemeProps } from 'ravenjs/utils/theme';

import { DEFAULT_COLORS } from 'constants/colors';

import PaginationActionsButtonContainer from './PaginationActionsButtonContainer';

const PaginationActionsStyled = styled(Row)`
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('PaginationActions.styles')};
`;

const PaginationTypography = styled(Typography)`
    cursor: pointer;
`;

const StyledMobilePaginationTypography = styled(Typography)`
    margin-left: 5px;
    padding: 0.2rem 0 0 0.2rem;
    color: ${({ color }) => color} !important;
    type: ${({ type }) => type};
`;

class PaginationActions extends React.Component {
    static propTypes = {
        compress: PropTypes.string,
        /**
         * The idx of the current page.
         */
        currentPage: PropTypes.number.isRequired,
        /**
         * Action taken upon each page change.
         */
        onChangePage: PropTypes.func,
        /**
         * The amount of rows per page.
         */
        rowsPerPage: PropTypes.number.isRequired,
        showcaseCount: PropTypes.number,
        /**
         * The total rows available.
         */
        totalRows: PropTypes.number.isRequired,
        paginationButtonSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        buttonColor: PropTypes.string,
        innerButtonColor: PropTypes.string,
    };

    static defaultProps = {
        compress: '',
        onChangePage: () => {},
        showcaseCount: 4,
        paginationButtonSize: '20',
        buttonColor: '#9CDEFF',
        innerButtonColor: '#282828',
    };

    constructor(props) {
        super(props);
        // Extract some props.
        const { currentPage = 1, rowsPerPage, totalRows } = props;
        // Build the initial state based on passed in props.
        this.state = {
            // Calc the last page from props if possible.
            lastPage: calculateLastPage(totalRows, rowsPerPage),
            // Navigate the user to the initial page value.
            pageInputValue: currentPage,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentPage, rowsPerPage, totalRows } = this.props;
        const {
            currentPage: prevCurrentPage,
            rowsPerPage: prevRowsPerPage,
            totalRows: prevTotalRows,
        } = prevProps;

        if (
            currentPage !== prevCurrentPage ||
            rowsPerPage !== prevRowsPerPage ||
            totalRows !== prevTotalRows
        ) {
            // NOTE: It's completely alright to update state in 'componentDidUpdate'
            // as long as it is inside a condition.
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(() => ({
                lastPage: calculateLastPage(totalRows, rowsPerPage),
                pageInputValue: currentPage,
            }));
        }
    }

    getPreviousButtonProps = () => {
        const { currentPage } = this.props;
        const prevButtonProps = {
            onClick: this.handleBackClick,
        };

        if (currentPage === 1) {
            prevButtonProps.disabled = true;
            delete prevButtonProps.onClick;
        }

        return prevButtonProps;
    };

    getNextButtonProps = () => {
        const { currentPage } = this.props;
        const { lastPage } = this.state;
        const nextButtonProps = {
            onClick: this.handleForwardClick,
        };

        if (currentPage === lastPage) {
            nextButtonProps.disabled = true;
            delete nextButtonProps.onClick;
        }

        return nextButtonProps;
    };

    handleBackClick = event => {
        const { currentPage, onChangePage } = this.props;

        onChangePage(event, currentPage - 1);
    };

    handleForwardClick = event => {
        const { currentPage, onChangePage } = this.props;

        onChangePage(event, currentPage + 1);
    };

    handleInputOnKeyPress = event => {
        if (event.charCode === 13) {
            const { lastPage, pageInputValue } = this.state;
            const { currentPage, onChangePage } = this.props;
            let value = parseInt(pageInputValue, 10) || lastPage;

            if (value >= lastPage) {
                value = lastPage;
            }

            if (value < 1) {
                value = 1;
            }

            if (currentPage !== value) {
                onChangePage(event, value);
            }

            this.setState(() => ({ pageInputValue: value }));
        }
    };

    renderPaginationButtons = (pageNumber, placeholder, onClickCallback, key) => {
        const { compress, currentPage, onChangePage } = this.props;
        const active = currentPage === pageNumber;
        const value = placeholder ? '...' : pageNumber;
        const gutter = compress || '0.687rem';
        const handlePageClick = event => {
            if (typeof onClickCallback === 'function') {
                callFunc(onClickCallback, pageNumber);
            } else if (pageNumber) {
                onChangePage(event, pageNumber);
            }
        };

        return (
            <PaginationTypography
                key={key || pageNumber}
                gutterBottom="0"
                gutterLeft={gutter}
                gutterRight={gutter}
                gutterTop="0"
                onClick={handlePageClick}
                type={active ? 'paginationListActive' : 'paginationList'}
            >
                {value}
            </PaginationTypography>
        );
    };

    render() {
        let nextIconDisabled = false;
        let nextIconDisabledColor = '';
        let previousIconDisabled = false;
        let previousIconDisabledColor = '';
        const { lastPage } = this.state;
        const { paginationButtonSize, innerButtonColor, currentPage, showcaseCount } = this.props;
        const previousButtonProps = this.getPreviousButtonProps();
        const nextButtonProps = this.getNextButtonProps();

        // To stay consistent with the current implementation,
        // we will hide the pagination actions if the last page
        // is less than or equal to `1`.
        if (lastPage <= 1) {
            return null;
        }

        const renderActions = paginationShowcaseCount => {
            const { onChangePage } = this.props;
            const pages = [];
            if (paginationShowcaseCount >= lastPage / 2) {
                _times(lastPage, i => pages.push(this.renderPaginationButtons(i + 1)));
            } else if (
                currentPage < paginationShowcaseCount ||
                currentPage > lastPage - paginationShowcaseCount + 1
            ) {
                _times(paginationShowcaseCount, i =>
                    pages.push(this.renderPaginationButtons(i + 1))
                );
                pages.push(
                    this.renderPaginationButtons(
                        null,
                        true,
                        e => {
                            onChangePage(e, Math.floor(lastPage / 2));
                        },
                        'placeholder_middle'
                    )
                );
                _times(paginationShowcaseCount, i =>
                    pages.push(
                        this.renderPaginationButtons(lastPage - paginationShowcaseCount + i + 1)
                    )
                );
            } else {
                pages.push(this.renderPaginationButtons(1));
                pages.push(this.renderPaginationButtons(null, true, null, 'placeholder_left'));

                let iterationCount;
                if (paginationShowcaseCount % 2 === 1) {
                    iterationCount =
                        paginationShowcaseCount > 1 ? (paginationShowcaseCount - 1) / 2 : 1;
                } else {
                    iterationCount = paginationShowcaseCount > 2 ? paginationShowcaseCount / 2 : 1;
                }
                _times(iterationCount, i => {
                    const numPage = currentPage - iterationCount + i;
                    if (numPage > 1) {
                        pages.push(this.renderPaginationButtons(numPage));
                    }
                });
                pages.push(this.renderPaginationButtons(currentPage));
                _times(iterationCount, i => {
                    const numPage = currentPage + i + 1;
                    if (numPage < lastPage) {
                        pages.push(this.renderPaginationButtons(numPage));
                    }
                });
                pages.push(this.renderPaginationButtons(null, true, null, 'placeholder_right'));
                pages.push(this.renderPaginationButtons(lastPage));
            }

            return pages;
        };

        const renderMobileActions = () => {
            const { currentPage } = this.props;
            const { lastPage } = this.state;

            return (
                <PaginationTypography type="paginationMobileActions">
                    {currentPage} of {lastPage}
                </PaginationTypography>
            );
        };

        previousIconDisabled = currentPage <= 1;
        previousIconDisabledColor = currentPage <= 1 ? DEFAULT_COLORS.DARK : innerButtonColor;
        nextIconDisabledColor = currentPage >= lastPage ? DEFAULT_COLORS.DARK : innerButtonColor;
        nextIconDisabled = currentPage >= lastPage;

        return (
            <>
                <PaginationActionsStyled
                    alignItems="center"
                    display={{ xs: 'flex', lg: 'none' }}
                    gutter={false}
                    height="100%"
                    justify="space-between"
                    wrap="nowrap"
                    width="100%"
                >
                    <PaginationActionsButtonContainer {...previousButtonProps}>
                        <PaginationPreviousIcon
                            width={paginationButtonSize}
                            height={paginationButtonSize}
                            fill={previousIconDisabledColor}
                            fillInner={innerButtonColor}
                            disabled={previousIconDisabled}
                        />
                        <StyledMobilePaginationTypography
                            type="paginationMobileLabels"
                            color={previousIconDisabledColor}
                        >
                            Previous
                        </StyledMobilePaginationTypography>
                    </PaginationActionsButtonContainer>
                    <PaginationTypography
                        gutterBottom="0"
                        gutterLeft="0.687rem"
                        gutterRight="0.687rem"
                        gutterTop="0"
                        type="paginationListActive"
                    >
                        {renderMobileActions()}
                    </PaginationTypography>
                    <PaginationActionsButtonContainer {...nextButtonProps}>
                        <StyledMobilePaginationTypography
                            type="paginationMobileLabels"
                            color={nextIconDisabledColor}
                        >
                            Next
                        </StyledMobilePaginationTypography>
                        <PaginationNextIcon
                            width={paginationButtonSize}
                            height={paginationButtonSize}
                            fill={nextIconDisabledColor}
                            fillInner={innerButtonColor}
                            disabled={nextIconDisabled}
                        />
                    </PaginationActionsButtonContainer>
                </PaginationActionsStyled>
                <PaginationActionsStyled
                    alignItems="center"
                    display={{ xs: 'none', lg: 'flex' }}
                    gutter={false}
                    height="100%"
                    justify="space-between"
                    wrap="nowrap"
                >
                    <PaginationActionsButtonContainer {...previousButtonProps}>
                        <PaginationPreviousIcon
                            width={paginationButtonSize}
                            height={paginationButtonSize}
                            fill={previousIconDisabledColor}
                            fillInner={innerButtonColor}
                            disabled={previousIconDisabled}
                        />
                    </PaginationActionsButtonContainer>
                    {renderActions(showcaseCount)}
                    <PaginationActionsButtonContainer {...nextButtonProps}>
                        <PaginationNextIcon
                            width={paginationButtonSize}
                            height={paginationButtonSize}
                            fill={nextIconDisabledColor}
                            fillInner={innerButtonColor}
                            disabled={nextIconDisabled}
                        />
                    </PaginationActionsButtonContainer>
                </PaginationActionsStyled>
            </>
        );
    }
}

export default PaginationActions;
