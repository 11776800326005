import { createActions } from 'utils/actions';

/*
 * Redux action to fetch permissions
 *
 * @type {Object}
 */
export const fetchPermissions = createActions('permissions', 'FETCH_PERMISSIONS', {
    asPromise: true,
});
