import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Typography from 'ravenjs/lib/Typography';
import { stringReplaceWithJSX } from 'ravenjs/utils';

import { DEFAULT_COLORS } from 'constants/colors';
import { USER_NAV_SUPPORT_INFO } from 'constants/navigation';

const StyledPaychexDescription = styled.span`
    color: ${DEFAULT_COLORS.BLACK};
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    margin: 5px 0 8px 0;
`;

const NeedHelpSectionPaychex = ({ paychexSupportEmail }) => {
    const linksMap = {
        PAYCHEX_SUPPORT_EMAIL: (
            <a
                style={{ color: DEFAULT_COLORS.LINK_PAYCHEX }}
                href={`mailto:${paychexSupportEmail}`}
                key={paychexSupportEmail}
            >
                {paychexSupportEmail}
            </a>
        ),
    };

    return (
        <>
            <Typography color={DEFAULT_COLORS.BLACK} fontFamily="Roboto" lineHeight="normal">
                {USER_NAV_SUPPORT_INFO.PAYCHEX_TITLE}
            </Typography>
            <StyledPaychexDescription>
                {stringReplaceWithJSX(USER_NAV_SUPPORT_INFO.PAYCHEX_DESCRIPTION, linksMap)}
            </StyledPaychexDescription>
        </>
    );
};

NeedHelpSectionPaychex.propTypes = {
    paychexSupportEmail: PropTypes.string.isRequired,
};

NeedHelpSectionPaychex.defaultProps = {};

export default NeedHelpSectionPaychex;
