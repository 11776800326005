import axios from 'axios';

/**
 * Fetch banner by category
 *
 * @param   {string}           category
 * @param   {string|number}    userId
 * @param   {string|number}    partnerId
 * @param   {string}           userRole
 * @return  {Promise}
 */
export const fetchBannerByCategory = (category, userId, partnerId, userRole) =>
    axios({
        method: 'get',
        url: '/v2/banners',
        params: {
            category,
            userId,
            partnerId,
            userRole,
        },
    });

/**
 * Fetch banner's list.
 *
 * @param   {Object}   params
 * @return  {Promise}
 */
export const fetchBanners = params =>
    axios({
        method: 'get',
        url: '/v2/banners',
        params,
    });

/**
 *  Update banner by id.
 *
 * @param {Object}    banner
 * @return {Promise}
 */
export const updateBannerById = banner =>
    axios({
        method: 'put',
        url: `/v2/banners/${banner.id}`,
        data: banner,
    });

/**
 *  Create banner.
 *
 * @param {Object}    banner
 * @return {Promise}
 */
export const createBannerAPI = banner =>
    axios({
        method: 'post',
        url: `/v2/banners`,
        data: banner,
    });

/**
 *  Delete banner.
 *
 * @param {Object}    bannerId
 * @return {Promise}
 */
export const deleteBannerAPI = bannerId =>
    axios({
        method: 'delete',
        url: `/v2/banners/${bannerId}`,
    });

/**
 *  Dismiss banner.
 *
 * @param {Object}    payload
 * @return {Promise}
 */
export const dismissBannerAPI = payload =>
    axios({
        method: 'post',
        url: `/v2/app-events`,
        data: payload,
    });
