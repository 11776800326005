import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const TrashIcon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path
                fill="#3A3A3A"
                d="M70.6,6.2h-3.8V0h-6.2v6.2H19.4V0h-6.2v6.2H9.4C4.2,6.3,0,10.4,0,15.6v55 C0,75.8,4.2,80,9.4,80h61.2c5.2,0,9.4-4.2,9.4-9.4v-55C80,10.4,75.8,6.3,70.6,6.2z M73.8,70.6c0,1.7-1.4,3.1-3.1,3.1H9.4 c-1.7,0-3.1-1.4-3.1-3.1V29.4h67.5V70.6z M73.8,23.1H6.2v-7.5c0-1.7,1.4-3.1,3.1-3.1h3.8v6.2h6.2v-6.2h41.2v6.2h6.2v-6.2h3.8 c1.7,0,3.1,1.4,3.1,3.1V23.1z"
            />
        </SvgIcon>
    );
};

TrashIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

TrashIcon.defaultProps = {
    height: '80',
    viewBox: '0 0 80 80',
    width: '80',
};

export default TrashIcon;
