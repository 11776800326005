import {
    any,
    array,
    arrayOf,
    bool,
    func,
    node,
    number,
    object,
    oneOf,
    oneOfType,
    shape,
    string,
} from 'prop-types';

import { STICKY_COLUMN_POSITION } from 'ravenjs/constants/list';
import { responsiveProptypes } from 'ravenjs/utils/proptypes';
import { KEYS } from 'ravenjs/constants/';

export const DATA_LIST_VIEW_SCHEMA = shape({
    actions: object,
    data: array,
    dataFilters: object,
    schema: shape({
        list: shape({
            cellTypes: object,
            columns: arrayOf(
                shape({
                    cellProps: object,
                    id: oneOfType([number, string]),
                    size: responsiveProptypes(
                        oneOfType([oneOf(['auto', '*', false]), number, string])
                    ),
                    sticky: shape({
                        border: bool,
                        check: shape({
                            breakpoint: oneOf(KEYS).isRequired,
                            value: oneOf(['less', 'greater']).isRequired,
                        }),
                        position: oneOf([
                            STICKY_COLUMN_POSITION.LEFT,
                            STICKY_COLUMN_POSITION.RIGHT,
                        ]),
                    }),
                    title: string,
                })
            ),
            rows: object,
            noData: string,
            table: object,
        }),
        actionButtons: arrayOf(
            shape({
                icon: string,
                id: string.isRequired,
                btnProps: object,
                iconProps: object,
                label: string,
                onClick: string,
                options: arrayOf(
                    shape({
                        Icon: node,
                        iconProps: object,
                        id: string.isRequired,
                        btnProps: object,
                        label: string,
                        onClick: string,
                    })
                ),
                type: oneOf(['single', 'dropdown']),
            })
        ),
        filters: arrayOf(
            shape({
                name: string.isRequired,
                title: string,
                placeholder: string,
                type: string.isRequired,
                options: arrayOf(
                    shape({
                        isAllOption: bool,
                        label: string,
                        value: any.isRequired,
                    })
                ),
            })
        ),
        filterOptions: shape({
            isExpanded: bool,
        }),
        onChangeRef: string,
        selected: shape({
            label: shape({
                prefix: string,
                suffix: string,
            }),
            width: string,
            isMultiSelectByPage: bool,
            isMulti: bool,
            itemCount: number,
            items: array,
            key: string.isRequired,
            selectedAll: bool,
            isRowSelectedRef: string,
        }),
        pagination: shape({
            rowsPerPage: number,
            startAtPage: number,
        }),
        refs: object,
        search: shape({
            label: string,
            placeholder: string,
            mobilePlaceholder: string,
            hideBorder: bool,
            title: string,
            isResetable: bool,
        }),
        sorting: object,
    }),
    updateOnMount: bool,
});

export const HISTORY = shape({
    block: func.isRequired,
    createHref: func.isRequired,
    go: func.isRequired,
    goBack: func.isRequired,
    goForward: func.isRequired,
    listen: func.isRequired,
    location: object.isRequired,
    push: func.isRequired,
    replace: func.isRequired,
});

export const LOCATION = shape({
    hash: string,
    pathname: string,
    search: string,
    state: object,
});

export const MATCH = shape({
    isExact: bool.isRequired,
    params: object.isRequired,
    path: string.isRequired,
    url: string.isRequired,
});

export const USER_NAV_ITEMS = shape({
    companySettings: arrayOf(
        shape({
            title: string,
            state: string.isRequired,
            to: string.isRequired,
        })
    ),
    myTools: arrayOf(
        shape({
            title: string,
            state: string.isRequired,
            to: string,
        })
    ),
    partnerTools: arrayOf(
        shape({
            title: string,
            state: string.isRequired,
            to: string.isRequired,
        })
    ),
    internalTools: arrayOf(
        shape({
            title: string,
            state: string.isRequired,
            to: string.isRequired,
        })
    ),
});
