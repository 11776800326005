import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ShareIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon cursor="pointer" width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0zM6 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0zM18 16a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.726 13.007a1 1 0 0 1 1.367-.361l6.83 3.98c-.009.374-.145.897-.423 1.374-.278.477-.106.632-.584.354l-6.83-3.98a1 1 0 0 1-.36-1.367zM15.5 6.5c.278.477 0 .5.414.874l-6.82 3.98a1 1 0 0 1-1.008-1.728l6.82-3.98c.477-.278.316.377.594.854z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ShareIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ShareIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default ShareIcon;
