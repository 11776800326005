import axios from 'axios';

/**
 * API call to fetch info about the given category, via `categoryId`.
 *
 * @method fetchContentByCategoryAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchContentByCategoryAPI = params => {
    return axios({
        method: 'get',
        url: `/v2/contentful/laws/`,
        params,
    });
};

/**
 * API call to fetch content details.
 *
 * @method fetchContentDetailsAPI
 * @param  {number}  id
 * @param  {string}  type
 * @return {Promise}
 */
export const fetchContentDetailsAPI = (id, type) => {
    return axios({
        method: 'get',
        url: `/v2/contentful/${type}/${id}`,
    });
};

/**
 * API call to fetch public content details.
 *
 * @method fetchPublicContentDetailsAPI
 * @param  {number}  id
 * @param  {string}  type
 * @param  {string}  token
 * @return {Promise}
 */
export const fetchPublicContentDetailsAPI = (id, type, token) => {
    return axios({
        ignoreAuthorization: true,
        method: 'get',
        params: {
            token,
        },
        url: `/v2/public/contentful/${type}/${id}`,
    });
};

/**
 * API call to fetch content details (preview).
 *
 * @method fetchPreviewContentDetailsAPI
 * @param  {number}  id
 * @return {Promise}
 */
export const fetchPreviewContentDetailsAPI = id => {
    return axios({
        method: 'get',
        noBearer: true,
        type: 'apiTools',
        url: `/tools/v1/contentful/content-preview/${id}`,
    });
};

/**
 * API call to fetch webinars details.
 *
 * @method fetchWebinarsDetailsAPI
 * @param  {number}  id
 * @param  {string}  type
 * @return {Promise}
 */
export const fetchWebinarsDetailsAPI = id => {
    return axios({
        method: 'get',
        noBearer: true,
        type: 'apiTools',
        url: `/tools/v1/webinar/on-demand/detail/${id}`,
    });
};

/**
 * API call to fetch webinars details.
 *
 * @method fetchRelatedMaterialDetailsAPI
 * @param  {stirng}  id
 * @param  {string}  materialId
 * @param  {bool}    isWebinar
 * @return {Promise}
 */
export const fetchRelatedMaterialDetailsAPI = (id, materialId, isWebinar) => {
    return axios({
        method: 'get',
        noBearer: true,
        type: 'apiTools',
        url: isWebinar
            ? `/tools/v1/webinar/${id}/related-material/${materialId}/detail`
            : `/tools/v1/webinar/upcoming/${id}/related-material/${materialId}`,
    });
};
