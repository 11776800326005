import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MultiPeopleIcon = ({ bodyFill, height, faceFill, viewBox, width, ...rest }) => (
    <SvgIcon height={height} width={width} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g>
                <g transform="translate(1 -4) translate(-.068 4)">
                    <g transform="translate(4.11)">
                        <path
                            fill={bodyFill}
                            d="M0 11.934c0-3.296 2.671-5.967 5.967-5.967 3.295 0 5.967 2.671 5.967 5.967H0z"
                        />
                        <circle cx="5.967" cy="2.983" r="2.983" fill={bodyFill} />
                        <path
                            fill={faceFill}
                            d="M5.967 4.547c-.664-.003-1.254-.424-1.474-1.05L5.09 3.3c.144.369.499.611.895.611s.751-.242.895-.611l.56.197c-.219.626-.81 1.047-1.473 1.05z"
                        />
                    </g>
                    <path
                        fill={bodyFill}
                        d="M0 9.668c0-2.24 1.816-4.056 4.055-4.056 2.24 0 4.056 1.816 4.056 4.056H0z"
                    />
                    <circle cx="4.055" cy="3.585" r="2.028" fill={bodyFill} />
                    <path
                        fill={faceFill}
                        d="M4.055 4.647c-.45-.002-.852-.288-1.001-.714l.405-.133c.098.25.34.415.609.415.269 0 .51-.165.608-.415l.381.133c-.15.426-.55.712-1.002.714z"
                    />
                    <path
                        fill={bodyFill}
                        d="M12.028 9.668c0-2.24 1.816-4.056 4.055-4.056 2.24 0 4.056 1.816 4.056 4.056h-8.111z"
                    />
                    <circle cx="16.083" cy="3.585" r="2.028" fill={bodyFill} />
                    <path
                        fill={faceFill}
                        d="M16.083 4.647c-.45-.002-.852-.288-1.001-.714l.405-.133c.098.25.34.415.609.415.269 0 .51-.165.608-.415l.381.133c-.15.426-.55.712-1.002.714z"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
);

MultiPeopleIcon.propTypes = {
    bodyFill: string,
    height: string,
    faceFill: string,
    viewBox: string,
    width: string,
};

MultiPeopleIcon.defaultProps = {
    bodyFill: DEFAULT_COLORS.YELLOW,
    height: '12',
    faceFill: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 22 12',
    width: '22',
};

export default MultiPeopleIcon;
