import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HamburgerMenuIcon = ({ fill, height, viewBox, width, ...rest }) => (
    <SvgIcon height={height} width={width} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g fill={fill}>
                <g>
                    <g>
                        <path
                            d="M24.75 17v1.5H0V17h24.75zm0-8.5V10H0V8.5h24.75zm0-8.5v1.5H0V0h24.75z"
                            transform="translate(-374 -83) translate(0 64) translate(374 19)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

HamburgerMenuIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

HamburgerMenuIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK_2,
    height: '22',
    viewBox: '0 0 25 22',
    width: '25',
};

export default HamburgerMenuIcon;
