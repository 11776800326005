const HANDBOOK = {
    HANDBOOK_CREATE: {
        event: 'Handbook Create',
        eventProperties: {
            description: 'User created handbook',
        },
    },
    HANDBOOK_POLICY_ALERT: {
        event: 'Handbook Policy Alert Click',
        eventProperties: {
            description: 'User clicked on policy alert handbook',
        },
    },
    HANDBOOK_POLICY_ALERT_ACCEPTED: {
        event: 'Handbook Policy Alert Accept Click',
        eventProperties: {
            description: 'User clicked on policy accept handbook',
        },
    },
    HANDBOOK_POLICY_DISMISS_ALERT: {
        event: 'Handbook Policy Alert Dismiss Click',
        eventProperties: {
            description: 'User clicked on policy dismiss handbook',
        },
    },
};

export default HANDBOOK;
