/**
 * Initialize the 'user' state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    // Information about the user's company.
    company: {},
    // Information about the user's configuration,
    // derived from the user's company and its configurationId.
    configuration: {},
    // Information about the user.
    info: {},
    locations: [],
    // Information about the user's permissions.
    // Combination of:
    // - 'original' permissions (fetched via API)
    // - 'actual' permissions (built from the configuration.skus and permissions.original)
    permissions: {},
    contentTypes: {},
    EULA: {
        isTermsAccepted: false,
    },
    welcomeInsightStatus: 'NOT_DELIVERED',
    companyDetailsReminder: {
        companyDetailsReminderStatus: false,
    },
    partnerSettings: {},
    safetyManualTemplateUrl: '',
    archivedHandbookStatus: false,
    clickSafetyMenu: false,
    sponsorships: [],
    partnerConfigurations: {},
    importStatus: {},
};

export default INITIAL_STATE;
