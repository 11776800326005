import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import { compose } from 'recompose';
import styled, { withTheme } from 'styled-components';

import { get, isEmpty } from 'ravenjs/utils/lodash';
import { getThemeProps } from 'ravenjs/utils/theme';

import AppNavigationLogoBar from 'components/AppNavigationLogoBar';
import { ContentSearchContext } from 'components/ContentSearch';
import { HamburgerMenuIcon, SearchActiveIcon } from 'components/Icons';
import AINav from 'components/AINav';
import NotificationWidget from 'components/NotificationWidget';
import MobileUserNav from 'components/MobileUserNav';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { DEFAULT_COLORS } from 'constants/colors';
import { EVENTS } from 'constants/events';
import { SOFT_LOGIN_APP_NAVIGATION } from 'constants/navigation';
import { DEFAULT_TITLE } from 'constants/safety';
import { APP_PERMISSIONS } from 'constants/permissions';

import { actions as pendoActions } from 'modules/pendo';
import { actions as userActions, selectors as userSelectors } from 'modules/user';
import Acl from 'modules/acl';

import { sendPendoEventToolClick } from 'utils/safety';
import { hasSearch } from 'utils/search';
import { isPublicRoute } from 'utils/thrservice';
import SideMenu from './SideMenu';

const NavItems = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
`;

const NavItem = styled.div`
    align-items: center;
    display: flex;
    margin-left: 1rem;
`;

const MobileAppNavigationStyled = styled.div`
    align-items: center;
    display: flex;
    background-color: rgba(0, 152, 219, 1);
    flex-direction: row;
    min-height: 2.875rem;
    padding: 0 10px;
    width: 100%;
    ${getThemeProps('MobileAppNavigation.styles')};
`;

const MobileAppNavigation = props => {
    const {
        appNav: appNavUnfiltered,
        authorized,
        history,
        isSoftLogin,
        sendPendoEvent,
        theme,
        updateClickSafetyMenu,
        user,
        userCompany,
        userNav,
        userPermissionsOriginal,
    } = props;
    const [searchState, setSearchState] = React.useContext(ContentSearchContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isSearchBarVisible = searchState.showSearchBar;
    const pathName = get(history, 'location.pathname', '');

    const onClickSearch = () => {
        setSearchState({ ...searchState, showSearchBar: !isSearchBarVisible });
    };

    const onMenuClick = () => {
        setIsMenuOpen(true);
    };

    const onSideMenuClose = (selectedParent, selectedChild) => {
        if (selectedParent) {
            const pendoEvent = EVENTS.NAVIGATION.SELECT;
            let navLink = selectedParent;

            if (!isEmpty(selectedChild)) {
                navLink = `${selectedParent}:${selectedChild.state}`;
            }
            pendoEvent.eventProperties.selection = navLink;
            sendPendoEvent(pendoEvent);

            if (navLink === DEFAULT_TITLE.toLowerCase()) {
                updateClickSafetyMenu(true);
            }

            if (selectedParent === DEFAULT_TITLE.toLowerCase()) {
                sendEventToolClick(selectedChild.label);
            }
        }
        setIsMenuOpen(false);
    };

    const onNavLogoClick = () => {
        const pendoEvent = EVENTS.NAVIGATION.SELECT;

        pendoEvent.eventProperties.selection = EVENTS.NAVIGATION.SELECTIONS.NAV_LOGO;
        sendPendoEvent(pendoEvent);
    };

    const onUserNavClick = navLink => {
        if (navLink && navLink.state) {
            const pendoEvent = EVENTS.NAVIGATION.SELECT;

            pendoEvent.eventProperties.selection = `usernav:${navLink.state}`;
            sendPendoEvent(pendoEvent);
        }
    };

    const filterAppNavItems = unfiltered => {
        return _cloneDeep(unfiltered);
    };

    const appNav = filterAppNavItems(isSoftLogin ? SOFT_LOGIN_APP_NAVIGATION : appNavUnfiltered);

    const sendEventToolClick = toolName => {
        sendPendoEventToolClick({
            currentUser: user,
            from: 'Navigation',
            sendPendoEvent,
            toolName,
            userCompany,
            userPermissionsOriginal,
        });
    };

    const showContentAi = Acl.check(APP_PERMISSIONS.aiDigitalAssistantView);

    return (
        ((authorized && !isPublicRoute(pathName)) || isSoftLogin) && (
            <>
                <AppNavigationLogoBar
                    logoUrl={appNav.logoUrl}
                    onClickLogo={() => onNavLogoClick()}
                />
                <MobileAppNavigationStyled className="mobileHeaderNav">
                    <NavItems>
                        <NavItem>
                            {hasSearch(pathName) && (
                                <SearchActiveIcon
                                    fill={getThemeProps(
                                        'NavItem.styles.color',
                                        DEFAULT_COLORS.BLACK,
                                        {
                                            theme,
                                        }
                                    )}
                                    fillInner={getThemeProps(
                                        'NavItem.styles.backgroundColor',
                                        DEFAULT_COLORS.BLACK,
                                        {
                                            theme,
                                        }
                                    )}
                                    height="27"
                                    width="27"
                                    onClick={onClickSearch}
                                />
                            )}
                        </NavItem>
                        <NavItem>{showContentAi && <AINav />}</NavItem>
                        <NavItem>
                            <NotificationWidget history={history} isMobile />
                        </NavItem>
                        <NavItem>
                            {userNav.show && (
                                <MobileUserNav
                                    items={userNav.items}
                                    onNavClick={navLink => {
                                        onUserNavClick(navLink);
                                    }}
                                    user={user}
                                />
                            )}
                        </NavItem>
                        <NavItem>
                            <HamburgerMenuIcon
                                fill={getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, {
                                    theme,
                                })}
                                height="27"
                                width="30"
                                viewBox="0 0 22 19"
                                onClick={onMenuClick}
                            />
                        </NavItem>
                    </NavItems>
                </MobileAppNavigationStyled>
                <SideMenu
                    appNavItems={appNav.items}
                    isOpen={isMenuOpen}
                    onClose={(selectedParent, selectedChild) => {
                        onSideMenuClose(selectedParent, selectedChild);
                    }}
                />
            </>
        )
    );
};

MobileAppNavigation.propTypes = {
    appNav: object,
    authorized: bool,
    history: object,
    isSoftLogin: bool.isRequired,
    sendPendoEvent: func.isRequired,
    theme: object.isRequired,
    updateClickSafetyMenu: func.isRequired,
    user: object,
    userCompany: object.isRequired,
    userNav: object,
    userPermissionsOriginal: arrayOf(
        shape({
            permissionKey: string,
        })
    ),
};

MobileAppNavigation.defaultProps = {
    appNav: {},
    authorized: false,
    history: {},
    user: {},
    userNav: {},
    userPermissionsOriginal: [],
};

const mapStateToProps = createStructuredSelector({
    userCompany: userSelectors.getUserCompany,
    userPermissionsOriginal: userSelectors.getUserPermissionsOriginal,
});

const mapDispatchToProps = {
    sendPendoEvent: pendoActions.sendPendoEvent,
    updateClickSafetyMenu: userActions.updateClickSafetyMenu,
};

export { MobileAppNavigation as MobileAppNavigationUnWrapped };
export default compose(
    withTheme,
    withPublicContent,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MobileAppNavigation);
