import * as authActions from 'modules/auth/actions';
import * as ticketActions from 'modules/tickets/actions';
import { createSelector } from 'reselect';
import { createReducer } from 'utils/reducer';
import { get } from 'ravenjs/utils/lodash';

const INITIAL_STATE = {
    areCaseMetricsCollapsed: false,
    recentCaseId: '',
    messageTypes: [],
    caseTopics: {},
    caseTypes: [],
    caseSources: [],
    caseStatuses: [],
    resourceTypes: [],
    taskTypes: [],
    tickets: {},
};

/**
 * Reducer helper for the fetching the create ticket (success).
 *
 * @method fetchCreateTicketSuccess
 * @param  {Object}         state  The 'ticket' state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const fetchCreateTicketSuccess = (state, action) => {
    return {
        ...state,
        recentCaseId: get(action, 'payload.data.id', ''),
    };
};

/**
 * Reducer helper for fetching messages (success).
 *
 * @method formatMessageTypes
 * @param  {Object}         state  The 'ticket' state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const formatMessageTypes = (state, action) => {
    return {
        ...state,
        messageTypes: get(action, 'payload', ''),
    };
};

/**
 * Reducer helper for fetching topics (success).
 *
 * @method formatCaseTopics
 * @param  {Object}         state  The 'ticket' state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const formatCaseTopics = (state, action) => {
    return {
        ...state,
        caseTopics: get(action, 'payload', ''),
    };
};

export const formatCaseStatuses = (state, action) => {
    return {
        ...state,
        caseStatuses: get(action, 'payload', ''),
    };
};

export const formatCaseTypes = (state, action) => {
    return {
        ...state,
        caseTypes: get(action, 'payload', ''),
    };
};

export const formatCaseSources = (state, action) => {
    return {
        ...state,
        caseSources: get(action, 'payload', ''),
    };
};

export const setCaseMetricsCollapsed = (state, action) => {
    return {
        ...state,
        areCaseMetricsCollapsed: get(action, 'payload', false),
    };
};

export const formatTaskTypes = (state, action) => {
    return {
        ...state,
        taskTypes: get(action, 'payload', ''),
    };
};

export const formatResourceTypes = (state, action) => {
    return {
        ...state,
        resourceTypes: get(action, 'payload', ''),
    };
};

export const formatTickets = (state, action) => {
    return {
        ...state,
        tickets: get(action, 'payload', {}),
    };
};

const logoutSuccess = () => INITIAL_STATE;

const tickets = {
    [ticketActions.setCaseMetricsCollapsed.TRIGGER]: setCaseMetricsCollapsed,
    [ticketActions.createTicket.SUCCESS]: fetchCreateTicketSuccess,
    [ticketActions.getMessageTypes.SUCCESS]: formatMessageTypes,
    [ticketActions.fetchCaseTopics.SUCCESS]: formatCaseTopics,
    [ticketActions.fetchMyCaseStatuses.SUCCESS]: formatCaseStatuses,
    [ticketActions.fetchMyCaseTypes.SUCCESS]: formatCaseTypes,
    [ticketActions.fetchCaseSources.SUCCESS]: formatCaseSources,
    [authActions.logout.SUCCESS]: logoutSuccess,
    [ticketActions.fetchCaseTaskTypes.SUCCESS]: formatTaskTypes,
    [ticketActions.fetchCaseResourceTypes.SUCCESS]: formatResourceTypes,
    [ticketActions.fetchTickets.SUCCESS]: formatTickets,
};

export default createReducer(INITIAL_STATE, tickets);

export const getTickets = state => get(state, 'tickets', {});

export const getRecentCreatedCase = createSelector([getTickets], tickets =>
    get(tickets, 'recentCaseId')
);

export const getMessageTypes = createSelector([getTickets], tickets =>
    get(tickets, 'messageTypes')
);

export const getCaseTopics = createSelector([getTickets], tickets => get(tickets, 'caseTopics'));

export const getCaseMetricsCollapsed = createSelector([getTickets], tickets =>
    get(tickets, 'areCaseMetricsCollapsed', false)
);

export const getCases = createSelector([getTickets], tickets => get(tickets, 'tickets.data', []));
