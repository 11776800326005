import { call, put } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import { fetchUserPermissions } from 'modules/apis';
import * as permissionsActions from '../actions';

/**
 * Saga to fetch permissions
 *
 * @method fetchPermissions
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchPermissionsSaga(action) {
    try {
        const { payload: param } = action;
        const response = yield call(fetchUserPermissions, param);
        const permissions = get(response, 'data.permissions', []);
        yield put(permissionsActions.fetchPermissions.success(permissions));
        return permissions;
    } catch (error) {
        yield put(permissionsActions.fetchPermissions.error(error));
        return error;
    }
}
