import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';

import EditIcon from 'components/Icons/EditIcon';

import InfoIcon from '../SvgIcon/types/InfoIcon';
import Tooltip from '../Tooltip';
import TooltipContent from '../TooltipContent';
import ArrowUpsideDownIcon from '../SvgIcon/types/ArrowUpsideDownIcon';

function TableCellSortIcon(props) {
    const { sortSchema, onSort } = props;
    // this field comes from the schema
    const extraCss = {
        margin: '0 0.438rem 0 0.438rem',
        ..._get(sortSchema, 'extraCss', ''),
    };
    const showSortIcon = !_isEmpty(_get(sortSchema, 'orderBy'));
    return showSortIcon && <ArrowUpsideDownIcon onClick={onSort} extraCss={extraCss} />;
}

TableCellSortIcon.propTypes = {
    onSort: PropTypes.func,
    sortSchema: PropTypes.object,
};

TableCellSortIcon.defaultProps = {
    onSort: null,
    sortSchema: null,
};

function renderTableCellSortable(props, Component) {
    const {
        children,
        infoText,
        cellProps: { cellHead },
        ...rest
    } = props;
    function renderInfoIcon(text) {
        return (
            <Tooltip
                content={
                    <TooltipContent
                        text={text}
                        bodyStyle={{
                            borderRadius: '8px',
                            lineHeight: '16px',
                            padding: '8px',
                        }}
                    />
                }
                padding="0"
                margin={0}
                arrowSize={0}
                color="dark"
                placement="top"
                hasFlip={false}
            >
                <InfoIcon
                    extraCss={{ verticalAlign: 'text-bottom' }}
                    margin="auto 0"
                    height="18px"
                    width="30px"
                />
            </Tooltip>
        );
    }

    const renderEditIcon = () => {
        return (
            <EditIcon
                extraCss={{ verticalAlign: 'text-bottom' }}
                margin="auto 0"
                height="18px"
                width="24px"
            />
        );
    };

    const cellProps = {
        ..._omit(rest, ['onSort']),
    };
    return (
        <Component {...cellProps}>
            {_get(props, 'sortSchema.iconPosition') === 'left' ? TableCellSortIcon(props) : null}
            {children}
            {_get(props, 'sortSchema.iconPosition') === 'left' ? null : TableCellSortIcon(props)}
            {infoText && renderInfoIcon(infoText)}
            {cellHead === 'custom' && renderEditIcon()}
        </Component>
    );
}

renderTableCellSortable.propTypes = {
    children: PropTypes.node,
    infoText: PropTypes.string,
};

renderTableCellSortable.defaultProps = {
    children: null,
    infoText: null,
};

export default renderTableCellSortable;
