const CASE_CAVE = {
    CLICK_SHOW_MORE: {
        event: 'caseCaveUserDetailsClickShowMore',
        eventProperties: {
            description: 'Expanding case cave user details section',
        },
    },
    CLICK_SHOW_LESS: {
        event: 'caseCaveUserDetailsClickShowLess',
        eventProperties: {
            description: 'Collapsing case cave user details section',
        },
    },
};

export default CASE_CAVE;
