import axios from 'axios';
import Qs from 'qs';

/**
 * Fetch esignature document list
 *
 * @method fetchEsignatureUserDocumentsAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const fetchEsignatureUserDocumentsAPI = payload => {
    return axios({
        method: 'get',
        url: '/v2/signatures',
        params: payload,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

/**
 * Download esignature document
 *
 * @method downloadDocumentForUserAPI
 * @param  {string}             signatureId
 * @return {Promise}
 */
export const downloadDocumentForUserAPI = signatureId => {
    return axios({
        method: 'get',
        url: `v2/signatures/${signatureId}/download`,
        headers: {
            Accept: 'text/pdf',
        },
        responseType: 'blob',
    });
};

/**
 * Fetching esignature for document
 *
 * @method fetchSignatureAPI
 * @param  {string}             signatureId
 * @return {Promise}
 */
export const fetchSignatureAPI = signatureId => {
    return axios({
        method: 'get',
        url: `v2/signatures/${signatureId}/esignatureSigningURL`,
    });
};

/**
 * Fetching esignature document
 *
 * @method fetchESignedDocumentsAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const fetchESignedDocumentsAPI = payload => {
    return axios({
        method: 'get',
        url: `v2/sign-documents`,
        params: payload,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

/**
 * Fetching Embedded Hello Sign for document
 *
 * @method fetchEmbeddedHelloSignUrlAPI
 * @param  {string}             token
 * @return {Promise}
 */
export const fetchEmbeddedHelloSignUrlAPI = token => {
    return axios({
        method: 'get',
        url: `/v2/signatures/token/${token}/embeddedSigningURL`,
    });
};

/**
 * Submitting request for e-signature for document
 *
 * @method postDocumentForSignaturesAPI
 * @param  {Object}         data
 * @param  {boolean}        sendToAll
 * @param  {number|string}  signatureDocumentId
 * @return {Promise}
 */
export const postDocumentForSignaturesAPI = (data, sendToAll, signatureDocumentId) =>
    axios({
        method: 'post',
        data,
        params: {
            sendToAll,
            signatureDocumentId,
        },
        url: '/v2/signatures',
    });

/**
 * Request to cancel ALL esignature for a document
 *
 * @method cancelAllEsignatureRequestAPI
 * @param  {string}             uuid
 * @return {Promise}
 */
export const cancelAllEsignatureRequestAPI = uuid => {
    return axios({
        method: 'delete',
        url: `/v2/sign-documents/${uuid}/signatures`,
    });
};
/**
 * Request to download document from Hello Sign
 *
 * @method downloadDocumentFromHelloSignSagaAPI
 * @param  {string}             signatureId
 * @return {Promise}
 */
export const downloadDocumentFromHelloSignSagaAPI = signatureId => {
    return axios({
        method: 'GET',
        url: `v2/sign-documents/${signatureId}/download`,
        headers: {
            Accept: 'text/pdf',
        },
        responseType: 'blob',
    });
};

/**
 * Request to fetch Sign Documents
 *
 * @method fetchSignDocumentDetailsAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const fetchSignDocumentDetailsAPI = payload => {
    // Fix: https://thinkhr.atlassian.net/browse/GN-10376
    let url = '/v2/signatures';
    if (payload.hasOwnProperty('sort') && payload.sort === '-createdOn') {
        delete payload.sort;
        url = '/v2/signatures?sort=-createdOn&sort=-signatureId';
    }
    return axios({
        method: 'get',
        params: payload,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        url,
    });
};

/**
 * Request to save document uploaded
 *
 * @method saveUploadDocumentInfoAPI
 * @param  {string}             data
 * @return {Promise}
 */
export const saveUploadDocumentInfoAPI = data => {
    return axios({
        method: 'post',
        data,
        url: `v2/sign-documents`,
    });
};

/**
 * Request to submit the request for uploaded document
 *
 * @method uploadDocumentForSignaturesAPI
 * @param  {string}             data
 * @return {Promise}
 */
export const uploadDocumentForSignaturesAPI = data => {
    const { signatureDocumentId, sendToAll, fileData } = data;
    return axios({
        method: 'post',
        data: fileData,
        url: `/v2/signatures?sendToAll=${sendToAll}&signatureDocumentId=${signatureDocumentId}`,
    });
};

/**
 * Request to cacncel the request for esignature for a document
 *
 * @method cancelIndividualEsignatureAPI
 * @param  {string}             uuid
 * @return {Promise}
 */
export const cancelIndividualEsignatureAPI = uuid => {
    return axios({
        method: 'delete',
        url: `/v2/signatures/${uuid}`,
    });
};

/**
 * Request to send reminder for esignatures
 *
 * @method remindEsignatureRequestAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const remindEsignatureRequestAPI = payload => {
    const { uuid, mailSubject, noteToSigner } = payload;
    return axios({
        method: 'post',
        data: { noteToSigner, mailSubject },
        url: `/v2/signatures/${uuid}/reminders`,
    });
};
