import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const WordFileIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                d="M21.8,8.5c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0
                l-7.6-7.6c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0H5.5
                C4.6,0,3.8,0.3,3.1,1c-0.6,0.6-1,1.4-1,2.3v17.5c0,0.9,0.3,1.7,1,2.3c0.6,0.6,1.4,1,2.3,1h13.1c0.9,0,1.7-0.3,2.3-1s1-1.4,1-2.3
                v-12C21.8,8.7,21.8,8.6,21.8,8.5z M14.2,3.7l3.9,3.9h-3.9V3.7z M19.3,21.5c-0.2,0.2-0.5,0.3-0.8,0.3H5.5c-0.3,0-0.6-0.1-0.8-0.3
                c-0.2-0.2-0.3-0.5-0.3-0.8V3.3c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3H12v6.5c0,0.6,0.5,1.1,1.1,1.1h6.5v10.9
                C19.6,21,19.5,21.3,19.3,21.5z"
                fill={fill}
            />
            <path
                d="M12.4,12h-0.9c-0.1,0-0.3,0.1-0.3,0.2l-1.3,5.3l-1.2-5.3c0-0.1-0.2-0.2-0.3-0.2H7.4c-0.2,0-0.3,0.1-0.3,0.3
                c0,0,0,0.1,0,0.1l2,7.4c0,0.1,0.2,0.2,0.3,0.2h0.9c0.1,0,0.3-0.1,0.3-0.2l1.4-5.3l1.4,5.3c0,0.1,0.2,0.2,0.3,0.2h0.8
                c0.1,0,0.3-0.1,0.3-0.2l2-7.4c0-0.2-0.1-0.3-0.2-0.4c0,0-0.1,0-0.1,0h-0.9c-0.1,0-0.3,0.1-0.3,0.2l-1.2,5.3l-1.3-5.3
                C12.7,12.1,12.6,12,12.4,12L12.4,12z"
                fill={fill}
            />
        </SvgIcon>
    );
};

WordFileIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

WordFileIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default WordFileIcon;
