import React from 'react';
import { string } from 'prop-types';

import { COLORS } from 'ravenjs/constants/colors';
import SvgIcon from '../SvgIcon';

const HidePasswordIcon = ({ fill, height, width }) => {
    return (
        <SvgIcon width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m10.84 5.994.085-.013A7.03 7.03 0 0 1 12 5.901m-1.16.093L12 6m-1.16-.006v.038a.914.914 0 0 1-.223-1.813c.457-.082.92-.121 1.382-.119 4.05 0 7.82 2.875 9.83 7.54m-10.99-5.646L12 4c4.1 0 7.9 2.91 9.92 7.6M12 5.9V6m0-.1V6m0-.1c3.231 0 6.25 2.325 8 6.058l.02.042-.02.043c-.267.568-.57 1.12-.905 1.65l-.001.002a.9.9 0 0 0-.144.494M12 6c3.18 0 6.17 2.29 7.91 6a15.213 15.213 0 0 1-.9 1.64 1 1 0 0 0-.16.55m.1 0h-.1m.1 0a.9.9 0 0 0 1.674.45l.002-.004c.463-.727.865-1.491 1.202-2.285a.9.9 0 0 0 0-.711m-2.878 2.55h-.1m0 0a1 1 0 0 0 1.86.5 15.76 15.76 0 0 0 1.21-2.3 1 1 0 0 0 0-.79m0 0-.092.04m.092-.04-.092.04M2.08 12.4C4.1 17.09 7.9 20 12 20m-9.92-7.6 19.63 7.89a.997.997 0 0 1 .219 1.095.999.999 0 0 1-.22.325 1 1 0 0 1-1.42 0l-3.24-3.25A9.26 9.26 0 0 1 12 20m-9.92-7.6a1 1 0 0 1 0-.8 14.62 14.62 0 0 1 3.24-4.73v.001l.07-.07-.068-.073-.002.002-3.24 5.67zM12 20v-.1m0 .1v-.1m0 0a9.16 9.16 0 0 0 4.996-1.523l.068-.045.058.058 3.24 3.25a.901.901 0 0 0 1.278 0 .9.9 0 0 0 0-1.279l-18-18a.904.904 0 0 0-1.278 1.278l3.1 3.09.072.072-.074.071a14.52 14.52 0 0 0-3.287 4.767v.001a.9.9 0 0 0 0 .72C4.18 17.026 7.95 19.9 12 19.9zm-1.978-8.61.12.12 2.45 2.45.12.12-.164.046A1.91 1.91 0 0 1 12 14.1m-1.978-2.81L12 14m-1.98-2.71-.046.163A1.91 1.91 0 0 0 9.9 12m.12-.712L10 12m2 2.1h-.001V14m0 .1V14m0 .1A2.1 2.1 0 0 1 9.9 12m2.1 2a2 2 0 0 1-2-2m-.1 0 .001.001h.1m-.1 0h.1m2.52 1.93-.07.07-.043-.042c.038-.009.076-.018.113-.029zm0 0-.028-.097-.054.015.082.081zm-2.45-2.45.096.027a1.73 1.73 0 0 0-.014.054l-.082-.082zm0 0-.07.07.041.042c.009-.038.018-.075.029-.113zM12 18.1H12a7.34 7.34 0 0 0 3.64-1.015l.112-.066-.093-.091-1.59-1.57-.053-.052-.065.035a3.9 3.9 0 0 1-5.294-5.294l.035-.065-.052-.053-1.77-1.79-.069-.07-.07.068a12.19 12.19 0 0 0-2.723 3.82l-.02.043.02.041C5.75 15.775 8.77 18.1 12 18.1z"
                fill={fill}
                stroke="#F9FAFA"
                strokeWidth=".2"
            />
        </SvgIcon>
    );
};

HidePasswordIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
};

HidePasswordIcon.defaultProps = {
    fill: COLORS.BLACK_3,
    height: '24',
    width: '24',
};

export default HidePasswordIcon;
