import { createActions } from 'utils/actions';

/**
 * Redux actions for setting the loading state on the ui module.
 *
 * @type {Object}
 */
export const isLoading = createActions('ui', 'IS_LOADING', {
    payloadCreator: (loading, text) => ({ loading, text }),
});
