import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CompleteIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.6095 7.05715C28.1302 7.57785 28.1302 8.42207 27.6095 8.94277L12.9428 23.6094C12.4221 24.1301 11.5779 24.1301 11.0572 23.6094L4.39052 16.9428C3.86983 16.4221 3.86983 15.5778 4.39052 15.0572C4.91122 14.5365 5.75544 14.5365 6.27614 15.0572L12 20.781L25.7239 7.05715C26.2446 6.53645 27.0888 6.53645 27.6095 7.05715Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CompleteIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CompleteIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '32',
    viewBox: '0 0 32 32',
    width: '32',
};

export default CompleteIcon;
