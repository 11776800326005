import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HandbookValueIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill={color}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)">
                    <circle stroke={color} cx="29" cy="29" r="29" />
                    <g transform="translate(14.000000, 14.000000)">
                        <polygon
                            fill={colorInner}
                            points="5.2449 0.0005 7.6542 0.0005 7.6542 2.4098 5.2449 2.4098"
                        />
                        <path
                            d="M30.3278,1.9229 L2.2583,1.9229 C1.8128,1.9229 1.4516,2.284 1.4516,2.7296 L1.4516,29.8597 C1.4516,30.3052 1.8128,30.6664 2.2583,30.6664 L30.3278,30.6664 C30.7734,30.6664 31.1346,30.3052 31.1346,29.8597 L31.1346,2.7296 C31.1346,2.284 30.7734,1.9229 30.3278,1.9229 Z"
                            fill={colorInner}
                            fillRule="nonzero"
                        />
                        <path
                            d="M7.3279,0 L30.1596,0 C30.6977,0 31.1346,0.378 31.1346,0.8435 L31.1346,25.5214 C30.686,25.5226 30.3609,25.523 30.1596,25.523 C30.0713,25.5363 29.9967,25.546 29.9196,25.546 L7.3279,25.546 L7.3279,0 Z M31.135,31.1565 L31.135,27.982 L31.135,27.1389 L29.1843,27.1389 L29.1843,30.3135 L5.3635,30.3135 L5.3635,31.9996 L31.1346,31.9996 L31.1346,31.1565 L31.135,31.1565 Z M4.7124,30.3131 C3.2304,30.3131 2.0203,29.2958 1.9614,28.0268 C1.9626,28.0042 1.9671,27.9828 1.9667,27.9598 C1.9659,27.9227 1.9643,27.865 1.9634,27.7884 C2.0486,26.5399 3.2474,25.5456 4.7124,25.5456 L5.3772,25.5456 L5.3772,0 L4.7136,0 C2.1179,0 0.0063,1.8265 0.0063,4.0704 C0.0063,4.1007 0.0131,4.1293 0.0168,4.1584 C0.0131,6.2313 -0.0167,25.279 0.0131,27.7831 C0.0115,27.8319 0.0043,27.8799 0.0043,27.9292 C0.0043,30.0872 1.9582,31.8536 4.416,31.9871 C4.4652,31.9935 4.5136,32 4.5648,32 L5.3772,32 L5.3772,30.3131 L4.7124,30.3131 Z"
                            fill={color}
                        />
                        <polygon
                            fill={color}
                            points="4.9186 30.3086 6.6095 30.3086 6.6095 31.9995 4.9186 31.9995"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

HandbookValueIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

HandbookValueIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default HandbookValueIcon;
