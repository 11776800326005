import axios from 'axios';

/**
 * API to fetch minimum wage map.
 *
 * @method fetchMinWageMapData
 * @return {Promise}
 */
export const fetchMinWageMapAPI = () =>
    axios({
        method: 'get',
        url: 'v2/minimumWageMap',
    });
