import APP_PERMISSIONS_LIST from './permissions.json';

/**
 * Create an empty App Permissions Map
 *
 * @type {Object}
 */
const APP_PERMISSIONS = {};

// For each of the permissions defined in the App Permissions List,
// we want to add their `key` to the App Permissions Map.filter((item) => {})
for (const permission in APP_PERMISSIONS_LIST) {
    if (Object.prototype.hasOwnProperty.call(APP_PERMISSIONS_LIST, permission)) {
        APP_PERMISSIONS[permission] = permission;
    }
}

export { APP_PERMISSIONS, APP_PERMISSIONS_LIST };
