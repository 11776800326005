import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';
import * as uiActions from './actions';

const INITIAL_STATE = {
    isIdle: false,
};

const idle = {
    [uiActions.setIdleStatus.success]: (state, { payload: isIdle }) => ({
        ...state,
        isIdle,
    }),
};

export default createReducer(INITIAL_STATE, idle);

/**
 * Get state of the 'idle' store
 *
 * @method getIdleState
 * @param  {Object}      state The current state
 * @return {Object}            The idle state
 */
export const getIdleState = state => _get(state, 'ui.idle', {});

/**
 * Get the idle status
 *
 * @method getIdleStatus
 * @param  {Object}     state
 * @return {boolean}
 */
export const getIdleStatus = createSelector([getIdleState], idle => _get(idle, 'isIdle', false));
