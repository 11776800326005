import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const BenefitsDocumentCreatorValueIcon = ({
    color,
    colorInner,
    height,
    viewBox,
    width,
    ...rest
}) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill={color}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)">
                    <circle stroke={color} cx="29" cy="29" r="28.6375" />
                    <g transform="translate(13.000000, 14.000000)">
                        <path
                            d="M31.8855,27.3027 L24.7192,0.4716 C24.6973,0.3874 24.6467,0.2307 24.5693,0 C24.3688,0.064 24.2257,0.1078 24.1381,0.1314 C19.9208,1.2733 17.7836,1.8543 17.7246,1.8712 C17.6657,1.888 17.5747,1.9217 17.4552,1.9688 L17.4552,0.9886 L17.4552,0.5103 L3.1326,0.5103 L3.1326,29.2463 L17.4535,29.2463 L17.4535,2.8699 L24.5069,29.2867 C24.5305,29.3709 24.5659,29.5225 24.6131,29.7364 L25.0914,29.6253 C29.2952,28.4935 31.4476,27.9158 31.5453,27.8905 L32,27.7608 L31.8855,27.3027 Z"
                            fill={color}
                        />
                        <polygon
                            fill={color}
                            points="9.8156 29.2293 0 29.2293 0 0.522 9.8156 0.522"
                        />
                        <path
                            d="M1.8173,26.0956 L6.3596,26.0956 L6.3596,27.7798 L1.8173,27.7798 L1.8173,26.0956 Z M1.8173,24.083 L6.3596,24.083 L6.3596,25.7672 L1.8173,25.7672 L1.8173,24.083 Z M11.3701,26.0956 L15.9124,26.0956 L15.9124,27.7798 L11.3701,27.7798 L11.3701,26.0956 Z M11.3701,24.083 L15.9124,24.083 L15.9124,25.7672 L11.3701,25.7672 L11.3701,24.083 Z M11.3701,4.0796 L15.9124,4.0796 L15.9124,5.7638 L11.3701,5.7638 L11.3701,4.0796 Z"
                            fill={colorInner}
                        />
                        <polygon
                            fill={colorInner}
                            points="9.7819 0.522 9.7819 29.2647 8.2088 29.2647 8.2088 0.522"
                        />
                        <polygon
                            fill={colorInner}
                            points="11.3701 2.0664 15.9124 2.0664 15.9124 3.7506 11.3701 3.7506"
                        />
                        <path
                            d="M24.0605,4.1162 L24.3689,5.3532 L19.9615,6.4521 L19.6531,5.2151 L24.0605,4.1162 Z M23.5736,2.1633 L23.882,3.4004 L19.4746,4.4993 L19.1662,3.2622 L23.5736,2.1633 Z"
                            fill={colorInner}
                        />
                        <path
                            d="M29.8205,25.4961 L30.1289,26.7331 L25.7215,27.832 L25.4131,26.5949 L29.8205,25.4961 Z M29.3336,23.5432 L29.642,24.7803 L25.2346,25.8792 L24.9262,24.6421 L29.3336,23.5432 Z"
                            fill={colorInner}
                        />
                        <path
                            d="M1.8173,4.079 L6.3596,4.079 L6.3596,5.7632 L1.8173,5.7632 L1.8173,4.079 Z M1.8173,2.0664 L6.3596,2.0664 L6.3596,3.7506 L1.8173,3.7506 L1.8173,2.0664 Z"
                            fill={colorInner}
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

BenefitsDocumentCreatorValueIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

BenefitsDocumentCreatorValueIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default BenefitsDocumentCreatorValueIcon;
