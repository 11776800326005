import styled from 'styled-components';

import InputStyles from 'ravenjs/lib/Input/InputStyles';
import { themeGet } from 'ravenjs/utils/theme';
import { DEFAULT_COLORS } from 'constants/colors';

const TextAreaStyled = styled.textarea`
    ${InputStyles};
    height: ${({ height }) => height || 'unset'};
    max-height: 225px; /* showing a maximum of about 10 rows */
    max-width: 100%;
    min-width: 100%;
    border-color: ${({ showRequired }) =>
        showRequired ? DEFAULT_COLORS.RED : DEFAULT_COLORS.GREY_16};
    overflow: auto;
    padding: ${({ padding }) => padding || '6px 15px'};
    resize: ${({ resize }) => resize || 'vertical'};
    /**
     * Add all of the remaining styles from theme
     */
    ${themeGet('TextArea.styles')};
`;

export default TextAreaStyled;
