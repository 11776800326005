import { object } from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

import Tooltip from 'ravenjs/lib/Tooltip';
import { getThemeProps } from 'ravenjs/utils/theme';

import { AriesIcon } from 'components/Icons';
import { TRY_ARIES } from 'constants/ai';
import { DEFAULT_COLORS } from 'constants/colors';

const NewToolIcon = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) => theme.media.up('md')`
        margin: 0 0.53rem;
    `};
    &::before {
        content: '';
        position: absolute;
        background-color: ${DEFAULT_COLORS.BLUE};
        width: 8px;
        height: 8px;
        top: -4px;
        right: -4px;
        border-radius: 50%;
    }
`;

function AINav({ theme }) {
    const renderIcon = () => {
        return (
            <NewToolIcon>
                <AriesIcon
                    fill={getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, {
                        theme,
                    })}
                    width="20px"
                    height="20px"
                    cursor="pointer"
                    isActive
                    onClick={() => {
                        window.location.href = '/aries';
                    }}
                />
            </NewToolIcon>
        );
    };

    return (
        <Tooltip
            content={TRY_ARIES}
            padding="4px 16px"
            margin={0}
            arrowSize={5}
            placement="top"
            borderRadius="5px"
        >
            {renderIcon()}
        </Tooltip>
    );
}

AINav.propTypes = {
    theme: object.isRequired,
};

export { AINav as AINavUnwrapped };
export default withTheme(AINav);
