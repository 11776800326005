import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeLawAlertsIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path fill={color} d="M14 14L0 14 7 0z" />
                        <path fill={colorInner} d="M6.65 5.292H7.65V9.457H6.65z" />
                        <path
                            fill={colorInner}
                            d="M6.524 10.535H7.524V11.535H6.524z"
                            transform="rotate(180 6.997 11.008)"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeLawAlertsIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeLawAlertsIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeLawAlertsIcon;
