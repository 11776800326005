import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    fetchImportedContactsListAPI,
    fetchWelcomeEmailIntroAPI,
    previewWelcomeEmailAPI,
    sendWelcomeEmailAPI,
    sendEmailAPI,
    updateWelcomeEmailAPI,
    fetchEmailPreviewAPI,
    fetchUnpublishedContentPreviewAPI,
} from 'modules/apis';

import * as adminActions from '../actions';

/**
 * Fetch imported contacts list
 *
 * @method fetchImportedContactsListSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* fetchImportedContactsListSaga(action) {
    try {
        const { payload: companyId } = action;
        const resp = yield call(fetchImportedContactsListAPI, companyId);
        const batchList = resp;
        yield put(adminActions.fetchImportedContactsList.success(batchList));
        return batchList;
    } catch (error) {
        yield put(adminActions.fetchImportedContactsList.error(error));
        return error;
    }
}

/**
 * Fetch Welcome Email preview
 *
 * @method fetchWelcomeEmailPreviewSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchWelcomeEmailPreviewSaga(action) {
    try {
        const {
            payload: { partnerId, userId },
        } = action;
        const response = yield call(previewWelcomeEmailAPI, partnerId, userId);
        const emailData = get(response, 'data');
        const emailTemplate = get(emailData, 'email-templates.0');
        yield put(adminActions.fetchWelcomeEmailPreview.success(emailTemplate));
        return emailTemplate;
    } catch (e) {
        yield put(adminActions.fetchWelcomeEmailPreview.error(e));
        return e;
    }
}

/**
 * Send Welcome email to the users
 *
 * @method sendWelcomeEmailSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* sendWelcomeEmailSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(sendWelcomeEmailAPI, payload);
        const message = resp;
        yield put(adminActions.sendWelcomeEmail.success(resp));
        return message;
    } catch (e) {
        yield put(adminActions.sendWelcomeEmail.error(e));
        return e;
    }
}

/**
 * Fetch Welcome Email Intro Saga
 *
 * @method fetchWelcomeEmailIntroSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchWelcomeEmailIntroSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchWelcomeEmailIntroAPI, payload);
        const emailTemplate = get(resp, 'data');
        yield put(adminActions.fetchWelcomeEmailIntro.success(emailTemplate));
        return emailTemplate;
    } catch (e) {
        yield put(adminActions.fetchWelcomeEmailIntro.error(e));
        return e;
    }
}

/**
 * Update Welcome Email Saga
 *
 * @method updateWelcomeEmailSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* updateWelcomeEmailSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(updateWelcomeEmailAPI, payload);
        const emailTemplate = get(resp, 'data');
        yield put(adminActions.updateWelcomeEmail.success(emailTemplate));
        return emailTemplate;
    } catch (e) {
        yield put(adminActions.updateWelcomeEmail.error(e));
        return e;
    }
}

/**
 * Send communication email to the users.
 *
 * @method sendCommunicationEmailSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* sendCommunicationEmailSaga(action) {
    try {
        const {
            payload: { partnerId, body },
        } = action;
        const resp = yield call(sendEmailAPI, partnerId, body);
        const message = resp;
        yield put(adminActions.sendCommunicationEmail.success(resp));
        return message;
    } catch (e) {
        yield put(adminActions.sendCommunicationEmail.error(e));
        return e;
    }
}

/**
 * Fetch Email Preview.
 *
 * @method fetchEmailPreviewSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchEmailPreviewSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchEmailPreviewAPI, payload);
        const emailData = get(response, 'data');
        const emailTemplate = get(emailData, 'email-templates.0');
        yield put(adminActions.fetchEmailPreview.success(emailTemplate));
        return emailTemplate;
    } catch (e) {
        yield put(adminActions.fetchEmailPreview.error(e));
        return e;
    }
}

/**
 * Fetch Unpublished Content Preview.
 *
 * @method fetchUnpublishedContentPreviewSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchUnpublishedContentPreviewSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchUnpublishedContentPreviewAPI, payload);
        const emailData = get(response, 'data');
        const emailTemplate = get(emailData, 'email-templates.0');
        yield put(adminActions.fetchUnpublishedContentPreview.success(emailTemplate));
        return emailTemplate;
    } catch (e) {
        yield put(adminActions.fetchUnpublishedContentPreview.error(e));
        return e;
    }
}
