import { DEFAULT_COLORS } from 'constants/colors';
import { STATIC_JSON_ORIGIN } from 'constants/api';

export const CATEGORY_NAMES = {
    OTHER: 'Other',
    WAGE_AND_HOUR: 'Wage & Hour',
    WAGES_AND_HOURS: 'Wages & Hours',
};

export const CONTENT_COLORS = {
    CHART: DEFAULT_COLORS.RED,
    CHECKLIST: DEFAULT_COLORS.ORANGE,
    FORM: DEFAULT_COLORS.YELLOW,
    GUIDE_WHITE_PAPER: DEFAULT_COLORS.GREEN,
    INFOGRAPHIC: DEFAULT_COLORS.BLUE,
    LAW: DEFAULT_COLORS.PEACH,
    LAW_ALERT: DEFAULT_COLORS.RED,
    LETTER: DEFAULT_COLORS.YELLOW,
    POLICY: DEFAULT_COLORS.ORANGE,
    QUESTION_AND_ANSWER: DEFAULT_COLORS.GREEN,
    TOOLKIT: DEFAULT_COLORS.CATEGORY_HIRING,
    TWO_MIN_HR: DEFAULT_COLORS.CATEGORY_MANAGING_EMPLOYEES,
    WEBINARS: DEFAULT_COLORS.PLACEHOLDER,
};

export const CONTENT_NAMES = {
    ALL: 'All',
    CHART: 'Charts',
    CHECKLIST: 'Checklists',
    FORM: 'Forms',
    GUIDE_WHITE_PAPER: 'Guides',
    INFOGRAPHIC: 'Infographics',
    LAW: 'Laws',
    LAW_ALERT: 'Law Alerts',
    LETTER: 'Letters',
    POLICY: 'Sample Policies',
    QUESTION_AND_ANSWER: 'Q&A',
    TOOLKIT: 'Toolkits',
    TWO_MIN_HR: 'Videos',
    WEBINARS: 'Webinars',
    WEBINARSONDEMAND: 'Webinars',
    'WEBINARS-ON-DEMAND': 'Webinars',
    HELP_CENTER: 'Help Center',
};

export const CONTENT_NAMES_SINGULAR = {
    CHART: 'Chart',
    CHECKLIST: 'Checklist',
    FORM: 'Form',
    GUIDE_WHITE_PAPER: 'Guide',
    INFOGRAPHIC: 'Infographic',
    LAW: 'Law',
    LAW_ALERT: 'Law Alert',
    LETTER: 'Letter',
    POLICY: 'Sample Policy',
    QUESTION_AND_ANSWER: 'Q&A',
    TOOLKIT: 'Toolkit',
    TWO_MIN_HR: 'Video',
    WEBINARS: 'Webinar',
    WEBINARSONDEMAND: 'Webinar',
};

export const CONTENT_SECTION_NAMES = {
    COMPLIANCE: 'HR Compliance',
    FUNDAMENTALS: 'Resources',
    FORMS_AND_LETTERS: 'Templates',
};

// TODO: merge into CONTENT_TYPES and convert usages
export const CONTENT_TYPE = {
    DOCUMENT: 'documents',
    JOB_DESCRIPTION: 'job-descriptions',
    LAW: 'law',
    LAW_ALERT: 'lawAlert',
    LINK: 'link',
    QUESTION_AND_ANSWER: 'questionAndAnswer',
    TOOLKIT: 'toolkit',
    // TODO: change to TWO_MIN_HR
    TWO_MHR: '2mhr',
};

export const CONTENT_TYPES = {
    CHARTS: 'charts',
    DOCUMENT: 'document',
    GUIDES: 'guides',
    HR_CHECKLIST: 'hr-checklist',
    // TODO: change to HR_CHECKLIST when BE supports
    HR_CHECKLISTS: 'hr-checklists',
    JOB_DESCRIPTION: 'jobDescription',
    LAW: 'law',
    LAW_ALERT: 'lawAlert',
    LINK: 'link',
    QUESTION_AND_ANSWER: 'questionAndAnswer',
    QUESTION_AND_ANSWER_ABBR: 'qa',
    TOOLKIT: 'toolkit',
    TWO_MIN_HR: '2mhr',
    WEBINARS: 'webinars',
};

export const CONTENT_TYPES_MAPPED = {
    DOCUMENT: 'documents',
    JOB_DESCRIPTION: 'job-descriptions',
    LAW: 'laws',
    LAW_ALERT: 'law-alerts',
    LINK: 'link',
    QUESTION_AND_ANSWER: 'questions-and-answers',
    TOOLKIT: 'toolkit',
    TWO_MIN_HR: '2mhr',
    CHARTS: 'documents',
    HR_CHECKLIST: 'documents',
    GUIDES: 'documents',
    WEBINARS: 'webinars',
    WEBINARS_ON_DEMAND: 'webinars-on-demand',
    DOCUMENTS: 'documents',
    TOOLKITS: 'toolkits',
    FEATURED: 'featured-content',
    '2MHR': '2MHR',
    HELP_CENTER: 'help-center',
    HELP_CENTER_SEARCH: 'Help Center',
    WEBINARS_HR_TOPICS: 'hr-topics',
    WEBINARS_UPCOMING: 'webinar-upcoming',
    WEBINARS_DEMAND: 'webinarsOnDemand',
};

export const CONTENT_TYPES_MAPPED_CUSTOM = ['charts', '2mhr'];

export const DOCUMENT_TYPES_MAPPED = {
    CHART: 'chart',
    CHECKLIST: 'checklist',
    FORM: 'form',
    GUIDE_WHITE_PAPER: 'guide-white-paper',
    INFOGRAPHIC: 'infographic',
    LETTER: 'letter',
    POLICY: 'policy',
    TOOLKIT: 'toolkit',
};

export const FILTER_DOCUMENT_TYPES = {
    '2MHR': 'Videos',
    CHART: 'Charts',
    CHECKLIST: 'Checklists',
    FORM: 'Forms',
    GUIDE_WHITE_PAPER: 'Guide/White paper',
    INFOGRAPHIC: 'Infographics',
    LAW: 'Laws',
    LAWS: 'Laws',
    LAW_ALERT: 'Law Alerts',
    LAWALERT: 'Law Alerts',
    LETTER: 'Letters',
    POLICY: 'Sample Policies',
    TOOLKIT: 'Toolkits',
    'GUIDE/WHITE PAPER': 'Guides',
    GUIDES: 'Guides',
    HELP_CENTER: 'Help Center',
};

export const RECOMMENDED_RESOURCES_DOCUMENT_TYPES_MAPPED = {
    Chart: 'chart',
    Checklist: 'checklist',
    Form: 'form',
    'Guide/White paper': 'guide-white-paper',
    Infographic: 'infographic',
    Letter: 'letter',
    Policy: 'policy',
    Toolkit: 'toolkit',
    law: 'laws',
};

export const FILTER_DOCUMENT_TYPES_KEY = {
    CHART: 'Chart',
    CHECKLIST: 'Checklist',
    FORM: 'Form',
    GUIDE_WHITE_PAPER: 'Guide/White paper',
    INFOGRAPHIC: 'Infographic',
    LETTER: 'Letter',
    POLICY: 'Policy',
    TOOLKIT: 'Toolkit',
};

export const FILTER_DOCUMENT_TYPES_MAPPED = {
    '2MHR': 'Videos',
    CHART: 'Chart',
    CHECKLIST: 'Checklist',
    DOCUMENT: 'Document',
    FORM: 'Form',
    GUIDE_WHITE_PAPER: 'Guide/White paper',
    INFOGRAPHIC: 'Infographic',
    LAW: 'Law',
    LAWS: 'Laws',
    LETTER: 'Letter',
    POLICY: 'Policy',
    TOOLKIT: 'Toolkit',
    'GUIDE/WHITE PAPER': 'Guide/White paper',
    'HELP-CENTER': 'Help Center',
};

export const META_CONTENT_TYPES = {
    CATEGORY: 'category',
    CONTENT_TYPES: 'content-types',
    DOCUMENT_TYPES: 'document-types',
    EMPLOYEE_COUNT: 'employee-count',
    JURISDICTION: 'jurisdiction',
};

export const NAV_STATE_TYPES = {
    CHART: 'charts',
    CHECKLIST: 'hr-checklists',
    FORM: 'forms',
    GUIDE_WHITE_PAPER: 'guides',
    INFOGRAPHIC: 'infographics',
    LAW: 'law',
    LETTER: 'letters',
    POLICY: 'policies',
    TOOLKIT: 'toolkits',
};

export const DOCUMENT_TYPES = [
    {
        name: 'Charts',
        mappedName: 'chart',
        state: 'charts',
    },
    {
        name: 'HR Checklists',
        mappedName: 'checklist',
        state: 'hr-checklists',
    },
    {
        name: 'Form',
        mappedName: 'form',
        state: 'form',
    },
    {
        name: 'Guides',
        mappedName: 'guide-white-paper',
        state: 'guide/whitepapers',
    },
    {
        name: 'Guides',
        mappedName: 'guide-white-paper',
        state: 'guides',
    },
    {
        name: 'Infographic',
        mappedName: 'infographic',
        state: 'infographic',
    },
    {
        name: 'Letter',
        mappedName: 'letter',
        state: 'letter',
    },
    {
        name: 'Policy',
        mappedName: 'policy',
        state: 'policy',
    },
    {
        name: 'Webinars',
        mappedName: 'webinars',
        state: 'webinars',
        mappedDocument: 'webinar',
    },
    {
        name: 'Webinars',
        mappedName: 'webinars-on-demand',
        state: 'webinars',
        mappedDocument: 'webinar',
    },
    {
        name: 'Videos',
        mappedName: '2mhr',
        state: '2mhr',
    },
    {
        mappedName: 'toolkit',
        state: 'toolkits',
        name: 'Toolkits',
    },
    {
        mappedName: 'questions-and-answers',
        state: 'q-and-a',
        name: 'Q&A Streams',
    },
    {
        mappedName: 'law-alerts',
        state: 'law-alerts',
        name: 'Law Alerts',
    },
];

export const DOCUMENT_TYPE_COLORS = {
    Chart: CONTENT_COLORS.CHART,
    Checklist: CONTENT_COLORS.CHECKLIST,
    Form: CONTENT_COLORS.FORM,
    'Guide/White paper': CONTENT_COLORS.GUIDE_WHITE_PAPER,
    Infographic: CONTENT_COLORS.INFOGRAPHIC,
    Letter: CONTENT_COLORS.LETTER,
    Policy: CONTENT_COLORS.POLICY,
};

export const CONTENT_TYPE_MAPPED = {
    laws: 'law',
};

export const CONTENT_TYPE_ROUTES = {
    QUESTION_AND_ANSWER: 'q-and-a',
    WEBINARS: 'webinars/topics',
};

export const FORM_LETTERS_TYPES = ['form', 'letter', 'policy', 'infographic'];
export const JDL_MODAL_MESSAGE =
    'For this role, we also have a simplified pre-built job description that includes information that our Advisors have found most commonly needed by our clients which can be found';
export const SECTIONS_WITH_TOPICS = ['Templates', 'Compliance'];

export const DROPDOWN_FILTER_NAME = {
    SORT: 'sort',
    DATE: 'date',
};
export const LAW_PASSED_LABEL = 'Law Passed';
export const UPDATED_LABEL = 'Updated';
export const RESOURCES_SECTIONS = [
    'q-and-a',
    'videos',
    'webinars',
    'guides',
    'hr-checklists',
    'charts',
];
export const TEMPLATES_SECTIONS = ['forms', 'letters', 'policies', 'sample-policies', 'toolkits'];
export const CONTENT_NOT_AVAILABLE_MESSAGE =
    'The article you want to view has expired and is no longer available.';
export const BUTTONS_LABELS = {
    VIEW_UPCOMING: 'Learn more',
};
export const TOGGLE_LABELS = {
    EXPAND: 'Expand',
    COLLAPSE: 'Collapse',
};

export const WISTIA_API = `${STATIC_JSON_ORIGIN}/js/ev1.js`;

export const SUPPRESS_VIDEO_PREVIEW = ['2MHR', 'Webinars'];

export const FEATURED_CONTENT = 'featuredcontent';

export const EHS_PRODUCTS = [
    {
        label: 'Content Library',
        path: '/safety/content-library',
        state: 'content-library',
    },
];

export const SHOW_BANNER_EHS_FLAG = 'showBannerUpgraded';
export const SHOW_EHS_FLAG = 'showEHS';
export const SHOW_NEW_SAFETY_LANDING_FLAG = 'showNewSafety';
export const LIST_DOCUMENT = ['documents', 'Document'];
export const HELP_CENTER_INFO = {
    contentTypeLabel: 'Help Center',
};
export const SHOW_PREVIEW_EHS_FLAG = 'showPreview';
export const WEBINAR_RELATED_MATERIALS_TITLE = 'Related Materials';
export const LAWS_RELATED_LAW_ALERTS = 'Related Law Alerts';
export const ARCHIVED = 'Archived';
export const ARCHIVED_TOOLTIP_LABEL =
    'Law Alerts are typically archived after two years or when no longer applicable.';
export const SECTIONS_WITH_FEEDBACK_MODAL = [
    '/help-center',
    '/hr-compliance',
    '/templates',
    '/resources',
];

export const BACK_TO_TOP = 'BACK TO TOP';
export const SECTIONS_WITH_BACK_TO_TOP_SIDEBAR = [
    '/faq',
    '/quick-reference',
    '/user-guides',
    '/law-alerts',
    '/laws',
];

export const COMPLIANCE_SECTIONS_URL = ['/hr-compliance', '/templates', '/resources'];
export const MODAL_LEARN_BUTTON_LABEL = 'Exit the course and return to the platform';
