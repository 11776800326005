import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const NavigationArrowLeft = ({ fill, height, viewBox, width, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <g fill="none" fillRule="evenodd">
            <g fill={fill}>
                <g>
                    <g>
                        <path
                            d="M6 6.755L0 13.163 0 0z"
                            transform="translate(-82 -151) translate(82.564 145.99) translate(0 5.837) matrix(-1 0 0 1 6 0)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

NavigationArrowLeft.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

NavigationArrowLeft.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 7 14',
    width: '7',
    height: '14',
};

export default NavigationArrowLeft;
