import PropTypes from 'prop-types';
import React from 'react';
import _isEqual from 'lodash/isEqual';
import showdown from 'showdown';
import styled from 'styled-components';
import ContentState from 'draft-js/lib/ContentState';
import { EditorState, convertFromHTML, CompositeDecorator } from 'draft-js';

import Editor from 'ravenjs/lib/Editor';
import { COLORS } from 'ravenjs/constants/colors';

import { addLinkPlugin, highlightText } from './plugins';

const editorWrapperProps = {
    margin: '0px',
    backgroundColor: COLORS.INPUT_DISABLED,
    position: 'relative',
};

const DisableWrapper = styled.div`
    position: absolute;
    z-index: 8;
    height: 16rem;
    opacity: 0.4;
    border-radius: 4px;
    width: 100%;
`;

function EditorWidget(props) {
    const { id, onChange, readonly, value } = props;
    const editorRef = React.useRef();

    if (!id) {
        throw new Error(`no id for EditorWidget ${JSON.stringify(props)}`);
    }
    const createEditorState = markDown => {
        const decorator = new CompositeDecorator([addLinkPlugin, highlightText]);
        let editorState = EditorState.createEmpty(decorator);
        if (markDown) {
            const converter = new showdown.Converter({ strikethrough: true });
            const markUpContent = converter.makeHtml(markDown);
            const blocksFromHTML = convertFromHTML(markUpContent.replace(/\n/g, '<br />'));
            const contentFromBlockArray = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            editorState = EditorState.createWithContent(contentFromBlockArray, decorator);
        }
        return editorState;
    };
    const [editorState, setEditorState] = React.useState(createEditorState(value));

    const handleOnChange = editorState => {
        setEditorState(editorState);
        onChange(editorState);
    };

    React.useEffect(() => {
        !_isEqual(editorState, value) &&
            editorRef.current.setEditorContent(createEditorState(value));
    }, [editorState, value]);

    return (
        <>
            {readonly && <DisableWrapper />}
            <Editor
                editorState={editorState}
                onChange={handleOnChange}
                ref={editorRef}
                readOnly={readonly}
                editorWrapperProps={readonly ? editorWrapperProps : {}}
            />
        </>
    );
}

EditorWidget.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
    value: PropTypes.any,
};

EditorWidget.defaultProps = {
    onChange: false,
    readonly: false,
    value: '',
};

export default EditorWidget;
