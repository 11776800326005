import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const SafetyAdvisorIcon = ({
    height,
    viewBox,
    width,
    fillColor,
    fillColorComplementary,
    strokeColor,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <rect fill="none" width="64" height="64" />
            <g>
                <circle fill={fillColor} cx="25.96" cy="12.87" r="11.86" />
                <path
                    fill={fillColor}
                    d="M7.31 56.48l37.29 0 -2.5 -21.1c-0.66,-5.75 -4.81,-10.65 -10.65,-10.65l-5.49 0 -5.48 0c-5.84,0 -9.99,4.9 -10.65,10.65l-2.52 21.1z"
                />
                <ellipse fill={fillColorComplementary} cx="27.82" cy="13.53" rx="10.3" ry="11.2" />
                <path
                    fill={fillColorComplementary}
                    d="M11.48 56.48l33.12 0 -2.22 -20.72c-0.59,-5.64 -4.27,-10.45 -9.46,-10.45l-4.87 0 -4.87 0c-5.19,0 -8.87,4.81 -9.46,10.45l-2.24 20.72z"
                />
                <path
                    fill={strokeColor}
                    fillRule="nonzero"
                    d="M25.96 0c3.55,0 6.77,1.44 9.1,3.77 2.33,2.33 3.78,5.55 3.78,9.1 0,3.55 -1.45,6.77 -3.77,9.1 -2.33,2.33 -5.55,3.77 -9.11,3.77 -3.55,0 -6.77,-1.44 -9.1,-3.77 -2.33,-2.32 -3.77,-5.54 -3.77,-9.1 0,-3.55 1.44,-6.77 3.77,-9.1l0.06 -0.05c2.32,-2.3 5.52,-3.72 9.04,-3.72zm7.68 5.19c-1.96,-1.96 -4.68,-3.17 -7.68,-3.17 -2.98,0 -5.67,1.19 -7.63,3.13l-0.04 0.05c-1.97,1.96 -3.18,4.67 -3.18,7.67 0,3 1.21,5.72 3.18,7.68 1.96,1.96 4.67,3.18 7.67,3.18 3,0 5.72,-1.22 7.68,-3.18 1.96,-1.96 3.18,-4.68 3.18,-7.68 0,-3 -1.22,-5.71 -3.18,-7.68z"
                />
                <path
                    fill={strokeColor}
                    fillRule="nonzero"
                    d="M8.44 55.47l35.03 0 -2.38 -20c-0.31,-2.74 -1.49,-5.26 -3.28,-7.05 -1.64,-1.65 -3.83,-2.68 -6.36,-2.68l-10.97 0c-2.54,0 -4.72,1.03 -6.36,2.68l-0.05 0.04c-1.77,1.79 -2.92,4.31 -3.24,7.04l-2.39 19.97zm36.16 2.02l-37.41 -0.01c-0.55,-0.06 -0.95,-0.56 -0.88,-1.12l2.52 -21.1c0.36,-3.16 1.72,-6.1 3.81,-8.21l0.05 -0.06c2.01,-2.01 4.68,-3.26 7.79,-3.26l10.97 0c3.11,0 5.78,1.25 7.79,3.26 2.1,2.12 3.48,5.06 3.85,8.24 0.99,6.63 1.98,14.59 2.52,21.25 0,0.56 -0.45,1.01 -1.01,1.01z"
                />
                <path
                    fill={fillColor}
                    d="M42.76 62.99l-10.94 0c-2.62,0 -3.75,-2.34 -2.44,-4.52l9.8 -17.6c1.83,-3.16 5.39,-3.07 7.16,0l9.8 17.6c1.31,2.18 0.18,4.52 -2.43,4.52l-10.95 0z"
                />
                <path
                    fill={strokeColor}
                    fillRule="nonzero"
                    d="M42.76 64l-10.94 0c-0.89,0 -1.65,-0.22 -2.27,-0.59l-0.07 -0.05c-0.52,-0.32 -0.93,-0.76 -1.21,-1.27 -0.29,-0.54 -0.45,-1.14 -0.45,-1.78 0,-0.75 0.22,-1.56 0.69,-2.34l9.83 -17.65c0.58,-0.98 1.31,-1.7 2.1,-2.16l0.07 -0.04c0.72,-0.4 1.49,-0.59 2.25,-0.59 0.8,0 1.59,0.22 2.31,0.64 0.82,0.47 1.57,1.21 2.14,2.19l9.81 17.61c0.46,0.78 0.68,1.59 0.68,2.34 0,0.64 -0.16,1.24 -0.45,1.77 -0.29,0.54 -0.73,0.99 -1.28,1.33 -0.62,0.37 -1.38,0.59 -2.26,0.59l-10.95 0zm-10.94 -2.02l21.89 0c0.5,0 0.91,-0.11 1.22,-0.29 0.24,-0.15 0.43,-0.35 0.56,-0.58 0.12,-0.22 0.19,-0.5 0.19,-0.8 0,-0.41 -0.12,-0.86 -0.39,-1.31 -3.35,-5.78 -6.4,-11.93 -9.82,-17.63 -0.39,-0.67 -0.88,-1.16 -1.4,-1.46 -0.42,-0.25 -0.87,-0.37 -1.31,-0.37 -0.42,0 -0.86,0.11 -1.26,0.33l-0.05 0.03c-0.51,0.29 -0.99,0.77 -1.37,1.43 -3.16,5.84 -6.5,11.93 -9.84,17.66 -0.27,0.45 -0.4,0.91 -0.4,1.32 0,0.3 0.07,0.58 0.19,0.81 0.12,0.21 0.29,0.4 0.51,0.54l0.05 0.03c0.31,0.18 0.73,0.29 1.23,0.29z"
                />
                <path
                    fill={strokeColor}
                    fillRule="nonzero"
                    d="M41.75 46.09c0,-0.56 0.45,-1.01 1.01,-1.01 0.56,0 1.01,0.45 1.01,1.01l0 6.67c0,0.56 -0.45,1.01 -1.01,1.01 -0.56,0 -1.01,-0.45 -1.01,-1.01l0 -6.67z"
                />
                <path
                    fill={strokeColor}
                    fillRule="nonzero"
                    d="M43.77 57.68c0,0.56 -0.45,1.01 -1.01,1.01 -0.56,0 -1.01,-0.45 -1.01,-1.01l0 -1c0,-0.56 0.45,-1.01 1.01,-1.01 0.56,0 1.01,0.45 1.01,1.01l0 1z"
                />
            </g>
        </SvgIcon>
    );
};

SafetyAdvisorIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
    fillColorComplementary: string,
    strokeColor: string,
};

SafetyAdvisorIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
    fillColor: `${DEFAULT_COLORS.WHITE}`,
    fillColorComplementary: `${DEFAULT_COLORS.YELLOW}`,
    strokeColor: `${DEFAULT_COLORS.BLACK}`,
};

export default SafetyAdvisorIcon;
