import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    createCalendarEventAPI,
    downloadCalendarEventsAPI,
    fetchCalendarEventsAPI,
    fetchCalendarEventDetailsAPI,
    updateCalendarEventAPI,
    deleteCalendarEventAPI,
} from 'modules/apis';
import * as calendarActions from '../actions';

/**
 * Function for fetching calendar events.
 *
 * @method fetchCalendarEventsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchCalendarEventsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCalendarEventsAPI, payload);
        const events = get(response, 'data._embedded.calendar_event.0', []);
        yield put(calendarActions.fetchCalendarEvents.success(events));
        return events;
    } catch (error) {
        yield put(calendarActions.fetchCalendarEvents.error(error));
        return error;
    }
}

/**
 * Function for fetching calendar event details.
 *
 * @method fetchCalendarEventDetailsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchCalendarEventDetailsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchCalendarEventDetailsAPI, params);
        const event = get(response, 'data', {});
        yield put(calendarActions.fetchCalendarEventDetails.success(event));
        return event;
    } catch (error) {
        yield put(calendarActions.fetchCalendarEventDetails.error(error));
        return error;
    }
}

/**
 * Function for create calendar event.
 *
 * @method createCalendarEventSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* createCalendarEventSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(createCalendarEventAPI, params);
        const event = get(response, 'data', {});
        yield put(calendarActions.createCalendarEvent.success(event));
        return event;
    } catch (error) {
        yield put(calendarActions.createCalendarEvent.error(error));
        return error;
    }
}

/**
 * Function for update calendar event.
 *
 * @method updateCalendarEventSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* updateCalendarEventSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(updateCalendarEventAPI, params);
        const event = get(response, 'data', {});
        yield put(calendarActions.updateCalendarEvent.success(event));
        return event;
    } catch (error) {
        yield put(calendarActions.updateCalendarEvent.error(error));
        return error;
    }
}

/**
 * Function for download calendar events.
 *
 * @method downloadCalendarEventsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* downloadCalendarEventsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(downloadCalendarEventsAPI, params);
        const event = get(response, 'data.results', {});
        yield put(calendarActions.downloadCalendarEvents.success(event));
        return event;
    } catch (error) {
        yield put(calendarActions.downloadCalendarEvents.error(error));
        return error;
    }
}

/**
 * Delete calendar event.
 *
 * @method deleteCalendarEventSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     calendar event
 */
export function* deleteCalendarEventSaga(action) {
    try {
        const { payload: params } = action;
        const { eventId } = params;
        const resp = yield call(deleteCalendarEventAPI, eventId);
        const data = get(resp, 'data', {});
        yield put(calendarActions.deleteCalendarEvent.success(data));
        return data;
    } catch (error) {
        yield put(calendarActions.deleteCalendarEvent.error(error));
        return error;
    }
}
