import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeQAIcon = ({ height, viewBox, width, color, colorInner }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            color={color}
            colorInner={colorInner}
        >
            <path
                fill={color}
                d="M5.061 0v5.159c-.204-.03-.41-.047-.616-.049-1.798 0-3.419 1.083-4.106 2.744-.688 1.66-.308 3.573.963 4.844L0 14h4.445c1.608.002 3.092-.866 3.878-2.27.786-1.403.751-3.122-.091-4.492h2.408l2.317 2.317V7.238H14V0H5.061z"
            />
            <path
                fill={colorInner}
                d="M4.809 10.15h-.7v-.077c.012-.523.272-1.009.7-1.309.134-.104.21-.265.203-.434-.01-.287-.239-.518-.525-.532-.143 0-.281.055-.385.154-.1.103-.158.24-.161.385h-.7c0-.332.134-.65.371-.882.242-.224.559-.351.889-.357.52.024.969.37 1.125.865.157.496-.013 1.037-.425 1.354-.246.175-.396.454-.406.756l.014.077z"
            />
            <path
                fill={colorInner}
                d="M7.378 2.982H8.378V3.982H7.378z"
                transform="rotate(180 7.85 3.454)"
            />
            <path
                fill={colorInner}
                d="M3.99 10.787H4.99V11.787H3.99z"
                transform="rotate(180 4.463 11.26)"
            />
            <path
                fill={colorInner}
                d="M9.17 2.982H10.17V3.982H9.17z"
                transform="rotate(180 9.643 3.454)"
            />
            <path
                fill={colorInner}
                d="M10.976 2.982H11.976V3.982H10.976z"
                transform="rotate(180 11.448 3.455)"
            />
        </SvgIcon>
    );
};

ContentTypeQAIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
    colorInner: string,
};

ContentTypeQAIcon.defaultProps = {
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
    color: `${DEFAULT_COLORS.BLUE}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
};

export default ContentTypeQAIcon;
