import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const FilterLinesIcon = ({ viewBox, height, width, fill }) => {
    return (
        <SvgIcon width={width} viewBox={viewBox} height={height} fill="none">
            <path
                d="M2.00073 5.66667C2.00073 5.29848 2.29921 5 2.6674 5H21.3341C21.7023 5 22.0007 5.29848 22.0007 5.66667C22.0007 6.40305 21.4038 7 20.6674 7H3.33407C2.59769 7 2.00073 6.40305 2.00073 5.66667Z"
                fill={fill}
            />
            <path
                d="M5.00073 9.66667C5.00073 9.29848 5.29921 9 5.6674 9H18.3341C18.7023 9 19.0007 9.29848 19.0007 9.66667C19.0007 10.403 18.4038 11 17.6674 11H6.33407C5.59769 11 5.00073 10.403 5.00073 9.66667Z"
                fill={fill}
            />
            <path
                d="M8.00073 13.6667C8.00073 13.2985 8.29921 13 8.6674 13H15.3341C15.7023 13 16.0007 13.2985 16.0007 13.6667C16.0007 14.403 15.4038 15 14.6674 15H9.33407C8.59769 15 8.00073 14.403 8.00073 13.6667Z"
                fill={fill}
            />
            <path
                d="M11.0007 17.4C11.0007 17.1791 11.1798 17 11.4007 17H12.6007C12.8216 17 13.0007 17.1791 13.0007 17.4V18C13.0007 18.5523 12.553 19 12.0007 19C11.4484 19 11.0007 18.5523 11.0007 18V17.4Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

FilterLinesIcon.propTypes = {
    viewBox: string,
    height: string,
    width: string,
    fill: string,
};

FilterLinesIcon.defaultProps = {
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
    fill: DEFAULT_COLORS.BLACK,
};

export default FilterLinesIcon;
