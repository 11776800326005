import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { THEME } from 'ravenjs/constants/theme';
import { getThemeProps } from 'ravenjs/utils/theme';
import { isReactComponent } from 'ravenjs/utils/component';

const TooltipStyled = styled.div`
    ${getThemeProps('typography.caption')};
    border-radius: 2px;
    max-width: ${({ maxWidth }) => maxWidth};
    width: fit-content;
    padding: 15px;
    position: relative;
    /**
     * Build the Tooltip Arrow.
     */
    &:before {
        position: absolute;
        top: 0;
        left: 0;
    }
    &:after {
        border: ${({ arrowSize }) => `${arrowSize}px`} solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('Tooltip.styles')};
    /**
     * Add in dynamic styles.
     */
    ${({ background, content, padding, theme, color: colorProp, fontSize, textAlign }) => {
        const isValidComp = React.isValidElement(content) || isReactComponent(content);
        const colorProps = !isValidComp ? 'dark' : colorProp;

        const color = getThemeProps(`palette.${colorProps}.text`, THEME.palette.dark.text, {
            theme,
        });
        let backgroundColor;
        if (!isValidComp) {
            backgroundColor = getThemeProps(
                `palette.${colorProps}.color`,
                THEME.palette.dark.color,
                {
                    theme,
                }
            );
        } else {
            backgroundColor = background || 'transparent';
        }

        return {
            backgroundColor,
            color,
            padding,
            fontSize,
            textAlign,
        };
    }};
    /**
     * Determine the different styles based on the current Tooltip placement.
     */
    ${({ arrowSize, margin: marginProps, placement, theme, color }) => {
        const bgColor = getThemeProps(`palette.${color}.color`, THEME.palette.dark.color, {
            theme,
        });
        const margin = marginProps + arrowSize;

        switch (placement) {
            case 'top-start': {
                return {
                    transformOrigin: 'center bottom',
                    margin: `${margin}px 0`,
                    '&:after': {
                        borderTopColor: bgColor,
                        left: '20%',
                        marginLeft: `-${arrowSize}px`,
                        top: '100%',
                    },
                };
            }
            case 'top': {
                return {
                    transformOrigin: 'center bottom',
                    margin: `${margin}px 0`,
                    '&:after': {
                        borderTopColor: bgColor,
                        left: '50%',
                        marginLeft: `-${arrowSize}px`,
                        top: '100%',
                    },
                };
            }
            case 'top-end': {
                return {
                    transformOrigin: 'center bottom',
                    margin: `${margin}px 0`,
                    '&:after': {
                        borderTopColor: bgColor,
                        left: '80%',
                        marginLeft: `-${arrowSize}px`,
                        top: '100%',
                    },
                };
            }
            case 'right-start': {
                return {
                    transformOrigin: 'left center',
                    margin: `0 ${margin}px`,
                    '&:after': {
                        borderRightColor: bgColor,
                        marginTop: `-${arrowSize}px`,
                        right: '100%',
                        top: '20%',
                    },
                };
            }
            case 'right': {
                return {
                    transformOrigin: 'left center',
                    margin: `0 ${margin}px`,
                    '&:after': {
                        borderRightColor: bgColor,
                        marginTop: `-${arrowSize}px`,
                        right: '100%',
                        top: '50%',
                    },
                };
            }
            case 'right-end': {
                return {
                    transformOrigin: 'left center',
                    margin: `0 ${margin}px`,
                    '&:after': {
                        borderRightColor: bgColor,
                        marginTop: `-${arrowSize}px`,
                        right: '100%',
                        top: '80%',
                    },
                };
            }
            case 'bottom-start': {
                return {
                    transformOrigin: 'center top',
                    margin: `${margin}px 0`,
                    '&:after': {
                        borderBottomColor: bgColor,
                        bottom: '100%',
                        left: '20%',
                        marginLeft: `-${arrowSize}px`,
                    },
                };
            }
            case 'bottom': {
                return {
                    transformOrigin: 'center top',
                    margin: `${margin}px 0`,
                    '&:after': {
                        borderBottomColor: bgColor,
                        bottom: '100%',
                        left: '50%',
                        marginLeft: `-${arrowSize}px`,
                    },
                };
            }
            case 'bottom-end': {
                return {
                    transformOrigin: 'center top',
                    margin: `${margin}px 0`,
                    '&:after': {
                        borderBottomColor: bgColor,
                        bottom: '100%',
                        left: '80%',
                        marginLeft: `-${arrowSize}px`,
                    },
                };
            }
            case 'left-start': {
                return {
                    transformOrigin: 'right center',
                    margin: `0 ${margin}px`,
                    '&:after': {
                        borderLeftColor: bgColor,
                        left: '100%',
                        marginTop: `-${arrowSize}px`,
                        top: '20%',
                    },
                };
            }
            case 'left': {
                return {
                    transformOrigin: 'right center',
                    margin: `0 ${margin}px`,
                    '&:after': {
                        borderLeftColor: bgColor,
                        left: '100%',
                        marginTop: `-${arrowSize}px`,
                        top: '50%',
                    },
                };
            }
            case 'left-end': {
                return {
                    transformOrigin: 'right center',
                    margin: `0 ${margin}px`,
                    '&:after': {
                        borderLeftColor: bgColor,
                        left: '100%',
                        marginTop: `-${arrowSize}px`,
                        top: '80%',
                    },
                };
            }
            default:
                return {};
        }
    }};
`;

TooltipStyled.propTypes = {
    /**
     * The size of the Tooltip Arrow.
     */
    arrowSize: PropTypes.number.isRequired,
    /**
     * Apply themed styling to Tooltip.
     *
     * Colors can be defined in `theme.palette`.
     */
    color: PropTypes.string,
    /**
     * The distance from the Tooltip to its container.
     */
    margin: PropTypes.number,
    maxWidth: PropTypes.string,
    /**
     * The padding for the rendered Tooltip container.
     */
    padding: PropTypes.string,
    /**
     * The placement of the Tooltip.
     * This goes directly with placements defined in `Popper.js`.
     */
    placement: PropTypes.oneOf([
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom-start',
        'bottom',
        'bottom-end',
        'left-start',
        'left',
        'left-end',
    ]).isRequired,
};

TooltipStyled.defaultProps = {
    color: 'dark',
    margin: 10,
    maxWidth: '320px',
    padding: '10px',
    textAlign: 'center',
};

export default TooltipStyled;
