import { string } from 'prop-types';
import React from 'react';
import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const InfoIcon = ({ fill, height, viewBox, width, ...rest }) => (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
        <path
            d="M12 11.09a.91.91 0 0 1 .91.91v3.636a.91.91 0 0 1-1.82 0V12a.91.91 0 0 1 .91-.91zM12 7.455a.91.91 0 1 0 0 1.818h.01a.91.91 0 1 0 0-1.818H12z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm10-8.182a8.182 8.182 0 1 0 0 16.364 8.182 8.182 0 0 0 0-16.364z"
            fill={fill}
        />
    </SvgIcon>
);

InfoIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

InfoIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
};

export default InfoIcon;
