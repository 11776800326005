import constants from 'namespace-constants';

/**
 * Constants for the different application modals.
 *
 * @type {Object}
 */
export const MODALS = constants('modal', [
    'ADD_CONFIG',
    'ADD_SKUS',
    'ADD_SKUS_PACKAGING',
    'ASSIGN_CONFIG',
    'COMMENTS',
    'CONFIRM',
    'CONFIRM_HB_STATE_DWNLD',
    'DEPENDENT_SKU_WARNING',
    'DIFF_CHECKER',
    'MULTI_DIFF_CHECKER',
    'FILTER_LIST',
    'FILTER',
    'HANDBOOKS_ELITE',
    'LINK_CUSTOM_POLICY',
    'LIVING_HANDBOOK',
    'LOGIN',
    'MULTI_STATE_HANDBOOK',
    'NEWSLETTER_PREVIEW',
    'POLICY_PREVIEW',
    'ROLE_PARTNER_MODAL',
    'TERMS_AND_CONDITIONS',
    'UPDATE_BANNER',
    'MOVE_COMPANY',
    'WELCOME_EMAIL_EDITOR',
    'SALARY_COMPARE',
    'SIGN_DOC',
    'SEND_FOR_ESIG_MODAL',
    'SESSION_EXPIRY_ALERT',
    'UNSAVED_CHANGES_MODAL',
    'UPLOAD_DOC_MODAL',
    'ESIGN_REMIND_MODAL',
    'ACTIVITY_STREAM_DETAIL',
    'CREATE_BANNER',
    'ADD_NICKNAME',
    'COMMON',
    'SELECT_LIST',
    'SEND_TEST_EMAIL',
    'SEND_TEST_WELCOME_EMAIL',
    'WELCOME_EMAIL_PREVIEW',
    'DUPLICATE_SKUS',
    'COMPANY_DETAILS_UPDATE_REMINDER',
    'CALENDAR_EVENT_MODAL',
    'CALCULATION_VALUES_MODAL',
    'RECORDABLE_INCIDENT_WIZARD_FORM',
    'DOWNLOAD_OSHA_REPORT',
    'ACCOUNT_UPDATE_PASSWORD',
    'DOWNTIME_WARNING_MODAL',
    'WELCOME_INSIGHTS_MODAL',
    'RESOURCES',
    'RESOURCES_EDIT',
    'NOMINATE_FOR_VPA',
    'CASE_ANSWER_OVERWRITE_MODAL',
    'VIEW_PREPPED_ANSWERS_MODAL',
    'OPENID_ERROR_MODAL',
    'EDITOR_URL_MODAL',
    'VIEW_ALL_PRODUCTS_MODAL',
    'RECOMMENDATION_DETAIL_MODAL',
    'FEEDBACK_MODAL',
    'DELETE_CONFIGURATION_MODAL',
    'IMPORT_PROCESSING_MODAL',
    'MESSAGE_REVIEW_MODAL',
    'ADVANCED_SEARCH_MODAL',
    'IMAGE_PREVIEW_MODAL',
    'TRAINING_COURSE',
]);
