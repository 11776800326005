import React from 'react';
import { array, object } from 'prop-types';
import styled, { withTheme } from 'styled-components';

import Typography from 'ravenjs/lib/Typography';
import Box from 'ravenjs/lib/Box';
import { isEmpty } from 'ravenjs/utils/lodash';
import { getThemeProps } from 'ravenjs/utils/theme';
import { renderCheck } from 'ravenjs/utils/viewport';

import { DEFAULT_COLORS } from 'constants/colors';
import { LANDING, TITLES } from 'constants/helpCenter';

import Acl from 'modules/acl';

const BoxHeader = styled(Box)`
    position: relative;
`;

const BoxBody = styled(Box)`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    a {
        padding: 0.6875rem 0.8125rem;
        white-space: nowrap;
        &.largeSuggestionLink {
            white-space: normal;
        }
    }
`;

const TypeLink = styled(Typography)`
    text-decoration: underline;
    &:hover {
        color: ${DEFAULT_COLORS.BLACK};
        text-decoration: none;
    }
`;

const QuickProductLinks = ({ history, quickProductLinks, theme }) => {
    const handleClick = (event, url) => {
        event.stopPropagation();

        if (url.indexOf('http') !== 0) {
            event.preventDefault();
            history.push(url);
        }
        // else: is external link, handle the click via <a> tag
    };

    const formatHelpCenterProductLinkName = name => {
        return name === LANDING.mainTitle ? TITLES.QUICK_PRODUCT_LINK : name;
    };

    let links = quickProductLinks.map(quickProductLink => {
        if (!isEmpty(quickProductLink.permissions)) {
            return Acl.check(quickProductLink.permissions) ? quickProductLink : null;
        }

        return quickProductLink;
    });

    links = links.filter(value => value !== null);

    return links.length ? (
        <Box
            borderColor="transparent"
            backgroundColor={getThemeProps(
                'palette.primary.backgroundLightColor',
                DEFAULT_COLORS.BLUE,
                {
                    theme,
                }
            )}
            padding="0"
            borderRadius="0"
            margin={renderCheck('md', 'less') ? '1rem 0 0 0' : '0'}
        >
            <BoxHeader
                borderWidth="0"
                backgroundColor={getThemeProps(
                    'palette.primary.gradientColor',
                    DEFAULT_COLORS.BLUE,
                    {
                        theme,
                    }
                )}
                padding="0.375rem 0.8125rem"
                borderRadius="0"
                margin="0"
            >
                <Typography
                    type="defaultRobotoMono"
                    lineHeight="0"
                    style={{ textTransform: 'uppercase' }}
                    color={getThemeProps('palette.primary.text', DEFAULT_COLORS.BLACK, {
                        theme,
                    })}
                >
                    Quick Product Links
                </Typography>
            </BoxHeader>
            <BoxBody
                borderWidth="0"
                backgroundColor="transparent"
                padding="0"
                borderRadius="0"
                margin="0"
            >
                {links.map(quickProductLink => {
                    const extraClass =
                        quickProductLink.name === LANDING.mainTitle ? 'largeSuggestionLink' : '';

                    return (
                        <TypeLink
                            className={`pendo-quick-product-link ${extraClass}`}
                            fontFamily="Roboto"
                            gutterBottom="0"
                            gutterTop="0"
                            href={quickProductLink.url}
                            key={quickProductLink.id}
                            onClick={event => {
                                handleClick(event, quickProductLink.url);
                            }}
                            type="link"
                        >
                            {formatHelpCenterProductLinkName(quickProductLink.name)}
                        </TypeLink>
                    );
                })}
            </BoxBody>
        </Box>
    ) : null;
};

QuickProductLinks.propTypes = {
    history: object.isRequired,
    quickProductLinks: array,
    theme: object.isRequired,
};
QuickProductLinks.defaultProps = {
    quickProductLinks: [],
};

export { QuickProductLinks as QuickProductLinksUnwrapped };
export default withTheme(QuickProductLinks);
