import SvgIcon from './SvgIcon';

// Import in the rest of the icons
import AlertIcon from './types/AlertIcon';
import ArrowIcon from './types/ArrowIcon';
import CalendarIcon from './types/CalendarIcon';
import CheckboxEmptyIcon from './types/CheckboxEmptyIcon';
import CheckboxIcon from './types/CheckboxIcon';
import CheckboxIndeterminateIcon from './types/CheckboxIndeterminateIcon';
import ChevronIcon from './types/ChevronIcon';
import ClearIcon from './types/ClearIcon';
import EmailIcon from './types/EmailIcon';
import EyeIcon from './types/EyeIcon';
import GearIcon from './types/GearIcon';
import GridIcon from './types/GridIcon';
import HidePasswordIcon from './types/HidePasswordIcon';
import KeyIcon from './types/KeyIcon';
import MenuIcon from './types/MenuIcon';
import PhoneIcon from './types/PhoneIcon';
import SearchIcon from './types/SearchIcon';
import TrashIcon from './types/TrashIcon';
import DownloadIcon from './types/DownloadIcon';
import PrintIcon from './types/PrintIcon';
import ShareIcon from './types/ShareIcon';
import ZoomInIcon from './types/ZoomInIcon';
import ZoomOutIcon from './types/ZoomOutIcon';
import PaginationNextIcon from './types/PaginationNextIcon';
import PaginationPreviousIcon from './types/PaginationPreviousIcon';
import ArrowDownIcon from './types/ArrowDownIcon';
import FavoriteIcon from './types/FavoriteIcon';
import InfoIcon from './types/InfoIcon';
import CloseIcon from './types/CloseIcon';
import CheckboxEmptyIconNew from './types/CheckboxEmptyIconNew';
import CheckboxFilledIconNew from './types/CheckboxFilledIconNew';
import ShowPasswordIcon from './types/ShowPasswordIcon';
import ExternalLinkIcon from './types/ExternalLinkIcon';

/**
 * All of the SVG Icon types map with an easier to read key names.
 *
 * @type {Object}
 */
const types = {
    alert: AlertIcon,
    arrow: ArrowIcon,
    calendar: CalendarIcon,
    checkbox: CheckboxIcon,
    checkboxEmpty: CheckboxEmptyIcon,
    checkboxIndeterminate: CheckboxIndeterminateIcon,
    chevron: ChevronIcon,
    clear: ClearIcon,
    close: CloseIcon,
    email: EmailIcon,
    eye: EyeIcon,
    gear: GearIcon,
    grid: GridIcon,
    hidePassword: HidePasswordIcon,
    key: KeyIcon,
    menu: MenuIcon,
    phone: PhoneIcon,
    search: SearchIcon,
    trash: TrashIcon,
    download: DownloadIcon,
    share: ShareIcon,
    print: PrintIcon,
    zoomin: ZoomInIcon,
    zoomout: ZoomOutIcon,
    paginationNext: PaginationNextIcon,
    paginationPrevious: PaginationPreviousIcon,
    arrowDownIcon: ArrowDownIcon,
    favoriteIcon: FavoriteIcon,
    infoIcon: InfoIcon,
    emptyCheckbox: CheckboxEmptyIconNew,
    checkboxChecked: CheckboxFilledIconNew,
    showPassword: ShowPasswordIcon,
    externalLinkIcon: ExternalLinkIcon,
};

/**
 * Add the types to the SVG Icon default export.
 *
 * @type {Object}
 */
SvgIcon.types = types;

// Default export the SvgIcon
export default SvgIcon;

export {
    AlertIcon,
    ArrowIcon,
    CalendarIcon,
    CheckboxEmptyIcon,
    CheckboxIcon,
    CheckboxIndeterminateIcon,
    ChevronIcon,
    ClearIcon,
    CloseIcon,
    EmailIcon,
    EyeIcon,
    GearIcon,
    HidePasswordIcon,
    InfoIcon,
    KeyIcon,
    MenuIcon,
    PhoneIcon,
    PrintIcon,
    SearchIcon,
    GridIcon,
    TrashIcon,
    ShareIcon,
    DownloadIcon,
    ZoomInIcon,
    ZoomOutIcon,
    PaginationPreviousIcon,
    PaginationNextIcon,
    ArrowDownIcon,
    FavoriteIcon,
    CheckboxEmptyIconNew,
    CheckboxFilledIconNew,
    ShowPasswordIcon,
    ExternalLinkIcon,
};
