import { get } from 'ravenjs/utils/lodash';
import { call, put } from 'redux-saga/effects';

import {
    deleteLocationAPI,
    fetchLocationAPI,
    fetchLocationsCsvPartnerTemplateAPI,
    fetchLocationsInstructionsPartnerTemplateAPI,
    importLocationsAPI,
    saveLocationAPI,
} from 'modules/apis';

import * as adminActions from '../actions';

/**
 * Delete location
 *
 * @method deleteLocationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* deleteLocationSaga(action) {
    try {
        const {
            payload: { companyId, locationId, type },
        } = action;
        const resp = yield call(deleteLocationAPI, type, companyId, locationId);
        yield put(adminActions.deleteLocation.success(resp));
    } catch (error) {
        yield put(adminActions.deleteLocation.error(error));
    }
}

/**
 * Fetch location
 *
 * @method fetchLocationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* fetchLocationSaga(action) {
    try {
        const {
            payload: { companyId, locationId, type },
        } = action;
        const resp = yield call(fetchLocationAPI, type, companyId, locationId);
        const location = get(resp, 'data.location', {});
        yield put(adminActions.fetchLocation.success(location));
        return location;
    } catch (error) {
        yield put(adminActions.fetchLocation.error(error));
        return error;
    }
}

/**
 * Save location
 *
 * @method saveLocationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* saveLocationSaga(action) {
    try {
        const { payload: locationInfo } = action;
        const resp = yield call(saveLocationAPI, locationInfo);
        const location = get(resp, 'data.location', {});
        yield put(adminActions.saveLocation.success(location));
        return location;
    } catch (error) {
        yield put(adminActions.saveLocation.error(error));
        return error;
    }
}

/**
 * Import Locations saga
 *
 * @method importLocationsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* importLocationsSaga(action) {
    try {
        const { payload: usersInfo } = action;
        const response = yield call(importLocationsAPI, usersInfo);
        yield put(adminActions.importLocations.success(response));
        return response;
    } catch (error) {
        yield put(adminActions.importLocations.error(error));
        return error;
    }
}

/**
 * Import Locations saga
 *
 * @method fetchLocationsCsvPartnerTemplateSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchLocationsCsvPartnerTemplateSaga(action) {
    const { payload } = action;
    try {
        const resp = yield call(fetchLocationsCsvPartnerTemplateAPI, payload);
        const template = get(resp, 'data', {});
        yield put(adminActions.fetchLocationsCsvPartnerTemplate.success(template));
        return template;
    } catch (error) {
        yield put(adminActions.fetchLocationsCsvPartnerTemplate.error(error));
        return error;
    }
}

/**
 * Import Locations saga
 *
 * @method fetchLocationsInstructionsPartnerTemplateSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchLocationsInstructionsPartnerTemplateSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchLocationsInstructionsPartnerTemplateAPI, payload);
        yield put(adminActions.fetchLocationsInstructionsPartnerTemplate.success(resp));
        return resp;
    } catch (error) {
        yield put(adminActions.fetchLocationsInstructionsPartnerTemplate.error(error));
        return error;
    }
}
