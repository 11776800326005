import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'styled-components';

import AlertCircleOutline from 'components/Icons/AlertCircleOutline';

import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';

import { DEFAULT_COLORS } from 'constants/colors';
import DisplayRemainingCharacterCount from './DisplayRemainingCharacterCount';

const StyledTypography = styled(Typography)`
    margin: 5px 0 8px !important;
`;

const DisplayRequiredWithIconField = ({ requiredErrorMessage, displayCharacterCount }) => {
    return (
        <>
            <Row>
                <AlertCircleOutline
                    height="14px"
                    color={DEFAULT_COLORS.RED}
                    width="14px"
                    margin="7px 2px 0 16px"
                />
                <StyledTypography
                    color={DEFAULT_COLORS.RED}
                    fontSize="14px"
                    fontFamily="Roboto"
                    fontWeight="400"
                    lineHeight="19px"
                    wordWrap
                >
                    {requiredErrorMessage}
                </StyledTypography>
            </Row>
            {displayCharacterCount && (
                <DisplayRemainingCharacterCount characterCount={0} color={DEFAULT_COLORS.RED} />
            )}
        </>
    );
};

DisplayRequiredWithIconField.propTypes = {
    requiredErrorMessage: string,
    displayCharacterCount: bool,
};

DisplayRequiredWithIconField.defaultProps = {
    requiredErrorMessage: 'Required',
    displayCharacterCount: false,
};

export default DisplayRequiredWithIconField;
