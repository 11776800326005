import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HandbookBuilderIcon = ({
    height,
    viewBox,
    width,
    fillColor,
    fillColorComplementary,
    strokeColor,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.61597 0.877563H38.1609C39.6684 0.877563 40.9001 2.16438 40.9001 3.74007V51.9441C40.9001 53.5198 39.6676 54.8066 38.1609 54.8066H2.61597V0.877563Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.9575 54.6317H37.7274C39.4641 54.6317 40.8824 53.15 40.8824 51.3385V2.61073C40.8824 2.60028 40.8741 2.58984 40.8641 2.58984H5.9575C5.94583 2.58984 5.9375 2.60028 5.9375 2.61073V54.6126C5.9375 54.6239 5.94583 54.6317 5.9575 54.6317Z"
                fill={fillColorComplementary}
            />
            <path
                d="M38.1607 1.75578H3.45657V53.9282H38.1607C38.6823 53.9282 39.159 53.7054 39.5032 53.3461C39.8448 52.9885 40.0607 52.4917 40.0607 51.9444V3.7404C40.0607 3.194 39.8448 2.69632 39.5032 2.33873C39.159 1.97852 38.6823 1.75578 38.1607 1.75578ZM2.61573 0H38.1607C39.1457 0 40.0423 0.42111 40.6898 1.09715C41.339 1.77493 41.7432 2.71111 41.7432 3.7404V51.9444C41.7432 52.9746 41.3398 53.9116 40.6898 54.5868C40.0423 55.2628 39.1448 55.6848 38.1607 55.6848H2.61573C2.15157 55.6848 1.7749 55.2924 1.7749 54.8069V0.877892C1.7749 0.395008 2.15157 0 2.61573 0Z"
                fill={strokeColor}
            />
            <path
                d="M5.23166 6.36005C5.69666 6.36005 6.07249 6.75331 6.07249 7.23794C6.07249 7.72082 5.69582 8.11583 5.23166 8.11583H0.842499C0.377499 8.11583 0 7.72082 0 7.23794C0 6.75244 0.378333 6.36005 0.842499 6.36005H5.23166V6.36005Z"
                fill={strokeColor}
            />
            <path
                d="M5.07261 7.40848L5.23177 7.24056L5.07261 7.40848ZM5.23177 5.73883C5.62344 5.73883 5.9826 5.90849 6.24427 6.17995C6.5051 6.45402 6.66844 6.82989 6.66844 7.23882C6.66844 7.65123 6.50594 8.02622 6.24594 8.29681C5.98844 8.56653 5.62677 8.73619 5.23177 8.73619C4.84011 8.73619 4.48094 8.56653 4.22177 8.29681C3.96094 8.02274 3.79761 7.64687 3.79761 7.23882C3.79761 6.82641 3.96011 6.4488 4.21844 6.17995C4.47761 5.90849 4.83927 5.73883 5.23177 5.73883Z"
                fill={strokeColor}
            />
            <path
                d="M5.23166 13.2284C5.69666 13.2284 6.07249 13.6217 6.07249 14.1045C6.07249 14.5918 5.69582 14.9824 5.23166 14.9824H0.842499C0.377499 14.9824 0 14.5918 0 14.1045C0 13.6208 0.378333 13.2284 0.842499 13.2284H5.23166V13.2284Z"
                fill={strokeColor}
            />
            <path
                d="M5.07261 14.2772L5.23177 14.1092L5.07261 14.2772ZM5.23177 12.6093C5.62344 12.6093 5.9826 12.7772 6.24427 13.0478C6.5051 13.3218 6.66844 13.6977 6.66844 14.1049C6.66844 14.5173 6.50594 14.894 6.24594 15.1655C5.98844 15.4343 5.62677 15.604 5.23177 15.604C4.84011 15.604 4.48094 15.4343 4.22177 15.1655C3.96094 14.8897 3.79761 14.5156 3.79761 14.1049C3.79761 13.6942 3.96011 13.3166 4.21844 13.0478C4.47761 12.778 4.83927 12.6093 5.23177 12.6093Z"
                fill={strokeColor}
            />
            <path
                d="M5.23166 20.095C5.69666 20.095 6.07249 20.49 6.07249 20.9747C6.07249 21.4575 5.69582 21.8526 5.23166 21.8526H0.842499C0.377499 21.8526 0 21.4575 0 20.9747C0 20.4892 0.378333 20.095 0.842499 20.095H5.23166Z"
                fill={strokeColor}
            />
            <path
                d="M5.07261 21.1452L5.23177 20.9773L5.07261 21.1452ZM5.23177 19.4755C5.62344 19.4755 5.9826 19.6452 6.24427 19.9158C6.5051 20.1881 6.66844 20.5657 6.66844 20.9746C6.66844 21.3871 6.50594 21.762 6.24594 22.0335C5.98844 22.3024 5.62677 22.472 5.23177 22.472C4.84011 22.472 4.48094 22.3024 4.22177 22.0335C3.96094 21.7594 3.79761 21.3836 3.79761 20.9746C3.79761 20.5622 3.96011 20.1855 4.21844 19.9158C4.47761 19.6461 4.83927 19.4755 5.23177 19.4755Z"
                fill={strokeColor}
            />
            <path
                d="M5.23166 26.965C5.69666 26.965 6.07249 27.3583 6.07249 27.8429C6.07249 28.3275 5.69582 28.7191 5.23166 28.7191H0.842499C0.377499 28.7191 0 28.3275 0 27.8429C0 27.3583 0.378333 26.965 0.842499 26.965H5.23166V26.965Z"
                fill={strokeColor}
            />
            <path
                d="M5.07261 28.0134L5.23177 27.8455L5.07261 28.0134ZM5.23177 26.3438C5.62344 26.3438 5.9826 26.5134 6.24427 26.784C6.5051 27.0581 6.66844 27.4339 6.66844 27.8429C6.66844 28.2535 6.50594 28.6303 6.24594 28.9009C5.98844 29.1706 5.62677 29.3402 5.23177 29.3402C4.84011 29.3402 4.48094 29.1706 4.22177 28.9009C3.96094 28.6268 3.79761 28.2518 3.79761 27.8429C3.79761 27.4305 3.96011 27.0537 4.21844 26.784C4.47761 26.5143 4.83927 26.3438 5.23177 26.3438Z"
                fill={strokeColor}
            />
            <path
                d="M5.23166 33.8322C5.69666 33.8322 6.07249 34.2263 6.07249 34.71C6.07249 35.1955 5.69582 35.5897 5.23166 35.5897H0.842499C0.377499 35.5897 0 35.1955 0 34.71C0 34.2263 0.378333 33.8322 0.842499 33.8322H5.23166Z"
                fill={strokeColor}
            />
            <path
                d="M5.07261 34.8814L5.23177 34.7135L5.07261 34.8814ZM5.23177 33.2117C5.62344 33.2117 5.9826 33.3814 6.24427 33.6529C6.5051 33.9252 6.66844 34.3028 6.66844 34.71C6.66844 35.1224 6.50594 35.4991 6.24594 35.7706C5.98844 36.0386 5.62677 36.21 5.23177 36.21C4.84011 36.21 4.48094 36.0403 4.22177 35.7706C3.96094 35.4965 3.79761 35.1189 3.79761 34.71C3.79761 34.2976 3.96011 33.9208 4.21844 33.6529C4.47761 33.3814 4.83927 33.2117 5.23177 33.2117Z"
                fill={strokeColor}
            />
            <path
                d="M5.23166 40.7013C5.69666 40.7013 6.07249 41.0946 6.07249 41.5801C6.07249 42.0629 5.69582 42.4562 5.23166 42.4562H0.842499C0.377499 42.4562 0 42.0629 0 41.5801C0 41.0946 0.378333 40.7013 0.842499 40.7013H5.23166Z"
                fill={strokeColor}
            />
            <path
                d="M5.07261 41.7479L5.23177 41.5817L5.07261 41.7479ZM5.23177 40.08C5.62344 40.08 5.9826 40.2497 6.24427 40.5211C6.5051 40.7952 6.66844 41.1702 6.66844 41.58C6.66844 41.9907 6.50594 42.3674 6.24594 42.6363C5.98844 42.9077 5.62677 43.0756 5.23177 43.0756C4.84011 43.0756 4.48094 42.9077 4.22177 42.6363C3.96094 42.3631 3.79761 41.9889 3.79761 41.58C3.79761 41.1667 3.96011 40.79 4.21844 40.5211C4.47761 40.2505 4.83927 40.08 5.23177 40.08Z"
                fill={strokeColor}
            />
            <path
                d="M5.23166 47.5704C5.69666 47.5704 6.07249 47.9628 6.07249 48.4466C6.07249 48.9321 5.69582 49.3254 5.23166 49.3254H0.842499C0.377499 49.3254 0 48.9321 0 48.4466C0 47.9628 0.378333 47.5704 0.842499 47.5704H5.23166Z"
                fill={strokeColor}
            />
            <path
                d="M5.07261 48.6183L5.23177 48.4487L5.07261 48.6183ZM5.23177 46.9513C5.62344 46.9513 5.9826 47.1192 6.24427 47.3898C6.5051 47.6621 6.66844 48.0397 6.66844 48.4469C6.66844 48.8602 6.50594 49.2361 6.24594 49.5075C5.98844 49.7755 5.62677 49.9469 5.23177 49.9469C4.84011 49.9469 4.48094 49.7773 4.22177 49.5075C3.96094 49.2317 3.79761 48.8559 3.79761 48.4469C3.79761 48.0363 3.96011 47.6595 4.21844 47.3898C4.47761 47.1192 4.83927 46.9513 5.23177 46.9513Z"
                fill={strokeColor}
            />
            <path d="M32.627 10.0525H15.2778V27.4537H32.627V10.0525Z" fill={fillColor} />
            <path
                d="M17.1819 16.8893C16.7169 16.8893 16.3411 16.4943 16.3411 16.0114C16.3411 15.5268 16.7177 15.1335 17.1819 15.1335H27.9119C28.3769 15.1335 28.7527 15.5268 28.7527 16.0114C28.7527 16.4951 28.3769 16.8893 27.9119 16.8893H17.1819Z"
                fill={strokeColor}
            />
            <path
                d="M17.1819 22.3946C16.7169 22.3946 16.3411 22.0013 16.3411 21.5167C16.3411 21.0321 16.7177 20.6388 17.1819 20.6388H22.5477C23.0127 20.6388 23.3894 21.0321 23.3894 21.5167C23.3894 22.0013 23.0127 22.3946 22.5477 22.3946H17.1819Z"
                fill={strokeColor}
            />
            <path
                d="M31.7857 10.9309H13.3074V26.5755H31.7857V10.9309ZM12.4666 9.17511H32.6274C33.0915 9.17511 33.4682 9.56838 33.4682 10.053V27.4542C33.4682 27.9397 33.0915 28.3321 32.6274 28.3321H12.4666C12.0024 28.3321 11.6257 27.9389 11.6257 27.4542V10.053C11.6257 9.56838 12.0016 9.17511 12.4666 9.17511Z"
                fill={strokeColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.4163 45.7114C35.5874 45.7114 31.6731 49.6192 31.6731 54.4389C31.6731 59.2585 35.5874 63.1647 40.4163 63.1647C45.246 63.1647 49.1603 59.2585 49.1603 54.4389C49.1603 49.6192 45.2451 45.7114 40.4163 45.7114Z"
                fill={fillColor}
            />
            <path
                d="M33.642 47.6759C35.3739 45.9451 37.7725 44.8732 40.4156 44.8732C43.062 44.8732 45.4581 45.9451 47.1916 47.6759C48.9259 49.4058 50.0001 51.7978 50.0001 54.4378C50.0001 57.0762 48.9259 59.4691 47.1916 61.1981C45.4581 62.9297 43.0611 64 40.4156 64C37.7717 64 35.3739 62.9297 33.642 61.1981C31.906 59.4691 30.8335 57.0754 30.8335 54.4378C30.8335 51.7978 31.906 49.4058 33.642 47.6759ZM40.4164 46.5483C38.2348 46.5483 36.256 47.4311 34.826 48.859C33.3967 50.2861 32.5105 52.2574 32.5105 54.4387C32.5105 56.6158 33.3967 58.5912 34.826 60.0158C36.256 61.4445 38.2348 62.3265 40.4164 62.3265C42.6005 62.3265 44.5776 61.4445 46.0077 60.0158C47.4385 58.5912 48.3232 56.6158 48.3232 54.4387C48.3232 52.2591 47.4385 50.2861 46.0077 48.859C44.5776 47.4311 42.6005 46.5483 40.4164 46.5483Z"
                fill={strokeColor}
            />
            <path
                d="M39.5769 50.8017C39.5769 50.3387 39.9544 49.9645 40.4158 49.9645C40.8797 49.9645 41.2555 50.3387 41.2555 50.8017V58.0723C41.2555 58.5352 40.8797 58.9102 40.4158 58.9102C39.9544 58.9102 39.5769 58.5352 39.5769 58.0723V50.8017Z"
                fill={strokeColor}
            />
            <path
                d="M36.7744 55.2731C36.3105 55.2731 35.9355 54.8989 35.9355 54.4376C35.9355 53.9746 36.3105 53.5996 36.7744 53.5996H44.0601C44.5241 53.5996 44.899 53.9746 44.899 54.4376C44.899 54.8989 44.5241 55.2731 44.0601 55.2731H36.7744Z"
                fill={strokeColor}
            />
        </SvgIcon>
    );
};

HandbookBuilderIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
    fillColorComplementary: string,
    strokeColor: string,
};

HandbookBuilderIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
    fillColor: `${DEFAULT_COLORS.WHITE}`,
    fillColorComplementary: `${DEFAULT_COLORS.LIGHTPINK_2}`,
    strokeColor: `${DEFAULT_COLORS.BLACK}`,
};

export default HandbookBuilderIcon;
