import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const SlashIcon = ({ height, viewBox, width, fill, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99996 2.50002C4.68625 2.50002 1.99996 5.18631 1.99996 8.50002C1.99996 11.8137 4.68625 14.5 7.99996 14.5C11.3137 14.5 14 11.8137 14 8.50002C14 5.18631 11.3137 2.50002 7.99996 2.50002ZM0.666626 8.50002C0.666626 4.44993 3.94987 1.16669 7.99996 1.16669C12.05 1.16669 15.3333 4.44993 15.3333 8.50002C15.3333 12.5501 12.05 15.8334 7.99996 15.8334C3.94987 15.8334 0.666626 12.5501 0.666626 8.50002Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.81526 3.31526C3.07561 3.05491 3.49772 3.05491 3.75807 3.31526L13.1847 12.7419C13.4451 13.0023 13.4451 13.4244 13.1847 13.6847C12.9244 13.9451 12.5023 13.9451 12.2419 13.6847L2.81526 4.25807C2.55491 3.99772 2.55491 3.57561 2.81526 3.31526Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

SlashIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
};

SlashIcon.defaultProps = {
    height: '17',
    viewBox: '0 0 16 17',
    width: '16',
    fill: DEFAULT_COLORS.RED_4,
};

export default SlashIcon;
