import { takeLatest } from 'redux-saga/effects';

import * as communityActions from '../actions';
import { fetchCommunityUrlSaga } from './community';

function* communitySaga() {
    yield takeLatest(communityActions.fetchCommunityUrl.TRIGGER, fetchCommunityUrlSaga);
}

export default communitySaga;

export { fetchCommunityUrlSaga };
