import axios from 'axios';
import Qs from 'qs';

/**
 * API call to fetch info about the given configuration, via `configurationId`.
 *
 * @method fetchConfigurationByIdAPI
 * @param  {number}                  configurationId The configuration to fetch
 * @return {Promise}
 */
export const fetchConfigurationByIdAPI = configurationId => {
    return axios({
        method: 'get',
        url: `/v2/configurations/${configurationId}`,
    });
};

/**
 * API call to fetch info about the given configuration, via `configurationId`.
 *
 * @method updateConfigurationAPI
 * @param  {number}               configurationId The configuration to update
 * @param  {Object}               data            The updated data for the configuration
 * @return {Promise}
 */
export const updateConfigurationAPI = (configurationId, data) => {
    return axios({
        method: 'put',
        url: `/v2/configurations/${configurationId}`,
        data,
    });
};

/**
 * API call to create a new configuration.
 *
 * @method createConfigurationAPI
 * @param  {Object}               data     The data for the configuration
 * @param  {number}               partnerId The id of the partner if present
 * @return {Promise}
 */
export const createConfigurationAPI = (data, partnerId) => {
    return axios({
        method: 'post',
        url: '/v2/configurations',
        data,
        params: {
            partnerId,
        },
    });
};

/**
 * API call to deactivate a configuration.
 *
 * @method deactivateConfigurationAPI
 * @param  {number}        configurationId
 * @param  {boolean}      isActive
 * @return {Promise}
 */
export const deactivateConfigurationAPI = (configurationId, isActive = false) => {
    return axios({
        method: 'patch',
        url: `/v2/configurations/${configurationId}`,
        data: { isActive },
    });
};

/**
 * Get all the configurations that have been created for this company
 *
 * @method fetchCompanyConfigurationsAPI
 * @param {number} companyId
 * @return {Promise}
 */
export const fetchCompanyConfigurationsAPI = companyId => {
    return axios({
        method: 'get',
        url: `/v2/configurations?companyId=${companyId}`,
    });
};

/**
 * Get the configurations information
 *
 * @method fetchCompanyConfigurationAPI
 * @param {number} configurationId
 * @return {Promise}
 */
export const fetchCompanyConfigurationAPI = configurationId => {
    return axios({
        method: 'get',
        url: `/v2/configurations/${configurationId}`,
    });
};

/**
 * Get configurations
 *
 * @method fetchConfigurations
 * @param  {Object}    params
 * @return {Promise}
 */
export const fetchConfigurations = params =>
    axios({
        method: 'get',
        url: '/v2/configurations',
        params,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });

/**
 * Get configurations types
 *
 * @method fetchConfigurationTypes
 * @return {Promise}
 */
export const fetchConfigurationTypes = () =>
    axios({
        method: 'get',
        url: '/v2/lookup-types',
        params: {
            categoryName: 'configuration-type',
        },
    });

/*
 * Get EHS Products
 *
 * @method fetchEHSProductsAPI
 * @return {Promise}
 */
export const fetchEHSProductsAPI = userId =>
    axios({
        method: 'get',
        url: `/v2/contentful/ehs/products/user/${userId}`,
    });

/*
 * Get skus by configuration id
 *
 * @method fetchSkusByConfigurationIdAPI
 * @return {Promise}
 */
export const fetchSkusByConfigurationIdAPI = ({ configurationId, params }) =>
    axios({
        method: 'get',
        url: `/v2/configurations/${configurationId}/skus`,
        params,
    });
