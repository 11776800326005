import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CheckboxFilledIcon = ({ backgroundFill, fill, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path fill={backgroundFill} d="M4 4h16v16H4z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5zM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.766 6.293c.312.39.312 1.024 0 1.414l-8 10c-.313.39-.82.39-1.132 0l-2.4-3c-.312-.39-.312-1.024 0-1.414.313-.39.82-.39 1.132 0L9.2 15.586l7.434-9.293c.313-.39.82-.39 1.132 0z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CheckboxFilledIcon.propTypes = {
    backgroundFill: string,
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CheckboxFilledIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    backgroundFill: 'transparent',
    height: '17',
    viewBox: '0 0 23 23',
    width: '17',
};

export default CheckboxFilledIcon;
