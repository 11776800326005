import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CopyLinkIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon height={height} width={width} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.708 3.54A5.48 5.48 0 0 1 22 7.44a5.49 5.49 0 0 1-1.54 3.86l-.01.011-2.741 2.744a5.482 5.482 0 0 1-8.268-.592.915.915 0 0 1 1.463-1.096 3.655 3.655 0 0 0 5.512.395l2.736-2.738a3.66 3.66 0 0 0-2.603-6.195 3.653 3.653 0 0 0-2.566 1.023L12.416 6.41a.913.913 0 1 1-1.289-1.297L12.7 3.55l.01-.01z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.253 8.683a5.478 5.478 0 0 1 6.306 1.854.915.915 0 0 1-1.463 1.096 3.655 3.655 0 0 0-5.512-.395l-2.736 2.738a3.66 3.66 0 0 0 2.603 6.195 3.653 3.653 0 0 0 2.566-1.022l1.556-1.558a.913.913 0 1 1 1.292 1.293l-1.562 1.564-.011.011A5.48 5.48 0 0 1 2 16.56 5.49 5.49 0 0 1 3.54 12.7l.011-.011 2.741-2.744a5.483 5.483 0 0 1 1.962-1.262z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CopyLinkIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CopyLinkIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default CopyLinkIcon;
