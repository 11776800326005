import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ErrorIcon = ({ height, viewBox, width, color, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g>
                <path
                    fill={color}
                    d="M230.4,128h51.2v179.2h-51.2V128z M230.4,332.8h51.2V384h-51.2V332.8z"
                />
                <path
                    fill={color}
                    d="M504.48,135.5l-128-128C371.7,2.7,365.2,0,358.4,0H153.6c-6.83,0-13.33,2.7-18.1,7.5l-128,128C2.67,140.3,0,146.8,0,153.6
                    v204.8c0,6.83,2.67,13.33,7.5,18.1l128,128c4.77,4.83,11.27,7.5,18.1,7.5h204.8c6.8,0,13.3-2.67,18.08-7.5l128-128
                    c4.82-4.77,7.52-11.27,7.52-18.1V153.6C512,146.8,509.3,140.3,504.48,135.5z M460.8,347.8l-113,113H164.18L51.2,347.8V164.2
                    l112.98-113H347.8l113,113V347.8z"
                />
            </g>
        </SvgIcon>
    );
};

ErrorIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

ErrorIcon.defaultProps = {
    height: '32',
    viewBox: '0 0 512 512',
    width: '32',
    color: `${DEFAULT_COLORS.RED}`,
};

export default ErrorIcon;
