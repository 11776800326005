import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ArrowDownDefaultIcon = ({ fillColor, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            {...rest}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00004 2.6665C8.36823 2.6665 8.66671 2.96498 8.66671 3.33317V12.6665C8.66671 13.0347 8.36823 13.3332 8.00004 13.3332C7.63185 13.3332 7.33337 13.0347 7.33337 12.6665V3.33317C7.33337 2.96498 7.63185 2.6665 8.00004 2.6665Z"
                fill="#3052DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.86189 7.52876C3.12224 7.26841 3.54435 7.26841 3.8047 7.52876L7.99996 11.724L12.1952 7.52876C12.4556 7.26841 12.8777 7.26841 13.138 7.52876C13.3984 7.78911 13.3984 8.21122 13.138 8.47157L8.47136 13.1382C8.21101 13.3986 7.7889 13.3986 7.52855 13.1382L2.86189 8.47157C2.60154 8.21122 2.60154 7.78911 2.86189 7.52876Z"
                fill="#3052DA"
            />
        </SvgIcon>
    );
};

ArrowDownDefaultIcon.propTypes = {
    fillColor: string,
    height: string,
    viewBox: string,
    width: string,
};

ArrowDownDefaultIcon.defaultProps = {
    fillColor: 'none',
    height: '16',
    viewBox: '0 0 16 16',
    width: '16',
};

export default ArrowDownDefaultIcon;
