import { takeLatest } from 'redux-saga/effects';

import * as bootstrapActions from '../actions';
import { fetchAppBootstrapSaga, fetchDowntimeInfoSaga } from './app';

/**
 * The root `bootstrap` saga.
 * We're triggering sagas based their respective events.
 *
 * @method bootstrapSaga
 * @type   {Generator}
 */
function* bootstrapSaga() {
    // Fetch the bootstrap config
    yield takeLatest(bootstrapActions.fetchAppBootstrap.TRIGGER, fetchAppBootstrapSaga);
    yield takeLatest(bootstrapActions.fetchDowntimeInfo.TRIGGER, fetchDowntimeInfoSaga);
}

// Default export is the module's root saga.
export default bootstrapSaga;

// Named exports are all the internal sagas that can be used
// for testing as well as imports in other modules
export { fetchAppBootstrapSaga };
