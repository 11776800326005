import _isEmpty from 'lodash/isEmpty';

import { CONFIGURATION_TYPE } from 'constants/configuration';
import { generateLicenseName } from './skus';

/**
 * Check to see if the given configuration is a master configuration.
 *
 * @method isMasterConfig
 * @param  {Object}       config The configuration to check
 * @return {boolean}
 */
export function isMasterConfig(config = {}) {
    // If the key `masterConfiguration` is not present,
    // then obviously this is not a master configuration.
    if (!config || !config.masterConfiguration) {
        return false;
    }
    // Check for `masterConfiguration` to equal 1.
    // NOTE: Check with backend if we can convert this to a boolean value instead.
    return config.masterConfiguration === 1;
}

/**
 * Determine if a `sku` exists in a given configuration.
 *
 * @method configContainsSku
 * @param  {Object}          [config={}]       The configuration
 * @param  {string}          skuKeyToFind      The sku key to find
 * @param  {boolean}         [returnSku=false] Should we return the found sku?
 * @return {boolean|Object}
 */
export function configContainsSku(config = {}, skuKeyToFind, returnSku = false) {
    const skusList = config && Array.isArray(config.skus) ? config.skus : [];

    if (_isEmpty(skusList)) {
        return false;
    }

    // TODO: Handbook Zend API's are returning SKUKEY, revisit later to handle the below changes for Handbooks
    const foundSkuKey = skusList.find(sku => sku.skuKey === skuKeyToFind);
    const foundKey = skusList.find(sku => sku.key === skuKeyToFind);
    const foundSku = foundSkuKey || foundKey;

    if (returnSku) {
        return foundSku;
    }
    return !!foundSku;
}

/**
 * Format configuration for the details section
 *
 * @param   {Object}    configuration
 * @param   {Array}     itemsSchema
 * @return  {Array}
 */
export function formatConfigurationDetails(configuration = {}, itemsSchema = []) {
    const formattedConfiguration = {
        ...configuration,
        isMaster: configuration.configurationType === CONFIGURATION_TYPE.MASTER ? 'Yes' : 'No',
        licenses: generateLicenseName(configuration?.licenses),
    };

    return itemsSchema.map(item => {
        return {
            ...item,
            value: formattedConfiguration[item.id] || '-',
        };
    });
}
