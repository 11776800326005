import axios from 'axios';
import mergeUser from 'static/config/mergeUser.json';

import { MOVE_TOOL } from 'constants/moveTool';
import { isEmpty } from 'lodash';

const { COMMON_KEYS } = MOVE_TOOL;

/**
 * API to fetch dashboard info
 *
 * @method getDashboardInfoAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getDashboardInfoAPI = params =>
    axios({
        method: 'get',
        params,
        url: '/v2/bulk-change-tool/dashboard',
    });
/**
 * API to fetch list of users to merge
 *
 * @method getMergeUserListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getMergeUserListAPI = params => {
    return axios({
        method: 'get',
        params,
        url: `/v2/bulk-change-tool/${params.type}-users`,
    });
};

/**
 * API to fetch user to merge
 *
 * @method getMergeUserAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getMergeUserAPI = () => {
    return new Promise(resolve =>
        setTimeout(() => {
            resolve(mergeUser);
        }, 100)
    );
};

/**
 * API to fetch list partners
 *
 * @method checkCsvMassUpdateUsersAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const checkCsvMassUpdateUsersAPI = ({ formData, isMove, isMerge, massUpdateType, type }) => {
    let labelMergeOrMove = '';
    let serviceLabel = '';
    const typeBulk = type === 'users' ? 'user' : COMMON_KEYS.COMPANY;

    if (isMerge) {
        labelMergeOrMove = 'upload-csv-merge-companies';
    } else if (isMove) {
        labelMergeOrMove = 'upload-csv-move-companies';
    } else if (
        massUpdateType === COMMON_KEYS.MASS_REACTIVATE_USERS ||
        massUpdateType === COMMON_KEYS.MASS_DEACTIVATE_USERS
    ) {
        labelMergeOrMove = 'mass-update-user/deactivate-activate';
    }

    serviceLabel = labelMergeOrMove || `mass-update-${typeBulk}/upload-csv`;

    return axios({
        method: 'post',
        url: `/v2/bulk-change-tool/${serviceLabel}`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * API to fetch list partners
 *
 * @method checkCsvMergeMultipleUsersAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const checkCsvMergeMultipleUsersAPI = ({ type, formData }) => {
    return axios({
        method: 'post',
        url: `v2/bulk-change-tool/upload-csv-${type}-user`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * API to fetch list of users to merge
 *
 * @method downloadMergeCsvAPI
 * @param  {string} url
 * @return {Promise}
 */
export const downloadMergeCsvAPI = url =>
    axios({
        method: 'get',
        url,
    });

/**
 * API to run merge process
 *
 * @method runMergeMultipleUsersAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMergeMultipleUsersAPI = ({ type, formData }) => {
    return axios({
        method: 'post',
        url: `v2/bulk-change-tool/prepare-${type}-user`,
        data: formData,
    });
};

/**
 * API to fetch merge users information
 *
 * @method fetchMergeUsersInformationAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchMergeUsersInformationAPI = ({ requestId }) =>
    axios({
        method: 'get',
        url: `v2/bulk-change-tool/${requestId}`,
    });

/**
 * API to fetch list of users to mass update
 *
 * @method getMassUpdateUserListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getMassUpdateUserListAPI = ({ isMerge, params, requestId, type }) => {
    let url = '';

    if (isMerge) {
        url = 'merge-companies';
    } else {
        url = type === COMMON_KEYS.COMPANIES ? 'mass-update-company/list' : 'mass-update-user/list';
    }

    return axios({
        method: 'get',
        params,
        url: `v2/bulk-change-tool/${url}?requestId=${requestId}`,
    });
};

/**
 * API to merge single user
 *
 * @method mergeSingleUserAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const mergeSingleUserAPI = ({ type, formData }) =>
    axios({
        method: 'post',
        data: formData,
        url: `v2/bulk-change-tool/${type === 'move' ? 'move-single-user' : 'merge-single-user'}`,
    });

/**
 * API to run merge process
 *
 * @method runMergeSingleUserAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMergeSingleUserAPI = ({ type, formData }) => {
    return axios({
        method: 'post',
        url: `v2/bulk-change-tool/${
            type === 'move' ? 'prepare-move-single-user' : 'prepare-merge-single-user'
        }`,
        data: formData,
    });
};

/**
 * API to run merge process
 *
 * @method runMassUpdateAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMassUpdateAPI = ({ requestId, isCompany }) => {
    const url = isCompany
        ? 'mass-update-company/prepare-request'
        : 'mass-update-user/prepare-request';
    return axios({
        method: isCompany ? 'get' : 'post',
        url: `/v2/bulk-change-tool/${url}?requestId=${requestId}`,
    });
};

/**
 * API to run sprint batch process
 *
 * @method runSprintBatchProcessAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runSprintBatchProcessAPI = () => {
    return axios({
        method: 'get',
        url: '/v2/bulk-change-tool/bulkchangetool-run',
    });
};

/**
 * API to run merge multiple companies process
 *
 * @method runMergeMultipleCompaniesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMergeMultipleCompaniesAPI = params => {
    return axios({
        method: 'post',
        url: 'v2/bulk-change-tool/prepare-merge-companies',
        data: params,
    });
};

/**
 * API to fetch details to merge multiple companies
 *
 * @method fetchDetailsMultipleCompaniesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchDetailsMultipleCompaniesAPI = ({ itemId }) => {
    return axios({
        method: 'get',
        url: `v2/bulk-change-tool/merge-companies/${itemId}`,
    });
};

/**
 * API to merge-move single company
 *
 * @method bulkSingleCompanyAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const bulkSingleCompanyAPI = ({ formData, type }) =>
    axios({
        method: 'post',
        data: formData,
        url: `v2/bulk-change-tool/${
            type === 'move' ? 'move-single-company' : 'merge-single-companies'
        }`,
    });

/**
 * API to check mass welcome email
 *
 * @method checkCsvMassWelcomeEmailAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const checkCsvMassWelcomeEmailAPI = params => {
    return axios({
        method: 'post',
        url: 'v2/bulk-change-tool/welcome-email/upload-csv-email',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * API to fetch list of users to mass welcme email
 *
 * @method getWelcomeEmailUsersListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getWelcomeEmailUsersListAPI = params => {
    return axios({
        method: 'get',
        params,
        url: `v2/bulk-change-tool/welcome-email/email-users`,
    });
};

/**
 * API to fetch details to mass update users
 *
 * @method fetchMassUpdateInformationAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchMassUpdateInformationAPI = ({ isMassUpdateCompany, requestId }) => {
    return axios({
        method: 'get',
        url: `v2/bulk-change-tool/${
            isMassUpdateCompany ? 'mass-update-company/get-partner' : 'mass-update-user/get-partner'
        }`,
        params: { requestId },
    });
};

/**
 * API to run mass welcome email process
 *
 * @method runMassWelcomeEmailProcessAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMassWelcomeEmailProcessAPI = params => {
    return axios({
        method: 'post',
        url: 'v2/bulk-change-tool/welcome-email/prepare-welcome-email',
        data: params,
    });
};

/**
 * API to fetch details to merge or move single company
 *
 * @method fetchSingleCompanyInfoAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchSingleCompanyInfoAPI = ({ requestId, type }) => {
    return axios({
        method: 'get',
        url: `v2/bulk-change-tool/${type}-companies/${requestId}`,
    });
};
/**
 * API to fetch list of users to merge multiple companies
 *
 * @method fetchMultipleCompaniesUserListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchMultipleCompaniesUserListAPI = ({ params, requestId, type }) => {
    return axios({
        method: 'get',
        params,
        url: `v2/bulk-change-tool/merge-companies/${type}-users?itemId=${requestId}`,
    });
};

/**
 * API to run merge or move single company process
 *
 * @method runMoveOrMergeSingleCompanyAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMoveOrMergeSingleCompanyAPI = ({ params, type }) => {
    return axios({
        method: 'post',
        url: `v2/bulk-change-tool/prepare-${type}-single-company`,
        data: params,
    });
};

/**
 * API to fetch list of companies to move or merge single company
 *
 * @method getMoveOrMergeSingleCompanyListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getMoveOrMergeSingleCompanyListAPI = ({ page, type, requestId }) => {
    return axios({
        method: 'get',
        url: `v2/bulk-change-tool/${type}-companies/${type}-users?itemId=${requestId}&offset=${
            page > 1 ? (page - 1) * 10 : 0
        }`,
    });
};

/**
 * API to fetch list of companies to move single company
 *
 * @method getMoveCompaniesListAPI
 * @param  {Object} params
 * @param  {number} requestId
 * @return {Promise}
 */
export const getMoveCompaniesListAPI = ({ params, requestId }) => {
    return axios({
        method: 'get',
        params,
        url: `v2/bulk-change-tool/move-companies?requestId=${requestId}`,
    });
};

/**
 * API to fetch user list to merge multiple companies
 *
 * @method fetchUserListToMultipleCompaniesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchUserListToMultipleCompaniesAPI = ({ type, itemId, params }) => {
    const page = isEmpty(params) ? 0 : params.offset;
    return axios({
        method: 'get',

        url: `v2/bulk-change-tool/merge-companies/${type}-users?itemId=${itemId}&offset=${page}`,
    });
};

/**
 * API to run merge process
 *
 * @method runMoveMultipleCompaniesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMoveMultipleCompaniesAPI = params => {
    return axios({
        method: 'post',
        url: 'v2/bulk-change-tool/prepare-move-companies',
        data: params,
    });
};

/**
 * API to check merge partners data
 * @method checkMergePartnersAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const checkMergePartnersAPI = params => {
    return axios({
        method: 'post',
        url: 'v2/bulk-change-tool/merge-partner',
        data: params,
    });
};

/**
 * API to get merge partner information
 * @method fetchMergePartnersInformationAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchMergePartnersInformationAPI = ({ requestId }) => {
    return axios({
        method: 'get',
        url: `v2/bulk-change-tool/merge-partner/${requestId}`,
    });
};

/**
 * API to get merge partner company list.
 * @method getMergePartnerCompanyListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getMergePartnerCompanyListAPI = ({ params, requestId }) => {
    return axios({
        method: 'get',
        params,
        url: `v2/bulk-change-tool/merge-partner?requestId=${requestId}`,
    });
};

/**
 * API to get merge partner user list.
 * @method getMergePartnerUsersListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const getMergePartnerUsersListAPI = ({ params, type, requestId }) => {
    const label =
        type === 'move' ? 'merge-partner/move-users?requestId=' : 'merge-users?requestId=';

    return axios({
        method: 'get',
        params,
        url: `v2/bulk-change-tool/${label}${requestId}`,
    });
};

/**
 * API to run merge partner process
 * @method runMergePartnersProcessAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runMergePartnersProcessAPI = params => {
    return axios({
        method: 'post',
        url: 'v2/bulk-change-tool/prepare-merge-partner',
        data: params,
    });
};

/**
 * API to cancel a bulk change tool process
 * @method cancelBtcProcessByIdAPI
 * @param  {string} requestId
 * @return {Promise}
 */
export const cancelBtcProcessByIdAPI = requestId => {
    return axios({
        method: 'delete',
        url: `v2/bulk-change-tool/dashboard/${requestId}`,
    });
};

/**
 * API to check status of services to run bulk change tool process
 * @method checkBctStatusServicesAPI
 * @param  {string} requestId
 * @return {Promise}
 */
export const checkBctStatusServicesAPI = () => {
    return axios({
        method: 'get',
        url: '/v2/bulk-change-tool/status',
    });
};

/**
 * API to get list of users or companies.
 * @method downloadCsvListAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const downloadCsvListAPI = ({ params, type }) => {
    return axios({
        method: 'get',
        params,
        url: `v2/bulk-change-tool/${type}/csv`,
    });
};

/**
 * API to fetch details to mass welcome email
 *
 * @method fetchMassWelcomeEmailInformationAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchMassWelcomeEmailInformationAPI = ({ requestId }) => {
    return axios({
        method: 'get',
        url: 'v2/bulk-change-tool/welcome-email/get-partner',
        params: { requestId },
    });
};
