import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

const AppNavigationStyled = styled.header`
    align-items: stretch;
    background-color: rgba(0, 152, 219, 1);
    color: white;
    display: flex;
    min-width: 100%;
    position: static;
    flex: ${({ theme }) => {
        const height = getThemeProps('AppNavigation.height', 62, { theme });
        return `0 0 ${height}px`;
    }};
    left: 0;
    padding: 0 5.125rem;
    right: 0;
    top: ${({ isImpersonatingUser, theme }) => {
        let top = 0;

        if (isImpersonatingUser) {
            top += getThemeProps('ImpersonateUserHeader.height', 62, { theme });
        }

        return `${top}px`;
    }};
    width: 100%;
    z-index: 1001;

    ${getThemeProps('AppNavigation.styles')};
`;

export default AppNavigationStyled;
