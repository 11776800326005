import { createBrowserHistory } from 'history';

/**
 * Create the browser history.
 * This is the HTML5 history, and not hash history.
 * Pretty URLs.
 *
 * @type {Object}
 */
const history = createBrowserHistory();

export default history;
