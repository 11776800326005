import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ImportAlertIcon = ({ height, viewBox, width, fill, ...rest }) => {
    return (
        <SvgIcon height={height} width={width} viewBox={viewBox} fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.56689 2.29289C7.75443 2.10536 8.00878 2 8.274 2H15.726C15.9912 2 16.2456 2.10536 16.4331 2.29289L21.7071 7.56689C21.8946 7.75443 22 8.00878 22 8.274V15.726C22 15.9912 21.8946 16.2456 21.7071 16.4331L16.4331 21.7071C16.2456 21.8946 15.9912 22 15.726 22H8.274C8.00878 22 7.75443 21.8946 7.56689 21.7071L2.29289 16.4331C2.10536 16.2456 2 15.9912 2 15.726V8.274C2 8.00878 2.10536 7.75443 2.29289 7.56689L7.56689 2.29289ZM8.68821 4L4 8.68821V15.3118L8.68821 20H15.3118L20 15.3118V8.68821L15.3118 4H8.68821Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 16C11 15.4477 11.4477 15 12 15H12.01C12.5623 15 13.01 15.4477 13.01 16C13.01 16.5523 12.5623 17 12.01 17H12C11.4477 17 11 16.5523 11 16Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ImportAlertIcon.propTypes = {
    height: string,
    width: string,
    viewBox: string,
    fill: string,
};

ImportAlertIcon.defaultProps = {
    height: '24',
    width: '24',
    viewBox: '0 0 24 24',
    fill: DEFAULT_COLORS.RED,
};

export default ImportAlertIcon;
