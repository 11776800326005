import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from 'ravenjs/lib/Box';
import Row from 'ravenjs/lib/Row';

const BadgeStyled = styled(Box)`
    min-width: ${({ minWidth }) => minWidth};
    text-align: center;
`;

const Badge = React.forwardRef((props, ref) => {
    const { children, minWidth, ...rest } = props;

    return (
        <BadgeStyled
            borderRadius="15px"
            borderWidth="0"
            height="auto"
            margin="0"
            minWidth={minWidth}
            padding="2px 5px"
            {...rest}
            ref={ref}
        >
            <Row alignItems="center" justify="center" gutter={false}>
                {children}
            </Row>
        </BadgeStyled>
    );
});

Badge.propTypes = {
    children: PropTypes.node,
    minWidth: PropTypes.string,
    width: PropTypes.string,
};

Badge.defaultProps = {
    children: null,
    minWidth: '32px',
    width: 'auto',
};

Badge.displayName = 'Badge';

export default Badge;
