import { takeLatest } from 'redux-saga/effects';

import * as permissionActions from '../actions';
import { fetchPermissionsSaga } from './permissions';

function* permissionsSaga() {
    yield takeLatest(permissionActions.fetchPermissions.TRIGGER, fetchPermissionsSaga);
}

export default permissionsSaga;
