import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HelpIcon = ({ height, viewBox, width, fill, fontFill, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <path
            d="M18.674 12.217a2.91 2.91 0 0 0-3.236 1.902 1.455 1.455 0 1 1-2.745-.965A5.818 5.818 0 0 1 24 15.092c0 2.226-1.651 3.696-2.83 4.482a11.57 11.57 0 0 1-1.534.854v1.208a1.455 1.455 0 0 1-2.909 0v-2.181c0-.626.4-1.181.993-1.38l.024-.008.108-.04a8.81 8.81 0 0 0 1.705-.874c1.003-.668 1.534-1.38 1.534-2.062v-.002a2.91 2.91 0 0 0-2.417-2.872zM18.298 24.818a1.455 1.455 0 1 0 0 2.91h.015a1.455 1.455 0 0 0 0-2.91h-.015z"
            fill={fontFill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 18C2 9.163 9.163 2 18 2s16 7.163 16 16-7.163 16-16 16S2 26.837 2 18zM18 3.91C10.77 3.91 3.91 10.77 3.91 18c0 7.23 6.86 14.09 14.09 14.09 7.23 0 14.09-6.86 14.09-14.09 0-7.23-6.86-14.09-14.09-14.09z"
            fill={fontFill}
        />
    </SvgIcon>
);

HelpIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
    fontFill: string,
};

HelpIcon.defaultProps = {
    viewBox: '0 0 36 36',
    height: '36',
    width: '36',
    fill: DEFAULT_COLORS.BLACK_00,
    fontFill: DEFAULT_COLORS.BLACK_00,
};

export default HelpIcon;
