import React from 'react';
import { bool, func, node, oneOfType, number, string } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { extractErrorMessage } from 'ravenjs/utils/error';
import { get } from 'ravenjs/utils/lodash';

import MESSAGES from 'constants/messages';
import { MODALS } from 'constants/modals';
import { HISTORY } from 'constants/proptypes';
import { actions as brandingActions, selectors as brandingSelectors } from 'modules/branding';
import { actions as uiActions } from 'modules/ui';
import { selectors as userSelectors } from 'modules/user';

const BrandingSwitcher = ({
    children,
    fetchBranding,
    history,
    isLoading,
    isUsingTemporaryTheme,
    loggedIn,
    openModal,
    setIsUsingTemporaryTheme,
    userPartnerId,
}) => {
    const pathname = get(history, 'location.pathname', '');

    const getPartnerBranding = React.useCallback(async () => {
        const partnerId = typeof userPartnerId === 'number' ? userPartnerId : false;

        isLoading(true, MESSAGES.LOADING.LOADING);
        try {
            if (partnerId) {
                await fetchBranding(partnerId);
            }
        } catch (e) {
            openModal(MODALS.CONFIRM, {
                title: MESSAGES.MODAL.CONFIRM.TITLE.ERROR,
                description: extractErrorMessage(e),
            });
        } finally {
            isLoading(false);
        }
    }, [fetchBranding, isLoading, openModal, userPartnerId]);

    React.useEffect(() => {
        if (loggedIn && pathname.indexOf('/admin/branding/') === -1 && isUsingTemporaryTheme) {
            setIsUsingTemporaryTheme(false);
            getPartnerBranding();
        }
    }, [getPartnerBranding, isUsingTemporaryTheme, loggedIn, pathname, setIsUsingTemporaryTheme]);

    return <>{children}</>;
};

BrandingSwitcher.propTypes = {
    children: node.isRequired,
    fetchBranding: func.isRequired,
    history: HISTORY.isRequired,
    isLoading: func.isRequired,
    isUsingTemporaryTheme: bool.isRequired,
    loggedIn: bool,
    openModal: func.isRequired,
    setIsUsingTemporaryTheme: func.isRequired,
    userPartnerId: oneOfType([string, number]),
};

BrandingSwitcher.defaultProps = {
    loggedIn: false,
    userPartnerId: 0,
};

const mapDispatchToProps = {
    fetchBranding: brandingActions.fetchBranding,
    isLoading: uiActions.isLoading,
    setIsUsingTemporaryTheme: brandingActions.setIsUsingTemporaryTheme,
    openModal: uiActions.openModal,
};

const mapStateToProps = createStructuredSelector({
    isUsingTemporaryTheme: brandingSelectors.getIsUsingTemporaryTheme,
    userPartnerId: userSelectors.getPartnerId,
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandingSwitcher);
