import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const SuccessIcon = ({ color, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4398 6.03542C18.291 5.07797 15.8903 4.84077 13.5957 5.35921C11.3011 5.87765 9.2355 7.12395 7.70705 8.91222C6.17861 10.7005 5.26918 12.9349 5.1144 15.2823C4.95961 17.6297 5.56777 19.9642 6.84816 21.9377C8.12855 23.9112 10.0126 25.4179 12.2193 26.2331C14.4259 27.0484 16.837 27.1285 19.093 26.4615C21.3489 25.7945 23.3287 24.4161 24.7373 22.5319C26.1458 20.6478 26.9076 18.3588 26.9089 16.0063V14.8919C26.9089 14.2224 27.4516 13.6797 28.1211 13.6797C28.7905 13.6797 29.3332 14.2224 29.3332 14.8919V16.007C29.3315 18.8822 28.4005 21.6806 26.6789 23.9835C24.9574 26.2863 22.5375 27.971 19.7803 28.7862C17.0231 29.6015 14.0762 29.5036 11.3791 28.5072C8.68208 27.5107 6.37938 25.6692 4.81445 23.2571C3.24953 20.8451 2.50623 17.9918 2.69541 15.1228C2.88459 12.2538 3.99611 9.52281 5.86421 7.33714C7.73231 5.15147 10.2569 3.62822 13.0614 2.99457C15.866 2.36093 18.8002 2.65083 21.4265 3.82105C22.038 4.09351 22.3128 4.81008 22.0404 5.42157C21.7679 6.03305 21.0513 6.30788 20.4398 6.03542Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.9777 5.45269C29.4513 5.92581 29.4517 6.69328 28.9786 7.16688L16.8574 19.3002C16.6301 19.5277 16.3217 19.6556 16.0001 19.6557C15.6786 19.6557 15.3701 19.528 15.1427 19.3006L11.5064 15.6643C11.033 15.1909 11.033 14.4234 11.5064 13.9501C11.9797 13.4767 12.7472 13.4767 13.2206 13.9501L15.9994 16.7289L27.2635 5.45354C27.7366 4.97994 28.5041 4.97956 28.9777 5.45269Z"
                fill={color}
            />
        </SvgIcon>
    );
};

SuccessIcon.propTypes = {
    color: string,
    height: string,
    viewBox: string,
    width: string,
};

SuccessIcon.defaultProps = {
    color: DEFAULT_COLORS.GREEN_1,
    height: '32',
    viewBox: '0 0 32 32',
    width: '32',
};

export default SuccessIcon;
