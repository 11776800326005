import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';

import * as authActions from 'modules/auth/actions';
import * as uiActions from './actions';

const INITIAL_STATE = {};

export const setCaseListSettings = (state, action) => {
    return {
        ...state,
        caseListSettings: _get(action, 'payload', {}),
    };
};

export const setUnknownCaseSettings = (state, action) => {
    return {
        ...state,
        unknownCaseSettings: _get(action, 'payload', {}),
    };
};

export const setMessagesSearch = (state, action) => {
    return {
        ...state,
        messagesSearch: _get(action, 'payload', {}),
    };
};

export const setMessagesFilters = (state, action) => {
    return {
        ...state,
        messagesFilters: _get(action, 'payload', {}),
    };
};

export const setPreppedAnswerSettings = (state, action) => {
    return {
        ...state,
        preppedAnswerSettings: _get(action, 'payload', {}),
    };
};

export const setMeetingsFilters = (state, action) => {
    return {
        ...state,
        meetingsFilters: _get(action, 'payload', {}),
    };
};

export const onLogoutSuccess = () => INITIAL_STATE;

const cases = {
    [uiActions.setCaseListSettings.TRIGGER]: setCaseListSettings,
    [uiActions.setUnknownCaseSettings.TRIGGER]: setUnknownCaseSettings,
    [uiActions.setMeetingsFilters.TRIGGER]: setMeetingsFilters,
    [uiActions.setMessagesFilters.TRIGGER]: setMessagesFilters,
    [uiActions.setMessagesSearch.TRIGGER]: setMessagesSearch,
    [uiActions.setPreppedAnswerSettings.TRIGGER]: setPreppedAnswerSettings,
    [authActions.logout.SUCCESS]: onLogoutSuccess,
};

export default createReducer(INITIAL_STATE, cases);

export const casesState = state => _get(state, 'ui.cases', {});

export const getCaseListSettings = createSelector([casesState], uiCases =>
    _get(uiCases, 'caseListSettings', {})
);

export const getMeetingsFilters = createSelector([casesState], uiCases =>
    _get(uiCases, 'meetingsFilters', {})
);

export const getMessagesSearch = createSelector([casesState], uiCases =>
    _get(uiCases, 'messagesSearch', {})
);

export const getMessagesFilters = createSelector([casesState], uiCases =>
    _get(uiCases, 'messagesFilters', {})
);

export const getPreppedAnswerSettings = createSelector([casesState], uiCases =>
    _get(uiCases, 'preppedAnswerSettings', {})
);

export const getUnknownCaseSettings = createSelector([casesState], uiCases =>
    _get(uiCases, 'unknownCaseSettings', {})
);
