import React from 'react';
import { array, bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _debounce from 'lodash/debounce';
import styled, { withTheme } from 'styled-components';
import Autosuggest from 'react-autosuggest';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

import Button from 'ravenjs/lib/Button';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import { formatDate } from 'ravenjs/utils/date';
import { renderCheck } from 'ravenjs/utils/viewport';
import { getThemeProps } from 'ravenjs/utils/theme';
import Col from 'ravenjs/lib/Col';
import Input from 'ravenjs/lib/Input';
import Container from 'ravenjs/lib/Container';
import Row from 'ravenjs/lib/Row';
import Sticky from 'ravenjs/lib/Sticky';
import Typography from 'ravenjs/lib/Typography';

import { ContentSearchContext } from 'components/ContentSearch/ContentSearchContext';
import { BackIcon, SearchIcon } from 'components/Icons';
import QuickProductLinks from 'components/QuickProductLinks';
import { DEFAULT_COLORS } from 'constants/colors';
import { PENDO_SEARCH } from 'constants/pendo';
import { APP_PERMISSIONS } from 'constants/permissions';
import { SPECIAL_SECTION_NEED_BACK } from 'constants/navigation';
import { AUTO_SUGGEST } from 'constants/search';
import { CONTENT_TYPES_MAPPED } from 'constants/content';
import { LANDING } from 'constants/helpCenter';
import { EVENTS } from 'constants/events';
import Acl from 'modules/acl';
import { actions as adminActions } from 'modules/admin';
import { actions as contentfulActions } from 'modules/contentful';
import { actions as pendoActions } from 'modules/pendo';
import { selectors as userSelectors } from 'modules/user';
import { linkTo } from 'utils/content';
import { getTypeAheadContentTypes, pushesToSearchPage } from 'utils/search';
import { isFirefox } from 'utils/thrservice';

import { selectors as uiSelectors } from 'modules/ui';

import TypeAheadContainer from './TypeAheadContainer';
import TypeAheadSuggestion from './TypeAheadSuggestion';

const ContentSearchBar = styled.div`
    top: 0;
    height: 3.125rem;
    background: ${({ theme }) =>
        getThemeProps('palette.secondary.gradientColor', DEFAULT_COLORS.BLUE, { theme })};
    z-index: 800;
    padding: 0.375rem 0;
`;

const ContainerSearch = styled(Container)`
    margin: 0 4.5rem;
    padding-left: 0.8125rem;
    padding-right: 0.8125rem;
    width: auto !important;
`;

const BackButtonContainer = styled.div`
    width: 100%;
    &.backButtonWithNav {
        width: auto;
    }
`;

const BackButton = styled(Button)`
    display: flex;
    align-items: center;
    min-height: 2.375rem;
`;

const BackButtonText = styled(Typography)`
    ${({ theme }) => theme.media.down('md')`
        margin-left: 0.5rem !important;
    `};
`;

const InputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const InputStyled = styled(Input)`
    border-radius: 0;
    border-width: 0;
    color: ${DEFAULT_COLORS.BLACK};
    font-size: 0.875rem;
    padding: 0.625rem 3.25rem 0.563rem 0.875rem;
    :focus {
        border-color: ${DEFAULT_COLORS.LIGHT};
        box-shadow: none;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 0.781rem;
    height: 100%;
    cursor: pointer;
`;

const SubNavigationStyled = styled.div`
    display: flex;
    align-items: center;
`;

const SubNavTitleStyled = styled(Typography)`
    position: relative;
    line-height: 38px !important;
`;

const ContentSearch = ({
    enablePushToSearchPage,
    fetchContentfulContent,
    history,
    onClickBack,
    sendPendoEvent,
    theme,
    userCompanyLocations,
    navigationSchema,
    loggedUserCompany,
}) => {
    const location = useLocation();

    const queryParams = get(history, 'location.search', '');
    const queryParamsParse = queryString.parse(queryParams);
    const [searchState, setSearchState] = React.useContext(ContentSearchContext);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [locations, setLocations] = React.useState([]);

    const [highlightedSuggestion, setHighlightedSuggestion] = React.useState(null);
    const [isLoadingTypeAhead, setIsLoadingTypeAhead] = React.useState(false);
    const [showTypeAheadContainer, setShowTypeAheadContainer] = React.useState(false);
    const [suggestions, setSuggestions] = React.useState([]);
    const [typeAheadResults, setTypeAheadResults] = React.useState([]);
    const [typeAheadResultsTotal, setTypeAheadResultsTotal] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [currentFilters, setCurrentFilters] = React.useState([]);
    const showSearch = searchState.showSearchBar;
    const fetchLocationByCompany = React.useCallback(async () => {
        try {
            if (isEmpty(filters)) {
                const locationByCompany = userCompanyLocations
                    .filter(item => item.state)
                    .map(item => {
                        return item.state;
                    });
                const locationsFormatted = locationByCompany.filter(
                    (el, index) => locationByCompany.indexOf(el) === index
                );
                const selectedFilters = {
                    category: ['categoryAll'],
                    contentTypes: ['contentTypeAll'],
                    employeeCount: ['employeeCountAll'],
                    jurisdiction: ['jurisdictionAll'],
                    sort: 'default',
                    locations: locationsFormatted,
                    status: 'current',
                };
                const currentFilters = getFilterSettings(selectedFilters);
                setFilters(selectedFilters);
                setLocations(locationsFormatted);
                setCurrentFilters(currentFilters);
            }
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }, [filters, userCompanyLocations]);

    const getFilters = () => {
        const selectedFilters = {
            category: ['categoryAll'],
            contentTypes: ['contentTypeAll'],
            employeeCount: ['employeeCountAll'],
            jurisdiction: ['jurisdictionAll'],
            sort: 'default',
            locations,
            status: 'current',
        };

        return selectedFilters;
    };

    const search = () => {
        const { companyName, companyId } = loggedUserCompany;
        setShowTypeAheadContainer(false);
        setSearchState({ ...searchState, searchTerm });
        const pendoEvent = EVENTS.INTELLIGENCE.MI_CORE_SEARCH_BAR_METRICS;
        pendoEvent.eventProperties.searchTerm = searchTerm;
        pendoEvent.eventProperties.searchCategory = '';
        pendoEvent.eventProperties.companyName = companyName;
        pendoEvent.eventProperties.companyId = companyId;
        pendoEvent.eventProperties.clickTimeStamp = new Date(formatDate(new Date(), 'MM/DD/YYYY'));
        pendoEvent.eventProperties.searchClick = 'Search Icon Clicked';
        sendPendoEvent(pendoEvent);
        history.push(
            `/search?query=${encodeURIComponent(searchTerm)}&filters=${JSON.stringify(
                getFilters()
            )}`
        );
        debouncedFetchTypeAheadContent.cancel();
    };

    const handleSearchIconClick = () => {
        search();
    };

    const onKeyDown = event => {
        if (event.keyCode === 13 && highlightedSuggestion === null) {
            search();
        }
    };

    const handleSearchOnChange = (event, { newValue }) => {
        const truncatedSearchTerm = newValue.substring(0, AUTO_SUGGEST.MAX_LENGTH);
        setSearchTerm(truncatedSearchTerm);
        setShowTypeAheadContainer(true);
    };

    const getFilterSettings = filters => {
        return {
            jurisdiction: filters.jurisdiction.some(filterId => filterId === 'jurisdictionAll')
                ? undefined
                : filters.jurisdiction,
            locations: filters.locations,
        };
    };

    const fetchTypeAheadContent = React.useCallback(
        async (typeAheadSearchTerm, filters) => {
            try {
                setIsLoadingTypeAhead(true);
                const formatTypeAheadResults = typeAheadResults => {
                    const { results = [], siteNavigation = [] } = typeAheadResults;
                    const formatData = results.map(item => {
                        item.originalContentType = item.contentType;

                        if (item.contentType === CONTENT_TYPES_MAPPED.WEBINARS_ON_DEMAND) {
                            item.contentType = 'webinars';
                            item.contentTypeLabel = 'webinars';
                        }

                        return item;
                    });

                    if (!formatData) {
                        return [];
                    }

                    const quickProductLinks =
                        siteNavigation && siteNavigation.length
                            ? { contentType: 'siteNavigation', siteNavigation }
                            : [];

                    return [].concat(quickProductLinks, ...formatData);
                };

                const param = {
                    params: {
                        'content-type': getTypeAheadContentTypes(history.location.pathname),
                        filters: {
                            ...filters,
                            status: 'current',
                        },
                        limit: 15,
                        search: typeAheadSearchTerm,
                        'show-suggestions': false,
                        'type-ahead': true,
                    },
                };
                const typeAheadResults = await fetchContentfulContent(param);
                const total = get(typeAheadResults, 'total.total', 0);
                setIsLoadingTypeAhead(false);
                setTypeAheadResultsTotal(total || {});
                setTypeAheadResults(formatTypeAheadResults(typeAheadResults));
                return Promise.resolve();
            } catch (error) {
                setIsLoadingTypeAhead(false);
                return Promise.reject(error);
            }
        },
        [fetchContentfulContent, history]
    );

    const debouncedFetchTypeAheadContent = React.useCallback(
        _debounce(
            (typeAheadSearchTerm, filters) => fetchTypeAheadContent(typeAheadSearchTerm, filters),
            300
        ),
        []
    );

    React.useEffect(() => {
        setSuggestions(typeAheadResults);
    }, [typeAheadResults]);

    React.useEffect(() => {
        if (pushesToSearchPage(history.location.pathname)) {
            setSearchState({ searchTerm: '', showSearchBar: searchState.showSearchBar });
            setSearchTerm('');
            setSuggestions([]);
            fetchLocationByCompany();
        }
        if (!enablePushToSearchPage) {
            fetchLocationByCompany();
        }
    }, [
        enablePushToSearchPage,
        history.location.pathname,
        history.location.search,
        fetchLocationByCompany,
        setSearchState,
        searchState.showSearchBar,
    ]);

    React.useEffect(() => {
        if (history.location.pathname.indexOf('/help-center') === -1) {
            const truncatedSearchTerm =
                (queryParamsParse.query &&
                    queryParamsParse.query.substring(0, AUTO_SUGGEST.MAX_LENGTH)) ||
                '';
            queryParamsParse.query = truncatedSearchTerm;
            setSearchTerm(truncatedSearchTerm);
        }
    }, [history.location.pathname, queryParamsParse.query]);

    React.useEffect(() => {
        setTimeout(() => {
            if (isFirefox()) {
                window.scroll(0, 10);
            }
        }, 750);
    }, [history.location.pathname]);

    React.useEffect(() => {
        if (history.location.pathname.indexOf('/help-center') === -1) {
            const truncatedSearchTerm =
                (searchState.searchTerm &&
                    searchState.searchTerm.substring(0, AUTO_SUGGEST.MAX_LENGTH)) ||
                '';
            setSearchTerm(truncatedSearchTerm);
        }
    }, [history.location.pathname, searchState.searchTerm]);

    const isHelpCenter = () => {
        return history.location.pathname.indexOf('/help-center') !== -1;
    };

    const onSuggestionsFetchRequested = ({ value, reason }) => {
        if (reason === 'input-changed') {
            debouncedFetchTypeAheadContent(value, currentFilters);
        } else if (reason === 'input-focused') {
            setSuggestions(typeAheadResults);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const renderInputComponent = inputProps => (
        <InputContainer>
            <InputStyled {...inputProps} />
            <IconWrapper onClick={handleSearchIconClick}>
                <SearchIcon />
            </IconWrapper>
        </InputContainer>
    );

    const renderSuggestionsContainer = suggestionsProps => {
        return (
            <TypeAheadContainer
                isLoading={isLoadingTypeAhead}
                showTypeAheadContainer={showTypeAheadContainer}
                {...suggestionsProps}
            />
        );
    };

    const renderSuggestion = (suggestion, { isHighlighted }) => {
        return suggestion.contentType === 'siteNavigation' ? (
            <QuickProductLinks history={history} quickProductLinks={suggestion.siteNavigation} />
        ) : (
            <TypeAheadSuggestion suggestion={suggestion} isHighlighted={isHighlighted} />
        );
    };

    const getSuggestionValue = () => {
        return searchTerm;
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        const { companyName, companyId } = loggedUserCompany;
        if (suggestion.contentType === 'siteNavigation') {
            return;
        }

        setSearchState({ ...searchState, searchTerm: '' });
        setSearchTerm('');

        const pendoEvent = EVENTS.INTELLIGENCE.MI_CORE_SEARCH_BAR_METRICS;
        pendoEvent.eventProperties.searchTerm = searchTerm;
        pendoEvent.eventProperties.searchCategory = suggestion?.documentType;
        pendoEvent.eventProperties.companyName = companyName;
        pendoEvent.eventProperties.companyId = companyId;
        pendoEvent.eventProperties.clickTimeStamp = new Date(formatDate(new Date(), 'MM/DD/YYYY'));
        pendoEvent.eventProperties.searchClick = 'Search suggestion Clicked';
        sendPendoEvent(pendoEvent);
        sendPendoEvent({
            event: PENDO_SEARCH.TYPE_AHEAD_ARTICLE.EVENT,
            eventProperties: {
                contentfulId: suggestion.id,
                description: PENDO_SEARCH.TYPE_AHEAD_ARTICLE.DESCRIPTION,
                searchTerm,
                searchCounts: JSON.stringify(typeAheadResultsTotal),
            },
        });

        if (!isEmpty(suggestion.sections)) {
            const helpSection = get(suggestion, 'sections.0', {});
            suggestion.helpSection = helpSection;
        }

        linkTo(suggestion, history);
    };

    const onSuggestionHighlighted = ({ suggestion }) => {
        setHighlightedSuggestion(suggestion);
    };

    const showBackButton = () => {
        return (
            !renderCheck('md', 'less') &&
            (!!location.key ||
                checkSpecialBackSection(location.pathname) ||
                !isEmpty(document.referrer)) &&
            typeof onClickBack === 'function'
        );
    };

    /* Special validation where location.key is not working correctly */
    const checkSpecialBackSection = section => {
        const isValid = SPECIAL_SECTION_NEED_BACK.filter(item => section.indexOf(item) !== -1);

        return isValid.length > 0;
    };

    const showSearchBox = () => {
        return isHelpCenter() || !(!showSearch || !Acl.check(APP_PERMISSIONS.compliance));
    };

    const getSearch = () => {
        if (
            !showSearch ||
            (Acl.check(APP_PERMISSIONS.compliance) &&
                Acl.check(APP_PERMISSIONS.viewPlatformliteInsights) &&
                !Acl.check(APP_PERMISSIONS.platformliteAdCampaignView))
        ) {
            return null;
        }
        return (
            <Col id={AUTO_SUGGEST.PENDO_ID} size={{ xs: 12, md: 8, lg: 5 }}>
                <Autosuggest
                    getSuggestionValue={getSuggestionValue}
                    inputProps={{
                        onChange: handleSearchOnChange,
                        onKeyDown,
                        maxLength: AUTO_SUGGEST.MAX_LENGTH,
                        placeholder: AUTO_SUGGEST.PLACEHOLDER,
                        value: searchTerm,
                    }}
                    onSuggestionSelected={onSuggestionSelected}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionHighlighted={onSuggestionHighlighted}
                    renderInputComponent={renderInputComponent}
                    renderSuggestion={renderSuggestion}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    suggestions={suggestions}
                />
            </Col>
        );
    };

    if (showBackButton() || showSearchBox()) {
        return (
            <Sticky innerZ={800} enabled={renderCheck('md', 'greater')}>
                <ContentSearchBar className="pendo-content-search-bar">
                    <ContainerSearch>
                        <Row>
                            <Col size={{ xs: 0, md: 2, lg: 7 }}>
                                <SubNavigationStyled>
                                    {showBackButton() && typeof onClickBack === 'function' && (
                                        <BackButtonContainer className="backButtonWithNav">
                                            <BackButton
                                                className="pendo-content-search-back-button"
                                                align="left"
                                                noMinWidth
                                                gutterBottom="0"
                                                padding="0.5625rem 0.5625rem 0.5625rem 0"
                                                borderRadius="0"
                                                color="backButton"
                                                onClick={onClickBack}
                                            >
                                                <BackIcon
                                                    stroke={getThemeProps(
                                                        'palette.secondary.text',
                                                        DEFAULT_COLORS.DARKEST,
                                                        {
                                                            theme,
                                                        }
                                                    )}
                                                />
                                                <BackButtonText type="backButton">
                                                    back
                                                </BackButtonText>
                                            </BackButton>
                                        </BackButtonContainer>
                                    )}
                                    {isHelpCenter() && !showBackButton() && (
                                        <SubNavTitleStyled
                                            gutterBottom="0"
                                            gutterTop="0"
                                            gutterRight="10px"
                                            type="headline"
                                            fontSize="1rem"
                                            fontFamily="Favorit"
                                            color={getThemeProps(
                                                'palette.secondary.text',
                                                DEFAULT_COLORS.DARKEST,
                                                {
                                                    theme,
                                                }
                                            )}
                                        >
                                            {LANDING.mainTitle}
                                        </SubNavTitleStyled>
                                    )}
                                </SubNavigationStyled>
                            </Col>
                            {!isHelpCenter() && getSearch()}
                        </Row>
                    </ContainerSearch>
                </ContentSearchBar>
            </Sticky>
        );
    }

    return null;
};

ContentSearch.propTypes = {
    contentTypes: array.isRequired,
    enablePushToSearchPage: bool,
    fetchAllLocationsForCompany: func.isRequired,
    fetchContentfulContent: func.isRequired,
    fetchContentfulJurisdictions: func.isRequired,
    history: object.isRequired,
    loggedUserCompany: object.isRequired,
    onClickBack: func,
    sendPendoEvent: func.isRequired,
    theme: object.isRequired,
    userCompanyLocations: array,
    navigationSchema: object,
};

ContentSearch.defaultProps = {
    enablePushToSearchPage: true,
    onClickBack: null,
    userCompanyLocations: [],
    navigationSchema: {},
};

const mapDispatchToProps = {
    fetchAllLocationsForCompany: adminActions.fetchAllLocationsForCompany,
    fetchContentfulContent: contentfulActions.fetchContentfulContent,
    fetchContentfulJurisdictions: contentfulActions.fetchContentfulJurisdictions,
    sendPendoEvent: pendoActions.sendPendoEvent,
};

const mapStateToProps = createStructuredSelector({
    contentTypes: userSelectors.getContentTypes,
    loggedUserCompany: userSelectors.getUserCompany,
    userCompanyLocations: userSelectors.getUserCompanyLocations,
    navigationSchema: uiSelectors.getNavigation,
});

export { ContentSearch as ContentSearchUnwrapped };
export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(ContentSearch)));
