import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const ArrowLeftIconLarge = ({ height, viewBox, width, fillColor }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.2678 35.9694C28.2915 36.8992 26.7085 36.8992 25.7322 35.9694L10.7322 21.6837C9.75592 20.7539 9.75592 19.2463 10.7322 18.3165L25.7322 4.03078C26.7085 3.10096 28.2915 3.10096 29.2678 4.03078C30.2441 4.9606 30.2441 6.46813 29.2678 7.39795L16.0355 20.0001L29.2678 32.6022C30.2441 33.532 30.2441 35.0396 29.2678 35.9694Z"
                fill={fillColor}
            />
        </SvgIcon>
    );
};

ArrowLeftIconLarge.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
};

ArrowLeftIconLarge.defaultProps = {
    width: '40',
    height: '40',
    viewBox: '0 0 40 40',
    fillColor: DEFAULT_COLORS.BLACK,
};

export default ArrowLeftIconLarge;
