import React from 'react';
import { string } from 'prop-types';

import SvgIcon from '../SvgIcon';

const ExternalLinkIcon = ({ fill, height, width, viewBox }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2.667a.667.667 0 1 1 0-1.334h4c.368 0 .667.299.667.667v4a.667.667 0 1 1-1.333 0V3.61L7.138 9.804a.667.667 0 0 1-.943-.943l6.196-6.195H10zm-6.667 2a.667.667 0 0 0-.666.666v7.334a.667.667 0 0 0 .667.666h7.333a.667.667 0 0 0 .667-.666v-4a.667.667 0 0 1 1.333 0v4a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V5.333a2 2 0 0 1 2-2h4a.667.667 0 0 1 0 1.334h-4z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ExternalLinkIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

ExternalLinkIcon.defaultProps = {
    fill: '#282828',
    height: '16',
    width: '16',
    viewBox: '0 0 16 16',
};

export default ExternalLinkIcon;
