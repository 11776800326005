import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const SafetyContentLibraryIcon = ({
    height,
    viewBox,
    width,
    fillColor,
    fillColorComplementary,
    strokeColor,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <rect fill="none" width="64" height="64" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fillColor}
                d="M59.5,42.5c-3.9,6.9-3,14.2,0.3,20.5l-49.2,0.3c-5.6,0-9.6-4.6-9.6-10.4c0-5.8,3.8-10.2,9.5-10.2
                c-5.6,0-9-4.9-9-10.7s3.3-10.7,9-10.7c-5.6,0-9-4.6-9-10.4c0-5.8,4.5-10,10.2-10l47.7-0.1c-4.7,7.7-4.4,14.7,0.1,20.5
                C54.5,28.4,54.8,37,59.5,42.5z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fillColorComplementary}
                d="M60,63.1l-46.1-0.2c-5.2,0-9.3-3.7-9.3-9.3c0-5.7,4.1-10.4,9.4-10.4c-1.1,0-2.1-0.2-3-0.5h48.7
                C57.8,45.6,53.1,52.9,60,63.1z M9.8,21.3c-3.1-1.7-5.2-5.3-5.2-9.3c0-5.7,3.8-9.1,9.1-9.1h44.8c-4.4,7.5-2.4,12.8,1.8,18.4H9.8z"
            />
            <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fillColor}
                points="44.1,10.4 39.8,10.7 39.6,0.8 48.5,0.8 48.8,12.3"
            />
            <g>
                <path
                    fill={strokeColor}
                    d="M62.7,20.7c0.6,0,1,0.5,1,1s-0.5,1-1,1H11.1c-2.4,0-4.6,1-6.2,2.7c-1.6,1.7-2.6,4.1-2.6,6.6
                    c0,2.6,1,4.9,2.6,6.6c1.6,1.7,3.8,2.7,6.2,2.7h51.6c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1H11.1c-3,0-5.7-1.3-7.6-3.3
                    c-2-2.1-3.2-4.9-3.2-8c0-3.1,1.2-6,3.1-8c1.9-2,4.6-3.3,7.6-3.3C11.1,20.7,62.7,20.7,62.7,20.7z"
                />
                <path
                    fill={strokeColor}
                    d="M63,41.3c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1H10.9c-2.5,0-4.7,1-6.2,2.7C3,47.7,2,50.1,2,52.7s1,4.9,2.6,6.6
                    c1.6,1.7,3.8,2.7,6.2,2.7H63c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1H10.9c-3,0-5.7-1.3-7.7-3.3c-2-2.1-3.2-4.9-3.2-8s1.2-6,3.2-8
                    c2-2,4.7-3.3,7.7-3.3C10.9,41.3,63,41.3,63,41.3z"
                />
                <path
                    fill={strokeColor}
                    d="M62.7,0c0.6,0,1,0.4,1,1s-0.5,1-1,1H11.1C8.6,2,6.5,3,4.9,4.7c-1.6,1.7-2.6,4.1-2.6,6.6c0,2.6,1,4.9,2.6,6.6
                    c1.6,1.7,3.8,2.7,6.2,2.7h51.6c0.6,0,1,0.5,1,1s-0.5,1-1,1H11.1c-3,0-5.7-1.3-7.6-3.3c-2-2.1-3.2-4.9-3.2-8c0-3.1,1.2-5.9,3.2-8
                    C5.4,1.3,8.1,0,11.1,0C11.1,0,62.7,0,62.7,0z"
                />
                <path
                    fill={strokeColor}
                    d="M58.4,21.1c0.3-0.5,0.9-0.6,1.4-0.3s0.6,0.9,0.3,1.4C58,25.4,57,28.7,57,32s1,6.6,3.1,9.8
                    c0.3,0.5,0.2,1.1-0.3,1.4s-1.1,0.2-1.4-0.3C56.1,39.4,55,35.7,55,32S56.1,24.6,58.4,21.1z"
                />
                <path
                    fill={strokeColor}
                    d="M58.9,41.8c0.3-0.5,0.9-0.6,1.4-0.3c0.5,0.3,0.6,0.9,0.3,1.4c-2.1,3.1-3.1,6.5-3.1,9.8s1,6.6,3.1,9.8
                    c0.3,0.5,0.2,1.1-0.3,1.4s-1.1,0.2-1.4-0.3c-2.3-3.5-3.5-7.2-3.5-10.9S56.6,45.3,58.9,41.8z"
                />
                <path
                    fill={strokeColor}
                    d="M58.4,0.5c0.3-0.5,0.9-0.6,1.4-0.3s0.6,0.9,0.3,1.4C58,4.7,57,8,57,11.3s1,6.6,3.1,9.8
                    c0.3,0.5,0.2,1.1-0.3,1.4c-0.5,0.3-1.1,0.2-1.4-0.3C56.1,18.7,55,15,55,11.3S56.1,3.9,58.4,0.5z"
                />
                <path
                    fill={strokeColor}
                    d="M35.2,1c0-0.6,0.5-1,1-1c0.5,0,1,0.4,1,1v10.1l4.7-1.8c0.5-0.2,1.1,0.1,1.3,0.6c0.2,0.5-0.1,1.1-0.6,1.3
                    l-5.9,2.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.6,0-1-0.5-1-1C35.2,12.5,35.2,1,35.2,1z"
                />
                <path
                    fill={strokeColor}
                    d="M47.2,1c0-0.6,0.5-1,1-1c0.5,0,1,0.4,1,1v11.5c0,0.6-0.5,1-1,1c-0.2,0-0.3,0-0.4-0.1l-5.9-2.2
                    c-0.5-0.2-0.8-0.8-0.6-1.3c0.2-0.5,0.8-0.8,1.3-0.6l4.6,1.8C47.2,11.1,47.2,1,47.2,1z"
                />
            </g>
        </SvgIcon>
    );
};

SafetyContentLibraryIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
    fillColorComplementary: string,
    strokeColor: string,
};

SafetyContentLibraryIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
    fillColor: `${DEFAULT_COLORS.WHITE}`,
    fillColorComplementary: `${DEFAULT_COLORS.BLUE}`,
    strokeColor: `${DEFAULT_COLORS.BLACK}`,
};

export default SafetyContentLibraryIcon;
