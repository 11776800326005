import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

import { ELEMENT_IDS } from 'constants/elements';

const AppContainerStyled = styled.div.attrs(() => ({
    id: ELEMENT_IDS.AppContainer,
}))`
    position: relative;
    flex: 1 1 auto;

    ${getThemeProps('AppWrapper.styles')};
`;

export default AppContainerStyled;
