/* istanbul ignore file */
import { darken, rgba } from 'polished';

import { get } from 'ravenjs/utils/lodash';
import { THEME } from 'ravenjs/constants/theme';

import { DEFAULT_BRANDING } from 'constants/app';
import { BRANDING } from 'constants/branding';
import { DEFAULT_COLORS } from 'constants/colors';
import { SPACING } from 'constants/spacing';
import { INFO } from 'constants/version';

/**
 * Build the branding action payload
 *
 * @param   {Object}    branding
 * @return  {Object}
 */
export const buildBrandingSuccessActionPayload = branding => {
    const config = get(branding, 'data.partner-branding.brandingAttribute', {});
    const partnerName = get(branding, 'data.partner-branding.partnerName', '');
    const platformName = get(branding, 'data.partner-branding.platformName', '');
    const platformDomain = get(branding, 'data.partner-branding.platformDomain', '');
    const mainPartnerName = get(branding, 'data.partner-branding.mainPartnerName', '');
    const partnerDisplayName = get(branding, 'data.partner-branding.partnerDisplayName', '');
    const theme = buildThemeWithConfig(config);
    const { sprintTimestamp } = INFO;

    return {
        config,
        mainPartnerName,
        partnerName,
        platformName,
        platformDomain,
        sprintTimestamp,
        partnerDisplayName,
        theme,
    };
};

/**
 * Build a default theme for the branding state
 *
 * @method buildThemeWithConfig
 * @param  {Object}             [brandingConfig={}] The branding config
 * @return {Object}                           The pre-built theme
 */
export const buildThemeWithConfig = (brandingConfig = {}) => {
    const branding = { ...DEFAULT_BRANDING, ...brandingConfig };
    return {
        AppNavigation: {
            styles: {
                backgroundColor: branding.topNavColor,
            },
            height: 62,
        },
        MobileAppNavigation: {
            styles: {
                backgroundColor: branding.topNavColor,
            },
            height: 130,
        },
        Button: {
            styles: {},
        },
        ButtonSecondary: {
            styles: {},
        },
        DataView: {
            primaryText: {
                styles: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Roboto',
                    color: 'inherit',
                    fontSize: '0.875rem !important',
                },
            },
        },
        Form: {
            styles: {
                form: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                },
            },
        },
        FormGroupTitle: {
            styles: {
                color: DEFAULT_COLORS.BLACK,
                lineHeight: 'normal',
            },
        },
        FormGroupTitleRequired: {
            styles: {
                fontWeight: 700,
            },
        },
        NavLogo: {
            alt: branding.companyName,
            src: branding.logoUrl,
        },
        NavItem: {
            styles: {
                backgroundColor: branding.topNavColor,
                color: branding.topNavTextColor,
                fontFamily: 'Favorit, Arial, Helvetica, sans-serif',
                fontSize: '0.6rem',
            },
            active: {
                backgroundColor: branding.topNavAccentColor,
            },
        },
        SubNavItem: {
            styles: {
                color: branding.secondaryTextColor,
            },
            active: {
                borderBottomColor: branding.topNavAccentColor,
            },
        },
        TableBody: {
            styles: {
                fontSize: '14px',
            },
        },
        TableCell: {
            styles: {
                head: {
                    backgroundColor: DEFAULT_COLORS.COMPONENTS_TABLE_TITLE_ROW,
                    fontFamily: 'Roboto',
                    color: DEFAULT_COLORS.BLACK,
                },
            },
        },
        TitleField: {
            styles: {
                root: {
                    color: 'red',
                    fontWeight: 500,
                },
            },
        },
        UserMenu: {
            styles: {
                fontFamily: 'Roboto',
                fontSize: '1rem',
            },
        },
        grid: {
            gutterWidth: 25.216,
        },
        palette: {
            primary: {
                text: branding.primaryTextColor,
                gradientColor: branding.primaryColor,
                color: darken(0.1, branding.primaryColor),
                borderColor: branding.primaryColor,
                backgroundLightColor: rgba(branding.primaryColor, 0.3),
            },
            primaryTransparent: {
                text: branding.primaryTextColor,
                gradientColor: 'transparent',
                color: darken(0.1, branding.primaryColor),
                borderColor: 'transparent',
            },
            secondary: {
                text: branding.secondaryTextColor,
                gradientColor: branding.secondaryColor,
                color: darken(0.1, branding.secondaryColor),
                borderColor: branding.secondaryColor,
            },
            light: {
                text: DEFAULT_COLORS.DARKEST,
                gradientColor: DEFAULT_COLORS.LIGHT,
                color: darken(0.1, DEFAULT_COLORS.LIGHT),
                borderColor: 'transparent',
            },
            lighter: {
                text: DEFAULT_COLORS.DARKEST,
                gradientColor: DEFAULT_COLORS.LIGHTER,
                color: darken(0.1, DEFAULT_COLORS.LIGHTER),
                borderColor: 'transparent',
            },
            activeButton: {
                text: DEFAULT_COLORS.DARKEST,
                gradientColor: DEFAULT_COLORS.BLUE,
                color: darken(0.1, DEFAULT_COLORS.LIGHTER),
                borderColor: 'transparent',
            },
            lightOutline: {
                text: DEFAULT_COLORS.BLACK,
                gradientColor: DEFAULT_COLORS.WHITE,
                color: darken(0.1, DEFAULT_COLORS.WHITE),
                borderColor: DEFAULT_COLORS.PLACEHOLDER,
            },
            yellow: {
                text: DEFAULT_COLORS.BLACK,
                gradientColor: DEFAULT_COLORS.YELLOW,
                color: darken(0.1, DEFAULT_COLORS.YELLOW),
                borderColor: DEFAULT_COLORS.DARK,
            },
            tableRowStyled: {
                color: DEFAULT_COLORS.LIGHT,
            },
            tableRowStyledHover: {
                color: branding.primaryColor,
            },
            actions: {
                color: DEFAULT_COLORS.PLACEHOLDER,
            },
            links: {
                color: branding.linkTextColor,
            },
            subNavActions: {
                text: branding.linkTextColor,
                color: DEFAULT_COLORS.LIGHTEST,
                gradientColor: DEFAULT_COLORS.WHITE,
            },
            loading: {
                color: DEFAULT_COLORS.LIGHTEST,
            },
            muted: {
                color: DEFAULT_COLORS.LIGHTER,
            },
            sidebarHelpText: {
                color: DEFAULT_COLORS.PLACEHOLDER,
            },
            sidebarOption: {
                color: DEFAULT_COLORS.LINK,
            },
            handbooks: {
                states: {
                    multistate: DEFAULT_COLORS.HANDBOOK_MULTISTATE,
                    federal: DEFAULT_COLORS.HANDBOOK_FEDERAL,
                    onestate: DEFAULT_COLORS.HANDBOOK_ONESTATE,
                },
            },
            backButton: {
                borderColor: branding.secondaryColor,
                color: branding.secondaryColor,
                focusColor: branding.secondaryColor,
                gradientColor: branding.secondaryColor,
                hoverColor: branding.secondaryColor,
                text: branding.secondaryTextColor,
            },
            deleteButton: {
                borderColor: DEFAULT_COLORS.BANNER_RED,
                color: DEFAULT_COLORS.BANNER_RED,
                gradientColor: DEFAULT_COLORS.BANNER_RED,
                text: DEFAULT_COLORS.BLACK,
            },
            actionButton: {
                borderColor: DEFAULT_COLORS.LIGHTBLUE,
                color: DEFAULT_COLORS.LIGHTBLUE,
                gradientColor: DEFAULT_COLORS.LIGHTBLUE,
                text: DEFAULT_COLORS.BLACK,
            },
            cancelButton: {
                borderColor: DEFAULT_COLORS.LIGHTLY,
                color: DEFAULT_COLORS.LIGHTEST,
                gradientColor: DEFAULT_COLORS.LIGHTEST,
                text: DEFAULT_COLORS.BLACK,
            },
            saveButton: {
                borderColor: DEFAULT_COLORS.SEMANTIC_NEW_HIGHLIGHT,
                color: DEFAULT_COLORS.SEMANTIC_NEW_HIGHLIGHT,
                gradientColor: DEFAULT_COLORS.SEMANTIC_NEW_HIGHLIGHT,
                text: DEFAULT_COLORS.BLACK,
            },
            lightSmallAction: {
                text: DEFAULT_COLORS.BLACK,
                gradientColor: 'transparent',
                color: 'transparent',
                borderColor: DEFAULT_COLORS.PLACEHOLDER,
            },
            tableRowDisabled: {
                color: DEFAULT_COLORS.GREY,
            },
            emailPrimaryButton: {
                text: DEFAULT_COLORS.WHITE,
                gradientColor: DEFAULT_COLORS.ORANGE,
                color: darken(0.1, DEFAULT_COLORS.ORANGE),
                borderColor: DEFAULT_COLORS.DARK,
                focusColor: DEFAULT_COLORS.WHITE,
            },
            emailSecondaryButton: {
                text: DEFAULT_COLORS.ORANGE,
                gradientColor: DEFAULT_COLORS.WHITE,
                color: darken(0.1, DEFAULT_COLORS.WHITE),
                borderColor: DEFAULT_COLORS.ORANGE,
                focusColor: DEFAULT_COLORS.WHITE,
            },
            showMoreButton: {
                text: DEFAULT_COLORS.WHITE,
                gradientColor: DEFAULT_COLORS.GREY_8,
                color: DEFAULT_COLORS.GREY_8,
                borderColor: DEFAULT_COLORS.GREY_8,
            },
            activeCalendar: {
                text: branding.primaryTextColor,
                gradientColor: branding.primaryColor,
                color: darken(0.1, branding.primaryColor),
                borderColor: 'transparent',
                focusColor: 'transparent',
            },
            inactiveCalendar: {
                text: DEFAULT_COLORS.BLACK,
                gradientColor: 'transparent',
                color: darken(0.1, branding.secondaryColor),
                borderColor: 'transparent',
                focusColor: 'transparent',
                hoverColor: branding.secondaryTextColor,
            },
        },
        typography: {
            mapping: {
                documentFilter: 'span',
                documentListSummary: 'span',
                documentListTitle: 'span',
                documentTotalResults: 'span',
                documentTopicTitle: 'h1',
                pageSectionTitle: 'h1',
                documentTypeTitle: 'h3',
                searchSuggestionsTitle: 'h3',
                bodyNew: 'p',
                bodyNewInline: 'span',
                paragraph: 'p',
                link: 'a',
                pBody: 'p',
                pSmallBody: 'p',
                favTertiarySubtitle: 'h4',
                robSubtitle: 'h3',
                robSuperSmallHeader: 'h6',
            },
            root: {
                fontFamily: `Favorit, Arial, Helvetica, sans-serif`,
                fontSize: '1rem',
                fontWeight: 400,
                margin: '5px 0',
            },
            headline: {
                fontFamily: 'Roboto',
                fontSize: '1.375rem',
                fontWeight: 500,
                lineHeight: '1.3',
                letterSpacing: '.02em',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1rem',
                },
            },
            dashboardWidgetHeader: {
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontWeight: 500,
                letterSpacing: '0.93px',
                color: DEFAULT_COLORS.BLACK,
            },
            display1: {
                fontSize: '1.125rem',
                fontWeight: 500,
                lineHeight: '1.2',
            },
            sidebarHelpText: {
                fontSize: '13px',
                padding: '10px',
            },
            sidebarOption: {
                fontSize: '12px',
                textTransform: 'uppercase',
                margin: 0,
            },
            handbookCardName: {
                fontSize: '18px',
            },
            handbookCardCompanyName: {
                fontSize: '14px',
                fontWeight: 300,
            },
            handbookCardStateInfoTitle: {
                fontFamily: 'Roboto',
                fontSize: '14px',
                lineHeight: 1,
            },
            handbookCardStateInfoText: {
                fontSize: '12px',
                lineHeight: 1,
            },
            // **********************************************************
            // ** Following is the Typography styles as per the new UX **
            // **********************************************************
            h1: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '2.25rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.875rem',
                },
            },
            h1Article: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '2rem',
                fontWeight: '400',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: '2.563rem',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.5rem',
                },
            },
            quote: {
                color: DEFAULT_COLORS.PLACEHOLDER,
                fontSize: '2.375rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            h2: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '1.625rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.375rem',
                },
            },
            h2Article: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '1.25rem',
                fontWeight: '500',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: '1.688rem',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1rem',
                },
            },
            h3: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '1.375rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.125rem',
                },
            },
            h4: {
                display: 'block',
                fontSize: '1.125rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                marginBottom: SPACING.M,
                marginTop: '0',
                color: DEFAULT_COLORS.BLACK,
            },
            h6: {
                display: 'block',
                fontSize: '0.75rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: '0.938rem',
            },
            subheader: {
                color: DEFAULT_COLORS.BLACK_3,
                display: 'block',
                fontFamily: 'Roboto',
                fontSize: '1.25rem',
                fontWeight: '300',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            navMain: {
                color: DEFAULT_COLORS.BLACK,
                fontSize: '1.125rem',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            navDropdown: {
                color: DEFAULT_COLORS.BLACK,
                letterSpacing: 'normal',
                lineHeight: 'normal',
                fontFamily: 'Roboto',
                fontSize: '0.9375rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
            },
            navSubnav: {
                color: DEFAULT_COLORS.DARKEST,
                fontSize: '1rem',
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            searchBarActive: {
                color: DEFAULT_COLORS.DARKEST,
                fontSize: '1rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            bodyNew: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 1.25,
            },
            bodyNewInline: {
                display: 'inline',
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 1.25,
            },
            showAll: {
                color: DEFAULT_COLORS.TAN,
                cursor: 'pointer',
                fontSize: '0.938rem',
                fontWeight: 600,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            showAllDocuments: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                cursor: 'pointer',
                fontFamily: 'RobotoMono',
                fontSize: '0.875rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: '500',
                height: '1rem',
                letterSpacing: 'normal',
                lineHeight: '1.14',
                textDecoration: 'underline',
            },
            navLink: {
                color: DEFAULT_COLORS.WHITE,
                fontSize: '0.938rem',
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            navLinkHover: {
                color: DEFAULT_COLORS.WHITE,
                fontFamily: 'Favorit, Arial, Helvetica, sans-serif',
                fontSize: '0.938rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            selection: {
                color: DEFAULT_COLORS.DARKEST,
                fontSize: '0.875rem',
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            imageDescription: {
                color: DEFAULT_COLORS.DARKEST,
                fontSize: '0.875rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                textAlign: 'center',
            },
            tooltip: {
                color: DEFAULT_COLORS.DARKEST,
                fontSize: '0.688rem',
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            breadCrumbItem: {
                color: DEFAULT_COLORS.BLACK_2,
                cursor: 'default !important',
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontWeight: 'normal',
                margin: 0,
            },
            breadCrumbLink: {
                color: DEFAULT_COLORS.LINK,
                cursor: 'pointer',
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontWeight: 'normal',
                margin: 0,
            },
            breadCrumbLinkActive: {
                color: DEFAULT_COLORS.BLACK,
                cursor: 'pointer',
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontWeight: 'normal',
                margin: 0,
            },
            documentListTitle: {
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                cursor: 'pointer',
                fontSize: '1rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'medium',
                letterSpacing: 'normal',
                lineHeight: '1.25rem',
            },
            documentListSummary: {
                color: DEFAULT_COLORS.BLACK,
                fontSize: '1rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 300,
                letterSpacing: 'normal',
                lineHeight: '1.25rem',
            },
            documentFilter: {
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: 'normal',
                lineHeight: 'normal',
                margin: 0,
            },
            documentCheckboxFilter: {
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '0.813rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 300,
                letterSpacing: 'normal',
                lineHeight: 1.46,
                margin: '0 0 0 0.813rem',
            },
            documentResultsBottom: {
                color: DEFAULT_COLORS.BLACK,
                display: 'block',
                fontFamily: 'RobotoMono',
                fontSize: '0.75rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 300,
                letterSpacing: 'normal',
                lineHeight: 'normal',
                margin: `0 0 ${SPACING.M} 0`,
            },
            documentResultsMobile: {
                color: DEFAULT_COLORS.BLACK,
                display: 'block',
                fontFamily: 'RobotoMono',
                fontSize: '0.875rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 300,
                letterSpacing: 'normal',
                lineHeight: 'normal',
                margin: 0,
            },
            documentResultsTop: {
                color: DEFAULT_COLORS.PLACEHOLDER,
                display: 'block',
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                margin: 0,
            },
            gotoPage: {
                color: DEFAULT_COLORS.BLACK,
                fontSize: '0.813rem',
                fontFamily: 'RobotoMono',
            },
            paginationList: {
                color: DEFAULT_COLORS.DARK,
                fontFamily: 'RobotoMono',
                fontSize: '1rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            paginationListActive: {
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'RobotoMono',
                fontSize: '1rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            paginationResults: {
                fontFamily: 'RobotoMono',
                textTransform: 'uppercase',
            },
            paginationMobileActions: {
                fontFamily: 'RobotoMono',
                fontWeight: 'bold',
                color: DEFAULT_COLORS.BLACK_2,
            },
            paginationMobileLabels: {
                fontFamily: 'RobotoMono',
                textTransform: 'uppercase',
                fontSize: '0.875rem',
                color: DEFAULT_COLORS.BLACK,
                fontWeight: 500,
            },
            backButton: {
                color: branding.secondaryTextColor,
                fontSize: '1rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                margin: `0 0 0 ${SPACING.M}`,
            },
            noNewNotifications: {
                fontSize: '0.75rem',
                fontWeight: '300',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.5',
                letterSpacing: 'normal',
                color: DEFAULT_COLORS.BLACK,
            },
            viewAllNotifications: {
                fontSize: '0.875rem',
                fontWeight: '500',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.29',
                letterSpacing: 'normal',
                color: DEFAULT_COLORS.TAN,
            },
            notificationMenuButtons: {
                fontSize: '0.813rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.38',
                letterSpacing: 'normal',
                color: DEFAULT_COLORS.TAN,
            },
            notificationItemMessage: {
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontWeight: '300',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1',
                letterSpacing: 'normal',
            },
            modalTitle: {
                display: 'block',
                fontFamily: 'Roboto',
                fontSize: '1.375rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            modalDescription: {
                color: DEFAULT_COLORS.BLACK,
                fontSize: '1rem',
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 1.25,
            },
            documentTopicTitle: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '3rem',
                lineHeight: 'normal',
                margin: `0`,
                padding: `0 ${SPACING.XL}`,
            },
            documentTypeTitle: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '1.375rem',
                lineHeight: 'normal',
                margin: 0,
                padding: 0,
            },
            searchSuggestionsTitle: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontFamily: `Favorit`,
                fontSize: '1.375rem',
                lineHeight: 'normal',
                margin: `0 0 1.0625rem`,
                padding: 0,
            },
            link: {
                color: DEFAULT_COLORS.LINK,
                cursor: 'pointer',
                fontFamily: `Favorit, Arial, Helvetica, sans-serif`,
                fontSize: '0.875rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.29',
                letterSpacing: 'normal',
            },
            link2: {
                fontWeight: '500',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.29',
                letterSpacing: 'normal',
                fontSize: '1rem',
                cursor: 'pointer',
                color: DEFAULT_COLORS.TAN,
            },
            linkDisable: {
                color: DEFAULT_COLORS.GREY_14,
                cursor: 'not-allowed',
                fontFamily: `Favorit, Arial, Helvetica, sans-serif`,
                fontSize: '0.875rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.29',
                letterSpacing: 'normal',
            },
            bottomLinkBar: {
                fontfamily: 'Favorit, Arial, Helvetica, sans-serif',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                fontSize: '0.875rem',
                cursor: 'pointer',
                color: DEFAULT_COLORS.LINK,
                textDecoration: 'underline',
            },
            pageSectionTitle: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '2.25rem',
                fontFamily: 'Favorit, Arial, Helvetica, sans-serif',
                lineHeight: 'normal',
                margin: `1.125rem 0 ${SPACING.XS} 0`,
                padding: '0',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.75rem',
                },
            },
            pageSectionSubtitle: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '1.75rem',
                lineHeight: 'normal',
                margin: `0`,
                padding: `0`,
            },
            pageSectionSidebarTitle: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '3rem',
                lineHeight: 'normal',
                margin: `1.125rem 0 1.5625rem 1.5rem`,
                padding: `0`,
            },
            textStyle2: {
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'inherit',
                fontSize: '1rem',
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 1.25,
            },
            textStyle3: {
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Favorit, Arial, Helvetica, sans-serif',
                fontSize: '2.25rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                display: 'flex',
                alignItems: 'center',
            },
            helpDocumentText: {
                fontSize: '1rem',
                color: DEFAULT_COLORS.DARKEST,
            },
            mostPopularTitle: {
                display: 'block;',
                fontFamily: 'RobotoMono',
                fontSize: '0.75rem',
                textTransform: 'Uppercase',
                color: DEFAULT_COLORS.BLACK,
                margin: `0.8125rem 0 0.875rem 0`,
            },
            QuestionHRFitness: {
                display: 'block',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                color: DEFAULT_COLORS.BLACK,
                marginBottom: '1rem',
                marginTop: '0',
            },
            widgetHeaderTitle: {
                fontSize: '1rem',
                fontWeight: '500',
                color: DEFAULT_COLORS.DARKEST,
                margin: '0',
            },
            toDoListType: {
                fontFamily: 'RobotoMono',
                fontSize: '0.625rem',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: '1',
            },
            QuestionnaireQuestion: {
                display: 'block',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                color: DEFAULT_COLORS.BLACK,
                marginBottom: '1rem',
                marginTop: '0',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.125rem',
                },
            },
            SectionModalTitle: {
                display: 'block',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                fontStyle: 'normal',
                lineHeight: 'normal',
                color: DEFAULT_COLORS.DARKEST,
                marginTop: '1.5625rem',
                marginBottom: '0.9375rem',
            },
            tabTitle: {
                fontSize: '0.938rem',
                fontWeight: 'normal',
                color: DEFAULT_COLORS.BLACK,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.375rem',
                },
            },
            viewDocumentLink: {
                fontFamily: 'inherit',
                fontSize: '1rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                textDecoration: 'underline',
                letterSpacing: 'normal',
                cursor: 'pointer',
                color: DEFAULT_COLORS.BLACK,
            },
            tableColActionLink: {
                fontFamily: 'RobotoMono',
                fontSize: '0.875rem',
                fontWeight: 'normal',
                color: DEFAULT_COLORS.BLACK,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: '1.14',
                cursor: 'pointer',
            },
            wizardTitles: {
                fontFamily: 'RobotoMono',
                fontSize: '0.875rem',
                color: DEFAULT_COLORS.BLACK,
                fontWeight: '300',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
            },
            radioListItems: {
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            fitnessResultsBoxTitles: {
                display: 'block',
                fontFamily: 'RobotoMono',
                fontSize: '0.75rem',
                color: DEFAULT_COLORS.BLACK,
                fontWeight: '300',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.56px',
                textTransform: 'uppercase',
            },
            defaultRoboto: {
                fontFamily: 'Roboto',
                fontSize: '1rem',
                color: 'inherit',
                fontWeight: '300',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
            },
            defaultRobotoMono: {
                display: 'block',
                fontFamily: 'RobotoMono',
                fontSize: '0.75rem',
                fontWeight: '300',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.56px',
            },
            QuickProductlink: {
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                color: DEFAULT_COLORS.LINK,
                cursor: 'pointer',
                margin: '0',
            },
            ButtonActionSmall: {
                fontFamily: `Favorit, Arial, Helvetica, sans-serif`,
                fontSize: '0.75rem',
                color: DEFAULT_COLORS.BLACK,
                textTransform: 'uppercase',
                letterSpacing: '0.6px',
            },
            paragraph: {
                display: 'block',
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 1.5,
                marginTop: 0,
                marginBottom: '1rem',
            },
            tableActionButtons: {
                fontFamily: `Favorit, Arial, Helvetica, sans-serif`,
                fontSize: '0.813rem',
                textTransform: 'uppercase',
            },
            labelInputs: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
            todoCompletedText: {
                fontFamily: `Helvetica`,
                fontSize: '1rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.5',
                letterSpacing: 'normal',
            },
            brandingTitle: {
                display: 'flex',
                color: DEFAULT_COLORS.BLACK,
                margin: '0 0 1rem',
                fontFamily: `Favorit, Arial, Helvetica, sans-serif`,
                fontSize: '1.625rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.375rem',
                },
            },
            DashboardTitle: {
                display: 'block',
                color: DEFAULT_COLORS.BLACK,
                fontSize: '2.5rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                [`@media only screen and (max-width: ${THEME.breakpoints.values.md}px)`]: {
                    fontSize: '1.875rem',
                },
            },
            todoTabTitle: {
                color: DEFAULT_COLORS.BLACK,
                letterSpacing: 'normal',
                lineHeight: 'normal',
                fontFamily: 'Roboto',
                fontSize: '0.9375rem',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
            },
            intelligenceSummary: {
                fontSize: '1rem',
                fontWeight: '300',
                color: DEFAULT_COLORS.BLACK,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                fontFamily: 'Roboto',
            },
            intelligenceActionTitle: {
                display: 'inline',
                color: DEFAULT_COLORS.BLACK,
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: 400,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 1.25,
            },
            intelligenceActionLink: {
                fontfamily: 'Favorit, Arial, Helvetica, sans-serif',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                fontSize: '1.25rem',
                color: DEFAULT_COLORS.GREY_4,
            },
            labelErrorValidation: {
                fontSize: '0.75rem',
                fontFamily: 'Roboto',
                fontWeight: '500',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                color: DEFAULT_COLORS.RED,
            },
            subNavCaption: {
                fontSize: '0.875rem',
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                color: branding.secondaryTextColor,
            },
            insightsTitle: {
                fontStretch: 'normal',
                fontStyle: 'normal',
            },
            insightsAction: {
                fontFamily: 'Roboto',
                lineHeight: '1.33',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
            },
            insightsTodoLink: {
                fontFamily: 'Roboto',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: '1.5rem',
            },
            insightsDashboardActionLink: {
                fontFamily: 'Roboto',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '1rem',
                lineHeight: '1.19',
                color: DEFAULT_COLORS.ORANGE,
                letterSpacing: 'normal',
                cursor: 'pointer',
                display: 'block',
            },
            insightsDashboardTitles: {
                fontFamily: 'Favorit',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '1.5rem',
                color: DEFAULT_COLORS.BLACK,
                letterSpacing: 'normal',
            },
            insightsDashboardSubTitles: {
                fontFamily: 'Favorit',
                fontStretch: 'normal',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '1rem',
                color: DEFAULT_COLORS.BLACK,
                letterSpacing: 'normal',
            },
            intelligenceEditCompanyButton: {
                fontFamily: 'Favorit, Arial, Helvetica, sans-serif',
                fontSize: '16px',
                color: DEFAULT_COLORS.BLACK_00,
                letterSpacing: '0.6px',
                lineHeight: '21.44px',
            },
            intelligenceDashboardLink: {
                fontFamily: 'Roboto',
                fontSize: '16px',
                color: DEFAULT_COLORS.LINK,
                lineHeight: '23px',
                fontWeight: '500',
                textDecoration: 'underline',
            },
            linkBase: {
                fontFamily: `Roboto, Helvetica, Arial sans-serif`,
                color: DEFAULT_COLORS.BLACK,
                cursor: 'pointer',
                fontSize: '1rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
            },
            categoriesSearch: {
                fontFamily: `Roboto, Helvetica, Arial sans-serif`,
                fontSize: '0.75rem',
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                marginTop: 0,
                marginBottom: 0,
                textTransform: 'uppercase',
            },
            categoryTags: {
                display: 'block',
                fontFamily: 'Roboto',
                fontSize: '0.75rem',
                fontWeight: '400',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.1px',
            },
            favTertiarySubtitle: {
                display: 'block',
                fontFamily: 'Favorit, Arial, Helvetica, sans-serif',
                fontSize: '0.875rem',
                lineHeight: '19px',
                fontWeight: '400',
            },
            robSubtitle: {
                display: 'block',
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                fontSize: '1.25rem',
                lineHeight: '27px',
                fontWeight: '400',
            },
            robSuperSmallHeader: {
                display: 'block',
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                fontSize: '0.75rem',
                lineHeight: '23px',
                fontWeight: '400',
            },
            pBody: {
                display: 'block',
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                fontSize: '1rem',
                lineHeight: '23px',
                fontWeight: '400',
                marginTop: 0,
                marginBottom: '1rem',
            },
            pSmallBody: {
                display: 'block',
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                fontSize: '0.875rem',
                lineHeight: '19px',
                fontWeight: '400',
                marginTop: 0,
                marginBottom: '1rem',
            },
            ipmTitle: {
                display: 'block',
                fontSize: '1.125rem',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                marginTop: '0',
            },
            ipmSmallBody: {
                display: 'block',
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                fontSize: '0.875rem',
                lineHeight: '19px',
                fontWeight: '400',
                marginTop: 0,
            },
        },
    };
};

export const isValidLogo = file => {
    let isValid = true;
    let error = '';

    if (file && file.type && file.type.indexOf('image') !== -1) {
        if (file.size > BRANDING.LOGOS.MAX_FILE_SIZE) {
            isValid = false;
            error = BRANDING.ERRORS.INVALID_FILE_SIZE;
        }
    } else {
        isValid = false;
        error = BRANDING.ERRORS.INVALID_FILE_TYPE;
    }

    return { isValid, error };
};

export const buildRestoreDefaultDescription = (sectionText = '') => {
    return BRANDING.DESCRIPTIONS.RESTORE_DEFAULT.replace('SECTION_TEXT', sectionText);
};
