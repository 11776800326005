import { get } from 'ravenjs/utils/lodash';

/**
 * Get environment value from window object
 *
 * @param   {string}    envVarName
 * @param   {string}    fallbackValue
 * @return  {string}
 */
export const getEnvVar = (envVarName, fallbackValue = '') =>
    get((window && window._env_) || {}, envVarName, fallbackValue);
