import { bool } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Row from 'ravenjs/lib/Row';
import Modal from 'ravenjs/lib/Modal';

import LoadingIcons from 'components/LoadingIcons';

const LoadingContentStyled = styled.div`
    margin-top: 15.5rem;
`;

const Loading = ({ loading }) => {
    const height = 32.6;
    const width = 32.6;

    return (
        <Modal
            BackdropProps={{ alignItems: 'flex-start', color: 'loading', opacity: 0.8 }}
            ContentComponent={LoadingContentStyled}
            open={loading}
        >
            <Row alignItems="center" gutter={false} justify="center" width="100%" wrap="nowrap">
                <LoadingIcons height={height} width={width} />
            </Row>
        </Modal>
    );
};

Loading.propTypes = {
    loading: bool,
};

Loading.defaultProps = {
    loading: false,
};

export default Loading;
