import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeToolkitsIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path
                            fill={color}
                            d="M12.159 4.963V0H1.876v4.963H0V14h14V4.963h-1.841zm-2.044 0H3.92V1.645h6.195v3.318z"
                        />
                        <path
                            fill={colorInner}
                            d="M0 8.288H5.117V9.288H0zM8.883 8.288H14V9.288H8.883z"
                        />
                        <path
                            fill={colorInner}
                            d="M9.1 10.136H4.9V7.14h4.2v2.996zm-3.5-.7h2.8V7.84H5.6v1.596z"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeToolkitsIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeToolkitsIcon.defaultProps = {
    color: `${DEFAULT_COLORS.PEACH}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeToolkitsIcon;
