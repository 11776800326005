import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'ravenjs/constants/colors';
import SvgIcon from '../SvgIcon';

const ZoomInIcon = ({ setRef, fill, ...rest }) => (
    <SvgIcon cursor="pointer" width="21" height="21" {...rest} ref={setRef}>
        <path
            fill={fill}
            d="M9.655.539c4.857 0 8.808 3.951 8.808 8.807 0 2.136-.765 4.097-2.034 5.624l4.18 4.181c.318.317.318.832 0 1.15-.158.158-.367.238-.574.238-.209 0-.416-.08-.575-.238l-4.182-4.18c-1.526 1.269-3.487 2.033-5.623 2.033-4.856 0-8.807-3.95-8.807-8.808C.848 4.49 4.798.54 9.655.54zm0 1.626c-3.96 0-7.181 3.222-7.181 7.181 0 3.96 3.221 7.182 7.181 7.182s7.182-3.222 7.182-7.182c0-3.96-3.222-7.181-7.182-7.181zm.297 3.331v3.412h3.17v.68h-3.17v3.508h-.727V9.587H6.039v-.679h3.186V5.496h.727z"
        />
    </SvgIcon>
);

ZoomInIcon.propTypes = {
    fill: PropTypes.string,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
};

ZoomInIcon.defaultProps = {
    fill: COLORS.GREY,
    setRef: null,
    viewBox: '0 0 21 21',
};

export default ZoomInIcon;
