import _get from 'lodash/get';
import { call, put } from 'redux-saga/effects';

import { buildBrandingSuccessActionPayload, buildThemeWithConfig } from 'utils/branding';
import {
    createPartnerBrandingAPI,
    deletePartnerBrandingAPI,
    fetchCompanyBrandingAPI,
    fetchCustomLoginWidgetApi,
    fetchDefaultCustomLoginWidgetApi,
    resetPartnerBrandingAPI,
    resetCustomLoginWidgetApi,
    updateCustomLoginWidgetApi,
    updatePartnerBrandingAPI,
    uploadPartnerBrandingFileAPI,
    uploadPartnerUpgradeAdLogoFileAPI,
} from 'modules/apis';
import { DEFAULT_BRANDING } from 'constants/app';
import * as brandingActions from '../actions';

/**
 * Fetch the partner branding by the `partnerId`.
 *
 * @method fetchBrandingSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchBrandingSaga(action) {
    try {
        const { payload: searchKey } = action;

        const resp = yield call(fetchCompanyBrandingAPI, searchKey);
        const branding = buildBrandingSuccessActionPayload(resp);

        yield put(brandingActions.fetchBranding.success(branding));

        return branding;
    } catch (error) {
        yield put(brandingActions.fetchBranding.error(error));
        return error;
    }
}

/**
 * Create branding of a partner
 *
 * @method createPartnerBrandingSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object|Error}
 */
export function* createPartnerBrandingSaga(action) {
    try {
        const {
            payload: { partnerId, branding },
        } = action;

        const response = yield call(createPartnerBrandingAPI, partnerId, branding);
        const partnerBranding = _get(response, 'data.partner-branding', {});

        yield put(brandingActions.createPartnerBranding.success(partnerBranding));
        return branding;
    } catch (error) {
        yield put(brandingActions.createPartnerBranding.error(error));
        return error;
    }
}

/**
 * Reset branding of a partner
 *
 * @method resetPartnerBrandingSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object|Error}
 */
export function* resetPartnerBrandingSaga(action) {
    try {
        const {
            payload: { partnerId, category },
        } = action;
        const resp = yield call(resetPartnerBrandingAPI, partnerId, category);
        yield put(brandingActions.resetPartnerBranding.success(resp));
        return resp;
    } catch (error) {
        yield put(brandingActions.resetPartnerBranding.error(error));
        return error;
    }
}

/**
 * Update branding of a partner
 *
 * @method updatePartnerBrandingSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object|Error}
 */
export function* updatePartnerBrandingSaga(action) {
    try {
        const {
            payload: { partnerId, branding },
        } = action;

        const response = yield call(updatePartnerBrandingAPI, partnerId, branding);
        const partnerBranding = buildBrandingSuccessActionPayload(response);

        yield put(brandingActions.updatePartnerBranding.success(partnerBranding));
        return branding;
    } catch (error) {
        yield put(brandingActions.updatePartnerBranding.error(error));
        return error;
    }
}

/**
 * Update branding of a partner
 *
 * @method uploadPartnerBrandingFileSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object|Error}
 */
export function* uploadPartnerBrandingFileSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(uploadPartnerBrandingFileAPI, payload);
        const data = _get(resp, 'data', {});
        yield put(brandingActions.uploadPartnerBrandingFile.success(data));
        return data;
    } catch (error) {
        yield put(brandingActions.uploadPartnerBrandingFile.error(error));
        return error;
    }
}

/**
 * Delete branding of a partner
 *
 * @method deletePartnerBrandingSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object|Error}
 */
export function* deletePartnerBrandingSaga(action) {
    try {
        const {
            payload: { partnerId },
        } = action;

        const response = yield call(deletePartnerBrandingAPI, partnerId);

        yield put(brandingActions.deletePartnerBranding.success(response));
        return response;
    } catch (error) {
        yield put(brandingActions.deletePartnerBranding.error(error));
        return error;
    }
}

export function* fetchBrandingByDomainSaga() {
    try {
        // TODO uncomment and call api
        // const resp = yield call(fetchPartnerBrandingByDomain, {});
        // const config = _get(resp, 'data.partner-branding.branding', {});

        const branding = {
            config: DEFAULT_BRANDING,
            theme: buildThemeWithConfig(DEFAULT_BRANDING),
        };
        // TODO uncomment
        // const theme = buildThemeWithConfig(config);
        // const branding = { config, theme };

        yield put(brandingActions.fetchBrandingByDomain.success(branding));
        return branding;
    } catch (error) {
        yield put(brandingActions.fetchBrandingByDomain.error(error));
        return error;
    }
}

/**
 * Fetch the Custom Login Widget settings for a partner.
 *
 * @method fetchCustomLoginWidgetSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object|Error}
 */
export function* fetchCustomLoginWidgetSaga(action) {
    try {
        const {
            payload: { partnerId },
        } = action;

        const { data } = yield call(fetchCustomLoginWidgetApi, partnerId);

        yield put(brandingActions.fetchCustomLoginWidget.success(data));
        return data;
    } catch (error) {
        yield put(brandingActions.fetchCustomLoginWidget.error(error));
        return error;
    }
}

/**
 * Fetch the default Custom Login Widget settings.
 *
 * @method fetchDefaultCustomLoginWidgetSaga
 * @type   {Generator}
 * @return {Object|Error}
 */
export function* fetchDefaultCustomLoginWidgetSaga() {
    try {
        const { data } = yield call(fetchDefaultCustomLoginWidgetApi);

        yield put(brandingActions.fetchDefaultCustomLoginWidget.success(data));
        return data;
    } catch (error) {
        yield put(brandingActions.fetchDefaultCustomLoginWidget.error(error));
        return error;
    }
}

/**
 * Update the Custom Login Widget settings by the `partnerId`.
 *
 * @method updateCustomLoginWidgetSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object|Error}
 */
export function* updateCustomLoginWidgetSaga(action) {
    try {
        const {
            payload: { partnerId, data },
        } = action;

        const response = yield call(updateCustomLoginWidgetApi, partnerId, data);
        const { data: responseData } = response;

        yield put(brandingActions.updateCustomLoginWidget.success(responseData));
        return responseData;
    } catch (error) {
        yield put(brandingActions.updateCustomLoginWidget.error(error));
        return error;
    }
}

/**
 * Reset the Custom Login Widget settings by the `partnerId`.
 *
 * @method resetCustomLoginWidgetSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object|Error}
 */
export function* resetCustomLoginWidgetSaga(action) {
    try {
        const {
            payload: { partnerId, data },
        } = action;

        const response = yield call(resetCustomLoginWidgetApi, partnerId, data);
        const { data: responseData } = response;

        yield put(brandingActions.resetCustomLoginWidget.success(responseData));
        return responseData;
    } catch (error) {
        yield put(brandingActions.resetCustomLoginWidget.error(error));
        return error;
    }
}

/**
 * Update the "temporary branding theme is currently in use" state
 *
 * @method setIsUsingTemporaryThemeSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {boolean|Error}
 */
export function* setIsUsingTemporaryThemeSaga(action) {
    try {
        const { payload } = action;

        yield put(brandingActions.setIsUsingTemporaryTheme.success(Boolean(payload)));
        return Boolean(payload);
    } catch (error) {
        yield put(brandingActions.setIsUsingTemporaryTheme.error(error));
        return error;
    }
}

/**
 * Update upgrade ad logo of a partner
 *
 * @method uploadPartnerUpgradeAdLogoFileSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object|Error}
 */
export function* uploadPartnerUpgradeAdLogoFileSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(uploadPartnerUpgradeAdLogoFileAPI, payload);
        const data = _get(resp, 'data', {});
        yield put(brandingActions.uploadPartnerUpgradeAdLogoFile.success(data));
        return data;
    } catch (error) {
        yield put(brandingActions.uploadPartnerUpgradeAdLogoFile.error(error));
        return error;
    }
}
