import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const PlaceholderIcon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <circle fill="#D8D8D8" cx="64" cy="64" r="63.5" />
            <path
                fill="#979797"
                d="M64,0C28.7,0,0,28.7,0,64s28.7,64,64,64s64-28.7,64-64S99.3,0,64,0z M127,63.5H64.5V1C98.8,1.3,126.7,29.2,127,63.5z M63.5,1v62.5H1C1.3,29.2,29.2,1.3,63.5,1z M1,64.5h62.5V127C29.2,126.7,1.3,98.8,1,64.5z M64.5,127V64.5H127C126.7,98.8,98.8,126.7,64.5,127z"
            />
        </SvgIcon>
    );
};

PlaceholderIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

PlaceholderIcon.defaultProps = {
    height: '128',
    viewBox: '0 0 128 128',
    width: '128',
};

export default PlaceholderIcon;
