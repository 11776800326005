const CUSTOM_LOGIN_WIDGET = {
    INITIAL_PROPERTIES: {
        id: 0,
        partnerId: 0,

        // Header
        showHeaderIcon: false,
        headerBackgroundColor: '',
        headerTextColor: '',
        showHeaderLogo: false,
        headerLogoColor: '',

        // Text
        showIntroText: false,
        introTextColor: '',
        showSupportText: false,
        supportTextColor: '',

        // Buttons
        loginButtonFullWidth: false,
        loginButtonBackgroundColor: '',
        loginButtonTextColor: '',

        // Miscellaneous
        maxWidth: '',
        showFooter: false,
        showFooterLogo: false,
        showResetPassword: false,
        openInNewTab: false,

        headerLogos: [
            {
                resourceName: '',
                resourceType: '',
                resourceValue: '',
            },
        ],
    },
    JS_SNIPPET: `<script type="text/javascript">var cacheBuster = (new Date).getTime(); document.write('<scr' + 'ipt type="text/javascript" src="SNIPPET_URL?cb=' + cacheBuster +'"></scr' + 'ipt>');</script>`,
};

export default CUSTOM_LOGIN_WIDGET;
