const CONTENT_DETAIL = {
    COPY_LINK: {
        event: 'contentDetailCopyLink',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Content detail page copy link',
            title: '',
        },
    },
    DOWNLOAD: {
        event: 'contentDetailDownload',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Content detail page download',
            title: '',
        },
    },
    FAVORITE: {
        event: 'contentDetailFavorite',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Content detail page favorite',
            title: '',
            value: '',
        },
    },
    LIKE_DISLIKE: {
        event: 'contentDetailLikeDislike',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Content detail page Like/Dislike',
            title: '',
            value: '',
        },
    },
    PRINT: {
        event: 'contentDetailPrint',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Content detail page print',
            title: '',
        },
    },
    SELECT_SUGGESTED_CONTENT: {
        event: 'contentDetailSelectSuggestedContent',
        eventProperties: {
            contentId: '',
            contentType: '',
            detailViewContentId: '',
            detailViewContentType: '',
            description: 'Content detail select suggested content',
            title: '',
            url: '',
        },
    },
    VALUES: {
        ADD: 'add',
        NEUTRAL: 'neutral',
        REMOVE: 'remove',
    },
    VIEW: {
        event: 'contentDetailView',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Content detail page view',
            feature: '',
            referrer: '',
            slug: '',
            title: '',
            url: '',
        },
    },
};

export default CONTENT_DETAIL;
