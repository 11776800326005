import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MessageIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        {...rest}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.5,21.9c-0.3,0-0.6-0.1-0.9-0.4c-0.4-0.4-0.5-0.9-0.3-1.4l1.4-3.1c-1-1.5-1.5-3.3-1.5-5.1
            c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.3,4.1,9.3,9.2c0,5.1-4.1,9.2-9.3,9.2c-1.4,0-2.8-0.3-4.1-1L5,21.8C4.9,21.9,4.7,21.9,4.5,21.9z
             M12.5,4.2c-4.3,0-7.8,3.5-7.8,7.8c0,1.6,0.5,3.2,1.4,4.5c0.2,0.3,0.2,0.7,0.1,1L5,20.2l3-1.4c0.3-0.1,0.6-0.1,0.9,0
            c1.1,0.6,2.4,0.9,3.6,0.9c4.3,0,7.8-3.5,7.8-7.7C20.3,7.7,16.8,4.2,12.5,4.2z M8.2,20.1C8.2,20.1,8.2,20.1,8.2,20.1
            C8.2,20.1,8.2,20.1,8.2,20.1z"
            fill={color}
        />
    </SvgIcon>
);

MessageIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

MessageIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default MessageIcon;
