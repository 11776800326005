import { DEFAULT_COLORS } from 'constants/colors';
import { isProductionEnvironment } from 'utils/common';
import { MNL_IDLE_SESSION_TIMEOUT } from './api';

export const EVENING_CUTOFF_HOURS = 17;
export const AFTERNOON_CUTOFF_HOURS = 12;

export const COMPANY_SOURCE = {
    THR: 'THR',
    MHR: 'MHR',
    MNL: 'MNL',
};

export const USER_IDLE_TIMEOUT = MNL_IDLE_SESSION_TIMEOUT || 3600000; // in milliseconds

export const SESSION_EXPIRY_ALERT_COUNTDOWN_IN_SECONDS = 60;

export const DEFAULT_BRANDING = {
    avatarBackgroundColor: DEFAULT_COLORS.TAN,
    companyName: 'Mineral, Inc.',
    linkTextColor: DEFAULT_COLORS.LINK,
    loginLogoUrl:
        'https://s3-us-west-2.amazonaws.com/cdn.trustmineral-staging.com/assets/MIN-PrimaryLogo-TM-Citrine-RGB-01.png',
    logoUrl:
        'https://s3-us-west-2.amazonaws.com/cdn.trustmineral-staging.com/assets/Mineral-Logo-Graphite.svg',
    topNavColor: DEFAULT_COLORS.TAN,
    navigationHighlightColor: DEFAULT_COLORS.TAN,
    topNavTextColor: DEFAULT_COLORS.BLACK,
    topNavAccentColor: DEFAULT_COLORS.BRAND_PEACH_2,
    primaryColor: DEFAULT_COLORS.BLUE,
    primaryTextColor: DEFAULT_COLORS.BLACK,
    secondaryColor: DEFAULT_COLORS.LIGHTBLUE,
    secondaryTextColor: DEFAULT_COLORS.BLACK,
    partnerName: '',
    platformName: '',
    platformDomain: '',
    mainPartnerName: '',
    whiteLogoBackground: true,
};

export const GLOBALS = {
    results: {
        system: {
            support: {
                email: 'support@trustmineral.com',
                phone: '877-225-1101',
                text: 'Need help? We\u2019re here for you.',
                linkUrl: null,
                linkLabel: null,
                hotlinePhone: null,
                thrpremium: '',
            },
        },
    },
};

export const EXTERNAL_LINKS = {
    EULA: {
        PRIVACY_POLICY:
            typeof isProductionEnvironment === 'function' && isProductionEnvironment()
                ? 'https://trustmineral.com/privacy-policy'
                : 'https://test-mineral-marketing-website.pantheonsite.io/privacy-policy/',
        TERMS_OF_SERVICE:
            isProductionEnvironment === 'function' && isProductionEnvironment()
                ? 'https://trustmineral.com/terms-of-service'
                : 'https://test-mineral-marketing-website.pantheonsite.io/terms-of-service/',
    },
};

export const CORPORATE_WEBSITE_URL =
    isProductionEnvironment === 'function' && isProductionEnvironment()
        ? 'https://www.trustmineral.com'
        : 'https://test-mineral-marketing-website.pantheonsite.io';

export const MINERAL_MAINTENANCE_URL =
    'http://maintenance.mineral.com.s3-website-us-west-2.amazonaws.com/';

export const ROOT_ELEMENT_ID = 'root';

export const HOTJAR_API_KEY = {
    PROD: '3059546',
    QA: '3066449',
    DEV: '3068328',
};

export const COMPLIANCEDISCLAIMER =
    '<strong>Disclaimer</strong><br />This content is owned and provided by Mineral, Inc. “AS IS,” and is intended for informational purposes only. It does not constitute legal, accounting, or tax advice, nor does it create an attorney-client relationship. Because laws are constantly changing, we do not represent or warranty that the content is comprehensive of all applicable laws and regulations, and/or accurate as of the date of use. You are solely responsible for complying with all applicable laws and regulations. Mineral expressly disclaims any liability associated with your use of the content, and/or your noncompliance with applicable laws and regulations.';

export const EXCLUDE_PARTNER_ID_HOTJAR = [8148, 169284];
