export const ADS = {
    COMPANY_POLICIES_LANDING_BOTTOM: 'company-policies-landing-bottom',
    COMPANY_POLICIES_LANDING_TOP: 'company-policies-landing-top',
    COMPLIANCE_LANDING_BOTTOM: 'compliance-landing-bottom',
    COMPLIANCE_LANDING_TOP: 'compliance-landing-top',
    COMPLIANCE_LAW_ALERTS_SIDEBAR: 'compliance-law-alerts-sidebar',
    COMPLIANCE_LAW_ALERTS_TOP: 'compliance-law-alerts-top',
    COMPLIANCE_LAWS_SIDEBAR: 'compliance-laws-sidebar',
    COMPLIANCE_TOPICS_SIDEBAR: 'compliance-topics-sidebar',
    DASHBOARD_LANDING_BOTTOM: 'dashboard-landing-bottom',
    DASHBOARD_LANDING_TOP: 'dashboard-landing-top',
    HANDBOOKS_LIST_TOP: 'handbooks-list-top',
    RESOURCES_LANDING_BOTTOM: 'resources-landing-bottom',
    RESOURCES_LANDING_TOP: 'resources-landing-top',
    RESOURCES_TOPICS_SIDEBAR: 'resources-topics-sidebar',
    RESOURCES_SECTIONS_SIDEBAR: 'resources-sections-sidebar',
    SAFETY_LANDING_BOTTOM: 'safety-landing-bottom',
    SAFETY_LANDING_TOP: 'safety-landing-top',
    SAFETY_OSHA_300_TOP: 'safety-osha-300-top',
    SEARCH_SIDEBAR: 'search-sidebar',
    TEMPLATES_LANDING_BOTTOM: 'templates-landing-bottom',
    TEMPLATES_LANDING_TOP: 'templates-landing-top',
    TEMPLATES_TOPICS_SIDEBAR: 'templates-topics-sidebar',
    TEMPLATES_SECTIONS_SIDEBAR: 'templates-sections-sidebar',
    GATED_DOCUMENT: 'gated-document',
    COMPLIANCE_DASHBOARD_MIDDLE: 'compliance-dashboard-middle',
    HR_ASSESSMENT_AD: 'hr-assessment-ad',
    INSIGHT_DETAILS_MIDDLE: 'insight-details-middle',
    SAFETY_CONTENT_LIBRARY: 'safety-content-library',
    WEBINARS_BOTTOM: 'webinars-bottom',
    WEBINARS_TOP: 'webinars-top',
    HELPCENTER_RELEASENOTES_MIDDLE: 'helpcenter-releasenotes-middle',
    SEARCH_TOP: 'search-top',
    FEATURED_CONTENT_TOP: 'featured-content-top',
    FEATURED_CONTENT_SIDEBAR: 'featured-content-sidebar',
    ARIES_LANDING_TOP: 'aries-landing-top',
    ARIES_DASHBOARD_TOP: 'aries-dashboard-top',
    SEHPLUS_LANDING_BOTTOM: 'sehplus-landing-bottom',
};

export const SEGMENT_ID = '4RlBdtAhFIYKrFBjZU3nJ1';

// TODO: Update the preview images with actual images
export const BASIC_ADS_PLACEMENTS = [
    {
        placementName: 'dashboard-landing-top',
        pageName: 'Homepage Dashboard',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CLIE - BAS',
        image: 'https://cdn.trustmineral.com/public/assets/img/basic-home-dashboard.png',
        topPosition: '20px',
    },
    {
        placementName: 'compliance-dashboard-middle',
        pageName: 'Compliance Dashboard Homepage',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CLIE - BAS',
        image: 'https://cdn.trustmineral.com/public/assets/img/basic-compliance-dashboard.png',
        topPosition: '90px',
    },
    {
        placementName: 'featured-content-sidebar',
        pageName: 'Featured Content',
        adPlacement: 'sidebar',
        licenseInternalName: 'MIN - CLIE - BAS',
        image: 'https://cdn.trustmineral.com/public/assets/img/basic-featured-content.png',
        topPosition: '150px',
        paddingLeft: '13%',
    },
    {
        placementName: 'handbooks-list-top',
        pageName: 'Handbooks Landing Page',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CLIE - BAS',
        image: 'https://cdn.trustmineral.com/public/assets/img/basic-handbook-list.png',
        topPosition: '29px',
    },
    {
        placementName: 'search-top',
        pageName: 'Search Results',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CLIE - BAS',
        image: 'https://cdn.trustmineral.com/public/assets/img/basic-search-results.png',
        topPosition: '42px',
        paddingLeft: '32%',
    },
];

export const CORE_ADS_PLACEMENTS = [
    {
        placementName: 'compliance-dashboard-middle',
        pageName: 'Compliance Dashboard Homepage',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CORE - RES',
        image: 'https://cdn.trustmineral.com/public/assets/img/core-complaince-dashboard.png',
        topPosition: '90px',
    },
    {
        placementName: 'insight-details-middle',
        pageName: 'Insights Detail Page',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CORE - RES',
        image: 'https://cdn.trustmineral.com/public/assets/img/core-insights-details.png',
        topPosition: '245px',
    },
    {
        placementName: 'compliance-law-alerts-top',
        pageName: 'Law Alerts Homepage',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CORE - RES',
        image: 'https://cdn.trustmineral.com/public/assets/img/core-law-alerts.png',
        topPosition: '67px',
        paddingLeft: '18%',
        paddingRight: '16%',
    },
    {
        placementName: 'hr-assessment-ad',
        pageName: 'HR Assessment Landing page post test',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CORE - RES',
        image: 'https://cdn.trustmineral.com/public/assets/img/core-hr-assessment-post-test.png',
        topPosition: '260px',
    },
    {
        placementName: 'hr-assessment-ad',
        pageName: 'HR Assessment test results details page',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CORE - RES',
        image:
            'https://cdn.trustmineral.com/public/assets/img/core-hr-assessment-result-detail.png',
        topPosition: '200px',
    },
    {
        placementName: 'gated-document',
        pageName: 'HR Library Gated banners',
        adPlacement: 'top',
        licenseInternalName: 'MIN - CORE - RES',
        image: 'https://cdn.trustmineral.com/public/assets/img/core-gated-document.png',
        topPosition: '255px',
    },
];

export const UPGRADE_BANNER_ADS = {
    LANDING_TITLE: 'Banner Ads',
    LANDING_DESCRIPTION:
        'Use this page to create banner ads with in the Mineral Platform that engage your clients, drive awareness, and promote product.',
    CREATE_BUTTON: 'Create New Upgrade Banner Ads',
    CREATE: 'Create Ad',
    TITLE: 'Upgrade Banner Ads',
    SUB_TITLE: 'Enable Banner Ads',
    SUMMARY:
        'Using the fields below, enter the information to describe your upgrade offering and provide users with a call to action (CTA). When your banner ad has been published, this page displays an Active status. If you begin creating an ad but haven’t published it yet, the status displays as Saved. Be sure to select the Save Changes button at the bottom of the page to save your work.',
    FIELDS_REQUIRED: 'All fields are required',
    DEFAULT_AD_TYPE: 'defaultAds',
    CUSTOM_AD_TYPE: 'customAds',
    PREVIEW: 'Ad Preview',
    BASIC_LICENSE: ['MIN - CLIE - BAS', 'MIN - BASC - TRI'],
    CORE_LICENSE: ['MIN - CORE - RES'],
    LICENSE: 'License',
    DESCRIPTION: 'Description',
    CLIENT_CONFIGURATION: `MNL Client Configurations: `,
    SAVE_TOAST_MESSAGE: 'You have successfully saved your changes',
    PUBLISH_TOAST_MESSAGE: 'You have successfully published ads',
    UN_PUBLISH_TOAST_MESSAGE: 'You have successfully unpublished your ad',
    RESET_BUTTON: 'RESET CHANGES',
    SAVE_BUTTON: 'SAVE CHANGES',
    CANCEL_BUTTON: 'CANCEL',
    PUBLISH_BUTTON: 'PUBLISH',
    UNPUBLISH_BUTTON: 'UNPUBLISH',
    BANNER_STATUS: {
        PUBLISH: 'PUBLISH',
        SAVE: 'SAVE',
    },
    AD_TYPE: {
        DEFAULT: 'defaultAd',
        CUSTOM: 'customAd',
    },
    CONFIGURATIONS_VALIDATION_ERROR: 'At least one client configuration must be selected.',
    SAVE_MODAL_DESCRIPTION: 'If you continue, your changes will be lost.',
    UPLOAD_DESCRIPTION:
        'The recommended image size is at least 300px by 300px with a maximum image size of 1000px by 1000px. Acceptable file types include JPEG, PNG, and GIF. You will be able to preview the ad before publishing.',
    CONFIGURATIONS_DESCRIPTION:
        'Using the dropdown, select which of your Mineral Core or Mineral Basic client configurations are the audience for this ad. Ads will only be visible to the clients within your selected configuration(s). Please note, if the configuration you’ve selected is updated or deleted within the System Administration section, the ad will no longer be available.',
    BUTTONS_LABELS: {
        DELETE: 'DELETE',
        UPLOAD: 'UPLOAD',
        REUPLOAD: 'REUPLOAD',
    },
    RESET_MODAL: {
        TITLE: 'Reset Changes',
        CANCEL: 'Exit Without Resetting',
        CONFIRM: 'Yes, Reset Changes',
        CONTENT: `Select <span style="font-weight:500">Exit Without Resetting</span> to return to the banner creation page and continue editing.`,
        DESCRIPTION: `To return to the default ad settings, select <span style="font-weight:500">Yes, Reset Changes</span>. All fields will be cleared and your changes will not be saved.`,
    },
    IMAGE_VALIDATION_ERROR: 'Upload valid image file',
    CHARACTER_MAX_LENGTH_ERROR:
        'The field is too long. The max character count for this field is ${maxLength} characters.',
    BASIC_CONFIGURATIONS: 'Basic Configuration Names',
    CORE_CONFIGURATIONS: 'Core Configuration Names',
    SAVE_TEXT: 'Continue Without Saving',
    PAST_DATE_ERROR: 'Date cannot be in the past',
    INITIAL_DATE_ERROR: 'Date must be after the initial date',
    SUB_DESCRIPTION_ACTIVE: 'Ads Active',
    SUB_DESCRIPTION_NON_ACTIVE: 'Not Active',
    ACTIVE: 'Active',
    NOT_ACTIVE: 'Not Active',
    DEFAULT_AD_ACTIVE_TIME_DESCRIPTION: 'Default Ads Active as of ${adPublishDate}',
    AS_OF: 'as of ${publishTime} on ${publishDate}',
    AD_TYPES: {
        defaultAd: 'Default Ads',
        customAd: 'Custom Ads',
    },
    TIME: 'Time: ',
    DELETE_BUTTON: 'DELETE',
    MANAGE_BUTTON: 'MANAGE',
    TIME_FROM_TO: '${from} CST to ${to} CST',
    SCHEDULED_AD: 'Scheduled for Publish for',
    REQUIRED: 'Required',
    TOOLTIP_CONTENT: 'No client configurations available',
    PENDO_STATUS: {
        PUBLISH: 'PUBLISH',
        UNPUBLISH: 'UNPUBLISH',
        SAVE: 'SAVE',
        SCHEDULED: 'SCHEDULED',
    },
    MESSAGE_FROM: 'A message from ',
    UPGRADE_ADS_SKU_KEY: 'ad-campaign',
};
