import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ImportSuccessIcon = ({ height, viewBox, width, fill, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3305 2.5265C11.7189 1.80841 9.91835 1.63052 8.19738 2.01935C6.47642 2.40818 4.92725 3.3429 3.78091 4.68411C2.63458 6.02531 1.95251 7.70115 1.83642 9.46167C1.72033 11.2222 2.17645 12.9731 3.13674 14.4532C4.09704 15.9333 5.51006 17.0634 7.16507 17.6748C8.82008 18.2862 10.6284 18.3463 12.3203 17.8461C14.0123 17.3458 15.4972 16.312 16.5536 14.8989C17.61 13.4858 18.1813 11.769 18.1823 10.0047V9.16884C18.1823 8.66676 18.5893 8.25975 19.0914 8.25975C19.5935 8.25975 20.0005 8.66676 20.0005 9.16884V10.0052C19.9993 12.1616 19.301 14.2604 18.0098 15.9875C16.7187 17.7147 14.9038 18.9782 12.8359 19.5896C10.7679 20.201 8.55776 20.1276 6.53497 19.3803C4.51218 18.633 2.78516 17.2518 1.61146 15.4428C0.43777 13.6338 -0.119705 11.4938 0.0221803 9.34204C0.164066 7.19029 0.99771 5.14205 2.39878 3.5028C3.79986 1.86354 5.69329 0.721106 7.79669 0.24587C9.90009 -0.229366 12.1008 -0.0119393 14.0705 0.865724C14.5291 1.07007 14.7352 1.6075 14.5309 2.06611C14.3265 2.52472 13.7891 2.73085 13.3305 2.5265Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7339 2.08945C20.0891 2.4443 20.0894 3.0199 19.7346 3.3751L10.6436 12.4751C10.4732 12.6457 10.2419 12.7416 10.0007 12.7417C9.75954 12.7417 9.52822 12.646 9.35768 12.4754L6.6304 9.74815C6.27538 9.39313 6.27538 8.81752 6.6304 8.4625C6.98543 8.10748 7.56103 8.10748 7.91605 8.4625L10.0002 10.5466L18.4483 2.0901C18.8031 1.7349 19.3787 1.73461 19.7339 2.08945Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ImportSuccessIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
};

ImportSuccessIcon.defaultProps = {
    height: '20',
    viewBox: '0 0 20 20',
    width: '20',
    fill: DEFAULT_COLORS.GREEN_3,
};

export default ImportSuccessIcon;
