import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const PrintIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.636 2.91a.91.91 0 0 1 .91-.91h10.909a.91.91 0 0 1 .909.91v6.363a.91.91 0 0 1-1.819 0V3.818h-9.09v5.455a.91.91 0 0 1-1.819 0V2.909z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.727 10.182a.91.91 0 0 0-.909.909v4.545a.91.91 0 0 0 .91.91h1.817a.91.91 0 1 1 0 1.818H4.727A2.727 2.727 0 0 1 2 15.636v-4.545a2.727 2.727 0 0 1 2.727-2.727h14.546A2.727 2.727 0 0 1 22 11.09v4.545a2.727 2.727 0 0 1-2.727 2.728h-1.819a.91.91 0 0 1 0-1.819h1.819a.91.91 0 0 0 .909-.909v-4.545a.91.91 0 0 0-.91-.91H4.728z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.636 13.818a.91.91 0 0 1 .91-.909h10.909a.91.91 0 0 1 .909.91v7.272a.91.91 0 0 1-.91.909H6.545a.91.91 0 0 1-.909-.91v-7.272zm1.819.91v5.454h9.09v-5.455h-9.09z"
                fill={fill}
            />
        </SvgIcon>
    );
};

PrintIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

PrintIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default PrintIcon;
