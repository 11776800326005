import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const PolicyAdvisor = ({ ...rest }) => (
    <SvgIcon {...rest}>
        <g fill="none" fillRule="evenodd">
            <g fill="#000" fillRule="nonzero">
                <path d="M44.308 62.81v-5.522H0v5.544C0 67.852 4.056 72 9.077 72h39.696c-.38-.346-.748-.65-1.094-.997-2.169-2.175-3.381-5.123-3.371-8.194zM31.372 29.34l6.43 4.415 6.454-4.414c1.765-1.205 3.167-3.922 3.167-6.058V14.16l-9.606-4.75-9.605 4.738v9.134c0 2.136 1.394 4.853 3.16 6.058zm1.447-8.62c.475-.471 1.243-.468 1.713.008l1.931 1.95 4.648-4.647c.473-.473 1.24-.473 1.713 0 .474.474.473 1.241 0 1.715l-5.508 5.51c-.228.228-.535.358-.857.36h-.003c-.323-.003-.632-.133-.858-.364l-2.788-2.817c-.47-.477-.466-1.244.009-1.716zM73.886 0h-.039c-3.56 0-6.52 2.974-6.52 6.536v11.118h12.98V6.514C80.325 2.946 77.455.034 73.887 0z" />
                <path d="M18.6 0c-3.366 0-6.105 2.703-6.105 6.018l-.358 48.847h33.382c.68.027 1.216.588 1.212 1.269v6.675c-.012 5.047 4.056 9.154 9.103 9.191h.03c5.028-.037 9.074-4.142 9.04-9.17V6.538c0-2.547 1.146-4.807 2.868-6.538H18.6zm7.188 13.393c-.015-.456.234-.88.64-1.088l10.85-5.332c.334-.165.725-.165 1.058 0l10.87 5.347c.406.208.655.632.64 1.088v9.875c0 2.939-1.793 6.404-4.223 8.06l-7.117 4.88c-.2.137-.437.211-.679.211-.24 0-.476-.073-.674-.21l-7.139-4.882c-2.428-1.656-4.226-5.12-4.226-8.06v-9.889zm29.25 35.934h-33.23c-.67 0-1.212-.542-1.212-1.212 0-.669.542-1.211 1.212-1.211h33.23c.67 0 1.212.542 1.212 1.211 0 .67-.542 1.212-1.212 1.212zm0-6.404h-33.23c-.67 0-1.212-.542-1.212-1.211 0-.67.542-1.212 1.212-1.212h33.23c.67 0 1.212.542 1.212 1.212 0 .669-.542 1.211-1.212 1.211z" />
            </g>
        </g>
    </SvgIcon>
);

PolicyAdvisor.propTypes = {
    viewBox: string,
    width: string,
    height: string,
};

PolicyAdvisor.defaultProps = {
    height: '72',
    viewBox: '0 0 81 72',
    width: '81',
};

export default PolicyAdvisor;
