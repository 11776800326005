import axios from 'axios';

/**
 * API call to fetch the user's permissions.
 *
 * @param   {Object}     params
 * @method fetchUserPermissions
 * @return {Promise}
 */
export const fetchUserPermissions = (params = {}) => {
    return axios({
        method: 'get',
        params,
        url: '/v2/permissions',
    });
};
