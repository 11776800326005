import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

const ImpersonateUserHeaderStyled = styled.header`
    display: flex;
    align-items: center;
    position: static;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    flex: ${({ theme }) => {
        const height = getThemeProps('ImpersonateUserHeader.height', 62, { theme });
        return `0 0 ${height}px`;
    }};
    z-index: 10;
    font-size: 1rem !important;
    padding: 0 0.9375rem !important;
    ${getThemeProps('ImpersonateUserHeader.styles')};
`;

export default ImpersonateUserHeaderStyled;
