import PropTypes from 'prop-types';
import React from 'react';
import rgba from 'polished/lib/color/rgba';
import styled, { css } from 'styled-components';

import { themeGet } from 'ravenjs/utils/theme';
import { checkObjectKeys } from 'ravenjs/utils/object';
import { COLORS } from 'ravenjs/constants/colors';

const TableRowStyled = styled.tr`
    color: inherit;
    outline: none;
    vertical-align: middle;
    /**
     * Add all the remaining styles from the theme
     */
    ${themeGet('TableRow.styles')};
    /**
     * Add in dynamic styles.
     */
    ${({
        cursor,
        disabledColor: disabledColorProps,
        enableHover,
        hoverColor: hoverColorProps,
        hoverOpacity,
        isDisabled,
        selected,
        selectedColor: selectedColorProps,
        selectedOpacity,
        specialColor,
        theme,
        zebra,
        zebraColor: zebraColorProps,
    }) => {
        let disabledCSS = '';
        let selectedCss = '';
        let specialColorCss = '';
        let zebraCss = '';
        let hoverCss = '';
        let cursorCss = '';
        const hoverColor = enableHover
            ? themeGet(`palette.${hoverColorProps}.color`, '#FFF')({ theme })
            : '';
        // Determine the selected row color.
        const selectedColor = themeGet(`palette.${selectedColorProps}.color`, '#FFF')({ theme });
        // Determine the zebra-stripe color.
        const zebraColor = themeGet(`palette.${zebraColorProps}.color`, '#FFF')({ theme });
        if (isDisabled) {
            const disabledColor = themeGet(
                `palette.${disabledColorProps}.color`,
                COLORS.BLACK
            )({ theme });
            disabledCSS = css`
                color: ${disabledColor};
            `;
        }
        // Update the `selectedCss`
        if (selected) {
            selectedCss = css`
                background-color: ${rgba(selectedColor, selectedOpacity)};
            `;
        }
        if (cursor) {
            cursorCss = css`
                cursor: pointer;
            `;
        }
        // Update the `zebraCss`
        if (zebra) {
            zebraCss = css`
                background-color: ${zebraColor};
            `;
        }
        if (hoverColor) {
            hoverCss = css`
                &:hover {
                    background-color: ${rgba(hoverColor, hoverOpacity)};
                }
            `;
        }

        if (specialColor) {
            specialColorCss = css`
                background-color: ${specialColor};
            `;
        }
        // Return the concatenated zebra-style with selected-row-style.
        return css`
            ${disabledCSS};
            ${zebraCss};
            ${selectedCss};
            ${hoverCss};
            ${cursorCss}
            ${specialColorCss}
        `;
    }};
`;

/**
 * The `row` component for the Table.
 *
 * @constructor
 */
const TableRow = React.forwardRef((props, ref) => {
    const { selected: selectedProps, handlers, row, ...rest } = props;
    // Determine if the table-row should be selected.
    const selected =
        typeof selectedProps === 'boolean'
            ? selectedProps
            : checkObjectKeys(row, selectedProps, handlers);
    // Render the table-row
    return <TableRowStyled {...rest} selected={selected} ref={ref} />;
});

TableRow.displayName = 'TableRow';

TableRow.propTypes = {
    /**
     * Should the `theme.grid.gutterWidth` be taken into account?
     */
    gutter: PropTypes.bool,
    /**
     * A map of action handlers used by rendered components.
     */
    handlers: PropTypes.object,
    /**
     * To determine if the row is disabled/inactive
     */
    isDisabled: PropTypes.bool,
    /**
     * The data for the current row.
     */
    row: PropTypes.object,
    /**
     * Determine if the table row should stand out from the rest. (be selected)
     */
    selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
    /**
     * Apply themed selected color to Row.
     */
    selectedColor: PropTypes.string,
    /**
     * Apply an opacity value to the theme selected color.
     */
    selectedOpacity: PropTypes.number,
    /**
     * Apply themed special (custom) color to Row.
     */
    specialColor: PropTypes.string,
    /**
     * If the table row should be zebra styled.
     */
    zebra: PropTypes.bool,
    /**
     * Apply themed zebra color to Row.
     */
    zebraColor: PropTypes.string,

    hoverColor: PropTypes.string,

    hoverOpacity: PropTypes.number,
};

TableRow.defaultProps = {
    gutter: false,
    handlers: null,
    isDisabled: false,
    row: null,
    selected: false,
    selectedColor: 'warning',
    selectedOpacity: 0.05,
    specialColor: '',
    zebra: false,
    zebraColor: 'muted',
    hoverColor: 'primary',
    hoverOpacity: 1,
};

export default TableRow;
