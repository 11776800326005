import { animateScroll } from 'react-scroll';

/**
 * Scroll to an element if its present.
 *
 * @method scrollTo
 * @param  {Element} [el=null]    An HTML element
 * @param  {Object}  [options={}] The options for the scrollTo feature
 */
export function scrollTo(el = null, options = { containerOffset: 150 }) {
    if (el) {
        animateScroll.scrollTo(el.offsetTop + options.containerOffset);
    }
}
