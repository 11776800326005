import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const EyeIcon = ({ colorInner, height, color, viewBox, width, ...rest }) => (
    <SvgIcon height={height} width={width} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g>
                <path
                    fill={color}
                    d="M10 3.798c4.043 0 7.663 2.367 9.522 6.202-1.86 3.835-5.482 6.202-9.522 6.202S2.337 13.835.478 10C2.338 6.165 5.957 3.798 10 3.798zm0 2.255c-2.175 0-3.947 1.772-3.947 3.947S7.825 13.947 10 13.947s3.947-1.772 3.947-3.947S12.175 6.053 10 6.053zm0 1.827c1.17 0 2.12.95 2.12 2.12 0 1.17-.95 2.12-2.12 2.12-1.17 0-2.12-.95-2.12-2.12 0-1.17.95-2.12 2.12-2.12z"
                />
                <circle cx="10" cy="10" r="2.12" fill={colorInner} />
            </g>
        </g>
    </SvgIcon>
);

EyeIcon.propTypes = {
    colorInner: string,
    height: string,
    color: string,
    viewBox: string,
    width: string,
};

EyeIcon.defaultProps = {
    colorInner: DEFAULT_COLORS.BLACK,
    height: '20',
    color: DEFAULT_COLORS.BLUE,
    viewBox: '0 0 20 20',
    width: '20',
};

export default EyeIcon;
