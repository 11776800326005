import { DEFAULT_NAV_ITEMS } from 'constants/navigation';
import { formatAppNavItems, formatUserNavItems } from 'utils/navigation';

/**
 * Initialize the 'ui.navigation' state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    appNav: {
        items: formatAppNavItems(DEFAULT_NAV_ITEMS),
        logoUrl: '/',
        show: true,
    },
    subNav: {
        show: false,
    },
    userNav: {
        items: formatUserNavItems(DEFAULT_NAV_ITEMS),
        show: true,
    },
};

export default INITIAL_STATE;
