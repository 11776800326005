import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ComplyIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFA96B"
                d="M28.588,14.142l-0.202,1.156
              -8.325,47.694L4.672,60.307l7.054-40.405l1.259-7.212c0.213-0.802,0.382-1.243,1.21-1.056L28.588,14.142z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFA96B"
                d="M33.976,3.648h19.926v59.307H33.287
              V4.337C33.287,3.957,33.597,3.648,33.976,3.648"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M52.892,2.018H30.14v59.928h22.751V2.018z M29.131,0h24.771
              c0.558,0,1.011,0.452,1.011,1.009v61.946c0,0.558-0.453,1.008-1.011,1.008H29.131c-0.557,0-1.009-0.45-1.009-1.008V1.009
              C28.122,0.452,28.574,0,29.131,0z"
            />

            <rect
                x="39.47"
                y="33.38"
                transform="matrix(-0.7072 -0.7071 0.7071 -0.7072 52.8353 101.9901)"
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                width="16.137"
                height="13.346"
            />

            <rect
                x="37.291"
                y="24.041"
                transform="matrix(0.7069 0.7073 -0.7073 0.7069 34.6754 -18.7424)"
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                width="5.322"
                height="16.851"
            />

            <rect
                x="52.464"
                y="39.213"
                transform="matrix(0.7071 0.7071 -0.7071 0.7071 49.8314 -25.0254)"
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                width="5.32"
                height="16.852"
            />

            <rect
                x="31.914"
                y="41.701"
                transform="matrix(0.7073 0.7069 -0.7069 0.7073 47.6353 -8.6684)"
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                width="4.744"
                height="22.979"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M56.536,41.039l-9.985-9.986l-8.01,8.012l9.985,9.985L56.536,41.039z
              M47.263,28.915l11.411,11.412c0.393,0.395,0.393,1.031,0,1.424l-9.436,9.438c-0.395,0.393-1.034,0.393-1.427,0L36.402,39.777
              c-0.393-0.393-0.393-1.031,0-1.424l9.436-9.438C46.231,28.522,46.87,28.522,47.263,28.915z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M44.74,23.914l3.762,3.762c0.393,0.393,0.393,1.034,0,1.426L36.588,41.017
              c-0.396,0.395-1.032,0.395-1.427,0l-3.762-3.762c-0.393-0.393-0.393-1.031,0-1.427l11.914-11.914
              C43.706,23.521,44.347,23.521,44.74,23.914 M46.363,28.39l-2.336-2.338L33.538,36.543l2.335,2.335L46.363,28.39z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M59.913,39.088l3.762,3.762c0.393,0.395,0.393,1.034,0,1.427L51.761,56.19
              c-0.395,0.395-1.031,0.395-1.426,0l-3.762-3.762c-0.394-0.393-0.394-1.031,0-1.427l11.914-11.914
              C58.882,38.695,59.521,38.695,59.913,39.088 M61.536,43.562l-2.337-2.335l-10.488,10.49l2.338,2.335L61.536,43.562z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M41.441,42.677l3.357,3.354c0.395,0.395,0.395,1.031,0,1.426L28.551,63.704
              c-0.393,0.391-1.029,0.391-1.422,0l-3.357-3.351c-0.395-0.396-0.395-1.034,0-1.427l16.248-16.25
              C40.414,42.286,41.049,42.286,41.441,42.677 M42.662,46.742l-1.929-1.929L25.908,59.641l1.929,1.93L42.662,46.742z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M34.435,8.665c-0.557,0-1.009-0.452-1.009-1.009
              c0-0.557,0.452-1.009,1.009-1.009H48.6c0.557,0,1.009,0.452,1.009,1.009c0,0.558-0.452,1.009-1.009,1.009H34.435z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M34.435,14.522c-0.557,0-1.009-0.454-1.009-1.011
              c0-0.555,0.452-1.007,1.009-1.007h6.71c0.558,0,1.01,0.452,1.01,1.007c0,0.558-0.452,1.011-1.01,1.011H34.435z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M27.965,11.843L10.935,8.871L2.208,58.852l17.033,2.975L27.965,11.843z
              M10.288,6.712l19.019,3.318c0.547,0.096,0.914,0.619,0.818,1.168l-9.07,51.969c-0.096,0.547-0.619,0.913-1.168,0.817L0.87,60.666
              c-0.549-0.095-0.916-0.62-0.82-1.167L9.122,7.53C9.215,6.982,9.74,6.616,10.288,6.712z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M13.042,17.788c-0.549-0.094-0.916-0.619-0.82-1.166
              c0.096-0.547,0.619-0.914,1.166-0.82l9.867,1.723c0.547,0.094,0.914,0.619,0.818,1.166c-0.094,0.547-0.616,0.914-1.166,0.818
              L13.042,17.788z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M12.051,23.9c-0.547-0.094-0.913-0.619-0.82-1.166
              c0.096-0.549,0.619-0.914,1.168-0.82l4.205,0.734c0.547,0.096,0.914,0.621,0.82,1.168c-0.096,0.547-0.619,0.914-1.168,0.818
              L12.051,23.9z"
            />
        </SvgIcon>
    );
};

ComplyIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

ComplyIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default ComplyIcon;
