export const NEWSLETTERS = {
    NEWS_LETTERS: 'newsletters',
    INSIGHTS: 'insights',
    LAW_ALERT: 'lawAlerts',
    LAW_ALERTS: 'law_alerts',
    WHAT_MATTERS: 'hrNewsletter2',
    QUESTION_OF_THE_WEEK: 'hrNewsletter1',
};

export const NEWSLETTERS_LABEL = {
    INSIGHTS: 'Insights from Mineral Intelligence™️',
    LAW_ALERTS: 'Law Alerts',
};

export const NEWSLETTERS_TITLE =
    'This will affect ${affectedUsers} out of the ${allUsers} users you selected. Users who don’t receive newsletters will be unaffected.';

export const SUBSCRIPTION_INFO = {
    [NEWSLETTERS.LAW_ALERTS]: {
        LABEL: 'Law Alerts',
        DESCRIPTION:
            'Check the boxes below to subscribe to law alerts for federal and individual states. Dependent upon your available services, this may also include policy alerts.',
        TOOLTIP:
            'Law Alerts provide email updates on the most critical legislative changes for a state and at the federal level. Note that these emails are intended to provide information on a small subset of the legislative changes included in the law alerts section on this platform, which we believe are most important for employers to know about immediately.',
    },
    [NEWSLETTERS.WHAT_MATTERS]: {
        LABEL: 'What Matters',
        DESCRIPTION: '',
        TOOLTIP:
            'What Matters is a monthly email newsletter that provides information on state and federal legislative changes from the previous month and highlights content created by our experts that is practical, timely, and user-friendly.',
    },
    [NEWSLETTERS.QUESTION_OF_THE_WEEK]: {
        LABEL: 'Question of the week',
        DESCRIPTION: '',
        TOOLTIP:
            'Question of the Week is a weekly email that provides a glimpse into an HR question we’ve received and the response from our HR Expert.',
    },
    [NEWSLETTERS.INSIGHTS]: {
        LABEL: 'Insights',
        DESCRIPTION: '',
        TOOLTIP:
            'Subscribe to be notified of important events when changes are made to your company profile',
    },
};

export const SUBSCRIPTION_TITLE = 'Select the HR Communications you would like to receive by email';
