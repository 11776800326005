import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';
import * as adminActions from 'modules/admin/actions';
import * as authActions from 'modules/auth/actions';

import * as helpers from './reducerHelpers';
import * as userActions from './actions';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'user' redux handlers.
 *
 * @type {Object}
 */
const user = {
    [adminActions.fetchAllLocationsForCompany.SUCCESS]: helpers.fetchAllLocationsForCompanySuccess,
    [adminActions.updatePageStatus.SUCCESS]: helpers.updatePageStatusSuccess,
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [userActions.buildPermissions.SUCCESS]: helpers.permissionsSuccess('actual'),
    [userActions.fetchPartner.SUCCESS]: helpers.fetchCompanySuccess,
    [userActions.fetchCompany.SUCCESS]: helpers.fetchCompanySuccess,
    [userActions.fetchCompanyLocations.SUCCESS]: helpers.fetchCompanyLocationsSuccess,
    [userActions.fetchConfiguration.SUCCESS]: helpers.fetchConfigurationSuccess,
    [userActions.fetchInformationBanner.SUCCESS]: helpers.fetchInformationBannerSuccess,
    [userActions.fetchPermissions.SUCCESS]: helpers.permissionsSuccess('original'),
    [userActions.fetchUser.SUCCESS]: helpers.fetchUserSuccess,
    [userActions.updateUser.SUCCESS]: helpers.updateUserSuccess,
    [userActions.updateUserPreferences.SUCCESS]: helpers.updateUserPreferencesSuccess,
    [userActions.fetchAllContentTypes.SUCCESS]: helpers.fetchAllContentTypesSuccess,
    [userActions.fetchPartnerSettings.SUCCESS]: helpers.fetchPartnerSettingsSuccess,
    [userActions.updateEULAStatus.SUCCESS]: helpers.updateEULAStatusSuccess,
    [userActions.updateWelcomeInsightsStatus.SUCCESS]: helpers.updateWelcomeInsightsStatusSuccess,
    [userActions.updateCompanyDetailsReminderStatus.SUCCESS]:
        helpers.updateCompanyDetailsReminderStatusSuccess,
    [userActions.updatePartnerSettings.SUCCESS]: helpers.updatePartnerSettingsSuccess,
    [userActions.updateSafetyManualTemplateUrl.SUCCESS]:
        helpers.updateSafetyManualTemplateUrlSuccess,
    [userActions.updateArchivedHandbookStatus.SUCCESS]: helpers.updateArchivedHandbookStatusSuccess,
    [userActions.updateClickSafetyMenu.SUCCESS]: helpers.updateClickSafetyMenuSuccess,
    [userActions.updateSponsorshipList.SUCCESS]: helpers.updateSponsorshipListSuccess,
    [userActions.updatePartnerConfigurations.SUCCESS]: helpers.updatePartnerConfigurationsSuccess,
    [userActions.updateImportStatus.SUCCESS]: helpers.updateImportStatusSuccess,
};

// Create and export the 'user' reducer.
// NOTE: The default export will be the reducer.
export default createReducer(INITIAL_STATE, user);

// Create and export named 'auth' selectors.
// NOTE: The rest of the 'named' exports will be the selectors, for this module.

/**
 * Get all the info about the current user.
 *
 * @method getUser
 * @param  {Object} state The current state
 * @return {Object}       The user info
 */
export const getUser = state => _get(state, 'user', {});

/**
 * Get info about the current user id
 *
 * @method getUserId
 */
export const getUserId = createSelector([getUser], user => _get(user, 'info.userId'));

/**
 * Get info about the current page status
 *
 * @method getUpdatedPageStatus
 */
export const getUpdatedPageStatus = createSelector([getUser], user =>
    _get(user, 'pageStatus', false)
);

/**
 * Get info about the current user.
 *
 * @method getUserInfo
 */
export const getUserInfo = createSelector([getUser], user => _get(user, 'info', {}));

/**
 * Get all the info about the current user's company.
 *
 * @method getUserCompany
 */
export const getUserCompany = createSelector([getUser], user => _get(user, 'company', {}));

/**
 * Get all the info about the current user's configuration.
 *
 * @method getUserConfiguration
 */
export const getUserConfiguration = createSelector([getUser], user =>
    _get(user, 'configuration', {})
);

/**
 * Get all of the current user's permissions.
 *
 * @method getUserPermissions
 */
export const getUserPermissions = createSelector([getUser], user => _get(user, 'permissions', {}));

/**
 * Get the current user's role.
 *
 * @method getUserRole
 */
export const getUserRole = createSelector([getUserInfo], user => _get(user, 'role', ''));

/**
 * Get the current user's roleId.
 *
 * @method getUserRoleId
 */
export const getUserRoleId = createSelector([getUserInfo], user => _get(user, 'roleId', null));

/**
 * Extract the current user's `companyId`.
 *
 * @method getCompanyId
 */
export const getCompanyId = createSelector([getUserCompany], company =>
    _get(company, 'companyId', '')
);

/**
 * Extract the current user's `partnerId`.
 *
 * @method getPartnerId
 */
export const getPartnerId = createSelector([getUserCompany], company =>
    _get(company, 'partnerId', 0)
);

/**
 * Extract the current user's `companyId` from the user's `info`.
 *
 * @method getCompanyIdFromUserInfo
 */
export const getCompanyIdFromUserInfo = createSelector([getUserInfo], user =>
    _get(user, 'companyId', 0)
);

/**
 * Extract the current user's `partnerId` from the user's `info`.
 *
 * @method getPartnerIdFromUserInfo
 */
export const getPartnerIdFromUserInfo = createSelector([getUserInfo], user =>
    _get(user, 'partnerId', '')
);

/**
 * Extract the current user's `companyId` and `partnerId` from the `info`.
 *
 * @method getCompanyAndPartnerIdFromUserInfo
 */
export const getCompanyAndPartnerIdFromUserInfo = createSelector([getUserInfo], user => ({
    companyId: _get(user, 'companyId', ''),
    partnerId: _get(user, 'partnerId', ''),
}));

/**
 * Extract the current user's `configurationId`.
 *
 * @method getUserCompanyId
 */
export const getConfigurationId = createSelector([getUserConfiguration], configuration =>
    _get(configuration, 'configurationId', '')
);

/**
 * Extract the current user's `configurationId` from the user's `company`.
 *
 * @method getUserConfigurationIdFromCompany
 */
export const getUserConfigurationIdFromCompany = createSelector([getUserCompany], company =>
    _get(company, 'configurationId', '')
);

/**
 * Extract the current configuration's `skus`.
 *
 * @method getConfigurationSkus
 */
export const getConfigurationSkus = createSelector([getUserConfiguration], configuration =>
    _get(configuration, 'skus', [])
);

/**
 * Extract the original `permissions`.
 * These are the one the we received from the API call upon login.
 *
 * @method getUserPermissionsOriginal
 */
export const getUserPermissionsOriginal = createSelector([getUserPermissions], permissions =>
    _get(permissions, 'original', [])
);

/**
 * Extract the actual `permissions`.
 * The permissions generated from the configuration skus and the user's role permissions.
 *
 * @method getUserPermissionsActual
 */
export const getUserPermissionsActual = createSelector([getUserPermissions], permissions =>
    _get(permissions, 'actual', [])
);

/**
 * Extract the current company's status for the logged in user.
 *
 * @method isCompanyActive
 */
export const isCompanyActive = createSelector([getUserCompany], company =>
    _get(company, 'isActive', '')
);

/**
 * Extract the company's SKU information for the logged in user.
 *
 * @method companySkus
 */
export const companySkus = createSelector([getUserCompany], company => _get(company, 'skus', []));

/**
 * Extract the addedBy value for the current user's company
 *
 * @method getCompanyAddedBy
 */
export const getCompanyAddedBy = createSelector([getUserCompany], company =>
    _get(company, 'addedBy', '')
);

/**
 * Extract the current company's source for the logged in user.
 *
 * @method getCompanySource
 */
export const getCompanySource = createSelector([getUserCompany], company =>
    _get(company, 'source', '')
);

/**
 * Extract the current company's primary location for the logged in user.
 *
 * @method getCompanyPrimaryLocation
 * @return {Object}
 */
export const getCompanyPrimaryLocation = createSelector([getUserCompany], company =>
    _get(company, 'location')
);

/**
 * Get logged in user email
 *
 * @method getUserEmail
 */
export const getUserEmail = createSelector([getUserInfo], data => _get(data, 'email', null));

/**
 * Get logged in username
 *
 * @method getUserName
 */
export const getUserName = createSelector([getUserInfo], data => _get(data, 'userName', null));

/**
 * Get user term accepted timestamp
 *
 * @method getUserTermsAcceptedAt
 */
export const getUserTermsAcceptedAt = createSelector([getUserInfo], data =>
    _get(data, 'termsAcceptedAt', null)
);

/**
 * Get all content types.
 *
 * @method getContentTypes
 */
export const getContentTypes = createSelector([getUser], user => _get(user, 'contentTypes', []));

/**
 * Get all locations for user company.
 *
 * @method getUserCompanyLocations
 */
export const getUserCompanyLocations = createSelector([getUser], user =>
    _get(user, 'locations', [])
);

/**
 * Get the current user's roleId.
 *
 * @method getUserRoleId
 */
export const getUserRoleType = createSelector([getUserInfo], user => _get(user, 'roleType', null));

/**
 * Get the EULA status.
 *
 * @method getEULAStatus
 */
export const getEULAStatus = createSelector([getUser], user =>
    _get(user, 'EULA.isTermsAccepted', false)
);

/**
 * Get company updateReminderStatus
 *
 * @method getCompanyDetailsReminderStatus
 */
export const getupdateReminderStatus = createSelector([getUserCompany], data =>
    _get(data, 'updateReminderStatus', 0)
);

/**
 * Get Partner configurations info about the current user's.
 *
 * @method getPartnerConfigurations
 */
export const getPartnerConfigurations = createSelector([getUser], user =>
    _get(user, 'partnerConfigurations')
);

/**
 * Get Partner Settings info about the current user's.
 *
 * @method getPartnerSettings
 */
export const getPartnerSettings = createSelector([getUser], user =>
    _get(user, 'partnerSettings', {})
);

/**
 * Get company's display name of the current user's.
 *
 * @method getUserCompanyDisplayName
 */
export const getUserCompanyDisplayName = createSelector([getUserCompany], company =>
    _get(company, 'displayName', '')
);

/**
 * Get information banner details for the user
 *
 * @method getInformationBanner
 */
export const getInformationBanner = createSelector([getUser], user =>
    _get(user, 'informationBanner', {})
);

/**
 * Get Disable Communication status from partner settings
 *
 * @method getDisableCommunicationsStatus
 */
export const getDisableCommunicationsStatus = createSelector(
    [getPartnerSettings],
    partnerSettings => _get(partnerSettings, 'disableCommunications', false)
);

/**
 * Get HrExpertResponse status from partner settings
 *
 * @method getHrExpertResponseStatus
 */
export const getHrExpertResponseStatus = createSelector([getPartnerSettings], partnerSettings =>
    _get(partnerSettings, 'sendHrExpertResponse', false)
);

/**
 * Extract the current user's `industry`.
 *
 * @method getCompanyIndustry
 */
export const getCompanyIndustry = createSelector([getUserCompany], company =>
    _get(company, 'industry', '')
);

/**
 * Get the Welcome Insight status.
 *
 * @method getWelcomeInsightsStatus
 */
export const getWelcomeInsightsStatus = createSelector([getUser], user =>
    _get(user, 'welcomeInsightStatus', { welcomeInsightStatus: 'NOT_DELIVERED' })
);

/**
 * Get safety manual template url.
 *
 * @method getSafetyManualTemplateUrl
 */
export const getSafetyManualTemplateUrl = createSelector([getUser], user =>
    _get(user, 'safetyManualTemplateUrl', [])
);

/**
 * Get status if user has archived hanbooks.
 *
 * @method getArchivedHandbookStatus
 */
export const getArchivedHandbookStatus = createSelector([getUser], user =>
    _get(user, 'archivedHandbookStatus', false)
);

/**
 * Get click on safety menu.
 *
 * @method getClickSafetyMenu
 */
export const getClickSafetyMenu = createSelector([getUser], currentUser =>
    _get(currentUser, 'clickSafetyMenu', '')
);

/**
 * Get status if user has sponsoship list.
 *
 * @method getSponsorshipList
 */
export const getSponsorshipList = createSelector([getUser], user => _get(user, 'sponsorships', []));

/**
 * Get status of import.
 *
 * @method getImportStatus
 */
export const getImportStatus = createSelector([getUser], user => _get(user, 'importStatus', {}));
