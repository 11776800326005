import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ShareIcon = ({ setRef, width, height, ...rest }) => (
    <SvgIcon cursor="pointer" width={width} height={height} {...rest} ref={setRef}>
        <path
            fill="#797979"
            d="M15.932 0c1.953 0 3.54 1.588 3.54 3.54 0 1.953-1.587 3.541-3.54 3.541-1.027 0-1.954-.44-2.601-1.141L6.928 9.59c.1.326.153.673.153 1.032 0 .358-.053.705-.153 1.031l6.403 3.65c.647-.701 1.574-1.14 2.601-1.14 1.953 0 3.54 1.587 3.54 3.54 0 1.952-1.587 3.54-3.54 3.54-1.952 0-3.54-1.588-3.54-3.54 0-.29.035-.57.1-.84l-6.488-3.7c-.638.618-1.507 1-2.464 1-1.952 0-3.54-1.589-3.54-3.541 0-1.953 1.588-3.54 3.54-3.54.957 0 1.825.38 2.463.998l6.49-3.7c-.066-.27-.101-.55-.101-.84 0-1.952 1.588-3.54 3.54-3.54z"
        />
    </SvgIcon>
);

ShareIcon.propTypes = {
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

ShareIcon.defaultProps = {
    setRef: null,
    viewBox: '0 0 20 22',
    width: '20px',
    height: '19px',
};

export default ShareIcon;
