import { takeLatest } from 'redux-saga/effects';

import * as esigDocument from '../actions';
import {
    fetchEsignatureUserDocumentsSaga,
    downloadDocumentForUserSaga,
    fetchSignatureSaga,
    fetchESignedDocumentsSaga,
    fetchEmbeddedHelloSignUrlSaga,
    postDocumentForSignaturesSaga,
    cancelAllEsignatureRequestSaga,
    downloadDocumentFromHelloSignSaga,
    fetchSignDocumentDetailsSaga,
    saveUploadDocumentInfoSaga,
    uploadDocumentForSignaturesSaga,
    cancelIndividualEsignatureSaga,
    remindEsignatureRequestSaga,
} from './esignDocuments';

/**
 * The root `esignDocuments` saga.
 * We're triggering sagas based their respective events.
 *
 * @method esignDocumentsSaga
 * @type   {Generator}
 */
function* esignDocumentsSaga() {
    yield takeLatest(
        esigDocument.fetchEsignatureUserDocuments.TRIGGER,
        fetchEsignatureUserDocumentsSaga
    );
    yield takeLatest(esigDocument.fetchSignature.TRIGGER, fetchSignatureSaga);
    yield takeLatest(esigDocument.fetchEmbeddedHelloSignUrl.TRIGGER, fetchEmbeddedHelloSignUrlSaga);
    yield takeLatest(esigDocument.downloadDocumentForUser.TRIGGER, downloadDocumentForUserSaga);
    yield takeLatest(esigDocument.fetchESignedDocuments.TRIGGER, fetchESignedDocumentsSaga);
    yield takeLatest(esigDocument.postDocumentForSignatures.TRIGGER, postDocumentForSignaturesSaga);
    yield takeLatest(
        esigDocument.cancelAllEsignatureRequest.TRIGGER,
        cancelAllEsignatureRequestSaga
    );
    yield takeLatest(
        esigDocument.downloadDocumentFromHelloSign.TRIGGER,
        downloadDocumentFromHelloSignSaga
    );
    yield takeLatest(
        esigDocument.uploadDocumentForSignatures.TRIGGER,
        uploadDocumentForSignaturesSaga
    );
    yield takeLatest(
        esigDocument.cancelIndividualEsignature.TRIGGER,
        cancelIndividualEsignatureSaga
    );
    yield takeLatest(esigDocument.remindEsignatureRequest.TRIGGER, remindEsignatureRequestSaga);

    yield takeLatest(esigDocument.fetchSignDocumentDetails.TRIGGER, fetchSignDocumentDetailsSaga);
    yield takeLatest(esigDocument.saveUploadDocumentInfo.TRIGGER, saveUploadDocumentInfoSaga);
}

export default esignDocumentsSaga;

export {
    fetchEsignatureUserDocumentsSaga,
    downloadDocumentForUserSaga,
    fetchSignatureSaga,
    fetchESignedDocumentsSaga,
    fetchEmbeddedHelloSignUrlSaga,
    postDocumentForSignaturesSaga,
    cancelAllEsignatureRequestSaga,
    downloadDocumentFromHelloSignSaga,
    fetchSignDocumentDetailsSaga,
    saveUploadDocumentInfoSaga,
    uploadDocumentForSignaturesSaga,
    cancelIndividualEsignatureSaga,
    remindEsignatureRequestSaga,
};
