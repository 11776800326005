import { arrayOf, bool, func, object, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';

import { DEFAULT_COLORS } from 'constants/colors';

import Typography from 'ravenjs/lib/Typography';
import { callFunc } from 'ravenjs/utils/actions';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import Row from 'ravenjs/lib/Row';
import { addEventListenerOnRoot, removeEventListenerOnRoot } from 'ravenjs/utils/events';
import { getThemeProps } from 'ravenjs/utils/theme';

import { AlertIcon, AlertIconCounter, ThrashIcon } from 'components/Icons';
import MESSAGES from 'constants/messages';
import NotificationItem from './MobileNotificationItem';
import NotificationsCounter from './MobileNotificationsCounter';

const MobileNotificationsMenuStyled = styled.div`
    align-items: center;
    cursor: pointer;
    font-weight: 100;
    ${getThemeProps('MobileUserNav.styles')};
`;

const BackdropStyled = styled.div`
    align-items: center;
    bottom: 0;
    display: ${({ display }) => display || 'none'};
    justify-content: center;
    left: 0;
    position: ${({ position }) => position || 'fixed'};
    right: 0;
    top: 114px;
    user-select: none;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
`;

const DeleteAllStyled = styled.div`
    align-items: center;
    background: ${DEFAULT_COLORS.BANNER_RED};
    display: flex;
    justify-content: center;
    min-height: 3.75rem;
`;

const PopoverArrowStyled = styled.div`
    width: 16px;
    height: 5px;
    background-color: ${DEFAULT_COLORS.WHITE};
    position: absolute;
    top: 115px;
    right: 6.5rem;
    z-index: 1150;

    ::after {
        content: '';
        position: absolute;
        bottom: 100%;
        right: -3px;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${DEFAULT_COLORS.WHITE} transparent;
    }
    ::before {
        content: '';
        position: absolute;
        bottom: 100%;
        right: -4px;
        margin-left: -5px;
        border-width: 11px;
        border-style: solid;
        border-color: transparent transparent ${DEFAULT_COLORS.BLACK_2} transparent;
    }
`;

const PopoverStyled = styled.div`
    background-color: ${DEFAULT_COLORS.WHITE};
    border: 1px solid ${DEFAULT_COLORS.BLACK_2};
    position: absolute;
    z-index: 1100;
    left: 0.955rem;
    right: 0.955rem;
    top: 114px;
`;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;
    height: 100%;
    margin: 0;
`;

const ContentStyled = styled.div`
    background-color: ${DEFAULT_COLORS.WHITE};
    min-width: 16.75rem;
    padding: 0;
`;

const NoNotificationsRow = styled(Row)`
    margin-top: 2.99rem;
    display: flex;
    justify-content: center;
    height: 5.438rem;
    align-items: center;
`;

const NotificationsStyled = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    user-select: none;
    ${({ theme }) => theme.media.up('md')`
        margin-right: 0.53rem;
        margin-left: 5rem;
    `};
    ${({ theme }) => theme.media.down('md')`
        margin-right: 0;
        margin-left: 0;
    `};
`;

const NotificationsWrapper = styled.div`
    height: 100%;
    position: relative;
`;

const ViewMoreStyled = styled(Row)`
    background: ${DEFAULT_COLORS.LIGHTBLUE};
    cursor: ${({ disableClick }) => (disableClick ? 'default' : 'pointer')};
    border-top: 1px solid ${DEFAULT_COLORS.DARK};
    align-items: center;
    justify-content: center;
    height: 3.75rem;
    padding: 0;
    width: 100%;
`;

function NotificationsMenu({
    errorMessage,
    hasError,
    iconRef,
    menuRef,
    noNotificationsMessage,
    notifications,
    onClickNotification,
    onClickNotificationIcon,
    hideNotificationMenu,
    onDeleteNotification,
    onViewMore,
    totalUnseen,
    total,
    onClickDeleteAll,
    history,
    theme,
}) {
    const [hover, setHover] = useState(false);

    function handleNotificationOnClick(id) {
        callFunc(
            onClickNotification,
            notifications.find(notification => notification.notificationId === id)
        );
        onMenuLeave();
    }

    function handleNotificationOnDelete(id) {
        callFunc(
            onDeleteNotification,
            notifications.find(notification => notification.notificationId === id)
        );
    }
    function handbleClickOnViewMore() {
        callFunc(onViewMore);
        onMenuLeave();
    }

    function renderNotifications() {
        if (hasError) {
            return (
                <NoNotificationsRow>
                    <Typography color="error" type="noNewNotifications" gutterBottom="0">
                        {errorMessage}
                    </Typography>
                </NoNotificationsRow>
            );
        } else if (isEmpty(notifications)) {
            return (
                <NoNotificationsRow
                    display="flex"
                    justify="center"
                    height="5.438rem"
                    alignItems="center"
                >
                    <Typography type="noNewNotifications" gutterBottom="0">
                        {noNotificationsMessage}
                    </Typography>
                </NoNotificationsRow>
            );
        }

        return notifications.map(
            ({ entityType, notificationId, summary, status, timestamp }, index) => (
                <NotificationItem
                    entityType={entityType}
                    key={notificationId}
                    id={notificationId}
                    onClick={handleNotificationOnClick}
                    onViewMore={handbleClickOnViewMore}
                    onDelete={handleNotificationOnDelete}
                    summary={summary}
                    status={status}
                    timestamp={timestamp}
                    zebra={index % 2 !== 0}
                />
            )
        );
    }

    function renderViewMore() {
        return (
            <>
                {total > 0 ? (
                    <ViewMoreStyled gutter={false}>
                        <Typography
                            gutterBottom="0"
                            gutterTop="0"
                            lineHeight="0.87"
                            fontSize="0.938rem"
                            type="link"
                            onClick={handbleClickOnViewMore}
                            style={{
                                color: DEFAULT_COLORS.BLACK,
                                letterSpacing: '0.65px',
                                textDecoration: 'none',
                            }}
                        >{`View All ${total} Notifications`}</Typography>
                    </ViewMoreStyled>
                ) : (
                    <ViewMoreStyled disableClick gutter={false} />
                )}
            </>
        );
    }

    const renderMenuContent = () => {
        return (
            <ContentStyled ref={menuRef}>
                {renderNotifications()}
                {!isEmpty(notifications) && (
                    <DeleteAllStyled onClick={onClickDeleteAll}>
                        <ThrashIcon />
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            fontSize="0.813rem"
                            gutterLeft="0.729rem"
                            gutterTop="0"
                            gutterBottom="0"
                            lineHeight="1"
                            style={{
                                letterSpacing: '0.65px',
                            }}
                        >
                            {MESSAGES.NOTIFICATIONS.DELETE_ALL}
                        </Typography>
                    </DeleteAllStyled>
                )}
                {renderViewMore()}
            </ContentStyled>
        );
    };

    const renderButtonComponent = () => {
        const pathName = get(history, 'location.pathname', '');
        const isNotificationList = pathName === '/notifications/list';
        const alertIconFillColor = getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, {
            theme,
        });

        return (
            <NotificationsWrapper>
                <NotificationsStyled
                    onBlur={hideNotificationMenu}
                    ref={iconRef}
                    onClick={onClickNotificationIcon}
                >
                    <IconWrapper onClick={onBellClick}>
                        {totalUnseen > 0 && !isNotificationList ? (
                            <>
                                <AlertIconCounter
                                    fill={alertIconFillColor}
                                    height="27"
                                    width="24"
                                />
                                <NotificationsCounter value={totalUnseen} />
                            </>
                        ) : (
                            <AlertIcon fill={alertIconFillColor} height="27" width="24" />
                        )}
                    </IconWrapper>
                </NotificationsStyled>
            </NotificationsWrapper>
        );
    };

    const onClickBlur = event => {
        if (iconRef && iconRef.current && !iconRef.current.contains(event.target)) {
            onMenuLeave();
        }
    };

    const onBellClick = () => {
        document.body.style.overflow = !hover ? 'hidden' : 'unset';
        addEventListenerOnRoot('mousedown', onClickBlur);
        setHover(!hover);
    };

    const onMenuLeave = () => {
        document.body.style.overflow = 'unset';
        removeEventListenerOnRoot('mousedown', onClickBlur);
        setHover(false);
    };

    return (
        <>
            <MobileNotificationsMenuStyled ref={iconRef}>
                {renderButtonComponent()}
                {hover && (
                    <>
                        <PopoverArrowStyled />
                        <PopoverStyled>{renderMenuContent()}</PopoverStyled>
                    </>
                )}
            </MobileNotificationsMenuStyled>
            <BackdropStyled display={hover ? 'flex' : 'none'} />
        </>
    );
}

NotificationsMenu.propTypes = {
    errorMessage: string,
    hasError: bool,
    iconRef: oneOfType([func, object]),
    menuRef: oneOfType([func, object]),
    noNotificationsMessage: string,
    notifications: arrayOf(object),
    onClickNotification: func,
    onDeleteNotification: func,
    onClickNotificationIcon: func.isRequired,
    hideNotificationMenu: func.isRequired,
    onClickDeleteAll: func,
    onViewMore: func,
    totalUnseen: string,
    total: string,
    history: object.isRequired,
    theme: object.isRequired,
};

NotificationsMenu.defaultProps = {
    errorMessage: null,
    hasError: false,
    iconRef: null,
    menuRef: null,
    noNotificationsMessage: 'No new Notifications',
    notifications: [],
    onClickNotification: null,
    onDeleteNotification: null,
    onClickDeleteAll: null,
    onViewMore: null,
    totalUnseen: null,
    total: null,
};

export { NotificationsMenu as NotificationsMenuUnwrapped };
export default withTheme(NotificationsMenu);
