import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeGuidesIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path
                            fill={color}
                            d="M10.038 0C8.898.002 7.806.455 7 1.26 6.194.454 5.102 0 3.962 0H0v12.502h3.962c1.14 0 2.232.454 3.038 1.26.806-.805 1.899-1.258 3.038-1.26H14V0h-3.962z"
                            transform="translate(0 -.7)"
                        />
                        <path
                            fill={colorInner}
                            d="M6.755 14c-.74-.746-1.749-1.165-2.8-1.162H0v-.7h3.962c1.12.005 2.2.418 3.038 1.162.842-.734 1.921-1.137 3.038-1.134H14v.7h-3.962c-1.051-.003-2.06.416-2.8 1.162L6.755 14z"
                            transform="translate(0 -.7)"
                        />
                        <path
                            fill={colorInner}
                            d="M6.65.959H7.65V13.727H6.65zM1.183 2.296H5.516V3.296H1.183zM1.183 4.333H5.516V5.333H1.183zM1.183 5.67H5.516V6.67H1.183zM1.183 7.007H5.516V8.007H1.183zM1.183 8.337H5.516V9.337H1.183zM8.54 2.296H12.873V3.296H8.54zM8.54 4.333H12.873V5.333H8.54zM8.54 5.67H12.873V6.67H8.54z"
                            transform="translate(0 -.7)"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeGuidesIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeGuidesIcon.defaultProps = {
    color: `${DEFAULT_COLORS.BLUE}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeGuidesIcon;
