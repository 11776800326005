import styled from 'styled-components';

import Button from 'ravenjs/lib/Button';
import Container from 'ravenjs/lib/Container';

import { DEFAULT_COLORS } from 'constants/colors';

export const TypeAheadSearch = styled.div`
    position: relative;
    padding: 1.5rem 0;
    margin: 0;
    background: ${DEFAULT_COLORS.STEEL};
    height: 123px;
    ${({ theme }) => theme.media.down('md')`
        height: auto;
    `};
    &.innerSearch {
        height: auto;
        span[type='h3'] {
            display: none;
        }
    }
`;

export const TypeAheadSearchContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    ${({ theme }) => theme.media.down('md')`
        margin: 0;
    `};
`;

export const TypeAheadSearchContainerInput = styled.div`
    position: relative;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;

    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__input {
        position: relative;
        width: 362px;
        padding: 0.5rem 1rem 0.5rem 3.5rem;
        min-height: 40px;
        border: 1px solid ${DEFAULT_COLORS.GREY_16};
        &--focused {
            outline: none;
        }
        &--open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        ${({ theme }) => theme.media.down('md')`
            padding: 0.5rem 3.5rem 0.5rem 1rem;
        `};
    }

    .react-autosuggest__suggestions-container {
        display: none;
        &--open {
            display: block;
            position: absolute;
            width: calc(100% + 111px);
            margin-top: 3px;
            border: 1px solid ${DEFAULT_COLORS.GREY_16};
            background-color: ${DEFAULT_COLORS.WHITE};
            z-index: 9;
            ${({ theme }) => theme.media.down('md')`
                width: 100%;
            `};
        }
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        color: ${DEFAULT_COLORS.GREY_10};
    }

    .react-autosuggest__suggestion {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0.594rem 1rem;
        border-top: 1px solid ${DEFAULT_COLORS.GREY_16};
        &:hover {
            color: ${DEFAULT_COLORS.BLACK};
            background-color: ${DEFAULT_COLORS.BLUE};
        }
        &--highlighted {
            background-color: ${DEFAULT_COLORS.BLUE};
        }
    }

    .react-autosuggest__section-title {
        padding: 0.594rem 1rem;
        color: ${DEFAULT_COLORS.GREY_10};
    }
`;

export const TypeAheadSearchInputIcon = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    ${({ theme }) => theme.media.down('md')`
        left: calc(100% - 56px);
    `};
`;

export const SearchHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonStyled = styled(Button)`
    margin-left: 0.25rem;
    min-width: 107px;
    font-family: 'Favorit', Helvetica, Arial, sans-serif;
    ${({ theme }) => theme.media.down('md')`
        display: none;
    `};
`;

export const HelpTopicsLiveChatSection = styled.div`
    position: relative;
    padding: 2.5rem 0;
    background-color: ${DEFAULT_COLORS.YELLOW};
    color: ${DEFAULT_COLORS.BLACK};
    ${({ theme }) => theme.media.down('md')`
        padding: 2rem 0;
    `};
`;

export const SectionContainer = styled(Container)`
    padding: 0 1.5rem;
`;

export const HelpTopicLiveChatBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 800px;
    margin: 0 auto;
    ${({ theme }) => theme.media.down('md')`
        width: auto;
        display: flex;
        flex-direction: column;
    `};
    img {
        height: 270px;
        ${({ theme }) => theme.media.down('md')`
            height: 205px;
        `};
    }
`;

export const HelpTopicLiveChatText = styled.div`
    position: relative;
    flex: 1;
`;
