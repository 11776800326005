import { bool, func, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { callFunc } from 'ravenjs/utils/actions';
import Button from 'ravenjs/lib/Button';
import Typography from 'ravenjs/lib/Typography';
import Box from 'ravenjs/lib/Box';

import { getThemeProps } from 'ravenjs/utils/theme';

import { actions as pendoActions } from 'modules/pendo';
import { actions as uiActions } from 'modules/ui';

import { showPendoSurveyGuide } from 'utils/pendo';
import { hasFeatureFlag } from 'utils/common';

import { DEFAULT_COLORS } from 'constants/colors';
import {
    HELP_MENU,
    HELP_MENU_TITLE,
    LANDING,
    VERGIC_OPEN_STATUS,
    VERGIC_READY_STATUS,
} from 'constants/helpCenter';

import { selectors as brandingSelectors } from 'modules/branding';
import { selectors as userSelectors } from 'modules/user';
import Acl from 'modules/acl';
import { VERGIC_GROUP_KEY } from 'constants/api';
import { MODALS } from 'constants/modals';
import PopupVergic from './PopupVergic';

const ChatWindow = styled(Box)`
    display: flex;
    background-color: ${DEFAULT_COLORS.LIGHTTAN};
    height: 500px;
    margin: 0;
    width: 500px;
`;
const BoxMenu = styled(Box)`
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
    padding: 1rem 2rem;
    box-shadow: 0px 4px 16px -4px ${DEFAULT_COLORS.GREY_17};
    &::before {
        content: '';
        position: absolute;
        bottom: -12px;
        right: 25px;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 22px 27px 0 27px;
        border-color: ${({ theme }) =>
                getThemeProps('palette.secondary.gradientColor', DEFAULT_COLORS.LIGHTTAN, {
                    theme,
                })}
            transparent transparent transparent;
    }
    button {
        margin: 0.5rem 0;
    }
`;

let removeVergigId = false;

class HelpWidget extends Component {
    static propTypes = {
        brandingConfig: shape({
            secondaryTextColor: string,
        }).isRequired,
        getChatPendoGuide: func.isRequired,
        history: object.isRequired,
        isVergigLoaded: bool.isRequired,
        onClick: func.isRequired,
        openModal: func.isRequired,
        isPageUpdated: bool.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            vergicChat: VERGIC_GROUP_KEY,
            openChat: false,
            openPopup: false,
        };
    }

    componentDidMount() {
        const { onClick } = this.props;
        if (document.getElementById('mainAppContent')) {
            document.getElementById('mainAppContent').addEventListener('click', function(e) {
                callFunc(onClick, { outside: true });
            });
        }

        if (window.vngage) {
            window.vngage.subscribe('queue:leave', function() {
                removeVergigId = true;
            });

            window.vngage.subscribe('conversation_started', function() {
                removeVergigId = true;
            });

            window.vngage.subscribe('close', async () => {
                const { getChatPendoGuide, openModal } = this.props;
                await showPendoSurveyGuide(getChatPendoGuide, openModal);
            });
        }
    }

    goTo = (path, target) => {
        const { history, isVergigLoaded, onClick } = this.props;
        const { vergicChat } = this.state;

        if (path === 'chat') {
            if (vergicChat) {
                if (
                    window.vngage &&
                    window.vngage.get('currentstate') === VERGIC_READY_STATUS &&
                    window.vngage.get('queuestatus', VERGIC_GROUP_KEY) === VERGIC_OPEN_STATUS
                ) {
                    window.vngage.evaluateOpportunities();
                }

                const vngageActive = document.getElementsByClassName('vngage-active');
                const vngagePanel = document.getElementsByClassName('vngage-panel-container');
                if (!vngageActive.length && !vngagePanel.length && !isVergigLoaded) {
                    const { openPopup } = this.state;
                    this.setState({
                        openPopup: !openPopup,
                    });
                } else if (vngagePanel.length) {
                    const {
                        style: { cssText },
                    } = vngagePanel[0];
                    const chatClasses = vngagePanel[0].className;

                    if (chatClasses.indexOf('idle-right') !== -1) {
                        const vngageTabloopBefore = document.getElementsByClassName(
                            'vngage-tabloop-before'
                        );
                        const vngageTabloopAfter = document.getElementsByClassName(
                            'vngage-tabloop-after'
                        );

                        if (vngageTabloopBefore.length) {
                            for (const elementBefore of vngageTabloopBefore) {
                                elementBefore.setAttribute('tabindex', '0');
                            }
                        }

                        if (vngageTabloopAfter.length) {
                            for (const elementAfter of vngageTabloopAfter) {
                                elementAfter.setAttribute('tabindex', '0');
                            }
                        }

                        if (cssText.indexOf('margin-left') !== -1) {
                            vngagePanel[0].style.marginLeft = '';
                            vngagePanel[0].style.transform = '';
                        }

                        vngagePanel[0].classList.remove('idle-right');
                        vngagePanel[0].classList.add('docked-right');
                        vngagePanel[0].setAttribute('aria-modal', 'true');
                    }
                } else if (removeVergigId) {
                    this.setState({ openPopup: true });
                } else {
                    callFunc(onClick, { outside: true });
                }
            } else {
                const { openChat } = this.state;
                this.setState({
                    openChat: !openChat,
                });
            }
        } else {
            if (target) {
                window.open(path, target);
            } else {
                history.push(path);
            }

            callFunc(onClick, { outside: true });
        }
    };

    renderChat = () => {
        return (
            <ChatWindow borderColor="transparent" borderRadius="0">
                <iframe
                    title="Chat Window"
                    // TODO: update url when it's available.
                    src="https://home-c35.nice-incontact.com/inContact/ChatClient/ChatClient.aspx?poc=93dbb938-5260-4688-96d2-33dbf4f17d2f&amp;bu=4599515&amp;P1=&amp;P2=&amp;P3=&amp;P4=;"
                    frameBorder="0"
                    height="100%"
                    width="100%"
                />
            </ChatWindow>
        );
    };

    render() {
        const { brandingConfig, openModal, isPageUpdated } = this.props;
        const { openChat, openPopup, vergicChat } = this.state;

        let chatInterface;
        let openChatInterface = false;

        if (vergicChat) {
            chatInterface = <PopupVergic />;
            openChatInterface = openPopup;
        } else {
            chatInterface = this.renderChat();
            openChatInterface = openChat;
        }

        return openChatInterface ? (
            chatInterface
        ) : (
            <BoxMenu
                backgroundColor="transparent"
                borderColor="transparent"
                borderRadius="0"
                padding="0"
            >
                <Typography
                    type="h2"
                    align="center"
                    style={{
                        color: brandingConfig.secondaryTextColor,
                    }}
                >
                    {HELP_MENU_TITLE}
                </Typography>

                {!vergicChat &&
                    HELP_MENU &&
                    HELP_MENU.map(({ title, path, permissions, permissionsToHide, target }) => {
                        const isHidden = permissionsToHide ? Acl.check(permissionsToHide) : false;
                        const isVisible = permissions ? Acl.check(permissions) : true;
                        return !isHidden && isVisible ? (
                            <Button
                                key={title}
                                onClick={() => {
                                    if (target === '_self' && isPageUpdated) {
                                        openModal(MODALS.UNSAVED_CHANGES_MODAL, {
                                            onContinue: () => {
                                                this.goTo(path, target);
                                            },
                                        });
                                    } else {
                                        this.goTo(path, target);
                                    }
                                }}
                            >
                                {title}
                            </Button>
                        ) : null;
                    })}

                {vergicChat &&
                    HELP_MENU &&
                    HELP_MENU.map(
                        ({
                            disabledLabel,
                            id,
                            title,
                            path,
                            pathToNewHelpCenter,
                            permissions,
                            permissionsToHide,
                            target,
                        }) => {
                            const isHidden = permissionsToHide
                                ? Acl.check(permissionsToHide)
                                : false;
                            const isVisible = permissions ? Acl.check(permissions) : true;

                            if (!isHidden && isVisible) {
                                let pathToGo = path;
                                if (
                                    hasFeatureFlag(window.location, 'showHelpCenterV2') &&
                                    title === LANDING.mainTitle
                                ) {
                                    pathToGo = pathToNewHelpCenter;
                                }

                                const buttonProps = {
                                    key: title,
                                    onClick: () => {
                                        if (target === '_self' && isPageUpdated) {
                                            openModal(MODALS.UNSAVED_CHANGES_MODAL, {
                                                onContinue: () => {
                                                    this.goTo(pathToGo, target);
                                                },
                                            });
                                        } else {
                                            this.goTo(pathToGo, target);
                                        }
                                    },
                                };

                                if (removeVergigId) {
                                    return <Button {...buttonProps}>{title}</Button>;
                                } else {
                                    buttonProps.id = id;
                                    return <Button {...buttonProps}>{title}</Button>;
                                }
                            }

                            return null;
                        }
                    )}
            </BoxMenu>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    brandingConfig: brandingSelectors.getConfig,
    isPageUpdated: userSelectors.getUpdatedPageStatus,
});

const mapDispatchToProps = {
    getChatPendoGuide: pendoActions.getChatPendoGuide,
    openModal: uiActions.openModal,
};

export { HelpWidget as HelpWidgetUnwrapped };
export default connect(mapStateToProps, mapDispatchToProps)(HelpWidget);
