import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AddIcon = ({ fill, height, width, viewBox, ...rest }) => {
    return (
        <SvgIcon cursor="pointer" width={width} height={height} viewBox={viewBox} {...rest}>
            <g fill="none" fillRule="evenodd">
                <g fill={fill}>
                    <path
                        d="M10.595 2.47v6.935h6.935v1.19h-6.935v6.935h-1.19v-6.935H2.47v-1.19h6.935V2.47h1.19z"
                        transform="translate(-2 -2)"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

AddIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

AddIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '16',
    width: '16',
    viewBox: '0 0 16 16',
};

export default AddIcon;
