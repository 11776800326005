/**
 * Initialize the 'bootStrap' state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    // Config for some internal components.
    components: {},
    // Config for the main application (i.e. system).
    system: {},
};

export default INITIAL_STATE;
