import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CloseIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            {...rest}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.818a8.182 8.182 0 1 0 0 16.364 8.182 8.182 0 0 0 0-16.364zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.37 8.63a.91.91 0 0 1 0 1.286L9.916 15.37a.91.91 0 0 1-1.286-1.286l5.454-5.454a.91.91 0 0 1 1.286 0z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.63 8.63a.91.91 0 0 1 1.286 0l5.454 5.454a.91.91 0 0 1-1.286 1.286L8.63 9.916a.91.91 0 0 1 0-1.286z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CloseIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CloseIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default CloseIcon;
