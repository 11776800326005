import axios from 'axios';

/**
 * API to fetch active clients and users
 *
 * @method fetchActiveClientsUsersAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchActiveClientsUsersAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/reports/active-client-user',
    });
};

/**
 * API to download active clients and users report
 *
 * @method downloadActiveClientsUsersReportsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const downloadActiveClientsUsersReportsAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data: params,
        url:
            params && params.partnerId
                ? '/tools/v1/reports/active-client-user-report'
                : '/tools/v1/reports/download-active-client-user',
    });
};

/**
 * API to fetch partners
 *
 * @method fetchPvrPartnersAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchPvrPartnersAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: 'tools/v1/reports/pvr/company-search',
    });
};

/**
 * API to fetch calculation values to Partner Value Report
 *
 * @method fetchCalculationValuesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchCalculationValuesAPI = () => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: '/tools/v1/reports/pvr/calculation-values',
    });
};

/**
 * API to update calculation values to Partner Value Report
 *
 * @method updateCalculationValuesAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const updateCalculationValuesAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'put',
        noBearer: true,
        data: params,
        url: '/tools/v1/reports/pvr/calculation-values',
    });
};

/**
 * API to fetch client usage
 *
 * @method fetchClientUsageAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchClientUsageAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data: params,
        url: '/tools/v1/reports/client-usage',
    });
};

/**
 * API to fetch partner value report
 *
 * @method fetchPartnerValueReportAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchPartnerValueReportAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data: params,
        url: '/tools/v1/reports/pvr',
    });
};

/**
 * API to fetch total clients
 *
 * @method fetchTotalClientsByPartnerAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchTotalClientsByPartnerAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/reports/pvr/total-companies',
    });
};

/**
 * API to fetch client value report clients
 *
 * @method fetchCvrClientsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchCvrClientsAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: 'tools/v1/reports/cvr/company-search',
    });
};

/**
 * API to fetch client value report
 *
 * @method fetchClientValueReportAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchClientValueReportAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data: params,
        url: '/tools/v1/reports/cvr',
    });
};

/**
 * API to fetch client value report template
 *
 * @method fetchClientValueReportTemplateAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchClientValueReportTemplateAPI = () => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: '/tools/v1/reports/cvr/template',
    });
};

/**
 * API to fetch client value report clients
 *
 * @method fetchCvrClientsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchEcAggregateReportAPI = params => {
    const { companyId, downloadReport = false, endDate, startDate, timeZone, type } = params;
    let url = `/v2/partners/${companyId}/ec-aggregate-report`;

    if (downloadReport) {
        url += '/csv';
    }

    return axios({
        method: 'get',
        params: {
            endDate,
            startDate,
            timeZone,
            type,
        },
        url,
    });
};

/**
 * API to fetch reporting snapshot by partner id
 *
 * @method fetchReportingSnapshotByPartnerAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchReportingSnapshotByPartnerAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/reporting-snapshot',
    });
};

/**
 * API to fetch request by id
 *
 * @method fetchReportingSnapshotRequestAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchReportingSnapshotRequestAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/reporting-snapshot/request',
    });
};

/**
 * API to fetch reporting snapshot refresh
 *
 * @method fetchReportingSnapshotRefreshAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchReportingSnapshotRefreshAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data: params,
        url: '/tools/v1/reporting-snapshot/refresh',
    });
};

/**
 * API to run email engagement reporting
 *
 * @method runEmailEngagementAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const runEmailEngagementAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data: params,
        url: '/tools/v1/reports/email-history',
    });
};

/**
 * API to fetch client solution report template
 *
 * @method fetchClientValueReportTemplateAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchClientSolutionReportAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `/tools/v1/reports/client-solution/search?filters=${JSON.stringify(params)}`,
    });
};

export const runClientSolutionReportAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data: params,
        url: '/tools/v1/reports/client-solution',
    });
};

/**
 * API to run Administration Reports reporting
 *
 * @method runAdministrationReportsAPI
 * @param  {Object} data
 * @return {Promise}
 */
export const runAdministrationReportsAPI = data => {
    return axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        data,
        url: '/tools/v1/reports/active-client-user-report',
    });
};

/**
 * API to fetch Administration total companies
 *
 * @method fetchAdministrationReportsAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchAdministrationReportsAPI = params => {
    return axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        params,
        url: '/tools/v1/reports/administrative/total-companies',
    });
};
