import { DEFAULT_COLORS } from 'constants/colors';

import { renderCheck } from 'ravenjs/utils/viewport';

const DOCUMENTS = {
    DOCUMENT_TYPES: [
        {
            name: 'Charts',
            mappedName: 'chart',
            state: 'charts',
        },
        {
            name: 'Checklist',
            mappedName: 'checklist',
            state: 'checklist',
        },
        {
            name: 'Forms',
            mappedName: 'form',
            state: 'form',
        },
        {
            name: 'Guides',
            mappedName: 'guide-white-paper',
            state: 'guide/whitepapers',
        },
        {
            name: 'Help Center',
            mappedName: 'help-center',
            state: 'help-center',
        },
        {
            name: 'Infographic',
            mappedName: 'infographic',
            state: 'infographic',
        },
        {
            name: 'Letters',
            mappedName: 'letter',
            state: 'letter',
        },
        {
            name: 'Policies',
            mappedName: 'policy',
            state: 'policy',
        },
        {
            name: 'Toolkits',
            mappedName: 'toolkit',
            state: 'toolkit',
        },
    ],
    DOCUMENT_TYPE_COLORS: {
        Chart: DEFAULT_COLORS.RED,
        Checklist: DEFAULT_COLORS.ORANGE,
        Form: DEFAULT_COLORS.YELLOW,
        'Guide/White paper': DEFAULT_COLORS.GREEN,
        Infographic: DEFAULT_COLORS.TAN,
        Letter: DEFAULT_COLORS.BRAND_RED_2,
        Policy: DEFAULT_COLORS.RED,
    },

    META_DOCUMENT_TYPES: {
        DOCUMENT_TYPES: 'document-types',
    },
    COLORS_FAVORITE_ICON: {
        iconColor: {
            active: DEFAULT_COLORS.BLACK,
            inactive: DEFAULT_COLORS.WHITE,
        },
        strokeColor: {
            active: DEFAULT_COLORS.BLACK,
            inactive: DEFAULT_COLORS.BLACK,
        },
    },
    SECTIONS_NEW_FILTERS: ['/hr-compliance/laws', '/hr-compliance/benefits'],
    DEFAULT_WIDGET_PROPS: {
        backgroundColor: `${DEFAULT_COLORS.COMPONENTS_THE_GREY_STYLE}`,
        borderColor: 'transparent',
        margin: renderCheck('md', 'greater') ? '0 0 3.5rem 0' : '0 0 1.5rem 0',
        minHeight: '24.0625rem',
    },
    DEFAULT_WIDGETHEADER_PROPS: {
        justifyContent: 'flex-start',
        textAlign: 'left',
        padding: renderCheck('md', 'greater') ? '1.125rem 2rem' : '1.125rem 1.1875rem',
        backgroundColor: `${DEFAULT_COLORS.COMPONENTS_TABLE_TITLE_ROW}`,
        borderColor: 'transparent',
        borderRadius: '0',
    },
    DEFAULT_WIDGETBODY_PROPS: {
        padding: renderCheck('md', 'greater') ? '1.125rem 2rem' : '1.125rem 1.1875rem',
        borderColor: 'transparent',
        minHeight: 'auto',
    },
    DEFAULT_WIDGETFILTER_PROPS: {
        justifyContent: 'flex-start',
        textAlign: 'left',
        padding: renderCheck('md', 'greater') ? '1rem 2rem' : '0.5625rem 1.1875rem',
        backgroundColor: `${DEFAULT_COLORS.COMPONENTS_THE_GREY_STYLE}`,
        borderColor: 'transparent',
        borderRadius: '0',
    },
    DEFAULT_PADDING_HEADER: '1.125rem 2rem 1.125rem 1rem',
    DEFAULT_PADDING_HEADER_CVR: '1.125rem 2rem 1.125rem 0.5rem',
    DEFAULT_PADDING_HEADER_STATIC_CVR: '1.125rem 2rem 1.125rem 1.40rem',
    RESOURCES_CONTENT_TYPE: ['2mhr', 'document', 'qa', 'webinars', 'webinarsOnDemand'],
    RESOURCES_DOCUMENT_TYPE: ['Chart', 'Checklist', 'Guide/White paper'],
    CONTENT_TYPES: {
        'q-and-a': 'qa',
        'Q&A': 'qa',
        '2MHR': '2mhr',
        '2mhr': '2mhr',
        webinars: 'webinars',
        Webinars: 'webinars',
        Videos: '2mhr',
    },
    RESOURCES_DOCUMENT_TYPE_API_KEYS: [
        { key: 'Chart', apiValue: 'Chart' },
        { key: 'Charts', apiValue: 'Chart' },
        { key: 'Checklist', apiValue: 'Checklist' },
        { key: 'Checklists', apiValue: 'Checklist' },
        { key: 'Guide/White paper', apiValue: 'Guide/White paper' },
        { key: 'Guides', apiValue: 'Guide/White paper' },
        { key: 'webinars', apiValue: 'webinars' },
        { key: '2mhr', apiValue: '2mhr' },
        { key: 'qa', apiValue: 'qa' },
    ],
    EXPORT_LAWS_LABEL: 'Export Laws',
    EXPORT_LAWS_BUTTON_ID: 'laws',
    EXPORT_LAWS_MODAL_BUTTON_ID: 'laws-modal',
    EXPORT_LAWS_SELECTED:
        'Below is a summary of the categories and states you have selected.  Clicking the Export Laws button will send a single document export to your email.',
    EXPORT_LAWS_NO_SELECTED:
        'To export a list of laws to a downloadable Word file, please select one or more states and between 1-10 categories from the filter on the left side of the page.',
    EXPORT_LAWS_SUCCESS:
        'Your request is being processed.  Please check your email (EMAIL_TO_REPLACE) in a few minutes.',
    UPCOMING_WEBINARS_DATE_FORMAT: 'dddd MMMM DD, YYYY',
    UPCOMING_WEBINARS_TIME_FORMAT: 'h:mm a',
    DURATION_LAW_ALERTS_FILTER: {
        last30Days: 1,
        last90Days: 2,
        last6Months: 3,
        last12Months: 4,
    },
    LAW_ALERTS_DEFAULT_FILTERS: {
        categoryTypes: ['categoryTypeAll'],
        employeeCount: ['employeeCountAll'],
        jurisdiction: ['jurisdictionAll'],
        dateType: 'all',
        duration: 'selectDateRange',
        fromDate: '',
        toDate: '',
        sort: 'all',
        status: 'current',
    },
};

export default DOCUMENTS;
