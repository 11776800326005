export const PENDO_SEARCH = {
    SEARCH: {
        DESCRIPTION: 'User search words',
        EVENT: 'search',
    },
    SEARCH_FILTER: {
        DESCRIPTION: 'User use filters after search',
        EVENT: 'searchFilter',
    },
    SEARCH_PAGINATION: {
        DESCRIPTION: 'User use pagination after search',
        EVENT: 'searchPagination',
    },
    SEARCH_SHOW_ALL: {
        DESCRIPTION: 'User use Show All after search',
        EVENT: 'searchShowAll',
    },
    SEARCH_SHOW_MORE: {
        DESCRIPTION: 'User use Show More after search',
        EVENT: 'searchShowMore',
    },
    TIME_ON_PAGE: {
        DESCRIPTION: 'Time user is spending on the search result page',
        EVENT: 'searchTimeOnPage',
    },
    TYPE_AHEAD_ARTICLE: {
        DESCRIPTION: 'User click on Type Ahead article of search',
        EVENT: 'searchTypeAheadArticle',
    },
    VIA_BACK_BUTTON: {
        DESCRIPTION: 'User search triggered via Back button',
        EVENT: 'searchViaBackButton',
    },
};

export const PENDO_LABEL = 'Pendo';
export const PENDO_GUIDE_SUCCESS = 'In-Product Marketing - Lead Success';
export const PENDO_GUIDE_FAIL = 'In-Product Marketing - Lead Failure';
