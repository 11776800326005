import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import axios from 'axios';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';

import { APP_PERSIST_ID } from 'constants/store';

import createRootReducer from './reducers';
import history from './history';
import rootSaga from './sagas';
import { oAuthTokenRequestInterceptor, onResponseFulfilled, onResponseRejected } from './axios';

/**
 * The config for the redux persist.
 *
 * @type {Object}
 */
const reduxPersistConfig = {
    // We don't want to persist the following keys.
    blacklist: ['errors', 'modals', 'router', 'ui', 'tickets'],
    // This is good for development environments.
    debug: MAESTER.nodeEnv === 'development',
    // A key to keep in `localStorage`.
    key: APP_PERSIST_ID,
    // Extraneous options for persisting and auto merging the previous and next states.
    stateReconciler: autoMergeLevel2,
    // The type of storage to use.
    storage,
};

/**
 * Configure the store for the application.
 *
 * @method configureStore
 * @return {Object}       The store, history, and persistor
 */
const configureStore = () => {
    // Combine the main reducers with the redux-persist.
    const reducers = persistReducer(reduxPersistConfig, createRootReducer(history));
    // Create the redux-saga middleware.
    const sagaMiddleware = createSagaMiddleware();
    // Create the redux-wait-for-action middlewware.
    const reduxWaitForMiddleware = createReduxWaitForMiddleware();

    const USE_REDUX_LOGGER = false;
    let reduxLogger = null;

    // Build the middlewares list for the store.
    let middlewaresList = [routerMiddleware(history), sagaMiddleware, reduxWaitForMiddleware];

    if (MAESTER.nodeEnv === 'development') {
        // NOTE: redux-logger must be the last middleware in the list,
        // otherwise it will log all of the other middlewares + promises before it.
        // @link https://github.com/evgenyrodionov/redux-logger/issues/20
        if (USE_REDUX_LOGGER) {
            // Build the redux-logger (good for debugging redux actions).
            reduxLogger = createLogger({
                duration: true,
            });
            middlewaresList = [...middlewaresList, reduxLogger];
        }
    }

    // Run the Redux DevTools Extension
    // NOTE: Usually you would only run the redux-devtools in 'development' environment,
    // but it's good to have it available if the extension is present.
    const composeEnhancers =
        MAESTER.nodeEnv === 'development'
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
            : compose;

    // Create the store.
    const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewaresList)));

    // Add interceptors to the global axios instance.
    axios.interceptors.request.use(config => oAuthTokenRequestInterceptor(store, config));
    axios.interceptors.response.use(onResponseFulfilled, error =>
        onResponseRejected(store, history, error)
    );

    // Create the redux-persist items.
    const persistor = persistStore(store);

    // Run the root redux-saga.
    sagaMiddleware.run(rootSaga);

    // Return an object consisting of the store, the history, and the persistor.
    // This is the object return for redux-persist to bind correctly.
    return {
        history,
        persistor,
        store,
    };
};

export default configureStore;
