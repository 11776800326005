import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CommentIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <path
                d="M12.5016 20C17.196 20 21.0016 16.1944 21.0016 11.5C21.0016 6.80558 17.196 3 12.5016 3C7.80716 3 4.00158 6.80558 4.00158 11.5C4.00158 13.3338 4.58232 15.032 5.56992 16.4207C5.6228 16.4951 5.6329 16.592 5.59459 16.6749L4.04801 20.0179C3.85264 20.4402 4.28943 20.877 4.71173 20.6816L8.3139 19.0152C8.38584 18.9819 8.46919 18.9849 8.53929 19.0219C9.72241 19.6464 11.0707 20 12.5016 20Z"
                strokeWidth="2"
                stroke={fill}
                fill="none"
            />
        </SvgIcon>
    );
};

CommentIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CommentIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default CommentIcon;
