import axios from 'axios';

/**
 * API call to send reset email to especific user.
 *
 * @method sendResetEmailAPI
 * @param  {number}  data The data to request reset email
 * @return {Promise}
 */
export const sendResetEmailAPI = data =>
    axios({
        method: 'post',
        url: '/v2/users/send-reset-password-email',
        data,
    });

/**
 * API call to reset password
 *
 * @method resetPasswordAPI
 * @param  {number}  data The data to reset password
 * @return {Promise}
 */
export const resetPasswordAPI = data =>
    axios({
        method: 'post',
        url: '/v2/users/reset-password',
        data,
    });

/**
 * API call to validate reset password link
 *
 * @method validateResetPasswordLinkAPI
 * @param  {number}  data The data to reset password
 * @return {Promise}
 */
export const validateResetPasswordLinkAPI = data =>
    axios({
        method: 'post',
        url: '/v2/users/validate-reset-password-link',
        data,
    });

/**
 * API call to update password
 *
 * @method updatePasswordAPI
 * @param  {number}  data The data to update password
 * @return {Promise}
 */
export const updatePasswordAPI = data =>
    axios({
        method: 'post',
        url: '/v2/users/update-password',
        data,
    });
