const PROFILE = {
    INTELLIGENCE_SUBSCRIPTION: {
        event: 'Intelligence Subscription',
        eventProperties: {
            description: 'User selected to subscribe mineral intelligence',
            selectionType: 'subscribeEntity',
        },
    },
    INTELLIGENCE_UNSUBSCRIPTION: {
        event: 'Intelligence Unsubscription',
        eventProperties: {
            description: 'User selected to unsubscribe mineral intelligence',
            selectionType: 'unsubscribeEntity',
        },
    },
    SAVE_PROFILE_SETTINGS: {
        event: 'Save User Profile Settings',
        eventProperties: {
            description: 'User selected to save settings on edit profile page',
            selectionType: 'saveProfileSettings',
        },
    },
};

export default PROFILE;
