import { takeLatest } from 'redux-saga/effects';

import * as notifications from '../actions';

import {
    fetchNotificationsSaga,
    updateNotificationsSaga,
    fetchNotificationsTypeSaga,
    deleteNotificationsSaga,
    fetchMenuNotificationsSaga,
} from './notifications';

/**
 * Root saga for Contentful actions
 *
 * @method contentfulSaga
 * @type   {Generator}
 */
function* notificationsSaga() {
    yield takeLatest(notifications.fetchNotifications.TRIGGER, fetchNotificationsSaga);
    yield takeLatest(notifications.updateNotifications.TRIGGER, updateNotificationsSaga);
    yield takeLatest(notifications.fetchNotificationsType.TRIGGER, fetchNotificationsTypeSaga);
    yield takeLatest(notifications.deleteNotifications.TRIGGER, deleteNotificationsSaga);
    yield takeLatest(notifications.fetchMenuNotifications.TRIGGER, fetchMenuNotificationsSaga);
}

export default notificationsSaga;

export {
    fetchNotificationsSaga,
    updateNotificationsSaga,
    deleteNotificationsSaga,
    fetchMenuNotificationsSaga,
};
