import { format, isFuture, isPast, isToday } from 'date-fns';
import get from 'lodash/get';

import { formatDate } from 'ravenjs/utils/date';

import { BANNERS_STATUS } from 'constants/banners';
import { getTimeStampFromDateTimeMeridian } from './date';

/**
 * Format banners list for data view.
 *
 * @param   {Array}   banners
 * @return  {Array}  formatted data
 */
export const formatBannersList = banners =>
    banners.map(banner => {
        const { active, effectiveFrom, effectiveTill } = banner;

        return {
            ...banner,
            from: formatDate(effectiveFrom, 'MM/DD/YYYY'),
            until: formatDate(effectiveTill, 'MM/DD/YYYY'),
            active: active ? 'Yes' : 'No',
        };
    });

/**
 * Get banner status on basis of  `effectiveFrom` and `effectiveTill` dates.
 *
 * @param   {number}  effectiveFrom
 * @param   {number}  effectiveTill
 * @return  {string}
 */
export const getBannerStatus = ({ effectiveFrom, effectiveTill }) => {
    if (isFuture(effectiveFrom)) {
        return BANNERS_STATUS.UPCOMING;
    } else if (isPast(effectiveTill)) {
        return BANNERS_STATUS.EXPIRED;
    } else if (isPast(effectiveFrom) && isFuture(effectiveTill)) {
        return BANNERS_STATUS.IN_PROGRESS;
    }
    return BANNERS_STATUS.UNKNOWN;
};

/**
 * Format banner data for update banner form.
 *
 * @param {Object}      banner
 * @return {Object}
 */
export const formatBannerFormData = banner => {
    const {
        active,
        category,
        dashboardVisibility,
        dismissible,
        displayText,
        effectiveFrom = new Date(),
        effectiveTill = new Date(),
    } = banner;

    return {
        banner: {
            category,
            displayText,
        },
        bannerStatus: {
            active: active === 'Yes',
            dismissible,
            dashboardVisibility,
        },
        from: {
            time: format(effectiveFrom, 'h:00'),
            meridian: format(effectiveFrom, 'A'),
            date: formatDate(effectiveFrom, 'MM/DD/YYYY'),
        },
        until: {
            time: format(effectiveTill, 'h:00'),
            meridian: format(effectiveTill, 'A'),
            date: formatDate(effectiveTill, 'MM/DD/YYYY'),
        },
    };
};

/**
 * Create payload for update banner API.
 *
 * @param {Object}      bannerDetails
 * @return {Object}
 */
export const getUpdateBannerPayload = bannerDetails => {
    const {
        banner: { category = '', displayText = '' },
        bannerStatus: { active, dismissible, dashboardVisibility },
        from,
        id,
        until,
        visibleToRoles,
        ...rest
    } = bannerDetails;

    return {
        active,
        category,
        dismissible,
        displayText,
        effectiveFrom: getTimeStampFromDateTimeMeridian(from),
        effectiveTill: getTimeStampFromDateTimeMeridian(until),
        id,
        visibleToRoles,
        dashboardVisibility,
        ...rest,
    };
};

/**
 * Create payload for create banner API.
 *
 * @param {Object}      bannerDetails
 * @return {Object}
 */
export const getCreateBannerPayload = bannerDetails => {
    const {
        banner: { category = '', displayText = '' },
        bannerStatus: { active, dismissible, dashboardVisibility },
        from,
        id,
        until,
        visibleToRoles,
    } = bannerDetails;

    return {
        active,
        category,
        dismissible: Boolean(dismissible),
        displayText,
        effectiveFrom: getTimeStampFromDateTimeMeridian(from),
        effectiveTill: getTimeStampFromDateTimeMeridian(until),
        id,
        visibleToRoles,
        dashboardVisibility: Boolean(dashboardVisibility),
    };
};

/**
 * Form validator for date fields.
 *
 * @method              formDateValidator
 * @param {Array}       dates           date fields values
 * @param {Array}       errorPaths      paths to form error object
 * @param {Object}      errors          form errors
 * @return {*}
 */
export const formDateValidator = (dates, errorPaths, errors) => {
    dates.forEach((date, idx) => {
        const formattedDate = new Date(date).toLocaleDateString();
        if (!isToday(formattedDate) && !isFuture(formattedDate)) {
            get(errors, errorPaths[idx]).addError('Invalid Date');
        }
    });
    return errors;
};

/**
 * Get payload for dismiss banner API.
 * @param {Object}        banner
 * @return {{requestData: *, objectId: *}}
 */
export const getDismissBannerPayload = banner => {
    const { internalId } = banner;
    return {
        objectId: internalId,
        requestData: JSON.stringify(banner),
    };
};
