import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const LoadingIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <g fill={fill}>
                <path d="M10 0C7.691 4.221 4.221 7.691 0 10c4.221 2.309 7.691 5.779 10 10 2.309-4.221 5.779-7.691 10-10-4.221-2.309-7.691-5.779-10-10z" />
                <path d="M10 0C7.691 4.221 4.221 7.691 0 10c4.221 2.309 7.691 5.779 10 10 2.309-4.221 5.779-7.691 10-10-4.221-2.309-7.691-5.779-10-10z" />
                <path d="M10 0C7.691 4.221 4.221 7.691 0 10c4.221 2.309 7.691 5.779 10 10 2.309-4.221 5.779-7.691 10-10-4.221-2.309-7.691-5.779-10-10z" />
                <path d="M10 0C7.691 4.221 4.221 7.691 0 10c4.221 2.309 7.691 5.779 10 10 2.309-4.221 5.779-7.691 10-10-4.221-2.309-7.691-5.779-10-10z" />
            </g>
        </SvgIcon>
    );
};

LoadingIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

LoadingIcon.defaultProps = {
    fill: DEFAULT_COLORS.ORANGE,
    height: '20',
    viewBox: '0 0 20 20',
    width: '20',
};

export default LoadingIcon;
