import { array, func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import chunk from 'lodash/chunk';
import styled from 'styled-components';

import Typography from 'ravenjs/lib/Typography';
import { isEmpty } from 'ravenjs/utils/lodash';

import { DEFAULT_COLORS } from 'constants/colors';
import { EVENTS } from 'constants/events';
import { TOPIC_STATE_ROUTE_MAP } from 'constants/navigation';
import { actions as pendoActions } from 'modules/pendo';
import { convertWagesAndHoursToWageAndHour } from 'utils/filters';
import { getTopicNavigationToProp } from 'utils/navigation';

const AppSubNavigationTopicsStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

const NavLinkStyled = styled(NavLink)`
    color: ${DEFAULT_COLORS.BLACK};
    :hover {
        color: ${DEFAULT_COLORS.LINK};
        text-decoration: none;
    }
`;

const TopicRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const TopicCol = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 15px 0 0;
`;

const linkStyle = {
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    marginBlockStart: '.1875rem',
    marginBlockEnd: '.1875rem',
    marginInlineStart: '0',
    marginInlineEnd: '0',
};

class AppSubNavigationTopics extends Component {
    static propTypes = {
        items: array,
        nav: object,
        sendPendoEvent: func.isRequired,
    };

    static defaultProps = {
        items: [],
        nav: {},
    };

    onClickNav = (mainState, item) => {
        const { sendPendoEvent } = this.props;
        const pendoEvent = EVENTS.NAVIGATION.SELECT;

        pendoEvent.eventProperties.selection = `${mainState}:${item.state}`;
        sendPendoEvent(pendoEvent);
    };

    renderItems = (items, state) => {
        const colSize = items.length % 2 === 0 ? items.length / 2 : 4;
        const chunks = chunk(items, colSize);
        if (chunks.length <= 1) {
            return (
                chunks.length === 1 && (
                    <TopicCol>
                        {chunks[0].map(item => {
                            return (
                                <NavLinkStyled
                                    key={item.label}
                                    to={getTopicNavigationToProp(state, item)}
                                    style={linkStyle}
                                    state={item.state}
                                    onClick={() => this.onClickNav(state, item)}
                                >
                                    {item.label}
                                </NavLinkStyled>
                            );
                        })}
                    </TopicCol>
                )
            );
        }

        return (
            <TopicRow>
                <TopicCol>
                    {chunks[0].map(item => (
                        <NavLinkStyled
                            key={item.label}
                            to={getTopicNavigationToProp(state, item)}
                            style={linkStyle}
                            activeStyle={linkStyle}
                            state={item.state}
                            onClick={() => this.onClickNav(state, item)}
                        >
                            {item.label}
                        </NavLinkStyled>
                    ))}
                </TopicCol>
                <TopicCol>
                    {chunks[1].map(item => (
                        <NavLinkStyled
                            key={item.label}
                            to={getTopicNavigationToProp(state, item)}
                            style={linkStyle}
                            activeStyle={linkStyle}
                            state={item.state}
                            onClick={() => this.onClickNav(state, item)}
                        >
                            {item.label}
                        </NavLinkStyled>
                    ))}
                </TopicCol>
            </TopicRow>
        );
    };

    render() {
        const { items, nav } = this.props;
        const { state } = nav;
        const topicName = !isEmpty(TOPIC_STATE_ROUTE_MAP[state])
            ? TOPIC_STATE_ROUTE_MAP[state]
            : 'Topics';

        convertWagesAndHoursToWageAndHour(items, 'label');

        return (
            !isEmpty(items) &&
            items.length > 0 && (
                <AppSubNavigationTopicsStyled>
                    <Typography fontFamily="Roboto" fontWeight="bold" fontSize="1rem">
                        {topicName}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {this.renderItems(items, state)}
                    </div>
                </AppSubNavigationTopicsStyled>
            )
        );
    }
}

const mapDispatchToProps = {
    sendPendoEvent: pendoActions.sendPendoEvent,
};

export { AppSubNavigationTopics as AppSubNavigationTopicsUnwrapped };
export default connect(null, mapDispatchToProps)(AppSubNavigationTopics);
