import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const AlertAndNotificationIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M32.154,54.496H7.95
              c-1.239,0-2.368-0.706-2.795-1.872c-0.427-1.164-0.053-2.472,0.924-3.235c4.724-3.499,7.536-9.09,7.45-15.062v-8.612
              c0-10.289,8.339-18.628,18.626-18.628c10.286,0,18.628,8.339,18.628,18.628v8.612c-0.086,5.972,2.725,11.563,7.448,15.062
              c0.979,0.764,1.353,2.071,0.926,3.235c-0.428,1.166-1.557,1.872-2.798,1.872H32.154z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M22.795,54.498h18.717
              c-0.464,4.815-4.515,8.496-9.358,8.496C27.312,62.994,23.264,59.313,22.795,54.498"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FF4C00"
                d="M32.154,54.496h-20.32
              c-1.239,0-2.371-0.706-2.798-1.872c-0.427-1.162-0.053-2.472,0.926-3.235c4.724-3.499,7.043-9.09,6.958-15.062v-8.083
              c0-5.005,1.058-9.08,3.322-11.937c4.476-5.652,13.91-7.622,21.271-4.411c5.646,2.464,9.27,8.409,9.27,15.819v8.612
              c-0.086,5.972,2.725,11.563,7.448,15.062c0.979,0.764,1.353,2.071,0.926,3.235c-0.428,1.166-1.557,1.872-2.798,1.872H32.154z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FF4C00"
                d="M26.091,54.498h15.421
              c-0.382,4.815-3.719,8.496-7.71,8.496C29.813,62.994,26.476,59.313,26.091,54.498"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M7.95,53.486h48.409c0.432,0,0.839-0.122,1.166-0.34
              c0.308-0.205,0.554-0.5,0.688-0.868c0.137-0.375,0.145-0.777,0.039-1.146c-0.104-0.364-0.317-0.696-0.625-0.938
              c-2.496-1.855-4.49-4.265-5.841-6.992c-1.326-2.686-2.038-5.689-2.01-8.792l-0.002-8.695c0-4.865-1.974-9.271-5.16-12.46
              c-3.188-3.186-7.593-5.16-12.459-5.16c-4.846,0-9.232,1.953-12.415,5.113l-0.042,0.047c-3.186,3.188-5.162,7.595-5.162,12.46
              v8.612c0.039,3.131-0.671,6.165-2.012,8.875c-1.349,2.728-3.343,5.137-5.841,6.992c-0.305,0.242-0.521,0.574-0.625,0.938
              c-0.102,0.355-0.098,0.748,0.027,1.11l0.014,0.035c0.134,0.368,0.38,0.663,0.688,0.868C7.112,53.364,7.517,53.486,7.95,53.486
              M32.154,55.505H7.95c-0.826,0-1.624-0.244-2.285-0.688c-0.651-0.436-1.17-1.066-1.457-1.846L4.19,52.917
              c-0.269-0.771-0.283-1.587-0.069-2.338c0.218-0.763,0.675-1.463,1.338-1.981l0.022-0.015c2.236-1.66,4.024-3.82,5.237-6.272
              c1.205-2.438,1.843-5.164,1.805-7.974l-0.004-8.622c0-5.42,2.199-10.332,5.751-13.884l0.057-0.053
              c3.55-3.521,8.437-5.699,13.826-5.699c5.42,0,10.331,2.197,13.884,5.752c3.552,3.552,5.753,8.463,5.753,13.884l-0.006,8.718
              c-0.022,2.777,0.614,5.469,1.807,7.878c1.211,2.452,3.001,4.612,5.237,6.272l0.042,0.033c0.651,0.515,1.103,1.206,1.318,1.963
              c0.218,0.767,0.197,1.604-0.089,2.389c-0.285,0.783-0.806,1.414-1.459,1.85c-0.661,0.443-1.461,0.688-2.283,0.688H32.154z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M40.331,55.505H23.978c0.395,1.692,1.298,3.18,2.535,4.301
              c1.493,1.357,3.479,2.18,5.642,2.18c2.165,0,4.15-0.822,5.644-2.18C39.033,58.687,39.937,57.197,40.331,55.505 M22.795,53.488
              l18.717-0.002c0.558,0,1.01,0.452,1.01,1.012l-0.017,0.187c-0.278,2.631-1.52,4.948-3.353,6.612
              c-1.857,1.685-4.319,2.706-6.999,2.706c-2.675,0-5.141-1.02-6.997-2.706c-1.854-1.685-3.107-4.036-3.363-6.706
              c-0.051-0.553,0.354-1.043,0.906-1.096L22.795,53.488z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M30.492,7.086c0,0.557-0.452,1.009-1.009,1.009
              c-0.555,0-1.009-0.452-1.009-1.009l0.006-3.457c0.008-0.625,0.173-1.227,0.468-1.754c0.313-0.555,0.777-1.035,1.349-1.373
              c0.309-0.179,0.637-0.312,0.97-0.395l0.071-0.017c0.317-0.069,0.645-0.102,0.966-0.087c0.301,0.012,0.598,0.061,0.881,0.145
              l0.058,0.018C33.51,0.25,33.768,0.362,34.008,0.5c0.573,0.332,1.035,0.813,1.351,1.377c0.31,0.549,0.479,1.19,0.471,1.847
              l0.004,3.361c0,0.557-0.452,1.009-1.01,1.009c-0.555,0-1.007-0.452-1.007-1.009l0.004-3.375c0.004-0.299-0.075-0.594-0.22-0.851
              c-0.14-0.25-0.348-0.47-0.606-0.621c-0.108-0.063-0.224-0.116-0.338-0.15l-0.037-0.01c-0.134-0.039-0.269-0.063-0.393-0.067
              c-0.143-0.006-0.293,0.008-0.436,0.041l-0.037,0.008c-0.156,0.039-0.307,0.1-0.445,0.179c-0.254,0.149-0.462,0.366-0.604,0.621
              c-0.134,0.236-0.209,0.502-0.217,0.777L30.492,7.086z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M12.356,0.303c0.439-0.342,1.074-0.263,1.414,0.177
              c0.342,0.439,0.263,1.072-0.177,1.414c-3.654,2.844-6.551,6.45-8.529,10.494c-1.978,4.043-3.043,8.541-3.043,13.174
              c0,0.558-0.454,1.009-1.009,1.009c-0.558,0-1.011-0.452-1.011-1.009c0-4.932,1.144-9.729,3.259-14.059
              C5.374,7.18,8.465,3.329,12.356,0.303"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M50.412,1.896c-0.439-0.34-0.519-0.975-0.177-1.414
              c0.339-0.439,0.974-0.519,1.413-0.177c3.893,3.025,6.98,6.875,9.097,11.2c2.116,4.325,3.257,9.127,3.257,14.057
              c0,0.558-0.451,1.009-1.009,1.009c-0.556,0-1.009-0.452-1.009-1.009c0-4.63-1.066-9.131-3.044-13.174
              C56.961,8.344,54.063,4.738,50.412,1.896"
            />
        </SvgIcon>
    );
};

AlertAndNotificationIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

AlertAndNotificationIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default AlertAndNotificationIcon;
