import { combineReducers } from 'redux';

import { reducer as cases } from './cases';
import { reducer as idle } from './idle';
import { reducer as loading } from './loading';
import { reducer as modals } from './modals';
import { reducer as navigation } from './navigation';
import { reducer as featureFlags } from './featureFlags';

// Create and export the 'ui' reducer,
// that is a combination of internal nested reducers.
// NOTE: The default export will be the reducer.
export default combineReducers({
    cases,
    idle,
    loading,
    modals,
    navigation,
    featureFlags,
});

// Create and export named 'ui' selectors.
// NOTE: The rest of the 'named' exports will be the selectors, for this module.
