import { call, put } from 'redux-saga/effects';

import { fetchSecretsByGroupIdAPI, createSecretByGroupIdAPI } from 'modules/apis';
import * as webhookActions from '../actions';

/**
 * Fetch Secrets by Group ID
 *
 * @method fetchSecretsByGroupIdSaga
 * @type {Generator}
 * @param {Object} action - The redux aciotn
 * @return {Object}
 */
export function* fetchSecretsByGroupIdSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchSecretsByGroupIdAPI, payload);
        yield put(webhookActions.fetchSecretsByGroupId.success(response));
        return response;
    } catch (error) {
        yield put(webhookActions.fetchSecretsByGroupId.error(error));
        return error;
    }
}

/**
 * Create Secret by Group ID
 *
 * @method createSecretByGroupIdSaga
 * @type {Generator}
 * @param {Object} action - The redux aciotn
 * @return {Object}
 */
export function* createSecretByGroupIdSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createSecretByGroupIdAPI, payload);
        yield put(webhookActions.createSecretByGroupId.success(response));
        return response;
    } catch (error) {
        yield put(webhookActions.createSecretByGroupId.error(error));
        return error;
    }
}
