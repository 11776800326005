import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { DEFAULT_COLORS } from 'constants/colors';

import SvgIcon from '../SvgIcon';

const ArrowUpsideDownIcon = ({ fill, ...rest }) => {
    const fillColor = _get(rest, 'extraCss.fill', fill);
    return (
        <SvgIcon {...rest}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" fill={fillColor} fillRule="nonzero">
                    <g id="path">
                        <polygon
                            id="Path-Poly"
                            points="5.76 9.515 3.773 11.44 3.773 0.379 2.727 0.379 2.727 11.44 0.74 9.515 0 10.231 3.25 13.379 6.5 10.231"
                        />
                    </g>
                    <g
                        id="path-copy"
                        transform="translate(14.500000, 7.000000) rotate(-180.000000) translate(-14.500000, -7.000000) translate(11.000000, 0.000000)"
                    >
                        <polygon
                            id="Path-Copy-Poly"
                            points="5.76 9.515 3.773 11.44 3.773 0.379 2.727 0.379 2.727 11.44 0.74 9.515 0 10.231 3.25 13.379 6.5 10.231"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ArrowUpsideDownIcon.propTypes = {
    fill: PropTypes.string,
    viewBox: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

ArrowUpsideDownIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 18 15',
    height: '14px',
    width: '14px',
};

export default ArrowUpsideDownIcon;
