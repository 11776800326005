import { array, oneOfType, string } from 'prop-types';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import setDisplayName from 'recompose/setDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';

function accessControlWrapper(Acl) {
    function accessControl(WrappedComponent) {
        function AccessControl(props) {
            const { allowedroles, permissions } = props;

            if (!_isEmpty(permissions)) {
                const allowed = Acl.check(permissions);
                return allowed ? <WrappedComponent {...props} /> : null;
            }
            if (!_isEmpty(allowedroles)) {
                const allowed = Acl.checkLoggedInUserRole(allowedroles);
                return allowed ? <WrappedComponent {...props} /> : null;
            }
            return <WrappedComponent {...props} />;
        }

        AccessControl.propTypes = {
            allowedroles: array,
            permissions: oneOfType([array, string]),
        };

        AccessControl.defaultProps = {
            allowedroles: [],
            permissions: null,
        };

        setDisplayName(wrapDisplayName(WrappedComponent, 'accessControl'))(AccessControl);

        return AccessControl;
    }

    return accessControl;
}

export default accessControlWrapper;
