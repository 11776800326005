import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ArrowUpIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            {...rest}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.612 11.707a1.036 1.036 0 0 1 0-1.414l5.715-6a.92.92 0 0 1 1.346 0l5.715 6c.372.39.372 1.024 0 1.414a.92.92 0 0 1-1.347 0L8 6.414l-5.04 5.293a.92.92 0 0 1-1.348 0z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ArrowUpIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ArrowUpIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK_00,
    height: '16',
    viewBox: '0 0 16 16',
    width: '16',
};

export default ArrowUpIcon;
