import { get } from 'ravenjs/utils/lodash';

/**
 * Get expiresIn timestamp from the decoded token
 *
 * @param   {Object}    decodedToken
 * @return  {number}
 */
export const getExpiresInTimestamp = decodedToken =>
    get(decodedToken, 'exp', new Date().getTime()) * 1000;

/**
 * Get userId from the decoded token
 *
 * @param   {Object}    decodedToken
 * @return  {number}
 */
export const getTokenUserId = decodedToken => get(decodedToken, 'sub', null);

export const isTokenExpired = token => {
    let isExpired = true;
    if (token) {
        try {
            isExpired = JSON.parse(window.atob(token.split('.')[1])).exp * 1000 < Date.now();
        } catch (e) {
            isExpired = true;
        }
    }
    return isExpired;
};
