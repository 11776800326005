/**
 * Action Stage - Trigger
 *
 * @type {String}
 */
export const TRIGGER = 'trigger';

/**
 * Action Stage - Request
 *
 * @type {String}
 */
export const REQUEST = 'request';

/**
 * Action Stage - Success
 *
 * @type {String}
 */
export const SUCCESS = 'success';

/**
 * Action Stage - Error
 *
 * @type {String}
 */
export const ERROR = 'error';

/**
 * Action Stage - Fulfill
 *
 * @type {String}
 */
export const FULFILL = 'fulfill';

/**
 * Action Stage - Abort
 *
 * @type {String}
 */
export const ABORT = 'abort';

/**
 * The different action stages of a single redux-action
 *
 * @type {Array}
 */
export const ACTION_STAGES = [TRIGGER, REQUEST, SUCCESS, ERROR, ABORT, FULFILL];

/**
 * Filter the action stages, by removing all the standard stages, SUCCESS, ERROR, and ABORT.
 * This will build a list of non-standard action stages.
 *
 * @type {Array}
 */
export const NON_STANDARD_ACTION_STAGES = [TRIGGER, REQUEST, FULFILL];
