import { createActions } from 'utils/actions';

/**
 * Redux actions for the bootstrap fetchConfig flow.
 *
 * @type {Object}
 */
export const fetchAppBootstrap = createActions('bootstrap', 'FETCH_APP_BOOTSTRAP');

export const fetchDowntimeInfo = createActions('bootstrap', 'FETCH_DOWNTIME_INFO', {
    asPromise: true,
});
