import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeChartsIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path
                            fill={color}
                            d="M2.275 1.099H4.62V12.432H2.275zM6.23 4.13H8.575000000000001V12.431999999999999H6.23zM10.332 7.147H12.677000000000001V12.432H10.332z"
                        />
                        <path
                            fill={colorInner}
                            d="M0 14.35L0 0 0.7 0 0.7 13.65 14.35 13.601 14.35 14.301z"
                        />
                        <path
                            fill={colorInner}
                            d="M3.507 9.646L3.073 9.1 7.28 5.719 9.317 6.797 12.39 2.576 12.957 2.989 9.527 7.7 7.357 6.552z"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeChartsIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeChartsIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeChartsIcon;
