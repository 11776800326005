import axios from 'axios';

/**
 * API to create session.
 *
 * @method createAISessionAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const createAISessionAPI = data =>
    axios({
        method: 'post',
        url: '/chat/sessions',
        data,
        type: 'aiAssistant',
    });

/**
 * API to send message.
 *
 * @method sendMessageAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const sendMessageAPI = data => {
    const { chatId } = data;
    return axios({
        method: 'post',
        url: `/chat/sessions/${chatId}/messages`,
        data,
        type: 'aiAssistant',
    });
};

/**
 * API to get status answer.
 *
 * @method getAnswerAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const getAnswerAPI = data => {
    const { chatId, messageId } = data;
    return axios({
        method: 'get',
        url: `/chat/sessions/${chatId}/messages/${messageId}`,
        data,
        type: 'aiAssistant',
    });
};

/**
 * API to create feedback.
 *
 * @method createFeedbackAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const createFeedbackAPI = data => {
    return axios({
        method: 'post',
        url: `/chat/feedback`,
        data,
        type: 'aiAssistant',
    });
};

/**
 *  Close Session.
 *
 * @param {Object}    sessionId
 * @return {Promise}
 */
export const closeAISessionAPI = sessionId =>
    axios({
        method: 'delete',
        url: `/chat/sessions/${sessionId}`,
        type: 'aiAssistant',
    });

/**
 * Send email in UI when user logs out.
 *
 * @method sendEmailCloseAISession
 * @param  {Object}  data
 * @return {Promise}
 */
export const sendEmailCloseAISession = data => {
    return axios({
        method: 'post',
        url: `/v2/users/send-email`,
        data,
    });
};

/**
 *  Close all AISession API the user.
 *
 * @param {Object}    userId
 * @return {Promise}
 */
export const closeAllAISessionAPI = userId =>
    axios({
        method: 'delete',
        url: `/chat/sessions?userId=${userId}`,
        type: 'aiAssistant',
    });

/**
 * API to get hitory chat.
 *
 * @method getHistoryChatAPI
 * @param  {string}  chatSessionId
 * @return {Promise}
 */
export const getHistoryChatAPI = chatSessionId => {
    return axios({
        method: 'get',
        url: `chat/sessions/${chatSessionId}/details`,
        type: 'aiAssistant',
    });
};

/**
 * API to get chat session details.
 *
 * @method getChatSessionDetailsAPI
 * @param  {string}  chatSessionId
 * @return {Promise}
 */
export const getChatSessionDetailsAPI = chatSessionId => {
    return axios({
        method: 'get',
        url: `chat/sessions/${chatSessionId}/details`,
        type: 'aiAssistant',
    });
};
