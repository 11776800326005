import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Divider from 'ravenjs/lib/Divider';
import Tooltip from 'ravenjs/lib/Tooltip';
import TooltipContent from 'ravenjs/lib/TooltipContent';
import InfoIcon from 'ravenjs/lib/SvgIcon/types/InfoIcon';
import { getThemeProps } from 'ravenjs/utils/theme';
import FormGroupTitle from 'ravenjs/lib/Form/fields/FormGroupTitle';
import CheckboxNew from 'ravenjs/lib/CheckboxNew';
import CheckboxFilledIconNew from 'ravenjs/lib/SvgIcon/types/CheckboxFilledIconNew';
import CheckboxEmptyIconNew from 'ravenjs/lib/SvgIcon/types/CheckboxEmptyIconNew';
import Typography from 'ravenjs/lib/Typography';

const CheckboxWidgetStyled = styled.div`
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('CheckboxWidget.styles')};
`;

const TooltipContentStyled = styled(TooltipContent)`
    height: auto;
    padding: 0.5rem;
    width: 224px;
`;

const LabelStyled = styled.label`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    line-height: 0.875rem;
    margin: 0;

    input {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
        margin-right: 0.625rem;
    }

    span {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
    }
`;

const CheckboxStyled = styled(CheckboxNew)`
    margin-right: 0.625rem;
`;

function CheckboxWidget(props) {
    const { id, value, required, disabled, readonly, label, onChange, options, schema } = props;
    const { divider, className, title, tooltipText, infoIcon, forceRemoveMargin } = options;
    const renderDivider = typeof divider === 'boolean' ? divider : !_isEmpty(divider);
    const renderInfoIcon = !_isEmpty(tooltipText);
    const inputClassName = _get(schema, 'inputClassName');

    const getTooltipContent = content => {
        return (
            <TooltipContentStyled
                text={content}
                bodyStyle={{
                    borderRadius: '0.5rem',
                    lineHeight: '1rem',
                    padding: '0.5rem',
                }}
            />
        );
    };

    return (
        <>
            {title && <FormGroupTitle title={title} />}
            <CheckboxWidgetStyled className={className} disabled={disabled || readonly}>
                <LabelStyled
                    htmlFor={id}
                    disabled={disabled || readonly}
                    hasInfoIcon={renderInfoIcon}
                >
                    <CheckboxStyled
                        checked={typeof value === 'undefined' ? false : value}
                        type="checkbox"
                        className={inputClassName}
                        icon={CheckboxEmptyIconNew}
                        checkedIcon={CheckboxFilledIconNew}
                        isDisabled={disabled || readonly}
                        id={id}
                        onChange={event => onChange && onChange(event.target.checked)}
                        required={required}
                    />
                    <Typography
                        type="label"
                        gutterBottom={`0${forceRemoveMargin ? ' !important' : ''}`}
                        gutterTop="0"
                        fontSize="0.875rem"
                    >
                        {label}
                    </Typography>
                    {renderInfoIcon && (
                        <Tooltip
                            content={getTooltipContent(tooltipText)}
                            padding="0"
                            margin={0}
                            arrowSize={0}
                            color="dark"
                            placement="top"
                            hasFlip={false}
                        >
                            {infoIcon}
                        </Tooltip>
                    )}
                </LabelStyled>
                {renderDivider && <Divider {...divider} />}
            </CheckboxWidgetStyled>
        </>
    );
}

CheckboxWidget.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.shape({
        divider: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        className: PropTypes.string,
        title: PropTypes.string,
        tooltipText: PropTypes.string,
        infoIcon: PropTypes.object,
        forceRemoveMargin: PropTypes.bool,
    }),
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    schema: PropTypes.object,
    value: PropTypes.bool,
};

CheckboxWidget.defaultProps = {
    disabled: false,
    label: '',
    onChange: null,
    options: {
        infoIcon: <InfoIcon style={{ margin: '0 0.5rem' }} />,
    },
    readonly: false,
    required: false,
    schema: {},
    value: false,
};

export default CheckboxWidget;
