import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { put, select } from 'redux-saga/effects';

import * as uiSelectors from 'modules/ui/modals/reducer';

/**
 * The saga for handling the ui modal state for adding modals.
 *
 * @method addModalSaga
 * @type  {Generator}
 * @param {Object}      dispatcher The set of dispatcher actions
 * @param {Object}      action     The original redux-action
 */
export function* addModalSaga(dispatcher = {}, action = {}) {
    try {
        // Extract the `action.payload` as the list of modals.
        let modalsList = _get(action, 'payload');
        // Convert the `modalsList` into an Array if it isn't already one.
        if (!Array.isArray(modalsList)) {
            modalsList = [modalsList];
        }
        // Go through the `modalsList` and convert it into a list of objects,
        // with the modal `id` being the `name` that's getting passed in,
        // and its `open` value set to false.
        modalsList = modalsList.map(modalId => ({ id: modalId, open: false, data: {} }));
        // Convert the list of modals into a dictionary with `modal.id` as the key.
        const modalsDictionary = _keyBy(modalsList, 'id');
        // Dispatch the success action with the updated modals list object.
        yield put(dispatcher.success(modalsDictionary));
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(dispatcher.error(error));
    }
}

/**
 * The saga for handling the ui modal state for closing modals.
 *
 * @method closeModalSaga
 * @type  {Generator}
 * @param {Object}        dispatcher The set of dispatcher actions
 * @param {Object}        action     The original redux-action
 */
export function* closeModalSaga(dispatcher = {}, action = {}) {
    try {
        // Extract the `action.payload` as the `modalId`.
        const modalId = _get(action, 'payload');
        // Find the modal from the current state, via the `modalId`.
        const modal = yield select(uiSelectors.getModalById(modalId));
        // Dispatch the success action with the updated modal,
        // and its `open` prop turned to `false`.
        yield put(
            dispatcher.success({
                [modalId]: {
                    ...modal,
                    open: false,
                },
            })
        );
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(dispatcher.error(error));
    }
}

/**
 * The saga for handling the ui modal state for opening modals.
 *
 * @method openModalSaga
 * @type  {Generator}
 * @param {Object}        dispatcher The set of dispatcher actions
 * @param {Object}        action     The original redux-action
 */
export function* openModalSaga(dispatcher = {}, action = {}) {
    try {
        // Extract the `modalId` and `modalData` from the payload.
        const modalId = _get(action, 'payload.id');
        const modalData = _get(action, 'payload.data');
        // Dispatch the success action with the `modalId` and `modalData`.
        yield put(dispatcher.success(modalId, modalData));
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(dispatcher.error(error));
    }
}

/**
 * The saga for handling the ui modal state for updating modals.
 *
 * @method updateModalSaga
 * @type  {Generator}
 * @param {Object}        dispatcher The set of dispatcher actions
 * @param {Object}        action     The original redux-action
 */
export function* updateModalSaga(dispatcher = {}, action = {}) {
    try {
        const modalId = _get(action, 'payload.id');
        const modalData = _get(action, 'payload.data');
        yield put(dispatcher.success(modalId, modalData));
    } catch (error) {
        yield put(dispatcher.error(error));
    }
}
