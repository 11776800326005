/**
 * Banners status text map .
 *
 * @type {{ IN_PROGRESS: string, UPCOMING: string, EXPIRED: string, UNKNOWN: string }}
 */
export const BANNERS_STATUS = {
    EXPIRED: 'Expired',
    IN_PROGRESS: 'In Progress',
    UPCOMING: 'Upcoming',
    UNKNOWN: 'Unknown',
};

/**
 * Type of product banners
 * @type {{ASK_A_PRO: string, HOMEPAGE: string, HANDBOOKS: string, LEARN: string}}
 */
export const PRODUCT_BANNER_TYPES = {
    HOMEPAGE: 'Homepage',
    LEARN: 'Learn',
    ASK_A_PRO: 'Ask a Pro',
    HANDBOOKS: 'Handbooks',
};
