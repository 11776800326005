import { string } from 'prop-types';
import React from 'react';

import { DEFAULT_COLORS } from 'constants/colors';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const PointerIcon = ({ ...rest }) => (
    <SvgIcon {...rest}>
        <defs>
            <filter
                id="prefix__a"
                width="141.3%"
                height="200%"
                x="-20.6%"
                y="-25%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                    stdDeviation="1"
                />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161631337 0"
                />
            </filter>
            <path id="prefix__b" d="M34.814 29.024L44.353 37.024 54.186 29.024z" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(-33 -29)">
            <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
            <use fill={DEFAULT_COLORS.LIGHTEST} xlinkHref="#prefix__b" />
        </g>
    </SvgIcon>
);

PointerIcon.propTypes = {
    viewBox: string,
    width: string,
    height: string,
};

PointerIcon.defaultProps = {
    height: '12',
    viewBox: '0 0 23 12',
    width: '23',
};

export default PointerIcon;
