import { bool, func, object, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Switch from 'ravenjs/lib/Switch';
import Typography from 'ravenjs/lib/Typography';
import TooltipContent from 'ravenjs/lib/TooltipContent';
import Tooltip from 'ravenjs/lib/Tooltip';

import { DEFAULT_COLORS } from 'constants/colors';
import InfoIcon from 'ravenjs/lib/SvgIcon/types/InfoIcon';

const WrapperStyled = styled.div`
    display: flex;
    margin: auto 0;
    justify-content: flex-end;
    ${({ styles }) => styles}
`;

const ToggleSwitch = props => {
    const { checked, infoText, showLabel, enableText, disableText, wrapperStyles } = props;
    return (
        <WrapperStyled styles={wrapperStyles}>
            {showLabel && (
                <Typography gutterTop="auto" gutterBottom="auto" gutterRight="0.938rem">
                    {checked ? enableText : disableText}

                    {infoText && (
                        <Tooltip
                            content={
                                <TooltipContent
                                    text={infoText}
                                    bodyStyle={{
                                        borderRadius: '8px',
                                        lineHeight: '16px',
                                        padding: '8px',
                                    }}
                                />
                            }
                            padding="0"
                            margin={0}
                            arrowSize={0}
                            color="dark"
                            placement="top"
                            hasFlip={false}
                        >
                            <InfoIcon
                                extraCss={{ verticalAlign: 'text-bottom' }}
                                margin="auto 0"
                                height="18px"
                                width="30px"
                            />
                        </Tooltip>
                    )}
                </Typography>
            )}
            <Switch
                activeBarColor={DEFAULT_COLORS.GREEN}
                activeIconColor={DEFAULT_COLORS.WHITE}
                inactiveBarColor={DEFAULT_COLORS.GREY_4}
                inactiveIconColor={DEFAULT_COLORS.WHITE}
                {...props}
            />
        </WrapperStyled>
    );
};

ToggleSwitch.propTypes = {
    checked: bool,
    onChange: func,
    disableText: string,
    infoText: string,
    enableText: string,
    showLabel: bool,
    wrapperStyles: object,
};

ToggleSwitch.defaultProps = {
    checked: false,
    onChange: null,
    disableText: 'Disabled',
    infoText: null,
    enableText: 'Enabled',
    showLabel: false,
    wrapperStyles: {},
};

export default ToggleSwitch;
