import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Col from 'ravenjs/lib/Col';
import Input from 'ravenjs/lib/Input';
import Row from 'ravenjs/lib/Row';
import FaIcon from 'ravenjs/lib/FaIcon';
import { get, isEmpty } from 'ravenjs/utils';
import { COLORS } from 'ravenjs/constants/colors';
import { faDollarSign, faPercent } from '@fortawesome/free-solid-svg-icons';

const ContainerInput = styled.div`
    width: 100%;
    position: relative;
`;

const InputField = styled(Input)`
    width: 100%;
    padding-left: ${({ hideInnerIcon }) => (hideInnerIcon ? '8px' : '45px')};
`;

const InputFieldIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 38px;
    height: calc(100% - 2px);
    background-color: ${COLORS.GREY_4};
    border-right: 1px solid ${COLORS.GREY};
`;

const IconContainer = styled(FaIcon)`
    path {
        fill: ${COLORS.GREY};
    }
`;

class InputCustomizableField extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        formData: PropTypes.string,
        idSchema: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        registry: PropTypes.object.isRequired,
        required: PropTypes.bool,
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object.isRequired,
    };

    static defaultProps = {
        disabled: false,
        required: null,
        formData: '',
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.onInputChange = this.onInputChange.bind(this);
        const uiOptions = get(props.uiSchema, 'ui:options');
        const inputIcon = uiOptions.iconType === 'faPercent' ? faPercent : faDollarSign;

        this.state = { inputIcon };
    }

    _getUiOptions = schema => get(schema, 'ui:options', {});

    onInputChange = ({ target: { value } }) => {
        const { onChange } = this.props;
        onChange(value === '' ? '' : value);
    };

    render() {
        const {
            disabled,
            idSchema,
            formData,
            name,
            registry,
            required,
            schema,
            uiSchema,
        } = this.props;
        const { inputIcon } = this.state;
        const title = get(schema, 'title') || name;
        const hideRequired = get(schema, 'hideRequired', false);
        const helpText = get(schema, 'helpText', {});
        const TitleField = get(registry, 'fields.FormGroupTitleField');
        const uiOptions = get(uiSchema, 'ui:options');
        const hideInnerIcon = get(uiOptions, 'hideInnerIcon', false);
        const { $id: id } = idSchema;

        return (
            <>
                <Row width="auto">
                    <Col size={12}>
                        <TitleField
                            id={id}
                            helpText={helpText}
                            hideRequired={hideRequired}
                            title={title}
                            required={required && isEmpty(formData)}
                            uiOptions={uiOptions}
                        />
                    </Col>
                    <Col style={{ position: 'relative' }}>
                        <ContainerInput>
                            {!hideInnerIcon && (
                                <InputFieldIcon>
                                    <IconContainer width="24" height="24" icon={inputIcon} />
                                </InputFieldIcon>
                            )}
                            <InputField
                                hideInnerIcon={hideInnerIcon}
                                disabled={disabled}
                                required={required}
                                type="text"
                                ref={this.inputRef}
                                value={formData === null ? '' : formData}
                                onChange={this.onInputChange}
                            />
                        </ContainerInput>
                    </Col>
                </Row>
            </>
        );
    }
}

export default InputCustomizableField;
