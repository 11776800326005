import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AddCircleIcon = ({ color, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path
                fill={color}
                d="M7.5 0C3.364 0 0 3.364 0 7.5S3.364 15 7.5 15 15 11.636 15 7.5 11.636 0 7.5 0zm3.281 8.125H8.125v2.656c0 .345-.28.625-.625.625s-.625-.28-.625-.625V8.125H4.219c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h2.656V4.219c0-.345.28-.625.625-.625s.625.28.625.625v2.656h2.656c.345 0 .625.28.625.625s-.28.625-.625.625z"
            />
        </SvgIcon>
    );
};

AddCircleIcon.propTypes = {
    color: string,
    height: string,
    viewBox: string,
    width: string,
};

AddCircleIcon.defaultProps = {
    color: DEFAULT_COLORS.BLACK_3,
    height: '16',
    viewBox: '0 0 16 16',
    width: '16',
};

export default AddCircleIcon;
