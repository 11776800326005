import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    createAISessionAPI,
    sendMessageAPI,
    getAnswerAPI,
    createFeedbackAPI,
    closeAISessionAPI,
    sendEmailCloseAISession,
    getHistoryChatAPI,
    getChatSessionDetailsAPI,
} from 'modules/apis';
import * as aiAssistantActions from '../actions';

/**
 * Function for create session
 *
 * @method createAISessionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* createAISessionSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(createAISessionAPI, params);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.createAISession.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.createAISession.error(error));
        return error;
    }
}

/**
 * Function for send message
 *
 * @method sendMessageSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* sendMessageSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(sendMessageAPI, params);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.sendMessage.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.sendMessage.error(error));
        return error;
    }
}

/**
 * Function for get answer
 *
 * @method getAnswerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* getAnswerSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(getAnswerAPI, params);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.getAnswer.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.getAnswer.error(error));
        return error;
    }
}

/**
 * Function for create feedback
 *
 * @method createFeedbackSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* createFeedbackSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(createFeedbackAPI, params);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.createFeedback.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.createFeedback.error(error));
        return error;
    }
}

/**
 * Function for close session
 *
 * @method closeAISessionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* closeAISessionSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(closeAISessionAPI, params.chatSessionId);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.closeAISession.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.closeAISession.error(error));
        return error;
    }
}

/**
 * Function for send email
 *
 * @method sendEmailCloseAISessionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* sendEmailCloseAISessionSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(sendEmailCloseAISession, params);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.sendEmailCloseAISession.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.sendEmailCloseAISession.error(error));
        return error;
    }
}

/**
 * Function for history chat
 *
 * @method getHistoryChatSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* getHistoryChatSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(getHistoryChatAPI, params.chatSessionId);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.getHistoryChat.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.getHistoryChat.error(error));
        return error;
    }
}

/**
 * Saga for chat session details
 *
 * @method getChatSessionDetailsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* getChatSessionDetailsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(getChatSessionDetailsAPI, params.chatSessionId);
        const results = get(response, 'data', {});
        yield put(aiAssistantActions.getChatSessionDetails.success(results));
        return results;
    } catch (error) {
        yield put(aiAssistantActions.getChatSessionDetails.error(error));
        return error;
    }
}
