import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import _join from 'lodash/join';

/**
 * API call to fetch info about the given partner, via `partnerId`.
 *
 * @method fetchPartnerByIdAPI
 * @param  {number}           partnerId The partner to fetch
 * @return {Promise}
 */
export const fetchPartnerByIdAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/partners/${partnerId}`,
    });
};

/**
 * API call to fetch available SKUs for partner's master configuration
 *
 * @param   {number}    partnerId
 * @param   {Array}     skus
 * @return  {Promise}
 */
export const fetchMasterConfigSkusForPartner = (partnerId, skus = []) =>
    axios({
        method: 'get',
        url: `/v2/partners/${partnerId}/companySKUs`,
        params: {
            skus: _join(skus, ','),
        },
    });

/**
 * API to fetch partner settings
 *
 * @method fetchPartnerSettingsAPI
 * @param  {Object}  partnerId
 * @return {Promise}
 */
export const fetchPartnerSettingsAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/partner-settings/${partnerId}`,
    });
};

/**
 * API fetch list of sent communications for a partner
 *
 * @param  {number|string}  partnerId
 * @param  {Object}         params
 * @method fetchPartnerSentCommunicationsAPI
 * @return {Promise}
 */
export const fetchPartnerSentCommunicationsAPI = (partnerId, params) =>
    axios({
        method: 'get',
        params,
        url: `/v2/partners/${partnerId}/communications`,
    });

/**
 * API fetch list of upcoming communications for a partner
 *
 * @param  {number|string}  partnerId
 * @param  {boolean}  isSendCommunication
 * @param  {string}  sort
 * @method fetchPartnerUpcomingCommunicationsAPI
 * @return {Promise}
 */
export const fetchPartnerUpcomingCommunicationsAPI = ({
    partnerId,
    isSendCommunication = false,
    ...rest
}) =>
    axios({
        method: 'get',
        url: `/v2/partners/${partnerId}/communications`,
        params: {
            isSendCommunication,
            ...rest,
        },
    });

/**
 * API to update partner self settings.
 *
 * @method updatePartnerSelfSettingsAPI
 * @param  {Object}  payload
 * @return {Promise}
 */
export const updatePartnerSelfSettingsAPI = payload => {
    const { partnerId } = payload;
    return axios({
        method: 'patch',
        url: `/v2/partner-settings/${partnerId}/partner-settings`,
        data: payload,
    });
};

/**
 * API update upcoming communications for a partner
 *
 * @param  {number|string}  partnerId
 * @param  {Object}         communication
 * @method updatePartnerUpcomingCommunicationAPI
 * @return {Promise}
 */
export const updatePartnerUpcomingCommunicationAPI = (partnerId, communication) =>
    axios({
        method: 'patch',
        url: `/v2/partners/${partnerId}/communications`,
        data: communication,
    });

/**
 * API fetch list of Onboarding And Notifications communications for a partner
 *
 * @param  {number|string}  partnerId
 * @param  {boolean}  isSendCommunication
 * @param  {boolean}  isOnboardingCommunication
 * @param  {string}  sort
 * @method fetchPartnerOnboardingAndNotificationsAPI
 * @return {Promise}
 */
export const fetchPartnerOnboardingAndNotificationsAPI = ({
    partnerId,
    isSendCommunication = false,
    isOnboardingCommunication = true,
    ...rest
}) =>
    axios({
        method: 'get',
        url: `/v2/partners/${partnerId}/communications`,
        params: {
            isSendCommunication,
            isOnboardingCommunication,
            ...rest,
        },
    });

/**
 * API to reset partner-settings
 *
 * @param   {number|string}    partnerId
 * @return  {Promise}
 */
export const resetPartnerSettingsAPI = partnerId =>
    axios({
        method: 'patch',
        url: `/v2/partner-settings/${partnerId}/reset`,
    });

/**
 * API to fetch the migrated details of a partner
 *
 * @param   {number|string}    partnerId
 * @return  {Promise}
 */
export const partnerMigratedFromTHRAPI = partnerId =>
    axios({
        method: 'get',
        url: `/v2/partners/migration-status?${partnerId}`,
        params: { partnerId },
    });
