import _get from 'lodash/get';
import { get } from 'ravenjs/utils/lodash';
import { call, put } from 'redux-saga/effects';

import { fetchAllLocationsForCompanyAPI, fetchCompanyAPI } from 'modules/apis';

import * as userActions from '../actions';

/**
 * Fetch info for a given company via a `companyId`.
 *
 * @method fetchCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchCompanySaga(action) {
    try {
        // Extract the companyId from the action.
        const { payload: companyId } = action;
        // Make the call to fetch the company with the given `companyId`.
        const resp = yield call(fetchCompanyAPI, companyId);
        // Extract the company from the valid response.
        const company = _get(resp, 'data.company', {});
        // Dispatch the `fetchCompany` SUCCESS action.
        yield put(userActions.fetchCompany.success(company));
        // Return the company just in case.
        return company;
    } catch (error) {
        // Otherwise catch the error and dispatch the `fetchCompany` ERROR action.
        yield put(userActions.fetchCompany.error(error));
        // Return the error.
        return error;
    }
}

export function* fetchCompanyLocationsSaga(action) {
    try {
        const {
            payload: { companyId, type, params },
        } = action;
        const response = yield call(fetchAllLocationsForCompanyAPI, type, companyId, params);
        const locations = get(response, 'data.locations', {});
        yield put(userActions.fetchCompanyLocations.success(locations));
    } catch (error) {
        yield put(userActions.fetchCompanyLocations.error(error));
    }
}
