export const DEFAULT_IMAGE =
    'https://images.ctfassets.net/k8f6o28heu0t/707LlOdumUrfHT2D4vuKke/945e7da7dd8bd8f532cec19c32a7e78e/noImagePreview.jpg';
export const DEFAULT_TITLE = 'Resources';
export const INTRODUCTION =
    'Scroll down to see our latest Q&As, videos, webinars, guides, charts, and checklists.';
export const WIDGETS = {
    GUIDES: {
        DESCRIPTION: 'Browse and download guides for in-depth guidance on important HR topics. ',
        FILTER: {
            text: 'What kind of guide are you looking for?',
            placeholder: 'Category',
        },
        HEADER: {
            text: 'Guides',
        },
        HIDE_INFO_ICON: true,
        FOOTER: {
            text: 'Go to Guides',
        },
        ID: 'GUIDES',
        PATH: 'guides',
        TITLE: 'Guides',
    },
    CHARTS: {
        DESCRIPTION:
            'Review charts for comprehensive information on leave, wage and hour, reporting requirements, and other important topics.',
        FILTER: {
            text: 'What kind of chart are you looking for?',
            placeholder: 'Category',
        },
        HEADER: {
            text: 'Charts',
        },
        HIDE_INFO_ICON: true,
        FOOTER: {
            text: 'Go to Charts',
        },
        ID: 'CHARTS',
        PATH: 'charts',
        TITLE: 'Charts',
    },
    HR_CHECKLIST: {
        DESCRIPTION:
            'Use these checklists to keep track of your step-by-step progress as you work though important HR issues.',
        FOOTER: {
            text: 'Go to Checklists',
        },
        HEADER: {
            text: 'Checklists',
        },
        HIDE_INFO_ICON: true,
        ID: 'HR_CHECKLIST',
        PATH: 'hr-checklists',
        TITLE: 'Checklists',
    },
    QA_STREAMS: {
        DESCRIPTION: 'Find answers to frequently asked HR questions by browsing the Q&A Stream.',
        FILTER: {
            text: 'What kind of Q&A are you looking for?',
            placeholder: 'Category',
        },
        HEADER: {
            text: 'Newest',
        },
        HIDE_INFO_ICON: true,
        FOOTER: {
            text: 'Go to Q&A',
        },
        ID: 'QA_STREAMS',
        KEY: 'entries',
        PATH: 'q-and-a',
        TITLE: 'Q&A',
        MORE_LINK_LABEL: 'View Answer',
    },
    WEBINARS: {
        DESCRIPTION_WIDGET:
            'Watch our webinars for in-depth guidance on issues facing your company right now.',
        FILTER: {
            text: 'Browse by category:',
            placeholder: 'Category',
        },
        FOOTER: {
            text: 'Go to Webinars',
        },
        HEADER: {
            text: 'Most Recent',
        },
        HIDE_INFO_ICON: true,
        ID: 'WEBINARS',
        PATH: 'webinars',
        TITLE: 'Webinars',
        TABS: ['Upcoming', 'Videos'],
        TABS_WITH_CATEGORIES: ['topics', 'upcoming'],
        NEW_TABS: ['Upcoming', 'Training', 'Topics'],
        NEW_TABS_LABEL: { Upcoming: 'Upcoming', Training: 'How-To Training', Topics: 'HR Topics' },
        NEW_TABS_KEYS: { Upcoming: 'upcoming', Training: 'training', Topics: 'topics' },
        TABS_LABEL: { Upcoming: 'Upcoming Webinars', Videos: 'Past Webinars' },
        PAST_WEBINARS_KEY: 'past-webinars',
        TRAINING_WEBINARS_KEY: 'how-to-training',
    },
    TWO_MHR: {
        DESCRIPTION:
            'Learn HR basics, tips, and best practices by watching these short training videos.',
        FILTER: {
            text: 'Browse by category:',
            placeholder: 'Select category',
        },
        FOOTER: {
            text: 'Go to Videos',
        },
        HEADER: {
            text: 'Most Recent',
        },
        HIDE_INFO_ICON: true,
        ID: 'TWO_MHR',
        PATH: 'videos',
        TITLE: 'Videos',
    },
    '2MHR': {
        DESCRIPTION:
            'Learn HR basics, tips, and best practices by watching these short training videos.',
    },
    'Q-AND-A': {
        DESCRIPTION: 'Find answers to frequently asked HR questions by browsing the Q&A Stream.',
    },
};

export const NAVIGATION_MOBILE = [
    {
        label: 'Q&A Streams',
        jumpTo: 'q-and-a',
    },
    {
        label: 'Videos',
        jumpTo: 'videos',
    },
    {
        label: 'Webinars',
        jumpTo: 'webinars',
    },
    {
        label: 'Guides',
        jumpTo: 'guides',
    },
    {
        label: 'Checklist',
        jumpTo: 'hr-checklists',
    },
    {
        label: 'Charts',
        jumpTo: 'charts',
    },
];

export const Q_AND_A_ANSWER_TITLE = 'Answered by the HR Experts';
