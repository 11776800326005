import { string } from 'prop-types';
import React from 'react';

import { DEFAULT_COLORS } from 'constants/colors';

const CheckboxRoundIcon = ({ fill, height, width, viewBox }) => {
    return (
        <svg width={width} height={height} fill="none" viewBox={viewBox}>
            <path
                d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <svg x="25%" y="25%" width={width / 2} height={height / 2} fill="none">
                <path
                    d="M10 3 4.5 8.5 2 6"
                    stroke={fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </svg>
    );
};

CheckboxRoundIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

CheckboxRoundIcon.defaultProps = {
    fill: DEFAULT_COLORS.GREEN,
    height: '24',
    width: '24',
    viewBox: '0 0 24 24',
};

export default CheckboxRoundIcon;
