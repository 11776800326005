import { format as formatDate, subMonths, subWeeks } from 'date-fns';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';

import { get, isEmpty } from 'ravenjs/utils/lodash';

import { downloadFileByUrl } from 'utils/thrservice';
import { extractErrorMessage } from 'utils/errors';

import { EVENTS } from 'constants/events';
import { WIDGETS } from 'constants/resources';
import MESSAGES from 'constants/messages';
import { MODALS } from 'constants/modals';

export const handleAllFilterSelection = (filterSelections, filterAllId = 'filterAllId') => {
    let revisedFilterSelections = [...filterSelections];

    if (filterSelections.length > 1 && filterSelections.includes(filterAllId)) {
        if (filterSelections[0] !== filterAllId) {
            revisedFilterSelections = [filterAllId];
        } else {
            const filterSelectionsWithAllIdRemoved = filterSelections.filter(
                filterId => filterId !== filterAllId
            );
            revisedFilterSelections = filterSelectionsWithAllIdRemoved;
        }
    } else if (filterSelections.length === 0) {
        revisedFilterSelections = [];
    }

    return revisedFilterSelections;
};

export const getDateFilterSelectionSettings = ({ endDate, amountToSubtract }) => {
    endDate = endDate || Date();
    let startDate = endDate;

    switch (amountToSubtract) {
        case 'OneWeek':
            startDate = subWeeks(endDate, 1);
            break;
        case 'OneMonth':
            startDate = subMonths(endDate, 1);
            break;
        case 'TwoMonths':
            startDate = subMonths(endDate, 2);
            break;
        default:
            break;
    }

    startDate = formatDate(startDate, 'YYYY-MM-DD');
    endDate = formatDate(endDate, 'YYYY-MM-DD');

    return {
        startDate,
        endDate,
    };
};

export const getDateRange = dateFilterValue => {
    if (dateFilterValue === 'oneMonth') {
        return getDateFilterSelectionSettings({ amountToSubtract: 'OneMonth' });
    }
    if (dateFilterValue === 'oneWeek') {
        return getDateFilterSelectionSettings({ amountToSubtract: 'OneWeek' });
    }
    if (dateFilterValue === 'twoMonths') {
        return getDateFilterSelectionSettings({ amountToSubtract: 'TwoMonths' });
    }
    return {
        startDate: undefined,
        endDate: undefined,
    };
};

export const getLocationStateCode = (locations, jurisdiction, type) => {
    const jurisdictionId = ['Federal'];
    locations.forEach(location => {
        const locationInfo = _find(jurisdiction, {
            abbreviation: location.stateCode,
        });
        if (locationInfo) {
            const { id, abbreviation } = locationInfo;
            if (type === 'abbreviation' && jurisdictionId.indexOf(abbreviation) === -1) {
                jurisdictionId.push(abbreviation);
            } else if (type !== 'abbreviation' && jurisdictionId.indexOf(id) === -1) {
                jurisdictionId.push(id);
            }
        }
    });
    return jurisdictionId;
};

export const getAllFilters = (defaultFilters, filters) => {
    const allFilters = {};

    if (!isEmpty(defaultFilters)) {
        Object.keys(defaultFilters).forEach(key => {
            if (!isEmpty(key)) {
                if (key === 'toDate' || key === 'fromDate') {
                    allFilters[key] = filters[key] ? new Date(filters[key]) : '';
                } else {
                    allFilters[key] = filters[key] || defaultFilters[key];
                }
            }
        });
    }

    return allFilters;
};

/**
 * Returns query params for filters
 *
 * @param   {Array}     filters
 * @param   {string}    searchSpec
 * @return  {string}
 */
export const getFilterSearchQuery = (filters = [], searchSpec = '') => {
    const searchFilters = {};
    filters.forEach(filter => {
        filter.selected &&
            filter.selected.length &&
            (searchFilters[filter.dataKey] = filter.selected);
    });
    const encodeUrl = url => {
        // GN-9101 : This is to differentiate between search param and Filters/Search values
        // containing '&' as string in query params.
        const updatedUrl = url.replace(/&/g, encodeURIComponent('&'));
        return updatedUrl.replace(/#/g, encodeURIComponent('#'));
    };
    let searchQuery = `?filters=${JSON.stringify(searchFilters)}`;
    searchQuery = encodeUrl(searchQuery);
    if (searchSpec) {
        if (typeof searchSpec === 'string') {
            searchQuery = `${searchQuery}&searchSpec=${encodeUrl(searchSpec)}`;
        } else {
            searchQuery = `${searchQuery}&advanceSearchSpec=${encodeUrl(
                JSON.stringify(searchSpec)
            )}`;
        }
    }
    return searchQuery;
};

/**
 * Returns filters extracted from query params
 *
 * @param   {Object} location
 * @param   {Array}  filters
 * @return  {Array}
 */
export const getFiltersFromQueryParams = (location, filters) => {
    const queryParams = get(location, 'search', '');
    const queryParamsParse = queryString.parse(queryParams);
    const filtersUrl = queryParamsParse.filters ? JSON.parse(queryParamsParse.filters) : null;
    return filters.map(item => {
        if (filtersUrl && filtersUrl[item.dataKey]) {
            item.selected = filtersUrl[item.dataKey];
        }
        return item;
    });
};

/**
 * Returns advance search value extracted from query params
 *
 * @param   {Object} location
 * @return  {string}
 */
export const getAdvanceSearchSpecFromQueryParams = location => {
    const queryParams = get(location, 'search', '');
    const queryParamsParse = queryString.parse(queryParams);
    return queryParamsParse.advanceSearchSpec ? JSON.parse(queryParamsParse.advanceSearchSpec) : '';
};

/**
 * Returns searchSpec value extracted from query params
 *
 * @param   {Object} location
 * @return  {string}
 */
export const getSearchSpecFromQueryParams = location => {
    const queryParams = get(location, 'search', '');
    const queryParamsParse = queryString.parse(queryParams);
    return queryParamsParse.searchSpec || '';
};

export const convertWagesAndHoursToWageAndHour = (items, propertyName) => {
    if (!items.length || !propertyName.length) {
        return;
    }

    const wagesAndHoursItem =
        items.filter(item => item[propertyName] === 'Wages & Hours')[0] || null;

    if (wagesAndHoursItem) {
        wagesAndHoursItem[propertyName] = 'Wage & Hour';
    }
};

export const getOptionsWithSubCategories = (initialFilterSettings, optionsData) => {
    const options = [...initialFilterSettings.options];

    optionsData.forEach(
        ({ bulletColor, id: categoryId, name: label, subcategory: subcategories }) => {
            options.push({
                color: bulletColor,
                id: categoryId,
                label,
                subcategories,
            });
            subcategories.forEach(({ id, name: label }) => {
                options.push({
                    id,
                    label,
                    parentId: categoryId,
                });
            });
        }
    );

    return {
        ...initialFilterSettings,
        options,
    };
};

export const getOptionsWithSubCategoriesOnly = (initialFilterSettings, optionsData) => {
    const options = [...initialFilterSettings.options];

    optionsData.forEach(({ subcategory: subcategories }) => {
        subcategories.forEach(({ id, name: label }) => {
            options.push({
                id,
                label,
                subcategory: true,
            });
        });
    });

    return {
        ...initialFilterSettings,
        options,
    };
};

const sendFilterEvent = ({ filters, filterEvent, sendEvent, value }) => {
    filterEvent.eventProperties.filters = JSON.stringify(filters) || '';
    filterEvent.eventProperties.value =
        typeof value === 'string' ? value : JSON.stringify(value) || '';
    sendEvent(filterEvent);
};

export const trackFilterChangeEvents = ({
    currentSelectedFilters,
    revisedUpdatedFilters,
    sendPendoEvent,
    state,
}) => {
    if (state === EVENTS.SEARCH.STATE) {
        if (!_isEqual(revisedUpdatedFilters.category, currentSelectedFilters.category)) {
            sendFilterEvent({
                filters: revisedUpdatedFilters,
                filterEvent: EVENTS.SEARCH.FILTERS.UPDATE_CATEGORY,
                sendEvent: sendPendoEvent,
                value: revisedUpdatedFilters.category,
            });
        }
        if (!_isEqual(revisedUpdatedFilters.contentTypes, currentSelectedFilters.contentTypes)) {
            sendFilterEvent({
                filters: revisedUpdatedFilters,
                filterEvent: EVENTS.SEARCH.FILTERS.UPDATE_CONTENT_TYPES,
                sendEvent: sendPendoEvent,
                value: revisedUpdatedFilters.contentTypes,
            });
        }
        if (!_isEqual(revisedUpdatedFilters.employeeCount, currentSelectedFilters.employeeCount)) {
            sendFilterEvent({
                filters: revisedUpdatedFilters,
                filterEvent: EVENTS.SEARCH.FILTERS.UPDATE_EMPLOYEE_COUNT,
                sendEvent: sendPendoEvent,
                value: revisedUpdatedFilters.employeeCount,
            });
        }
        if (!_isEqual(revisedUpdatedFilters.jurisdiction, currentSelectedFilters.jurisdiction)) {
            sendFilterEvent({
                filters: revisedUpdatedFilters,
                filterEvent: EVENTS.SEARCH.FILTERS.UPDATE_JURISDICTION,
                sendEvent: sendPendoEvent,
                value: revisedUpdatedFilters.jurisdiction,
            });
        }
        if (!_isEqual(revisedUpdatedFilters.sort || '', currentSelectedFilters.sort || '')) {
            sendFilterEvent({
                filters: revisedUpdatedFilters,
                filterEvent: EVENTS.SEARCH.FILTERS.UPDATE_SORT,
                sendEvent: sendPendoEvent,
                value: revisedUpdatedFilters.sort,
            });
        }
    } else if (state === WIDGETS.WEBINARS.PATH) {
        if (!_isEqual(revisedUpdatedFilters.categoryTypes, currentSelectedFilters.categoryTypes)) {
            sendFilterEvent({
                filters: revisedUpdatedFilters,
                filterEvent: EVENTS.RESOURCES.WEBINARS.VIDEOS.FILTERS.UPDATE_CATEGORY,
                sendEvent: sendPendoEvent,
                value: revisedUpdatedFilters.categoryTypes,
            });
        }
        if (!_isEqual(revisedUpdatedFilters.sort || '', currentSelectedFilters.sort || '')) {
            sendFilterEvent({
                filters: revisedUpdatedFilters,
                filterEvent: EVENTS.RESOURCES.WEBINARS.VIDEOS.FILTERS.UPDATE_SORT,
                sendEvent: sendPendoEvent,
                value: revisedUpdatedFilters.sort,
            });
        }
    }
};

export const getCompareCategoryParams = (defaultFiltersValues, filters, removeFederalState) => {
    const currentFilters = _cloneDeep(filters);
    const includeFederalStates =
        currentFilters.jurisdiction && currentFilters.jurisdiction.indexOf('Federal') !== -1;

    if (removeFederalState) {
        currentFilters.jurisdiction = currentFilters.jurisdiction.filter(
            state => state !== 'Federal'
        );
    }

    let allCategories = [];
    let categories = [];
    defaultFiltersValues.categories.forEach(category => {
        allCategories = [
            { id: category.id, name: category.name },
            ...allCategories,
            ...category.subcategory,
        ];
    });

    const states = currentFilters.jurisdiction.map(state => {
        const dataState = _filter(defaultFiltersValues.jurisdictions, { id: state });

        return isEmpty(dataState)
            ? null
            : {
                  id: dataState[0].id,
                  name: dataState[0].name,
              };
    });

    let employeeCount;
    if (!isEmpty(currentFilters.employeeCount) && !isEmpty(defaultFiltersValues.employeeCount)) {
        employeeCount = currentFilters.employeeCount.map(count => {
            const dataState = _filter(defaultFiltersValues.employeeCount, { id: count });

            return isEmpty(dataState)
                ? null
                : {
                      id: dataState[0].id,
                      name: dataState[0].name,
                  };
        });
    }

    const categoriesKey = filters.categoryTypes ? 'categoryTypes' : 'subcategoryType';

    if (filters[categoriesKey].indexOf('subcategoryTypeAll') !== -1) {
        categories = allCategories;
    } else {
        categories = filters[categoriesKey]
            ? allCategories.filter(category => filters[categoriesKey].indexOf(category.id) !== -1)
            : [];
    }

    return {
        categories: categories.map(category => {
            return { id: category.id, name: category.name };
        }),
        employeeCount,
        includeFederalStates,
        states,
    };
};

export const compareLawByStates = async compareProps => {
    const {
        closeModal,
        fetchCompareLawsByStates,
        jurisdictionAndCategories,
        isLoading,
        openModal,
        selectedFilters,
    } = compareProps;

    try {
        isLoading(true, MESSAGES.LOADING.DEFAULT);

        const response = await fetchCompareLawsByStates(
            getCompareCategoryParams(jurisdictionAndCategories, selectedFilters, true)
        );
        const url = get(response, 'results.documentUrl', '');

        if (url) {
            downloadFileByUrl(url, '', '_blank');
        }
    } catch (error) {
        openModal(MODALS.CONFIRM, {
            title: MESSAGES.MODAL.CONFIRM.TITLE.ERROR,
            description: extractErrorMessage(error),
            actions: [
                {
                    text: 'Ok',
                    color: 'primary',
                    onClick() {
                        closeModal(MODALS.CONFIRM);
                    },
                },
            ],
        });
    } finally {
        isLoading(false);
    }
};
