import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ProfileIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.627 15.464C4.67 14.527 6.082 14 7.556 14h8.888c1.474 0 2.887.527 3.929 1.464C21.415 16.402 22 17.674 22 19v2c0 .552-.497 1-1.111 1s-1.111-.448-1.111-1v-2c0-.796-.351-1.559-.976-2.121-.626-.563-1.474-.879-2.358-.879H7.556c-.885 0-1.732.316-2.357.879-.626.562-.977 1.325-.977 2.121v2c0 .552-.497 1-1.11 1C2.496 22 2 21.552 2 21v-2c0-1.326.585-2.598 1.627-3.536zM12 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM7 7a5 5 0 1 1 10 0A5 5 0 0 1 7 7z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ProfileIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ProfileIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK_00,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default ProfileIcon;
