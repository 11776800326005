import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ArrowRightIcon = ({ fill, height, strokeWidth, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            {...rest}
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.293 1.612a1.036 1.036 0 0 1 1.414 0l6 5.715a.92.92 0 0 1 0 1.346l-6 5.715a1.036 1.036 0 0 1-1.414 0 .92.92 0 0 1 0-1.347L9.586 8 4.293 2.96a.92.92 0 0 1 0-1.348z"
                fill="#000"
            />
        </SvgIcon>
    );
};

ArrowRightIcon.propTypes = {
    fill: string,
    height: string,
    strokeWidth: string,
    viewBox: string,
    width: string,
};

ArrowRightIcon.defaultProps = {
    fill: 'none',
    height: '16',
    strokeWidth: '2',
    viewBox: '0 0 16 16',
    width: '16',
};

export default ArrowRightIcon;
