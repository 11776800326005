import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    activateProspectAPI,
    bulkUploadProspectsAPI,
    createProspectAPI,
    deactivateProspectAPI,
    deleteBulkProspectsAPI,
    deleteProspectAPI,
    emailProspectReportAPI,
    enrollBulkProspectsAPI,
    fetchProspectByIdAPI,
    fetchProspectsByPartnerAPI,
    updateProspectAPI,
    createProspectQuestionnaireAPI,
    downloadSampleForBulkProspectAPI,
} from 'modules/apis';

import * as prospectActions from '../actions';

/**
 * Saga to activate a prospect
 *
 * @method activateProspectSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* activateProspectSaga(action) {
    try {
        const {
            payload: { partnerId, prospectId },
        } = action;
        const response = yield call(activateProspectAPI, { partnerId, prospectId });
        const message = get(response, 'data');
        yield put(prospectActions.activateProspect.success(message));
        return message;
    } catch (error) {
        yield put(prospectActions.activateProspect.error(error));
        return error;
    }
}

/**
 * Saga to bulk upload prospects
 *
 * @method bulkUploadProspectsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* bulkUploadProspectsSaga(action) {
    try {
        const { payload: param } = action;
        const response = yield call(bulkUploadProspectsAPI, param);
        yield put(prospectActions.bulkUploadProspects.success(response));
        return response;
    } catch (error) {
        yield put(prospectActions.bulkUploadProspects.error(error));
        return error;
    }
}

/**
 * Function for create prospect
 *
 * @method createProspectSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* createProspectSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(createProspectAPI, payload);
        const prospect = get(resp, 'data.results', {});
        yield put(prospectActions.createProspect.success(prospect));
        return prospect;
    } catch (error) {
        yield put(prospectActions.createProspect.error(error));
        return error;
    }
}

/**
 * Saga to deactivate a prospect
 *
 * @method deactivateProspectSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* deactivateProspectSaga(action) {
    try {
        const {
            payload: { partnerId, prospectId },
        } = action;
        const response = yield call(deactivateProspectAPI, { partnerId, prospectId });
        const message = get(response, 'data');
        yield put(prospectActions.deactivateProspect.success(message));
        return message;
    } catch (error) {
        yield put(prospectActions.deactivateProspect.error(error));
        return error;
    }
}

/**
 * Function for fetch prospect by id.
 *
 * @method fetchProspectByIdSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchProspectByIdSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchProspectByIdAPI, payload);
        const prospect = get(resp, 'data.prospect', {});
        yield put(prospectActions.fetchProspectById.success(prospect));
        return prospect;
    } catch (error) {
        yield put(prospectActions.fetchProspectById.error(error));
        return error;
    }
}

/**
 * Function for fetch prospect by id.
 *
 * @method fetchProspectsByPartnerSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 */
export function* fetchProspectsByPartnerSaga(action) {
    try {
        const {
            payload: { params, partnerId },
        } = action;
        const resp = yield call(fetchProspectsByPartnerAPI, partnerId, params);
        const prospects = get(resp, 'data.prospects', []);
        const totalRecords = get(resp, 'data.totalRecords', 0);
        yield put(prospectActions.fetchProspectsByPartner.success({ prospects, totalRecords }));
    } catch (error) {
        yield put(prospectActions.fetchProspectsByPartner.error(error));
    }
}

/**
 * Function for update prospect.
 *
 * @method updateProspectSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* updateProspectSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(updateProspectAPI, payload);
        const prospect = get(resp, 'data.prospect', {});
        yield put(prospectActions.updateProspect.success(prospect));
        return prospect;
    } catch (error) {
        yield put(prospectActions.updateProspect.error(error));
        return error;
    }
}

/**
 * Function for delete prospect.
 *
 * @method deleteProspectSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* deleteProspectSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(deleteProspectAPI, payload);
        const message = get(resp, 'data.message', '');
        yield put(prospectActions.deleteProspect.success(message));
        return message;
    } catch (error) {
        yield put(prospectActions.deleteProspect.error(error));
        return error;
    }
}

/**
 * Function to create Questionnaire for prospect.
 *
 * @method createProspectQuestionnaire
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* createProspectQuestionnaireSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(createProspectQuestionnaireAPI, payload);
        const results = get(resp, 'data.results', []);
        yield put(prospectActions.createQuestionnaire.success(results));
        return results;
    } catch (error) {
        yield put(prospectActions.createQuestionnaire.error(error));
        return error;
    }
}

/**
 * Saga to delete bulk prospect saga
 *
 * @method deleteBulkProspectSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* deleteBulkProspectSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(deleteBulkProspectsAPI, params);
        const message = get(response, 'data.message', '');
        yield put(prospectActions.deleteBulkProspect.success(message));
        return message;
    } catch (error) {
        yield put(prospectActions.deleteBulkProspect.error(error));
        return error;
    }
}

/**
 * Saga to bulk enroll prospects
 *
 * @method enrollBulkProspectsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* enrollBulkProspectsSaga(action) {
    try {
        const { payload: param } = action;
        const response = yield call(enrollBulkProspectsAPI, param);
        const prospectIds = get(response, 'data', []);
        yield put(prospectActions.enrollBulkProspect.success(prospectIds));
        return prospectIds;
    } catch (error) {
        yield put(prospectActions.enrollBulkProspect.error(error));
        return error;
    }
}

/**
 * Saga to email prospect report
 *
 * @method emailProspectReportSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* emailProspectReportSaga(action) {
    try {
        const { payload: param } = action;
        const response = yield call(emailProspectReportAPI, param);
        const message = get(response, 'data', '');
        yield put(prospectActions.emailProspectReport.success(message));
        return message;
    } catch (error) {
        yield put(prospectActions.emailProspectReport.error(error));
        return error;
    }
}

/**
 * Saga to download sample csv for bulk import prospect.
 *
 * @method downloadSampleForBulkProspectSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* downloadSampleForBulkProspectSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(downloadSampleForBulkProspectAPI, payload);
        const data = get(response, 'data', '');
        yield put(prospectActions.downloadSampleForBulkProspect.success(data));
        return data;
    } catch (error) {
        yield put(prospectActions.downloadSampleForBulkProspect.error(error));
        return error;
    }
}
