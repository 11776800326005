import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HarassmentIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1.000000, -1.000000)">
                    <path
                        d="M13.1646154,39.5907692 C19.0430769,39.5907692 23.8061538,44.3584615 23.8061538,50.2323077 Ca23.8061538,56.1107692 19.0415385,60.8723077 13.1646154,60.8723077 C7.28923077,60.8723077 2.52461538,56.1107692 2.52461538,50.2323077 C2.52615385,44.3584615 7.28923077,39.5907692 13.1646154,39.5907692"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M13.1646154,39.5907692 C19.0430769,39.5907692 23.8061538,44.3584615 23.8061538,50.2323077 C23.8061538,56.1107692 19.0415385,60.8723077 13.1646154,60.8723077 C7.28923077,60.8723077 2.52461538,56.1107692 2.52461538,50.2323077 C2.52615385,44.3584615 7.28923077,39.5907692 13.1646154,39.5907692"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M50.2338462,35.3153846 C58.4707692,35.3153846 65.1507692,41.9923077 65.1507692,50.2338462 C65.1507692,58.4692308 58.4707692,65.1492308 50.2338462,65.1492308 C41.9984615,65.1492308 35.3184615,58.4692308 35.3184615,50.2338462 C35.3184615,41.9923077 41.9984615,35.3153846 50.2338462,35.3153846"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M59.6830769,40.7830769 C57.2707692,38.3661538 53.9246154,36.8707692 50.2338462,36.8707692 C46.5753846,36.8707692 43.26,38.3353846 40.8523077,40.7092308 L40.7876923,40.7846154 C38.3707692,43.1969231 36.8753846,46.5430769 36.8753846,50.2338462 C36.8753846,53.9276923 38.3692308,57.2707692 40.7815385,59.68 C43.1984615,62.1 46.5415385,63.5923077 50.2353846,63.5923077 C53.9246154,63.5923077 57.2707692,62.0969231 59.6846154,59.68 C62.1046154,57.2707692 63.5984615,53.9276923 63.5984615,50.2338462 C63.5969231,46.5430769 62.1,43.1969231 59.6830769,40.7830769 Z M50.2338462,33.7615385 C54.7784615,33.7615385 58.9015385,35.6092308 61.8769231,38.5892308 C64.8569231,41.5661538 66.7030769,45.6876923 66.7030769,50.2323077 C66.7030769,54.7707692 64.8569231,58.8892308 61.8738462,61.8738462 C58.9,64.8538462 54.7784615,66.7 50.2338462,66.7 C45.6984615,66.7 41.58,64.8538462 38.5969231,61.8769231 C35.6107692,58.8876923 33.7676923,54.7676923 33.7676923,50.2323077 C33.7676923,45.6876923 35.6138462,41.5646154 38.5907692,38.5892308 L38.6784615,38.5107692 C41.6538462,35.5753846 45.7384615,33.7615385 50.2338462,33.7615385 Z"
                        fill={DEFAULT_COLORS.BLACK}
                        fillRule="nonzero"
                    />
                    <path
                        d="M15.4153846,41.2676923 C20.0661538,41.2676923 23.8353846,45.64 23.8353846,51.0338462 C23.8353846,56.4276923 20.0661538,60.7969231 15.4153846,60.7969231 C10.7646154,60.7969231 6.99230769,56.4276923 6.99230769,51.0338462 C6.99230769,45.64 10.7646154,41.2676923 15.4153846,41.2676923"
                        fill={DEFAULT_COLORS.ORANGE}
                    />
                    <path
                        d="M28.5892308,2.52153846 C34.4646154,2.52153846 39.2292308,7.28615385 39.2292308,13.1630769 C39.2292308,19.0384615 34.4646154,23.8030769 28.5892308,23.8030769 C22.7107692,23.8030769 17.9476923,19.0384615 17.9476923,13.1630769 C17.9476923,7.28461538 22.7107692,2.52153846 28.5892308,2.52153846"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M28.5892308,2.52153846 C34.4646154,2.52153846 39.2292308,7.28615385 39.2292308,13.1630769 C39.2292308,19.0384615 34.4646154,23.8030769 28.5892308,23.8030769 C22.7107692,23.8030769 17.9476923,19.0384615 17.9476923,13.1630769 C17.9476923,7.28461538 22.7107692,2.52153846 28.5892308,2.52153846"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M30.8369231,4.19846154 C35.4876923,4.19846154 39.2569231,8.56769231 39.2569231,13.9615385 C39.2569231,19.3553846 35.4876923,23.7276923 30.8369231,23.7276923 C26.1861538,23.7276923 22.4169231,19.3553846 22.4169231,13.9615385 C22.4169231,8.56769231 26.1861538,4.19846154 30.8369231,4.19846154"
                        fill={DEFAULT_COLORS.ORANGE}
                    />
                    <path
                        d="M72.56,2.52153846 C78.4384615,2.52153846 83.2015385,7.28615385 83.2015385,13.1630769 C83.2015385,19.0384615 78.4384615,23.8030769 72.56,23.8030769 C66.6861538,23.8030769 61.9215385,19.0384615 61.9215385,13.1630769 C61.9215385,7.28461538 66.6846154,2.52153846 72.56,2.52153846"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M72.56,2.52153846 C78.4384615,2.52153846 83.2015385,7.28615385 83.2015385,13.1630769 C83.2015385,19.0384615 78.4384615,23.8030769 72.56,23.8030769 C66.6861538,23.8030769 61.9215385,19.0384615 61.9215385,13.1630769 C61.9215385,7.28461538 66.6846154,2.52153846 72.56,2.52153846"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M74.8107692,4.19846154 C79.4615385,4.19846154 83.2307692,8.56769231 83.2307692,13.9615385 C83.2307692,19.3553846 79.4630769,23.7276923 74.8107692,23.7276923 C70.1584615,23.7276923 66.3876923,19.3553846 66.3876923,13.9615385 C66.3876923,8.56769231 70.1584615,4.19846154 74.8107692,4.19846154"
                        fill={DEFAULT_COLORS.ORANGE}
                    />
                    <path
                        d="M87.3030769,39.5907692 C93.18,39.5907692 97.9446154,44.3584615 97.9446154,50.2323077 C97.9446154,56.1107692 93.18,60.8723077 87.3030769,60.8723077 C81.4276923,60.8723077 76.6630769,56.1107692 76.6630769,50.2323077 C76.6630769,44.3584615 81.4261538,39.5907692 87.3030769,39.5907692"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M87.3030769,39.5907692 C93.18,39.5907692 97.9446154,44.3584615 97.9446154,50.2323077 C97.9446154,56.1107692 93.18,60.8723077 87.3030769,60.8723077 C81.4276923,60.8723077 76.6630769,56.1107692 76.6630769,50.2323077 C76.6630769,44.3584615 81.4261538,39.5907692 87.3030769,39.5907692"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M89.5538462,41.2676923 C94.2030769,41.2676923 97.9738462,45.64 97.9738462,51.0338462 C97.9738462,56.4276923 94.2046154,60.7969231 89.5538462,60.7969231 C84.9030769,60.7969231 81.1323077,56.4276923 81.1323077,51.0338462 C81.1323077,45.64 84.9015385,41.2676923 89.5538462,41.2676923"
                        fill={DEFAULT_COLORS.ORANGE}
                    />
                    <path
                        d="M72.56,76.6615385 C78.4384615,76.6615385 83.2015385,81.4261538 83.2015385,87.3030769 C83.2015385,93.1784615 78.4384615,97.9430769 72.56,97.9430769 C66.6861538,97.9430769 61.9215385,93.1784615 61.9215385,87.3030769 C61.9215385,81.4246154 66.6846154,76.6615385 72.56,76.6615385"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M72.56,76.6615385 C78.4384615,76.6615385 83.2015385,81.4261538 83.2015385,87.3030769 C83.2015385,93.1784615 78.4384615,97.9430769 72.56,97.9430769 C66.6861538,97.9430769 61.9215385,93.1784615 61.9215385,87.3030769 C61.9215385,81.4246154 66.6846154,76.6615385 72.56,76.6615385"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M74.8107692,78.3384615 C79.4615385,78.3384615 83.2307692,82.7092308 83.2307692,88.1015385 C83.2307692,93.4953846 79.4630769,97.8692308 74.8107692,97.8692308 C70.1584615,97.8692308 66.3876923,93.4953846 66.3876923,88.1015385 C66.3876923,82.7076923 70.1584615,78.3384615 74.8107692,78.3384615"
                        fill={DEFAULT_COLORS.ORANGE}
                    />
                    <path
                        d="M28.5738462,76.6615385 C34.4492308,76.6615385 39.2138462,81.4261538 39.2138462,87.3030769 C39.2138462,93.1784615 34.4492308,97.9430769 28.5738462,97.9430769 C22.6953846,97.9430769 17.9323077,93.1784615 17.9323077,87.3030769 C17.9323077,81.4246154 22.6953846,76.6615385 28.5738462,76.6615385"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M28.5738462,76.6615385 C34.4492308,76.6615385 39.2138462,81.4261538 39.2138462,87.3030769 C39.2138462,93.1784615 34.4492308,97.9430769 28.5738462,97.9430769 C22.6953846,97.9430769 17.9323077,93.1784615 17.9323077,87.3030769 C17.9323077,81.4246154 22.6953846,76.6615385 28.5738462,76.6615385"
                        fill={DEFAULT_COLORS.WHITE}
                    />
                    <path
                        d="M30.8215385,78.3384615 C35.4723077,78.3384615 39.2415385,82.7092308 39.2415385,88.1015385 C39.2415385,93.4953846 35.4738462,97.8692308 30.8215385,97.8692308 C26.1692308,97.8692308 22.4015385,93.4953846 22.4015385,88.1015385 C22.4015385,82.7076923 26.1692308,78.3384615 30.8215385,78.3384615"
                        fill={DEFAULT_COLORS.ORANGE}
                    />
                    <path
                        d="M19.5938462,43.8046154 C17.9538462,42.1646154 15.6784615,41.1492308 13.1646154,41.1492308 C10.6507692,41.1492308 8.37846154,42.1630769 6.74153846,43.8046154 C5.09538462,45.4446154 4.07846154,47.72 4.07846154,50.2338462 C4.07846154,52.7507692 5.09538462,55.0230769 6.73692308,56.66 C8.38,58.3030769 10.6523077,59.32 13.1661538,59.32 C15.68,59.32 17.9553846,58.3030769 19.5923077,56.6646154 C21.2384615,55.0215385 22.2523077,52.7492308 22.2523077,50.2323077 C22.2507692,47.72 21.2369231,45.4446154 19.5938462,43.8046154 Z M13.1646154,38.0415385 C16.5230769,38.0415385 19.5723077,39.4061538 21.7815385,41.6138462 C23.9938462,43.8292308 25.3584615,46.8784615 25.3584615,50.2338462 C25.3584615,53.5923077 23.9907692,56.6415385 21.7876923,58.8476923 C19.5692308,61.06 16.5230769,62.4246154 13.1646154,62.4246154 C9.80923077,62.4246154 6.75692308,61.06 4.55076923,58.8538462 C2.33846154,56.6415385 0.973846154,53.5923077 0.973846154,50.2338462 C0.973846154,46.8784615 2.34153846,43.8261538 4.54769231,41.62 C6.76153846,39.4061538 9.80923077,38.0415385 13.1646154,38.0415385 Z"
                        fill={DEFAULT_COLORS.BLACK}
                        fillRule="nonzero"
                    />
                    <path
                        d="M35.0153846,6.73384615 C33.3753846,5.09384615 31.1,4.07384615 28.5892308,4.07384615 C26.0723077,4.07384615 23.8,5.09538462 22.1630769,6.73230769 C20.5169231,8.37538462 19.5030769,10.6507692 19.5030769,13.1646154 C19.5030769,15.6784615 20.5169231,17.9507692 22.1584615,19.5876923 C23.8015385,21.2338462 26.0738462,22.2476923 28.5907692,22.2476923 C31.1015385,22.2476923 33.3769231,21.2338462 35.0138462,19.5923077 C36.66,17.9492308 37.6769231,15.6769231 37.6769231,13.1630769 C37.6769231,10.6492308 36.6584615,8.37384615 35.0153846,6.73384615 Z M28.5892308,0.967692308 C31.9446154,0.967692308 34.9969231,2.33538462 37.2030769,4.54307692 C39.4153846,6.75538462 40.78,9.80461538 40.78,13.1630769 C40.78,16.5215385 39.4123077,19.5676923 37.2061538,21.7769231 C34.9938462,23.9892308 31.9446154,25.3538462 28.5892308,25.3538462 C25.2307692,25.3538462 22.1815385,23.9861538 19.9723077,21.7830769 C17.76,19.5646154 16.3953846,16.5215385 16.3953846,13.1630769 C16.3953846,9.80461538 17.7630769,6.75538462 19.9661538,4.54615385 C22.1861538,2.33384615 25.2307692,0.967692308 28.5892308,0.967692308 Z"
                        fill={DEFAULT_COLORS.BLACK}
                        fillRule="nonzero"
                    />
                    <path
                        d="M78.9876923,6.73384615 C77.3492308,5.09384615 75.0723077,4.07384615 72.5584615,4.07384615 C70.0446154,4.07384615 67.7738462,5.09538462 66.1353846,6.73230769 C64.4892308,8.37538462 63.4753846,10.6507692 63.4753846,13.1646154 C63.4753846,15.6784615 64.4892308,17.9507692 66.1307692,19.5876923 C67.7738462,21.2338462 70.0461538,22.2476923 72.56,22.2476923 C75.0738462,22.2476923 77.3492308,21.2338462 78.9861538,19.5923077 C80.6323077,17.9492308 81.6461538,15.6769231 81.6461538,13.1630769 C81.6461538,10.6492308 80.6323077,8.37384615 78.9876923,6.73384615 Z M72.56,0.967692308 C75.92,0.967692308 78.9676923,2.33538462 81.1784615,4.54307692 C83.3907692,6.75538462 84.7553846,9.80461538 84.7553846,13.1630769 C84.7553846,16.5215385 83.3861538,19.5676923 81.1846154,21.7769231 C78.9692308,23.9892308 75.92,25.3538462 72.5615385,25.3538462 C69.2061538,25.3538462 66.1553846,23.9861538 63.9476923,21.7830769 C61.7353846,19.5646154 60.3707692,16.5215385 60.3707692,13.1630769 C60.3707692,9.80461538 61.7384615,6.75538462 63.9415385,4.54615385 C66.1569231,2.33384615 69.2046154,0.967692308 72.56,0.967692308 Z"
                        fill={DEFAULT_COLORS.BLACK}
                        fillRule="nonzero"
                    />
                    <path
                        d="M93.7307692,43.8046154 C92.0907692,42.1646154 89.8153846,41.1492308 87.3015385,41.1492308 C84.7876923,41.1492308 82.5153846,42.1630769 80.8784615,43.8046154 C79.2323077,45.4446154 78.2184615,47.72 78.2184615,50.2338462 C78.2184615,52.7507692 79.2323077,55.0230769 80.8723077,56.66 C82.5153846,58.3030769 84.7892308,59.32 87.3030769,59.32 C89.8169231,59.32 92.0907692,58.3030769 93.7292308,56.6646154 C95.3707692,55.0215385 96.3923077,52.7492308 96.3923077,50.2323077 C96.3923077,47.72 95.3707692,45.4446154 93.7307692,43.8046154 Z M87.3030769,38.0415385 C90.6615385,38.0415385 93.7092308,39.4061538 95.92,41.6138462 C98.1323077,43.8292308 99.4969231,46.8784615 99.4969231,50.2338462 C99.4969231,53.5923077 98.1292308,56.6415385 95.9230769,58.8476923 C93.7107692,61.06 90.6615385,62.4246154 87.3030769,62.4246154 C83.9430769,62.4246154 80.8984615,61.06 78.6876923,58.8538462 C76.4753846,56.6415385 75.1107692,53.5923077 75.1107692,50.2338462 C75.1107692,46.8784615 76.4784615,43.8261538 78.6815385,41.62 C80.9015385,39.4061538 83.9430769,38.0415385 87.3030769,38.0415385 Z"
                        fill={DEFAULT_COLORS.BLACK}
                        fillRule="nonzero"
                    />
                    <path
                        d="M78.9876923,80.8738462 C77.3492308,79.2307692 75.0723077,78.2153846 72.5584615,78.2153846 C70.0446154,78.2153846 67.7738462,79.2292308 66.1353846,80.8707692 C64.4892308,82.5138462 63.4753846,84.7892308 63.4753846,87.3030769 C63.4753846,89.8169231 64.4892308,92.0892308 66.1307692,93.7261538 C67.7738462,95.3723077 70.0461538,96.3892308 72.56,96.3892308 C75.0738462,96.3892308 77.3492308,95.3723077 78.9861538,93.7307692 C80.6323077,92.0892308 81.6461538,89.8153846 81.6461538,87.3015385 C81.6461538,84.7876923 80.6323077,82.5138462 78.9876923,80.8738462 Z M72.56,75.1076923 C75.92,75.1076923 78.9676923,76.4769231 81.1784615,78.6784615 C83.3907692,80.8984615 84.7553846,83.9446154 84.7553846,87.3015385 C84.7553846,90.6584615 83.3861538,93.7092308 81.1846154,95.9169231 C78.9692308,98.1292308 75.92,99.4938462 72.5615385,99.4938462 C69.2061538,99.4938462 66.1553846,98.1261538 63.9476923,95.92 C61.7353846,93.7076923 60.3707692,90.6584615 60.3707692,87.3015385 C60.3707692,83.9430769 61.7384615,80.8938462 63.9415385,78.6846154 C66.1569231,76.4738462 69.2046154,75.1076923 72.56,75.1076923 Z"
                        fill={DEFAULT_COLORS.BLACK}
                        fillRule="nonzero"
                    />
                    <path
                        d="M35,80.8738462 C33.36,79.2307692 31.0846154,78.2153846 28.5707692,78.2153846 C26.0569231,78.2153846 23.7846154,79.2292308 22.1476923,80.8707692 C20.5015385,82.5138462 19.4876923,84.7892308 19.4876923,87.3030769 C19.4876923,89.8169231 20.5015385,92.0892308 22.1415385,93.7261538 C23.7846154,95.3723077 26.0569231,96.3892308 28.5707692,96.3892308 C31.0846154,96.3892308 33.36,95.3723077 35,93.7307692 C36.6430769,92.0892308 37.6584615,89.8153846 37.6584615,87.3015385 C37.6584615,84.7876923 36.6430769,82.5138462 35,80.8738462 Z M28.5707692,75.1076923 C31.9292308,75.1076923 34.9784615,76.4769231 37.1876923,78.6784615 C39.4,80.8984615 40.7646154,83.9446154 40.7646154,87.3015385 C40.7646154,90.6584615 39.3969231,93.7092308 37.1907692,95.9169231 C34.9784615,98.1292308 31.9292308,99.4938462 28.5707692,99.4938462 C25.2123077,99.4938462 22.1661538,98.1261538 19.96,95.92 C17.7476923,93.7076923 16.3784615,90.6584615 16.3784615,87.3015385 C16.3784615,83.9430769 17.7461538,80.8938462 19.9523077,78.6846154 C22.1661538,76.4738462 25.2123077,75.1076923 28.5707692,75.1076923 Z"
                        fill={DEFAULT_COLORS.BLACK}
                        fillRule="nonzero"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

HarassmentIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

HarassmentIcon.defaultProps = {
    height: '20',
    viewBox: '0 0 100 100',
    width: '20',
};

export default HarassmentIcon;
