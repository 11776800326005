import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { fetchMinWageMapAPI } from 'modules/apis';
import * as minimumWageMapActions from '../actions';

/**
 * Fetch minimum wage map
 *
 * @method fetchMinWageMapSaga
 * @type   {Generator}
 * @return {Array}
 */
export function* fetchMinWageMapSaga() {
    try {
        const response = yield call(fetchMinWageMapAPI);
        const data = get(response, 'data', {});
        yield put(minimumWageMapActions.fetchMinWageMapData.success(data));
        return data;
    } catch (error) {
        yield put(minimumWageMapActions.fetchMinWageMapData.error(error));
        return error;
    }
}
