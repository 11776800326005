import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    fetchBenefitsDocumentCreatorUrlAPI,
    fetchBenefitsDocumentCreateDocumentUrlAPI,
    fetchBdcDocumentsAPI,
    fetchBenefitsDocumentCreatorUrlResourcesAPI,
} from 'modules/apis';
import * as benefitsDocumentCreatorActions from '../actions';

/**
 * Function for fetching benefits document creator url.
 *
 * @method fetchBenefitsDocumentCreatorUrlSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchBenefitsDocumentCreatorUrlSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchBenefitsDocumentCreatorUrlAPI, params);
        const events = get(response, 'data.results', {});
        yield put(benefitsDocumentCreatorActions.fetchBenefitsDocumentCreatorUrl.success(events));
        return events;
    } catch (error) {
        yield put(benefitsDocumentCreatorActions.fetchBenefitsDocumentCreatorUrl.error(error));
        return error;
    }
}

/**
 * Function for fetching benefits document creator url.
 *
 * @method fetchBenefitsDocumentCreateDocumentUrlSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchBenefitsDocumentCreateDocumentUrlSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchBenefitsDocumentCreateDocumentUrlAPI, params);
        const events = get(response, 'data.results', {});
        yield put(
            benefitsDocumentCreatorActions.fetchBenefitsDocumentCreateDocumentUrl.success(events)
        );
        return events;
    } catch (error) {
        yield put(
            benefitsDocumentCreatorActions.fetchBenefitsDocumentCreateDocumentUrl.error(error)
        );
        return error;
    }
}

/**
 * Function for fetching documents to benefits documents creator widget.
 *
 * @method fetchBdcDocumentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} documents
 */
export function* fetchBdcDocumentsSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchBdcDocumentsAPI, params);
        const events = get(response, 'data.results', {});
        yield put(benefitsDocumentCreatorActions.fetchBdcDocuments.success(events));
        return events;
    } catch (error) {
        yield put(benefitsDocumentCreatorActions.fetchBdcDocuments.error(error));
        return error;
    }
}

/**
 * Function for fetching benefits document creator url.
 *
 * @method fetchBenefitsDocumentCreatorUrlResourcesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchBenefitsDocumentCreatorUrlResourcesSaga(action) {
    try {
        const { payload: bdcSection } = action;
        const response = yield call(fetchBenefitsDocumentCreatorUrlResourcesAPI, bdcSection);
        const events = get(response, 'data.results', {});
        yield put(
            benefitsDocumentCreatorActions.fetchBenefitsDocumentCreatorUrlResources.success(events)
        );
        return events;
    } catch (error) {
        yield put(
            benefitsDocumentCreatorActions.fetchBenefitsDocumentCreatorUrlResources.error(error)
        );
        return error;
    }
}
