import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MapPinIcon = ({ viewBox, height, width, fill }) => {
    return (
        <SvgIcon width={width} viewBox={viewBox} height={height} fill="none">
            <path
                fill={fill}
                d="M12,24c-0.3,0-0.6-0.1-0.8-0.2c-0.4-0.3-9.3-6.3-9.3-13.7c0-2.7,1-5.2,3-7.1C6.8,1,9.3,0,12,0s5.2,1,7.1,3 c1.9,1.9,3,4.4,3,7.1c0,7.4-8.9,13.4-9.3,13.7C12.6,23.9,12.3,24,12,24z M12,2.9c-1.9,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3.2-2.1,5.1 c0,4.4,4.6,8.8,7.2,10.7c2-1.5,7.2-6,7.2-10.7c0-1.9-0.7-3.7-2.1-5.1C15.8,3.6,13.9,2.9,12,2.9z"
            />
            <path
                fill={fill}
                d="M12,14.4c-2.4,0-4.3-1.9-4.3-4.3S9.6,5.8,12,5.8s4.3,1.9,4.3,4.3S14.4,14.4,12,14.4z M12,8.6 c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,8.6,12,8.6z"
            />
        </SvgIcon>
    );
};

MapPinIcon.propTypes = {
    viewBox: string,
    height: string,
    width: string,
    fill: string,
};

MapPinIcon.defaultProps = {
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
    fill: DEFAULT_COLORS.BLACK,
};

export default MapPinIcon;
