import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const DeclineIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75ZM1.25 15C1.25 7.40608 7.40608 1.25 15 1.25C22.5939 1.25 28.75 7.40608 28.75 15C28.75 22.5939 22.5939 28.75 15 28.75C7.40608 28.75 1.25 22.5939 1.25 15Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.27823 5.27859C5.76638 4.79044 6.55784 4.79044 7.04599 5.27859L24.721 22.9536C25.2091 23.4417 25.2091 24.2332 24.721 24.7214C24.2328 25.2095 23.4414 25.2095 22.9532 24.7214L5.27823 7.04636C4.79007 6.5582 4.79007 5.76675 5.27823 5.27859Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

DeclineIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

DeclineIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '30',
    viewBox: '0 0 30 30',
    width: '30',
};

export default DeclineIcon;
