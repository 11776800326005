import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const CheckListIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            width={height}
            height={height}
            viewBox={viewBox}
            fill="none"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.0746 3.01099H11.5282H7.85977C6.9819 3.01099 6.4942 3.63947 6.4942 4.33277L6.46521 8.58425V28.5482C6.46521 29.3413 7.05322 29.989 7.77281 29.989H24.0746C24.7951 29.989 25.3831 29.3418 25.3831 28.5482V4.45178C25.3831 3.65922 24.7951 3.01099 24.0746 3.01099Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.3832 4.29175H9.0648C8.53016 4.29175 8.25134 4.62346 8.25134 5.22611V29.989C13.5259 29.989 18.801 29.989 24.0756 29.989C24.785 29.989 25.3832 29.3408 25.3832 28.5482V4.29175Z"
                fill="#F1E3D8"
            />
            <path
                d="M7.77277 2.5L24.0829 2.50203C24.5729 2.51013 25.0113 2.72384 25.3256 3.06973C25.6279 3.40246 25.8207 3.86128 25.8432 4.38341C25.9913 6.74084 26.1045 27.3449 25.8455 28.5589C25.8345 29.1058 25.6385 29.5859 25.3256 29.9303C25.0215 30.265 24.6032 30.4752 24.1321 30.4959L7.77277 30.5C7.28506 30.5 6.84152 30.2792 6.52037 29.9272C6.19922 29.5737 6 29.084 6 28.5482V4.45178C6 3.91598 6.19968 3.42677 6.52037 3.07277C6.84152 2.7203 7.28506 2.5 7.77277 2.5ZM24.0755 3.52096L7.77277 3.52198C7.54041 3.52198 7.32923 3.62731 7.17648 3.79494C7.02372 3.96308 6.92848 4.19705 6.92848 4.45178V28.5487C6.92848 28.8045 7.02418 29.0384 7.17694 29.2061C7.32969 29.3732 7.54088 29.478 7.77323 29.478L24.104 29.4806C24.3272 29.4659 24.5268 29.3666 24.6709 29.2081C24.8195 29.0445 24.9143 28.8126 24.9216 28.5487L24.9198 4.42241C24.9078 4.1697 24.8144 3.9494 24.6709 3.7919C24.5213 3.62731 24.3115 3.52603 24.0755 3.52096Z"
                fill="#282828"
            />
            <path
                d="M9.94177 11.795C9.76095 11.596 9.76095 11.2729 9.94177 11.0728C10.1226 10.8738 10.4161 10.8738 10.5969 11.0728L11.2696 11.8127L13.2632 9.61837C13.445 9.41934 13.7381 9.42035 13.9175 9.62039C14.0974 9.81942 14.0974 10.142 13.9157 10.3395L11.5954 12.8945C11.4145 13.0935 11.1219 13.0935 10.9402 12.8945L9.94177 11.795Z"
                fill="#282828"
            />
            <path
                d="M16.0142 10.4904C15.7574 10.4904 15.5499 10.2615 15.5499 9.97943C15.5499 9.69735 15.7579 9.46844 16.0142 9.46844H21.7562C22.0116 9.46844 22.2195 9.69735 22.2195 9.97943C22.2195 10.262 22.0116 10.4904 21.7562 10.4904H16.0142Z"
                fill="#282828"
            />
            <path
                d="M16.0142 12.9755C15.7574 12.9755 15.5499 12.7466 15.5499 12.4645C15.5499 12.1819 15.7579 11.9535 16.0142 11.9535H18.8852C19.141 11.9535 19.3485 12.1824 19.3485 12.4645C19.3485 12.7466 19.141 12.9755 18.8852 12.9755H16.0142Z"
                fill="#282828"
            />
            <path
                d="M9.94177 17.9112C9.76095 17.7122 9.76095 17.3891 9.94177 17.1901C10.1226 16.99 10.4161 16.99 10.5969 17.1901L11.2696 17.9284L13.2632 15.7341C13.445 15.5361 13.7381 15.5361 13.9175 15.7361C14.0974 15.9351 14.0974 16.2577 13.9157 16.4552L11.5954 19.0102C11.4145 19.2102 11.1219 19.2102 10.9402 19.0102L9.94177 17.9112Z"
                fill="#282828"
            />
            <path
                d="M16.0142 16.6066C15.7574 16.6066 15.5499 16.3777 15.5499 16.0956C15.5499 15.8135 15.7579 15.5846 16.0142 15.5846H21.7562C22.0116 15.5846 22.2195 15.8135 22.2195 16.0956C22.2195 16.3782 22.0116 16.6066 21.7562 16.6066H16.0142Z"
                fill="#282828"
            />
            <path
                d="M16.0142 19.0916C15.7574 19.0916 15.5499 18.8627 15.5499 18.5807C15.5499 18.2981 15.7579 18.0707 16.0142 18.0707H18.8852C19.141 18.0707 19.3485 18.2986 19.3485 18.5807C19.3485 18.8627 19.141 19.0916 18.8852 19.0916H16.0142Z"
                fill="#282828"
            />
            <path
                d="M9.94177 24.0283C9.76095 23.8283 9.76095 23.5062 9.94177 23.3061C10.1226 23.1061 10.4161 23.1061 10.5969 23.3061L11.2696 24.046L13.2632 21.8501C13.445 21.6521 13.7381 21.6531 13.9175 21.8522C14.0969 22.0512 14.0974 22.3733 13.9156 22.5723L11.5954 25.1278C11.4145 25.3278 11.1219 25.3278 10.9402 25.1278L9.94177 24.0283Z"
                fill="#282828"
            />
            <path
                d="M16.0142 22.7232C15.7574 22.7232 15.5499 22.4954 15.5499 22.2128C15.5499 21.9302 15.7579 21.7018 16.0142 21.7018H21.7562C22.0116 21.7018 22.2195 21.9302 22.2195 22.2128C22.2195 22.4954 22.0116 22.7232 21.7562 22.7232H16.0142Z"
                fill="#282828"
            />
            <path
                d="M16.0142 25.2084C15.7574 25.2084 15.5499 24.9805 15.5499 24.6979C15.5499 24.4158 15.7579 24.1869 16.0142 24.1869H18.8852C19.141 24.1869 19.3485 24.4158 19.3485 24.6979C19.3485 24.9805 19.141 25.2084 18.8852 25.2084H16.0142Z"
                fill="#282828"
            />
            <path d="M21.1156 3.01202H10.7316V5.8217H21.1156V3.01202Z" fill="white" />
            <path
                d="M20.6515 3.52194H11.1965V5.31065H20.6515V3.52194ZM10.7318 2.50098H21.1158C21.372 2.50098 21.5791 2.72988 21.5791 3.01196V5.82164C21.5791 6.10423 21.372 6.33262 21.1158 6.33262H10.7318C10.475 6.33262 10.2684 6.10372 10.2684 5.82164V3.01196C10.2684 2.72988 10.4755 2.50098 10.7318 2.50098Z"
                fill="#282828"
            />
        </SvgIcon>
    );
};

CheckListIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

CheckListIcon.defaultProps = {
    height: '33',
    viewBox: '0 0 32 33',
    width: '32',
};

export default CheckListIcon;
