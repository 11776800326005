import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching resource.
 *
 * @type {Object}
 */
export const fetchResourceByType = createActions('legalAndPrivacy', 'FETCH_RESOURCE_BY_TYPE', {
    asPromise: true,
});
