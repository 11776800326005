import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ToolTipIcon = ({ viewBox, height, width, fill, ...rest }) => {
    return (
        <SvgIcon
            data-testid="tooltip-icon"
            width={width}
            viewBox={viewBox}
            height={height}
            fill="none"
            {...rest}
        >
            <path
                d="M20.9,24h-10c-1.7,0-3.1-1.4-3.1-3.1v-10c0-1.7,1.4-3.1,3.1-3.1h10c1.7,0,3.1,1.4,3.1,3.1v10C24,22.6,22.6,24,20.9,24z M10.9,9.5c-0.8,0-1.4,0.6-1.4,1.4v10c0,0.8,0.6,1.4,1.4,1.4h10c0.8,0,1.4-0.6,1.4-1.4v-10c0-0.8-0.6-1.4-1.4-1.4H10.9z"
                fill={fill}
            />
            <path
                d="M4.2,16.2H3.1c-0.8,0-1.6-0.3-2.2-0.9S0,13.9,0,13.1v-10c0-0.8,0.3-1.6,0.9-2.2S2.2,0,3.1,0h10c0.8,0,1.6,0.3,2.2,0.9 s0.9,1.4,0.9,2.2v1.1c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V3.1c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4h-10 c-0.4,0-0.7,0.1-1,0.4s-0.4,0.6-0.4,1v10c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4h1.1c0.5,0,0.8,0.4,0.8,0.8 C5,15.8,4.6,16.2,4.2,16.2z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ToolTipIcon.propTypes = {
    viewBox: string,
    height: string,
    width: string,
    fill: string,
};

ToolTipIcon.defaultProps = {
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
    fill: DEFAULT_COLORS.BLACK,
};

export default ToolTipIcon;
