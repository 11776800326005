import { call, put } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import {
    fetchPartnerSentCommunicationsAPI,
    fetchPartnerSettingsAPI,
    fetchPartnerUpcomingCommunicationsAPI,
    fetchPartnerOnboardingAndNotificationsAPI,
    updatePartnerSelfSettingsAPI,
    updatePartnerUpcomingCommunicationAPI,
    partnerMigratedFromTHRAPI,
} from 'modules/apis';

import * as adminActions from '../actions';

/**
 * Fetch partner settings.
 *
 * @method fetchPartnerSettingsSaga
 *
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* fetchPartnerSettingsSaga(action) {
    try {
        const { payload: partnerId } = action;
        const resp = yield call(fetchPartnerSettingsAPI, partnerId);
        const partnerSettings = get(resp, 'data.partnersettings', {});
        yield put(adminActions.fetchPartnerSettings.success(partnerSettings));
        return partnerSettings;
    } catch (error) {
        yield put(adminActions.fetchPartnerSettings.error(error));
        return error;
    }
}

/**
 * Update partner self settings.
 *
 * @method updatePartnerSelfSettingsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* updatePartnerSelfSettingsSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(updatePartnerSelfSettingsAPI, payload);
        const partnerSettings = get(resp, 'data.partnersettings', {});
        yield put(adminActions.updatePartnerSelfSettings.success(partnerSettings));
        return partnerSettings;
    } catch (error) {
        yield put(adminActions.updatePartnerSelfSettings.error(error));
        return error;
    }
}

/**
 * Saga to fetch list of sent communications for a partner
 *
 * @method fetchPartnerSentCommunicationsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchPartnerSentCommunicationsSaga(action) {
    try {
        const {
            payload: { partnerId, params },
        } = action;
        const response = yield call(fetchPartnerSentCommunicationsAPI, partnerId, {
            ...params,
            isSendCommunication: true,
        });
        const { communications = [], totalRecords = 0 } = get(response, 'data', {});
        yield put(
            adminActions.fetchPartnerSentCommunications.success({
                communications,
                totalRecords,
            })
        );
        return communications;
    } catch (error) {
        yield put(adminActions.fetchPartnerSentCommunications.error(error));
        return error;
    }
}

/**
 * Saga to fetch list of upcoming communications for a partner
 *
 * @method fetchPartnerUpcomingCommunicationsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchPartnerUpcomingCommunicationsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchPartnerUpcomingCommunicationsAPI, payload);
        const { communications = [], totalRecords = 0 } = get(response, 'data', {});
        yield put(
            adminActions.fetchPartnerUpcomingCommunications.success({
                communications,
                totalRecords,
            })
        );
        return communications;
    } catch (error) {
        yield put(adminActions.fetchPartnerUpcomingCommunications.error(error));
        return error;
    }
}

/**
 * Saga to update partner upcoming communications
 *
 * @method updatePartnerUpcomingCommunicationSaga
 * @param  {Object}               action The original redux-action
 */
export function* updatePartnerUpcomingCommunicationSaga(action) {
    try {
        const {
            payload: { communication, partnerId },
        } = action;
        const response = yield call(
            updatePartnerUpcomingCommunicationAPI,
            partnerId,
            communication
        );
        const updatedCommunication = get(response, 'data.communication');
        yield put(adminActions.updatePartnerUpcomingCommunication.success(updatedCommunication));
    } catch (error) {
        yield put(adminActions.updatePartnerUpcomingCommunication.error(error));
    }
}

/**
 * Saga to fetch list of Onboarding And Notifications communications for a partner
 *
 * @method fetchPartnerOnboardingAndNotificationsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchPartnerOnboardingAndNotificationsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchPartnerOnboardingAndNotificationsAPI, payload);
        const { communications = [], totalRecords = 0 } = get(response, 'data', {});
        yield put(
            adminActions.fetchPartnerOnboardingAndNotifications.success({
                communications,
                totalRecords,
            })
        );
        return communications;
    } catch (error) {
        yield put(adminActions.fetchPartnerOnboardingAndNotifications.error(error));
        return error;
    }
}

/**
 * Saga to fetch the migrated details of a partner
 *
 * @method fetchPartnerMigratedFromTHRSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchPartnerMigratedFromTHRSaga(action) {
    try {
        const { payload: partnerId } = action;
        const response = yield call(partnerMigratedFromTHRAPI, partnerId);
        const { migrationStatus } = get(response, 'data', {});
        yield put(
            adminActions.fetchPartnerMigratedFromTHR.success({
                ...migrationStatus,
            })
        );
        return { ...migrationStatus };
    } catch (error) {
        yield put(adminActions.fetchPartnerMigratedFromTHR.error(error));
        return error;
    }
}
