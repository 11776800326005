import React from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import { get, isEmpty } from 'ravenjs/utils/lodash';
import { addSchemaValue } from 'ravenjs/utils/schema';
import { formatDate } from 'ravenjs/utils/date';

import { isTouchDevice } from 'utils/common';
import { handleAllFilterSelection } from 'utils/filters';
import { getHRComplianceLink } from 'utils/comply';

import { HomeIcon } from 'components/Icons';

import {
    CATEGORY_NAMES,
    CONTENT_NAMES,
    CONTENT_TYPES,
    CONTENT_TYPES_MAPPED,
    NAV_STATE_TYPES,
    SECTIONS_WITH_BACK_TO_TOP_SIDEBAR,
} from 'constants/content';
import { WIDGETS } from 'constants/compliance';
import DOCUMENTS from 'constants/documents';
import { HOME_ICON_LABEL } from 'constants/breadcrumbs';

/**
 * Format list of documents for the <DocumentView> component
 *
 * @param   {string}    category
 * @param   {string}    documentType
 * @param   {Array}     documents
 * @return  {Array}
 */
export const formatDocuments = (category, documentType, documents = []) =>
    documents.map(({ contentfulId, status, summary, title, mediaThumbnail }) => ({
        documentType,
        id: contentfulId,
        parent: category,
        status,
        summary,
        thumbNailImg: get(mediaThumbnail, 'file.url', ''),
        title,
    }));

/**
 * Format documents based on the group(category)
 *
 * @param   {Object}    documentsObj
 * @param   {Array}     categories
 * @param   {string}    documentType
 * @return {Array}
 */
export const formatDocumentsByGroup = (documentsObj, categories, documentType) => {
    const getCategoryId = categoryName => {
        return categories.find(({ name }) => name === categoryName).id;
    };

    return Object.entries(documentsObj).map(([category, documents]) => {
        return {
            id: getCategoryId(category),
            // TODO: Add color based on the response from the API
            color: 'transparent',
            title: category,
            totalDocuments: documents.length,
            documents: formatDocuments(category, documentType, documents),
        };
    });
};

export const formatCategoriesAndSubcategories = listCategories => {
    const parentCategories = [];

    if (!isEmpty(listCategories)) {
        listCategories.forEach(item => {
            if (isEmpty(parentCategories.find(option => option.id === item.parentId))) {
                parentCategories.push({
                    color: item.bulletColor,
                    id: item.parentId,
                    name: item.parentName,
                });
            }
        });

        parentCategories.map(item => {
            item.subcategories = listCategories.filter(
                option => option.parentId === item.id && option.id !== option.parentId
            );
            return item;
        });
    }

    return parentCategories;
};

export const getStatesByDocument = (states, state) => {
    let list = '';

    if (!isEmpty(states)) {
        list = states.map(item => {
            return item.name;
        });

        list = list.join(', ');
    } else if (typeof state === 'string') {
        if (state === 'All') {
            list = 'All States';
        } else if (state !== 'N/A') {
            list = state;
        }
    }

    return list;
};

export const getFormattedCategories = (
    categoriesResponse,
    buildUpdatedFilter,
    categoryFilterIndex
) => {
    const categories = get(categoriesResponse, 'data.categories', []);
    // TODO: remove filter when API no longer returns Other
    const categoryTypesData = {
        ...categoriesResponse,
        categories: categories.filter(category => category.name !== CATEGORY_NAMES.OTHER),
    };
    const contentTypeNames = get(categoryTypesData, 'categories', []);
    const categoryIndex =
        categoryFilterIndex !== undefined && categoryFilterIndex !== null ? categoryFilterIndex : 1;
    const categoryTypeNamesFilter = buildUpdatedFilter(
        categoryTypesData,
        'categories',
        categoryIndex
    );
    if (!isEmpty(categoryTypeNamesFilter)) {
        categoryTypeNamesFilter.rowsPerColumn =
            categoryTypeNamesFilter &&
            categoryTypeNamesFilter.options &&
            Math.ceil(categoryTypeNamesFilter.options.length / 2);
    }

    return { categories, categoryTypeNamesFilter, contentTypeNames };
};

export const formatRoute = route => {
    return route
        ? route
              .toLowerCase()
              .replace(/ /g, '-')
              .replace('&', 'and')
        : null;
};

export const getRoutesForDocumentType = documentTypeLabel => {
    let documentTypeRoute;
    switch (documentTypeLabel) {
        case CONTENT_NAMES.POLICY:
            documentTypeRoute = formatRoute(NAV_STATE_TYPES.POLICY);
            break;
        case CONTENT_NAMES.CHECKLIST:
            documentTypeRoute = formatRoute(NAV_STATE_TYPES.CHECKLIST);
            break;
        default:
            documentTypeRoute = formatRoute(documentTypeLabel);
            break;
    }
    return documentTypeRoute;
};

export const goToHelpCenter = (history, sections) => {
    const slug = get(sections, '0.slug');

    history.push(`/help-center/${slug}`);
};

export const buildUrlLawAlertFilters = ({
    categorySelections,
    categoriesToLaw,
    history,
    stateSelections,
    section,
}) => {
    const filters = {};
    const { HR_FITNESS_TEST } = WIDGETS;
    let search;

    const sectionKey =
        section === CONTENT_TYPES_MAPPED.LAW ? CONTENT_TYPES.LAW : CONTENT_TYPES.LAW_ALERT;

    if (!isEmpty(categorySelections[sectionKey])) {
        if (section === CONTENT_TYPES_MAPPED.LAW) {
            const getCategory = categoriesToLaw.filter(
                item => categorySelections[sectionKey] === item.id
            );
            const getSubcategories = getCategory && getCategory[0].subcategory.map(item => item.id);

            filters.categoryTypes = [...getSubcategories, categorySelections[sectionKey]];
        } else {
            filters.categoryTypes = [categorySelections[sectionKey]];
        }
    }

    if (!isEmpty(stateSelections[sectionKey])) {
        filters.jurisdiction = stateSelections[sectionKey];
    }

    if (section === CONTENT_TYPES_MAPPED.LAW_ALERT) {
        filters.status = 'current';
    }

    if (!isEmpty(filters)) {
        search = `?filters=${JSON.stringify(filters)}`;
    }

    return {
        pathname: `/hr-compliance/${section}`,
        search: section === HR_FITNESS_TEST.PATH ? undefined : search,
    };
};

export const processLawAlertsFilters = (
    updatedFilters,
    noFetchData,
    updateModalFilters,
    currentSelectedFilters,
    documentsViewSchema,
    selectedFilters
) => {
    let sort = '';
    let noFetchDataFilters = false;
    const documentsViewSchemaClone = _cloneDeep(documentsViewSchema);
    const durationFilterSelected = updatedFilters.duration;
    const fromDateFilterSelected = updatedFilters.fromDate;
    const toDateFilterSelected = updatedFilters.toDate;

    if (durationFilterSelected === 'selectDateRange') {
        addSchemaValue(documentsViewSchemaClone, 'filterSchema.filters[4].hide', false);
        addSchemaValue(documentsViewSchemaClone, 'filterSchema.filters[5].hide', false);
        addSchemaValue(
            documentsViewSchemaClone,
            'filterSchema.filters[4].maxDate',
            toDateFilterSelected
        );
        addSchemaValue(
            documentsViewSchemaClone,
            'filterSchema.filters[5].minDate',
            fromDateFilterSelected
        );
    } else {
        addSchemaValue(documentsViewSchemaClone, 'filterSchema.filters[4].hide', true);
        addSchemaValue(documentsViewSchemaClone, 'filterSchema.filters[5].hide', true);
    }

    if (
        (updateModalFilters === 'fromDate' || updateModalFilters === 'toDate') &&
        (!fromDateFilterSelected || !toDateFilterSelected)
    ) {
        noFetchDataFilters = true;
    }

    if (updateModalFilters === 'dateType' && !noFetchData) {
        sort = updatedFilters.dateType;
    }

    const revisedUpdatedFilters = {
        ...updatedFilters,
        categoryTypes: handleAllFilterSelection(updatedFilters.categoryTypes, 'categoryTypeAll'),
        employeeCount: handleAllFilterSelection(updatedFilters.employeeCount, 'employeeCountAll'),
        jurisdiction: handleAllFilterSelection(updatedFilters.jurisdiction, 'jurisdictionAll'),
        sort: sort || updatedFilters.sort,
    };

    const filtersToCheck = _cloneDeep(isTouchDevice() ? selectedFilters : revisedUpdatedFilters);
    const filtersToCheckUrl = _cloneDeep(filtersToCheck);
    const isUsingDefaultFilters = _isEqual(
        revisedUpdatedFilters,
        DOCUMENTS.LAW_ALERTS_DEFAULT_FILTERS
    );

    filtersToCheckUrl.fromDate = filtersToCheckUrl.fromDate
        ? formatDate(filtersToCheckUrl.fromDate, 'YYYY-MM-DD')
        : '';
    filtersToCheckUrl.toDate = filtersToCheckUrl.toDate
        ? formatDate(filtersToCheckUrl.toDate, 'YYYY-MM-DD')
        : '';
    currentSelectedFilters.fromDate = currentSelectedFilters.fromDate
        ? formatDate(currentSelectedFilters.fromDate, 'YYYY-MM-DD')
        : '';
    currentSelectedFilters.toDate = currentSelectedFilters.toDate
        ? formatDate(currentSelectedFilters.toDate, 'YYYY-MM-DD')
        : '';

    return {
        documentsViewSchemaClone,
        noFetchDataFilters,
        revisedUpdatedFilters,
        filtersToCheckUrl,
        isUsingDefaultFilters,
        filtersToCheck,
    };
};

export const getLawAlertDate = ({ dateType, dateTypeFilter, documentItem }) => {
    const currentDateTypeFilter = dateTypeFilter || dateType;
    let dateLabel = '';
    let dateValue = '';

    switch (currentDateTypeFilter) {
        case 'passage': {
            dateLabel = 'Passage Date';
            dateValue = documentItem.passageDate;

            break;
        }
        case 'effective': {
            dateLabel = 'Effective Date';
            dateValue = documentItem.effectiveDate || documentItem.importantDate;
            break;
        }
        default: {
            dateLabel = documentItem.displaySort;
            dateValue = documentItem.sortedDate;

            break;
        }
    }

    return { dateLabel, dateValue };
};

export const formatDocumentsViewSchema = (schema, props, documentsPerGroup, filters, history) => {
    const categoryName = CONTENT_NAMES.LAW_ALERT;
    const formattedSchema = _cloneDeep(schema);

    addSchemaValue(formattedSchema, 'title', categoryName);
    addSchemaValue(
        formattedSchema,
        'breadcrumbs.items',
        formattedSchema.breadcrumbs.items.map(item => {
            if (item.id === 'compliance') {
                return {
                    ...item,
                    href: getHRComplianceLink(),
                    onClick: () => {
                        history.push(getHRComplianceLink());
                    },
                };
            }
            if (item.id === 'category') {
                return {
                    ...item,
                    label: categoryName,
                };
            }
            if (item.id === 'home') {
                return {
                    ...item,
                    href: '/dashboard',
                    label: <HomeIcon width="18" height="18" />,
                    title: HOME_ICON_LABEL,
                    onClick: () => {
                        history.push('/dashboard');
                    },
                };
            }

            return item;
        })
    );
    addSchemaValue(formattedSchema, 'groups.defaultDocumentsPerGroup', documentsPerGroup);
    addSchemaValue(formattedSchema, 'groups.documentsPerGroupWithPagination', documentsPerGroup);

    if (filters) {
        if (!filters.duration || filters.duration === 'selectDateRange') {
            addSchemaValue(formattedSchema, 'filterSchema.filters[4].hide', false);
            addSchemaValue(formattedSchema, 'filterSchema.filters[5].hide', false);
        }
    } else {
        addSchemaValue(formattedSchema, 'filterSchema.filters[4].hide', false);
        addSchemaValue(formattedSchema, 'filterSchema.filters[5].hide', false);
    }

    return formattedSchema;
};

export const showSidebarBackToTop = url => {
    return url && SECTIONS_WITH_BACK_TO_TOP_SIDEBAR.some(section => url.includes(section));
};
