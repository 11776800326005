import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const LocalPolicyIcon = ({ color, height, strokeColor, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                d="M8.8,5.8c0.8-0.8,2-1.3,3.2-1.3s2.3,0.5,3.2,1.3c0.8,0.8,1.3,1.9,1.3,3.1c0,1.7-1.1,3.3-2.4,4.7
                c-0.6,0.6-1.3,1.2-1.7,1.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5-0.4-1.1-0.9-1.7-1.5c-1.3-1.3-2.4-3-2.4-4.7
                C7.5,7.7,8,6.6,8.8,5.8z M11.6,16.9c0,0,0,0,0.4-0.6L11.6,16.9c0.2,0.2,0.6,0.2,0.8,0L12,16.3c0.4,0.6,0.4,0.6,0.4,0.6l0,0l0,0l0,0
                l0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.3,0.6-0.5c0.5-0.4,1.2-1,1.9-1.7c1.3-1.4,2.8-3.4,2.8-5.7
                c0-1.6-0.6-3.1-1.8-4.2C15.1,3.6,13.6,3,12,3S8.9,3.6,7.8,4.7C6.6,5.8,6,7.3,6,8.9c0,2.3,1.5,4.3,2.8,5.7c0.7,0.7,1.4,1.3,1.9,1.7
                c0.3,0.2,0.5,0.4,0.6,0.5C11.4,16.8,11.5,16.8,11.6,16.9C11.5,16.8,11.6,16.9,11.6,16.9L11.6,16.9L11.6,16.9L11.6,16.9L11.6,16.9z
                    M10.8,8.9c0-0.6,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2S12.7,10,12,10C11.3,10,10.8,9.5,10.8,8.9z M12,6.6c-1.3,0-2.3,1-2.3,2.2
                c0,1.2,1,2.2,2.3,2.2c1.3,0,2.3-1,2.3-2.2C14.3,7.6,13.3,6.6,12,6.6z"
                fill={color}
            />
            <path
                d="M24.2,12.2h3.4V13h-4.3V5.9h0.9V12.2z M28.2,10.3c0-0.5,0.1-1,0.3-1.4c0.2-0.4,0.5-0.7,0.8-1
                c0.4-0.2,0.8-0.3,1.2-0.3c0.7,0,1.3,0.2,1.7,0.7c0.4,0.5,0.7,1.2,0.7,2v0.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.7-0.8,1
                c-0.4,0.2-0.8,0.3-1.3,0.3c-0.7,0-1.3-0.2-1.7-0.7C28.5,11.9,28.2,11.2,28.2,10.3L28.2,10.3z M29.1,10.4c0,0.6,0.1,1.1,0.4,1.4
                c0.3,0.4,0.6,0.5,1.1,0.5c0.5,0,0.8-0.2,1.1-0.5c0.3-0.4,0.4-0.9,0.4-1.5c0-0.6-0.1-1-0.4-1.4c-0.3-0.4-0.6-0.5-1.1-0.5
                c-0.4,0-0.8,0.2-1.1,0.5C29.3,9.2,29.1,9.8,29.1,10.4z M36.3,12.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.4-0.4,0.4-0.7h0.9
                c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.4,0.5-0.8,0.7c-0.3,0.2-0.7,0.2-1,0.2c-0.7,0-1.3-0.2-1.7-0.7c-0.4-0.5-0.6-1.1-0.6-2v-0.2
                c0-0.5,0.1-1,0.3-1.4C34.4,8.5,34.7,8.2,35,8c0.4-0.2,0.8-0.3,1.3-0.3c0.6,0,1.1,0.2,1.5,0.5c0.4,0.4,0.6,0.8,0.6,1.4h-0.9
                c0-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.8-0.4,1.4v0.2c0,0.6,0.1,1.1,0.4,1.4
                C35.5,12.2,35.8,12.4,36.3,12.4z M42.7,13c-0.1-0.1-0.1-0.3-0.1-0.6c-0.4,0.4-0.9,0.7-1.5,0.7c-0.5,0-0.9-0.1-1.3-0.4
                c-0.3-0.3-0.5-0.7-0.5-1.1c0-0.6,0.2-1,0.6-1.3c0.4-0.3,1-0.5,1.8-0.5h0.9V9.4c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
                c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.3,0.4-0.3,0.6h-0.9c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.4-0.4,0.8-0.6c0.3-0.1,0.7-0.2,1.1-0.2
                c0.6,0,1.1,0.2,1.4,0.5c0.3,0.3,0.5,0.7,0.5,1.3v2.4c0,0.5,0.1,0.9,0.2,1.2V13H42.7z M41.2,12.3c0.3,0,0.6-0.1,0.8-0.2
                c0.3-0.1,0.4-0.3,0.6-0.6v-1.1h-0.7c-1.1,0-1.7,0.3-1.7,1c0,0.3,0.1,0.5,0.3,0.7C40.6,12.2,40.9,12.3,41.2,12.3z M45.8,13h-0.9V5.5
                h0.9V13z"
                fill={color}
            />
            <path
                d="M10,0.5h35c5.2,0,9.5,4.3,9.5,9.5l0,0c0,5.2-4.3,9.5-9.5,9.5H10c-5.2,0-9.5-4.3-9.5-9.5l0,0
                C0.5,4.8,4.8,0.5,10,0.5z"
                fill="none"
                stroke={strokeColor}
            />
        </SvgIcon>
    );
};

LocalPolicyIcon.propTypes = {
    color: string,
    height: string,
    strokeColor: string,
    viewBox: string,
    width: string,
};

LocalPolicyIcon.defaultProps = {
    color: DEFAULT_COLORS.GREY_10,
    viewBox: '0 0 55 20',
    width: '55',
    height: '20',
    strokeColor: DEFAULT_COLORS.GREY_10,
};

export default LocalPolicyIcon;
