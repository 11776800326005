import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import templateReplace from 'es6-template-strings';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';

import Button from 'ravenjs/lib/Button';
import Checkbox from 'ravenjs/lib/CheckboxNew';
import Col from 'ravenjs/lib/Col';
import FaIcon from 'ravenjs/lib/FaIcon';
import List from 'ravenjs/lib/List';
import ListItem from 'ravenjs/lib/ListItem';
import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { formatNumberWithDelimiter } from 'ravenjs/utils/number';

/**
 * Render a single list-item for a given column
 *
 * @method _renderItemChild
 * @param  {Object}         [col={}]  The col schema
 * @param  {Object}         [item={}] The item to render
 * @return {string}                   The string to render
 */
const _renderItemChild = (col = {}, item = {}) => {
    // Extract the `id` from the provided `col`.
    const { id = '' } = col;
    // If the `id` is an object, it means we have a number of keys to extract and render.
    if (id && typeof id === 'object') {
        // Extract the `delimiter` and `keys` list from the `id`.
        const { delimiter, keys } = id;
        // Map over the keys list and build the final string.
        return keys.reduce(
            (accum, val) =>
                accum ? `${accum}${delimiter}${_get(item, val)}` : `${_get(item, val)}`,
            ''
        );
    }
    // Otherwise return the extracted value from the `col.id`.
    return `${_get(item, id, '-')}`;
};

/**
 * A list column component to render the different lists for the `Transfer` Component.
 *
 * @method      TransferColumnList
 * @param       {Object}           props The props for the component
 * @constructor
 */
function TransferColumnList(props) {
    const {
        allLoaded,
        clearAllItems,
        initialTotalRecords,
        isItemChecked,
        isItemDisabled,
        itemKey,
        items,
        loadMore,
        maxLimit,
        schema,
        searchSpec,
        selectItem,
        sendToAll,
        totalRecords,
        type,
    } = props;
    // Extract info from the schema.
    const columns = _get(schema, [type, 'columns'], []);
    const allLoadedText = _get(schema, 'text.allLoaded', 'All data has been loaded.');
    const allLoadedSearchText = templateReplace(
        _get(
            schema,
            'text.searchAllLoaded',
            "All data matching term '${searchSpec}' has been loaded."
        ),
        { searchSpec }
    );
    const loadMoreText = _get(schema, 'text.loadMore', 'Load More Items');
    const loadMoreSearchText = _get(
        schema,
        'text.searchToLoadMore',
        `Please 'search' for additional data.`
    );
    const noDataText = _get(schema, 'text.noData', 'No data available.');
    const showLoadMore = !allLoaded && items.length !== totalRecords && items.length < maxLimit;
    const showLoadMoreSearch = !allLoaded && items.length !== totalRecords && !showLoadMore;
    const total = formatNumberWithDelimiter(initialTotalRecords);
    // If we've got the type `source`, let's render the first column
    switch (type) {
        // If we're rendering the `source` list.
        case 'source': {
            // If there are no items present, then display a `noData` text.
            if (items.length === 0) {
                return (
                    <List flush>
                        <ListItem>
                            <Row gutter={false} alignItems="center" justify="center" width="100%">
                                <Typography gutterBottom="0">{noDataText}</Typography>
                            </Row>
                        </ListItem>
                    </List>
                );
            }

            return (
                <List flush>
                    {items.map(sourceItem => {
                        const checked = isItemChecked(sourceItem);
                        const disabled = isItemDisabled(sourceItem);
                        const selectItemFunc = selectItem(sourceItem);

                        return (
                            <ListItem
                                active={checked}
                                disabled={disabled}
                                button
                                key={`${sourceItem[itemKey]}__source_item`}
                                onClick={disabled ? null : selectItemFunc}
                            >
                                <Row gutter={false} alignItems="center" width="100%">
                                    <Col size={1} gutter={false}>
                                        <Checkbox
                                            checked={checked || disabled}
                                            color={disabled ? 'light' : 'checkbox'}
                                        />
                                    </Col>
                                    {columns.map(sourceCol => (
                                        <Col
                                            {...sourceCol.col}
                                            key={`${sourceItem[itemKey]}__source_col__${sourceCol.id}`}
                                        >
                                            <Typography
                                                gutterBottom="0"
                                                noWrap
                                                {...sourceCol.typography}
                                            >
                                                {_renderItemChild(sourceCol, sourceItem)}
                                            </Typography>
                                        </Col>
                                    ))}
                                </Row>
                            </ListItem>
                        );
                    })}
                    <ListItem>
                        <Row alignItems="center" justify="center" gutter={false} width="100%">
                            {showLoadMore && (
                                <Button color="actions" onClick={loadMore}>
                                    {loadMoreText}
                                </Button>
                            )}
                            {showLoadMoreSearch && (
                                <Typography gutterBottom="0">{loadMoreSearchText}</Typography>
                            )}
                            {allLoaded && (
                                <Typography gutterBottom="0">
                                    {searchSpec ? allLoadedSearchText : allLoadedText}
                                </Typography>
                            )}
                        </Row>
                    </ListItem>
                </List>
            );
        }
        // If we're rendering the `target` list.
        case 'target': {
            // If `sendToAll` is turned on, then display the send to all message.
            if (sendToAll) {
                return (
                    <List flush>
                        <ListItem button onClick={clearAllItems}>
                            <Row gutter={false} alignItems="center" width="100%">
                                <Col size={1} gutter={false}>
                                    <FaIcon
                                        color="error"
                                        height="15px"
                                        icon={faTimesCircle}
                                        width="15px"
                                    />
                                </Col>
                                <Col size={11}>
                                    <Typography gutterBottom="0">
                                        Successfully added {total} records.
                                    </Typography>
                                </Col>
                            </Row>
                        </ListItem>
                    </List>
                );
            }

            return (
                <List flush>
                    {items.map(targetItem => {
                        const disabled = isItemDisabled(targetItem);
                        const listItemKey = `${targetItem[itemKey]}__target_item`;
                        const selectItemFunc = selectItem(targetItem);

                        return (
                            <ListItem
                                button
                                disabled={disabled}
                                key={listItemKey}
                                onClick={disabled ? null : selectItemFunc}
                            >
                                <Row gutter={false} alignItems="center" width="100%">
                                    <Col size={1} gutter={false}>
                                        <FaIcon
                                            color="error"
                                            height="15px"
                                            icon={faTimesCircle}
                                            width="15px"
                                        />
                                    </Col>
                                    {columns.map(targetCol => (
                                        <Col
                                            {...targetCol.col}
                                            key={`${targetItem[itemKey]}__target_col__${targetCol.id}`}
                                        >
                                            <Typography
                                                gutterBottom="0"
                                                noWrap
                                                {...targetCol.typography}
                                            >
                                                {_renderItemChild(targetCol, targetItem)}
                                            </Typography>
                                        </Col>
                                    ))}
                                </Row>
                            </ListItem>
                        );
                    })}
                </List>
            );
        }
        default: {
            return (
                <List flush>
                    <ListItem>
                        <Row alignItems="center" gutter={false} justify="center" width="100%">
                            <Typography gutterBottom="0">
                                You provided an unsupported &#39;type&#39; value to the
                                TransferColumnList Component.
                            </Typography>
                        </Row>
                    </ListItem>
                </List>
            );
        }
    }
}

TransferColumnList.propTypes = {
    allLoaded: PropTypes.bool.isRequired,
    initialTotalRecords: PropTypes.number.isRequired,
    loadMore: PropTypes.func.isRequired,
    maxLimit: PropTypes.number.isRequired,
    searchSpec: PropTypes.string.isRequired,
    /**
     * Callback function for clearing all items.
     */
    clearAllItems: PropTypes.func,
    /**
     * Callback function for checking if an item is active / checked.
     */
    isItemChecked: PropTypes.func,
    /**
     * Callback function for checking if an item is disabled.
     */
    isItemDisabled: PropTypes.func,
    /**
     * The unique key identifier for the set item.
     */
    itemKey: PropTypes.string,
    /**
     * The list of items to render.
     */
    items: PropTypes.array,
    /**
     * The schema for the Transfer component.
     */
    schema: PropTypes.object,
    /**
     * Callback function for selecting an item.
     */
    selectItem: PropTypes.func,
    /**
     * If `true` the component will render the send to all message.
     */
    sendToAll: PropTypes.bool,
    /**
     * Total number of active records present.
     */
    totalRecords: PropTypes.number,
    /**
     * The type of column to render, either `source` or `target`.
     */
    type: PropTypes.oneOf(['source', 'target']),
};

TransferColumnList.defaultProps = {
    clearAllItems() {},
    isItemChecked() {},
    isItemDisabled() {},
    itemKey: null,
    items: [],
    schema: {},
    selectItem() {},
    sendToAll: false,
    totalRecords: 0,
    type: 'source',
};

export default TransferColumnList;
