import _isEmpty from 'lodash/isEmpty';

import { APP_PERMISSIONS_LIST } from 'constants/permissions';
import { selectors as userSelectors } from 'modules/user';

/**
 * Define a WeakMap to hold the redux-store.
 * This way we don't define the store as `this.store = store`,
 * and make it inaccessible from the public.
 * NOTE: When an object instance is garbage collected,
 * all the data that is tied to that instance in the WeakMap
 * will also be garbage collected.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap#Why_WeakMap}
 *
 * @type {WeakMap}
 */
const reduxStore = new WeakMap();

/**
 * An Access Control Layer (ACL) Service,
 * to determine if the current logged in user has a certain set of permissions.
 *
 * @class
 */
class Acl {
    constructor({ store }) {
        // Define the store for the Acl Class.
        // NOTE: Using a WeakMap to set the store locally,
        // this way it is not accessible from outside this Acl Class.
        // Whereas, doing `this.store = store` is accessible from outside.
        reduxStore.set(this, store);
    }

    /**
     * Get all of the current application's available permissions.
     * NOTE: Converting the constant `APP_PERMISSIONS` into a Class method,
     * as it can be accessed from the Acl object.
     *
     * @method getAppPermissions
     * @return {Object}          Map of current application's available permissions
     */
    getAppPermissions = () => {
        return APP_PERMISSIONS_LIST;
    };

    /**
     * Get all of the current user's available permissions.
     *
     * @method getUserPermissions
     * @return {Array}            List of current user's available permissions
     */
    getUserPermissions = () => {
        return userSelectors.getUserPermissionsActual(reduxStore.get(this).getState());
    };

    /**
     * Get logged in user role.
     *
     * @method getLoggedInUserRole
     * @return {string}            user role
     */
    getLoggedInUserRole = () => {
        return userSelectors.getUserRole(reduxStore.get(this).getState());
    };

    /**
     * Check to see if the current user has access to the requested permission(s).
     *
     * @example
     * NOTE: By default this method will run the `Array.some()` method on the passed in
     * permission(s) set. Meaning [ANY] of the passed in permissions [CAN] exist.
     *
     * const someAcl = Acl.check(['osha', 'moveTool']);
     *
     * NOTE: By passing in an `every` (boolean) as the second argument,
     * this method will run the `Array.ever()` method on the passed in permission(s) set.
     * Meaning [ALL] of the passed in permissions [MUST] exist.
     *
     * const someAcl = Acl.check(['training', 'learn'], true);
     *
     * @method check
     * @param  {Array|string}   [permsList=[]] The permisison(s) to test
     * @param  {boolean}       every          Are all of the requested permissions required?
     * @param  {Array}  customUserPermissions  User defined permission (set) to test given permission(s)
     * @return {boolean}                      If the user can access the requested permission(s)
     */
    check = (permsList = [], every, customUserPermissions = []) => {
        // Determine the current application and user permissions.
        const appPermissionsMap = this.getAppPermissions();
        const userPermissionsList =
            Array.isArray(customUserPermissions) && !_isEmpty(customUserPermissions)
                ? customUserPermissions
                : this.getUserPermissions();
        // Convert the passed in `permsList` into an array if it is a 'string'.
        const checkPermsList = Array.isArray(permsList) ? permsList : [permsList];
        // Depending on the options, we can check for all the permissions to be true,
        // or we can check for some of the permissions to be true.
        if (typeof every === 'boolean' && every) {
            // Check for every permission to be true, and return.
            return checkPermsList.every(permission =>
                this.#checkPermissions(permission, appPermissionsMap, userPermissionsList)
            );
        }
        // Check for some of the permissions to be true, and return the result.
        return checkPermsList.some(permission =>
            this.#checkPermissions(permission, appPermissionsMap, userPermissionsList)
        );
    };

    /**
     * Check if the current user has access to the requested roles.
     *
     * @example
     * const someAcl = Acl.checkLoggedInUserRole(['System Administration', 'Partner Admin']);
     *
     * @method check
     * @param  {Array|string}  [roles=[]] The roles to check
     * @return {boolean}                  If the user has the allowed role.
     */
    checkLoggedInUserRole = (roles = []) => {
        return roles.indexOf(this.getLoggedInUserRole()) !== -1;
    };

    /**
     * Check the passed in permissions against the available
     * application's and user's permissions.
     *
     * @method checkPermissions
     * @private
     * @param  {boolean}        permission          The permission to check
     * @param  {Object}         appPermissionsMap   The applications permissions map
     * @param  {Array}          userPermissionsList The current user's permissions list
     * @return {boolean}                            Whether or not the requested permission exists
     */
    #checkPermissions = (permission, appPermissionsMap, userPermissionsList) => {
        let acl = false;
        // If the given permission is in the current `appPermissionsMap`.
        if (permission in appPermissionsMap) {
            // The permission exists, let's find it.
            let ePermsList = appPermissionsMap[permission];
            // Convert the existing permission into an Array if not already.
            ePermsList = Array.isArray(ePermsList) ? ePermsList : [ePermsList];
            // For each of the `ePermsList` `ePerm`, check to see if some of the
            // permissions to be available in the `userPermissionsList` and return the result.
            for (const ePerm of ePermsList) {
                if (userPermissionsList.some(p => p === ePerm)) {
                    acl = true;
                }
            }
            return acl;
        }
        return acl;
    };
}

export default Acl;
