import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const PaginationPrevIcon = ({ height, width, viewBox, fillInner, cursor }) => {
    return (
        <SvgIcon
            cursor={cursor}
            height={height}
            viewBox={viewBox}
            width={width}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m11.357 16.28-3.636-3.637-.006-.006a.906.906 0 0 1-.26-.634v-.006a.906.906 0 0 1 .26-.634l.006-.006 3.636-3.636a.909.909 0 1 1 1.286 1.285l-2.085 2.085h5.078a.91.91 0 0 1 0 1.818h-5.078l2.085 2.084a.91.91 0 0 1-1.286 1.286z"
                fill={fillInner}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-10 8.182a8.182 8.182 0 1 0 0-16.364 8.182 8.182 0 0 0 0 16.364z"
                fill={fillInner}
            />
        </SvgIcon>
    );
};

PaginationPrevIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillInner: string,
    cursor: string,
};

PaginationPrevIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
    fillInner: '#000',
    cursor: 'pointer',
};

export default PaginationPrevIcon;
