import { string, func } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AriesIcon = ({ fill, height, onClick, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            data-testid="aries-icon"
            width={width}
            height={height}
            onClick={onClick}
            viewBox={viewBox}
            fill="none"
            {...rest}
        >
            <path
                d="M9.7,4.7l0.7,1.9c0.8,2.1,2.4,3.8,4.6,4.6l1.9,0.7c0.2,0.1,0.2,0.3,0,0.4l-1.9,0.7c-2.1,0.8-3.8,2.4-4.6,4.6l-0.7,1.9
                c-0.1,0.2-0.3,0.2-0.4,0l-0.7-1.9c-0.8-2.1-2.4-3.8-4.6-4.6l-1.9-0.7c-0.2-0.1-0.2-0.3,0-0.4l1.9-0.7c2.1-0.8,3.8-2.4,4.6-4.6
                l0.7-1.9C9.4,4.5,9.6,4.5,9.7,4.7z"
                fill={fill}
            />
            <path
                d="M18.3,0.1l0.3,1C19,2.1,19.9,3,20.9,3.3l1,0.3c0.1,0,0.1,0.2,0,0.2l-1,0.3c-1.1,0.4-1.9,1.2-2.3,2.3l-0.3,1
                c0,0.1-0.2,0.1-0.2,0l-0.3-1c-0.4-1.1-1.2-1.9-2.3-2.3l-1-0.3c-0.1,0-0.1-0.2,0-0.2l1-0.3C16.5,3,17.4,2.1,17.7,1l0.3-1
                C18.1,0,18.3,0,18.3,0.1z"
                fill={fill}
            />
            <path
                d="M18.3,16.5l0.3,1c0.4,1.1,1.2,1.9,2.3,2.3l1,0.3c0.1,0,0.1,0.2,0,0.2l-1,0.3C19.9,21,19,21.9,18.6,23l-0.3,1
		        c0,0.1-0.2,0.1-0.2,0l-0.3-1c-0.4-1.1-1.2-1.9-2.3-2.3l-1-0.3c-0.1,0-0.1-0.2,0-0.2l1-0.3c1.1-0.4,1.9-1.2,2.3-2.3l0.3-1
		        C18.1,16.4,18.3,16.4,18.3,16.5z"
                fill={fill}
            />
        </SvgIcon>
    );
};

AriesIcon.propTypes = {
    fill: string,
    height: string,
    onClick: func,
    viewBox: string,
    width: string,
};

AriesIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    onClick: () => {},
    viewBox: '0 0 24 24',
    width: '24',
};

export default AriesIcon;
