import styled from 'styled-components';

export const NavItemsStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    list-style: none;
    margin: 0;
    padding: 0;
`;
