import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ReadIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g>
                <path fill={colorInner} d="M4.202 1.013H5.555V2.3659999999999997H4.202z" />
                <rect width="16.673" height="16.145" x="2.071" y="2.093" fill={colorInner} rx="2" />
                <path
                    fill={color}
                    d="M5.372 1.013h12.824c.303 0 .548.212.548.474v13.861h-.548c-.05.008-.091.014-.134.014H5.372V1.012zm13.372 17.5V16.73v-.473H17.65v1.783H4.269v.947h14.475v-.474zM3.903 18.04c-.833 0-1.512-.572-1.546-1.285.001-.012.004-.024.004-.037l-.002-.096c.047-.702.72-1.26 1.544-1.26h.373V1.012h-.373C2.445 1.013 1.26 2.04 1.26 3.3c0 .017.004.033.006.05-.002 1.164-.019 11.863-.002 13.27 0 .027-.005.054-.005.082 0 1.212 1.098 2.204 2.478 2.279.028.004.055.007.084.007h.456v-.947h-.373z"
                />
                <path fill={color} d="M4.019 18.037H5.019V19.037H4.019z" />
            </g>
        </g>
    </SvgIcon>
);

ReadIcon.propTypes = {
    color: string,
    colorInner: string,
    viewBox: string,
    width: string,
    height: string,
};

ReadIcon.defaultProps = {
    color: `${DEFAULT_COLORS.PEACH}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '20',
    viewBox: '0 0 20 20',
    width: '20',
};

export default ReadIcon;
