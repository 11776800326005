import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'ravenjs/constants/colors';
import SvgIcon from '../SvgIcon';

const ZoomOutIcon = ({ setRef, fill, ...rest }) => (
    <SvgIcon cursor="pointer" width="20" height="21" {...rest} ref={setRef}>
        <path
            fill={fill}
            d="M8.808.539c4.856 0 8.807 3.951 8.807 8.807 0 2.136-.764 4.097-2.033 5.624l4.18 4.181c.317.317.317.832 0 1.15-.159.158-.367.238-.575.238-.208 0-.416-.08-.575-.238l-4.181-4.18c-1.527 1.269-3.487 2.033-5.623 2.033C3.95 18.154 0 14.204 0 9.346 0 4.49 3.951.54 8.808.54zm0 1.626c-3.96 0-7.182 3.222-7.182 7.181 0 3.96 3.222 7.182 7.182 7.182 3.96 0 7.181-3.222 7.181-7.182 0-3.96-3.222-7.181-7.181-7.181zm3.985 6.31v1.643H4.578V8.475h8.215z"
        />
    </SvgIcon>
);

ZoomOutIcon.propTypes = {
    fill: PropTypes.string,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
};

ZoomOutIcon.defaultProps = {
    fill: COLORS.GREY,
    setRef: null,
    viewBox: '0 0 20 21',
};

export default ZoomOutIcon;
