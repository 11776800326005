import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';
import { actions as authActions } from 'modules/auth';

import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'errors' redux handlers.
 *
 * @type {Object}
 */
const errors = {
    [authActions.login.ERROR]: helpers.handleAuthError('login'),
};

// Create and export the 'errors' reducer.
// NOTE: The default export will be the reducer.
export default createReducer(INITIAL_STATE, errors);

// Create and export named 'errors' selectors.
// NOTE: The rest of the 'named' exports will be the selectors, for this module.

/**
 * Get all the info about the application errors.
 *
 * @method getErrors
 * @param  {Object}  state The current state
 * @return {Object}        The token info
 */
export const getErrors = state => _get(state, 'errors', {});

/**
 * Get info about a given `authState` error.
 *
 * @method getErrors
 * @param  {Object}  authState The auth state error to get (login, forgetPassword, and more)
 * @return {Object}            The auth error info
 */
export const getAuthErrors = authState =>
    createSelector([getErrors], errors => _get(errors, ['auth', authState], {}));
