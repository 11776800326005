import { func, string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const DocumentIcon = ({ fill, height, onClick, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} onClick={onClick} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.586 1.56A2.05 2.05 0 0 1 4 1h5.333c.177 0 .347.067.472.186l4 3.819c.125.119.195.28.195.45v7.636c0 .506-.21.992-.586 1.35A2.05 2.05 0 0 1 12 15H4c-.53 0-1.04-.201-1.414-.56A1.866 1.866 0 0 1 2 13.09V2.91c0-.507.21-.993.586-1.35zM4 2.272a.683.683 0 0 0-.471.186.622.622 0 0 0-.196.45v10.182c0 .169.07.33.196.45.125.12.294.186.471.186h8a.683.683 0 0 0 .471-.186.622.622 0 0 0 .196-.45V5.718l-3.61-3.445H4z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.333 1c.369 0 .667.285.667.636v3.182h3.333c.368 0 .667.285.667.637 0 .351-.299.636-.667.636h-4c-.368 0-.666-.285-.666-.636V1.636c0-.351.298-.636.666-.636zM4.667 8.636c0-.351.298-.636.666-.636h5.334c.368 0 .666.285.666.636 0 .352-.298.637-.666.637H5.333c-.368 0-.666-.285-.666-.637zM4.667 11.182c0-.352.298-.636.666-.636h5.334c.368 0 .666.284.666.636 0 .351-.298.636-.666.636H5.333c-.368 0-.666-.285-.666-.636zM4.667 6.09c0-.35.298-.635.666-.635h1.334c.368 0 .666.284.666.636 0 .351-.298.636-.666.636H5.333c-.368 0-.666-.285-.666-.636z"
                fill={fill}
            />
        </SvgIcon>
    );
};

DocumentIcon.propTypes = {
    fill: string,
    height: string,
    onClick: func,
    viewBox: string,
    width: string,
};

DocumentIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '16',
    onClick: () => {},
    viewBox: '0 0 16 16',
    width: '16',
};

export default DocumentIcon;
