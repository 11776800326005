import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';

import { callFunc } from 'ravenjs/utils/actions';
import { getThemeProps } from 'ravenjs/utils/theme';

import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { DEFAULT_BRANDING } from 'constants/app';
import NavLogoStyled from './NavLogoStyled';
import NavLogoImage from './NavLogoImage';

const NavLogo = ({
    alt: altProps,
    isSoftLogin,
    openLoginPrompt,
    src: srcProps,
    theme,
    to,
    hasCustomLogo,
    ...rest
}) => {
    const logoProps = getThemeProps('NavLogo', null, { theme }) || { src: '', alt: 'logo' };
    const src = logoProps.src || hasCustomLogo ? logoProps.src : DEFAULT_BRANDING.logoUrl;
    const alt = logoProps.alt || altProps;

    const onClickLogo = e => {
        if (isSoftLogin) {
            // Preventing default to stop redirection
            e.preventDefault();
            callFunc(openLoginPrompt);
        }
    };

    return (
        <NavLogoStyled to={to} onClick={onClickLogo}>
            {src && <NavLogoImage alt={alt} src={src} {...rest} />}
        </NavLogoStyled>
    );
};

NavLogo.propTypes = {
    /**
     * An alternate text for the logo image element.
     */
    alt: string,
    isSoftLogin: bool.isRequired,
    openLoginPrompt: func.isRequired,
    /**
     * The source URL for the logo image element.
     */
    src: string,
    /**
     * @ignore
     */
    theme: object.isRequired,
    /**
     * URL for the logo to navigate to
     */
    to: string,
    hasCustomLogo: bool,
};

NavLogo.defaultProps = {
    alt: 'nav-logo',
    src: '',
    to: '/',
    hasCustomLogo: false,
};

export { NavLogo as NavLogoUnwrapped };
export default compose(withPublicContent, withTheme)(NavLogo);
