import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ProfileCircleIcon = ({ color, fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                fill={color}
                d="M15.7,5.9c-0.1-0.2-0.1-0.4-0.2-0.6c0,0,0,0,0,0c-0.1-0.4-0.3-0.7-0.5-1.1c-0.1-0.2-0.3-0.4-0.4-0.7
                c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2-0.2-0.3-0.3C12,0.8,10.1,0,8,0C5.9,0,4,0.8,2.6,2.1
                C2.5,2.2,2.4,2.3,2.3,2.3v0c-0.5,0.4-0.9,1-1.2,1.5C1,4.1,0.9,4.3,0.8,4.5C0.6,4.9,0.5,5.2,0.4,5.6C0.1,6.4,0,7.2,0,8
                s0.1,1.6,0.4,2.4c0.1,0.4,0.3,0.7,0.4,1.1c0.1,0.2,0.2,0.4,0.4,0.7c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0,0,0,0,0,0
                c0.3,0.4,0.6,0.8,1,1.2v0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.1,0.1,0.2,0.2C4.2,15.3,6,16,8,16c2,0,3.8-0.7,5.2-1.9
                c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3v0c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.3-0.4,0.5-0.6c0,0,0,0,0,0c0,0,0,0,0,0v0
                c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.3,0.3-0.7,0.5-1.1c0,0,0,0,0,0c0.1-0.2,0.1-0.4,0.2-0.6C15.9,9.4,16,8.7,16,8S15.9,6.6,15.7,5.9z"
            />
            <path
                fill={fill}
                d="M14.2,11.1c-0.3,0.6-0.6,1.1-1.1,1.5c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-1-0.6-1.6-0.6H5c-0.6,0-1.1,0.2-1.6,0.6
                c-0.4,0.4-0.6,0.9-0.6,1.4c-0.4-0.5-0.8-1-1.1-1.5c0.2-0.3,0.4-0.6,0.7-0.8c0.7-0.6,1.6-1,2.6-1H11c1,0,1.9,0.4,2.6,1
                C13.9,10.5,14.1,10.8,14.2,11.1z"
            />
            <path
                fill={fill}
                d="M8,1.3c-1.8,0-3.3,1.5-3.3,3.3C4.7,6.5,6.2,8,8,8c1.8,0,3.3-1.5,3.3-3.3C11.3,2.8,9.8,1.3,8,1.3z M8,6.7
                c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C10,5.8,9.1,6.7,8,6.7z"
            />
        </SvgIcon>
    );
};

ProfileCircleIcon.propTypes = {
    color: string,
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ProfileCircleIcon.defaultProps = {
    color: DEFAULT_COLORS.GREY_23,
    fill: DEFAULT_COLORS.WHITE,
    height: '16px',
    viewBox: '0 0 16 16',
    width: '16px',
};

export default ProfileCircleIcon;
