import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeFormsIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path
                            fill={color}
                            d="M9.443 0L0.671 8.765 0.671 12.667 4.573 12.667 13.344 3.901z"
                            transform="translate(0 .35)"
                        />
                        <path
                            fill={colorInner}
                            d="M2.522 7.75L3.156 7.75 3.156 13.266 2.522 13.266z"
                            transform="translate(0 .35) rotate(-45 2.84 10.508)"
                        />
                        <path
                            fill={colorInner}
                            d="M0.715 6.138L10.025 6.138 10.025 6.771 0.715 6.771z"
                            transform="translate(0 .35) rotate(-45 5.37 6.455)"
                        />
                        <path
                            fill={colorInner}
                            d="M2.238 7.658L11.548 7.658 11.548 8.291 2.238 8.291z"
                            transform="translate(0 .35) rotate(-45 6.893 7.975)"
                        />
                        <path
                            fill={colorInner}
                            d="M9.107 1.163L9.741 1.163 9.741 6.679 9.107 6.679z"
                            transform="translate(0 .35) rotate(-45 9.424 3.921)"
                        />
                        <path
                            fill={colorInner}
                            d="M.671 12.667L.671 11.368 1.976 12.667zM6.53 12.033H13.338000000000001V13.033H6.53zM8.43 10.247H13.338000000000001V11.247H8.43zM10.336 8.461H13.344000000000001V9.461H10.336z"
                            transform="translate(0 .35)"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeFormsIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeFormsIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeFormsIcon;
