import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

import { DEFAULT_COLORS } from 'constants/colors';

const UserMenuStyled = styled.div`
    background-color: ${DEFAULT_COLORS.WHITE};
    ${({ theme }) => theme.media.down('md')`
        border: none;
        height: 100%;
    `};
    ${({ theme }) => theme.media.up('md')`
        border: 1px solid ${DEFAULT_COLORS.BLACK};
        max-height: 25rem;
    `};
    border-radius: 0;
    padding: 1rem;
    overflow: auto;
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('UserMenu.styles')}
`;

export default UserMenuStyled;
