import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ThumbsDownIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon cursor="pointer" {...rest} width={width} height={height} viewBox={viewBox}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.619 21.46a.923.923 0 0 1-.843.54 3.717 3.717 0 0 1-2.609-1.065 3.61 3.61 0 0 1-1.08-2.571v-2.728H4.792c-.4.004-.795-.077-1.159-.239a2.764 2.764 0 0 1-.955-.698 2.689 2.689 0 0 1-.648-2.199l1.273-8.182c.1-.65.436-1.243.944-1.669A2.792 2.792 0 0 1 6.066 2h13.167c.734 0 1.438.287 1.956.799.52.511.811 1.205.811 1.928v6.364c0 .723-.291 1.417-.81 1.928a2.788 2.788 0 0 1-1.957.8h-2.168L13.62 21.46zm1.924-8.744-3.328 7.38a1.85 1.85 0 0 1-.743-.447 1.805 1.805 0 0 1-.54-1.285v-3.637a.916.916 0 0 0-.923-.909H4.778a.933.933 0 0 1-.707-.312.905.905 0 0 1-.216-.733l1.273-8.182a.906.906 0 0 1 .315-.556.93.93 0 0 1 .608-.217h9.492v8.898zm1.845-8.898V12h1.845a.93.93 0 0 0 .652-.266.902.902 0 0 0 .27-.643V4.727a.902.902 0 0 0-.27-.642.93.93 0 0 0-.652-.267h-1.845z"
            fill={color}
        />
    </SvgIcon>
);

ThumbsDownIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

ThumbsDownIcon.defaultProps = {
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
    color: DEFAULT_COLORS.PLACEHOLDER,
};

export default ThumbsDownIcon;
