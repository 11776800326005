import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import styled from 'styled-components';
import _isEmpty from 'lodash/isEmpty';

import Col from 'ravenjs/lib/Col';
import Input from 'ravenjs/lib/Input';
import { callFunc } from 'ravenjs/utils/actions';
import Typography from 'ravenjs/lib/Typography/Typography';
import Row from 'ravenjs/lib/Row/Row';

import { DEFAULT_COLORS } from 'constants/colors';

import AddNewFileIcon from 'components/Icons/AddNewFileIcon';
import CrossIconWithCircle from 'components/Icons/CrossIconWithCircle';
import DisplayRequiredWithIconField from './DisplayRequiredWithIconField';

const StyledContainer = styled.div`
    padding: 6px 14px 80px 11px;
`;

const StyledFileIconWrapper = styled.span`
    position: absolute;
    top: 7px;
    left: 25px;
`;

const StyledCrossIconWrapper = styled.span`
    position: absolute;
    top: 8px;
    right: 25px;
`;

const StyledColContainer = styled(Col)`
    position: relative;
`;

class ChooseFileUploadField extends React.Component {
    static propTypes = {
        /**
         * If `true`, the field is disabled.
         */
        disabled: PropTypes.bool,
        /**
         * The schema object for identifying the field.
         */
        idSchema: PropTypes.object.isRequired,
        /**
         * Callback fired on the change event of the input element.
         */
        onChange: PropTypes.func.isRequired,
        /**
         * If `true`, the field will be readonly.
         */
        readonly: PropTypes.bool,
        /**
         * Is this a required field?
         */
        required: PropTypes.bool,
        /**
         * The JSON uiSchema for this field.
         */
        uiSchema: PropTypes.object.isRequired,
        /**
         * The value for this field.
         */
        value: PropTypes.object,
        /**
         * Callback fired on the blur event of the input element.
         */
        onBlur: PropTypes.func,
        /**
         * formContext of the input element.
         */
        formContext: PropTypes.object,
        /**
         * formData of the input element.
         */
        formData: PropTypes.object,
        /**
         * schema of the form.
         */
        schema: PropTypes.object,
    };

    static defaultProps = {
        disabled: null,
        readonly: null,
        required: null,
        value: null,
        onBlur: null,
        formContext: null,
        formData: null,
        schema: {},
    };

    // Create a reference to the hidden `input` DOM element.
    hiddenFileInputRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            // Create an empty files list.
            filesList: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { formData } = this.props;
        if (!_isEmpty(prevProps.formData) && _isEmpty(formData)) {
            this.setState({ filesList: formData });
        }
    }

    /**
     * Get the `ui:options` for the current field.
     *
     * @method _getUiOptions
     * @private
     * @param  {Object}      schema The schema to extract the options from
     * @return {Object}             The extracted options
     */
    _getUiOptions = schema => _get(schema, 'ui:options', {});

    /**
     * The label for the rendered `Input` component.
     *
     * @method getInputLabel
     * @return {string}
     */
    getInputLabel = () => {
        const { uiSchema, value } = this.props;
        const { filesList } = this.state;
        // Determine the value based on current formData or internal filesList.
        const files = value || filesList;
        // Get the uiOptions
        const options = this._getUiOptions(uiSchema);
        const whiteSpaces = Array(8)
            .fill('\u00A0')
            .join('');
        const { chooseFileLabel = `${whiteSpaces}Choose file...` } = options;
        const count = files?.length || 0;
        // Based on the `count` value, update the label.
        if (!count) {
            return chooseFileLabel;
        } else if (count > 1) {
            return `${count} files selected.`;
        }
        return `${whiteSpaces}${_get(files, '[0].name', '1 file selected.')}`;
    };

    /**
     * Styles for the hidden `input` element
     *
     * @method getHiddenInputStyle
     * @return {Object}
     */
    getHiddenInputStyle = () => ({
        height: 38,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: -1,
    });

    handleOnUploadClick = event => {
        const { formData } = this.props;
        event.preventDefault();
        const { current } = this.hiddenFileInputRef;
        // Invoke the input click() event.
        _isEmpty(formData) && current && typeof current.click === 'function' && current.click();
    };

    handleOnInputChange = event => {
        // Extract the `onChange` handler from props.
        const { onChange } = this.props;
        const setParentState = _get(this.props, 'formContext.handlers.setParentState');
        // Extract the files out of the file input.
        const { current } = this.hiddenFileInputRef;
        const files = current?.files || {};
        // Update the current files list.
        this.setState(
            () => ({
                filesList: files,
            }),
            () => {
                // Invoke the `onChange` handler.
                typeof onChange === 'function' && onChange(files);
            }
        );
        // The use case for this is that if you need to know from the parent if a file has
        // been uploaded to the input field. That way from your parent you can do other checks.
        // For example, if you need a button disabled in your form, you can set the state
        // in order to know when the file has a value. See `CompaniesDeactivate.js` for an example.
        callFunc(setParentState, { selectedFile: current?.files || {} });
    };

    handleOnInputClick = event => {
        event.target.value = null;
    };

    render() {
        const {
            disabled: disabledProps,
            idSchema,
            readonly,
            required,
            uiSchema,
            onBlur,
            formContext,
            formData,
            value,
            onChange,
            schema,
        } = this.props;
        const { filesList } = this.state;
        const files = value || filesList;
        const fileCount = files?.length || 0;

        // Determine if the field is disabled
        const disabled = readonly || disabledProps;
        // Extract couple of fields from the props.
        const options = this._getUiOptions(uiSchema);
        const pristine = _get(formContext, 'pristine', true);
        const taintedFields = _get(formContext, 'taintedFields', []);
        const { $id: id } = idSchema;
        const updateCustomFields = _get(schema, 'updateCustomFields', {});
        const title = _get(updateCustomFields, 'title', '');
        const description = _get(updateCustomFields, 'description', '');

        let showRequired = false;
        if (!_isEmpty(taintedFields)) {
            showRequired =
                (!pristine || taintedFields.includes(id)) && required && _isEmpty(formData);
        }

        const { accept = '*', multiple = false } = options;
        // Render the `FileField` component.

        return (
            <StyledContainer>
                <Typography fontSize="24px" fontWeight="400" lineHeight="29px" fontFamily="Roboto">
                    {title}
                </Typography>
                <Typography
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="23px"
                    fontFamily="Roboto"
                    width="900px"
                >
                    {description}
                </Typography>
                <Row>
                    <StyledColContainer size={12}>
                        <Input
                            onChange={() => {}}
                            onClick={this.handleOnUploadClick}
                            required={required}
                            type="text"
                            value={this.getInputLabel()}
                            showRequired={showRequired}
                            onBlur={
                                typeof onBlur === 'function' &&
                                (event => onBlur(id, event.target.value))
                            }
                            data-testid="file-input-view"
                            style={{
                                color: DEFAULT_COLORS.LINK,
                            }}
                        />
                        <input
                            accept={accept}
                            defaultValue=""
                            disabled={disabled}
                            multiple={multiple}
                            onChange={this.handleOnInputChange}
                            onClick={this.handleOnInputClick}
                            ref={this.hiddenFileInputRef}
                            required={required}
                            style={this.getHiddenInputStyle()}
                            tabIndex={-1}
                            type="file"
                            data-testid="file-upload-input"
                            onBlur={
                                typeof onBlur === 'function' &&
                                (event => onBlur(id, event.target.value))
                            }
                        />
                        <StyledFileIconWrapper onClick={this.handleOnUploadClick}>
                            <AddNewFileIcon
                                fill={
                                    !_isEmpty(formData) ? DEFAULT_COLORS.LINK : DEFAULT_COLORS.BLACK
                                }
                            />
                        </StyledFileIconWrapper>
                        {fileCount ? (
                            <StyledCrossIconWrapper
                                onClick={() => {
                                    this.setState({ filesList: {} }, () => {
                                        typeof onChange === 'function' && onChange({});
                                    });
                                }}
                            >
                                <CrossIconWithCircle />
                            </StyledCrossIconWrapper>
                        ) : null}
                        {showRequired && <DisplayRequiredWithIconField />}
                    </StyledColContainer>
                </Row>
            </StyledContainer>
        );
    }
}

export default ChooseFileUploadField;
