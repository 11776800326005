import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    createEstablishmentAPI,
    fetchEstablishmentsAPI,
    fetchEstablishmentTypeAPI,
    fetchCitiesAPI,
    fetchNaicsAPI,
    fetchCasesAPI,
    fetchEmployeeNamesAPI,
    fetchIllnessTypesAPI,
    fetchWhereOccurredAPI,
    fetchEstablishmentByIdAPI,
    fetchFormEstablishmentsAPI,
    updateEstablishmentAPI,
    deleteEstablishmentByIdAPI,
    fetchBodyPartsAPI,
    getCaseNumberAPI,
    fetchCaseTypesAPI,
    createCaseAPI,
    fetchCaseByIdAPI,
    updateCaseAPI,
    deleteCaseByIdAPI,
    downloadForm300API,
    downloadForm300AAPI,
    downloadFormReportAPI,
} from 'modules/apis';
import * as reportingActions from '../actions';

/**
 * Fetch Establishments
 *
 * @method fetchEstablishmentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEstablishmentsSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        if (params.sort) {
            const order = params.sort.charAt(0);
            params.sort =
                order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        }
        const response = yield call(fetchEstablishmentsAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchEstablishments.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchEstablishments.error(error));
        return error;
    }
}

/**
 * Fetch Establishment Type
 *
 * @method fetchEstablishmentTypeSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEstablishmentTypeSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchEstablishmentTypeAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchEstablishmentType.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchEstablishmentType.error(error));
        return error;
    }
}

/**
 * Fetch Cities
 *
 * @method fetchCitiesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchCitiesSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchCitiesAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchCities.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchCities.error(error));
        return error;
    }
}

/**
 * Fetch Naics
 *
 * @method fetchCitiesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchNaicsSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchNaicsAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchNaics.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchNaics.error(error));
        return error;
    }
}

/**
 * Fetch Cases
 *
 * @method fetchCasesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchCasesSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        const paramsOrder = params.sort.substr(1);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        if (paramsOrder.indexOf(',') !== -1) {
            const paramsSort = paramsOrder.split(',');
            const newSort = paramsSort.map(param => {
                const sort = order === '+' ? `${param} asc` : `${param} desc`;
                return sort;
            });
            params.sort = newSort.join();
        }
        const response = yield call(fetchCasesAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchCases.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchCases.error(error));
        return error;
    }
}

/**
 * Fetch Employee Names
 *
 * @method fetchEmployeeNamesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEmployeeNamesSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchEmployeeNamesAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchEmployeeNames.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchEmployeeNames.error(error));
        return error;
    }
}

/**
 * Fetch Illness Types
 *
 * @method fetchIllnessTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchIllnessTypesSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchIllnessTypesAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchIllnessTypes.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchIllnessTypes.error(error));
        return error;
    }
}

/**
 * Fetch Where Occurred
 *
 * @method fetchWhereOccurredSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchWhereOccurredSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchWhereOccurredAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchWhereOccurred.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchWhereOccurred.error(error));
        return error;
    }
}

/**
 * Function for create establishment
 *
 * @method createEstablishmentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* createEstablishmentSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(createEstablishmentAPI, params);
        const establishemt = get(response, 'data', {});
        yield put(reportingActions.createEstablishment.success(establishemt));
        return establishemt;
    } catch (error) {
        yield put(reportingActions.createEstablishment.error(error));
        return error;
    }
}

/**
 * Fetch a establshment
 *
 * @method fetchEstablishmentByIdSaga
 * @type   {Generator}
 * @param  {Object}  action The redux action
 */
export function* fetchEstablishmentByIdSaga(action) {
    try {
        const {
            payload: { establishmentId },
        } = action;
        const response = yield call(fetchEstablishmentByIdAPI, establishmentId);
        const establishment = get(response, 'data', {});
        yield put(reportingActions.fetchEstablishmentById.success(establishment));
    } catch (error) {
        yield put(reportingActions.fetchEstablishmentById.error(error));
    }
}

/**
 * Update a establshment
 *
 * @method updateEstablishmentSaga
 * @type   {Generator}
 * @param  {Object}  action The redux action
 * @return {Object} The data or error
 */
export function* updateEstablishmentSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(updateEstablishmentAPI, params);
        const establishemt = get(response, 'data', {});
        yield put(reportingActions.updateEstablishment.success(establishemt));
        return establishemt;
    } catch (error) {
        yield put(reportingActions.updateEstablishment.error(error));
        return error;
    }
}

/**
 * Delete a establshment
 *
 * @method deleteEstablishmentByIdSaga
 * @type   {Generator}
 * @param  {Object}  action The redux action
 */
export function* deleteEstablishmentByIdSaga(action) {
    try {
        const {
            payload: { establishmentId },
        } = action;
        const response = yield call(deleteEstablishmentByIdAPI, establishmentId);
        const establishment = get(response, 'data', {});
        yield put(reportingActions.deleteEstablishmentById.success(establishment));
    } catch (error) {
        yield put(reportingActions.deleteEstablishmentById.error(error));
    }
}

/**
 * Fetch body parts
 *
 * @method fetchBodyPartsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchBodyPartsSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchBodyPartsAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchBodyParts.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchBodyParts.error(error));
        return error;
    }
}

/**
 * Get case number
 *
 * @method getCaseNumberSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* getCaseNumberSaga(action) {
    try {
        const response = yield call(getCaseNumberAPI);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.getCaseNumber.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.getCaseNumber.error(error));
        return error;
    }
}

/**
 * Fetch case types
 *
 * @method fetchCaseTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchCaseTypesSaga(action) {
    try {
        const { payload: params } = action;
        params.limit = params.limit || 10;
        const order = params.sort.charAt(0);
        params.sort =
            order === '+' ? `${params.sort.substr(1)} asc` : `${params.sort.substr(1)} desc`;
        const response = yield call(fetchCaseTypesAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.fetchCaseTypes.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchCaseTypes.error(error));
        return error;
    }
}

/**
 * Function for create case
 *
 * @method createCaseSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* createCaseSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(createCaseAPI, params);
        const data = get(response, 'data', {});
        yield put(reportingActions.createCase.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.createCase.error(error));
        return error;
    }
}

/**
 * Fetch a case
 *
 * @method fetchCaseByIdAPI
 * @type   {Generator}
 * @param  {Object}  action The redux action
 */
export function* fetchCaseByIdSaga(action) {
    try {
        const {
            payload: { caseId },
        } = action;
        const response = yield call(fetchCaseByIdAPI, caseId);
        const data = get(response, 'data', {});
        yield put(reportingActions.fetchCaseById.success(data));
    } catch (error) {
        yield put(reportingActions.fetchCaseById.error(error));
    }
}

/**
 * Update a case
 *
 * @method updateCaseSaga
 * @type   {Generator}
 * @param  {Object}  action The redux action
 * @return {Object} The data or error
 */
export function* updateCaseSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(updateCaseAPI, params);
        const data = get(response, 'data', {});
        yield put(reportingActions.updateCase.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.updateCase.error(error));
        return error;
    }
}

/**
 * Delete a case
 *
 * @method deleteCaseByIdSaga
 * @type   {Generator}
 * @param  {Object}  action The redux action
 */
export function* deleteCaseByIdSaga(action) {
    try {
        const {
            payload: { caseId },
        } = action;
        const response = yield call(deleteCaseByIdAPI, caseId);
        const data = get(response, 'data', {});
        yield put(reportingActions.deleteCaseById.success(data));
    } catch (error) {
        yield put(reportingActions.deleteCaseById.error(error));
    }
}

/**
 * Fetch form establishments
 *
 * @method fetchFormEstablishmentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchFormEstablishmentsSaga(action) {
    try {
        const response = yield call(fetchFormEstablishmentsAPI);
        const data = get(response, 'data.results.list', []);
        yield put(reportingActions.fetchFormEstablishments.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchFormEstablishments.error(error));
        return error;
    }
}

/**
 * Download Form 300
 *
 * @method downloadForm300Saga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* downloadForm300Saga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(downloadForm300API, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.downloadForm300.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.downloadForm300.error(error));
        return error;
    }
}

/**
 * Download Form 300A
 *
 * @method downloadForm300ASaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* downloadForm300ASaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(downloadForm300AAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.downloadForm300A.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.downloadForm300A.error(error));
        return error;
    }
}

/**
 * Download Form Report
 *
 * @method downloadForm300ASaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* downloadFormReportSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(downloadFormReportAPI, params);
        const data = get(response, 'data.results', {});
        yield put(reportingActions.downloadFormReport.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.downloadFormReport.error(error));
        return error;
    }
}
