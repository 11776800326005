import { call, put } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import { fetchInformationBannerAPI } from 'modules/apis';
import * as adminActions from '../actions';

/**
 * Saga to fetch information banner from contentful
 *
 * @method fetchInformationBannerSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchInformationBannerSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchInformationBannerAPI, params);
        const informationBanner = get(response, 'data.information-banner');
        yield put(adminActions.fetchInformationBanner.success(informationBanner));
        return informationBanner;
    } catch (error) {
        yield put(adminActions.fetchInformationBanner.error(error));
        return error;
    }
}
