import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ActiveIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <circle cx="4" cy="4.5" r="4" fill={fill} />
        </SvgIcon>
    );
};

ActiveIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ActiveIcon.defaultProps = {
    fill: DEFAULT_COLORS.GREEN_3,
    height: '8',
    viewBox: '0 0 8 8',
    width: '8',
};

export default ActiveIcon;
