import _sortBy from 'lodash/sortBy';
import React from 'react';
import { array, bool, string } from 'prop-types';
import styled from 'styled-components';

import { isEmpty } from 'ravenjs/utils/lodash';
import Typography from 'ravenjs/lib/Typography';

import IdentifierPatch from 'components/IdentifierPatch';

import { DEFAULT_COLORS } from 'constants/colors';
import { CATEGORY_NAMES } from 'constants/content';
import { EHS_CATEGORY_ID } from 'constants/safety';
import { convertWagesAndHoursToWageAndHour } from 'utils/filters';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: 0.625rem;
    font-weight: bold;
    margin: ${({ margin }) => margin};
    min-width: 200px;
`;

const CategoryLabel = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 0.5rem 0 0 0;
    width: ${({ width }) => width};
    &:first-child {
        margin-top: 0;
    }
`;

const CategoryName = styled(Typography)`
    display: flex;
`;

const CategoriesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const CategoryLabels = ({ categories, margin, showSubcategories, states, ehsTopics }) => {
    const formattedCategories = _sortBy(categories, ['name'])
        .filter(category => category.name !== CATEGORY_NAMES.OTHER)
        .map(category => {
            return {
                id: category.id,
                name: category.name,
                color: category.color,
                subcategories: category.subcategories,
            };
        });

    const renderStates = states => {
        if (isEmpty(states)) {
            return false;
        }
        return (
            <>
                <Typography
                    gutterBottom="0"
                    gutterLeft="0.25rem"
                    gutterRight="0.25rem"
                    gutterTop="0"
                    type="categoryTags"
                >
                    :
                </Typography>
                <Typography gutterBottom="0" gutterTop="0" type="categoryTags">
                    {states ? states.toUpperCase() : ''}
                </Typography>
            </>
        );
    };

    const renderTopics = topics => {
        if (isEmpty(topics)) {
            return <></>;
        }
        const totalTopics = topics.length;
        let i = 0;
        return (
            <>
                <Typography
                    gutterBottom="0"
                    gutterLeft="0.25rem"
                    gutterRight="0.25rem"
                    gutterTop="0"
                    type="categoryTags"
                >
                    :
                </Typography>
                {topics.map(topic => {
                    i += 1;
                    return (
                        !isEmpty(topic.name) && (
                            <React.Fragment key={topic.id}>
                                <Typography gutterBottom="0" gutterTop="0" type="categoryTags">
                                    {topic.name.toUpperCase()}
                                </Typography>
                                {i !== totalTopics && (
                                    <Typography
                                        gutterTop="0"
                                        gutterBottom="0"
                                        gutterLeft="0.5rem"
                                        gutterRight="0.5rem"
                                        color={DEFAULT_COLORS.LIGHTLY}
                                    >
                                        |
                                    </Typography>
                                )}
                            </React.Fragment>
                        )
                    );
                })}
            </>
        );
    };

    const renderCategoriesAndSubcategories = (mainCategory, states) => {
        return (
            <CategoryLabel key={mainCategory.id} width="100%">
                {mainCategory.color && (
                    <IdentifierPatch
                        size="0.6875rem"
                        borderRadius="1rem"
                        color={mainCategory.color}
                        flex="0 1 auto"
                        margin="0.3125rem 0.552rem 0 0"
                        minWidth="0.6875rem"
                    />
                )}
                <CategoriesContainer>
                    {mainCategory.name && (
                        <CategoryName gutterBottom="0" gutterTop="0" type="categoryTags">
                            {mainCategory.name.toUpperCase()}
                        </CategoryName>
                    )}
                    {mainCategory.subcategories.map(item => {
                        return (
                            !isEmpty(item.name) && (
                                <React.Fragment key={item.id}>
                                    <Typography
                                        gutterTop="0"
                                        gutterBottom="0"
                                        gutterLeft="0.5rem"
                                        gutterRight="0.5rem"
                                        color={DEFAULT_COLORS.LIGHTLY}
                                    >
                                        |
                                    </Typography>
                                    <CategoryName
                                        gutterBottom="0"
                                        gutterTop="0"
                                        type="categoryTags"
                                    >
                                        {item.name.toUpperCase()}
                                    </CategoryName>
                                </React.Fragment>
                            )
                        );
                    })}
                    {mainCategory.id !== EHS_CATEGORY_ID
                        ? renderStates(states)
                        : renderTopics(ehsTopics)}
                </CategoriesContainer>
            </CategoryLabel>
        );
    };

    convertWagesAndHoursToWageAndHour(formattedCategories, 'name');

    return (
        <Container margin={margin}>
            {formattedCategories.map(category => {
                return !showSubcategories || isEmpty(category.subcategories) ? (
                    <CategoryLabel key={category.id} width="100%">
                        {!isEmpty(category.color) && (
                            <IdentifierPatch
                                size="0.6875rem"
                                borderRadius="1rem"
                                color={category.color}
                                flex="0 1 auto"
                                margin="0.125rem 0.552rem 0 0"
                                minWidth="0.6875rem"
                            />
                        )}
                        {category.name && (
                            <CategoryName type="categoryTags" gutterBottom="0.25rem" gutterTop="0">
                                {category.name.toUpperCase()}
                            </CategoryName>
                        )}
                        {renderStates(states)}
                    </CategoryLabel>
                ) : (
                    renderCategoriesAndSubcategories(category, states)
                );
            })}
        </Container>
    );
};

CategoryLabels.propTypes = {
    categories: array,
    margin: string,
    showSubcategories: bool,
    states: string,
    ehsTopics: array,
};

CategoryLabels.defaultProps = {
    categories: [],
    margin: '0.125rem 0 0 0',
    showSubcategories: true,
    states: '',
    ehsTopics: [],
};

export default CategoryLabels;
