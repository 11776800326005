import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { usePopper } from 'react-popper';

import Portal from 'ravenjs/lib/Portal';
import { COLORS } from 'ravenjs/constants/colors';

export const TooltipArrow = styled.div`
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    &::after,
    &::before {
        position: absolute;
        display: block;
        content: '';
        border-color: transparent;
        border-style: solid;
    }
`;

const TipWrapper = styled.div`
    width: ${({ width }) => width};
    background: ${({ color, backgroundColor }) => backgroundColor || color};
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: ${({ borderRadius }) => borderRadius};
    color: ${({ textColor }) => textColor || COLORS.BLACK};
    padding: 0.5rem;
    font-weight: ${({ fontWeight }) => fontWeight};
    font-size: 1rem;
    z-index: 9999;
    box-shadow: ${({ boxShadow }) => boxShadow};
    &[data-popper-placement^='right'] {
        ${TooltipArrow} {
            left: calc(-0.5rem - 1px);
            width: 0.5rem;
            height: 1rem;
            &::before {
                left: 0;
                border-width: 0.5rem 0.5rem 0.5rem 0;
                border-right-color: ${({ borderColor }) => borderColor};
            }
            &::after {
                left: 1px;
                border-width: 0.5rem 0.5rem 0.5rem 0;
                border-right-color: ${({ color, backgroundColor }) => backgroundColor || color};
            }
        }
    }
    &[data-popper-placement^='left'] {
        ${TooltipArrow} {
            right: calc(-0.5rem - 1px);
            width: 0.5rem;
            height: 1rem;
            &::before {
                right: 0;
                border-width: 0.5rem 0 0.5rem 0.5rem;
                border-left-color: ${({ borderColor }) => borderColor};
            }
            &::after {
                right: 1px;
                border-width: 0.5rem 0 0.5rem 0.5rem;
                border-left-color: ${({ color, backgroundColor }) => backgroundColor || color};
            }
        }
    }
    &[data-popper-placement^='top'] {
        ${TooltipArrow} {
            bottom: calc(-0.5rem - 1px);
            &::before {
                bottom: 0;
                border-width: 0.5rem 0.5rem 0;
                border-top-color: ${({ borderColor }) => borderColor};
            }
            &::after {
                bottom: 1px;
                border-width: 0.5rem 0.5rem 0;
                border-top-color: ${({ color, backgroundColor }) => backgroundColor || color};
            }
        }
    }
    &[data-popper-placement^='bottom'] {
        ${TooltipArrow} {
            top: calc(-0.5rem - 1px);
            &::before {
                top: 0;
                border-width: 0 0.5rem 0.5rem 0.5rem;
                border-bottom-color: ${({ borderColor }) => borderColor};
            }
            &::after {
                top: 1px;
                border-width: 0 0.5rem 0.5rem 0.5rem;
                border-bottom-color: ${({ color, backgroundColor }) => backgroundColor || color};
            }
        }
    }
`;

const TooltipNew = ({
    children,
    color,
    borderColor,
    borderRadius,
    fontWeight,
    hideTooltip,
    offset,
    placement,
    trigger,
    width,
    boxShadow,
    textAlign,
    id,
    applyNewTooltipStyle,
    textColor,
    backgroundColor,
}) => {
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(trigger, popperElement, {
        placement,
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            {
                name: 'offset',
                options: {
                    offset,
                },
            },
        ],
    });

    return (
        <Portal>
            <TipWrapper
                id={id}
                ref={setPopperElement}
                color={color}
                borderColor={borderColor}
                borderRadius={borderRadius}
                fontWeight={fontWeight}
                style={styles.popper}
                width={width}
                boxShadow={boxShadow}
                textAlign={textAlign}
                {...attributes.popper}
                applyNewTooltipStyle={applyNewTooltipStyle}
                textColor={textColor}
                backgroundColor={backgroundColor}
            >
                {children}
                {!hideTooltip && (
                    <TooltipArrow ref={setArrowElement} color={COLORS.BLACK} style={styles.arrow} />
                )}
            </TipWrapper>
        </Portal>
    );
};

TooltipNew.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.string,
    fontWeight: PropTypes.string,
    hideTooltip: PropTypes.bool,
    offset: PropTypes.array,
    placement: PropTypes.string,
    width: PropTypes.string,
    trigger: PropTypes.object,
    boxShadow: PropTypes.string,
    textAlign: PropTypes.string,
    id: PropTypes.string,
    applyNewTooltipStyle: PropTypes.bool,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
};

TooltipNew.defaultProps = {
    children: null,
    color: '',
    borderColor: COLORS.BLACK,
    borderRadius: '0.25rem',
    fontWeight: '500',
    hideTooltip: false,
    offset: [0, 8],
    placement: '',
    width: '',
    trigger: null,
    boxShadow: '',
    textAlign: 'center',
    id: '',
    applyNewTooltipStyle: true,
    textColor: null,
    backgroundColor: null,
};

export default TooltipNew;
