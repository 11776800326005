import { ROLE_TYPE } from '../constants/roles';

/**
 * Util to transform input data in the <Form> component
 *
 * @param   {Object}    data
 * @return  {Object}
 */
export const transformRoleFormDataIn = data => {
    const {
        administrator,
        description,
        name,
        partnerId,
        partnerName,
        permissions,
        type,
        id,
    } = data;

    return {
        roleInfo: {
            administrator,
            description,
            name,
            type,
            id,
        },
        partnerConfig: {
            partnerInfo: {
                id: partnerId,
                name: partnerName,
            },
        },
        permissionConfig: permissions,
    };
};

/**
 * Util to transform output data from the <Form> component
 *
 * @param   {Object}    data
 * @return  {Object}
 */
export const transformRoleFormDataOut = data => {
    const { roleInfo, partnerConfig, permissionConfig: permissions } = data;
    const { description, administrator = false, name, type, id } = roleInfo;
    const { partnerInfo } = partnerConfig;
    const { id: partnerId } = partnerInfo;

    return {
        id,
        name,
        description,
        type,
        administrator,
        partnerId,
        permissions,
    };
};

/**
 * Get list of roles that have given permission
 *
 * @param   {Array}     roles
 * @param   {string}    permissionKey
 * @return  {Array}
 */
export const getRolesThatHavePermission = (roles, permissionKey) => {
    const rolesWithPermission = [];

    roles.forEach(role => {
        if (roleHasPermission(role, permissionKey)) {
            rolesWithPermission.push(role);
        }
    });

    return rolesWithPermission;
};

/**
 * Check whether role has given permission
 *
 * @param   {Object}    role
 * @param   {string}    permissionKey
 * @return  {boolean}
 */
export const roleHasPermission = (role, permissionKey) => {
    const { permissions = [] } = role;
    return Boolean(permissions.find(permission => permission.permissionKey === permissionKey));
};

export const getParamsForRolesBasedOnCompanyType = ({ ...params }) => {
    const { partnerId, companyId, companyInfo } = params;
    const { companyType = '' } = companyInfo;
    const roleTypeToParamMap = {
        [MAESTER.companies.root_company_id]: ROLE_TYPE.ROOT,
        [ROLE_TYPE.PARTNER]: [ROLE_TYPE.PARTNER, ROLE_TYPE.CLIENT],
        [ROLE_TYPE.CLIENT]: [ROLE_TYPE.CLIENT],
    };

    return {
        partnerId,
        type: roleTypeToParamMap[companyId]
            ? roleTypeToParamMap[companyId]
            : roleTypeToParamMap[companyType],
        companyId,
        optionApiRoles: true,
        isDemoCompany: true,
    };
};
