import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the list of TODO items.
 *
 * @type {Object}
 */
export const fetchToDoList = createActions('toDoList', 'FETCH_TODO_LIST', {
    asPromise: true,
});

/**
 * Redux actions for updating the TODO item.
 *
 * @type {Object}
 */
export const updateToDoList = createActions('toDoList', 'UPDATE_TODO_LIST', {
    asPromise: true,
});

/**
 * Redux actions for creating the TODO item.
 *
 * @type {Object}
 */
export const createToDoList = createActions('toDoList', 'CREATE_TODO_LIST', {
    asPromise: true,
});
