import { bool, func, object, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Button from 'ravenjs/lib/Button';
import { get } from 'ravenjs/utils/lodash';

import MESSAGES from 'constants/messages';
import { MODALS } from 'constants/modals';
import { actions as uiActions } from 'modules/ui';
import { isPublicRoute } from 'utils/thrservice';
import ImpersonateUserHeaderStyled from './ImpersonateUserHeaderStyled';

const ImpersonateUserHeader = ({
    authorized,
    closeModal,
    isImpersonatingUser,
    openModal,
    history,
    location,
    user,
}) => {
    const pathname = get(location, 'pathname', '');
    const handlerEndImpersonateUser = () => {
        openModal(MODALS.CONFIRM, {
            title: MESSAGES.MODAL.CONFIRM.TITLE.CAUTION,
            description: `Are you sure you would like to end this impersonation session?`,
            actions: [
                {
                    text: 'CANCEL',
                    color: 'secondary',
                    onClick() {
                        closeModal(MODALS.CONFIRM);
                    },
                },
                {
                    text: 'OK',
                    color: 'primary',
                    onClick() {
                        closeModal(MODALS.CONFIRM);
                        history.push('/impersonation/end');
                    },
                },
            ],
        });
    };

    return (
        !isPublicRoute(pathname) &&
        authorized &&
        isImpersonatingUser && (
            <ImpersonateUserHeaderStyled className="impersonatingBar">
                <span>
                    <strong>You are currently impersonating:</strong> {user.fullName}
                </span>
                <Button onClick={handlerEndImpersonateUser}>End Session</Button>
            </ImpersonateUserHeaderStyled>
        )
    );
};

ImpersonateUserHeader.propTypes = {
    authorized: bool,
    closeModal: func.isRequired,
    isImpersonatingUser: bool,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    openModal: func.isRequired,
    user: object,
    history: object.isRequired,
};

ImpersonateUserHeader.defaultProps = {
    authorized: false,
    isImpersonatingUser: false,
    user: {},
};

const mapDispatchToProps = {
    closeModal: uiActions.closeModal,
    openModal: uiActions.openModal,
};

export { ImpersonateUserHeader as UnwrappedImpersonateUserHeader };
export default connect(null, mapDispatchToProps)(withRouter(ImpersonateUserHeader));
