import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the list of events to calendar.
 *
 * @type {Object}
 */
export const fetchCalendarEvents = createActions('calendar', 'FETCH_CALENDAR_EVENTS', {
    asPromise: true,
});

/**
 * Redux actions for fetch the detail of event.
 *
 * @type {Object}
 */
export const fetchCalendarEventDetails = createActions('calendar', 'FETCH_CALENDAR_EVENT_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions for create calendar event.
 *
 * @type {Object}
 */
export const createCalendarEvent = createActions('calendar', 'CREATE_CALENDAR_EVENT', {
    asPromise: true,
});

/**
 * Redux actions for update calendar event.
 *
 * @type {Object}
 */
export const updateCalendarEvent = createActions('calendar', 'UPDATE_CALENDAR_EVENT', {
    asPromise: true,
});

/**
 * Redux actions for download calendar events.
 *
 * @type {Object}
 */
export const downloadCalendarEvents = createActions('calendar', 'DOWNLOAD_CALENDAR_EVENTS', {
    asPromise: true,
});

/**
 * Redux actions for delete calendar event.
 *
 * @type {Object}
 */
export const deleteCalendarEvent = createActions('calendar', 'DELETE_CALENDAR_EVENT', {
    asPromise: true,
});
