import _get from 'lodash/get';

import CUSTOM_LOGIN_WIDGET from 'constants/customLoginWidget';

/**
 * Reducer helper for the `fetchBranding` success action.
 *
 * @method fetchBrandingSuccess
 * @param  {Object}              state  The 'branding' state
 * @param  {Object}              action The fired action
 * @return {Object}                     The updated state
 */
export const fetchBrandingSuccess = (state, action) => {
    return {
        ...state,
        ..._get(action, 'payload', {}),
        isDefault: false,
    };
};

/**
 * Reducer helper for the `fetchCustomLoginWidget` success action.
 *
 * @method fetchCustomLoginWidgetSuccess
 * @param  {Object} state The 'branding' state
 * @param  {Object} action The fired action
 * @return {Object} The updated state
 */
export const fetchCustomLoginWidgetSuccess = (state, action) => {
    return {
        ...state,
        customLoginWidget: {
            ...state.customLoginWidget,
            partner: {
                ...state.customLoginWidget.partner,
                ..._get(
                    action,
                    'payload.customLoginWidget',
                    CUSTOM_LOGIN_WIDGET.INITIAL_PROPERTIES
                ),
            },
        },
    };
};

/**
 * Reducer helper for the `fetchDefaultCustomLoginWidget` success action.
 *
 * @method fetchDefaultCustomLoginWidgetSuccess
 * @param  {Object} state The 'branding' state
 * @param  {Object} action The fired action
 * @return {Object} The updated state
 */
export const fetchDefaultCustomLoginWidgetSuccess = (state, action) => {
    return {
        ...state,
        customLoginWidget: {
            ...state.customLoginWidget,
            default: _get(
                action,
                'payload.customLoginWidget',
                CUSTOM_LOGIN_WIDGET.INITIAL_PROPERTIES
            ),
        },
    };
};

/**
 * Reducer helper for the `setIsUsingTemporaryTheme` success action.
 *
 * @method setIsUsingTemporaryThemeSuccess
 * @param  {Object} state  The 'branding' state
 * @param  {Object} action The fired action
 * @return {Object} The updated state
 */
export const setIsUsingTemporaryThemeSuccess = (state, action) => {
    return {
        ...state,
        isUsingTemporaryTheme: _get(action, 'payload', false),
    };
};
