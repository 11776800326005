/**
 * Create a reducer based on an initial state and a map of reducer handlers.
 *
 * @method createReducer
 * @see    {@link https://redux.js.org/recipes/reducing-boilerplate#generating-reducers}
 * @param  {any}           initialState The value to set the initial state with
 * @param  {Object}        handlers     The map of action handlers
 * @return {Function|any}               The reducer function or default state
 */
export function createReducer(initialState, handlers) {
    // Return the reducer fn with (state, action).
    return function reducer(state = initialState, action) {
        // Determine if the fired action exists in the list of handlers.
        if (action && Object.prototype.hasOwnProperty.call(handlers, action.type)) {
            // If action exists, then invoke the handler with state and action.
            return handlers[action.type](state, action);
        }
        // Otherwise, return the original state.
        return state;
    };
}
