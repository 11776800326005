import { takeLatest } from 'redux-saga/effects';

import * as legalAndPrivacyActions from '../actions';

import { fetchResourceByTypeSaga } from './legalAndPrivacy';

/**
 * Root saga for `Legal and privacy`
 * Triggers sagas related to all legal and privacy policy resources.
 *
 * @method legalAndPrivacySaga
 * @type   {Generator}
 */
function* legalAndPrivacySaga() {
    yield takeLatest(legalAndPrivacyActions.fetchResourceByType.TRIGGER, fetchResourceByTypeSaga);
}

export default legalAndPrivacySaga;
