export const STATUS = {
    READ: 'READ',
    UNREAD: 'UNREAD',
    DELETE: 'DELETED',
};

export const NOTIFICATION_TYPES = {
    lawalerts: 'Law alerts',
    taskactive: 'Task active',
    taskassigned: 'Task assigned',
    ticketpendingfollowup: 'Case pending follow up',
    ticketclosed: 'Case closed',
    newticket: 'New Case',
    whatsnew: `What's new`,
    fitnesstest: 'Fitness test report',
    handbooks: 'Handbooks',
    broadcastpreview: 'Broadcast preview',
    vPArequest: 'VPA Request',
    upcomingCommunication: 'Upcoming Communication',
};
