import styled from 'styled-components';

import { COLORS } from 'ravenjs/constants/colors';
import Input from 'ravenjs/lib/Input';
import { getThemeProps } from 'ravenjs/utils/theme';

const InputStyled = styled(Input)`
    background: ${COLORS.GREY_4};
    border-color: ${COLORS.GREY_4};
    border-radius: 4px;
    color: ${COLORS.GREY};
    display: inline-block;
    height: 1.375rem !important;
    line-height: normal;
    margin: 0 0.688rem;
    padding: 2px;
    text-align: center;
    width: ${({ width }) => width || '4rem'};

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
    :focus {
        border-color: ${COLORS.GREY_4};
        box-shadow: none;
    }
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('PaginationActionsCurrentPage.styles')};
`;

export default InputStyled;
