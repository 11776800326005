import axios from 'axios';
import Qs from 'qs';

/**
 * API to create role
 *
 * @method createRole
 * @param  {Object}  data
 * @return {Promise}
 */
export const createRole = data =>
    axios({
        method: 'post',
        url: '/v2/roles',
        data,
    });

/**
 * API to delete role
 *
 * @method deleteRole
 * @param  {number}  id
 * @return {Promise}
 */
export const deleteRole = id =>
    axios({
        method: 'delete',
        url: `/v2/roles/${id}`,
    });

/**
 * API to fetch role bu id
 *
 * @method fetchRole
 * @param  {number}  id
 * @return {Promise}
 */
export const fetchRole = id =>
    axios({
        method: 'get',
        url: `/v2/roles/${id}`,
    });

/**
 * API to fetch roles
 *
 * @method fetchRolesAPI
 * @param  {Object}             data
 * @return {Promise}
 */
export const fetchRolesAPI = data => {
    return axios({
        method: 'get',
        url: '/v2/roles',
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        params: data,
    });
};

/**
 * Fetch roles for newsletter categories
 *
 * @method fetchRolesForNewsletterSubscription
 * @param  {string}     permissionKey
 * @return {Promise}
 */
export const fetchRolesByPermission = permissionKey =>
    axios({
        method: 'get',
        url: '/v2/roles',
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        params: {
            permissionKey,
        },
    });

/**
 * API to update role
 *
 * @method updateRole
 * @param  {number}  id
 * @param  {Object}  data
 * @return {Promise}
 */
export const updateRole = (id, data) =>
    axios({
        method: 'put',
        url: `/v2/roles/${id}`,
        data,
    });
