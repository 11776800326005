import _find from 'lodash/find';
import _get from 'lodash/get';

import { get, isEmpty } from 'ravenjs/utils/lodash';
import MESSAGES from 'constants/messages';
import { MODALS } from 'constants/modals';
import { APP_PERMISSIONS } from 'constants/permissions';
import { extractErrorMessage } from 'utils/errors';
import { PENDO_API_KEY } from 'constants/api';

/* eslint-disable */
/* istanbul ignore next */
// ref: https://developers.pendo.io/docs
export function loadPendo(apiKey, propsToInitialize) {
    (function(p, e, n, d, o) {
        let v;
        let w;
        let x;
        let y;
        let z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
            (function(m) {
                o[m] =
                    o[m] ||
                    function() {
                        o._q[m === v[0] ? 'unshift' : 'push'](
                            [m].concat([].slice.call(arguments, 0))
                        );
                    };
            })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = `https://content.analytics.trustmineral.com/agent/static/${apiKey}/pendo.js`;
        if (e) {
            z = e.getElementsByTagName(n)[0];
            if (z) z.parentNode.insertBefore(y, z);
        }
    })(window, document, 'script', 'pendo');

    if (!isEmpty(propsToInitialize)) {
        activatePendo(propsToInitialize);
    }
}
/* eslint-enable */

let pendoInitialized = false;
let pendoUserId = '';

export function initializePendo({
    accountId = '',
    clearUser = false,
    userId = '',
    userPermissions = '',
    // additional data, phase 0
    email = '',
    firstName = '',
    lastName = '',
    phone = '',
    userName = '',
    // additional data, phase 1
    city = '',
    clientId = '',
    clientName = '',
    customerSource = '',
    industry = '',
    partnerId = '',
    partnerName = '',
    productsAvailableToSell = '',
    role = '',
    salesforceId = '',
    skus = '',
    userLocationCompanyState = '',
    userLocationPrimary = '',
    // additional data, phase 2
    coBrandPlus = false,
    companyCreatedOn = '',
    mineralExperts = '',
    premium = '',
    whiteLabeling = '',
    // additional data, post phase 2
    clientUserCreate = false,
    employeeCount = 0,
    gtmModel = '',
    licenses = [],
    readonlyLocations = false,
    stateLocations = [],
}) {
    if (typeof pendo === 'undefined') {
        return;
    }
    const skuData = skus ? JSON.stringify(skus.map(sku => ({ name: sku.name, id: sku.id }))) : '';

    const pendoPayload = {
        visitor: {
            id: userId,
            permissions: userPermissions ? JSON.stringify(userPermissions) : '',
            // additional data, phase 0
            email,
            firstName,
            lastName,
            phone,
            userName,
            // additional data, phase 1
            city,
            productsAvailableToSell: productsAvailableToSell
                ? JSON.stringify(productsAvailableToSell)
                : '',
            role,
            userLocationCompanyState,
            userLocationPrimary: userLocationPrimary ? JSON.stringify(userLocationPrimary) : '',
        },
        account: {
            id: accountId,
            // additional data, phase 1
            clientId,
            clientName,
            customerSource,
            industry,
            partnerId,
            partnerName,
            salesforceId,
            skus: skuData,
            // additional data, phase 2
            coBrandPlus,
            companyCreatedOn,
            mineralExperts,
            premium,
            whiteLabeling,
            // additional data, post phase 2
            clientUserCreate,
            employeeCount,
            gtmModel,
            licenses,
            readonlyLocations,
            stateLocations,
        },
    };

    const pendoClearPayload = {
        visitor: {
            id: 0,
            permissions: '',
            // additional data, phase 0
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            userName: '',
            // additional data, phase 1
            city: '',
            productsAvailableToSell: '',
            role: '',
            userLocationCompanyState: '',
            userLocationPrimary: '',
        },
        account: {
            id: 0,
            // additional data, phase 1
            clientId: '',
            clientName: '',
            customerSource: '',
            industry: '',
            partnerId: '',
            partnerName: '',
            salesforceId: '',
            skus: '',
            // additional data, phase 2
            coBrandPlus: false,
            companyCreatedOn: '',
            mineralExperts: '',
            premium: '',
            whiteLabeling: '',
            // additional data, post phase 2
            clientUserCreate: false,
            employeeCount: '',
            gtmModel: '',
            licenses: [],
            readonlyLocations: false,
            stateLocations: [],
        },
    };

    if (clearUser) {
        pendoUserId = 0;
        pendo.identify(pendoClearPayload);
    } else if (!pendoInitialized) {
        pendoInitialized = true;
        pendoUserId = userId;
        pendo.initialize(pendoPayload);
    } else if (userId !== pendoUserId) {
        pendoUserId = userId;
        pendo.identify(pendoPayload);
    }
}

/* istanbul ignore next */
export const activatePendo = activatePendoProps => {
    const {
        Acl,
        closeModal,
        loggedIn,
        openModal,
        user,
        userCompany,
        userCompanyLocations,
        userConfiguration,
        userId,
        userPermissions,
    } = activatePendoProps;

    if (!PENDO_API_KEY) {
        return;
    }

    if (loggedIn) {
        const {
            companyId,
            companyName,
            gtmModel = '',
            licenses = [],
            location,
            partnerId,
            partnerName,
        } = userCompany;
        const isPartnerCompany = partnerId === companyId;
        const accountId = isPartnerCompany ? partnerId : companyId;
        const { city } = location;

        try {
            const userLocationPrimary = _find(userCompanyLocations, { isPrimary: true }) || {};
            const { skusAvailableToAdd } = userConfiguration;
            const companyCreatedOn = userCompany.createdOn
                ? toIsoString(new Date(userCompany.createdOn))
                : '';
            const employeeCount = get(userLocationPrimary, 'employeeCount', 0);
            let stateLocations = userCompanyLocations
                .map(({ stateCode }) => {
                    return stateCode;
                })
                .filter(stateCode => stateCode !== undefined);
            stateLocations = stateLocations.filter((item, index) => {
                return stateLocations.indexOf(item) === index;
            });
            const clientUserCreate = !_get(userCompany, 'disableClientUserCreation', true);
            const readonlyLocations = _get(userCompany, 'verifiedAPI', false);
            const disableLocationSettings = get(userCompany, 'disableLocationSettings', false);
            const readOnlyLocationSettings = readonlyLocations || disableLocationSettings;

            const licenseNames = licenses
                .map(license => get(license, 'name', ''))
                .filter(licenseName => !isEmpty(licenseName))
                .sort();

            const pendoData = {
                accountId,
                city,
                clientId: companyId,
                clientName: companyName,
                coBrandPlus: false,
                companyCreatedOn,
                customerSource: user.source,
                email: user.email,
                firstName: user.firstName,
                mineralExperts: Boolean(userCompany.skus?.find(sku => sku.key === 'askthepro')),
                industry: userCompany.location.industry,
                lastName: user.lastName,
                partnerId,
                partnerName,
                phone: user.phone,
                premium: Acl.check(APP_PERMISSIONS.premium),
                productsAvailableToSell: skusAvailableToAdd,
                role: user.role,
                salesforceId: user.salesforceId,
                skus: userCompany.skus,
                userId,
                userLocationCompanyState: userCompany.location.state,
                userLocationPrimary,
                userName: user.userName,
                userPermissions,
                whiteLabeling: Acl.check(APP_PERMISSIONS.whiteLabeling),
                clientUserCreate,
                employeeCount,
                gtmModel,
                licenses: licenseNames,
                readonlyLocations: readOnlyLocationSettings,
                stateLocations,
            };
            initializePendo(pendoData);
        } catch (error) {
            openModal(MODALS.CONFIRM, {
                title: MESSAGES.MODAL.CONFIRM.TITLE.ERROR,
                description: extractErrorMessage(error),
                actions: [
                    {
                        text: MESSAGES.MODAL.CONFIRM.ACTIONS.OK,
                        color: 'primary',
                        onClick() {
                            closeModal(MODALS.CONFIRM);
                        },
                    },
                ],
            });
        }
    }
};

export const toIsoString = date => {
    const timezoneOffset = -date.getTimezoneOffset();
    const dif = timezoneOffset >= 0 ? '+' : '-';
    const getValue = value => {
        return (value < 10 ? '0' : '') + value;
    };

    return `${date.toISOString().replace('Z', '')}${dif}${getValue(
        Math.floor(Math.abs(timezoneOffset) / 60)
    )}:${getValue(Math.abs(timezoneOffset) % 60)}`;
};

export const showPendoSurveyGuide = async (getChatPendoGuide, openModal) => {
    try {
        const guideId = await getChatPendoGuide();

        if (guideId) {
            pendo.showGuideById(guideId);
        }
    } catch (e) {
        const error = extractErrorMessage(e);
        openModal(MODALS.CONFIRM, {
            title: MESSAGES.MODAL.CONFIRM.TITLE.ERROR,
            description: error,
        });
    }
};
