import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const DiamondIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fill={fill}
                d="M8,0.1C6.2,3.4,3.4,6.2,0,8c3.4,1.8,6.2,4.6,8,7.9c1.8-3.3,4.6-6.1,8-7.9C12.6,6.2,9.8,3.4,8,0.1z"
            />
            <path
                fill={fill}
                d="M1.8,0.2C1.4,0.9,0.8,1.6,0,2c0.8,0.4,1.4,1.1,1.8,1.8C2.3,3.1,2.9,2.4,3.7,2C2.9,1.6,2.3,0.9,1.8,0.2z"
            />
        </SvgIcon>
    );
};

DiamondIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

DiamondIcon.defaultProps = {
    fill: DEFAULT_COLORS.CATEGORY_LEAVES_ACCOMMODATIONS,
    height: '16px',
    viewBox: '0 0 16 16',
    width: '16px',
};

export default DiamondIcon;
