import PropTypes from 'prop-types';
import React from 'react';

import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { callFunc, genID } from 'ravenjs/utils';

function LinkTextToolTip(props) {
    const { toolTipContents, handleToolTipClick } = props;

    return (
        <Row id="tooltip">
            {toolTipContents.map(toolTipContent => {
                return (
                    <RenderLinkToolTip
                        key={genID()}
                        handleToolTipClick={handleToolTipClick}
                        linkProps={props}
                        toolTipContent={toolTipContent}
                    />
                );
            })}
        </Row>
    );
}

LinkTextToolTip.propTypes = {
    toolTipContents: PropTypes.array,
    handleToolTipClick: PropTypes.func,
};

LinkTextToolTip.defaultProps = {
    toolTipContents: null,
    handleToolTipClick: null,
};

export default LinkTextToolTip;

const RenderLinkToolTip = ({ linkProps, toolTipContent, handleToolTipClick }) => {
    const { name, onClick } = toolTipContent;

    const onMouseDown = e => {
        e.preventDefault();
        callFunc(onClick, linkProps);
        callFunc(handleToolTipClick);
    };
    return (
        <Typography
            as="a"
            key={genID()}
            onMouseDown={onMouseDown}
            name={name}
            style={{
                fontSize: '14px',
                color: '#0098db',
                margin: '0 8px',
                textDecoration: 'none',
                cursor: 'pointer',
                maxWidth: '400px',
            }}
            type="link"
        >
            {toolTipContent.name}
        </Typography>
    );
};
RenderLinkToolTip.propTypes = {
    linkProps: PropTypes.object,
    toolTipContent: PropTypes.object,
    handleToolTipClick: PropTypes.func,
};

RenderLinkToolTip.defaultProps = {
    linkProps: null,
    toolTipContent: null,
    handleToolTipClick: null,
};
