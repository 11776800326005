import PropTypes from 'prop-types';
import React, { Component } from 'react';
import printJS from 'print-js';
import styled from 'styled-components';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import { DOCUMENT_PREVIEW } from 'ravenjs/constants/messages';
import FaIcon from 'ravenjs/lib/FaIcon';
import { FavoriteIcon, PrintIcon, DownloadIcon } from 'ravenjs/lib/SvgIcon';
import Tooltip from 'ravenjs/lib/Tooltip';
import TooltipContent from 'ravenjs/lib/TooltipContent';
import Responsive from 'ravenjs/lib/Responsive';

import { callFunc } from 'ravenjs/utils';

const ShareIcon = styled(FaIcon)`
    cursor: pointer;
`;

class DocumentPreviewAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFavorite: props.isFavorite,
            shareIconClicked: false,
        };
    }

    handleOnShareIcon = () => {
        const url = (window && window.location.href) || '';
        const el = document.createElement('input');

        el.value = url;
        el.id = 'url-input';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        el.remove();

        this.setState({ shareIconClicked: true }, () => {
            setTimeout(() => {
                this.setState({ shareIconClicked: false });
            }, 2000);
        });
    };

    handlePrint = () => {
        const { disabledPrint, onClickPrint, url, printOptions } = this.props;

        if (disabledPrint) {
            return false;
        }

        if (onClickPrint) {
            callFunc(onClickPrint);
        } else if (printOptions.type) {
            printJS(printOptions);
        } else {
            printJS(url);
        }
        return undefined;
    };

    handleOnClickFavorite = () => {
        const { onClickFavorite } = this.props;
        const { isFavorite } = this.state;
        const isFav = isFavorite === '1' ? '0' : '1';

        callFunc(onClickFavorite, isFav);
        this.setState({ isFavorite: isFav });
    };

    getTooltipContent = type => {
        const { disabledDownload, disabledPrint } = this.props;
        const { shareIconClicked } = this.state;
        let text = '';

        if (type === 'print') {
            text = disabledPrint
                ? DOCUMENT_PREVIEW.TOOLTIP.GENERATING_DOC
                : DOCUMENT_PREVIEW.TOOLTIP.PRINT;
        } else if (type === 'share') {
            if (shareIconClicked) {
                text = DOCUMENT_PREVIEW.TOOLTIP.LINK_COPIED;
            } else {
                text = DOCUMENT_PREVIEW.TOOLTIP.COPY_LINK;
            }
        } else if (type === 'download') {
            text = disabledDownload
                ? DOCUMENT_PREVIEW.TOOLTIP.GENERATING_DOC
                : DOCUMENT_PREVIEW.TOOLTIP.DOWNLOAD;
        } else if (type === 'favorite') {
            text = DOCUMENT_PREVIEW.TOOLTIP.FAVORITE;
        }

        return <TooltipContent text={text} />;
    };

    render() {
        const {
            colorsFavoriteIcon,
            disabledDownload,
            disabledPrint,
            hideDownload,
            hideFavorite,
            hidePrint,
            hideShare,
            isFavorite,
            onClickDownload,
        } = this.props;
        const color =
            isFavorite === '1'
                ? colorsFavoriteIcon.iconColor.active
                : colorsFavoriteIcon.iconColor.inactive;
        const strokeColor =
            isFavorite === '1'
                ? colorsFavoriteIcon.strokeColor.active
                : colorsFavoriteIcon.strokeColor.inactive;

        return (
            <>
                {!hideFavorite && (
                    <Tooltip
                        padding="0"
                        margin={0}
                        arrowSize={0}
                        color="white"
                        content={this.getTooltipContent('favorite')}
                        placement="top"
                    >
                        <>
                            <Responsive size="md" value="less">
                                <FavoriteIcon
                                    onClick={this.handleOnClickFavorite}
                                    margin="0 1.063rem 0 0.3rem"
                                    color={color}
                                    strokeWidth={1.5}
                                    strokeColor={strokeColor}
                                    width="1.356rem"
                                    height="1.725rem"
                                />
                            </Responsive>
                            <Responsive size="md" value="greater">
                                <FavoriteIcon
                                    onClick={this.handleOnClickFavorite}
                                    margin="0 1.063rem 0 0"
                                    color={color}
                                    strokeWidth={1.5}
                                    strokeColor={strokeColor}
                                    width="1.156rem"
                                    height="1.625rem"
                                />
                            </Responsive>
                        </>
                    </Tooltip>
                )}
                {!hidePrint && (
                    <>
                        <Responsive size="md" value="less">
                            <PrintIcon
                                disabled={disabledPrint}
                                onClick={this.handlePrint}
                                margin="0 1.063rem 0 0"
                                height="1.65rem"
                                width="1.65rem"
                            />
                        </Responsive>
                        <Responsive size="md" value="greater">
                            <Tooltip
                                padding="0"
                                margin={0}
                                arrowSize={0}
                                color="white"
                                content={this.getTooltipContent('print')}
                                placement="top"
                            >
                                <PrintIcon
                                    disabled={disabledPrint}
                                    onClick={this.handlePrint}
                                    margin="0 1.063rem 0 0"
                                />
                            </Tooltip>
                        </Responsive>
                    </>
                )}
                {!hideDownload && (
                    <>
                        <Responsive size="md" value="less">
                            <DownloadIcon
                                disabled={disabledDownload}
                                onClick={onClickDownload}
                                margin="0 1.063rem 0 0"
                                height="1.65rem"
                            />
                        </Responsive>
                        <Responsive size="md" value="greater">
                            <Tooltip
                                padding="0"
                                margin={0}
                                arrowSize={0}
                                color="white"
                                content={this.getTooltipContent('download')}
                                placement="top"
                            >
                                <DownloadIcon
                                    disabled={disabledDownload}
                                    onClick={onClickDownload}
                                    margin="0 1.063rem 0 0"
                                />
                            </Tooltip>
                        </Responsive>
                    </>
                )}
                {!hideShare && (
                    <>
                        <Responsive size="md" value="less">
                            <ShareIcon
                                color="#797979"
                                height="1.6rem"
                                icon={faLink}
                                margin="0.063rem 0 0"
                                onClick={this.handleOnShareIcon}
                                width="1.5rem"
                            />
                        </Responsive>
                        <Responsive size="md" value="greater">
                            <Tooltip
                                content={this.getTooltipContent('share')}
                                padding="0"
                                margin={0}
                                arrowSize={0}
                                color="white"
                                placement="top"
                            >
                                <ShareIcon
                                    color="#797979"
                                    height="1.1rem"
                                    icon={faLink}
                                    margin="0.063rem 0 0"
                                    onClick={this.handleOnShareIcon}
                                    width="1.1rem"
                                />
                            </Tooltip>
                        </Responsive>
                    </>
                )}
            </>
        );
    }
}

DocumentPreviewAction.propTypes = {
    colorsFavoriteIcon: PropTypes.object,
    disabledDownload: PropTypes.bool,
    disabledPrint: PropTypes.bool,
    hideDownload: PropTypes.bool,
    hideFavorite: PropTypes.bool,
    hidePrint: PropTypes.bool,
    hideShare: PropTypes.bool,
    isFavorite: PropTypes.string,
    url: PropTypes.string,
    onClickDownload: PropTypes.func,
    onClickFavorite: PropTypes.func,
    onClickPrint: PropTypes.func,
    printOptions: PropTypes.object,
};

DocumentPreviewAction.defaultProps = {
    colorsFavoriteIcon: { iconColor: {}, strokeColor: {} },
    isFavorite: '0',
    url: '',
    disabledDownload: false,
    disabledPrint: false,
    hideDownload: false,
    hideFavorite: false,
    hidePrint: false,
    hideShare: false,
    onClickDownload: null,
    onClickFavorite: null,
    onClickPrint: null,
    printOptions: {},
};

export default DocumentPreviewAction;
