import _get from 'lodash/get';
import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import { THR_CLIENT_ID } from 'constants/api';

/**
 * API call to fetch the `oAuthToken` for the current user.
 *
 * @method fetchTokenAPI
 * @param  {Object}          userCredentials The current user's credentials
 * @return {Promise}
 */

export const fetchTokenAPI = userCredentials => {
    const { partnerId, ssoKey, redirect, ssoType, openIdConnect } = userCredentials;
    if (!_isEmpty(openIdConnect)) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                params: {
                    ...openIdConnect,
                },
                type: 'auth',
                url: '/v1/oauth/openid/token',
            })
                .then(response => {
                    const sso_url = _get(response, 'data.sso_url', null);
                    const url = sso_url ? new URL(sso_url) : {};
                    const { ssoKey, partnerId, ssoType, redirect } = queryString.parse(url.search);

                    axios({
                        method: 'POST',
                        params: {
                            partnerId,
                            ssoKey,
                            redirect: encodeURIComponent(decodeURIComponent(redirect)),
                            ssoType,
                        },
                        preventRefreshToken: true,
                        url: '/v2/oauth/sso',
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    if (ssoKey) {
        return axios({
            method: 'POST',
            params: {
                partnerId,
                ssoKey,
                redirect: encodeURIComponent(decodeURIComponent(redirect)),
                ssoType,
            },
            preventRefreshToken: true,
            url: '/v2/oauth/sso',
        });
    }

    const FormData = new window.FormData();
    FormData.append('username', _get(userCredentials, 'username'));
    FormData.append('password', _get(userCredentials, 'password'));

    return axios({
        data: FormData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        url: '/v2/oauth/authenticate',
        withCredentials: true,
    });
};

/**
 * API call to decode the `token` (access_token) for the current user.
 *
 * @method decodeTokenAPI
 * @return {Promise}
 */
export const decodeTokenAPI = () => {
    return axios({
        method: 'get',
        url: '/v2/oauth/decode_token',
    });
};

/**
 * API call to refresh the `token` (access_token) for the current user
 *
 * @method refreshTokenAPI
 * @return {Promise}
 */
export const refreshTokenAPI = () =>
    axios({
        method: 'POST',
        ignoreAuthorization: true,
        preventRefreshToken: true,
        forceRefreshToken: true,
        url: '/v2/oauth/token/refresh',
    });

/**
 * API to impersonate a user
 *
 * @method impersonateUserAPI
 * @param  {string} username
 * @return {Promise}
 */
export const impersonateUserAPI = username =>
    axios({
        method: 'post',
        url: `/v2/oauth/impersonation/login`,
        data: {
            username,
        },
        withCredentials: true,
    });

/**
 * API to end impersonation of a user
 *
 * @method endImpersonateUserAPI
 * @return {Promise}
 */
export const endImpersonateUserAPI = () =>
    axios({
        method: 'post',
        url: `/v2/oauth/impersonation/logoff`,
        withCredentials: true,
    });

/**
 * API for Custom SSO login workflow
 *
 * @param   {string}    authCode
 * @param   {string}    clientId
 * @param   {string}    ssoId
 * @return  {Promise}
 */
export const customSsoLoginAPI = ({ authCode, clientId, ssoId }) =>
    axios({
        method: 'POST',
        params: {
            authCode,
            clientId,
            SSOID: ssoId,
        },
        preventRefreshToken: true,
        url: '/v2/oauth/sso',
    });

export const openIdAuth = ({ payload = {} }) => {
    const { username, password, redirectUrl } = payload;
    const config = {
        method: 'get',
        url: '/v2/oauth/authorize',
        withCredentials: true,
        crossDomain: true,
        params: {
            client_id: THR_CLIENT_ID,
            response_type: 'code',
            scope: 'all',
        },
    };

    if (username && password) {
        config.headers = {
            username,
            password,
        };
    }
    if (redirectUrl) {
        config.params.redirect_uri = redirectUrl;
    }
    return axios(config);
};

/**
 * API to fetch token from the auth code
 *
 * @param   {string}    authCode
 * @param   {string}    clientId
 * @param   {string}    clientSecret
 * @param   {string}    redirectUri
 * @return  {Promise}
 */
export const fetchTokenFromCode = ({ authCode, clientId, clientSecret, redirectUri }) =>
    axios({
        auth: {
            username: clientId,
            password: clientSecret,
        },
        params: {
            code: authCode,
            grant_type: 'authorization_code',
            redirect_uri: redirectUri,
        },
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        method: 'post',
        url: '/v2/oauth/token',
    });

/**
 * Get Identity Token for Open ID
 *
 * @param   {string}    clientId
 * @param   {string}    clientSecret
 * @param   {string}    idToken
 * @param   {string}    accessToken
 * @return  {Promise}
 */
export const fetchOpenIdIdentityToken = ({ clientId, clientSecret, idToken, accessToken }) =>
    axios({
        method: 'post',
        auth: {
            username: clientId,
            password: clientSecret,
        },
        url: '/v2/oauth/identity/token',
        data: {
            accessToken,
            idToken,
            grantType: 'openid',
        },
    });

/**
 * API for fetching OpenIdConnect client details
 *
 * @param   {string}    payload
 * @return  {Promise}
 */
export const fetchOpenIdConnectDetailsAPI = ({ payload }) => {
    const clientId = _get(payload, 'clientId', '');
    const additionalQueryParams = _get(payload, 'additionalQueryParams', '');

    const constructUrl = (clientId, additionalQueryParams) => {
        let baseUrl = `/v1/oauth/configuration?clientId=${clientId}&type=openid`;

        if (additionalQueryParams && additionalQueryParams.trim() !== '') {
            baseUrl += `&${additionalQueryParams}`;
        }

        return baseUrl;
    };

    const url = constructUrl(clientId, additionalQueryParams);
    return axios({
        method: 'get',
        type: 'auth',
        url,
    });
};
