import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    createSKU,
    fetchLearnPackages,
    fetchSKU,
    fetchSKUs,
    updateSKU,
    fetchLicenses,
} from 'modules/apis';
import * as adminActions from '../actions';

/**
 * Create SKU
 *
 * @method createSKUSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* createSKUSaga(action) {
    try {
        const {
            payload: { data },
        } = action;
        const response = yield call(createSKU, data);
        const sku = get(response, 'data.sku', {});
        yield put(adminActions.createSKU.success(sku));
        return sku;
    } catch (error) {
        yield put(adminActions.createSKU.error(error));
        return error;
    }
}

/**
 * Fetch learn packages
 *
 * @method fetchLearnPackagesSaga
 * @type   {Generator}
 * @return {Array}
 */
export function* fetchLearnPackagesSaga() {
    try {
        const response = yield call(fetchLearnPackages);
        const data = get(response, 'data', {});
        yield put(adminActions.fetchLearnPackages.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.fetchLearnPackages.error(error));
        return error;
    }
}

/**
 * Fetch sku
 *
 * @method fetchSKUSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} sku
 */
export function* fetchSKUSaga(action) {
    try {
        const { payload: skuId } = action;
        const resp = yield call(fetchSKU, skuId);
        const sku = get(resp, 'data.sku', []);
        yield put(adminActions.fetchSKU.success(sku));
        return sku;
    } catch (error) {
        yield put(adminActions.fetchSKU.error(error));
        return error;
    }
}

/**
 * Fetch skus
 *
 * @method fetchSKUsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} skus
 */
export function* fetchSKUsSaga(action) {
    try {
        const { payload: params } = action;
        const resp = yield call(fetchSKUs, params);
        const skus = get(resp, 'data', []);
        yield put(adminActions.fetchSKUs.success(skus));
        return skus;
    } catch (error) {
        yield put(adminActions.fetchSKUs.error(error));
        return error;
    }
}

/**
 * Update SKU
 *
 * @method updateSKUSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* updateSKUSaga(action) {
    try {
        const {
            payload: { data, id },
        } = action;
        const response = yield call(updateSKU, id, data);
        const sku = get(response, 'data.sku', {});
        yield put(adminActions.updateSKU.success(sku));
        return sku;
    } catch (error) {
        yield put(adminActions.updateSKU.error(error));
        return error;
    }
}

/**
 * List Licenses
 *
 * @method fetchLicenses
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchLicensesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchLicenses, payload);
        const licenses = get(response, 'data.licenses', []);
        yield put(adminActions.fetchLicenses.success(licenses));
        return licenses;
    } catch (error) {
        yield put(adminActions.fetchLicenses.error(error));
        return error;
    }
}
