import { takeLatest } from 'redux-saga/effects';

import * as brandingActions from '../actions';
import {
    createPartnerBrandingSaga,
    deletePartnerBrandingSaga,
    fetchBrandingByDomainSaga,
    fetchCustomLoginWidgetSaga,
    fetchBrandingSaga,
    fetchDefaultCustomLoginWidgetSaga,
    resetPartnerBrandingSaga,
    resetCustomLoginWidgetSaga,
    setIsUsingTemporaryThemeSaga,
    updateCustomLoginWidgetSaga,
    updatePartnerBrandingSaga,
    uploadPartnerBrandingFileSaga,
    uploadPartnerUpgradeAdLogoFileSaga,
} from './branding';

/**
 * The root `branding` saga.
 * We're triggering sagas based on their respective events.
 *
 * @method brandingSaga
 * @type   {Generator}
 */
function* brandingSaga() {
    yield takeLatest(brandingActions.createPartnerBranding.TRIGGER, createPartnerBrandingSaga);
    yield takeLatest(brandingActions.deletePartnerBranding.TRIGGER, deletePartnerBrandingSaga);
    yield takeLatest(brandingActions.fetchBranding.TRIGGER, fetchBrandingSaga);
    yield takeLatest(brandingActions.fetchCustomLoginWidget.TRIGGER, fetchCustomLoginWidgetSaga);
    yield takeLatest(
        brandingActions.fetchDefaultCustomLoginWidget.TRIGGER,
        fetchDefaultCustomLoginWidgetSaga
    );
    yield takeLatest(brandingActions.resetPartnerBranding.TRIGGER, resetPartnerBrandingSaga);
    yield takeLatest(brandingActions.resetCustomLoginWidget.TRIGGER, resetCustomLoginWidgetSaga);
    yield takeLatest(
        brandingActions.setIsUsingTemporaryTheme.TRIGGER,
        setIsUsingTemporaryThemeSaga
    );
    yield takeLatest(brandingActions.updateCustomLoginWidget.TRIGGER, updateCustomLoginWidgetSaga);
    yield takeLatest(brandingActions.updatePartnerBranding.TRIGGER, updatePartnerBrandingSaga);
    yield takeLatest(
        brandingActions.uploadPartnerBrandingFile.TRIGGER,
        uploadPartnerBrandingFileSaga
    );
    yield takeLatest(
        brandingActions.uploadPartnerUpgradeAdLogoFile.TRIGGER,
        uploadPartnerUpgradeAdLogoFileSaga
    );
}

// Default export is the module's root saga.
export default brandingSaga;

// Named exports are all the internal sagas that can be used
// for testing as well as imports in other modules
export {
    createPartnerBrandingSaga,
    deletePartnerBrandingSaga,
    fetchBrandingByDomainSaga,
    fetchBrandingSaga,
    fetchCustomLoginWidgetSaga,
    fetchDefaultCustomLoginWidgetSaga,
    resetPartnerBrandingSaga,
    resetCustomLoginWidgetSaga,
    setIsUsingTemporaryThemeSaga,
    updateCustomLoginWidgetSaga,
    updatePartnerBrandingSaga,
    uploadPartnerBrandingFileSaga,
    uploadPartnerUpgradeAdLogoFileSaga,
};
