import { bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { get } from 'ravenjs/utils/lodash';

import { USER_IDLE_TIMEOUT } from 'constants/app';
import { MODALS } from 'constants/modals';
import { selectors as authSelectors } from 'modules/auth';
import { actions as uiActions } from 'modules/ui';
import { isPublicRoute } from 'utils/thrservice';

const SessionExpiry = props => {
    const {
        history,
        isExpired,
        location,
        loggedIn,
        openModal,
        setIdleStatus,
        loginAttemptStatus,
    } = props;
    const idleTimerRef = React.createRef();
    const [isExpiredOnMount, setIsExpiredOnMount] = useState(isExpired);
    const pathname = get(location, 'pathname', '');

    const onActive = () => {
        setIdleStatus(false);
    };

    const onIdle = () => {
        if (!isExpired) {
            openModal(MODALS.SESSION_EXPIRY_ALERT);
        }
        setIdleStatus(true);
    };

    // If session is already expired and not openid workflow on page load, redirect to login page
    if (
        isExpired &&
        isExpiredOnMount &&
        pathname.indexOf('/openid') === -1 &&
        !loginAttemptStatus
    ) {
        setIsExpiredOnMount(false);
        history.push('/auth/logout');
    }

    // To disable the session-expiry functionality when not logged-in
    if (!loggedIn || isPublicRoute(pathname)) {
        return null;
    }

    return (
        <IdleTimer
            ref={idleTimerRef}
            element={document}
            onActive={onActive}
            onIdle={onIdle}
            debounce={250}
            timeout={Number(USER_IDLE_TIMEOUT)}
            crossTab={{
                emitOnAllTabs: true,
            }}
        />
    );
};

SessionExpiry.propTypes = {
    history: shape({
        push: func.isRequired,
    }).isRequired,
    isExpired: bool,
    loginAttemptStatus: bool,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    loggedIn: bool,
    openModal: func.isRequired,
    setIdleStatus: func.isRequired,
};

SessionExpiry.defaultProps = {
    isExpired: false,
    loggedIn: false,
    loginAttemptStatus: false,
};

const mapStateToProps = createStructuredSelector({
    isExpired: authSelectors.getExpiredState,
    loginAttemptStatus: authSelectors.getLoginAttemptStatus,
});

const mapDispatchToProps = {
    openModal: uiActions.openModal,
    setIdleStatus: uiActions.setIdleStatus,
};

export { SessionExpiry as SessionExpiryUnwrapped };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SessionExpiry));
