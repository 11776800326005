import axios from 'axios';

/**
 * API call to fetch TODO list
 *
 * @method fetchToDoList
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchToDoList = payload => {
    return axios({
        method: 'get',
        url: `/v2/todoitems`,
        params: payload,
    });
};

/**
 * API call to update TODO list item
 *
 * @method updateToDoList
 * @param  {Object}  payload
 * @return {Promise}
 */
export const updateToDoList = payload => {
    const { todoItemIds, updateAction } = payload;
    return axios({
        method: 'patch',
        url: `/v2/todoitems`,
        data: {
            todoItemIds,
            updateAction,
        },
    });
};

/**
 * API call to create TODO list item
 *
 * @method addToDoList
 * @param  {Object}  payload
 * @return {Promise}
 */
export const createToDoList = payload => {
    const {
        entityType,
        entityId,
        companyId,
        eventType,
        userId,
        additionalInfo,
        optimizedInsights,
        type,
    } = payload;
    return axios({
        method: 'POST',
        url: `/v2/todoitems`,
        data: {
            entityId,
            entityType,
            companyId,
            eventType,
            userId,
            additionalInfo,
            optimizedInsights,
            type,
        },
    });
};
