import { call, put, select } from 'redux-saga/effects';
import { get, isEmpty } from 'ravenjs/utils/lodash';

import {
    fetchContentfulCarouselContentAPI,
    fetchContentfulCategoriesAPI,
    fetchContentfulContentAPI,
    fetchContentfulContentTypesAPI,
    fetchContentfulDocumentAPI,
    fetchContentfulDocumentsAPI,
    fetchContentfulEmployeeCountsAPI,
    fetchContentfulJurisdictionsAPI,
    fetchContentfulTopicDocumentsAPI,
    fetchContentfulTopicsDocumentsAPI,
    addUserFavItemAPI,
    removeUserFavItemAPI,
    addLikeDislikeItemAPI,
    removeLikeDislikeItemAPI,
    fetchContentTypesAPI,
    fetchContentfulDocumentListAPI,
    fetchDocumentsByCategoryAPI,
    fetchLawAlertsContentAPI,
    fetchContentByCategoryAPI,
    deleteUserFavoriteItemAPI,
    fetchUserFavoriteItemsAPI,
    fetchUserFavoriteItemsByTypeAndIdAPI,
    fetchDocumentsAPI,
    downloadDocumentAPI,
    fetchLawAlertsAPI,
    downloadHrFitnnesTestReportAPI,
    fetchFeaturedContentAPI,
    addRelatedTermAPI,
    addVisitItemAPI,
    fetchUpcomingWebinarAPI,
    downloadContentfulDocumentAPI,
    getDocumentWithLogoAPI,
    fetchResourcesDocumentsAPI,
    fetchSafetyResourcesAPI,
    fetchSafetyCoursesAPI,
    fetchSafetyProductsAPI,
    fetchTrainingCategoriesAPI,
    fetchHelpCenterSectionsAPI,
    fetchHelpCenterCategoryAPI,
    fetchHelpCenterDetailAPI,
    fetchCompareLawsByStatesAPI,
    fetchPublicFeaturedContentAPI,
    fetchContentfulEHSContentAPI,
    fetchEHSTopicsByCategoryAPI,
    fetchEHSIndustriesAPI,
    fetchEHSContentAPI,
    fetchEHSContentByTopicAPI,
    fetchEHSBannerByTopicAPI,
    fetchSafetyManualTemplateAPI,
    fetchEHSContentTypeRouteAPI,
    fetchHelpCenterCategoriesAPI,
    fetchHelpCenterSearchAPI,
    fetchTrainingWebinarAPI,
    fetchLastWebinarsAPI,
    fetchWebinarsCategoriesAPI,
    fetchContentfulAdditionalEhsResultsAPI,
    fetchContentfulLawAlertsAPI,
    fetchHelpCenterFaqsAPI,
    fetchHelpCenterCategoriesBySectionAPI,
    fetchHelpCenterHomeWidgetAPI,
    downloadHelpCenterVideoAPI,
    fetchUserFavoriteItemsInsightsAPI,
    fetchHelpCenterRecentDocumentsAPI,
    fetchHelpCenterReleaseNotesAPI,
    fetchFeedbackOptionsAPI,
} from 'modules/apis';

import { getSponsorshipList } from 'modules/user/reducer';
import * as contentfulActions from '../actions';
import * as userActions from '../../user/actions';
import { getContentful } from '../reducer';

/**
 * Fetch Contentful content
 *
 * @method fetchContentfulContentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchContentfulContentSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params['content-type'] = params['content-type'] || 'all';
        params.limit = params.limit || 10;
        params.search = params.search || '';
        params['show-suggestions'] = Boolean(params['show-suggestions']);

        const response = yield call(fetchContentfulContentAPI, params);
        const data = get(response, 'data.searchResult', {});
        yield put(contentfulActions.fetchContentfulContent.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulContent.error(error));
        return error;
    }
}

/**
 * Fetch Contentful contentTypes
 *
 * @method fetchContentfulContentTypesSaga
 * @type   {Generator}
 * @return {Object} The data or error
 */
export function* fetchContentfulContentTypesSaga() {
    try {
        const contentfulStore = yield select(getContentful);
        let response;

        if (contentfulStore.contentTypes.length) {
            response = {
                data: {
                    contentTypes: contentfulStore.contentTypes,
                },
            };
        } else {
            response = yield call(fetchContentfulContentTypesAPI);
        }

        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulContentTypes.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulContentTypes.error(error));
        return error;
    }
}

/**
 * Fetch Contentful documentTypes
 *
 * @method fetchContentfulDocumentTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchContentfulDocumentTypesSaga(action) {
    try {
        const contentfulStore = yield select(getContentful);
        const type = get(action, 'payload', 'document-types');
        let sendRequest = true;
        let response;

        if (type === 'ehs') {
            if (!isEmpty(contentfulStore.ehsDocumentTypes)) {
                response = {
                    data: {
                        contentTypes: contentfulStore.ehsDocumentTypes,
                    },
                };
                sendRequest = false;
            }
        }

        if (sendRequest) {
            response = yield call(fetchContentTypesAPI, { contentType: type });
        }
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulDocumentTypes.success(data));
        if (type === 'ehs') {
            yield put(contentfulActions.fetchContentfulEhsDocumentTypes.success(data));
        }

        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulDocumentTypes.error(error));
        return error;
    }
}

/**
 * Fetch Contentful categories
 *
 * @method fetchContentfulCategoriesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchContentfulCategoriesSaga(action) {
    try {
        const contentfulStore = yield select(getContentful);
        let response;
        const contentType = get(action, 'payload.contentType', '');
        const documentType = get(action, 'payload.documentType', '');
        const forceApiCall = get(action, 'payload.forceApiCall', false);
        const ehsReadyToCall = get(action, 'payload.ehsReadyToCall', false);

        if (
            !forceApiCall &&
            ((contentfulStore.categories[documentType] &&
                contentfulStore.categories[documentType].length) ||
                (contentfulStore.categories[contentType] &&
                    contentfulStore.categories[contentType].length))
        ) {
            response = {
                data: {
                    categories: contentfulStore.categories[documentType || contentType],
                },
            };
        } else {
            response = yield call(fetchContentfulCategoriesAPI, action.payload);
        }

        const data = get(response, 'data', {});

        yield put(
            contentfulActions.fetchContentfulCategories.success({ data, payload: action.payload })
        );

        if (ehsReadyToCall) {
            yield put(contentfulActions.updateEhsCategories.success(data));
        }
        return { categories: [] };
    } catch (error) {
        yield put(contentfulActions.fetchContentfulCategories.error(error));
        return error;
    }
}

/**
 * Fetch Contentful employee counts
 *
 * @method fetchContentfulEmployeeCountsSaga
 * @type   {Generator}
 * @return {Object} The data or error
 */
export function* fetchContentfulEmployeeCountsSaga() {
    try {
        const contentfulStore = yield select(getContentful);
        let response;

        if (contentfulStore.employeeCounts.length) {
            response = {
                data: {
                    employeeCounts: contentfulStore.employeeCounts,
                },
            };
        } else {
            response = yield call(fetchContentfulEmployeeCountsAPI);
        }

        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulEmployeeCounts.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulEmployeeCounts.error(error));
        return error;
    }
}

/**
 * Fetch Contentful jurisdictions
 *
 * @method fetchContentfulJurisdictionsSaga
 * @type   {Generator}
 * @return {Object} The data or error
 */
export function* fetchContentfulJurisdictionsSaga() {
    try {
        const contentfulStore = yield select(getContentful);
        let response;

        if (contentfulStore.jurisdictions.length) {
            response = {
                data: {
                    jurisdictions: contentfulStore.jurisdictions,
                },
            };
        } else {
            response = yield call(fetchContentfulJurisdictionsAPI);
        }

        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulJurisdictions.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulJurisdictions.error(error));
        return error;
    }
}

/**
 * Fetch Contentful document
 *
 * @method fetchContentfulDocumentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The Article
 */
export function* fetchContentfulDocumentSaga(action) {
    try {
        const {
            payload: { documentId },
        } = action;
        const resp = yield call(fetchContentfulDocumentAPI, documentId);
        const document = get(resp, 'data.document', {});
        yield put(contentfulActions.fetchContentfulDocument.success(document));
        return document;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulDocument.error(error));
        return error;
    }
}

/**
 * Fetch Contentful documents
 *
 * @method fetchContentfulDocumentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The documents
 */
export function* fetchContentfulDocumentsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchContentfulDocumentsAPI, params);
        const documentList = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulDocuments.success(documentList));
        return documentList;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulDocuments.error(error));
        return error;
    }
}

/**
 * Fetch Contentful documents for a topic
 *
 * @method fetchContentfulTopicDocumentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The documents
 */
export function* fetchContentfulTopicDocumentsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchContentfulTopicDocumentsAPI, params);
        const documentList = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulTopicDocuments.success(documentList));
        return documentList;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulTopicDocuments.error(error));
        return error;
    }
}

/**
 * Fetch Contentful documents for all topics
 *
 * @method fetchContentfulTopicsDocumentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The documents
 */
export function* fetchContentfulTopicsDocumentsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchContentfulTopicsDocumentsAPI, params);
        const documentList = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulTopicsDocuments.success(documentList));
        return documentList;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulTopicsDocuments.error(error));
        return error;
    }
}

/**
 * Add User Favorite Item
 *
 * @method addUserFavItemSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* addUserFavItemSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(addUserFavItemAPI, payload);
        yield put(contentfulActions.addUserFavItem.success(resp));
        return resp;
    } catch (error) {
        yield put(contentfulActions.addUserFavItem.error(error));
        return error;
    }
}

/**
 * Remove User Favorite Item
 *
 * @method removeUserFavItemSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* removeUserFavItemSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(removeUserFavItemAPI, payload);
        yield put(contentfulActions.removeUserFavItem.success(resp));
        return resp;
    } catch (error) {
        yield put(contentfulActions.removeUserFavItem.error(error));
        return error;
    }
}

/**
 * Add User Like or Dislike option to Item
 *
 * @method addLikeDislikeItemSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* addLikeDislikeItemSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(addLikeDislikeItemAPI, payload);
        yield put(contentfulActions.addLikeDislikeItem.success(resp));
        return resp;
    } catch (error) {
        yield put(contentfulActions.addLikeDislikeItem.error(error));
        return error;
    }
}

/**
 * Remove User Like or Dislike option to Item
 *
 * @method removeLikeDislikeItemSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* removeLikeDislikeItemSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(removeLikeDislikeItemAPI, payload);
        yield put(contentfulActions.removeLikeDislikeItem.success(resp));
        return resp;
    } catch (error) {
        yield put(contentfulActions.removeLikeDislikeItem.error(error));
        return error;
    }
}

/**
 * Fetch Contentful contentTypes of topics
 *
 * @method fetchContentTypesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchContentTypesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchContentTypesAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchContentTypes.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentTypes.error(error));
        return error;
    }
}

/**
 * Fetch Contentful document
 *
 * @method fetchContentfulDocumentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The Article
 */
export function* fetchContentfulDocumentListSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchContentfulDocumentListAPI, params);
        const documentList = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulDocumentList.success(documentList));
        return documentList;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulDocumentList.error(error));
        return error;
    }
}

/**
 * Fetch Contentful document by category
 *
 * @method fetchDocumentsByCategorySaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The Article
 */
export function* fetchDocumentsByCategorySaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchDocumentsByCategoryAPI, params);
        const documentList = get(response, 'data', {});
        yield put(contentfulActions.fetchDocumentsByCategory.success(documentList));
        return documentList;
    } catch (error) {
        yield put(contentfulActions.fetchDocumentsByCategory.error(error));
        return error;
    }
}

/**
 * Fetch law alerts Contentful document
 *
 * @method fetchLawAlertsContentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The Article
 */
export function* fetchLawAlertsContentSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchLawAlertsContentAPI, params);
        const documentList = get(response, 'data', {});
        yield put(contentfulActions.fetchLawAlertsContent.success(documentList));
        return documentList;
    } catch (error) {
        yield put(contentfulActions.fetchLawAlertsContent.error(error));
        return error;
    }
}

/**
 * Saga to fetch content by category
 *
 * @method fetchContentByCategorySaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchContentByCategorySaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchContentByCategoryAPI, params);
        const category = get(response, 'data', {});
        yield put(contentfulActions.fetchContentByCategory.success(category));
        return category;
    } catch (error) {
        yield put(contentfulActions.fetchContentByCategory.error(error));
        return error;
    }
}

/**
 * Saga to fetch user favorite items
 *
 * @method fetchUserFavoriteItemsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchUserFavoriteItemsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchUserFavoriteItemsAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchUserFavoriteItems.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchUserFavoriteItems.error(error));
        return error;
    }
}

/**
 * Saga to fetch user favorite items
 *
 * @method fetchUserFavoriteItemsByTypeAndIdSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchUserFavoriteItemsByTypeAndIdSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchUserFavoriteItemsByTypeAndIdAPI, params);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchUserFavoriteItemsByTypeAndId.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchUserFavoriteItemsByTypeAndId.error(error));
        return error;
    }
}

/**
 * Saga to delete user favorite item
 *
 * @method deleteUserFavoriteItemSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* deleteUserFavoriteItemSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(deleteUserFavoriteItemAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.deleteUserFavoriteItem.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.deleteUserFavoriteItem.error(error));
        return error;
    }
}

/**
 * Saga to fetch documents
 *
 * @method fetchDocumentsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchDocumentsSaga(action) {
    try {
        const {
            payload: { params, type, id },
        } = action;
        const response = yield call(fetchDocumentsAPI, params, type, id);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchDocuments.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchDocuments.error(error));
        return error;
    }
}

/**
 * Saga to download document
 *
 * @method downloadDocumentSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* downloadDocumentSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(downloadDocumentAPI, params);
        const data = get(response, 'data', {});
        yield put(contentfulActions.downloadDocument.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.downloadDocument.error(error));
        return error;
    }
}

/**
 * Saga to download document
 *
 * @method downloadHrFitnnesTestReportSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* downloadHrFitnnesTestReportSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(downloadHrFitnnesTestReportAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.downloadHrFitnnesTestReport.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.downloadHrFitnnesTestReport.error(error));
        return error;
    }
}

/**
 * Saga to fetch law Alerts
 *
 * @method fetchLawAlertsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchLawAlertsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchLawAlertsAPI, params);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchLawAlerts.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchLawAlerts.error(error));
        return error;
    }
}

/**
 * Saga to fetch featured content
 *
 * @method fetchFeaturedContentSaga
 * @param  {Object} action The original redux-action
 */
export function* fetchFeaturedContentSaga(action) {
    try {
        const { payload: params = {} } = action;
        const isSoftLogin = get(params, 'isSoftLogin', false);

        if (isSoftLogin) {
            const response = yield call(fetchPublicFeaturedContentAPI, params);

            const images = get(response, 'data.featureContent.images', []).map(
                ({ file }) => file.url
            );
            const data = {
                ...get(response, 'data'),
                results: {
                    ...get(response, 'data.featureContent', {}),
                    images,
                },
            };
            yield put(contentfulActions.fetchFeaturedContent.success(data));
        } else {
            const updateIndex = get(action, 'payload.updateIndex', false);
            const contentfulStore = yield select(getContentful);
            const currentImageIndex = get(contentfulStore, 'currentFeaturedImageIndex', 0);
            const response = yield call(fetchFeaturedContentAPI, params);
            const data = get(response, 'data', {});
            const images = get(data, 'results.images', []);
            const newIndex = currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;
            yield put(contentfulActions.fetchFeaturedContent.success(data));
            if (updateIndex) {
                yield put(contentfulActions.updateFeaturedContentImageIndex.success(newIndex));
            }
        }
    } catch (error) {
        yield put(contentfulActions.fetchFeaturedContent.error(error));
    }
}

/**
 * Saga to fetch Contentful carousel content
 *
 * @method fetchContentfulCarouselContentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchContentfulCarouselContentSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchContentfulCarouselContentAPI, params);
        const data = get(response, 'data.result', {});
        yield put(contentfulActions.fetchContentfulCarouselContent.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulCarouselContent.error(error));
        return error;
    }
}

/**
 * Saga to add related term
 *
 * @method addRelatedTermSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* addRelatedTermSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(addRelatedTermAPI, payload);
        yield put(contentfulActions.addRelatedTerm.success(resp));
        return resp;
    } catch (error) {
        yield put(contentfulActions.addRelatedTerm.error(error));
        return error;
    }
}

/**
 * Saga to add visit item
 *
 * @method addVisitItemSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* addVisitItemSaga(action) {
    try {
        let { payload } = action;

        const sponsorshipList = yield select(getSponsorshipList);
        const resourceId = get(payload, 'resourceId', '');
        const hasResource = sponsorshipList.find(item => resourceId.indexOf(item.key) !== -1);

        if (hasResource) {
            payload = {
                ...payload,
                configurationType: hasResource.configurationType,
                sponsoredBy: hasResource.sponsorBy,
            };
        }

        const resp = yield call(addVisitItemAPI, payload);
        yield put(contentfulActions.addVisitItem.success(resp));
        return resp;
    } catch (error) {
        yield put(contentfulActions.addVisitItem.error(error));
        return error;
    }
}

/**
 * Saga to fetch upcoming webinar
 *
 * @method fetchUpcomingWebinarSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchUpcomingWebinarSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchUpcomingWebinarAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchUpcomingWebinar.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchUpcomingWebinar.error(error));
        return error;
    }
}

/**
 * Saga to download contentful document
 *
 * @method downloadContentfulDocumentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* downloadContentfulDocumentSaga(action) {
    try {
        const {
            payload: { url },
        } = action;
        const response = yield call(downloadContentfulDocumentAPI, url);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.downloadContentfulDocument.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.downloadContentfulDocument.error(error));
        return error;
    }
}

/**
 * Saga to get contentful document
 *
 * @method getDocumentWithLogoSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* getDocumentWithLogoSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(getDocumentWithLogoAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.getDocumentWithLogo.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.getDocumentWithLogo.error(error));
        return error;
    }
}

/**
 * Saga to fetch resources documents
 *
 * @method fetchResourcesDocumentsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchResourcesDocumentsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchResourcesDocumentsAPI, params);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchResourcesDocuments.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchResourcesDocuments.error(error));
        return error;
    }
}

/**
 * Saga to fetch featured content
 *
 * @method fetchSafetyResourcesSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchSafetyResourcesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchSafetyResourcesAPI, payload);
        const data = get(response, 'data.results', []);
        yield put(contentfulActions.fetchSafetyResources.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchSafetyResources.error(error));
        return error;
    }
}

/**
 * Saga to fetch safety courses
 *
 * @method fetchUpcomingWebinarSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchSafetyCoursesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchSafetyCoursesAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchSafetyCourses.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchSafetyCourses.error(error));
        return error;
    }
}

/**
 * Saga to fetch training categories
 *
 * @method fetchTrainingCategoriesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchTrainingCategoriesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchTrainingCategoriesAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchTrainingCategories.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchTrainingCategories.error(error));
        return error;
    }
}

/**
 * Saga to fetch help center sections
 *
 * @method fetchHelpCenterSectionsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchHelpCenterSectionsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchHelpCenterSectionsAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterSections.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterSections.error(error));
        return error;
    }
}

/**
 * Saga to fetch help center faqs
 *
 * @method fetchHelpCenterFaqsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchHelpCenterFaqsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchHelpCenterFaqsAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterFaqs.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterFaqs.error(error));
        return error;
    }
}

/**
 * Saga to fetch help center categories by section
 *
 * @method fetchHelpCenterCategoriesBySectionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchHelpCenterCategoriesBySectionSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchHelpCenterCategoriesBySectionAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterCategoriesBySection.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterCategoriesBySection.error(error));
        return error;
    }
}

/**
 * Saga to fetch help center category
 *
 * @method fetchHelpCenterCategorySaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchHelpCenterCategorySaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchHelpCenterCategoryAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterCategory.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterCategory.error(error));
        return error;
    }
}

/**
 * Saga to fetch help center detail
 *
 * @method fetchHelpCenterDetailSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchHelpCenterDetailSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchHelpCenterDetailAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterDetail.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterDetail.error(error));
        return error;
    }
}

/**
 * Saga to download a docx with laws comparison between states.
 *
 * @method fetchCompareLawsByStatesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchCompareLawsByStatesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchCompareLawsByStatesAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchCompareLawsByStates.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchCompareLawsByStates.error(error));
        return error;
    }
}

/**
 * Saga to fetch ehs document detail
 *
 * @method fetchContentfulEHSContentSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchContentfulEHSContentSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchContentfulEHSContentAPI, payload);

        const content = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulEHSContent.success(content));
        return content;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulEHSContent.error(error));
        return error;
    }
}

/**
 * Saga to fetch safety products
 *
 * @method fetchSafetyProductsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchSafetyProductsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchSafetyProductsAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchSafetyProducts.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchSafetyProducts.error(error));
        return error;
    }
}

/**
 * Saga to fetch ehs topics by parent category
 *
 * @method fetchEHSTopicsByCategorySaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchEHSTopicsByCategorySaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchEHSTopicsByCategoryAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchEHSTopicsByCategory.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchEHSTopicsByCategory.error(error));
        return error;
    }
}

/**
 * Fetch Contentful EHS Industries
 *
 * @method fetchEHSIndustriesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEHSIndustriesSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchEHSIndustriesAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchEHSIndustries.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchEHSIndustries.error(error));
        return error;
    }
}

/**
 * Fetch Contentful EHS
 *
 * @method fetchEHSContentSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEHSContentSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchEHSContentAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchEHSContent.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchEHSContent.error(error));
        return error;
    }
}

/**
 * Fetch Contentful EHS by topic
 *
 * @method fetchEHSContentByTopicSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEHSContentByTopicSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchEHSContentByTopicAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchEHSContentByTopic.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchEHSContentByTopic.error(error));
        return error;
    }
}

/**
 * Fetch Contentful EHS banner by topic
 *
 * @method fetchEHSBannerByTopicSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEHSBannerByTopicSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchEHSBannerByTopicAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchEHSBannerByTopic.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchEHSBannerByTopic.error(error));
        return error;
    }
}

/**
 * Fetch Contentful safety manual template
 *
 * @method fetchSafetyManualTemplateSaga
 * @type   {Generator}
 * @return {Object} The data or error
 */
export function* fetchSafetyManualTemplateSaga() {
    try {
        const response = yield call(fetchSafetyManualTemplateAPI);
        const data = get(response, 'data', {});
        const safetyManualTemplateUrl = get(data, 'EhsSafetyManualTemplate.url', '');
        yield put(contentfulActions.fetchSafetyManualTemplate.success(data));
        yield put(userActions.updateSafetyManualTemplateUrl.success(safetyManualTemplateUrl));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchSafetyManualTemplate.error(error));
        return error;
    }
}

/**
 * Fetch Contentful EHS Content Type Route
 *
 * @method fetchEHSContentTypeRouteSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchEHSContentTypeRouteSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchEHSContentTypeRouteAPI, payload);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchEHSContentTypeRoute.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchEHSContentTypeRoute.error(error));
        return error;
    }
}

/**
 * Fetch categories to help center
 *
 * @method fetchHelpCenterCategoriesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchHelpCenterCategoriesSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchHelpCenterCategoriesAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterCategories.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterCategories.error(error));
        return error;
    }
}

/**
 * Fetch search to help center
 *
 * @method fetchHelpCenterSearchSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchHelpCenterSearchSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchHelpCenterSearchAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterSearch.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterSearch.error(error));
        return error;
    }
}

/**
 * Fetch home widget to help center
 *
 * @method fetchHelpCenterHomeWidgetSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchHelpCenterHomeWidgetSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchHelpCenterHomeWidgetAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterHomeWidget.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterHomeWidget.error(error));
        return error;
    }
}

/**
 * Fetch search to help center
 *
 * @method fetchTrainingWebinarSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchTrainingWebinarSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchTrainingWebinarAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchTrainingWebinar.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchTrainingWebinar.error(error));
        return error;
    }
}

/**
 * Fetch search to help center
 *
 * @method fetchLastWebinarsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchLastWebinarsSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchLastWebinarsAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchLastWebinars.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchLastWebinars.error(error));
        return error;
    }
}

/**
 * Fetch search to help center
 *
 * @method fetchWebinarsCategoriesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchWebinarsCategoriesSaga(action) {
    try {
        const payload = get(action, 'payload');
        const response = yield call(fetchWebinarsCategoriesAPI, payload);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchWebinarsCategories.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchWebinarsCategories.error(error));
        return error;
    }
}

/**
 * Fetch Contentful content
 *
 * @method fetchContentfulAdditionalEhsResultsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchContentfulAdditionalEhsResultsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params['content-type'] = params['content-type'] || 'all';
        params.limit = params.limit || 10;
        params.search = params.search || '';
        params['show-suggestions'] = Boolean(params['show-suggestions']);

        const response = yield call(fetchContentfulAdditionalEhsResultsAPI, params);
        const data = get(response, 'data.searchResult', {});
        yield put(contentfulActions.fetchContentfulAdditionalEhsResults.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulAdditionalEhsResults.error(error));
        return error;
    }
}

/**
 * Fetch Contentful law alerts
 *
 * @method fetchContentfulLawAlertsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The documents
 */
export function* fetchContentfulLawAlertsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchContentfulLawAlertsAPI, params);
        const documentList = get(response, 'data', {});
        yield put(contentfulActions.fetchContentfulLawAlerts.success(documentList));
        return documentList;
    } catch (error) {
        yield put(contentfulActions.fetchContentfulLawAlerts.error(error));
        return error;
    }
}

/**
 * Download help center video
 *
 * @method downloadHelpCenterVideoSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* downloadHelpCenterVideoSaga(action) {
    try {
        const payload = get(action, 'payload');
        const { title } = payload;
        delete payload.title;
        const response = yield call(downloadHelpCenterVideoAPI, payload);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.mp4`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        yield put(contentfulActions.downloadHelpCenterVideo.success(response));
        return response;
    } catch (error) {
        yield put(contentfulActions.downloadHelpCenterVideo.error(error));
        return error;
    }
}

/**
 * Saga to fetch user favorite items insights
 *
 * @method fetchUserFavoriteItemsInsightsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchUserFavoriteItemsInsightsSaga(action) {
    try {
        const response = yield call(fetchUserFavoriteItemsInsightsAPI);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchUserFavoriteItemsInsights.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchUserFavoriteItemsInsights.error(error));
        return error;
    }
}

/**
 * Saga to fetch user favorite items insights
 *
 * @method fetchHelpCenterRecentDocumentsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchHelpCenterRecentDocumentsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchHelpCenterRecentDocumentsAPI, params);
        const data = get(response, 'data', {});
        yield put(contentfulActions.fetchHelpCenterRecentDocuments.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterRecentDocuments.error(error));
        return error;
    }
}

/**
 * Saga to fetch release notes
 *
 * @method fetchHelpCenterReleaseNotesSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchHelpCenterReleaseNotesSaga(action) {
    try {
        const params = get(action, 'payload', {});
        const response = yield call(fetchHelpCenterReleaseNotesAPI, params);
        const data = get(response, 'data.results', {});
        yield put(contentfulActions.fetchHelpCenterReleaseNotes.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchHelpCenterReleaseNotes.error(error));
        return error;
    }
}

/**
 * Saga to fetch release notes
 *
 * @method fetchFeedbackOptionsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchFeedbackOptionsSaga(action) {
    try {
        const params = get(action, 'payload', {});
        const response = yield call(fetchFeedbackOptionsAPI, params);
        const data = get(response, 'data.data', {});
        yield put(contentfulActions.fetchFeedbackOptions.success(data));
        return data;
    } catch (error) {
        yield put(contentfulActions.fetchFeedbackOptions.error(error));
        return error;
    }
}
