import { faBold } from '@fortawesome/free-solid-svg-icons/faBold';
import { faItalic } from '@fortawesome/free-solid-svg-icons/faItalic';
import { faStrikethrough } from '@fortawesome/free-solid-svg-icons/faStrikethrough';
import { faParagraph } from '@fortawesome/free-solid-svg-icons/faParagraph';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faBrush } from '@fortawesome/free-solid-svg-icons/faBrush';

export const DefaultEditorSchema = [
    {
        id: 'format',
        groupId: 'style',
        label: 'format',
        isMenuItems: true,
        dropDownOpen: false,
        icon: {
            icon: faParagraph,
        },
        toolTip: 'Format',
        type: 'blockStyle',
        menuItems: [
            {
                label: 'Normal text',
                inlineStyle: '',
            },
            {
                label: 'Quote',
                inlineStyle: 'blockquote',
                customStyles: {
                    fontStyle: 'italic',
                },
            },
            {
                label: 'Code',
                inlineStyle: 'code-block',
                customStyles: {
                    fontFamily: 'monospace,sans-serif',
                },
            },
            {
                label: 'Heading 1',
                inlineStyle: 'header-one',
                customStyles: {
                    fontSize: '40px',
                    fontWeight: '700',
                },
            },
            {
                label: 'Heading 2',
                inlineStyle: 'header-two',
                customStyles: {
                    fontSize: '32px',
                    fontWeight: '700',
                },
            },
            {
                label: 'Heading 3',
                inlineStyle: 'header-three',
                customStyles: {
                    fontSize: '24px',
                    fontWeight: '700',
                },
            },
            {
                label: 'Heading 4',
                inlineStyle: 'header-four',
                customStyles: {
                    fontSize: '21px',
                    fontWeight: '700',
                },
            },
            {
                label: 'Heading 5',
                inlineStyle: 'header-five',
                customStyles: {
                    fontSize: '18px',
                    fontWeight: '700',
                },
            },
            {
                label: 'Heading 6',
                inlineStyle: 'header-six',
                customStyles: {
                    fontWeight: '700',
                    fontSize: '16px',
                },
            },
        ],
    },
    {
        id: 'bold',
        groupId: 'basic',
        label: 'bold',
        toolTip: 'Bold',
        icon: {
            icon: faBold,
        },
        type: 'inlineStyle',
        inlineStyle: 'BOLD',
    },
    {
        id: 'italic',
        groupId: 'basic',
        label: 'italic',
        toolTip: 'Italic',
        icon: {
            icon: faItalic,
        },
        type: 'inlineStyle',
        inlineStyle: 'ITALIC',
    },
    {
        id: 'strikeThrough',
        groupId: 'basic',
        label: 'strikeThrough',
        toolTip: 'Strikethrough',
        icon: {
            icon: faStrikethrough,
        },
        type: 'inlineStyle',
        inlineStyle: 'STRIKETHROUGH',
    },
    {
        id: 'lists',
        groupId: 'list',
        label: 'lists',
        isMenuItems: true,
        dropDownOpen: false,
        toolTip: 'Lists',
        icon: {
            icon: faList,
        },
        type: 'blockStyle',
        menuItems: [
            {
                label: '.Unordered List',
                inlineStyle: 'unordered-list-item',
                disable: false,
                fontSize: '14px',
                customStyles: {
                    opacity: 'unset',
                },
            },
            {
                label: '1.Ordered List',
                inlineStyle: 'ordered-list-item',
                disable: false,
                customStyles: {
                    opacity: 'unset',
                },
            },
            {
                label: '< Outdent',
                inlineStyle: '',
                checkDisable: true,
                customStyles: {
                    opacity: '0.4',
                },
            },
            {
                label: '> Indent',
                inlineStyle: '',
                checkDisable: true,
                customStyles: {
                    opacity: '0.4',
                },
            },
        ],
    },
    {
        id: 'link',
        groupId: 'list',
        label: 'link',
        inlineStyle: 'link',
        isMenuItems: true,
        dropDownOpen: false,
        icon: {
            icon: faLink,
        },
        toolTip: 'Link',
        type: 'entityStyle',
        menuItems: [
            {
                label: 'Insert Link',
                inlineStyle: 'link',
                action: 'insertLink',
                disable: false,
                customStyles: {
                    fontColor: '#0098db',
                },
            },
            {
                label: 'Unlink',
                inlineStyle: '',
                action: 'removeLink',
                checkDisable: true,
                customStyles: {
                    fontColor: '#0098db',
                },
            },
        ],
    },
    {
        id: 'line',
        groupId: 'basic',
        label: 'Line',
        toolTip: 'Line',
        icon: {
            icon: faMinus,
        },
        type: 'inlineStyle',
        inlineStyle: 'Line',
    },
    {
        id: 'unstyled',
        groupId: 'custom',
        label: 'unstyled',
        toolTip: 'Unstyled',
        icon: {
            icon: faBrush,
        },
        type: 'inlineStyle',
        inlineStyle: 'Unstyled',
    },
];
