import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

const KeyValueKeyStyled = styled.p`
    ${({ theme }) => theme.media.down('md')`
        flex: 1 1 50%;
        font-size: 0.75rem;
        white-space: pre-wrap;
        padding: 0.25rem 0;
        word-break: break-word;
    `};
    ${({ theme }) => theme.media.up('md')`
        flex: ${({ flex }) => flex || '1 1 15%'};
    `};
    text-align: right;
    margin-bottom: 0;
    white-space: nowrap;
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('KeyValuePairsKey.styles')};
`;

export default KeyValueKeyStyled;
