import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeLettersIcon = ({ color, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fill={color}>
                    <path d="M3.395 11.023V-.153H13.384c.236 0 .427.165.427.369v10.428c0 .204-.191.37-.427.37l-.007-.002c-.031.007-.064.011-.098.011H3.395zm9.99 2.824c.235 0 .426-.165.426-.37V12.09c0-.204-.192-.369-.427-.369s-.427.165-.427.369v1.02H3.395v.738h9.99zM2.25 13.109c-.649 0-1.178-.445-1.204-1l.003-.03-.002-.075c.037-.546.562-.981 1.203-.981h.29V-.153h-.29c-1.135 0-2.06.799-2.06 1.78 0 .014.004.026.005.039-.001.907-.014 9.24-.001 10.336 0 .021-.004.042-.004.064 0 .944.855 1.717 1.93 1.775.022.003.043.006.065.006h.356v-.738H2.25z" />
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeLettersIcon.propTypes = {
    color: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeLettersIcon.defaultProps = {
    color: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeLettersIcon;
