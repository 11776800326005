import { createActions } from 'utils/actions';

/**
 * Redux actions for the ai assistant flow.
 *
 * @type {Object}
 */
export const createAISession = createActions('aiAssistant', 'CREATE_AI_SESSION', {
    asPromise: true,
});

export const sendMessage = createActions('aiAssistant', 'SEND_MESSAGE', {
    asPromise: true,
});

export const getAnswer = createActions('aiAssistant', 'GET_ANSWER', {
    asPromise: true,
});

export const createFeedback = createActions('aiAssistant', 'CREATE_FEEDBACK', {
    asPromise: true,
});

export const closeAISession = createActions('aiAssistant', 'CLOSE_AI_SESSION', {
    asPromise: true,
});

export const sendEmailCloseAISession = createActions('aiAssistant', 'SEND_EMAIL', {
    asPromise: true,
});

export const getHistoryChat = createActions('aiAssistant', 'GET_HISTORY_CHAT', {
    asPromise: true,
});

export const getChatSessionDetails = createActions('aiAssistant', 'GET_CHAT_SESSION_DETAILS', {
    asPromise: true,
});
