import { bool, func, string, arrayOf, shape, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { get } from 'ravenjs/utils/lodash';

import NavItem from 'components/NavItem';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { SHOW_NEW_SAFETY_LANDING_FLAG } from 'constants/content';
import { EVENTS } from 'constants/events';
import { EXTERNAL_ROUTES, NAV_STATE_PARENT_ROUTE_MAP } from 'constants/navigation';
import { DEFAULT_TITLE } from 'constants/safety';
import Acl from 'modules/acl';
import { actions as pendoActions } from 'modules/pendo';
import { actions as userActions, selectors as userSelectors } from 'modules/user';
import { selectors as authSelectors } from 'modules/auth';
import { actions as communityActions } from 'modules/community';

import { navItemsNotBeClickable } from 'utils/navigation';
import { redirectExternalRoutes } from 'utils/user';

import { MNL_INTELLIGENCE } from 'constants/intelligence';
import { NavItemsStyled } from './NavItemsStyled';

class NavItems extends Component {
    static propTypes = {
        history: object,
        isImpersonatingUser: bool,
        items: arrayOf(
            shape({
                title: string.isRequired,
                to: string,
                state: string,
            })
        ),
        isSoftLogin: bool.isRequired,
        isVisibleOnDOM: bool,
        openLoginPrompt: func.isRequired,
        sendPendoEvent: func.isRequired,
        updateClickSafetyMenu: func.isRequired,
        currentUser: object.isRequired,
        userCompany: object.isRequired,
        accessToken: string.isRequired,
        fetchCommunityUrl: func.isRequired,
    };

    static defaultProps = {
        history: {},
        isImpersonatingUser: false,
        items: [],
        isVisibleOnDOM: true,
    };

    constructor(props) {
        super(props);

        const subNavVisible = {};
        props.items.forEach(item => {
            subNavVisible[item.title] = false;
        });

        this.state = {
            scrollTop: null,
            subNavVisible,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isVisibleOnDOM } = this.props;
        const { subNavVisible } = this.state;

        if (!isVisibleOnDOM) {
            const activeSubNav = Object.keys(subNavVisible).filter(function(key) {
                return subNavVisible[key];
            });
            activeSubNav[0] && this.hide(activeSubNav[0])();
        }
    }

    handleScroll = event => {
        const scrollTop = get(event, 'target.scrollingElement.scrollTop', 0);

        this.setState({
            scrollTop,
        });
    };

    show = title => () => {
        const { subNavVisible: newSubNavVisible } = this.state;
        Object.keys(newSubNavVisible).forEach(sn => {
            newSubNavVisible[sn] = false;
        });
        newSubNavVisible[title] = true;
        this.setState({
            subNavVisible: newSubNavVisible,
        });
    };

    hide = title => () => {
        const { subNavVisible: newSubNavVisible } = this.state;
        newSubNavVisible[title] = false;
        this.setState({
            subNavVisible: newSubNavVisible,
        });
    };

    sendEvent = item => {
        const { sendPendoEvent, userCompany, currentUser } = this.props;
        const pendoEvent = EVENTS.NAVIGATION.SELECT;
        if (item.state === MNL_INTELLIGENCE.MINTEL_NAV_STATE) {
            const intelligencePendoEvent = EVENTS.INTELLIGENCE.REDIRECT_TO_DASHBOARD;
            intelligencePendoEvent.eventProperties.source = 'HR Compliance';
            intelligencePendoEvent.eventProperties.userName = currentUser && currentUser.userName;
            intelligencePendoEvent.eventProperties.companyId = userCompany.companyId;
            intelligencePendoEvent.eventProperties.companyName = userCompany.companyName;
            sendPendoEvent(intelligencePendoEvent);
        }
        pendoEvent.eventProperties.selection = item.state;
        sendPendoEvent(pendoEvent);
    };

    hideAndClick = item => id => {
        const {
            accessToken,
            fetchCommunityUrl,
            history,
            isSoftLogin,
            openLoginPrompt,
            updateClickSafetyMenu,
        } = this.props;
        const itemsNotBeClickable = navItemsNotBeClickable(Acl);
        if (isSoftLogin) {
            openLoginPrompt({ redirectUrl: item.to });
        } else {
            const { subNavVisible: newSubNavVisible } = this.state;
            newSubNavVisible[item.title] = false;
            this.setState({
                subNavVisible: newSubNavVisible,
            });

            if (EXTERNAL_ROUTES.indexOf(item.to) !== -1) {
                const params = {
                    accessToken,
                    apiToCall: fetchCommunityUrl,
                };
                redirectExternalRoutes(item.to, params);
            } else if (id === item.title && itemsNotBeClickable.indexOf(item.state) === -1) {
                history.push(item.to);
                this.sendEvent(item);

                if (item.state === DEFAULT_TITLE.toLowerCase()) {
                    updateClickSafetyMenu(true);
                }
            }
        }
    };

    hideSubNav = () => {
        const { subNavVisible: newSubNavVisible } = this.state;
        Object.keys(newSubNavVisible).forEach(sn => {
            newSubNavVisible[sn] = false;
        });
        this.setState({
            subNavVisible: newSubNavVisible,
        });
    };

    isNavItemSelected = ({ state }) => {
        const pathname = get(this.props, 'history.location.pathname', '');
        const search = get(this.props, 'history.location.search', '');
        const navItem =
            search && search.indexOf(SHOW_NEW_SAFETY_LANDING_FLAG) !== -1
                ? `/${pathname.split('/')[1]}${search}`
                : `/${pathname.split('/')[1]}`;
        const targetRoute = NAV_STATE_PARENT_ROUTE_MAP[state];
        return navItem.indexOf('?') !== -1
            ? navItem === targetRoute
            : navItem.indexOf(targetRoute) !== -1;
    };

    render() {
        const { items, isImpersonatingUser, isVisibleOnDOM } = this.props;
        const { scrollTop, subNavVisible } = this.state;

        return (
            <NavItemsStyled onMouseLeave={this.hideSubNav}>
                {items.map(item => (
                    <NavItem
                        selected={this.isNavItemSelected(item)}
                        onMouseEnter={this.show(item.title)}
                        onMouseLeave={this.hide(item.title)}
                        onMouseOut={this.hide(item.title)}
                        onBlur={this.hide(item.title)}
                        onClick={this.hideAndClick(item)}
                        key={item.state}
                        isImpersonatingUser={isImpersonatingUser}
                        isSubNavVisibleOnDOM={isVisibleOnDOM}
                        showSubNav={subNavVisible[item.title]}
                        subNavScrollTop={scrollTop}
                        {...item}
                    />
                ))}
            </NavItemsStyled>
        );
    }
}

const mapDispatchToProps = {
    sendPendoEvent: pendoActions.sendPendoEvent,
    updateClickSafetyMenu: userActions.updateClickSafetyMenu,
    fetchCommunityUrl: communityActions.fetchCommunityUrl,
};

const mapStateToProps = createStructuredSelector({
    currentUser: userSelectors.getUserInfo,
    userCompany: userSelectors.getUserCompany,
    accessToken: authSelectors.getAccessToken,
});

export { NavItems as NavItemsUnwrapped };
export default withPublicContent(connect(mapStateToProps, mapDispatchToProps)(NavItems));
