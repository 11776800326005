import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ResourceIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7084 3.54046C13.7425 2.54083 15.1274 1.9877 16.565 2.00021C18.0025 2.01271 19.3776 2.58985 20.3942 3.60731C21.4107 4.62477 21.9873 6.00115 21.9998 7.44001C22.0123 8.87886 21.4597 10.2651 20.4609 11.3001L20.4498 11.3114L17.7086 14.0551C17.1528 14.6116 16.4839 15.042 15.7473 15.317C15.0107 15.5921 14.2236 15.7053 13.4394 15.6491C12.6552 15.5929 11.8923 15.3685 11.2024 14.9913C10.5124 14.614 9.91163 14.0926 9.44072 13.4625C9.13843 13.058 9.22099 12.4848 9.62511 12.1822C10.0292 11.8797 10.6019 11.9623 10.9042 12.3668C11.2181 12.7869 11.6186 13.1345 12.0786 13.386C12.5385 13.6375 13.0472 13.7871 13.57 13.8246C14.0927 13.862 14.6175 13.7865 15.1085 13.6032C15.5996 13.4198 16.0455 13.1329 16.4161 12.7619L19.1516 10.0239C19.8141 9.33445 20.1806 8.41264 20.1723 7.4559C20.164 6.49667 19.7796 5.57908 19.1019 4.90077C18.4242 4.22246 17.5074 3.8377 16.5491 3.82937C15.5928 3.82105 14.6714 4.18818 13.9826 4.85186L12.4158 6.41094C12.0579 6.76707 11.4793 6.76539 11.1235 6.40717C10.7677 6.04895 10.7694 5.46985 11.1273 5.11371L12.699 3.54972L12.7084 3.54046Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.25273 8.68295C8.98933 8.40792 9.77642 8.29467 10.5606 8.35089C11.3448 8.4071 12.1077 8.63146 12.7976 9.00874C13.4876 9.38603 14.0884 9.90742 14.5593 10.5375C14.8616 10.942 14.779 11.5152 14.3749 11.8178C13.9708 12.1203 13.3981 12.0377 13.0958 11.6332C12.7819 11.2131 12.3814 10.8655 11.9214 10.614C11.4615 10.3625 10.9528 10.2129 10.43 10.1754C9.90726 10.138 9.38254 10.2135 8.89147 10.3968C8.40039 10.5802 7.95446 10.8671 7.58392 11.2381L4.84839 13.9761C4.18586 14.6655 3.81939 15.5874 3.8277 16.5441C3.83603 17.5033 4.22043 18.4209 4.89812 19.0992C5.57581 19.7775 6.49256 20.1623 7.45092 20.1706C8.40677 20.1789 9.32774 19.8121 10.0165 19.149L11.5732 17.5909C11.93 17.2338 12.5086 17.2338 12.8654 17.5909C13.2223 17.9481 13.2223 18.5272 12.8654 18.8844L11.3029 20.4484L11.2916 20.4595C10.2575 21.4592 8.87258 22.0123 7.43504 21.9998C5.9975 21.9873 4.62237 21.4102 3.60584 20.3927C2.58931 19.3752 2.0127 17.9988 2.00021 16.56C1.98772 15.1211 2.54034 13.7349 3.53905 12.6999L3.55018 12.6886L6.2914 9.94488C6.29137 9.94492 6.29144 9.94484 6.2914 9.94488C6.8472 9.38841 7.51617 8.95796 8.25273 8.68295Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ResourceIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ResourceIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default ResourceIcon;
