import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeLawsIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path
                            fill={color}
                            d="M9.835 7.854L14 7.854 14 5.817 12.292 0 7.413 0 7.287 0 1.708 0 0 5.838 0 7.854 4.165 7.854 4.165 5.782 3.136 1.05 4.165 1.05 5.796 1.05 5.796 6.454 5.796 7.336 5.796 11.438 2.1 11.438 2.1 14 7.287 14 7.413 14 11.9 14 11.9 11.438 8.204 11.438 8.204 7.336 8.204 6.454 8.204 1.05 9.835 1.05 10.864 1.05 9.835 5.782z"
                        />
                        <path
                            fill={colorInner}
                            d="M0 5.81H4.165V6.81H0zM2.1 11.431H11.9V12.431H2.1z"
                        />
                        <path
                            fill={colorInner}
                            d="M1.736 0H2.7359999999999998V6.272H1.736zM11.564 0H12.564V6.272H11.564z"
                        />
                        <path fill={colorInner} d="M9.835 5.81H14V6.81H9.835z" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeLawsIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeLawsIcon.defaultProps = {
    color: `${DEFAULT_COLORS.PEACH}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeLawsIcon;
