import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { fetchMenus } from 'modules/apis';
import { fetchAppNav } from '../actions';

/**
 * The saga for showing the navigation.
 *
 * @method showNavSaga
 * @type  {Generator}
 * @param {string}     [navType='']    The type of navigation to target
 * @param {Object}     [dispatcher={}] The set of dispatcher actions
 * @param {Object}     [action={}]     The original redux-action
 */
export function* showNavSaga(navType = '', dispatcher = {}, action = {}) {
    try {
        // Extract the payload from the action.
        const { payload = {} } = action;
        // Overwrite the current state with the passed in payload.
        const updatedState = {
            show: true,
            ...payload,
        };
        // Dispatch the success action with the updated state.
        yield put(dispatcher.success(updatedState));
    } catch (error) {
        // Dispatch the error action with the error
        yield put(dispatcher.error(error));
    }
}

/**
 * The saga for hiding the navigation.
 *
 * @method hideNavSaga
 * @type  {Generator}
 * @param {Object}     [dispatcher={}] The set of dispatcher actions
 * @param {Object}     [action={}]     The original redux-action
 */
export function* hideNavSaga(dispatcher = {}, action = {}) {
    try {
        // Not much to do here, we are simply hiding requested navigation.
        // Just dispatch the success action, and let the reducer handle the rest.
        yield put(dispatcher.success());
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(dispatcher.error(error));
    }
}

/**
 * Saga to fetch app navigation menus
 *
 * @method fetchAppNavigationMenusSaga
 * @type  {Generator}
 * @return {Array|Object}
 */
export function* fetchAppNavigationMenusSaga() {
    try {
        const response = yield call(fetchMenus);
        const menus = get(response, 'data.menus', []);
        yield put(fetchAppNav.success(menus));
        return menus;
    } catch (error) {
        yield put(fetchAppNav.error(error));
        return error;
    }
}
