import _isEmpty from 'lodash/isEmpty';

/**
 * Get enum values from list to schema form options.
 *
 * @method getFormEnumSchema
 * @param  {Array}          list        The original list
 * @param  {string}         enumKey key to enum array
 * @param  {string}         enumNameKey key to enumName array
 * @return {Array}
 */
export function getFormEnumSchema(list, enumKey, enumNameKey) {
    if (!_isEmpty(list)) {
        const { enums, enumNames } = list.reduce(
            (accumulator, option) => {
                return {
                    enums: [...accumulator.enums, option[enumKey]],
                    enumNames: [...accumulator.enumNames, option[enumNameKey]],
                };
            },
            { enums: [], enumNames: [] }
        );

        return { enums, enumNames };
    } else {
        return { enums: [], enumNames: [] };
    }
}
