import React, { Component } from 'react';
import { string } from 'prop-types';
import { WISTIA_API } from 'constants/content';

const insertScriptHead = ({ name, src }) => {
    if (!document.querySelector(`#${name}`)) {
        const container = document.head || document.querySelector('head');
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('id', name);
        scriptElement.async = true;
        scriptElement.src = `${src}?cb=${new Date().getTime()}`;
        container.appendChild(scriptElement);
    }
};

const wistiaScriptsHandler = embedId => {
    const requiredScripts = [
        {
            name: 'wistia-script',
            src: WISTIA_API,
        },
    ];
    requiredScripts.forEach(v =>
        insertScriptHead({
            name: v.name,
            src: v.src,
        })
    );
};

class WistiaPlayerEmbed extends Component {
    constructor(props) {
        super(props);
        const { embedId } = this.props;
        window._wq = window._wq || [];
        window._wq.push({
            id: embedId,
            onReady: video => {
                this.handle = video;
            },
        });
    }

    componentDidMount() {
        const { embedId } = this.props;
        wistiaScriptsHandler(embedId);
    }

    componentWillUnmount() {
        this.handle && this.handle.remove();
    }

    render() {
        const { embedId } = this.props;
        return (
            <div>
                <div
                    className="wistia_responsive_padding"
                    style={{ padding: '0', position: 'relative' }}
                >
                    <div
                        className="wistia_responsive_wrapper"
                        style={{
                            height: '100%',
                            left: '0',
                            position: 'relative',
                            top: '0',
                            width: '100%',
                        }}
                    >
                        <div
                            className={`wistia_embed wistia_async_${embedId} videoFoam=true`}
                            style={{ width: '100%', height: '100%' }}
                        >
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

WistiaPlayerEmbed.propTypes = {
    embedId: string.isRequired,
};

export default WistiaPlayerEmbed;
