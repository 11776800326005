import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ArrowsIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.28 8.63a.91.91 0 0 1 0 1.286L4.194 12l2.084 2.084a.91.91 0 0 1-1.285 1.286l-2.728-2.727a.909.909 0 0 1 0-1.286L4.994 8.63a.91.91 0 0 1 1.285 0zM11.357 2.266a.909.909 0 0 1 1.286 0l2.727 2.728a.91.91 0 0 1-1.286 1.285L12 4.195 9.916 6.279A.91.91 0 0 1 8.63 4.994l2.727-2.728zM8.63 17.72a.91.91 0 0 1 1.286 0L12 19.806l2.084-2.084a.91.91 0 0 1 1.286 1.285l-2.727 2.728a.909.909 0 0 1-1.286 0L8.63 19.006a.91.91 0 0 1 0-1.285zM17.72 8.63a.91.91 0 0 1 1.287 0l2.727 2.727a.909.909 0 0 1 0 1.286l-2.728 2.727a.91.91 0 0 1-1.285-1.286L19.805 12l-2.084-2.084a.91.91 0 0 1 0-1.286z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12a.91.91 0 0 1 .91-.91h18.18a.91.91 0 1 1 0 1.82H2.91A.91.91 0 0 1 2 12z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2a.91.91 0 0 1 .91.91v18.18a.91.91 0 1 1-1.82 0V2.91A.91.91 0 0 1 12 2z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ArrowsIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ArrowsIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK_00,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default ArrowsIcon;
