import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DEFAULT_COLORS } from 'constants/colors';
import Typography from '../../Typography';

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const TooltipBox = styled.div`
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 15px;
    border-radius: 4px;
    position: absolute;
    z-index: 12;
    bottom: 125%; /* Adjust position */
    left: 100%;
    width: ${({ width }) => width};
    transform: translateX(-50%);
    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: opacity 0.3s;
    margin-left: 65px;

    &::after {
        content: '';
        position: absolute;
        top: 100%; /* Arrow at the bottom */
        left: 15%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
`;

const TooltipStyled = styled.div``;

const CustomTooltip = ({ content, children, hasLink, linktext, redirectionURL, tooltip }) => {
    const [open, setOpen] = useState(false);
    const tooltipRef = useRef(null);

    const handleClickOutside = () => {
        setOpen(false);
    };

    useEffect(() => {
        return () => {
            setOpen(false);
        };
    }, []);

    return (
        <TooltipContainer ref={tooltipRef} onMouseLeave={() => handleClickOutside()}>
            <TooltipStyled onMouseEnter={() => setOpen(!open)}>{children}</TooltipStyled>
            <TooltipBox open={open} width={tooltip.style.maxWidth}>
                {hasLink && typeof content === 'string' && (
                    <span>
                        <Typography
                            fontFamily={tooltip.style.fontFamily}
                            fontSize={tooltip.style.fontSize}
                            color={tooltip.style.color}
                            fontWeight="400"
                            gutterBottom="0"
                        >
                            {content}
                            <a
                                href={`${window.location.origin}${redirectionURL}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: DEFAULT_COLORS.WHITE, textDecoration: 'underline' }}
                            >
                                {linktext}
                            </a>
                            .
                        </Typography>
                    </span>
                )}
            </TooltipBox>
        </TooltipContainer>
    );
};

CustomTooltip.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.node.isRequired,
    hasLink: PropTypes.bool,
    tooltip: PropTypes.object,
    linktext: PropTypes.string,
    redirectionURL: PropTypes.string,
};

CustomTooltip.defaultProps = {
    hasLink: false,
    linktext: '',
    redirectionURL: '',
    tooltip: {},
};

export default CustomTooltip;
