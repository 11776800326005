import { createActions } from 'utils/actions';

/**
 * Redux actions to feature flags state.
 *
 * @type {Object}
 */
export const setFeatureFlag = createActions('ui', 'SET_FEATURE_FLAG', {
    payloadCreator: payload => payload,
});
