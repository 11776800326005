import axios from 'axios';

/**
 * API to create questionnarie.
 *
 * @method createQuestionnarieAPI
 * @return {Promise}
 */
export const createQuestionnaireAPI = () =>
    axios({
        method: 'post',
        url: `/tools/v1/fitness-test/questionnaires`,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetching questionnaire.
 *
 * @method fetchQuestionnaireAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchQuestionnaireAPI = params => {
    const { questionnaireId, ...rest } = params;
    return axios({
        method: 'get',
        url: `/tools/v1/fitness-test/questionnaires/${questionnaireId}`,
        params: rest,
        type: 'apiTools',
        noBearer: true,
    });
};

/**
 * API to fetching questionnaires list.
 *
 * @method fetchQuestionnairesAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchQuestionnairesAPI = params =>
    axios({
        method: 'get',
        url: '/tools/v1/fitness-test/questionnaires/list',
        params,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to sending responses.
 *
 * @method sendResponsesAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const sendResponsesAPI = data =>
    axios({
        method: 'post',
        url: `/tools/v1/fitness-test/responses`,
        data,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetching prospect questionnaire.
 *
 * @method fetchProspectQuestionnaireAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchProspectQuestionnaireAPI = params => {
    return axios({
        method: 'get',
        url: '/tools/v1/prospect-fitness-test',
        params,
        type: 'apiTools',
        noBearer: true,
    });
};

/**
 * API to sending responses for prospect.
 *
 * @method prospectSendResponsesAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const prospectSendResponsesAPI = data =>
    axios({
        method: 'post',
        url: '/tools/v1/prospect-fitness-test/responses',
        data,
        type: 'apiTools',
        noBearer: true,
    });
