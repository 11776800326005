import { takeLatest } from 'redux-saga/effects';

import * as contentActions from '../actions';

import {
    fetchContentfulCarouselContentSaga,
    fetchContentfulCategoriesSaga,
    fetchContentfulContentSaga,
    fetchContentfulContentTypesSaga,
    fetchContentfulDocumentSaga,
    fetchContentfulDocumentsSaga,
    fetchContentfulDocumentTypesSaga,
    fetchContentfulEmployeeCountsSaga,
    fetchContentfulJurisdictionsSaga,
    fetchContentfulTopicDocumentsSaga,
    fetchContentfulTopicsDocumentsSaga,
    addUserFavItemSaga,
    removeUserFavItemSaga,
    addLikeDislikeItemSaga,
    removeLikeDislikeItemSaga,
    fetchContentfulDocumentListSaga,
    fetchContentTypesSaga,
    fetchDocumentsByCategorySaga,
    fetchLawAlertsContentSaga,
    fetchContentByCategorySaga,
    fetchUserFavoriteItemsSaga,
    fetchUserFavoriteItemsByTypeAndIdSaga,
    deleteUserFavoriteItemSaga,
    fetchDocumentsSaga,
    downloadDocumentSaga,
    fetchLawAlertsSaga,
    downloadHrFitnnesTestReportSaga,
    fetchFeaturedContentSaga,
    addRelatedTermSaga,
    addVisitItemSaga,
    fetchUpcomingWebinarSaga,
    downloadContentfulDocumentSaga,
    getDocumentWithLogoSaga,
    fetchResourcesDocumentsSaga,
    fetchSafetyResourcesSaga,
    fetchSafetyCoursesSaga,
    fetchSafetyProductsSaga,
    fetchTrainingCategoriesSaga,
    fetchHelpCenterSectionsSaga,
    fetchHelpCenterCategorySaga,
    fetchHelpCenterDetailSaga,
    fetchCompareLawsByStatesSaga,
    fetchContentfulEHSContentSaga,
    fetchEHSTopicsByCategorySaga,
    fetchEHSIndustriesSaga,
    fetchEHSContentSaga,
    fetchEHSContentByTopicSaga,
    fetchEHSBannerByTopicSaga,
    fetchSafetyManualTemplateSaga,
    fetchEHSContentTypeRouteSaga,
    fetchHelpCenterCategoriesSaga,
    fetchHelpCenterSearchSaga,
    fetchTrainingWebinarSaga,
    fetchLastWebinarsSaga,
    fetchWebinarsCategoriesSaga,
    fetchContentfulAdditionalEhsResultsSaga,
    fetchContentfulLawAlertsSaga,
    fetchHelpCenterFaqsSaga,
    fetchHelpCenterCategoriesBySectionSaga,
    fetchHelpCenterHomeWidgetSaga,
    downloadHelpCenterVideoSaga,
    fetchUserFavoriteItemsInsightsSaga,
    fetchHelpCenterRecentDocumentsSaga,
    fetchHelpCenterReleaseNotesSaga,
    fetchFeedbackOptionsSaga,
} from './contentful';

/**
 * Root saga for Contentful actions
 *
 * @method contentfulSaga
 * @type   {Generator}
 */
function* contentfulSaga() {
    yield takeLatest(
        contentActions.fetchContentfulCategories.TRIGGER,
        fetchContentfulCategoriesSaga
    );
    yield takeLatest(contentActions.fetchContentfulContent.TRIGGER, fetchContentfulContentSaga);
    yield takeLatest(
        contentActions.fetchContentfulContentTypes.TRIGGER,
        fetchContentfulContentTypesSaga
    );
    yield takeLatest(contentActions.fetchContentfulDocument.TRIGGER, fetchContentfulDocumentSaga);
    yield takeLatest(contentActions.fetchContentfulDocuments.TRIGGER, fetchContentfulDocumentsSaga);
    yield takeLatest(
        contentActions.fetchContentfulDocumentTypes.TRIGGER,
        fetchContentfulDocumentTypesSaga
    );
    yield takeLatest(
        contentActions.fetchContentfulTopicDocuments.TRIGGER,
        fetchContentfulTopicDocumentsSaga
    );
    yield takeLatest(
        contentActions.fetchContentfulTopicsDocuments.TRIGGER,
        fetchContentfulTopicsDocumentsSaga
    );
    yield takeLatest(
        contentActions.fetchContentfulEmployeeCounts.TRIGGER,
        fetchContentfulEmployeeCountsSaga
    );
    yield takeLatest(
        contentActions.fetchContentfulJurisdictions.TRIGGER,
        fetchContentfulJurisdictionsSaga
    );
    yield takeLatest(contentActions.addUserFavItem.TRIGGER, addUserFavItemSaga);
    yield takeLatest(contentActions.removeUserFavItem.TRIGGER, removeUserFavItemSaga);
    yield takeLatest(contentActions.addLikeDislikeItem.TRIGGER, addLikeDislikeItemSaga);
    yield takeLatest(contentActions.removeLikeDislikeItem.TRIGGER, removeLikeDislikeItemSaga);
    yield takeLatest(contentActions.fetchContentTypes.TRIGGER, fetchContentTypesSaga);
    yield takeLatest(
        contentActions.fetchContentfulDocumentList.TRIGGER,
        fetchContentfulDocumentListSaga
    );
    yield takeLatest(contentActions.fetchDocumentsByCategory.TRIGGER, fetchDocumentsByCategorySaga);
    yield takeLatest(contentActions.fetchLawAlertsContent.TRIGGER, fetchLawAlertsContentSaga);

    yield takeLatest(contentActions.fetchContentByCategory.TRIGGER, fetchContentByCategorySaga);
    yield takeLatest(contentActions.fetchUserFavoriteItems.TRIGGER, fetchUserFavoriteItemsSaga);
    yield takeLatest(
        contentActions.fetchUserFavoriteItemsByTypeAndId.TRIGGER,
        fetchUserFavoriteItemsByTypeAndIdSaga
    );
    yield takeLatest(contentActions.deleteUserFavoriteItem.TRIGGER, deleteUserFavoriteItemSaga);
    yield takeLatest(contentActions.fetchDocuments.TRIGGER, fetchDocumentsSaga);
    yield takeLatest(contentActions.downloadDocument.TRIGGER, downloadDocumentSaga);
    yield takeLatest(contentActions.fetchLawAlerts.TRIGGER, fetchLawAlertsSaga);
    yield takeLatest(
        contentActions.downloadHrFitnnesTestReport.TRIGGER,
        downloadHrFitnnesTestReportSaga
    );
    yield takeLatest(contentActions.fetchFeaturedContent.TRIGGER, fetchFeaturedContentSaga);
    yield takeLatest(
        contentActions.fetchContentfulCarouselContent.TRIGGER,
        fetchContentfulCarouselContentSaga
    );
    yield takeLatest(contentActions.addRelatedTerm.TRIGGER, addRelatedTermSaga);
    yield takeLatest(contentActions.addVisitItem.TRIGGER, addVisitItemSaga);
    yield takeLatest(contentActions.fetchUpcomingWebinar.TRIGGER, fetchUpcomingWebinarSaga);
    yield takeLatest(
        contentActions.downloadContentfulDocument.TRIGGER,
        downloadContentfulDocumentSaga
    );
    yield takeLatest(contentActions.getDocumentWithLogo.TRIGGER, getDocumentWithLogoSaga);
    yield takeLatest(contentActions.fetchResourcesDocuments.TRIGGER, fetchResourcesDocumentsSaga);
    yield takeLatest(contentActions.fetchSafetyResources.TRIGGER, fetchSafetyResourcesSaga);
    yield takeLatest(contentActions.fetchSafetyCourses.TRIGGER, fetchSafetyCoursesSaga);
    yield takeLatest(contentActions.fetchTrainingCategories.TRIGGER, fetchTrainingCategoriesSaga);
    yield takeLatest(contentActions.fetchHelpCenterSections.TRIGGER, fetchHelpCenterSectionsSaga);
    yield takeLatest(contentActions.fetchHelpCenterCategory.TRIGGER, fetchHelpCenterCategorySaga);
    yield takeLatest(contentActions.fetchHelpCenterDetail.TRIGGER, fetchHelpCenterDetailSaga);
    yield takeLatest(contentActions.fetchCompareLawsByStates.TRIGGER, fetchCompareLawsByStatesSaga);
    yield takeLatest(
        contentActions.fetchContentfulEHSContent.TRIGGER,
        fetchContentfulEHSContentSaga
    );
    yield takeLatest(contentActions.fetchSafetyProducts.TRIGGER, fetchSafetyProductsSaga);
    yield takeLatest(contentActions.fetchEHSTopicsByCategory.TRIGGER, fetchEHSTopicsByCategorySaga);
    yield takeLatest(contentActions.fetchEHSIndustries.TRIGGER, fetchEHSIndustriesSaga);
    yield takeLatest(contentActions.fetchEHSContent.TRIGGER, fetchEHSContentSaga);
    yield takeLatest(contentActions.fetchEHSContentByTopic.TRIGGER, fetchEHSContentByTopicSaga);
    yield takeLatest(contentActions.fetchEHSBannerByTopic.TRIGGER, fetchEHSBannerByTopicSaga);
    yield takeLatest(
        contentActions.fetchSafetyManualTemplate.TRIGGER,
        fetchSafetyManualTemplateSaga
    );
    yield takeLatest(contentActions.fetchEHSContentTypeRoute.TRIGGER, fetchEHSContentTypeRouteSaga);
    yield takeLatest(contentActions.fetchHelpCenterSearch.TRIGGER, fetchHelpCenterSearchSaga);
    yield takeLatest(
        contentActions.fetchHelpCenterCategories.TRIGGER,
        fetchHelpCenterCategoriesSaga
    );
    yield takeLatest(contentActions.fetchTrainingWebinar.TRIGGER, fetchTrainingWebinarSaga);
    yield takeLatest(contentActions.fetchLastWebinars.TRIGGER, fetchLastWebinarsSaga);
    yield takeLatest(contentActions.fetchWebinarsCategories.TRIGGER, fetchWebinarsCategoriesSaga);
    yield takeLatest(
        contentActions.fetchContentfulAdditionalEhsResults.TRIGGER,
        fetchContentfulAdditionalEhsResultsSaga
    );
    yield takeLatest(contentActions.fetchContentfulLawAlerts.TRIGGER, fetchContentfulLawAlertsSaga);
    yield takeLatest(contentActions.fetchHelpCenterFaqs.TRIGGER, fetchHelpCenterFaqsSaga);
    yield takeLatest(
        contentActions.fetchHelpCenterCategoriesBySection.TRIGGER,
        fetchHelpCenterCategoriesBySectionSaga
    );
    yield takeLatest(
        contentActions.fetchHelpCenterHomeWidget.TRIGGER,
        fetchHelpCenterHomeWidgetSaga
    );
    yield takeLatest(contentActions.downloadHelpCenterVideo.TRIGGER, downloadHelpCenterVideoSaga);
    yield takeLatest(
        contentActions.fetchUserFavoriteItemsInsights.TRIGGER,
        fetchUserFavoriteItemsInsightsSaga
    );
    yield takeLatest(
        contentActions.fetchHelpCenterRecentDocuments.TRIGGER,
        fetchHelpCenterRecentDocumentsSaga
    );
    yield takeLatest(
        contentActions.fetchHelpCenterReleaseNotes.TRIGGER,
        fetchHelpCenterReleaseNotesSaga
    );
    yield takeLatest(contentActions.fetchFeedbackOptions.TRIGGER, fetchFeedbackOptionsSaga);
}

export default contentfulSaga;

export {
    fetchContentfulCarouselContentSaga,
    fetchContentfulCategoriesSaga,
    fetchContentfulContentSaga,
    fetchContentfulContentTypesSaga,
    fetchContentfulDocumentSaga,
    fetchContentfulDocumentsSaga,
    fetchContentfulDocumentTypesSaga,
    fetchContentfulEmployeeCountsSaga,
    fetchContentfulJurisdictionsSaga,
    fetchContentfulTopicDocumentsSaga,
    fetchContentfulTopicsDocumentsSaga,
    addUserFavItemSaga,
    addLikeDislikeItemSaga,
    removeUserFavItemSaga,
    removeLikeDislikeItemSaga,
    fetchContentfulDocumentListSaga,
    fetchContentTypesSaga,
    fetchDocumentsByCategorySaga,
    fetchContentByCategorySaga,
    fetchDocumentsSaga,
    downloadDocumentSaga,
    fetchLawAlertsSaga,
    downloadHrFitnnesTestReportSaga,
    fetchFeaturedContentSaga,
    addRelatedTermSaga,
    addVisitItemSaga,
    fetchUpcomingWebinarSaga,
    downloadContentfulDocumentSaga,
    getDocumentWithLogoSaga,
    fetchResourcesDocumentsSaga,
    fetchSafetyResourcesSaga,
    fetchSafetyCoursesSaga,
    fetchSafetyProductsSaga,
    fetchTrainingCategoriesSaga,
    fetchHelpCenterSectionsSaga,
    fetchHelpCenterCategorySaga,
    fetchHelpCenterDetailSaga,
    fetchContentfulEHSContentSaga,
    fetchEHSTopicsByCategorySaga,
    fetchEHSIndustriesSaga,
    fetchEHSContentSaga,
    fetchEHSContentByTopicSaga,
    fetchEHSBannerByTopicSaga,
    fetchSafetyManualTemplateSaga,
    fetchEHSContentTypeRouteSaga,
    fetchHelpCenterCategoriesSaga,
    fetchHelpCenterSearchSaga,
    fetchTrainingWebinarSaga,
    fetchLastWebinarsSaga,
    fetchWebinarsCategoriesSaga,
    fetchContentfulAdditionalEhsResultsSaga,
    fetchContentfulLawAlertsSaga,
    fetchHelpCenterFaqsSaga,
    fetchHelpCenterCategoriesBySectionSaga,
    fetchHelpCenterHomeWidgetSaga,
    downloadHelpCenterVideoSaga,
    fetchUserFavoriteItemsInsightsSaga,
    fetchHelpCenterRecentDocumentsSaga,
    fetchHelpCenterReleaseNotesSaga,
    fetchFeedbackOptionsSaga,
};
