import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const ThrashIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            cursor="pointer"
            {...rest}
            width={width}
            height={height}
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 6.545c0-.502.448-.909 1-.909h18c.552 0 1 .407 1 .91 0 .502-.448.909-1 .909H3c-.552 0-1-.407-1-.91z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.818c-.265 0-.52.096-.707.266A.87.87 0 0 0 9 4.727v.91h6v-.91a.87.87 0 0 0-.293-.643A1.053 1.053 0 0 0 14 3.818h-4zm7 1.818v-.909c0-.723-.316-1.417-.879-1.928A3.159 3.159 0 0 0 14 2h-4c-.796 0-1.559.287-2.121.799C7.316 3.31 7 4.004 7 4.727v.91H5c-.552 0-1 .406-1 .908v12.728c0 .723.316 1.417.879 1.928A3.159 3.159 0 0 0 7 22h10c.796 0 1.559-.287 2.121-.799.563-.511.879-1.205.879-1.928V6.545c0-.502-.448-.909-1-.909h-2zM6 7.455v11.818a.87.87 0 0 0 .293.643c.187.17.442.266.707.266h10c.265 0 .52-.096.707-.266a.87.87 0 0 0 .293-.643V7.455H6z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 10.182c.552 0 1 .407 1 .909v5.455c0 .502-.448.909-1 .909s-1-.407-1-.91v-5.454c0-.502.448-.91 1-.91zM14 10.182c.552 0 1 .407 1 .909v5.455c0 .502-.448.909-1 .909s-1-.407-1-.91v-5.454c0-.502.448-.91 1-.91z"
                fill="#000"
            />
        </SvgIcon>
    );
};

ThrashIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

ThrashIcon.defaultProps = {
    fill: DEFAULT_COLORS.RED,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default ThrashIcon;
