import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentType2MinHrIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <circle cx="6.459" cy="7.544" r="6.459" fill={color} />
                        <path
                            fill={colorInner}
                            d="M6.807 7.194h4.314v.7H6.107V2.88h.7v4.314zm-.7-6.091c.117-.007.233-.018.352-.018.117 0 .232.011.348.018V.7h.388V0H5.719v.7h.388v.403zm7.194 5.703v.388h-.4c.005.116.017.232.017.35 0 .118-.012.233-.018.35h.401v.387h.7V6.806h-.7z"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentType2MinHrIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentType2MinHrIcon.defaultProps = {
    color: `${DEFAULT_COLORS.PEACH}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentType2MinHrIcon;
