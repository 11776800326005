import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AddFileIcon = ({ backgroundFill, fill, height, width, viewBox, ...rest }) => {
    return (
        <SvgIcon cursor="pointer" width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.954.944A3.277 3.277 0 0 1 5.258 0h7.99c.267 0 .525.105.714.293l7.742 6.667c.19.187.296.441.296.707v13.11c0 .855-.343 1.675-.954 2.28a3.277 3.277 0 0 1-2.304.943H5.258c-.864 0-1.693-.34-2.304-.944A3.204 3.204 0 0 1 2 20.778V3.222c0-.854.343-1.674.954-2.278zM5.258 2c-.327 0-.642.129-.874.358-.231.23-.362.54-.362.864v17.556c0 .324.13.635.362.864.232.23.547.358.874.358h13.484c.327 0 .642-.129.873-.358.232-.23.363-.54.363-.864V8.667h-6.73a1.006 1.006 0 0 1-1.012-1V2H5.258zm9 1.414 4.29 3.253h-4.29V3.414zM12 11c.559 0 1.011.448 1.011 1v2.333h2.36c.558 0 1.011.448 1.011 1 0 .553-.453 1-1.011 1h-2.36v2.334c0 .552-.452 1-1.011 1a1.006 1.006 0 0 1-1.011-1v-2.334h-2.36a1.006 1.006 0 0 1-1.011-1c0-.552.453-1 1.011-1h2.36V12c0-.552.452-1 1.011-1z"
                fill={fill}
            />
        </SvgIcon>
    );
};

AddFileIcon.propTypes = {
    backgroundFill: string,
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

AddFileIcon.defaultProps = {
    backgroundFill: DEFAULT_COLORS.YELLOW,
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    width: '24',
    viewBox: '0 0 24 24',
};

export default AddFileIcon;
