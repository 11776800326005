import { array, string, bool, object, oneOfType, func } from 'prop-types';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { Redirect, Route as RouterRoute } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { get } from 'ravenjs/utils/lodash';

import Acl from 'modules/acl';
import { APP_PERMISSIONS } from 'constants/permissions';
import { MODALS } from 'constants/modals';
import { actions as uiActions } from 'modules/ui';
import { IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS } from 'constants/navigation';
import {
    ROOT_PUBLIC_ROUTE,
    ROOT_PRIVATE_ROUTE,
    ROOT_PRIVATE_ROUTE_PLATFORM_LITE,
} from 'constants/routes';
import { selectors as authSelectors } from 'modules/auth';
import { getLoginRedirectSearchString } from 'utils/search';
import MESSAGES from 'constants/messages';
import { isSoftLoginRoute } from 'utils/thrservice';

/**
 * A wrapper around the react-router's `Route` Component.
 *
 * @method      Route
 * @param       {Object} props The passed in props
 * @constructor
 */
function Route(props) {
    const {
        showPermissionModal,
        disabled,
        closeModal,
        failedPermissionsRedirect,
        isImpersonatingUser,
        isLoggedIn,
        isPublic,
        location,
        permissions,
        preventAllPermissionCheck,
        openModal,
        ...rest
    } = props;

    // Create the redirect search param.
    const redirectSearch = getLoginRedirectSearchString(location);
    const route_url = Acl.check(APP_PERMISSIONS.platformliteCompanyDashboardView)
        ? ROOT_PRIVATE_ROUTE_PLATFORM_LITE
        : ROOT_PRIVATE_ROUTE;
    const queryParams = queryString.parse(location.search);

    // If the route is marked as public, render the route.
    if (!isLoggedIn && !isSoftLoginRoute(location.pathname)) {
        const partnerLoginUrl = get(queryParams, 'partnerLoginUrl', null);
        if (partnerLoginUrl) {
            window.location.href = partnerLoginUrl;
            return null;
        }
    }

    if (isPublic) {
        return <RouterRoute {...rest} />;
    }

    // Since the route is not marked as public,
    // check for a logged in user, if present, continue,
    // otherwise redirect to the root public route.
    if (!isLoggedIn) {
        const queryParams = queryString.parse(location.search);
        const partnerLoginUrl = get(queryParams, 'partnerLoginUrl', null);

        // Redirect to partner login url if it is available in url params
        if (partnerLoginUrl) {
            window.location.href = partnerLoginUrl;
            return null;
        }

        // Create the `to` object for the redirect.
        const redirectTo = {
            pathname: ROOT_PUBLIC_ROUTE,
            search: redirectSearch,
        };

        return <Redirect to={redirectTo} />;
    }

    // Do not route to impersonation restricted routes
    if (
        isImpersonatingUser &&
        rest.path &&
        IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS.includes(rest.path)
    ) {
        const redirectTo = {
            pathname: failedPermissionsRedirect || route_url,
            search: redirectSearch,
        };

        return <Redirect to={redirectTo} />;
    }

    // If a list of `permissions` is supplied to the route,
    // use the ACL Service to check if the requested set of permissions
    // allows the logged in user to view the current route.
    if (!_isEmpty(permissions) || disabled) {
        // Check if the user is allowed to view the requested route.
        const allowed = Acl.check(permissions, !preventAllPermissionCheck);
        // Create the `to` object for the redirect.
        const redirectTo = {
            pathname: failedPermissionsRedirect || route_url,
            search: redirectSearch,
        };

        if (showPermissionModal && !allowed) {
            openModal(MODALS.CONFIRM, {
                description: MESSAGES.NO_PERMISSION_MESSAGE,
                actions: [
                    {
                        text: MESSAGES.MODAL.CONFIRM.ACTIONS.OK,
                        color: 'primary',
                        onClick() {
                            closeModal(MODALS.CONFIRM);
                        },
                    },
                ],
            });
        }
        // Based on the `allowed` boolean property, render the route.
        return allowed && !disabled ? <RouterRoute {...rest} /> : <Redirect to={redirectTo} />;
    }
    // Otherwise validate if the user `isLoggedIn` before routing the user.
    return <RouterRoute {...rest} />;
}

Route.propTypes = {
    disabled: bool,
    /**
     * If a list of `permissions` is supplied to the route,
     * then we can define a `failedPermissionsRedirect` param to redirect
     * the user to another route if their permission set
     * doesn't allow them to access the route.
     */
    failedPermissionsRedirect: string,
    isImpersonatingUser: bool,
    /**
     * Is the user logged in?
     */
    isLoggedIn: bool,
    /**
     * If `true`, the user doesn't have to log in
     * to access the route.
     */
    isPublic: bool,
    /**
     * The react-router `location` object.
     */
    location: object.isRequired,
    /**
     * List of permissions to check if we should render the
     * `WrappedComponent` or not. This can be a single permission,
     * or a list of permissions.
     */
    permissions: oneOfType([array, string]),
    /**
     * Allow route when any one permission is available to a user
     * from the passed list of permissions
     */
    preventAllPermissionCheck: bool,
    openModal: func.isRequired,
    closeModal: func.isRequired,
    showPermissionModal: bool,
};

Route.defaultProps = {
    disabled: false,
    showPermissionModal: false,
    failedPermissionsRedirect: null,
    isImpersonatingUser: false,
    isLoggedIn: false,
    isPublic: false,
    permissions: null,
    preventAllPermissionCheck: false,
};

const mapStateToProps = createStructuredSelector({
    isImpersonatingUser: authSelectors.isImpersonatingUser,
    isLoggedIn: authSelectors.isUserLoggedIn,
});

const mapDispatchToProps = {
    openModal: uiActions.openModal,
    closeModal: uiActions.closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Route);
