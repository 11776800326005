import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const CloseIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                d="M9.042 7.356l5.235-5.235c.144-.144.224-.336.224-.541 0-.205-.08-.398-.224-.542L13.82.58c-.145-.145-.337-.224-.542-.224-.205 0-.397.08-.542.224L7.501 5.814 2.266.58C2.122.435 1.929.356 1.724.356c-.205 0-.397.08-.541.224l-.459.458c-.299.3-.299.785 0 1.083l5.235 5.235-5.235 5.235c-.144.144-.223.337-.223.542 0 .205.079.397.223.541l.459.459c.144.144.336.223.541.223.205 0 .398-.079.542-.223L7.5 8.898l5.234 5.235c.145.144.337.223.542.223.205 0 .397-.079.541-.223l.46-.459c.143-.144.223-.336.223-.541 0-.205-.08-.398-.224-.542L9.042 7.356z"
            />
        </SvgIcon>
    );
};

CloseIcon.propTypes = {
    height: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

CloseIcon.defaultProps = {
    height: '15',
    viewBox: '0 0 15 15',
    width: '15',
};

export default CloseIcon;
