import React from 'react';
import { bool, node, object } from 'prop-types';
import styled from 'styled-components';

import { DEFAULT_COLORS } from 'constants/colors';

const TypeAheadContainerStyled = styled.div`
    background-color: ${DEFAULT_COLORS.WHITE};
    border: 1px solid ${DEFAULT_COLORS.LIGHT};
    font-family: Roboto;
    font-size: 0.875rem;
    max-height: 24rem;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    width: 100%;
    z-index: 100;
    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
`;

const IsLoadingContainerStyled = styled.div`
    background-color: ${DEFAULT_COLORS.WHITE};
    border: 1px solid ${DEFAULT_COLORS.LIGHT};
    color: ${DEFAULT_COLORS.LIGHT};
    font-family: Roboto;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    position: relative;
    width: 100%;
    z-index: 10;
`;

const TypeAheadContainer = ({ children, containerProps, isLoading, showTypeAheadContainer }) => {
    return (
        (isLoading && showTypeAheadContainer && (
            <IsLoadingContainerStyled {...containerProps}>Loading...</IsLoadingContainerStyled>
        )) ||
        (children && showTypeAheadContainer && (
            <TypeAheadContainerStyled {...containerProps}>{children}</TypeAheadContainerStyled>
        )) ||
        null
    );
};

TypeAheadContainer.propTypes = {
    children: node,
    containerProps: object,
    isLoading: bool,
    showTypeAheadContainer: bool,
};

TypeAheadContainer.defaultProps = {
    children: null,
    containerProps: {},
    isLoading: false,
    showTypeAheadContainer: true,
};

export default TypeAheadContainer;
