import { string } from 'prop-types';
import React from 'react';

import { renderCheck } from 'ravenjs/utils/viewport';
import SvgIcon from 'ravenjs/lib/SvgIcon';

const FeedbackIconTooltip = ({ viewBox, ...rest }) => (
    <SvgIcon {...rest} width="162" height="34" viewBox={viewBox}>
        <defs>
            <filter
                id="prefix__a"
                width="105.1%"
                height="127.6%"
                x="-2.5%"
                y="-6.9%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                    stdDeviation="1"
                />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161631337 0"
                />
            </filter>
            <path
                id="prefix__b"
                d={
                    renderCheck('md', 'less')
                        ? 'M14.51,29.02h128.98c8.01,0,14.51-6.5,14.51-14.51v0C158,6.5,151.5,0,143.49,0H14.51C6.5,0,0,6.5,0,14.51v0 C0,22.53,6.5,29.02,14.51,29.02z'
                        : 'M143.495 0c7.905 0 14.334 6.326 14.502 14.196l.003.316c0 8.014-6.494 14.512-14.505 14.512H18.505c-5.152 0-9.678-2.688-12.25-6.738L0 14.598 5.864 7.39C8.352 2.98 13.08 0 18.505 0h124.99z'
                }
            />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(2)">
            <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
            <use fill="#EDEDED" xlinkHref="#prefix__b" />
            <text fill="#5E5E5E" fontFamily="Roboto" fontSize="11" fontWeight="400">
                <tspan x="16.5" y="18.012">
                    Thanks for your feedback.
                </tspan>
            </text>
        </g>
    </SvgIcon>
);

FeedbackIconTooltip.propTypes = {
    viewBox: string,
};

FeedbackIconTooltip.defaultProps = {
    viewBox: '0 0 162 34',
};

export default FeedbackIconTooltip;
