import axios from 'axios';

/**
 * API to fetch surveys
 *
 * @method fetchSurveysAPI
 * @return {Promise}
 */
export const fetchSurveysAPI = () => {
    return axios({
        method: 'get',
        type: 'surveys',
        url: '/api/internal/surveys',
    });
};

/**
 * fetch the structure of the survey by surveyId
 *
 * @method fetchSurveyAPI
 * @param  {number} surveyId
 * @param  {boolean} useExternalEndpoint
 * @return {Promise}
 */
export const fetchSurveyAPI = (surveyId, useExternalEndpoint = false) => {
    const url = useExternalEndpoint
        ? `/api/v1/survey/${surveyId}`
        : `/api/internal/survey/${surveyId}`;
    return axios({
        method: 'get',
        type: 'surveys',
        url,
    });
};

/**
 * fetch the Q&A results of a survey by surveyResponseId
 *
 * @method fetchSurveyResponseAPI
 * @param  {number} surveyResponseId
 * @param  {boolean} useExternalEndpoint
 * @return {Promise}
 */
export const fetchSurveyResponseAPI = (surveyResponseId, useExternalEndpoint = false) => {
    const url = useExternalEndpoint
        ? `/api/v1/surveyResponse/${surveyResponseId}`
        : `/api/internal/surveyResponse/${surveyResponseId}`;

    return axios({
        method: 'get',
        type: 'surveys',
        url,
    });
};

/**
 * API to fetch survey responses
 *
 * @method fetchSurveyResponsesAPI
 * @param  {Object} params
 * @param  {Object} useExternalEndpoint
 * @return {Promise}
 */
export const fetchSurveyResponsesAPI = (params, useExternalEndpoint = false) => {
    const url = useExternalEndpoint ? '/api/v1/surveyResponses' : '/api/internal/surveyResponses';

    return axios({
        method: 'get',
        type: 'surveys',
        url,
        params,
    });
};
