import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    fetchNotificationsAPI,
    updateNotificationsAPI,
    fetchNotificationsTypeAPI,
    deleteNotificationsAPI,
} from 'modules/apis';

import * as notificationActions from '../actions';

/**
 * Saga to fetch notifications list
 *
 * @method fetchNotificationsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchNotificationsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchNotificationsAPI, payload);
        const data = get(response, 'data', {});
        yield put(notificationActions.fetchNotifications.success(data));
        return response;
    } catch (error) {
        yield put(notificationActions.fetchNotifications.error(error));
        return error;
    }
}

/**
 * Saga to update notifications ID for delete or status
 *
 * @method updateNotificationsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* updateNotificationsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateNotificationsAPI, payload);
        const data = get(response, 'data', {});
        yield put(notificationActions.updateNotifications.success(data));
        return response;
    } catch (error) {
        yield put(notificationActions.updateNotifications.error(error));
        return error;
    }
}

/**
 * Saga to get notification types
 *
 * @method fetchNotificationsTypeSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchNotificationsTypeSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchNotificationsTypeAPI, payload);
        const data = get(response, 'data', {});
        yield put(notificationActions.fetchNotificationsType.success(data));
        return response;
    } catch (error) {
        yield put(notificationActions.fetchNotificationsType.error(error));
        return error;
    }
}

/**
 * Saga to delete notification ID
 *
 * @method deleteNotificationsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* deleteNotificationsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(deleteNotificationsAPI, payload);
        yield put(notificationActions.deleteNotifications.success(response));
        return response;
    } catch (error) {
        yield put(notificationActions.deleteNotifications.error(error));
        return error;
    }
}

/**
 * Saga to fetch notifications menu list
 *
 * @method fetchNotificationsSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchMenuNotificationsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchNotificationsAPI, payload);
        const data = get(response, 'data', {});
        yield put(notificationActions.fetchMenuNotifications.success(data));
        return response;
    } catch (error) {
        yield put(notificationActions.fetchMenuNotifications.error(error));
        return error;
    }
}
