import axios from 'axios';

/**
 * API call to logout user.
 *
 * @param {string}    url
 * @return {Promise}
 */
export const logoutAPI = url => {
    return axios({
        method: 'get',
        url: url || `/v2/oauth/logout`,
        withCredentials: true,
    });
};

/**
 * API call to logout from external vendors.
 *
 * @param   {string}    logoutUrl
 * @return  {Promise}
 */
export const logoutFromVendorsAPI = logoutUrl => {
    return axios({
        method: 'get',
        url: `/v2/oauth/vendor-logout?logoutUrl=${logoutUrl}`,
        ignoreAuthorization: true,
    });
};
