import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import throttle from 'lodash/throttle';

import { COLORS } from 'ravenjs/constants/colors';
import Box from 'ravenjs/lib/Box';
import Col from 'ravenjs/lib/Col';
import DocumentPreviewAction from 'ravenjs/lib/DocumentPreviewAction';
import Pagination from 'ravenjs/lib/Pagination';
import Responsive from 'ravenjs/lib/Responsive';
import Row from 'ravenjs/lib/Row';
import { ZoomInIcon, ZoomOutIcon, SearchIcon, ClearIcon } from 'ravenjs/lib/SvgIcon';
import Typography from 'ravenjs/lib/Typography';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MobileDocumentBlanket = styled.div`
    background-color: ${COLORS.GREY_10};
    height: 16.375rem;
    margin: 0 0 -16.375rem 0;
    width: 16.375rem;
    z-index: 5000;
    opacity: 0.69;
    position: absolute;
    cursor: pointer;
`;

const MobileDocumentBlanketIcon = styled(SearchIcon)`
    margin: 6rem 0 0 6rem;
`;

const MobileModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: ${COLORS.GREY_10};
    z-index: 6000;
`;

class PreviewFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numPages: null,
            scale: 1.0,
            pageNumber: 1,
            width: null,
            mobileModal: false,
        };

        this.pdfWrapperRef = React.createRef();
    }

    componentDidMount() {
        this.setDivSize();
        window.addEventListener('resize', throttle(this.setDivSize, 500));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', throttle(this.setDivSize, 500));
    }

    handleMobileModalOpen = () => {
        document.body.style.overflow = 'hidden';
        this.setState({
            mobileModal: true,
        });
    };

    handleMobileModalClose = () => {
        document.body.style.overflow = 'unset';
        this.setState({
            mobileModal: false,
        });
    };

    setDivSize = () => {
        if (this.pdfWrapperRef.current) {
            this.setState({ width: this.pdfWrapperRef.current.getBoundingClientRect().width });
        }
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages, pageNumber: 1 });
    };

    handleOnChangePage = (config, pageNumber) => {
        this.setState({ pageNumber });
    };

    handleZoomIn = () => {
        const { scale } = this.state;
        this.setState({
            scale: scale + 0.5,
        });
    };

    getPaginationSchema = () => {
        const { numPages, pageNumber } = this.state;
        const pagination = {
            hidePagination: true,
            currentPage: pageNumber,
            rowsPerPage: 1,
            onChangePage: this.handleOnChangePage,
            startAtPage: 1,
            count: 1,
            totalRows: numPages,
            goToPage: false,
        };

        return pagination;
    };

    handleZoomOut = () => {
        const { scale } = this.state;
        this.setState({
            scale: scale - 0.5,
        });
    };

    render() {
        const {
            colorsFavoriteIcon,
            hideFavorite,
            hideShare,
            isFavorite,
            url,
            onClickDownload,
            onClickFavorite,
            onClickPrint,
            formTitle,
        } = this.props;
        const { pageNumber, numPages, scale, width, mobileModal } = this.state;
        const pagination = this.getPaginationSchema();

        return (
            <>
                <Typography
                    gutterTop="0"
                    gutterBottom="1rem"
                    gutterRight="0"
                    type="h2"
                    color={COLORS.GREY_1}
                >
                    Preview:
                </Typography>
                <Responsive size="md" value="greater">
                    <Box
                        borderStyle="solid"
                        borderWidth="1px"
                        borderColor={COLORS.GREY}
                        backgroundColor={COLORS.WHITE_1}
                        borderRadius="4px"
                        overflow="auto"
                        height="44.3125rem"
                        padding="0"
                        margin="0 0 1.5625rem 0"
                    >
                        <Row margin="1.4rem 3.688rem 0 3.688rem">
                            <DocumentPreviewAction
                                hideFavorite={hideFavorite}
                                hideShare={hideShare}
                                isFavorite={isFavorite}
                                colorsFavoriteIcon={colorsFavoriteIcon}
                                url={url}
                                onClickDownload={onClickDownload}
                                onClickFavorite={onClickFavorite}
                                onClickPrint={onClickPrint}
                            />
                            <Col margin="-1.312rem 0 0 0">
                                {numPages > 1 && <Pagination {...pagination} />}
                            </Col>
                            <Col>
                                <Row display="flex" justify="flex-end">
                                    <ZoomInIcon
                                        onClick={this.handleZoomIn}
                                        margin="0 0.885rem 0 0"
                                    />
                                    <ZoomOutIcon onClick={this.handleZoomOut} />
                                </Row>
                            </Col>
                        </Row>
                        <Row margin="3.241rem 3.688rem 0 3.688rem" ref={this.pdfWrapperRef}>
                            <Document
                                loading=""
                                file={url}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                <Page scale={scale} pageNumber={pageNumber} width={width} />
                            </Document>
                        </Row>
                    </Box>
                </Responsive>
                <Responsive size="md" value="less">
                    <>
                        <Box
                            borderStyle="solid"
                            borderWidth="1px"
                            borderColor={COLORS.GREY_3}
                            backgroundColor={COLORS.GREY_10}
                            borderRadius="4px"
                            overflow="hidden"
                            height="16.375rem"
                            width="16.375rem"
                            padding="0"
                            margin="auto"
                            position="relative"
                        >
                            <MobileDocumentBlanket>
                                <MobileDocumentBlanketIcon
                                    onClick={this.handleMobileModalOpen}
                                    height="3.438rem"
                                    width="3.438rem"
                                />
                            </MobileDocumentBlanket>
                            <Document
                                loading=""
                                file={url}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                <Page scale={0.33} pageNumber={pageNumber} />
                            </Document>
                        </Box>
                        {mobileModal && (
                            <MobileModal>
                                <Row margin="0.5rem 0 0 0">
                                    <Col margin="1rem 0 0 0" size={11}>
                                        <Typography
                                            gutterTop="0"
                                            gutterBottom="1rem"
                                            gutterRight="0"
                                            type="h2"
                                            color={COLORS.GREY_1}
                                        >
                                            {formTitle}
                                        </Typography>
                                    </Col>
                                    <Col size={1} padding="0 0 0 0" margin="0 0 0 0">
                                        <ClearIcon
                                            onClick={this.handleMobileModalClose}
                                            height="2.188rem"
                                            width="2.188rem"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col padding="1.77rem 0 0 2rem" size={9}>
                                        <DocumentPreviewAction
                                            hideFavorite={hideFavorite}
                                            hideShare={hideShare}
                                            isFavorite={isFavorite}
                                            colorsFavoriteIcon={colorsFavoriteIcon}
                                            url={url}
                                            onClickDownload={onClickDownload}
                                            onClickFavorite={onClickFavorite}
                                            onClickPrint={onClickPrint}
                                        />
                                    </Col>
                                    <Col padding="2rem 0 0 0" size={3}>
                                        <ZoomInIcon
                                            onClick={this.handleZoomIn}
                                            margin="0 0.885rem 0 0"
                                        />
                                        <ZoomOutIcon onClick={this.handleZoomOut} />
                                    </Col>
                                </Row>
                                <Row padding="0 0 0 1.252rem">
                                    <Document
                                        loading=""
                                        file={url}
                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                    >
                                        <Page
                                            scale={scale}
                                            pageNumber={pageNumber}
                                            width={window.innerWidth - 40}
                                        />
                                    </Document>
                                </Row>
                                <Row>
                                    <Col margin="0 0 0 0">
                                        {numPages > 1 && <Pagination {...pagination} />}
                                    </Col>
                                </Row>
                            </MobileModal>
                        )}
                    </>
                </Responsive>
            </>
        );
    }
}

PreviewFile.propTypes = {
    hideFavorite: PropTypes.bool,
    hideShare: PropTypes.bool,
    isFavorite: PropTypes.string,
    colorsFavoriteIcon: PropTypes.object,
    url: PropTypes.string,
    onClickDownload: PropTypes.func,
    onClickFavorite: PropTypes.func,
    onClickPrint: PropTypes.func,
    formTitle: PropTypes.string,
};

PreviewFile.defaultProps = {
    hideFavorite: false,
    hideShare: false,
    isFavorite: '0',
    colorsFavoriteIcon: {},
    url: '',
    onClickDownload: null,
    onClickFavorite: null,
    onClickPrint: null,
    formTitle: '',
};

export default PreviewFile;
