import { get } from 'ravenjs/utils/lodash';
import { call, put } from 'redux-saga/effects';

import * as adminActions from 'modules/admin/actions';
import {
    fetchCommunicationTypesAPI,
    fetchContentfulContentAPI,
    updateUsersNewslettersSubscription,
} from 'modules/apis';

/**
 * Fetch categories for newsletter subscription
 *
 * @method fetchNewsletterCategoriesSaga
 * @type   {Generator}
 */
export function* fetchNewsletterCategoriesSaga() {
    try {
        const response = yield call(fetchContentfulContentAPI, { contentType: 'newsletter' });
        const categories = get(response, 'data.contentModels', []);
        yield put(adminActions.fetchNewsletterCategories.success(categories));
    } catch (e) {
        yield put(adminActions.fetchNewsletterCategories.error(e));
    }
}

/**
 * Update user's newsletter subscription.
 *
 * @param {Object}      action
 * @type {Generator}
 */
export function* updateUsersNewslettersSubscriptionSaga(action) {
    try {
        const response = yield call(updateUsersNewslettersSubscription, action.payload);
        yield put(adminActions.updateUsersNewslettersSubscription.success(response));
    } catch (e) {
        yield put(adminActions.updateUsersNewslettersSubscription.error(e));
    }
}

/**
 * Fetch communication types
 *
 * @param {Object}      action
 * @type {Generator}
 */
export function* fetchCommunicationTypesSaga(action) {
    try {
        const response = yield call(fetchCommunicationTypesAPI, {
            contentType: ['newsletter', 'news-letter'],
        });
        const communicationTypes = get(response, 'data.contentModels', []);
        yield put(adminActions.fetchCommunicationTypes.success(communicationTypes));
    } catch (e) {
        yield put(adminActions.fetchCommunicationTypes.error(e));
    }
}

/**
 * Update page status
 *
 * @param {Object}      action
 * @type {Generator}
 */
export function* updatePageStatusSaga(action) {
    const { payload } = action;
    yield put(adminActions.updatePageStatus.success(payload));
}
