import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { fetchResourceByTypeAPI } from '../../apis';
import * as legalAndPrivacyActions from '../actions';

/**
 * Fetch resource by type.
 *
 * @method fetchResourceByTypeSaga
 *
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchResourceByTypeSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchResourceByTypeAPI, payload);
        const staticResource = get(resp, 'data.static-resource', {});
        yield put(legalAndPrivacyActions.fetchResourceByType.success(staticResource));
        return staticResource;
    } catch (error) {
        yield put(legalAndPrivacyActions.fetchResourceByType.error(error));
        return error;
    }
}
