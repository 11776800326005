import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const PlayIcon = ({ height, viewBox, width, color, fill, stroke, filter, ...rest }) => (
    <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        {...rest}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        filter={filter}
    >
        <circle cx="18" cy="18" r="14" fill={fill} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 4.02C10.77 4.02 4.02 10.77 4.02 18S10.77 31.98 18 31.98 31.98 25.23 31.98 18 25.23 4.02 18 4.02zM2 18C2 9.163 9.163 2 18 2s16 7.163 16 16-7.163 16-16 16S2 26.837 2 18z"
            fill={stroke}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.405 10.9a1.454 1.454 0 0 1 1.493.072l8.727 5.818a1.455 1.455 0 0 1 0 2.42l-8.727 5.818a1.454 1.454 0 0 1-2.262-1.21V12.182c0-.537.296-1.03.769-1.283zm1.252 2.222v9.756L22.974 18l-7.317-4.878z"
            fill={stroke}
        />
    </SvgIcon>
);

PlayIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
    filter: string,
    fill: string,
    stroke: string,
};

PlayIcon.defaultProps = {
    viewBox: '0 0 36 36',
    width: '36',
    height: '36',
    color: DEFAULT_COLORS.WHITE,
    // filter: 'drop-shadow( 0 1px 4px rgba(0, 0, 0, 0.15))',
    filter: '',
    fill: DEFAULT_COLORS.WHITE,
    stroke: DEFAULT_COLORS.BLACK,
};

export default PlayIcon;
