import React from 'react';
import { string, object } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ZoomInIcon = ({ fill, height, width, viewBox, setRef, ...rest }) => (
    <SvgIcon
        cursor="pointer"
        height={height}
        width={width}
        viewBox={viewBox}
        {...rest}
        ref={setRef}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 4a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.943 15.943a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414zM11 7a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 11a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z"
            fill={fill}
        />
    </SvgIcon>
);

ZoomInIcon.propTypes = {
    fill: string,
    setRef: object,
    height: string,
    width: string,
    viewBox: string,
};

ZoomInIcon.defaultProps = {
    fill: `${DEFAULT_COLORS.BLACK}`,
    height: '24',
    setRef: null,
    width: '24',
    viewBox: '0 0 24 24',
};

export default ZoomInIcon;
