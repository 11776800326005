import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const LogoutIcon = ({ fill, height, viewBox, width, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h4a1 1 0 1 1 0 2H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h4a1 1 0 0 1 0 2H5zM15.293 6.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L19.586 12l-4.293-4.293a1 1 0 0 1 0-1.414z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1z"
            fill={fill}
        />
    </SvgIcon>
);

LogoutIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

LogoutIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK_00,
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
};

export default LogoutIcon;
