import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const TrainingIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill={color}>
            <path
                d="M2.82770104,5.84673775 C2.82770104,5.58777991 2.82770104,5.32885842 2.82770104,5.06993694 C2.82773694,4.89964934 2.82780875,4.89946757 2.98010369,4.95443659 C3.89369381,5.28417793 4.80724806,5.61406471 5.72087409,5.94373333 C5.95674816,6.02887713 6.19348384,6.11169421 6.42863989,6.19887388 C6.72170532,6.30757583 7.01329879,6.31335631 7.30891327,6.20665391 C8.46677844,5.78864222 9.62546935,5.37288453 10.7838013,4.95621797 C10.9352705,4.90172161 10.9352346,4.90150344 10.9352346,5.07037319 C10.9352705,5.5836718 10.9299211,6.09704309 10.9373887,6.61023257 C10.9414097,6.88664091 10.8364331,7.1020089 10.6332296,7.27596839 C10.3259471,7.5390707 9.96057568,7.68772698 9.58371571,7.80726275 C8.43468232,8.17172327 7.25728663,8.26344738 6.06176056,8.16783329 C5.31733918,8.10831988 4.58889402,7.97147906 3.88845218,7.70223269 C3.61538397,7.59723895 3.35430693,7.46937784 3.12934699,7.27553209 C2.92646657,7.10066377 2.81721775,6.88624102 2.82601366,6.60983268 C2.83405564,6.35571008 2.82770104,6.10111483 2.82770104,5.84673775"
                fill={color}
            />
            <path
                d="M12.8952996,4.28385417 L12.8952996,4.44402401 C12.8952996,5.53052574 12.8961712,6.61702751 12.8936654,7.70352924 C12.8934475,7.79044649 12.9110972,7.84713479 12.9863807,7.90567163 C13.4196329,8.24253936 13.4129144,8.87314241 12.9739969,9.20112993 C12.9092451,9.24951798 12.8939923,9.29689114 12.8942102,9.36909264 C12.8960986,9.99382389 12.896607,10.6185914 12.8945733,11.2433226 C12.8938106,11.4714169 12.7464395,11.6378936 12.5323192,11.6636642 C12.3309459,11.6879489 12.1466049,11.5629374 12.0934742,11.3603963 C12.0799646,11.3089636 12.0791294,11.2532177 12.0790204,11.1994653 C12.0781125,10.5883262 12.0773862,9.97715083 12.0798194,9.3659755 C12.0801462,9.29178052 12.0594823,9.24698079 11.9964374,9.19975261 C11.5557766,8.86995278 11.5568298,8.22535891 11.9990885,7.89476166 C12.0632228,7.84680859 12.0797467,7.80103022 12.0796378,7.72846628 C12.0778583,6.71891422 12.0798557,5.70936213 12.0761151,4.69984632 C12.075752,4.60430258 12.1008101,4.55997407 12.1948328,4.53018008 C12.4264938,4.45678252 12.6538332,4.36979278 12.8952996,4.28385417"
                fill={colorInner}
            />
            <path
                d="M13.849931,2.85129831 C13.7618633,3.02028988 13.614302,3.10293277 13.4380582,3.16503187 C11.337781,3.90504011 9.23898298,4.64907792 7.14083436,5.39509456 C6.99276795,5.44773129 6.85808671,5.44701173 6.70951521,5.39415913 C4.58618374,4.63857217 2.46191428,3.8855037 0.336165581,3.13678865 C0.180558756,3.08199322 0.0814509551,2.98222451 -1.44314236e-05,2.85129831 L-1.44314236e-05,2.6085146 C0.0302915581,2.46337685 0.126513075,2.37756785 0.256937066,2.32036184 C0.451220105,2.23520045 0.654558866,2.17475637 0.854109375,2.10477796 C2.35043153,1.57999383 3.84513015,1.05071238 5.34224604,0.528302837 C5.78879035,0.372479441 6.23482957,0.215037007 6.68068843,0.0571628291 C6.84531489,-0.00115851151 7.00434918,-0.000834703948 7.16922819,0.0565871709 C8.42836991,0.495202509 9.68567161,0.93917866 10.9433341,1.38196752 C11.7591785,1.66914885 12.5753477,1.95535876 13.3908675,2.24343956 C13.4545822,2.26596217 13.5187659,2.28686575 13.5807128,2.31431743 C13.7235118,2.37760382 13.8355357,2.4656435 13.849931,2.63549856 L13.849931,2.85129831 Z"
                fill={color}
            />
        </SvgIcon>
    );
};

TrainingIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

TrainingIcon.defaultProps = {
    color: `${DEFAULT_COLORS.BLACK}`,
    colorInner: `${DEFAULT_COLORS.YELLOW}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default TrainingIcon;
