import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const DeleteIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66699 8.72723C2.66699 8.0578 3.26395 7.51511 4.00033 7.51511H28.0003C28.7367 7.51511 29.3337 8.0578 29.3337 8.72723C29.3337 9.39667 28.7367 9.93935 28.0003 9.93935H4.00033C3.26395 9.93935 2.66699 9.39667 2.66699 8.72723Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3337 5.09087C12.98 5.09087 12.6409 5.21857 12.3909 5.44589C12.1408 5.67321 12.0003 5.98152 12.0003 6.30299V7.51511H20.0003V6.30299C20.0003 5.98152 19.8598 5.67321 19.6098 5.44589C19.3598 5.21857 19.0206 5.09087 18.667 5.09087H13.3337ZM22.667 7.51511V6.30299C22.667 5.33857 22.2456 4.41364 21.4954 3.73169C20.7453 3.04974 19.7279 2.66663 18.667 2.66663H13.3337C12.2728 2.66663 11.2554 3.04974 10.5052 3.73169C9.75509 4.41364 9.33366 5.33857 9.33366 6.30299V7.51511H6.66699C5.93061 7.51511 5.33366 8.0578 5.33366 8.72723V25.6969C5.33366 26.6614 5.75509 27.5863 6.50523 28.2682C7.25538 28.9502 8.27279 29.3333 9.33366 29.3333H22.667C23.7279 29.3333 24.7453 28.9502 25.4954 28.2682C26.2456 27.5863 26.667 26.6614 26.667 25.6969V8.72723C26.667 8.0578 26.07 7.51511 25.3337 7.51511H22.667ZM8.00033 9.93935V25.6969C8.00033 26.0184 8.1408 26.3267 8.39085 26.554C8.6409 26.7813 8.98004 26.909 9.33366 26.909H22.667C23.0206 26.909 23.3598 26.7813 23.6098 26.554C23.8598 26.3267 24.0003 26.0184 24.0003 25.6969V9.93935H8.00033Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3337 13.5757C14.07 13.5757 14.667 14.1184 14.667 14.7878V22.0606C14.667 22.73 14.07 23.2727 13.3337 23.2727C12.5973 23.2727 12.0003 22.73 12.0003 22.0606V14.7878C12.0003 14.1184 12.5973 13.5757 13.3337 13.5757Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.667 13.5757C19.4034 13.5757 20.0003 14.1184 20.0003 14.7878V22.0606C20.0003 22.73 19.4034 23.2727 18.667 23.2727C17.9306 23.2727 17.3337 22.73 17.3337 22.0606V14.7878C17.3337 14.1184 17.9306 13.5757 18.667 13.5757Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

DeleteIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

DeleteIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '32',
    viewBox: '0 0 32 32',
    width: '32',
};

export default DeleteIcon;
