import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { string } from 'prop-types';
import { DEFAULT_COLORS } from 'constants/colors';

const CrossIconWithCircle = ({ height, viewBox, width, fill }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.81818C5.48131 1.81818 1.81818 5.48131 1.81818 10C1.81818 14.5187 5.48131 18.1818 10 18.1818C14.5187 18.1818 18.1818 14.5187 18.1818 10C18.1818 5.48131 14.5187 1.81818 10 1.81818ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3701 6.6299C13.7251 6.98493 13.7251 7.56053 13.3701 7.91555L7.91555 13.3701C7.56053 13.7251 6.98493 13.7251 6.6299 13.3701C6.27488 13.0151 6.27488 12.4395 6.6299 12.0844L12.0844 6.6299C12.4395 6.27488 13.0151 6.27488 13.3701 6.6299Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.6299 6.6299C6.98493 6.27488 7.56053 6.27488 7.91555 6.6299L13.3701 12.0844C13.7251 12.4395 13.7251 13.0151 13.3701 13.3701C13.0151 13.7251 12.4395 13.7251 12.0844 13.3701L6.6299 7.91555C6.27488 7.56053 6.27488 6.98493 6.6299 6.6299Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CrossIconWithCircle.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
};

CrossIconWithCircle.defaultProps = {
    height: '20',
    viewBox: '0 0 20 20',
    width: '20',
    fill: DEFAULT_COLORS.LINK,
};

export default CrossIconWithCircle;
