const FITNESS_TEST = {
    ACTIONS: {
        ADD_FAVORITE: 'add favorite',
        REMOVE_FAVORITE: 'remove favorite',
    },
    DOWNLOAD: {
        event: 'fitnessTestViewResults',
        eventProperties: {
            description: 'User click on download',
        },
    },
    PRINT: {
        event: 'fitnessTestViewResults',
        eventProperties: {
            description: 'User click on print',
        },
    },
    SAVE: {
        event: 'fitnessTestViewResults',
        eventProperties: {
            description: 'User click on save',
        },
    },
    SHARE: {
        event: 'fitnessTestViewResults',
        eventProperties: {
            description: 'User click on share',
        },
    },
    WIDGET_TAKE_TEST: {
        event: 'fitnessTestWidget',
        eventProperties: {
            description: 'User click on take test',
        },
    },
    WIDGET_RETAKE_TEST: {
        event: 'fitnessTestWidget',
        eventProperties: {
            description: 'User click on retake test',
        },
    },
    WIDGET_VIEW_RESULTS: {
        event: 'fitnessTestWidget',
        eventProperties: {
            description: 'User click on view results',
        },
    },
};

export default FITNESS_TEST;
