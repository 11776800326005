import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const WarningFilledIcon = ({ height, viewBox, width, color, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                d="M1,21.5h22l-11-19L1,21.5z M13,18.5h-2v-2h2V18.5z M13,14.5h-2v-4h2V14.5z"
                fill={color}
            />
        </SvgIcon>
    );
};

WarningFilledIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

WarningFilledIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
    color: `${DEFAULT_COLORS.BLACK}`,
};

export default WarningFilledIcon;
