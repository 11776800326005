import axios from 'axios';

/**
 * API to fetch audit trail events.
 *
 * @method fetchAuditTrailEvents
 * @param  {Object}  eventInfo
 * @return {Promise}
 */
export const fetchAuditTrailEventsAPI = eventInfo => {
    const { entityId, entityType } = eventInfo;
    return axios({
        method: 'get',
        url: `/v2/events?entityId=${entityId}&entityType=${entityType}`,
        params: {
            limit: 100,
        },
    });
};
