import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import _times from 'lodash/times';
import styled, { keyframes } from 'styled-components';

import { LoadingIcon } from 'components/Icons';

const loadingItemAnimation1 = keyframes`
  0% { opacity: 0; }
  50%{ opacity: 1; }
  60%{ opacity: 0.6; }
  100%{ opacity: 0; }
`;

const loadingItemAnimation2 = keyframes`
  0% { opacity: 0; }
  10%{ opacity: 0; }
  60%{ opacity: 1; }
  70%{ opacity: 0.6; }
  100%{ opacity: 0; }
`;

const loadingItemAnimation3 = keyframes`
  0% { opacity: 0; }
  15%{ opacity: 0; }
  70%{ opacity: 1; }
  80%{ opacity: 0.6; }
  100%{ opacity: 0; }
`;

const loadingItemAnimation4 = keyframes`
  0% { opacity: 0; }
  20%{ opacity: 0; }
  90%{ opacity: 1; }
  100%{ opacity: 0; }
`;

const LoadingIconStyled = styled(LoadingIcon)`
    margin: auto;
`;

const LoadingWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const LoadingItem = styled.div`
    margin: ${({ iconWidth }) => iconWidth / 10}px;

    &:nth-child(1) {
        animation: ${loadingItemAnimation1} 2s linear infinite;
    }
    &:nth-child(2) {
        animation: ${loadingItemAnimation2} 2s linear infinite;
    }
    &:nth-child(3) {
        animation: ${loadingItemAnimation3} 2s linear infinite;
    }
    &:nth-child(4) {
        animation: ${loadingItemAnimation4} 2s linear infinite;
    }
`;

const LoadingIcons = ({ height, width }) => {
    const renderLoadingIcons = () => {
        const loadingIcons = [];
        const LOADING_ICONS_COUNT = 4;

        _times(LOADING_ICONS_COUNT, index => {
            loadingIcons.push(
                <LoadingItem index={index} key={`loading_icon_${index}`} iconWidth={width}>
                    <LoadingIconStyled height={height.toString()} width={width.toString()} />
                </LoadingItem>
            );
        });

        return loadingIcons;
    };

    return <LoadingWrapper>{renderLoadingIcons()}</LoadingWrapper>;
};

LoadingIcons.propTypes = {
    height: oneOfType([number, string]),
    width: oneOfType([number, string]),
};

LoadingIcons.defaultProps = {
    height: 65.22,
    width: 65.22,
};

export default LoadingIcons;
