import styled from 'styled-components';

import ButtonLink from 'ravenjs/lib/Button/ButtonLink';

const FooterLink = styled(ButtonLink)`
    border-bottom: none;
    transition: color 0.3s ease-in-out;
`;

export default FooterLink;
