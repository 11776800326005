import _cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { arrayOf, string, bool, func, shape, object } from 'prop-types';
import { connect } from 'react-redux';
import { components } from 'react-select';
import AsyncReactSelect from 'react-select/lib/Async';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import Typography from 'ravenjs/lib/Typography';

import { CloseIcon, NavigationArrowLeft, SearchIcon } from 'components/Icons';
import { DEFAULT_COLORS } from 'constants/colors';
import { DEFAULT_TITLE } from 'constants/safety';
import { selectors as userSelectors } from 'modules/user';
import { getNavigationSearchOptions } from 'utils/navigation';
import { isEmployee } from 'utils/user';

import NavItems from './NavItems';

const BackButton = styled.div`
    align-items: center;
    display: flex;
    margin-top: 2.85rem;
`;

const Backdrop = styled.div`
    backdrop-filter: blur(6px);
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 900;
`;

const CloseContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const NavItemsContainer = styled.div`
    margin-top: 3rem;
`;

const NavSearch = styled.div`
    align-items: center;
    border-bottom: solid 1px ${DEFAULT_COLORS.BLACK};
    display: none;
    justify-content: space-between;
    margin-right: 2.44rem;
    margin-top: 2.85rem;
`;

const SideMenuContainer = styled.div`
    background: ${DEFAULT_COLORS.WHITE};
    height: 100%;
    left: ${({ isOpen }) => (isOpen ? '2.2rem' : '100%')};
    padding: 1.5rem 1.5rem 1.5rem 1.97rem;
    position: fixed;
    overflow-y: scroll;
    right: 0;
    top: 0;
    transition: 0.3s;
    z-index: 1000;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SearchIcon fill={DEFAULT_COLORS.BLACK} />
        </components.DropdownIndicator>
    );
};

const SideMenu = props => {
    const { isOpen, onClose, user } = props;
    let defaultAppNavItems = props.appNavItems;
    if (isEmployee(user)) {
        defaultAppNavItems = defaultAppNavItems.filter(item => item.title !== DEFAULT_TITLE);
    }
    const [selected, setSelected] = useState(null);
    const [navigationItems, setNavigationItems] = useState(defaultAppNavItems);
    const [isFiltered, setFilteredStatus] = useState(false);
    const navSearchOptions = getNavigationSearchOptions(navigationItems);

    const loadSearchOptions = (inputValue, callback) => {
        if (inputValue.length <= 2) {
            callback([]);
        } else {
            const searchOptions = navSearchOptions.filter(
                ({ label }) => label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            );
            callback(searchOptions);
        }
    };

    const onChangeSelection = selection => {
        setSelected(selection);
    };

    const onClickBack = () => {
        onChangeSelection(null);
    };

    const onClickClose = () => {
        onClose();
        setSelected(null);
        setFilteredStatus(false);
        setNavigationItems(defaultAppNavItems);
    };

    const onSelectSearchOption = selectedValue => {
        if (selectedValue) {
            const { parent, value } = selectedValue;
            if (parent) {
                const formattedNavigationItems = navigationItems.map(navigationItem => {
                    if (navigationItem.state === parent) {
                        const updatedItem = _cloneDeep(navigationItem);
                        updatedItem.subnav.main = navigationItem.subnav.main.filter(
                            ({ state }) => state === value
                        );
                        return updatedItem;
                    }
                    return navigationItem;
                });
                setNavigationItems(formattedNavigationItems);
                setSelected(parent);
            } else {
                setSelected(value);
            }
            setFilteredStatus(true);
        } else {
            setFilteredStatus(false);
            setSelected(null);
            setNavigationItems(defaultAppNavItems);
        }
    };

    const navigationSearchStyles = {
        container: provided => ({
            ...provided,
            width: '100%',
        }),
        control: provided => ({
            ...provided,
            border: 'none',
        }),
        indicatorSeparator: provided => ({
            ...provided,
            display: 'none',
        }),
        input: provided => ({
            ...provided,
            fontSize: '0.875rem',
        }),
        placeholder: provided => ({
            ...provided,
            color: DEFAULT_COLORS.PLACEHOLDER,
            fontSize: '0.875rem',
        }),
    };
    return (
        <>
            <SideMenuContainer isOpen={isOpen}>
                <CloseContainer>
                    <CloseIcon onClick={onClickClose} />
                </CloseContainer>
                {selected && !isFiltered ? (
                    <BackButton>
                        <NavigationArrowLeft style={{ marginRight: '0.8rem' }} />
                        <Typography
                            as="span"
                            fontFamily="Roboto"
                            fontSize="0.875rem"
                            fontWeight="500"
                            gutterBottom="0"
                            gutterTop="0"
                            onClick={onClickBack}
                            style={{
                                letterSpacing: '0.93px',
                            }}
                        >
                            ALL
                        </Typography>
                    </BackButton>
                ) : (
                    <NavSearch>
                        <AsyncReactSelect
                            classNamePrefix="navigation-search__"
                            components={{ DropdownIndicator }}
                            id="navigation-search"
                            isClearable
                            loadOptions={loadSearchOptions}
                            onChange={onSelectSearchOption}
                            placeholder="Look for item"
                            styles={navigationSearchStyles}
                        />
                    </NavSearch>
                )}
                <NavItemsContainer>
                    <NavItems
                        items={navigationItems}
                        selected={selected}
                        onChangeSelection={onChangeSelection}
                        onRedirect={(selectedParent, selectedChild) => {
                            onClose(selectedParent, selectedChild);
                        }}
                    />
                </NavItemsContainer>
            </SideMenuContainer>
            {isOpen && <Backdrop />}
        </>
    );
};

SideMenu.propTypes = {
    appNavItems: arrayOf(
        shape({
            state: string.isRequired,
            subnav: shape({
                main: arrayOf(
                    shape({
                        label: string.isRequired,
                        state: string.isRequired,
                    })
                ).isRequired,
                topics: arrayOf(
                    shape({
                        id: string,
                        label: string,
                        state: string,
                    })
                ),
            }).isRequired,
            title: string.isRequired,
            to: string.isRequired,
        })
    ).isRequired,
    isOpen: bool,
    onClose: func.isRequired,
    user: object.isRequired,
};

SideMenu.defaultProps = {
    isOpen: false,
};

const mapStateToProps = createStructuredSelector({
    user: userSelectors.getUserInfo,
});

export { SideMenu as SideMenuUnwrapped };
export default connect(mapStateToProps, null)(SideMenu);
