import _get from 'lodash/get';
import { call, put } from 'redux-saga/effects';

import {
    createUserAPI,
    downloadCsvUserTemplateAPI,
    fetchDashboardWidgetsAPI,
    fetchUserByIdAPI,
    fetchUsersAPI,
    changeUserStatusAPI,
    updateUserByIdAPI,
    updateDashboardWidgetsAPI,
    importUsersAPI,
    fetchUserEmailHistoryPreviewAPI,
    exportUsersCSV,
} from 'modules/apis/user';
import { fetchCompanyConfigurationsAPI } from 'modules/apis/configuration';
import {
    fetchCompanyByIdAPI,
    fetchCompanyAPI,
    createCompanyAPI,
    updateCompanyAPI,
    changeCompanyStatusAPI,
} from 'modules/apis/company';

import * as adminActions from '../actions';

/**
 * Fetch the partner branding by the `partnerId`.
 *
 * @method fetchUserSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchUserSaga(action) {
    try {
        const { payload: userId } = action;

        const resp = yield call(fetchUserByIdAPI, userId);

        const user = _get(resp, 'data.user', {});

        yield put(adminActions.fetchUser.success(user));

        return user;
    } catch (error) {
        yield put(adminActions.fetchUser.error(error));

        return error;
    }
}

export function* changeUserStatusSaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: userInfo } = action;
        // Make the call to update the user with the given `userId`.
        const resp = yield call(changeUserStatusAPI, userInfo);
        // Extract the updated user from the valid response.
        const user = _get(resp, 'data.user', {});

        yield put(adminActions.changeUserStatus.success(user));

        return user;
    } catch (error) {
        yield put(adminActions.changeUserStatus.error(error));

        return error;
    }
}

/**
 * Update info for a given user via a `userId`.
 *
 * @method updateUserSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* updateUserSaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: userInfo } = action;
        // Make the call to update the user with the given `userId`.
        const resp = yield call(updateUserByIdAPI, userInfo);
        // Extract the updated user from the valid response.
        const user = _get(resp, 'data.user', {});

        yield put(adminActions.updateUser.success(user));

        return user;
    } catch (error) {
        yield put(adminActions.updateUser.error(error));

        return error;
    }
}

/**
 * Create user
 *
 * @method createUserSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* createUserSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(createUserAPI, payload);
        const user = _get(resp, 'data.user', {});
        yield put(adminActions.createUser.success(user));
        return user;
    } catch (error) {
        yield put(adminActions.createUser.error(error));
        return error;
    }
}

/**
 * Fetch all configurations from a partner
 *
 * @method fetchCompanyConfigurationsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} configurations
 */
export function* fetchCompanyConfigurationsSaga(action) {
    try {
        const { payload: configurationId } = action;

        const resp = yield call(fetchCompanyConfigurationsAPI, configurationId);

        const configurations = _get(resp, 'data.configurations', {});

        yield put(adminActions.fetchCompanyConfigurations.success(configurations));

        return configurations;
    } catch (error) {
        yield put(adminActions.fetchCompanyConfigurations.error(error));
        return error;
    }
}

/**
 * Fetch a partner
 *
 * @method fetchPartnerSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* fetchPartnerSaga(action) {
    try {
        const { payload: partnerId } = action;

        // API calls for getting a partner `fetchCompanyByIdAPI` should be renamed as to not
        // confuse that you'll be using it to get a company.
        const resp = yield call(fetchCompanyByIdAPI, partnerId);

        const partner = _get(resp, 'data.company', {});

        yield put(adminActions.fetchPartner.success(partner));
    } catch (error) {
        yield put(adminActions.fetchPartner.error(error));
    }
}

/**
 * Fetch a company.
 *
 * @method fetchCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} company
 */
export function* fetchCompanySaga(action) {
    try {
        const { payload: companyId } = action;

        const resp = yield call(fetchCompanyAPI, companyId);

        const company = _get(resp, 'data.company', {});

        yield put(adminActions.fetchCompany.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.fetchCompany.error(error));
        return error;
    }
}

/**
 * Create company for any partner.
 *
 * @method createCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} newCompany
 */
export function* createCompanySaga(action) {
    try {
        const { payload: partnerId } = action;

        const resp = yield call(createCompanyAPI, partnerId);

        const newCompany = _get(resp, 'data', {});

        yield put(adminActions.createCompany.success(newCompany));

        return newCompany;
    } catch (error) {
        yield put(adminActions.createCompany.error(error));

        return error;
    }
}

/**
 * Update company based on an `companyId`
 *
 * @method updateCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} company
 */
export function* updateCompanySaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: companyInfo } = action;
        // Make the call to update the user with the given `userId`.
        const resp = yield call(updateCompanyAPI, companyInfo);
        // Extract the updated user from the valid response.
        const company = _get(resp, 'data.company', {});

        yield put(adminActions.updateCompany.success(company));

        return company;
    } catch (error) {
        yield put(adminActions.updateCompany.error(error));
        return error;
    }
}

/**
 * Disable/Enable company saga
 *
 * @method changeCompanyStatusSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} company
 */
export function* changeCompanyStatusSaga(action) {
    try {
        const { payload: companyInfo } = action;
        const resp = yield call(changeCompanyStatusAPI, companyInfo);
        const company = _get(resp, 'data', {});
        yield put(adminActions.changeCompanyStatus.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.changeCompanyStatus.error(error));
        return error;
    }
}

/**
 * Import Users saga
 *
 * @method importUsersSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* importUsersSaga(action) {
    try {
        const { payload: usersInfo } = action;
        const response = yield call(importUsersAPI, usersInfo);
        yield put(adminActions.importUsers.success(response));
        return response;
    } catch (error) {
        yield put(adminActions.importUsers.error(error));
        return error;
    }
}

/**
 * Fetch users from current user
 *
 * @method fetchUsersSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* fetchUsersSaga(action) {
    try {
        const { payload: params } = action;
        const resp = yield call(fetchUsersAPI, params);
        const clients = _get(resp, 'data', {});
        yield put(adminActions.fetchUsers.success(clients));
        return clients;
    } catch (error) {
        yield put(adminActions.fetchUsers.error(error));
        return error;
    }
}

/**
 * Download csv template to import users
 *
 * @method downloadCsvUserTemplateSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* downloadCsvUserTemplateSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(downloadCsvUserTemplateAPI, partnerInfo);
        const csv = _get(resp, 'data', '');
        yield put(adminActions.downloadCsvUserTemplate.success(csv));
        return csv;
    } catch (error) {
        yield put(adminActions.downloadCsvUserTemplate.error(error));
        return error;
    }
}

/**
 * Fetch a preview of a user's email history
 *
 * @method fetchUserEmailHistoryPreviewSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchUserEmailHistoryPreviewSaga(action) {
    try {
        const { payload } = action;

        const resp = yield call(fetchUserEmailHistoryPreviewAPI, payload);

        const userEmailHistoryPreview = _get(resp, 'data', {});

        yield put(adminActions.fetchUserEmailHistoryPreview.success(userEmailHistoryPreview));

        return userEmailHistoryPreview;
    } catch (error) {
        yield put(adminActions.fetchUserEmailHistoryPreview.error(error));

        return error;
    }
}

export function* exportUsersCSVSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(exportUsersCSV, params);
        yield put(adminActions.exportUsersCSV.success(response));
        return response;
    } catch (error) {
        yield put(adminActions.exportUsersCSV.error(error));
        return error;
    }
}

/**
 * Saga to fetch dashboard widgets for a user
 *
 * @method fetchDashboardWidgetsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchDashboardWidgetsSaga(action) {
    try {
        const { payload: userId } = action;
        const response = yield call(fetchDashboardWidgetsAPI, userId);
        const widgets = _get(response, 'data.widgets', []);
        yield put(adminActions.fetchDashboardWidgets.success(widgets));
        return widgets;
    } catch (error) {
        yield put(adminActions.fetchDashboardWidgets.error(error));
        return error;
    }
}

/**
 * Saga to update dashboard widgets for a user
 *
 * @method updateDashboardWidgetsSaga
 * @param  {Object}               action The original redux-action
 */
export function* updateDashboardWidgetsSaga(action) {
    try {
        const {
            payload: { userId, widgets },
        } = action;
        const response = yield call(updateDashboardWidgetsAPI, userId, widgets);
        const updatedWidgets = _get(response, 'data.widgets');
        yield put(adminActions.updateDashboardWidgets.success(updatedWidgets));
    } catch (error) {
        yield put(adminActions.updateDashboardWidgets.error(error));
    }
}
