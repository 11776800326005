import _get from 'lodash/get';

import INITIAL_STATE from './initialState';

/**
 * Reducer helper for the token user action (success).
 *
 * @method tokenSuccess
 * @param {Object} state The current state
 * @param {Object} action The fired action
 * @return {Object} The updated state
 */
export const tokenSuccess = (state, action) => {
    return {
        ...state,
        token: {
            ...state.token,
            ..._get(action, 'payload', {}),
        },
    };
};

/**
 * Reducer helper for the login user action (success).
 *
 * @method loginSuccess
 * @param {Object} state The current state
 * @param {Object} action The fired action
 * @return {Object} The updated state
 */
export const loginSuccess = (state, action) => {
    return {
        ...state,
        isLoggedIn: true,
        isExpired: false,
    };
};

/**
 * Reducer helper for catching the auth logout action.
 *
 * @method logoutSuccess
 * @param {Object} state The current state
 * @param {Object} action The fired action
 * @return {Object} The updated state
 */
export const logoutSuccess = () => INITIAL_STATE;

/**
 * Reducer helper for catching the auth internally logout action.
 *
 * @method internallyLogoutSuccess
 * @param {Object} state The current state
 * @param {Object} action The fired action
 * @return {Object} The updated state
 */
export const internallyLogoutSuccess = state => ({
    ...state,
    isImpersonatingUser: false,
    impersonateUserShowEnd: false,
    impersonateUserShowStart: false,
    impersonatorToken: {},
    isExpired: true,
    token: {},
});

/**
 * Success reducer helper to enable display of the "impersonation is starting" banner
 *
 * @method setImpersonateUserShowStartSuccess
 * @param {Object} state The current state
 * @return {Object} The updated state
 */
export const setImpersonateUserShowStartSuccess = state => {
    return {
        ...state,
        impersonateUserShowEnd: false,
        impersonateUserShowStart: true,
    };
};

/**
 * Success reducer helper for fetchImpersonateUserTokenSuccess
 *
 * @method fetchImpersonateUserTokenSuccess
 * @param {Object} state The current state
 * @param {Object} action The fired action
 * @return {Object} The updated state
 */
export const fetchImpersonateUserTokenSuccess = (state, action) => {
    return {
        ...state,
        impersonateUserShowStart: action.payload.impersonateUserShowStart,
        impersonateUserShowEnd: action.payload.impersonateUserShowEnd,
        isImpersonatingUser: action.payload.isImpersonatingUser,
        token: {
            ...action.payload.impersonatedUserToken,
        },
        impersonatorToken: {
            ...state.token,
        },
    };
};

/**
 * Success reducer helper for fetchImpersonateUserContentSuccess
 *
 * @method fetchImpersonateUserContentSuccess
 * @param {Object} state The current state
 * @param {Object} action The fired action
 * @return {Object} The updated state
 */
export const fetchImpersonateUserContentSuccess = (state, action) => {
    return {
        ...state,
        impersonateUserShowStart: action.payload.impersonateUserShowStart,
        impersonateUserShowEnd: action.payload.impersonateUserShowEnd,
        isImpersonatingUser: action.payload.isImpersonatingUser,
    };
};

/**
 * Error reducer helper for impersonateUser; it clears the impersonation flags.
 *
 * @method impersonateUserError
 * @param {Object} state The current state
 * @return {Object} The updated state
 */
export const impersonateUserError = state => {
    return {
        ...state,
        impersonateUserShowStart: false,
        impersonateUserShowEnd: false,
        isImpersonatingUser: false,
    };
};

/**
 * Success reducer helper to enable display of the "impersonation is ending" banner
 *
 * @method setImpersonateUserShowEndSuccess
 * @param {Object} state The current state
 * @return {Object} The updated state
 */
export const setImpersonateUserShowEndSuccess = state => {
    return {
        ...state,
        impersonateUserShowEnd: true,
        impersonateUserShowStart: false,
        token: {
            ...state.impersonatorToken,
        },
        impersonatorToken: {},
    };
};

/**
 * Success reducer helper for endImpersonateUser
 *
 * @method endImpersonateUserSuccess
 * @param {Object} state The current state
 * @param {Object} action The fired action
 * @return {Object} The updated state
 */
export const endImpersonateUserSuccess = (state, action) => {
    return {
        ...state,
        impersonateUserShowStart: false,
        impersonateUserShowEnd: false,
        isImpersonatingUser: false,
        token: {
            ...action.payload.userToken,
        },
        impersonatorToken: {},
    };
};

/**
 * Success reducer helper for setIsExpired
 *
 * @method setIsExpiredSuccess
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object} The
 */
export const setIsExpiredSuccess = (state, { payload }) => {
    return {
        ...state,
        isExpired: payload,
    };
};

/**
 * Success reducer helper for setLoginAttemptStatus
 *
 * @method setLoginAttemptStatusSuccess
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object} The
 */
export const setLoginAttemptStatusSuccess = (state, { payload }) => {
    return {
        ...state,
        loginAttemptStatus: payload,
    };
};
