import React from 'react';
import { string } from 'prop-types';

import { COLORS } from 'ravenjs/constants/colors';
import SvgIcon from '../SvgIcon';

const ShowPasswordIcon = ({ fill, height, width }) => {
    return (
        <SvgIcon width={height} height={width} fill="none">
            <path
                d="M21.828 11.64a.9.9 0 0 1 0 .72C19.82 17.025 16.05 19.9 12 19.9c-4.05 0-7.82-2.875-9.828-7.54a.9.9 0 0 1 0-.72C4.18 6.975 7.95 4.1 12 4.1c4.05 0 7.82 2.875 9.828 7.54zm-17.819.318L3.99 12l.02.042C5.75 15.775 8.78 18.1 12 18.1s6.25-2.325 7.99-6.058l.02-.042-.02-.042C18.25 8.225 15.222 5.9 12 5.9s-6.25 2.325-7.99 6.058zM22.004 12c0-.138-.029-.274-.084-.4l-19.84.8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6c.055-.126.084-.262.084-.4zM9.834 8.757a3.9 3.9 0 1 1 4.333 6.486 3.9 3.9 0 0 1-4.334-6.486zm1 4.99a2.1 2.1 0 1 0 2.333-3.493 2.1 2.1 0 0 0-2.334 3.492z"
                fill={fill}
                stroke="#F9FAFA"
                strokeWidth=".2"
            />
        </SvgIcon>
    );
};

ShowPasswordIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
};

ShowPasswordIcon.defaultProps = {
    fill: COLORS.BLACK_3,
    height: '24',
    width: '24',
};

export default ShowPasswordIcon;
