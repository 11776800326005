const INTELLIGENCE = {
    DESCRIPTION_TODO_LIST: {
        event: 'MNL Intelligence',
        eventProperties: {
            description: 'User clicks to-do list link in description',
            selectionType: 'viewEntity',
        },
    },
    ACTION_TODO_LIST: {
        event: 'MNL Intelligence',
        eventProperties: {
            description: 'User clicks to-do list link in action items',
            selectionType: 'viewEntity',
        },
    },
    ADDITIONAL_CONTENT_VIEW_DETAIL: {
        event: 'MNL Intelligence',
        eventProperties: {
            description: 'User clicks on prescription page additional content',
            selectionType: 'viewDetailEntity',
        },
    },
    REDIRECT_TO_INSIGHTS_FROM_EMAIL: {
        event: 'Insights Email to platform',
        eventProperties: {
            description: 'User selects view insights link on mineral intelligence email',
            selectionType: 'viewEntity',
            source: 'email',
        },
    },
    REDIRECT_TO_INSIGHTS_FROM_NOTIFICATION: {
        event: 'Insights Views from Notification',
        eventProperties: {
            description:
                'User selects view insights link on mineral intelligence notification list',
            selectionType: 'viewEntity',
            source: 'notification',
        },
    },
    REDIRECT_TO_COMPANY_EDIT_FROM_EMAIL: {
        event: 'MNL Intelligence',
        eventProperties: {
            description: 'User selects to go to company profile from mineral intelligence email',
            selectionType: 'viewEntity',
            source: 'email',
        },
    },
    REDIRECT_TO_PROFILE_DETAILS_FROM_EMAIL: {
        event: 'MNL Intelligence',
        eventProperties: {
            description:
                'User selects to go to their profile from mineral intelligence notifications email',
            selectionType: 'viewEntity',
            source: 'email',
        },
    },
    REDIRECT_TO_UNSUBSCRIBE_FROM_EMAIL: {
        event: 'Intelligence Unsubscription',
        eventProperties: {
            description:
                'User selects to go to unsubscribe from mineral intelligence notifications email',
            selectionType: 'viewEntity',
            source: 'email',
        },
    },
    INSIGHT_ACTION_ITEMS: {
        event: 'Mineral Insight Action Items',
        eventProperties: {},
    },
    INSIGHT_LIST_FILTER: {
        event: 'Details Mineral Insights List filter Options',
        eventProperties: {},
    },
    INSIGHT_LIST_VIEW_CLICK: {
        event: 'Details Mineral Insight List View Click',
        eventProperties: {},
    },
    OPEN_RATE_NEW_INSIGHTS: {
        event: 'Open rate of new recommendations',
        eventProperties: {},
    },
    REDIRECT_TO_DASHBOARD: {
        event: 'Mineral Intelligence dashboard landing',
        eventProperties: {
            description: 'User clicked on Mineral Intelligence Dashboard',
        },
    },
    REDIRECT_TO_INSIGHTS: {
        event: 'Mineral Intelligence Insights list',
        eventProperties: {
            description: 'User clicked on Mineral Intelligence Insights list',
        },
    },
    DASHBOARD_DROPDOWN_SELECTION: {
        event: 'Visual component: Filter Dropdown',
        eventProperties: {
            description: 'User clicked on Mineral Intelligence filter dropdown',
        },
    },
    DASHBOARD_GRAPH_REGULATION_SELECTION: {
        event: 'Visual component: Interaction',
        eventProperties: {},
    },
    DASHBOARD_GRAPH_MANAGE_PROFILE_CLICk: {
        event: 'Visual component: Manage Profile click',
        eventProperties: {
            description: 'User clicked on Manage Profile in About the data section',
        },
    },
    DASHBOARD_PREVIEW_CLICk: {
        event: 'Preview component: Interaction',
        eventProperties: {},
    },
    MI_CORE_LANDING_PAGE: {
        event: 'Mineral Intelligence Core Landing Page',
        eventProperties: {},
    },
    MI_CORE_BANNER_CLICK: {
        event: 'MI Core: Clicks on Upgrade banners',
        eventProperties: {},
    },
    MI_CORE_SEARCH_BAR_METRICS: {
        event: 'MI core: SearchBar metrics',
        eventProperties: {},
    },
};

export default INTELLIGENCE;
