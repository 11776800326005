import { bool, object } from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { getThemeProps } from 'ravenjs/utils/theme';

const AppContent = styled.div`
    flex: 1 1 auto;
    position: relative;
    width: 100%;
    height: 100%;

    ${({ authorized }) => {
        return (
            authorized && {
                paddingTop: 0,
            }
        );
    }};

    ${getThemeProps('AppContent.styles')};
`;

AppContent.propTypes = {
    authorized: bool,
    history: object,
    isImpersonatingUser: bool,
};

AppContent.defaultProps = {
    authorized: false,
    history: {},
    isImpersonatingUser: false,
};

export { AppContent as AppContentUnwrapped };
export default withRouter(AppContent);
