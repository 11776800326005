import _get from 'lodash/get';

import INITIAL_STATE from './initialState';

export const setFeatureFlagSuccess = (state, action) => ({
    ...state,
    ..._get(action, 'payload'),
});

/**
 * Reducer helper for catching the auth logout action.
 *
 * @method logoutSuccess
 * @return {Object}      The updated state
 */
export const logoutSuccess = () => INITIAL_STATE;
