import { createActions } from 'utils/actions';

/**
 * Redux actions for create questionnaire.
 *
 * @type {Object}
 */
export const fetchSecretsByGroupId = createActions('webhook', 'FETCH_SECRETS_BY_GROUP_ID', {
    asPromise: true,
});

export const createSecretByGroupId = createActions('webhook', 'CREATE_SECRET_BY_GROUP_ID', {
    asPromise: true,
});
