const MEETINGS = {
    CLICK_JOIN_MY_MEETING: {
        event: 'myMeetingsListJoinMeeting',
        eventProperties: {
            description: 'Joined meeting from My Meetings list',
        },
    },
};

export default MEETINGS;
