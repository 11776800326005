import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const InsightCheckIcon = ({ fill, height, viewBox, width }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.6095 15.0572C36.1302 15.5779 36.1302 16.4221 35.6095 16.9428L20.9428 31.6095C20.4221 32.1302 19.5779 32.1302 19.0572 31.6095L12.3905 24.9428C11.8698 24.4221 11.8698 23.5779 12.3905 23.0572C12.9112 22.5365 13.7554 22.5365 14.2761 23.0572L20 28.7811L33.7239 15.0572C34.2446 14.5365 35.0888 14.5365 35.6095 15.0572Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

InsightCheckIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
};

InsightCheckIcon.defaultProps = {
    height: '48',
    viewBox: '0 0 48 48',
    width: '48',
    fill: '#282828',
};

export default InsightCheckIcon;
