import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AlertIcon = ({ fill, height, width, viewBox, ...rest }) => {
    return (
        <SvgIcon
            cursor="pointer"
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.05 3.81C8.363 2.65 10.143 2 12 2c1.857 0 3.637.65 4.95 1.81C18.263 4.967 19 6.54 19 8.177c0 2.958.717 4.796 1.378 5.865.332.537.655.889.88 1.098a2.917 2.917 0 0 0 .306.25l.002.002c.359.217.518.615.391.983-.128.372-.516.627-.957.627H3c-.44 0-.83-.255-.957-.627-.126-.368.032-.766.39-.983l.004-.002.052-.037c.053-.039.141-.108.254-.213.224-.21.547-.561.88-1.098C4.282 12.973 5 11.136 5 8.178c0-1.639.738-3.21 2.05-4.369zm-1.9 11.428h13.7a8.049 8.049 0 0 1-.228-.35C17.782 13.53 17 11.396 17 8.178c0-1.17-.527-2.293-1.464-3.12-.938-.828-2.21-1.293-3.536-1.293s-2.598.465-3.536 1.292C7.527 5.885 7 7.007 7 8.177c0 3.22-.783 5.353-1.622 6.711-.076.123-.153.24-.229.35zM9.768 19.138a1 1 0 0 1 1.367.363 1 1 0 0 0 1.73 0 1 1 0 1 1 1.73 1.004 3 3 0 0 1-5.19 0 1 1 0 0 1 .363-1.367z"
                fill={fill}
            />
        </SvgIcon>
    );
};

AlertIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

AlertIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK_00,
    height: '24',
    width: '45',
    viewBox: '0 0 20 23',
};

export default AlertIcon;
