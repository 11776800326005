import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';

import SkusTooltip from 'ravenjs/components/tooltips/SkusTooltip';
import Divider from 'ravenjs/lib/Divider';
import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { callFunc, get, getHandler, isEmpty } from 'ravenjs/utils';
import { CheckboxEmptyIconNew, CheckboxFilledIconNew } from 'ravenjs/lib/SvgIcon';
import { selectors as uiSelectors } from 'modules/ui';
import { generateLicenseName } from 'utils/skus';

import MESSAGES from 'constants/messages';
import Checkbox from '../../CheckboxNew';

const CompanyConfigInput = styled(Checkbox)``;
const SkuTooltipContainer = styled.div``;

/**
 * A checkbox form field for selecting a new configuration for a company / client.
 *
 * @method      CompanyConfigField
 * @param       {Object}           props Component props
 * @constructor
 */
function CompanyConfigField(props) {
    // Extract a few props.
    const {
        disabled,
        formContext,
        idSchema,
        onChange,
        readonly,
        schema,
        uiSchema,
        isLicensingEnabled,
    } = props;
    const { handlers = {} } = formContext;
    const defaultVal = get(schema, 'default');
    // `use`Hooks
    const [checkedState, setCheckedState] = React.useState(defaultVal);
    // Build some internally used props.
    const id = get(idSchema, '$id', 'CompanyConfigField');
    const onPickRef = get(uiSchema, 'onPick');
    const configInfo = get(uiSchema, 'configInfo', {});
    const addonConfigInfo = get(uiSchema, 'addonConfigInfo');
    const onPickHandler = getHandler(handlers, onPickRef);
    const configName = get(configInfo, 'configurationName', 'Configuration Name');
    const addonConfigName = get(addonConfigInfo, 'configurationName', 'Add-On Configuration Name');
    const configType = get(configInfo, 'configurationType');
    const configTypeLabel =
        configType === 'master' ? '(Master)' : configType === 'alternate' ? '(Alternate)' : null;
    const configLicense = get(configInfo, 'licenses', []);
    const formattedLicenseInfo = generateLicenseName(configLicense);
    const addonConfigLicense = get(addonConfigInfo, 'licenses', []);
    const formattedAddonLicenseInfo = generateLicenseName(addonConfigLicense);
    // Handle the `onChange` event of the checkbox input.
    async function handleOnChange(e) {
        const newVal = e.target.checked;
        // Update the local state.
        setCheckedState(newVal);
        // Call the form's onChange func.
        callFunc(onChange, newVal);
        // Call the passed in `onPick` func.
        await callFunc(onPickHandler, newVal, e);
        // Reset the local state back to checked.
        setCheckedState(!newVal);
    }
    // Render info about the add-on config.
    function renderAddonConfigInfo() {
        // If no add-on config info is present, no need to render anything.
        if (isEmpty(addonConfigInfo)) {
            return null;
        }
        // Otherwise, render the sku tooltip info about the add-on config.
        return (
            <>
                <Divider transparent />
                <Row alignItems="center" justify="flex-start" gutter={false}>
                    <CompanyConfigInput
                        checked
                        isDisabled
                        onChange={() => {}}
                        type="checkbox"
                        icon={CheckboxEmptyIconNew}
                        checkedIcon={CheckboxFilledIconNew}
                        id={id}
                    />
                    <SkuTooltipContainer>
                        <SkusTooltip configuration={addonConfigInfo} placement="top-start">
                            <Typography as="span" gutterBottom="0" gutterLeft="10px">
                                {addonConfigName} (Add-On)
                            </Typography>
                        </SkusTooltip>
                        {isLicensingEnabled && (
                            <Typography as="span" gutterBottom="0" gutterLeft="10px">
                                {MESSAGES.LICENSING.CONFIGURATION.LICENSE}:{' '}
                                {formattedAddonLicenseInfo}
                            </Typography>
                        )}
                    </SkuTooltipContainer>
                </Row>
            </>
        );
    }
    // Build the Company Config field.
    return (
        <Row gutter={false} direction="column">
            <Row alignItems="center" justify="flex-start" gutter={false}>
                <CompanyConfigInput
                    icon={CheckboxEmptyIconNew}
                    checkedIcon={CheckboxFilledIconNew}
                    checked={checkedState}
                    isDisabled={disabled || readonly}
                    id={id}
                    onChange={handleOnChange}
                    type="checkbox"
                />
                <SkuTooltipContainer>
                    <SkusTooltip configuration={configInfo} placement="top-start">
                        <Typography as="span" gutterBottom="0" gutterLeft="10px">
                            {configName} {configTypeLabel}
                        </Typography>
                    </SkusTooltip>
                    {isLicensingEnabled && (
                        <Typography as="span" gutterBottom="0" gutterLeft="10px">
                            {MESSAGES.LICENSING.CONFIGURATION.LICENSE}: {formattedLicenseInfo}
                        </Typography>
                    )}
                </SkuTooltipContainer>
            </Row>
            {renderAddonConfigInfo()}
        </Row>
    );
}

CompanyConfigField.propTypes = {
    disabled: PropTypes.bool,
    formContext: PropTypes.object.isRequired,
    idSchema: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object.isRequired,
    isLicensingEnabled: PropTypes.bool,
};

CompanyConfigField.defaultProps = {
    disabled: null,
    readonly: null,
    isLicensingEnabled: false,
};

const mapStateToProps = createStructuredSelector({
    isLicensingEnabled: uiSelectors.isLicensingEnabled,
});

export default connect(mapStateToProps, null)(CompanyConfigField);
