import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';

import { getThemeProps } from 'ravenjs/utils/theme';
import { DEFAULT_COLORS } from 'constants/colors';

const TitleFieldStyled = styled.label`
    ${({ showRequired, color }) => `
         color : ${showRequired ? DEFAULT_COLORS.RED : color};
    `};
    ${({ fontSize, fontWeight, fontFamily, margin, padding }) => `
        font-weight : ${fontWeight || 'bold'};
        font-size : ${fontSize || '16px'};
        font-family : ${fontFamily || 'Favorit'};
        margin : ${margin || '0 0 10px 15px'};
        padding : ${padding || '0'};
    `};
    /**
     * Add all of the remaining styles from the theme
     */
    ${getThemeProps('TitleField.styles')};
    /**
     * Add in the dynamic styles
     */
    ${({ root, theme }) => {
        const rootStyles = getThemeProps('TitleField.styles.root', null, { theme });

        return root ? rootStyles : null;
    }};
`;

function TitleField({ id, title, color, uiOptions, ...rest }) {
    const styles = _get(uiOptions, 'titleStyles', {});
    return (
        title && (
            <TitleFieldStyled {...rest} htmlFor={id} color={color} {...styles}>
                {title}
            </TitleFieldStyled>
        )
    );
}

TitleField.propTypes = {
    id: PropTypes.string,
    color: PropTypes.string,
    uiOptions: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TitleField.defaultProps = {
    id: null,
    title: null,
    uiOptions: {},
    color: DEFAULT_COLORS.GREY_19,
};

export default TitleField;
