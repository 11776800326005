import PropTypes from 'prop-types';
import React from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import JsonSchemaForm from 'react-jsonschema-form';
import styled from 'styled-components';

import Modal from 'ravenjs/lib/Modal';
import { ClearIcon } from 'ravenjs/lib/SvgIcon';
import { addSchemaValue, callFunc, getThemeProps } from 'ravenjs/utils';

import Typography from '../Typography';
import customFields from '../Form/fields';
import BaseInput from '../Form/widgets/BaseInputPicklist';
import CheckboxWidget from '../Form/widgets/CheckboxWidget';
import Row from '../Row';
import Col from '../Col';
import Button from '../Button';
import schema from './insertLinkSchema.json';
import { ModalHeaderStyled, ModalBody } from './editorStyles';
import { ACTIONS } from '../../constants';

const FormStyled = styled.div`
    position: relative;
    .RavenSelect__clear-indicator,
    .RavenSelect__dropdown-indicator {
        cursor: pointer;
    }
    ${getThemeProps('Form.styles')};
`;
class InsertLinkModal extends React.Component {
    static propTypes = {
        modalStyle: PropTypes.func,
        handleModalClose: PropTypes.func,
        onSubmit: PropTypes.func,
        open: PropTypes.bool,
        formData: PropTypes.object,
        hideCheckbox: PropTypes.bool,
    };

    static defaultProps = {
        modalStyle: null,
        handleModalClose: null,
        onSubmit: null,
        open: false,
        formData: {},
        hideCheckbox: false,
    };

    constructor(props) {
        super(props);
        const linkSchema = _cloneDeep(schema);

        if (props.hideCheckbox) {
            addSchemaValue(linkSchema, 'schema.properties.openNewTab', {});
        }

        this.state = {
            linkSchema,
        };
        this.formRef = React.createRef();
    }

    modalClose = () => {
        const { handleModalClose } = this.props;
        callFunc(handleModalClose);
    };

    onFormSubmit = ({ formData }) => {
        const { onSubmit } = this.props;
        if (!formData.url?.match(/^(http|https)/g) && formData.url.indexOf('mailto:') === -1) {
            formData.url = `https://${formData.url}`;
        }
        callFunc(onSubmit, formData, this.props);
    };

    render() {
        const { modalStyle, open, formData } = this.props;
        const { linkSchema } = this.state;

        return (
            <Modal open={open} onClose={this.modalClose} style={modalStyle}>
                <ModalHeaderStyled>
                    <Typography type="headline">Insert Link</Typography>
                    <ClearIcon
                        onClick={this.modalClose}
                        style={{ color: 'inherit', width: 20, height: 20 }}
                    />
                </ModalHeaderStyled>
                <ModalBody>
                    <FormStyled>
                        <JsonSchemaForm
                            FieldTemplate={customFields.FieldTemplate}
                            ObjectFieldTemplate={customFields.ObjectFieldTemplate}
                            widgets={{ BaseInput, CheckboxWidget }}
                            fields={{ ...customFields }}
                            ref={this.formRef}
                            liveValidate
                            showErrorList={false}
                            {...linkSchema}
                            formData={formData}
                            onSubmit={this.onFormSubmit}
                        >
                            <Row>
                                <Col>
                                    <Button color="secondary" onClick={this.modalClose}>
                                        {ACTIONS.CANCEL}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button color="primary" type="submit">
                                        {ACTIONS.SUBMIT}
                                    </Button>
                                </Col>
                            </Row>
                        </JsonSchemaForm>
                    </FormStyled>
                </ModalBody>
            </Modal>
        );
    }
}

export default InsertLinkModal;
