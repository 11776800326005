import { takeLatest } from 'redux-saga/effects';

import * as toDoList from '../actions';

import { fetchToDoListSaga, updateToDoListSaga, createToDoListSaga } from './toDoList';

/**
 * Root saga for Contentful actions
 *
 * @method contentfulSaga
 * @type   {Generator}
 */
function* toDoListSaga() {
    yield takeLatest(toDoList.fetchToDoList.TRIGGER, fetchToDoListSaga);
    yield takeLatest(toDoList.updateToDoList.TRIGGER, updateToDoListSaga);
    yield takeLatest(toDoList.createToDoList.TRIGGER, createToDoListSaga);
}

export default toDoListSaga;

export { fetchToDoListSaga, updateToDoListSaga };
