import React from 'react';
import Sticky from 'react-stickynode';

const StickyElement = React.forwardRef((props, ref) => {
    const { ...rest } = props;

    return <Sticky {...rest} />;
});

StickyElement.displayName = 'StickyElement';

export default StickyElement;
