import axios from 'axios';

/**
 * API to fetching jobs functional area list.
 *
 * @method fetchJobsListAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchJobsListAPI = params =>
    axios({
        method: 'get',
        url: `/v2/contentful/job-descriptions/all`,
        params,
    });

/**
 * API to fetching job details
 *
 * @method fetchJobDetailsAPI
 * @param  {Object}  jobId
 * @return {Promise}
 */
export const fetchJobDetailsAPI = jobId => {
    return axios({
        method: 'get',
        url: `/v2/contentful/job-descriptions/${jobId}`,
    });
};

/**
 * API to fetching jobs functional areas list
 *
 * @method fetchFunctionalAreasListAPI
 * @param  {Object}  eventInfo
 * @return {Promise}
 */
export const fetchFunctionalAreasListAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/contentful/functional-areas`,
        params: {
            limit: 100,
        },
    });
};

/**
 * API to fetching jobs recommended content list
 *
 * @method fetchJobsRecommendedContentListAPI
 * @param  {Object}  eventInfo
 * @return {Promise}
 */
export const fetchJobsRecommendedContentListAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/contentful/landing`,
        params: {
            limit: 100,
        },
    });
};

/**
 * API to fetch jobs descriptions by functional area
 *
 * @param   {Object}    params
 * @return  {Promise}
 */
export const fetchJobsByFunctionalAreaAPI = params =>
    axios({
        method: 'get',
        params,
        url: '/v2/contentful/job-descriptions',
    });
