import { get, isEmpty } from 'ravenjs/utils/lodash';
import _uniqBy from 'lodash/uniqBy';
import { renderCheck } from 'ravenjs/utils/viewport';
import Acl from 'modules/acl';
import {
    SafetyCoursesIcon,
    SafetyContentLibraryIcon,
    SafetyManualTemplateIcon,
    HandbookBuilderIcon,
    SafetyAdvisorIcon,
    OshaLogIcon,
    ToolboxTalksIcon,
} from 'components/Icons';

import { EVENTS } from 'constants/events';
import MESSAGES from 'constants/messages';
import { MODALS } from 'constants/modals';
import { APP_PERMISSIONS } from 'constants/permissions';
import { OSHA, SAFETY_COURSES, SAFETY_EXPERTS } from 'constants/safety';

/**
 * Get safety icon.
 *
 * @method getSafetyIcon
 * @param  {string} key
 * @return {string}
 */
export const getSafetyIcon = key => {
    let icon = '';

    switch (key) {
        case 'SafetyCoursesIcon':
            icon = SafetyCoursesIcon;
            break;
        case 'SafetyContentLibraryIcon':
            icon = SafetyContentLibraryIcon;
            break;
        case 'SafetyManualTemplateIcon':
            icon = SafetyManualTemplateIcon;
            break;
        case 'HandbookBuilderIcon':
            icon = HandbookBuilderIcon;
            break;
        case 'SafetyAdvisorIcon':
            icon = SafetyAdvisorIcon;
            break;
        case 'OshaLogIcon':
            icon = OshaLogIcon;
            break;
        case 'ToolboxTalksIcon':
            icon = ToolboxTalksIcon;
            break;
        default:
            break;
    }

    return icon;
};

/**
 * Get Url Template.
 *
 * @method getUrlTemplate
 * @param  {Object} props
 */
export const getUrlTemplate = async props => {
    const { fetchSafetyManualTemplate, isLoading, safetyManualTemplateUrl, pendoData } = props;
    sendPendoEventDownloadSafetyManualTemplate(pendoData);
    if (isEmpty(safetyManualTemplateUrl)) {
        isLoading(true, MESSAGES.LOADING.DEFAULT);
        try {
            const safetyManualTemplate = await fetchSafetyManualTemplate();
            const currentSafetyManualTemplateUrl = get(
                safetyManualTemplate,
                'EhsSafetyManualTemplate.url',
                ''
            );
            window.open(currentSafetyManualTemplateUrl, '_blank', '');
        } finally {
            isLoading(false);
        }
    } else {
        window.open(safetyManualTemplateUrl, '_blank', '');
    }
};

/**
 * Download Safety Manual Template.
 *
 * @method downloadSafetyManualTemplate
 * @param  {Object} props
 * @return {string}
 */
export const downloadSafetyManualTemplate = props => {
    const { closeModal, openModal, sendEvent = null, product = {} } = props;
    const title = get(product, 'title', null);

    return () => {
        if (!isEmpty(title)) {
            sendEvent(title);
        }

        openModal(MODALS.COMMON, {
            title: MESSAGES.MODAL.EHS.SAFETY_MANUAL_TEMPLATE.TITLE,
            description: MESSAGES.MODAL.EHS.SAFETY_MANUAL_TEMPLATE.CONTENT,
            paddingContainer: '0 1rem 1rem 1rem',
            hasCloseIcon: true,
            hideIcon: true,
            isComponent: false,
            note: '',
            actions: [
                {
                    text: MESSAGES.MODAL.CONFIRM.ACTIONS.NO,
                    color: 'secondary',
                    className: 'cancelButtonModal',
                    width: renderCheck('md', 'less') ? '100%' : 'auto',
                    onClick: () => {
                        closeModal(MODALS.COMMON);
                    },
                },
                {
                    text: MESSAGES.MODAL.CONFIRM.ACTIONS.YES,
                    color: 'primary',
                    className: 'ActionButtonModal',
                    width: renderCheck('md', 'less') ? '100%' : 'auto',
                    onClick: () => {
                        getUrlTemplate(props);
                        closeModal(MODALS.COMMON);
                    },
                },
            ],
        });
    };
};

/**
 * Validate if have access to Safety.
 *
 * @method hasAccessToSafety
 * @return {boolean}
 */
export const hasAccessToSafety = () => {
    return !(
        !Acl.check(APP_PERMISSIONS.allEhs) &&
        !Acl.check(APP_PERMISSIONS.packageWorkplaceSafety) &&
        !Acl.check(APP_PERMISSIONS.osha)
    );
};

/**
 * Validate if have ehs packages.
 *
 * @method haveEHSPackages
 * @return {boolean}
 */
export const haveEHSPackages = () => {
    return (
        Acl.check(APP_PERMISSIONS.ehsclientbasic) ||
        Acl.check(APP_PERMISSIONS.ehsclientpremium) ||
        Acl.check(APP_PERMISSIONS.ehspartner)
    );
};

/**
 * Get if menu item safety is disabled.
 *
 * @method isDisableSafetyItem
 * @param  {string} key
 * @return {boolean}
 */
export const isDisableSafetyItem = key => {
    let disabled = false;
    const isDemoEHS = !haveEHSPackages() && Acl.check(APP_PERMISSIONS.ehsdemo);

    switch (key) {
        case 'safetyadvisor':
            disabled = isDemoEHS;
            break;
        case 'oshareporting':
            disabled = !Acl.check(APP_PERMISSIONS.osha) && Acl.check(APP_PERMISSIONS.ehsdemo);
            break;
        case 'safetycourses':
            disabled = isDemoEHS && !Acl.check(APP_PERMISSIONS.packageWorkplaceSafety);
            break;
        case 'safetymanualtemplate':
            disabled = isDemoEHS;
            break;
        default:
            break;
    }

    return disabled;
};

/**
 * Get if have access to safety expert.
 *
 * @method haveAccessToSafetyExpert
 * @return {boolean}
 */
export const haveAccessToSafetyExpert = () => {
    return (
        (Acl.check(APP_PERMISSIONS.allEhs) && Acl.check(APP_PERMISSIONS.askTheExpert)) ||
        (Acl.check(APP_PERMISSIONS.ehsdemo) && !haveEHSPackages())
    );
};

/**
 * Get if have access to OSHA.
 *
 * @method haveAccessToOsha
 * @return {boolean}
 */
export const haveAccessToOsha = () => {
    return (
        (!haveEHSPackages() && Acl.check(APP_PERMISSIONS.ehsdemo)) ||
        Acl.check(APP_PERMISSIONS.osha)
    );
};

/**
 * Get if have access to safety courses.
 *
 * @method haveAccessToSafetyCourses
 * @return {boolean}
 */
export const haveAccessToSafetyCourses = () => {
    return (
        (!haveEHSPackages() && Acl.check(APP_PERMISSIONS.ehsdemo)) ||
        Acl.check(APP_PERMISSIONS.ehspartner) ||
        Acl.check(APP_PERMISSIONS.packageWorkplaceSafety)
    );
};

/**
 * Filter Safety Subnavigation.
 *
 * @method filterSafetySubnavigation
 * @param  {Object} item
 * @return {boolean}
 */
export const filterSafetySubnavigation = item => {
    const key = get(item, 'state', '');
    switch (key) {
        case SAFETY_EXPERTS:
            return haveAccessToSafetyExpert();
        case OSHA:
            return haveAccessToOsha();
        case SAFETY_COURSES:
            return haveAccessToSafetyCourses();
        default:
            break;
    }
    return true;
};

export const sendPendoEventToolClick = props => {
    const {
        currentUser,
        from,
        sendPendoEvent,
        toolName,
        userCompany,
        userPermissionsOriginal,
    } = props;
    let userSkus = _uniqBy(userPermissionsOriginal, 'skuKey');
    const ehsSku = userSkus
        .filter(item => item.skuKey.indexOf('ehs') !== -1)
        .map(function(item) {
            return item.displayLabel;
        });
    userSkus = userSkus.map(({ skuKey }) => {
        return skuKey;
    });
    const pendoEvent = EVENTS.SAFETY.TOOL_CLICK;

    pendoEvent.eventProperties.CompanyId = currentUser.companyId;
    pendoEvent.eventProperties.CompanyName = currentUser.companyName;
    pendoEvent.eventProperties.CompanyType = userCompany.companyType;
    pendoEvent.eventProperties.EhsSku = ehsSku.join(', ');
    pendoEvent.eventProperties.DisplayName = userCompany.displayName;
    pendoEvent.eventProperties.Industry = userCompany.industry;
    pendoEvent.eventProperties.Location = JSON.stringify(userCompany.location);
    pendoEvent.eventProperties.Source = from;
    pendoEvent.eventProperties.ToolName = toolName;
    pendoEvent.eventProperties.ToolNameSource = `${toolName} - ${from}`;
    pendoEvent.eventProperties.Username = currentUser.userName;
    pendoEvent.eventProperties.UserRole = currentUser.role;
    pendoEvent.eventProperties.UserSkus = JSON.stringify(userSkus);
    sendPendoEvent(pendoEvent);
    return true;
};

export const sendPendoEventDownloadSafetyManualTemplate = props => {
    const { currentUser, sendPendoEvent, userCompany, userPermissionsOriginal } = props;
    let userSkus = _uniqBy(userPermissionsOriginal, 'skuKey');
    const ehsSku = userSkus
        .filter(item => item.skuKey.indexOf('ehs') !== -1)
        .map(function(item) {
            return item.displayLabel;
        });
    userSkus = userSkus.map(({ skuKey }) => {
        return skuKey;
    });
    const pendoEvent = EVENTS.SAFETY.DOWNLOAD_SAFETY_MANUAL_TEMPLATE;

    pendoEvent.eventProperties.CompanyId = currentUser.companyId;
    pendoEvent.eventProperties.CompanyName = currentUser.companyName;
    pendoEvent.eventProperties.CompanyType = userCompany.companyType;
    pendoEvent.eventProperties.EhsSku = ehsSku.join(', ');
    pendoEvent.eventProperties.DisplayName = userCompany.displayName;
    pendoEvent.eventProperties.Industry = userCompany.industry;
    pendoEvent.eventProperties.Location = JSON.stringify(userCompany.location);
    pendoEvent.eventProperties.Username = currentUser.userName;
    pendoEvent.eventProperties.UserRole = currentUser.role;
    pendoEvent.eventProperties.UserSkus = JSON.stringify(userSkus);
    sendPendoEvent(pendoEvent);
    return true;
};

/**
 * Validate if have access to Safety Detail.
 *
 * @method hasAccessToSafetyDetail
 * @return {boolean}
 */
export const hasAccessToSafetyDetail = () => {
    return !(
        !Acl.check(APP_PERMISSIONS.allEhs) &&
        !Acl.check(APP_PERMISSIONS.packageWorkplaceSafety) &&
        !Acl.check(APP_PERMISSIONS.osha) &&
        !Acl.check(APP_PERMISSIONS.premium)
    );
};
