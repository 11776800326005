import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'ravenjs/constants/colors';

import SvgIcon from '../SvgIcon';

function DownloadIcon(props) {
    const { disabled, setRef, ...rest } = props;
    const cursor = disabled ? 'not-allowed' : 'pointer';
    const fill = disabled ? COLORS.INPUT_DISABLED : COLORS.GREY;
    return (
        <SvgIcon cursor={cursor} width="20" height="21" {...rest} ref={setRef}>
            <path
                fill={fill}
                d="M2.6 14.3v3.9h14.3v-3.9h2.6v5.2c0 .72-.58 1.3-1.3 1.3H1.3c-.718 0-1.3-.58-1.3-1.3v-5.2h2.6zM11.05 0c.36 0 .65.291.65.65V9.1h2.6c.255 0 .487.149.592.381.105.233.066.506-.103.697l-4.55 5.2c-.123.14-.301.223-.489.223-.187 0-.365-.081-.488-.223l-4.55-5.2c-.168-.192-.21-.464-.103-.697.105-.231.336-.38.591-.38h2.6V.65c0-.359.291-.65.65-.65z"
            />
        </SvgIcon>
    );
}

DownloadIcon.propTypes = {
    disabled: PropTypes.bool,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
};

DownloadIcon.defaultProps = {
    disabled: false,
    setRef: null,
    viewBox: '0 0 20 21',
};

export default DownloadIcon;
