import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';

import * as authActions from 'modules/auth/actions';

import * as uiActions from '../actions';
import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'ui.modals' redux handlers.
 *
 * @type {Object}
 */
const loading = {
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [uiActions.addModal.SUCCESS]: helpers.handleModalSuccess,
    [uiActions.closeModal.SUCCESS]: helpers.handleModalSuccess,
    [uiActions.openModal.SUCCESS]: helpers.openModalSuccess,
    [uiActions.updateModal.SUCCESS]: helpers.updateModalSuccess,
};

// Create and export the 'ui.modals' reducer.
// NOTE: The default export will be the reducer.
export default createReducer(INITIAL_STATE, loading);

// Create and export named 'ui.modals' selectors.
// NOTE: The rest of the 'named' exports will be the selectors, for this module.

/**
 * Get all the info about the application modals.
 *
 * @method getAllModals
 * @param  {Object}     state The current state
 * @return {Object}           The modals info
 */
export const getAllModals = state => _get(state, 'ui.modals', {});

/**
 * Get a modal by a given `modalId`.
 *
 * @method getModalById
 * @param  {string}     modalId The id of the modal to fetch
 * @return {Function}
 */
export const getModalById = modalId =>
    createSelector([getAllModals], modals => _get(modals, modalId, {}));
