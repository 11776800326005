import { DEFAULT_COLORS } from 'constants/colors';

export const DEFAULT_TITLE = 'Safety';
export const DESCRIPTION = '';
export const WIDGETS = {
    OSHA: {
        DESCRIPTION:
            'Access OSHA forms to report health and safety issues and manage reported incidents.',
        HEADER: {
            text: '',
        },
        FOOTER: {
            text: 'Go to OSHA Reports',
        },
        ID: 'OSHA',
        ITEMS: [
            {
                title: 'Form 301: Download workplace Injury and Illness Report Form',
                handler: 'downloadOshaForm301',
            },
            {
                title: 'Form 300: Add new record of a workplace injury and illness',
                pathname: '/safety/osha/add',
            },
            {
                title: 'Form 300/300A: Download summary of Workplace injury and illness',
                handler: 'downloadOsha300',
            },
            {
                title: 'My Establishments',
                pathname: '/safety/osha/establishments/list',
            },
        ],
        PATH: '/safety/osha/list',
        TITLE: 'OSHA Logs',
    },
    SAFETY_RESOURCES: {
        BUTTON: {
            text: 'View All Safety Resources',
            handler: 'goToResources',
        },
        DESCRIPTION: 'Access additional health and safety resources.',
        HEADER: {
            text: '',
        },
        FOOTER: {
            text: 'View All Safety Resources',
        },
        ID: 'SAFETY_RESOURCES',
        PATH: '/safety/resources',
        TITLE: 'Safety Resources',
    },
};
export const UNDER_CONSTRUCTION_LABEL = 'Under Construction';
export const CONTENT_LIBRARY_TITLE = 'Safety Content Library';
export const LEARN_MOST_LABEL = 'Learn More';
export const SEARCH_TOPICS = {
    placeholder: 'Search Safety Topics',
    labelButton: 'Search',
};
export const SEARCH_LANDING_CONTENT_LIBRARY = {
    placeholder: 'Search the Safety Content Library',
    labelButton: 'Search',
};
export const TOPIC_HEADER = 'Safety Topics';
export const SHOW_MORE_LABEL = 'Show More';
export const WIDGETS_CONTENT_LIBRARY = [
    {
        id: 1,
        name: 'Featured Content',
        internalName: 'featuredContent',
        isVisible: true,
        isRequired: false,
    },
    {
        disableCollapse: true,
        id: 2,
        name: 'Explore the categories and topics in the Safety Content Library',
        internalName: 'categories',
        isVisible: true,
        isRequired: false,
    },
];
export const LANDING_WIDGET_INTERNAL_NAMES = {
    FEATURED_CONTENT: 'featuredContent',
    CATEGORIES: 'categories',
};

export const LANDING_WIDGET_INTRO_TEXT = {
    FEATUREDCONTENT:
        'Check out key news, compliance updates, and information vital to your business.',
    CATEGORIES: '',
};

export const LANDING_WIDGET_PENDO_CLASSNAME = {
    FEATUREDCONTENT: 'pendo-featured-content',
};

export const LANDING_SECTION_HIDE_INFO_ICON = {
    FEATUREDCONTENT: true,
    CATEGORIES: true,
};

export const LANDING_SECTION_INFO_ICON_TOOLTIP_PLACEMENT = {
    TODO: 'left',
};

export const LANDING_SECTION_INFO_ICON = {
    FEATUREDCONTENT: '',
};
export const SAFETY_MANUAL_TEMPLATE_ID = 'SafetyManualTemplate';
export const SAFETY = 'Safety';
export const UPGRADE_TO_ACCESS = 'Upgrade to access';
export const VIEW_LABEL = 'View \u2192';
export const SAFETY_EXPERTS = 'safetyadvisor';
export const COLORS_NOT_ALLOWED = [DEFAULT_COLORS.WHITE];
export const OSHA = 'oshareporting';
export const SAFETY_COURSES = 'safetycourses';
export const UPGRADE_NEEDED_LABEL = 'Upgrade needed to access';
export const MNL_ID = '7lfJ6OOjPxWYXuqzp1wYLc';
export const EHS_CATEGORY_ID = '5QZx2VftbmYQwlw8P4zTvE';
export const NEW_ESTABLISHMENT = 'New Establishment';
