import { string, func } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AriesAvatarIcon = ({
    color,
    fill,
    opacity1,
    opacity2,
    opacity3,
    height,
    onClick,
    viewBox,
    width,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} onClick={onClick} viewBox={viewBox} {...rest}>
            <rect width={width} height={height} rx="8" fill={color} />
            <path
                d="M8.01162 10.6633C7.93663 10.6633 7.87598 10.6052 7.87598 10.5334V7.936C7.87598 7.86422 7.93663 7.80615 8.01162 7.80615C8.08661 7.80615 8.14727 7.86422 8.14727 7.936V10.5334C8.14727 10.6052 8.08661 10.6633 8.01162 10.6633Z"
                fill={fill}
            />
            <path
                opacity={opacity1}
                d="M8.55459 10.1699C8.4796 10.1699 8.41895 10.1118 8.41895 10.04V8.42965C8.41895 8.35787 8.4796 8.2998 8.55459 8.2998C8.62958 8.2998 8.69024 8.35787 8.69024 8.42965V10.04C8.69024 10.1118 8.62958 10.1699 8.55459 10.1699Z"
                fill={fill}
            />
            <path
                opacity={opacity2}
                d="M9.09756 9.68937C9.02257 9.68937 8.96191 9.63131 8.96191 9.55953V8.91012C8.96191 8.83834 9.02257 8.78027 9.09756 8.78027C9.17255 8.78027 9.23321 8.83834 9.23321 8.91012V9.55953C9.23321 9.63131 9.17255 9.68937 9.09756 9.68937Z"
                fill={fill}
            />
            <path
                opacity={opacity3}
                d="M9.63955 9.53339C9.56456 9.53339 9.50391 9.47533 9.50391 9.40355V9.06588C9.50391 8.9941 9.56456 8.93604 9.63955 8.93604C9.71454 8.93604 9.7752 8.9941 9.7752 9.06588V9.40355C9.7752 9.47533 9.71454 9.53339 9.63955 9.53339Z"
                fill={fill}
            />
            <path
                opacity={opacity1}
                d="M7.46865 10.1699C7.39367 10.1699 7.33301 10.1118 7.33301 10.04V8.42965C7.33301 8.35787 7.39367 8.2998 7.46865 8.2998C7.54364 8.2998 7.6043 8.35787 7.6043 8.42965V10.04C7.6043 10.1118 7.54364 10.1699 7.46865 10.1699Z"
                fill={fill}
            />
            <path
                opacity={opacity2}
                d="M6.92666 9.68937C6.85167 9.68937 6.79102 9.63131 6.79102 9.55953V8.91012C6.79102 8.83834 6.85167 8.78027 6.92666 8.78027C7.00165 8.78027 7.06231 8.83834 7.06231 8.91012V9.55953C7.06231 9.63131 7.00165 9.68937 6.92666 9.68937Z"
                fill={fill}
            />
            <path
                opacity={opacity3}
                d="M6.38369 9.53339C6.3087 9.53339 6.24805 9.47533 6.24805 9.40355V9.06588C6.24805 8.9941 6.3087 8.93604 6.38369 8.93604C6.45868 8.93604 6.51934 8.9941 6.51934 9.06588V9.40355C6.51934 9.47533 6.45868 9.53339 6.38369 9.53339Z"
                fill={fill}
            />
            <path
                d="M13.4998 12.5H11.6316L8 4.54566L8.93351 2.50134L13.4998 12.5Z"
                fill="white"
                fillOpacity={opacity2}
            />
            <path
                d="M8.93333 2.5H7.06727L7.06678 2.50134L2.5 12.5H4.36874L8.93357 2.50134L8.93333 2.5Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

AriesAvatarIcon.propTypes = {
    color: string,
    fill: string,
    opacity1: string,
    opacity2: string,
    opacity3: string,
    height: string,
    onClick: func,
    viewBox: string,
    width: string,
};

AriesAvatarIcon.defaultProps = {
    color: DEFAULT_COLORS.CHAT_AVATAR_BASE,
    fill: DEFAULT_COLORS.CHAT_AVATAR_WHITE,
    opacity1: '0.8',
    opacity2: '0.5',
    opacity3: '0.3',
    height: '16',
    onClick: () => {},
    viewBox: '0 0 16 16',
    width: '16',
};

export default AriesAvatarIcon;
