import { call, put } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import {
    activateAffiliateAPI,
    deactivateAffiliateAPI,
    fetchAffiliateAPI,
    fetchAffiliatesAPI,
    updateAffiliateAPI,
} from 'modules/apis';
import * as adminActions from '../actions';

/**
 * Saga to fetch list of affiliates
 *
 * @method fetchAffiliatesSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchAffiliatesSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchAffiliatesAPI, params);
        const affiliates = get(response, 'data.affiliates', []);
        const totalRecords = Number(get(response, 'data.totalRecords', 0));
        yield put(adminActions.fetchAffiliates.success({ affiliates, totalRecords }));
        return affiliates;
    } catch (error) {
        yield put(adminActions.fetchAffiliates.error(error));
        return error;
    }
}

/**
 * Saga to fetch affiliate details
 *
 * @method fetchAffiliateSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchAffiliateSaga(action) {
    try {
        const { payload: param } = action;
        const response = yield call(fetchAffiliateAPI, param);
        const affiliate = get(response, 'data.affiliate');
        yield put(adminActions.fetchAffiliate.success(affiliate));
        return affiliate;
    } catch (error) {
        yield put(adminActions.fetchAffiliate.error(error));
        return error;
    }
}

/**
 * Saga to update Affiliate
 *
 * @method updateAffiliateSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* updateAffiliateSaga(action) {
    try {
        const {
            payload: { affiliateId, data },
        } = action;
        const response = yield call(updateAffiliateAPI, affiliateId, data);
        const affiliate = get(response, 'data');
        yield put(adminActions.updateAffiliate.success(affiliate));
        return affiliate;
    } catch (error) {
        yield put(adminActions.updateAffiliate.error(error));
        return error;
    }
}

/**
 * Saga to activate affiliate
 *
 * @method activateAffiliateSaga
 * @param  {Object}               action The original redux-action
 */
export function* activateAffiliateSaga(action) {
    try {
        const { payload: affiliateId } = action;
        const response = yield call(activateAffiliateAPI, affiliateId);
        yield put(adminActions.activateAffiliate.success(get(response, 'data')));
    } catch (error) {
        yield put(adminActions.activateAffiliate.error(error));
    }
}

/**
 * Saga to deactivate affiliate
 *
 * @method deactivateAffiliateSaga
 * @param  {Object}               action The original redux-action
 */
export function* deactivateAffiliateSaga(action) {
    try {
        const { payload: affiliateId } = action;
        const response = yield call(deactivateAffiliateAPI, affiliateId);
        yield put(adminActions.deactivateAffiliate.success(get(response, 'data')));
    } catch (error) {
        yield put(adminActions.deactivateAffiliate.error(error));
    }
}
