import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    fetchJobsByFunctionalAreaAPI,
    fetchJobsListAPI,
    fetchJobDetailsAPI,
    fetchFunctionalAreasListAPI,
    fetchJobsRecommendedContentListAPI,
} from 'modules/apis';
import * as uiActions from 'modules/ui/actions';
import * as toolActions from '../actions';

/**
 * Function for fetching jobs functional area list
 *
 * @method fetchJobsList
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* fetchJobsList(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const response = yield call(fetchJobsListAPI, payload);
        const jobList = get(response, 'data', {});
        yield put(toolActions.fetchJobsList.success(jobList));
        return jobList;
    } catch (error) {
        yield put(toolActions.fetchJobsList.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Function for fetching jobs functional area list
 *
 * @method fetchJobDetails
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* fetchJobDetails(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const response = yield call(fetchJobDetailsAPI, payload);
        const jobDetails = get(response, 'data', {});
        yield put(toolActions.fetchJobDetails.success(jobDetails));
        return jobDetails;
    } catch (error) {
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Function for fetching functional area list
 *
 * @method fetchFunctionalAreasList
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* fetchFunctionalAreasList(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const response = yield call(fetchFunctionalAreasListAPI);
        const functionalAreasList = get(response, 'data', {});
        yield put(toolActions.fetchFunctionalAreasList.success(functionalAreasList));
        return functionalAreasList;
    } catch (error) {
        yield put(toolActions.fetchFunctionalAreasList.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Function for fetching recommended content list
 *
 * @method fetchJobsRecommendedContentList
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* fetchJobsRecommendedContentList(action) {
    try {
        const response = yield call(fetchJobsRecommendedContentListAPI);
        const recommendedContentList = get(response, 'data', {});
        yield put(toolActions.fetchJobsRecommendedContent.success(recommendedContentList));
        return recommendedContentList;
    } catch (error) {
        yield put(toolActions.fetchJobsRecommendedContent.error(error));
        return error;
    }
}

/**
 * Saga to fetch job description by function area
 *
 * @method fetchJobsByFunctionalArea
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchJobsByFunctionalArea(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchJobsByFunctionalAreaAPI, params);
        const responseIdentifier = Object.keys(get(response, 'data.result.entry'))[0];
        const jobs = get(response, `data.result.entry.${responseIdentifier}`, []);
        const total = get(response, `data.result.totalRecords`, 0);
        yield put(toolActions.fetchJobsByFunctionalArea.success({ jobs, total }));
        return jobs;
    } catch (error) {
        yield put(toolActions.fetchJobsByFunctionalArea.error(error));
        return error;
    }
}
