import styled from 'styled-components';

const FooterMenuItem = styled.li`
    line-height: 1;
    list-style-type: none;
    margin-right: 2rem;
    &:last-of-type {
        margin-right: 0;
    }

    ${({ theme }) => theme.media.down('md')`
        width: ${({ width }) => width};
        margin-bottom: ${({ marginBottom }) => marginBottom || '0.625rem'};
    `};
`;

export default FooterMenuItem;
