const CALCULATORS = {
    CALCULATORS_NAVIGATE: {
        event: 'calculatorsFromListPage',
        eventProperties: {
            description: 'User navigate to specific calculators section',
        },
    },
    CALCULATORS_CLICK_CALCULATE: {
        event: 'clickCalculateButton',
        eventProperties: {
            description: 'User click on "Calculate" button in a specific calculators section',
        },
    },
};

export default CALCULATORS;
