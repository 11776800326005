import PropTypes from 'prop-types';
import AsyncReactSelect from 'react-select/lib/Async';
import React from 'react';
import { withTheme } from 'styled-components';

import DropdownIndicator from './DropdownIndicator';
import getSelectStyles from './SelectStyles';

/**
 * Shallow wrapper around the `react-select`.
 *
 * @method      AsyncSelect
 * @param       {Object} props The props for the component.
 * @constructor
 */
const AsyncSelect = React.forwardRef((props, ref) => {
    const {
        components,
        disabled,
        id,
        inputId,
        loadOptions,
        onChange,
        value,
        required,
        theme,
        styles,
        showRequired,
        onBlur,
        useSearchIcon,
        ...rest
    } = props;

    return (
        <div style={{ position: 'relative' }}>
            <AsyncReactSelect
                {...rest}
                classNamePrefix="RavenSelect-Async"
                components={{
                    DropdownIndicator,
                    ...components,
                }}
                id={id || inputId}
                isDisabled={disabled}
                loadOptions={loadOptions}
                menuPlacement="auto"
                ref={ref}
                required={required}
                useSearchIcon={useSearchIcon}
                styles={getSelectStyles(theme, styles, showRequired)}
                onChange={onChange}
                value={value}
                name={id}
                onBlur={typeof onBlur === 'function' && (event => onBlur(id, event.target.value))}
            />
            {/*
                FIXME: HACK !!!!
                react-select do not pass down the 'required' props to the HTML <input> in v2.x
                Please refer:
                - https://github.com/JedWatson/react-select/issues/3140
                - https://github.com/JedWatson/react-select/issues/2751

                So here we are having a hidden <input> which will hold the similar
                value to the actual dropdown. But it will be used for the native HTML validation.

                This piece can be removed once the library provide support for the
                'required' prop in future.
            */}
            <input
                id={`${id || inputId}__hidden`}
                tabIndex={-1}
                value={value || ''}
                required={required}
                onChange={onChange}
                style={{
                    height: '100%',
                    left: 0,
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    zIndex: -100,
                }}
                onFocus={() => ref.current.focus()}
                onBlur={typeof onBlur === 'function' && (event => onBlur(id, event.target.value))}
            />
        </div>
    );
});

AsyncSelect.propTypes = {
    /**
     * Components for the dropdown
     */
    components: PropTypes.object,
    /**
     * disables the dropdown when true
     */
    disabled: PropTypes.bool,
    /**
     * the dropdown identifier
     */
    id: PropTypes.string,
    /**
     * the dropdown's input identifier
     */
    inputId: PropTypes.string,
    /**
     * load options via API call, or async
     */
    loadOptions: PropTypes.func,
    /**
     * On Change Callback
     */
    onChange: PropTypes.func,
    /**
     * 'true' if the input value is required
     */
    required: PropTypes.bool,
    /**
     * the THEME object
     */
    theme: PropTypes.object,
    /**
     * Value of the input field
     */
    value: PropTypes.any,
    /**
     * Styles of the input field
     */
    styles: PropTypes.object,
    /**
     * showRequired of the input field
     */
    showRequired: PropTypes.bool,
    /**
     * onBlur of the input field
     */
    onBlur: PropTypes.func,
    useSearchIcon: PropTypes.bool,
};

AsyncSelect.defaultProps = {
    components: null,
    disabled: false,
    id: null,
    inputId: null,
    loadOptions: () => {},
    onChange: null,
    required: false,
    theme: null,
    // TODO: Remove once the 'required' hack is not needed
    // Setting to undefined to avoid passing null to the <input>
    value: undefined,
    styles: null,
    showRequired: false,
    onBlur: () => {},
    useSearchIcon: false,
};

export { AsyncSelect as AsyncSelectUnwrapped };
export default withTheme(AsyncSelect);
