import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MyFavoriteIcon = ({ color, strokeColor, height, viewBox, width, ...rest }) => (
    <SvgIcon cursor="pointer" width={width} height={height} viewBox={viewBox} {...rest}>
        <g fill="none" fillRule="evenodd">
            <g fill={color} fillRule="nonzero">
                <path
                    d="M12,0c0.4,0,0.8,0.2,1,0.6l3.1,6.6l7,1.1c0.4,0.1,0.8,0.4,0.9,0.8c0.1,0.4,0,0.9-0.3,1.2l-5,5.1l1.2,7.3
                    c0.1,0.4-0.1,0.9-0.4,1.1s-0.8,0.3-1.1,0.1L12,20.4l-6.2,3.4C5.4,24.1,5,24,4.6,23.8c-0.3-0.3-0.5-0.7-0.4-1.1l1.2-7.3l-5-5.1
                    C0,10-0.1,9.5,0.1,9.1c0.1-0.4,0.5-0.7,0.9-0.8l7-1.1L11,0.6C11.2,0.2,11.6,0,12,0z"
                    fill={strokeColor}
                />
                <path
                    d="M12,3.7L9.6,8.8C9.4,9.1,9.1,9.4,8.8,9.4l-5.4,0.8l3.9,3.9c0.3,0.3,0.4,0.6,0.3,1l-0.9,5.6l4.8-2.6
                    c0.3-0.2,0.7-0.2,1,0l4.8,2.6l-0.9-5.6c-0.1-0.4,0.1-0.7,0.3-1l3.9-3.9l-5.4-0.8c-0.4-0.1-0.7-0.3-0.8-0.6L12,3.7z"
                    fill={color}
                />
            </g>
        </g>
    </SvgIcon>
);

MyFavoriteIcon.propTypes = {
    color: string,
    strokeColor: string,
    height: string,
    viewBox: string,
    width: string,
};

MyFavoriteIcon.defaultProps = {
    color: DEFAULT_COLORS.WHITE,
    strokeColor: DEFAULT_COLORS.BLACK,
    height: '26',
    viewBox: '0 0 26 26',
    width: '26',
};

export default MyFavoriteIcon;
