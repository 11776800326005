import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const CalendarIcon = ({ fill, setRef, ...rest }) => (
    <SvgIcon {...rest} ref={setRef}>
        <path
            fill={fill}
            fillRule="evenodd"
            d="M5.858 1.215v1.857h9.284V1.215H17v1.857c1.579 0 2.786 1.207 2.786 2.786v11.141c0 1.579-1.207 2.786-2.786 2.786H4.001c-1.579 0-2.786-1.207-2.786-2.786V5.858c0-1.579 1.207-2.786 2.786-2.786V1.215h1.857zm12.07 7.428H3.072v8.356c0 .557.372.929.929.929h12.998c.557 0 .929-.372.929-.929V8.643zm-5.571 5.57v1.858H4.929v-1.857h7.428zm3.714-3.713v1.857H4.929V10.5h11.142zm.928-5.57H4.001c-.557 0-.929.37-.929.928v.928h14.856v-.928c0-.557-.372-.929-.929-.929z"
            transform="translate(-698 -496) translate(81 479) translate(609 9.5) translate(8.5 8)"
        />
    </SvgIcon>
);

CalendarIcon.propTypes = {
    fill: PropTypes.string,
    height: PropTypes.string,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

CalendarIcon.defaultProps = {
    fill: '#3A3A3A',
    height: '20',
    setRef: null,
    viewBox: '0 0 19 20',
    width: '22',
};

export default CalendarIcon;
