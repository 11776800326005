/* Hotjar Tracking Code */

import { HOTJAR_API_KEY } from 'constants/app';
import { isProductionEnvironment, isQAEnvironment } from './common';

export const getHotjarApiKey = () => {
    let hotjarApiKey = '';

    if (isProductionEnvironment()) {
        hotjarApiKey = HOTJAR_API_KEY.PROD;
    }

    if (isQAEnvironment()) {
        hotjarApiKey = HOTJAR_API_KEY.QA;
    }

    return hotjarApiKey;
};
/* eslint-disable */

export function loadHotjar(apiKey) {
    (function(h, o, t, j, a, r) {
        h.hj =
            h.hj ||
            function() {
                (h.hj.q = h.hj.q || []).push(arguments);
            };
        h._hjSettings = { hjid: `${apiKey}`, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.id = 'hotjarScript';
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

export function pauseHotjarScript() {
    const scriptElement = document.getElementById('hotjarScript');

    if (scriptElement) {
        scriptElement.setAttribute('disabled', 'true');
    }
}

export function resumeHotjarScript() {
    const scriptElement = document.getElementById('hotjarScript');

    if (scriptElement) {
        scriptElement.removeAttribute('disabled');
    }
}

/* eslint-enable */
