import { DEFAULT_COLORS } from 'constants/colors';
import {
    CONTENT_TYPES,
    CONTENT_TYPES_MAPPED,
    DOCUMENT_TYPES_MAPPED,
    FILTER_DOCUMENT_TYPES,
} from 'constants/content';

export const AUTO_SUGGEST = {
    MAX_LENGTH: 100,
    PENDO_ID: 'global-search-box',
    PLACEHOLDER: 'Search Content and Documents in the Platform',
};

export const CONTENT_TYPE_LIST_ORDER = [
    CONTENT_TYPES_MAPPED.LAW,
    CONTENT_TYPES_MAPPED.LAW_ALERT,
    CONTENT_TYPES_MAPPED.TWO_MIN_HR,
    CONTENT_TYPES_MAPPED.WEBINARS,
    CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER,
    DOCUMENT_TYPES_MAPPED.LETTER,
    DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER,
    DOCUMENT_TYPES_MAPPED.FORM,
    DOCUMENT_TYPES_MAPPED.CHECKLIST,
    DOCUMENT_TYPES_MAPPED.POLICY,
    DOCUMENT_TYPES_MAPPED.CHART,
    CONTENT_TYPES_MAPPED.TOOLKIT,
];

export const CONTENT_TYPE_LIST_ORDER_FOR_TYPE_AHEAD = [
    CONTENT_TYPES_MAPPED.LAW,
    CONTENT_TYPES_MAPPED.LAW_ALERT,
    CONTENT_TYPES_MAPPED.TWO_MIN_HR,
    CONTENT_TYPES_MAPPED.WEBINARS,
    CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER,
    DOCUMENT_TYPES_MAPPED.LETTER,
    DOCUMENT_TYPES_MAPPED.GUIDE_WHITE_PAPER,
    DOCUMENT_TYPES_MAPPED.FORM,
    DOCUMENT_TYPES_MAPPED.CHECKLIST,
    DOCUMENT_TYPES_MAPPED.POLICY,
    DOCUMENT_TYPES_MAPPED.CHART,
    CONTENT_TYPES_MAPPED.TOOLKIT,
];

export const JAPI_SEARCH_CONTENT_TYPES = {
    ALL: 'all',
    DOCUMENT: 'document',
    LAW: CONTENT_TYPES.LAW,
    LAW_ALERT: CONTENT_TYPES.LAW_ALERT,
    QUESTION_AND_ANSWER: 'qa',
    TOOLKIT: CONTENT_TYPES.TOOLKIT,
    TWO_MIN_HR: CONTENT_TYPES.TWO_MIN_HR,
};

export const JAPI_SEARCH_FILTER_DOCUMENT_TYPES = {
    CHART: FILTER_DOCUMENT_TYPES.CHART,
    CHECKLIST: FILTER_DOCUMENT_TYPES.CHECKLIST,
    FORM: FILTER_DOCUMENT_TYPES.FORM,
    GUIDE_WHITE_PAPER: FILTER_DOCUMENT_TYPES.GUIDE_WHITE_PAPER,
    INFOGRAPHIC: FILTER_DOCUMENT_TYPES.INFOGRAPHIC,
    LETTER: FILTER_DOCUMENT_TYPES.LETTER,
    POLICY: FILTER_DOCUMENT_TYPES.POLICY,
};

export const SEARCH_TERM_TITLE = 'Showing results for...';
export const NO_MORE_RESULTS = 'No more results available';
export const TABS = {
    colors: {
        searchResults: DEFAULT_COLORS.BLACK,
        premiumContent: DEFAULT_COLORS.ORANGE,
    },
    icon: {
        labels: {
            premiumContent: 'This content is only available to Premium Subscribers.',
        },
    },
    keys: ['searchResults', 'premiumContent'],
    labels: {
        searchResults: 'Search Results',
        premiumContent: 'Premium Content',
    },
    totalResults: {
        searchResults: 0,
        premiumContent: 0,
    },
};
export const BANNER = {
    title: 'Unlock Premium',
    text: 'Get the most out of EHS search and lorem ipsum...',
    labelButton: 'Request Access',
};
