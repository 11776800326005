import axios from 'axios';

/**
 * API to fetching occupations search.
 *
 * @method fetchOccupationsSearchAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchOccupationsSearchAPI = params =>
    axios({
        method: 'get',
        url: `/tools/v1/jdb/search`,
        params,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetching occupations list.
 *
 * @method fetchOccupationsListAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchOccupationsListAPI = params =>
    axios({
        method: 'get',
        url: `/tools/v1/jdb/occupations`,
        params,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetching occupation by code.
 *
 * @method fetchOccupationsListAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchOccupationByCodeAPI = params =>
    axios({
        method: 'get',
        url: `/tools/v1/jdb/sections`,
        params,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetching states.
 *
 * @method fetchStatesAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchStatesAPI = () =>
    axios({
        method: 'get',
        url: 'tools/v1/jdb/states',
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to save job description.
 *
 * @method saveJobDescriptionBuilderAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const saveJobDescriptionBuilderAPI = params => {
    return axios({
        method: 'post',
        url: '/tools/v1/jdb',
        data: params,
        type: 'apiTools',
        noBearer: true,
    });
};

/**
 * API to fetching job descriptions list.
 *
 * @method fetchJobDescriptionsAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchJobDescriptionsAPI = params =>
    axios({
        method: 'get',
        url: 'tools/v1/jdb',
        params: { filters: { ...params } },
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to delete job description.
 *
 * @method deleteJobDescriptionAPI
 * @param  {number}  id
 * @return {Promise}
 */
export const deleteJobDescriptionAPI = id =>
    axios({
        method: 'delete',
        url: `tools/v1/jdb/${id}`,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to downlaod job description.
 *
 * @method downloadJobDescriptionAPI
 * @param  {number}  id
 * @param  {string}  type
 * @return {Promise}
 */
export const downloadJobDescriptionAPI = (id, type) =>
    axios({
        method: 'get',
        url: `/tools/v1/jdb/download?jobDescriptionId=${id}&format=${type}`,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetch job description.
 *
 * @method fetchJobDescriptionAPI
 * @param  {number}  id
 * @return {Promise}
 */
export const fetchJobDescriptionAPI = id =>
    axios({
        method: 'get',
        url: `/tools/v1/jdb/${id}`,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to update job description.
 *
 * @method updateJobDescriptionAPI
 * @param  {number}  id
 * @param  {Object}  data
 * @return {Promise}
 */
export const updateJobDescriptionAPI = (id, data) =>
    axios({
        method: 'put',
        url: `/tools/v1/jdb/${id}`,
        data,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetch occupation.
 *
 * @method fetchOccupationAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const fetchOccupationAPI = params =>
    axios({
        method: 'get',
        url: `/tools/v1/jdb/occupation`,
        params,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to get job description recent documents.
 *
 * @method getJobDescriptionDocumentsAPI
 * @param  {Object}  params
 * @return {Promise}
 */
export const getJobDescriptionDocumentsAPI = params =>
    axios({
        method: 'get',
        url: '/tools/v1/jdb',
        params,
        type: 'apiTools',
        noBearer: true,
    });
