/**
 * Reducer helper for the `fetchUpsellAdsSuccess` success action.
 *
 * @method fetchUpsellAdsSuccess
 * @param  {Object}              state  The 'upsellAds' state
 * @param  {Object}              action The fired action
 * @return {Object}                     The updated state
 */
export const fetchUpsellAdsSuccess = (state, action) => ({
    ...state,
    ...action.payload,
});
