import { createActions } from 'utils/actions';

/**
 * Redux actions to fetch app-navigation menus.
 *
 * @type {Object}
 */
export const fetchAppNav = createActions('ui', 'FETCH_APP_NAV');

/**
 * Redux actions for showing the app-navigation.
 *
 * @type {Object}
 */
export const showAppNav = createActions('ui', 'SHOW_APP_NAV');

/**
 * Redux actions for showing the sub-navigation.
 *
 * @type {Object}
 */
export const showSubNav = createActions('ui', 'SHOW_SUB_NAV');

/**
 * Redux actions for showing the user-navigation.
 *
 * @type {Object}
 */
export const showUserNav = createActions('ui', 'SHOW_USER_NAV');

/**
 * Redux actions for hiding the app-navigation.
 *
 * @type {Object}
 */
export const hideAppNav = createActions('ui', 'HIDE_APP_NAV');

/**
 * Redux actions for hiding the sub-navigation.
 *
 * @type {Object}
 */
export const hideSubNav = createActions('ui', 'HIDE_SUB_NAV');

/**
 * Redux actions for hiding the user-navigation.
 *
 * @type {Object}
 */
export const hideUserNav = createActions('ui', 'HIDE_USER_NAV');
