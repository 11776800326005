import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CalendarCheckIcon = ({ width, height, viewBox, fillColor }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path
                fill={fillColor}
                d="M19.3,4.3v14.3c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4H2.1c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1V4.3c0-0.4,0.1-0.7,0.4-1s0.6-0.4,1-0.4h1.4V1.8c0-0.5,0.2-0.9,0.5-1.3C4.4,0.2,4.9,0,5.4,0h0.7C6.6,0,7,0.2,7.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3v1.1h4.3V1.8c0-0.5,0.2-0.9,0.5-1.3C13,0.2,13.4,0,13.9,0h0.7c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3v1.1h1.4c0.4,0,0.7,0.1,1,0.4C19.1,3.6,19.3,3.9,19.3,4.3z M2.1,18.6h15.7V7.1H2.1V18.6z M6.4,5V1.8c0-0.1,0-0.2-0.1-0.3C6.3,1.5,6.2,1.4,6.1,1.4H5.4c-0.1,0-0.2,0-0.3,0.1C5,1.6,5,1.7,5,1.8V5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.7c0.1,0,0.2,0,0.3-0.1C6.4,5.2,6.4,5.1,6.4,5z M15.3,10.8l-5.7,5.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1l-3.2-3.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2l0.5-0.5c0.1-0.1,0.1-0.1,0.2-0.1s0.2,0,0.3,0.1l2.5,2.5l5-5c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0.1l0.5,0.5c0.1,0.1,0.1,0.1,0.1,0.2C15.4,10.6,15.3,10.7,15.3,10.8z M15,5V1.8c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-0.7c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3V5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.7c0.1,0,0.2,0,0.3-0.1C15,5.2,15,5.1,15,5z"
            />
        </SvgIcon>
    );
};

CalendarCheckIcon.propTypes = {
    width: string,
    height: string,
    viewBox: string,
    fillColor: string,
};

CalendarCheckIcon.defaultProps = {
    width: '20',
    height: '20',
    viewBox: '0 0 20 20',
    fillColor: DEFAULT_COLORS.DARK,
};

export default CalendarCheckIcon;
