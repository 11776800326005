import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

import { DEFAULT_COLORS } from 'constants/colors';

const SubNavigationStyled = styled.div`
    align-items: center;
    background-color: ${({ theme }) =>
        getThemeProps('palette.secondary.gradientColor', DEFAULT_COLORS.BLUE, { theme })};
    border-bottom: 1px solid ${getThemeProps('palette.common.lighter')};
    box-shadow: ${getThemeProps('shadows.bottom[5]')};
    color: ${({ theme }) =>
        getThemeProps('palette.secondary.text', DEFAULT_COLORS.BLUE, { theme })};
    display: flex;
    height: ${getThemeProps('AppNavigation.height')}px;
    left: 0;
    opacity: 1;
    overflow-x: ${({ overflowX }) => overflowX || 'auto'};
    position: sticky;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease-in-out, top 0.3s ease-in-out;
    z-index: 10;

    ${({ theme }) => theme.media.down('md')`
        justify-content: space-between;
        padding: 0 0.5rem;
    `};

    ${({ theme }) => theme.media.up('md')`
        justify-content: space-between;
        overflow: hidden;
        padding: ${({ padding }) => padding || '0 1rem'};
    `};

    ${getThemeProps('SubNavigation.styles')};

    ${({ show }) =>
        !show && {
            opacity: 0,
            top: 0,
            transition: 'opacity 0.5s ease-in-out, top 0.3s ease-in-out',
        }};
`;

export default SubNavigationStyled;
