import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const EmailSmallIcon = ({ fill, height, width, viewBox, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.87261 9.40526L18 17.7058L30.1274 9.40527C29.9019 8.96923 29.4381 8.66667 28.9091 8.66667H7.09091C6.56187 8.66667 6.09812 8.96923 5.87261 9.40526ZM30.2727 12.5609L18.782 20.4256C18.3125 20.747 17.6875 20.747 17.218 20.4256L5.72727 12.5609V26C5.72727 26.7303 6.34402 27.3333 7.09091 27.3333H28.9091C29.656 27.3333 30.2727 26.7303 30.2727 26V12.5609ZM3 10C3 7.79695 4.83779 6 7.09091 6H28.9091C31.1622 6 33 7.79695 33 10V26C33 28.203 31.1622 30 28.9091 30H7.09091C4.83779 30 3 28.203 3 26V10Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

EmailSmallIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

EmailSmallIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '36',
    width: '36',
    viewBox: '0 0 36 36',
};

export default EmailSmallIcon;
