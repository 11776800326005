import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const InfoLargeIcon = ({ height, viewBox, width, ...rest }) => (
    <SvgIcon
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 14.88c.803 0 1.454.652 1.454 1.455v5.818a1.455 1.455 0 1 1-2.909 0v-5.818c0-.803.652-1.455 1.455-1.455zM16 9.062a1.455 1.455 0 0 0 0 2.91h.015a1.455 1.455 0 1 0 0-2.91H16z"
            fill="#3052DA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16.335c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16zM16 3.244c-7.23 0-13.09 5.861-13.09 13.091 0 7.23 5.86 13.09 13.09 13.09 7.23 0 13.09-5.86 13.09-13.09 0-7.23-5.86-13.091-13.09-13.091z"
            fill="#3052DA"
        />
    </SvgIcon>
);

InfoLargeIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

InfoLargeIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
};

export default InfoLargeIcon;
