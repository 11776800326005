import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { genID } from 'ravenjs/utils/generate';

import SubNavItem from 'components/SubNavItem';
import MESSAGES from 'constants/messages';
import { EVENTS } from 'constants/events';
import { MNL_INTELLIGENCE } from 'constants/intelligence';
import { IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS } from 'constants/navigation';
import Acl from 'modules/acl';
import { selectors as authSelectors } from 'modules/auth';
import { selectors as userSelectors } from 'modules/user';
import { actions as pendoActions } from 'modules/pendo';
import { isClientSolutionReportDisabled, isPartnerValueReportDisabled } from 'utils/reporting';

import SubNavItemsStyled from './SubNavItemsStyled';

const SubNavItems = ({
    flexWrap,
    isImpersonatingUser,
    items,
    justifyContent,
    sendPendoEvent,
    userCompany,
    userRole,
    currentUser,
}) => {
    const filterUnauthorized = item => {
        if (item.permission) {
            return Acl.check(item.permission);
        }
        return true;
    };
    const filterLicense = item => {
        if (item?.hasLicenseCheck) {
            return !isClientSolutionReportDisabled(userCompany, userRole);
        }
        return true;
    };

    const filterMAPSku = item => {
        if (item?.hasMAPSkuCheck) {
            return !isPartnerValueReportDisabled(userCompany, userRole);
        }
        return true;
    };

    const handleClickForPendo = item => {
        return () => {
            if (item.state === MNL_INTELLIGENCE.MINTEL_NAV_STATE) {
                const intelligencePendoEvent = EVENTS.INTELLIGENCE.REDIRECT_TO_DASHBOARD;
                intelligencePendoEvent.eventProperties.source = 'HR Compliance Dashboard Tab';
                intelligencePendoEvent.eventProperties.userName = currentUser.userName;
                intelligencePendoEvent.eventProperties.companyId = userCompany.companyId;
                intelligencePendoEvent.eventProperties.companyName = userCompany.companyName;
                sendPendoEvent(intelligencePendoEvent);
            } else if (item.state === MNL_INTELLIGENCE.MINTEL_INSIGHT_STATE) {
                const intelligencePendoEvent = EVENTS.INTELLIGENCE.REDIRECT_TO_INSIGHTS;
                intelligencePendoEvent.eventProperties.source = 'HR Compliance Insights Tab';
                intelligencePendoEvent.eventProperties.userName = currentUser.userName;
                intelligencePendoEvent.eventProperties.companyId = userCompany.companyId;
                intelligencePendoEvent.eventProperties.companyName = userCompany.companyName;
                sendPendoEvent(intelligencePendoEvent);
            }
        };
    };

    return (
        <SubNavItemsStyled flexWrap={flexWrap} justifyContent={justifyContent}>
            {items
                .filter(filterUnauthorized)
                .filter(filterLicense)
                .filter(filterMAPSku)
                .map(item => {
                    const disabled =
                        isImpersonatingUser &&
                        IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS.includes(item.to);
                    const subNavItemProps = disabled
                        ? {
                              ...item,
                              disabled: true,
                              onClick: e => {
                                  e.preventDefault();
                              },
                              title: MESSAGES.RESTRICTED_NAV_ITEM,
                          }
                        : { ...item };

                    return (
                        <SubNavItem
                            onClick={handleClickForPendo(item)}
                            key={item.subnavItemId || genID()}
                            {...subNavItemProps}
                        >
                            {item.title}
                        </SubNavItem>
                    );
                })}
        </SubNavItemsStyled>
    );
};

SubNavItems.propTypes = {
    flexWrap: string,
    isImpersonatingUser: bool,
    sendPendoEvent: func.isRequired,
    // List of navigational items
    items: arrayOf(
        shape({
            // The title to display for the NavLink
            title: string,
            // The URL for the NavLink
            to: string,
            subnavItemId: string,
        })
    ).isRequired,
    justifyContent: string,
    currentUser: object.isRequired,
    userCompany: object.isRequired,
    userRole: string.isRequired,
};

SubNavItems.defaultProps = {
    flexWrap: 'nowrap',
    isImpersonatingUser: false,
    justifyContent: 'flex-start',
};

const mapDispatchToProps = {
    sendPendoEvent: pendoActions.sendPendoEvent,
};

const mapStateToProps = createStructuredSelector({
    isImpersonatingUser: authSelectors.isImpersonatingUser,
    userRole: userSelectors.getUserRole,
    currentUser: userSelectors.getUserInfo,
    userCompany: userSelectors.getUserCompany,
});

export { SubNavItems as SubNavItemsUnconnected };
export default connect(mapStateToProps, mapDispatchToProps)(SubNavItems);
