import { takeLatest } from 'redux-saga/effects';

import * as pendoActions from '../actions';

import { getChatPendoGuideSaga, sendPendoEventSaga, sendPublicPendoEventSaga } from './pendo';

/**
 * Root saga for pendo actions
 *
 * @method pendoSaga
 * @type   {Generator}
 */
function* pendoSaga() {
    yield takeLatest(pendoActions.sendPendoEvent.TRIGGER, sendPendoEventSaga);
    yield takeLatest(pendoActions.sendPublicPendoEvent.TRIGGER, sendPublicPendoEventSaga);
    yield takeLatest(pendoActions.getChatPendoGuide.TRIGGER, getChatPendoGuideSaga);
}

export default pendoSaga;

export { getChatPendoGuideSaga, sendPendoEventSaga, sendPublicPendoEventSaga };
