import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import _get from 'lodash/get';

import Typography from 'ravenjs/lib/Typography';
import { getThemeProps } from 'ravenjs/utils/theme';
import { renderCheck } from 'ravenjs/utils/viewport';

import NavLogo from 'components/NavLogo';
import { DEFAULT_COLORS } from 'constants/colors';
import { SPACING } from 'constants/spacing';
import { selectors as brandingSelectors } from 'modules/branding';
import { selectors as userSelectors } from 'modules/user';
import { isRootCompanyRole } from 'utils/company';
import { configContainsSku } from 'utils/configurations';

const CoBrandingLogoStyled = styled.div`
    align-items: center;
    display: flex;
    ${({ theme }) => theme.media.up('md')`
        flex-direction: row;
        padding-right: ${({ padding }) => padding || '5.125rem'};
    `};
    ${({ theme }) => theme.media.down('md')`
        flex-direction: column;
        padding-right: ${({ padding }) => padding || '0.6rem'};
    `};
`;

const LogoBarStyled = styled.div`
    background: ${DEFAULT_COLORS.WHITE};
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const NavLogoBackground = styled.div`
    background: ${({ logoBackground, whiteBackground }) =>
        whiteBackground
            ? DEFAULT_COLORS.WHITE
            : logoBackground ||
              getThemeProps('AppNavigation.styles.backgroundColor', DEFAULT_COLORS.WHITE)};
    ${({ theme }) => theme.media.up('md')`
        padding: ${({ padding }) => padding || '1rem 5.125rem'};
    `};
    ${({ theme }) => theme.media.down('md')`
        padding: ${({ padding }) => padding || '0.6rem'};
    `};
`;

const RootCompanyLogo = styled.img`
    ${({ theme }) => theme.media.up('md')`
        height: 30px;
        width: 96px;
    `};
    ${({ theme }) => theme.media.down('md')`
        height: 20px;
        width: 105px;
    `};
`;

const AppNavigationLogoBar = props => {
    const {
        hasCustomLogo,
        logoBackground,
        logoUrl,
        logoPadding,
        onClickLogo,
        rootCompanyLogoUrl,
        userRoleType,
        whiteLogoBackground,
        partnerSettings,
        userConfiguration,
    } = props;

    const [isNotRootCompanyRole, setIsNotRootCompanyRole] = React.useState(true);
    const displayPartnerOnlyLogo = _get(partnerSettings, 'displayPartnerOnlyLogo', false);

    React.useEffect(() => {
        setIsNotRootCompanyRole(!isRootCompanyRole());
    }, [userRoleType]);

    const hasWhiteLabelSku = configContainsSku(userConfiguration, 'whitelabeling');

    return (
        <LogoBarStyled className="LogoBar">
            <NavLogoBackground
                logoBackground={logoBackground}
                padding={logoPadding}
                whiteBackground={whiteLogoBackground}
            >
                <NavLogo
                    to={logoUrl}
                    onClick={onClickLogo}
                    hasCustomLogo={hasCustomLogo}
                    height="100%"
                    width="auto"
                    openLoginPrompt={() => {}}
                    isSoftLogin={false}
                />
            </NavLogoBackground>
            {!hasWhiteLabelSku && isNotRootCompanyRole && !displayPartnerOnlyLogo && hasCustomLogo && (
                <CoBrandingLogoStyled padding={logoPadding}>
                    <Typography
                        fontSize={renderCheck('md', 'less') ? '0.6rem' : '0.8rem'}
                        gutterBottom="8px"
                        gutterLeft={SPACING.M}
                        gutterRight={SPACING.M}
                        gutterTop="0.6rem"
                    >
                        Powered By
                    </Typography>
                    <RootCompanyLogo alt="logo" src={rootCompanyLogoUrl} />
                </CoBrandingLogoStyled>
            )}
        </LogoBarStyled>
    );
};

AppNavigationLogoBar.propTypes = {
    hasCustomLogo: bool,
    logoBackground: string,
    logoPadding: string,
    logoUrl: string,
    onClickLogo: func,
    rootCompanyLogoUrl: string,
    userRoleType: string,
    whiteLogoBackground: bool,
    partnerSettings: object,
    userConfiguration: shape({ whitelabeling: bool }),
};

AppNavigationLogoBar.defaultProps = {
    hasCustomLogo: false,
    logoBackground: null,
    logoPadding: null,
    logoUrl: '',
    onClickLogo: () => {},
    rootCompanyLogoUrl: '',
    userRoleType: '',
    whiteLogoBackground: true,
    partnerSettings: {},
    userConfiguration: { whitelabeling: false },
};

const mapStateToProps = createStructuredSelector({
    hasCustomLogo: brandingSelectors.hasCustomLogo,
    rootCompanyLogoUrl: brandingSelectors.getRootCompanyLogoUrl,
    userRoleType: userSelectors.getUserRoleType,
    whiteLogoBackground: brandingSelectors.hasWhiteLogoBackground,
    partnerSettings: userSelectors.getPartnerSettings,
    userConfiguration: userSelectors.getUserConfiguration,
});

export { AppNavigationLogoBar as AppNavigationLogoBarUnwrapped };
export default connect(mapStateToProps)(AppNavigationLogoBar);
