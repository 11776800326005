import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';

import { AUTH_PERSIST_ID, TICKETS_PERSIST_ID, UI_PERSIST_ID } from 'constants/store';
import { reducer as auth } from 'modules/auth';
import { reducer as branding } from 'modules/branding';
import { reducer as config } from 'modules/bootstrap';
import { reducer as contentful } from 'modules/contentful';
import { reducer as errors } from 'modules/errors';
import { reducer as ui } from 'modules/ui';
import { reducer as user } from 'modules/user';
import { reducer as tickets } from 'modules/tickets';
import { reducer as inProductMarketingAds } from 'modules/inProductMarketing';
/**
 * The config for the redux persist for 'auth' reducer.
 *
 * @type {Object}
 */
const authPersistConfig = {
    // We don't want to persist the following keys.
    blacklist: ['error'],
    // This is good for development environments.
    debug: MAESTER.nodeEnv === 'development',
    // A key to keep in `localStorage`.
    key: AUTH_PERSIST_ID,
    // The type of storage to use.
    storage,
};

/**
 * The config for the redux persist for 'ui' reducer.
 *
 * @type {Object}
 */
const uiPersistConfig = {
    // We don't want to persist the following keys.
    blacklist: ['idle', 'loading', 'modals'],
    // This is good for development environments.
    debug: MAESTER.nodeEnv === 'development',
    // A key to keep in `localStorage`.
    key: UI_PERSIST_ID,
    // The type of storage to use.
    storage,
};

const ticketsPersistConfig = {
    // We only want to persist the following keys.
    whitelist: ['areCaseMetricsCollapsed'],
    // This is good for development environments.
    debug: MAESTER.nodeEnv === 'development',
    // A key to keep in `localStorage`.
    key: TICKETS_PERSIST_ID,
    // The type of storage to use.
    storage,
};

/**
 * Create the root reducer by combining all the available reducers into one.
 *
 * @method createRootReducer
 * @param  {Object}          history The browser history
 * @return {Function}
 */
function createRootReducer(history) {
    return combineReducers({
        auth: persistReducer(authPersistConfig, auth),
        branding,
        config,
        contentful,
        errors,
        router: connectRouter(history),
        ui: persistReducer(uiPersistConfig, ui),
        user,
        tickets: persistReducer(ticketsPersistConfig, tickets),
        inProductMarketingAds,
    });
}

export default createRootReducer;
