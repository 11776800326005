import get from 'lodash/get';
import { css } from 'styled-components';

import { COLORS } from 'ravenjs/constants/colors';
import { STICKY_COLUMN_POSITION } from 'ravenjs/constants/list';
import {
    getTableCellOffset,
    renderTableCellSize,
    verifyCSSColOffset,
    verifyCSSColSize,
} from 'ravenjs/utils/css';
import { getThemeProps, themeGet } from 'ravenjs/utils/theme';
import { renderStyle } from 'ravenjs/utils/styled';
import { renderCheck } from 'ravenjs/utils/viewport';

const TableCellCSS = css`
    box-sizing: border-box;
    max-width: 100%;
    ${({ style }) => {
        return style;
    }};
    padding: ${({ cellProps }) => {
        const noPadding = get(cellProps, 'noPadding', false);
        return noPadding ? '0' : '0.563rem 0 0.719rem 0.938rem';
    }};
    position: relative;
    text-align: ${({ align }) => align || 'left'};
    justify-content: ${({ align }) => align || 'left'};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    vertical-align: middle;
    /**
     * Add all the remaining styles from the theme
     *
     */
    ${themeGet('TableCell.styles')};
    /**
     * Add dynamic styles.
     */
    ${({ cellType, sortSchema, size, sticky, theme, type, cellProps, zebra, zebraColor }) => {
        let headCss = '';
        let sortableCss = '';
        let stickyCss = '';
        const borderRight = get(cellProps, 'borderRight', false);
        const borderLeft = get(cellProps, 'borderLeft', false);
        const borderBottom = get(cellProps, 'borderBottom', false);
        if (type === 'head') {
            headCss = css`
                font-size: 0.938rem;
                font-family: Favorit;
                border-top: none;
                border-right: ${borderRight ? `1px solid ${COLORS.GREY_2}` : 'none'};
                border-bottom: none;
                color: ${COLORS.BLACK2};
                font-weight: ${themeGet('typography.fontWeights.regular')};
                /**
                 * Add all the remaining styles from the theme
                 */
                ${themeGet('TableCell.styles.head')};
            `;
        }

        if (type !== 'head') {
            headCss = css`
                border-left: ${borderLeft ? `1px solid ${COLORS.GREY_2}` : 'none'};
                border-bottom: ${borderBottom ? `1px solid ${COLORS.GREY_2}` : 'none'};
            `;
        }

        if (cellType === 'sortable') {
            sortableCss = css`
                cursor: pointer;
                > svg:first-of-type {
                    margin-left: ${sortSchema.iconPosition === 'right' ? '0.438rem' : null};
                    margin-right: ${sortSchema.iconPosition === 'left' ? '0.438rem' : null};
                }
                /**
                * Add all the remaining styles from the theme
                */
                ${themeGet('TableCell.styles.sortable')};
            `;
        }

        if (sticky && sticky.position) {
            const position =
                sticky.offset === 0
                    ? `${sticky.position}: 0;`
                    : renderStyle(
                          sticky.position,
                          sticky.offset,
                          theme,
                          verifyCSSColOffset,
                          getTableCellOffset
                      );

            let border = '';

            if (sticky.border && sticky.position === STICKY_COLUMN_POSITION.LEFT) {
                border = `border-right: 1px solid ${COLORS.GREY_2};`;
            } else if (sticky.border && sticky.position === STICKY_COLUMN_POSITION.RIGHT) {
                border = `border-left: 1px solid ${COLORS.GREY_2};`;
            }

            const zebraRowColor = zebra
                ? themeGet(`palette.${zebraColor}.color`, COLORS.WHITE)({ theme })
                : getThemeProps('TableRow.styles.backgroundColor')({ theme });
            const stickyCellCSS = css`
                background-color: ${
                    type === 'head' ? COLORS.COMPONENTS_TABLE_TITLE_ROW : zebraRowColor
                };
                position: sticky;
                z-index: 1;
                ${border}
                ${position}
                ${renderStyle('max-width', size, theme, verifyCSSColSize, renderTableCellSize)}
            `;

            if (sticky.check) {
                const { breakpoint, value } = sticky.check;
                stickyCss = renderCheck(breakpoint, value) ? stickyCellCSS : '';
            } else {
                stickyCss = stickyCellCSS;
            }
        }

        return css`
            ${headCss};
            ${sortableCss};
            ${stickyCss};
            ${renderStyle('width', size, theme, verifyCSSColSize, renderTableCellSize)};
            ${renderStyle('min-width', size, theme, verifyCSSColSize, renderTableCellSize)};
        `;
    }};
`;

export default TableCellCSS;
