import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ThumbsUpIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon cursor="pointer" {...rest} width={width} height={height} viewBox={viewBox}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.381 2.54a.923.923 0 0 1 .843-.54c.979 0 1.917.383 2.609 1.065a3.61 3.61 0 0 1 1.08 2.571v2.728h4.294c.4-.004.795.077 1.159.239.366.161.692.4.955.698a2.69 2.69 0 0 1 .648 2.199l-1.273 8.182c-.1.65-.436 1.243-.944 1.669a2.791 2.791 0 0 1-1.818.649H4.767A2.788 2.788 0 0 1 2.81 21.2 2.708 2.708 0 0 1 2 19.273v-6.364c0-.723.292-1.417.81-1.928a2.788 2.788 0 0 1 1.957-.8h2.168L10.38 2.54zm-1.924 8.744 3.328-7.38c.278.088.533.24.743.447.346.34.54.803.54 1.285v3.637c0 .502.414.909.923.909h5.231a.933.933 0 0 1 .707.312.905.905 0 0 1 .216.733l-1.273 8.182a.905.905 0 0 1-.315.556.931.931 0 0 1-.608.217H8.457v-8.898zm-1.845 8.898V12H4.767a.93.93 0 0 0-.652.266.902.902 0 0 0-.27.643v6.364c0 .24.097.472.27.642a.93.93 0 0 0 .652.267h1.845z"
            fill={color}
        />
    </SvgIcon>
);

ThumbsUpIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

ThumbsUpIcon.defaultProps = {
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
    color: DEFAULT_COLORS.PLACEHOLDER,
};

export default ThumbsUpIcon;
