import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { genID } from 'ravenjs/utils/generate';
import { getThemeProps } from 'ravenjs/utils/theme';

/**
 * Generate a activeClassName for the NavLink
 *
 * @type {string}
 */
const subNavItemActiveClassName = genID();

const SubNavItem = styled(NavLink).attrs(() => ({
    /**
     * Add the prop `activeClassName` to the SubNavItem component.
     */
    activeClassName: subNavItemActiveClassName,
}))`
    align-items: center;
    background-color: transparent;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    color: ${getThemeProps('palette.common.dark')};
    display: flex;
    font-size: ${props => props.fontSize || '14px'};
    font-family: ${props => props.fontFamily || undefined};
    height: 100%;
    justify-content: center;
    margin: ${props => props.margin || '0'};
    padding: ${props => props.padding || '12px'};
    text-decoration: none !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    white-space: nowrap;
    &:last-of-type {
        margin-right: 0;
    }
    /**
     * Add in the styles for the activeClassName (mostly for <NavLink /> component)
     */
    &.${subNavItemActiveClassName} {
        ${getThemeProps('SubNavItem.active')};
        font-weight: ${props => props.fontWeight || 'normal'};
    }
    & {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('SubNavItem.styles')};
    &:hover {
        color: inherit;
    }
`;

export default SubNavItem;
