import { takeLatest } from 'redux-saga/effects';

import * as meetingsActions from '../actions';
import { fetchMeetingsSaga, updateMeetingSaga, fetchMyMeetingsSaga } from './meetings';

function* meetingsSaga() {
    yield takeLatest(meetingsActions.fetchMeetings.TRIGGER, fetchMeetingsSaga);
    yield takeLatest(meetingsActions.updateMeeting.TRIGGER, updateMeetingSaga);
    yield takeLatest(meetingsActions.fetchMyMeetings.TRIGGER, fetchMyMeetingsSaga);
}

export default meetingsSaga;

export { fetchMeetingsSaga, updateMeetingSaga, fetchMyMeetingsSaga };
