import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ArrowUpDefaultIcon = ({ fillColor, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            {...rest}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00016 2.6665C8.36835 2.6665 8.66683 2.96498 8.66683 3.33317V12.6665C8.66683 13.0347 8.36835 13.3332 8.00016 13.3332C7.63197 13.3332 7.3335 13.0347 7.3335 12.6665V3.33317C7.3335 2.96498 7.63197 2.6665 8.00016 2.6665Z"
                fill="#3052DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.52843 2.86177C7.78878 2.60142 8.21089 2.60142 8.47124 2.86177L13.1379 7.52843C13.3983 7.78878 13.3983 8.21089 13.1379 8.47124C12.8776 8.73159 12.4554 8.73159 12.1951 8.47124L7.99984 4.27598L3.80458 8.47124C3.54423 8.73159 3.12212 8.73159 2.86177 8.47124C2.60142 8.21089 2.60142 7.78878 2.86177 7.52843L7.52843 2.86177Z"
                fill="#3052DA"
            />
        </SvgIcon>
    );
};

ArrowUpDefaultIcon.propTypes = {
    fillColor: string,
    height: string,
    viewBox: string,
    width: string,
};

ArrowUpDefaultIcon.defaultProps = {
    fillColor: 'none',
    height: '16',
    viewBox: '0 0 16 16',
    width: '16',
};

export default ArrowUpDefaultIcon;
