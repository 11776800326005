import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const MessageSquareIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        {...rest}
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.00073 4C4.73552 4 4.48116 4.10536 4.29363 4.29289C4.10609 4.48043 4.00073 4.73478 4.00073 5V18.5858L6.29363 16.2929C6.48116 16.1054 6.73552 16 7.00073 16H19.0007C19.2659 16 19.5203 15.8946 19.7078 15.7071C19.8954 15.5196 20.0007 15.2652 20.0007 15V5C20.0007 4.73478 19.8954 4.48043 19.7078 4.29289C19.5203 4.10536 19.2659 4 19.0007 4H5.00073ZM2.87941 2.87868C3.44202 2.31607 4.20508 2 5.00073 2H19.0007C19.7964 2 20.5594 2.31607 21.1221 2.87868C21.6847 3.44129 22.0007 4.20435 22.0007 5V15C22.0007 15.7957 21.6847 16.5587 21.1221 17.1213C20.5594 17.6839 19.7964 18 19.0007 18H7.41495L3.70784 21.7071C3.42184 21.9931 2.99172 22.0787 2.61805 21.9239C2.24437 21.7691 2.00073 21.4045 2.00073 21V5C2.00073 4.20435 2.3168 3.44129 2.87941 2.87868Z"
            fill={color}
        />
    </SvgIcon>
);

MessageSquareIcon.propTypes = {
    viewBox: string,
    width: string,
    height: string,
    color: string,
};

MessageSquareIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default MessageSquareIcon;
