import _cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { addSchemaValue } from 'ravenjs/utils/schema';

import { AFTERNOON_CUTOFF_HOURS, EVENING_CUTOFF_HOURS } from 'constants/app';
import { APP_PERMISSIONS } from 'constants/permissions';
import { ROLE_TYPE } from 'constants/roles';
import REGEX_VALIDATE from 'constants/regex';
import { NEWSLETTERS, NEWSLETTERS_LABEL } from 'constants/subscriptions';
import Acl from 'modules/acl';
import { selectors as userSelectors } from 'modules/user';
import orderBy from 'lodash/orderBy';
import { buildCommunityUrl } from 'utils/common';

import store from '../store';

/**
 * Build Custom Fields for user
 *
 * @method buildUserCustomFields
 * @param  {Array}          [customFields=[]]  The customFields
 * @return {Object}
 */
export function buildUserCustomFields(customFields) {
    const properties = {};
    const requiredFields = [];
    const readOnlyProperties = {};
    let companyCustomFields = customFields.filter(field => {
        return field.type === 'USER' && field.isActive;
    });

    companyCustomFields = orderBy(companyCustomFields, 'displayOrder');

    companyCustomFields.forEach((value, index) => {
        const isReadOnly = get(value, 'readOnly', false);
        const keyName = `customField${index + 1}`;
        properties[keyName] = {
            title: value.displayLabel,
            type: 'string',
        };

        readOnlyProperties[keyName] = {
            'ui:disabled': isReadOnly,
        };

        if (value.importRequired) {
            properties[keyName].format = 'onlySpaces';
            requiredFields.push(keyName);
        }
    });
    return {
        schema: {
            title: 'Custom Fields',
            type: 'object',
            formGroup: true,
            properties,
            required: requiredFields,
        },
        uiSchema: {
            ...readOnlyProperties,
        },
    };
}

/**
 * Build Custom Fields form Data
 *
 * @method buildUserCustomFieldsFormData
 * @param  {Object}          userData  The userdata
 * @param  {Array}          [customFields=[]]  The customFields
 * @return {Object}
 */
export function buildUserCustomFieldsFormData(userData, { customFields = [] }) {
    const userCustomFields = {};
    const fieldMap = {};

    customFields.forEach((field, index) => {
        fieldMap[`u${index + 1}`] = `customField${index + 1}`;
    });

    customFields.forEach(field => {
        if (field.type === 'USER' && field.isActive)
            userCustomFields[fieldMap[field.displayKey]] = userData[fieldMap[field.displayKey]];
    });
    return userCustomFields;
}

/**
 * Build the user's full name
 *
 * @method buildFullName
 * @param  {Object}      user The user
 * @return {string}           The user's full name
 */
export function buildFullName(user) {
    if (!user) {
        return '';
    }
    // Extract the `firstName` from the user.
    const firstName = (user.firstName && user.firstName.trim()) || '';
    // Extract the `lastName` from the user.
    const lastName = (user.lastName && user.lastName.trim()) || '';
    // Based on which one is present, return the full name.
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    } else if (firstName && !lastName) {
        return firstName;
    } else if (!firstName && lastName) {
        return lastName;
    }
    return '';
}

/**
 * Generate the greeting for the given user.
 *
 * @method genUserGreeting
 * @param  {Object}        [user={}] The user
 * @return {string}                  The greeting with the user's name
 */
export function genUserGreeting(user = {}) {
    if (!user) {
        return '';
    }
    // Build the user's name.
    const name = buildFullName(user);
    // Get the hours of current local time.
    const hours = new Date().getHours();
    // Build the greeting based on a given greeting string.
    const _greeting = str => (name ? `${str}, ${name}` : str);
    // Update the greeting based on the current time.
    if (hours >= EVENING_CUTOFF_HOURS) {
        return _greeting('Good Evening');
    } else if (hours >= AFTERNOON_CUTOFF_HOURS) {
        return _greeting('Good Afternoon');
    }
    return _greeting('Good Morning');
}

/**
 * Returns logged in user permission for User Subscriptions
 *
 * @param {boolean}  disableSubscriptions
 * @return {{canUpdateNewsletterSubscriptions: boolean, canUpdateMineralInsightsSubscriptions: boolean, canUpdateLawAlertsSubscriptions: boolean}}
 */
export const getUserSubscriptionPermissions = disableSubscriptions => ({
    canUpdateLawAlertsSubscriptions:
        Acl.check(APP_PERMISSIONS.systemPreferencesUpdateChild) &&
        Acl.check(APP_PERMISSIONS.compliance) &&
        !disableSubscriptions,
    canUpdateNewsletterSubscriptions:
        Acl.check(APP_PERMISSIONS.systemPreferencesUpdateChild) &&
        Acl.check(APP_PERMISSIONS.compliance) &&
        !disableSubscriptions,
    canUpdateMineralInsightsSubscriptions: Acl.check(
        APP_PERMISSIONS.mineralInsightsSubscriptionUpdate
    ),
});

/**
 * Check whether the user is of Admin role from THR company
 *
 * @method  isAdministratorFromTHR
 * @param   {Object}    user
 * @return {boolean}
 */
export function isAdministratorFromTHR(user) {
    return (
        isTHRAdministrator(user.role) ||
        isSystemAdmin(user) ||
        isContentAuthor(user.role) ||
        isTHREmployee(user) ||
        isSupportManager(user.role)
    );
}

/**
 * Check to see if the given user is a THR admin.
 *
 * @method isUserThrAdmin
 * @param  {Object}       user The user to check
 * @return {boolean}
 */
export function isUserThrAdmin(user = {}) {
    // Handle when no `userRole` is present.
    if (!user.userRole) {
        return false;
    }
    // Match the `userRole` with the role defined.
    return user.userRole === MAESTER.roles.admin;
}

/**
 * Check to see if the given user is a Partner.
 *
 * @method isPartner
 * @param  {Object}       user The user to check
 * @return {boolean}
 */
export function isPartner({ role }) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.partner;
}

/**
 * Check to see if the given user is a Content Author.
 *
 * @method isContentAuthor
 * @param  {Object}       role The user to check
 * @return {boolean}
 */
export function isContentAuthor(role = '') {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.contentAuthor;
}

/**
 * Check if an user is a Partner Administrator
 *
 * @method isPartnerAdministrator
 * @param  {string} role Role
 * @return {string}
 */
export const isPartnerAdministrator = (role = '') => {
    if (!role) {
        return false;
    }
    return role === MAESTER.roles.partnerAdmin || role === MAESTER.roles.partnerARAdmin;
};

/**
 * // FIXME: Duplicate of isUserThrAdmin()
 *
 * Check if an user is a THR Administrator
 *
 * @method isTHRAdministrator
 * @param  {string} role Role
 * @return {string}
 */
export const isTHRAdministrator = (role = '') => {
    if (!role) {
        return false;
    }
    return role === MAESTER.roles.admin;
};

/**
 * Check to see if the given user is a Employee.
 *
 * @method isEmployee
 * @param  {Object}       user The user to check
 * @return {boolean}
 */
export function isEmployee({ role }) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.employee;
}

/**
 * Check to see if the given user is a client admin.
 *
 * @method isClientAdmin
 * @param  {Object}       user The user to check
 * @return {boolean}
 */
export function isClientAdmin({ role }) {
    if (!role) {
        return false;
    }
    return role === MAESTER.roles.clientAdmin || role === MAESTER.roles.clientARAdmin;
}

/**
 * Check to see if the given user is a Genesis Employee.
 *
 * @method isTHREmployee
 * @param  {Object}       user The user to check
 * @return {boolean}
 */
export function isTHREmployee({ role }) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.thrEmployee;
}

/**
 * Check to see if the given user is a System Admin.
 *
 * @method isSystemAdmin
 * @param  {Object}       user The user to check
 * @return {boolean}
 */
export function isSystemAdmin({ role }) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.sysAdmin;
}

/**
 * Format payload for create user API
 *
 * @param   {Object}    formData
 * @param   {Object}    company
 * @return  {Object}
 */
export function formatCreateUserPayload(formData, company) {
    const { accountInfo, trainingEmailSubscription, userInfo, customFields = {} } = formData;
    const payload = {
        ...accountInfo,
        ...trainingEmailSubscription,
        ...userInfo,
        ...customFields,
    };

    if (company.partnerId === MAESTER.companies.paychexFlex_id) {
        // Customization for Paychex flex.
        payload.companyCustomField = company.custom5;
    } else if (company.custom1) {
        // Set 'companyCustomField' if custom1 is available in the user company response
        // Refer: https://thinkhr.atlassian.net/browse/GN-880
        payload.companyCustomField = company.custom1;
    }

    return payload;
}

/**
 * Check to see if the given user is Paychex RE
 *
 * @method  isPaychexRE
 * @param   {Object}        user to check
 * @return  {boolean}
 */
export function isPaychexRE({ role }) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.paychexRE;
}

/**
 * Check to see if the given user is a Frontline Coordinator
 *
 * @method  isFrontlineCoordinator
 * @param   {Object} user to check
 * @return  {boolean}
 */
export function isFrontlineCoordinator({ role }) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.frontlineCoordinator;
}

/**
 * Check to see if the given user role is Support Manager
 *
 * @method  isSupportManager
 * @param   {string}        role to check
 * @return  {boolean}
 */
export function isSupportManager(role) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.supportManager;
}

/**
 * Check whether given role has permission to mark user as 'demo'
 *
 * @param  {string} role
 * @param  {boolean} disableDemoFunctionality
 * @return {boolean}
 */
export function hasDemoPermission(role, disableDemoFunctionality) {
    if (disableDemoFunctionality) {
        return false;
    }

    return Acl.check(APP_PERMISSIONS.systemUsersDemoUpdate);
}

/**
 * Formatter for update user info API Payload.
 *
 * @param  {string}     firstName
 * @param  {string}     lastName
 * @param  {string}     email
 * @param  {number}     phone
 * @param  {number}     directLinePhone
 * @param  {number}     cellPhone
 * @param  {string}     department
 * @param  {string}     jobTitle
 * @param  {number}     userId
 * @param  {string}     pronouns
 * @return {Object}
 */
export const formatUpdateUserPayload = ({
    firstName,
    lastName,
    email,
    phone = '',
    directLinePhone = '',
    cellPhone = '',
    department = '',
    jobTitle = '',
    userId,
    pronouns = '',
}) => ({
    firstName,
    lastName,
    pronouns,
    email,
    phone,
    directLinePhone,
    cellPhone,
    department,
    jobTitle,
    userId,
});

/**
 * Check whether the given role can edit user name
 *
 * @param  {boolean}    isSelfUser
 * @return {boolean}
 */
export const canEditUserName = isSelfUser => {
    let canEdit = false;

    if (Acl.check(APP_PERMISSIONS.systemUsersUpdateUsername)) {
        if (isSelfUser) {
            if (Acl.check(APP_PERMISSIONS.systemUsersUpdateUsernameSelf)) {
                canEdit = true;
            }
        } else {
            canEdit = true;
        }
    }

    return canEdit;
};

/**
 * Check to see if the given user role is client User.
 *
 * @method isClientUserRole
 * @param  {string}       role to check
 * @return {boolean}
 */
export const isClientUserRole = role => role === MAESTER.roles.clientUser;

/**
 * Check whether the user role is client user.
 *
 * @method  isClientUser
 * @param   {string}    role
 * @return {boolean}
 */
export const isClientUser = role => {
    return (
        isClientAdmin({ role }) ||
        isPaychexRE({ role }) ||
        isClientUserRole(role) ||
        isInternalSalesUser(role)
    );
};

/**
 * Check whether the user role is Independent Contractor.
 * @param   {string}     role
 * @return {boolean}
 */
export function isIndependentContractor(role) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.independentContractor;
}

/**
 * Check whether the user role is Internal Sales User.
 * @param   {string}     role
 * @return {boolean}
 */
export function isInternalSalesUser(role) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.internalSalesUser;
}

/**
 * Check whether the user role is HR Pro.
 * @param {string}      role
 * @return {boolean}
 */
export function isHrProRole(role) {
    if (!role) {
        return false;
    }

    return role === MAESTER.roles.hrPro;
}

/**
 * To Check if user role is genesis internal user .
 * @param   {string}        role
 * @return  {boolean}
 */
export const isGenesisInternalUser = role => {
    return (
        isFrontlineCoordinator({ role }) ||
        isIndependentContractor(role) ||
        isTHRAdministrator(role) ||
        isSystemAdmin({ role }) ||
        isHrProRole(role) ||
        isContentAuthor(role) ||
        isTHREmployee({ role })
    );
};
/**
 * Check if current user's role type is client
 * @return {boolean}
 */
export const isClientRoleType = () => {
    const storeConfig = store;
    const state = storeConfig.store.getState();
    const roleType = userSelectors.getUserRoleType(state);
    return roleType === ROLE_TYPE.CLIENT;
};

/**
 * Prepare form schema for UserSubscriptions form field
 *
 * @param {boolean} canUpdateLawAlertsSubscriptions
 * @param {boolean} canUpdateNewsletterSubscriptions
 * @param {boolean} canUpdateMineralInsightsSubscriptions
 * @param {Array}   newsletterCategories
 * @param {Array}   states
 * @param {Object}  subscriptionsInfo
 * @return {Object}
 */
export const prepareUserSubscriptionFieldFormSchema = ({
    canUpdateLawAlertsSubscriptions,
    canUpdateNewsletterSubscriptions,
    canUpdateMineralInsightsSubscriptions,
    newsletterCategories,
    states,
    schema,
}) => {
    const updatedSchema = _cloneDeep(schema);

    if (canUpdateLawAlertsSubscriptions) {
        addSchemaValue(
            updatedSchema,
            'properties.subscriptions.properties.userSubscriptions.lawAlerts.states',
            [
                {
                    name: 'Federal',
                    value: 'FE',
                },
                ...states.map(({ abbreviation, name }) => ({
                    name,
                    value: abbreviation,
                })),
            ]
        );
    }

    let newsletterOptions = [];

    if (canUpdateNewsletterSubscriptions) {
        newsletterOptions = [
            ...newsletterOptions,
            ...newsletterCategories.map(({ id, type }) => ({
                name: type,
                value: id,
            })),
        ];
    }

    if (canUpdateMineralInsightsSubscriptions) {
        newsletterOptions.push({
            name: NEWSLETTERS_LABEL.INSIGHTS,
            value: NEWSLETTERS.INSIGHTS,
        });
    }

    addSchemaValue(
        updatedSchema,
        'properties.subscriptions.properties.userSubscriptions.newsletters.options',
        newsletterOptions
    );

    return updatedSchema;
};

export const extractEmail = inputString => {
    const match = inputString.match(REGEX_VALIDATE.EMAIL_FORMAT);
    return match ? match[0] : null;
};

export const redirectExternalRoutes = async (route, params) => {
    const { accessToken, apiToCall } = params;

    switch (route) {
        case '/community': {
            const communityResult = await apiToCall(accessToken);

            const responseUrl = buildCommunityUrl(communityResult);

            window.open(responseUrl, '_blank');
            break;
        }

        default:
            break;
    }
};
