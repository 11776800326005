import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const FileIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                d="M21.7,8.3c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0l-7.6-7.6c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2
                C13.4,0,13.2,0,13.1,0H5.5C4.6,0,3.8,0.3,3.1,1s-1,1.4-1,2.3v17.5c0,0.9,0.3,1.7,1,2.3c0.6,0.6,1.4,1,2.3,1h13.1
                c0.9,0,1.7-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3v-12C21.8,8.6,21.8,8.4,21.7,8.3z M14.2,3.7l3.9,3.9h-3.9V3.7z M19.3,21.5
                c-0.2,0.2-0.5,0.3-0.8,0.3H5.5c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V3.3c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
                H12v6.5c0,0.6,0.5,1.1,1.1,1.1h6.5v10.9C19.6,21,19.5,21.3,19.3,21.5z"
                fill={fill}
            />
        </SvgIcon>
    );
};

FileIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

FileIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default FileIcon;
