import React from 'react';
import templateReplace from 'es6-template-strings';
import _get from 'lodash/get';
import { func, object, string } from 'prop-types';
import styled from 'styled-components';

import { callFunc } from 'ravenjs/utils';

import { DEFAULT_COLORS } from 'constants/colors';
import MESSAGES from 'constants/messages';

const Container = styled.div`
    font-family: Roboto, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    border: none !important;
`;

const ToastContainer = styled.span`
    font-family: Roboto, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
`;

const LinkContainer = styled.a`
    color: ${DEFAULT_COLORS.WHITE} !important;
    text-decoration: underline;
`;

const CustomToast = props => {
    const { importLinktext, importData, toastType, importDescription, onClickLink } = props;

    const importId = _get(importData, 'name', '');
    const companyId = _get(importData, 'companyId', '');
    const partnerId = _get(importData, 'partnerId', '');
    const importFrom = _get(importData, 'importFrom', '');
    const importBy = _get(importData, 'importBy', '');
    const importName = _get(importData, 'importName', '');

    const getImportDescription = () => {
        return templateReplace(importDescription, {
            importName,
        });
    };

    const getImportUrl = () => {
        let url;
        if (toastType === MESSAGES.BULK_IMPORT_CONSTANT.TOAST_TYPE.SUCCESS) {
            url = `/admin/companies/${partnerId}/${companyId}/import-history`;
        } else if (importFrom === MESSAGES.IMPORT_FROM.CONTACTS) {
            url = `/admin/companies/${importBy}/${companyId}/import/${importFrom}/?importId=${importId}`;
        } else if (importFrom === MESSAGES.IMPORT_FROM.COMPANY) {
            url = `/admin/companies/${partnerId}/import/${importFrom}/?importId=${importId}`;
        } else {
            url = `/admin/companies/${partnerId}/${companyId}/import/${importFrom}/?importId=${importId}`;
        }
        return url;
    };

    return (
        <Container>
            {getImportDescription()}
            <ToastContainer>
                <LinkContainer
                    href={getImportUrl()}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid={`toast-link-${toastType}`}
                    onClick={() => {
                        callFunc(onClickLink, importId);
                    }}
                >
                    {importLinktext}
                </LinkContainer>
            </ToastContainer>
        </Container>
    );
};

CustomToast.propTypes = {
    importData: object,
    importDescription: string,
    importLinktext: string,
    toastType: string,
    onClickLink: func.isRequired,
};
CustomToast.defaultProps = {
    importData: {},
    importDescription: '',
    importLinktext: '',
    toastType: '',
};

export default CustomToast;
