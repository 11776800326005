import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _get from 'lodash/get';

import Row from 'ravenjs/lib/Row';
import Col from 'ravenjs/lib/Col';
import { callFunc } from 'ravenjs/utils';
import SortingButton from 'ravenjs/lib/Sorting/SortingButton';

function Sorting(props) {
    const { options, defaultOptions, onChange, uiSchema } = props;
    const [selectedOption, setSelectedOption] = useState(defaultOptions);
    // Handle the `onChange` for the `AdvanceSearchInput`.
    function handleInputChange(selectedInput) {
        const PreviousSelectedInput = selectedOption;
        setSelectedOption(selectedInput);
        callFunc(onChange, PreviousSelectedInput.order, selectedInput.orderBy);
    }

    const fontSize = _get(uiSchema, 'fontSize', null);
    const maxWidth = _get(uiSchema, 'maxWidth', '40%');

    return (
        <Row>
            <Col size={12} style={{ fontSize, marginLeft: 'auto', maxWidth }}>
                <Row justify="flex-end" alignItems="center">
                    <Col>
                        <SortingButton
                            handleInputChange={handleInputChange}
                            options={options}
                            selectedOption={selectedOption}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

Sorting.propTypes = {
    options: PropTypes.array,
    defaultOptions: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    uiSchema: PropTypes.object,
};

Sorting.defaultProps = {
    options: null,
    defaultOptions: null,
    uiSchema: {},
};

export default Sorting;
