const TODO_LIST = {
    CLICK_ON_TODO_LIST_WIDGET_ENTITY: {
        event: 'ToDo List View',
        eventProperties: {
            action: 'View todo list',
            description: 'User clicks view action item from todo list widget',
            entityId: '',
            entityType: '',
            selectionType: 'viewTodoItem',
        },
    },
    CLICK_ON_TODO_LIST_ENTITY: {
        event: 'ToDo List View',
        eventProperties: {
            description: 'User clicks view action item from todo landing page',
            entityId: '',
            entityType: '',
            selectionType: 'viewTodoItem',
        },
    },
    CLICK_ON_TODO_LIST_COMPLIANCE_WIDGET_ENTITY: {
        event: 'Todo list - only for compliance',
        eventProperties: {
            description: 'User clicks view action item from todo list widget',
            entityId: '',
            entityType: '',
            selectionType: 'viewTodoItem',
        },
    },
    CLICK_ON_TODO_LIST_COMPLIANCE_ENTITY: {
        event: 'Todo list - only for compliance',
        eventProperties: {
            description: 'User clicks view action item from todo landing page',
            entityId: '',
            entityType: '',
            selectionType: 'viewTodoItem',
        },
    },
    CLICK_ON_MY_TODO_LIST_AVATAR_MENU: {
        event: 'ToDo List From Avatar',
        eventProperties: {
            description: 'User accesses to-do via vatar',
        },
    },
    CLICK_ON_APPLY_FILTER: {
        event: 'ToDo List filter option',
        eventProperties: {
            description: 'User applies a filter on the to-do list',
            entityType: '',
        },
    },
    CLICK_ON_RESET_FILTER: {
        event: 'ToDo List filter option',
        eventProperties: {
            description: 'Reset applied filters for todo list',
            entityId: '',
            entityType: '',
        },
    },
    CLICK_ON_VIEW_ALL: {
        event: 'ToDo List View',
        eventProperties: {
            description: 'user accesses to-do list via dashboard widget',
            entityId: '',
            entityType: '',
            selectionType: 'viewAllList',
        },
    },
    CLICK_ON_CATEGORY_TAB_WIDGET: {
        event: 'ToDo List Category tab',
        eventProperties: {
            description: 'User selects a tab on to-do widget',
            entityId: '',
            entityType: '',
            pagesource: 'Widget',
        },
    },
    CLICK_ON_CATEGORY_TAB_LIST: {
        event: 'ToDo List Category tab',
        eventProperties: {
            description: 'User selects a tab on to-do list',
            entityId: '',
            entityType: '',
            pagesource: 'listpage',
        },
    },
    CLICK_ON_TOGGELE_WIDGET: {
        event: 'ToDo List Toggle button',
        eventProperties: {
            description: 'User updates status for a item from to-do widget',
            entityId: '',
            entityType: '',
            pagesource: 'Widget',
        },
    },
    CLICK_ON_TOGGLE_LIST: {
        event: 'ToDo List Toggle button',
        eventProperties: {
            description: 'User updates status for a item from to-do list',
            entityId: '',
            entityType: '',
            pagesource: 'listpage',
        },
    },
};

export default TODO_LIST;
