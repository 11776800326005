import axios from 'axios';

/**
 * API to send events to Pendo
 *
 * @method sendPendoEvent
 * @param  {Object}  data
 * @return {Promise}
 */
export const sendPendoEventAPI = data => {
    return axios({
        method: 'post',
        url: `/v2/pendo-events`,
        data,
    });
};

/**
 * API to send public events to Pendo
 *
 * @method sendPublicPendoEventAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const sendPublicPendoEventAPI = data => {
    return axios({
        method: 'post',
        url: `/v2/pendo-events/public`,
        data,
    });
};

/**
 * API to get chat pendo guide
 *
 * @method getChatPendoGuideAPI
 * @return {Promise}
 */
export const getChatPendoGuideAPI = () => {
    return axios({
        type: 'staticJson',
        ignoreAuthorization: true,
        method: 'get',
        noBearer: true,
        url: `/json/constants.json?cb=${new Date().getTime()}`,
    });
};
