import { takeLatest } from 'redux-saga/effects';

import * as adminActions from '../actions';
import {
    resetPasswordSaga,
    sendResetEmailSaga,
    updatePasswordSaga,
    validateResetPasswordLinkSaga,
} from './forgotPassword';
import {
    activateAffiliateSaga,
    deactivateAffiliateSaga,
    fetchAffiliateSaga,
    fetchAffiliatesSaga,
    updateAffiliateSaga,
} from './affiliates';
import { fetchAuditTrailEventsSaga } from './auditTrail';
import {
    createConfigSaga,
    deleteConfigurationSaga,
    fetchConfigSaga,
    fetchConfigurationsSaga,
    fetchConfigurationTypesSaga,
    fetchEHSProductSaga,
    fetchSkusByConfigurationIdSaga,
    updateConfigSaga,
} from './config';
import {
    changeUserStatusSaga,
    createUserSaga,
    downloadCsvUserTemplateSaga,
    exportUsersCSVSaga,
    fetchDashboardWidgetsSaga,
    fetchUserEmailHistoryPreviewSaga,
    fetchUserSaga,
    fetchUsersSaga,
    importUsersSaga,
    updateDashboardWidgetsSaga,
    updateUserSaga,
} from './user';
import {
    bulkDeactivateCompaniesSaga,
    changeCompanyStatusSaga,
    createCompanySaga,
    deletePrismHRConfigurationSaga,
    fetchAdminOptionsSaga,
    fetchAllConfigurationsForPartnerSaga,
    fetchAllLocationsForCompanySaga,
    fetchClientsSaga,
    fetchCompanyConfigurationSaga,
    fetchCompanyConfigurationsSaga,
    fetchCompanyHeadersSaga,
    fetchCompanySaga,
    fetchCompanyStatusesSaga,
    fetchCompanyTypesSaga,
    fetchContactByTagIdSaga,
    fetchContentDetailsSaga,
    fetchCsvPartnerTemplateSaga,
    fetchCustomFieldsSaga,
    fetchEmployeeCountByCompanyIdSaga,
    fetchEmployeeCountsByStateSaga,
    fetchImportDetailsSaga,
    fetchImportHistoryListSaga,
    fetchIndustryTypesSaga,
    fetchMasterConfigSkusForPartnerSaga,
    fetchNaicsCodesSaga,
    fetchPartnerClientsSaga,
    fetchPartnerLicensingSaga,
    fetchPartnerSaga,
    fetchPartnersSaga,
    fetchPreviewContentDetailsSaga,
    fetchPrismHRConfigurationSaga,
    fetchPrismHRIntegrationDataSaga,
    fetchSignInOptionsSaga,
    fetchSsoApiOptionsSaga,
    fetchSsoConfigurationSaga,
    fetchSsoMappedFieldSaga,
    fetchWelcomeEmailSettingsSaga,
    fetchZipCodeValidateSaga,
    generateAuthenticationCodeSaga,
    importClientsSaga,
    moveCompanySaga,
    resetPrismHRSyncStatusSaga,
    updateAdminOptionsSaga,
    updateCompanySaga,
    updatePartnerLicensingSaga,
    updatePartnerSsoConfigurationSaga,
    updatePrismHRConfigurationSaga,
    updateSsoApiOptionsSaga,
    updateWelcomeEmailSettingsSaga,
    validateBulkUpdateCompanyConfigurationsSaga,
} from './company';
import { fetchInformationBannerSaga } from './content';
import {
    createRoleSaga,
    deleteRoleSaga,
    fetchRoleSaga,
    fetchRolesByPermissionSaga,
    fetchRolesForNewsletterSubscriptionSaga,
    fetchRoleSKUsSaga,
    fetchRolesSaga,
    updateRoleSaga,
} from './roles';

import {
    fetchEmailPreviewSaga,
    fetchImportedContactsListSaga,
    fetchUnpublishedContentPreviewSaga,
    fetchWelcomeEmailIntroSaga,
    fetchWelcomeEmailPreviewSaga,
    sendCommunicationEmailSaga,
    sendWelcomeEmailSaga,
    updateWelcomeEmailSaga,
} from './mail';

import {
    deleteLocationSaga,
    fetchLocationSaga,
    fetchLocationsCsvPartnerTemplateSaga,
    fetchLocationsInstructionsPartnerTemplateSaga,
    importLocationsSaga,
    saveLocationSaga,
} from './location';

import {
    createBannerSaga,
    deleteBannerSaga,
    dismissBannerSaga,
    fetchBannersSaga,
    updateBannerSaga,
} from './banners';

import {
    createSKUSaga,
    fetchLearnPackagesSaga,
    fetchLicensesSaga,
    fetchSKUSaga,
    fetchSKUsSaga,
    updateSKUSaga,
} from './sku';

import {
    fetchCommunicationTypesSaga,
    fetchNewsletterCategoriesSaga,
    updatePageStatusSaga,
    updateUsersNewslettersSubscriptionSaga,
} from './miscellaneous';

import {
    fetchPartnerMigratedFromTHRSaga,
    fetchPartnerOnboardingAndNotificationsSaga,
    fetchPartnerSentCommunicationsSaga,
    fetchPartnerSettingsSaga,
    fetchPartnerUpcomingCommunicationsSaga,
    updatePartnerSelfSettingsSaga,
    updatePartnerUpcomingCommunicationSaga,
} from './partner';
import { forgotUsernameSaga } from './forgotUsername';
import {
    bulkSingleCompanySaga,
    cancelBtcProcessByIdSaga,
    checkBctStatusServicesSaga,
    checkCsvMassUpdateUsersSaga,
    checkCsvMassWelcomeEmailSaga,
    checkCsvMergeMultipleUsersSaga,
    checkMergePartnersSaga,
    downloadCsvListSaga,
    downloadMergeCsvSaga,
    fetchDetailsMultipleCompaniesSaga,
    fetchMassUpdateInformationSaga,
    fetchMassWelcomeEmailInformationSaga,
    fetchMergePartnersInformationSaga,
    fetchMergeUsersInformationSaga,
    fetchMultipleCompaniesUserListSaga,
    fetchSingleCompanyInfoSaga,
    fetchUserListToMultipleCompaniesSaga,
    getDashboardInfoSaga,
    getMassUpdateUserListSaga,
    getMergePartnerCompanyListSaga,
    getMergePartnerUsersListSaga,
    getMergeUserListSaga,
    getMergeUserSaga,
    getMoveCompaniesListSaga,
    getMoveOrMergeSingleCompanyListSaga,
    getWelcomeEmailUsersListSaga,
    mergeSingleUserSaga,
    runMassUpdateSaga,
    runMassWelcomeEmailProcessSaga,
    runMergeMultipleCompaniesSaga,
    runMergeMultipleUsersSaga,
    runMergePartnersProcessSaga,
    runMergeSingleUserSaga,
    runMoveMultipleCompaniesSaga,
    runMoveOrMergeSingleCompanySaga,
    runSprintBatchProcessSaga,
} from './moves';

/**
 * Root saga for `admin`
 * Triggers sagas related to all admin events
 *
 * @method adminSaga
 * @type   {Generator}
 */
function* adminSaga() {
    yield takeLatest(adminActions.fetchUser.TRIGGER, fetchUserSaga);
    yield takeLatest(adminActions.changeUserStatus.TRIGGER, changeUserStatusSaga);
    yield takeLatest(adminActions.updateUser.TRIGGER, updateUserSaga);
    yield takeLatest(adminActions.createUser.TRIGGER, createUserSaga);
    yield takeLatest(adminActions.importUsers.TRIGGER, importUsersSaga);
    yield takeLatest(adminActions.importLocations.TRIGGER, importLocationsSaga);
    yield takeLatest(
        adminActions.fetchLocationsCsvPartnerTemplate.TRIGGER,
        fetchLocationsCsvPartnerTemplateSaga
    );
    yield takeLatest(
        adminActions.fetchLocationsInstructionsPartnerTemplate.TRIGGER,
        fetchLocationsInstructionsPartnerTemplateSaga
    );
    yield takeLatest(
        adminActions.fetchUserEmailHistoryPreview.TRIGGER,
        fetchUserEmailHistoryPreviewSaga
    );
    yield takeLatest(adminActions.fetchConfig.TRIGGER, fetchConfigSaga);
    yield takeLatest(adminActions.updateConfig.TRIGGER, updateConfigSaga);
    yield takeLatest(adminActions.createConfig.TRIGGER, createConfigSaga);
    yield takeLatest(
        adminActions.fetchCompanyConfigurations.TRIGGER,
        fetchCompanyConfigurationsSaga
    );
    yield takeLatest(adminActions.fetchCompanyConfiguration.TRIGGER, fetchCompanyConfigurationSaga);
    yield takeLatest(adminActions.fetchConfigurations.TRIGGER, fetchConfigurationsSaga);
    yield takeLatest(adminActions.fetchConfigurationTypes.TRIGGER, fetchConfigurationTypesSaga);
    yield takeLatest(adminActions.fetchPartner.TRIGGER, fetchPartnerSaga);
    yield takeLatest(adminActions.createCompany.TRIGGER, createCompanySaga);
    yield takeLatest(adminActions.fetchCompany.TRIGGER, fetchCompanySaga);
    yield takeLatest(adminActions.updateCompany.TRIGGER, updateCompanySaga);
    yield takeLatest(adminActions.changeCompanyStatus.TRIGGER, changeCompanyStatusSaga);
    yield takeLatest(adminActions.fetchCompanyHeaders.TRIGGER, fetchCompanyHeadersSaga);
    yield takeLatest(adminActions.fetchNaicsCodes.TRIGGER, fetchNaicsCodesSaga);
    yield takeLatest(adminActions.fetchZipCodeValidate.TRIGGER, fetchZipCodeValidateSaga);
    yield takeLatest(adminActions.fetchIndustryTypes.TRIGGER, fetchIndustryTypesSaga);
    yield takeLatest(adminActions.fetchWelcomeEmailSettings.TRIGGER, fetchWelcomeEmailSettingsSaga);
    yield takeLatest(
        adminActions.updateWelcomeEmailSettings.TRIGGER,
        updateWelcomeEmailSettingsSaga
    );
    yield takeLatest(
        adminActions.fetchAllLocationsForCompany.TRIGGER,
        fetchAllLocationsForCompanySaga
    );
    yield takeLatest(
        adminActions.fetchEmployeeCountsByState.TRIGGER,
        fetchEmployeeCountsByStateSaga
    );
    yield takeLatest(adminActions.fetchPartnerClients.TRIGGER, fetchPartnerClientsSaga);
    yield takeLatest(
        adminActions.fetchAllConfigurationsForPartner.TRIGGER,
        fetchAllConfigurationsForPartnerSaga
    );
    yield takeLatest(
        adminActions.validateBulkUpdateCompanyConfigurations.TRIGGER,
        validateBulkUpdateCompanyConfigurationsSaga
    );
    yield takeLatest(adminActions.fetchPrismHRConfiguration.TRIGGER, fetchPrismHRConfigurationSaga);
    yield takeLatest(
        adminActions.updatePrismHRConfiguration.TRIGGER,
        updatePrismHRConfigurationSaga
    );
    yield takeLatest(
        adminActions.fetchPrismHRIntegrationData.TRIGGER,
        fetchPrismHRIntegrationDataSaga
    );
    yield takeLatest(adminActions.resetPrismHRSyncStatus.TRIGGER, resetPrismHRSyncStatusSaga);
    yield takeLatest(adminActions.fetchCsvPartnerTemplate.TRIGGER, fetchCsvPartnerTemplateSaga);
    yield takeLatest(adminActions.importClients.TRIGGER, importClientsSaga);
    yield takeLatest(adminActions.fetchClients.TRIGGER, fetchClientsSaga);
    yield takeLatest(adminActions.fetchUsers.TRIGGER, fetchUsersSaga);
    yield takeLatest(adminActions.fetchPartnerLicensing.TRIGGER, fetchPartnerLicensingSaga);
    yield takeLatest(adminActions.updatePartnerLicensing.TRIGGER, updatePartnerLicensingSaga);
    yield takeLatest(adminActions.deleteLocation.TRIGGER, deleteLocationSaga);
    yield takeLatest(adminActions.fetchLocation.TRIGGER, fetchLocationSaga);
    yield takeLatest(adminActions.saveLocation.TRIGGER, saveLocationSaga);
    yield takeLatest(adminActions.fetchImportedContactsList.TRIGGER, fetchImportedContactsListSaga);
    yield takeLatest(
        adminActions.generateAuthenticationCode.TRIGGER,
        generateAuthenticationCodeSaga
    );
    yield takeLatest(adminActions.fetchSsoMappedField.TRIGGER, fetchSsoMappedFieldSaga);
    yield takeLatest(adminActions.fetchSsoConfiguration.TRIGGER, fetchSsoConfigurationSaga);
    yield takeLatest(
        adminActions.updatePartnerSsoConfiguration.TRIGGER,
        updatePartnerSsoConfigurationSaga
    );
    yield takeLatest(adminActions.fetchSsoApiOptions.TRIGGER, fetchSsoApiOptionsSaga);
    yield takeLatest(adminActions.updateSsoApiOptions.TRIGGER, updateSsoApiOptionsSaga);
    yield takeLatest(adminActions.fetchAuditTrailEvents.TRIGGER, fetchAuditTrailEventsSaga);
    yield takeLatest(
        adminActions.fetchMasterConfigSkusForPartner.TRIGGER,
        fetchMasterConfigSkusForPartnerSaga
    );
    yield takeLatest(adminActions.deleteConfiguration.TRIGGER, deleteConfigurationSaga);
    yield takeLatest(adminActions.fetchPartners.TRIGGER, fetchPartnersSaga);
    yield takeLatest(adminActions.fetchSignInOptions, fetchSignInOptionsSaga);
    yield takeLatest(adminActions.createRole.TRIGGER, createRoleSaga);
    yield takeLatest(adminActions.deleteRole.TRIGGER, deleteRoleSaga);
    yield takeLatest(adminActions.fetchRoles.TRIGGER, fetchRolesSaga);
    yield takeLatest(adminActions.fetchRole.TRIGGER, fetchRoleSaga);
    yield takeLatest(adminActions.fetchRoleSKUs.TRIGGER, fetchRoleSKUsSaga);
    yield takeLatest(adminActions.updateRole.TRIGGER, updateRoleSaga);
    yield takeLatest(adminActions.fetchCompanyTypes.TRIGGER, fetchCompanyTypesSaga);
    yield takeLatest(adminActions.fetchCompanyStatuses.TRIGGER, fetchCompanyStatusesSaga);
    yield takeLatest(adminActions.bulkDeactivateCompanies.TRIGGER, bulkDeactivateCompaniesSaga);
    yield takeLatest(adminActions.downloadCsvUserTemplate.TRIGGER, downloadCsvUserTemplateSaga);
    yield takeLatest(adminActions.createSKU.TRIGGER, createSKUSaga);
    yield takeLatest(adminActions.fetchLearnPackages.TRIGGER, fetchLearnPackagesSaga);
    yield takeLatest(adminActions.fetchSKU.TRIGGER, fetchSKUSaga);
    yield takeLatest(adminActions.fetchSKUs.TRIGGER, fetchSKUsSaga);
    yield takeLatest(adminActions.updateSKU.TRIGGER, updateSKUSaga);
    yield takeLatest(adminActions.sendResetEmail.TRIGGER, sendResetEmailSaga);
    yield takeLatest(adminActions.resetPassword.TRIGGER, resetPasswordSaga);
    yield takeLatest(adminActions.validateResetPasswordLink.TRIGGER, validateResetPasswordLinkSaga);
    yield takeLatest(adminActions.fetchBanners.TRIGGER, fetchBannersSaga);
    yield takeLatest(
        adminActions.fetchRolesForNewsletterSubscription.TRIGGER,
        fetchRolesForNewsletterSubscriptionSaga
    );
    yield takeLatest(adminActions.fetchNewsletterCategories.TRIGGER, fetchNewsletterCategoriesSaga);
    yield takeLatest(adminActions.updateBanner.TRIGGER, updateBannerSaga);
    yield takeLatest(adminActions.createBanner.TRIGGER, createBannerSaga);
    yield takeLatest(adminActions.deleteBanner.TRIGGER, deleteBannerSaga);
    yield takeLatest(adminActions.dismissBanner.TRIGGER, dismissBannerSaga);
    yield takeLatest(adminActions.exportUsersCSV.TRIGGER, exportUsersCSVSaga);
    yield takeLatest(
        adminActions.updateUsersNewslettersSubscription.TRIGGER,
        updateUsersNewslettersSubscriptionSaga
    );
    yield takeLatest(adminActions.fetchWelcomeEmailPreview.TRIGGER, fetchWelcomeEmailPreviewSaga);
    yield takeLatest(adminActions.sendWelcomeEmail.TRIGGER, sendWelcomeEmailSaga);
    yield takeLatest(adminActions.fetchCustomFields.TRIGGER, fetchCustomFieldsSaga);
    yield takeLatest(adminActions.moveCompany.TRIGGER, moveCompanySaga);
    yield takeLatest(adminActions.fetchContentDetails.TRIGGER, fetchContentDetailsSaga);
    yield takeLatest(
        adminActions.fetchPreviewContentDetails.TRIGGER,
        fetchPreviewContentDetailsSaga
    );
    yield takeLatest(adminActions.fetchContactByTagId.TRIGGER, fetchContactByTagIdSaga);
    yield takeLatest(adminActions.fetchInformationBanner.TRIGGER, fetchInformationBannerSaga);
    yield takeLatest(adminActions.fetchAffiliates.TRIGGER, fetchAffiliatesSaga);
    yield takeLatest(adminActions.fetchAffiliate.TRIGGER, fetchAffiliateSaga);
    yield takeLatest(adminActions.updateAffiliate.TRIGGER, updateAffiliateSaga);
    yield takeLatest(adminActions.activateAffiliate.TRIGGER, activateAffiliateSaga);
    yield takeLatest(adminActions.deactivateAffiliate.TRIGGER, deactivateAffiliateSaga);
    yield takeLatest(adminActions.fetchDashboardWidgets.TRIGGER, fetchDashboardWidgetsSaga);
    yield takeLatest(adminActions.updateDashboardWidgets.TRIGGER, updateDashboardWidgetsSaga);
    yield takeLatest(
        adminActions.fetchPartnerSentCommunications.TRIGGER,
        fetchPartnerSentCommunicationsSaga
    );
    yield takeLatest(
        adminActions.fetchPartnerUpcomingCommunications.TRIGGER,
        fetchPartnerUpcomingCommunicationsSaga
    );
    yield takeLatest(
        adminActions.updatePartnerUpcomingCommunication.TRIGGER,
        updatePartnerUpcomingCommunicationSaga
    );
    yield takeLatest(adminActions.fetchWelcomeEmailIntro.TRIGGER, fetchWelcomeEmailIntroSaga);
    yield takeLatest(adminActions.updateWelcomeEmail.TRIGGER, updateWelcomeEmailSaga);
    yield takeLatest(adminActions.fetchPartnerSettings.TRIGGER, fetchPartnerSettingsSaga);
    yield takeLatest(adminActions.updatePartnerSelfSettings.TRIGGER, updatePartnerSelfSettingsSaga);
    yield takeLatest(adminActions.sendCommunicationEmail.TRIGGER, sendCommunicationEmailSaga);
    yield takeLatest(adminActions.fetchCommunicationTypes.TRIGGER, fetchCommunicationTypesSaga);
    yield takeLatest(adminActions.fetchRolesByPermission.TRIGGER, fetchRolesByPermissionSaga);
    yield takeLatest(adminActions.fetchEmailPreview.TRIGGER, fetchEmailPreviewSaga);
    yield takeLatest(
        adminActions.fetchUnpublishedContentPreview.TRIGGER,
        fetchUnpublishedContentPreviewSaga
    );
    yield takeLatest(
        adminActions.fetchPartnerOnboardingAndNotifications.TRIGGER,
        fetchPartnerOnboardingAndNotificationsSaga
    );
    yield takeLatest(
        adminActions.fetchPartnerMigratedFromTHR.TRIGGER,
        fetchPartnerMigratedFromTHRSaga
    );
    yield takeLatest(adminActions.updatePassword.TRIGGER, updatePasswordSaga);
    yield takeLatest(adminActions.forgotUsername.TRIGGER, forgotUsernameSaga);
    yield takeLatest(adminActions.fetchEHSProducts.TRIGGER, fetchEHSProductSaga);
    yield takeLatest(adminActions.getDashboardInfo.TRIGGER, getDashboardInfoSaga);
    yield takeLatest(adminActions.getMergeUserList.TRIGGER, getMergeUserListSaga);
    yield takeLatest(adminActions.getMergeUser.TRIGGER, getMergeUserSaga);
    yield takeLatest(adminActions.checkCsvMassUpdateUsers.TRIGGER, checkCsvMassUpdateUsersSaga);
    yield takeLatest(
        adminActions.checkCsvMergeMultipleUsers.TRIGGER,
        checkCsvMergeMultipleUsersSaga
    );
    yield takeLatest(adminActions.downloadMergeCsv.TRIGGER, downloadMergeCsvSaga);
    yield takeLatest(adminActions.runMergeMultipleUsers.TRIGGER, runMergeMultipleUsersSaga);
    yield takeLatest(
        adminActions.fetchMergeUsersInformation.TRIGGER,
        fetchMergeUsersInformationSaga
    );
    yield takeLatest(adminActions.getMassUpdateUserList.TRIGGER, getMassUpdateUserListSaga);
    yield takeLatest(adminActions.mergeSingleUser.TRIGGER, mergeSingleUserSaga);
    yield takeLatest(adminActions.runMergeSingleUser.TRIGGER, runMergeSingleUserSaga);
    yield takeLatest(adminActions.runMassUpdate.TRIGGER, runMassUpdateSaga);
    yield takeLatest(adminActions.runSprintBatchProcess.TRIGGER, runSprintBatchProcessSaga);
    yield takeLatest(adminActions.runMergeMultipleCompanies.TRIGGER, runMergeMultipleCompaniesSaga);
    yield takeLatest(
        adminActions.fetchDetailsMultipleCompanies.TRIGGER,
        fetchDetailsMultipleCompaniesSaga
    );
    yield takeLatest(adminActions.bulkSingleCompany.TRIGGER, bulkSingleCompanySaga);
    yield takeLatest(adminActions.checkCsvMassWelcomeEmail.TRIGGER, checkCsvMassWelcomeEmailSaga);
    yield takeLatest(adminActions.getWelcomeEmailUsersList.TRIGGER, getWelcomeEmailUsersListSaga);
    yield takeLatest(
        adminActions.fetchMassUpdateInformation.TRIGGER,
        fetchMassUpdateInformationSaga
    );
    yield takeLatest(
        adminActions.runMassWelcomeEmailProcess.TRIGGER,
        runMassWelcomeEmailProcessSaga
    );
    yield takeLatest(adminActions.fetchSingleCompanyInfo.TRIGGER, fetchSingleCompanyInfoSaga);
    yield takeLatest(
        adminActions.fetchMultipleCompaniesUserList.TRIGGER,
        fetchMultipleCompaniesUserListSaga
    );
    yield takeLatest(
        adminActions.runMoveOrMergeSingleCompany.TRIGGER,
        runMoveOrMergeSingleCompanySaga
    );
    yield takeLatest(
        adminActions.getMoveOrMergeSingleCompanyList.TRIGGER,
        getMoveOrMergeSingleCompanyListSaga
    );
    yield takeLatest(adminActions.getMoveCompaniesList.TRIGGER, getMoveCompaniesListSaga);
    yield takeLatest(
        adminActions.fetchUserListToMultipleCompanies.TRIGGER,
        fetchUserListToMultipleCompaniesSaga
    );
    yield takeLatest(adminActions.runMoveMultipleCompanies.TRIGGER, runMoveMultipleCompaniesSaga);
    yield takeLatest(adminActions.checkMergePartners.TRIGGER, checkMergePartnersSaga);
    yield takeLatest(
        adminActions.fetchMergePartnersInformation.TRIGGER,
        fetchMergePartnersInformationSaga
    );
    yield takeLatest(
        adminActions.getMergePartnerCompanyList.TRIGGER,
        getMergePartnerCompanyListSaga
    );
    yield takeLatest(adminActions.getMergePartnerUsersList.TRIGGER, getMergePartnerUsersListSaga);
    yield takeLatest(adminActions.runMergePartnersProcess.TRIGGER, runMergePartnersProcessSaga);
    yield takeLatest(
        adminActions.fetchEmployeeCountByCompanyId.TRIGGER,
        fetchEmployeeCountByCompanyIdSaga
    );
    yield takeLatest(adminActions.cancelBtcProcessById.TRIGGER, cancelBtcProcessByIdSaga);
    yield takeLatest(adminActions.checkBctStatusServices.TRIGGER, checkBctStatusServicesSaga);
    yield takeLatest(adminActions.downloadCsvList.TRIGGER, downloadCsvListSaga);
    yield takeLatest(
        adminActions.fetchMassWelcomeEmailInformation.TRIGGER,
        fetchMassWelcomeEmailInformationSaga
    );
    yield takeLatest(
        adminActions.fetchSkusByConfigurationId.TRIGGER,
        fetchSkusByConfigurationIdSaga
    );
    yield takeLatest(adminActions.fetchLicenses.TRIGGER, fetchLicensesSaga);
    yield takeLatest(adminActions.updatePageStatus.TRIGGER, updatePageStatusSaga);
    yield takeLatest(
        adminActions.deletePrismHRConfiguration.TRIGGER,
        deletePrismHRConfigurationSaga
    );
    yield takeLatest(adminActions.fetchImportHistoryList.TRIGGER, fetchImportHistoryListSaga);
    yield takeLatest(adminActions.fetchImportDetails.TRIGGER, fetchImportDetailsSaga);
    yield takeLatest(adminActions.fetchAdminOptions.TRIGGER, fetchAdminOptionsSaga);
    yield takeLatest(adminActions.updateAdminOptions.TRIGGER, updateAdminOptionsSaga);
}

export default adminSaga;

export {
    bulkDeactivateCompaniesSaga,
    changeCompanyStatusSaga,
    changeUserStatusSaga,
    createCompanySaga,
    createConfigSaga,
    createUserSaga,
    downloadCsvUserTemplateSaga,
    fetchAllConfigurationsForPartnerSaga,
    fetchAllLocationsForCompanySaga,
    fetchEmployeeCountsByStateSaga,
    fetchAuditTrailEventsSaga,
    fetchClientsSaga,
    fetchCompanyTypesSaga,
    fetchPartnerSaga,
    fetchPartnersSaga,
    fetchCompanyConfigurationSaga,
    fetchCompanyConfigurationsSaga,
    fetchCompanyHeadersSaga,
    fetchCompanySaga,
    fetchCompanyStatusesSaga,
    fetchConfigSaga,
    fetchCsvPartnerTemplateSaga,
    fetchCustomFieldsSaga,
    fetchImportedContactsListSaga,
    fetchIndustryTypesSaga,
    fetchLocationSaga,
    fetchNaicsCodesSaga,
    fetchPartnerClientsSaga,
    fetchPartnerLicensingSaga,
    fetchSsoConfigurationSaga,
    fetchRolesSaga,
    fetchPrismHRConfigurationSaga,
    fetchPrismHRIntegrationDataSaga,
    resetPrismHRSyncStatusSaga,
    fetchSsoMappedFieldSaga,
    fetchUserSaga,
    fetchUserEmailHistoryPreviewSaga,
    fetchUsersSaga,
    fetchWelcomeEmailSettingsSaga,
    fetchZipCodeValidateSaga,
    generateAuthenticationCodeSaga,
    importClientsSaga,
    importUsersSaga,
    importLocationsSaga,
    resetPasswordSaga,
    saveLocationSaga,
    sendResetEmailSaga,
    updateCompanySaga,
    updateConfigSaga,
    updatePartnerLicensingSaga,
    updatePartnerSsoConfigurationSaga,
    updatePrismHRConfigurationSaga,
    updateUserSaga,
    updateWelcomeEmailSettingsSaga,
    validateBulkUpdateCompanyConfigurationsSaga,
    validateResetPasswordLinkSaga,
    fetchWelcomeEmailPreviewSaga,
    fetchContentDetailsSaga,
    fetchPreviewContentDetailsSaga,
    sendWelcomeEmailSaga,
    fetchEHSProductSaga,
    getDashboardInfoSaga,
    getMergeUserListSaga,
    getMergeUserSaga,
    checkCsvMassUpdateUsersSaga,
    checkCsvMergeMultipleUsersSaga,
    downloadMergeCsvSaga,
    runMergeMultipleUsersSaga,
    fetchMergeUsersInformationSaga,
    getMassUpdateUserListSaga,
    mergeSingleUserSaga,
    runMergeSingleUserSaga,
    runMassUpdateSaga,
    runSprintBatchProcessSaga,
    runMergeMultipleCompaniesSaga,
    fetchDetailsMultipleCompaniesSaga,
    bulkSingleCompanySaga,
    checkCsvMassWelcomeEmailSaga,
    getWelcomeEmailUsersListSaga,
    fetchMassUpdateInformationSaga,
    runMassWelcomeEmailProcessSaga,
    fetchSingleCompanyInfoSaga,
    fetchMultipleCompaniesUserListSaga,
    runMoveOrMergeSingleCompanySaga,
    getMoveOrMergeSingleCompanyListSaga,
    getMoveCompaniesListSaga,
    fetchUserListToMultipleCompaniesSaga,
    runMoveMultipleCompaniesSaga,
    checkMergePartnersSaga,
    fetchMergePartnersInformationSaga,
    getMergePartnerCompanyListSaga,
    getMergePartnerUsersListSaga,
    runMergePartnersProcessSaga,
    fetchEmployeeCountByCompanyIdSaga,
    cancelBtcProcessByIdSaga,
    checkBctStatusServicesSaga,
    downloadCsvListSaga,
    fetchMassWelcomeEmailInformationSaga,
    fetchSkusByConfigurationIdSaga,
    updatePageStatusSaga,
    deletePrismHRConfigurationSaga,
    fetchImportHistoryListSaga,
    fetchAdminOptionsSaga,
    updateAdminOptionsSaga,
};
