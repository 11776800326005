import { createActions } from 'utils/actions';

export const fetchSurveys = createActions('surveys', 'FETCH_SURVEYS', {
    asPromise: true,
});

export const fetchSurvey = createActions('surveys', 'FETCH_SURVEY', {
    asPromise: true,
});

export const fetchSurveyResponse = createActions('surveys', 'FETCH_SURVEY_RESPONSE', {
    asPromise: true,
});

export const fetchSurveyResponses = createActions('surveys', 'FETCH_SURVEY_RESPONSES', {
    asPromise: true,
});
