import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeWebinarsIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path fill={color} d="M0 0H14V14H0z" />
                        <path
                            fill={colorInner}
                            d="M5.418 9.401V4.606L9.569 7 5.418 9.401zm.7-3.584v2.366L8.169 7 6.118 5.817zM0 2.24H14V3.24H0zM0 11.06H14V12.06H0z"
                        />
                        <path
                            fill={colorInner}
                            d="M1.974 0H2.974V2.59H1.974zM5.089 0H6.089V2.59H5.089zM8.211 0H9.211V2.59H8.211zM11.333 0H12.333V2.59H11.333zM1.974 11.403H2.974V13.993H1.974zM5.089 11.403H6.089V13.993H5.089zM8.211 11.403H9.211V13.993H8.211zM11.333 11.403H12.333V13.993H11.333z"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeWebinarsIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeWebinarsIcon.defaultProps = {
    color: `${DEFAULT_COLORS.BLUE}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypeWebinarsIcon;
