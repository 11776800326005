import { get } from 'ravenjs/utils';

import { DEFAULT_COLORS } from 'constants/colors';

const getSelectStyles = (
    {
        borderColor = DEFAULT_COLORS.GREY_16,
        borderRadius = '2px',
        borderWidth = '1px',
        customMenuPosition,
        maxHeightMenuList = '300px',
        maxWidth,
        minHeight = '38px',
        minWidth,
        width,
    } = {},
    styles = {},
    showRequired = false
) => {
    return {
        clearIndicator: (provided, state) => {
            return {
                ...provided,
                cursor: 'pointer',
                ...get(styles, 'clearIndicator', {}),
            };
        },
        container: (provided, state) => {
            return {
                ...provided,
                border: 'none',
                font: '500 0.875rem/1.25rem Roboto',
                ...get(styles, 'container', {}),
            };
        },
        control: (provided, state) => {
            const { isDisabled } = state;
            const borderColor = showRequired ? DEFAULT_COLORS.RED : DEFAULT_COLORS.GREY_16;
            return {
                ...provided,
                backgroundColor: isDisabled
                    ? DEFAULT_COLORS.COMPONENTS_DISABLED
                    : DEFAULT_COLORS.WHITE,
                border: `${borderWidth} solid ${borderColor}`,
                borderRadius,
                boxShadow: 'none',
                cursor: 'pointer',
                maxWidth,
                minHeight,
                minWidth,
                padding: '0 4px 0 12px',
                width,
                '&:hover': {
                    borderColor,
                },
                ...get(styles, 'control', {}),
            };
        },
        dropdownIndicator: (provided, state) => {
            return {
                ...provided,
                cursor: 'pointer',
                ...get(styles, 'dropdownIndicator', {}),
            };
        },
        indicatorSeparator: (provided, state) => {
            return {
                ...provided,
                display: 'none',
                ...get(styles, 'indicatorSeparator', {}),
            };
        },
        input: (provided, state) => {
            return {
                ...provided,
                color: DEFAULT_COLORS.BLACK,
                input: {
                    height: 'auto',
                },
                minWidth: '1rem',
                margin: 0,
                padding: 0,
                ...get(styles, 'input', {}),
            };
        },
        loadingMessage: (provided, state) => {
            return {
                ...provided,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                minHeight,
                ...get(styles, 'loadingMessage', {}),
            };
        },
        menu: (provided, state) => {
            return {
                ...provided,
                border: `${borderWidth} solid ${borderColor}`,
                borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
                boxShadow: 'none',
                marginTop: '-1px',
                marginBottom: '-1px',
                position: customMenuPosition || 'absolute',
                zIndex: 1000,
                ...get(styles, 'menu', {}),
            };
        },
        menuList: (provided, state) => {
            return {
                ...provided,
                fontSize: '0.875rem',
                maxHeight: maxHeightMenuList,
                minHeight,
                ...get(styles, 'menuList', {}),
            };
        },
        multiValue: (provided, state) => {
            return {
                ...provided,
                margin: '2px 2px 2px 0',
                ...get(styles, 'multiValue', {}),
            };
        },
        multiValueLabel: (provided, state) => {
            return {
                ...provided,
                padding: '2px',
                ...get(styles, 'multiValueLabel', {}),
            };
        },
        multiValueRemove: (provided, state) => {
            return {
                ...provided,
                cursor: 'pointer',
                ...get(styles, 'multiValueRemove', {}),
            };
        },
        noOptionsMessage: (provided, state) => {
            return {
                ...provided,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                minHeight,
                ...get(styles, 'noOptionsMessage', {}),
            };
        },
        option: (provided, state) => {
            const { isSelected, data } = state;
            const disableListItem = get(data, 'disableListItem', false);

            return {
                ...provided,
                backgroundColor: DEFAULT_COLORS.WHITE,
                border: 'none',
                color: disableListItem ? DEFAULT_COLORS.GREY_10 : DEFAULT_COLORS.BLACK,
                cursor: disableListItem ? 'not-allowed' : 'pointer',
                pointerEvents: disableListItem ? 'none' : 'auto',
                font: '400 0.875rem/1.25rem Roboto',
                fontWeight: isSelected ? 500 : 400,
                padding: '8px 22px',
                '&:hover': {
                    backgroundColor: DEFAULT_COLORS.LIGHTEST,
                },
                '&:active': {
                    backgroundColor: DEFAULT_COLORS.LIGHTEST,
                    fontWeight: state.isDisabled ? 400 : 500,
                },
                ...get(styles, 'option', {}),
            };
        },
        placeholder: (provided, state) => {
            return {
                ...provided,
                color: showRequired ? DEFAULT_COLORS.RED : DEFAULT_COLORS.PLACEHOLDER,
                margin: 0,
                ...get(styles, 'placeholder', {}),
            };
        },
        singleValue: (provided, state) => {
            return {
                ...provided,
                color: DEFAULT_COLORS.BLACK,
                ...get(styles, 'singleValue', {}),
            };
        },
        valueContainer: (provided, state) => {
            return {
                ...provided,
                minWidth,
                padding: 0,
                ...get(styles, 'valueContainer', {}),
            };
        },
    };
};

export default getSelectStyles;
