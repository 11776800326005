import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the company's branding.
 *
 * @type {Object}
 */
export const fetchBranding = createActions('branding', 'FETCH_BRANDING', {
    asPromise: true,
});

/**
 * Redux actions for creating the partner branding
 *
 * @type {Object}
 */
export const createPartnerBranding = createActions('branding', 'CREATE_PARTNER_BRANDING', {
    asPromise: true,
});

/**
 * Redux actions for updating the partner branding
 *
 * @type {Object}
 */
export const updatePartnerBranding = createActions('branding', 'UPDATE_PARTNER_BRANDING', {
    asPromise: true,
});

/**
 * Redux actions for updating the partner branding
 *
 * @type {Object}
 */
export const uploadPartnerBrandingFile = createActions('branding', 'UPLOAD_PARTNER_BRANDING_FILE', {
    asPromise: true,
});

/**
 * Redux actions for deleting the partner branding
 *
 * @type {Object}
 */
export const resetPartnerBranding = createActions('branding', 'RESET_PARTNER_BRANDING', {
    asPromise: true,
});

/**
 * Redux actions for deleting the partner branding
 *
 * @type {Object}
 */
export const deletePartnerBranding = createActions('branding', 'DELETE_PARTNER_BRANDING', {
    asPromise: true,
});

/**
 * Redux actions for fetching the partner's branding by domain
 *
 * @type {Object}
 */
export const fetchBrandingByDomain = createActions('branding', 'FETCH_BRANDING_BY_DOMAIN', {
    asPromise: true,
});

/**
 * Redux actions for fetching the partner's Custom Login Widget settings.
 *
 * @type {Object}
 */
export const fetchCustomLoginWidget = createActions('branding', 'FETCH_CUSTOM_LOGIN_WIDGET', {
    asPromise: true,
});

/**
 * Redux actions for updating the partner's Custom Login Widget settings.
 *
 * @type {Object}
 */
export const updateCustomLoginWidget = createActions('branding', 'UPDATE_CUSTOM_LOGIN_WIDGET', {
    asPromise: true,
});

/**
 * Redux actions for resetting the partner's Custom Login Widget settings.
 *
 * @type {Object}
 */
export const resetCustomLoginWidget = createActions('branding', 'RESET_CUSTOM_LOGIN_WIDGET', {
    asPromise: true,
});

/**
 * Redux actions for fetching the default Custom Login Widget settings.
 *
 * @type {Object}
 */
export const fetchDefaultCustomLoginWidget = createActions(
    'branding',
    'FETCH_DEFAULT_CUSTOM_LOGIN_WIDGET',
    {
        asPromise: true,
    }
);

/**
 * Redux action for setting the "temporary branding theme is currently in use" state
 *
 * @type {Object}
 */
export const setIsUsingTemporaryTheme = createActions('branding', 'SET_IS_USING_TEMPORARY_THEME', {
    asPromise: false,
});

/**
 * Redux actions for updating the partner upgrade ad logo
 *
 * @type {Object}
 */
export const uploadPartnerUpgradeAdLogoFile = createActions(
    'branding',
    'UPLOAD_PARTNER_UPGRADE_LOGO_FILE',
    {
        asPromise: true,
    }
);
