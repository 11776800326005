import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'ravenjs/constants/colors';
import ButtonLink from 'ravenjs/lib/Button/ButtonLink';
import Tooltip from 'ravenjs/lib/Tooltip';
import { isEmpty } from 'ravenjs/utils';
import LinkTextToolTip from 'ravenjs/lib/Editor/LinkTextToolTip';
import EditorContext from 'ravenjs/lib/Editor/EditorContext';

export const linkStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
};

export const Link = props => {
    const { children, contentState, entityKey, decoratedText, offsetKey } = props;
    const keys = offsetKey.split('-');
    const blockKey = keys[0];
    const anchorOffset = !isEmpty(children) ? children[0].props.start : '';
    const { getLinkToolTipContent } = useContext(EditorContext);
    const { url, openNewTab } = contentState.getEntity(entityKey).getData();
    const toolTipContents = getLinkToolTipContent(url);
    const [isOpen, setIsOpen] = useState(false);
    const handleToolTipClick = () => {
        setIsOpen(!isOpen);
    };

    const toolTipProps = {
        toolTipContents,
        url,
        anchorOffset,
        contentState,
        blockKey,
        entityKey,
        selectedText: decoratedText,
        openNewTab,
        handleToolTipClick,
    };

    const handleTooltipClose = event => {
        if (!event.relatedTarget.querySelector('#tooltip')) {
            setIsOpen(false);
        }
    };

    const onMouseDown = e => {
        e.preventDefault();
        handleToolTipClick();
    };

    return (
        <Tooltip
            arrowSize={0}
            background={COLORS.GREY_9}
            margin={0}
            maxWidth="600px"
            padding="16px"
            tooltip={{ id: 'tooltipComp' }}
            tooltipProps={toolTipProps}
            tooltipsList={{ tooltipComp: LinkTextToolTip }}
            placement="bottom"
            open={isOpen}
            onClose={handleTooltipClose}
            disableFocusListener
            TransitionProps={{ onMouseLeave: () => setIsOpen(false) }}
        >
            <ButtonLink onMouseDown={onMouseDown} className="link">
                {children}
            </ButtonLink>
        </Tooltip>
    );
};

Link.propTypes = {
    children: PropTypes.array,
    contentState: PropTypes.object,
    entityKey: PropTypes.string,
    decoratedText: PropTypes.string,
    offsetKey: PropTypes.string,
};

Link.defaultProps = {
    children: null,
    contentState: {},
    entityKey: null,
    decoratedText: '',
    offsetKey: '',
};

const addLinkPlugin = {
    strategy: linkStrategy,
    component: Link,
};

export default addLinkPlugin;
