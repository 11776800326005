import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import styled from 'styled-components';

import Fade from 'ravenjs/lib/Fade';
import Popper from 'ravenjs/lib/Popper';
import RootRef from 'ravenjs/lib/RootRef';
import { CloseIcon } from 'ravenjs/lib/SvgIcon';
import { getThemeProps } from 'ravenjs/utils/theme';
import { isReactComponent } from 'ravenjs/utils/component';
import { renderCheck } from 'ravenjs/utils/viewport';

import TooltipStyled from './TooltipStyled';

const CloseIconStyled = styled(CloseIcon)`
    float: right;
    margin-left: 0.3rem;
    mix-blend-mode: difference;
    height: 12px;
    width: 12px;
`;

class Tooltip extends React.Component {
    // If we have a controlled Tooltip.
    isControlled = null;

    // Reference to the Tooltip's children.
    childRef = null;

    static propTypes = {
        /**
         * Properties passed down to the `Popper.js` component.
         */
        PopperProps: PropTypes.object,
        /**
         * A Transition Component.
         */
        TransitionComponent: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.object,
        ]),
        /**
         * Properties passed down to the `TransitionComponent` component.
         */
        TransitionProps: PropTypes.object,
        /**
         * The size of the Tooltip Arrow.
         */
        arrowSize: PropTypes.number,
        background: PropTypes.string,
        /**
         * The main reference component.
         */
        children: PropTypes.node,
        /**
         * Apply themed styling to Tooltip.
         *
         * Colors can be defined in `theme.palette`.
         */
        color: PropTypes.string,
        /**
         * The main content of the Tooltip
         */
        content: PropTypes.node,
        /**
         * Do not respond to focus events.
         */
        disableFocusListener: PropTypes.bool,
        /**
         * Do not respond to hover events.
         */
        disableHoverListener: PropTypes.bool,
        /**
         * The number of milliseconds to wait before showing the Tooltip.
         */
        enterDelay: PropTypes.number,
        hasFlip: PropTypes.bool,
        /**
         * Helps resolve the accessibility issue on readers / etc.
         * Fallbacks to an autogenerated ID.
         */
        id: PropTypes.string,
        /**
         * The number of milliseconds to wait before hiding the Tooltip.
         */
        leaveDelay: PropTypes.number,
        /**
         * The distance from the Tooltip to its container.
         */
        margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        maxWidth: PropTypes.string,
        /**
         * Event fired when the Tooltip is ready to be closed.
         */
        onClose: PropTypes.func,
        /**
         * Event fired when the Tooltip is ready to be opened.
         */
        onOpen: PropTypes.func,
        /**
         * If `true`, the Tooltip gets displayed.
         */
        open: PropTypes.bool,
        /**
         * The padding for the rendered Tooltip container.
         */
        padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        /**
         * The placement of the Tooltip.
         * This goes directly with placements defined in `Popper.js`.
         */
        placement: PropTypes.oneOf([
            'top-start',
            'top',
            'top-end',
            'right-start',
            'right',
            'right-end',
            'bottom-start',
            'bottom',
            'bottom-end',
            'left-start',
            'left',
            'left-end',
        ]),
        /**
         * Close icon For mobile view.
         */
        showCloseIcon: PropTypes.bool,
        /**
         * Add a timeout for the tooltip to show / hide itself (animation delay).
         */
        timeout: PropTypes.number,
        /**
         * The Tooltip's title.
         */
        title: PropTypes.node,
        /**
         * Info about the tooltip which is to be rendered via the `tooltipsList`.
         */
        tooltip: PropTypes.object,
        /**
         * The props passed to the tooltip, if a `tooltipId` is present.
         */
        tooltipProps: PropTypes.object,
        /**
         * A list of tooltip components.
         */
        tooltipsList: PropTypes.object,
    };

    static defaultProps = {
        PopperProps: {},
        TransitionComponent: Fade,
        TransitionProps: {},
        arrowSize: 7.5,
        background: null,
        children: null,
        color: 'dark',
        content: null,
        disableFocusListener: null,
        disableHoverListener: null,
        enterDelay: 0,
        hasFlip: false,
        id: null,
        leaveDelay: 0,
        margin: 5,
        maxWidth: '320px',
        onClose: null,
        onOpen: null,
        open: null,
        padding: null,
        placement: 'top',
        showCloseIcon: true,
        timeout: null,
        title: '',
        tooltip: null,
        tooltipProps: null,
        tooltipsList: null,
    };

    // Timers.
    enterTimer = null;

    leaveTimer = null;

    // Internal state,
    // doesn't do a re-render().
    internalState = {
        hover: false,
        focus: false,
    };

    constructor(props) {
        super(props);
        // We have a controlled Tooltip if the parent is providing the `props.open`.
        this.isControlled = props.open != null;

        this.state = {
            open: !this.isControlled ? false : null,
        };
    }

    componentDidMount() {
        const { open } = this.props;
        // If we have a controlled Tooltip, then we should render immediately.
        if (open) {
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        // Clearout all of the timeouts.
        clearTimeout(this.enterTimer);
        clearTimeout(this.leaveTimer);
    }

    /**
     * Get info about the tooltip's content component
     * if a `tooltipId` is present in the props,
     * otherwise we'll return the `content`.
     *
     * @method getContentComponent
     * @return {Object}            The info about the tooltip component
     */
    getContentComponent = () => {
        const { content, tooltip, tooltipProps, tooltipsList } = this.props;
        // Find the content component from the list of components.
        const ContentComponent = !_isEmpty(tooltip) && tooltip.id && tooltipsList[tooltip.id];
        // Determine whether the found component is a valid react component.
        // This validation excludes HTML tagNames, and strings, it must be a functional stateless or statefull or styled component.
        const valid = React.isValidElement(ContentComponent) || isReactComponent(ContentComponent);
        // If needed, we can assign a ref for the wrapped or regular React component.
        const componentRef = valid && (ContentComponent.WrappedComponent || ContentComponent);

        const tooltipContent =
            typeof content === 'string'
                ? content.split('\n').map(str => <div key={str}>{str}</div>)
                : content;
        return {
            Component: valid ? (
                <ContentComponent {...tooltipProps} {..._omit(tooltip, ['id'])} />
            ) : (
                React.createElement('div', null, tooltipContent)
            ),
            componentRef,
        };
    };

    /**
     * Handle the 'enter' event of a Tooltip.
     *
     * @method handleEnter
     * @param  {Event}    event The synthetic event
     */
    handleEnter = event => {
        const { children, enterDelay } = this.props;
        const childProps = children.props;

        if (event.type === 'focus') {
            this.internalState.focus = true;

            if (typeof childProps.onFocus === 'function') {
                childProps.onFocus(event);
            }
        }

        if (event.type === 'mouseenter') {
            this.internalState.hover = true;

            if (typeof childProps.onMouseEnter === 'function') {
                childProps.onMouseEnter(event);
            }
        }

        // Remove the title from the child.
        // We don't want the native browser tooltip to show up.
        this.childRef.setAttribute('title', '');
        // Clear timeouts for enter and leave delay.
        clearTimeout(this.enterTimer);
        clearTimeout(this.leaveTimer);
        // If there is an `enterDelay`, then set a timeout to open the Tooltip.
        if (enterDelay) {
            event.persist();
            this.enterTimer = setTimeout(() => {
                this.handleOpen(event);
            }, enterDelay);
        } else {
            this.handleOpen(event);
        }
    };

    handleClick = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    /**
     * Handle the 'show' event of the Tooltip.
     * Works with the `this.handleEnter()`
     *
     * @method handleOpen
     * @param  {Event}   event The synthetic event
     */
    handleOpen = event => {
        const { onOpen } = this.props;

        if (!this.isControlled) {
            this.setState({ open: true });
        }

        if (typeof onOpen === 'function') {
            onOpen(event, true);
        }
    };

    /**
     * Handle the 'leave' event of the Tooltip.
     *
     * @method handleLeave
     * @param  {Event}    event The synthetic event
     */
    handleLeave = event => {
        const { children, leaveDelay } = this.props;
        const childProps = children.props;

        if (event.type === 'blur') {
            this.internalState.focus = false;

            if (typeof childProps.onBlur === 'function') {
                childProps.onBlur(event);
            }
        }

        if (event.type === 'mouseleave') {
            this.internalState.hover = false;

            if (typeof childProps.onMouseLeave === 'function') {
                childProps.onMouseLeave(event);
            }
        }

        // Clear Timeouts.
        clearTimeout(this.enterTimer);
        clearTimeout(this.leaveTimer);
        // If there is an `leaveDelay`, then set a timeout to open the Tooltip.
        if (leaveDelay) {
            event.persist();
            this.leaveTimer = setTimeout(() => {
                this.handleClose(event);
            }, leaveDelay);
        } else {
            this.handleClose(event);
        }
    };

    /**
     * Handle the 'hide' event of the Tooltip.
     * Works with the `this.handleLeave()`
     *
     * @method handleClose
     * @param  {Event}    event The synthetic event
     */
    handleClose = event => {
        const { onClose } = this.props;

        if (this.internalState.focus || this.internalState.hover) {
            return;
        }

        if (!this.isControlled) {
            this.setState({ open: false });
        }

        if (typeof onClose === 'function') {
            onClose(event, false);
        }
    };

    /**
     * Assign a `ref` to the child node.
     *
     * @method assignChildRef
     * @param  {React}       node The react DOM node
     * @return {Ref}
     */
    assignChildRef = node => {
        this.childRef = node;
        return this.childRef;
    };

    renderCloseIcon = () => {
        const { content, showCloseIcon } = this.props;
        let showIcon = showCloseIcon;
        if (React.isValidElement(content) || isReactComponent(content)) {
            showIcon = false;
        }
        return (
            showIcon && renderCheck('md', 'less') && <CloseIconStyled onClick={this.handleClose} />
        );
    };

    render() {
        const {
            PopperProps,
            TransitionComponent,
            TransitionProps,
            arrowSize,
            background,
            children,
            color,
            content,
            disableFocusListener,
            disableHoverListener,
            hasFlip,
            id,
            margin,
            maxWidth,
            open: openProps,
            padding,
            placement,
            showCloseIcon,
            timeout: timeoutProps,
            title,
            tooltip,
        } = this.props;
        const { open: openState } = this.state;
        // Determine the Content Component.
        const ContentComponent = this.getContentComponent();
        // Get the default timeout for the tooltip.
        const timeout =
            typeof timeoutProps === 'number'
                ? timeoutProps
                : getThemeProps('transitions.duration.none')();

        // If we have a controlled Tooltip, then set it to the `props.open`,
        // otherwise, use the internal `this.state.open`.
        let open = this.isControlled ? openProps : openState;

        // What will we do with a blank tooltip?
        if (_isEmpty(ContentComponent.Component)) {
            open = false;
        }

        // Build the props for the Children
        const childProps = {
            'aria-describedby': id || `tooltip_${title}`,
            title: typeof title === 'string' ? title : null,
        };

        if (renderCheck('md', 'less')) {
            if (!disableHoverListener) {
                childProps.onMouseDown = this.handleClick;
                childProps.onMouseLeave = this.handleLeave;
            }
        } else {
            if (!disableHoverListener) {
                childProps.onMouseEnter = this.handleEnter;
                childProps.onMouseLeave = this.handleLeave;
            }

            if (!disableFocusListener) {
                childProps.onFocus = this.handleEnter;
                childProps.onBlur = this.handleLeave;
            }
        }

        return (
            <>
                <RootRef rootRef={this.assignChildRef}>
                    {React.cloneElement(children, childProps)}
                </RootRef>
                <Popper
                    style={{ zIndex: 1000, overflowWrap: 'break-word' }}
                    anchorEl={this.childRef}
                    id={childProps['aria-describedby']}
                    open={open}
                    placement={placement}
                    timeout={timeout}
                    transition
                    hasFlip={hasFlip}
                    {...PopperProps}
                >
                    {({ placement: childPlacement, TransitionProps: childTransitionProps }) => (
                        <TransitionComponent
                            direction={childPlacement.split('-')[0]}
                            timeout={timeout}
                            {...childTransitionProps}
                            {...TransitionProps}
                        >
                            <TooltipStyled
                                hasFlip={hasFlip}
                                arrowSize={arrowSize}
                                background={background}
                                content={content}
                                margin={margin}
                                maxWidth={maxWidth}
                                open={open}
                                padding={padding}
                                placement={childPlacement}
                                color={color}
                                showCloseIcon={showCloseIcon}
                                {..._omit(tooltip, ['id'])}
                            >
                                {this.renderCloseIcon()}
                                {ContentComponent.Component}
                            </TooltipStyled>
                        </TransitionComponent>
                    )}
                </Popper>
            </>
        );
    }
}

export default Tooltip;
