import { THEME } from 'ravenjs/constants/theme';
import { get } from 'ravenjs/utils/lodash';

export const isXsViewport = () => {
    return window.innerWidth <= THEME.breakpoints.values.xs;
};

export const isSmViewport = () => {
    return window.innerWidth <= THEME.breakpoints.values.sm;
};

export const isMdViewport = () => {
    return window.innerWidth <= THEME.breakpoints.values.md;
};

export const isLgViewport = () => {
    return window.innerWidth <= THEME.breakpoints.values.lg;
};

export const isXlViewport = () => {
    return window.innerWidth <= THEME.breakpoints.values.xl;
};

export const isXxlViewport = () => {
    return window.innerWidth <= THEME.breakpoints.values.xxl;
};

export const renderCheck = (size, value) => {
    if (value === 'greater') {
        return window.innerWidth >= get(THEME.breakpoints.values, size);
    } else if (value === 'less') {
        return window.innerWidth <= get(THEME.breakpoints.values, size);
    }
    return null;
};
