import { createActions } from 'utils/actions';

/**
 * Redux actions for adding single or multiple modals by given Id(s).
 *
 * @type {Object}
 */
export const addModal = createActions('ui', 'ADD_MODAL');

/**
 * Redux actions for closing modal.
 *
 * @type {Object}
 */
export const closeModal = createActions('ui', 'CLOSE_MODAL');

/**
 * Redux actions for opening modal.
 *
 * @type {Object}
 */
export const openModal = createActions('ui', 'OPEN_MODAL', {
    payloadCreator: (modalId, data) => ({ id: modalId, data }),
});

/**
 * Redux actions for updating modal.
 *
 * @type {Object}
 */
export const updateModal = createActions('ui', 'UPDATE_MODAL', {
    payloadCreator: (modalId, data) => ({ id: modalId, data }),
});
