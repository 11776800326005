import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const ToolboxTalksIcon = ({
    height,
    viewBox,
    width,
    fillColor,
    fillColorComplementary,
    strokeColor,
    ...rest
}) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fill={fillColor}
                d="M1.2,24.1h61.6v-8.5c0-3.3-2.7-6.1-6.1-6.1H7.2c-3.3,0-6.1,2.7-6.1,6.1C1.2,15.5,1.2,24.1,1.2,24.1z"
            />
            <rect x="4.6" y="24.1" fill={fillColor} width="54.8" height="27.6" />
            <rect y="0" fill="none" width="64" height="64" />
            <path
                fill={fillColorComplementary}
                d="M5.1,24.1h57.7v-7.3c0-2.9-2.3-5.2-5.2-5.2H10.3c-2.9,0-5.2,2.4-5.2,5.2V24.1z"
            />
            <rect x="8.3" y="24.1" fill={fillColorComplementary} width="51.3" height="27.6" />
            <path
                fill={strokeColor}
                d="M2.2,23h59.6v-7.5c0-1.4-0.6-2.7-1.5-3.6c-0.9-0.9-2.2-1.5-3.6-1.5H7.2c-1.4,0-2.7,0.6-3.6,1.5l0,0.1
                c-0.9,0.9-1.4,2.2-1.4,3.5C2.2,15.5,2.2,23,2.2,23z M62.8,25.1H1.2c-0.6,0-1-0.5-1-1v-8.5c0-1.9,0.8-3.7,2-4.9l0-0.1
                c1.3-1.3,3-2.1,5-2.1h49.5c1.9,0,3.7,0.8,5,2.1s2.1,3.1,2.1,5v8.5C63.8,24.6,63.4,25.1,62.8,25.1z"
            />
            <path
                fill={strokeColor}
                d="M20,8.5h25.1V5.3c0-0.9-0.4-1.7-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9H23.2c-0.9,0-1.7,0.3-2.2,0.9
                C20.4,3.7,20,4.5,20,5.3C20,5.3,20,8.5,20,8.5z M46.2,10.5H19c-0.6,0-1-0.4-1-1V5.3c0-1.4,0.6-2.7,1.5-3.6c0.9-0.9,2.2-1.5,3.6-1.5
                H42c1.4,0,2.7,0.6,3.6,1.5c0.9,0.9,1.5,2.2,1.5,3.6v4.1C47.2,10,46.7,10.5,46.2,10.5z"
            />
            <path
                fill={strokeColor}
                d="M4.6,23h54.8c0.5,0,1,0.5,1,1v27.6c0,0.5-0.5,1-1,1H4.6c-0.6,0-1-0.5-1-1V24.1C3.6,23.5,4,23,4.6,23z
                M58.4,25.1H5.6v25.6h52.8C58.4,50.7,58.4,25.1,58.4,25.1z"
            />
            <rect x="17.1" y="22.4" fill={fillColorComplementary} width="4.8" height="7.1" />
            <path
                fill={strokeColor}
                d="M17.1,21.4h4.8c0.5,0,1,0.5,1,1v7.1c0,0.6-0.5,1-1,1h-4.8c-0.6,0-1-0.4-1-1v-7.1C16.1,21.9,16.6,21.4,17.1,21.4
                z M20.9,23.4h-2.8v5.1h2.8V23.4z"
            />
            <rect x="42.1" y="22.4" fill={fillColorComplementary} width="4.8" height="7.1" />
            <path
                fill={strokeColor}
                d="M42.1,21.4h4.8c0.6,0,1,0.5,1,1v7.1c0,0.6-0.5,1-1,1h-4.8c-0.5,0-1-0.4-1-1v-7.1C41.1,21.9,41.5,21.4,42.1,21.4
                z M45.9,23.4h-2.8v5.1h2.8V23.4z"
            />
            <path
                fill={fillColor}
                d="M44,44H28.7c-1.2,0-2.2,1-2.2,2.2v11c0,1.2,1,2.2,2.2,2.2v3.4l6-3.4H44c1.2,0,2.2-1,2.2-2.2v-11
                C46.1,44.9,45.2,44,44,44z"
            />
            <path
                fill={fillColor}
                d="M45.5,46.1H30.9c-0.5,0-0.8,0.4-0.8,0.8v10.6c0,1.1,1,2,2,2v1.9l2.3-1.9h8.2c1.1,0,3.5-0.2,3.5-2.3V50v-3.1
                C46.1,46.4,45.9,46.1,45.5,46.1z"
            />
            <path
                fill={strokeColor}
                d="M44,45H28.7c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8v11c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3
                c0.6,0,1,0.5,1,1v1.8l4.4-2.7c0.2-0.1,0.3-0.1,0.5-0.1l9.3,0c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8v-11c0-0.3-0.1-0.6-0.3-0.8
                C44.6,45.1,44.3,45,44,45z M28.7,43H44c0.9,0,1.7,0.4,2.2,0.9c0.6,0.6,0.9,1.4,0.9,2.2v11c0,0.9-0.4,1.7-0.9,2.2
                c-0.6,0.6-1.4,0.9-2.2,0.9h-9.1l-5.6,3.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0-1-0.5-1-1v-2.7c-0.5-0.2-0.9-0.4-1.2-0.8
                c-0.6-0.6-0.9-1.4-0.9-2.2v-11c0-0.9,0.4-1.7,0.9-2.2C27,43.3,27.8,43,28.7,43z"
            />
            <path
                fill={fillColor}
                d="M39.5,33.9h20.5c1.6,0,2.9,1.3,2.9,2.9v13.6c0,1.6-1.3,2.9-2.9,2.9v6.4l-8-6.4H39.5c-1.6,0-2.9-1.3-2.9-2.9
                V36.8C36.6,35.2,37.9,33.9,39.5,33.9z"
            />
            <path
                fill={strokeColor}
                d="M39.5,32.9h20.5c1.1,0,2,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8v13.6c0,1.1-0.4,2.1-1.2,2.8
                c-0.5,0.5-1.1,0.8-1.8,1v5.6c0,0.6-0.5,1-1,1c-0.3,0-0.5-0.1-0.7-0.3l-7.6-6.2H39.5c-1.1,0-2.1-0.4-2.8-1.1
                c-0.7-0.7-1.2-1.7-1.2-2.8V36.8c0-1.1,0.4-2.1,1.2-2.8C37.4,33.3,38.4,32.9,39.5,32.9z M59.9,34.9H39.5c-0.5,0-1,0.2-1.3,0.6
                c-0.3,0.3-0.6,0.8-0.6,1.3v13.6c0,0.5,0.2,1,0.6,1.3c0.3,0.3,0.8,0.6,1.3,0.6H52c0.2,0,0.4,0.1,0.6,0.2l6.3,5.1v-4.3
                c0-0.5,0.4-1,1-1c0.5,0,1-0.2,1.3-0.6c0.3-0.3,0.6-0.8,0.6-1.3V36.8c0-0.5-0.2-1-0.6-1.3C60.9,35.1,60.4,34.9,59.9,34.9z"
            />
            <path
                fill={strokeColor}
                d="M56,42.8c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1C57,43.3,56.5,42.8,56,42.8z"
            />
            <path
                fill={strokeColor}
                d="M49.7,42.8c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1C50.7,43.3,50.3,42.8,49.7,42.8z"
            />
            <path
                fill={strokeColor}
                d="M43.4,42.8c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1C44.4,43.3,44,42.8,43.4,42.8z"
            />
        </SvgIcon>
    );
};

ToolboxTalksIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
    fillColorComplementary: string,
    strokeColor: string,
};

ToolboxTalksIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
    fillColor: `${DEFAULT_COLORS.WHITE}`,
    fillColorComplementary: `${DEFAULT_COLORS.YELLOW}`,
    strokeColor: `${DEFAULT_COLORS.BLACK}`,
};

export default ToolboxTalksIcon;
