import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import FormGroupTitle from 'ravenjs/lib/Form/fields/FormGroupTitle';
import Input from 'ravenjs/lib/Input';
import { get } from 'ravenjs/utils';

const BaseInputWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
`;

class InputUsernameField extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        autofocus: PropTypes.bool,
        idSchema: PropTypes.object.isRequired,
        formContext: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        readonly: PropTypes.bool,
        registry: PropTypes.object.isRequired,
        required: PropTypes.bool,
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object.isRequired,
        formData: PropTypes.string,
    };

    static defaultProps = {
        disabled: null,
        readonly: null,
        required: null,
        formData: '',
        autofocus: false,
        onFocus: false,
    };

    inputWrapperRef = React.createRef();

    /**
     * Get the `ui:options` for the current field.
     *
     * @method _getUiOptions
     * @private
     * @param  {Object}      schema The schema to extract the options from
     * @return {Object}             The extracted options
     */
    _getUiOptions = schema => get(schema, 'ui:options', {});

    render() {
        const {
            autofocus,
            disabled,
            idSchema,
            onBlur,
            onChange,
            readonly,
            uiSchema,
            formData,
            schema,
        } = this.props;

        const options = this._getUiOptions(uiSchema);
        const { $id: id } = idSchema;
        const { styles, col, placeholder } = options;
        const { title } = schema;
        const inputValue = document.getElementById(id)?.value;
        const _onChange = ({ target: { value } }) =>
            onChange(value === '' ? options.emptyValue : value);

        const _onBlur = ({ target: { value } }) => {
            if (typeof onBlur === 'function') {
                onBlur(id, value);
            }
        };

        const _onFocus = () => {
            if (inputValue) {
                document.getElementById(id).value = inputValue;
            }
        };

        return (
            <>
                <FormGroupTitle title={title} uiOptions={options} />
                <BaseInputWrapper ref={this.inputWrapperRef}>
                    <Input
                        autoFocus={autofocus}
                        disabled={disabled}
                        id={id}
                        readOnly={readonly}
                        value={formData == null ? '' : formData}
                        type="text"
                        placeholder={placeholder}
                        {...styles}
                        {...col}
                        onChange={_onChange}
                        onBlur={_onBlur}
                        onFocus={_onFocus}
                    />
                </BaseInputWrapper>
            </>
        );
    }
}

export default InputUsernameField;
