/**
 * These are the attribute names and labels used in the forms
 *
 * LABELS[entityType]
 */
import { CONTENT_TYPES_MAPPED } from 'constants/content';

export const ENTITY_LABELS = {
    CONTENT: {
        CONTENT_TYPE: {
            [CONTENT_TYPES_MAPPED.DOCUMENT]: 'Document',
            [CONTENT_TYPES_MAPPED.JOB_DESCRIPTION]: 'Job Description Library',
            [CONTENT_TYPES_MAPPED.LAW]: 'Laws',
            [CONTENT_TYPES_MAPPED.LAW_ALERT]: 'Law Alert',
            [CONTENT_TYPES_MAPPED.QUESTION_AND_ANSWER]: 'Q&A',
            [CONTENT_TYPES_MAPPED.TOOLKIT]: 'Toolkit',
            [CONTENT_TYPES_MAPPED.TWO_MIN_HR]: '2MHR',
            [CONTENT_TYPES_MAPPED.TOOLKITS]: 'Toolkits',
            [CONTENT_TYPES_MAPPED.WEBINARS]: 'Webinars',
        },
    },
    COMPANY: {
        brokerName: {
            label: 'Partner Name',
        },
        canCreateBcsEliteDocuments: {
            label: 'Create Wrap SPD and POP documents',
        },
        configurationName: {
            label: 'Configuration Name',
        },
        companyName: {
            label: 'Client Name',
        },
        companyPhone: {
            label: 'Phone',
        },
        companySize: {
            label: 'Company Size',
        },
        companyUrl: {
            label: 'Company Url',
        },
        companySince: {
            label: 'Company Since',
        },
        displayName: {
            label: 'Display Name',
        },
        producer: {
            label: 'Producer',
        },
        industry: {
            label: 'Industry',
        },
        isDemoAccount: {
            label: 'Demo Account',
        },
        source: {
            label: 'Source',
        },
        custom1: { label: 'Custom Field 1' },
        custom2: { label: 'Custom Field 2' },
        custom3: { label: 'Custom Field 3' },
        custom4: { label: 'Custom Field 4' },
        lastRemindedToUpdate: {
            label: 'Company last reminded to update',
        },
        modifiedOn: {
            label: 'Company modified on',
        },
        location: {
            address: {
                label: 'Address Line 1',
            },
            address2: {
                label: 'Address Line 2',
            },
            employeeCount: {
                label: 'Number of employees at this location',
            },
            zip: {
                label: 'Zip',
            },
            city: {
                label: 'City',
            },
            county: {
                label: 'County',
            },
            state: {
                label: 'State',
            },
            stateCode: {
                label: 'State Code',
            },
            phone: {
                label: 'Phone',
            },
            industry: {
                label: 'Industry',
            },
            locationName: {
                label: 'Location Name',
            },
            industryClassification: {
                label: 'NAICS Code',
            },
            isFederalContractor: {
                label: 'Are you a federal contractor at this location?',
            },
            isUnionized: {
                label: 'Does your company have any union agreements at this location?',
            },
            isHQ: {
                label: 'Is HQ?',
            },
            businessType: {
                label: 'Type of Business',
            },
            createdOn: {
                label: 'Industry created on',
            },
            createdBy: {
                label: 'Industry created by',
            },
            cellPhone: {
                label: 'Cell Phone',
            },
            directPhone: {
                label: 'Direct Phone',
            },
        },
        partnerId: {
            label: 'Partner ID',
        },
        partnerName: {
            label: 'Partner Name',
        },
        accountManager: {
            label: 'Account Manager',
        },
        successManager: {
            label: 'Customer Success Manager',
        },
        hrProId: {
            label: 'Dedicated HR Pro',
        },
        customSSOConfigurations: {
            ssoCompanyName: {
                label: 'SSO Company Name',
            },
            id: {
                label: 'SSO ID',
            },
            ssoKey: {
                label: 'SSO Key',
            },
            allowedReferrers: {
                label: 'SSO allowed referrers',
            },
        },
        samlSSOConfigurations: {
            idpEntityId: {
                label: 'IDP Entity Id',
            },
            logInUrl: {
                label: 'Sign in Url',
            },
            logOutUrl: {
                label: 'Sign out Url',
            },
            idpCertFingerprint: {
                label: 'IDP Cert Fingerprint',
            },
            idpCert: {
                label: 'IDP Cert',
            },
            nameIdentifierFormat: {
                label: 'Name Identifier Format',
            },
            metadataUrl: {
                label: 'Metadata Url',
            },
        },
        openIdSSOConfigurations: {
            idpClientId: {
                label: 'IDP Client Id',
            },
            idpClientSecret: {
                label: 'IDP Client Secret',
            },
            discoveryEndpoint: {
                label: 'Discovery Endpoint',
            },
            authorizationEndpoint: {
                label: 'Authorization Endpoint',
            },
            tokenEndpoint: {
                label: 'Token Endpoint',
            },
            issuer: {
                label: 'Issuer',
            },
            userinfoEndpoint: {
                label: 'User Info Endpoint',
            },
            jwkUri: {
                label: 'JWK Uri',
            },
            logOutUrl: {
                label: 'Sign out Url',
            },
            metadata: {
                label: 'Metadata',
            },
        },
        ssoUrl: {
            label: 'SSO Url',
        },
        companyId: {
            label: 'Company ID',
        },
        ssoConfigType: {
            label: 'SSO Config Type',
        },
        ssoMappedField: {
            label: 'SSO Mapped Field',
        },
        useCustomLoginInstructions: {
            label: 'Use Custom Sign in Instructions',
        },
        customLoginInstructions: {
            label: 'Custom Sign in Instructions',
        },
        useSupportInstructions: {
            label: 'Use Support Instructions',
        },
        supportInstructions: {
            label: 'Support Instructions',
        },
        redirectUri: {
            label: 'Redirect Uri',
        },
    },
    CONFIGURATION: {
        configurationName: { label: 'Configuration Name' },
        description: { label: 'Description' },
        skus: {
            sponsor: {
                label: 'Sponsor',
            },
        },
    },
    USER: {
        firstName: { label: 'First Name' },
        lastName: { label: 'Last Name' },
        fullName: { label: 'Full Name' },
        userName: { label: 'Username' },
        email: { label: 'Email Address' },
        companyName: { label: 'Company Name' },
        role: { label: 'Role' },
        isActive: { label: 'Is Active' },
        isDemoAccount: { label: 'Demo Account' },
        source: { label: 'Source' },
        phone: { label: 'Phone' },
        department: { label: 'Department' },
        jobTitle: { label: 'Job Title' },
        blockedAccount: { label: 'Blocked Account' },
        modifiedOn: {
            label: 'User modified on',
        },
        hrLens: {
            label: 'HR Lens',
        },
        thinkhrCrunch: {
            label: 'Crunch',
        },
        isPrimary: {
            label: 'Primary User',
        },
        cellPhone: {
            label: 'Cell Phone',
        },
        directLinePhone: {
            label: 'Direct Phone',
        },
        locationName: {
            label: 'Location Name',
        },
    },
    AFFILIATES: {
        name: {
            label: 'Name',
        },
        url: {
            label: 'URL',
        },
        primaryRegion: {
            label: 'Primary Region',
        },
    },
};

export const CUSTOM_FIELD_LABEL_MAPPINGS = {
    custom1: 'c1',
    custom2: 'c2',
    custom3: 'c3',
    custom4: 'c4',
};

export const ENTITY_TYPES = {
    AFFILIATES: 'affiliates',
    COMPANIES: 'companies',
    CONFIGURATIONS: 'configurations',
    USERS: 'users',
    HANDBOOKS: 'handbooks',
    USER: 'user',
};
