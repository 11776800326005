import _get from 'lodash/get';

/**
 * Reducer helper for catching auth module errors.
 *
 * @method handleAuthError
 * @param  {string}        authState The auth state to add the error to
 * @return {Function}
 */
export const handleAuthError = authState => (state, action) => {
    // Only add errors to the application errors module
    // if the error is an actual ERROR
    if (action.payload instanceof Error) {
        // Extract the error from the action.
        const error = _get(action, 'payload.message', 'unknown error');
        const errorCode = _get(action, 'payload.errorCode', '');

        // Return the updated state.
        return {
            ...state,
            auth: {
                ...state.auth,
                [authState]: {
                    description: error,
                    errorCode,
                    timestamp: new Date().getTime(),
                },
            },
        };
    }
    // Otherwise return the current state.
    return state;
};
