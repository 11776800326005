import { Component } from 'react';

class WebSocketSingleton extends Component {
    constructor() {
        super();
        this.connections = {};
        this.onMessageCallback = null;
        this.onOpenCallback = null;
        this.onCloseCallback = null;
    }

    connect({ url, name, protocol }) {
        if (this.connections[name]) {
            return this.connections[name];
        }

        const ws = new WebSocket(url, protocol);

        ws.onopen = () => {
            if (this.onOpenCallback) this.onOpenCallback(name);
        };

        ws.onmessage = event => {
            if (this.onMessageCallback) this.onMessageCallback(name, event);
        };

        ws.onclose = () => {
            if (this.onCloseCallback) this.onCloseCallback(name);
            delete this.connections[name];
        };

        ws.onerror = error => {};

        this.connections[name] = ws;
        return ws;
    }

    disconnect(name) {
        if (this.connections[name]) {
            this.connections[name].close();
            delete this.connections[name];
        }
    }

    setOnMessage(callback) {
        this.onMessageCallback = callback;
    }

    setOnOpen(callback) {
        this.onOpenCallback = callback;
    }

    setOnClose(callback) {
        this.onCloseCallback = callback;
    }

    closeAllConnections() {
        Object.keys(this.connections).forEach(name => this.disconnect(name));
    }
}

const webSocketSingleton = new WebSocketSingleton();
export { WebSocketSingleton, webSocketSingleton };
export default webSocketSingleton;
