import axios from 'axios';

/**
 * API call to fetch the url to benefits document creator
 *
 * @method fetchBenefitsDocumentCreatorUrl
 * @param {Object}    params
 * @return {Promise}
 */
export const fetchBenefitsDocumentCreatorUrlAPI = params =>
    axios({
        data: params,
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        url: '/tools/v1/bdc/login',
    });

/**
 * API call to fetch the url to benefits document creator
 *
 * @method fetchBenefitsDocumentCreateDocumentUrlAPI
 * @param {Object}    params
 * @return {Promise}
 */
export const fetchBenefitsDocumentCreateDocumentUrlAPI = params =>
    axios({
        type: 'apiTools',
        method: 'post',
        noBearer: true,
        url: '/tools/v1/bdc/create-document',
        data: params,
    });

/**
 * API call to fetch the url to benefits document creator
 *
 * @method fetchBdcDocumentsAPI
 * @param {string}    companyId
 * @return {Promise}
 */
export const fetchBdcDocumentsAPI = companyId =>
    axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `/tools/v1/bdc/widget?companyId=${companyId}`,
    });

/**
 * API call to fetch the url to benefits document creator resources or faq
 *
 * @method fetchBenefitsDocumentCreatorUrlResources
 * @param {string}    type
 * @return {Promise}
 */
export const fetchBenefitsDocumentCreatorUrlResourcesAPI = type =>
    axios({
        type: 'apiTools',
        method: 'get',
        noBearer: true,
        url: `/tools/v1/bdc/resource-faqs?type=${type}`,
    });
