import React, { forwardRef, useContext } from 'react';

import { PublicContentContext } from 'components/PublicContent/PublicContentContext';

const withPublicContent = WrappedComponent =>
    forwardRef((props, ref) => {
        const contextValue = useContext(PublicContentContext);
        return <WrappedComponent {...props} {...contextValue} ref={ref} />;
    });

export default withPublicContent;
