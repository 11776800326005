import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const NewIcon = ({ ...rest }) => (
    <SvgIcon {...rest}>
        <g fill="none" fillRule="evenodd">
            <g fill={DEFAULT_COLORS.BLUE}>
                <g>
                    <g transform="translate(-103 -427) translate(75.547 426.294) translate(27.776 1.5)">
                        <rect width="42" height="15" rx="7.5" />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

NewIcon.propTypes = {
    viewBox: string,
    width: string,
    height: string,
};

NewIcon.defaultProps = {
    height: '15',
    viewBox: '0 0 42 15',
    width: '42',
};

export default NewIcon;
