import axios from 'axios';

/**
 * API call to find username by email.
 *
 * @method forgotUsernameAPI
 * @param  {number}  email
 * @return {Promise}
 */
export const forgotUsernameAPI = email =>
    axios({
        method: 'post',
        url: `/v2/users/forgot-username?emailId=${email}`,
        ignoreAuthorization: true,
    });
