import { bool, func, number, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Typography from 'ravenjs/lib/Typography';
import Col from 'ravenjs/lib/Col';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import Row from 'ravenjs/lib/Row';

import { LogoutIcon } from 'components/Icons';
import MobileUserMenu from 'components/MobileUserMenu';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import SupportEmailAddress from 'components/SupportEmailAddress';
import SupportPhoneNumber from 'components/SupportPhoneNumber';
import { DEFAULT_COLORS } from 'constants/colors';
import {
    DEFAULT_USER_NAV_ITEMS,
    USER_NAV_HANDBOOK_USER_GUIDE,
    USER_NAV_STATE_ROUTE_MAP,
    USER_NAV_SUPPORT_INFO,
    USER_NAV_TITLE_MAP,
} from 'constants/navigation';
import { APP_PERMISSIONS } from 'constants/permissions';
import { USER_NAV_ITEMS } from 'constants/proptypes';
import Acl from 'modules/acl';
import { selectors as userSelectors } from 'modules/user';
import { isOasisCompany, isOasisEnabled } from 'utils/company';
import { sanitizeMarkup } from 'utils/sanitize';
import { isPartner, isPartnerAdministrator } from 'utils/user';

import { ADMIN_HANDBOOK_ID, EMPLOYEE_HANDBOOK_ID } from 'constants/api';
import UserMenuStyled from './UserMenuStyled';
import NeedHelpSectionPaychex from '../NeedHelpSectionPaychex';

const LinkList = styled.ul`
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    ${({ theme }) => theme.media.down('md')`
        margin-left: 0.85rem;
    `};
`;

const LinkItem = styled.li`
    align-items: center;
    display: flex;
    position: relative;
    padding: 0.3125rem 0;

    ${({ theme }) => theme.media.down('md')`
        margin: 0.5rem 0 2.263rem;
        font-size: 1.188rem;
        font-weight: normal;
    `};
    ${({ theme }) => theme.media.up('md')`
        font-size: 1rem;
    `};
`;

const LinkItemButton = styled(NavLink)`
    color: ${DEFAULT_COLORS.BLACK};
    :hover {
        color: ${DEFAULT_COLORS.LINK};
        text-decoration: none;
    }
`;

const UserActions = styled(Col)`
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
`;

class UserMenu extends Component {
    showLink = (link = {}) => {
        const { permission, to: pathUrl } = link;
        const { partnerSettings, userRole } = this.props;
        const partnerId = get(partnerSettings, 'partnerId', 0);
        const displayUserGuideMenu = get(partnerSettings, 'displayUserGuideMenu', false);
        const supportInfoSubTitle = get(partnerSettings, 'support.supportInfoSubTitle', null);

        if (link.state && link.state === 'dheaudits') {
            return Acl.check(APP_PERMISSIONS.systemDHEAuditsView);
        }

        if (link.state && link.state === 'userGuide') {
            const LINK_TO_PATH = get(USER_NAV_STATE_ROUTE_MAP, 'userGuide.to', '');

            if (displayUserGuideMenu && Boolean(!supportInfoSubTitle)) {
                link.title = USER_NAV_HANDBOOK_USER_GUIDE.TITLE;

                if (Acl.check(APP_PERMISSIONS.viewMenuHandbookGuide)) {
                    link.to = LINK_TO_PATH.replace('DOCUMENT_ID', EMPLOYEE_HANDBOOK_ID);
                } else {
                    link.to = LINK_TO_PATH.replace('DOCUMENT_ID', ADMIN_HANDBOOK_ID);
                }
                return true;
            } else if (isOasisEnabled() && isOasisCompany(partnerId)) {
                link.title = USER_NAV_HANDBOOK_USER_GUIDE.TITLE;

                if (isPartnerAdministrator(userRole) || isPartner({ role: userRole })) {
                    link.to = LINK_TO_PATH.replace('DOCUMENT_ID', ADMIN_HANDBOOK_ID);
                } else {
                    link.to = LINK_TO_PATH.replace('DOCUMENT_ID', EMPLOYEE_HANDBOOK_ID);
                }
                return true;
            }

            return false;
        }

        return (
            pathUrl &&
            (isEmpty(permission) ||
                (!isEmpty(permission) && Acl.check(APP_PERMISSIONS[permission])))
        );
    };

    supportEmailAddress = () => {
        const { partnerSettings } = this.props;
        return get(partnerSettings, 'support.supportEmail', '');
    };

    supportPhoneNumber = () => {
        const { partnerSettings } = this.props;
        return get(partnerSettings, 'support.hotlinePhone', '');
    };

    supportInfoSubTitle = () => {
        const { partnerSettings } = this.props;
        return get(partnerSettings, 'support.supportInfoSubTitle', '');
    };

    showSupportInformation = () => {
        const { partnerSettings } = this.props;
        const displaySupportInfoInAvatar = _get(
            partnerSettings,
            'support.displaySupportInfoInAvatar',
            false
        );
        return displaySupportInfoInAvatar || Boolean(this.supportInfoSubTitle());
    };

    renderSupportSubtitle = () => {
        const { partnerSettings } = this.props;
        const supportInfoSubTitle = get(partnerSettings, 'support.supportInfoSubTitle', null);
        const displaySupportInfoInAvatar = get(
            partnerSettings,
            'support.displaySupportInfoInAvatar',
            false
        );
        const partnerId = get(partnerSettings, 'partnerId', '');
        const paychexSupportEmail = get(partnerSettings, 'support.supportEmail', '');

        const isPaychexPartner =
            partnerId === MAESTER.companies.paychexinc_id ||
            partnerId === MAESTER.companies.paychexsales_id ||
            partnerId === MAESTER.companies.paychexFlex_id;

        if (isPaychexPartner) {
            return <NeedHelpSectionPaychex paychexSupportEmail={paychexSupportEmail} />;
        } else if (displaySupportInfoInAvatar) {
            return (
                <>
                    <SupportPhoneNumber
                        margin="0.3125rem 0"
                        phoneNumber={this.supportPhoneNumber()}
                    />
                    <SupportEmailAddress
                        emailAddress={this.supportEmailAddress()}
                        margin="0.3125rem 0"
                    />
                </>
            );
        }
        return (
            <Typography color={DEFAULT_COLORS.BLACK} fontFamily="Roboto" lineHeight="normal">
                {supportInfoSubTitle}
            </Typography>
        );
    };

    renderUserLinks = () => {
        const { isMobile, items, onNavClick, userCompany } = this.props;
        const { companyId, partnerId } = userCompany;
        const onClick = navLink => {
            const { isSoftLogin, openLoginPrompt } = this.props;

            if (isSoftLogin) {
                openLoginPrompt();
            }

            onNavClick(navLink);
        };

        const menuSections = Object.keys(items);

        const getMobileUserNavItems = () => {
            if (get(items, 'myTools.0.state', '') === 'userGuide') {
                this.showLink(items.myTools[0]);
            }

            return items;
        };

        if (isMobile) {
            return (
                <MobileUserMenu
                    userNavItems={getMobileUserNavItems()}
                    onClose={navLink => {
                        onClick(navLink);
                    }}
                />
            );
        }

        return (
            <>
                <Row margin="0 0 0 1rem">
                    {menuSections.map(section => {
                        const linkList = items[section].filter(link => this.showLink(link));
                        return (
                            linkList.length && (
                                <Col style={{ minWidth: '170px' }} key={section}>
                                    <Typography
                                        color={DEFAULT_COLORS.BLACK}
                                        fontSize="1rem"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                        lineHeight="normal"
                                        gutterBottom="15px"
                                    >
                                        {USER_NAV_TITLE_MAP[section]}
                                    </Typography>
                                    <LinkList>
                                        {linkList.map(link => {
                                            const pendoClass =
                                                link.state === 'companies' &&
                                                Acl.check(APP_PERMISSIONS.userAssistanceClientAdmin)
                                                    ? 'pendo-nav-userprofile-companies'
                                                    : '';
                                            if (link.state === 'company') {
                                                link.to = `/admin/companies/${partnerId}/${companyId}/edit?isClientUser=true`;
                                            }

                                            return (
                                                <LinkItem key={link.state}>
                                                    <LinkItemButton
                                                        {...link}
                                                        onClick={() => {
                                                            onClick(link);
                                                        }}
                                                        dangerouslySetInnerHTML={sanitizeMarkup(
                                                            link.title
                                                        )}
                                                        className={pendoClass}
                                                    />
                                                </LinkItem>
                                            );
                                        })}
                                    </LinkList>
                                </Col>
                            )
                        );
                    })}
                </Row>
                {this.showSupportInformation() && (
                    <Row margin="1rem 1rem 0">
                        <Col>
                            <Typography
                                color={DEFAULT_COLORS.BLACK}
                                fontSize="1rem"
                                fontWeight="bold"
                                fontFamily="Roboto"
                                lineHeight="normal"
                                gutterBottom="0.9375rem"
                            >
                                {USER_NAV_SUPPORT_INFO.TITLE}
                            </Typography>
                            {this.renderSupportSubtitle()}
                        </Col>
                    </Row>
                )}
                <Row>
                    <UserActions size={12}>
                        {this.showLink(DEFAULT_USER_NAV_ITEMS) && (
                            <LinkItem key={DEFAULT_USER_NAV_ITEMS.id}>
                                {DEFAULT_USER_NAV_ITEMS.id === 'logout' ? (
                                    <LogoutIcon
                                        width="14px"
                                        height="15px"
                                        style={{ marginRight: '15px' }}
                                    />
                                ) : null}
                                <LinkItemButton
                                    {...DEFAULT_USER_NAV_ITEMS}
                                    onClick={() => onClick({ state: 'logout' })}
                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                        DEFAULT_USER_NAV_ITEMS.title
                                    )}
                                />
                            </LinkItem>
                        )}
                    </UserActions>
                </Row>
            </>
        );
    };

    render() {
        return <UserMenuStyled>{this.renderUserLinks()}</UserMenuStyled>;
    }
}

UserMenu.propTypes = {
    history: object,
    isMobile: bool,
    isSoftLogin: bool.isRequired,
    items: USER_NAV_ITEMS,
    onNavClick: func,
    openLoginPrompt: func.isRequired,
    partnerSettings: shape({
        partnerId: number,
    }).isRequired,
    userCompany: shape({
        companyId: number,
    }).isRequired,
    userRole: string.isRequired,
};

UserMenu.defaultProps = {
    history: {},
    isMobile: false,
    items: {},
    onNavClick: () => {},
};

const mapStateToProps = createStructuredSelector({
    partnerSettings: userSelectors.getPartnerSettings,
    userCompany: userSelectors.getUserCompany,
    userRole: userSelectors.getUserRole,
});

export { UserMenu as UserMenuUnwrapped };
export default withPublicContent(connect(mapStateToProps, null)(UserMenu));
