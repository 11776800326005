import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const RefreshIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fill={fill}
                d="M8.7,2.7c1.4-0.3,2.9-0.3,4.4,0.1s2.7,1.2,3.8,2.3c0,0,0,0,0,0L19.7,8h-3.6c-0.7,0-1.2,0.6-1.2,1.3
                c0,0.7,0.5,1.3,1.2,1.3h6.6c0.7,0,1.2-0.6,1.2-1.3V2.4c0-0.7-0.5-1.3-1.2-1.3c-0.7,0-1.2,0.6-1.2,1.3v3.9l-3.1-3
                c-1.3-1.4-3-2.4-4.8-2.9C11.9-0.1,10-0.1,8.1,0.3c-1.8,0.4-3.5,1.3-5,2.6C1.8,4.2,0.7,5.8,0.1,7.7C-0.2,8.3,0.2,9,0.8,9.2
                c0.6,0.2,1.3-0.1,1.6-0.8c0.5-1.4,1.3-2.7,2.4-3.7C5.9,3.8,7.2,3.1,8.7,2.7z M15.3,21.3c-1.4,0.3-2.9,0.3-4.4-0.1
                c-1.4-0.4-2.7-1.2-3.8-2.3c0,0,0,0,0,0L4.3,16h3.6c0.7,0,1.2-0.6,1.2-1.3c0-0.7-0.5-1.3-1.2-1.3H1.2c-0.7,0-1.2,0.6-1.2,1.3v6.8
                c0,0.7,0.5,1.3,1.2,1.3s1.2-0.6,1.2-1.3v-3.9l3.1,3c1.3,1.4,3,2.4,4.8,2.9c1.8,0.5,3.7,0.6,5.6,0.2c1.8-0.4,3.5-1.3,5-2.6
                c1.4-1.3,2.5-2.9,3.1-4.8c0.2-0.7-0.1-1.4-0.7-1.6c-0.6-0.2-1.3,0.1-1.6,0.8c-0.5,1.4-1.3,2.7-2.4,3.7
                C18.1,20.2,16.8,20.9,15.3,21.3z"
            />
        </SvgIcon>
    );
};

RefreshIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

RefreshIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24px',
    viewBox: '0 0 24 24',
    width: '24px',
};

export default RefreshIcon;
