import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CheckboxEmptyIcon = ({ backgroundFill, fill, height, viewBox, width }) => {
    return (
        <SvgIcon viewBox={viewBox} height={height} width={width}>
            <path fill={backgroundFill} d="M4 4h16v16H4z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5zM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CheckboxEmptyIcon.propTypes = {
    fill: string,
    backgroundFill: string,
    height: string,
    viewBox: string,
    width: string,
};

CheckboxEmptyIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    backgroundFill: 'transparent',
    height: '17',
    viewBox: '0 0 23 23',
    width: '17',
};

export default CheckboxEmptyIcon;
