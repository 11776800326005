import _get from 'lodash/get';
import { createSelector } from 'reselect';

import * as authActions from 'modules/auth/actions';
import { createReducer } from 'utils/reducer';

import * as contentfulActions from './actions';
import INITIAL_STATE from './initialState';
import * as helpers from './reducerHelpers';

/**
 * Build the map of 'contentful' redux handlers.
 *
 * @type {Object}
 */
const contenful = {
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [contentfulActions.fetchContentfulCategories.SUCCESS]: helpers.fetchContentfulCategoriesSuccess,
    [contentfulActions.fetchContentfulContentTypes.SUCCESS]:
        helpers.fetchContentfulContentTypesSuccess,
    [contentfulActions.fetchContentfulDocumentTypes.SUCCESS]:
        helpers.fetchContentfulDocumentTypesSuccess,
    [contentfulActions.fetchContentfulEhsDocumentTypes.SUCCESS]:
        helpers.fetchContentfulEhsDocumentTypesSuccess,
    [contentfulActions.fetchContentfulEmployeeCounts.SUCCESS]:
        helpers.fetchContentfulEmployeeCountsSuccess,
    [contentfulActions.fetchContentfulJurisdictions.SUCCESS]:
        helpers.fetchContentfulJurisdictionsSuccess,
    [contentfulActions.fetchFeaturedContent.SUCCESS]: helpers.fetchFeaturedContentSuccess,
    [contentfulActions.updateFeaturedContentImageIndex.SUCCESS]:
        helpers.updateFeaturedContentImageIndexSuccess,
    [contentfulActions.updateEhsCategories.SUCCESS]: helpers.updateEhsCategoriesSuccess,
};

export default createReducer(INITIAL_STATE, contenful);

// NOTE: The rest of the 'named' exports will be the selectors, for this module.

/**
 * Get all the info about the current contenful.
 *
 * @method getContenful
 * @param  {Object} state The current state
 * @return {Object}       The contenful info
 */
export const getContentful = state => _get(state, 'contentful', {});

/**
 * Get featured content
 *
 * @method getFeaturedContentIndex
 * @param  {Object} contenful The current contenful info
 * @return {Object}       The featured content info
 */
export const getFeaturedContentIndex = createSelector([getContentful], contenful => {
    return _get(contenful, 'currentFeaturedImageIndex', 0);
});
