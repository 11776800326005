import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import _get from 'lodash/get';

import Col from 'ravenjs/lib/Col';
import Input from 'ravenjs/lib/Input';
import Row from 'ravenjs/lib/Row';
import Select from 'ravenjs/lib/Select';
import Typography from 'ravenjs/lib/Typography';
import { callFunc, getHandler, get } from 'ravenjs/utils';

import DescriptionField from './DescriptionField';
import FormGroupTitle from './FormGroupTitle';
import DisplayRequiredWithIconField from './DisplayRequiredWithIconField';

function LocationField(props) {
    const { id, disabled, readonly, formContext, formData, schema, errorSchema, onBlur } = props;

    const pristine = _get(formContext, 'pristine', false);
    const taintedFields = _get(formContext, 'taintedFields', []);
    const handlers = _get(formContext, 'handlers', false);
    // errors
    const errors = {
        zip: get(errorSchema, 'zip.__errors', []),
        city: get(errorSchema, 'city.__errors', []),
        county: get(errorSchema, 'county.__errors', []),
        state: get(errorSchema, 'state.__errors', []),
    };
    // actions
    const onValidateZipcode = getHandler(handlers, 'onValidateZipcode');
    // get the `zipAddresses` data to build the default list of options
    const zipAddressesData = get(schema, 'zipAddresses');
    // build the default options for `city, `county` and `state`
    const cityOptions = buildSelectOptions(zipAddressesData, 'city');
    // build the `county` options
    const countyOptions = buildSelectOptions(zipAddressesData, 'county');
    // build the `state` options
    const stateOptions = buildSelectOptions(zipAddressesData, 'state');
    // update the selected options
    const [selectOptions, setSelectOptions] = useState({
        city: cityOptions,
        county: countyOptions,
        state: stateOptions,
    });
    // initial data component state
    const [initialData, setInitialData] = useState({ ...formData });

    // input ids
    const zipId = `${id}__zip`;

    useEffect(() => {
        setInitialData(formData);
    }, [formData]);

    // fields to set the default values
    const [zip, setZip] = useState(get(initialData, 'zip', ''));
    const zipRef = useRef(null);
    useEffect(() => {
        setZip(get(formData, 'zip', ''));
        zipRef.current.value = get(formData, 'zip', '');
    }, [formData, setZip]);

    function handleSelectOnChange(type) {
        return function(option) {
            const { onChange } = props;
            // get the value
            const value = get(option, 'value', '');
            // Build the updated location.
            const updatedLoc = {
                ...initialData,
                [type]: value,
            };
            // Update the location.
            setInitialData(prevLoc => ({
                ...prevLoc,
                [type]: value,
            }));
            // update the the form
            callFunc(onChange, updatedLoc);
        };
    }

    // handle the onBlur event
    async function _onBlur(e) {
        const { formContext, onChange } = props;
        const { value } = e.target;
        let updatedData = {
            ...initialData,
            zip: value,
        };

        try {
            // validate the zipcode
            const addresses = await onValidateZipcode(value);
            // make the result is an array
            if (Array.isArray(addresses)) {
                // build the `city` options
                const cityOptions = buildSelectOptions(addresses, 'city');
                // build the `county` options
                const countyOptions = buildSelectOptions(addresses, 'county');
                // build the `state` options
                const stateOptions = buildSelectOptions(addresses, 'state');
                // update the selected options
                setSelectOptions({
                    city: cityOptions,
                    county: countyOptions,
                    state: stateOptions,
                });
                // update the fields for the `locationLookup`
                updatedData = {
                    ...updatedData,
                    city: addresses[0].city,
                    state: addresses[0].state,
                    county: addresses[0].county,
                };
            }
        } catch (err) {
            return [];
        } finally {
            setInitialData(updatedData);
            formContext.updateFormData({ formData: updatedData });
            // update the form
            callFunc(onChange, updatedData);
        }
        return undefined;
    }

    /**
     * Remove duplicate counties when building the county options for the select field
     *
     * @param {Array} arr List of objects
     * @param {Array} key The object key to use to create the options
     * @return {Array} Return Array of objects for the select options
     */
    function buildSelectOptions(arr, key) {
        if (!Array.isArray(arr)) {
            return [];
        }
        // get the list of counties
        const options = arr.map(item => item[key]);
        // filter out the array from duplication, for example state: [CA, CA]
        return options
            .filter((item, index) => {
                return options.indexOf(item) >= index;
            })
            .map(value => ({ label: value, value }));
    }

    /**
     * Returns errors for the given field name
     *
     * @param   {string}    field    Name of the field for which data needs to be retrieved
     * @return  {string}
     */
    function renderFieldError(field) {
        const error = get(errors, field, []);
        return error.length
            ? error.map(error => {
                  return (
                      <Typography
                          type="labelErrorValidation"
                          color="error"
                          gutterBottom="0"
                          gutterTop="0"
                      >
                          {error}
                      </Typography>
                  );
              })
            : '';
    }

    /**
     * Returns value for the given field name from initialData object
     *
     * @param   {string}    fieldName    Name of the field for which data needs to be retrieved
     * @return  {Object}
     */
    function getValueForField(fieldName) {
        return initialData[fieldName]
            ? { label: initialData[fieldName], value: initialData[fieldName] }
            : undefined;
    }

    /**
     * Handles the keydown event on the zipcode input field.
     *
     * @param   {Event} e
     */
    function onKeyDown(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            zipRef.current.blur();
            e.stopPropagation();
            e.preventDefault();
        }
    }

    const isFieldDirtyOrTainted = !pristine || taintedFields.includes(id);
    const showRequiredCity = isFieldDirtyOrTainted && !initialData.city;
    const showRequiredState = isFieldDirtyOrTainted && !initialData.state;
    const showRequiredCounty = isFieldDirtyOrTainted && !initialData.county;
    const showRequiredZip = !pristine && !initialData.zip;

    return (
        <>
            <Row width="auto">
                <Col size={12}>
                    <FormGroupTitle id={zipId} title="Zip" required={showRequiredZip} />
                    <Input
                        id={zipId}
                        type="text"
                        defaultValue={zip}
                        disabled={disabled || readonly}
                        onBlur={_onBlur}
                        onKeyDown={onKeyDown}
                        required={showRequiredZip}
                        showRequired={showRequiredZip}
                        ref={zipRef}
                    />
                    {showRequiredZip && <DisplayRequiredWithIconField />}
                    <DescriptionField
                        id={zipId}
                        formContext={formContext}
                        description="Please provide a valid 5 digit zip code. A valid zip code will populate City, County and State automatically for the provided zip code."
                    />
                </Col>
            </Row>
            <Row width="auto" margin="10px -8px">
                <Col size={12}>
                    <FormGroupTitle id={`${id}__city`} required={showRequiredCity} title="City" />
                    <Select
                        isDisabled={disabled || readonly}
                        id={`${id}__city`}
                        options={selectOptions.city}
                        value={getValueForField('city')}
                        onChange={handleSelectOnChange('city')}
                        onBlur={
                            typeof onBlur === 'function' &&
                            (event => onBlur(id, event.target.value))
                        }
                        required={!pristine || taintedFields.includes(id)}
                        title="City"
                    />
                    {renderFieldError('city')}
                </Col>
            </Row>
            <Row width="auto" margin="10px -8px">
                <Col size={6}>
                    <FormGroupTitle
                        id={`${id}__county`}
                        required={showRequiredCounty}
                        title="County"
                    />
                    <Select
                        isDisabled={disabled || readonly}
                        id={`${id}__county`}
                        options={selectOptions.county}
                        value={getValueForField('county')}
                        onChange={handleSelectOnChange('county')}
                        onBlur={
                            typeof onBlur === 'function' &&
                            (event => onBlur(id, event.target.value))
                        }
                        placeholder="--Please choose a county--"
                        required={!pristine || taintedFields.includes(id)}
                        title="County"
                    />
                    {renderFieldError('county')}
                </Col>
                <Col size={6}>
                    <FormGroupTitle
                        id={`${id}__state`}
                        required={showRequiredState}
                        title="State"
                    />
                    <Select
                        isDisabled={disabled || readonly}
                        id={`${id}__state`}
                        options={selectOptions.state}
                        value={getValueForField('state')}
                        onChange={handleSelectOnChange('state')}
                        onBlur={
                            typeof onBlur === 'function' &&
                            (event => onBlur(id, event.target.value))
                        }
                        placeholder="--Please choose a state"
                        required={!pristine || taintedFields.includes(id)}
                        title="State"
                    />
                    {renderFieldError('state')}
                </Col>
            </Row>
        </>
    );
}

LocationField.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    formData: PropTypes.object,
    formContext: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    errorSchema: PropTypes.object.isRequired,
};

LocationField.defaultProps = {
    id: null,
    disabled: false,
    readonly: false,
    formData: null,
    formContext: null,
};

export default LocationField;
