import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const Intelligenceicon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#k82a8jmyta)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.325 35.478h17.83c.133-5.771 2.785-7.314 4.869-10.926a16.049 16.049 0 0 0-.11-15.854C35.088 3.803 29.92.676 24.24.676c-5.678 0-10.848 3.127-13.674 8.022a16.049 16.049 0 0 0-.11 15.854c2.084 3.612 4.738 5.154 4.87 10.926zM15.42 35.478h17.638c.053 0 .097.043.097.096v2.846c0 1.52-1.242 2.76-2.761 2.76h-12.31a2.767 2.767 0 0 1-2.76-2.76v-2.846c0-.054.043-.096.096-.096zM18.086 41.18h12.307v3.071a3.084 3.084 0 0 1-3.073 3.075h-6.161a3.083 3.083 0 0 1-3.073-3.075V41.18z"
                    fill="#fff"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.784 35.478H33.76c.12-5.587 2.541-7.08 4.441-10.577 2.517-4.778 2.478-10.608-.099-15.348-2.578-4.737-7.294-7.6-12.473-7.6-5.177 0-9.774 2.79-12.47 7.459-2.476 4.284-2.515 8.327-1.16 12.029.798 2.184 2.2 4.077 3.39 6.028 1.265 2.07 2.312 4.168 2.394 8.009z"
                    fill="#FFBF00"
                />
                <path
                    d="M33.154 36.235h-17.83a.76.76 0 0 1-.752-.743c-.091-3.966-1.463-5.839-2.969-7.892-.588-.8-1.195-1.627-1.78-2.636l-.027-.045a16.812 16.812 0 0 1 .09-16.551l.026-.048a16.937 16.937 0 0 1 6.09-6.149A16.252 16.252 0 0 1 24.24-.08c2.951 0 5.776.808 8.236 2.252a16.941 16.941 0 0 1 6.09 6.15 16.826 16.826 0 0 1 2.25 8.287 16.821 16.821 0 0 1-2.134 8.31l-.007.01c-.59 1.025-1.205 1.863-1.798 2.672-1.5 2.044-2.864 3.907-2.968 7.83l.001.048a.757.757 0 0 1-.756.757zM16.047 34.72h16.385c.244-3.942 1.67-5.89 3.23-8.013.575-.785 1.172-1.599 1.703-2.522a15.33 15.33 0 0 0 1.944-7.571 15.288 15.288 0 0 0-2.046-7.538 15.453 15.453 0 0 0-5.548-5.604 14.742 14.742 0 0 0-7.475-2.04c-2.686 0-5.25.733-7.477 2.04a15.44 15.44 0 0 0-5.545 5.604l-.024.04a15.281 15.281 0 0 0-.08 15.07l.013.02c.53.917 1.12 1.722 1.692 2.501 1.557 2.123 2.984 4.07 3.228 8.013z"
                    fill="#282828"
                />
                <path
                    d="M32.397 36.235H16.08v2.183c0 .553.226 1.054.589 1.417.361.363.863.588 1.416.588h12.307c.55 0 1.053-.225 1.415-.588.363-.362.589-.865.589-1.417v-2.183zM15.42 34.72h17.64a.852.852 0 0 1 .852.852v2.845c0 .968-.397 1.848-1.033 2.486a3.511 3.511 0 0 1-2.485 1.033H18.087a3.513 3.513 0 0 1-2.486-1.033 3.517 3.517 0 0 1-1.033-2.486v-2.846a.85.85 0 0 1 .852-.852z"
                    fill="#282828"
                />
                <path
                    d="M29.638 41.937H18.842v2.316a2.32 2.32 0 0 0 2.316 2.317h6.163c.638 0 1.216-.263 1.636-.68.42-.42.68-1 .68-1.637v-2.316h.001zm-11.551-1.514h12.307c.419 0 .757.339.757.755v3.075a3.817 3.817 0 0 1-1.124 2.704 3.818 3.818 0 0 1-2.705 1.125h-6.163a3.81 3.81 0 0 1-2.704-1.125 3.819 3.819 0 0 1-1.125-2.704v-3.075c0-.416.34-.755.757-.755zM30.095 15.683a2.256 2.256 0 0 0-1.594.686 2.477 2.477 0 0 0-.677 1.733v2.475h2.224c.667 0 1.27-.275 1.707-.716.44-.444.713-1.057.713-1.732v-.012a2.44 2.44 0 0 0-.711-1.724 2.412 2.412 0 0 0-1.662-.71zm-.048-1.513h.096a3.924 3.924 0 0 1 2.683 1.16 3.948 3.948 0 0 1 1.155 2.787v.012a3.97 3.97 0 0 1-1.156 2.796 3.904 3.904 0 0 1-2.778 1.164h-2.982a.755.755 0 0 1-.756-.755V18.1a3.99 3.99 0 0 1 1.11-2.785 3.757 3.757 0 0 1 2.628-1.146zM19.98 16.369a2.268 2.268 0 0 0-1.596-.686 2.405 2.405 0 0 0-1.66.71 2.433 2.433 0 0 0-.71 1.724v.012c0 .674.272 1.288.712 1.732a2.4 2.4 0 0 0 1.707.716h2.224v-2.475c0-.682-.254-1.297-.677-1.733zm-1.645-2.2h.098a3.766 3.766 0 0 1 2.63 1.148 3.988 3.988 0 0 1 1.109 2.785v3.232a.756.756 0 0 1-.757.756h-2.982a3.906 3.906 0 0 1-2.777-1.164A3.974 3.974 0 0 1 14.5 18.13v-.012c0-1.083.44-2.073 1.153-2.788a3.913 3.913 0 0 1 2.681-1.16z"
                    fill="#282828"
                />
                <path
                    d="M27.823 35.478a.758.758 0 0 1-1.514 0v-13.39h-4.138v13.39a.758.758 0 0 1-1.514 0V21.334c0-.419.34-.757.757-.757h5.652c.419 0 .758.339.758.757v14.144zM4.587 15.853a.757.757 0 0 1 0 1.513H.757a.757.757 0 0 1 0-1.513h3.83zM6.445 26.683a.757.757 0 0 1 .975.432.755.755 0 0 1-.432.975L3.597 29.41A.754.754 0 0 1 3.052 28l3.393-1.317zM6.988 5.128a.754.754 0 0 1-.543 1.407L3.053 5.218a.753.753 0 1 1 .544-1.407l3.39 1.317z"
                    fill="#282828"
                />
                <path
                    d="M4.409 15.853a.758.758 0 0 1 0 1.513H.756a.757.757 0 0 1 0-1.513h3.651zM41.016 28.09a.755.755 0 0 1 .544-1.407L44.95 28c.388.151.582.59.43.976a.754.754 0 0 1-.975.433l-3.39-1.319zM41.56 6.535a.754.754 0 1 1-.544-1.407l3.39-1.317a.755.755 0 0 1 .546 1.407L41.56 6.535zM43.595 17.366a.757.757 0 1 1 0-1.513h3.65a.757.757 0 0 1 0 1.513h-3.65z"
                    fill="#282828"
                />
            </g>
            <defs>
                <clipPath id="k82a8jmyta">
                    <path fill="#fff" d="M0 0h48v48H0z" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

Intelligenceicon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

Intelligenceicon.defaultProps = {
    height: '48',
    viewBox: '0 0 48 48',
    width: '48',
};

export default Intelligenceicon;
