/**
 * RegExp map to validate given strings.
 *
 * @type {Object}
 */
const REGEX_VALIDATE = {
    COMPANY_NAME: RegExp("^[A-Za-z-0-9 .,+_'&()/#!?@]*$"),
    CITY: RegExp('^[A-Za-z .]*$'),
    EMAIL: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    STRING_ONLY: /(^[A-Za-z]+$)/,
    NAME: /(^[a-zA-Z'-]+$)/,
    STRING_ONLY_WITH_SPACE: /^[a-zA-Z ]*$/,
    STRING_ONLY_WITH_HYPHEN_SPACE: /([a-zA-Z- ]+$)|(^$)/,
    STRING_ONLY_NUMBERS_LETTERS: /[^-\d\a-zA-Z]/g,
    USERNAME: /^[A-Za-z-0-9.-_*@+!|"']+$/,
    ZIP: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    SPECIAL_CHAR: /[!@#$%&+-]/gi,
    PASSWORD_CHAR: /[A-Za-z0-9!@#$%&+-]/gi,
    PERCENTAGE_CHARS: /[0-9%]+/g,
    LOWERCASE: /[a-z]/,
    UPPERCASE: /[A-Z]/,
    NUMBER: /[0-9]/,
    NUMBER_ONLY: /^[0-9]*$/,
    NO_SPACES: /\s/,
    ONLY_SPACES: /^(?!\s+$).+/,
    HANDBOOK_NAME: /[/\\\\]/g,
    URL: /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~]*)*(#[\w\-]*)?(\?.*)?/,
    HTTPS_URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    NUMBER_WITH_COMMAS_AND_DECIMALS: /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/,
    NUMBER_WITH_COMMAS: /^(\d+|\d{1,3}(,\d{3})*)$/,
    EMAIL_ADDRESS: /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
    ONE_CHAR_NUM_ALPHA_SPECIAL: /^(?=.*[A-Za-z0-9!@#$%^&*])/,
    EMAIL_FORMAT: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
};

export const FORM_FIELD_MASKS = {
    PHONE: [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        'e',
        'x',
        't',
        '.',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ],
    EI_NUMBER: [/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    DATE: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};

export const FORM_FORMAT = {
    STRING_ONLY: /(^[A-Za-z]+$)/,
    STRING_ONLY_WITH_SPACE: /^(?!\s+$)[-@./#&+\w\s]*$/,
    STRING_ONLY_WITH_HYPHEN_SPACE: /^[a-zA-Z- ]+$/,
    STRING_ONLY_WITH_SPECIAL_CHARACTERS: /^(?!\s+$)[-@./#&+\w\s]*$/,
};

export const FILE_REGEX = {
    EXTENSION: /^.*\./,
};

export const URL_REGEX = {
    PATHNAME_GROUP: /^.([^/]+\/[^/]+).*$/,
};

export const ANCHOR_TAG_REGEX = /<a .*?>|<\/a>|<\a>/g;

export const PRINTABLE_ASCII_CHARS = /^[\x20-\x7E]+$/;

export default REGEX_VALIDATE;
