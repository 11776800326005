import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const RectangleIcon = ({ fill, height, width, rectHeight, rectWidth, viewBox, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g fill="none" fillRule="evenodd">
                <g fill={fill}>
                    <g transform="translate(-1020 -387) translate(1020.656 387.962)">
                        <rect width={rectWidth} height={rectHeight} rx="7.5" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

RectangleIcon.propTypes = {
    fill: string,
    height: string,
    rectHeight: string,
    width: string,
    rectWidth: string,
    viewBox: string,
};

RectangleIcon.defaultProps = {
    fill: DEFAULT_COLORS.STEEL,
    height: '16',
    rectHeight: '15',
    width: '63',
    rectWidth: '62',
    viewBox: '0 0 63 16',
};

export default RectangleIcon;
