import _get from 'lodash/get';

import INITIAL_STATE from './initialState';

/**
 * Reducer helper for catching the auth logout action.
 *
 * @method logoutSuccess
 * @return {Object}      The updated state
 */
export const logoutSuccess = () => INITIAL_STATE;

/**
 * Reducer helper for handling modal success for:
 *  - adding modals
 *  - closing modals
 *
 * @method handleModalSuccess
 * @param  {Object}           state  The current state
 * @param  {Object}           action The dispatched action
 * @return {Object}                  The new state
 */
export const handleModalSuccess = (state, action) => ({
    ...state,
    ...action.payload,
});

/**
 * Reducer helper for handling opening modals (success).
 *
 * @method openModalSuccess
 * @param  {Object}         state  The current state
 * @param  {Object}         action The dispatched action
 * @return {Object}                The new state
 */
export const openModalSuccess = (state, action) => {
    // Extract the `id` and `data` from the payload.
    const id = _get(action, 'payload.id');
    const data = _get(action, 'payload.data');
    // Find the modal from the current state, via the `id`.
    const modal = _get(state, id);
    // Return the updated state.
    return {
        ...state,
        [id]: {
            ...modal,
            data,
            open: true,
        },
    };
};

/**
 * Reducer helper for handling updating modals.
 *
 * @method updateModalSuccess
 * @param  {Object}         state  The current state
 * @param  {Object}         action The dispatched action
 * @return {Object}                The new state
 */
export const updateModalSuccess = (state, action) => {
    const id = _get(action, 'payload.id');
    const data = _get(action, 'payload.data');
    const modal = _get(state, id);
    const modalData = { ...modal, data: { ...modal.data, ...data } };
    return {
        ...state,
        [id]: {
            ...modalData,
        },
    };
};
