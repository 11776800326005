import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const FavoriteIcon = ({ height, viewBox, width, color, strokeWidth, strokeColor, ...rest }) => (
    <SvgIcon
        {...rest}
        width={width}
        height={height}
        viewBox={viewBox}
        color={color}
        strokeWidth={strokeWidth}
        strokeColor={strokeColor}
        cursor="pointer"
    >
        <path
            fill={color}
            stroke={strokeColor}
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
            d="M10.415.78h-7.59C1.777.78.928 1.528.928 2.448v14c0 .184.169.333.378.334.102 0 .198-.035.27-.098l5.044-4.431 5.044 4.43c.148.13.388.13.537 0 .071-.062.111-.147.111-.235v-14c0-.92-.85-1.666-1.897-1.666z"
        />
    </SvgIcon>
);

FavoriteIcon.propTypes = {
    height: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
    strokeColor: PropTypes.string,
    strokeWidth: PropTypes.number,
};

FavoriteIcon.defaultProps = {
    viewBox: '0 0 14 18',
    width: '0.688rem',
    height: '1rem',
    color: 'none',
    strokeColor: '#797979',
    strokeWidth: 2,
};

export default FavoriteIcon;
