import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const WarningIcon = ({ height, viewBox, width, color, colorInner, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.39 29.559H26.61c1.798 0 2.95-1.184 2.95-2.684 0-.66-.175-1.19-.48-1.776L18.911 4.636c-.708-1.48-1.894-2.195-2.949-2.195-1.032 0-2.183.75-2.875 2.195L2.922 25.1c-.305.587-.48 1.116-.48 1.776 0 1.5 1.151 2.684 2.948 2.684z"
                fill={DEFAULT_COLORS.WHITE}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.252 29.559h19.356c1.798 0 2.95-1.183 2.95-2.684 0-.66-.175-1.19-.48-1.776L18.912 4.637c-1.04-2.174-3.265-1.456-3.962 0L4.783 25.1c-.305.586-.48 1.116-.48 1.775 0 1.5 1.152 2.684 2.949 2.684z"
                fill={color}
            />
            <path
                d="M26.608 30H5.39c-.994 0-1.822-.328-2.41-.864A3.013 3.013 0 0 1 2 26.874c0-.371.05-.708.143-1.034a5.03 5.03 0 0 1 .387-.944L12.693 4.441c.365-.76.85-1.346 1.38-1.751.599-.457 1.26-.69 1.89-.69.643 0 1.324.226 1.94.684.537.399 1.025.977 1.393 1.736L29.47 24.896c.16.31.29.61.381.924l.005.02A3.7 3.7 0 0 1 30 26.873c0 .892-.353 1.69-.98 2.262-.59.537-1.416.864-2.412.864zM5.39 29.116h21.218c.765 0 1.388-.24 1.82-.632.44-.4.688-.97.688-1.61 0-.288-.038-.546-.108-.793l-.004-.017a4.193 4.193 0 0 0-.318-.76l-10.182-20.5c-.302-.625-.697-1.095-1.125-1.413-.457-.34-.954-.508-1.416-.508-.442 0-.917.17-1.356.506-.429.328-.823.808-1.123 1.437L3.313 25.303c-.14.27-.25.523-.321.775-.07.25-.109.508-.109.796 0 .639.248 1.209.689 1.61.432.393 1.054.632 1.818.632z"
                fill={DEFAULT_COLORS.BLACK}
            />
            <path
                d="M16 21.93c.46 0 .834.378.834.836A.839.839 0 0 1 16 23.6a.84.84 0 0 1-.834-.835c0-.46.375-.835.834-.835zM16.444 19.927a.442.442 0 1 1-.884 0v-7.86a.443.443 0 0 1 .884 0v7.86z"
                fill={colorInner}
            />
        </SvgIcon>
    );
};

WarningIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
    colorInner: string,
};

WarningIcon.defaultProps = {
    height: '32',
    viewBox: '0 0 32 32',
    width: '32',
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
};

export default WarningIcon;
