import axios from 'axios';
import { DEFAULT_BRANDING } from 'constants/app';

/**
 * API call to fetch branding of a partner by partnerId or domain name
 *
 * @method fetchCompanyBrandingAPI
 * @param  {number|string}         searchKey The user to fetch
 * @return {Promise}
 */
export const fetchCompanyBrandingAPI = searchKey =>
    axios({
        method: 'get',
        url: `/v2/partner-brandings/${searchKey}`,
    });

/**
 * API to create branding of a partner
 *
 * @method createPartnerBrandingAPI
 * @param  {string} partnerId
 * @param  {Object} data
 * @return {Promise}
 */
export const createPartnerBrandingAPI = (partnerId, data) => {
    const url = `/v2/partner-brandings/${partnerId}`;

    return axios({
        data,
        method: 'post',
        url,
    });
};

/**
 * API to reset the branding of a partner; category can be logo or theme
 * @method resetPartnerBrandingAPI
 * @param  {string} partnerId
 * @param  {string} category
 * @return {Promise}
 */
export const resetPartnerBrandingAPI = (partnerId, category) => {
    return axios({
        method: 'patch',
        params: {
            category,
        },
        url: `/v2/partner-brandings/${partnerId}`,
    });
};

/**
 * API to update branding of a partner
 *
 * @method updatePartnerBrandingAPI
 * @param  {string} partnerId
 * @param  {Object} data
 * @return {Promise}
 */
export const updatePartnerBrandingAPI = (partnerId, data) => {
    const url = `/v2/partner-brandings/${partnerId}`;

    return axios({
        data,
        method: 'put',
        url,
    });
};

/**
 * API to upload branding logos for a partner
 *
 * @method uploadFileAPI
 * @param  {Object} file
 * @return {Promise}
 */
export const uploadPartnerBrandingFileAPI = file => {
    return axios({
        method: 'post',
        url: '/v2/documents/upload',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: {
            feature: 'branding',
        },
    });
};

/**
 * API to delete branding of a partner
 *
 * @method deletePartnerBrandingAPI
 * @param  {string} partnerId
 * @return {Promise}
 */
export const deletePartnerBrandingAPI = partnerId => {
    const url = `/v2/partner-brandings/${partnerId}`;

    return axios({
        method: 'delete',
        url,
    });
};

export const fetchPartnerBrandingByDomain = () => {
    const subDomain = window.location.hostname.split('.')[0];
    const invalidSubdomains = ['localhost', 'maester', 'apps', 'handbook'];
    if (subDomain && !invalidSubdomains.includes(subDomain)) {
        return axios({
            method: 'get',
            url: `/v2/partner-brandings/${subDomain}`,
        });
    } else {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    data: {
                        'partner-branding': {
                            branding: {
                                ...DEFAULT_BRANDING,
                                updatedBy: 472222,
                                updatedWhen: 1573804255,
                                whiteLogoBackground: 0,
                            },
                        },
                    },
                });
            }, 100);
        });
    }
};

/**
 * API to fetch Custom Login Widget settings for a partner
 *
 * @method fetchCustomLoginWidgetApi
 * @param  {string} partnerId
 * @return {Promise}
 */
export const fetchCustomLoginWidgetApi = partnerId => {
    const url = `/v2/custom-login-widget/${partnerId}`;

    return axios({
        method: 'get',
        url,
    });
};

/**
 * API to fetch the default Custom Login Widget settings
 *
 * @method fetchDefaultCustomLoginWidgetApi
 * @return {Promise}
 */
export const fetchDefaultCustomLoginWidgetApi = () => {
    const url = `/v2/custom-login-widget/8148`;

    return axios({
        method: 'get',
        url,
    });
};

/**
 * API to update Custom Login Widget settings for a partner
 *
 * @method updateCustomLoginWidgetApi
 * @param  {string} partnerId
 * @param  {Object} data
 * @return {Promise}
 */
export const updateCustomLoginWidgetApi = (partnerId, data) => {
    const url = `/v2/custom-login-widget/${partnerId}`;

    return axios({
        data,
        method: 'put',
        url,
    });
};

/**
 * API to reset the Custom Login Widget settings for a partner
 *
 * @method resetCustomLoginWidgetApi
 * @param  {string} partnerId
 * @param  {Object} data
 * @return {Promise}
 */
export const resetCustomLoginWidgetApi = (partnerId, data) => {
    const url = `/v2/custom-login-widget/${partnerId}`;

    return axios({
        data,
        method: 'patch',
        url,
    });
};

/**
 * API to upload upgrade logos for a partner
 *
 * @method uploadPartnerUpgradeAdLogoFileAPI
 * @param  {Object} file
 * @return {Promise}
 */
export const uploadPartnerUpgradeAdLogoFileAPI = file => {
    return axios({
        method: 'post',
        url: '/v2/documents/upload',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: {
            feature: 'partnerAds',
        },
    });
};
