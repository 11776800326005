import _get from 'lodash/get';

import INITIAL_STATE from './initialState';

/**
 * Reducer helper for the logout user action (success).
 *
 * @method logoutSuccess
 * @return {Object} The initial state
 */
export const logoutSuccess = () => INITIAL_STATE;

/**
 * Reducer helper for fetching category types (success).
 *
 * @method fetchContentfulCategoriesSuccess
 * @param  {Object} state  The current state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchContentfulCategoriesSuccess = (state, action) => {
    const contentType = _get(action, 'payload.payload.contentType', '');
    const documentType = _get(action, 'payload.payload.documentType', '');
    const currentCategories = _get(state, 'categories', {});

    currentCategories[documentType || contentType] = _get(action, 'payload.data.categories', []);

    return {
        ...state,
        categories: currentCategories,
    };
};

/**
 * Reducer helper for fetching content types (success).
 *
 * @method fetchContentfulContentTypesSuccess
 * @param  {Object} state  The current state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchContentfulContentTypesSuccess = (state, action) => {
    return {
        ...state,
        contentTypes: _get(action, 'payload.contentTypes', []),
    };
};

/**
 * Reducer helper for fetching document types (success).
 *
 * @method fetchContentfulDocumentTypesSuccess
 * @param  {Object} state  The current state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchContentfulDocumentTypesSuccess = (state, action) => {
    return {
        ...state,
        documentTypes: _get(action, 'payload.contentTypes', []),
    };
};

/**
 * Reducer helper for fetching the employee counts (success).
 *
 * @method fetchContentfulEmployeeCountsSuccess
 * @param  {Object} state  The current state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchContentfulEmployeeCountsSuccess = (state, action) => {
    return {
        ...state,
        employeeCounts: _get(action, 'payload.employeeCounts', []),
    };
};

/**
 * Reducer helper for fetching the jurisdictions (success).
 *
 * @method fetchContentfulJurisdictionsSuccess
 * @param  {Object} state  The current state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchContentfulJurisdictionsSuccess = (state, action) => {
    return {
        ...state,
        jurisdictions: _get(action, 'payload.jurisdictions', []),
    };
};

/**
 * Reducer helper for the fetching the featured content (success).
 *
 * @method fetchFeaturedContentSuccess
 * @param  {Object}         state  The current state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const fetchFeaturedContentSuccess = (state, action) => {
    return {
        ...state,
        info: _get(action, 'payload.results', {}),
    };
};

/**
 * Reducer helper for update the image index on featured content (success).
 *
 * @method updateFeaturedContentImageIndexSuccess
 * @param  {Object}         state  The current state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const updateFeaturedContentImageIndexSuccess = (state, action) => {
    return {
        ...state,
        currentFeaturedImageIndex: _get(action, 'payload', 0),
    };
};

/**
 * Reducer helper for fetching ehs document types (success).
 *
 * @method fetchContentfulEhsDocumentTypesSuccess
 * @param  {Object} state  The current state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchContentfulEhsDocumentTypesSuccess = (state, action) => {
    return {
        ...state,
        ehsDocumentTypes: _get(action, 'payload.contentTypes', []),
    };
};

/**
 * Reducer helper for update ehs categories (success).
 *
 * @method updateEhsCategoriesSuccess
 * @param  {Object}         state  The current state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const updateEhsCategoriesSuccess = (state, action) => {
    return {
        ...state,
        ehsCategories: _get(action, 'payload.categories', []),
    };
};
