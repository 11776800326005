import { put } from 'redux-saga/effects';

import * as uiActions from '../actions';

export function* setIdleStatusSaga(action = {}) {
    try {
        const { payload } = action;
        yield put(uiActions.setIdleStatus.success(payload));
    } catch (error) {
        yield put(uiActions.setIdleStatus.error(error));
    }
}
