import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';

import Root from 'containers/Root';
import storeConfig from 'store';

import styles from './index.module.scss';

const rootElement = document.getElementById('root');
const { store, ...rest } = storeConfig;

rootElement.classList.add(styles.root);

render(
    <Provider store={store}>
        <Root {...rest} />
    </Provider>,
    rootElement
);
