import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const ExpandIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        {...rest}
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill={color}
            d="M21.9,2.6c-0.1-0.2-0.3-0.4-0.5-0.5C21.3,2,21.1,2,21,2h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3L20,5.4V9c0,0.6,0.4,1,1,1s1-0.4,1-1V3C22,2.9,22,2.7,21.9,2.6z"
        />
        <path
            fill={color}
            d="M9.3,13.3L4,18.6V15c0-0.6-0.4-1-1-1s-1,0.4-1,1v6c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.5,0.5 C2.7,22,2.9,22,3,22h6c0.6,0,1-0.4,1-1s-0.4-1-1-1H5.4l5.3-5.3c0.4-0.4,0.4-1,0-1.4S9.7,12.9,9.3,13.3z"
        />
    </SvgIcon>
);

ExpandIcon.propTypes = {
    viewBox: string,
    width: string,
    height: string,
    color: string,
};

ExpandIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default ExpandIcon;
