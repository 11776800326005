import _get from 'lodash/get';
import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { decodeTokenAPI, fetchTokenAPI, refreshTokenAPI } from 'modules/apis';
import { getExpiresInTimestamp, getTokenUserId } from 'utils/auth';
import * as authActions from '../actions';

/**
 * Fetch the oAuth token for a given set of user credentials.
 *
 * @method fetchTokenSaga
 * @type   {Generator}
 * @param  {Object}        action The redux action
 * @return {Object|Error}
 */
export function* fetchTokenSaga(action) {
    try {
        // Extract the userCreds from the action.
        const { payload: userCreds } = action;
        // Make the call to fetch the `oAuthToken`.
        const resp = yield call(fetchTokenAPI, userCreds);
        // Extract the token from the valid response.
        const token = _get(resp, 'data', {});
        // Dispatch the `fetchToken` SUCCESS action.
        yield put(authActions.fetchToken.success(token));
        // Return the token.
        return token;
    } catch (error) {
        // Otherwise catch the error and dispatch the `fetchToken` ERROR action.
        yield put(authActions.fetchToken.error(error));
        // Return the error
        return error;
    }
}

/**
 * Decode the given oAuth token.
 *
 * @method decodeTokenSaga
 * @type   {Generator}
 * @return {Object|Error}
 */
export function* decodeTokenSaga() {
    try {
        const resp = yield call(decodeTokenAPI);
        const decodedToken = _get(resp, 'data', {});
        // Extract the expires_in UNIX timestamp (in sec.) for the token,
        // convert it into milliseconds for JavaScript purposes.
        const expiresInTimestamp = getExpiresInTimestamp(decodedToken);
        const userId = getTokenUserId(decodedToken);
        yield put(
            authActions.decodeToken.success({ expires_in_timestamp: expiresInTimestamp, userId })
        );
        return decodedToken;
    } catch (error) {
        yield put(authActions.decodeToken.error(error));
        return error;
    }
}

/**
 * Refresh the oAuth token
 *
 * @method refreshTokenSaga
 * @type   {Generator}
 */
export function* refreshTokenSaga() {
    try {
        const response = yield call(refreshTokenAPI);
        const authToken = get(response, 'data', {});
        yield put(authActions.fetchToken.success(authToken));
        const accessToken = authToken.access_token;
        yield call(decodeTokenSaga, { payload: accessToken });
        yield put(authActions.refreshToken.success(accessToken));
    } catch (error) {
        yield put(authActions.refreshToken.error(error));
    }
}
