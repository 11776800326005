import _sortBy from 'lodash/sortBy';
import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';

import { get, isEmpty } from 'ravenjs/utils/lodash';

import { LICENSE_TYPES, SKU_TYPES } from 'constants/skus';
import { isArray } from 'lodash';
/**
 * Filter the given list of skus to remove any `skuType` of 'SYSTEM'
 *
 * @method filterSystemSkusFromList
 * @param  {Array}                   list List of SKUs to filter
 * @return {Array}
 */
export const filterSystemSkusFromList = (list = []) =>
    list.filter(item => item.type !== SKU_TYPES.SYSTEM);

/**
 * Sort the given list of skus
 *
 * @method sortSkuList
 * @param  {Array} skuList List of SKUs to sort
 * @return {Array}
 */

export const sortSkuList = skuList => {
    return _sortBy(skuList, sku => {
        return sku.name.toLowerCase();
    });
};
/**
 * Format SKU details
 *
 * @param   {Object}    sku
 * @return  {Object}
 */
export const formatSKUDetails = sku => {
    const {
        description,
        id,
        isActive,
        isPartnerOnly,
        key,
        type,
        learnPackageId,
        mdlPackageMaster,
        name,
        permissions = [],
    } = sku;
    const learnPackageName = get(mdlPackageMaster, 'name', '-');

    return {
        info: [
            {
                title: 'Products and Services ID',
                value: id,
            },
            {
                title: 'Products and Services',
                value: name,
            },
            {
                title: 'Products and Services Key',
                value: key,
            },
            {
                title: 'Products and Services Type',
                value: type,
            },
            {
                title: 'Description',
                value: description || '-',
            },
            {
                title: 'Active',
                value: isActive ? 'YES' : 'NO',
            },
            {
                title: 'Partner Only',
                value: isPartnerOnly ? 'YES' : 'NO',
            },
            {
                title: 'Is Learn Package',
                value: learnPackageId ? 'YES' : 'NO',
            },
            {
                title: 'Learn Package',
                value: learnPackageName,
            },
        ],
        permissions,
    };
};

export const getSponsorshipsSkus = companySkus => {
    const sponsorships = [];
    const indexNotAllowed = [];

    _forEach(companySkus, (item, index) => {
        if (indexNotAllowed.indexOf(index) === -1) {
            const findRepeated =
                item.configurationType !== 'alternate' &&
                companySkus.find((el, indexF) => {
                    const found = indexF > index && el.id === item.id;
                    if (found) {
                        indexNotAllowed.push(indexF);
                    }
                    return found;
                });

            if (
                isEmpty(findRepeated) &&
                item.sponsorBy &&
                !sponsorships.find(row => row.id === item.id)
            ) {
                sponsorships.push(item);
            } else if (
                !isEmpty(findRepeated) &&
                findRepeated.configurationType !== 'alternate' &&
                item.configurationType !== 'alternate' &&
                !sponsorships.find(row => row.id === findRepeated.id)
            ) {
                if (findRepeated.sponsorBy) {
                    sponsorships.push(findRepeated);
                } else {
                    sponsorships.push(item);
                }
            }
        }
    });

    return sponsorships;
};

/**
 * Generate Mineral Package name from sku licenses
 *
 * @param   {Array}    licenses
 * @return  {Object}
 */
export const generateLicenseName = (licenses = []) => {
    let formattedLicenses = [];
    if (isArray(licenses)) {
        formattedLicenses = licenses.filter(({ isActive }) => isActive).map(({ name }) => name);
    }
    const uniqueLicenseName = getUniqueValues(formattedLicenses);
    return uniqueLicenseName.length ? uniqueLicenseName.join(', ') : '-';
};

export const getUniqueValues = (data = []) => {
    return _uniq(data).sort();
};

/**
 * Generate SKU Package name sku licenses
 *
 * @param   {Array}    licenses
 * @param   {Array}    ownerLicenseIds
 * @return  {Object}
 */
export const generateSKULicenseName = (licenses = [], ownerLicenseIds = []) => {
    const skuLicenseName = licenses
        .filter(({ licenseId, isActive }) => ownerLicenseIds.includes(licenseId) && isActive)
        .map(({ name }) => name);

    const skuLicenseUniqueName = getUniqueValues(skuLicenseName);

    const skuPack = [];
    if (!isEmpty(skuLicenseUniqueName)) {
        return skuLicenseUniqueName.join(', ');
    } else {
        skuPack.push('-');
    }
    return skuPack.join(', ');
};

/**
 * Generate License name from selected skus with applying ranking
 *
 * @param   {Array}    selectedSkus
 * @param   {Array}    ownerLicensesIds
 * @param   {Array}    licenseList
 * @return  {Array}
 */

export const getRankedLicenseName = (selectedSkus, ownerLicensesIds, licenseList) => {
    const packageLicensesIds = new Set();
    const premiumLicensesIds = new Set();
    const packageLicenseListLookup = new Map();

    selectedSkus.forEach(({ licenses = [] }) => {
        licenses.forEach(pack => {
            if (ownerLicensesIds.includes(pack.licenseId)) {
                if (pack.licenseType === LICENSE_TYPES.PACKAGE)
                    packageLicensesIds.add(pack.licenseId);
                else if (pack.licenseType === LICENSE_TYPES.PREMIUM)
                    premiumLicensesIds.add(pack.licenseId);
            }
        });
    });
    // Filter out licenses need to lookup from master list.
    licenseList.forEach(license => {
        if (packageLicensesIds.has(license.licenseId))
            packageLicenseListLookup.set(license.licenseId, {
                ...license,
                skus: license.skus.map(({ id }) => id),
            });
    });

    // List only sku that are added from package type license.
    const filteredSkusIds = selectedSkus
        .filter(({ licenses = [] }) =>
            licenses.some(pack => packageLicensesIds.has(pack.licenseId))
        )
        .map(({ id }) => id);

    // Filter selected sku match across the license

    const orderSet = Array.from(packageLicenseListLookup.values()).map(list => list.rank);
    const differentSku = [];
    const differentSkuId = [];
    Array.from(packageLicenseListLookup.values()).forEach(list =>
        filteredSkusIds.forEach(ele => {
            if (!list.skus.includes(ele) && !orderSet.some(el => el < list.rank)) {
                differentSku.push(list.rank);
                differentSkuId.push(ele);
            }
        })
    );
    const differentSkuNextRank = orderSet[orderSet?.sort()?.indexOf(differentSku[0]) + 1];

    const minBy = (arr, fn) => Math.min(...arr.map(val => val[fn]));
    const maxBy = (arr, fn) => Math.max(...arr.map(val => val[fn]));

    const order = {};
    Array.from(packageLicenseListLookup.values()).forEach(list => {
        order[list.rank] = list.name;
    });
    const rankIdMap = {};
    Array.from(packageLicenseListLookup.values()).forEach(list => {
        rankIdMap[list.rank] = list.licenseId;
    });
    if (differentSkuNextRank) {
        packageLicenseListLookup.delete(rankIdMap[differentSku[0]]);
    }
    const fil = filteredSkusIds.filter(id => differentSkuId.includes(id));
    const x = Array.from(packageLicenseListLookup.values()).filter(list =>
        fil.every(ele => list.skus.includes(ele))
    );

    // Pick the min ranked license based on match case.
    const y = x.length
        ? minBy(x, 'rank')
        : maxBy(Array.from(packageLicenseListLookup.values()), 'rank');

    const premiumLicenseName = licenseList
        .filter(list => premiumLicensesIds.has(list.licenseId))
        .map(list => list.name);

    if (order[y]) premiumLicenseName.push(order[y]);

    return premiumLicenseName;
};
