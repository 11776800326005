const NOTIFICATION = {
    BELL_ICON_DELETE_ALL_CANCEL: {
        event: 'notification',
        eventProperties: {
            description: 'Select Delete All Cancel via notification bell',
            selectionType: 'deleteAllCancel',
        },
    },
    BELL_ICON_DELETE_ALL_CONFIRM: {
        event: 'notification',
        eventProperties: {
            description: 'Select Delete All Confirm via notification bell',
            selectionType: 'deleteAllConfirm',
        },
    },
    BELL_ICON_DELETE_ALL_SELECT: {
        event: 'notification',
        eventProperties: {
            description: 'Select Delete All via notification bell',
            selectionType: 'deleteAllSelect',
        },
    },
    BELL_ICON_DELETE_ENTITY_CANCEL: {
        event: 'notification',
        eventProperties: {
            description: 'Cancel delete entity via notification bell',
            entityId: '',
            entityType: '',
            selectionType: 'deleteEntityCancel',
        },
    },
    BELL_ICON_DELETE_ENTITY_CONFIRM: {
        event: 'notification',
        eventProperties: {
            description: 'Confirm delete entity via notification bell',
            entityId: '',
            entityType: '',
            selectionType: 'deleteEntityConfirm',
        },
    },
    BELL_ICON_DELETE_ENTITY_SELECT: {
        event: 'notification',
        eventProperties: {
            description: 'User deletes a notification from bell Icon',
            entityId: '',
            entityType: '',
            selectionType: 'deleteEntitySelect',
        },
    },
    BELL_ICON_VIEW_ENTITY_SELECT: {
        event: 'notification',
        eventProperties: {
            description: 'User clicks on a notification from bell Icon',
            entityId: '',
            entityType: '',
            selectionType: 'viewEntity',
        },
    },
    BELL_ICON_VIEW_ALL_SELECT: {
        event: 'notification',
        eventProperties: {
            description: 'Select View All via notification bell',
            selectionType: 'viewAll',
        },
    },
    LIST_VIEW_ENTITY_SELECT: {
        event: 'notification',
        eventProperties: {
            description: 'User clicks on a notification from notifications landing page',
            entityId: '',
            entityType: '',
            selectionType: 'viewEntity',
        },
    },
    LIST_VIEW_DELETE_ENTITY_CONFIRM: {
        event: 'notification',
        eventProperties: {
            description: 'User deletes a notification from notification landing page ',
            entityId: '',
            entityType: '',
            selectionType: 'deleteEntityConfirm',
        },
    },
};

export default NOTIFICATION;
