import axios from 'axios';

/**
 * API to fetch resource by type
 *
 * @method fetchResourceByTypeAPI
 * @param  {Object} params
 * @return {Promise}
 */
export const fetchResourceByTypeAPI = params =>
    axios({
        method: 'get',
        url: '/v2/partner-settings/resources/',
        params,
    });
