import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CalendarIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon height={height} width={width} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4.636c-.552 0-1 .407-1 .91v12.727c0 .502.448.909 1 .909h14c.552 0 1-.407 1-.91V5.546c0-.502-.448-.909-1-.909H5zm-3 .91c0-1.507 1.343-2.728 3-2.728h14c1.657 0 3 1.221 3 2.727v12.728C22 19.779 20.657 21 19 21H5c-1.657 0-3-1.221-3-2.727V5.545z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 1c.552 0 1 .407 1 .91v3.635c0 .503-.448.91-1 .91s-1-.407-1-.91V1.91c0-.502.448-.909 1-.909zM8 1c.552 0 1 .407 1 .91v3.635c0 .503-.448.91-1 .91s-1-.407-1-.91V1.91C7 1.407 7.448 1 8 1zM2 9.182c0-.502.448-.91 1-.91h18c.552 0 1 .408 1 .91s-.448.909-1 .909H3c-.552 0-1-.407-1-.91z"
                fill={fill}
            />
        </SvgIcon>
    );
};

CalendarIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CalendarIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    width: '24',
    viewBox: '0 0 24 24',
    height: '24',
};

export default CalendarIcon;
