import templateReplace from 'es6-template-strings';
import { bool } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';
import Box from 'ravenjs/lib/Box';

import AppDisclaimer from 'components/AppDisclaimer';
import { CORPORATE_WEBSITE_URL } from 'constants/app';
import { DEFAULT_COLORS } from 'constants/colors';
import { FOOTER_ITEMS } from 'constants/navigation';
import FooterLink from 'components/FooterLink';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { selectors as brandingSelectors } from 'modules/branding';
import { FOOTER_LOGO_URL } from 'constants/api';
import Acl from 'modules/acl';
import { APP_PERMISSIONS } from 'constants/permissions';
import { isProductionEnvironment } from 'utils/common';
import FooterMenu from './FooterMenu';
import FooterMenuItem from './FooterMenuItem';

const FooterStyled = styled(Box)`
    background: ${DEFAULT_COLORS.BLACK};
    color: ${DEFAULT_COLORS.WHITE};
    display: flex;
    font-size: 0.688rem;
    justify-content: space-between;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${({ theme }) => theme.media.up('md')`
        padding: 0 5.125rem;
        align-items: center;
        flex: ${({ theme }) => {
            const height = getThemeProps('AppNavigation.height', null, { theme });
            return `0 0 ${height}px`;
        }};
    `};

    ${({ theme }) => theme.media.down('md')`
        flex-direction: column;
        align-items: flex-start;
        flex: ${({ theme }) => {
            const height = getThemeProps('MobileAppNavigation.height', null, { theme });
            return `0 0 ${height}px`;
        }};

    `};
`;

const FooterLogoStyled = styled.img`
    width: 129.29px;
    height: 40px;
`;

const Footer = props => {
    const { authorized, hasCustomLogo, isSoftLogin } = props;
    const showAiTermsAndConditionsLink = Acl.check(APP_PERMISSIONS.aiDigitalAssistantView);

    const linkStyle = {
        color: DEFAULT_COLORS.WHITE,
    };

    const onClickLink = urlData => () => {
        const url = isProductionEnvironment() ? urlData.PROD : urlData.NON_PROD;
        window.open(url, '_blank');
    };

    return (
        (authorized || isSoftLogin) && (
            <>
                <AppDisclaimer />
                <FooterStyled
                    id="mineralFooter"
                    className="footerPage"
                    borderWidth="0"
                    borderRadius="0"
                    margin="0"
                >
                    <FooterMenu>
                        <FooterMenuItem>
                            {!hasCustomLogo && (
                                <FooterLink href={CORPORATE_WEBSITE_URL} style={linkStyle}>
                                    <FooterLogoStyled src={FOOTER_LOGO_URL} alt="logo" />
                                </FooterLink>
                            )}
                        </FooterMenuItem>
                    </FooterMenu>
                    <FooterMenu>
                        <FooterMenuItem width="100%" marginBottom="2.5rem">
                            {templateReplace(FOOTER_ITEMS.COPY_RIGHTS.TITLE, {
                                currentYear: new Date().getFullYear(),
                            })}
                        </FooterMenuItem>
                        <FooterMenuItem>
                            <FooterLink
                                onClick={onClickLink(FOOTER_ITEMS.PRIVACY_POLICY.TO)}
                                style={linkStyle}
                            >
                                {FOOTER_ITEMS.PRIVACY_POLICY.TITLE}
                            </FooterLink>
                        </FooterMenuItem>
                        {showAiTermsAndConditionsLink && (
                            <FooterMenuItem>
                                <FooterLink
                                    onClick={onClickLink(FOOTER_ITEMS.AI_TERMS_AND_CONDITIONS.TO)}
                                    style={linkStyle}
                                >
                                    {FOOTER_ITEMS.AI_TERMS_AND_CONDITIONS.TITLE}
                                </FooterLink>
                            </FooterMenuItem>
                        )}
                        <FooterMenuItem>
                            <FooterLink
                                onClick={onClickLink(FOOTER_ITEMS.TERMS_OF_SERVICE.TO)}
                                style={linkStyle}
                            >
                                {FOOTER_ITEMS.TERMS_OF_SERVICE.TITLE}
                            </FooterLink>
                        </FooterMenuItem>
                    </FooterMenu>
                </FooterStyled>
            </>
        )
    );
};

Footer.propTypes = {
    authorized: bool,
    hasCustomLogo: bool,
    isSoftLogin: bool.isRequired,
};

Footer.defaultProps = {
    authorized: false,
    hasCustomLogo: false,
};

const mapStateToProps = createStructuredSelector({
    hasCustomLogo: brandingSelectors.hasCustomLogo,
});

export { Footer as FooterUnwrapped };
export default compose(withPublicContent, withRouter, connect(mapStateToProps))(Footer);
