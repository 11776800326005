import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

const NavLogoImageStyled = styled.img`
    height: auto;
    ${({ theme }) => theme.media.up('md')`
        max-height: 60px;
    `};
    ${({ theme }) => theme.media.down('md')`
        max-height: 40px;
    `};
    max-width: 100%;

    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('NavLogoImage.styles')}
`;

export default NavLogoImageStyled;
