import axios from 'axios';

/**
 *
 * @method  deleteLocationAPI
 *
 * @param   {string}    type
 * @param   {number}    companyId
 * @param   {number}    locationId
 * @return  {Promise}
 */
export const deleteLocationAPI = (type, companyId, locationId) =>
    axios({
        method: 'delete',
        url: `v2/${type}/${companyId}/locations/${locationId}`,
    });

/**
 * API to fetch location
 *
 * @method fetchLocationAPI
 * @param  {string}  type
 * @param  {number}  companyId
 * @param  {number}  locationId
 * @return {Promise}
 */
export const fetchLocationAPI = (type, companyId, locationId) =>
    axios({
        method: 'get',
        url: `v2/${type}/${companyId}/locations/${locationId}`,
    });

/**
 * API to save location
 *
 * @method saveLocationAPI
 * @param  {Object}  locationInfo
 * @return {Promise}
 */
export const saveLocationAPI = locationInfo => {
    const { companyId, companyType, locationId } = locationInfo;
    const method = locationId ? 'put' : 'post';
    const id = locationId || '';
    return axios({
        method,
        url: `v2/${companyType}/${companyId}/locations/${id}`,
        data: locationInfo,
    });
};

/**
 * API call to import locations.
 *
 * @method importLocationsAPI
 * @param  {Object}         usersInfo
 * @return {Promise}
 */
export const importLocationsAPI = usersInfo => {
    const { companyId, partnerId, file } = usersInfo;
    return axios({
        method: 'post',
        url: `/v2/companies/${companyId}/locations/bulk-async?partnerId=${partnerId}`,
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * API to fetch locations csv template from partner.
 *
 * @method fetchLocationsCsvPartnerTemplateAPI
 * @param  {string}         partnerId
 * @return {Promise}
 */
export const fetchLocationsCsvPartnerTemplateAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/companies/locations/csv?type=template&partnerId=${partnerId}`,
    });
};

/**
 * API to fetch locations instructions template from partner.
 *
 * @method fetchLocationsInstructionsPartnerTemplateAPI
 * @param  {string}         partnerId
 * @return {Promise}
 */
export const fetchLocationsInstructionsPartnerTemplateAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/companies/locations/template-instructions?type=template&partnerId=${partnerId} `,
        responseType: 'blob',
    });
};
