import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

import { ELEMENT_IDS } from 'constants/elements';

import { DEFAULT_COLORS } from 'constants/colors';

const AppWrapperStyled = styled.div.attrs(() => ({
    id: ELEMENT_IDS.AppWrapper,
}))`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    z-index: 0;

    ${({ theme }) => theme.media.up('md')`
        // As the minimum supported desktop width is 1280px 
        // and considering some space for vertical scrollbar. 
        min-width: 1200px;
    `};
    ${({ theme }) => theme.media.down('md')`
        min-width: 100%;
    `};

    * > ::-webkit-scrollbar {
        width: 0.625rem;
    }
    * > ::-webkit-scrollbar:horizontal {
        height: 0.375rem;
    }
    * > ::-webkit-scrollbar-track-piece {
        background-color: ${DEFAULT_COLORS.LIGHTEST};
    }
    * > ::-webkit-scrollbar-thumb:horizontal {
        background-color: ${({ theme }) =>
            getThemeProps('palette.primary.gradientColor', DEFAULT_COLORS.LIGHTBLUE, { theme })};
    }
    * > ::-webkit-scrollbar-thumb:vertical {
        background-color: ${({ theme }) =>
            getThemeProps('palette.primary.gradientColor', DEFAULT_COLORS.LIGHTBLUE, { theme })};
    }

    ${getThemeProps('AppWrapper.styles')};
`;

export default AppWrapperStyled;
