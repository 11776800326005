import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ClosePanelIcon = ({ fill, fillStroke, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <circle cx="12" cy="12" r="10" fill={fill} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.707 16.749a1.121 1.121 0 0 1-1.414 0l-6-4.143c-.39-.335-.39-.877 0-1.212l6-4.143a1.121 1.121 0 0 1 1.414 0c.39.335.39.878 0 1.212L9.414 12l5.293 3.537c.39.334.39.877 0 1.212z"
                fill={fillStroke}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-10 8.182a8.182 8.182 0 1 0 0-16.364 8.182 8.182 0 0 0 0 16.364z"
                fill={fillStroke}
            />
        </SvgIcon>
    );
};

ClosePanelIcon.propTypes = {
    fill: string,
    fillStroke: string,
    height: string,
    viewBox: string,
    width: string,
};

ClosePanelIcon.defaultProps = {
    fill: DEFAULT_COLORS.WHITE,
    fillStroke: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default ClosePanelIcon;
