import { func, number, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import Typography from 'ravenjs/lib/Typography';
import { isEmpty } from 'ravenjs/utils/lodash';
import Link from 'ravenjs/lib/Link';

import { DEFAULT_COLORS } from 'constants/colors';
import { NavigationArrowRight } from 'components/Icons';
import { USER_NAV_TITLE_MAP } from 'constants/navigation';
import { USER_NAV_ITEMS } from 'constants/proptypes';
import { APP_PERMISSIONS } from 'constants/permissions';
import Acl from 'modules/acl';
import { selectors as userSelectors } from 'modules/user';

const NavItem = styled.div`
    margin-bottom: 2.9rem;
`;

const SubNavItems = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-left: 1rem;
`;

const NavItems = props => {
    const { items, onChangeSelection, onRedirect, selected, userCompany } = props;
    const { companyId } = userCompany;
    const { partnerId } = userCompany;
    const onNavItemClick = item => () => {
        onChangeSelection(item);
    };

    const showLink = (link = {}) => {
        const { permission, to: pathUrl } = link;
        return (
            pathUrl &&
            (isEmpty(permission) ||
                (!isEmpty(permission) && Acl.check(APP_PERMISSIONS[permission])))
        );
    };

    if (selected) {
        const subNavItems = items[selected].filter(link => showLink(link)) || [];
        const navLinkStyle = {
            color: DEFAULT_COLORS.BLACK,
            fontSize: '1.118rem',
            fontWeight: 'normal',
            marginBottom: '1.5rem',
            textDecoration: 'none',
        };
        return (
            <>
                <Typography type="navMain" fontSize="24px">
                    {USER_NAV_TITLE_MAP[selected]}
                </Typography>
                <SubNavItems>
                    {subNavItems.map(item => {
                        const { title, state, to = '/futurework' } = item;
                        let link = null;
                        if (state === 'company') {
                            link = `/admin/companies/${partnerId}/${companyId}/edit?isClientUser=true`;
                        }
                        return (
                            <NavLink
                                key={state}
                                onClick={() => {
                                    onRedirect(state);
                                }}
                                style={navLinkStyle}
                                to={state === 'company' && link ? link : to}
                            >
                                {title}
                            </NavLink>
                        );
                    })}
                </SubNavItems>
            </>
        );
    }
    const userNav = Object.keys(items);
    return userNav.map(item => {
        return (
            <NavItem key={item}>
                <Link onClick={onNavItemClick(item)} style={{ textDecoration: 'none' }}>
                    <Typography color={DEFAULT_COLORS.BLACK} fontSize="1.5rem" fontWeight="bold">
                        {USER_NAV_TITLE_MAP[item]}
                        <NavigationArrowRight style={{ marginLeft: '0.8rem' }} />
                    </Typography>
                </Link>
            </NavItem>
        );
    });
};

NavItems.propTypes = {
    items: USER_NAV_ITEMS.isRequired,
    onChangeSelection: func.isRequired,
    onRedirect: func.isRequired,
    selected: string,
    userCompany: shape({ companyId: number.isRequired, partnerId: number.isRequired }).isRequired,
};

NavItems.defaultProps = {
    selected: null,
};

const mapStateToProps = createStructuredSelector({
    userCompany: userSelectors.getUserCompany,
});

export { NavItems as NavItemsUnwrapped };
export default connect(mapStateToProps, null)(NavItems);
