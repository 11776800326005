import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';

import * as authActions from 'modules/auth/actions';

import * as uiActions from '../actions';
import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'ui.loading' redux handlers.
 *
 * @type {Object}
 */
const loading = {
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [uiActions.isLoading.SUCCESS]: helpers.isLoadingSuccess,
};

// Create and export the 'ui.loading' reducer.
// NOTE: The default export will be the reducer.
export default createReducer(INITIAL_STATE, loading);

// Create and export named 'ui.loading' selectors.
// NOTE: The rest of the 'named' exports will be the selectors, for this module.

/**
 * Get all the info about the application loading.
 *
 * @method getLoading
 * @param  {Object}   state The current state
 * @return {Object}         The loading info
 */
export const getLoading = state => _get(state, 'ui.loading', {});

/**
 * Get the current loading text.
 *
 * @method getLoadingText
 * @param  {Object}       state The current state
 * @return {string}             The loading text
 */
export const getLoadingText = createSelector([getLoading], loading =>
    _get(loading, 'text', 'loading')
);

/**
 * Get the current loading state. (boolean)
 *
 * @method getLoadingState
 * @param  {Object}        state The current state
 * @return {boolean}             The loading info
 */
export const getLoadingState = createSelector([getLoading], loading =>
    _get(loading, 'loading', false)
);
