import React from 'react';
import { components } from 'react-select';

import { get } from 'ravenjs/utils/lodash';

import ArrowDownIcon from 'components/Icons/ArrowDownIcon';
import ArrowUpIcon from 'components/Icons/ArrowUpIcon';
import SearchIcon from 'components/Icons/SearchIcon';

import { DEFAULT_COLORS } from 'constants/colors';

function DropdownIndicator(props) {
    const menuIsOpen = get(props, 'selectProps.menuIsOpen', false);
    const useSearchIcon = get(props, 'selectProps.useSearchIcon', false);

    const MenuOpenIcon = useSearchIcon ? SearchIcon : ArrowUpIcon;
    const MenuClosedIcon = useSearchIcon ? SearchIcon : ArrowDownIcon;
    const iconStyles = useSearchIcon ? { height: '20', width: '20' } : {};

    return (
        <components.DropdownIndicator {...props}>
            {menuIsOpen ? (
                <MenuOpenIcon fill={DEFAULT_COLORS.BLACK} {...iconStyles} />
            ) : (
                <MenuClosedIcon fill={DEFAULT_COLORS.BLACK} {...iconStyles} />
            )}
        </components.DropdownIndicator>
    );
}

export default DropdownIndicator;
