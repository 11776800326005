import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const SearchActiveIcon = ({ fill, fillInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <rect width="20.859" height="20.859" x="1" y="1" fill={fillInner} rx="9.43" />
                    <g fill={fill} fillRule="nonzero">
                        <path d="M11.625.151c6.326 0 11.473 5.203 11.473 11.597 0 2.78-.974 5.336-2.595 7.336l5.345 5.304c.013.014-1.465 1.467-1.472 1.46l-5.315-5.276c-2.004 1.728-4.602 2.772-7.436 2.772-6.326 0-11.473-5.202-11.473-11.596S5.3.15 11.625.15zm0 2.141c-5.158 0-9.355 4.242-9.355 9.456 0 5.213 4.197 9.455 9.355 9.455s9.355-4.242 9.355-9.455c0-5.214-4.197-9.456-9.355-9.456z" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

SearchActiveIcon.propTypes = {
    fill: string,
    fillInner: string,
    height: string,
    viewBox: string,
    width: string,
};

SearchActiveIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    fillInner: DEFAULT_COLORS.YELLOW,
    height: '26',
    viewBox: '0 0 26 26',
    width: '26',
};

export default SearchActiveIcon;
