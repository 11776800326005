import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const LiveConsultationValueIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill={color}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)">
                    <g transform="translate(13.000000, 20.000000)">
                        <path
                            d="M7.02795294,18.3476706 C7.02795294,13.3842824 11.0501647,9.36207059 16.0135529,9.36207059 C20.9754353,9.36207059 24.9991529,13.3842824 24.9991529,18.3476706 L7.02795294,18.3476706 Z"
                            fill={color}
                        />
                        <path
                            d="M16.0135529,9.36056471 C18.4944,9.36056471 20.5056,7.34945882 20.5056,4.86851765 C20.5056,2.38767059 18.4944,0.376470588 16.0135529,0.376470588 C13.5326118,0.376470588 11.5215059,2.38767059 11.5215059,4.86851765 C11.5215059,7.34945882 13.5326118,9.36056471 16.0135529,9.36056471 Z"
                            fill={color}
                            fillRule="nonzero"
                        />
                        <path
                            d="M16.0134588,7.22381176 C15.0135529,7.21929412 14.1250824,6.58531765 13.7937882,5.64263529 L14.6928,5.34597647 C14.9096471,5.90164706 15.4442353,6.26607059 16.0405647,6.26607059 C16.6368941,6.26607059 17.1714824,5.90164706 17.3883294,5.34597647 L18.2316235,5.64263529 C17.9018353,6.58531765 17.0118588,7.21929412 16.0134588,7.22381176 Z"
                            fill={colorInner}
                        />
                        <path
                            d="M0.838776471,14.9353412 C0.838776471,11.5621647 3.57345882,8.82748235 6.94512941,8.82748235 C10.3183059,8.82748235 13.0529882,11.5621647 13.0529882,14.9353412 L0.838776471,14.9353412 Z"
                            fill={color}
                        />
                        <path
                            d="M6.94512941,8.82898824 C8.63181176,8.82898824 9.99905882,7.46174118 9.99905882,5.77505882 C9.99905882,4.08847059 8.63181176,2.72112941 6.94512941,2.72112941 C5.25844706,2.72112941 3.8912,4.08847059 3.8912,5.77505882 C3.8912,7.46174118 5.25844706,8.82898824 6.94512941,8.82898824 Z"
                            fill={color}
                            fillRule="nonzero"
                        />
                        <path
                            d="M6.94512941,7.37430588 C6.26748235,7.37129412 5.66211765,6.94061176 5.43774118,6.29910588 L6.04762353,6.09882353 C6.1952,6.47529412 6.55962353,6.72376471 6.96470588,6.72376471 C7.36978824,6.72376471 7.73270588,6.47529412 7.88028235,6.09882353 L8.45402353,6.29910588 C8.22814118,6.94061176 7.62578824,7.37129412 6.94512941,7.37430588 Z"
                            fill={colorInner}
                        />
                        <path
                            d="M18.9515294,14.9353412 C18.9515294,11.5621647 21.6862118,8.82748235 25.0578824,8.82748235 C28.4310588,8.82748235 31.1657412,11.5621647 31.1657412,14.9353412 L18.9515294,14.9353412 Z"
                            fill={color}
                        />
                        <path
                            d="M25.0578824,8.82898824 C26.7444706,8.82898824 28.1117176,7.46174118 28.1117176,5.77505882 C28.1117176,4.08847059 26.7444706,2.72112941 25.0578824,2.72112941 C23.3712,2.72112941 22.0039529,4.08847059 22.0039529,5.77505882 C22.0039529,7.46174118 23.3712,8.82898824 25.0578824,8.82898824 Z"
                            fill={color}
                            fillRule="nonzero"
                        />
                        <path
                            d="M25.0578824,7.37430588 C24.3802353,7.37129412 23.7748706,6.94061176 23.5504941,6.29910588 L24.1603765,6.09882353 C24.3079529,6.47529412 24.6723765,6.72376471 25.0774588,6.72376471 C25.4825412,6.72376471 25.8454588,6.47529412 25.9930353,6.09882353 L26.5667765,6.29910588 C26.3408941,6.94061176 25.7385412,7.37129412 25.0578824,7.37430588 Z"
                            fill={colorInner}
                        />
                    </g>
                    <circle id="Oval" stroke={color} cx="29" cy="29" r="28.6375" />
                </g>
            </g>
        </SvgIcon>
    );
};

LiveConsultationValueIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

LiveConsultationValueIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default LiveConsultationValueIcon;
