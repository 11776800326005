import { takeLatest } from 'redux-saga/effects';

import * as threadActions from '../actions';

import {
    fetchThreadDocumentsSaga,
    fetchInsightsDocumentsSaga,
    fetchPreviewDocumentSaga,
    fetchInsightsListSaga,
    fetchInsightsPreviewDashboardSaga,
    fetchInsightsGraphDashboardSaga,
    updateInsightsNewTagSaga,
    fetchAllRecommendationsSaga,
    fetchRecommendationByIdSaga,
    fetchRecommendationsByStateSaga,
    fetchRecommendationDetailsSaga,
    fetchRecommendationSaga,
} from './thread';

/**
 * Root saga for thread actions
 *
 * @method threadSaga
 * @type   {Generator}
 */
function* threadSaga() {
    yield takeLatest(threadActions.fetchThreadDocuments.TRIGGER, fetchThreadDocumentsSaga);
    yield takeLatest(threadActions.fetchInsightsDocuments.TRIGGER, fetchInsightsDocumentsSaga);
    yield takeLatest(threadActions.fetchPreviewDocument.TRIGGER, fetchPreviewDocumentSaga);
    yield takeLatest(threadActions.fetchInsightsList.TRIGGER, fetchInsightsListSaga);
    yield takeLatest(
        threadActions.fetchInsightsPreviewDashboard.TRIGGER,
        fetchInsightsPreviewDashboardSaga
    );
    yield takeLatest(threadActions.updateInsightsNewTag.TRIGGER, updateInsightsNewTagSaga);
    yield takeLatest(
        threadActions.fetchInsightsGraphDashboard.TRIGGER,
        fetchInsightsGraphDashboardSaga
    );
    yield takeLatest(threadActions.fetchAllRecommendations.TRIGGER, fetchAllRecommendationsSaga);
    yield takeLatest(threadActions.fetchRecommendationById.TRIGGER, fetchRecommendationByIdSaga);
    yield takeLatest(
        threadActions.fetchRecommendationsByState.TRIGGER,
        fetchRecommendationsByStateSaga
    );
    yield takeLatest(
        threadActions.fetchRecommendationDetails.TRIGGER,
        fetchRecommendationDetailsSaga
    );
    yield takeLatest(threadActions.fetchRecommendation.TRIGGER, fetchRecommendationSaga);
}

export default threadSaga;

export { fetchThreadDocumentsSaga, fetchInsightsDocumentsSaga };
