import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { THEME } from 'ravenjs/constants/theme';
import { renderCheck } from 'ravenjs/utils/viewport';

import AccordionHeaderStyled from './AccordionHeaderStyled';

const IconBox = styled.div`
    background-color: ${props => props.iconBackgroundColor};
`;

const AccordionTitle = styled.div`
    position: relative;
    flex: 1;
`;

/**
 * The header component for the Accordion.
 *
 * @method      AccordionHeader
 * @param       {Object}        props Component props
 * @constructor
 */
function AccordionHeader(props) {
    const {
        children,
        disabled,
        expanded,
        hideIcon,
        icon: Icon,
        iconPosition,
        iconColor,
        iconBackgroundColor,
        headerColor,
        onChange,
        noBorder,
        iconLeftStyle,
        alignIconTop,
        ...rest
    } = props;

    const faIconStyles = {
        transition: THEME.transitions.create('transform'),
        transform: expanded ? null : 'rotate(180)',
        color: iconColor,
    };

    return (
        <AccordionHeaderStyled
            borderRadius="0"
            disabled={disabled}
            margin="0"
            {...rest}
            style={{
                borderTop: noBorder ? 'transparent' : 'solid 1px black',
                borderBottom: noBorder ? 'transparent' : 'solid 1px black',
                justifyContent: iconPosition === 'right' ? 'space-between' : 'flex-start',
                alignItems: alignIconTop ? 'flex-start' : null,
            }}
        >
            {iconPosition === 'left' && !hideIcon && (
                <IconBox
                    iconBackgroundColor={iconBackgroundColor}
                    style={{ margin: iconPosition === 'left' ? '0 1rem 0 0' : '' }}
                    className="iconBoxElement"
                    onClick={event => {
                        onChange(event, 'icon');
                    }}
                >
                    {Icon ? (
                        <Icon
                            expanded={expanded}
                            iconPosition={iconPosition}
                            iconColor={iconColor}
                        />
                    ) : iconLeftStyle === 'left' ? (
                        <SvgIcon
                            transition={THEME.transitions.create('transform')}
                            color={iconColor}
                            width="14px"
                            height="8px"
                            viewBox="0 0 14 8"
                            margin="7px 4px 0 0"
                            style={{ transform: expanded ? null : 'rotate(-90deg)' }}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.3878 0.792893C13.7597 1.18342 13.7597 1.81658 13.3878 2.20711L7.67352 8.20711C7.30159 8.59763 6.69857 8.59763 6.32665 8.20711L0.612363 2.20711C0.240435 1.81658 0.240435 1.18342 0.612363 0.792893C0.98429 0.402368 1.5873 0.402368 1.95923 0.792893L7.00008 6.08579L12.0409 0.792893C12.4129 0.402369 13.0159 0.402369 13.3878 0.792893Z"
                                fill={iconColor}
                            />
                        </SvgIcon>
                    ) : (
                        <SvgIcon
                            transition={THEME.transitions.create('transform')}
                            color={iconColor}
                            width={renderCheck('md', 'less') ? '45' : '48'}
                            height={renderCheck('md', 'less') ? '45' : '48'}
                            viewBox="0 0 36 37"
                            style={{ transform: expanded ? 'rotate(180deg)' : null }}
                        >
                            <g fill="none" fillRule="evenodd">
                                <path
                                    d="M0.5 0.527H37.5V37.527H0.5z"
                                    transform="translate(-.5 -1)"
                                />
                                <g>
                                    <path
                                        fill={iconColor}
                                        d="M3.378 6.079L8.549.92 7.63 0 .902 6.727l6.727 6.74.92-.92-5.171-5.172c-.437-.446-.655-.665-.655-.659 0 .007.218-.205.655-.637z"
                                        transform="translate(-.5 -1) matrix(0 -1 -1 0 25.76 24.154)"
                                    />
                                </g>
                            </g>
                        </SvgIcon>
                    )}
                </IconBox>
            )}
            <AccordionTitle
                onClick={event => {
                    onChange(event, 'title');
                }}
            >
                {children}
            </AccordionTitle>
            {iconPosition === 'right' && !hideIcon && (
                <IconBox
                    iconBackgroundColor={iconBackgroundColor}
                    onClick={event => {
                        onChange(event, 'icon');
                    }}
                >
                    {Icon ? (
                        <Icon
                            expanded={expanded}
                            iconPosition={iconPosition}
                            faIconStyles={faIconStyles}
                        />
                    ) : (
                        <SvgIcon
                            transition={THEME.transitions.create('transform')}
                            color={iconColor}
                            width={renderCheck('md', 'less') ? '45' : '48'}
                            height={renderCheck('md', 'less') ? '45' : '48'}
                            viewBox="0 0 36 37"
                            style={{ transform: expanded ? 'rotate(180deg)' : null }}
                        >
                            <g fill="none" fillRule="evenodd">
                                <path
                                    d="M0.5 0.527H37.5V37.527H0.5z"
                                    transform="translate(-.5 -1)"
                                />
                                <g>
                                    <path
                                        fill={iconColor}
                                        d="M3.378 6.079L8.549.92 7.63 0 .902 6.727l6.727 6.74.92-.92-5.171-5.172c-.437-.446-.655-.665-.655-.659 0 .007.218-.205.655-.637z"
                                        transform="translate(-.5 -1) matrix(0 -1 -1 0 25.76 24.154)"
                                    />
                                </g>
                            </g>
                        </SvgIcon>
                    )}
                </IconBox>
            )}
        </AccordionHeaderStyled>
    );
}

AccordionHeader.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['right', 'left', 'none']),
    iconColor: PropTypes.string,
    iconBackgroundColor: PropTypes.string,
    headerColor: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onChange: PropTypes.func,
    noBorder: PropTypes.bool,
    hideIcon: PropTypes.bool,
    iconLeftStyle: PropTypes.string,
    alignIconTop: PropTypes.bool,
};

AccordionHeader.defaultProps = {
    children: null,
    disabled: false,
    expanded: null,
    iconPosition: 'left',
    iconColor: '#282828',
    iconBackgroundColor: '#F4F4F4',
    headerColor: null,
    icon: null,
    onChange: null,
    noBorder: false,
    hideIcon: false,
    iconLeftStyle: 'left',
    alignIconTop: false,
};

export default AccordionHeader;
