import PropTypes from 'prop-types';
import React from 'react';

import Link from 'ravenjs/lib/Link';
import { genID, getThemeProps, stringReplaceWithJSX } from 'ravenjs/utils';
import styled from 'styled-components';

const LinkStyled = styled(Link)`
    color: ${getThemeProps('palette.link.color')};
`;

const ErrorWithLinks = props => {
    const { error, linksMap } = props;

    const replaceMap = {};

    Object.entries(linksMap).forEach(([key, { onClick, text }]) => {
        replaceMap[key] = (
            <LinkStyled onClick={onClick} key={genID()}>
                {text}
            </LinkStyled>
        );
    });

    return stringReplaceWithJSX(error, replaceMap);
};

ErrorWithLinks.propTypes = {
    error: PropTypes.string,
    linksMap: PropTypes.object,
};

ErrorWithLinks.defaultProps = {
    error: '',
    linksMap: {},
};

export default ErrorWithLinks;
