import { takeLatest } from 'redux-saga/effects';

import * as hrFitnessTestActions from '../actions';

import {
    createQuestionnaireSaga,
    fetchQuestionnaireSaga,
    fetchQuestionnairesSaga,
    fetchProspectQuestionnaireSaga,
    sendResponsesSaga,
    prospectSendResponsesSaga,
} from './hrFitnessTest';

/**
 * Root saga for `HR Fitness Test`
 * Triggers sagas related to all tools events
 *
 * @method hrFitnessTestSaga
 * @type   {Generator}
 */
function* hrFitnessTestSaga() {
    yield takeLatest(hrFitnessTestActions.createQuestionnaire.TRIGGER, createQuestionnaireSaga);
    yield takeLatest(hrFitnessTestActions.fetchQuestionnaire.TRIGGER, fetchQuestionnaireSaga);
    yield takeLatest(hrFitnessTestActions.fetchQuestionnaires.TRIGGER, fetchQuestionnairesSaga);
    yield takeLatest(
        hrFitnessTestActions.fetchProspectQuestionnaire.TRIGGER,
        fetchProspectQuestionnaireSaga
    );
    yield takeLatest(hrFitnessTestActions.sendResponses.TRIGGER, sendResponsesSaga);
    yield takeLatest(hrFitnessTestActions.prospectSendResponses.TRIGGER, prospectSendResponsesSaga);
}

export default hrFitnessTestSaga;

export {
    createQuestionnaireSaga,
    fetchQuestionnaireSaga,
    fetchQuestionnairesSaga,
    sendResponsesSaga,
};
