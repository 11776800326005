import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const FpoIcon = ({ height, viewBox, width, ...rest }) => (
    <SvgIcon cursor="pointer" {...rest} width={width} height={height} viewBox={viewBox}>
        <g fill="none" fillRule="evenodd" stroke="#979797">
            <ellipse cx="9" cy="8.5" fill="#D8D8D8" rx="8.5" ry="8" />
            <path d="M9 0L9 17M0 8.5L18 8.5" />
        </g>
    </SvgIcon>
);

FpoIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

FpoIcon.defaultProps = {
    viewBox: '0 0 18 17',
    height: '17',
    width: '18',
};

export default FpoIcon;
