import constants from 'namespace-constants';
import {
    CALLBACK_ARGUMENT,
    CALLBACK_ERROR_ARGUMENT,
    ERROR_ACTION,
    WAIT_FOR_ACTION,
} from 'redux-wait-for-action';

import { ACTION_STAGES, NON_STANDARD_ACTION_STAGES } from 'constants/actions';

/**
 * Simple identity function.
 *
 * @method _identity
 * @param  {Any}    value
 * @return {Any}
 */
const _identity = value => value;

/**
 * Build an FSA (Flux Standard Action) Compliant redux-action success payload.
 *
 * @method fsaCompliantSuccessPayload
 * @param  {Object}                   action The redux-action
 * @return {Any|error}                       The payload or an Error
 */
export function fsaCompliantSuccessPayload(action) {
    return action.payload || new Error('action.payload is not defined to handle success callback.');
}

/**
 * Build an FSA (Flux Standard Action) Compliant redux-action error payload.
 *
 * @method fsaCompliantErrorPayload
 * @param  {Object}                 action The redux-action
 * @return {Any|error}                     The payload or an Error
 */
export function fsaCompliantErrorPayload(action) {
    return action.payload || new Error('action.payload is not defined to handle error callback.');
}

/**
 * Get the final payload creator for the redux-action.
 *
 * @method getFinalPayloadCreator
 * @param  {Function}               payloadCreator The payload creator
 * @return {Function|Error}
 */
export function getFinalPayloadCreator(payloadCreator) {
    return payloadCreator == null
        ? _identity
        : (head, ...args) => (head instanceof Error ? head : payloadCreator(head, ...args));
}

/**
 * Create action types for a given module.
 *
 * @example
 * // Build a list of todos
 * const list = ['ADD_TODO', 'DELETE_TODO', 'EMPTY_TODOS'];
 * // Call the createActionTypes
 * const actionTypes = createActionTypes('todo', list);
 * // Output
 * ['todo/ADD_TODO', 'todo/DELETE_TODO', 'todo/EMPTY_TODOS'];
 *
 * @method createActionTypes
 * @param  {string}          [name='']         The name of the module
 * @param  {Array}           [typesList=[]]    The list of action types to create
 * @param  {Array}           [actionStages=[]] The list of action stages to create actions for
 * @return {Array}                             The action types
 */
export const createActionTypes = (name = '', typesList = [], actionStages = ACTION_STAGES) => {
    // Determine whether the actionTypes list is actually a list.
    // eslint-disable-next-line prefer-const
    let actionTypes = Array.isArray(typesList) ? [...typesList] : [];
    // For each of the `actionTypes`...
    actionTypes.forEach(type => {
        // For each of the action stages...
        actionStages.forEach(stage => {
            // Push a new `actionType`, using the current `stage` as a suffix.
            actionTypes.push(`${type}_${stage}`);
        });
    });
    // Construct and return the namespaced constants.
    return constants(name, actionTypes, { separator: '/' });
};

/**
 * Create an (FSA) action that is to be dispatched via redux.
 *
 * NOTE: This is a fork of redux-actions.createAction() functionality.
 * Edited for our internal consumption to be used alongside `redux-wait-for-action` package.
 *
 * @method createAction
 * @see {@link https://github.com/redux-utilities/redux-actions}
 * @param  {string}          type           The redux action type
 * @param  {Function}        payloadCreator Function to create the payload for the action
 * @param  {Function}        metaCreator    Function to create the meta for the action
 * @param  {Object}          actionProps    Set of extra properties for the action
 * @return {Function|Object}
 */
export const createAction = (
    type = null,
    payloadCreator = _identity,
    metaCreator = null,
    actionProps = null
) => {
    // If `type` is missing, then we will throw an error.
    if (!type) {
        throw new Error('an action.type must be defined.');
    }
    // The final payload creator for the current action.
    const finalPayloadCreator = getFinalPayloadCreator(payloadCreator);
    /**
     * The main action creator function call.
     *
     * @method actionCreator
     * @return {Object|Function}
     */
    const actionCreator = (...args) => {
        // Build the payload of the next action.
        const payload = finalPayloadCreator(...args);
        // Create the next action, add the type and any additional `actionProps` to it.
        const action = {
            type,
            ...actionProps,
        };
        // If the incoming `payload` is an instanceof an Error class,
        // or the current 'action type' ends with the `/error` string,
        // then this is an error action.
        if (payload instanceof Error || type.endsWith('/error')) {
            action.error = true;
        }
        // As long as `payload` is not `undefined` we will add it to the action.
        // NOTE: This includes payloads of `null` value.
        if (payload !== undefined) {
            action.payload = payload;
        }
        // If the `metaCreator` is a valid function, then we will invoke it,
        // and add it to our action.
        if (typeof metaCreator === 'function' && action) {
            action.meta = metaCreator(...args);
        }
        // Lastly, return the built action.
        return action;
    };
    // Add a `toString` method to the `actionCreator`
    actionCreator.toString = () => type.toString();
    // Finally, return the `actionCreator`.
    return actionCreator;
};

/**
 * Create multiple FSA (flux standard action) for a given `module` and `actionType`.
 * We will map over our internal ACTION_STAGES for each actionType,
 * creating an FSA (function) alongside it's `actionType` (string) counterpart.
 *
 * @method createActions
 * @see    {@link https://github.com/redux-utilities/redux-actions}
 * @see    {@link https://github.com/redux-utilities/flux-standard-action}
 * @param  {string}      [module='']      The module name
 * @param  {string}      [actionType='']  The actionType to create actions for
 * @param  {Object}      [options={}]     The set of actions passed to the createActions
 * @return {Object}                       Map of the created actions
 */
export const createActions = (module = '', actionType = '', options = {}) => {
    const { metaCreator, payloadCreator } = options;
    // Identify if we need to promisify the current action.
    const promisifyAction = options.asPromise
        ? {
              // By default the redux-wait-for-action returns the `action.error` key
              // if an has an Error, we're overwriting that to return the entire payload instead.
              [CALLBACK_ERROR_ARGUMENT]: fsaCompliantErrorPayload,
              [CALLBACK_ARGUMENT]: fsaCompliantSuccessPayload,
              // The redux-wait-for-action's error action to listen for.
              [ERROR_ACTION]: `@@thr-${module}/${actionType}/error`,
              // The redux-wait-for-action's success action to listen for.
              [WAIT_FOR_ACTION]: `@@thr-${module}/${actionType}/success`,
          }
        : null;
    // This is our creator object, it'll create an FSA for us by passing in a `stage`.
    const creator = stage =>
        createAction(
            `@@thr-${module}/${actionType}/${stage}`,
            payloadCreator,
            metaCreator,
            promisifyAction
        );
    // We want to create a FSA for each of our `ACTION_STAGES`.
    return ACTION_STAGES.reduce((result, stage) => {
        // Create the action beforehand
        const actionCreator = creator(stage);
        // Return the result alongside the `actionCreator` and its `toString` counterpart.
        return Object.assign(result, {
            // Define the redux `action` (fired in sagas, etc.).
            [stage.toLowerCase()]: actionCreator,
            // Define the redux `actionType`.
            [stage.toUpperCase()]: actionCreator.toString(),
        });
    }, creator(ACTION_STAGES[0]));
};

/**
 * Update the currently fired action's suffix, removing any non-standard suffixes.
 * Non-standard suffixes include TRIGGER, REQUEST, FULFILL.
 *
 * @method getActionWithSuffix
 * @param  {string}            suffix The suffix to attach to the action
 * @return {Function}
 */
export const getActionWithSuffix = (suffix = '') => (actionType = null) => {
    // Throw an error if we do not have an action type present.
    if (!actionType) {
        throw new Error(`A valid action needs to contain a 'type' key which is not null`);
    }
    // Define the final `actionType`.
    let finalActionType = actionType;
    // We gotta make sure the `finalActionType` does not have a non-standard suffix,
    // if so then we'll replace it with the passed in suffix.
    NON_STANDARD_ACTION_STAGES.forEach(stage => {
        // If the `finalActionType` includes a non-standard suffix,
        // we'll replace that with the SUCCESS, ERROR, or ABORT suffix.
        if (finalActionType.includes(stage)) {
            finalActionType = finalActionType.replace(stage, suffix);
        }
    });
    // Return the `finalActionType` in the end.
    return finalActionType;
};
