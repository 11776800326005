import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching occupations search.
 *
 * @type {Object}
 */
export const fetchOccupationsSearch = createActions('companyPolicies', 'FETCH_OCCUPATIONS_SEARCH', {
    asPromise: true,
});

/**
 * Redux actions for fetching occupations list.
 *
 * @type {Object}
 */
export const fetchOccupationsList = createActions('companyPolicies', 'FETCH_OCCUPATIONS_LIST', {
    asPromise: true,
});

/**
 * Redux actions for fetching occupations list.
 *
 * @type {Object}
 */
export const fetchOccupationByCode = createActions('companyPolicies', 'FETCH_OCCUPATION_BY_CODE', {
    asPromise: true,
});

/**
 * Redux actions for fetching states list.
 *
 * @type {Object}
 */
export const fetchStates = createActions('companyPolicies', 'FETCH_STATES', {
    asPromise: true,
});

/**
 * Redux actions for save job description.
 *
 * @type {Object}
 */
export const saveJobDescriptionBuilder = createActions(
    'companyPolicies',
    'SAVE_JOB_DESCRIPTION_BUILDER',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for get list of job descriptions.
 *
 * @type {Object}
 */
export const fetchJobDescriptions = createActions('companyPolicies', 'FETCH_JOB_DESCRIPTIONS', {
    asPromise: true,
});

/**
 * Redux actions for delete job description.
 *
 * @type {Object}
 */
export const deleteJobDescription = createActions('companyPolicies', 'DELETE_JOB_DESCRIPTION', {
    asPromise: true,
});

/**
 * Redux actions for download job description.
 *
 * @type {Object}
 */
export const downloadJobDescription = createActions('companyPolicies', 'DOWNLOAD_JOB_DESCRIPTION', {
    asPromise: true,
});

/**
 * Redux actions for fetch job description.
 *
 * @type {Object}
 */
export const fetchJobDescription = createActions('companyPolicies', 'FETCH_JOB_DESCRIPTION', {
    asPromise: true,
});

/**
 * Redux actions for update job description.
 *
 * @type {Object}
 */
export const updateJobDescription = createActions('companyPolicies', 'UPDATE_JOB_DESCRIPTION', {
    asPromise: true,
});

/**
 * Redux actions for fetch occupation.
 *
 * @type {Object}
 */
export const fetchOccupation = createActions('companyPolicies', 'FETCH_OCCUPATION', {
    asPromise: true,
});

/**
 * Redux actions for get job description documents.
 *
 * @type {Object}
 */
export const getJobDescriptionDocuments = createActions(
    'companyPolicies',
    'GET_JOB_DESCRIPTION_DOCUMENTS',
    {
        asPromise: true,
    }
);
