export const ALMOST_THERE = 'Almost there!';
export const DEFAULT_TITLE = 'HR Compliance';
export const HR_ASSESSMENT_LANDINGPAGE_TITLE = 'HR Assessment';
export const INTRODUCTION =
    'Scroll down to learn about your latest compliance obligations. Underneath that will be Law Alerts and Federal and State Laws.';
export const ICON_LABEL = 'Icon';
export const RATING_LABEL = 'Rating';
export const WIDGETS = {
    HR_FITNESS_TEST: {
        BUTTONS: [
            {
                text: 'View Results',
                path: '',
            },
            {
                firstTimeText: 'Take HR Assessment',
                secondTimeText: 'Retake HR Assessment',
                path: 'hr-assessment',
            },
        ],
        CONTENT: {
            firstTimeText:
                'Use the HR Assessment to identify your company’s strengths and potential risks. This will help you focus on changes your company can make to be compliant and legally protected.',
            secondTimeText:
                "Review recommended action steps, or come back and take this test any time to get updated feedback on your organization's HR fitness.",
        },
        HEADER: {
            text: 'HR Assessment',
        },
        HIDE_INFO_ICON: true,
        INFO_ICON: 'Take the HR Assessment test often for pointed guidance on compliance.',
        ID: 'HR_FITNESS_TEST',
        TITLE: 'HR Assessment',
        PATH: 'hr-assessment',
    },
    LAW_ALERT: {
        DESCRIPTION: 'Keep up with the latest laws that affect your state or locality.',
        FILTER: {
            text: 'Browse by State:',
            placeholder: 'Select State',
            secondText: 'And / or browse by Category:',
            secondPlaceholder: 'Select Category',
        },
        FOOTER: {
            text: 'Go to Law Alerts',
        },
        HIDE_INFO_ICON: true,
        ID: 'LAW_ALERT',
        PATH: 'law-alerts',
        TITLE: 'Law Alerts',
    },
    LAW: {
        DESCRIPTION: 'Here are some relevant law topics that were recently updated.',
        FILTER: {
            text: 'Browse by State:',
            placeholder: 'Select State',
            secondText: 'And / or browse by Category:',
            secondPlaceholder: 'Select Category',
        },
        FOOTER: {
            text: 'Go to Federal and State Laws',
        },
        HEADER: {
            text: 'Newest',
        },
        HIDE_INFO_ICON: true,
        ID: 'LAW',
        PATH: 'laws',
        TITLE: 'Federal and State Laws',
    },
};
export const NAVIGATION_MOBILE = [
    {
        label: 'Law Alerts',
        jumpTo: 'law-alerts',
    },
    {
        label: 'Laws',
        jumpTo: 'laws',
    },
];
export const hrFitnessTestKey = 'hrFitnessTest';
export const FIND_SCORE = 'Find Your Score';
export const YOUR_SCORE = 'Your Score';
export const FEEDBACK = 'Feedback';
export const FEEDBACK_DESCRIPTION = `You’re operating in a compliance danger zone and are at high risk
for complaints and lawsuits. Dedicate some time to browsing the laws pages, comprehensive guides, Quick Takes, and other just-for-HR content to help you get up to speed and institute sound policies and
practices. We are ready to assist you in bringing your organization into full compliance!`;
export const BTN_TEXT = 'Begin';
export const VIEW_RESULTS_BTN = 'View Detailed Results';
export const RETAKE_BTN = 'Retake HR Assessment';
export const HR_TEST_DESC = `Use the HR Assessment to identify your company's strengths and potential risks. This will help you focus on changes your company can make to be compliant and reduce potential liability.`;
export const THUMBS_FEEDBACK_TITLE = 'We appreciate your feedback! Please select all that apply:';
export const THUMBS_UP_OPTIONS = {
    TEXBOX_LABEL: 'Anything else we should know about your experience with this information?',
};

export const THUMBS_DOWN_OPTIONS = {
    TEXBOX_LABEL: 'Anything else we should know about your experience with this information?',
    BUTTON_CANCEL: 'CANCEL',
    BUTTON_FEEDBACK: 'SUBMIT FEEDBACK',
};
export const FEEDBACK_MAX_CHARACTERS = 500;
