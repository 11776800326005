import PropTypes from 'prop-types';
import React from 'react';

import SwitchBase from 'ravenjs/lib/SwitchBase';

import SwitchBar from './SwitchBar';
import SwitchIcon from './SwitchIcon';
import SwitchStyled from './SwitchStyled';

/**
 * A simple Switch.
 *
 * @method      Switch
 * @param       {Object} props The props
 * @constructor
 */
function Switch(props) {
    const {
        checkedIcon,
        isDisabled,
        icon,
        margin,
        onClick,
        useSameIcon,
        isTooltip,
        ...rest
    } = props;
    const switchBaseStyle = isTooltip
        ? {
              justify: 'flex-end',
              width: '45px',
              zIndex: 2,
          }
        : {
              height: '20px',
              width: '45px',
              zIndex: 2,
          };

    return (
        <SwitchStyled isDisabled={isDisabled} margin={margin} onClick={onClick}>
            <SwitchBase
                checkedIcon={checkedIcon}
                isDisabled={isDisabled}
                icon={icon}
                style={switchBaseStyle}
                useSameIcon={useSameIcon}
                isTooltip={isTooltip}
                {...rest}
            />
            <SwitchBar disabled={isDisabled} {...rest} />
        </SwitchStyled>
    );
}

Switch.propTypes = {
    activeBarColor: PropTypes.string,
    activeIconColor: PropTypes.string,
    checked: PropTypes.bool,
    checkedIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inactiveBarColor: PropTypes.string,
    inactiveIconColor: PropTypes.string,
    isDisabled: PropTypes.bool,
    margin: PropTypes.string,
    onClick: PropTypes.func,
    useSameIcon: PropTypes.bool,
    isTooltip: PropTypes.bool,
};

Switch.defaultProps = {
    activeBarColor: 'success',
    activeIconColor: 'white',
    checked: null,
    checkedIcon: null,
    icon: SwitchIcon,
    inactiveBarColor: 'muted',
    inactiveIconColor: 'light',
    isDisabled: null,
    margin: null,
    onClick: null,
    useSameIcon: true,
    isTooltip: false,
};

export default Switch;
