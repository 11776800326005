import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const PauseIcon = ({ height, viewBox, width, fill, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99996 2.50002C4.68625 2.50002 1.99996 5.18631 1.99996 8.50002C1.99996 11.8137 4.68625 14.5 7.99996 14.5C11.3137 14.5 14 11.8137 14 8.50002C14 5.18631 11.3137 2.50002 7.99996 2.50002ZM0.666626 8.50002C0.666626 4.44993 3.94987 1.16669 7.99996 1.16669C12.05 1.16669 15.3333 4.44993 15.3333 8.50002C15.3333 12.5501 12.05 15.8334 7.99996 15.8334C3.94987 15.8334 0.666626 12.5501 0.666626 8.50002Z"
                fill={fill}
                fillOpacity="0.6"
            />
            <path
                d="M6.5 6L6.5 11"
                stroke={fill}
                strokeOpacity="0.6"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M9.5 6L9.5 11"
                stroke={fill}
                strokeOpacity="0.6"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </SvgIcon>
    );
};

PauseIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
};

PauseIcon.defaultProps = {
    height: '17',
    viewBox: '0 0 16 17',
    width: '16',
    fill: DEFAULT_COLORS.BLACK,
};

export default PauseIcon;
