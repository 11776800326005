import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    deleteJobDescriptionAPI,
    downloadJobDescriptionAPI,
    fetchJobDescriptionAPI,
    fetchJobDescriptionsAPI,
    fetchOccupationsSearchAPI,
    fetchOccupationsListAPI,
    fetchOccupationAPI,
    fetchOccupationByCodeAPI,
    fetchStatesAPI,
    getJobDescriptionDocumentsAPI,
    saveJobDescriptionBuilderAPI,
    updateJobDescriptionAPI,
} from 'modules/apis';
import * as companyPoliciesActions from '../actions';

/**
 * Function for fetching occupations search
 *
 * @method fetchOccupationsSearchSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchOccupationsSearchSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchOccupationsSearchAPI, params);
        const occupationsSearch = get(response, 'data', {});
        yield put(companyPoliciesActions.fetchOccupationsSearch.success(occupationsSearch));
        return occupationsSearch;
    } catch (error) {
        yield put(companyPoliciesActions.fetchOccupationsSearch.error(error));
        return error;
    }
}

/**
 * Function for fetching occupations list
 *
 * @method fetchOccupationsListSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchOccupationsListSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchOccupationsListAPI, params);
        const occupationsList = get(response, 'data', {});
        yield put(companyPoliciesActions.fetchOccupationsList.success(occupationsList));
        return occupationsList;
    } catch (error) {
        yield put(companyPoliciesActions.fetchOccupationsList.error(error));
        return error;
    }
}

/**
 * Function for fetching occupation by code
 *
 * @method fetchOccupationByCodeSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchOccupationByCodeSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchOccupationByCodeAPI, params);
        const occupation = get(response, 'data', {});
        yield put(companyPoliciesActions.fetchOccupationByCode.success(occupation));
        return occupation;
    } catch (error) {
        yield put(companyPoliciesActions.fetchOccupationByCode.error(error));
        return error;
    }
}

/**
 * Function for fetching states list
 *
 * @method fetchStatesSaga
 * @type   {Generator}
 * @return {Object} states
 */
export function* fetchStatesSaga() {
    try {
        const response = yield call(fetchStatesAPI);
        const states = get(response, 'data', {});
        yield put(companyPoliciesActions.fetchStates.success(states));
        return states;
    } catch (error) {
        yield put(companyPoliciesActions.fetchStates.error(error));
        return error;
    }
}

/**
 * Function for save Job description
 *
 * @method saveJobDescriptionBuilderSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} states
 */
export function* saveJobDescriptionBuilderSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(saveJobDescriptionBuilderAPI, params);
        const jdb = get(response, 'data', {});
        yield put(companyPoliciesActions.saveJobDescriptionBuilder.success(jdb));
        return jdb;
    } catch (error) {
        yield put(companyPoliciesActions.saveJobDescriptionBuilder.error(error));
        return error;
    }
}

/**
 * Function for fetching job description list
 *
 * @method fetchJobDescriptionsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchJobDescriptionsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchJobDescriptionsAPI, params);
        const occupation = get(response, 'data._embedded.job_description.0', {});
        yield put(companyPoliciesActions.fetchJobDescriptions.success(occupation));
        return occupation;
    } catch (error) {
        yield put(companyPoliciesActions.fetchJobDescriptions.error(error));
        return error;
    }
}

/**
 * Function for delete job description
 *
 * @method deleteJobDescriptionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* deleteJobDescriptionSaga(action) {
    try {
        const {
            payload: { id },
        } = action;
        const response = yield call(deleteJobDescriptionAPI, id);
        yield put(companyPoliciesActions.deleteJobDescription.success(response));
        return response;
    } catch (error) {
        yield put(companyPoliciesActions.deleteJobDescription.error(error));
        return error;
    }
}

/**
 * Function for download job description
 *
 * @method downloadJobDescriptionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* downloadJobDescriptionSaga(action) {
    try {
        const {
            payload: { id, type },
        } = action;
        const response = yield call(downloadJobDescriptionAPI, id, type);
        const download = get(response, 'data', {});
        yield put(companyPoliciesActions.downloadJobDescription.success(download));
        return download;
    } catch (error) {
        yield put(companyPoliciesActions.downloadJobDescription.error(error));
        return error;
    }
}

/**
 * Function for fetch job description
 *
 * @method fetchJobDescriptionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchJobDescriptionSaga(action) {
    try {
        const {
            payload: { id },
        } = action;
        const response = yield call(fetchJobDescriptionAPI, id);
        const job = get(response, 'data', {});
        yield put(companyPoliciesActions.fetchJobDescription.success(job));
        return job;
    } catch (error) {
        yield put(companyPoliciesActions.fetchJobDescription.error(error));
        return error;
    }
}

/**
 * Function for fetch job description
 *
 * @method updateJobDescriptionSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* updateJobDescriptionSaga(action) {
    try {
        const {
            payload: { id, data },
        } = action;
        const response = yield call(updateJobDescriptionAPI, id, data);
        const job = get(response, 'data', {});
        yield put(companyPoliciesActions.updateJobDescription.success(job));
        return job;
    } catch (error) {
        yield put(companyPoliciesActions.updateJobDescription.error(error));
        return error;
    }
}

/**
 * Function for fetch occupation
 *
 * @method fetchOccupationSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchOccupationSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchOccupationAPI, params);
        const occupation = get(response, 'data', {});
        yield put(companyPoliciesActions.fetchOccupation.success(occupation));
        return occupation;
    } catch (error) {
        yield put(companyPoliciesActions.fetchOccupation.error(error));
        return error;
    }
}

/**
 * Function for get job description documents
 *
 * @method getJobDescriptionDocumentsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* getJobDescriptionDocumentsSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(getJobDescriptionDocumentsAPI, params);
        const occupation = get(response, 'data', {});
        yield put(companyPoliciesActions.getJobDescriptionDocuments.success(occupation));
        return occupation;
    } catch (error) {
        yield put(companyPoliciesActions.getJobDescriptionDocuments.error(error));
        return error;
    }
}
