import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const BookMineralIcon = ({ width, height, viewBox, fill, colorInner, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <path
            fill={fill}
            d="M17.3,0H5C4.2,0,3.3,0.3,2.8,0.9S1.8,2.3,1.8,3.2v13.6c0,0.8,0.3,1.7,0.9,2.2S4.2,20,5,20h12.3 
            c0.5,0,0.9-0.4,0.9-0.9V0.9C18.2,0.4,17.8,0,17.3,0z M4,2.2C4.3,2,4.6,1.8,5,1.8h11.4v11.8H5c-0.5,0-0.9,0.1-1.4,0.3V3.2
            C3.6,2.8,3.8,2.5,4,2.2z M5,18.2c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h11.4v2.7H5z"
        />
        <path
            fill={colorInner}
            d="M10,4.5C9.2,5.9,8.1,7,6.7,7.8C8.1,8.5,9.2,9.7,10,11c0.8-1.4,1.9-2.5,3.3-3.2C11.9,7,10.8,5.9,10,4.5z"
        />
    </SvgIcon>
);

BookMineralIcon.propTypes = {
    colorInner: string,
    fill: string,
    width: string,
    height: string,
    viewBox: string,
};

BookMineralIcon.defaultProps = {
    colorInner: DEFAULT_COLORS.ORANGE,
    fill: DEFAULT_COLORS.LINK,
    width: '20',
    height: '20',
    viewBox: '0 0 20 20',
};

export default BookMineralIcon;
