import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching threading documents by thread ID.
 *
 * @type {Object}
 */
export const fetchThreadDocuments = createActions('threadDocuments', 'FETCH_THREAD_DOCUMENTS', {
    asPromise: true,
});

/**
 * Redux actions for fetching insights documents by thread ID.
 *
 * @type {Object}
 */
export const fetchInsightsDocuments = createActions(
    'insightsDocuments',
    'FETCH_INSIGHTS_DOCUMENTS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for updating new tag for insights
 *
 * @type {Object}
 */
export const updateInsightsNewTag = createActions('insightsDocuments', 'UPDATE_INSIGHTS_NEW_TAG', {
    asPromise: true,
});

/**
 * Redux actions for fetching documents by entry ID for preview.
 *
 * @type {Object}
 */
export const fetchPreviewDocument = createActions('insightsDocuments', 'FETCH_PREVIEW_DOCUMENT', {
    asPromise: true,
});

/**
 * Redux actions for fetching insights list for a company
 *
 * @type {Object}
 */
export const fetchInsightsList = createActions('insightslist', 'FETCH_INSIGHTS_LIST', {
    asPromise: true,
});

/**
 * Redux actions for fetching insights preview data
 *
 * @type {Object}
 */
export const fetchInsightsPreviewDashboard = createActions(
    'insightPreviewDashboard',
    'FETCH_INSIGHTS_PREVIEW_DASHBOARD',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching insights graph data
 *
 * @type {Object}
 */
export const fetchInsightsGraphDashboard = createActions(
    'insightGraphDashboard',
    'FETCH_INSIGHTS_GRAPH_DASHBOARD',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching All Recommendations
 *
 * @type {Object}
 */
export const fetchAllRecommendations = createActions(
    'allRecommendations',
    'FETCH_ALL_RECOMMENDATIONS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching Recommendation by Id
 *
 * @type {Object}
 */
export const fetchRecommendationById = createActions(
    'recommendationById',
    'FETCH_RECOMMENDATION_BY_ID',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching Recommendations by State
 *
 * @type {Object}
 */
export const fetchRecommendationsByState = createActions(
    'recommendationsByState',
    'FETCH_RECOMMENDATIONS_BY_STATE',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching Recommendations details
 *
 * @type {Object}
 */
export const fetchRecommendationDetails = createActions(
    'recommendationDetails',
    'FETCH_RECOMMENDATION_DETAILS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching Recommendations details
 *
 * @type {Object}
 */
export const fetchRecommendation = createActions(
    'recommendation',
    'FETCH_RECOMMENDATION_DASHBOARD',
    {
        asPromise: true,
    }
);
