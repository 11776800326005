import _get from 'lodash/get';
import { call, put, select } from 'redux-saga/effects';

import { getChatPendoGuideAPI, sendPendoEventAPI, sendPublicPendoEventAPI } from 'modules/apis';
import * as pendoActions from '../actions';
import { getEULAStatus } from '../../user/reducer';

/**
 * The Context data for pendo track event API.
 *
 * @method _getPendoContext
 * @return {Object}                          The context data
 */
const _getPendoContext = () => ({
    title: _get(window, 'document.title', ''),
    url: _get(window, 'location.href', ''),
    userAgent: _get(window, 'navigator.userAgent', ''),
});

/**
 * Saga to fetch thread documents by threadId
 *
 * @method sendPendoEventSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* sendPendoEventSaga(action) {
    const context = _getPendoContext();
    const { title, url, userAgent } = context;
    const EULAStatus = yield select(getEULAStatus);

    try {
        if (EULAStatus) {
            const { payload } = action;
            payload.context = {
                title,
                url,
                userAgent,
                ...payload.context,
            };
            yield call(sendPendoEventAPI, payload);
            yield put(pendoActions.sendPendoEvent.success());
        }
        return true;
    } catch (error) {
        yield put(pendoActions.sendPendoEvent.error(error));
        return error;
    }
}

/**
 * Saga to send public pendo events
 *
 * @method sendPublicPendoEventSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* sendPublicPendoEventSaga(action) {
    const context = _getPendoContext();
    const { title, url, userAgent } = context;
    try {
        const { payload } = action;
        payload.context = {
            title,
            url,
            userAgent,
            ...payload.context,
        };
        yield call(sendPublicPendoEventAPI, payload);
        yield put(pendoActions.sendPublicPendoEvent.success());
        return true;
    } catch (error) {
        yield put(pendoActions.sendPublicPendoEvent.error(error));
        return error;
    }
}

/**
 * Saga to get chat pendo guide.
 *
 * @method getChatPendoGuideSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* getChatPendoGuideSaga() {
    try {
        const resp = yield call(getChatPendoGuideAPI);
        const guideId = _get(resp, 'data.pendo_chat_guide_id', '');
        yield put(pendoActions.getChatPendoGuide.success(guideId));
        return guideId;
    } catch (error) {
        yield put(pendoActions.getChatPendoGuide.error(error));
        return error;
    }
}
