import axios from 'axios';

/**
 * Fetch Notifications
 *
 * @method fetchNotificationsAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const fetchNotificationsAPI = payload => {
    const { limit, offset, eventType } = payload;
    return axios({
        method: 'get',
        url: '/v2/notifications',
        params: {
            limit,
            offset,
            eventType,
        },
    });
};

/**
 * update Notification by ID for delete or read status
 *
 * @method updateNotificationsAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const updateNotificationsAPI = payload => {
    const { notificationId, userId, status } = payload;
    return axios({
        method: 'patch',
        url: '/v2/notifications',
        data: {
            notificationId,
            userId,
            status,
        },
    });
};

/**
 * Delete Notification by ID for delete or read status
 *
 * @method deleteNotificationsAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const deleteNotificationsAPI = payload => {
    const { notificationIds, userId } = payload;
    return axios({
        method: 'delete',
        url: '/v2/notifications',
        data: {
            notificationIds,
            userId,
        },
    });
};

/**
 * Fetch Notifications Types
 *
 * @method fetchNotificationsTypeAPI
 * @param  {string}             category
 * @return {Promise}
 */
export const fetchNotificationsTypeAPI = () => {
    return axios({
        method: 'get',
        url: '/v2/notifications/types',
    });
};

/**
 * Fetch Notifications on click of Bell Icon
 *
 * @method fetchMenuNotificationsAPI
 * @param  {string}             payload
 * @return {Promise}
 */
export const fetchMenuNotificationsAPI = payload => {
    const { limit } = payload;
    return axios({
        method: 'get',
        url: '/v2/notifications',
        params: {
            limit,
        },
    });
};
