import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ViewDocumentIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.784 12a14.703 14.703 0 0 0 2.053 2.58c1.493 1.485 3.58 2.864 6.163 2.864 2.584 0 4.67-1.379 6.163-2.865A14.7 14.7 0 0 0 20.216 12a14.698 14.698 0 0 0-2.053-2.58C16.671 7.936 14.583 6.557 12 6.557c-2.584 0-4.67 1.379-6.163 2.865A14.7 14.7 0 0 0 3.784 12zm17.383 0 .745-.348-.002-.003-.002-.005-.01-.018-.034-.06a14.286 14.286 0 0 0-.61-.96 16.271 16.271 0 0 0-1.875-2.25C17.746 6.733 15.249 5 12 5 8.75 5 6.254 6.732 4.621 8.357a16.274 16.274 0 0 0-2.36 2.994 9.562 9.562 0 0 0-.16.275l-.009.018-.002.005-.001.002s-.001.001.744.349l-.745-.348a.731.731 0 0 0 0 .696L2.833 12l-.745.348.002.003.002.005.01.018a6.273 6.273 0 0 0 .16.275 16.277 16.277 0 0 0 2.36 2.994C6.253 17.268 8.75 19 12 19s5.746-1.732 7.379-3.357a16.275 16.275 0 0 0 2.36-2.994c.055-.091.096-.164.125-.215l.034-.06.01-.018.002-.005.002-.002-.745-.349zm0 0 .745.348a.733.733 0 0 0 0-.696l-.745.348z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 10.444c-.92 0-1.667.697-1.667 1.556 0 .86.747 1.556 1.667 1.556s1.667-.697 1.667-1.556c0-.86-.746-1.556-1.667-1.556zM8.667 12c0-1.718 1.492-3.111 3.333-3.111 1.841 0 3.333 1.393 3.333 3.111 0 1.718-1.492 3.111-3.333 3.111-1.84 0-3.333-1.393-3.333-3.111z"
                fill="#000"
            />
        </SvgIcon>
    );
};

ViewDocumentIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

ViewDocumentIcon.defaultProps = {
    height: '24px',
    viewBox: '0 0 24 24',
    width: '24px',
};

export default ViewDocumentIcon;
