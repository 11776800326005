import { createActions } from 'utils/actions';

/**
 * Redux actions for send email.
 *
 * @type {Object}
 */
export const sendEmail = createActions('inProductMarketing', 'SEND_EMAIL', {
    asPromise: true,
});

/**
 * Redux actions for fetching upsell ads
 *
 * @type {Object}
 */
export const fetchUpsellAds = createActions('inProductMarketing', 'FETCH_UPSELL_ADS', {
    asPromise: true,
});

export const fetchPricing = createActions('inProductMarketing', 'FETCH_PRICING', {
    asPromise: true,
});

export const fetchPaymentPageRedirectUrl = createActions(
    'inProductMarketing',
    'FETCH_PAYMENT_PAGE_REDIRECT_URL',
    {
        asPromise: true,
    }
);

export const fetchUpgradeAds = createActions('inProductMarketing', 'FETCH_UPGRADE_ADS', {
    asPromise: true,
});

export const postUpgradeAds = createActions('inProductMarketing', 'POST_UPGRADE_ADS', {
    asPromise: true,
});

/**
 * Delete upgrade ad
 *
 * @type {Object}
 */
export const deleteUpgradeAd = createActions('inProductMarketing', 'DELETE_UPGRADE_AD', {
    asPromise: true,
});

/**
 * Get upgrade ad by id
 *
 * @type {Object}
 */
export const getUpgradeAdById = createActions('inProductMarketing', 'GET_UPGRADE_AD_ID', {
    asPromise: true,
});

/**
 * Update upgrade ad by id
 *
 * @type {Object}
 */
export const updateUpgradeAd = createActions('inProductMarketing', 'UPDATE_UPGRADE_AD_ID', {
    asPromise: true,
});
