import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ArrowLargeDownIcon = ({ fill, height, width, viewBox, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L12 17.5858L18.2929 11.2929C18.6834 10.9024 19.3166 10.9024 19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

ArrowLargeDownIcon.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

ArrowLargeDownIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    width: '24',
    viewBox: '0 0 24 24',
};

export default ArrowLargeDownIcon;
