import { takeLatest } from 'redux-saga/effects';

import * as webhookActions from '../actions';
import { fetchSecretsByGroupIdSaga, createSecretByGroupIdSaga } from './webhook';

/**
 * Root saga for webhooks
 * Triggers sagas related to all tools events
 *
 * @method webhookSaga
 * @type   {Generator}
 */
function* webhookSaga() {
    yield takeLatest(webhookActions.fetchSecretsByGroupId.TRIGGER, fetchSecretsByGroupIdSaga);
    yield takeLatest(webhookActions.createSecretByGroupId.TRIGGER, createSecretByGroupIdSaga);
}

export default webhookSaga;

export { fetchSecretsByGroupIdSaga, createSecretByGroupIdSaga };
