import { get } from 'ravenjs/utils/lodash';
import { call, put } from 'redux-saga/effects';

import {
    createRole,
    deleteRole,
    fetchRole,
    fetchRolesAPI,
    fetchRolesByPermission,
    updateRole,
} from 'modules/apis';
import { actions as uiActions } from 'modules/ui';
import { normalizePermissions } from 'utils/permissions';

import * as adminActions from '../actions';

/**
 * Create role
 *
 * @method createRoleSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} role
 */
export function* createRoleSaga(action) {
    try {
        const {
            payload: { data },
        } = action;
        yield put(uiActions.isLoading(true, 'Creating Role'));
        const response = yield call(createRole, data);
        const role = get(response, 'data.role');
        yield put(adminActions.createRole.success(role));
        return role;
    } catch (e) {
        yield put(adminActions.createRole.error(e));
        return e;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Delete role
 *
 * @method deleteRoleSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} role
 */
export function* deleteRoleSaga(action) {
    try {
        const { payload: roleId } = action;
        yield put(uiActions.isLoading(true, 'Deleting Role'));
        const response = yield call(deleteRole, roleId);
        yield put(adminActions.deleteRole.success(response));
        return response;
    } catch (e) {
        yield put(adminActions.deleteRole.error(e));
        return e;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Fetch role
 *
 * @method fetchRoleSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} role
 */
export function* fetchRoleSaga(action) {
    try {
        const { payload: roleId } = action;
        const response = yield call(fetchRole, roleId);
        const role = get(response, 'data.role');
        yield put(adminActions.fetchRole.success(role));
        return role;
    } catch (e) {
        yield put(adminActions.fetchRole.error(e));
        return e;
    }
}

/**
 * Fetch roles
 *
 * @method fetchRolesSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} roles
 */
export function* fetchRolesSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchRolesAPI, partnerInfo);
        const roles = get(resp, 'data', []);
        yield put(adminActions.fetchRoles.success(roles));
        return roles;
    } catch (error) {
        yield put(adminActions.fetchRoles.error(error));
        return error;
    }
}

/**
 * Fetch role SKUs
 *
 * @method fetchRoleSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} role
 */
export function* fetchRoleSKUsSaga(action) {
    try {
        const { payload: roleId } = action;
        const response = yield call(fetchRole, roleId);
        const role = get(response, 'data.role');
        const skus = normalizePermissions(role.permissions);
        yield put(adminActions.fetchRoleSKUs.success(skus));
        return role;
    } catch (e) {
        yield put(adminActions.fetchRoleSKUs.error(e));
        return e;
    }
}

/**
 * Update role
 *
 * @method updateRoleSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} role
 */
export function* updateRoleSaga(action) {
    try {
        const {
            payload: { data, id },
        } = action;
        yield put(uiActions.isLoading(true, 'Updating Role'));
        const response = yield call(updateRole, id, data);
        const role = get(response, 'data.role');
        yield put(adminActions.updateRole.success(role));
        return role;
    } catch (e) {
        yield put(adminActions.updateRole.error(e));
        return e;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Fetch roles for newsletter subscription
 *
 * @method fetchRolesForNewsletterSubscriptionSaga
 * @type   {Generator}
 */
export function* fetchRolesForNewsletterSubscriptionSaga() {
    try {
        const response = yield call(fetchRolesByPermission, [
            'system.preferences.update.self',
            'system.preferences.update.child',
            'system.preferences',
            'system.preferences.mineral.insights',
        ]);
        const roles = get(response, 'data.roles', []);
        yield put(adminActions.fetchRolesForNewsletterSubscription.success(roles));
    } catch (e) {
        yield put(adminActions.fetchRolesForNewsletterSubscription.error(e));
    }
}

/**
 *  Fetch roles by permissions.
 *
 * @param {Object} action
 * @type   {Generator}
 */
export function* fetchRolesByPermissionSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchRolesByPermission, payload);
        const roles = get(response, 'data.roles', []);
        yield put(adminActions.fetchRolesByPermission.success(roles));
    } catch (e) {
        yield put(adminActions.fetchRolesByPermission.error(e));
    }
}
