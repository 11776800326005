import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const LayoutIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        {...rest}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 5.4458C20 4.89352 19.5523 4.4458 19 4.4458L16 4.4458L16 9.4458L16 20.4458L19 20.4458C19.5523 20.4458 20 19.9981 20 19.4458L20 5.4458ZM14 8.4458L14 4.4458L5 4.4458C4.44772 4.4458 4 4.89352 4 5.4458L4 8.4458L14 8.4458ZM4 10.4458L14 10.4458L14 20.4458L5 20.4458C4.44771 20.4458 4 19.9981 4 19.4458L4 10.4458ZM2 9.4458L2 5.4458C2 3.78895 3.34315 2.4458 5 2.4458L15 2.4458L19 2.4458C20.6569 2.4458 22 3.78895 22 5.4458L22 19.4458C22 21.1027 20.6569 22.4458 19 22.4458L15 22.4458L5 22.4458C3.34314 22.4458 2 21.1027 2 19.4458L2 9.4458Z"
            fill={color}
        />
    </SvgIcon>
);

LayoutIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

LayoutIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default LayoutIcon;
