import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';

import Table from 'ravenjs/lib/Table';

const TableStyled = styled(Table)`
    min-width: 200px !important;
    table {
        margin-bottom: 1rem;
        background-color: transparent;
        border: 1px solid #dee2e6;
    }
    th {
        padding: 0.25rem;
        vertical-align: top;
        border-bottom: 1px solid #dee2e6;
        text-align: center;
    }
    td {
        padding: 0.25rem;
        vertical-align: top;
    }
`;

export const InsertHorizontalLine = block => {
    if (block.getType() === 'atomic') {
        return {
            component: HRLINE,
            editable: false,
        };
    }
    return null;
};

const HRLINE = props => {
    const { contentState, block } = props;
    const entityValue = block.getEntityAt(0);
    if (entityValue) {
        const entity = contentState.getEntity(block.getEntityAt(0));
        const type = entity.getType();

        let hrLine = <hr />;

        if (type === 'HORIZONTAL_LINE') {
            hrLine = <hr />;
        } else if (type === 'TABLE') {
            const boxProps = {
                margin: '0 0 1rem 0',
            };
            // eslint-disable-next-line react/no-danger
            hrLine = (
                <TableStyled
                    boxProps={boxProps}
                    dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }}
                />
            );
        }

        return hrLine;
    }
    return null;
};

HRLINE.propTypes = {
    contentState: shape({}).isRequired,
    block: shape({}).isRequired,
};
