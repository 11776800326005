import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { fetchBrokenLinksAPI, deleteBrokenLinkAPI } from 'modules/apis';
import * as reportingActions from '../actions';

/**
 * Fetch Broken Links
 *
 * @method fetchBrokenLinksSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* fetchBrokenLinksSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        params.limit = params.limit || 10;
        const response = yield call(fetchBrokenLinksAPI, params);
        const data = get(response, 'data', {});
        yield put(reportingActions.fetchBrokenLinks.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.fetchBrokenLinks.error(error));
        return error;
    }
}

/**
 * Delete a Broken Link
 *
 * @method deleteBrokenLinkSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} The data or error
 */
export function* deleteBrokenLinkSaga(action) {
    try {
        const {
            payload: { brokenLinkId },
        } = action;
        const response = yield call(deleteBrokenLinkAPI, brokenLinkId);
        const data = get(response, 'data', {});
        yield put(reportingActions.deleteBrokenLink.success(data));
        return data;
    } catch (error) {
        yield put(reportingActions.deleteBrokenLink.error(error));
        return error;
    }
}
