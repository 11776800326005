import React from 'react';
import styled from 'styled-components';

import Button from 'ravenjs/lib/Button';
import Typography from 'ravenjs/lib/Typography';

import { REFRESH_MESSAGE } from 'constants/helpCenter';

const PopupContainer = styled.div``;

const PopupVergic = () => {
    return (
        <PopupContainer className="vngage-banner vngage-dooreye vngage-hide-tab vngage-open vngage-active vngage-expand">
            <div className="vngage-content">
                <div className="vngage-message">
                    <div className="vngage-container">
                        <div className="vngage-agent-picture" />
                        <Typography type="paragraph" className="vngage-subtitle">
                            {REFRESH_MESSAGE}
                        </Typography>
                        <Button
                            className="vngage-btn"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </div>
        </PopupContainer>
    );
};

export default PopupVergic;
