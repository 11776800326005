import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const LibraryIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <path
                        fill={color}
                        d="M19.124 16.211L14.869.28C14.856.23 14.826.137 14.78 0c-.119.038-.204.064-.256.078-2.504.678-3.773 1.023-3.808 1.033-.035.01-.089.03-.16.058V.587.303H2.052V17.365h8.503V1.704l4.188 15.685c.014.05.035.14.063.267l.284-.066c2.496-.672 3.774-1.015 3.832-1.03l.27-.077-.068-.272z"
                        transform="translate(-.192 1.172)"
                    />
                    <path
                        fill={color}
                        d="M6.02 17.355L0.192 17.355 0.192 0.31 6.02 0.31z"
                        transform="translate(-.192 1.172)"
                    />
                    <path
                        fill={colorInner}
                        d="M1.271 15.494H3.968V16.494H1.271zM1.271 14.299H3.968V15.299H1.271zM6.943 15.494H9.64V16.494H6.943zM6.943 14.299H9.64V15.299H6.943zM6.943 2.422H9.64V3.422H6.943z"
                        transform="translate(-.192 1.172)"
                    />
                    <path
                        fill={colorInner}
                        d="M-3 8.376L14.066 8.376 14.066 9.31 -3 9.31z"
                        transform="translate(-.192 1.172) rotate(90 5.533 8.843)"
                    />
                    <path
                        fill={colorInner}
                        d="M6.943 1.227H9.64V2.2270000000000003H6.943z"
                        transform="translate(-.192 1.172)"
                    />
                    <path
                        fill={colorInner}
                        d="M14.465 2.777v.757h-2.697v-.757h2.697zm0-1.195v.757h-2.697v-.757h2.697z"
                        transform="translate(-.192 1.172) rotate(-14 13.116 2.558)"
                    />
                    <path
                        fill={colorInner}
                        d="M17.885 15.471v.757h-2.697v-.757h2.697zm0-1.195v.757h-2.697v-.757h2.697z"
                        transform="translate(-.192 1.172) rotate(-14 16.536 15.252)"
                    />
                    <path
                        fill={colorInner}
                        d="M1.271 2.422H3.968V3.422H1.271zM1.271 1.227H3.968V2.2270000000000003H1.271z"
                        transform="translate(-.192 1.172)"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

LibraryIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

LibraryIcon.defaultProps = {
    color: DEFAULT_COLORS.BLUE,
    colorInner: DEFAULT_COLORS.BLACK,
    height: '19',
    viewBox: '0 0 19 20',
    width: '20',
};

export default LibraryIcon;
