import _get from 'lodash/get';
import { call, put, race, take } from 'redux-saga/effects';

import {
    contactUsTicketAPI,
    customSsoLoginAPI,
    endImpersonateUserAPI,
    fetchOpenIdConnectDetailsAPI,
    fetchOpenIdIdentityToken,
    fetchTokenFromCode,
    impersonateUserAPI,
    openIdAuth,
} from 'modules/apis';
import { _loginWithAccessTokenSaga, loginSaga } from './login';
import * as authActions from '../actions';

/**
 * Saga to set isExpired state in the redux store
 *
 * @param   {Object}    action
 */
export function* setIsExpiredSaga(action) {
    try {
        const { payload } = action;
        yield put(authActions.setIsExpired.success(payload));
    } catch (e) {
        yield put(authActions.setIsExpired.error(e));
    }
}

/**
 * Saga to set LoginAttemptStatus state in the redux store
 *
 * @param   {Object}    action
 */
export function* setLoginAttemptStatusSaga(action) {
    try {
        const { payload } = action;
        yield put(authActions.setLoginAttemptStatus.success(payload));
    } catch (e) {
        yield put(authActions.setLoginAttemptStatus.error(e));
    }
}

/**
 * The saga for impersonating a user
 *
 * @method fetchImpersonateUserTokenSaga
 * @type   {Generator}
 * @param  {Object} action The redux action containing the userId
 * @return {Object|Error}
 */
export function* fetchImpersonateUserTokenSaga(action) {
    try {
        const { payload: username } = action;
        const response = yield call(impersonateUserAPI, username);
        const impersonatedUserToken = _get(response, 'data', {});
        const returnPayload = {
            impersonatedUserToken,
            impersonateUserShowStart: true,
            impersonateUserShowEnd: false,
            isImpersonatingUser: true,
        };

        yield put(authActions.fetchImpersonateUserToken.success(returnPayload));
        return returnPayload;
    } catch (error) {
        yield put(authActions.fetchImpersonateUserToken.error(error));
        return error;
    }
}

export function* fetchImpersonateUserContentSaga(action) {
    try {
        const { payload } = action;
        const { impersonatedUserToken } = payload;
        const { access_token: accessToken } = impersonatedUserToken;

        const response = yield race({
            callLogin: call(_loginWithAccessTokenSaga, accessToken),
            success: take(authActions.login.SUCCESS),
            error: take(authActions.login.ERROR),
        });

        const { success } = response;

        if (success) {
            yield put(
                authActions.fetchImpersonateUserContent.success({
                    impersonateUserShowStart: false,
                    impersonateUserShowEnd: false,
                    isImpersonatingUser: true,
                })
            );
        }

        return response;
    } catch (error) {
        yield put(authActions.fetchImpersonateUserContent.error(error));
        return error;
    }
}

export function* impersonateUserSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchImpersonateUserTokenSaga, { payload });
        yield call(fetchImpersonateUserContentSaga, { payload: response });
        yield put(authActions.impersonateUser.success());
    } catch (e) {
        yield put(authActions.impersonateUser.error(e));
    }
}

/**
 * The saga for ending user impersonation
 *
 * @method endImpersonateUserSaga
 * @type   {Generator}
 */
export function* endImpersonateUserSaga() {
    try {
        const response = yield call(endImpersonateUserAPI);
        const userToken = _get(response, 'data', {});
        const { access_token: accessToken } = userToken;

        const { error, success } = yield race({
            callLogin: call(_loginWithAccessTokenSaga, accessToken),
            success: take(authActions.login.SUCCESS),
            error: take(authActions.login.ERROR),
        });

        if (success) {
            yield put(authActions.endImpersonateUser.success({ userToken }));
        } else if (error) {
            throw error;
        }
    } catch (error) {
        yield put(authActions.endImpersonateUser.error(error));
    }
}

export function* contactUsTicketSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(contactUsTicketAPI, { payload });
        const data = _get(response, 'data', {});
        yield put(authActions.contactUsTicket.success());
        return data;
    } catch (e) {
        yield put(authActions.contactUsTicket.error(e));
        return e;
    }
}

/**
 * Saga for the Custom SSO login
 *
 * @param   {Object} action
 */
export function* customSsoLoginSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(customSsoLoginAPI, payload);
        const ssoResponse = _get(response, 'data');
        yield put(authActions.customSsoLogin.success(ssoResponse));
    } catch (error) {
        yield put(authActions.customSsoLogin.error(error));
    }
}

/**
 * Saga for the OpenId SSO Auth
 *
 * @param   {Object} action
 */
export function* openIdAuthSaga(action) {
    try {
        const { payload } = action;
        yield call(openIdAuth, { payload });
        yield put(authActions.openIdAuth.success());
    } catch (e) {
        yield put(authActions.openIdAuth.error(e));
    }
}

/**
 * Saga to fetch auth token from code
 *
 * @method fetchTokenFromCodeSaga
 * @param  {Object}               action The original redux-action
 */
export function* fetchTokenFromCodeSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchTokenFromCode, payload);
        const authResponse = _get(response, 'data');
        yield put(authActions.fetchTokenFromCode.success(authResponse));
    } catch (error) {
        yield put(authActions.fetchTokenFromCode.error(error));
    }
}

/**
 * Saga to fetch identity token for Open ID SSO
 *
 * @method fetchOpenIdIdentityTokenSaga
 * @param  {Object}               action The original redux-action
 */
export function* fetchOpenIdIdentityTokenSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchOpenIdIdentityToken, payload);
        yield put(authActions.fetchOpenIdIdentityToken.success(_get(response, 'data')));
    } catch (error) {
        yield put(authActions.fetchOpenIdIdentityToken.error(error));
    }
}

/**
 * Saga to generate domain URL And ClientID
 *
 * @method fetchOpenIdConnectDetailsSaga
 * @param  {Object}              action The original redux-action
 */
export function* fetchOpenIdConnectDetailsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchOpenIdConnectDetailsAPI, { payload });
        const data = _get(response, 'data', {});
        yield put(authActions.fetchOpenIdConnectDetails.success(data));
    } catch (e) {
        yield put(authActions.fetchOpenIdConnectDetails.error(e));
    }
}

/**
 * Saga to fetch auth access token and refresh token
 *
 * @method fetchAccessAuthTokenSaga
 * @param  {Object}               action The original redux-action
 */
export function* fetchAccessAuthTokenSaga(action) {
    try {
        const { payload } = action;
        yield call(loginSaga, { payload });
        yield put(authActions.fetchAccessAuthToken.success());
    } catch (error) {
        yield put(authActions.fetchAccessAuthToken.error(error));
    }
}
