import { bool, func, number, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Typography from 'ravenjs/lib/Typography';
import { callFunc } from 'ravenjs/utils/actions';
import Box from 'ravenjs/lib/Box';
import ButtonLink from 'ravenjs/lib/Button/ButtonLink';

import { DEFAULT_COLORS } from 'constants/colors';
import { EVENTS } from 'constants/events';
import MESSAGES from 'constants/messages';
import { ThrashIcon } from 'components/Icons';
import { actions as pendoActions } from 'modules/pendo';
import { getSimpleFormattedTimestamp } from 'utils/date';
import { STATUS } from 'utils/notifications';

const NotificationItemStyled = styled(Box)`
    align-items: center;
    background: ${({ zebra }) => (zebra ? DEFAULT_COLORS.TABLE_GREY : DEFAULT_COLORS.WHITE)};
    border: none;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    height: 67px;
    padding: 0;
    margin: 0;
`;

const ConfirmationButton = styled(Box)`
    cursor: pointer;
    width: 3.5rem;
    padding: 0;
    border-radius: 4px;
    text-align: center;
    border-color: ${DEFAULT_COLORS.DARK};
    color: ${DEFAULT_COLORS.BLACK};
    &:hover {
        background-color: ${DEFAULT_COLORS.BLUE};
        color: ${DEFAULT_COLORS.WHITE};
    }
`;

const ActionButtons = styled.div`
    display: flex;
    margin-left: auto;
`;

const NotificationItemBody = styled.div`
    align-items: center;
    display: flex;
    padding: 0 1.125rem;
    width: 100%;
`;

function NotificationItem({
    entityType,
    id,
    onClick,
    onDelete,
    summary,
    timestamp,
    onViewMore,
    sendPendoEvent,
    status,
    zebra,
}) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showSuccessDeletionMsg, setShowSuccessDeletionMsg] = useState(false);

    const [isNotificationDeleted, setisNotificationDeleted] = useState(false);

    function trackEvent(event) {
        const pendoEvent = event;
        pendoEvent.eventProperties.entityId = id;
        pendoEvent.eventProperties.entityType = entityType;
        sendPendoEvent(pendoEvent);
    }

    function handleOnClickDelete(e) {
        e.stopPropagation();
        trackEvent(EVENTS.NOTIFICATION.BELL_ICON_DELETE_ENTITY_SELECT);
        setShowDeleteConfirmation(true);
    }

    function handleOnClick() {
        callFunc(onClick, id);
    }

    function closeDeleteConfirmationMessage() {
        trackEvent(EVENTS.NOTIFICATION.BELL_ICON_DELETE_ENTITY_CANCEL);
        setShowDeleteConfirmation(false);
    }

    function handleNotificationDelete(e) {
        e.stopPropagation();
        trackEvent(EVENTS.NOTIFICATION.BELL_ICON_DELETE_ENTITY_CONFIRM);
        setShowSuccessDeletionMsg(true);
        setShowDeleteConfirmation(false);
        setisNotificationDeleted(true);
        callFunc(onDelete, id);
    }

    return (
        <>
            {!isNotificationDeleted && (
                <NotificationItemStyled gutter={false} onClick={handleOnClick} zebra={zebra}>
                    <NotificationItemBody>
                        <Typography
                            status={status}
                            color={DEFAULT_COLORS.BLACK}
                            gutterBottom="0"
                            gutterTop="0"
                            type="notificationItemMessage"
                            fontWeight={status === STATUS.READ ? '300' : '500'}
                            lineHeight="1.14"
                            padding="0 10px 0 0"
                            style={{ flexGrow: '1' }}
                        >
                            {summary}
                        </Typography>
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            gutterBottom="0"
                            gutterLeft="auto"
                            gutterRight="1.875rem"
                            gutterTop="0"
                            type="notificationItemMessage"
                            fontWeight={status === STATUS.READ ? '300' : '500'}
                        >
                            {getSimpleFormattedTimestamp(timestamp)}
                        </Typography>
                        <Typography
                            gutterBottom="0"
                            gutterTop="0"
                            type="link"
                            onClick={handleOnClickDelete}
                        >
                            <ThrashIcon height="23" width="18" />
                        </Typography>
                    </NotificationItemBody>
                </NotificationItemStyled>
            )}

            {showDeleteConfirmation && (
                <NotificationItemStyled gutter={false}>
                    <NotificationItemBody>
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            gutterTop="0"
                            gutterLeft="0.313rem"
                            justify="center"
                            fullWidth
                            gutterBottom="0"
                            fontWeight="300"
                            lineHeight="1.5"
                            type="notificationItemMessage"
                        >
                            {MESSAGES.NOTIFICATIONS.CONFIRMATION_MESSAGE}
                        </Typography>

                        <ActionButtons>
                            <ConfirmationButton margin="0" onClick={closeDeleteConfirmationMessage}>
                                <Typography type="notificationItemMessage" gutterBottom="0">
                                    No
                                </Typography>
                            </ConfirmationButton>
                            <ConfirmationButton
                                onClick={handleNotificationDelete}
                                margin="0 0 0 0.1888rem"
                            >
                                <Typography type="notificationItemMessage" gutterBottom="0">
                                    Yes
                                </Typography>
                            </ConfirmationButton>
                        </ActionButtons>
                    </NotificationItemBody>
                </NotificationItemStyled>
            )}
            {showSuccessDeletionMsg && (
                <NotificationItemStyled gutter={false}>
                    <NotificationItemBody>
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            gutterTop="0"
                            gutterLeft="0.313rem"
                            justify="center"
                            fullWidth
                            gutterBottom="0"
                            fontWeight="300"
                            lineHeight="1.5"
                            type="notificationItemMessage"
                        >
                            {MESSAGES.NOTIFICATIONS.DELETED}
                        </Typography>
                        <Typography
                            color={DEFAULT_COLORS.BLACK}
                            gutterTop="0"
                            gutterLeft="0.313rem"
                            justify="center"
                            fullWidth
                            gutterBottom="0"
                            fontWeight="300"
                            lineHeight="1.5"
                            type="notificationItemMessage"
                        >
                            {MESSAGES.NOTIFICATIONS.ALL_NOTIFICATIONS}
                            <ButtonLink onClick={onViewMore}>here.</ButtonLink>
                        </Typography>
                    </NotificationItemBody>
                </NotificationItemStyled>
            )}
        </>
    );
}

NotificationItem.propTypes = {
    entityType: string,
    id: oneOfType([string, number]),
    onClick: func.isRequired,
    onViewMore: func.isRequired,
    onDelete: func.isRequired,
    summary: string,
    timestamp: number,
    sendPendoEvent: func.isRequired,
    status: string,
    zebra: bool,
};

NotificationItem.defaultProps = {
    entityType: '',
    id: null,
    summary: null,
    timestamp: null,
    status: null,
    zebra: false,
};

const mapDispatchToProps = {
    sendPendoEvent: pendoActions.sendPendoEvent,
};

export { NotificationItem as NotificationItemUnwrapped };
export default connect(null, mapDispatchToProps)(NotificationItem);
