import axios from 'axios';

/**
 * API call to fetch secrets by group ID
 *
 * @method fetchSecretByGroupIdAPI
 * @param  {Object} params
 * @param  {string} params.groupId - the group ID
 * @return {Promise}
 */
export function fetchSecretsByGroupIdAPI({ groupId }) {
    return axios({
        method: 'get',
        url: `/groups/${groupId}/secrets`,
        type: 'hook',
    });
}

/**
 * API call to create secret for group ID
 *
 * @method createSecretByGroupIdAPI
 * @param  {Object} params
 * @param  {string} params.groupId - the group ID
 * @param  {string} params.secret - the encryption key
 * @return {Promise}
 */
export function createSecretByGroupIdAPI({ groupId, secret }) {
    return axios({
        method: 'post',
        url: `/groups/${groupId}/secrets`,
        type: 'hook',
        body: {
            secret: {
                secret,
            },
        },
    });
}
