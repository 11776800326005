import React from 'react';
import styled from 'styled-components';
import { string, bool, number } from 'prop-types';
import { ToastContainer, toast as reactToast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import { ErrorIcon, SuccessIcon, InfoIcon, AlertCircleOutline } from 'components/Icons';

const ToastContainerStyled = styled(ToastContainer)`
    && {
        width: auto;
        max-width: 100vw;
    }

    .Toastify__toast--default {
        ${({ commonStyles }) => commonStyles}
        ${({ defaultStyles }) => defaultStyles}
    }
    .Toastify__toast--error {
        ${({ commonStyles }) => commonStyles}
        ${({ errorStyles }) => errorStyles}
    }
    .Toastify__toast--warning {
        ${({ commonStyles }) => commonStyles}
        ${({ warningStyles }) => warningStyles}
    }
    .Toastify__toast--success {
        ${({ commonStyles }) => commonStyles}
        ${({ successStyles }) => successStyles}
    }
    .Toastify__toast--info {
        ${({ commonStyles }) => commonStyles}
        ${({ infoStyles }) => infoStyles}
    }
    .Toastify__progress-bar--default {
        background: rgba(255, 255, 255, 0.7);
        ${({ progressBarStyles }) => progressBarStyles}
    }
`;

const toast = {
    ...reactToast,
    success: content => {
        reactToast.success(content, {
            icon: <SuccessIcon color="white" height="24px" width="24px" />,
        });
    },
    warn: content => {
        reactToast.warn(content, {
            icon: <AlertCircleOutline color="none" height="24px" width="24px" />,
        });
    },
    error: content => {
        reactToast.error(content, {
            icon: <ErrorIcon color="white" height="24px" width="24px" />,
        });
    },
    info: content => {
        reactToast.info(content, {
            icon: <InfoIcon color="none" height="24px" width="24px" />,
        });
    },
};
const Toaster = props => {
    return <ToastContainerStyled {...props} />;
};

Toaster.propTypes = {
    autoClose: number,
    closeOnClick: bool,
    draggable: bool,
    hideProgressBar: bool,
    newestOnTop: bool,
    pauseOnFocusLoss: bool,
    pauseOnHover: bool,
    position: string,
    rtl: bool,
};

Toaster.defaultProps = {
    autoClose: 5000,
    closeOnClick: true,
    draggable: false,
    hideProgressBar: false,
    newestOnTop: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    position: 'top-center',
    rtl: false,
};

export { Toaster, toast };
