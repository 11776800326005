import _orderBy from 'lodash/orderBy';

/**
 * Build the permissions based on the user's permissions
 *
 * @method buildPermissions
 * @param  {Array}          permissions Permissions for the logged in user
 * @return {Array}                      Return the permissions for the user
 */
export const buildPermissions = permissions => {
    return permissions.map(
        permission =>
            `${permission.skuKey}.${permission.featureKey}.${
                permission.privilege ? permission.privilege.toLowerCase() : ''
            }`
    );
};

/**
 * Normalize skus permissions
 *
 * @param   {Array} permissions
 * @return  {Array}
 */
export const normalizePermissions = permissions => {
    const skus = permissions.reduce((acc, obj) => {
        const { skuId, skuKey, skuName } = obj;
        const key = obj.skuKey;

        if (!acc[key]) {
            acc[key] = {
                skuId,
                skuName,
                skuKey,
                permissions: [],
            };
        }

        acc[key].permissions.push(obj);

        return acc;
    }, {});

    const normalizedSKUs = {};

    Object.entries(skus).forEach(([key, value]) => {
        const normalizedPermissions = value.permissions.reduce((acc, obj) => {
            const { displayLabel, featureKey } = obj;
            const key = obj.featureKey;

            if (!acc[key]) {
                acc[key] = {
                    displayLabel,
                    featureKey,
                    privileges: [],
                };
            }

            acc[key].privileges.push(obj);

            return acc;
        }, {});

        normalizedSKUs[key] = {
            ...value,
            permissions: Object.values(normalizedPermissions),
        };
    });

    return _orderBy(Object.values(normalizedSKUs), 'skuName');
};
