import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const SafetyIcon = ({ height, viewBox, width, color, colorInner, colorComplementary, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g transform="translate(0.000000, 1.000000)">
                <polygon fill={colorComplementary} points="2.7,0.8 27.6,0.8 27.6,31 2.7,31" />
            </g>
            <g transform="translate(1.000000, 0.000000)">
                <polygon fill={color} points="3.4,0 28.3,0 28.3,30.2 3.4,30.2" />
            </g>
            <polygon
                fill={colorInner}
                points="23.1,13.3 18.7,13.3 18.7,8.9 15.1,8.9 15.1,13.3 10.7,13.3 10.7,16.9 15.1,16.9 15.1,21.3 18.7,21.3 18.7,16.9 23.1,16.9 "
            />
        </SvgIcon>
    );
};

SafetyIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
    colorInner: string,
    colorComplementary: string,
};

SafetyIcon.defaultProps = {
    height: '32',
    viewBox: '0 0 32 32',
    width: '32',
    color: '#DEA3AD',
    colorInner: '#FFFFFF',
    colorComplementary: '#B6354A',
};

export default SafetyIcon;
