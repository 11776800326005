import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    getDashboardInfoAPI,
    getMergeUserListAPI,
    getMergeUserAPI,
    checkCsvMassUpdateUsersAPI,
    checkCsvMergeMultipleUsersAPI,
    downloadMergeCsvAPI,
    runMergeMultipleUsersAPI,
    fetchMergeUsersInformationAPI,
    getMassUpdateUserListAPI,
    mergeSingleUserAPI,
    runMergeSingleUserAPI,
    runMassUpdateAPI,
    runSprintBatchProcessAPI,
    runMergeMultipleCompaniesAPI,
    fetchDetailsMultipleCompaniesAPI,
    bulkSingleCompanyAPI,
    checkCsvMassWelcomeEmailAPI,
    getWelcomeEmailUsersListAPI,
    fetchMassUpdateInformationAPI,
    runMassWelcomeEmailProcessAPI,
    fetchSingleCompanyInfoAPI,
    fetchMultipleCompaniesUserListAPI,
    runMoveOrMergeSingleCompanyAPI,
    getMoveOrMergeSingleCompanyListAPI,
    getMoveCompaniesListAPI,
    fetchUserListToMultipleCompaniesAPI,
    runMoveMultipleCompaniesAPI,
    checkMergePartnersAPI,
    fetchMergePartnersInformationAPI,
    getMergePartnerCompanyListAPI,
    getMergePartnerUsersListAPI,
    runMergePartnersProcessAPI,
    cancelBtcProcessByIdAPI,
    checkBctStatusServicesAPI,
    downloadCsvListAPI,
    fetchMassWelcomeEmailInformationAPI,
} from 'modules/apis/moves';
import { MOVE_TOOL } from 'constants/moveTool';
import * as adminActions from '../actions';

const { COMMON_KEYS } = MOVE_TOOL;

/**
 * Get Dashboard Info
 *
 * @method getDashboardInfoSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getDashboardInfoSaga(action) {
    try {
        const response = yield call(getDashboardInfoAPI, action.payload);
        const events = get(response, 'data', {});
        yield put(adminActions.getDashboardInfo.success(events));
        return events;
    } catch (error) {
        yield put(adminActions.getDashboardInfo.error(error));
        return error;
    }
}

/**
 * Get merge list user
 *
 * @method getMergeUserListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getMergeUserListSaga(action) {
    try {
        const response = yield call(getMergeUserListAPI, action.payload);
        const userList = get(response, 'data', {});
        yield put(adminActions.getMergeUserList.success(userList));
        return userList;
    } catch (error) {
        yield put(adminActions.getMergeUserList.error(error));
        return error;
    }
}

/**
 * Get merge user info
 *
 * @method getMergeUserSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getMergeUserSaga() {
    try {
        const response = yield call(getMergeUserAPI);
        const events = get(response, 'users', {});
        yield put(adminActions.getMergeUser.success(events));
        return events;
    } catch (error) {
        yield put(adminActions.getMergeUser.error(error));
        return error;
    }
}

/**
 * Get list of partners
 *
 * @method checkCsvMassUpdateUsersSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* checkCsvMassUpdateUsersSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(checkCsvMassUpdateUsersAPI, params);
        const label =
            params.isMerge ||
            params.isMove ||
            params.type === COMMON_KEYS.COMPANIES ||
            params.massUpdateType === COMMON_KEYS.MASS_REACTIVATE_USERS ||
            params.massUpdateType === COMMON_KEYS.MASS_DEACTIVATE_USERS
                ? 'data.result'
                : 'data.UserUpdate';
        const result = get(response, label, {});
        yield put(adminActions.checkCsvMassUpdateUsers.success(result));
        return result;
    } catch (error) {
        yield put(adminActions.checkCsvMassUpdateUsers.error(error));
        return error;
    }
}

/**
 * Get list of partners
 *
 * @method checkCsvMergeMultipleUsersSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* checkCsvMergeMultipleUsersSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(checkCsvMergeMultipleUsersAPI, params);
        const result = get(response, 'data.result', {});
        yield put(adminActions.checkCsvMergeMultipleUsers.success(result));
        return result;
    } catch (error) {
        yield put(adminActions.checkCsvMergeMultipleUsers.error(error));
        return error;
    }
}

/**
 * Get merge list user
 *
 * @method downloadMergeCsvSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* downloadMergeCsvSaga(action) {
    try {
        const response = yield call(downloadMergeCsvAPI, action.payload);
        const csv = get(response, 'data', {});
        yield put(adminActions.downloadMergeCsv.success(csv));
        return csv;
    } catch (error) {
        yield put(adminActions.downloadMergeCsv.error(error));
        return error;
    }
}

/**
 * Run merge user process
 *
 * @method runMergeMultipleUsersSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMergeMultipleUsersSaga(action) {
    try {
        const response = yield call(runMergeMultipleUsersAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.runMergeMultipleUsers.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMergeMultipleUsers.error(error));
        return error;
    }
}

/**
 * Fetch merge users information
 *
 * @method fetchMergeUsersInformationSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchMergeUsersInformationSaga(action) {
    try {
        const response = yield call(fetchMergeUsersInformationAPI, action.payload);
        const merge = get(response, 'data.request', {});
        yield put(adminActions.fetchMergeUsersInformation.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.fetchMergeUsersInformation.error(error));
        return error;
    }
}

/**
 * Get merge list user
 *
 * @method getMassUpdateUserListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getMassUpdateUserListSaga(action) {
    try {
        const response = yield call(getMassUpdateUserListAPI, action.payload);
        const userList = get(response, 'data', {});
        yield put(adminActions.getMassUpdateUserList.success(userList));
        return userList;
    } catch (error) {
        yield put(adminActions.getMassUpdateUserList.error(error));
        return error;
    }
}

/**
 * Get merge list user
 *
 * @method mergeSingleUserSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* mergeSingleUserSaga(action) {
    try {
        const response = yield call(mergeSingleUserAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.mergeSingleUser.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.mergeSingleUser.error(error));
        return error;
    }
}

/**
 * Run merge user process
 *
 * @method runMergeSingleUserSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMergeSingleUserSaga(action) {
    try {
        const response = yield call(runMergeSingleUserAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.runMergeSingleUser.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMergeSingleUser.error(error));
        return error;
    }
}

/**
 * Run mass updater process
 *
 * @method runMassUpdateSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMassUpdateSaga(action) {
    try {
        const response = yield call(runMassUpdateAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.runMassUpdate.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMassUpdate.error(error));
        return error;
    }
}

/**
 * Run sprint batch process
 *
 * @method runSprintBatchProcessSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runSprintBatchProcessSaga(action) {
    try {
        const response = yield call(runSprintBatchProcessAPI, action.payload);
        const sprint = get(response, 'data', {});
        yield put(adminActions.runSprintBatchProcess.success(sprint));
        return sprint;
    } catch (error) {
        yield put(adminActions.runSprintBatchProcess.error(error));
        return error;
    }
}

/**
 * Run merge multiple companies
 *
 * @method runMergeMultipleCompaniesSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMergeMultipleCompaniesSaga(action) {
    try {
        const response = yield call(runMergeMultipleCompaniesAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.runMergeMultipleCompanies.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMergeMultipleCompanies.error(error));
        return error;
    }
}

/**
 * Fetch details merge multiple companies
 *
 * @method fetchDetailsMultipleCompaniesSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchDetailsMultipleCompaniesSaga(action) {
    try {
        const response = yield call(fetchDetailsMultipleCompaniesAPI, action.payload);
        const merge = get(response, 'data.request', {});
        yield put(adminActions.fetchDetailsMultipleCompanies.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.fetchDetailsMultipleCompanies.error(error));
        return error;
    }
}

/**
 * Prepare merge - move single company
 *
 * @method bulkSingleCompanySaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* bulkSingleCompanySaga(action) {
    try {
        const response = yield call(bulkSingleCompanyAPI, action.payload);
        const merge = get(response, 'data.result', {});
        yield put(adminActions.bulkSingleCompany.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.bulkSingleCompany.error(error));
        return error;
    }
}

/**
 * Check csv to mass welcome email
 *
 * @method checkCsvMassWelcomeEmailSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* checkCsvMassWelcomeEmailSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(checkCsvMassWelcomeEmailAPI, params);
        const result = get(response, 'data.result', {});
        yield put(adminActions.checkCsvMassWelcomeEmail.success(result));
        return result;
    } catch (error) {
        yield put(adminActions.checkCsvMassWelcomeEmail.error(error));
        return error;
    }
}

/**
 * Get list of partners
 *
 * @method getWelcomeEmailUsersListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getWelcomeEmailUsersListSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(getWelcomeEmailUsersListAPI, params);
        const result = get(response, 'data', {});
        yield put(adminActions.getWelcomeEmailUsersList.success(result));
        return result;
    } catch (error) {
        yield put(adminActions.getWelcomeEmailUsersList.error(error));
        return error;
    }
}

/**
 * Fetch details to mass update users
 *
 * @method fetchMassUpdateInformationSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchMassUpdateInformationSaga(action) {
    try {
        const response = yield call(fetchMassUpdateInformationAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.fetchMassUpdateInformation.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.fetchMassUpdateInformation.error(error));
        return error;
    }
}

/**
 * Fetch details to mass update users
 *
 * @method runMassWelcomeEmailProcessSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMassWelcomeEmailProcessSaga(action) {
    try {
        const response = yield call(runMassWelcomeEmailProcessAPI, action.payload);
        const merge = get(response, 'data.request', {});
        yield put(adminActions.runMassWelcomeEmailProcess.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMassWelcomeEmailProcess.error(error));
        return error;
    }
}

/**
 * Fetch details to merge or move single company
 *
 * @method fetchSingleCompanyInfoSaga
 *
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchSingleCompanyInfoSaga(action) {
    try {
        const response = yield call(fetchSingleCompanyInfoAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.fetchSingleCompanyInfo.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.fetchSingleCompanyInfo.error(error));
        return error;
    }
}

/**
 * Fetch details to merge or move single company
 *
 * @method fetchMultipleCompaniesUserListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchMultipleCompaniesUserListSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(fetchMultipleCompaniesUserListAPI, params);
        const result = get(response, 'data', {});
        yield put(adminActions.fetchMultipleCompaniesUserList.success(result));
        return result;
    } catch (error) {
        yield put(adminActions.fetchMultipleCompaniesUserList.error(error));
        return error;
    }
}

/**
 * Fetch details to mass update users
 *
 * @method runMoveOrMergeSingleCompanySaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMoveOrMergeSingleCompanySaga(action) {
    try {
        const response = yield call(runMoveOrMergeSingleCompanyAPI, action.payload);
        const merge = get(response, 'data.request', {});
        yield put(adminActions.runMoveOrMergeSingleCompany.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMoveOrMergeSingleCompany.error(error));
        return error;
    }
}

/**
 * Fetch list of companies to move or merge single company
 *
 * @method getMoveOrMergeSingleCompanyListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getMoveOrMergeSingleCompanyListSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(getMoveOrMergeSingleCompanyListAPI, params);
        const result = get(response, 'data', {});
        yield put(adminActions.getMoveOrMergeSingleCompanyList.success(result));
        return result;
    } catch (error) {
        yield put(adminActions.getMoveOrMergeSingleCompanyList.error(error));
        return error;
    }
}

/**
 * Fetch list of companies to move or merge single company
 *
 * @method getMoveCompaniesListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getMoveCompaniesListSaga(action) {
    try {
        const { payload: params } = action;
        const response = yield call(getMoveCompaniesListAPI, params);
        const result = get(response, 'data', {});
        yield put(adminActions.getMoveCompaniesList.success(result));
        return result;
    } catch (error) {
        yield put(adminActions.getMoveCompaniesList.error(error));
        return error;
    }
}

/**
 * Fetch details merge multiple companies
 *
 * @method fetchUserListToMultipleCompaniesSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchUserListToMultipleCompaniesSaga(action) {
    try {
        const response = yield call(fetchUserListToMultipleCompaniesAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.fetchUserListToMultipleCompanies.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.fetchUserListToMultipleCompanies.error(error));
        return error;
    }
}

/**
 * Fetch details merge multiple companies
 *
 * @method runMoveMultipleCompaniesSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMoveMultipleCompaniesSaga(action) {
    try {
        const response = yield call(runMoveMultipleCompaniesAPI, action.payload);
        const merge = get(response, 'data.request', {});
        yield put(adminActions.runMoveMultipleCompanies.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMoveMultipleCompanies.error(error));
        return error;
    }
}

/**
 * Check merge partners process
 *
 * @method checkMergePartnersSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* checkMergePartnersSaga(action) {
    try {
        const response = yield call(checkMergePartnersAPI, action.payload);
        const merge = get(response, 'data.result', {});
        yield put(adminActions.checkMergePartners.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.checkMergePartners.error(error));
        return error;
    }
}

/**
 * Fetch merge partner information
 *
 * @method fetchMergePartnersInformationSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* fetchMergePartnersInformationSaga(action) {
    try {
        const response = yield call(fetchMergePartnersInformationAPI, action.payload);
        const merge = get(response, 'data.request', {});
        yield put(adminActions.fetchMergePartnersInformation.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.fetchMergePartnersInformation.error(error));
        return error;
    }
}

/**
 * Fetch merge partner information
 *
 * @method getMergePartnerCompanyListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getMergePartnerCompanyListSaga(action) {
    try {
        const response = yield call(getMergePartnerCompanyListAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.getMergePartnerCompanyList.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.getMergePartnerCompanyList.error(error));
        return error;
    }
}

/**
 * Fetch merge partner information
 *
 * @method getMergePartnerUsersListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* getMergePartnerUsersListSaga(action) {
    try {
        const response = yield call(getMergePartnerUsersListAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.getMergePartnerUsersList.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.getMergePartnerUsersList.error(error));
        return error;
    }
}

/**
 * Run merge partner process
 *
 * @method runMergePartnersProcessSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* runMergePartnersProcessSaga(action) {
    try {
        const response = yield call(runMergePartnersProcessAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.runMergePartnersProcess.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.runMergePartnersProcess.error(error));
        return error;
    }
}

/**
 * Cancel bulk change tool process by id
 *
 * @method cancelBtcProcessByIdSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* cancelBtcProcessByIdSaga(action) {
    try {
        const response = yield call(cancelBtcProcessByIdAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.cancelBtcProcessById.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.cancelBtcProcessById.error(error));
        return error;
    }
}

/**
 * Cancel bulk change tool process by id
 *
 * @method checkBctStatusServicesSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* checkBctStatusServicesSaga(action) {
    try {
        const response = yield call(checkBctStatusServicesAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.checkBctStatusServices.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.checkBctStatusServices.error(error));
        return error;
    }
}

/**
 * Download list of users or companies.
 *
 * @method downloadCsvListSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */
export function* downloadCsvListSaga(action) {
    try {
        const response = yield call(downloadCsvListAPI, action.payload);
        if (response.status === 202) {
            const batchProcessResponse = yield call(runSprintBatchProcessAPI, action.payload);
            const sprint = get(batchProcessResponse, 'data', {});
            yield put(adminActions.runSprintBatchProcess.success(sprint));
        }
        const merge = get(response, 'data', {});
        yield put(adminActions.downloadCsvList.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.downloadCsvList.error(error));
        return error;
    }
}

/**
 * Fetch details to mass welcome email
 *
 * @method fetchMassWelcomeEmailInformationSaga
 * @type   {Generator}
 * @param  {Object} action
 * @return {Object}
 */

export function* fetchMassWelcomeEmailInformationSaga(action) {
    try {
        const response = yield call(fetchMassWelcomeEmailInformationAPI, action.payload);
        const merge = get(response, 'data', {});
        yield put(adminActions.fetchMassWelcomeEmailInformation.success(merge));
        return merge;
    } catch (error) {
        yield put(adminActions.fetchMassWelcomeEmailInformation.error(error));
        return error;
    }
}
