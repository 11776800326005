/**
 * The uppermost private route which requires user to be authenticated.
 *
 * @type {String}
 */
export const ROOT_PRIVATE_ROUTE = '/dashboard';

export const ROOT_PRIVATE_ROUTE_PLATFORM_LITE = '/mineral-intelligence/dashboard';

/**
 * The uppermost public route which does not require the user to be authenticated.
 *
 * @type {String}
 */
export const ROOT_PUBLIC_ROUTE = '/auth/login';

/**
 * Default learn url.
 *
 * @type {String}
 */
export const LEARN_URL_DEFAULT_SECTION = '/blocks/course_categories/pages/catalog.php';

export const ROUTE_STATE_MAPPING = {
    companypolicies: 'companypolicies',
};

export const TRAINING_AVOID_ROUTES = ['/training/course/catalog'];
