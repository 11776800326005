import PropTypes from 'prop-types';
import React from 'react';

import TextArea from 'ravenjs/lib/TextArea';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import DisplayRequiredWithIconField from '../fields/DisplayRequiredWithIconField';

function TextAreaWidget(props) {
    const {
        autofocus,
        disabled,
        formContext,
        id,
        onBlur,
        onChange,
        onFocus,
        options,
        readonly,
        registry,
        schema,
        value,
        required,
        ...inputProps
    } = props;

    if (!id) {
        console.log('No id for TextAreaWidget :', props); // eslint-disable-line no-console
        throw new Error(`no id for TextAreaWidget ${JSON.stringify(props)}`);
    }

    const { inputType = '', emptyValue = '', label, enumOptions, ...optionProps } = options;

    inputProps.type = inputType || inputProps.type || 'textarea';

    const _onChange = ({ target: { value } }) => onChange(value === '' ? emptyValue : value);

    const { rawErrors, ...cleanProps } = inputProps;
    const row = get(options, 'row', 5);
    const pristine = get(formContext, 'pristine', false);
    const taintedFields = get(formContext, 'taintedFields', []);

    let showRequired = false;
    if (!isEmpty(taintedFields)) {
        showRequired = (!pristine || taintedFields?.includes(id)) && required && isEmpty(value);
    }

    return (
        <>
            <TextArea
                autoFocus={autofocus}
                disabled={disabled}
                id={id}
                readOnly={readonly}
                value={value == null ? '' : value}
                showRequired={showRequired}
                required={required}
                rows={row}
                {...optionProps}
                {...cleanProps}
                onChange={_onChange}
                onBlur={typeof onBlur === 'function' && (event => onBlur(id, event.target.value))}
                onFocus={
                    typeof onFocus === 'function' && (event => onFocus(id, event.target.value))
                }
            />
            {showRequired && <DisplayRequiredWithIconField />}
        </>
    );
}

TextAreaWidget.propTypes = {
    autofocus: PropTypes.bool,
    disabled: PropTypes.bool,
    formContext: PropTypes.object,
    id: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.object,
    readonly: PropTypes.bool,
    registry: PropTypes.object,
    required: PropTypes.bool,
    schema: PropTypes.object,
    value: PropTypes.any,
};

TextAreaWidget.defaultProps = {
    autofocus: false,
    disabled: false,
    formContext: null,
    onBlur: false,
    onChange: false,
    onFocus: false,
    options: null,
    readonly: false,
    registry: null,
    required: false,
    schema: null,
    value: null,
};

export default TextAreaWidget;
