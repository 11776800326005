import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const TimelapseFilledIcon = ({ height, viewBox, width, color, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                d="M16.2,7.8C15.1,6.6,13.5,6,12,6v6l-4.2,4.2c2.3,2.3,6.1,2.3,8.5,0C18.6,13.9,18.6,10.1,16.2,7.8z M12,2C6.5,2,2,6.5,2,12
                s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z"
                fill={color}
            />
        </SvgIcon>
    );
};

TimelapseFilledIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

TimelapseFilledIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
    color: `${DEFAULT_COLORS.BLACK}`,
};

export default TimelapseFilledIcon;
