const JDB = {
    SEARCH: {
        event: 'jdbsearchClick',
        eventProperties: {
            description: 'Search click',
        },
    },
    SEARCH_JDB: {
        event: 'jdbSearch',
        eventProperties: {
            description: 'Search of JDB',
        },
    },
    SAVED_JOBS: {
        event: 'jdbMySavedJobsClick',
        eventProperties: {
            description: 'My Saved jobs Click',
        },
    },
    DOWNLOAD_WORK_DOC: {
        event: 'jdbDownloadWordDoc',
        eventProperties: {
            description: 'Save jobs download word doc',
        },
    },
    DOWNLOAD_PDF: {
        event: 'jdbDownloadPdf',
        eventProperties: {
            description: 'Save jobs download pdf',
        },
    },
    PRINT: {
        event: 'jdbPrint',
        eventProperties: {
            description: 'Save jobs print',
        },
    },
    DELETE: {
        event: 'jdbDelete',
        eventProperties: {
            description: 'Save jobs delete',
        },
    },
    EDIT: {
        event: 'jdbEdit',
        eventProperties: {
            description: 'Save jobs edit',
        },
    },
    SAVE_JOB_DESCRIPTION: {
        event: 'jdbSaveJobDescription',
        eventProperties: {
            description: 'Save job description',
        },
    },
    FINISH_JOB_DESCRIPTION: {
        event: 'jdbFinishJobDescription',
        eventProperties: {
            description: 'Finish job description',
        },
    },
    DELETE_SECTION: {
        event: 'jdbDeleteSection',
        eventProperties: {
            description: 'Delete section click',
        },
    },
    EDIT_SECTION: {
        event: 'jdbEditSection',
        eventProperties: {
            description: 'Edit section click',
        },
    },
};

export default JDB;
