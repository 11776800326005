import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ActionListIcon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path fill="#FFAD76" d="M0 0H14V14H0z" />
                        <path
                            fill="#282828"
                            d="M3.129 4.599L1.813 3.395 2.282 2.877 2.94 3.479 4.053 1.456 4.662 1.792zM5.082 2.891H12.082V3.891H5.082zM3.129 8.505L1.813 7.301 2.282 6.79 2.94 7.385 4.053 5.362 4.662 5.698zM5.082 6.797H12.082V7.797H5.082zM3.129 12.411L1.813 11.207 2.282 10.696 2.94 11.298 4.053 9.268 4.662 9.604zM5.082 10.703H12.082V11.703H5.082z"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ActionListIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

ActionListIcon.defaultProps = {
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ActionListIcon;
