import React from 'react';
import _find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import WistiaPlayerEmbed from 'components/WistiaPlayerEmbed';
import {
    ArrowRightIcon,
    BookOpenIcon,
    HomeIcon,
    LayoutIcon,
    MessageIcon,
    MessageSquareIcon,
    MouseIcon,
    PlayIcon,
    WatchIcon,
} from 'components/Icons';

import { MODALS } from 'constants/modals';
import { DEFAULT_COLORS } from 'constants/colors';
import { BUTTONS_LABELS, HELP_CENTER_BREADCRUMB } from 'constants/helpCenter';
import HELP_CENTER from 'constants/events/helpCenter';

export const getBreadcrumbs = (history, title, sectionBreadcrumb) => {
    const items = [
        {
            type: 'breadCrumbLink',
            id: 'home',
            label: <HomeIcon width="18" height="18" />,
            onClick: () => {
                history.push('/dashboard');
            },
        },
        {
            type: 'breadCrumbLink',
            id: HELP_CENTER_BREADCRUMB.ID,
            label: HELP_CENTER_BREADCRUMB.LABEL,
            onClick: () => {
                history.push(HELP_CENTER_BREADCRUMB.PATH);
            },
        },
        {
            type: 'breadCrumbLinkActive',
            label: title,
        },
    ];

    if (!isEmpty(sectionBreadcrumb)) {
        items.splice(2, 0, {
            type: 'breadCrumbLink',
            id: 'section',
            label: sectionBreadcrumb.label,
            onClick: () => {
                history.push(`/help-center/${sectionBreadcrumb.path}`);
            },
        });
    }
    return {
        boxProps: {
            backgroundColor: 'transparent',
        },
        separator: <ArrowRightIcon width="10" height="10" />,
        items: items.filter(item => item.label),
    };
};

export const formatFaqs = faqs => {
    const formatedFaqs = faqs.map(item => {
        const itemAnswer = _find(item.faqContentPreview, { contentPreviewType: 'text_preview' });
        let itemMedia = _find(item.faqContentPreview, { contentPreviewType: 'image_preview' });

        if (isEmpty(itemMedia)) {
            itemMedia = _find(item.faqContentPreview, { contentPreviewType: 'video_preview' });
        }

        return {
            answer: itemAnswer?.content,
            categoryName: get(item, 'categories.0.title'),
            category: get(item, 'categories.0.slug'),
            isFavorite: item.isFavorite,
            guid: item.guid,
            question: item.name,
            thumbnailUrl: itemMedia?.content,
            videoUrl: itemMedia?.videoUrl,
        };
    });

    return formatedFaqs;
};

export const formatSidebarItems = (categories, userPermissions, sidebarItems, slugToAdd) => {
    let formatedCategories = [];

    if (isEmpty(sidebarItems)) {
        formatedCategories = _orderBy(
            Object.values(categories).map(item => {
                return {
                    elementId: `container_category${item.slug}`,
                    expanded: item.slug === slugToAdd,
                    order: item.order,
                    slug: item.slug,
                    subItems: formatCategories(item.categories, userPermissions),
                    title: item.title,
                };
            }),
            ['order'],
            ['asc']
        );
    } else {
        formatedCategories = sidebarItems.map(item => {
            const itemCategories = get(categories, `${slugToAdd}.categories`, {});
            return {
                ...item,
                expanded: item.expanded || item.slug === slugToAdd,
                subItems:
                    item.slug === slugToAdd
                        ? formatCategories(itemCategories, userPermissions)
                        : item.subItems,
            };
        });
    }

    return formatedCategories;
};

export const formatCategories = (categories, userPermissions) => {
    const formatedCategories =
        !isEmpty(categories) &&
        Object.values(categories).map(item => {
            return {
                elementId: `container_category_${item.slug}`,
                slug: item.slug,
                title: item.title,
                description: item.description,
                iconName: item.iconName,
                sort: item.order,
                subItems: _orderBy(
                    item.entries.map(subItem => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(subItem.name, 'text/html');
                        const spanElements = doc.querySelectorAll('span[condition]');
                        const filteredSpanElements = Array.from(spanElements).filter(span => {
                            const conditionsAttributeValue = span.getAttribute('condition');
                            const conditions = conditionsAttributeValue.split(';');

                            return conditions.some(condition =>
                                _find(userPermissions.original, { permissionKey: condition })
                            );
                        });

                        const finalTextArray = [];

                        filteredSpanElements.forEach(span => {
                            finalTextArray.push(span.textContent);
                        });
                        const textOutsideSpan = doc.body.childNodes[0].textContent;
                        let finalText = finalTextArray.unshift(textOutsideSpan);
                        finalText = finalTextArray.join('');

                        return {
                            buttonLabel: getButtonLabel(item.slug, subItem.contentType),
                            contentType: subItem.contentType,
                            guid: subItem.guid,
                            pendoContentType: item.slug
                                .split('-')
                                .join(' ')
                                .replace(/\b\w/g, match => match.toUpperCase()),
                            srcVideo: subItem.contentType === 'video link' ? subItem.link : '',
                            srcLink:
                                subItem.contentType === 'custom link' ||
                                subItem.contentType === 'pendo link'
                                    ? subItem.link
                                    : '',
                            src: subItem.preview,
                            title: finalText,
                        };
                    }),
                    ['title'],
                    ['asc']
                ),
            };
        });

    return formatedCategories ? _orderBy(formatedCategories, ['sort'], ['asc']) : [];
};

export const getButtonLabel = (section, contentType) => {
    if (contentType === 'pendo link') {
        return BUTTONS_LABELS.START_TOUR;
    }

    let buttonLabel = '';

    switch (section) {
        case 'interactive-walkthroughs':
            buttonLabel = BUTTONS_LABELS.START_WALKTHROUGH;
            break;
        case 'user-guides':
            buttonLabel = BUTTONS_LABELS.VIEW_GUIDE;
            break;
        case 'guided-tours':
            buttonLabel = BUTTONS_LABELS.START_TOUR;
            break;
        case 'quick-reference':
            buttonLabel = BUTTONS_LABELS.VIEW_QUICK_REFERENCE;
            break;
        case 'getting-started-guides':
            buttonLabel = BUTTONS_LABELS.VIEW_GUIDE;
            break;

        default:
            break;
    }

    return buttonLabel;
};

export const getIcon = iconName => {
    let icon = '';

    switch (iconName) {
        case 'BookOpenIcon':
            icon = BookOpenIcon;
            break;
        case 'LayoutIcon':
            icon = LayoutIcon;
            break;
        case 'MouseIcon':
            icon = MouseIcon;
            break;
        case 'PlayIcon':
            icon = PlayIcon;
            break;
        case 'WatchIcon':
            icon = WatchIcon;
            break;
        case 'MessageIcon':
            icon = MessageIcon;
            break;
        case 'MessageSquareIcon':
            icon = MessageSquareIcon;
            break;

        default:
            icon = LayoutIcon;
            break;
    }

    return icon;
};

export const openHelpCenterVideo = videoProps => {
    const {
        categoryName,
        closeModal,
        entryUrl,
        guid,
        isFavorite,
        openModal,
        sendPendoEvent,
        url,
        videoName,
    } = videoProps;

    if (url) {
        if (entryUrl) {
            const entryUrlSplit = entryUrl.split('/');
            const section =
                entryUrl === '/help-center'
                    ? 'Home'
                    : entryUrlSplit[2]
                          .split('-')
                          .join(' ')
                          .replace(/\b\w/g, match => match.toUpperCase());

            sendPendoEvent({
                ...HELP_CENTER.FEATURED_PAGE.CLICK_VIDEO_BUTTON,
                eventProperties: {
                    ...HELP_CENTER.FEATURED_PAGE.CLICK_VIDEO_BUTTON.eventProperties,
                    contentId: guid,
                    contentType: HELP_CENTER.CONTENT_TYPES.videos,
                    section,
                    title: videoName,
                },
            });
        }

        openModal(MODALS.COMMON, {
            title: videoName,
            description: renderVideoContainer(url),
            hasCloseIcon: false,
            hideActions: true,
            hideIcon: true,
            isComponent: true,
            ModalProps: {
                BackdropProps: {
                    style: {
                        backgroundColor: 'rgba(0,0,0,0.8)',
                    },
                },
                ContentProps: {
                    style: {
                        maxWidth: '840px',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                },
                onClose: () => {
                    closeModal(MODALS.COMMON);
                },
            },
            modalProps: {
                headerProps: {
                    backgroundColor: 'transparent',
                    removeBorderBottom: true,
                    color: DEFAULT_COLORS.WHITE,
                    padding: '0 0 0.5rem 0',
                    fontSize: '2rem',
                    lineHeight: '1',
                    iconsColor: DEFAULT_COLORS.WHITE,
                    iconsWidth: '32px',
                    iconsHeight: '32px',
                    extraHeaderTitle: categoryName,
                    extraHeaderProps: {
                        entryUrl,
                        isFavoriteEntry: isFavorite,
                        guid,
                        title: videoName,
                        url,
                    },
                },
                bodyProps: {
                    backgroundColor: 'transparent !important',
                    borderRadius: '0',
                },
            },
        });
    }
};

export const renderVideoContainer = url => {
    const videoSplit = url.split('/');
    const sanitizedVideoId = videoSplit[videoSplit.length - 1];
    return <WistiaPlayerEmbed embedId={sanitizedVideoId} />;
};

export const goToSearchDetails = entryProps => {
    const { contentType, history, section, category, id, src } = entryProps;

    if (category === 'release-notes') {
        let publicationYear = get(entryProps, 'publicationYear', '');
        publicationYear = publicationYear ? `/${publicationYear}` : '';
        history.push(`/help-center/release-notes${publicationYear}`);
    } else if (contentType !== 'custom link' && contentType !== 'pendo link') {
        history.push(`/help-center/${section}/${category}/${id}/details`);
    } else if (src) {
        window.open(src, '_blank');
    }
};

export const formatReleaseNotesTitle = ({ contentGroup, publicationYear, name }) => {
    if (contentGroup !== 'release_note') {
        return name;
    }

    return `${publicationYear || ''} Release Notes`;
};

export const getDefaultJumpTo = (releaseNoteDates, publicationYear) => {
    if (releaseNoteDates.length > 0) {
        let defaultJump = !isEmpty(publicationYear) ? publicationYear : null;

        if (typeof defaultJump === 'string') {
            const jumpToLabel =
                releaseNoteDates[0].label === parseInt(defaultJump, 10)
                    ? `${releaseNoteDates[0]?.label} (Latest)`
                    : defaultJump;
            defaultJump = { label: jumpToLabel, value: defaultJump };
        }

        releaseNoteDates[0].label = `${releaseNoteDates[0]?.label} (Latest)`;
        return defaultJump || releaseNoteDates[0];
    }

    return null;
};

export const buildReleaseDatesMonth = dateAndVersionList => {
    return (
        Array.isArray(dateAndVersionList) &&
        dateAndVersionList.map(info => {
            return {
                ...info,
                jumpTo: `release-note-${info.releaseNoteDate}-${info.releaseNoteVersion}`,
            };
        })
    );
};

export const onClickReleaseDate = jumpTo => {
    if (jumpTo) {
        const elementBody = document.getElementById(jumpTo);
        const offset = 100;
        const y = elementBody.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }
};

export const getReleaseNoteTilte = (title, contentGroup) => {
    return contentGroup === 'release_note' ? title : 'Release Note';
};
