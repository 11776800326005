import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    createQuestionnaireAPI,
    fetchQuestionnaireAPI,
    fetchQuestionnairesAPI,
    fetchProspectQuestionnaireAPI,
    prospectSendResponsesAPI,
    sendResponsesAPI,
} from 'modules/apis';
import * as hrFitnessTestActions from '../actions';

/**
 * Function for create questionnaires
 *
 * @method createQuestionnairesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* createQuestionnaireSaga(action) {
    try {
        const response = yield call(createQuestionnaireAPI);
        const questionnaire = get(response, 'data.results', {});
        yield put(hrFitnessTestActions.createQuestionnaire.success(questionnaire));
        return questionnaire;
    } catch (error) {
        yield put(hrFitnessTestActions.createQuestionnaire.error(error));
        return error;
    }
}

/**
 * Function for fetching questionnaire
 *
 * @method fetchQuestionnaireSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchQuestionnaireSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchQuestionnaireAPI, params);
        const questionnaire = get(response, 'data.results', {});
        yield put(hrFitnessTestActions.fetchQuestionnaire.success(questionnaire));
        return questionnaire;
    } catch (error) {
        yield put(hrFitnessTestActions.fetchQuestionnaire.error(error));
        return error;
    }
}

/**
 * Function for fetching questionnaires
 *
 * @method fetchQuestionnairesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchQuestionnairesSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(fetchQuestionnairesAPI, params);
        const questionnaires = get(response, 'data.questionnaires', {});
        yield put(hrFitnessTestActions.fetchQuestionnaires.success(questionnaires));
        return questionnaires;
    } catch (error) {
        yield put(hrFitnessTestActions.fetchQuestionnaires.error(error));
        return error;
    }
}

/**
 * Function for sending responses
 *
 * @method sendResponsesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* sendResponsesSaga(action) {
    try {
        const {
            payload: { data },
        } = action;
        const response = yield call(sendResponsesAPI, data);
        const questionnaires = get(response, 'data', {});
        yield put(hrFitnessTestActions.sendResponses.success(questionnaires));
        return questionnaires;
    } catch (error) {
        yield put(hrFitnessTestActions.sendResponses.error(error));
        return error;
    }
}

/**
 * Function for sending responses for prospect
 *
 * @method prospectSendResponsesSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* prospectSendResponsesSaga(action) {
    try {
        const {
            payload: { data },
        } = action;
        const response = yield call(prospectSendResponsesAPI, data);
        const questionnaires = get(response, 'data', {});
        yield put(hrFitnessTestActions.prospectSendResponses.success(questionnaires));
        return questionnaires;
    } catch (error) {
        yield put(hrFitnessTestActions.prospectSendResponses.error(error));
        return error;
    }
}

/**
 * Function for fetching prospect questionnaire
 *
 * @method fetchProspectQuestionnaireSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* fetchProspectQuestionnaireSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchProspectQuestionnaireAPI, payload);
        const questionnaire = get(response, 'data.results', {});
        yield put(hrFitnessTestActions.fetchProspectQuestionnaire.success(questionnaire));
        return questionnaire;
    } catch (error) {
        yield put(hrFitnessTestActions.fetchProspectQuestionnaire.error(error));
        return error;
    }
}
