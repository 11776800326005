import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';
import { DEFAULT_COLORS } from 'constants/colors';

const ArrowLeftIcon = ({ height, viewBox, width, fillColor }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.707 14.388a1.036 1.036 0 0 1-1.414 0l-6-5.715a.92.92 0 0 1 0-1.346l6-5.715a1.036 1.036 0 0 1 1.414 0 .92.92 0 0 1 0 1.347L6.414 8l5.293 5.04a.92.92 0 0 1 0 1.348z"
                fill={fillColor}
            />
        </SvgIcon>
    );
};

ArrowLeftIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fillColor: string,
};

ArrowLeftIcon.defaultProps = {
    width: '16',
    height: '16',
    viewBox: '0 0 16 16',
    fillColor: DEFAULT_COLORS.BLACK_00,
};

export default ArrowLeftIcon;
