import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypeChecklistsIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <g>
                            <path
                                fill={color}
                                d="M0 0H14V14H0z"
                                transform="translate(-25 -681) translate(25.5 667.4) translate(0 14.5)"
                            />
                            <path
                                fill={colorInner}
                                d="M3.129 4.599L1.813 3.395 2.282 2.877 2.94 3.479 4.053 1.456 4.662 1.792zM5.082 2.891H12.082V3.891H5.082zM3.129 8.505L1.813 7.301 2.282 6.79 2.94 7.385 4.053 5.362 4.662 5.698zM5.082 6.797H12.082V7.797H5.082zM3.129 12.411L1.813 11.207 2.282 10.696 2.94 11.298 4.053 9.268 4.662 9.604zM5.082 10.703H12.082V11.703H5.082z"
                                transform="translate(-25 -681) translate(25.5 667.4) translate(0 14.5)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypeChecklistsIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypeChecklistsIcon.defaultProps = {
    color: `${DEFAULT_COLORS.PEACH}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '15',
    viewBox: '0 0 15 15',
    width: '15',
};

export default ContentTypeChecklistsIcon;
