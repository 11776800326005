import styled from 'styled-components';

import { getThemeProps } from 'ravenjs/utils/theme';

const UserNavStyled = styled.div`
    align-items: center;
    cursor: pointer;
    font-weight: 100;
    height: 100%;
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('UserNav.styles')};
`;

export default UserNavStyled;
