import _has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

import List from 'ravenjs/lib/List';
import ListItem from 'ravenjs/lib/ListItem';
import Typography from 'ravenjs/lib/Typography';
import { ErrorWithLinks } from './ErrorFields';
import DisplayRequiredWithIconField from './DisplayRequiredWithIconField';

const CUSTOM_ERROR_FIELDS = {
    ERROR_WITH_LINKS: ErrorWithLinks,
};

function ErrorList({ errors, errorField, displayErrorIcon, displayCharacterCount, ...restProps }) {
    // Remove the 'is a required property' error,
    // since we're displaying a `REQUIRED` label per field.
    const filteredErrors = errors
        ? errors.filter(e => !e.includes('is a required property') && !e.startsWith('should be'))
        : [];
    // Don't render the error list if there aren't any errors present.
    if (filteredErrors.length === 0) {
        return null;
    }

    const renderError = error => {
        if (errorField && _has(CUSTOM_ERROR_FIELDS, errorField)) {
            const CustomErrorField = CUSTOM_ERROR_FIELDS[errorField];
            return (
                <Typography color="error" gutterBottom="0">
                    <CustomErrorField error={error} {...restProps} />
                </Typography>
            );
        }

        return displayErrorIcon ? (
            <DisplayRequiredWithIconField
                requiredErrorMessage={error}
                displayCharacterCount={displayCharacterCount}
            />
        ) : (
            <Typography type="labelErrorValidation" color="error" gutterBottom="0" gutterTop="0">
                {error}
            </Typography>
        );
    };

    // Render the list of errors.
    return (
        <List backgroundColor="transparent" margin="0.375rem 0 0 0" padding="0" borderWidth="0">
            {filteredErrors.map(error => (
                <ListItem
                    backgroundColor="transparent"
                    borderWidth="0"
                    key={error}
                    margin="0"
                    padding="0"
                >
                    {renderError(error)}
                </ListItem>
            ))}
        </List>
    );
}

ErrorList.propTypes = {
    errors: PropTypes.array,
    errorField: PropTypes.string,
    displayErrorIcon: PropTypes.bool,
    displayCharacterCount: PropTypes.bool,
};

ErrorList.defaultProps = {
    errors: null,
    errorField: null,
    displayErrorIcon: false,
    displayCharacterCount: false,
};

export default ErrorList;
