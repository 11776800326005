import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { fetchToDoList, updateToDoList, createToDoList } from 'modules/apis';

import * as toDoList from '../actions';

/**
 * Saga to fetch ToDo list
 *
 * @method fetchToDoListSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchToDoListSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchToDoList, payload);
        const data = get(response, 'data', {});
        yield put(toDoList.fetchToDoList.success(data));
        return response;
    } catch (error) {
        yield put(toDoList.fetchToDoList.error(error));
        return error;
    }
}

/**
 * Saga to update ToDo list item
 *
 * @method updateToDoListSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* updateToDoListSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(updateToDoList, payload);
        const data = get(response, 'data', {});
        yield put(toDoList.updateToDoList.success(data));
        return response;
    } catch (error) {
        yield put(toDoList.updateToDoList.error(error));
        return error;
    }
}

/**
 * Saga to create ToDo list item
 *
 * @method createToDoListSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* createToDoListSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createToDoList, payload);
        const data = get(response, 'data', {});
        yield put(toDoList.createToDoList.success(data));
        return response;
    } catch (error) {
        yield put(toDoList.createToDoList.error(error));
        return error;
    }
}
