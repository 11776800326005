import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AddNewFileIcon = ({ height, viewBox, width, fill }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.954371 0.943767C1.56544 0.339483 2.39424 0 3.25843 0H11.2472C11.5154 0 11.7726 0.105357 11.9622 0.292893L19.7038 6.95956C19.8935 7.1471 20 7.40145 20 7.66667V20.7778C20 21.6324 19.6567 22.452 19.0456 23.0562C18.4346 23.6605 17.6058 24 16.7416 24H3.25843C2.39424 24 1.56544 23.6605 0.954371 23.0562C0.343298 22.452 0 21.6324 0 20.7778V3.22222C0 2.36764 0.343297 1.54805 0.954371 0.943767ZM3.25843 2C2.93063 2 2.61626 2.12877 2.38447 2.35798C2.15269 2.58719 2.02247 2.89807 2.02247 3.22222V20.7778C2.02247 21.1019 2.15269 21.4128 2.38447 21.642C2.61626 21.8712 2.93063 22 3.25843 22H16.7416C17.0694 22 17.3837 21.8712 17.6155 21.642C17.8473 21.4128 17.9775 21.1019 17.9775 20.7778V8.66667H11.2472C10.6887 8.66667 10.236 8.21895 10.236 7.66667V2H3.25843ZM12.2584 3.41421L16.5474 6.66667H12.2584V3.41421ZM10 11C10.5585 11 11.0112 11.4477 11.0112 12V14.3333H13.3708C13.9293 14.3333 14.382 14.781 14.382 15.3333C14.382 15.8856 13.9293 16.3333 13.3708 16.3333H11.0112V18.6667C11.0112 19.219 10.5585 19.6667 10 19.6667C9.44151 19.6667 8.98877 19.219 8.98877 18.6667V16.3333H6.62921C6.07072 16.3333 5.61798 15.8856 5.61798 15.3333C5.61798 14.781 6.07072 14.3333 6.62921 14.3333H8.98877V12C8.98877 11.4477 9.44151 11 10 11Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

AddNewFileIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
};

AddNewFileIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 20 24',
    width: '20',
    fill: DEFAULT_COLORS.LINK,
};

export default AddNewFileIcon;
