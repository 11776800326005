import INITIAL_STATE from './initialState';

/**
 * Reducer helper for the `fetchConfig` success action
 *
 * @method fetchConfigSuccess
 * @param  {Object}           state  The application state
 * @param  {Object}           action The fired action
 * @return {Object}                  The updated state
 */
export const fetchConfigSuccess = (state, action) => {
    // We aren't doing any special data normalization(s) on this, yet.
    return {
        ...state,
        ...action.payload,
    };
};

/**
 * Reducer helper for catching the auth logout action.
 *
 * @method logoutSuccess
 * @return {Object}      The updated state
 */
export const logoutSuccess = () => INITIAL_STATE;

/**
 * Reducer helper for downtime info action.
 *
 * @method downtimeSuccess
 * @param  {Object}           state  The application state
 * @param  {Object}           action The fired action
 * @return {Object}                  The updated state

 */
export const downtimeSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
    };
};
