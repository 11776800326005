import { takeLatest } from 'redux-saga/effects';

import * as calendarActions from '../actions';
import {
    createCalendarEventSaga,
    deleteCalendarEventSaga,
    downloadCalendarEventsSaga,
    fetchCalendarEventsSaga,
    fetchCalendarEventDetailsSaga,
    updateCalendarEventSaga,
} from './calendar';

/**
 * The root `calendar` saga.
 * We're triggering sagas based their respective events.
 *
 * @method calendarSaga
 * @type   {Generator}
 */
function* calendarSaga() {
    yield takeLatest(calendarActions.fetchCalendarEvents.TRIGGER, fetchCalendarEventsSaga);
    yield takeLatest(
        calendarActions.fetchCalendarEventDetails.TRIGGER,
        fetchCalendarEventDetailsSaga
    );
    yield takeLatest(calendarActions.createCalendarEvent.TRIGGER, createCalendarEventSaga);
    yield takeLatest(calendarActions.updateCalendarEvent.TRIGGER, updateCalendarEventSaga);
    yield takeLatest(calendarActions.downloadCalendarEvents.TRIGGER, downloadCalendarEventsSaga);
    yield takeLatest(calendarActions.deleteCalendarEvent.TRIGGER, deleteCalendarEventSaga);
}

export default calendarSaga;

export {
    createCalendarEventSaga,
    deleteCalendarEventSaga,
    downloadCalendarEventsSaga,
    fetchCalendarEventsSaga,
    fetchCalendarEventDetailsSaga,
    updateCalendarEventSaga,
};
