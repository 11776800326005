const HELP_CENTER = {
    CONTENT_DETAIL: {
        COPY_LINK: {
            event: 'helpCenterPageCopyLink',
            eventProperties: {
                contentId: '',
                contentType: '',
                description: 'Help Center page copy link',
                title: '',
            },
        },
        DOWNLOAD: {
            event: 'helpCenterPageDownload',
            eventProperties: {
                contentId: '',
                contentType: '',
                description: 'Help Center page download',
                title: '',
            },
        },
        FAVORITE: {
            event: 'helpCenterPageFavorite',
            eventProperties: {
                contentId: '',
                contentType: '',
                description: 'Help Center page favorite',
                title: '',
                value: '',
            },
        },
        LIKE_DISLIKE: {
            event: 'helpCenterPageLikeDislike',
            eventProperties: {
                contentId: '',
                contentType: '',
                description: 'Help Center page Like/Dislike',
                title: '',
                value: '',
            },
        },
        PRINT: {
            event: 'helpCenterPagePrint',
            eventProperties: {
                contentId: '',
                contentType: '',
                description: 'Help Center page print',
                title: '',
            },
        },
        VIEW: {
            event: 'HelpCenterPageViewed',
            eventProperties: {
                contentId: '',
                contentType: '',
                description: 'Help Center page view',
                feature: '',
                referrer: '',
                slug: '',
                title: '',
                url: '',
            },
        },
    },
    FEATURED_PAGE: {
        CLICK_START_BUTTON: {
            event: 'helpCenterClickStartTour',
            eventProperties: {
                contentId: '',
                description: 'Click on start tour button',
                title: '',
            },
        },
        CLICK_VIDEO_BUTTON: {
            event: 'helpCenterClickVideoButton',
            eventProperties: {
                contentId: '',
                description: 'Click on help center video play button',
                title: '',
            },
        },
    },
    CONTENT_TYPES: {
        'quick-reference': 'userAssistanceQuickReference',
        videos: 'userAssistanceVideo',
        'user-guides': 'userAssistanceUserGuide',
        faq: 'userAssistanceFAQ',
    },
};

export default HELP_CENTER;
