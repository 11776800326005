const SEARCH = {
    FILTERS: {
        UPDATE_CATEGORY: {
            event: 'searchPageFiltersUpdateCategory',
            eventProperties: {
                description: 'Update Category filter on Search page',
                filters: '',
                value: '',
            },
        },
        UPDATE_CONTENT_TYPES: {
            event: 'searchPageFiltersUpdateContentTypes',
            eventProperties: {
                description: 'Update Content Types filter on Search page',
                filters: '',
                value: '',
            },
        },
        UPDATE_EMPLOYEE_COUNT: {
            event: 'searchPageFiltersUpdateEmployeeCount',
            eventProperties: {
                description: 'Update Employee Count filter on Search page',
                filters: '',
                value: '',
            },
        },
        UPDATE_JURISDICTION: {
            event: 'searchPageFiltersUpdateJurisdiction',
            eventProperties: {
                description: 'Update Jurisdiction filter on Search page',
                filters: '',
                value: '',
            },
        },
        UPDATE_SORT: {
            event: 'searchPageFiltersUpdateSort',
            eventProperties: {
                description: 'Update Sort filter on Search page',
                filters: '',
                value: '',
            },
        },
    },
    STATE: 'search',
};

export default SEARCH;
