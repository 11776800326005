import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const SearchIcon = ({ fill, setRef, ...rest }) => (
    <SvgIcon {...rest} ref={setRef}>
        <path
            fill={fill}
            fillRule="evenodd"
            d="M8.367.5c4.614 0 8.367 3.754 8.367 8.367 0 2.03-.726 3.892-1.932 5.343l3.972 3.972c.301.301.301.79 0 1.092-.151.15-.349.226-.546.226-.198 0-.396-.075-.546-.226l-3.972-3.972c-1.45 1.206-3.313 1.932-5.343 1.932C3.754 17.234 0 13.481 0 8.867 0 4.254 3.754.5 8.367.5zm0 1.545c-3.762 0-6.822 3.06-6.822 6.822s3.06 6.823 6.822 6.823 6.823-3.06 6.823-6.823c0-3.762-3.06-6.822-6.823-6.822z"
        />
    </SvgIcon>
);

SearchIcon.propTypes = {
    fill: PropTypes.string,
    height: PropTypes.string,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

SearchIcon.defaultProps = {
    fill: '#3A3A3A',
    height: '20',
    setRef: null,
    viewBox: '0 0 19 20',
    width: '19',
};

export default SearchIcon;
