import React from 'react';
import _get from 'lodash/get';

import { COLORS } from 'ravenjs/constants/colors';
import { FORM_FIELDS_DESCRIPTION } from 'ravenjs/constants/messages';
import Typography from 'ravenjs/lib/Typography/Typography';
import { shape, string } from 'prop-types';

const FormDescriptionTextField = ({ styles }) => {
    const marginLeft = _get(styles, 'marginLeft', '0.938rem');
    const fontWeight = _get(styles, 'fontWeight', '400px');
    const fontFamily = _get(styles, 'fontFamily', 'Roboto');
    const fontSize = _get(styles, 'fontSize', '0.875rem');
    const color = _get(styles, 'color', COLORS.DESC_GREY);

    return (
        <Typography
            color={color}
            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            lineHeight="19px"
            wordWrap
            gutterLeft={marginLeft}
        >
            {FORM_FIELDS_DESCRIPTION}
        </Typography>
    );
};

export default FormDescriptionTextField;

FormDescriptionTextField.defaultProps = {
    styles: {},
};

FormDescriptionTextField.propTypes = {
    styles: shape({
        marginLeft: string,
        fontWeight: string,
        color: string,
        fontFamily: string,
        fontSize: string,
    }),
};
