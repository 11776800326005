import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { formatNumberWithDelimiter } from 'ravenjs/utils/number';
import { themeGet } from 'ravenjs/utils/theme';

const PaginationTotalRowsStyled = styled(Row)`
    /**
     * Add all of the remaining styles from theme
     */
    ${themeGet('PaginationTotalRows.styles')};
`;

function PaginationTotalRows(props) {
    const { totalRows } = props;
    const total = formatNumberWithDelimiter(totalRows || 0);

    return (
        <PaginationTotalRowsStyled
            alignItems="center"
            gutter={false}
            justify="flex-start"
            width="auto"
        >
            <Typography
                color="text"
                fontSize="0.75rem"
                fontFamily="RobotoMono"
                gutterBottom="0"
                gutterRight="4px"
                gutterTop="0"
                type="paginationLabel"
            >
                {total} RESULTS
            </Typography>
        </PaginationTotalRowsStyled>
    );
}

PaginationTotalRows.propTypes = {
    /**
     * The number of total rows.
     */
    totalRows: PropTypes.number,
};

PaginationTotalRows.defaultProps = {
    totalRows: 0,
};

export default PaginationTotalRows;
