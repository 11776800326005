export const MOBILE_MODAL_PROPS = {
    ContentProps: {
        style: {
            borderRadius: 0,
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            top: 0,
        },
    },
};

export const TEXT_ALIGNMENTS_MAP = {
    LeftAlign: 'left',
    CenterAlign: 'center',
    RightAlign: 'right',
};
