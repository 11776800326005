import { func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { COLORS } from 'ravenjs/constants/colors';
import Button from 'ravenjs/lib/Button';
import Typography from 'ravenjs/lib/Typography';
import { isEmpty } from 'ravenjs/utils/lodash';

import { LABEL_PAGINATION } from 'constants/paginationGoTo';

import InputStyled from './InputStyled';
import { callFunc } from '../../utils';

const GotoButton = styled(Button)`
    background: transparent;
    color: ${COLORS.GREY};
    height: 30px;
    min-width: 0;
    min-height: 0;
    padding: 5px;
    :hover {
        background: transparent;
        color: ${COLORS.GREY};
        font-weight: 600;
    }
    :focus {
        box-shadow: none;
    }
    :disabled {
        background: transparent;
        color: ${COLORS.GREY};
        cursor: not-allowed;
    }
`;

const PaginationGoTo = ({
    buttonLabel,
    label,
    lastPage,
    onSubmit,
    pageValidationCallback,
    scrollCurrentPage,
    totalPageNumber,
}) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (scrollCurrentPage > 0) {
            setInputValue(scrollCurrentPage);
        }
    }, [scrollCurrentPage]);

    const handleGoToPageClick = () => {
        onSubmit(inputValue);
        setInputValue('');
    };

    const isValidValue = value => {
        return value && (pageValidationCallback ? callFunc(pageValidationCallback, value) : true);
    };

    const onInputChange = event => {
        if (
            event.target.value === '' ||
            (parseInt(event.target.value, 10) <= lastPage && parseInt(event.target.value, 10) >= 1)
        ) {
            setInputValue(event.target.value);
        }
    };

    const onInputKeyPress = event => {
        if (event.charCode === 13 && isValidValue(inputValue)) {
            onSubmit(inputValue);
            setInputValue('');
        }
        // To validate just positive numbers.
        const e = event || window.event;
        const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/)) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <Typography type="gotoPage" color="grey">
                {label.toUpperCase()}
            </Typography>
            <InputStyled
                onChange={onInputChange}
                onKeyPress={onInputKeyPress}
                value={inputValue}
                type="number"
                data-testid="goToPageInput"
            />
            {totalPageNumber > 0 && (
                <Typography type="gotoPage" color="grey">
                    {totalPageNumber ? 'OF' : ''} {totalPageNumber} &nbsp;
                </Typography>
            )}

            <GotoButton onClick={handleGoToPageClick} disabled={isEmpty(inputValue)}>
                <Typography type="gotoPage" color="grey">
                    {buttonLabel.toUpperCase()}
                </Typography>
            </GotoButton>
        </div>
    );
};

PaginationGoTo.propTypes = {
    buttonLabel: string,
    label: string,
    lastPage: number,
    onSubmit: func.isRequired,
    pageValidationCallback: func,
    totalPageNumber: number,
    scrollCurrentPage: number,
};

PaginationGoTo.defaultProps = {
    buttonLabel: LABEL_PAGINATION.GO_TO_PAGE,
    label: LABEL_PAGINATION.LABEL,
    lastPage: 1,
    pageValidationCallback: null,
    totalPageNumber: 0,
    scrollCurrentPage: 0,
};

export default PaginationGoTo;
