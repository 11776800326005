import { object, string } from 'prop-types';
import { withTheme } from 'styled-components';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HelpIcon = ({ height, viewBox, width, fill, fontFill, theme, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <g fill="none" fillRule="evenodd">
            <g>
                <g transform="translate(-1222 -694) translate(1222 694.78)">
                    <circle cx="18" cy="18" r="18" fill={fill} />
                    <path
                        fill={fontFill}
                        d="M18.859 7.129V17.14H28.87v1.718H18.86V28.87h-1.718V18.86H7.129v-1.718H17.14V7.129h1.718z"
                        transform="rotate(45 18 18)"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
);

HelpIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    fill: string,
    fontFill: string,
    theme: object.isRequired,
};

HelpIcon.defaultProps = {
    viewBox: '0 0 36 37',
    height: '37',
    width: '36',
    fill: DEFAULT_COLORS.YELLOW,
    fontFill: DEFAULT_COLORS.BLACK,
};

export { HelpIcon as HelpIconUnwrapped };
export default withTheme(HelpIcon);
