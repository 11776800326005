import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'ravenjs/constants/colors';

import SvgIcon from '../SvgIcon';

const PhoneIcon = ({ setRef, fill, width, height, viewBox, ...rest }) => (
    <SvgIcon {...rest} ref={setRef} width={width} height={height} viewBox={viewBox}>
        <g fill={fill} fillRule="evenodd">
            <path
                d="M16.859 12.555c-1 0-1.983-.156-2.914-.464-.456-.155-1.016-.013-1.295.273l-1.837 1.387c-2.13-1.137-3.443-2.45-4.565-4.564l1.346-1.79c.35-.349.475-.859.325-1.337-.31-.936-.466-1.918-.466-2.919 0-.723-.588-1.311-1.311-1.311h-3c-.724 0-1.312.588-1.312 1.311 0 8.287 6.742 15.03 15.029 15.03.723 0 1.311-.59 1.311-1.312v-2.993c0-.722-.588-1.31-1.311-1.31z"
                transform="translate(-960 -1629) translate(82.5 1617.641) translate(878 12)"
            />
        </g>
    </SvgIcon>
);

PhoneIcon.propTypes = {
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    fill: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

PhoneIcon.defaultProps = {
    setRef: null,
    viewBox: '0 0 24 24',
    fill: COLORS.GREY,
    width: '18',
    height: '18',
};

export default PhoneIcon;
