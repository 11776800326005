import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const SignaturesIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g id="Clipped" transform="translate(0.000000, 3.678477)">
                <polygon
                    id="Rectangle"
                    fill="#FFBF00"
                    transform="translate(23.954018, 18.940654) rotate(45.000000) translate(-23.954018, -18.940654) "
                    points="19.7034906 1.93854607 28.2045448 1.93854607 28.2045448 35.9427627 19.7034906 35.9427627"
                />
                <path
                    d="M3.98808167,37.3334419 L14.1581761,33.2515191 L37.8704498,9.5364117 L31.7851119,3.45107377 L8.0700045,27.1647643 L3.98808167,37.3334419 Z M15.4064142,35.823088 L1.95349604,41.2198405 C1.22665591,41.51171 0.394969445,41.1560826 0.103099918,40.4264088 C-0.0385843173,40.0750319 -0.0300832632,39.6939013 0.103099918,39.3694444 L5.53669036,25.8258483 C5.61178301,25.6416588 5.72087987,25.4801388 5.85122937,25.3469556 L30.7749033,0.417614285 C31.334556,-0.139204762 32.2385015,-0.139204762 32.7953205,0.417614285 L40.9039093,8.52761994 C41.4607284,9.08443899 41.4607284,9.98838441 40.9039093,10.5480371 L15.9774017,35.4745447 C15.8102143,35.6388985 15.6146901,35.7536627 15.4064142,35.823088 Z"
                    id="Shape"
                    fill="#282828"
                />
            </g>
            <g id="Clipped" transform="translate(1.426430, 37.534988)" fill="#282828">
                <polygon
                    id="Path"
                    points="2.42421727 0 3.39900282e-24 6.03999897 6.04141581 3.61861538"
                />
            </g>
            <g id="Clipped" transform="translate(0.001615, 36.109996)" fill="#282828">
                <path
                    d="M0.101484059,6.93792552 L2.52570133,0.895092863 C2.5951266,0.722238095 2.70138978,0.557884382 2.84307402,0.416200146 C3.40272675,-0.1406189 4.30383849,-0.137785215 4.85782385,0.419033831 L8.45235291,4.01639658 C8.59828767,4.15241345 8.7130519,4.31960084 8.78956139,4.51370825 C9.08143092,5.24338206 8.72580349,6.07648537 7.99612967,6.36693805 L1.95046333,8.78832164 C1.62600643,8.92150482 1.24487584,8.93000588 0.893498937,8.78832164 C0.165241965,8.49786896 -0.188968625,7.66759934 0.101484059,6.93792552 M4.36759639,3.95972289 L3.9864658,4.90192305 L4.93291649,4.52362614 L4.36759639,3.95972289 Z"
                    id="Shape"
                />
            </g>
            <g id="Clipped" transform="translate(5.430781, 28.604985)" fill="#282828">
                <path
                    d="M0.417614285,2.43944833 C-0.139204762,1.88121244 -0.139204762,0.977267016 0.417614285,0.417614285 C0.977267016,-0.139204762 1.88121244,-0.139204762 2.43803149,0.417614285 L10.5480371,8.52761994 C11.1048562,9.08443899 11.1048562,9.98838441 10.5480371,10.5480371 C9.98838441,11.1048562 9.08443899,11.1048562 8.5262031,10.5480371 L0.417614285,2.43944833 Z"
                    id="Path"
                />
            </g>
            <g id="Clipped" transform="translate(31.786528, 1.431719)" fill="#FFFFFF">
                <path
                    d="M0,3.67458065 L8.10858881,11.7845863 L11.2029725,8.6902026 C11.980819,7.91235615 11.980819,6.64428224 11.2029725,5.86643578 L5.91956737,0.584447473 C5.14313776,-0.194815824 3.87364701,-0.194815824 3.09438371,0.584447473 L0,3.67458065 Z"
                    id="Path"
                />
            </g>
            <g id="Clipped" transform="translate(30.354809, 0.000000)" fill="#282828">
                <path
                    d="M8.52726573,14.2265141 L0.418676917,6.11650846 C-0.139558972,5.55968941 -0.139558972,4.65574399 0.418676917,4.09609126 L3.51447747,1.00312439 C4.18039338,0.337208481 5.06308617,0 5.93586106,0 C6.76896436,0 7.60490136,0.306037949 8.25948252,0.913863321 L13.6420666,6.28652954 C14.3079825,6.96236335 14.645191,7.83797192 14.645191,8.70791313 C14.645191,9.58210487 14.3079825,10.4633808 13.6420666,11.1292967 L10.5476829,14.2265141 C9.99228073,14.7833332 9.08408478,14.7833332 8.52726573,14.2265141 M3.45071956,5.10913354 L9.53605749,11.1944715 L11.6230663,9.11313005 C11.7307463,9.00403318 11.7845863,8.85668158 11.7845863,8.70932997 C11.7845863,8.56197837 11.7307463,8.41320992 11.6230663,8.30694674 L6.30565691,2.99095422 C6.20222742,2.90310999 6.06904424,2.86060472 5.93586106,2.86060472 C5.78850945,2.86060472 5.64257469,2.91586157 5.53489467,3.02495843 L3.45071956,5.10913354 Z"
                    id="Shape"
                />
            </g>
            <g id="Clipped" transform="translate(29.053085, 11.647861)" fill="#282828">
                <path
                    d="M9.69438962,2.4383857 C9.13473689,1.88156665 9.13473689,0.977621227 9.69438962,0.42080218 C10.2512087,-0.140267393 11.1523204,-0.140267393 11.7119731,0.42080218 L12.8567818,1.56561081 C13.8868262,2.59423836 14.436561,3.96290807 14.4223926,5.36133148 C14.409641,6.69032961 13.8939104,8.0377467 12.8015249,9.12588163 L2.44015675,19.4886666 C1.88050402,20.0454857 0.976558595,20.0454857 0.419739548,19.4886666 C-0.139913183,18.9290139 -0.139913183,18.0250685 0.419739548,17.4682494 L10.7796909,7.10688127 C11.3138404,6.57414854 11.5702889,5.93940316 11.5759563,5.33724516 C11.5830405,4.6996661 11.3195078,4.06633757 10.8377814,3.58461117 L9.69438962,2.4383857 Z"
                    id="Path"
                />
            </g>
        </SvgIcon>
    );
};

SignaturesIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

SignaturesIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default SignaturesIcon;
