import { string, func, array, bool, node } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { renderCheck } from 'ravenjs/utils/viewport';
import { callFunc } from 'ravenjs/utils/actions';
import TooltipNew from 'ravenjs/lib/TooltipNew';
import { COLORS } from 'ravenjs/constants/colors';

const ContainerIconStyled = styled.div`
    display: ${({ display }) => display};
    cursor: pointer;
`;

const MainContainer = styled.div`
    display: ${({ display }) => display || undefined};
    margin: ${({ margin }) => margin || 0};
    width: ${({ width }) => width};
    text-overflow: ${({ hasEllipsis }) => (hasEllipsis ? 'ellipsis' : '')};
    overflow: ${({ hasEllipsis }) => (hasEllipsis ? 'hidden' : '')};
`;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const ContentTooltipPreview = props => {
    const {
        checkIconChange,
        display,
        color,
        borderColor,
        borderRadius,
        content,
        displayRefContainer,
        extraClass,
        fontWeight,
        hasEllipsis,
        hideInnerContent,
        hideTooltip,
        icon,
        margin,
        offset,
        onMouseEnter,
        onMouseLeave,
        placement,
        width,
        boxShadow,
        widthContainer,
        open,
        applyNewTooltipStyle,
        textColor,
        backgroundColor,
        isTooltipContent,
    } = props;

    const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    const prevIcon = usePrevious(icon);
    const iconRef = useRef();
    const [show, setShow] = useState(false);

    React.useEffect(() => {
        if (checkIconChange && prevIcon && prevIcon !== icon) {
            setShow(false);
        }
    }, [checkIconChange, icon, prevIcon]);

    React.useEffect(() => {
        window &&
            window.addEventListener(
                'scroll',
                function() {
                    setShow(false);
                },
                true
            );

        return () => {
            window &&
                window.removeEventListener(
                    'scroll',
                    function() {
                        setShow(false);
                    },
                    true
                );
        };
    });

    const handleMouseEnter = () => {
        setShow(true);
        // Fix to click inside tooltip content (to mobile)
        window.addEventListener('click', handleOutsideClick);

        if (typeof onMouseEnter === 'function') {
            callFunc(onMouseEnter);
        }
    };

    const handleMouseLeave = () => {
        if (!hideInnerContent && !isTouchDevice) {
            setShow(false);

            if (typeof onMouseLeave === 'function') {
                callFunc(onMouseLeave);
            }
        }
    };

    const handleOutsideClick = e => {
        if (document.getElementById('containerTooltip')) {
            e.stopImmediatePropagation();
            const isMobile = renderCheck('md', 'less');

            if (
                (isMobile || isTouchDevice) &&
                !(
                    document.getElementById('triggerTooltip') &&
                    document.getElementById('triggerTooltip').contains(e.target)
                ) &&
                !(
                    document.getElementById('containerTooltip') &&
                    document.getElementById('containerTooltip').contains(e.target)
                )
            ) {
                if (isTouchDevice) {
                    if (typeof onMouseLeave === 'function') {
                        callFunc(onMouseLeave);
                    }
                }

                setShow(false);
            }
        }
    };

    React.useEffect(() => {
        if (typeof open === 'boolean') {
            setShow(open);
        }
    }, [open]);

    return (
        <MainContainer
            display={display}
            margin={margin}
            width={widthContainer}
            hasEllipsis={hasEllipsis}
        >
            {content || hideInnerContent ? (
                <div
                    data-testid="parentTooltipContainer"
                    id="parentTooltipContainer"
                    onMouseEnter={handleMouseEnter}
                    onPointerLeave={handleMouseLeave}
                    onTouchStart={handleMouseEnter}
                >
                    <ContainerIconStyled
                        id="triggerTooltip"
                        className={extraClass}
                        display={displayRefContainer}
                        ref={iconRef}
                    >
                        {icon}
                    </ContainerIconStyled>
                    {content && show && !isTooltipContent && content}
                    {content && show && isTooltipContent && (
                        <TooltipNew
                            id="containerTooltip"
                            borderColor={borderColor}
                            borderRadius={borderRadius}
                            color={color}
                            fontWeight={fontWeight}
                            hideTooltip={hideTooltip}
                            offset={offset}
                            placement={placement}
                            trigger={iconRef.current}
                            width={width}
                            boxShadow={boxShadow}
                            applyNewTooltipStyle={applyNewTooltipStyle}
                            textColor={textColor}
                            backgroundColor={backgroundColor}
                        >
                            {content}
                        </TooltipNew>
                    )}
                </div>
            ) : (
                <ContainerIconStyled>{icon}</ContainerIconStyled>
            )}
        </MainContainer>
    );
};

ContentTooltipPreview.propTypes = {
    checkIconChange: bool,
    display: string,
    color: string,
    borderColor: string,
    borderRadius: string,
    textColor: string,
    backgroundColor: string,
    content: node,
    displayRefContainer: string,
    extraClass: string,
    fontWeight: string,
    hasEllipsis: bool,
    hideInnerContent: bool,
    hideTooltip: bool,
    icon: node,
    margin: string,
    offset: array,
    open: bool,
    onMouseEnter: func,
    onMouseLeave: func,
    placement: string,
    width: string,
    boxShadow: string,
    widthContainer: string,
    applyNewTooltipStyle: bool,
    isTooltipContent: bool,
};

ContentTooltipPreview.defaultProps = {
    checkIconChange: false,
    display: null,
    color: COLORS.WHITE,
    borderColor: COLORS.BLACK,
    textColor: null,
    backgroundColor: null,
    borderRadius: '0.25rem',
    content: null,
    displayRefContainer: 'flex',
    extraClass: '',
    fontWeight: '',
    hasEllipsis: false,
    hideInnerContent: false,
    hideTooltip: false,
    icon: null,
    margin: '0',
    offset: [0, 8],
    open: null,
    onMouseEnter: null,
    onMouseLeave: null,
    placement: 'left',
    width: '',
    boxShadow: '',
    widthContainer: '',
    applyNewTooltipStyle: true,
    isTooltipContent: true,
};

export default ContentTooltipPreview;
