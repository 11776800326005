const COMPLIANCE = {
    WIDGET_CONTENT_DETAILS_SELECT: {
        event: 'compliance',
        eventProperties: {
            description: 'User select view content from landing page widget',
            contentfulId: '',
            contentType: '',
        },
    },
    EXPORT_LAWS: {
        EVENT: 'Export Laws Modal',
        SUMMARY: 'Summary',
        WARNING: 'Warning',
    },
};

export default COMPLIANCE;
