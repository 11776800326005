import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const PdfFileIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                d="M21.7,8.3c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0l-7.6-7.6c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2
                C13.4,0,13.2,0,13.1,0H5.5C4.6,0,3.8,0.3,3.1,1s-1,1.4-1,2.3v17.5c0,0.9,0.3,1.7,1,2.3c0.6,0.6,1.4,1,2.3,1h13.1
                c0.9,0,1.7-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3v-12C21.8,8.6,21.8,8.4,21.7,8.3z M14.2,3.7l3.9,3.9h-3.9V3.7z M19.3,21.5
                c-0.2,0.2-0.5,0.3-0.8,0.3H5.5c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V3.3c0-0.3,0.1-0.6,0.3-0.8
                c0.2-0.2,0.5-0.3,0.8-0.3H12v6.5c0,0.6,0.5,1.1,1.1,1.1h6.5v10.9C19.6,21,19.5,21.3,19.3,21.5z"
                fill={fill}
            />
            <path
                d="M9.4,16.1c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.3-0.2-0.6-0.3c-0.2-0.1-0.5-0.1-0.7-0.1H6.5
                c-0.2,0-0.4,0.2-0.4,0.4v4c0,0.2,0.2,0.4,0.4,0.4h0.3c0.2,0,0.4-0.2,0.4-0.4v-1.1c0-0.1,0.1-0.1,0.1-0.1h0.4c0.3,0,0.5,0,0.7-0.1
                c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.4,0.1-0.6C9.6,16.5,9.5,16.3,9.4,16.1z M8.4,16.7
                c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0H7.4c-0.1,0-0.1-0.1-0.1-0.1v-0.9
                c0-0.1,0.1-0.1,0.1-0.1h0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2C8.4,16.6,8.4,16.6,8.4,16.7z"
                fill={fill}
            />
            <path
                d="M13.6,16.7c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3
                c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.6-0.1h-0.9c-0.2,0-0.4,0.2-0.4,0.4v4c0,0.2,0.2,0.4,0.4,0.4h0.9c0.2,0,0.4,0,0.6-0.1
                c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2,0.1-0.4,0.1-0.6v-0.4
                C13.7,17.1,13.7,16.9,13.6,16.7z M11.4,16.3c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.2,0.2
                c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3v0.4c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2-0.1,0.3
                c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0h-0.1c-0.1,0-0.1-0.1-0.1-0.1V16.3z"
                fill={fill}
            />
            <path
                d="M17.5,15.1h-2.5c-0.2,0-0.4,0.2-0.4,0.4v4c0,0.2,0.2,0.4,0.4,0.4h0.3c0.2,0,0.4-0.2,0.4-0.4v-1.3
                c0-0.1,0.1-0.1,0.1-0.1h1.4c0.2,0,0.4-0.2,0.4-0.4v-0.2c0-0.2-0.2-0.4-0.4-0.4h-1.4c-0.1,0-0.1-0.1-0.1-0.1v-0.7
                c0-0.1,0.1-0.1,0.1-0.1h1.6c0.2,0,0.4-0.2,0.4-0.4v-0.2C17.9,15.3,17.7,15.1,17.5,15.1z"
                fill={fill}
            />
        </SvgIcon>
    );
};

PdfFileIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

PdfFileIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default PdfFileIcon;
