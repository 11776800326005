import React from 'react';
import { array } from 'prop-types';

import { renderCheck } from 'ravenjs/utils/viewport';

const ContentSearchContext = React.createContext([{}, () => {}]);

const ContentSearchProvider = ({ children }) => {
    const [searchState, setSearchState] = React.useState({
        searchTerm: '',
        showSearchBar: !renderCheck('md', 'less'),
    });
    return (
        <ContentSearchContext.Provider value={[searchState, setSearchState]}>
            {children}
        </ContentSearchContext.Provider>
    );
};

ContentSearchProvider.propTypes = {
    children: array.isRequired,
};

export { ContentSearchContext, ContentSearchProvider };
