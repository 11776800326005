import _get from 'lodash/get';

import { formatAppNavItems, formatUserNavItems } from 'utils/navigation';

import INITIAL_STATE from './initialState';
import { DEFAULT_NAV_ITEMS } from '../../../constants/navigation';

/**
 * Reducer helper for the fetch app nav
 *
 * @method fetchAppNavSuccess
 * @param   {Object} state
 * @param   {Object} action
 * @return  {Object}
 */
export const fetchAppNavSuccess = (state, { payload: menus }) => ({
    ...state,
    appNav: {
        ...state.appNav,
        items: formatAppNavItems([...DEFAULT_NAV_ITEMS, ...menus]),
    },
    userNav: {
        ...state.userNav,
        items: formatUserNavItems([...DEFAULT_NAV_ITEMS, ...menus]),
    },
});

/**
 * Reducer helper for showing the navigation, by navId (success).
 *
 * @method showNavSuccess
 * @param  {string}       navId The id of the navigation to show
 * @return {Object}             The updated state
 */
export const showNavSuccess = navId => (state, action) => ({
    ...state,
    [navId]: _get(action, 'payload', {}),
});

/**
 * Reducer helper for hiding the navigation, by navId (success).
 *
 * @method hideNavSuccess
 * @param  {string}       navId The id of the navigation to hide
 * @return {Object}             The updated state
 */
export const hideNavSuccess = navId => state => ({
    ...state,
    [navId]: {
        ..._get(state, navId, {}),
        show: false,
    },
});

/**
 * Reducer helper for catching the auth logout action.
 *
 * @method logoutSuccess
 * @return {Object}      The updated state
 */
export const logoutSuccess = () => INITIAL_STATE;
