import { DEFAULT_BRANDING } from 'constants/app';
import CUSTOM_LOGIN_WIDGET from 'constants/customLoginWidget';
import { buildThemeWithConfig } from 'utils/branding';

/**
 * Initialize the 'branding' state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    config: DEFAULT_BRANDING,
    isDefault: true,
    isUsingTemporaryTheme: false,
    theme: buildThemeWithConfig(),
    customLoginWidget: {
        default: CUSTOM_LOGIN_WIDGET.INITIAL_PROPERTIES,
        partner: CUSTOM_LOGIN_WIDGET.INITIAL_PROPERTIES,
    },
};

export default INITIAL_STATE;
