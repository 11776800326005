import React from 'react';
import { number, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import Typography from 'ravenjs/lib/Typography';
import { get } from 'ravenjs/utils/lodash';

import { DEFAULT_COLORS } from 'constants/colors';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { selectors as userSelectors } from 'modules/user';

const AppDisclaimer = props => {
    const { partnerSettings } = props;
    const disclaimerText = get(partnerSettings, 'disclaimerText', '');

    const renderDisclaimer = () => {
        if (disclaimerText) {
            return (
                <Typography
                    color={DEFAULT_COLORS.PLACEHOLDER}
                    className="disclaimerText"
                    fontStyle="italic"
                    fontSize="0.6875rem !important"
                    gutterBottom="0 !important"
                    gutterTop="0 !important"
                    padding="1rem 1rem 0.5rem"
                    type="paragraph"
                >
                    {disclaimerText}
                </Typography>
            );
        } else {
            return null;
        }
    };

    return renderDisclaimer();
};

AppDisclaimer.propTypes = {
    userCompany: shape({
        disclaimerText: string,
        partnerId: number,
    }),
};

AppDisclaimer.defaultProps = {
    partnerSettings: {
        disclaimerText: '',
    },
    userCompany: {
        partnerId: 0,
    },
};

const mapStateToProps = createStructuredSelector({
    partnerSettings: userSelectors.getPartnerSettings,
    userCompany: userSelectors.getUserCompany,
});

export { AppDisclaimer as AppDisclaimerUnwrapped };
export default compose(withPublicContent, withRouter, connect(mapStateToProps))(AppDisclaimer);
