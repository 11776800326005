import { string, object } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const RestoreIcon = ({ fill, setRef, ...rest }) => {
    return (
        <SvgIcon cursor="pointer" {...rest} ref={setRef}>
            <path
                d="M2.9,3.9c0.5,0,0.9,0.4,0.9,0.9v4.5h4.5c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9H2.9c-0.5,0-0.9-0.4-0.9-0.9V4.8
                C2,4.3,2.4,3.9,2.9,3.9L2.9,3.9z"
            />
            <path
                d="M11.6,3.1c2-0.3,3.9,0.1,5.7,1c1.7,0.9,3.1,2.4,3.9,4.2c0.8,1.8,1,3.8,0.6,5.7c-0.4,1.9-1.5,3.6-3,4.9
                c-3.1,2.6-7.5,2.8-10.9,0.6C6.2,18.4,5,16.8,4.3,15c-0.2-0.5,0.1-1,0.5-1.1c0,0,0,0,0,0c0.5-0.2,1,0.1,1.2,0.6
                c0.5,1.5,1.5,2.7,2.8,3.6c2.7,1.8,6.2,1.6,8.7-0.5c1.2-1,2-2.4,2.4-3.9c0.4-1.5,0.2-3.1-0.5-4.5c-0.6-1.4-1.7-2.6-3.1-3.4
                c-1.4-0.8-3-1-4.5-0.8c-1.6,0.2-3,0.9-4.1,2.1l-4.2,3.9c-0.4,0.3-0.9,0.3-1.3,0c-0.3-0.4-0.3-0.9,0-1.3c0,0,0,0,0,0l4.2-3.9
                C7.9,4.2,9.7,3.4,11.6,3.1L11.6,3.1z"
            />
        </SvgIcon>
    );
};

RestoreIcon.propTypes = {
    fill: string,
    height: string,
    setRef: object,
    viewBox: string,
    width: string,
};

RestoreIcon.defaultProps = {
    fill: DEFAULT_COLORS.BLACK,
    height: '24',
    setRef: null,
    viewBox: '0 0 24 24',
    width: '24',
};

export default RestoreIcon;
