import { string } from 'prop-types';
import React from 'react';

import { DEFAULT_COLORS } from 'constants/colors';

const ArrowIconRight = ({ fill, height, width, viewBox }) => {
    return (
        <svg width={width} height={height} fill="none">
            <path
                d="M5 12h14M12 5l7 7-7 7"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

ArrowIconRight.propTypes = {
    fill: string,
    height: string,
    width: string,
    viewBox: string,
};

ArrowIconRight.defaultProps = {
    fill: DEFAULT_COLORS.LINK,
    height: '24',
    width: '24',
    viewBox: '0 0 24 24',
};

export default ArrowIconRight;
