import { takeLatest } from 'redux-saga/effects';

import {
    activateProspectSaga,
    bulkUploadProspectsSaga,
    createProspectSaga,
    deactivateProspectSaga,
    deleteProspectSaga,
    deleteBulkProspectSaga,
    emailProspectReportSaga,
    enrollBulkProspectsSaga,
    fetchProspectByIdSaga,
    fetchProspectsByPartnerSaga,
    updateProspectSaga,
    createProspectQuestionnaireSaga,
    downloadSampleForBulkProspectSaga,
} from './prospect';
import * as prospectActions from '../actions';

/**
 * Root saga for `Prospect`
 * Triggers sagas related to all prospect events
 *
 * @method prospectSaga
 * @type   {Generator}
 */
function* prospectSaga() {
    yield takeLatest(prospectActions.bulkUploadProspects.TRIGGER, bulkUploadProspectsSaga);
    yield takeLatest(prospectActions.createProspect.TRIGGER, createProspectSaga);
    yield takeLatest(prospectActions.fetchProspectById.TRIGGER, fetchProspectByIdSaga);
    yield takeLatest(prospectActions.fetchProspectsByPartner.TRIGGER, fetchProspectsByPartnerSaga);
    yield takeLatest(prospectActions.updateProspect.TRIGGER, updateProspectSaga);
    yield takeLatest(prospectActions.deleteProspect.TRIGGER, deleteProspectSaga);
    yield takeLatest(prospectActions.createQuestionnaire.TRIGGER, createProspectQuestionnaireSaga);
    yield takeLatest(prospectActions.deleteBulkProspect.TRIGGER, deleteBulkProspectSaga);
    yield takeLatest(prospectActions.enrollBulkProspect.TRIGGER, enrollBulkProspectsSaga);
    yield takeLatest(prospectActions.emailProspectReport.TRIGGER, emailProspectReportSaga);
    yield takeLatest(prospectActions.activateProspect.TRIGGER, activateProspectSaga);
    yield takeLatest(prospectActions.deactivateProspect.TRIGGER, deactivateProspectSaga);
    yield takeLatest(
        prospectActions.downloadSampleForBulkProspect.TRIGGER,
        downloadSampleForBulkProspectSaga
    );
}

export default prospectSaga;

export {
    createProspectSaga,
    fetchProspectByIdSaga,
    deleteProspectSaga,
    createProspectQuestionnaireSaga,
    fetchProspectsByPartnerSaga,
    updateProspectSaga,
};
