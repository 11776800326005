/**
 * The different types of SKUS for the application.
 *
 * @type {Object}
 */
export const SKU_TYPES = {
    SYSTEM: 'SYSTEM',
    COMMERCIAL: 'COMMERCIAL',
};

export const BENEFITS_DOCUMENT_CREATOR_SKU = {
    KEY: 'benefits-document-creator',
    VALUE: 'Benefits Documents Creator',
};

export const HANDBOOKS_SKU = {
    KEY: 'handbooks',
};

export const EHS_SKU = {
    EHS_CLIENT_BASIC_KEY: 'ehsclientbasic',
    EHS_CLIENT_PREMIUM_KEY: 'ehsclientpremium',
    EHS_COSMIC_KEY: 'ehscosmic',
    EHS_PARTNER_KEY: 'ehspartner',
    EHS_DEMO_KEY: 'ehsdemo',
};

export const LICENSE_TYPES = {
    PACKAGE: 'package',
    PREMIUM: 'premium',
};

export const PRODUCT_AND_SERVICES = {
    MESSAGE: `At least one permission must be added in order to save Products and Services.`,
};

export const MAP_PORTAL_SKU = {
    MINERAL_ADVANTAGE_PROGRAM: 'mineral_advantage_program',
};

export const SKU_LEARN_LINKS = {
    'courses.communication':
        'https://trustmineral.com/solutions/learn-course-catalogs/#communication',
    'courses.cybersecurity':
        'https://trustmineral.com/solutions/learn-course-catalogs/#cybersecurity',
    'courses.deib': 'https://trustmineral.com/solutions/learn-course-catalogs/#DEIB',
    'courses.healthwellness': 'https://trustmineral.com/solutions/learn-course-catalogs/#health',
    'courses.leadershipmanagement':
        'https://trustmineral.com/solutions/learn-course-catalogs/#leadership',
    'courses.msftskills': 'https://trustmineral.com/solutions/learn-course-catalogs/#microsoft',
    learnpackagenysharassment: 'https://trustmineral.com/solutions/learn-course-catalogs/#whp',
    learnpackageriskandsafety: 'https://trustmineral.com/solutions/learn-course-catalogs/#safety',
    learnpackageebonly: 'https://trustmineral.com/solutions/learn-course-catalogs/#core',
};
