/**
 * Initialize the 'ui.loading' state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    // Boolean to hold the current loading state.
    loading: false,
    // The text to display to the user while showing the loading indicator.
    text: 'loading',
};

export default INITIAL_STATE;
