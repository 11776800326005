import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Box from 'ravenjs/lib/Box';
import Typography from 'ravenjs/lib/Typography';
import { themeGet } from 'ravenjs/utils/theme';
import { renderCheck } from 'ravenjs/utils/viewport';

const FormGroupContentStyled = styled(Box)`
    /**
     * Add all of the remaining styles from theme
     */
    ${themeGet('FormGroupContent.styles')};
`;

function FormGroupContent(props) {
    const { children, schema } = props;
    // Extract the `boxProps` from the schema.
    const boxProps = _get(schema, 'boxProps', {});
    const disclaimer = _get(schema, 'disclaimer', '');

    // Extract and render description from the schema.
    const renderSubDescription = () => {
        return (
            <Typography
                gutterBottom="10px"
                gutterTop="0"
                gutterLeft="18px"
                gutterRight="10px"
                fontSize="14px"
                fontFamily="Roboto"
                fontWeight="600"
            >
                {disclaimer}
            </Typography>
        );
    };

    // Render the styled FormGroupContent.
    return (
        <FormGroupContentStyled
            elevation={5}
            margin="0 0 1.25rem 0"
            padding={renderCheck('md', 'less') ? '0.625rem 0' : '1.25rem 0'}
            backgroundColor="transparent"
            {...boxProps}
        >
            {!isEmpty(disclaimer) ? renderSubDescription() : null}
            {children}
        </FormGroupContentStyled>
    );
}

FormGroupContent.propTypes = {
    children: PropTypes.node,
    schema: PropTypes.object,
};

FormGroupContent.defaultProps = {
    children: null,
    schema: null,
};

export default FormGroupContent;
