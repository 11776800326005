import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const NewCheckboxEmptyIcon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1577_24152)">
                <rect x="4" y="4" width="16" height="16" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5Z"
                    fill="#D4D4D4"
                />
            </g>
            <defs>
                <clipPath id="clip0_1577_24152">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

NewCheckboxEmptyIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

NewCheckboxEmptyIcon.defaultProps = {
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default NewCheckboxEmptyIcon;
