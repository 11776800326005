import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';

import * as authActions from 'modules/auth/actions';

import * as uiActions from '../actions';
import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'ui.navigation' redux handlers.
 *
 * @type {Object}
 */
const navigation = {
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [uiActions.fetchAppNav.SUCCESS]: helpers.fetchAppNavSuccess,
    [uiActions.hideAppNav.SUCCESS]: helpers.hideNavSuccess('appNav'),
    [uiActions.hideSubNav.SUCCESS]: helpers.hideNavSuccess('subNav'),
    [uiActions.hideUserNav.SUCCESS]: helpers.hideNavSuccess('userNav'),
    [uiActions.showAppNav.SUCCESS]: helpers.showNavSuccess('appNav'),
    [uiActions.showSubNav.SUCCESS]: helpers.showNavSuccess('subNav'),
    [uiActions.showUserNav.SUCCESS]: helpers.showNavSuccess('userNav'),
};

// Create and export the 'ui.navigation' reducer.
// NOTE: The default export will be the reducer.
export default createReducer(INITIAL_STATE, navigation);

// Create and export named 'ui.navigation' selectors.
// NOTE: The rest of the 'named' exports will be the selectors, for this module.

/**
 * Get all the info about the application navigation.
 *
 * @method getNavigation
 * @param  {Object}      state The current state
 * @return {Object}            The navigation info
 */
export const getNavigation = state => _get(state, 'ui.navigation', {});

/**
 * Get info about an application navigation via the `navId`.
 *
 * @method getNavById
 * @param  {Object}   navId The id of the navigation to fetch
 * @return {Object}         The navigation info
 */
export const getNavById = navId => createSelector([getNavigation], nav => _get(nav, navId, {}));

/**
 * Get the info about the application's navigation.
 *
 * @method getAppNav
 * @param  {Object}   state The current state
 * @return {Object}         The navigation info
 */
export const getAppNav = createSelector([getNavigation], nav => _get(nav, 'nav', {}));

/**
 * Get all the info about the application's sub navigation.
 *
 * @method getSubNav
 * @param  {Object}  state The current state
 * @return {Object}        The sub navigation info
 */
export const getSubNav = createSelector([getNavigation], nav => _get(nav, 'subNav', {}));

/**
 * Get the info about the application's user navigation.
 *
 * @method getUserNav
 * @param  {Object}   state The current state
 * @return {Object}         The user navigation info
 */
export const getUserNav = createSelector([getNavigation], nav => _get(nav, 'userNav', {}));
