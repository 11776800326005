import axios from 'axios';
import Qs from 'qs';

import learnPackages from 'static/config/learnPackages.json';

/**
 * API to create sku
 *
 * @method createSKU
 * @param  {Object}  data
 * @return {Promise}
 */
export const createSKU = data =>
    axios({
        method: 'post',
        url: '/v2/skus',
        data,
    });

/**
 * API to fetch Learn packages
 *
 * @method fetchLearnPackages
 * @return {Promise}
 */
export const fetchLearnPackages = () =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve(learnPackages);
        }, 1500);
    });

/**
 * API to fetch sku bu id
 *
 * @method fetchSKU
 * @param  {number}  id
 * @return {Promise}
 */
export const fetchSKU = id =>
    axios({
        method: 'get',
        url: `/v2/skus/${id}`,
    });

/**
 * API to fetch skus
 *
 * @method fetchSKUsAPI
 * @param  {Object}             params
 * @return {Promise}
 */
export const fetchSKUs = params => {
    return axios({
        method: 'get',
        url: '/v2/skus',
        params,
    });
};

/**
 * API to update sku
 *
 * @method updateSKU
 * @param  {number}  id
 * @param  {Object}  data
 * @return {Promise}
 */
export const updateSKU = (id, data) =>
    axios({
        method: 'put',
        url: `/v2/skus/${id}`,
        data,
    });

/**
 * API to fetch Licenses
 *
 * @method fetchLicenses
 * @param  {Object}  data
 * @return {Promise}
 */
export const fetchLicenses = data =>
    axios({
        method: 'get',
        url: `/v2/licenses`,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        params: data,
    });
