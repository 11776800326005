import _get from 'lodash/get';

import INITIAL_STATE from './initialState';

/**
 * Reducer helper for fetching company/partner locations (success).
 *
 * @method fetchCompanyLocationsSuccess
 * @param  {Object} state  The 'user' state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchCompanyLocationsSuccess = (state, { payload: locations }) => {
    return {
        ...state,
        locations,
    };
};

/**
 * Reducer helper for fetching company/partner locations (success).
 *
 * @method fetchAllLocationsForCompanySuccess
 * @param  {Object} state  The 'user' state
 * @param  {Object} action The current action
 * @return {Object} The updated state
 */
export const fetchAllLocationsForCompanySuccess = (state, action) => {
    if (_get(action, 'payload.useStore', false)) {
        return {
            ...state,
            locations: _get(action, 'payload.locations', {}),
        };
    }
    return {
        ...state,
    };
};

/**
 * Reducer helper for the fetching the user (success).
 *
 * @method fetchUserSuccess
 * @param  {Object}         state  The 'user' state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const fetchUserSuccess = (state, action) => {
    return {
        ...state,
        info: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the fetching the user's company (success).
 *
 * @method fetchCompanySuccess
 * @param  {Object}            state  The 'user' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const fetchCompanySuccess = (state, action) => {
    return {
        ...state,
        company: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the updating page status (success).
 *
 * @method updatePageStatusSuccess
 * @param  {Object}            state  The 'form' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const updatePageStatusSuccess = (state, action) => {
    return {
        ...state,
        pageStatus: _get(action, 'payload', false),
    };
};

/**
 * Reducer helper for the fetching the user's company (success).
 *
 * @method fetchConfigurationSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const fetchConfigurationSuccess = (state, action) => {
    return {
        ...state,
        configuration: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the fetching the user's information banner (success).
 *
 * @method fetchInformationBannerSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const fetchInformationBannerSuccess = (state, action) => {
    return {
        ...state,
        informationBanner: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for user's permissions (success).
 *
 * @method permissionsSuccess
 * @param  {string}           type  The type of user permissions
 * @return {Object}                 The updated state
 */
export const permissionsSuccess = type => (state, action) => {
    return {
        ...state,
        permissions: {
            ...state.permissions,
            [type]: _get(action, 'payload', []),
        },
    };
};

/**
 * Reducer helper for catching the auth logout action.
 *
 * @method logoutSuccess
 * @return {Object}      The updated state
 */
export const logoutSuccess = () => INITIAL_STATE;

/**
 * Reducer helper for the fetching the user (success).
 *
 * @method updateUserSuccess
 * @param  {Object}         state  The 'user' state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const updateUserSuccess = (state, action) => {
    return {
        ...state,
        info: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the updating the user preferences (success).
 *
 * @method updateUserPreferencesSuccess
 * @param  {Object}         state  The 'user' state
 * @param  {Object}         action The current action
 * @return {Object}                The updated state
 */
export const updateUserPreferencesSuccess = (state, action) => {
    return {
        ...state,
        preference: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the fetching the content type (success).
 *
 * @method fetchAllContentTypeSuccess
 * @param  {Object}            state  The 'user' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const fetchAllContentTypesSuccess = (state, action) => {
    return {
        ...state,
        contentTypes: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for updating EULA status (success).
 *
 * @method updateEULAStatusSuccess
 * @param  {Object}            state  The 'user' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const updateEULAStatusSuccess = (state, action) => {
    return {
        ...state,
        EULA: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for updating welcome insights status (success).
 *
 * @method updateWelcomeInsightsStatusSuccess
 * @param  {Object}            state  The 'user' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const updateWelcomeInsightsStatusSuccess = (state, action) => {
    return {
        ...state,
        welcomeInsightStatus: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for updating Company details reminder status (success).
 *
 * @method updateCompanyDetailsReminderStatusSuccess
 * @param  {Object}            state  The 'user' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const updateCompanyDetailsReminderStatusSuccess = (state, action) => {
    return {
        ...state,
        companyDetailsReminder: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the fetching the Partner Settings (success).
 *
 * @method fetchPartnerSettingsSuccess
 * @param  {Object}            state  The 'user' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const fetchPartnerSettingsSuccess = (state, action) => {
    return {
        ...state,
        partnerSettings: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the update the Partner Settings.
 *
 * @method updatePartnerSettingsSuccess
 * @param  {Object}            state  The 'user' state
 * @param  {Object}            action The current action
 * @return {Object}                   The updated state
 */
export const updatePartnerSettingsSuccess = (state, action) => {
    return {
        ...state,
        partnerSettings: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the update the safety manual template url (success).
 *
 * @method updateSafetyManualTemplateUrlSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const updateSafetyManualTemplateUrlSuccess = (state, action) => {
    return {
        ...state,
        safetyManualTemplateUrl: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the update the handbook archived status (success).
 *
 * @method updateArchivedHandbookStatusSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const updateArchivedHandbookStatusSuccess = (state, action) => {
    return {
        ...state,
        archivedHandbookStatus: _get(action, 'payload', false),
    };
};

/**
 * Reducer helper for the update to click on safety menu navigation (success).
 *
 * @method updateClickSafetyMenuSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const updateClickSafetyMenuSuccess = (state, action) => {
    return {
        ...state,
        clickSafetyMenu: _get(action, 'payload', false),
    };
};

/**
 * Reducer helper for the update the sponsorship list (success).
 *
 * @method updateSponsorshipListSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const updateSponsorshipListSuccess = (state, action) => {
    return {
        ...state,
        sponsorships: _get(action, 'payload', false),
    };
};

/**
 * Reducer helper for the update the partner ads TOS acceptance (success).
 *
 * @method updatePartnerConfigurationsSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const updatePartnerConfigurationsSuccess = (state, action) => {
    return {
        ...state,
        partnerConfigurations: _get(action, 'payload', {}),
    };
};

/**
 * Reducer helper for the update the import status (success).
 *
 * @method updateImportStatusSuccess
 * @param  {Object}                  state  The 'user' state
 * @param  {Object}                  action The current action
 * @return {Object}                         The updated state
 */
export const updateImportStatusSuccess = (state, action) => {
    const payload = _get(action, 'payload', {});
    return {
        ...state,
        importStatus: {
            ...state.importStatus,
            [payload.name]: {
                ...state.importStatus[payload.name],
                ...payload,
            },
        },
    };
};
