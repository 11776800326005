const AI_ASSISTANT = {
    ASK_BUTTON: {
        EVENT: 'ARIESQuestion',
        EVENT_PROPERTIES: {
            DESCRIPTION: 'Trigger the track event every time a question is submitted to the LLM',
        },
    },
    THUMBS_UP_BUTTON: {
        event: 'ARIESThumbsUp',
        eventProperties: {
            description: 'Trigger the feature every time a thumbs up on a response is clicked',
        },
    },
    THUMBS_DOWN_BUTTON: {
        event: 'ARIESThumbsDown',
        eventProperties: {
            description: 'Trigger the feature every time a thumbs down on a response is clicked',
        },
    },
    REVIEW_COMPANY_LOCATIONS: {
        event: 'ARIESReviewCompanyLocations',
        eventProperties: {
            description:
                'Trigger the feature every time the Review Company Locations link is clicked.',
        },
    },
    RESPONSE_LINK: {
        event: 'AriesResponseLinkClick',
        eventProperties: {
            description:
                'Trigger the track event every time a referenced link is clicked on a response is clicked.',
        },
    },
    RESPONSE_LINKS: {
        event: 'ARIESResponseLinks',
        eventProperties: {
            description: 'Trigger the track event every time a response is shown.',
        },
    },
    DEFAULT_PROMPT: {
        event: 'ARIESDefaultPrompt',
        eventProperties: {
            description:
                'Trigger the feature every time a default prompt is clicked. As the Event Property, pass in the title of the prompt.',
        },
    },
};

export default AI_ASSISTANT;
