import { put } from 'redux-saga/effects';

/**
 * The saga for handling the ui loading state.
 *
 * @method uiLoadingSaga
 * @type  {Generator}
 * @param {Object}       [dispatcher={}] The set of dispatcher actions
 * @param {Object}       [action={}]     The original redux-action
 */
export function* uiLoadingSaga(dispatcher = {}, action = {}) {
    try {
        const {
            payload: { loading, text },
        } = action;
        // Not much to do here, we are simply setting the `ui.loading` state
        // based on the given payload from the current action.
        // Just dispatch the success action, and let the reducer handle the rest.
        yield put(dispatcher.success(loading, text));
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(dispatcher.error(error));
    }
}
