import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MediaIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <g fill="none" fillRule="evenodd">
                <g fill="#FFF" fillRule="nonzero">
                    <g>
                        <path
                            d="M8.108 24.012H33.056V27.754H8.108z"
                            transform="translate(-175 -930) translate(175 930.597)"
                        />
                        <path
                            d="M52.244 59.95l2.646-2.645-8.9-8.9c1.563-2.107 2.489-4.713 2.489-7.531 0-5.072-2.998-9.457-7.315-11.478V13.57L27.594 0H0v55.51h41.164v-3.158c.772-.362 1.502-.798 2.18-1.301l8.9 8.9zM28.69 6.389l6.086 6.086H28.69V6.388zM3.742 3.742h21.206v12.474h12.474v12.095c-.527-.067-1.064-.102-1.609-.102-3.891 0-7.378 1.765-9.703 4.535H8.108v3.742h15.824c-.507 1.368-.784 2.846-.784 4.388 0 .202.005.403.015.602H8.108v3.742h15.808c1.01 2.757 2.952 5.068 5.443 6.55H3.742V3.741zm32.071 46.055c-4.92 0-8.923-4.003-8.923-8.923s4.003-8.923 8.923-8.923 8.923 4.003 8.923 8.923-4.002 8.923-8.923 8.923z"
                            transform="translate(-175 -930) translate(175 930.597)"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

MediaIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

MediaIcon.defaultProps = {
    fill: DEFAULT_COLORS.WHITE,
    height: '61',
    viewBox: '0 0 55 61',
    width: '55',
};

export default MediaIcon;
