import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import Tooltip from 'ravenjs/lib/Tooltip';
import Typography from 'ravenjs/lib/Typography';
import { callFunc, get, getHandler, isEmpty, themeGet, renderCheck } from 'ravenjs/utils';
import MESSAGES from 'constants/messages';

const SkusTooltipStyled = styled.div`
    display: flex;
    width: 100%;
`;

/**
 * Display a comma separated list of configuration SKUs.
 * TODO: Move this component out of RavenJS library.
 *
 * @method      SkusTooltip
 * @param       {Object}        props Component props
 * @constructor
 */
function SkusTooltip(props) {
    const { children, configuration, fetchDataRef, handlers, noData, placement } = props;
    // Extract handlers.
    const fetchDataHandler = getHandler(handlers, fetchDataRef);
    // `use`Hooks
    const [config, setConfig] = React.useState(configuration);
    const isMobile = renderCheck('md', 'less');
    const maxWidth = !isMobile ? 600 : null;
    const minWidth = 200;

    // Render the list of SKUs in a single string.
    function renderSkusList() {
        // Extract the SKUs from the configuration.
        const skus = get(config, 'skus', []).filter(sku => {
            return sku.type === 'COMMERCIAL';
        });
        // Build a sample string in case SKUs aren't available.
        let skusList = noData;
        // If SKUs are available, then build up the string.
        if (!isEmpty(skus)) {
            skusList = skus
                .map(
                    sku =>
                        `${sku.name}${
                            sku.sponsor && config.displaySponsorInfo ? ` (${sku.sponsor})` : ''
                        }` || sku
                )
                .sort()
                .join(', ');
        }
        // Render the SKUs list string.
        return (
            <>
                <Typography>{MESSAGES.TOOLTIP.SKUS}</Typography>
                <Typography gutterBottom="0">{skusList}</Typography>
            </>
        );
    }
    // Handle the `onOpen` event for the Tooltip.
    async function handleOnOpen() {
        // Check to see if we have a fetchDataHandler available.
        if (!fetchDataHandler) {
            return undefined;
        }
        // Try to fetch the data and update the local configuration.
        try {
            // Await the API call.
            const resp = await callFunc(fetchDataHandler, props);
            // Update the config with the new list of SKUs.
            return setConfig(resp);
        } catch (e) {
            return null;
        }
    }

    useEffect(() => {
        setConfig(configuration);
    }, [configuration]);

    // Render the SkusTooltip.
    return (
        <Tooltip
            background={themeGet('palette.dark.color')()}
            content={renderSkusList()}
            disableFocusListener
            onOpen={handleOnOpen}
            placement={placement}
            timeout={0}
            margin={0}
            tooltip={{
                style: { minWidth, maxWidth },
            }}
            // This is to tackle the z-index issue when rendering inside the Angular.js app.
            PopperProps={{ style: { zIndex: 1200 } }}
        >
            <SkusTooltipStyled>{children}</SkusTooltipStyled>
        </Tooltip>
    );
}

SkusTooltip.propTypes = {
    /**
     * The children for the SKUs list tooltip component.
     */
    children: PropTypes.node.isRequired,
    /**
     * The main configuration data.
     */
    configuration: PropTypes.object,
    /**
     * The handler name to fetch Async data.
     */
    fetchDataRef: PropTypes.string,
    /**
     * A set of action handlers.
     */
    handlers: PropTypes.object,
    /**
     * Text to display when no SKUs are availabe.
     */
    noData: PropTypes.string,
    /**
     * The placement of the Tooltip.
     */
    placement: PropTypes.oneOf([
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom-start',
        'bottom',
        'bottom-end',
        'left-start',
        'left',
        'left-end',
    ]),
};

SkusTooltip.defaultProps = {
    configuration: null,
    fetchDataRef: null,
    handlers: null,
    noData: 'No Products and Services found.',
    placement: 'top',
};

export default SkusTooltip;
