import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_COLORS } from 'constants/colors';

import SvgIcon from '../SvgIcon';

const GearIcon = ({ color, colorInner, setRef, ...rest }) => (
    <SvgIcon {...rest} ref={setRef}>
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <path
                            fill={colorInner}
                            d="M10.628 20H9.372c-1.015 0-1.84-.826-1.84-1.841v-.425c-.432-.138-.852-.312-1.255-.52l-.3.3c-.73.73-1.897.71-2.605 0l-.887-.887c-.709-.708-.73-1.875 0-2.604l.301-.3c-.208-.403-.382-.823-.52-1.254H1.84C.826 12.469 0 11.643 0 10.628V9.372c0-1.015.826-1.84 1.841-1.84h.425c.138-.432.312-.852.52-1.255l-.3-.3c-.73-.729-.71-1.896 0-2.605l.887-.887c.71-.71 1.876-.728 2.604 0l.3.301c.403-.208.823-.382 1.254-.52V1.84C7.531.826 8.357 0 9.372 0h1.256c1.015 0 1.84.826 1.84 1.841v.425c.432.138.852.312 1.255.52l.3-.3c.73-.73 1.897-.71 2.605 0l.887.887c.709.708.73 1.875 0 2.604l-.301.3c.208.403.382.823.52 1.254h.425c1.015 0 1.841.826 1.841 1.841v1.256c0 1.015-.826 1.84-1.841 1.84h-.425c-.138.432-.312.852-.52 1.255l.3.3c.73.729.71 1.896 0 2.605l-.887.887c-.71.71-1.876.728-2.604 0l-.3-.301c-.403.208-.823.382-1.254.52v.425c0 1.015-.826 1.841-1.841 1.841z"
                            transform="translate(-103 -476) translate(80 467.303) translate(23.5 9)"
                        />
                        <path
                            fill={color}
                            d="M6.473 15.983c.56.331 1.162.581 1.79.743.26.067.44.3.44.568v.865c0 .369.3.67.67.67h1.255c.369 0 .669-.301.669-.67v-.865c0-.268.18-.5.44-.568.628-.162 1.23-.412 1.79-.743.23-.136.523-.099.712.09l.613.613c.264.265.689.258.946 0l.888-.887c.257-.257.266-.682 0-.947l-.613-.613c-.189-.189-.226-.482-.09-.712.331-.56.581-1.162.743-1.79.067-.26.3-.44.568-.44h.865c.369 0 .67-.3.67-.67V9.373c0-.369-.301-.669-.67-.669h-.865c-.267 0-.5-.18-.568-.44-.162-.628-.412-1.23-.743-1.79-.136-.23-.099-.523.09-.712l.613-.613c.265-.265.258-.689 0-.946l-.887-.888c-.258-.258-.682-.265-.947 0l-.613.613c-.189.189-.482.226-.712.09-.56-.331-1.162-.581-1.79-.743-.26-.067-.44-.3-.44-.568v-.865c0-.369-.3-.67-.67-.67H9.373c-.369 0-.669.301-.669.67v.865c0 .268-.18.5-.44.568-.628.162-1.23.412-1.79.743-.23.136-.523.099-.712-.09l-.613-.613c-.264-.265-.689-.258-.946 0l-.888.887c-.257.257-.266.682 0 .947l.613.613c.189.189.226.482.09.712-.331.56-.581 1.162-.743 1.79-.067.26-.3.44-.568.44h-.865c-.369 0-.67.3-.67.67v1.255c0 .369.301.669.67.669h.865c.268 0 .5.18.568.44.162.628.412 1.23.743 1.79.136.23.099.523-.09.712l-.613.613c-.265.265-.258.689 0 .946l.887.888c.258.258.682.265.947 0l.613-.613c.139-.14.429-.257.712-.09z"
                            transform="translate(-103 -476) translate(80 467.303) translate(23.5 9)"
                        />
                        <path
                            fill={colorInner}
                            d="M10 14.352c-2.4 0-4.352-1.953-4.352-4.352 0-2.4 1.953-4.352 4.352-4.352 2.4 0 4.352 1.953 4.352 4.352 0 2.4-1.953 4.352-4.352 4.352zm0-7.532c-1.753 0-3.18 1.427-3.18 3.18 0 1.753 1.427 3.18 3.18 3.18 1.753 0 3.18-1.427 3.18-3.18 0-1.753-1.427-3.18-3.18-3.18z"
                            transform="translate(-103 -476) translate(80 467.303) translate(23.5 9)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

GearIcon.propTypes = {
    color: PropTypes.string,
    colorInner: PropTypes.string,
    height: PropTypes.string,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

GearIcon.defaultProps = {
    color: DEFAULT_COLORS.BLUE,
    colorInner: DEFAULT_COLORS.BLACK,
    height: '21',
    setRef: null,
    viewBox: '0 0 21 21',
    width: '21',
};

export default GearIcon;
