import _get from 'lodash/get';
import { createSelector } from 'reselect';

import * as authActions from 'modules/auth/actions';
import { createReducer } from 'utils/reducer';

import * as bootStrapActions from './actions';
import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'bootStrap' redux handlers.
 *
 * @type {Object}
 */
const bootStrap = {
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [bootStrapActions.fetchAppBootstrap.SUCCESS]: helpers.fetchConfigSuccess,
    [bootStrapActions.fetchDowntimeInfo.SUCCESS]: helpers.downtimeSuccess,
};

// Create and export the 'bootStrap' reducer.
// NOTE: The default export will be the reducer.
export default createReducer(INITIAL_STATE, bootStrap);

// SELECTORS

/**
 * Get the complete bootstrap config
 *
 * @method getConfig
 * @param  {Object}  state The app state
 * @return {Object}        The config
 */
export const getConfig = state => _get(state, 'config', {});

/**
 * Get the map / dictionary of components from the bootstrap state
 *
 * @method getComponents
 * @return {Object}        The components map
 */
export const getComponents = createSelector([getConfig], config => _get(config, 'components', {}));

/**
 * Get the list of dashboard cards
 *
 * @method getComponentConfig
 * @param  {string}  componentName The name of the component to fetch the config for
 * @return {Any|Object}            The fetched component's config
 */
export const getComponentConfig = componentName =>
    createSelector([getComponents], componentsList => componentsList[componentName] || {});

/**
 * Get the disclaimer
 *
 * @method getDisclaimer
 * @param  {Object}      state The app state
 * @return {string}            The system disclaimer
 */
export const getDisclaimer = createSelector([getConfig], config =>
    _get(config, 'system.disclaimer', '')
);

/**
 * Get issue categories
 *
 * @method getIssueCategories
 * @param  {Object}      state The app state
 * @return {Array}             issue categories
 */
export const getIssueCategories = createSelector([getConfig], config =>
    _get(config, 'issueCategories', [])
);

/**
 * Get list of states
 *
 * @method getStates
 * @param  {Object}      state The app state
 * @return {Array}             issue categories
 */
export const getStates = createSelector([getConfig], config => _get(config, 'states', []));

/**
 * Get system support. These include email, phone number, etc..
 *
 * @method getSystemSupport
 * @param  {Object}         state The app state
 * @return {Object}               The system support
 */
export const getSystemSupport = createSelector([getConfig], config =>
    _get(config, 'system.support', {})
);

/**
 * Get downtime info.
 *
 * @method getDowntimeInfo
 * @param  {Object}         state The app state
 * @return {Object}               The Downtime Info
 */
export const getDowntimeInfo = createSelector([getConfig], config =>
    _get(config, 'maintenance', {})
);

/**
 * Return true if user has archived handbooks.
 *
 * @method getStates
 * @param  {Object}      state The app state
 * @return {Boolean}             issue categories
 */
export const getArchivedHandbooks = createSelector([getConfig], config =>
    _get(config, 'hasArchivedHandbooks', false)
);
