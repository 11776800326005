import { takeLatest } from 'redux-saga/effects';

import * as companyPoliciesActions from '../actions';

import {
    deleteJobDescriptionSaga,
    downloadJobDescriptionSaga,
    fetchJobDescriptionSaga,
    fetchJobDescriptionsSaga,
    fetchOccupationSaga,
    fetchOccupationsSearchSaga,
    fetchOccupationsListSaga,
    fetchOccupationByCodeSaga,
    fetchStatesSaga,
    getJobDescriptionDocumentsSaga,
    saveJobDescriptionBuilderSaga,
    updateJobDescriptionSaga,
} from './jobDescriptionBuilder';

/**
 * Root saga for `company Policies`
 * Triggers sagas related to all tools events
 *
 * @method companyPoliciesSaga
 * @type   {Generator}
 */
function* companyPoliciesSaga() {
    yield takeLatest(
        companyPoliciesActions.fetchOccupationsSearch.TRIGGER,
        fetchOccupationsSearchSaga
    );
    yield takeLatest(companyPoliciesActions.fetchOccupationsList.TRIGGER, fetchOccupationsListSaga);
    yield takeLatest(
        companyPoliciesActions.fetchOccupationByCode.TRIGGER,
        fetchOccupationByCodeSaga
    );
    yield takeLatest(companyPoliciesActions.fetchStates.TRIGGER, fetchStatesSaga);
    yield takeLatest(
        companyPoliciesActions.saveJobDescriptionBuilder.TRIGGER,
        saveJobDescriptionBuilderSaga
    );
    yield takeLatest(companyPoliciesActions.fetchJobDescriptions.TRIGGER, fetchJobDescriptionsSaga);
    yield takeLatest(companyPoliciesActions.deleteJobDescription.TRIGGER, deleteJobDescriptionSaga);
    yield takeLatest(
        companyPoliciesActions.downloadJobDescription.TRIGGER,
        downloadJobDescriptionSaga
    );
    yield takeLatest(companyPoliciesActions.fetchJobDescription.TRIGGER, fetchJobDescriptionSaga);
    yield takeLatest(companyPoliciesActions.updateJobDescription.TRIGGER, updateJobDescriptionSaga);
    yield takeLatest(companyPoliciesActions.fetchOccupation.TRIGGER, fetchOccupationSaga);
    yield takeLatest(
        companyPoliciesActions.getJobDescriptionDocuments.TRIGGER,
        getJobDescriptionDocumentsSaga
    );
}

export default companyPoliciesSaga;

export {
    deleteJobDescriptionSaga,
    downloadJobDescriptionSaga,
    fetchJobDescriptionSaga,
    fetchJobDescriptionsSaga,
    fetchOccupationSaga,
    fetchOccupationsSearchSaga,
    fetchOccupationsListSaga,
    fetchOccupationByCodeSaga,
    fetchStatesSaga,
    getJobDescriptionDocumentsSaga,
    saveJobDescriptionBuilderSaga,
    updateJobDescriptionSaga,
};
