import axios from 'axios';
import Qs from 'qs';

/**
 * Activate Prospect API
 *
 * @method activateProspectAPI
 * @param   {number}    partnerId
 * @param   {number}    prospectId
 * @return {Promise}
 */
export const activateProspectAPI = ({ partnerId, prospectId }) =>
    axios({
        method: 'patch',
        url: `/v2/partners/${partnerId}/prospects/${prospectId}/activate`,
    });

/**
 * Bulk Upload prospects API
 *
 * @param   {boolean}   enrollmentStatus
 * @param   {File}      file
 * @param   {number}    partnerId
 * @param   {boolean}   takeFitnessTest
 * @return  {Promise}
 */
export const bulkUploadProspectsAPI = ({ enrollmentStatus, file, fitnessTest, partnerId }) =>
    axios({
        method: 'post',
        url: `/v2/partners/${partnerId}/prospects/bulk`,
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: {
            enrollmentStatus: enrollmentStatus ? 'enrolled' : '',
            fitnessTest: fitnessTest ? 'sent' : '',
        },
    });

/**
 * Add new prospect API
 *
 * @method  createProspectAPI
 * @param {Object}  params
 * @return {Promise}
 */
export const createProspectAPI = params => {
    const { data, partnerId } = params;
    return axios({
        data,
        method: 'post',
        url: `/v2/partners/${partnerId}/prospects`,
    });
};

/**
 * Deactivate Prospect API
 *
 * @method deactivateProspectAPI
 * @param   {number}    partnerId
 * @param   {number}    prospectId
 * @return {Promise}
 */
export const deactivateProspectAPI = ({ partnerId, prospectId }) =>
    axios({
        method: 'patch',
        url: `/v2/partners/${partnerId}/prospects/${prospectId}/deactivate`,
    });

/**
 * Update prospect API
 *
 * @method  updateProspectAPI
 * @param {Object}  params
 * @return {Promise}
 */
export const updateProspectAPI = params => {
    const { data, partnerId, prospectId } = params;
    return axios({
        data,
        method: 'put',
        url: `/v2/partners/${partnerId}/prospects/${prospectId}`,
    });
};

/**
 * Fetch prospect by id API
 *
 * @method  fetchProspectByIdAPI
 * @param {Object}  params
 * @return {Promise}
 */
export const fetchProspectByIdAPI = params => {
    const { prospectId, partnerId } = params;
    return axios({
        method: 'get',
        url: `/v2/partners/${partnerId}/prospects/${prospectId}`,
    });
};

/**
 * Fetch list of prospects
 *
 * @method fetchProspectsByPartnerAPI
 * @param {string|number}    partnerId
 * @param {Object}           params
 * @return {Promise}
 */
export const fetchProspectsByPartnerAPI = (partnerId, params) =>
    axios({
        method: 'get',
        params,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
        url: `/v2/partners/${partnerId}/prospects`,
    });

/**
 * Delete prospect by id API
 *
 * @method  deleteProspectAPI
 * @param {Object}  params
 * @return {Promise}
 */
export const deleteProspectAPI = params => {
    const { prospectId, partnerId } = params;
    return axios({
        method: 'delete',
        url: `/v2/partners/${partnerId}/prospects/${prospectId}`,
    });
};

/**
 * API to create questionnaire for prospect.
 *
 * @method createProspectQuestionnaireAPI
 * @return {Promise}
 */
export const createProspectQuestionnaireAPI = ({ prospectIds, suppressEmail = true }) =>
    axios({
        method: 'post',
        url: `/tools/v1/prospect-fitness-test/questionnaires`,
        type: 'apiTools',
        params: {
            suppressEmail,
        },
        data: {
            prospect_ids: prospectIds,
        },
    });

/**
 * Bulk delete prospect by id API
 *
 * @method  deleteBulkProspectsAPI
 * @param   {number}    partnerId
 * @param   {Array}     prospectIds
 * @return {Promise}
 */
export const deleteBulkProspectsAPI = ({ partnerId, prospectIds }) => {
    return axios({
        data: {
            prospectIds,
        },
        method: 'post',
        url: `/v2/partners/${partnerId}/prospects/bulk-delete`,
    });
};

/**
 * Bulk enroll prospects by id API
 *
 * @method  enrollBulkProspectsAPI
 * @param   {number}    partnerId
 * @param   {Array}     prospectIds
 * @return {Promise}
 */
export const enrollBulkProspectsAPI = ({ partnerId, prospectIds }) => {
    return axios({
        data: {
            prospectIds,
        },
        method: 'post',
        url: `/v2/partners/${partnerId}/prospects/bulk-enroll`,
    });
};

/**
 * Email prospect report API
 *
 * @method  emailProspectReportAPI
 * @param   {number}    companyId
 * @param   {number}    partnerId
 * @param   {Array}     prospectIds
 * @return {Promise}
 */
export const emailProspectReportAPI = ({ companyId, partnerId, userIds }) => {
    return axios({
        data: {
            companyId,
            type: 'partner-prospect',
            userIds,
        },
        method: 'post',
        params: {
            partnerId,
        },
        url: `/v2/users/send-email`,
    });
};

/**
 * Download sample csv for bulk import prospect.
 *
 * @method  downloadSampleForBulkProspectAPI
 * @param   {number}    partnerId
 * @return {Promise}
 */
export const downloadSampleForBulkProspectAPI = ({ partnerId }) => {
    return axios({
        method: 'get',
        url: `/v2/partners/${partnerId}/prospects/csv?type=template`,
    });
};
