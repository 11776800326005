/**
 * Initialize the 'auth' state.
 *
 * @type {Object}
 */
const INITIAL_STATE = {
    isImpersonatingUser: false,
    impersonateUserShowEnd: false,
    impersonateUserShowStart: false,
    impersonatorToken: {},
    isLoggedIn: false,
    isExpired: false,
    token: {},
    loginAttemptStatus: false,
};

export default INITIAL_STATE;
