import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { createReducer } from 'utils/reducer';

import * as authActions from './actions';
import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'auth' redux handlers.
 *
 * @type {Object}
 */
const auth = {
    [authActions.decodeToken.SUCCESS]: helpers.tokenSuccess,
    [authActions.fetchToken.SUCCESS]: helpers.tokenSuccess,
    [authActions.login.SUCCESS]: helpers.loginSuccess,
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [authActions.internallyLogout.SUCCESS]: helpers.internallyLogoutSuccess,
    [authActions.impersonateUser.ERROR]: helpers.impersonateUserError,
    [authActions.endImpersonateUser.SUCCESS]: helpers.endImpersonateUserSuccess,
    [authActions.setImpersonateUserShowStart.SUCCESS]: helpers.setImpersonateUserShowStartSuccess,
    [authActions.setImpersonateUserShowEnd.SUCCESS]: helpers.setImpersonateUserShowEndSuccess,
    [authActions.fetchImpersonateUserToken.SUCCESS]: helpers.fetchImpersonateUserTokenSuccess,
    [authActions.fetchImpersonateUserContent.SUCCESS]: helpers.fetchImpersonateUserContentSuccess,
    [authActions.setIsExpired.SUCCESS]: helpers.setIsExpiredSuccess,
    [authActions.setLoginAttemptStatus.SUCCESS]: helpers.setLoginAttemptStatusSuccess,
};

// Create and export the 'auth' reducer.
// NOTE: The default export will be the reducer.
export default createReducer(INITIAL_STATE, auth);

// Create and export named 'auth' selectors.
// NOTE: The rest of the 'named' exports will be the selectors, for this module.

/**
 * Get all the info about the current token.
 *
 * @method getToken
 * @param  {Object} state The current state
 * @return {Object} The token info
 */
export const getToken = state => _get(state, 'auth.token', {});

/**
 * Get all the info about the current decodedToken.
 *
 * @method getDecodedToken
 * @param  {Object} state The current state
 * @return {Object} The token info
 */
export const getDecodedToken = state => _get(state, 'auth.decodedToken', {});

/**
 * Get all the info about the current auth error.
 *
 * @method getAuthError
 * @param  {Object} state The current state
 * @return {Object} The auth error info
 */
export const getAuthError = state => _get(state, 'auth.error', '');

/**
 * Is the user currently logged in.
 *
 * @method isUserLoggedIn
 * @param  {Object} state The current state
 * @return {Object} The auth error info
 */
export const isUserLoggedIn = state => Boolean(_get(state, 'auth.isLoggedIn', false));

/**
 * get login attempt status
 *
 * @method getLoginAttemptStatus
 * @param  {Object} state The current state
 * @return {Object} The auth error info
 */
export const getLoginAttemptStatus = state =>
    Boolean(_get(state, 'auth.loginAttemptStatus', false));

/**
 * Get the login error from the auth state.
 *
 * @method getLoginError
 * @param  {Object} state The current state
 * @return {Object} The login error info
 */
export const getLoginError = createSelector([getAuthError], authError =>
    _get(authError, 'login', {})
);

/**
 * Get the forget password error from the auth state.
 *
 * @method getForgetPasswordError
 * @param  {Object} state The current state
 * @return {Object} The forget password error info
 */
export const getForgetPasswordError = createSelector([getAuthError], authError =>
    _get(authError, 'forgetPassword', {})
);

/**
 * Get the access token from the auth state.
 *
 * @method getAccessToken
 * @param  {Object} state The current state
 * @return {string} The access token
 */
export const getAccessToken = createSelector([getToken], token => _get(token, 'access_token', ''));

/**
 * Get the refresh token from the auth state.
 *
 * @method getRefreshToken
 * @param  {Object} state The current state
 * @return {string} The refresh token
 */
export const getRefreshToken = createSelector([getToken], token =>
    _get(token, 'refresh_token', '')
);

/**
 * Get the amount of time that the token expires in.
 *
 * @method getTokenExpiresIn
 * @param  {Object} state The current state
 * @return {number} The expires in time for the token
 */
export const getTokenExpiresIn = createSelector([getToken], token => _get(token, 'expires_in'));

/**
 * Get the timestamp when the token was last fetched.
 *
 * @method getTokenFetchedAt
 * @param  {Object} state The current state
 * @return {number} The fetched at time for the token
 */
export const getTokenFetchedAt = createSelector([getToken], token => _get(token, 'fetched_at'));

/**
 * Get the current user's ID from the token.
 *
 * @method getUserIdFromToken
 * @param  {Object} state The current state
 * @return {number} The current user's ID
 */
export const getUserIdFromToken = createSelector([getToken], token => _get(token, 'userId'));

/**
 * Is the current user impersonating another user.
 *
 * @method isImpersonatingUser
 * @param  {Object} state The current state
 * @return {boolean} The isImpersonatingUser state
 */
export const isImpersonatingUser = state => Boolean(_get(state, 'auth.isImpersonatingUser', false));

/**
 * Is display of the "impersonation is ending" banner enabled
 *
 * @method getImpersonateUserShowEnd
 * @param  {Object} state The current state
 * @return {boolean} The impersonateUserShowEnd state
 */
export const getImpersonateUserShowEnd = state =>
    Boolean(_get(state, 'auth.impersonateUserShowEnd', false));

/**
 * Is display of the "impersonation is starting" banner enabled
 *
 * @method getImpersonateUserShowStart
 * @param  {Object} state The current state
 * @return {boolean} The impersonateUserShowStart state
 */
export const getImpersonateUserShowStart = state =>
    Boolean(_get(state, 'auth.impersonateUserShowStart', false));

/**
 * Returns the timestamp for the expiry alert
 *
 * @method getExpiredState
 * @param  {Object}     state
 * @return {boolean}
 */
export const getExpiredState = state => _get(state, 'auth.isExpired', false);
