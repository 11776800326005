import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'ravenjs/constants/colors';

import SvgIcon from '../SvgIcon';

function PrintIcon(props) {
    const { disabled, setRef, width, height, ...rest } = props;
    const cursor = disabled ? 'not-allowed' : 'pointer';
    const fill = disabled ? COLORS.INPUT_DISABLED : COLORS.GREY;
    return (
        <SvgIcon cursor={cursor} width={width} height={height} {...rest} ref={setRef}>
            <path
                fill={fill}
                d="M15.412.399c.363 0 .657.287.657.64v3.71h2.088c.844 0 1.531.67 1.531 1.495v6.448c0 .825-.687 1.496-1.531 1.496h-2.088v3.44c0 .354-.294.64-.657.64H4.276c-.363 0-.656-.286-.656-.64v-3.44H1.53C.687 14.188 0 13.517 0 12.692V6.244C0 5.42.687 4.75 1.531 4.75H3.62v-3.71c0-.353.294-.64.657-.64zm-.656 11.345H4.932v5.243h9.824v-5.243zM12.51 15.02c.34 0 .615.182.615.406 0 .224-.275.406-.615.406H7.178c-.34 0-.615-.182-.615-.406 0-.224.275-.406.615-.406zm0-2.437c.34 0 .615.182.615.406 0 .224-.275.406-.615.406H7.178c-.34 0-.615-.182-.615-.406 0-.224.275-.406.615-.406zM14.756 1.68H4.932v3.068h9.824V1.681z"
            />
        </SvgIcon>
    );
}

PrintIcon.propTypes = {
    disabled: PropTypes.bool,
    setRef: PropTypes.object,
    viewBox: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

PrintIcon.defaultProps = {
    disabled: false,
    setRef: null,
    viewBox: '0 0 20 19',
    width: '20',
    height: '19px',
};

export default PrintIcon;
