import { bool, object, string, number } from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import { isEmpty } from 'ravenjs/utils/lodash';

import { DEFAULT_COLORS } from 'constants/colors';
import AppSubNavigationMain from 'components/AppSubNavigationMain';
import AppSubNavigationTopics from 'components/AppSubNavigationTopics';

const AppSubNavigationStyled = styled.div`
    display: flex;
    position: absolute;
    top: 42px;
    background: ${DEFAULT_COLORS.WHITE};
    color: ${DEFAULT_COLORS.BLACK};
    border: 1px solid ${DEFAULT_COLORS.BLACK};
    z-index: 100;
    padding: 2.094rem 2.094rem 2.094rem 2.094rem;
    right: ${({ subNavOverflow }) => (subNavOverflow ? 0 : 'auto')};
    margin: 0 0.625rem 0 0;
`;

const ArrowStyled = styled.div`
    width: 16px;
    height: 16px;
    border-top: 1px solid ${DEFAULT_COLORS.BLACK};
    border-left: 1px solid ${DEFAULT_COLORS.BLACK};
    position: absolute;
    top: 34px;
    right: 40%;
    transform: rotate(45deg);
    background: ${DEFAULT_COLORS.WHITE};
    z-index: 1000;
    box-shadow: none;
`;

const AppSubNavigation = props => {
    const { isImpersonatingUser, show, nav, scrollTop, subnav, state, isVisibleOnDOM } = props;

    const [subNavOverflow, setSubNavOverflow] = useState(false);
    const [overflowChecked, setOverflowChecked] = useState(false);

    const hasSubNav = () => {
        return (subnav.main && subnav.main.length) || (subnav.topics && subnav.topics.length);
    };

    const onClick = e => {
        // Need to stop propagation of event as this component is child of <NavItem>
        e.stopPropagation();
    };

    const renderTopicsOrContentSection = () => {
        if (!isEmpty(subnav.topics)) {
            return (
                <div style={{ marginLeft: '4rem' }}>
                    <AppSubNavigationTopics nav={nav} items={subnav.topics} />
                </div>
            );
        }
        return null;
    };

    if (show && isVisibleOnDOM && hasSubNav()) {
        return (
            <>
                <ArrowStyled />
                <VisibilitySensor
                    onChange={isVisible => {
                        if (!overflowChecked) {
                            setSubNavOverflow(!isVisible);
                        }
                        setOverflowChecked(true);
                    }}
                >
                    <AppSubNavigationStyled
                        isImpersonatingUser={isImpersonatingUser}
                        subNavOverflow={subNavOverflow}
                        scrollTop={scrollTop}
                    >
                        <div onClick={onClick} style={{ display: 'flex', flexDirection: 'row' }}>
                            <AppSubNavigationMain items={subnav.main} mainState={state} />
                            {renderTopicsOrContentSection()}
                        </div>
                    </AppSubNavigationStyled>
                </VisibilitySensor>
            </>
        );
    }

    return null;
};

AppSubNavigation.propTypes = {
    isImpersonatingUser: bool,
    scrollTop: number,
    show: bool,
    state: string,
    subnav: object,
    nav: object,
    isVisibleOnDOM: bool,
};

AppSubNavigation.defaultProps = {
    isImpersonatingUser: false,
    scrollTop: 0,
    show: false,
    state: '',
    subnav: {
        main: [],
        topics: [],
    },
    nav: {},
    isVisibleOnDOM: true,
};

export { AppSubNavigation as AppSubNavigationUnwrapped };
export default withRouter(AppSubNavigation);
