import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import { renderCheck } from 'ravenjs/utils/viewport';

import { DEFAULT_COLORS } from 'constants/colors';
import { trimPhoneNumber } from 'utils/phone';

const SupportPhoneNumberContainer = styled.div`
    color: ${DEFAULT_COLORS.BLACK};
    margin: ${props => props.margin};
    a {
        color: ${DEFAULT_COLORS.BLACK};
        font-family: ${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-decoration: none;
        :hover {
            color: ${DEFAULT_COLORS.LINK};
            text-decoration: none;
        }
    }
`;

const SupportPhoneNumber = ({ fontFamily, fontSize, fontWeight, margin, phoneNumber }) => {
    const isMobile = renderCheck('md', 'less');
    const formattedPhoneNumber = trimPhoneNumber(phoneNumber)
        .trim()
        .replace(/[\(\)]/g, '')
        .replace(/[\s]/g, '-');
    const formattedHrefPhoneNumber = formattedPhoneNumber.replace(/[\-]/g, '');
    const hrefPhoneNumber = `+1${formattedHrefPhoneNumber}`;

    return phoneNumber ? (
        <SupportPhoneNumberContainer
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            margin={margin}
        >
            {isMobile ? (
                <a className="pendo-support-phone-number" href={`tel:${hrefPhoneNumber}`}>
                    {formattedPhoneNumber}
                </a>
            ) : (
                formattedPhoneNumber
            )}
        </SupportPhoneNumberContainer>
    ) : null;
};

SupportPhoneNumber.propTypes = {
    fontFamily: string,
    fontSize: string,
    fontWeight: string,
    margin: string,
    phoneNumber: string,
};

SupportPhoneNumber.defaultProps = {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    margin: '0',
    phoneNumber: '',
};

export default SupportPhoneNumber;
