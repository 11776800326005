const DASHBOARD = {
    CAROUSEL_LIKE_DISLIKE: {
        event: 'dashboard',
        eventProperties: {
            description: 'Like/dislike article from carousel',
            articleId: '',
            value: '',
        },
    },
    CAROUSEL_RELEVANT_IRRELEVANT: {
        event: 'dashboard',
        eventProperties: {
            description: 'Relevant/irrelevant article from carousel',
            articleId: '',
            value: '',
        },
    },
    CAROUSEL_VIEW_ARTICLE: {
        event: 'dashboard',
        eventProperties: {
            description: 'View article from carousel',
            articleId: '',
        },
    },
    FAVORITE_DELETE_ARTICLE: {
        event: 'dashboard',
        eventProperties: {
            description: 'Delete article from favorite content',
            articleId: '',
        },
    },
    FAVORITE_VIEW_ARTICLE: {
        event: 'dashboard',
        eventProperties: {
            description: 'View article from favorite content',
            articleId: '',
        },
    },
    FAVORITE_SHARE_ARTICLE: {
        event: 'dashboard',
        eventProperties: {
            description: 'Share article from favorite content',
            articleId: '',
            url: '',
        },
    },
    FEATURED_CONTENT_VIEW_ARTICLE: {
        event: 'dashboard',
        eventProperties: {
            description: 'View article from featured content',
            articleId: '',
        },
    },
};

export default DASHBOARD;
