import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const LivingHandbookIcon = ({ height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M30.663,6.72
              C22.606,2.572,15.655,1.005,6.07,1.005V42.41c9.585,0,16.537,1.566,24.593,5.715V6.72z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M55.254,42.41
              c-8.555-0.274-17.035,1.695-24.591,5.715h22.715l6.942-6.75V6.134h-5.066V42.41z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M6.07,42.41
              c8.555-0.274,17.033,1.695,24.593,5.715H1.004V6.134H6.07V42.41z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M61.327,35.125c0,0.555-0.45,1.007-1.007,1.007
              c-0.558,0-1.012-0.452-1.012-1.007V7.145h-4.057c-0.556,0-1.009-0.454-1.009-1.011c0-0.558,0.453-1.007,1.009-1.007h5.068
              c0.557,0,1.007,0.45,1.007,1.007V35.125z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M30.663,47.116c0.555,0,1.007,0.452,1.007,1.009
              c0,0.558-0.452,1.01-1.007,1.01H1.004c-0.557,0-1.011-0.452-1.011-1.01V6.134c0-0.558,0.454-1.007,1.011-1.007H6.07
              c0.558,0,1.009,0.45,1.009,1.007c0,0.557-0.452,1.011-1.009,1.011H2.013v39.971H30.663z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#F1E3D8"
                d="M30.663,7.839
              C23.878,3.912,18.025,3.534,9.954,3.534v39.184c8.071,0,13.924,1.48,20.709,5.407c8.055-4.128,15.005-5.687,24.589-5.687V1.237
              c-9.584,0-16.534,1.557-24.589,5.687V7.839z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M19.164,1.326c4.006,0.924,7.814,2.373,11.89,4.464
              c0.362,0.153,0.616,0.513,0.616,0.93v41.405c-0.002,0.157-0.039,0.315-0.114,0.462c-0.254,0.493-0.863,0.688-1.357,0.434
              c-3.953-2.036-7.631-3.438-11.485-4.327c-3.853-0.89-7.92-1.271-12.645-1.271c-0.557,0-1.009-0.454-1.009-1.012V1.005
              c0-0.555,0.452-1.009,1.009-1.009C10.928-0.004,15.134,0.396,19.164,1.326 M29.652,7.334c-3.746-1.884-7.263-3.198-10.938-4.047
              C15.136,2.462,11.378,2.073,7.079,2.02v39.389c4.435,0.051,8.335,0.458,12.085,1.322c3.544,0.816,6.932,2.045,10.488,3.767V7.334z
              "
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M42.607,3.288c-3.674,0.851-7.189,2.163-10.938,4.049v39.162
              c3.557-1.722,6.944-2.95,10.488-3.767c3.749-0.864,7.649-1.271,12.085-1.324V2.022C49.944,2.075,46.187,2.464,42.607,3.288
              M30.269,5.79c4.073-2.091,7.884-3.538,11.89-4.461c4.03-0.93,8.235-1.331,13.094-1.331c0.558,0,1.011,0.452,1.011,1.009V42.41
              c0,0.558-0.453,1.01-1.011,1.01c-4.724,0-8.789,0.382-12.645,1.273c-3.854,0.889-7.533,2.291-11.484,4.327
              c-0.493,0.255-1.103,0.06-1.357-0.434c-0.075-0.146-0.112-0.305-0.11-0.462L29.652,6.72C29.652,6.303,29.906,5.943,30.269,5.79z"
            />
            <polygon
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                points="42.813,59.165 33.257,63
              37.09,53.441 54.685,35.849 60.407,41.57 "
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M35.065,61.189l7.178-2.881L58.979,41.57l-4.295-4.295L37.946,54.012
              L35.065,61.189z M43.124,60.123l-9.495,3.809c-0.513,0.206-1.1-0.045-1.306-0.56c-0.1-0.248-0.094-0.517,0-0.746l3.835-9.559
              c0.053-0.13,0.13-0.244,0.222-0.338l17.591-17.595c0.395-0.393,1.033-0.393,1.426,0l5.723,5.724c0.393,0.393,0.393,1.031,0,1.426
              L43.527,59.877C43.409,59.993,43.271,60.074,43.124,60.123z"
            />
            <polygon
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#282828"
                points="34.968,58.735
              33.257,62.998 37.521,61.289 "
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M32.323,62.626l1.711-4.265c0.049-0.122,0.124-0.238,0.224-0.338
              c0.395-0.393,1.031-0.391,1.422,0.002l2.537,2.539c0.103,0.096,0.184,0.214,0.238,0.351c0.206,0.515-0.045,1.103-0.56,1.308
              l-4.267,1.709c-0.229,0.094-0.498,0.1-0.746,0C32.368,63.727,32.118,63.141,32.323,62.626 M35.334,60.524l-0.269,0.665
              l0.668-0.267L35.334,60.524z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M36.378,54.154c-0.393-0.394-0.393-1.032,0-1.427
              c0.395-0.393,1.033-0.393,1.426,0l5.724,5.724c0.393,0.393,0.393,1.031,0,1.426c-0.395,0.393-1.033,0.393-1.427,0L36.378,54.154z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M54.685,35.847l5.723,5.724
              l2.184-2.184c0.549-0.549,0.549-1.444,0-1.993l-3.729-3.728c-0.548-0.55-1.444-0.55-1.994,0L54.685,35.847z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M59.693,42.284l-5.723-5.724c-0.394-0.393-0.394-1.031,0-1.426l2.185-2.183
              c0.47-0.47,1.093-0.708,1.709-0.708c0.588,0,1.178,0.216,1.64,0.645l3.799,3.792c0.47,0.477,0.708,1.095,0.708,1.709
              c0,0.617-0.238,1.239-0.708,1.709l-2.184,2.186C60.727,42.677,60.086,42.677,59.693,42.284 M56.11,35.849l4.295,4.295l1.473-1.469
              c0.076-0.077,0.114-0.181,0.114-0.285c0-0.104-0.038-0.209-0.114-0.284l-3.753-3.752c-0.073-0.062-0.167-0.092-0.261-0.092
              c-0.104,0-0.207,0.039-0.283,0.116L56.11,35.849z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M59.598,42.185c-0.395-0.393-0.395-1.031,0-1.424
              c0.393-0.396,1.029-0.396,1.424,0l0.808,0.808c0.727,0.726,1.115,1.692,1.105,2.679c-0.009,0.938-0.373,1.889-1.144,2.657
              l-7.313,7.314c-0.395,0.393-1.033,0.393-1.426,0c-0.395-0.395-0.395-1.033,0-1.426l7.312-7.313
              c0.377-0.376,0.558-0.824,0.562-1.249c0.005-0.45-0.181-0.897-0.521-1.237L59.598,42.185z"
            />
            <path
                clipPath="url(#SVGID_2_)"
                fill="#282828"
                d="M30.663,49.135c-0.558,0-1.011-0.452-1.011-1.01
              c0-0.557,0.454-1.009,1.011-1.009h11.615c0.558,0,1.009,0.452,1.009,1.009c0,0.558-0.451,1.01-1.009,1.01H30.663z"
            />
        </SvgIcon>
    );
};

LivingHandbookIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

LivingHandbookIcon.defaultProps = {
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default LivingHandbookIcon;
