import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching Contentful content
 *
 * @type {Object}
 */
export const fetchContentfulContent = createActions('content', 'FETCH_CONTENTFUL_CONTENT', {
    asPromise: true,
});

/**
 * Redux actions for fetching law alerts content
 *
 * @type {Object}
 */
export const fetchLawAlertsContent = createActions('content', 'FETCH_LAW_ALERTS_CONTENT', {
    asPromise: true,
});

/**
 * Redux actions for fetching Contentful content types
 *
 * @type {Object}
 */
export const fetchContentfulContentTypes = createActions(
    'content',
    'FETCH_CONTENTFUL_CONTENT_TYPES',
    {
        asPromise: true,
    }
);
/**
 * Redux actions for fetching Contentful content types
 *
 * @type {Object}
 */
export const fetchContentfulDocumentTypes = createActions(
    'content',
    'FETCH_CONTENTFUL_DOCUMENT_TYPES',
    {
        asPromise: true,
    }
);
/**
 * Redux actions for fetching Contentful categories
 *
 * @type {Object}
 */
export const fetchContentfulCategories = createActions('content', 'FETCH_CONTENTFUL_CATEGORIES', {
    asPromise: true,
});
/**
 * Redux actions for fetching Contentful employee counts
 *
 * @type {Object}
 */
export const fetchContentfulEmployeeCounts = createActions(
    'content',
    'FETCH_CONTENTFUL_EMPLOYEE_COUNTS',
    {
        asPromise: true,
    }
);
/**
 * Redux actions for fetching Contentful jurisdictions
 *
 * @type {Object}
 */
export const fetchContentfulJurisdictions = createActions(
    'content',
    'FETCH_CONTENTFUL_JURISDICTIONS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching a Contentful document
 *
 * @type {Object}
 */
export const fetchContentfulDocument = createActions('content', 'FETCH_CONTENTFUL_DOCUMENT', {
    asPromise: true,
});

/**
 * Redux actions for fetching Contentful documents
 *
 * @type {Object}
 */
export const fetchContentfulDocuments = createActions('content', 'FETCH_CONTENTFUL_DOCUMENTS', {
    asPromise: true,
});

/**
 * Redux actions to fetch Contentful documents for a topic
 *
 * @type {Object}
 */
export const fetchContentfulTopicDocuments = createActions(
    'content',
    'FETCH_CONTENTFUL_TOPIC_DOCUMENTS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to fetch Contentful documents for all topics
 *
 * @type {Object}
 */
export const fetchContentfulTopicsDocuments = createActions(
    'content',
    'FETCH_CONTENTFUL_TOPICS_DOCUMENTS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for adding user favorite items
 *
 * @type {Object}
 */
export const addUserFavItem = createActions('content', 'ADD_FAV_ITEM', {
    asPromise: true,
});

/**
 * Redux actions for remove user favorite items
 *
 * @type {Object}
 */
export const removeUserFavItem = createActions('content', 'REMOVE_FAV_ITEM', {
    asPromise: true,
});

/**
 * Redux actions for adding user like or dislike option to item
 *
 * @type {Object}
 */
export const addLikeDislikeItem = createActions('content', 'ADD_LIKE_DISLIKE_ITEM', {
    asPromise: true,
});

/**
 * Redux actions for remove user like or dislike option to item
 *
 * @type {Object}
 */
export const removeLikeDislikeItem = createActions('content', 'REMOVE_LIKE_DISLIKE_ITEM', {
    asPromise: true,
});

/**
 * Redux actions for fetching Contentful content
 *
 * @type {Object}
 */
export const fetchContentTypes = createActions('content', 'FETCH_CONTENT_TYPES', {
    asPromise: true,
});

/**
 * Redux actions for fetching a Contentful document list
 *
 * @type {Object}
 */
export const fetchContentfulDocumentList = createActions(
    'content',
    'FETCH_CONTENTFUL_DOCUMENT_LIST',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching a Contentful document list by category
 *
 * @type {Object}
 */
export const fetchDocumentsByCategory = createActions(
    'content',
    'FETCH_CONTENTFUL_DOCUMENT_BY_CATEGORY',
    {
        asPromise: true,
    }
);

/*
 * Redux actions for fetching the content by category.
 *
 * @type {Object}
 */
export const fetchContentByCategory = createActions('content', 'FETCH_CONTENT_BY_CATEGORY', {
    asPromise: true,
});

/*
 * Redux actions for fetching user favorite items.
 *
 * @type {Object}
 */
export const fetchUserFavoriteItems = createActions('content', 'FETCH_USER_FAVORITE_ITEMS', {
    asPromise: true,
});

/*
 * Redux actions for fetching user favorite items by Resource Type and Id.
 *
 * @type {Object}
 */
export const fetchUserFavoriteItemsByTypeAndId = createActions(
    'content',
    'FETCH_USER_FAVORITE_ITEMS_BY_TYPE_AND_ID',
    {
        asPromise: true,
    }
);

/*
 * Redux actions for deleting user favorite item.
 *
 * @type {Object}
 */
export const deleteUserFavoriteItem = createActions('content', 'DELETE_USER_FAVORITE_ITEM', {
    asPromise: true,
});

/*
 * Redux actions for fetching documents.
 *
 * @type {Object}
 */
export const fetchDocuments = createActions('content', 'FETCH_DOCUMENTS', {
    asPromise: true,
});

/*
 * Redux actions for download document.
 *
 * @type {Object}
 */
export const downloadDocument = createActions('content', 'DOWNLOAD_DOCUMENT', {
    asPromise: true,
});

/*
 * Redux actions for fetch Law Alerts.
 *
 * @type {Object}
 */
export const fetchLawAlerts = createActions('content', 'FETCH_LAW_ALERTS', {
    asPromise: true,
});

/*
 * Redux actions for download HR Fitnnes Test report.
 *
 * @type {Object}
 */
export const downloadHrFitnnesTestReport = createActions('content', 'DOWNLOAD_HR_FITNNES_TEST', {
    asPromise: true,
});

/*
 * Redux actions for fetch Featured Content.
 *
 * @type {Object}
 */
export const fetchFeaturedContent = createActions('content', 'FETCH_FEATURED_CONTENT', {
    asPromise: true,
});

/*
 * Redux actions for update image index.
 *
 * @type {Object}
 */
export const updateFeaturedContentImageIndex = createActions(
    'content',
    'UPDATE_FEATURED_CONTENT_IMAGE_INDEX',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching Contentful content for the dashboard carousel
 *
 * @type {Object}
 */
export const fetchContentfulCarouselContent = createActions(
    'content',
    'FETCH_CONTENTFUL_CAROUSEL_CONTENT',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for add related term
 *
 * @type {Object}
 */
export const addRelatedTerm = createActions('content', 'ADD_RELATED_TERM', {
    asPromise: true,
});

/**
 * Redux actions for add visit item
 *
 * @type {Object}
 */
export const addVisitItem = createActions('content', 'ADD_VISIT_ITEM', {
    asPromise: true,
});

/**
 * Redux actions for fetching upcoming webinar
 *
 * @type {Object}
 */
export const fetchUpcomingWebinar = createActions('content', 'FETCH_UPCOMING_WEBINAR', {
    asPromise: true,
});

/**
 * Redux actions for download contentful document
 *
 * @type {Object}
 */
export const downloadContentfulDocument = createActions('content', 'DOWNLOAD_CONTENTFUL_DOCUMENT', {
    asPromise: true,
});

/**
 * Redux actions for get document with logo
 *
 * @type {Object}
 */
export const getDocumentWithLogo = createActions('content', 'GET_DOCUMENT_WITH_LOGO', {
    asPromise: true,
});

/*
 * Redux actions for fetching resources documents.
 *
 * @type {Object}
 */
export const fetchResourcesDocuments = createActions('content', 'FETCH_RESOURCES_DOCUMENTS', {
    asPromise: true,
});

/*
 * Redux actions for fetching safety resources.
 *
 * @type {Object}
 */
export const fetchSafetyResources = createActions('content', 'FETCH_SAFETY_RESOURCES', {
    asPromise: true,
});

/**
 * Redux actions for fetching safety courses
 *
 * @type {Object}
 */
export const fetchSafetyCourses = createActions('content', 'FETCH_SAFETY_COURSES', {
    asPromise: true,
});

/**
 * Redux actions for fetching categories training
 *
 * @type {Object}
 */
export const fetchTrainingCategories = createActions('content', 'FETCH_TRAINING_CATEGORIES', {
    asPromise: true,
});

/**
 * Redux actions for fetching help center sections
 *
 * @type {Object}
 */
export const fetchHelpCenterSections = createActions('content', 'FETCH_HELP_CENTER_SECTIONS', {
    asPromise: true,
});

/**
 * Redux actions for fetching help center faqs
 *
 * @type {Object}
 */
export const fetchHelpCenterFaqs = createActions('content', 'FETCH_HELP_CENTER_FAQS', {
    asPromise: true,
});

/**
 * Redux actions for fetching help center categories
 *
 * @type {Object}
 */
export const fetchHelpCenterCategoriesBySection = createActions(
    'content',
    'FETCH_HELP_CENTER_CATEGORIES_BY_SECTION',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching help center category
 *
 * @type {Object}
 */
export const fetchHelpCenterCategory = createActions('content', 'FETCH_HELP_CENTER_CATEGORY', {
    asPromise: true,
});

/**
 * Redux actions for fetching help center detail
 *
 * @type {Object}
 */
export const fetchHelpCenterDetail = createActions('content', 'FETCH_HELP_CENTER_DETAIL', {
    asPromise: true,
});

/*
 * Redux actions for download a docx with laws comparison between states.
 *
 * @type {Object}
 */
export const fetchCompareLawsByStates = createActions('content', 'FETCH_COMPARE_LAWS_BY_STATES', {
    asPromise: true,
});

/*
 * Redux actions for contentful EHS Content.
 *
 * @type {Object}
 */
export const fetchContentfulEHSContent = createActions('content', 'FETCH_CONTENTFUL_EHS_CONTENT', {
    asPromise: true,
});

/**
 * Redux actions for fetching safety products
 *
 * @type {Object}
 */
export const fetchSafetyProducts = createActions('content', 'FETCH_SAFETY_PRODUCTS', {
    asPromise: true,
});

/**
 * Redux actions for fetch ehs topics by parent category
 *
 * @type {Object}
 */
export const fetchEHSTopicsByCategory = createActions('content', 'FETCH_EHS_TOPICS_BY_CATEGORY', {
    asPromise: true,
});

/**
 * Redux actions for fetch ehs industries
 *
 * @type {Object}
 */
export const fetchEHSIndustries = createActions('content', 'FETCH_EHS_INDUSTRIES', {
    asPromise: true,
});

/**
 * Redux actions for fetch ehs content
 *
 * @type {Object}
 */
export const fetchEHSContent = createActions('content', 'FETCH_EHS_CONTENT', {
    asPromise: true,
});

/**
 * Redux actions for fetch ehs content by topic
 *
 * @type {Object}
 */
export const fetchEHSContentByTopic = createActions('content', 'FETCH_EHS_CONTENT_BY_TOPIC', {
    asPromise: true,
});

/**
 * Redux actions for fetch ehs banner by topic
 *
 * @type {Object}
 */
export const fetchEHSBannerByTopic = createActions('content', 'FETCH_EHS_BANNER_BY_TOPIC', {
    asPromise: true,
});

/**
 * Redux actions for fetch safety manual template
 *
 * @type {Object}
 */
export const fetchSafetyManualTemplate = createActions('content', 'FETCH_SAFETY_MANUAL_TEMPLATE', {
    asPromise: true,
});

/**
 * Redux actions for fetch ehs content type route
 *
 * @type {Object}
 */
export const fetchEHSContentTypeRoute = createActions('content', 'FETCH_EHS_CONTENT_TYPE_ROUTE', {
    asPromise: true,
});

/**
 * Redux actions for fetching Contentful ehs content types
 *
 * @type {Object}
 */
export const fetchContentfulEhsDocumentTypes = createActions(
    'content',
    'FETCH_CONTENTFUL_EHS_DOCUMENT_TYPES',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching categories to Help Center
 *
 * @type {Object}
 */
export const fetchHelpCenterCategories = createActions('content', 'FETCH_HELP_CENTER_CATEGORIES', {
    asPromise: true,
});

/**
 * Redux actions for fetching search results to Help Center
 *
 * @type {Object}
 */
export const fetchHelpCenterSearch = createActions('content', 'FETCH_HELP_CENTER_SEARCH', {
    asPromise: true,
});

/*
 * Redux actions for update ehs categories.
 *
 * @type {Object}
 */
export const updateEhsCategories = createActions('content', 'UPDATE_EHS_CATEGORIES', {
    asPromise: true,
});

/**
 * Redux actions for fetching training webinar
 *
 * @type {Object}
 */
export const fetchTrainingWebinar = createActions('content', 'FETCH_TRAINING_WEBINAR', {
    asPromise: true,
});

/**
 * Redux actions for fetching last webinar
 *
 * @type {Object}
 */
export const fetchLastWebinars = createActions('content', 'FETCH_LAST_WEBINARS', {
    asPromise: true,
});

/**
 * Redux actions for fetching webinar categories
 *
 * @type {Object}
 */
export const fetchWebinarsCategories = createActions('content', 'FETCH_WEBINARS_CATEGORIES', {
    asPromise: true,
});

/**
 * Redux actions for fetching contentful additional ehs results categories
 *
 * @type {Object}
 */
export const fetchContentfulAdditionalEhsResults = createActions(
    'content',
    'FETCH_CONTENTFUL_ADDITIONAL_EHS_RESULTS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for contentful law alerts
 *
 * @type {Object}
 */
export const fetchContentfulLawAlerts = createActions('content', 'FETCH_CONTENTTFUL_LAW_ALERTS', {
    asPromise: true,
});

/**
 * Redux actions for help center home widget
 *
 * @type {Object}
 */
export const fetchHelpCenterHomeWidget = createActions('content', 'FETCH_HELP_CENTER_HOME_WIDGET', {
    asPromise: true,
});

/**
 * Redux actions to download help center video
 *
 * @type {Object}
 */
export const downloadHelpCenterVideo = createActions('content', 'DOWNLOAD_HELP_CENTER_VIDEO', {
    asPromise: true,
});

/**
 * Redux actions for contentful law alerts
 *
 * @type {Object}
 */
export const fetchUserFavoriteItemsInsights = createActions(
    'content',
    'FETCH_USER_FAVORITE_ITEMS_INSIGHTS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for contentful law alerts
 *
 * @type {Object}
 */
export const fetchHelpCenterRecentDocuments = createActions(
    'content',
    'FETCH_HELP_CENTER_RECENT_DOCUMENTS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for release notes
 *
 * @type {Object}
 */
export const fetchHelpCenterReleaseNotes = createActions(
    'content',
    'FETCH_HELP_CENTER_RELEASE_NOTES',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetch feedback options
 *
 * @type {Object}
 */
export const fetchFeedbackOptions = createActions('content', 'FETCH_FEEDBACK_OPTIONS', {
    asPromise: true,
});
