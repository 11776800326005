import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ContentTypePoliciesIcon = ({ color, colorInner, height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path fill={color} d="M4.137 1.834H14.014V13.951H4.137z" />
                        <path
                            fill={colorInner}
                            d="M10.024 4.963L6.146 4.963 6.559 4.263 10.024 4.263zM6.146 7H12.012V8H6.146zM6.146 8.988H12.012V9.988H6.146zM6.146 10.969H12.012V11.969H6.146z"
                        />
                        <path
                            fill={colorInner}
                            d="M4.137 7.189v-.742c.688-.23 1.28-.683 1.68-1.288.574-.924.567-2.527.567-3.206v-.119h.742v.112c0 .812 0 2.499-.7 3.605-.518.826-1.34 1.415-2.289 1.638z"
                        />
                        <path
                            fill={color}
                            d="M3.171 0c.788.902 2.063 1.203 3.171.749v1.26c.084 3.654-1.302 3.997-2.1 4.389-.331.198-.697.331-1.078.392-.376-.063-.737-.196-1.064-.392-.791-.392-2.177-.735-2.1-4.389V.749C1.108 1.203 2.383.902 3.171 0z"
                        />
                        <path fill={colorInner} d="M2.8 2.121H3.8V4.662H2.8z" />
                        <path fill={colorInner} d="M1.904 3.024H4.445V4.024H1.904z" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ContentTypePoliciesIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ContentTypePoliciesIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '14',
    viewBox: '0 0 14 14',
    width: '14',
};

export default ContentTypePoliciesIcon;
