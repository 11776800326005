import React from 'react';

import ClearIcon from 'ravenjs/lib/SvgIcon/types/ClearIcon';
import { Toaster as DefaultToaster } from 'ravenjs/lib/Toaster';

import { DEFAULT_COLORS } from 'constants/colors';

const commonStyles = {
    color: DEFAULT_COLORS.WHITE,
    margin: '0.625rem',
    minHeight: '48px',
    borderRadius: '8px',
    padding: '0.75rem 1rem 0.75rem 1rem',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: '1.438rem',
    boxShadow: '0 2px 6px 0 rgba(223, 223, 223, 0.5)',
    backgroundColor: DEFAULT_COLORS.WHITE,
    border: `solid 1px ${DEFAULT_COLORS.LIGHTER}`,
    alignItems: 'center',
    justifyContent: 'center',
};
const defaultStyles = {
    borderLeft: `solid 16px ${DEFAULT_COLORS.GREEN}`,
};
const errorStyles = {
    backgroundColor: DEFAULT_COLORS.RED,
};
const successStyles = {
    backgroundColor: DEFAULT_COLORS.GREEN_3,
};
const warningStyles = {
    backgroundColor: DEFAULT_COLORS.YELLOW,
    color: DEFAULT_COLORS.BLACK,
};
const infoStyles = {
    backgroundColor: DEFAULT_COLORS.BLUE_4,
    color: DEFAULT_COLORS.BLACK,
};

const Toaster = props => {
    const closeButton = ({ closeToast }) => (
        <ClearIcon margin="5px 0.5rem 2px 0.5rem" onClick={closeToast} />
    );

    return (
        <DefaultToaster
            closeButton={closeButton}
            closeOnClick={false}
            commonStyles={commonStyles}
            errorStyles={errorStyles}
            successStyles={successStyles}
            warningStyles={warningStyles}
            defaultStyles={defaultStyles}
            infoStyles={infoStyles}
            position="bottom-left"
            {...props}
        />
    );
};

export default Toaster;
