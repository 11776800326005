import { array, bool, func, number, object } from 'prop-types';
import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createStructuredSelector } from 'reselect';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { generateTheme } from 'ravenjs/utils/theme';
import Responsive from 'ravenjs/lib/Responsive';
import { renderCheck } from 'ravenjs/utils/viewport';
import { VALUES } from 'ravenjs/constants';

import AppContainer from 'components/AppContainer';
import AppContent from 'components/AppContent';
import AppFooter from 'components/AppFooter';
import AppNavigation from 'components/AppNavigation';
import AppWrapper from 'components/AppWrapper';
import AsyncBulkImportProvider from 'components/AsyncBulkImport/AsyncBulkImportContext';
import { ContentSearchProvider } from 'components/ContentSearch';
import Help from 'components/Help';
import ImpersonateUserHeader from 'components/ImpersonateUserHeader/ImpersonateUserHeader';
import Loading from 'components/Loading';
import MobileAppNavigation from 'components/MobileAppNavigation';
import { PublicContentProvider } from 'components/PublicContent/PublicContentContext';
import SessionExpiry from 'components/SessionExpiry';
import SubNavigation from 'components/SubNavigation';
import Toaster from 'components/Toaster';
import BrandingSwitcher from 'containers/Admin/Branding/BrandingSwitcher';
import ModalsList from 'containers/ModalsList';
import { PENDO_SEARCH } from 'constants/pendo';
import Acl from 'modules/acl';
import { actions as authActions, selectors as authSelectors } from 'modules/auth';
import { selectors as bootstrapSelectors } from 'modules/bootstrap';
import { selectors as brandingSelectors } from 'modules/branding';
import { actions as pendoActions } from 'modules/pendo';
import { actions as uiActions, selectors as uiSelectors } from 'modules/ui';
import { selectors as userSelectors } from 'modules/user';
import Routes from 'routes';
import { loadPendo } from 'utils/pendo';
import { getEnvVar } from 'utils/env';
import { hasFeatureFlag } from 'utils/common';

import './FaIcons';

const GlobalStyle = createGlobalStyle`
  ._pendo-badge {
    right: -9999px !important;
  }
`;

class Root extends Component {
    pendoPageData = {
        pageViewStartTime: 0,
        previousPathname: '',
    };

    isLoginStatusSentToParent = false;

    countUpdates = 0;

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            closeModal,
            EULAStatus,
            history: { location },
            loggedIn,
            openModal,
            user,
            userCompany,
            userCompany: { companyId },
            userCompanyLocations,
            userConfiguration,
            userId,
            userPermissions,
        } = this.props;

        if (!loggedIn) {
            this.countUpdates = 0;
        }

        if (this.countUpdates === 1) {
            if (EULAStatus) {
                const pendoApiKey = getEnvVar('PENDO_API_KEY', MAESTER.thr.pendoApiKey);

                loadPendo(pendoApiKey, {
                    Acl,
                    closeModal,
                    loggedIn,
                    openModal,
                    user,
                    userCompany,
                    userCompanyLocations,
                    userConfiguration,
                    userId,
                    userPermissions,
                });
            }
        }

        if (location.pathname.indexOf('/auth') === -1) {
            this.countUpdates += 1;
        }

        // This is to check that is the app is opened in a iFrame from legacy application,
        // we need to pass the '{ loggedIn, userId }' data to parent page.
        //
        // Refer Requirement: https://thinkhr.atlassian.net/wiki/spaces/PD/pages/2545582092/SSO+Deep+Link
        const isInIframe = window !== window.parent;

        if (
            !this.isLoginStatusSentToParent &&
            isInIframe &&
            document.referrer.includes('apps.thinkhr')
        ) {
            window.parent.postMessage(
                { companyId, loggedIn, type: 'login_status' },
                document.referrer
            );

            this.isLoginStatusSentToParent = true;
        }
    }

    handleLogout = () => {
        const { logout } = this.props;
        return logout();
    };

    render() {
        const {
            history,
            isImpersonatingUser,
            loading,
            loggedIn,
            navigationSchema,
            persistor,
            sendPendoEvent,
            theme,
            user,
        } = this.props;

        const isFullScreenMode = hasFeatureFlag(history.location, 'showFullScreenMode');
        const iPadView = window.innerWidth <= VALUES.md;
        const appNavigationProps = {
            appNav: navigationSchema.appNav,
            authorized: loggedIn,
            history,
            isImpersonatingUser,
            onLogout: this.handleLogout,
            user,
            userNav: navigationSchema.userNav,
        };

        const isLearnMobile =
            history.location.pathname.indexOf('/training') !== -1 && renderCheck('lg', 'less');

        history.listen(location => {
            if (
                location.pathname !== '/search' &&
                this.pendoPageData.previousPathname === '/search' &&
                this.pendoPageData.pageViewStartTime > 0
            ) {
                const duration = Date.now() - this.pendoPageData.pageViewStartTime;

                sendPendoEvent({
                    event: PENDO_SEARCH.TIME_ON_PAGE.EVENT,
                    eventProperties: {
                        duration,
                        description: PENDO_SEARCH.TIME_ON_PAGE.DESCRIPTION,
                    },
                });
            } else if (
                location.pathname === '/search' &&
                this.pendoPageData.previousPathname !== '/search'
            ) {
                this.pendoPageData.pageViewStartTime = Date.now();
            }

            this.pendoPageData.previousPathname = location.pathname;
        });

        return (
            <PersistGate persistor={persistor}>
                <ConnectedRouter history={history}>
                    <GlobalStyle loggedIn={loggedIn} />
                    <ThemeProvider theme={generateTheme(theme)}>
                        <Toaster />
                        <SessionExpiry loggedIn={loggedIn} />
                        <AppWrapper authorized={loggedIn}>
                            <PublicContentProvider>
                                <BrandingSwitcher history={history} loggedIn={loggedIn}>
                                    <AsyncBulkImportProvider>
                                        <ContentSearchProvider>
                                            {!isFullScreenMode && (
                                                <ImpersonateUserHeader
                                                    authorized={loggedIn}
                                                    user={user}
                                                    history={history}
                                                    isImpersonatingUser={isImpersonatingUser}
                                                />
                                            )}
                                            <Responsive size="md" value="less">
                                                <MobileAppNavigation {...appNavigationProps} />
                                            </Responsive>
                                            {!isFullScreenMode && !iPadView && (
                                                <Responsive size="md" value="greater">
                                                    <AppNavigation {...appNavigationProps} />
                                                </Responsive>
                                            )}
                                            <AppContainer>
                                                {!isFullScreenMode && (
                                                    <SubNavigation
                                                        isImpersonatingUser={isImpersonatingUser}
                                                        history={history}
                                                        {...navigationSchema.subNav}
                                                        iPadView={iPadView}
                                                    />
                                                )}
                                                <AppContent
                                                    authorized={loggedIn}
                                                    history={history}
                                                    id="mainAppContent"
                                                    isImpersonatingUser={isImpersonatingUser}
                                                >
                                                    <Routes />
                                                    <ModalsList />
                                                    <Loading {...loading} />
                                                </AppContent>
                                            </AppContainer>
                                            {!isLearnMobile && <Help history={history} />}
                                            {!isLearnMobile && !isFullScreenMode && (
                                                <AppFooter authorized={loggedIn} />
                                            )}
                                        </ContentSearchProvider>
                                    </AsyncBulkImportProvider>
                                </BrandingSwitcher>
                            </PublicContentProvider>
                        </AppWrapper>
                    </ThemeProvider>
                </ConnectedRouter>
            </PersistGate>
        );
    }
}

Root.propTypes = {
    closeModal: func.isRequired,
    EULAStatus: bool.isRequired,
    history: object.isRequired,
    isImpersonatingUser: bool.isRequired,
    loading: object,
    loggedIn: bool,
    logout: func.isRequired,
    navigationSchema: object,
    openModal: func.isRequired,
    persistor: object.isRequired,
    sendPendoEvent: func.isRequired,
    theme: object.isRequired,
    user: object,
    userId: number,
    userCompany: object,
    userCompanyLocations: array,
    userConfiguration: object,
    userPermissions: object,
};

Root.defaultProps = {
    loading: {},
    loggedIn: false,
    navigationSchema: {},
    user: {},
    userId: null,
    userCompany: {
        companyId: null,
        location: {
            city: '',
        },
    },
    userCompanyLocations: [],
    userConfiguration: {},
    userPermissions: {},
};

const mapStateToProps = createStructuredSelector({
    disclaimer: bootstrapSelectors.getDisclaimer,
    EULAStatus: userSelectors.getEULAStatus,
    isImpersonatingUser: authSelectors.isImpersonatingUser,
    loading: uiSelectors.getLoading,
    loggedIn: authSelectors.isUserLoggedIn,
    navigationSchema: uiSelectors.getNavigation,
    systemSupport: bootstrapSelectors.getSystemSupport,
    theme: brandingSelectors.getTheme,
    user: userSelectors.getUserInfo,
    userId: userSelectors.getUserId,
    userCompany: userSelectors.getUserCompany,
    userCompanyLocations: userSelectors.getUserCompanyLocations,
    userConfiguration: userSelectors.getUserConfiguration,
    userPermissions: userSelectors.getUserPermissions,
});

const mapDispatchToProps = {
    closeModal: uiActions.closeModal,
    logout: authActions.logout,
    openModal: uiActions.openModal,
    sendPendoEvent: pendoActions.sendPendoEvent,
};

export { Root as UnwrappedRoot };
export default connect(mapStateToProps, mapDispatchToProps)(Root);
