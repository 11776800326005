import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const ExpandedIcon = ({ height, viewBox, width, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
        <g id="Group_1" data-name="Group 1" transform="translate(-272 -212)">
            <g
                id="Rectangle_1"
                data-name="Rectangle 1"
                transform="translate(272 212)"
                fill="#fff"
                stroke="#979797"
                strokeWidth="1"
            >
                <rect width="13" height="27" stroke="none" />
                <rect x="0.5" y="0.5" width="12" height="26" fill="none" />
            </g>
            <rect
                id="Rectangle_2"
                data-name="Rectangle 2"
                width="1"
                height="15"
                transform="translate(276 218)"
                fill="#979797"
            />
            <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="1"
                height="15"
                transform="translate(280 218)"
                fill="#979797"
            />
        </g>
    </SvgIcon>
);

ExpandedIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

ExpandedIcon.defaultProps = {
    viewBox: '0 0 13 27',
    height: '27px',
    width: '13px',
};

export default ExpandedIcon;
