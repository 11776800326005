import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { matchPath } from 'react-router';
import queryString from 'query-string';

import { get } from 'ravenjs/utils/lodash';
import { renderCheck } from 'ravenjs/utils/viewport';

import { setSafetyLocalStorage } from 'utils/common';
import { formatCategoriesAndSubcategories } from 'utils/documents';

import { JAPI_SEARCH_CONTENT_TYPES } from 'constants/search';
import { CUSTOM_PARTNER_ID } from '../constants/company';

const contains = (src, test) => {
    return src.indexOf(test) !== -1;
};

export const hasSearch = pathname => {
    return (
        !contains(pathname, '/admin/') &&
        !contains(pathname, 'auth') &&
        !contains(pathname, '/dhe-audits/') &&
        !contains(pathname, '/handbooks/') &&
        !contains(`${window.location.origin}${pathname}`, `${window.location.origin}/training/`) &&
        !contains(pathname, 'profile') &&
        !contains(pathname, 'public') &&
        !contains(pathname, 'documents') &&
        !contains(pathname, 'legal-and-privacy') &&
        !contains(pathname, 'osha') &&
        !contains(pathname, '/reporting/') &&
        !contains(pathname, 'partner-enablement') &&
        !contains(pathname, 'benefits-document-creator') &&
        !contains(pathname, '/case-cave/') &&
        !contains(pathname, '/ticketing/') &&
        !contains(pathname, '/whp/whp-upgrade')
    );
};

export const pushesToSearchPage = pathname => {
    return !(pathname === '/search');
};

export const getTypeAheadContentTypes = pathname => {
    // TODO: restore this when "customized" type-ahead content gets re-enabled
    /*
    if (contains(pathname, '/dashboard')) {
        return JAPI_SEARCH_CONTENT_TYPES.ALL;
    } else if (contains(pathname, '/search')) {
        return [
            JAPI_SEARCH_CONTENT_TYPES.LAW,
            JAPI_SEARCH_CONTENT_TYPES.LAW_ALERT,
            JAPI_SEARCH_CONTENT_TYPES.QUESTION_AND_ANSWER,
            JAPI_SEARCH_CONTENT_TYPES.TOOLKIT,
            JAPI_SEARCH_CONTENT_TYPES.TWO_MIN_HR,
        ].join(',');
    }
    */
    return JAPI_SEARCH_CONTENT_TYPES.ALL;
};

export const getSearchParams = (advancedSearchSpec, searchSpec) => {
    advancedSearchSpec = advancedSearchSpec || [];

    const usingAdvancedSearch = advancedSearchSpec.some(
        item => item.value && item.value.trim().length
    );

    if (usingAdvancedSearch) {
        const advancedSearchTerms = {};

        advancedSearchSpec.forEach(item => {
            if (item.id === CUSTOM_PARTNER_ID.LABEL) {
                item.searchKey = `custom${item.displayOrder}`;
                item.hasPartnerId = CUSTOM_PARTNER_ID.UPDATED_VALUE;
            }
            if (item.value && item.value.trim().length) {
                if (item.id === CUSTOM_PARTNER_ID.LABEL) {
                    advancedSearchTerms[item.searchKey] = item.value.trim();
                    advancedSearchTerms[item.hasPartnerId] = item.value.trim();
                } else {
                    advancedSearchTerms[item.searchKey] = item.value.trim();
                }
            }
        });
        return advancedSearchTerms;
    } else {
        return {
            searchSpec: searchSpec ? searchSpec.trim() : '',
        };
    }
};

export const getSearchParamsFull = (advancedSearchSpec, searchSpec) => {
    advancedSearchSpec = advancedSearchSpec || [];
    searchSpec = searchSpec ? searchSpec.trim() : '';

    const advancedSearchTerms = {};

    advancedSearchSpec.forEach(item => {
        if (item.value && item.value.trim().length) {
            advancedSearchTerms[item.searchKey] = item.value.trim();
        }
    });

    return {
        ...advancedSearchTerms,
        searchSpec,
    };
};

export const formatSearchSuggestions = ({ relatedSearchTerms = [], searchSuggestion, hits }) => {
    const mostRelevantSuggestion = get(searchSuggestion, 'mostRelevantSuggestion', '');
    const allKeywords = [];
    const searchTerms = [];
    const relatedSearch =
        relatedSearchTerms.length &&
        _sortBy(relatedSearchTerms, [{ score: 'asc' }]).map(({ term }) => term && term);

    !_isEmpty(hits) &&
        hits.forEach(item => {
            const { keywords = [] } = item;
            allKeywords.length < 8 &&
                keywords.length &&
                allKeywords.length < 8 &&
                keywords.forEach(({ name }) => {
                    name && allKeywords.push(name);
                });
        });

    mostRelevantSuggestion && searchTerms.push(mostRelevantSuggestion);
    !_isEmpty(relatedSearch) && searchTerms.push(...relatedSearch);
    if (_isEmpty(searchTerms)) {
        searchTerms.push(...allKeywords);
    }

    return searchTerms.slice(0, 8);
};

export const formatMisspelledText = searchResults => {
    const { searchSuggestion = { mostRelevantSuggestion: '' } } = searchResults;

    if (searchSuggestion.mostRelevantSuggestion) {
        return searchSuggestion.mostRelevantSuggestion;
    } else {
        return '';
    }
};

/**
 * Returns the onClickBack callback for the <ContentSearch>
 *
 * @param   {Object}    history
 * @return  {Function|null}
 */
export const getOnClickBackCallback = history => {
    const pathname = get(history, 'location.pathname', null);
    const prevPathname = get(history, 'location.state.from', null);
    const DEFAULT_GO_BACK = () => {
        if (pathname.includes('/prospect') && pathname.includes('/edit')) {
            prevPathname === '/prospect/my-prospects'
                ? history.push('/prospect/my-prospects')
                : history.push('/prospect/manage-prospects');
        } else if (pathname === '/help-center' && window.history.length <= 1) {
            history.push('/dashboard');
        } else {
            history.goBack();
        }
    };

    const ROUTES = [
        { path: '/featured-content/:id/details' },
        { path: '/templates' },
        { path: '/hr-tools' },
        { path: '/hr-tools/job-description-builder/create/:occupationCode' },
        { path: '/hr-tools/job-description-builder/edit/:jobId' },
        { path: '/hr-tools/job-description-builder/list' },
        { path: '/hr-tools/job-description-builder/search' },
        { path: '/hr-tools/job-description-builder/search-results' },
        { path: '/hr-tools/calculators/cost-per-hire' },
        { path: '/hr-tools/calculators/employee-turnover' },
        { path: '/hr-tools/calculators/overtime' },
        { path: '/hr-tools/calculators/aca-full-time-equivalent' },
        { path: '/hr-tools/calculators/absenteeism' },
        { path: '/hr-tools/calculators/safe-harbor' },
        { path: '/hr-compliance' },
        { path: '/hr-compliance/topics/:type/:id/details' },
        { path: '/hr-compliance/:type/:id/details' },
        { path: '/hr-compliance/topics/:categoryRoute' },
        { path: '/hr-compliance/:state' },
        { path: '/hr-compliance/hr-assessment/results' },
        { path: '/hr-compliance/hr-assessment/start' },
        { path: '/resources' },
        { path: '/resources/:state' },
        { path: '/resources/:state/:type' },
        { path: '/resources/:state/:id/details' },
        { path: '/resources/:state/:id/details/:materialId' },
        { path: '/templates' },
        { path: '/templates/topics/:state' },
        { path: '/templates/topics/:state/:id/details' },
        { path: '/templates/:type/:id/details' },
        { path: '/templates/:state' },
        { path: '/search' },
        { path: '/mineral-intelligence/insights' },
        { path: '/mineral-intelligence/:id/prescription' },
        { path: '/todo/list' },
        { path: '/tools/jobs/:id/details' },
        { path: '/tools/jobs/list' },
        { path: '/company-policies' },
        { path: '/notifications/list' },
        { path: '/reporting' },
        { path: '/reporting/client-usage' },
        { path: '/reporting/partner-value-report' },
        { path: '/prospect/add' },
        { path: '/prospect/:id/edit' },
        { path: '/prospect/bulk-import' },
        { path: '/safety' },
        { path: '/safety/:state' },
        { path: '/safety/:state/:category' },
        { path: '/safety/:state/:category/:topic' },
        { path: '/safety/:state/:category/:topic/:id/details' },
        { path: '/help-center' },
        { path: '/help-center/:slug' },
        { path: '/help-center/:state/:category/:id/details' },
        { path: '/help-center/faqs/:slug' },
        { path: '/help-center/release-notes/:publicationYear' },
        { path: '/my-cases' },
        { path: '/my-cases/:id/details' },
        { path: '/my-cases/create' },
        { path: '/my-cases/client-cases' },
        { path: '/my-cases/client-cases/:id/details' },
        { path: '/my-cases/client-cases/create' },
        { path: '/hr-tools/aca-reporting/info' },
        { path: '/hr-tools/aca-reporting/questionnaire' },
    ].map(route => ({ ...route, onBack: DEFAULT_GO_BACK }));

    if (pathname) {
        const matchRoute = ROUTES.find(({ onBack, path }) => {
            const match = matchPath(pathname, {
                path,
                exact: true,
            });
            return !_isEmpty(match);
        });

        if (!_isEmpty(matchRoute)) {
            return matchRoute.onBack;
        }
        return null;
    }

    return null;
};

/**
 * Get a from search string when user is redirect to login page if not logged in
 *
 * @param   {string}    pathname
 * @param   {string}    search
 * @return  {string}
 */
export const getLoginRedirectSearchString = ({ pathname = '', search = '' }) => {
    const SEARCH_PARAM_EXCEPTIONS = ['partnerLoginUrl'];
    let from = pathname;

    const queryParams = queryString.parse(search) || {};

    if (!_isEmpty(queryParams)) {
        let isFirstParam = true;
        Object.entries(queryParams).forEach(([key, value]) => {
            if (!SEARCH_PARAM_EXCEPTIONS.includes(key)) {
                if (isFirstParam) {
                    from = `${from}?${key}=${value}`;
                    isFirstParam = false;
                } else {
                    from = `${from}&${key}=${value}`;
                }
            }
        });
    }

    return queryString.stringify({
        from,
    });
};

export const scrollElementIntoViewById = elementRef => {
    if (renderCheck('md', 'less') && elementRef.current) {
        const scrollToElement = document.getElementById(elementRef.current);
        if (scrollToElement) {
            scrollToElement.scrollIntoView();
        }
    }
};

export const getDocumentGroups = documentGroups => {
    return !_isEmpty(documentGroups)
        ? documentGroups.map(documentGroup => {
              return {
                  ...documentGroup,
                  documents: documentGroup.documents.map(item => {
                      item.parentCategories = formatCategoriesAndSubcategories(
                          item.contentCategories
                      );
                      return item;
                  }),
              };
          })
        : [];
};

export const addCompanyTypeIdForDerivedCustomField = (
    params,
    hasPartnerId,
    companyTypeIdValues
) => {
    const newParams = { ...params };

    for (const key in newParams) {
        if (key.includes('custom')) {
            delete newParams[key];
        }
    }

    if (hasPartnerId in params) {
        newParams.companyId = [params.hasPartnerId];

        delete newParams.hasPartnerId;

        if (!newParams.companyTypeId) {
            newParams.companyTypeId = companyTypeIdValues;
        } else {
            newParams.companyTypeId = [...newParams.companyTypeId];
        }

        return newParams;
    }

    return { ...params };
};

/**
 * Saves safety content to local storage.
 *
 * @param {Object} document - The document object.
 * @return {boolean}
 */
export const saveSafetyContent = document => {
    const safetyCategory = get(document, 'contentCategories.0.name', '');
    const safetyTopic = get(document, 'ehsTopics.0.name', '');
    const safetyContent = get(document, 'title', '');
    const safetyLocalStorage = {
        safetyCategory,
        safetyTopic,
        safetyContent,
        source: 'Search',
    };
    setSafetyLocalStorage(safetyLocalStorage);
    return true;
};
