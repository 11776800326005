import _get from 'lodash/get';
import { createSelector } from 'reselect';

/**
 * Get all the info about the current router.
 *
 * @method getRouter
 * @param  {Object}  state The current state
 * @return {Object}        The router info
 */
export const getRouter = state => _get(state, 'router', {});

/**
 * Get the current location from redux store.
 *
 * @method getLocation
 * @param  {Object}    state The current state
 * @return {Object}          The location info
 */
export const getLocation = createSelector([getRouter], router => _get(router, 'location', {}));
