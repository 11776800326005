import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const SendIcon = ({ cursor, height, viewBox, width, fill, textFill, ...rest }) => {
    return (
        <SvgIcon cursor={cursor} width={width} height={height} viewBox={viewBox} {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.814 1.186a.636.636 0 0 1 0 .9l-7 7a.636.636 0 0 1-.9-.9l7-7a.636.636 0 0 1 .9 0z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.814 1.186c.172.173.231.43.15.66L10.51 14.575a.637.637 0 0 1-1.182.048L6.882 9.118 1.378 6.672a.636.636 0 0 1 .048-1.182l12.727-4.454a.636.636 0 0 1 .66.15zM3.362 6.161l4.26 1.894c.144.064.26.179.323.323l1.894 4.26 3.487-9.964L3.362 6.16z"
                fill="#000"
            />
        </SvgIcon>
    );
};

SendIcon.propTypes = {
    cursor: string,
    fill: string,
    textFill: string,
    height: string,
    viewBox: string,
    width: string,
};

SendIcon.defaultProps = {
    cursor: 'pointer',
    fill: DEFAULT_COLORS.YELLOW,
    textFill: DEFAULT_COLORS.BLACK,
    height: '20',
    viewBox: '0 0 16 16',
    width: '20',
};

export default SendIcon;
