import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const RefreshNewIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox} fill="none" {...rest}>
            <path
                d="M9.76667 2.23335C8.80001 1.26669 7.47334 0.666687 6.00001 0.666687C3.05334 0.666687 0.67334 3.05335 0.67334 6.00002C0.67334 8.94669 3.05334 11.3334 6.00001 11.3334C8.48667 11.3334 10.56 9.63335 11.1533 7.33335H9.76667C9.22001 8.88669 7.74001 10 6.00001 10C3.79334 10 2.00001 8.20669 2.00001 6.00002C2.00001 3.79335 3.79334 2.00002 6.00001 2.00002C7.10667 2.00002 8.09334 2.46002 8.81334 3.18669L6.66667 5.33335H11.3333V0.666687L9.76667 2.23335Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

RefreshNewIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

RefreshNewIcon.defaultProps = {
    fill: DEFAULT_COLORS.NAVY_BLUE,
    height: '12',
    viewBox: '0 0 12 12',
    width: '12',
};

export default RefreshNewIcon;
