import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const BookOpenIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        {...rest}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 2.4458C1.44772 2.4458 1 2.89352 1 3.4458V18.4458C1 18.9981 1.44772 19.4458 2 19.4458H9C9.53043 19.4458 10.0391 19.6565 10.4142 20.0316C10.7893 20.4067 11 20.9154 11 21.4458C11 21.9981 11.4477 22.4458 12 22.4458C12.5523 22.4458 13 21.9981 13 21.4458C13 20.9154 13.2107 20.4067 13.5858 20.0316C13.9609 19.6565 14.4696 19.4458 15 19.4458H22C22.5523 19.4458 23 18.9981 23 18.4458V3.4458C23 2.89352 22.5523 2.4458 22 2.4458H16C14.6739 2.4458 13.4021 2.97258 12.4645 3.91027C12.2962 4.07855 12.1411 4.25758 12 4.44577C11.8589 4.25758 11.7038 4.07855 11.5355 3.91027C10.5979 2.97258 9.32608 2.4458 8 2.4458H2ZM13 17.9817C13.6029 17.6336 14.2918 17.4458 15 17.4458H21V4.4458H16C15.2044 4.4458 14.4413 4.76187 13.8787 5.32448C13.3161 5.88709 13 6.65015 13 7.4458V17.9817ZM11 17.9817V7.4458C11 6.65015 10.6839 5.88709 10.1213 5.32448C9.55871 4.76187 8.79565 4.4458 8 4.4458H3V17.4458H9C9.70823 17.4458 10.3971 17.6336 11 17.9817Z"
            fill={color}
        />
    </SvgIcon>
);

BookOpenIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

BookOpenIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default BookOpenIcon;
