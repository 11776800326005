export const BRANDING = {
    BUTTON_LABELS: {
        CANCEL: 'CANCEL',
        COPY: 'COPY',
        REMOVE: 'REMOVE',
        RESTORE_DEFAULT: 'RESTORE DEFAULT',
        SAVE: 'SAVE',
        UNDO: 'UNDO',
        UPLOAD: 'UPLOAD',
    },
    DESCRIPTIONS: {
        CANCEL_CHANGES: 'Are you sure you want to cancel changes?',
        RESTORE_DEFAULT:
            "Selecting Restore Default sets the SECTION_TEXT to the platform's default configuration. Would you like to proceed?",
        RESTORE_SECTION_TEXT: {
            COLORS: 'Colors settings',
            CONTACT_US: 'Contact Settings',
            CUSTOM_LOGIN_WIDGET: 'Login Widget settings',
            LOGIN_LOGOUT: 'Sign-in/Sign-out settings',
            LOGOS: 'Logos',
        },
    },
    ERRORS: {
        INVALID_FILE_SIZE: 'Invalid file size',
        INVALID_FILE_TYPE: 'Invalid file type',
        INVALID_HEADER_STRING: 'Header may not be empty or contain extra spaces.',
    },
    LOGOS: {
        MAX_FILE_SIZE: 500000,
    },
    SUB_TITLES: {
        COLORS_PAGE: 'Use this page to configure the colors that will appear across the platform.',
        CONTACT_US_PAGE:
            'Use these settings to configure contact information that appears on email messages and other communications.',
        LOGOS_PAGE: 'Use this page to configure the logos that will appear across the platform.',
        SIGN_IN_SIGN_OUT_PAGE: 'Use this feature to customize your Sign-In page header.',
        SIGN_IN_WIDGET_PAGE: 'Use this page to configure settings for the Sign-In widget.',
    },
    TITLES: {
        CODE_SNIPPET: 'JS Snippet',
        COLORS_TOP_NAV: 'Top Navigation',
        COLORS_TOP_NAV_ACCENT_COLOR: 'Top Nav Accent Color',
        COLORS_TOP_NAV_COLOR: 'Top Nav Color',
        COLORS_TOP_NAV_TEXT_COLOR: 'Top Nav Text Color',
        COLORS_PRIMARY: 'Primary Colors',
        COLORS_PRIMARY_COLOR: 'Primary Color',
        COLORS_PRIMARY_TEXT_COLOR: 'Primary Text Color',
        COLORS_SECONDARY: 'Secondary Colors',
        COLORS_SECONDARY_COLOR: 'Secondary Color',
        COLORS_SECONDARY_TEXT_COLOR: 'Secondary Text Color',
        CONTACT_DISPLAY_COMPANY_INFORMATION: 'Display Company Information',
        CONTACT_DISPLAY_COMPANY_LOGO: 'Display Company Logo',
        CONTACT_DISPLAY_CONTACT_INFORMATION: 'Display Company Contact Information',
        LOGO_UPLOADER_LOGIN_PAGE: 'Sign-In Logo',
        LOGO_UPLOADER_NAV: 'Platform Logo',
        UPLOADER: 'Uploader',
        WHITE_BACKGROUND: 'Set White Logo Background',
        WIDGET_HEADER: 'Header',
        WIDGET_HEADER_SHOW_ICON: 'Show Header Icon',
        WIDGET_HEADER_BACKGROUND_COLOR: 'Header Background Color',
        WIDGET_HEADER_TEXT_COLOR: 'Header Text Color',
        WIDGET_HEADER_SHOW_LOGO: 'Show Header Logo',
        WIDGET_HEADER_LOGO_COLOR: 'Header Logo Color',
        WIDGET_TEXT: 'Text',
        WIDGET_TEXT_SHOW_INTRO_TEXT: 'Show Intro Text',
        WIDGET_TEXT_INTRO_TEXT_COLOR: 'Intro Text Color',
        WIDGET_TEXT_SHOW_SUPPORT_TEXT: 'Show Support Text',
        WIDGET_TEXT_SUPPORT_TEXT_COLOR: 'Support Text Color',
        WIDGET_BUTTONS: 'Buttons',
        WIDGET_BUTTONS_LOGIN_BUTTON_FULL_WIDTH: 'Full Width Sign-In Button',
        WIDGET_BUTTONS_LOGIN_BUTTON_BACKGROUND_COLOR: 'Button Background Color',
        WIDGET_BUTTONS_LOGIN_BUTTON_TEXT_COLOR: 'Button Text Color',
        WIDGET_MISC: 'Miscellaneous',
        WIDGET_MISC_MAX_WIDTH: 'Widget Max Width (250 - 9999)',
        WIDGET_MISC_SHOW_FOOTER: 'Show Footer',
        WIDGET_MISC_SHOW_FOOTER_LOGO: 'Show Footer Logo',
        WIDGET_MISC_SHOW_RESET_PASSWORD: 'Show Reset Password Link',
        WIDGET_MISC_OPEN_IN_NEW_TAB: 'Open Session in a New Browser Tab',
    },
    TOOLTIPS: {
        COLORS_PRIMARY:
            'Use this to configure the primary colors that will appear across the platform.',
        COLORS_SECONDARY:
            'Use this to configure the secondary colors that will appear across the platform.',
        COLORS_TOP_NAV: 'Use this to configure the colors that will appear in the top navigation.',
        CONTACT_US_COMPANY_LOGO:
            'To hide the company logo on system communications, click the Toggle button.',
        CONTACT_US_CONTACT_INFO:
            'To hide company contact information on email messages and other communications, click the Toggle button.',
        CUSTOM_LOGIN_WIDGET_COPY_SNIPPET: 'Copy JS Snippet',
        CUSTOM_LOGIN_WIDGET_SNIPPET_COPIED: 'JS Snippet Copied',
        LOGO_UPLOADER_LOGIN_PAGE: 'This is the logo that will appear on the sign-in page.',
        LOGO_UPLOADER_NAV: 'This is the logo that appears across the platform.',
    },
};

export const PARTNER_BRANDING_CATEGORY = {
    LOGO: 'logo',
    THEME: 'theme',
    LOGIN_LOGOUT: 'loginlogout',
};

export const SIGNIN_SIGNOUT_BRANDING = {
    SIGN_OUT_FORWARDING: 'Sign-out Forwarding',
    ALLOW_SIGN_OUT_FORWARDING: 'Allow Sign-out Forwarding',
    DESCRIPTION: 'Designate where users will be directed to when they sign-out.',
};
