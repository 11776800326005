import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const GearIcon = ({ color, colorInner, ...rest }) => (
    <SvgIcon {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 10.333a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334zM8.667 12a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0z"
            fill="#000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.667a.833.833 0 0 0-.833.833v.145a2.21 2.21 0 0 1-1.339 2.021.833.833 0 0 1-.22.06 2.208 2.208 0 0 1-2.274-.505l-.007-.007-.05-.05a.836.836 0 0 0-1.18 0 .833.833 0 0 0 0 1.18l.057.057a2.208 2.208 0 0 1 .452 2.411 2.208 2.208 0 0 1-2.012 1.421H4.5a.833.833 0 1 0 0 1.667h.145a2.208 2.208 0 0 1 2.02 1.334 2.208 2.208 0 0 1-.444 2.432l-.007.007-.05.05a.835.835 0 0 0 0 1.18.833.833 0 0 0 1.18 0l.057-.057a2.209 2.209 0 0 1 2.411-.452 2.208 2.208 0 0 1 1.421 2.012v.094a.833.833 0 0 0 1.667 0v-.145a2.208 2.208 0 0 1 1.334-2.02 2.209 2.209 0 0 1 2.432.444l.007.007.05.05a.836.836 0 0 0 .909.181.834.834 0 0 0 .27-.18v-.002a.832.832 0 0 0 0-1.179l-.056-.057a2.209 2.209 0 0 1-.444-2.431 2.209 2.209 0 0 1 2.02-1.335h.078a.834.834 0 0 0 0-1.666h-.145a2.209 2.209 0 0 1-2.021-1.339.837.837 0 0 1-.06-.22 2.208 2.208 0 0 1 .505-2.274l.007-.007.05-.05a.837.837 0 0 0 .181-.909.834.834 0 0 0-.18-.27h-.002a.833.833 0 0 0-1.179 0l-.057.056a2.208 2.208 0 0 1-2.431.444 2.208 2.208 0 0 1-1.335-2.02V4.5A.834.834 0 0 0 12 3.667zM18.167 14.5l.762.336a.542.542 0 0 0 .106.595l.046.046a2.5 2.5 0 0 1 0 3.538l-.59-.59.59.589a2.5 2.5 0 0 1-3.537 0l-.046-.046a.542.542 0 0 0-.595-.106l-.008.004a.542.542 0 0 0-.328.494v.14a2.5 2.5 0 0 1-5 0v-.063a.542.542 0 0 0-.355-.488.932.932 0 0 1-.048-.02.542.542 0 0 0-.595.106l-.046.046a2.501 2.501 0 1 1-3.537-3.537l.046-.046a.542.542 0 0 0 .106-.595l-.004-.008a.542.542 0 0 0-.494-.328H4.5a2.5 2.5 0 1 1 0-5h.063a.542.542 0 0 0 .488-.355.831.831 0 0 1 .02-.048.542.542 0 0 0-.106-.595l-.046-.046a2.5 2.5 0 1 1 3.537-3.537l.046.046a.542.542 0 0 0 .595.106.834.834 0 0 1 .166-.054.542.542 0 0 0 .237-.444V4.5a2.5 2.5 0 0 1 5 0v.073a.542.542 0 0 0 .328.494l.008.004a.542.542 0 0 0 .595-.106l.046-.046a2.5 2.5 0 1 1 3.537 3.537l-.046.046a.541.541 0 0 0-.106.595.83.83 0 0 1 .054.166.543.543 0 0 0 .444.237h.14a2.5 2.5 0 0 1 0 5h-.073a.542.542 0 0 0-.494.328l-.766-.328z"
            fill="#000"
        />
    </SvgIcon>
);

GearIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

GearIcon.defaultProps = {
    color: DEFAULT_COLORS.BLUE,
    colorInner: DEFAULT_COLORS.BLACK,
    height: '24',
    viewBox: '0 0 24 24',
    width: '24',
};

export default GearIcon;
