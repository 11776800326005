import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the list of notifications.
 *
 * @type {Object}
 */
export const fetchNotifications = createActions('notifications', 'FETCH_NOTIFICATIONS_LIST', {
    asPromise: true,
});

/**
 * Redux actions for update the notification by ID for delete or read status
 *
 * @type {Object}
 */
export const updateNotifications = createActions('notifications', 'UPDATE_NOTIFICATIONS_LIST', {
    asPromise: true,
});

/**
 * Redux actions for get notification types
 *
 * @type {Object}
 */
export const fetchNotificationsType = createActions('notifications', 'FETCH_NOTIFICATIONS_TYPE', {
    asPromise: true,
});

/**
 * Redux actions for delete notification ID
 *
 * @type {Object}
 */
export const deleteNotifications = createActions('notifications', 'DELETE_NOTIFICATIONS_ID', {
    asPromise: true,
});

/**
 * Redux actions for fetching the Menu list of notifications.
 *
 * @type {Object}
 */
export const fetchMenuNotifications = createActions(
    'notifications',
    'FETCH_MENU_NOTIFICATIONS_LIST',
    {
        asPromise: true,
    }
);
