import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import styled from 'styled-components';

import { genID } from 'ravenjs/utils/generate';
import { getThemeProps } from 'ravenjs/utils/theme';
import Divider from 'ravenjs/lib/Divider';

import Item from './Item';
import Key from './Key';
import Value from './Value';

const KeyValuePairsStyled = styled.ul`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('KeyValuePairs.styles')};
`;

const KeyComponentStyled = styled.div`
    margin-bottom: 0;

    ${({ theme }) => theme.media.down('md')`
        flex: 1 1 50%;
    `};
    ${({ theme }) => theme.media.up('md')`
        flex: ${({ flexContainer }) => flexContainer || '1 1 85%'};
    `};

    ${getThemeProps('KeyValuePairsValue.styles')};
`;

const ValueComponentStyled = styled(Value)`
    font-weight: bold;
    margin-bottom: 0;

    ${({ theme }) => theme.media.down('md')`
        font-size: 0.75rem;
        padding: 0.25rem 0;
        flex: 1 1 50%;
        word-break: break-word;
    `};
    ${({ theme }) => theme.media.up('md')`
        flex: ${({ flexContainer }) => flexContainer || '1 1 85%'};
    `};
`;

const KeyValuePairs = ({
    items,
    itemKey,
    onItemClick,
    itemValue,
    keySuffix,
    keyValueStyles,
    showDivider,
}) => (
    <KeyValuePairsStyled>
        {items.map(item => {
            const theKey = _get(item, itemKey, itemKey);
            const theValue = _get(item, itemValue, itemValue);
            const onClick = _isFunction(onItemClick) ? () => onItemClick(item) : null;
            const { [itemValue]: ValueComponent } = item;

            return (
                <React.Fragment key={genID()}>
                    <Item key={genID('KeyItem')} onClick={onClick}>
                        <Key flex={keyValueStyles.flexKey}>{`${theKey}${keySuffix}`}</Key>
                        {React.isValidElement(ValueComponent) ? (
                            <KeyComponentStyled flexContainer={keyValueStyles.flexValuePair}>
                                {ValueComponent}
                            </KeyComponentStyled>
                        ) : (
                            <ValueComponentStyled
                                flexContainer={keyValueStyles.flexValuePair}
                                flex={keyValueStyles.flexValue}
                            >
                                {theValue}
                            </ValueComponentStyled>
                        )}
                    </Item>
                    {showDivider && <Divider />}
                </React.Fragment>
            );
        })}
    </KeyValuePairsStyled>
);

KeyValuePairs.propTypes = {
    /**
     * The `key` to extract from the item.
     * Key is inserted into the first column of the KeyValuePairs.
     */
    itemKey: PropTypes.string,
    /**
     * The `value` to extract from the item.
     * Value is inserted into the second column of the KeyValuePairs.
     *
     * @type {[type]}
     */
    itemValue: PropTypes.string,
    /**
     * The list of items for the KeyValuePairs
     */
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyValueStyles: PropTypes.object,
    keySuffix: PropTypes.string,
    /**
     * The `onClick` prop for the item.
     */
    onItemClick: PropTypes.func,

    showDivider: PropTypes.bool,
};

KeyValuePairs.defaultProps = {
    itemKey: '',
    itemValue: '',
    keySuffix: '',
    keyValueStyles: {},
    onItemClick: null,
    showDivider: false,
};

export default KeyValuePairs;
