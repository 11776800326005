import _get from 'lodash/get';
import { call, put } from 'redux-saga/effects';

import { fetchConfigurationByIdAPI } from 'modules/apis';

import * as userActions from '../actions';

/**
 * Fetch info for a given configuration via a `configId`.
 *
 * @method fetchConfigurationSaga
 * @type   {Generator}
 * @param  {Object}                action The redux action
 * @return {Object|Error}
 */
export function* fetchConfigurationSaga(action) {
    try {
        // Extract the configId from the action.
        const { payload: configId } = action;
        // Make the call to fetch the configuration with the given `configId`.
        const resp = yield call(fetchConfigurationByIdAPI, configId);
        // Extract the configuration from the valid response.
        const configuration = _get(resp, 'data.configuration', {});
        // Dispatch the `fetchConfiguration` SUCCESS action.
        yield put(userActions.fetchConfiguration.success(configuration));
        // Return the configuration just in case.
        return configuration;
    } catch (error) {
        // Otherwise catch the error and dispatch the `fetchConfiguration` ERROR action.
        yield put(userActions.fetchConfiguration.error(error));
        // Return the error.
        return error;
    }
}
