import axios from 'axios';

export const fetchMyTicketsAPI = (params = {}) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: 'api/v1/tickets',
        params,
    });

export const fetchVpaAPI = (params = {}) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/internal/vpa_requests',
        params,
    });

export const fetchMyTicketsDetailsAPI = (data = {}) => {
    const { params, id } = data;

    return axios({
        method: 'get',
        type: 'tickets',
        url: `api/v1/tickets/${id}`,
        params,
    });
};

export const updateMyCaseDetailsAPI = ({ id, data }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `/api/v1/tickets/${id}`,
        data,
    });

export const fetchMyCaseStatusesAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: 'api/v1/case_statuses',
    });

export const createTicketAPI = (data = {}) => {
    data.type = 'tickets';

    return axios({
        method: 'post',
        type: 'tickets',
        url: 'api/v1/tickets',
        data: { data },
    });
};

export const fetchMyCasesTypesAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: 'api/v1/case_types',
    });

// TODO:  switch to using fetchCaseTopicsAPI() and filter with redux reducers instead of request query headers
export const fetchMyTicketsTopicsAPI = (data = {}) => {
    const { query } = data;
    return axios({
        method: 'get',
        type: 'tickets',
        url: `api/v1/topics?${query}`,
        headers: {
            Accept: 'application/vnd.api+json',
        },
    });
};

export const fetchCaseTopicsAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: 'api/v1/topics?page[size]=1000',
        headers: {
            Accept: 'application/vnd.api+json',
        },
    });

export const createCommentAPI = data =>
    axios({
        method: 'post',
        type: 'tickets',
        url: 'api/internal/comments',
        data,
    });

export const fetchCommentsAPI = params =>
    axios({
        method: 'get',
        type: 'tickets',
        url: 'api/internal/comments',
        params,
    });

export const updateCommentAPI = params => {
    const { data } = params;
    const { id } = data.data;

    return axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/comments/${id}`,
        data,
    });
};

export const deleteCommentAPI = data => {
    const { id } = data;

    return axios({
        method: 'delete',
        type: 'tickets',
        url: `api/internal/comments/${id}`,
    });
};

export const contactUsTicketAPI = ({ payload }) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: 'api/v1/contact_us_tickets',
        data: payload,
    });

export const fetchCustomerCaseCountsAPI = ({ params }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: 'api/v1/customer_ticket_counts',
        params,
    });

export const fetchUnidentifiedCasesAPI = (params = {}) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: 'api/internal/unidentified_cases',
        params,
    });

export const fetchUnidentifiedCaseAPI = (params = {}) => {
    const { id } = params;

    return axios({
        method: 'get',
        type: 'tickets',
        url: `api/internal/unidentified_cases/${id}`,
    });
};

export const respondToUnidentifiedCaseAPI = (payload = {}) => {
    const { id, body } = payload;

    return axios({
        method: 'post',
        type: 'tickets',
        url: `api/internal/unidentified_cases/${id}/responses`,
        data: {
            data: {
                type: 'unidentified_cases',
                attributes: {
                    body,
                },
            },
        },
    });
};

export const editUnidentifiedCaseAPI = (payload = {}) => {
    const { data } = payload;
    const { id } = data;

    return axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/unidentified_cases/${id}`,
        data,
    });
};

export const cancelUnidentifiedCaseAPI = (params = {}) => {
    const { id } = params;

    return axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/unidentified_cases/${id}/cancel`,
    });
};

export const cancelUnidentifiedCasesAPI = (params = {}) => {
    const { unknownTicketIds } = params;

    const data = {
        data: {
            attributes: {
                unknown_ticket_ids: unknownTicketIds,
            },
            type: 'unidentified_cases',
        },
    };

    return axios({
        data,
        method: 'put',
        type: 'tickets',
        url: `/api/internal/unidentified_cases/bulk_cancel`,
    });
};

export const fetchUnidentifiedCaseTypesAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/internal/unidentified_case_types',
    });

export const fetchAttachmentUrlAPI = ({ fileUrl }) =>
    axios({
        method: 'get',
        url: fileUrl,
    });

export const fetchCaseSurveyAPI = ({ caseId }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `/api/v1/tickets/${caseId}/surveys`,
    });

export const createCaseSurveyAPI = ({ caseId, data }) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: `/api/v1/tickets/${caseId}/surveys`,
        data,
    });

export const fetchQuickSightUrlAPI = (params = {}) =>
    axios({
        method: 'get',
        params,
        url: '/v2/amazon-quicksight-dashboards',
    });

export const fetchCaseAssignmentsAPI = (params = {}) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/v1/user_assignments',
        params,
    });

export const transferCaseAPI = ({ caseId, data }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/tickets/${caseId}/transfer`,
        data: {
            data: {
                attributes: {
                    ...data.attributes,
                },
            },
        },
    });

export const transferCasesAPI = ({ data }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/tickets/transfer`,
        data: {
            data: {
                attributes: {
                    ...data.attributes,
                },
            },
        },
    });

export const fetchPreppedAnswersAPI = (params = {}) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/internal/prepped_answers',
        params,
    });

export const fetchMultiplePreppedAnswersAPI = ({ params = {}, ids = [] }) => {
    const queryParams = ids.map(id => `filter[id][]=${id}`);
    return axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/prepped_answers?${queryParams.join('&')}`,
        params,
    });
};

export const favoritePreppedAnswerAPI = ({ id }) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${id}/favorite`,
    });

export const unFavoritePreppedAnswerAPI = ({ id }) =>
    axios({
        method: 'delete',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${id}/favorite`,
    });

export const deletePreppedAnswerAPI = ({ id }) =>
    axios({
        method: 'delete',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${id}`,
    });

export const fetchPreppedAnswerDetailsAPI = (data = {}) => {
    const { params, id } = data;

    return axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${id}`,
        params,
    });
};

export const fetchAllSuggestedPAUpdatesAPI = (params = {}) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/internal/prepped_answers/suggested_edits',
        params,
    });

export const fetchSuggestedPAUpdatesAPI = ({ preppedAnswerId }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${preppedAnswerId}/suggested_edits`,
    });

export const suggestPAUpdateAPI = ({ preppedAnswerId, data }) => {
    return axios({
        method: 'post',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${preppedAnswerId}/suggested_edits`,
        data,
    });
};

export const approveSuggestedPAUpdateAPI = ({ preppedAnswerId, id }) => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${preppedAnswerId}/suggested_edits/${id}/approve`,
    });
};

export const declineSuggestedPAUpdateAPI = ({ preppedAnswerId, id }) => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${preppedAnswerId}/suggested_edits/${id}/decline`,
    });
};

export const fetchAllCaseCountsAPI = ({ id }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/ticket_count_details/${id}`,
    });

export const fetchMessagesAPI = ({ params = {}, useInternalEndpoint = false }) => {
    const url = useInternalEndpoint ? '/api/internal/messages' : '/api/v1/messages';

    return axios({
        method: 'get',
        type: 'tickets',
        url,
        params,
    });
};

export const fetchMessageTypesAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/v1/message_types',
    });

export const createMessageAPI = ({ data, params }) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: '/api/internal/messages',
        data,
        params,
    });

export const nominatePreppedAnswerAPI = (data = {}) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: `/api/internal/vpa_requests`,
        data,
    });

export const downgradeVpaAPI = ({ id }) => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${id}/downgrade`,
    });
};

export const completeVpaAPI = ({ id }) => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/vpa_requests/${id}/complete`,
    });
};

export const declineVpaAPI = ({ id }) => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/vpa_requests/${id}/cancel`,
    });
};

export const createCaseConversationMessageAPI = ({ id, data }) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: `/api/internal/tickets/${id}/conversation_messages`,
        data,
    });

export const fetchCaseConversationAPI = ({ id, params }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/tickets/${id}/conversation_messages`,
        params,
    });

export const createMyCaseConversationMessageAPI = ({ id, data }) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: `/api/v1/tickets/${id}/conversation_messages`,
        data,
    });

export const fetchMyCaseConversationAPI = ({ id, params }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `/api/v1/tickets/${id}/conversation_messages`,
        params,
    });

export const removeMyCaseAttachmentAPI = ({ caseId, attachmentId }) =>
    axios({
        method: 'delete',
        type: 'tickets',
        url: `/api/v1/tickets/${caseId}/attachments/${attachmentId}`,
    });

export const fetchCaseDetailsAPI = ({ id, params }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/tickets/${id}`,
        params,
    });

export const updateCaseDetailsAPI = ({ id, data }) =>
    axios({
        method: 'patch',
        type: 'tickets',
        url: `/api/internal/tickets/${id}`,
        data,
    });

export const removeCaseAttachmentAPI = ({ caseId, attachmentId }) =>
    axios({
        method: 'delete',
        type: 'tickets',
        url: `/api/internal/tickets/${caseId}/attachments/${attachmentId}`,
    });

export const fetchTicketsAPI = (params = {}) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/internal/tickets',
        params,
    });

export const updatePreppedAnswerAPI = ({ data, id }) => {
    return axios({
        data,
        method: 'put',
        type: 'tickets',
        url: `/api/internal/prepped_answers/${id}`,
    });
};

export const fetchTicketCountsAPI = ({ userId }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `/api/v1/ticket_counts/${userId}`,
    });

export const fetchContentLinksAPI = (data = {}) => {
    return axios({
        method: 'get',
        url: '/v2/contentful/content-link',
        params: data,
    });
};

export const createPreppedAnswerAPI = (data = {}) => {
    return axios({
        method: 'post',
        type: 'tickets',
        url: '/api/internal/prepped_answers',
        data,
    });
};

export const linkPreppedAnswerAPI = ({ data, id }) => {
    return axios({
        method: 'post',
        type: 'tickets',
        url: `/api/internal/tickets/${id}/prepped_answer_link`,
        data,
    });
};

export const createResourceAPI = (data = {}) => {
    return axios({
        method: 'post',
        type: 'tickets',
        url: '/api/internal/resources',
        data,
    });
};

export const updateResourceAPI = ({ data }) => {
    const { data: attributes } = data;
    return axios({
        data,
        method: 'put',
        type: 'tickets',
        url: `/api/internal/resources/${attributes?.id}`,
    });
};

export const deleteResourceAPI = ({ id }) => {
    return axios({
        method: 'delete',
        type: 'tickets',
        url: `/api/internal/resources/${id}`,
    });
};

export const unlinkPreppedAnswerAPI = ({ caseId, preppedAnswerLinkId }) => {
    return axios({
        method: 'delete',
        type: 'tickets',
        url: `/api/internal/tickets/${caseId}/prepped_answer_unlink/${preppedAnswerLinkId}`,
    });
};

export const fetchCaseSourcesAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: '/api/internal/tickets/sources',
    });

export const fetchCaseTasksAPI = ({ caseId }) =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks?page[size]=1000`,
    });

export const createCaseTaskAPI = ({ caseId, data }) =>
    axios({
        method: 'post',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks`,
        data,
    });

export const updateCaseTaskAPI = ({ caseId, taskId, data }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks/${taskId}`,
        data,
    });

export const assignCaseTaskAPI = ({ caseId, taskId, data }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks/${taskId}/assign`,
        data,
    });

export const activateCaseTaskAPI = ({ caseId, taskId }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks/${taskId}/activate`,
    });

export const acceptCaseTaskAPI = ({ caseId, taskId }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks/${taskId}/accept`,
    });

export const completeCaseTaskAPI = ({ caseId, taskId }) =>
    axios({
        method: 'put',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks/${taskId}/complete`,
    });

export const deleteCaseTaskAPI = ({ caseId, taskId }) =>
    axios({
        method: 'delete',
        type: 'tickets',
        url: `api/internal/tickets/${caseId}/tasks/${taskId}`,
    });

export const fetchCaseTaskTypesAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `api/internal/tasks/types`,
    });

export const fetchCaseResourceTypesAPI = () =>
    axios({
        method: 'get',
        type: 'tickets',
        url: `api/internal/resources/types`,
    });

export const createNewCaseAPI = (data = {}) => {
    data.type = 'tickets';

    return axios({
        method: 'post',
        type: 'tickets',
        url: 'api/internal/tickets',
        data: { data },
    });
};

export const fetchCaseHistoryAPI = ({ id, params }) => {
    return axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/tickets/${id}/history`,
        params,
    });
};

export const reopenCaseAPI = id => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/tickets/${id}/reopen`,
    });
};

export const cancelCaseAPI = id => {
    return axios({
        method: 'put',
        type: 'tickets',
        url: `/api/internal/tickets/${id}/cancel`,
    });
};

export const createChatMessageReviewAPI = ({ caseId, data }) => {
    return axios({
        method: 'post',
        type: 'tickets',
        url: `/api/internal/tickets/${caseId}/chat_message_reviews`,
        data,
    });
};

export const fetchChatMessageReviewsAPI = ({ caseId }) => {
    return axios({
        method: 'get',
        type: 'tickets',
        url: `/api/internal/tickets/${caseId}/chat_message_reviews`,
    });
};

export const updateChatMessageReviewAPI = ({ caseId, reviewId, data }) => {
    return axios({
        method: 'patch',
        type: 'tickets',
        url: `/api/internal/tickets/${caseId}/chat_message_reviews/${reviewId}`,
        data,
    });
};
