import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { fetchAuditTrailEventsAPI } from 'modules/apis';

import * as adminActions from '../actions';

/**
 * Fetch audit trail events
 *
 * @method fetchAuditTrailEventsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} location
 */
export function* fetchAuditTrailEventsSaga(action) {
    try {
        const { payload: eventInfo } = action;
        const resp = yield call(fetchAuditTrailEventsAPI, eventInfo);
        const auditTrail = get(resp, 'data', {});
        yield put(adminActions.fetchAuditTrailEvents.success(auditTrail));
        return auditTrail;
    } catch (error) {
        yield put(adminActions.fetchAuditTrailEvents.error(error));
        return error;
    }
}
