import styled from 'styled-components';

import Box from 'ravenjs/lib/Box';
import { THEME } from 'ravenjs/constants/theme';

// Define styles for the AccordionHeader.
const AccordionHeaderStyled = styled(Box)`
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 0;
    transition: ${THEME.transitions.create('background-color')};
    ${({ disabled, defaultCursor }) => {
        if (disabled || defaultCursor) {
            return {
                cursor: 'default',
            };
        }
        return {};
    }}
    margin: ${({ margin }) => margin || '1.5rem 0 0.4375rem 0'};
    min-height: 3rem;
    ${({ theme }) => theme.media.down('md')`
        margin: 1.5rem 0 0.9375rem 0;
        min-height: 2.8125rem;
        line-height: 1;
    `};
    svg {
        transition: transform 400ms ease-in;
    }
`;

export default AccordionHeaderStyled;
