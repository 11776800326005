import React from 'react';
import { string } from 'prop-types';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const CheckIcon = ({ fill, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox}>
            <path
                fill={fill}
                fillRule="evenodd"
                d="M18.616 3.097l-11.182 11.9c-.285.3-.68.467-1.094.465-.413.002-.809-.166-1.094-.465l-3.657-3.942c-.414-.379-.581-.957-.433-1.499.147-.541.585-.955 1.134-1.072.549-.116 1.117.083 1.472.518L6.4 11.805 16.487 1.073c.27-.296.646-.472 1.046-.489.4-.017.79.127 1.083.4.58.584.58 1.528 0 2.113z"
            />
        </SvgIcon>
    );
};

CheckIcon.propTypes = {
    fill: string,
    height: string,
    viewBox: string,
    width: string,
};

CheckIcon.defaultProps = {
    fill: DEFAULT_COLORS.GREEN,
    height: '16',
    viewBox: '0 0 20 16',
    width: '20',
};

export default CheckIcon;
