const RESOURCES = {
    LANDING_PAGE_WIDGET_GO_WITH_CATEGORY: {
        event: 'resources',
        eventProperties: {
            category: '',
            categoryId: '',
            description: 'Go to list from landing page widget using category filter',
            section: '',
        },
    },
    LANDING_PAGE_WIDGET_GO_TO_PAGE: {
        event: 'resources',
        eventProperties: {
            description: 'Go to list from landing page widget',
            section: '',
        },
    },
    LANDING_PAGE_WIDGET_LIKE_DISLIKE: {
        event: 'resources',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Like/dislike content from landing page widget',
            value: '',
        },
    },
    LANDING_PAGE_WIDGET_OPEN_VIDEO_MODAL: {
        event: 'resources',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'Open video in modal from landing page widget',
        },
    },
    LANDING_PAGE_WIDGET_VIEW_DETAIL: {
        event: 'resources',
        eventProperties: {
            contentId: '',
            contentType: '',
            description: 'View content from landing page widget',
        },
    },
    LIST_PAGE_VIEW: {
        event: 'resourcesListPageView',
        eventProperties: {
            contentType: '',
            description: 'View Resources list page',
        },
    },
    WEBINARS: {
        LIST_PAGE_VIEW: {
            event: 'webinarsListPageView',
            eventProperties: {
                description: 'View of Webinars list page',
            },
        },
        UPCOMING: {
            event: 'webinarsPageUpcomingRegisterClick',
            eventProperties: {
                description: 'Click on Upcoming Webinars Register button',
                id: '',
                link: '',
                title: '',
            },
        },
        VIDEOS: {
            FILTERS: {
                UPDATE_CATEGORY: {
                    event: 'webinarsPageVideoFiltersUpdateCategory',
                    eventProperties: {
                        description: 'Update videos list category filters on Webinars page',
                        filters: '',
                        value: '',
                    },
                },
                UPDATE_SORT: {
                    event: 'webinarsPageVideoFiltersUpdateSort',
                    eventProperties: {
                        description: 'Update videos list sort filters on Webinars page',
                        filters: '',
                        value: '',
                    },
                },
            },
            VIEW_CONTENT: {
                event: 'webinarsPageVideosViewContent',
                eventProperties: {
                    contentId: '',
                    description: 'Click to view detail page of Webinars video',
                },
            },
            VIEW_VIDEO: {
                event: 'webinarsPageVideosViewInModal',
                eventProperties: {
                    contentId: '',
                    description: 'Click to view Webinars video in modal',
                },
            },
        },
        UPCOMING_WEBINAR: {
            event: 'webinarRegistrationClicked',
            eventProperties: {
                description: 'User click on registration links "Upcoming Webinar" page section',
                userId: '',
                link: '',
                companyId: '',
                partnerId: '',
            },
        },
    },
};

export default RESOURCES;
