import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import {
    deleteBannerAPI,
    dismissBannerAPI,
    fetchBanners,
    updateBannerById,
    createBannerAPI,
} from 'modules/apis';
import {
    getUpdateBannerPayload,
    getCreateBannerPayload,
    getDismissBannerPayload,
} from 'utils/banners';

import * as adminActions from '../actions';

/**
 * Fetch banner's list
 *
 * @method fetchBannersSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banners
 */
export function* fetchBannersSaga(action) {
    try {
        const { payload: params } = action;
        const resp = yield call(fetchBanners, params);
        const banners = get(resp, 'data', {});
        yield put(adminActions.fetchBanners.success(banners));
        return banners;
    } catch (error) {
        yield put(adminActions.fetchBanners.error(error));
        return error;
    }
}

/**
 * Update banner by id.
 *
 * @method updateBannerSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banner
 */
export function* updateBannerSaga(action) {
    try {
        const { payload: params } = action;
        const updatedBanner = getUpdateBannerPayload(params);
        const resp = yield call(updateBannerById, updatedBanner);
        const banner = get(resp, 'data', {});
        yield put(adminActions.updateBanner.success(banner));
        return banner;
    } catch (error) {
        yield put(adminActions.updateBanner.error(error));
        return error;
    }
}

/**
 * Create banner.
 *
 * @method createBannerSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banner
 */
export function* createBannerSaga(action) {
    try {
        const { payload: params } = action;
        const createBanner = getCreateBannerPayload(params);
        const resp = yield call(createBannerAPI, createBanner);
        const banner = get(resp, 'data', {});
        yield put(adminActions.createBanner.success(banner));
        return banner;
    } catch (error) {
        yield put(adminActions.createBanner.error(error));
        return error;
    }
}

/**
 * Delete banner.
 *
 * @method deleteBannerSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banner
 */
export function* deleteBannerSaga(action) {
    try {
        const { payload: params } = action;
        const { bannerId } = params;
        const resp = yield call(deleteBannerAPI, bannerId);
        const data = get(resp, 'data', {});
        yield put(adminActions.deleteBanner.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.deleteBanner.error(error));
        return error;
    }
}

/**
 * Dismiss banner.
 *
 * @method dismissBannerSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banner
 */
export function* dismissBannerSaga(action) {
    try {
        const { payload: params } = action;
        const payload = getDismissBannerPayload(params);
        const resp = yield call(dismissBannerAPI, payload);
        const data = get(resp, 'data', {});
        yield put(adminActions.dismissBanner.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.dismissBanner.error(error));
        return error;
    }
}
