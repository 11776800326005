import { DEFAULT_COLORS } from 'constants/colors';

export const MNL_INTELLIGENCE = {
    DOCUMENT_NOT_FOUND: 'Document was not found.',
    END_OF_TASK: 'END OF TASK LIST',
    END_OF_INSIGHT: 'End Of Insights',
    BACK_TO_TOP: 'Back to the top',
    ADDITIONAL_INFO_TITLE: 'Additional Information Related to Your Recent Changes',
    PRESCRIPTION_TITLE: 'You Made Some Recent Changes',
    PROACTIVE_PRESCRIPTION_TITLE: 'You Made Some Updates That May Soon Require Action',
    REACTIVE_PRESCRIPTION_TITLE: 'You Made Some Changes',
    REACTIVE_PRESCRIPTION_SUBTITLE: `Based on changes in your profile, some new rules may apply to you. Please review the information below to remain compliant with certain state and federal regulations.`,
    PROACTIVE_PRESCRIPTION_SUBTITLE:
        'Based on changes in your company profile, some new regulations may soon apply to your company. Please review the information below to remain compliant with the state and federal regulations below.',
    PROACTIVE_PRESCRIPTION_SUMMARY: `You can choose which of the recommended action items you would like to add to your addToDoList by checking the box of the action item and clicking 'Add to to-do list.'`,
    CHANGE_ACTIVITY_TITLE: 'Your change activitys:',
    ACCESS_TODO: 'Access To Do List',
    ADD_TODO: 'Add to To-Do List',
    STATE_TITLE: 'State Level Information:',
    FEDERAL_TITLE: 'Federal Level Information',
    ACTION_SUMMARY: 'You can also mark off completed items in your ',
    PENDO_SOURCE_MNL: 'mineral_intel_email',
    PRESCRIPTION_SUMMARY_INFO:
        'Because of your recent changes, we have collected information from the state and federal level (if applicable) that you need to be aware of, and in some cases take action on.',
    JURISDICTION_TYPE: {
        FEDERAL: 'FEDERAL',
        STATE: 'STATE',
    },
    REGULATIONS_TYPE: {
        FEDERAL: 'Federal',
        STATE: 'State',
    },
    CLEAR: 'Clear filters',
    APPLY: 'APPLY',
    PROACTIVE_REGULATIONS_TITLE: 'Laws that may soon apply to your company:',
    REACTIVE_REGULATIONS_TITLE: 'Laws that may now apply to your company:',
    PROACTIVE_TOOLTIP:
        'Add any action item to your to-do list by checking the box next to each action item and clicking Add to to-do list',
    FEDERAL_PRESCRIPTION_HEADER: 'Federal',
    STATE_PRESCRIPTION_HEADER: 'State Level Information',
    INSIGHTS_PAGE_TITLE: 'Insights Overview',
    WELCOME_INSIGHTS_PAGE_TITLE: 'Welcome Insights Overview',
    FEDERALTYPE: 'federal',
    STATETYPE: 'state',
    SHOW_MORE_FEDERAL: 'Show More',
    SHOW_MORE_STATE: 'Show More statename Insights',
    STATES: 'Added New states: ',
    STATE: 'Added New state: ',
    USERNAME_INFO: 'Let’s get you caught up, ',
    WELCOME: 'Welcome, ',
    WELCOME_INSIGHT: 'Welcome Insight',
    COMPLIANCE_INSIGHTS: 'Compliance Insights',
    PROACTIVE_INSIGHTS: 'Proactive Insights',
    CHANGES: 'Your Company Information:',
    UPDATES: `Updates`,
    EMPLOYEE_COUNT_INCREASE_CHANGE: 'Increased the total employee count from ',
    EMPLOYEE_COUNT_DECREASE_CHANGE: 'Decreased the total employee count from ',
    EMPLOYEE_COUNT: 'Your current total employee count is ',
    COMPANY_LOCATIONS: 'Your company has locations in: ',
    TOTAL_EMPLOYEE_COUNT: 'Your total employee count is: ',
    WELCOME_INSIGHTS_TITLE: 'WELCOME TO INSIGHTS',
    WELCOME_INSIGHTS_DESCRIPTION:
        'The following Insight provides you with regulations, action items, due dates, and other noteworthy information that may impact your business. The information provided is based on your company’s current profile data and is separated into Federal and State level regulations. Add an action item to your To-Do list at any time to keep track of recommended steps to keep your organization compliant.',
    WELCOME_INSIGHTS_BUTTON: 'Get Started',
    TODO_LIST: 'To Do List',
    TO_DO: 'To Do',
    ACTION_ITEM_TEXT: 'Action Items',
    LIST: 'list...',
    ACTION_ITEM_STARTTEXT: 'The',
    TODO_LIST_DESCRIPTION: 'detailed below can be added to your ',
    WELCOME_INSIGHTS_DELIVERED_STATUS: 'DELIVERED',
    WELCOME_INSIGHTS_VIEWED_STATUS: 'VIEWED',
    WELCOME_INSIGHTS_NOT_DELIVERED_STATUS: 'NOT_DELIVERED',
    PREVIEW_TITLE: 'Mineral Insights Prescriptions Preview',
    ACTION_ITEMS: 'Action Items',
    ACTION_ITEMS_SUBTEXT: 'Select action items to add to your to-do list',
    NO_ACTION_ITEMS_SUBTEXT: 'No action items available',

    INSIGHTS_NO_PERMISSION_MESSAGE:
        'You do not have sufficient permissions to access this page. Please contact your system administrator.',
    UPDATE_INSIGHT_PROACTIVE:
        'We’ve updated our suite of state and federal regulatory insights. See below for new insights and action items that may soon apply to your company.',
    UPDATE_INSIGHT_COMPLIANCE:
        'We’ve updated our suite of state and federal regulatory insights. See below for new insights and action items that may now apply to your company',
    PROACTIVE: 'Proactive',
    COMPLIANCE: 'Compliance',
    UPDATE_INSIGHT: 'Update Insight',
    PROACTIVE_INSIGHT: 'Proactive Insight',
    COMPLIANCE_INSIGHT: 'Compliance Insight',
    LAW_ALERT_TYPE: 'Law Alert',
    UPDATE_INSIGHT_CHANGE:
        "We've expanded our suite of state and federal and regulatory compliance insights. Some of these insights may apply to your company. ",
    DASHBOARD_TITLE: 'Hi firstName, welcome to your Mineral Intelligence Compliance Dashboard.',
    COMPANY_ID: 'Company ID: ',
    TOTAL: 'Total',
    VIEW_ALL: 'View All Insights',
    VIEW: 'View',
    RECENTLY: 'Recently Received Insights',
    MANAGE_LOCATIONS: 'Manage Locations',
    LOCATIONS: 'Locations',
    COMPANY_INFO: 'Company Info',
    PREVIEW_TTLE: 'Prescriptions Preview',
    COMPLIANCE_ACTION_ITEMS_TITLE: 'Regulations that may apply to your company',
    PROACTIVE_ACTION_ITEMS_TITLE: 'Regulations that may soon apply to your company',
    ACTION_ITEM_DESCRIPTION: 'Number of to-do list action items per federal and state regulations.',
    CHART_COLOR: [
        DEFAULT_COLORS.CATEGORY_BENEFITS,
        DEFAULT_COLORS.GREEN_1,
        DEFAULT_COLORS.LINK,
        DEFAULT_COLORS.CATEGORY_DISCRIMINATION_EQUAL_EMPLOYMENT,
        DEFAULT_COLORS.CATEGORY_BENEFITS,
        DEFAULT_COLORS.DARK_GREY,
    ],
    INSIGHTS_CATEGORY_BORDER_COLOR: {
        'Welcome Insight': DEFAULT_COLORS.CATEGORY_DISCRIMINATION_EQUAL_EMPLOYMENT,
        'Compliance Insight': DEFAULT_COLORS.CATEGORY_HIRING,
        'Proactive Insight': DEFAULT_COLORS.CATEGORY_LEAVES_ACCOMMODATIONS,
    },
    INSIGHTS_CATEGORY_BACKGROUND_COLOR: {
        'Welcome Insight': DEFAULT_COLORS.LIGHTBLUE1,
        'Compliance Insight': DEFAULT_COLORS.LIGHTORANGE,
        'Proactive Insight': DEFAULT_COLORS.LIGHTPURPLE,
    },
    SCHEDULED: 'Delivered',
    EMPLOYEES: '# of Employees',
    RECOMMENDATION: 'Insights',
    PAGE_END_TITLE: `You've reached the end of your dashboard insights.`,
    TODO_LIST_TITLE: 'My Company To-do list',
    VIEW_MORE: 'View more',
    EDIT_COMPANY: 'Edit Company Profile',
    EDIT: 'Edit',
    EMPLOYEE_HEADER: 'Number of Employees',
    COUNTY: 'County',
    REGULATION: 'Regulation',
    NO_RESULTS_MSG: `You have no Insights matching your search criteria. Try updating or clearing your search filters to view your previously received Insights.`,
    NEW: 'NEW',
    INSIGHTS: 'Insights',
    FILTERS_APPLIED: 'Filters Applied:',
    COMPLIANCE_TITLE: 'Take Action',
    PROACTIVE_TITLE: 'Plan Ahead',
    STATUS: 'Status: ',
    INSIGHTS_TITLE: 'Insights Explained',
    COMPLIANCE_DESCRIPTON: `These include all insights that may apply now to your company based on the information you’ve submitted to Mineral Intelligence. These help you stay compliant in real-time.`,
    DASHBOARD_COMPANY_HEADER: 'Your company has',
    DASHBOARD_LOCATION_HEADER: 'States & Locations',
    DASHBOARD_EMPLOYEES_HEADER: 'Number of Employees',
    REGULATION_CARD_HEADER: 'Your Latest Compliance Updates',
    FEDERAL_REGULATIONS: 'Federal Laws',
    STATE_REGULATIONS: 'State Laws',
    LAW_ALERT_TAB: 'Law Alerts',
    FEDERAL_REGULATION_HEADER: 'Law',
    STATE_REGULATION_HEADER: 'State',
    INSIGHT_RECIEVED: 'Insight Recieved',
    INSIGHTS_RECIEVED: 'Insights Recieved',
    INSIGHTS_EXPLAINED: 'Insights Explained',
    INSIGHT: 'Insight',
    PREVIEW: 'Preview of Insight',
    LEARN_MORE: 'More Details',
    VIEW_ALL_LAW_ALERT: 'View all total jurisdiction Law Alerts',
    STATE_RECOMMENDATION: 'State Insights: ',
    WELCOME_INSIGHT_TEXT: 'Welcome',
    REGULATION_TITLE: 'REGULATION',
    EMPLOYEE_NUM: '# EMPLOYEES',
    REGULATION_APPLY_MSG: ' Laws that may apply to your company.',
    LEARN_MORE_DATA: 'explore the',
    ACTIVE_STATE_JURISDICTION_MSG:
        'At totalemployeecount total employees with totaljurisdictionemployeecount employees in jurisdiction,',
    ACTIVE_FEDERAL_JURISDICTION_MSG: 'At totalemployeecount total employees, ',
    INFO_TEXT: 'The laws applicable to you are based on your current company profile data of ',
    INFO_TEXT_FOR_NO_INSIGHTS_STATE:
        'If you do not see one or more of your states on the chart then there may be no insights available for that state.',
    UPDATE_PROFILE_TEXT: `Update your company profile`,
    TOTAL_EMP_COUNT_TEXT:
        'The laws applicable to you are based on your current company profile data of  <span style="font-weight:bold;font-size:1rem;font-family:Favorit">${totalEmployeeCount} employees in ${totalStates} states.</span>',
    UPDATE_PROFILE_END_TEXT: `to ensure you receive the most accurate insights from  Mineral Intelligence`,
    UPDATE_PROFILE_PARTNER_TEXT:
        'If there is an error in this data, please contact your partner to ensure your data is up to date to receive the most accurate insights from Mineral Intelligence',
    ABOUT_DATA: 'About this data',
    GRAPH_TOOLTIP_PLANAHEAD_MSG: ' This law is not considered applicable to your company.',
    GRAPH_TOOLTIP_TAKEACTION_MSG: 'This regulation is considered applicable to your company.',
    EMPLOYEES_LABEL: 'Employees',
    GRAPH_TOOLTIP_DESC_HEADER: 'About this regulation:',
    MINTEL_NAV_STATE: 'myintelligencedashboard',
    MINTEL_INSIGHT_STATE: 'myinsights',
    MODAL_EMP_COUNT: 'Applies at',
    PROACTIVE_MODAL_MESSAGE: 'This law is not yet considered applicable to your company.',
    COMPLIANCE_MODAL_MESSAGE: 'This regulation is considered applicable to your company',
    UPGRADE_MSG:
        '<a class="upgradeLink" rel="noopener noreferrer" href="https://lp.trustmineral.com/mineral-core-promotion/">Upgrade</a>',
    UPGRADE: `Upgrade  `,
    EMPLOYEE_COUNT_THRESHOLD: 105,
    RETURN_INSIGHT: 'Return to Insight',
    INSIGHTS_TYPE_COLOR: {
        'Law Alert': DEFAULT_COLORS.BLACK,
        'Compliance Insight': DEFAULT_COLORS.CATEGORY_HIRING,
        'Proactive Insight': DEFAULT_COLORS.CATEGORY_LEAVES_ACCOMMODATIONS,
    },
    EFFECTIVE_DATE: 'Effective Date:',
    PASSAGE_DATE: 'Passage Date:',
    PAGE_ALL_INSIGHTS_DESCRIPTION: `Welcome to Mineral's suite of Mineral Intelligence Insights and laws based on employee count and jurisdiction.`,
    SORT_OPTIONS: [
        {
            label: 'Employee Count - Low to High',
            value: '-employeeCount',
        },
        {
            label: 'Employee Count - High to Low',
            value: '+employeeCount',
        },
    ],
    RECOMMENDATION_UPDATED_LABEL: 'Updated',
    EMPLOYEE_COUNT_ALL: 'All',
    SHOW_MORE_ITEM_LABEL: 'Show ${totalEntries} more ${state} item',
    SHOW_MORE_ITEMS_LABEL: 'Show ${totalEntries} more ${state} items',
    OPTIONS_ALL: { id: 'All', label: 'All' },
    SUBNAV_ACTIONS_FOR_RECOMMENDATIONS: {
        NEW_TAB: 'Open in a new Tab',
        COPY_URL: 'Copy URL (Internal Only)',
        COPY_TITLE: 'Copy Title',
        COPY_DESCRIPTION: 'Copy Description',
        COPY_ACTIONS_ITEMS: 'Copy Action Items',
        COPY_ALL: 'Copy All',
        ADD_TO_FAVORITES: 'Add to Favorites',
        REMOVE_FROM_FAVORITES: 'Remove from Favorites',
    },
    TOOLTIP_LABEL_FEDERAL: 'Applies to all employers.',
    TOOLTIP_LABEL_STATE: 'Applies to all employers in this state.',
    RECOMMENDATION_PREVIEW: 'Preview',
    FILTERS: {
        LABEL: 'Filters',
        CLEAR_LABEL: 'Clear',
    },
    SORT_LABEL: 'Sort by:',
    DETAIL_RECOMMENDATION_LABELS: {
        STATE: 'State:',
        APPLIES_AT: 'Applies at',
        UPDATED: 'Updated',
        STATE_TYPE: 'State',
        ACTION_ITEM: 'Action Item',
        ACTION_ITEMS: 'Action Items',
        DESCRIPTION: 'Description',
        LABEL_BUTTON: 'View Full Insight',
    },
    DETAIL_RECOMMENDATION_TOOLTIP: {
        FAVORITE: 'Add to Favorites',
        COPY_ALL: 'Copy All Text',
        COPY_URL: 'Copy URL (Internal Only)',
        PRINT: 'Print',
        COPY_TITLE: 'Copy Title',
    },
    COPIED: 'Copied',
    APPLIES_TO: '${state}: Applies at ${employeeCount}.',
    STATE_HAS_ITEMS: '${state} has ${totalLaws} items.',
    STATE_LAWS: '${state} Insights',
    ALL_INSIGHTS: 'All Insights',
    ADD_FAVORITES: 'Added to favorites.',
    REMOVE_FAVORITES: 'Removed from favorites.',
    PAGE_ALL_INSIGHTS_TITLE: 'Mineral Intelligence Insights',
    NO_ACTION_ITEMS: 'No action items',
    DASHBOARD_RECOMMENDATION_MODEL: {
        TOOLTIP_ADDED: 'This Action item is on your To-Do List',
        TOOLTIP_SELECT: 'Select to Add to To-Do List',
        ACTION_ITEM_TEXT: 'Action items are in your',
        ALL_ACTIONS_TODO: 'All action items have been added to',
        TODO_LIST: 'To-Do List',
    },
};
