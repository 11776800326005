const ANONYMOUS_REPORTING = {
    TELL_US_PAGE_LOADED: {
        event: 'anonymousReportingTellUsPageLoaded',
        eventProperties: {
            description: 'Tell Us page was loaded',
        },
    },
    TELL_US_IFRAME_LOADED: {
        event: 'anonymousReportingTellUsIframeLoaded',
        eventProperties: {
            description: 'Tell Us iframe was loaded',
        },
    },
};

export default ANONYMOUS_REPORTING;
