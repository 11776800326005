import { takeLatest } from 'redux-saga/effects';

import * as aiAssistantActions from '../actions';

import {
    createAISessionSaga,
    sendMessageSaga,
    getAnswerSaga,
    createFeedbackSaga,
    closeAISessionSaga,
    sendEmailCloseAISessionSaga,
    getHistoryChatSaga,
    getChatSessionDetailsSaga,
} from './aiAssistant';

/**
 * Root saga for `AI Assistant`
 * Triggers sagas related to ai assistant
 *
 * @method aiAssistantSaga
 * @type   {Generator}
 */
function* aiAssistantSaga() {
    yield takeLatest(aiAssistantActions.createAISession.TRIGGER, createAISessionSaga);
    yield takeLatest(aiAssistantActions.sendMessage.TRIGGER, sendMessageSaga);
    yield takeLatest(aiAssistantActions.getAnswer.TRIGGER, getAnswerSaga);
    yield takeLatest(aiAssistantActions.createFeedback.TRIGGER, createFeedbackSaga);
    yield takeLatest(aiAssistantActions.closeAISession.TRIGGER, closeAISessionSaga);
    yield takeLatest(
        aiAssistantActions.sendEmailCloseAISession.TRIGGER,
        sendEmailCloseAISessionSaga
    );
    yield takeLatest(aiAssistantActions.getHistoryChat.TRIGGER, getHistoryChatSaga);
    yield takeLatest(aiAssistantActions.getChatSessionDetails.TRIGGER, getChatSessionDetailsSaga);
}

export default aiAssistantSaga;

export {
    createAISessionSaga,
    sendMessageSaga,
    getAnswerSaga,
    createFeedbackSaga,
    closeAISessionSaga,
    sendEmailCloseAISessionSaga,
    getHistoryChatSaga,
    getChatSessionDetailsSaga,
};
