import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import _set from 'lodash/set';

import Button from 'ravenjs/lib/Button';
import ButtonLink from 'ravenjs/lib/Button/ButtonLink';
import Col from 'ravenjs/lib/Col';
import Divider from 'ravenjs/lib/Divider';
import Input from 'ravenjs/lib/Input';
import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { callFunc, getHandler } from 'ravenjs/utils/actions';
import { genID } from 'ravenjs/utils/generate';
import { get, isEmpty } from 'ravenjs/utils/lodash';

import MESSAGES from 'constants/messages';
import DisplayRequiredWithIconField from './DisplayRequiredWithIconField';

const DividerStyled = styled(Divider)`
    margin: 20px -10px;
    width: calc(100% + 20px);
`;

class InputWithButton extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        idSchema: PropTypes.object.isRequired,
        formContext: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        readonly: PropTypes.bool,
        registry: PropTypes.object.isRequired,
        required: PropTypes.bool,
        schema: PropTypes.object.isRequired,
        uiSchema: PropTypes.object.isRequired,
        formData: PropTypes.object,
        onBlur: PropTypes.func,
    };

    static defaultProps = {
        disabled: null,
        formData: {},
        readonly: null,
        required: null,
        onBlur: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            disableButton: true,
        };

        this.inputRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.formData.items !== prevState.items) {
            return { items: nextProps.formData.items };
        } else {
            return null;
        }
    }

    /**
     * Get the `ui:options` for the current field.
     *
     * @method _getUiOptions
     * @private
     * @param  {Object}      schema The schema to extract the options from
     * @return {Object}             The extracted options
     */
    _getUiOptions = schema => get(schema, 'ui:options', {});

    addToList = () => {
        const { items = [] } = this.state;
        const inputValue = this.inputRef.current.value;
        if (!isEmpty(inputValue)) {
            if (items.indexOf(inputValue) === -1) {
                items.push(inputValue);
                this.inputRef.current.value = '';
                this.setState(() => ({ items }));
                const { onChange } = this.props;
                // Update FormData
                onChange({ items, value: '' });
            } else {
                const { formContext } = this.props;
                const handlers = get(formContext, 'handlers', {});
                const showWarningModal = getHandler(handlers, 'showWarningModal');
                callFunc(showWarningModal);
            }
        }
    };

    removeFromList = item => {
        if (!isEmpty(item)) {
            const { onChange } = this.props;
            const { items, value } = this.state;
            const index = items.indexOf(item);

            if (index !== -1) {
                items.splice(index, 1);
                let updatedItems;

                if (items.length) {
                    updatedItems = cloneDeep(items);
                }

                this.setState(() => ({ items: updatedItems }));
                onChange({ items, value });
            }
        }
    };

    onInputChange = ({ target: { value } }) => {
        const {
            onChange,
            formContext,
            idSchema: { $id },
        } = this.props;
        const { items } = this.state;
        const formData = { ...formContext.getFormData() };
        onChange(items);
        const path = $id.split('_').splice(1);
        _set(formData, path.join('.'), { value, items });
        formContext.updateFormData({ formData });
        this.setState(() => ({
            disableButton: isEmpty(value),
            items,
            value,
        }));
    };

    renderList = (items, required) => {
        return (
            <>
                <DividerStyled color="muted" />
                <>
                    {' '}
                    {items?.map(item => (
                        <div key={genID()}>
                            <Row width="auto" style={{ paddingTop: '8px' }}>
                                <Col style={{ position: 'relative' }}>
                                    <Input required={required} type="text" value={item} disabled />
                                </Col>
                                <Col size="auto">
                                    <ButtonLink
                                        onClick={() => this.removeFromList(item)}
                                        color="secondary"
                                        style={{ padding: '7px 0', display: 'block' }}
                                    >
                                        X
                                    </ButtonLink>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </>
            </>
        );
    };

    render() {
        const {
            idSchema,
            name,
            registry,
            required,
            schema,
            uiSchema,
            formContext,
            onBlur,
        } = this.props;
        const { disableButton, items } = this.state;
        const options = this._getUiOptions(uiSchema);
        const title = get(schema, 'title') || name;
        const TitleField = get(registry, 'fields.FormGroupTitleField', () => <></>);
        const { $id: id } = idSchema;
        const { btnProps = {}, btnLabel = '', helpText, requiredText } = options;
        const isRequired = required && isEmpty(items);
        const pristine = get(formContext, 'pristine', false);
        const taintedFields = get(formContext, 'taintedFields', []);
        const showRequiredText = isEmpty(items);
        const showRequired = (!pristine || taintedFields.includes(id)) && showRequiredText;

        return (
            <>
                <Row width="auto">
                    <Col size={12}>
                        <TitleField
                            id={id}
                            title={title}
                            required={showRequiredText && showRequired}
                            helpText={helpText}
                        />
                    </Col>
                    <Col style={{ position: 'relative' }}>
                        <Input
                            required={isRequired}
                            type="text"
                            ref={this.inputRef}
                            onChange={this.onInputChange}
                            showRequired={showRequired}
                            onBlur={
                                typeof onBlur === 'function' &&
                                (event => onBlur(id, event.target.value))
                            }
                        />
                    </Col>
                    <Col size="auto">
                        <Button
                            disabled={disableButton}
                            onClick={this.addToList}
                            type="button"
                            {...btnProps}
                        >
                            {btnLabel}
                        </Button>
                        <Input
                            required={isRequired}
                            type="text"
                            value={isEmpty(items) ? '' : items[0]}
                            style={{
                                position: 'absolute',
                                opacity: 0,
                                left: 0,
                            }}
                            onChange={() => {}}
                        />
                    </Col>
                </Row>
                {showRequired && <DisplayRequiredWithIconField />}
                {showRequiredText && (
                    <Typography
                        color="error"
                        fontSize="12px"
                        fontFamily="roboto"
                        gutterLeft="5px"
                        gutterBottom="0"
                    >
                        {requiredText || MESSAGES.FORM.REQUIRED_FIELD}
                    </Typography>
                )}
                {!isEmpty(items) && this.renderList(items, showRequiredText)}
            </>
        );
    }
}

export default InputWithButton;
