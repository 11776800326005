import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const RiskIcon = ({ viewBox, width, height, color, colorElement }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            color={color}
            colorElement={colorElement}
        >
            <path
                fill={color}
                d="M15.84 0H0v14.592c1.377.02 2.325.03 2.843.03h8.767l3.943 3.942c.052.052.283.29.693.717v-4.689c.462-.066 1.274-.066 2.437 0V2.843 0H15.84z"
                transform="translate(.659 .481)"
            />
            <path
                fill={colorElement}
                d="M10.114 10.77v1.545H8.57v-1.546h1.545zm0-8.82V9.52H8.57V1.951h1.545z"
                transform="translate(.659 .481)"
            />
        </SvgIcon>
    );
};

RiskIcon.propTypes = {
    viewBox: string,
    width: string,
    height: string,
    color: string,
    colorElement: string,
};

RiskIcon.defaultProps = {
    viewBox: '0 0 20 21',
    width: '20',
    height: '21',
    color: `${DEFAULT_COLORS.RED}`,
    colorElement: `${DEFAULT_COLORS.WHITE}`,
};

export default RiskIcon;
