import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';

import { callFunc, getHandler } from 'ravenjs/utils/actions';
import Row from 'ravenjs/lib/Row';

import ToggleSwitch from 'components/ToggleSwitch';

function TableCellToggle(props) {
    const { cellProps, handlers, row, disabled } = props;
    const dataKey = _get(cellProps, 'dataKey', 'id');

    const onToggle = getHandler(handlers, 'onToggle');
    const getCheckedValue = getHandler(handlers, 'getCheckedValue');
    return (
        <Row justify="center">
            <ToggleSwitch
                showLabel
                isDisabled={disabled}
                checked={callFunc(getCheckedValue, row)}
                onChange={(e, value) => {
                    callFunc(onToggle, { id: row[dataKey], value });
                }}
            />
        </Row>
    );
}

TableCellToggle.propTypes = {
    handlers: PropTypes.object,
    row: PropTypes.object,
    cellProps: PropTypes.object,
    disabled: PropTypes.bool,
};
TableCellToggle.defaultProps = {
    handlers: null,
    disabled: false,
    row: {},
    cellProps: {},
};

export default TableCellToggle;
