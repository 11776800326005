import _get from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';
import { STICKY_COLUMN_POSITION } from 'ravenjs/constants/list';
import { KEYS } from 'ravenjs/constants/breakpoints';
import { getThemeProps } from 'ravenjs/utils/theme';
import { calcWidth } from 'ravenjs/utils/css';

/**
 * Calculate the zebra style via the given index and zebra property
 *
 * @method getZebraStyle
 * @param  {number}      index The current index to test
 * @param  {string}      zebra The zebra property, 'odd' or 'even'
 * @return {boolean}
 */
export const getZebraStyle = (index, zebra) => {
    // Increment the index by 1 to start at 1
    const idx = index + 1;
    // Switch on the zebra prop of 'odd' or 'even'
    switch (zebra) {
        case 'odd':
            return Math.abs(idx % 2) === 1;
        case 'even':
            return idx % 2 === 0;
        default:
            return false;
    }
};

/**
 * Should we disable the table-cell?
 *
 * @method disableTableCell
 * @param  {Object}         col      The column schema
 * @param  {Object}         row      The current data-set
 * @param  {Object}         handlers A list of action handlers
 * @return {boolean}                 Whether or not the table-cell is disabled
 */
export const disableTableCell = ({ col, row, handlers }) => {
    const disableKeys = _get(col, 'disabled');
    // Return `false` if no disable keys are found.
    if (!disableKeys) {
        return false;
    }
    // If keys is a string, let's look for it in the handlers
    if (typeof disableKeys === 'string' && disableKeys in handlers) {
        return handlers[disableKeys](row);
    }
    // Determine if the required keys match their
    // values for the current data set.
    return (
        row &&
        Object.keys(disableKeys).every(
            key => Boolean(key in row) && Boolean(row[key] === disableKeys[key])
        )
    );
};

export const addStickyOffsetInColumns = (columns, position, defaultOffsetWidth = '0px', theme) => {
    const stickyColumnOffset = {};
    KEYS.forEach(key => {
        stickyColumnOffset[key] = `calc(${defaultOffsetWidth} + 0px)`;
    });

    const parseSize = size => {
        const OFFSET_EXCEPTIONS = ['auto', 'min-content', 'max-content', 'fit-content'];
        const isException = Boolean(
            OFFSET_EXCEPTIONS.find(value => typeof size === 'string' && size.indexOf(value) !== -1)
        );

        if (isException) {
            return '0px';
        }

        const columnCount = getThemeProps('grid.columns', 12, { theme });

        if (typeof size === 'number' && size <= columnCount) {
            return `${calcWidth(size, columnCount)}%`;
        } else if (typeof value === 'number') {
            return `${size}px`;
        }

        return size;
    };

    const modifier = column => {
        const { size, sticky } = column;
        if (sticky && sticky.position === position) {
            const updatedColumn = {
                ...column,
                sticky: {
                    ...sticky,
                    offset: { ...stickyColumnOffset },
                },
            };

            if (typeof size === 'number' || typeof size === 'string') {
                const parsedSize = parseSize(size);

                Object.entries(stickyColumnOffset).forEach(([key, value]) => {
                    stickyColumnOffset[key] = `calc(${value} + ${parsedSize})`;
                });
            } else if (_isPlainObject(size)) {
                const parsedSize = {};
                let lastParsedSize = '0px';

                Object.entries(size).forEach(([key, value]) => {
                    parsedSize[key] = parseSize(value);
                });

                Object.entries(stickyColumnOffset).forEach(([key, value]) => {
                    if (parsedSize[key]) {
                        stickyColumnOffset[key] = `calc(${value} + ${parsedSize[key]})`;
                        lastParsedSize = parsedSize[key];
                    } else {
                        stickyColumnOffset[key] = `calc(${value} + ${lastParsedSize})`;
                    }
                });
            }

            return updatedColumn;
        }

        return column;
    };

    if (position === STICKY_COLUMN_POSITION.LEFT) {
        return columns.map(modifier);
    } else if (position === STICKY_COLUMN_POSITION.RIGHT) {
        const cloneColumns = [...columns];
        return cloneColumns
            .reverse()
            .map(modifier)
            .reverse();
    }

    return columns;
};
