import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const GripIcon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path fill="#EFE3D9" stroke="#282828" d="M0.5 0.5H12.5V26.5H0.5z" />
            <path
                fill="#D8D8D8"
                stroke="#080808"
                d="M4.5 6.5H5.5V20.5H4.5zM8.5 6.5H9.5V20.5H8.5z"
            />
        </SvgIcon>
    );
};

GripIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

GripIcon.defaultProps = {
    height: '30',
    viewBox: '0 0 20 30',
    width: '20',
};

export default GripIcon;
