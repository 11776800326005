import { takeLatest } from 'redux-saga/effects';

import * as uiActions from '../actions';
import { setIdleStatusSaga } from './idle';
import { setFeatureFlagSaga } from './featureFlags';
import { addModalSaga, closeModalSaga, openModalSaga, updateModalSaga } from './modals';
import { fetchAppNavigationMenusSaga, hideNavSaga, showNavSaga } from './nav';
import { uiLoadingSaga } from './loading';

/**
 * The root `ui` saga.
 * We're triggering sagas based their respective events.
 *
 * @method uiSaga
 * @type   {Generator}
 */
function* uiSaga() {
    // =====
    // SAGAS for NAVIGATION
    // Show the app-navigation.
    yield takeLatest(uiActions.showAppNav.TRIGGER, showNavSaga, 'appNav', uiActions.showAppNav);
    // Show the sub-navigation.
    yield takeLatest(uiActions.showSubNav.TRIGGER, showNavSaga, 'subNav', uiActions.showSubNav);
    // Show the user-navigation.
    yield takeLatest(uiActions.showUserNav.TRIGGER, showNavSaga, 'userNav', uiActions.showUserNav);
    // Hide the app-navigation.
    yield takeLatest(uiActions.hideAppNav.TRIGGER, hideNavSaga, uiActions.hideAppNav);
    // Hide the sub-navigation.
    yield takeLatest(uiActions.hideSubNav.TRIGGER, hideNavSaga, uiActions.hideSubNav);
    // Hide the user-navigation.
    yield takeLatest(uiActions.hideUserNav.TRIGGER, hideNavSaga, uiActions.hideAppNav);
    // =====
    // SAGAS for LOADING
    // Handle the loading ui state.
    yield takeLatest(uiActions.isLoading.TRIGGER, uiLoadingSaga, uiActions.isLoading);
    // =====
    // SAGAS for MODALS
    // Add a modal to the state.
    yield takeLatest(uiActions.addModal.TRIGGER, addModalSaga, uiActions.addModal);
    // Close a modal.
    yield takeLatest(uiActions.closeModal.TRIGGER, closeModalSaga, uiActions.closeModal);
    // Open a modal.
    yield takeLatest(uiActions.openModal.TRIGGER, openModalSaga, uiActions.openModal);
    yield takeLatest(uiActions.updateModal.TRIGGER, updateModalSaga, uiActions.updateModal);
    yield takeLatest(uiActions.setIdleStatus.TRIGGER, setIdleStatusSaga);
    yield takeLatest(uiActions.setFeatureFlag.TRIGGER, setFeatureFlagSaga);
}

// Default export is the module's root saga.
export default uiSaga;

// Named exports are all the internal sagas that can be used
// for testing as well as imports in other modules
export {
    addModalSaga,
    closeModalSaga,
    fetchAppNavigationMenusSaga,
    openModalSaga,
    hideNavSaga,
    showNavSaga,
    uiLoadingSaga,
};
