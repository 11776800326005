import { object, string } from 'prop-types';
import { withTheme } from 'styled-components';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const HomeIcon = ({ height, theme, viewBox, width, color, colorInner, ...rest }) => (
    <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.386 2.192a1.08 1.08 0 0 1 1.228 0l9 6.363a.882.882 0 0 1 .386.718v10c0 .723-.316 1.417-.879 1.928A3.159 3.159 0 0 1 19 22H5a3.159 3.159 0 0 1-2.121-.799C2.316 20.69 2 19.996 2 19.273v-10c0-.28.142-.546.386-.718l9-6.364zM4 9.716v9.556a.87.87 0 0 0 .293.643c.187.17.442.266.707.266h14c.265 0 .52-.096.707-.266a.87.87 0 0 0 .293-.643V9.717l-8-5.656-8 5.656z"
            fill="#000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12c0-.502.448-.91 1-.91h6c.552 0 1 .408 1 .91v9.09c0 .503-.448.91-1 .91s-1-.407-1-.91v-8.18h-4v8.18c0 .503-.448.91-1 .91s-1-.407-1-.91V12z"
            fill="#000"
        />
    </SvgIcon>
);

HomeIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    theme: object.isRequired,
    viewBox: string,
    width: string,
};

HomeIcon.defaultProps = {
    color: DEFAULT_COLORS.BLUE,
    colorInner: DEFAULT_COLORS.BLACK,
    viewBox: '0 0 24 24',
    height: '24',
    width: '24',
};

export { HomeIcon as HomeIconUnwrapped };
export default withTheme(HomeIcon);
