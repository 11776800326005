import { ROOT_ELEMENT_ID } from '../../constants/app';

/**
 * Prevent the default action the browser makes on the passed in event.
 *
 * @method preventDefault
 * @param  {Object}       event The synthetic event object
 * @return {Any}
 */
export const preventDefault = event =>
    typeof event.preventDefault === 'function' ? event.preventDefault() : null;

/**
 * Stop the passed in event from bubbling up the event chain.
 *
 * @method stopPropagation
 * @param  {Object}        event The synthetic event object
 * @return {Any}
 */
export const stopPropagation = event =>
    typeof event.stopPropagation === 'function' ? event.stopPropagation() : null;

export const addEventListenerOnRoot = (eventType, handler, ...rest) => {
    document &&
        document.getElementById &&
        document.getElementById(ROOT_ELEMENT_ID) &&
        document.getElementById(ROOT_ELEMENT_ID).addEventListener(eventType, handler, rest);
};

export const removeEventListenerOnRoot = (eventType, handler, ...rest) => {
    document &&
        document.getElementById &&
        document.getElementById(ROOT_ELEMENT_ID) &&
        document.getElementById(ROOT_ELEMENT_ID).removeEventListener(eventType, handler, rest);
};
