import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const MouseIcon = ({ height, viewBox, width, color, ...rest }) => (
    <SvgIcon
        width={width}
        height={height}
        viewBox={viewBox}
        {...rest}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9231 3.83038C22.0789 3.45633 21.9936 3.02522 21.7071 2.73869C21.4206 2.45217 20.9894 2.36687 20.6154 2.52271L3.64539 9.59271C3.26139 9.75269 3.01654 10.1337 3.03054 10.5495C3.04454 10.9652 3.31447 11.3289 3.70837 11.4627L9.57599 13.4556L4.29289 18.7387C3.90237 19.1292 3.90237 19.7624 4.29289 20.1529C4.68342 20.5434 5.31658 20.5434 5.70711 20.1529L10.9902 14.8698L12.9831 20.7374C13.1169 21.1313 13.4806 21.4012 13.8963 21.4152C14.3121 21.4292 14.6931 21.1844 14.8531 20.8004L21.9231 3.83038ZM6.8589 10.4205L19.143 5.30276L14.0252 17.5869L12.3668 12.7042C12.2669 12.4099 12.0358 12.1789 11.7416 12.0789L6.8589 10.4205Z"
            fill={color}
        />
    </SvgIcon>
);

MouseIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
    color: string,
};

MouseIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    color: DEFAULT_COLORS.BLACK,
};

export default MouseIcon;
