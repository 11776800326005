import styled from 'styled-components';

const SubNavActions = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    > * {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
        ${({ theme }) => theme.media.down('md')`
            min-width: auto;
        `};
    }
`;

export default SubNavActions;
