import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const ComplianceValueIcon = ({ color, colorInner, height, viewBox, width, ...rest }) => {
    return (
        <SvgIcon {...rest} width={width} height={height} viewBox={viewBox} fill={color}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)">
                    <circle stroke={color} cx="29" cy="29" r="28.6375" />
                    <g transform="translate(13.000000, 18.000000)" fillRule="nonzero">
                        <path
                            d="M16,0.362626852 C22.5657518,0.362626852 28.4442086,4.20678873 31.464142,10.4346667 C28.4442086,16.6625446 22.5605451,20.5067065 16,20.5067065 C9.43945493,20.5067065 3.5557915,16.6625446 0.53585807,10.4346667 C3.5557915,4.20678873 9.43424816,0.362626852 16,0.362626852 Z M16,4.02518679 C12.4670947,4.02518679 9.59052013,6.90176136 9.59052013,10.4346667 C9.59052013,13.967572 12.4670947,16.8441466 16,16.8441466 C19.5329053,16.8441466 22.4094799,13.967572 22.4094799,10.4346667 C22.4094799,6.90176136 19.5329053,4.02518679 16,4.02518679 Z M16,6.99186033 C17.8996189,6.99186033 19.4428063,8.53504764 19.4428063,10.4346667 C19.4428063,12.3342856 17.8996189,13.8774729 16,13.8774729 C14.100381,13.8774729 12.5571937,12.3342856 12.5571937,10.4346667 C12.5571937,8.53504764 14.100381,6.99186033 16,6.99186033 Z"
                            fill={color}
                        />
                        <circle fill={colorInner} cx="16" cy="10.4346667" r="3.44280634" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

ComplianceValueIcon.propTypes = {
    color: string,
    colorInner: string,
    height: string,
    viewBox: string,
    width: string,
};

ComplianceValueIcon.defaultProps = {
    color: `${DEFAULT_COLORS.YELLOW}`,
    colorInner: `${DEFAULT_COLORS.BLACK}`,
    height: '64',
    viewBox: '0 0 64 64',
    width: '64',
};

export default ComplianceValueIcon;
