import _get from 'lodash/get';

import { createReducer } from 'utils/reducer';

import * as inProductMarketingActions from './actions';
import * as helpers from './reducerHelpers';
import INITIAL_STATE from './initialState';

/**
 * Build the map of 'upsell ads' redux handlers.
 *
 * @type {Object}
 */
const inProductMarketingAds = {
    [inProductMarketingActions.fetchUpsellAds.SUCCESS]: helpers.fetchUpsellAdsSuccess,
};

// Create and export the 'upsell ads' reducer.
export default createReducer(INITIAL_STATE, inProductMarketingAds);

/**
 * Get all the Ads about the current user.
 *
 * @method getAds
 * @param  {Object} state The current state
 * @return {Object}       The Ads
 */
export const getInProductMarketingAds = state => _get(state, 'inProductMarketingAds', {});
